import React, { useCallback, useState, useRef, useEffect } from "react";
import Spreadsheet from "@blessmesanta/react-spreadsheet/dist/index";

import { TableSaveIndicator } from "../table-save-indicator/TableSaveIndicator";
import { MyPatientsTablePlaceholder } from "./MyPatientsTablePlaceholder";
import { MyPatientsTableCell } from "./MyPatientsTableCell";

import * as S from "./MyPatientsTable.styled";

export const MyPatientsTable = ({
  table,
  loading,
  loaded,
  updating,
  onUpdate,
  onCreate,
  error,
  noResultsMessage
}) => {
  const [inEditMode, setInEditMode] = useState(false);

  const $containerRef = useRef();
  const scrollOffset = useRef(0);
  const selectedCellsRef = useRef(null);

  const handleTableBlur = useCallback(() => {
    setInEditMode(false);
  }, []);

  const handleTableModeChange = useCallback(mode => {
    setInEditMode(mode === "edit");
  }, []);

  const handleTableScroll = useCallback(() => {
    scrollOffset.current = $containerRef.current.scrollLeft;
  }, []);

  const handleSelect = useCallback(cells => {
    selectedCellsRef.current = cells.map(({ row, column }) => [row, column]);
  }, []);

  const handleKeyDown = useCallback(
    e => {
      if (e.key === "Enter" && e.target.dataset.keyPressUpdate) {
        const data = JSON.parse(e.target.dataset.keyPressUpdate);
        if (data.id) {
          onUpdate(data);
        } else {
          onCreate(data);
        }
      }

      if (
        e.code === "Backspace" &&
        table.values.length > 0 &&
        selectedCellsRef.current &&
        selectedCellsRef.current.length === 1 &&
        !inEditMode
      ) {
        e.stopPropagation();

        const index = selectedCellsRef.current[0];
        const item = table.values[index[0]][index[1]];

        if (item.id && item.removable) {
          onUpdate({ id: item.id, index, value: "" });
        }
      }
    },
    [onUpdate, onCreate, inEditMode, table]
  );

  useEffect(() => {
    const element = $containerRef.current;

    if (element) {
      $containerRef.current.addEventListener("scroll", handleTableScroll);
    }

    return () => {
      element.removeEventListener("scroll", handleTableScroll);
    };
  }, [handleTableScroll]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, { capture: true });

    return () => {
      document.removeEventListener("keydown", handleKeyDown, { capture: true });
    };
  }, [handleKeyDown]);

  return (
    <S.Container>
      <S.Table ref={$containerRef} inEditMode={inEditMode}>
        {loading && <MyPatientsTablePlaceholder />}
        {!loading && loaded && table.values.length === 0 && (
          <S.NoResults>{noResultsMessage}</S.NoResults>
        )}
        {!loading && table.values.length > 0 && (
          <Spreadsheet
            data={table.values}
            hideRowIndicators
            columnLabels={table.fields.map(({ name }) => name)}
            onBlur={handleTableBlur}
            onSelect={handleSelect}
            onModeChange={handleTableModeChange}
            ColumnIndicator={({ label }) => <S.ColumnIndicator>{label}</S.ColumnIndicator>}
            DataViewer={MyPatientsTableCell}
            // onKeyDown={handleEnterPress}
          />
        )}
      </S.Table>
      <S.Footer>
        <TableSaveIndicator saving={updating} error={error} />
      </S.Footer>
    </S.Container>
  );
};
