//@flow
import * as React from "react";
import { useState } from "react";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";

import type { UploadsPatchParams } from "../../domain/value-objects/uploadedFile";
import { FileEditModal } from "./FileEditModal";
import { UploadFileEditForm } from "./UploadFileEditForm";
import apiv2 from '../../apiv2';

export const useFileEdit = () => {
  const token = useStore(tokenStore);
  const [modalIsShown, setModalIsShown] = useState(false);
  const [confirmRemoveModalIsShown, setConfirmRemoveModalIsShown] = useState(false);
  const [fileToEdit, setFileToEdit] = useState();
  const [lastEditedFile, setLastEditedFile] = useState();
  const [lastRemovedFile, setLastRemovedFile] = useState();

  const editFile = (file: any) => {
    setConfirmRemoveModalIsShown(false);
    setModalIsShown(true);
    setFileToEdit(file);
  };

  const hideModal = () => setModalIsShown(false);
  const hideConfirmRemoveModal = () => setConfirmRemoveModalIsShown(false);
  const onRemove = () => setConfirmRemoveModalIsShown(true);

  const onSubmitUploadFile = async (params: UploadsPatchParams) => {
    const request = fileToEdit?.type === "1c_analysis" ? apiv2.analyses.patchAnalyse.bind(apiv2.analyses) : apiv2.uploads.patchUploads.bind(apiv2.uploads);
    const file = await request(params.id, {
      original_name: params.original_name,
      upload_date: params.upload_date
    });
    setLastEditedFile(file);
    hideModal();
  };

  const onConfirmRemove = async () => {
    const data = {
      token: token || "",
      id: fileToEdit?.id
    };
    if (fileToEdit?.type === "1c_analysis") {
      await apiv2.analyses.removeAnalyse(data.id);
    } else {
      await apiv2.uploads.removeUploads(data.id);
    }

    setLastRemovedFile(fileToEdit);

    hideModal();
  };

  const uploadFileEditForm = fileToEdit && (
    <UploadFileEditForm file={fileToEdit} onSubmit={onSubmitUploadFile} onRemove={onRemove} />
  );

  const fileEditModal = (
    <FileEditModal
      isShown={modalIsShown}
      isShownConfirmRemove={confirmRemoveModalIsShown}
      onCancelRemove={hideConfirmRemoveModal}
      onConfirmRemove={onConfirmRemove}
      hide={hideModal}
      form={fileToEdit && uploadFileEditForm}
    />
  );

  return {
    fileEditModal,
    editFile,
    lastEditedFile,
    lastRemovedFile
  };
};
