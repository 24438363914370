import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ToastTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const ToastDescription = styled.span`
  font-size: 13px;
  line-height: 15px;
  color: ${colors.gray700};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const NoticeToast = ({ title="У Вас новое уведомление",text = "" }) => {
  return (
    <ToastContainer>
      <ToastTitle>{title}</ToastTitle>
      {!!text && <ToastDescription>{text}</ToastDescription>}
    </ToastContainer>
  );
};
