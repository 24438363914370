// @flow
import { useEffect, useState } from "react";

export const useSidebarFade = (startProp: any): boolean => {
  const [shouldStartTransition, setShouldStartTransition] = useState(false);

  useEffect(() => {
    setShouldStartTransition(true);
  }, []);

  useEffect(() => {
    setShouldStartTransition(false);
    setTimeout(() => setShouldStartTransition(true));
  }, [startProp]);

  return shouldStartTransition;
};
