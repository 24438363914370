// @flow
import * as React from "react";
import styled from "styled-components";
import { rgba } from "polished";

import type { Shortcut } from "../../domain/entities/Shortcut";
import { PNormal } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import EditIcon from "./icons/edit.svg";
import DragIcon from "./icons/drag.svg";

const Edit = styled.div`
  border: none;
  background: url(${EditIcon}) no-repeat;
  background-size: contain;
  min-width: 18px;
  height: 18px;
  opacity: 0;
  transition: all 0.15s ease-in-out;
`;

const Container = styled.div`
  width: 100%;
  height: 51px;
  box-sizing: border-box;
  padding: 0 24px;
  cursor: pointer;

  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: 0 50%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.15s ease-in-out;

  :hover {
    background-image: url(${DragIcon});
    background-color: ${rgba(colors.gray100, 0.3)};
    ${Edit} {
      opacity: 0.4;
    }
  }
`;

export const ShortcutsShortcutItem = (props: {
  shortcutName: string,
  onEdit: Shortcut => mixed,
  onInsert: Shortcut => mixed
}) => {
  const handleEdit = e => {
    e.stopPropagation();
    props.onEdit(e);
  };
  return (
    <Container onClick={props.onInsert}>
      <PNormal modifiers={["left", "nowrap"]}>{props.shortcutName}</PNormal>
      <Edit onClick={e => handleEdit(e)} />
    </Container>
  );
};
