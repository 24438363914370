// @flow
import type { Section } from "./Section";
import type { UserAgreement } from "./UserAgreement";
import type { Figure } from "./Figure";

export type LayoutBase = {
  id: number,
  groups: Array<number>,
  name: string,
  copy_id: string,
  is_editable: boolean,
  default_services_description?: string,
  needs_chief_signature?: boolean,
  user_agreement?: UserAgreement,
  figure?: Figure,
  decision_support?: boolean,
  appointment_id_bar_enabled: boolean,
  appointment_id_bar_text: string,
  slices_bar_enabled?: boolean,
  display_next_visit_date: boolean,
  feedback_layout?: ?number,
  logo?: string,
  text_for_finish: string | null,
  display_ovarian_response?: boolean
};

export type LayoutWithCount = {
  ...$Exact<LayoutBase>,
  count: number
};

export type LayoutDetailed = {
  ...$Exact<LayoutBase>,
  sections: Array<Section>,
  print_visit_time: boolean
};

export type LayoutForSummary = {
  ...$Exact<LayoutDetailed>,
  date?: string
};

export opaque type TLayout_id: number = number;

export const getLayout_id = (
  appointment: LayoutBase | LayoutWithCount | LayoutDetailed
): TLayout_id => {
  return appointment.id;
};
