import React, { Fragment, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { observer } from "mobx-react";
import reportPunctureStore from "../../../stores/reports/reportPunctureStore";
import { trimLongTextValueField } from "../../../utils/tableUtils";
import { READABLE_DATE_FORMAT } from "../../../utils/dates";
import { ReportCalculations } from "../common/ReportCalculations";
import { MaterialTable } from "../../common/MaterialTable";
import {
  besplodie1Options,
  besplodie2Options,
  besplodie3Options,
  financeTypeOptions,
  reportDateGTE,
  reportDateLTE
} from "../constants";
import { ReportDateFilter } from "../common/ReportDateFilter";
import { ReportLinkButton } from "../common/ReportLinkButton";
import { ReportsTableWrapper } from "../Reports.styled";
import organizationsStore from "../../../stores/organizationsStore";

export const ReportPuncture = observer(props => {
  const [isInitialized, setIsInitialized] = useState(reportPunctureStore.isInitialized);
  const defaultValueDates = {
    date__gte: reportDateGTE,
    date__lte: reportDateLTE
  };
  const methods = useForm({ defaultValues: defaultValueDates });

  const calculations = useMemo(
    () => [
      {
        key: "total",
        name: "Все",
        description: "Количество документов «Пункция»",
        value: reportPunctureStore.othersTableData?.["total"] || 0
      },
      {
        key: "eko",
        name: "ЭКО",
        description: "Количество документов «Пункция» в папках «ЭКО»",
        value: reportPunctureStore.othersTableData?.["eko"] || 0
      },
      {
        key: "banking",
        name: "Банкинг",
        description: "Число документов «Пункция»  в папках «Банкинг ооцитов»",
        value: reportPunctureStore.othersTableData?.["banking"] || 0
      },
      {
        key: "donors",
        name: "Доноры",
        description: "Число документов «Пункция», где «Донор ооцитов»=да",
        value: reportPunctureStore.othersTableData?.["donors"] || 0
      }
    ],
    [reportPunctureStore.othersTableData]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Прием",
        enableHiding: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        enableResizing: false,
        size: 100,
        Cell: ({ row }) => <ReportLinkButton path={`/appointment/${row.original.id}`} />
      },
      {
        accessorKey: "date",
        header: "Дата создания шаблона",
        enableColumnFilter: false,
        size: 250,
        Cell: ({ row }) => moment(row.original.date).format(READABLE_DATE_FORMAT)
      },
      {
        accessorKey: "folder_name",
        header: "Название папки",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.folder_name)
      },
      {
        accessorKey: "layout_name",
        header: "Название шаблона",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.layout_name)
      },
      {
        accessorKey: "organization",
        header: "Клиника",
        filterFn: "in",
        filterVariant: "multi-select",
        filterSelectOptions: organizationsStore.organizations.map(item => item.name),
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.organization)
      },
      {
        accessorKey: "patient_name",
        header: "Пациент",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.patient_name)
      },
      {
        accessorKey: "doctor_name",
        header: "Врач",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.doctor_name)
      },
      {
        accessorKey: "embriolog",
        header: "Эмбриолог",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.embriolog)
      },
      {
        accessorKey: "age",
        header: "Возраст женщины",
        filterFn: "range",
        filterVariant: "range",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.age)
      },
      {
        accessorKey: "amg",
        header: "АМГ",
        filterFn: "range",
        filterVariant: "range",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.amg)
      },
      {
        accessorKey: "finance_type",
        header: "Тип финансирования",
        filterFn: "icontains",
        filterVariant: "select",
        filterSelectOptions: financeTypeOptions,
        size: 250
      },
      {
        accessorKey: "besplodie1",
        header: "Бесплодие",
        filterFn: "icontains",
        filterVariant: "select",
        filterSelectOptions: besplodie1Options,
        size: 210
      },
      {
        accessorKey: "besplodie2",
        header: "Факторы бесплодия",
        filterFn: "contains",
        filterVariant: "multi-select",
        filterSelectOptions: besplodie2Options,
        size: 250,
        Cell: ({ row }) =>
          trimLongTextValueField(
            !row.original?.besplodie2?.length ? "" : row.original.besplodie2.join(", ")
          )
      },
      {
        accessorKey: "besplodie3",
        header: "Др. факторы бесплодия",
        filterFn: "contains",
        filterVariant: "multi-select",
        filterSelectOptions: besplodie3Options,
        size: 260,
        Cell: ({ row }) =>
          trimLongTextValueField(
            !row.original?.besplodie3?.length ? "" : row.original.besplodie3.join(", ")
          )
      },
      {
        accessorKey: "donor_oocitov",
        header: "Донор ооцитов",
        filterFn: "icontains",
        filterVariant: "select",
        filterSelectOptions: ["Да", "Нет"],
        enableHiding: false,
        size: 210
      }
    ],
    [organizationsStore.organizations]
  );

  const afterInitializeParams = async callback => {
    if (!reportPunctureStore.globalFiltersTable.date__lte) {
      reportPunctureStore.setGlobalFiltersTable(defaultValueDates, false);
    } else {
      methods.setValue("date__gte", reportPunctureStore.globalFiltersTable.date__gte);
      methods.setValue("date__lte", reportPunctureStore.globalFiltersTable.date__lte);
    }

    await callback();
    setIsInitialized(reportPunctureStore.isInitialized);
  };

  const topToolbarCustomActions = [
    {
      key: "set_filters",
      options: {
        color: "success",
        variant: "outlined",
        onClick: () => reportPunctureStore.fetchDataTable(true)
      },
      text: "Применить фильтры"
    }
  ];

  return (
    <Fragment>
      <ReportDateFilter {...methods} store={reportPunctureStore} />
      <ReportCalculations
        isLoading={!isInitialized}
        label={props.label}
        calculations={calculations}
      />
      <ReportsTableWrapper>
        <MaterialTable
          disableHeightResizing
          isDisableSavingPresets
          enableColumnResizing
          disableFetchAfterChangeFilters
          notPadding
          enableCollapseCustomActions={false}
          topToolbarCustomActions={topToolbarCustomActions}
          afterInitializeParams={afterInitializeParams}
          store={reportPunctureStore}
          columns={columns}
          localStorageKeyName={props.name}
          localization={props.localization}
          initialState={{
            showColumnFilters: true
          }}
        />
      </ReportsTableWrapper>
    </Fragment>
  );
});
