import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FolderContainer = styled(Link)`
  position: relative;
  display: inline-flex;
  
  &:hover {
    filter: brightness(var(--hover-brightness, 1.1));
  }
`;

const FolderTitle = styled.span`
  position: absolute;
  top: 8px;
  left: 8px;
  display: inline-block;
  font-family: "Graphik LCG";
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: var(--text-color, #fff);
  max-width: 56px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FolderText = styled.span`
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: var(--text-color, #fff);
  max-width: 124px;
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export const ChatFolderTypes = {
  APPOINTMENT: {
    folderColor: '#c6007f',
    textColor: '#fff'
  },
  FILE: {
    folderColor: '#fff5f1',
    textColor: '#262626',
    hoverBrightness: 1.02,
    target: "_blank",
    rel: "noopener noreferrer"
  }
};

export const ChatFolder = ({
  href,
  type,
  title,
  text,
  folderColor,
  textColor,
  hoverBrightness,
  target,
  rel
}) => {
  return (
    <FolderContainer
      to={{
        pathname: window.ReactNativeWebView && type === "file" ? "" : href
      }}
      target={window.ReactNativeWebView && type === "file" ? "" : target}
      rel={rel}
      style={{
        '--svg-fill': folderColor || '#C6007F',
        '--text-color': textColor || '#fff',
        '--hover-brightness': hoverBrightness || 1.1
      }}
      onClick={() => {
        if (window.ReactNativeWebView && type === "file") {
          window.ReactNativeWebView.postMessage(JSON.stringify({ event: "openLink", link: href }));
        }
      }}
    >
      <svg width="140" height="112" viewBox="0 0 140 112" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 12C0 5.37258 5.37258 0 12 0H61.349C65.4865 0 69.3321 2.13144 71.525 5.64001L76.2375 13.18C77.3339 14.9343 79.2567 16 81.3255 16H128C134.627 16 140 21.3726 140 28V100C140 106.627 134.627 112 128 112H12C5.37258 112 0 106.627 0 100V12Z" fill="var(--svg-fill, #C6007F)"/>
      </svg>
      <FolderTitle title={title}>
        {title}
      </FolderTitle>
      <FolderText title={text}>
        {text}
      </FolderText>
    </FolderContainer>
  );
};