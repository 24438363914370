import React, { useState, useCallback, useContext, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { ContextMenu } from "./EmbryosTableContextMenu";
import { EmbryosTableContext } from "./EmbryosTableContext";
import { usePortal } from "../../utils/usePortal";
import dots from "./icons/dots.svg";

const RowTr = styled.tr``;

const ContextMenuAction = styled.button`
  display: inline-block;
  opacity: 0;
  transition: 0.2s;
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    opacity: 1 !important;
  }
`;

export const Row = props => {
  const { onRowDelete, onRowDuplicate, hasEditAccess, data } = useContext(EmbryosTableContext);

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(false);

  const $rowRef = useRef(null);

  const onDuplicate = () => {
    setActionsVisible(false);
    onRowDuplicate(props.row);
    setContextMenuVisible(false);
  };

  const onDelete = () => {
    setActionsVisible(false);
    onRowDelete(props.row);
    setContextMenuVisible(false);
  };

  const btnRoot = usePortal("embryo-table-settings-root");

  const handleMouseOver = useCallback(() => {
    setActionsVisible(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setActionsVisible(false);
  }, []);

  const actionsPosition = $rowRef.current && $rowRef.current.getBoundingClientRect();

  return (
    <>
      <RowTr {...props} ref={$rowRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} />
      {hasEditAccess && (
        <>
          {ReactDOM.createPortal(
            <ContextMenuAction
              type="button"
              onClick={() => setContextMenuVisible(true)}
              style={{
                opacity: actionsVisible || contextMenuVisible ? 1 : 0,
                top: actionsPosition ? actionsPosition.top + 12 : null,
                left: actionsPosition ? actionsPosition.left - 24 : null
              }}
              onContextMenu={e => {
                e.preventDefault();
                setContextMenuVisible(true);
                return false;
              }}>
              <img src={dots} alt="context dots image" />
            </ContextMenuAction>,
            btnRoot
          )}
          {contextMenuVisible && (
            <ContextMenu
              id={data[props.row][0].entityId}
              close={() => setContextMenuVisible(false)}
              posX={actionsPosition ? actionsPosition.left - 20 : null}
              posY={actionsPosition ? actionsPosition.top + 50 : null}
              onDuplicate={onDuplicate}
              onDelete={onDelete}
            />
          )}
        </>
      )}
    </>
  );
};
