import { observer } from "mobx-react";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import React from "react";
import apiv2 from "../../apiv2";
import { SidebarTree } from "../common/sidebar-tree/SidebarTree";
import {
  checkForDatetimeInTemplate,
  checkForExaminationsTableInTemplate,
  checkForPrescribedServicesInTemplate,
  createPrescribedServicesTable,
  createPrintExaminationsTable,
  APPOINTMENT_VISIT_DATETIME_KEY,
  PPR_TEMPLATE_KEY,
  PRESCRIBED_SERVICES_TEMPLATE_KEY
} from "../decision-support/createPrintExaminationsTable";
import printEditorStore, { PrintEditorEvents } from "../../stores/printEditorStore";
import printIcon from "../appointment-page/icons/print.svg";
import styled from "styled-components";
import {
  formatAppointmentDateToDateYearWithWords
} from '../../domain/services/appointments';

const SidebarItemPrintButton = styled.button`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.2s;
  border: none;
  padding: 2px 7px;
  border-radius: 3px;
  cursor: pointer;
  background: transparent;
`;

const SidebarDocumentItem = styled.div`
  position: relative;
  padding: 12px 60px 12px 24px;
  cursor: pointer;

  font-family: "Graphik LCG";
  font-size: 15px;
  line-height: 23px;

  &:not(:last-child)::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 48px);
    left: 24px;
    bottom: 0;
    border-bottom: 1px solid #f9f9f9;
  }

  &:hover {
    background: #f9f9f9;

    ${SidebarItemPrintButton} {
      opacity: 1;
    }
  }
`;

export const DocumentsTree = observer(props => {
  const token = useStore(tokenStore);
  const { appointment } = props;

  const [agreements, setAgreements] = React.useState([]);
  const [filteredAgreements, setFilteredAgreements] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [categories, setCategories] = React.useState([]);
  const [searchText, onChangeSearchText] = React.useState("");

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const categories = await apiv2.documents.getCategories();
      setCategories(categories);
      const agreements = await apiv2.documents.get();
      setAgreements(agreements);
      setFilteredAgreements(agreements);
      setLoading(false);
    })();
  }, []);

  const onItemClick = async (item) => {
    const template = await apiv2.documents.getTemplate(item.id, {appointmentId: props.appointment.id});
    const appointment = await apiv2.appointments.getAppointment(props.appointment.id);

    if (checkForDatetimeInTemplate(template.file)) {
      const visitTime = formatAppointmentDateToDateYearWithWords(appointment.date);
      template.file = template.file.replaceAll(APPOINTMENT_VISIT_DATETIME_KEY, visitTime);
    }
    if (checkForExaminationsTableInTemplate(template.file)) {
      const examinationsTableTemplate = createPrintExaminationsTable(appointment);
      template.file = template.file.replaceAll(PPR_TEMPLATE_KEY, examinationsTableTemplate);
    }
    if (checkForPrescribedServicesInTemplate(template.file)) {
      const prescribedServicesTableTemplate = createPrescribedServicesTable(appointment);
      template.file = template.file.replaceAll(PRESCRIBED_SERVICES_TEMPLATE_KEY, prescribedServicesTableTemplate);
    }
    printEditorStore.clearEventListeners(PrintEditorEvents.back);
    printEditorStore.setData(template.file);
    printEditorStore.show();
  };

  const itemRender = (item) => {
    return <SidebarDocumentItem key={item.id}>
      {item.name}
      <SidebarItemPrintButton type="button">
        <img src={printIcon} alt="print" />
      </SidebarItemPrintButton>
    </SidebarDocumentItem>
  }

  const updateAgreements = (value) => {
    onChangeSearchText(value);
    value = value?.toLowerCase();
    setFilteredAgreements(value ? agreements.filter(item => item.name.toLowerCase().includes(value)) : agreements);
  }

  return (
    <SidebarTree
      placeholderSearch="Поиск по названию документа"
      loading={loading}
      categories={categories}
      items={filteredAgreements}
      rootName="Документы"
      itemRender={itemRender}
      onItemClick={onItemClick}
      searchText={searchText}
      onChangeSearchText={updateAgreements}
    />
  );
});