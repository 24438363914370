import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Schedule {
	apiService;
	constructor() {
		this.apiService = new ApiService('/api/schedule', null, null, API_URL);
	}
	create(start, end) {
		return this.apiService.post('create', {}, { start, end });
	}

	get(params) {
		return this.apiService.get('', params);
	}

	getScheduleUpdatesUrl() {
		const url = new URL(`${API_URL}/api/ws/schedule-updates/`);

		url.protocol = "wss:";
		return url.toString();
	}

	delete(id) {
		return this.apiService.post('delete', {}, {id});
	}

	deleteDay(date) {
		return this.apiService.post('delete-day', {}, {date});
	}

	chatEnable(date) {
		return this.apiService.post('chat', {}, {date, is_deleted: false});
	}

	chatDisable(date) {
		return this.apiService.post('chat', {}, {date, is_deleted: true});
	}

	scheduleListWithValues({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params}) {
		return this.apiService.post(`list_dynamic_data_search`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params}, {}, true, "", true);
	}
}

export default new Schedule();
