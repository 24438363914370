// @flow
import { setShortcutsPointer, shortcutsPointer, allShortcuts } from "./shortcutsShared";
import { isShortcutForChecklist } from "../../domain/value-objects/TShortcuts";
import * as R from "ramda";
import type {
  TShortcutsForChecklist,
  TChecklistDestination,
  TChecklistShortcutArray
} from "../../domain/value-objects/TShortcuts";

import type { TAppointment_id } from "../../domain/entities/Appointment";

import { createEvent, forward, merge, createStore } from "effector";
import {
  openShortcutsCreate,
  openShortcutsForChecklist,
  shortcutsSidebarSectionStore
} from "./shortcutsSections";

import {
  decisionTypeStores,
  appointmentRecommendationsStore,
  appointmentMedicationStore,
  appointmentExaminationsStore
} from "../decisionSupportStores";

type TOpenShortcutChecklistPayload = {
  fieldName: TChecklistDestination,
  appointment_id: TAppointment_id
};

export const isCurrentChecklists = shortcutsPointer.map<*>(
  pointer => (pointer && pointer.type === "checklist") || false
);

export const checklistShortcut = allShortcuts.map<Array<TShortcutsForChecklist>>(
  R.filter(isShortcutForChecklist)
);

export const isNotEmptyShortcut = (fieldName: TChecklistDestination) =>
  checklistShortcut.getState().some(el => el.destination_type === fieldName);

export const isHaveChecklistShortcuts = checklistShortcut.map<*>(shortcuts => shortcuts.length > 0);

export const isShortcutsSectionOpen = (): boolean =>
  shortcutsSidebarSectionStore.thru<boolean>(store => store.getState() !== null);

export const currentFieldStore = shortcutsPointer.map<?TChecklistDestination>(pointer =>
  pointer && pointer.type === "checklist" ? pointer.fieldName : null
);

export const currentFieldAppointmentID = shortcutsPointer.map<?TAppointment_id>(pointer =>
  // $FlowFixMe
  pointer && pointer.appointment_id ? pointer.appointment_id : null
);

export const currentChecklistStore = createStore<TChecklistShortcutArray>([]);

export const createShortcuts = createEvent<TOpenShortcutChecklistPayload>();
export const openShortcuts = createEvent<TOpenShortcutChecklistPayload>();

export const updateCreateChecklist = createEvent<*>();

const checklistMerged = merge([
  appointmentRecommendationsStore,
  appointmentMedicationStore,
  appointmentExaminationsStore
]);

forward({
  // $FlowFixMe
  from: merge([checklistMerged, currentFieldAppointmentID, currentFieldStore]),
  to: updateCreateChecklist
});

currentChecklistStore.on(updateCreateChecklist, () => {
  const appointment_id = currentFieldAppointmentID.getState();
  const fieldName = currentFieldStore.getState();

  if (!fieldName || !appointment_id) return;

  const currentStore = decisionTypeStores[fieldName].getState();

  if (appointment_id && currentStore[appointment_id]) {
    return currentStore[appointment_id].reduce((currValue, item) => {
      const checklistArray = item.items ? item.items.filter(elem => elem.is_checked) : [];
      if (item.is_checked || checklistArray.length > 0) {
        return [
          // $FlowFixMe
          ...currValue,
          // $FlowFixMe
          {
            ...item,
            items: checklistArray.length > 0 ? checklistArray : []
          }
        ];
      } else return [...currValue];
    }, []);
  } else return [];
});

forward({
  from: createShortcuts.map(() => undefined),
  to: openShortcutsCreate
});

forward({
  from: openShortcuts.map(() => undefined),
  to: openShortcutsForChecklist
});

forward({
  from: merge([createShortcuts, openShortcuts]).map(({ fieldName, appointment_id }) => ({
    type: "checklist",
    fieldName,
    appointment_id
  })),
  to: setShortcutsPointer
});
