// @flow
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { CorporateWrapper } from "../../styled/CorporateWrapper.styled";
import { CorporateCard } from "../../components/CorporateCard";
import { CorporateSubTitle } from "../../styled/CorporateTypography.styled";
import Button from "@mui/material/Button";
import { Box, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import surveyStore from "../../../stores/surveyStore";
import { DynamicForm } from "../../../components/common/dynamic-form/DynamicForm";
import { observer } from "mobx-react";
import { useStore } from "effector-react";
import { currentUser } from "../../../stores/auth";
import { colors } from "../../../components/styleguide/colors";

const WidgetContent = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;
`;

const SuccessText = styled('div')`
  color: ${colors.magenta};
  padding: 6px 0;  
`;

export const CorporateSurveyWidget = observer((props: {
  surveyId?: number,
}) => {

  const user = useStore(currentUser);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      await surveyStore.initialize(props.surveyId, 'survey');
      surveyStore.fieldsValidation();
    })();
  }, []);

  const defaultData = useMemo(() => {
    return surveyStore.currentStepValues;
  }, [surveyStore.currentStepValues]);

  const changeHandler = (data, reset, changedKey, step) => {
    surveyStore.setCurrentStepValues({ currentStepValues: data, reset, changedKey, step });
  }

  const finishStep = () => {
    const data = [];
    Object.keys(surveyStore.currentFieldsStep).forEach((stepId) => {
      surveyStore.currentFieldsStep[stepId].forEach((item) => {
        let fieldId = '';
        if (item.name.startsWith("other")) {
          fieldId = parseInt(item.name.replaceAll('other-', ''));
        } else {
          fieldId = parseInt(item.name.replaceAll('field-', ''));
        }
        if (item.label === 'ФИО') {
          data.push({
            field_id: fieldId,
            value: JSON.stringify(`${user.doctor.last_name} ${user.doctor.first_name} ${user.doctor.middle_name}`)
          })
        }
        if (item.label === 'Город') {
          data.push({
            field_id: fieldId,
            value: JSON.stringify(user.doctor.organizations)
          })
        }
        if (item.label === 'Должность') {
          data.push({
            field_id: fieldId,
            value: JSON.stringify("")
          })
        }
      })
    });
    surveyStore.finishStep(data);
  }

  useEffect(() => {
    if (surveyStore.surveyCompleted) {
      setShowSuccess(true);

      setTimeout(() => {
        setShowSuccess(false);
      }, 2000)
      surveyStore.changeSurveyCompleted(false);
      surveyStore.initialize(props.surveyId, 'survey');
    }
  }, [surveyStore.surveyCompleted]);

  return (
    <CorporateWrapper modifiers={"withoutPadding"}>
      {surveyStore.isInitialized && (
        <CorporateCard
          title={surveyStore.surveyData.survey.name.replace(": Корпоративный портал", "")}
          enableCollapseContent
          isDefaultExpanded>
          {surveyStore.surveyData.survey.description && (
            <CorporateSubTitle>{surveyStore.surveyData.survey.description}</CorporateSubTitle>
          )}
          {showSuccess && (
            <SuccessText>{surveyStore.surveyData.survey.final_text_after_survey}</SuccessText>
          )}
          <WidgetContent>
            {surveyStore.currentStepData.map(step => {
              return (
                <Fragment key={step.id}>
                  {!!defaultData[step.id] && surveyStore.currentFieldsStep[step.id].length > 0 && (
                    <DynamicForm
                      spacingForm={1}
                      textButton="Сохранить"
                      changeHandler={(...props) => changeHandler(...props, step.id)}
                      data={defaultData[step.id]}
                      debounceTime={300}
                      fields={surveyStore.currentFieldsStep[step.id].filter(
                        item =>
                          item.label !== "ФИО" &&
                          item.label !== "Город" &&
                          item.label !== "Должность"
                      )}
                      validateSchema={surveyStore.validateSchema[step.id]}
                      hiddenButtonCancel={true}
                      hiddenButtonSave={true}
                      hiddenButtonBack={true}
                    />
                  )}
                  {surveyStore.currentStepData.length > 1 && <Divider />}
                </Fragment>
              );
            })}
            <Button variant="contained" onClick={() => finishStep()} fullWidth>
              Отправить
            </Button>
          </WidgetContent>
        </CorporateCard>
      )}
    </CorporateWrapper>
  );
});
