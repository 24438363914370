//@flow
import * as React from "react";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";

import type { UploadedFile } from "../../domain/entities/UploadedFile";
import type { Analysis } from "../../domain/entities/Analysis";
import { File } from "./File";

const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -22px 0 0 -10px;
  gap: 10px;
`;

export const FileContainer = styled.div`
  position: relative;
  padding: 27px 0 0;
  box-sizing: border-box;
`;

const OverridableProps = {
  FileContainer,
  GroupContainer
};

type FileContainerOverridesProps = ExtractOverridesProps<typeof OverridableProps>;

export const FileGroup = (props: {|
  children?: Array<React$Node>,
  files?: Array<UploadedFile>,
  overrides?: FileContainerOverridesProps,
  onDelete?: (id: number) => mixed,
  onEdit?: (file: UploadedFile | Analysis) => mixed
|}) => {
  return (
    <GroupContainer {...o}>
      {props.files
        ? props.files.map((file, index) => (
            <FileContainer {...o} key={index}>
              <File file={file} onDelete={props.onDelete} onEdit={props.onEdit} />
            </FileContainer>
          ))
        : props.children
        ? props.children.map((child, index) => (
            <FileContainer {...o} key={index}>
              {child}
            </FileContainer>
          ))
        : null}
    </GroupContainer>
  );
};
