import { ChartComponent } from "../common/charts/ChartComponent";
import reviewStatisticsStore, { INTERVAL_FORMAT } from "../../stores/reviewStatisticsStore";
import { Box, Paper, Stack } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialTable } from "../common/MaterialTable";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import _ from "lodash";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import CircularProgress from "@mui/material/CircularProgress";
import organizationsStore from "../../stores/organizationsStore";
import * as yup from "yup";
import { filterValidation } from "../common/widgets-components-common/constants";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = 'widgetRate';

const transformTableData = (data, organization_ids, valueName, organizations) => {
  const returnData = [];
  if (Array.isArray(data)) {
    const organizationsData = _.groupBy(data, 'organization_id');
    Object.keys(organizationsData).forEach((organizationId) => {
      const rateData = _.groupBy(organizationsData[organizationId], 'rate');
      Object.keys(rateData).forEach((key) => {
        returnData.push(
          {
            organization: organizations.find((organization) => parseInt(organization.id) === parseInt(organizationId))?.name,
            rate: key,
            count: rateData[key].reduce((acc, item) => {
              acc += item[valueName];
              return acc;
            }, 0)
          }
        )
      })
    })
  }
  return returnData;
};

const transformRateDiagram = (data, organizationIds, interval, isCollapseData) => {
  const groupedDataDate = _.groupBy(_.sortBy(data, 'date_trunc'), 'date_trunc');
  const groupedDataOrganizations = _.groupBy(_.sortBy(data, 'organization_id'), 'organization_id');
  const groupedDataDateOrganizations = Object.keys(groupedDataDate).reduce((acc, key) => {
    acc[key] = _.groupBy(_.sortBy(groupedDataDate[key], 'organization_id'), 'organization_id');
    return acc;
  }, {})
  const labels = Object.keys(groupedDataDate);
  const organizationsData = Object.keys(groupedDataOrganizations).reduce((acc, key) => {
    acc[key] = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      label: '',
    };
    return acc;
  }, {});
  labels.forEach((labelDate) => {
    Object.keys(groupedDataOrganizations).forEach((organizationId) => {
      const dataOrganizations = groupedDataDateOrganizations[labelDate][organizationId];
      if (!organizationsData[organizationId].label && dataOrganizations) {
        organizationsData[organizationId].label = dataOrganizations[0].name;
      }
      if (dataOrganizations) {
        const groupedAppointmentType = _.groupBy(dataOrganizations, 'rate');
        const setData = (key) => {
          if (groupedAppointmentType[key]?.length) {
            organizationsData[organizationId][key].push(groupedAppointmentType[key].reduce((acc, item) => {
              acc += item.count;
              return acc;
            }, 0));
          } else {
            organizationsData[organizationId][key].push(0);
          }
        }
        setData(1);
        setData(2);
        setData(3);
        setData(4);
        setData(5);
      } else {
        organizationsData[organizationId][1].push(0);
        organizationsData[organizationId][2].push(0);
        organizationsData[organizationId][3].push(0);
        organizationsData[organizationId][4].push(0);
        organizationsData[organizationId][5].push(0);
      }
    })
  })

  const datasets = Object.keys(organizationsData).reduce((acc, key, index) => {
    const data = organizationsData[key];
    if (isCollapseData) {
      if (!acc.length) {
        acc.push({
          label: data.label + Object.keys(organizationsData).length === 1 ? ' - оценка 1' : '',
          data: data[1] || [0],
          stack: `Stack ${index}`,
          color: '#e83222',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + Object.keys(organizationsData).length === 1 ? ' - оценка 2' : '',
          data: data[2] || [0],
          stack: `Stack ${index}`,
          color: '#ef9536',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + Object.keys(organizationsData).length === 1 ? ' - оценка 3' : '',
          data: data[3] || [0],
          stack: `Stack ${index}`,
          color: '#f6d246',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + Object.keys(organizationsData).length === 1 ? ' - оценка 4' : '',
          data: data[4] || [0],
          stack: `Stack ${index}`,
          color: '#fffc55',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + Object.keys(organizationsData).length === 1 ? ' - оценка 5' : '',
          data: data[5] || [0],
          stack: `Stack ${index}`,
          color: '#63cc3d',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
      } else {
        acc[0].label += ', ' + data.label;
        acc[1].label += ', ' + data.label;
        acc[2].label += ', ' + data.label;
        acc[3].label += ', ' + data.label;
        acc[4].label += ', ' + data.label;

        if (index === Object.keys(organizationsData).length - 1) {
          acc[0].label += ' - оценка 1';
          acc[1].label += ' - оценка 2';
          acc[2].label += ' - оценка 3';
          acc[3].label += ' - оценка 4';
          acc[4].label += ' - оценка 5';
        }

        data[1].forEach((count, index) => {
          acc[0].data[index] = acc[0].data[index] + count;
        })
        data[2].forEach((count, index) => {
          acc[1].data[index] = acc[1].data[index] + count;
        })
        data[3].forEach((count, index) => {
          acc[2].data[index] = acc[2].data[index] + count;
        })
        data[4].forEach((count, index) => {
          acc[3].data[index] = acc[3].data[index] + count;
        })
        data[5].forEach((count, index) => {
          acc[4].data[index] = acc[4].data[index] + count;
        })
      }
    } else {
      acc.push({
        label: data.label + ' - оценка 1',
        data: data[1] || [0],
        stack: `Stack ${index}`,
        color: '#e83222',
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - оценка 2',
        data: data[2] || [0],
        stack: `Stack ${index}`,
        color: '#ef9536',
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - оценка 3',
        data: data[3] || [0],
        stack: `Stack ${index}`,
        color: '#f6d246',
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - оценка 4',
        data: data[4] || [0],
        stack: `Stack ${index}`,
        color: '#fffc55',
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - оценка 5',
        data: data[5] || [0],
        stack: `Stack ${index}`,
        color: '#63cc3d',
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
    }

    return acc;
  }, []);
  return { labels, datasets };
}
export const FILTERS_FIELDS_WIDGET_RATE = () => [
  {
    type: 'select',
    name: 'type',
    label: 'Вид графика',
    xs: 12,
    options: [
      {value: 'line', label: 'Линейный'},
      {value: 'bar', label: 'Столбцами'},
      {value: 'table', label: 'Таблицей'},
    ]
  },
  {
    type: 'select',
    name: 'map_names',
    label: 'Источник',
    multiple: true,
    xs: 12,
    options: reviewStatisticsStore.mapNamesOptions
  },
  {
    type: 'select',
    name: 'rates',
    label: 'Оценки',
    multiple: true,
    xs: 12,
    options: [
      {value: 1, label: '1'},
      {value: 2, label: '2'},
      {value: 3, label: '3'},
      {value: 4, label: '4'},
      {value: 5, label: '5'},
    ]
  },
  {
    type: 'checkbox',
    name: 'isCollapse',
    label: 'Объединить выбранные организации (только для графиков)',
    xs: 12,
  },
  {
    type: 'text',
    name: 'label',
    label: 'Название графика',
    xs: 12,
  },
  {
    type: 'select',
    name: 'organization_ids',
    label: 'Организации',
    multiple: true,
    needSelectAll: true,
    valueName: 'id',
    labelName: 'name',
    xs: 12,
    options: organizationsStore.organizations,
  },
];

export const WidgetRate = observer((props: {
  filtersData: () => {},
  rewriteFilters: () => {},
  keyWidget: String
}) => {

  const styledPaper = {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [filterFields, setFilterFields] = useState({});
  const [tableData, setTableData] = useState([]);
  const [chartsData, setChartsData] = useState();
  const [label, setLabel] = useState('График оценок по отзывам');
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const validateSchema = yup.object().shape({
    organization_ids: yup.array().required("организации").min(1, "организации")
  });

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
      if (getLocalStorage(NAME_WIDGET + props.keyWidget)) {
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), getLocalStorage(NAME_WIDGET + props.keyWidget));
        localStorage.removeItem(NAME_WIDGET + props.keyWidget);
      }
      const filterDiagramLocaleStorage = getLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''));
      if (filterDiagramLocaleStorage && moment(filterDiagramLocaleStorage.date_end).isBefore(moment())) {
        filterDiagramLocaleStorage.date_end = moment();
      }
      const filters = props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
      if (filters.label) {
        setLabel(filters.label);
      }
      setFiltersDataDiagram(filters)
      setFilterFields([...FILTERS_FIELDS_WIDGET_RATE()]);
      setIsInitialized(true);
    })()
  }, []);

  useEffect(() => {
    (async () => {
      if (_.size(filtersDataDiagram)) {
        setLoading(true);
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), filtersDataDiagram);
        if (filtersDataDiagram.label) {
          setLabel(filtersDataDiagram.label);
        }
        const response = await reviewStatisticsStore.getRateDiagram(filtersDataDiagram);
        if (response.error) {
          filterValidation({ validateSchema, filtersDataDiagram, setErrors });
        }
        setChartsData(transformRateDiagram(response, filtersDataDiagram.organization_ids, filtersDataDiagram.interval || 'day', filtersDataDiagram.isCollapse));
        setTableData(transformTableData(response, filtersDataDiagram.organization_ids, 'count', organizationsStore.organizations));
        setLoading(false);
      }
    })()
  }, [filtersDataDiagram]);

  useEffect(() => {
    if (props.rewriteFilters) {
      setFiltersDataDiagram({...filtersDataDiagram, ...props.rewriteFilters});
    }
  }, [props.rewriteFilters]);

  const submitHandler = (filters, callback) => {
    setErrors([]);
    setFiltersDataDiagram(filters);
    callback();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'organization', //access nested data with dot notation
        header: 'Название организации',
      },
      {
        accessorKey: 'rate',
        header: 'Оценка',
      },
      {
        accessorKey: 'count',
        header: 'Количество',
        aggregationFn: 'sum',
        AggregatedCell: ({ cell, table }) => (
          <>
            <Box
              sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            >
              {cell.getValue()}
            </Box>
          </>
        ),
        Footer: () => (
          <Stack>
            <Box color="primary.main">Всего: {tableData.reduce((acc, item) => {
              acc += item.count;
              return acc;
            }, 0)}</Box>
          </Stack>
        ),
      },
    ],
    [tableData],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    enableFullScreenToggle: false,
    enableGrouping: true,
    initialState: {
      density: 'compact',
      grouping: ['organization'],
      pagination: {pageIndex: 0, pageSize: 300}
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  return (
    <Paper style={styledPaper}>
      {filterFields && (
        <LabelWithFiltersModal
          fullWidth={true}
          type={"filter"}
          filtersData={filtersDataDiagram}
          filtersFields={filterFields}
          label={label}
          textButtonFilter={"Применить"}
          submitHandler={submitHandler}
        />
      )}
      {(loading || !isInitialized) && <AbsoluteLoader />}
      {isInitialized && (
        <>
          {filtersDataDiagram.type !== 'table' && chartsData && (
            <ChartComponent
              textButtonFilter="Применить"
              type={filtersDataDiagram.type || 'bar'}
              filtersDataDiagram={filtersDataDiagram}
              nameDiagram="ReviewDiagramRate"
              data={chartsData}
              interval={filtersDataDiagram?.interval || 'day'}
              errors={errors}
            />
          )}
          {filtersDataDiagram.type === 'table' && tableData && (
            <div style={{
              maxHeight: '100%',
              overflow: "auto"
            }} className={'material-table'}>
              <MaterialReactTable table={table} />
            </div>
          )}
        </>
      )}
    </Paper>
  )
});
