//@flow
import styled from "styled-components";
import { colors } from "../styleguide/colors";

export const PreloaderBody = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background: linear-gradient(
      to right,
      transparent,
      transparent,
      ${colors.white},
      transparent,
      transparent
    );
    animation: 3s preloader-animation ease-in-out infinite;
  }
`;
