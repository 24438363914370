import { makeAutoObservable } from "mobx";
import _ from "lodash";
import apiv2 from "../../src/apiv2";

function notContainsInSecondArray(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersectionArray(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

class PopularLayoutsStore {
  withoutGroup = "Без группы"
  allLayouts = [];
  layoutGroups = [];
  popularLayouts = [];
  selectedLayoutIds = [];
  isInitialized = false;

  constructor() {
    makeAutoObservable(this);
  }

  get selectedLayouts() {
    return this.allLayouts.filter(item => this.selectedLayoutIds.includes(item.id));
  }

  isPopularLayout(layoutId) {
    return !!this.popularLayouts.find(layout => layout.previous_layouts?.includes(layoutId));
  }

  setSelectedLayoutIds(ids) {
    this.selectedLayoutIds = ids;
  }

  async fetchAllLayouts() {
    try {
      const allLayoutsByGroups = [];
      const allLayouts = await apiv2.appointments.getLayoutsByGroupID();
      allLayouts.forEach(layout => {
        if (layout.groups.length) {
          layout.groups.forEach(groupId => {
            const newLayout = _.cloneDeep(layout);
            newLayout.groupName = this.layoutGroups.find(group => group.id === groupId)?.name;
            allLayoutsByGroups.push(newLayout);
          });
          return;
        }
        layout.groupName = this.withoutGroup;
        allLayoutsByGroups.push(layout);
      });
      allLayoutsByGroups.sort((a, b) => {
        if (a.groupName === this.withoutGroup) return 1;
        if (b.groupName === this.withoutGroup) return -1;
        if (b.groupName > a.groupName) return -1;
        if (b.groupName === a.groupName) return 0;
        if (b.groupName < a.groupName) return 1;
      });
      this.allLayouts = allLayoutsByGroups;
    } catch(e) {
      console.error(e);
    }
  }

  async fetchPopularLayouts() {
    try {
      this.popularLayouts = await apiv2.appointments.getPopularLayouts();
      this.setSelectedLayoutIds(this.popularLayouts.map(layout => layout.id))
    } catch (e) {
      console.error(e);
    }
  }

  async fetchLayoutGroups() {
    try {
      this.layoutGroups = await apiv2.appointments.getLayoutGroups();
    } catch (e) {
      console.error(e);
    }
  }

  async addPopularLayout(layoutId) {
    try {
      const newLayout = await apiv2.appointments.addPopularLayout(layoutId);
      this.popularLayouts = [...this.popularLayouts, newLayout];
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deletePopularLayout(layoutId) {
    try {
      await apiv2.appointments.deletePopularLayout(layoutId);
      this.popularLayouts = this.popularLayouts.filter(layout => layout.id !== layoutId);
      this.selectedLayoutIds = this.selectedLayoutIds.filter(id => id !== layoutId);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  addPopularLayouts(layoutIds) {
    const addedLayouts = notContainsInSecondArray(layoutIds, intersectionArray(layoutIds, this.selectedLayoutIds));
    for (let id of addedLayouts) {
      this.addPopularLayout(id);
    }
    this.setSelectedLayoutIds(layoutIds);
  }

  deletePopularLayouts(layoutIds) {
    const removedLayouts = notContainsInSecondArray(this.selectedLayoutIds, intersectionArray(this.selectedLayoutIds, layoutIds));
    for (let id of removedLayouts) {
      this.deletePopularLayout(id);
    }
    this.setSelectedLayoutIds(layoutIds);
  }

  async initialize() {
    if (!this.isInitialized) {
      try {
        await this.fetchLayoutGroups();
        await this.fetchAllLayouts();
        await this.fetchPopularLayouts();
        this.isInitialized = true;
      } catch (e) {
        console.error(e);
      }
    }
  }
}

export default new PopularLayoutsStore();
