// @flow
import { useEffect } from "react";

export const useModalEscape = (onPressEscape: () => void) => {
  useEffect(() => {
    const handleKeyUp = e => {
      if (e.keyCode === 27) {
        onPressEscape();
      }
    };

    window.addEventListener("keyup", handleKeyUp, false);

    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [onPressEscape]);
};
