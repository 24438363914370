import { addTimeSlotAndUpdateView, changeDataForNewTimeSlot, dataStore } from "./scheduleStore";
import { Select } from "../form-elements/Select";
import { Modal } from "../modal/Modal";
import { PrimaryButton } from "../buttons/PrimaryButton";
import React, { useState } from "react";
import styled from "styled-components";
import { FieldsetVertical } from "../form/FieldsetVertical";
import { ButtonContainer } from "../styleguide/form";
import moment from "moment";

const Form = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

const AddTimeSlotWrapper = styled.div`
  margin-top: 40px;

  fieldset {
    width: 100%;
    margin-bottom: 0;
  }
`;

export const AddTimeSlotForDayModal = () => {
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('17:00');
  const [errorStartTime, setErrorStartTime] = useState('');

  const submitHandler = () => {
    let startDate = moment(`${dataStore.getState().dataAddTimeSlot} ${startTime}`);
    let endDate = moment(`${dataStore.getState().dataAddTimeSlot} ${endTime}`);
    if (startDate.isAfter(endDate)) {
      setErrorStartTime('Начало первого приема не может быть позже начала последнего приема ')
    } else {
      setErrorStartTime('');
      addTimeSlotAndUpdateView(startDate, endDate)
    }
  }

  const submitButton = (
    <PrimaryButton
      disabled={!startTime || !endTime}
      onClick={submitHandler}>
      Сохранить
    </PrimaryButton>
  );

  const getDateFromHours = (time) => {
    time = time.split(':');
    const now = moment();
    now.set('hour', time[0]);
    now.set('minute', time[1]);
    return now;
  }

 const timeItems = {};

  const companyX = getDateFromHours('00:00');
  const companyY = getDateFromHours('23:45');

  for (let i = companyX.valueOf(); i <= companyY.valueOf(); i += 15 * 60000) {
    const time = moment(i).format('HH:mm');
    timeItems[time] = { name: time };
  }

  return (
    <Modal
      content={(
        <AddTimeSlotWrapper>
          <Form visible={true} button={submitButton}>
            <FieldsetVertical legend="Начало первого приема">
              <Select optionsModifiers={['overflowScroll']} error={errorStartTime} active={startTime} items={timeItems} onSelect={setStartTime}/>
            </FieldsetVertical>
            <FieldsetVertical legend="Начало последнего приема">
              <Select optionsModifiers={['overflowScroll']} active={endTime} items={timeItems} onSelect={setEndTime}/>
            </FieldsetVertical>
          </Form>
          <ButtonContainer>
            {submitButton}
          </ButtonContainer>
        </AddTimeSlotWrapper>
      )}
      isShown
      hide={() => changeDataForNewTimeSlot('')}
      title="Добавить рабочее время"
    />
  )
}