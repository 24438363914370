import * as React from "react";
import { useDebouncedCallback } from "use-debounce";

import * as S from "./MyPatientsTableSearch.styled";

export const MyPatientsTableSearch = ({ onChange }) => {
  const [debouncedChange] = useDebouncedCallback(value => {
    onChange(value);
  }, 500);

  return (
    <S.Container>
      <S.Input placeholder="Поиск по пациенту" onChange={e => debouncedChange(e.target.value)} />
      <S.SearchIcon />
    </S.Container>
  );
};
