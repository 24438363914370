//@flow
import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { PList, PSmall } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

export const Cell = styled.td`
  max-width: ${props => (props.width ? `${props.width}` : "350px")};
  height: ${props => (props.big ? "70px" : "53px")};
  box-sizing: border-box;
  padding-right: ${props => (props.withoutPadding ? 0 : "10px")};
  vertical-align: middle;
  ${props => (props.width ? `width: ${props.width};` : null)};
  @media screen and (max-width: 768px) {
    padding-right: ${props => (props.withoutPadding ? 0 : "5px")};
  };
`;

const CellLink = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: ${props => (props.center ? "center" : "flex-start")};
`;

const DateCell = styled(Cell)`
  width: 123px;
  @media (max-width: 480px) {
    width: auto;
  }
`;

const DoctorCell = styled(Cell)`
  width: 215px;
`;

const PatientCellName = styled(PSmall)`
  display: flex;
  align-items: flex-start;
  white-space: nowrap;
  width: 100%;
`;

const PatientName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PatientNameWithBadge = (props: { children?: React.Node | string }) => {
  const reference = React.useRef<HTMLSpanElement | null>(null);

  React.useEffect(() => {
    if (reference && reference.current) {
      const badgeWidth = Math.round(
        // $FlowFixMe
        reference.current.nextElementSibling.getBoundingClientRect().width
      );
      // $FlowFixMe
      reference.current.style.maxWidth = `calc(100% - ${badgeWidth}px - 8px)`;
    }
  }, [reference]);

  return <PatientName ref={reference}>{props.children}</PatientName>;
};

const Badge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid ${colors.magenta};
  border-radius: 99em;
  color: ${colors.magenta};
  margin-left: 8px;
  font-size: 11px;
  line-height: 11px;
`;

const AppointmentHistoryCellTime = (props: {|
  big?: boolean,
  children: string,
  link: string,
  onClickCell: () => mixed
|}) => (
  <DateCell big={props.big} onClick={props.onClickCell}>
    <CellLink to={props.link}>
      <PList modifiers={"disabled"}>{props.children}</PList>
    </CellLink>
  </DateCell>
);

const AppointmentHistoryCellDoctor = (props: {|
  big?: boolean,
  children: React.Node,
  link: string,
  onClickCell: () => mixed
|}) => (
  <DoctorCell big={props.big} onClick={props.onClickCell}>
    <CellLink to={props.link}>{props.children}</CellLink>
  </DoctorCell>
);

const AppointmentHistoryCellDefault = (props: {|
  big?: boolean,
  children: string,
  link: string,
  onClickCell: () => mixed
|}) => (
  <Cell big={props.big} onClick={props.onClickCell}>
    <CellLink to={props.link}>
      <PSmall>{props.children}</PSmall>
    </CellLink>
  </Cell>
);

const AppointmentHistoryCellPatient = (props: {|
  big?: boolean,
  children: string,
  badge?: string,
  link: string,
  onClickCell: () => mixed
|}) => (
  <Cell big={props.big} width="350px" onClick={props.onClickCell}>
    <CellLink to={props.link}>
      {props.badge ? (
        <PatientCellName>
          <PatientNameWithBadge>{props.children}</PatientNameWithBadge>
          <Badge>{props.badge}</Badge>
        </PatientCellName>
      ) : (
        <PSmall>{props.children}</PSmall>
      )}
    </CellLink>
  </Cell>
);

const AppointmentHistoryCellCallNeeded = (props: {|
  big?: boolean,
  children: string,
  link: string,
  onClickCell: () => mixed
|}) => (
  <Cell big={props.big} onClick={props.onClickCell}>
    <CellLink center to={props.link} style={{ width: "103px" }}>
      <PSmall>{props.children}</PSmall>
    </CellLink>
  </Cell>
);

const AppointmentHistoryCellRate = (props: {|
  big?: boolean,
  children: string,
  link: string,
  onClickCell: () => mixed
|}) => (
  <Cell big={props.big} onClick={props.onClickCell}>
    <CellLink center to={props.link} style={{ width: "120px" }}>
      <PSmall>{props.children}</PSmall>
    </CellLink>
  </Cell>
);

const AppointmentHistoryCellDot = (props: {|
  big?: boolean,
  children: ?React.Node,
  link: string,
  onClickCell: () => mixed
|}) => (
  <Cell big={props.big} width="20px" onClick={props.onClickCell}>
    <CellLink to={props.link}>{props.children}</CellLink>
  </Cell>
);

const AppointmentHistoryCellArrow = (props: {|
  big?: boolean,
  children: ?React.Node,
  link: string,
  onClickCell: () => mixed
|}) => (
  <Cell withoutPadding big={props.big} onClick={props.onClickCell}>
    <CellLink to={props.link}>{props.children}</CellLink>
  </Cell>
);

export const cellsByName = {
  time: AppointmentHistoryCellTime,
  date: AppointmentHistoryCellTime,
  doctor: AppointmentHistoryCellDoctor,
  dot: AppointmentHistoryCellDot,
  arrow: AppointmentHistoryCellArrow,
  default: AppointmentHistoryCellDefault,
  patient: AppointmentHistoryCellPatient,
  isCallNeeded: AppointmentHistoryCellCallNeeded,
  rate: AppointmentHistoryCellRate
};
