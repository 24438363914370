// @flow
import React from "react";

import { useConfirm } from "../alert-warning/useConfirm";
import { AlertWarning } from "../alert-warning/AlertWarning";
import { HeadingH3 } from "../styleguide/typography";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { ModalPortal } from "../modal/ModalPortal";

export const RemovePrescriptionTableRowWithConfirmButton = (props: {
  children: (onButtonClick: (() => mixed) => mixed) => React$Node
}) => {
  const removeRowConfirm = useConfirm();

  const removeRowWarning = (
    <AlertWarning
      isNeedConfirm={removeRowConfirm.isNeedConfirm}
      sendConfirm={removeRowConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Вы точно хотите удалить строку?</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отмена</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
      )}
    />
  );

  const onButtonClick = onClick => async () => {
    if (!removeRowConfirm.requestConfirm) {
      return;
    }

    try {
      const confirmResult = await removeRowConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      onClick();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {props.children(onButtonClick)}
      <ModalPortal>{removeRowWarning}</ModalPortal>
    </>
  );
};
