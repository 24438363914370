import { useEffect, createRef } from "react";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";

import {socketErrorHandler} from '../../utils/sockerErrorHandler';
import apiv2 from '../../apiv2';

const webSocket = createRef();

export const useScheduleSocket = socketMessageHandler => {
  const token = useStore(tokenStore);

  const initSocket = () => {
    if (webSocket.current && webSocket.current instanceof WebSocket) {
      webSocket.current.close();
    }
    webSocket.current = new WebSocket(apiv2.schedule.getScheduleUpdatesUrl());

    webSocket.current.onopen = () => {
      const authMsg = JSON.stringify({ msg_type: "auth", auth_token: token || "" });
      webSocket.current.send(authMsg);
    };

    webSocket.current.onclose = socketErrorHandler(webSocket.current, initSocket);

    webSocket.current.onmessage = event => {
      const parsedData =
        event && event.data && typeof event.data === "string" ? JSON.parse(event.data) : {};
      const { msg_type, data } = parsedData;

      socketMessageHandler(msg_type, data);
    };
  };

  useEffect(() => {
    initSocket();
  }, []);
};
