import Dialog from "@mui/material/Dialog";
import patientsStore from "../../stores/patientsStore";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { DynamicForm } from "../common/dynamic-form/DynamicForm";
import { ModalPortal } from "../modal/ModalPortal";
import React, { useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import {READABLE_DATE_FORMAT} from "../../utils/dates";


export const PatientAddFieldModal = observer(() => {
  const defaultData = {
    layout: '',
    section: '',
    field: '',
  };
  const [dataNewField, setDataNewField] = useState(defaultData);

  const onCloseModal = () => {
    patientsStore.changeShowAddFieldPopup(false);
    setDataNewField(defaultData);
  }

  const fields = [
    {
      type: 'text',
      name: 'layout',
      label: 'Шаблон',
      xs: 12,
    },
    {
      type: 'text',
      name: 'section',
      label: 'Раздел',
      xs: 12,
    },
    {
      type: 'text',
      name: 'field',
      label: 'Поле',
      xs: 12,
    },
  ]

  const submitHandler = (data) => {
    if (!data.layout && !data.section && !data.field) {
      return;
    }

    let name = `${patientsStore.customColumns.length + 1}field_`;
    let label = `${patientsStore.customColumns.length + 1}`;
    if (data.layout) {
      name += `L_${data.layout.replaceAll(' ', '_')}`;
      label += ` - ${data.layout}`;
    }
    if (data.section) {
      name += name.length > 7 ? `IIIS_${data.section.replaceAll(' ', '_')}` : data.section.replaceAll(' ', '_');
      label += ` - ${data.section}`;
    }
    if (data.field) {
      name += name.length > 7 ? `IIIF_${data.field.replaceAll(' ', '_')}` : data.field.replaceAll(' ', '_');
      label += ` - ${data.field}`;
    }
    const newField = {
      type: 'text',
      name: name,
      label: label,
      xs: 12,
      md: 3,
      sm: 6,
    }
    const newFieldDate = {
      type: 'date',
      typePicker: 'date',
      filterFn: 'range',
      name: name+'_date',
      label: label + ' (дата)',
      xs: 12,
      md: 3,
      sm: 6,
    }

    const newColumn = {
      accessorKey: name,
      header: label,
      size: 300,
    }

    const newColumnDate = {
      accessorKey: name+'_date',
      header: label + ' (дата)',
      Cell: ({ cell }) =>
          cell.getValue() ? moment(cell.getValue()).format(READABLE_DATE_FORMAT) : '',
      size: 300,
    }
    patientsStore.changeCustomFilters([...patientsStore.customFilters, newField, newFieldDate]);
    patientsStore.changeCustomColumns([...patientsStore.customColumns, newColumn, newColumnDate]);
    onCloseModal();
  }

  return (
    <ModalPortal>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={patientsStore.showAddFieldPopup}
        onClose={(event, reason) => onCloseModal(reason)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Добавление поля из приема</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => onCloseModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DynamicForm textButton="Добавить поле" data={dataNewField} submitHandler={submitHandler}
                       fields={fields} hiddenButtonCancel={true} />
        </DialogContent>
      </Dialog>
    </ModalPortal>
  )
});