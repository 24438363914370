import React, { useState, useCallback, useEffect } from "react";
import { useAsyncFn } from "react-use";

import { getPatientFullName } from "../../../domain/services/patient";

import { MedicalFileSelect } from "../../input-select/MedicalFileSelect";

import * as S from "./PartnerWidget.styled";
import apiv2 from '../../../apiv2';

export const PartnerWidget = ({ token, questionnaireId, field, onChange }) => {
  const [value, setValue] = useState(
    field.value && !field.value.medical_file_id ? field.value.value : ""
  );

  const [, updateQuestionnaire] = useAsyncFn(async partnerId => {
    await apiv2.questionnaire.patchQuestionnairePartner(questionnaireId, partnerId);
  });

  const [fetchMedicalFileByIdState, fetchMedicalFileById] = useAsyncFn(async id => {
    const medicalFile = await apiv2.medicalFiles.getById(id);
    setValue(getPatientFullName(medicalFile.patient));
  });

  const handleSelect = useCallback(
    medicalFile => {
      const patientFullName = getPatientFullName(medicalFile.patient);
      setValue(patientFullName);

      updateQuestionnaire(medicalFile.patient.id);
      onChange({
        field_type: field.field_type,
        field_id: field.id,
        value: [{ medical_file_id: medicalFile.id, value: patientFullName }]
      });
    },
    [field, onChange, updateQuestionnaire]
  );

  const handleAdd = useCallback(
    value => {
      setValue();
      onChange({
        field_type: field.field_type,
        field_id: field.id,
        value: [{ value }]
      });
    },
    [field, onChange]
  );

  useEffect(() => {
    if (field.value) {
      if (field.value.medical_file_id) {
        fetchMedicalFileById(field.value.medical_file_id);
      } else {
        setValue(field.value.value);
      }
    }
  }, []);

  return (
    <S.Container>
      {fetchMedicalFileByIdState.loading ? (
        <S.Loading>Загрузка медицинской карты ...</S.Loading>
      ) : (
        <MedicalFileSelect value={value} onSelect={handleSelect} onAdd={handleAdd} />
      )}
    </S.Container>
  );
};
