// @flow
import { createEvent, forward, restore } from "effector";

type TContent =
  | "INFO_CONTENT"
  | "DIAGNOSES_TREE_CONTENT"
  | "SERVICES"
  | "STIMULATION_PROTOCOL_CONTENT"
  | "PRINT_DOCUMENTS";

export const INFO_CONTENT: TContent = "INFO_CONTENT";

export const DIAGNOSES_TREE_CONTENT: TContent = "DIAGNOSES_TREE_CONTENT";

export const SERVICES: TContent = "SERVICES";

export const STIMULATION_PROTOCOL_CONTENT: TContent = "STIMULATION_PROTOCOL_CONTENT";

export const PRINT_DOCUMENTS: TContent = "PRINT_DOCUMENTS";

export const openInfoRightSidebar = createEvent<void>();

export const openDiagnosesTreeSidebar = createEvent<void>();

export const openPrintDocumentsSidebar = createEvent<void>();

export const closeRightSidebar = createEvent<void>();

export const changeRightSidebar = createEvent<?TContent>();

export const rightSidebarState = restore<*>(changeRightSidebar, null);

export const isRightSidebarOpenState = rightSidebarState.map<boolean>(state => !!state);

export const isAppointmentSidebarHiddenState = rightSidebarState.map<*>(
  state => state === "INFO_CONTENT" || state === "STIMULATION_PROTOCOL_CONTENT"
);

forward({
  from: openInfoRightSidebar.map(() => INFO_CONTENT),
  to: changeRightSidebar
});
forward({
  from: openDiagnosesTreeSidebar.map(() => DIAGNOSES_TREE_CONTENT),
  to: changeRightSidebar
});
forward({
  from: openPrintDocumentsSidebar.map(() => PRINT_DOCUMENTS),
  to: changeRightSidebar
});
forward({
  from: closeRightSidebar.map(() => null),
  to: changeRightSidebar
});
