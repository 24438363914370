// @flow
import React from "react";
import styled from "styled-components";
import { HeadingH6 } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import { useAutoFocusRef } from "../../utils/useAutoFocusRef";
import { RequiredField } from "../form/FieldGroupItemFieldSet";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 15px;
  border-bottom: solid 1px ${colors.transparentGray};
  margin: 0 30px;
  width: calc(100% - 60px);
  border-color: ${(props) => props?.error && colors.watermelon};
  ${HeadingH6} {
    color: ${(props) => props?.error && colors.watermelon};
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
    margin: 0 20px;
    padding: 0 0 15px;
    width: calc(100% - 40px);
  }
  @media (max-width: 480px) {
    padding: 0 0 10px;
    margin: 0 15px;
    width: calc(100% - 30px);
  }
`;

const TitleContainer = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    flex-basis: 70%;
  }
`;

const ChildrenContainer = styled.div`
  @media (min-width: 769px) {
    opacity: ${props => (props.isAlreadyOpened ? 1 : 0)};
    transition: opacity 0.2s;
  }

  ${Container}:hover > & {
    opacity: 1;
  }
`;

const Input = styled(HeadingH6)`
  border: none;
  width: 100%;
  outline: none;
  padding: 0;
  font: inherit !important;
`;

export const GroupTitle = (props: {
  isEdit?: boolean,
  onBlur?: () => mixed,
  onChange?: string => mixed,
  title: string,
  checkbox?: React$Node,
  children?: React$Node,
  isAlreadyOpened?: boolean,
  isRequired?: boolean,
  isError?: boolean
}) => {
  const { title, checkbox, children, isEdit, isRequired, isError, onChange, onBlur, isAlreadyOpened } = props;
  const inputRef = useAutoFocusRef();

  return (
    <Container error={isError}>
      {isRequired && <RequiredField>*</RequiredField>}
      {!isEdit && checkbox ? checkbox : null}
      <TitleContainer>
        {isEdit ? (
          <HeadingH6>
            <Input
              ref={inputRef}
              as="input"
              type="text"
              value={title}
              onChange={e => onChange && onChange(e.target.value)}
              onBlur={onBlur}
            />
          </HeadingH6>
        ) : (
          <HeadingH6>{title}</HeadingH6>
        )}
      </TitleContainer>
      {children && (
        <ChildrenContainer isAlreadyOpened={isAlreadyOpened}>{children}</ChildrenContainer>
      )}
    </Container>
  );
};
