import React from "react";
import styled from "styled-components";
import plusIcon from "../drug-select/icons/plus.svg";
import { PNormal } from "../styleguide/typography";

const Container = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  background: url(${plusIcon});
  background-size: cover;
  width: 12px;
  height: 12px;
`;

const TextContainer = styled.div`
  margin-left: 8px;
  opacity: 0.3;
`;

export const TableAddRowButton = (props) => {
  return (
    <Container type="button" onClick={props.onClick}>
      <Icon />
      <TextContainer>
        <PNormal modifiers="left">{props.children}</PNormal>
      </TextContainer>
    </Container>
  );
};
