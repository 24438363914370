import * as React from "react";
import styled from "styled-components";
import { PreloaderBody } from "../prealoader/PreloaderBody";

const Table = styled.table`
  margin-right: 30px;
  min-width: calc(100% - 30px);
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;
`;

const Column = styled.th`
  border: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid #c6007f;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
  font-size: 15px;
  width: ${({ isFirst }) => (isFirst ? "68px" : "unset")};
  padding: 0 16px;
  max-width: 300px;
  height: 44px;
  font-weight: 500;
  vertical-align: middle;
  white-space: break-spaces;
  line-height: 1.35;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

const Cell = styled.td`
  vertical-align: middle;
  border-bottom: 1px solid rgb(68, 68, 68, 0.1);
  border-right: 1px solid rgb(68, 68, 68, 0.1);
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
  padding: 0 16px;
  font-size: 15px;
  min-width: ${({ isShort }) => (isShort ? "unset" : "140px")};
  max-width: 300px;
  height: 44px;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

const PlaceholderBase = styled.div`
  height: 10px;
  border-radius: 3px;
  background: #d4d4d4;
  opacity: 0.5;
`;

const PlaceholderCol = styled(PlaceholderBase)`
  width: 182px;
  height: 30px;
`;

const getCellWidth = index => {
  const mod = index % 3;
  switch (mod) {
    case 0:
      return 169;
    case 1:
      return 228;
    case 2:
      return 125;
    default:
      return 169;
  }
};

export const EmbryosTablePlaceholder = () => {
  return (
    <div>
      <PreloaderBody>
        <Table>
          <thead>
            <tr>
              <Column isFirst>
                <PlaceholderCol style={{ width: 34 }} />
              </Column>
              <Column>
                <PlaceholderCol style={{ width: getCellWidth(0) }} />
              </Column>
              <Column>
                <PlaceholderCol style={{ width: getCellWidth(0) }} />
              </Column>
              <Column>
                <PlaceholderCol style={{ width: getCellWidth(0) }} />
              </Column>
              <Column>
                <PlaceholderCol style={{ width: getCellWidth(0) }} />
              </Column>
            </tr>
          </thead>
          <tbody>
            {[...new Array(4).keys()].map(el => (
              <tr key={el}>
                <Cell isShort>
                  <PlaceholderCol style={{ width: 34 }} />
                </Cell>
                <Cell>
                  <PlaceholderCol style={{ width: getCellWidth(el) }} />
                </Cell>
                <Cell>
                  <PlaceholderCol style={{ width: getCellWidth(el) }} />
                </Cell>
                <Cell>
                  <PlaceholderCol style={{ width: getCellWidth(el) }} />
                </Cell>
                <Cell>
                  <PlaceholderCol style={{ width: getCellWidth(el) }} />
                </Cell>
              </tr>
            ))}
          </tbody>
        </Table>
      </PreloaderBody>
    </div>
  );
};
