// @flow
import { observer } from "mobx-react";
import React from "react";
import { ProfileLayout } from "../layouts/ProfileLayout";
import { TicketPage } from "./TicketPage";
import { TICKET_SEGMENTS } from "../../stores/ticketStore";

export const ProfileTicketPage = observer(() => {
  return (
    <ProfileLayout activeIndexMenu={3}>
      <TicketPage isNeedAdditionalFilters={false} segment={TICKET_SEGMENTS.PROFILE.name} enableAdaptiveSetting={false} />
    </ProfileLayout>
  );
});
