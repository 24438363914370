// @flow
import * as React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import o, { type ExtractOverridesProps } from "react-overrides";
import { BlockLoader } from "../loader/Loader";

const ContentContainer = styled.div`
  padding: 15px 100px 100px;

  max-width: 1224px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    padding: 40px 40px 80px;
  }
  @media screen and (max-width: 480px) {
    padding: 15px 20px 50px;
  }
`;

const MetaInfoContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }
  @media (max-width: 768px) {
    margin-top: 15px;
  }
  @media (max-width: 480px) {
    margin-top: 5px;
  }
`;

const SectionsContainer = styled.div`
  margin-top: 25px;
`;

const BottomFormContainer = styled.div`
  background: ${colors.white};
  padding: 0 0 45px;
  width: 100%;
  box-sizing: border-box;
`;

const Overrides = {
  ContentContainer,
  MetaInfoContainer,
  SectionsContainer
};

export const AppointmentFormLayout = (props: {
  title: React.Node,
  metaInfo: React.Node,
  toolButtons: React.Node,
  sections: React.Node,
  analyzes?: React.Node,
  onlineSection?: React.Node,
  notNeedAddMinHeight?: boolean,
  stimulationProtocol?: React.Node,
  overrides?: ExtractOverridesProps<typeof Overrides>,
  buttonForm?: React.Node
}) => {
  return (
    <form onSubmit={e => e.preventDefault()}>
      <ContentContainer {...o} style={props.notNeedAddMinHeight ? {} : {minHeight: 'calc(100vh - 300px)'}}>
        {
          props.sections ? (
            <>
              {props.title}
              <MetaInfoContainer {...o}>
                {props.metaInfo}
                {props.toolButtons}
              </MetaInfoContainer>
              {props.onlineSection}
              {props.stimulationProtocol}
              <SectionsContainer {...o}>
                {props.sections}
                {props.analyzes}
              </SectionsContainer>
            </>
          ) : (
            <BlockLoader />
          )
        }
      </ContentContainer>
      {props.buttonForm && <BottomFormContainer>{props.buttonForm}</BottomFormContainer>}
    </form>
  );
};
