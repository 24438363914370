import * as React from "react";

import { PreloaderBody } from "../../prealoader/PreloaderBody";

import styled from "styled-components";

export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 607px;
`;

export const Column = styled.th`
  border: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid #c6007f;
  box-sizing: border-box;
  padding: 0 16px;
  height: 44px;
  vertical-align: middle;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export const Cell = styled.td`
  border-bottom: 1px solid rgb(68, 68, 68, 0.1);
  border-right: 1px solid rgb(68, 68, 68, 0.1);
  padding: 0 16px;
  height: 44px;
  vertical-align: middle;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export const Placeholder = styled.div`
  height: 24px;
  border-radius: 3px;
  background: #d4d4d4;
  opacity: 0.5;
`;

export const EmbryosManipulationsTablePlaceholder = ({ rows }) => {
  return (
    <div>
      <PreloaderBody>
        <Table>
          <thead>
            <tr>
              <Column>
                <Placeholder />
              </Column>
              <Column>
                <Placeholder />
              </Column>
              <Column>
                <Placeholder />
              </Column>
            </tr>
          </thead>
          <tbody>
            {[...new Array(rows).keys()].map(id => (
              <tr key={id}>
                <Cell>
                  <Placeholder />
                </Cell>
                <Cell>
                  <Placeholder />
                </Cell>
                <Cell>
                  <Placeholder />
                </Cell>
              </tr>
            ))}
          </tbody>
        </Table>
      </PreloaderBody>
    </div>
  );
};
