import { createEvent, createStore } from "effector";

export const protocols = {
  low: {
    title: "Риск бедного ответа",
    caption:
      "Пациентка относится к группе бедного ответа. Протокол стимуляции выбирается в зависимости от количества фолликулов в яичниках."
  },
  normal: {
    title: "Ожидается нормальный ответ",
    caption:
      "Пациентка относится к группе нормального ответа. Рекомендуется протокол с антагонистами, короткий или длинный протокол."
  },
  high: {
    title: "Риск избыточного ответа",
    caption:
      "Пациентка относится к группе избыточного ответа и синдрома гиперстимуляции яичников. Рекомендуется протокол с антагонистами или применение непрямых индукторов овуляции."
  },
  default: {
    title: "Протокол стимуляции",
    caption:
      "Для получения информации по оптимальному протоколу стимуляции введите АМГ и КАФ в протокол «консультация репродуктолога»."
  }
};

export const setStimulationProtocol = createEvent();

export const resetStimulationProtocol = createEvent();

export const stimulationProtocol = createStore(null);

stimulationProtocol.on(setStimulationProtocol, (state, protocol) => ({
  ...protocols[protocol.response_type],
  type: protocol.response_type,
  description: protocol.file_template
}));

stimulationProtocol.on(resetStimulationProtocol, () => null);
