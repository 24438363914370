// @flow
import React from "react";
import { useForm } from "react-hook-form";
import { Grid, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { observer } from "mobx-react";
import { ProfileLayout } from "../layouts/ProfileLayout";
import authStore from "../../stores/authStore";
import { FormInputText } from "../common/dynamic-form/fields/FormInputText";
import { FEATURE, useApplicationFeatureValue } from "../../utils/applicationFeatures";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    padding-bottom: 70px;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;


export const ProfilePage = observer(() => {

  const { user } = authStore;
  const IVB__DOCTOR__ID = useApplicationFeatureValue(FEATURE.IVB__DOCTOR__ID)
  const PEDIATRICIAN__DOCTOR__ID = useApplicationFeatureValue(FEATURE.PEDIATRICIAN__DOCTOR__ID)

  const methods = useForm({
    defaultValues: {
      first_name: user.doctor?.first_name || '',
      middle_name: user.doctor?.middle_name || '',
      last_name: user.doctor?.last_name || '',
    },
  });
  const { handleSubmit, control } = methods;

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('first_name', data.first_name);
      formData.append('middle_name', data.middle_name);
      formData.append('last_name', data.last_name);
      await authStore.editUser(formData);
    } catch(e) {
      console.error(e);
    }
  };

  const checkUserIdWithFeature = parseInt(user.id) === parseInt(IVB__DOCTOR__ID)
    || parseInt(user.id) === parseInt(PEDIATRICIAN__DOCTOR__ID);

  return (
    <ProfileLayout activeIndexMenu={0}>
      <Container>
        <Paper style={{
          padding: '20px',
          display: "flex",
          gap: "20px",
          flexDirection: "column"
        }}>
          <Typography fontSize={20}>
            Профиль
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid alignItems={"center"} container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormInputText readOnly={!checkUserIdWithFeature} label="Фамилия" name="last_name" control={control} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInputText readOnly={!checkUserIdWithFeature} label="Имя" name="first_name" control={control} />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInputText readOnly={!checkUserIdWithFeature} label="Отчество" name="middle_name" control={control} />
              </Grid>
              {checkUserIdWithFeature && (
                <Grid item xs={12}>
                  <Button type={"submit"} size={"large"} variant="contained" color={"success"}>
                    Сохранить
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Paper>
      </Container>
    </ProfileLayout>
  );
});
