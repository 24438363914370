// @flow
import React from "react";
import { HeadingH4 } from "../styleguide/typography";
import { AppointmentFeedbackLayout } from "./AppointmentFeedbackLayout";
import { SortSelect } from "./SortSelect";
import { AppointmentHistoryTable } from "../appointment-history/AppointmentHistoryTable";
import { AppointmentHistoryTableStub } from "../appointment-history/AppointmentHistoryTableStub";
import { AppointmentFeedbackRow } from "./AppointmentFeedbackRow";
import { useStore } from "effector-react";
import * as R from "ramda";
import moment from "moment";
import { READABLE_DATE_FORMAT } from "../../domain/services/field";
import type { TFeedback } from "../../domain/entities/Feedback";
import { getPatientFullName } from "../../domain/services/patient";
import { openFeedbackForView } from "../../stores/feedbacks/feedbackSections";
import InfiniteScroll from "react-infinite-scroller";
import { dataStore, loadMore, loadMoreStore, orderStore, setOrder } from "./feedbacksStore";

const columns = {
  date: { name: "Дата" },
  patientWithTemplate: { name: "Пациент и шаблон" },
  rate: { name: "Оценка лечения" },
  isCallNeeded: { name: "Нужен звонок" }
};

const transformFeedbacks = R.map((item: TFeedback) => {
  const date = moment((item.created_at: any)).format(READABLE_DATE_FORMAT);
  return {
    ...item,
    isCallNeeded: item.response && item.response.needs_callback,
    rate: (item.response && item.response.rating && `${item.response.rating}`) || "",
    date,
    patientWithTemplate: item.patient && {
      patient: getPatientFullName(item.patient),
      template: item.layout.name
    },
    link: `/appointment/${item.appointment_id}`,
    onClickCell: () => {
      setTimeout(() => {
        openFeedbackForView((item.id: any));
      });
    }
  };
});

export const AppointmentFeedback = () => {
  const sort = useStore(orderStore);
  const title = <HeadingH4>Обратная связь</HeadingH4>;

  const _feedbackItems = useStore(dataStore);
  const feedbackItems = transformFeedbacks(_feedbackItems || []);
  const hasMore = useStore(loadMoreStore);

  const sortElement = (
    <SortSelect
      value={(sort: any)}
      onClickItem={(setOrder: any)}
      items={[
        {
          id: "priority",
          title: "важности"
        },
        {
          id: "date",
          title: "дате"
        }
      ]}
    />
  );

  const table = (feedbackItems && feedbackItems.length > 0 && (
    <AppointmentHistoryTable columns={columns}>
      {feedbackItems.map((feedbackItem, id) => (
        <AppointmentFeedbackRow {...feedbackItem} key={id} />
      ))}
    </AppointmentHistoryTable>
  )) || <AppointmentHistoryTableStub />;

  const tableWithScroll = (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => setTimeout(loadMore, 100)}
      hasMore={hasMore}
      loader={<div />}>
      {table}
    </InfiniteScroll>
  );

  return <AppointmentFeedbackLayout title={title} sort={sortElement} table={tableWithScroll} />;
};
