// @flow
import React from "react";
import { Stack, Unstable_Grid2 as Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled(Stack)(
  ({ theme }) => `
    width: 290px;
    ${theme.breakpoints.down("lg")} {
      width: 200px;
    }
    ${theme.breakpoints.down("md")} {
      width: auto;
    }
    transition: ${theme.transitions.create("min-width", {
      duration: theme.transitions.duration.shortest
    })};
  `
);

export const CorporateSideBarLayout = ({ children }) => {
  return (
    <Grid>
      <Container spacing={{ xs: 1, sm: 2, lg: 3 }}>{children}</Container>
    </Grid>
  );
};
