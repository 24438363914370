// @flow
import * as React from "react";
import styled from "styled-components";
import copyIcon from "./icons/copy.svg";
import { DefaultButton } from "../buttons/DefaultButton";
import { colors } from "../styleguide/colors";
import { ButtonWithTooltip } from "../buttons/ButtonWithTooltip";

const Icon = styled.div`
  width: 24px;
  height: 24px;

  mask: url(${copyIcon});
  background: ${colors.magenta};
`;

const IconContainer = styled.div`
  margin-right: 6px;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
`;

export const CopyButton = (props: {
  disabled?: boolean,
  tooltip?: string | React$Node,
  onClick?: () => mixed
}) => (
  <ButtonContainer>
    {props.disabled ? (
      // $FlowFixMe
      <ButtonWithTooltip
        {...props}
        overrides={{
          Button: {
            component: DefaultButton
          },
          Icon: {
            component: Icon
          },
          IconContainer: {
            component: IconContainer
          }
        }}>
        Дублировать
      </ButtonWithTooltip>
    ) : (
      <DefaultButton
        {...props}
        overrides={{
          Icon: {
            component: Icon
          },
          IconContainer: {
            component: IconContainer
          }
        }}>
        Дублировать
      </DefaultButton>
    )}
  </ButtonContainer>
);
