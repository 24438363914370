import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Payment {
	apiService;
	constructor() {
		this.apiService = new ApiService('/api/concierge', null, null, API_URL);
	}

	getAnalyticsMetric(filters) {
		return this.apiService.post(`analytics/metric`, {}, filters);
	}
}

export default new Payment();
