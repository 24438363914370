// @flow
import React, { useState } from "react";
import type { AISlice } from "../../domain/entities/AISlice";
import styled from "styled-components";
import goodQualityIcon from "./icons/good_quality.svg";
import middleQualityIcon from "./icons/middle_quality.svg";
import unavailableIcon from "./icons/unavailable.svg";
import {
  AI_STATUS_POOR_QUALITY,
  AI_STATUS_SUFFICIENT_QUALITY,
  type AIStatus
} from "../../domain/entities/AISlice";
import { PSmall } from "../styleguide/typography";
import { Tooltip } from "../buttons/ButtonWithTooltip";
import { useDebouncedCallback } from "use-debounce";

const IconContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${(props: { status: AIStatus }) =>
    props.status === AI_STATUS_SUFFICIENT_QUALITY
      ? goodQualityIcon
      : props.status === AI_STATUS_POOR_QUALITY
      ? middleQualityIcon
      : unavailableIcon});
`;

const TitleContainer = styled.div`
  margin-left: 24px;
`;

export const AISliceListItem = ({ item }: { item: AISlice }) => {
  const [tooltipShown, setTooltipShown] = useState(false);

  const [setTooltipShownDebounced, cancelSettingTooltipShown] = useDebouncedCallback(
    value => setTooltipShown(value),
    700
  );

  const handleMouseLeave = () => {
    cancelSettingTooltipShown();
    setTooltipShown(false);
  };

  return (
    <Container>
      <IconContainer
        onMouseEnter={() => setTooltipShownDebounced(true)}
        onMouseLeave={handleMouseLeave}>
        <Icon status={item.status} />
        {tooltipShown && (
          <Tooltip>
            {item.status === AI_STATUS_SUFFICIENT_QUALITY
              ? "Хорошее качество"
              : item.status === AI_STATUS_POOR_QUALITY
              ? "Недостаточное качество"
              : "Остутствует"}
          </Tooltip>
        )}
      </IconContainer>

      <TitleContainer>
        <PSmall modifiers="1">{item.name}</PSmall>
      </TitleContainer>
    </Container>
  );
};
