import React from "react";
import styled from "styled-components";
import { FormSection } from "./FormSection";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";

export const Container = styled(RoundedRectangleWithShadow)`
  padding: 0 32px;
  @media (max-width: 768px) {
    padding: 25px 30px 0 30px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px 0 15px;
  }
`;

export const HeaderContainer = styled.div`
  border: none;
  background: none;
  outline: none;
  width: 100%;
  height: 84px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 20px 0;
  border: none;
  cursor: auto;
  @media (max-width: 768px) {
    height: auto;
    padding: 0;
  }
`;

export const ChildrenContainer = styled.div`
  padding-bottom: 30px;
`;

export const AnalyzesFormSection = props => {
  return (
    <FormSection
      {...props}
      expand_by_default
      overrides={{
        ArrowButton: {
          component: () => null
        },
        Container: {
          component: Container
        },
        HeaderContainer: {
          component: HeaderContainer,
          props: {
            onClick: null,
            as: "div",
            "aria-label": null
          }
        },
        ChildrenContainer: {
          component: ChildrenContainer
        }
      }}
    />
  );
};
