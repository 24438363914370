import React from "react";
import styled from "styled-components";
import {FEATURE, featureEnabledForOrganization} from "../../utils/applicationFeatures";
import {teethIDsToString, teethValueToArray} from '../teeth-picker/TeethPicker';

const Icon = styled.div`
  width: 24px;
  font-size: 24px;
  text-align: center;
  opacity: 0.3;
  flex-shrink: 0;
`;

const PrintableServiceItem = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 23px;
`;

const PrintableServiceItemText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrintableServicesComment = styled.div`
  font-size: 16px;
  line-height: 23px;
  margin-top: 17px;
`;

export const PrintableServices = ({ services, organizationId, displayTeeth, showServicesId }) => {
    const totalPrice = services.reduce((acc, item) => acc + (item.name.startsWith('ОМС ') ? 0 : (item.price * item.count)), 0);
    const withPrice = featureEnabledForOrganization(FEATURE.PRICE_IN_PRINT, organizationId);
    return <>
        {
            services.map(({name, id, kdf_code, count, price, comment, teeth}) => {
                let serviceText = name;
                if (displayTeeth && teeth) {
                    serviceText += ` - Зубы: ${teethIDsToString(teethValueToArray(teeth), true)}`;
                }
                if (count && count > 1) {
                    serviceText += ` - ${count} шт`;
                }
                if (withPrice && !name.startsWith('ОМС ')) {
                    serviceText += ` - ${price ? `${price}₽` : ''}`;
                }
                return <PrintableServiceItem key={id}>
                    <Icon>•</Icon>
                    <PrintableServiceItemText>
                        { (showServicesId && kdf_code?.trim()) ? (
                          <span>
                              {kdf_code.trim()} - {serviceText}
                          </span>
                        ) : (
                          serviceText
                        )}
                    </PrintableServiceItemText>
                </PrintableServiceItem>
            })
        }
        {!!totalPrice && withPrice &&
            <PrintableServiceItem style={{marginTop: 8}}>
                Итого: {totalPrice}₽
            </PrintableServiceItem>
        }
    </>
}
