// @flow
import React, { createContext, useRef } from "react";
import styled from "styled-components";

export const ScheduleDaysContext = createContext();

const ScheduleDaysContainer = styled.div`
  @media (max-width: 768px) {
    height: 100%;
    overflow-y: auto;
  }
`;

const DayContainer = styled.div`
  & + & {
    margin-top: 35px;
    @media (max-width: 768px) {
      margin-top: 30px;
    }
    @media (max-width: 480px) {
      margin-top: 20px;
    }
  }
`;

export const ScheduleDays = (props: { children: React.Node }) => {
  const scheduleContainer = useRef(null);

  return (
    <ScheduleDaysContext.Provider value={{ scheduleContainer }}>
      <ScheduleDaysContainer ref={scheduleContainer}>
        {React.Children.map(props.children, (child, id) => {
          return !child
            ? null
            : <DayContainer key={id}>{child}</DayContainer>;
        })}
      </ScheduleDaysContainer>
    </ScheduleDaysContext.Provider>
  );
};
