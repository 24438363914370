import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useStore } from "effector-react";
import { Box, LinearProgress, Button, Avatar as MuiAvatar } from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

import { CorporateCard } from "./CorporateCard";
import { CorporateTitle } from "../styled/CorporateTypography.styled";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import corporateNewsStore from "../stores/corporateNewsStore";
import { CorporateComment } from "./CorporateComment";
import { currentUser } from "../../stores/auth";
import { breakpoints } from "../styled/common";
import { shortName } from "../utils/shortName";
import { scrollbars } from "../../components/chats/style-elements";

const ProgressBar = styled(Box)(
  ({ theme }) => `
    position: relative;
    height: 65px;
    overflow: hidden;
    .MuiLinearProgress-root {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    ${theme.breakpoints.down("xl")} {
      height: 60px;
    }
    ${theme.breakpoints.down("lg")} {
      height: 55px;
    }
  `
);

const CardActionsContainer = styled(Box)(
  () =>
    `
      display: flex;
      align-items: center;
      gap: 12px;      
      
      @media screen and (max-width: 580px) {
        .MuiAvatar-root {
          display: none;
        }
      }
      
      @media screen and (max-width: 580px) {
        flex-direction: column;
       
        .MuiButtonBase-root {
          width: 100%;
        }
      }
    `
);

const CommentList = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  max-height: 480px;
  overflow-y: auto;
  margin-block: 24px;
  ${scrollbars};
`;

const Avatar = styled(MuiAvatar)`
  width: 36px;
  height: 36px;
  font-size: 0.9rem;
  text-transform: uppercase;
  ${breakpoints.desktop} {
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
  }
  ${breakpoints.tablet} {
    width: 25px;
    height: 25px;
    font-size: 0.7rem;
  }
`;

export const CorporateComments = observer(props => {
  const user = useStore(currentUser);
  const commentsBlockRef = useRef(null);
  const [isCommentSent, setIsCommentSent] = useState(true);

  useEffect(() => {
    commentsBlockRef?.current?.scrollTo({
      top: commentsBlockRef.current.scrollHeight,
      behavior: "smooth"
    });
  }, [corporateNewsStore.newsComments.length]);

  const handleChangeCommentValue = event => {
    corporateNewsStore.changeCommentValue(event.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      handlePostComment();
    }
  };

  const handlePostComment = async () => {
    setIsCommentSent(false);
    await corporateNewsStore.postComment(props.newsId);
    setIsCommentSent(true);
  };

  return (
    <CorporateWrapper>
      <CorporateCard>
        <CorporateTitle>Комментарии</CorporateTitle>
        <CommentList ref={commentsBlockRef}>
          {corporateNewsStore.isCommentsLoading ? (
            <ProgressBar>
              <LinearProgress />
            </ProgressBar>
          ) : corporateNewsStore.newsComments.length > 0 ? (
            corporateNewsStore.newsComments.map(comment => (
              <CorporateComment key={comment.id} {...comment} />
            ))
          ) : (
            "Нет комментариев для новости"
          )}
        </CommentList>
        <CardActionsContainer>
          <Avatar src={user?.doctor?.photo}>
            {shortName({ lastName: user?.doctor?.last_name, firstName: user?.doctor?.first_name })}
          </Avatar>
          <TextField
            fullWidth
            size="small"
            margin="dense"
            placeholder="Написать комментарий..."
            multiline
            maxRows={4}
            value={corporateNewsStore.commentValue}
            onChange={handleChangeCommentValue}
            onKeyDown={handleKeyPress}
          />
          <Button variant="contained" onClick={handlePostComment} disabled={!isCommentSent}>
            Отправить
          </Button>
        </CardActionsContainer>
      </CorporateCard>
    </CorporateWrapper>
  );
});
