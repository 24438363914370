// @flow
import * as React from "react";

import { PNormal, PSmall } from "../styleguide/typography";
import TextareaAutosize from "react-autosize-textarea";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";

const Textarea = PNormal;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  font-family: inherit;
  background: inherit;
  width: 100%;
  border: none;
  resize: none;
  padding: 0;
`;

const InlineContainer = styled(PSmall)`
  display: inline;
  white-space: pre-wrap;
`;

export const TextareaView = (props: {
  children: React$Node,
  overrides?: ExtractOverridesProps<{ Textarea: typeof Textarea }>,
  inline?: boolean
}) =>
  props.inline ? (
    <InlineContainer as="span">{props.children}</InlineContainer>
  ) : (
    <Textarea
      {...o}
      as={StyledTextareaAutosize}
      modifiers={"left"}
      disabled
      value={props.children}
    />
  );
