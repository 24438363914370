import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { ProfileLayout } from "../layouts/ProfileLayout";
import { BlockLoader } from "../loader/Loader";
import styled from "styled-components";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import noticesStore, { NOTICE_TYPES, NOTICE_TYPES_RU } from "../../stores/noticesStore";
import { trimLongTextValueField } from "../../utils/tableUtils";
import moment from "moment";
import { READABLE_DATE_FORMAT, READABLE_TIME_FORMAT_MOMENT } from "../../utils/dates";

const Container = styled.div`
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  display: block;
  position: relative;
  padding: 40px 15px 70px;
  height: ${props => props?.height || "100%"};
  .MuiTableContainer-root {
    ${scrollbars}
  }
`;

export const NoticesPage = observer(() => {
  const tableRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const [tableData, setTableData] = useState([]);

  const columns = useMemo(() => {
    if (!tableData.length) {
      return [];
    }

    return [
      {
        accessorKey: "title",
        header: "Заголовок",
        size: 300
      },
      {
        accessorKey: "description",
        header: "Описание",
        size: 400,
        Cell: ({ row }) => trimLongTextValueField(row.original.description)
      },
      {
        accessorKey: "is_read",
        header: "Статус",
        size: 300,
        filterVariant: "select",
        filterSelectOptions: [
          {
            value: true,
            label: "Прочитано"
          },
          {
            value: false,
            label: "Не прочитано"
          }
        ],
        Cell: ({ row }) => (row.original.is_read ? "Прочитано" : "Не прочитано")
      },
      {
        accessorKey: "type",
        header: "Тип",
        size: 300,
        filterVariant: "select",
        filterSelectOptions: Object.values(NOTICE_TYPES).map(type => {
          return {
            value: type,
            label: NOTICE_TYPES_RU[type]
          };
        }),
        Cell: ({ row }) => NOTICE_TYPES_RU[row.original.type]
      },
      {
        accessorKey: "date",
        accessorFn: row => moment(row.created_at).format(READABLE_DATE_FORMAT),
        header: "Дата",
        size: 300,
        enableColumnFilter: false,
      },
      {
        accessorKey: "time",
        accessorFn: row => moment(row.created_at).format(READABLE_TIME_FORMAT_MOMENT),
        filterFn: "contains",
        header: "Время",
        size: 300,
        enableColumnFilter: false,
      }
    ];
  }, [tableData]);

  useEffect(() => {
    if (!noticesStore.notices.length) {
      setIsInitialized(false);

      (async () => {
        await noticesStore.fetchAllNotices();
      })();
    }
  }, []);

  useEffect(() => {
    setTableData(noticesStore.notices);
    setIsInitialized(true);
  }, [noticesStore.notices]);

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableGlobalFilter: false,
    // enableRowActions: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 85
      }
    },
    layoutMode: "grid",
    initialState: {
      density: "compact"
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
      }
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%"
      }
    },
    // renderRowActions: ({ row }) => {
    //   const menuItems = [
    //     {
    //       name: "Прочитать уведомление",
    //       handleOnClick: () => noticesStore.readNotice(row.original.id)
    //     }
    //   ];
    //
    //   return <DropDownMenu menuItems={menuItems} view="item" variant="horizontal" />;
    // },
    localization: {
      ...MRT_Localization_RU,
      noRecordsToDisplay: "Уведомления не найдены"
    },
    isMultiSortEvent: () => true
  });

  const handleChangeTableHeightOnResize = () => {
    setTableHeight(window.innerHeight - tableRef?.current?.getBoundingClientRect().top);
  };

  useEffect(() => {
    window.addEventListener("resize", handleChangeTableHeightOnResize);
    return () => window.removeEventListener("resize", handleChangeTableHeightOnResize);
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    handleChangeTableHeightOnResize();
  }, [tableData]);

  return (
    <ProfileLayout activeIndexMenu={6}>
      {!isInitialized ? (
        <BlockLoader transparent />
      ) : (
        <Container ref={tableRef} height={`${tableHeight}px`}>
          <MaterialReactTable table={table} />
        </Container>
      )}
    </ProfileLayout>
  );
});
