// @flow
import React, { useEffect, useMemo } from "react";

import useReactRouter from "use-react-router";
import { DynamicForm, backToHistory } from "../common/dynamic-form/DynamicForm";
import { observer } from "mobx-react";
import { Accordion, AccordionSummary, Paper } from "@mui/material";
import { ContainerComponent } from "../common/dynamic-form/styled/Container.styled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import surveyFeedbackClinicResultStore from "../../stores/surveyFeedbackClinicResultStore";


const SurveyResultTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const SurveyResultBlock = styled.div`
  margin-top: 30px;
`;

const SurveyValueTextValue = styled.div`
    font-size: 14px;
    line-height: 18px;
`;

const SurveyAdditionalInfo = styled.div`
    margin: 20px 0;
`;

const SurveyValueText = styled.div`
    font-size: 14px;
    line-height: 18px;
`;

const SurveyValueTextBold = styled(SurveyValueText)`
    font-weight: 500;
`;

const SurveyValueBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 10px;
    
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.gray700};
    
  &:last-child {
      margin-bottom: 0;
      border-bottom: none;
  }
`;


export const SurveyFeedbackClinicEditPage = observer(() => {
  const {
    history,
    match: { params }
  } = useReactRouter();


  const editSchema = [
    {
      type: 'text',
      name: 'id',
      label: 'Идентификатор',
      disabled: true,
      hiddenField: true
    },
    {
      type: 'select',
      name: 'status',
      label: 'Статус',
      options: surveyFeedbackClinicResultStore.statusResult
    },
  ]

  const fetchData = async () => {
    await surveyFeedbackClinicResultStore.getResultById();
  }

  useEffect(() => {
    (async () => {
      surveyFeedbackClinicResultStore.setSurveyResultId(params.id);
      fetchData();
    })();
  }, []);

  const data = useMemo(() => {
    if (surveyFeedbackClinicResultStore.surveyResultData) {
      return {
        status: surveyFeedbackClinicResultStore.surveyResultData.status,
        id: surveyFeedbackClinicResultStore.surveyResultData.id,
      };
    }
    return null;

  }, [surveyFeedbackClinicResultStore.surveyResultData]);

  const valuesSteps = useMemo(() => {
    if (surveyFeedbackClinicResultStore.surveyResultData) {
      return surveyFeedbackClinicResultStore.surveyResultData.survey.steps.reduce((acc, step) => {
        const filterFields = step.fields.filter((field) => {
          const value = surveyFeedbackClinicResultStore.surveyResultData.values.find((item) => item.field === field.id)?.value;
          return value ? !!JSON.parse(value) : false;
        });
        if (filterFields.length) {
          acc.push({
            id: step.id,
            name: step.name,
            fields: filterFields.map((field) => {
                const value = surveyFeedbackClinicResultStore.surveyResultData.values.find((item) => item.field === field.id)?.value;
                return {
                  name: field.name,
                  value: JSON.parse(value)
                }
              })
          })
        }
        return acc;
      }, [])

    }
    return null;

  }, [surveyFeedbackClinicResultStore.surveyResultData]);

  const submitHandler = async (data) => {
    await surveyFeedbackClinicResultStore.postSurveyResult(data);
    history.push('/review-statistics/');
  }

  return (
    <ContainerComponent>
      {data && (
        <Paper
          style={{
            padding: '25px',
            maxWidth: '1440px',
            margin: '20px auto'
          }}
        >
          {data && (
            <DynamicForm
              callbackOnClose={() => history.push('/review-statistics/')}
              searchHistory={surveyFeedbackClinicResultStore.generateSearch.bind(surveyFeedbackClinicResultStore)}
              data={data} submitHandler={submitHandler}
              fields={editSchema}
              title="Просмотр результатов анкеты" />
          )}

          <SurveyResultBlock>
            <SurveyResultTitle>
              {surveyFeedbackClinicResultStore.surveyResultData.survey.name}
            </SurveyResultTitle>
            {valuesSteps.map((step) => (
              <>
                {step.fields.map((field) => (
                  <SurveyValueBlock key={field.name}>
                    <SurveyValueText>
                      <SurveyValueTextBold>Вопрос:</SurveyValueTextBold> {field.name}:
                    </SurveyValueText>
                    <SurveyValueTextValue>
                      <SurveyValueTextBold>Ответ:</SurveyValueTextBold> {field.value}
                    </SurveyValueTextValue>
                  </SurveyValueBlock>
                ))}
              </>
            ))}
          </SurveyResultBlock>
        </Paper>
      )}
    </ContainerComponent>
  );
});
