import { createEvent, createStore } from "effector";

import { appointmentFieldValuesSet } from "./appointmentFieldValues";

export const appointmentLiveBirthProbability = createStore({});

export const setAppointmentLiveBrithProbability = createEvent("setAppointmentLiveBrithProbability");

appointmentLiveBirthProbability.on(
  appointmentFieldValuesSet,
  (state, { eko_chance, eko_do_chance, vmi_chance }) => ({
    ...state,
    eko_chance,
    eko_do_chance,
    vmi_chance
  })
);

appointmentLiveBirthProbability.on(setAppointmentLiveBrithProbability, (state, values) => ({
  ...state,
  ...values
}));
