import React from "react";
import { CorporateControlPanelWrapper } from "../styled/CorporateWrapper.styled";
import {
  Box,
  LinearProgress,
  Unstable_Grid2 as Grid,
  Breadcrumbs as MuiBreadcrumbs
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CorporateResponsiveTabs } from "./CorporateResponsiveTabs";
import { CorporateSearchInput } from "./CorporateSearchInput";
import { CorporateBackButton } from "./CorporateBackButton";

export const CONTROL_PANEL_VIEW = {
  BREADCRUMBS: "breadcrumbs",
  TABS: "tabs"
};

const ContentContainer = styled(Box)`
  width: 100%;
  height: 100%;
`;

const ProgressBar = styled(Box)(
  ({ theme }) => `
    position: relative;
    height: 65px;
    overflow: hidden;
    .MuiLinearProgress-root {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    ${theme.breakpoints.down("xl")} {
      height: 60px;
    }
    ${theme.breakpoints.down("lg")} {
      height: 55px;
    }
  `
);

const BreadcrumbsContainer = styled(Box)(
  ({ theme }) => `
  min-height: 65px;
  display: flex;
  align-items: center; 
  ${theme.breakpoints.down("xl")} {
    min-height: 60px;
  }
  ${theme.breakpoints.down("lg")} {
    min-height: 55px;
  }
  `
);

const Breadcrumb = styled(Box)(
  ({ theme, active }) => `
    color: ${active === "true" ? theme.palette.text.pink : theme.palette.text.black};
    cursor: ${active === "true" ? "auto" : "pointer"};
  `
);

const ContentComponent = ({ view = CONTROL_PANEL_VIEW.TABS, ...props }) => {
  if (view === CONTROL_PANEL_VIEW.BREADCRUMBS) {
    return <Breadcrumbs {...props.breadcrumbsParams} />;
  }
  if (view === CONTROL_PANEL_VIEW.TABS) {
    return <CorporateResponsiveTabs {...props.tabsParams} />;
  }
  return null;
};

const Breadcrumbs = props => {
  return !props.items.length ? null : (
    <BreadcrumbsContainer>
      <MuiBreadcrumbs separator="›">
        {props.items.map((item, index) => (
          <Breadcrumb
            key={`${item.name}_index`}
            active={`${index === props.items.length - 1}`}
            onClick={() => props.handleBack(item.id)}>
            {item.name}
          </Breadcrumb>
        ))}
      </MuiBreadcrumbs>
    </BreadcrumbsContainer>
  );
};

export const CorporateControlPanel = ({
  isLoading = false,
  backButton = null,
  searchField = null,
  ...props
}) => {
  return (
    <CorporateControlPanelWrapper
      modifiers={[
        !backButton && !backButton?.isShow && !isLoading && "withoutPadding",
        !!backButton && backButton.isShow && "fixedHeight"
      ]}>
      <ContentContainer>
        {!isLoading ? (
          <ProgressBar>
            <LinearProgress />
          </ProgressBar>
        ) : (
          <Grid container spacing={1} flexWrap={{ sm: "nowrap" }} alignItems="center">
            <Grid xs={12}>
              <ContentComponent {...props} />
            </Grid>
            {!!searchField && (
              <Grid xs={12} sm={8}>
                <CorporateSearchInput
                  label={searchField.label}
                  value={searchField.value}
                  setValue={searchField.onChange}
                />
              </Grid>
            )}
          </Grid>
        )}
        {!!backButton && backButton.isShow && (
          <CorporateBackButton withWrapper text={backButton.text} path={backButton.path} />
        )}
      </ContentContainer>
    </CorporateControlPanelWrapper>
  );
};
