import * as React from "react";
import styled from "styled-components";
import { getOffsetRect } from "./utils";

const CellContainer = styled.td`
  border-bottom: 1px solid rgb(68, 68, 68, 0.1);
  border-right: 1px solid rgb(68, 68, 68, 0.1);
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
  padding: 0 12px;
  font-size: 14px;
  // min-width: ${({ isShort, isMinimal }) => (isShort ? "unset" : isMinimal ? "60px" : "140px")};
  // width: ${({ isMinimal }) => isMinimal && "0px"};
  max-width: 300px;
  height: 44px;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export const Cell = ({
  row,
  data,
  mode,
  column,
  select,
  active,
  activate,
  dragging,
  selected,
  DataViewer,
  formulaParser,
  setCellDimensions
}) => {
  const rootRef = React.useRef(null);
  const point = React.useMemo(
    () => ({
      row,
      column
    }),
    [row, column]
  );

  const handleMouseDown = React.useCallback(
    event => {
      if (mode === "view") {
        setCellDimensions(point, getOffsetRect(event.currentTarget));
        if (event.shiftKey) {
          select(point);
        } else {
          activate(point);
        }
      }
    },
    [mode, setCellDimensions, point, select, activate]
  );

  const handleMouseOver = React.useCallback(
    event => {
      if (dragging) {
        setCellDimensions(point, getOffsetRect(event.currentTarget));
        select(point);
      }
    },
    [setCellDimensions, select, dragging, point]
  );

  React.useEffect(() => {
    const root = rootRef.current;
    if (selected && root) {
      setCellDimensions(point, getOffsetRect(root));
    }
    if (root && active && mode === "view") {
      root.focus();
    }
  }, [setCellDimensions, selected, active, mode, point]);

  if (data && data.DataViewer) {
    DataViewer = data.DataViewer;
  }

  const isShort = data?.isShort || false;
  const isMinimal = data?.isMinimal || false;

  return (
    <CellContainer
      ref={rootRef}
      onMouseOver={handleMouseOver}
      onMouseDown={handleMouseDown}
      isShort={isShort}
      isMinimal={isMinimal}
      tabIndex={0}>
      <DataViewer row={row} column={column} cell={data} formulaParser={formulaParser} />
    </CellContainer>
  );
};
