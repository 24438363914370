import React, { useCallback, useRef, useEffect } from "react";

import { useMyPatientsTableInput } from "./useMyPatientsTableInput";

import * as CommonInputStyles from "./MyPatientsTableInput.styled";
import * as S from "./MyPatientsTableInputsText.styled";

export const MyPatientsTableInputsText = ({ type = "text", ...props }) => {
  const { value, onUpdate, cell } = useMyPatientsTableInput(props);

  const $inputRef = useRef();

  const handleInputChange = useCallback(
    e => {
      $inputRef.current.dataset.keyPressUpdate = JSON.stringify({
        id: cell.id,
        index: cell.index,
        fieldId: cell.fieldId,
        fieldType: cell.fieldType,
        appointmentId: cell.appointmentId,
        value: e.currentTarget.value
      });
    },
    [cell]
  );

  const handleInputBlur = useCallback(() => {
    onUpdate($inputRef.current.value);
  }, [onUpdate]);

  useEffect(() => {
    $inputRef.current.focus();
  }, []);

  return (
    <CommonInputStyles.Input>
      <S.Input
        as="input"
        type={type}
        defaultValue={value}
        ref={$inputRef}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        data-key-press-update={JSON.stringify({ id: cell.id, index: cell.index, value })}
      />
    </CommonInputStyles.Input>
  );
};
