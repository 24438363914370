import { makeAutoObservable } from 'mobx';
import apiv2 from "../apiv2";
import {arrayToEntriesById} from "../utils/arrays";
import {FEATURE, featureEnabledForOrganization} from "../utils/applicationFeatures";

const sortByNameWithSearchString = (a, b, search) => {
    search = search.toLowerCase();
    a = a.toLowerCase();
    b = b.toLowerCase();
    if (search) {
        if (a.startsWith(search) && b.startsWith(search)) {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            return 0;
        }

        if (a.startsWith(search)) {
            return -1;
        }

        if (b.startsWith(search)) {
            return 1;
        }
    }

    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    return 0;
}

const categoriesRequest = {};
const servicesRequest = {};

const _calcMissedExamination = (examinations, prescribedServices) => {
    const result = []
    if (!examinations) {
        return result;
    }
    examinations.forEach((checklistItem) => {
        if (checklistItem.items) {
            result.push(..._calcMissedExamination(checklistItem.items, prescribedServices));
        } else if (checklistItem.is_checked) {
            if (checklistItem.services && checklistItem.services.find(s => prescribedServices.find(ps => ps.id === s.id))) {
                return;
            }
            result.push(checklistItem.name);
        }
    });
    return result;
}

class ServiceStore {
    categories = {};
    services = {};
    organizationId = 0;
    appointmentId = null;
    examinations = [];
    prescribedServices = [];
    appointmentServices = [];
    createdAppointmentWithCopy = false;

    constructor() {
        makeAutoObservable(this);
    }

    setAppointmentId(appointmentId) {
        this.appointmentId = appointmentId;
    }

    setOrganizationId(organizationId) {
        if (this.organizationId === organizationId) {
            return;
        }
        this.organizationId = organizationId;
        this.getServiceCategories(this.organizationId);
    }

    *getServiceCategories(organizationId) {
        if (!categoriesRequest[organizationId]) {
            categoriesRequest[organizationId] = apiv2.services.getServiceCategories(organizationId);
            servicesRequest[organizationId] = apiv2.services.getOrganizationServices(organizationId);
        }
        this.categories[organizationId] = yield categoriesRequest[organizationId];
        this.services[organizationId] = yield servicesRequest[organizationId];
    }

    get flatCategories() {
        if (!this.organizationId) {
            return [];
        }
        const calcFlatCategories = (categories) => {
            const result = [];
            categories.forEach((cat) => {
                result.push(cat);
                if (cat.subcategories.length) {
                    result.push(...calcFlatCategories(cat.subcategories));
                }
            })
            return result;
        }

        return calcFlatCategories(this.categories[this.organizationId]);
    }

    get categoriesObject() {
        if (!this.organizationId) {
            return {};
        }
       return arrayToEntriesById(this.flatCategories);
    }

    get consultationsCategories() {
        return this.flatCategories.filter(cat => cat.name.toLowerCase().startsWith('консультации') && cat.category_code !== '10;10/30;10/30/1')
    }

    get consultationsCategoriesIds() {
        const calcFlatCategories = (categories) => {
            const result = [];
            categories.forEach((cat) => {
                result.push(cat);
                if (cat.subcategories.length) {
                    result.push(...calcFlatCategories(cat.subcategories));
                }
            })
            return result;
        }

        return calcFlatCategories(this.consultationsCategories).map(cat => cat.id);
    }

    get serviceForCurrentOrganization() {
        return this.services[this.organizationId];
    }

    get categoriesText() {
        if (!this.organizationId) {
            return () => '';
        }

        // if (!featureEnabledForOrganization(FEATURE.CATEGORIES_IN_SERVICE, this.organizationId)) {
        //     return () => '';
        // }

        if (!this.categories[this.organizationId] || !this.services[this.organizationId]) {
            return () => '';
        }
        return (service) => {
            if (service.ministry_of_health_code.startsWith('B01') && !service.kdf_code.startsWith('Д')) {
                return '';
            }

            // if (name && this.services[this.organizationId].filter(s => s.name === name && s.category_id !== categoryId).length === 0) {
            //     return '';
            // }

            const result = [];
            let categoryId = service.category_id;
            while (this.categoriesObject[categoryId]) {
                result.push(this.categoriesObject[categoryId].name);
                categoryId = this.categoriesObject[categoryId].parent_id;
            }
            if (!service.kdf_code.startsWith('Д')) {
                result.pop();
            }
            return result.length ? result.reverse().join(' | ') : '';
        }
    }

    setCreatedAppointmentWithCopy(value) {
        this.createdAppointmentWithCopy = value;
    }

    setExaminationItems(value) {
        this.examinations = value;
    }

    setPrescribedServices(value) {
        this.prescribedServices = value;
    }

    setAppointmentServices(value) {
        this.appointmentServices = value;
    }

    get missedExamination() {
        return _calcMissedExamination(this.examinations, this.prescribedServices)
    }

    methodSortServices(services, value="") {
        return services.sort((a, b) => {
            const catA = this.categoriesObject[a.category_id];
            const catB = this.categoriesObject[b.category_id];

            // вверху показываем услуги-консультации-врачей
            if (catA && catB && this.consultationsCategoriesIds.includes(catA.id) && this.consultationsCategoriesIds.includes(catB.id)) {
                return sortByNameWithSearchString(a.name, b.name, value);
            }


            if (catA && this.consultationsCategoriesIds.includes(catA.id)) {
                return -1;
            }
            if (catB && this.consultationsCategoriesIds.includes(catB.id)) {
                return 1;
            }

            // вверху показываем услуги-от-ИНВИТРО
            if (catA && catB && catA.category_code.startsWith('24;24/9') && catB.category_code.startsWith('24;24/9')) {
                return sortByNameWithSearchString(a.name, b.name, value);
            }


            if (catA && catA.category_code.startsWith('24;24/9')) {
                return -1;
            }
            if (catB && catB.category_code.startsWith('24;24/9')) {
                return 1;
            }
            return sortByNameWithSearchString(a.name, b.name, value);
        })
    }
}

export default new ServiceStore();
