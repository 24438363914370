// @flow

import _ from "lodash";

export const possibleValuesToButtonGroupItems = (
  possible_values: Array<string>
): { [key: string]: { name: string } } => {
  return _.chain(possible_values)
    .map(item => ({ name: item }))
    .keyBy(item => item.name)
    .value();
};
