// @flow
import moment from "moment";

export const formatFileDateToNumericDateFullYear = (date: string, utc: boolean = true): string => {
  return (utc ? moment.utc(date) : moment(date)).format("DD.MM.YYYY");
};

export const formatFileDateToYearMonth = (date: string): string => {
  return moment(date).format("YYYY-MM");
};

export const formatFileDateToMonthYearDateWords = (date: string): string => {
  return moment(date, "YYYY-MM")
    .locale("ru")
    .format("MMMM, YYYY");
};
