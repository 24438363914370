import React, { useEffect } from "react";
import styled from "styled-components";
import { useToast } from "../toast/useToast";
import { colors } from "../styleguide/colors";
import { useStore } from "effector-react";
import { currentUser } from "../../stores/auth";
import { UPDATE_NEWS_LINK, VERSION } from "../../version";
import { parseMilliseconds } from "../../utils/parseMilliseconds";
import commonStore from "../../stores/commonStore";

const UPDATE_KEY = "ems_update_data";

const saveData = data => {
  localStorage.setItem(UPDATE_KEY, JSON.stringify(data));
};

export const useUpdateMessage = () => {
  const user = useStore(currentUser);

  const showUpdateVersionModal = data => {
    if (user && user.role === "doctor") {
      if (!data.version || data.version !== VERSION) {
        data.version = VERSION;
        data.doctorList = [];
        setTimeout(() => {
          saveData(data);
          commonStore.changeShowUpdateVersionModal(true);
        }, 1000);
      }
      if (!data.doctorList.includes(user.doctor.id)) {
        data.doctorList.push(user.doctor.id);
        setTimeout(() => {
          saveData(data);
          commonStore.changeShowUpdateVersionModal(true);
        }, 1000);
      }
    }
  };

  useEffect(() => {
    const updateLocalData = localStorage.getItem(UPDATE_KEY);

    let parsedData = {
      version: VERSION,
      doctorList: []
    };

    if (updateLocalData) {
      parsedData = JSON.parse(updateLocalData);
    }

    showUpdateVersionModal(parsedData);
  }, [user]);

};
