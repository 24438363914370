import moment from "moment";

import { MyPatientsTableViewerDate } from "./MyPatientsTableViewers/MyPatientsTableViewersDate";
import { MyPatientsTableViewer } from "./MyPatientsTableViewers/MyPatientsTableViewer";
import { MyPatientsTableInputsNumber } from "./MyPatientsTableInputs/MyPatientsTableInputsNumber";
import { MyPatientsTableInputsText } from "./MyPatientsTableInputs/MyPatientsTableInputsText";
import { MyPatientsTableInputsSelect } from "./MyPatientsTableInputs/MyPatientsTableInputsSelect";
import { MyPatientsTableInputsCalendar } from "./MyPatientsTableInputs/MyPatientsTableInputsCalendar";
import { MyPatientsTableViewerSelect } from "./MyPatientsTableViewers/MyPatientsTableViewersSelect";

export const defaultFields = [
  {
    name: "ФИО",
    isDefault: true
  },
  {
    name: "Напомнить",
    isDefault: true
  }
];

export const fieldTypes = {
  name: "name",
  reminder: "reminder",
  text: "text",
  number: "number",
  date: "date",
  select: "select",
  multipleSelect: "multipleSelect"
};

export const eventTypeFieldName = "Тип события";

export const fieldTypesIncomeHandlers = {
  [fieldTypes.date]: value => (value ? new Date(value) : null),
  [fieldTypes.multipleSelect]: value => value || []
};

export const fieldTypesOutcomeHandlers = {
  [fieldTypes.number]: value => (value ? Number(value) : ""),
  [fieldTypes.multipleSelect]: value => value.join(";"),
  [fieldTypes.date]: value => moment(value).format("YYYY-MM-DD")
};

export const fieldTypeByApiType = {
  text: fieldTypes.text,
  textarea: fieldTypes.text,
  number: fieldTypes.number,
  date: fieldTypes.date,
  radiobutton: fieldTypes.select,
  checkbox: fieldTypes.multipleSelect
};

export const fieldInputs = {
  [fieldTypes.text]: MyPatientsTableInputsText,
  [fieldTypes.date]: MyPatientsTableInputsCalendar,
  [fieldTypes.number]: MyPatientsTableInputsNumber,
  [fieldTypes.select]: MyPatientsTableInputsSelect,
  [fieldTypes.multipleSelect]: MyPatientsTableInputsSelect
};

export const fieldViewers = {
  [fieldTypes.text]: MyPatientsTableViewer,
  [fieldTypes.date]: MyPatientsTableViewerDate,
  [fieldTypes.number]: MyPatientsTableViewer,
  [fieldTypes.select]: MyPatientsTableViewerSelect,
  [fieldTypes.multipleSelect]: MyPatientsTableViewerSelect
};
