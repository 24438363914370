// @flow
import * as React from "react";
import styled from "styled-components";

const HeaderContainer = styled.div`
  flex-shrink: 0;
`;

const ContentContainer = styled.div`
  overflow: auto;
  height: 100%;
`;

export const SidebarLayout = (props: { header: React.Node, children?: React.Node }) => (
  <React.Fragment>
    <HeaderContainer>{props.header}</HeaderContainer>
    <ContentContainer>{props.children}</ContentContainer>
  </React.Fragment>
);
