import React from "react";

import * as S from "./styled/SidebarTreeCategory.styled";

export const SidebarTreeCategory = ({ onOpen, title }) => (
  <S.Container onClick={onOpen}>
    {title}
    <S.Arrow />
  </S.Container>
);
