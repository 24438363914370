// @flow
import React from "react";
import styled from "styled-components";
import editIcon from "./icons/edit.svg";
import editHoverIcon from "./icons/edit-hover.svg";
import { CommonHintedButton, Container } from "./CommonHintedButton";

const Icon = styled.div`
  width: 18px;
  height: 18px;
  background: url(${editIcon});
  background-size: cover;

  ${Container}:hover & {
    background: url(${editHoverIcon});
    background-size: cover;
  }
`;

export const EditButton = (props: React$ElementConfig<typeof CommonHintedButton>) => {
  return (
    <CommonHintedButton
      {...props}
      overrides={{
        Icon: {
          component: Icon
        },
        Text: {
          props: {
            children: "Редактировать"
          }
        }
      }}
    />
  );
};
