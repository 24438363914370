import React from "react";
import { Route, Switch } from "react-router-dom";

import { documentsTypes } from "./types";

import { EmbryosProtocol } from "./documents/EmbryosProtocol";
import { CryoTransfer } from "./documents/CryoTransfer";
import { CoverLetter } from "./documents/CoverLetter";

export const PrintableEmbryosDocumentPage = () => (
  <Switch>
    <Route
      path={`/embryos/:folder_id/:medical_file_id/print/documents/${
        documentsTypes.embryosProtocol
      }`}
      component={EmbryosProtocol}
    />
    <Route
      path={`/embryos/:folder_id/:medical_file_id/print/documents/${documentsTypes.cryoTransfer}`}
      component={CryoTransfer}
    />
    <Route
      exact
      path="/embryos/:medical_file_id/print/documents/:document"
      component={CoverLetter}
    />
  </Switch>
);
