import { rgba } from "polished";
import styled from "styled-components";

import { colors } from "../../styleguide/colors";

import { Container as SidebarHeaderContainer } from "../../sidebar/SidebarHeader";

import crossIcon from "../icons/cross.inline.svg";

export const headerHeight = 150;

export const Container = styled.div`
  max-width: 429px;
  width: 100%;
  height: 100%;
`;

export const Categories = styled.div``;

export const Service = styled.div`
  padding: 12px 12px 60px 24px;
`;

export const Header = styled(SidebarHeaderContainer)`
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-height: ${headerHeight}px;
  padding: 24px;
  background-color: ${rgba(colors.gray100, 0.3)};
  font-size: 22px;
  line-height: 22px;
`;

export const Title = styled.div`
  font-size: 22px;
  line-height: 22px;
`;

export const NoResultsMessage = styled.div`
  max-width: 351px;
  margin: 80px auto 0;
  font-size: 17px;
  line-height: 23px;
  color: ${colors.gray700};
  text-align: center;
`;

export const Close = styled(crossIcon)`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 15px;
  height: 15px;
  cursor: pointer;

  path {
    stroke: ${colors.gray400};
  }
`;
