import React from "react";
import { useScheduleInsert } from "../schedule-insert-modal/useScheduleInsert";

export const ScheduleInsertContext = React.createContext({});

export const ScheduleInsertContextProvider = ({ children }) => {
  return (
    <ScheduleInsertContext.Provider value={useScheduleInsert()}>
      {children}
    </ScheduleInsertContext.Provider>
  );
};
