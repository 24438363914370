import * as React from "react";

export const IconCameraOff = ({style}) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g clipPath="url(#clip0_101_8598)">
        <path d="M22.595 6.0825C22.4758 6.02555 22.3436 6.00111 22.2119 6.01165C22.0802 6.02218 21.9536 6.06732 21.845 6.1425L17.75 9.045V7.83L22.46 3.12C22.7582 2.82177 22.7582 2.33823 22.46 2.04C22.1618 1.74177 21.6782 1.74177 21.38 2.04L2.54 20.88C2.24177 21.1782 2.24177 21.6618 2.54 21.96C2.83823 22.2582 3.32177 22.2582 3.62 21.96L6.08 19.5H16.25C16.6478 19.5 17.0294 19.342 17.3107 19.0607C17.592 18.7794 17.75 18.3978 17.75 18V14.955L21.815 17.8575C21.9269 17.9372 22.0586 17.9846 22.1957 17.9946C22.3327 18.0045 22.4699 17.9766 22.5922 17.9139C22.7144 17.8513 22.8171 17.7562 22.8891 17.6391C22.961 17.522 22.9994 17.3874 23 17.25V6.75C23.0003 6.61228 22.9626 6.47714 22.8912 6.35939C22.8198 6.24165 22.7173 6.14585 22.595 6.0825ZM21.5 15.795L17.435 12.8925C17.3231 12.8128 17.1914 12.7654 17.0543 12.7554C16.9173 12.7455 16.7801 12.7734 16.6578 12.8361C16.5356 12.8987 16.4329 12.9938 16.3609 13.1109C16.289 13.228 16.2506 13.3626 16.25 13.5V18H7.58L16.25 9.33V10.5C16.2506 10.6374 16.289 10.772 16.3609 10.8891C16.4329 11.0062 16.5356 11.1013 16.6578 11.1639C16.7801 11.2266 16.9173 11.2545 17.0543 11.2446C17.1914 11.2346 17.3231 11.1872 17.435 11.1075L21.5 8.205V15.795Z" fill="var(--svg-fill, #000)"/>
        <path d="M2.75 18C3.16421 18 3.5 17.6642 3.5 17.25V6H14.75C15.1642 6 15.5 5.66421 15.5 5.25C15.5 4.83579 15.1642 4.5 14.75 4.5H3.5C3.10218 4.5 2.72064 4.65804 2.43934 4.93934C2.15804 5.22064 2 5.60218 2 6V17.25C2 17.6642 2.33579 18 2.75 18Z" fill="var(--svg-fill, #000)"/>
      </g>
      <defs>
        <clipPath id="clip0_101_8598">
          <rect width="24" height="24" fill="var(--svg-fill, #000)" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
};
