// @flow
import React from "react";
import { ModalFade } from "../modal/ModalFade";
import { ModalBackground } from "../modal/ModalBackground";
import { AlertWarningLayout } from "./AlertWarningLayout";
import { useModalEscape } from "../modal/useModalEscape";

export const AlertWarning = (props: {
  isNeedConfirm: boolean,
  sendConfirm?: boolean => void,
  hiddenCloseButton?: boolean,
  hiddenButtons?: boolean,
  heading: React$Node,
  description?: React$Node,
  containerModifiers?: Array,
  confirmButton?: (onClick: () => mixed) => React$Node,
  cancelButton?: (onClick: () => mixed) => React$Node
}) => {
  const { isNeedConfirm, sendConfirm, heading, description, confirmButton, cancelButton, containerModifiers, hiddenCloseButton, hiddenButtons } = props;
  const confirm = value => sendConfirm && sendConfirm(value);

  useModalEscape(() => confirm(false));

  return (
    <ModalFade>
      {isNeedConfirm && (
        <ModalBackground>
          <AlertWarningLayout
            containerModifiers={containerModifiers}
            hiddenCloseButton={hiddenCloseButton}
            hiddenButtons={hiddenButtons}
            heading={heading}
            description={description}
            cancelButton={cancelButton && cancelButton(() => confirm(false))}
            confirmButton={confirmButton && confirmButton(() => confirm(true))}
            onClose={() => confirm(false)}
          />
        </ModalBackground>
      )}
    </ModalFade>
  );
};
