import React from "react";
import { observer } from "mobx-react";
import { Box, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CorporateCard } from "./CorporateCard";
import { CorporateLikeCounter } from "./activity/CorporateLikeCounter";
import { CorporateViewCounter } from "./activity/CorporateViewCounter";
import { CorporateSubTitle, CorporateTitle } from "../styled/CorporateTypography.styled";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import { breakpoints } from "../styled/common";
import corporateNewsStore from "../stores/corporateNewsStore";

const CardTitleStyled = styled(CardContent)(
  () =>
    `
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0;
      ${breakpoints.tablet} {
        gap: 5px;
      }
    `
);

const CardTitleInfoContainer = styled(Box)(
  () =>
    `
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      ${breakpoints.tablet} {
        gap: 8px;
      }
    `
);

export const DetailedContent = styled("div")`
  font-family: "Graphik LCG" !important;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  
  * {
    font-family: "Graphik LCG" !important;
  }

  img {
    max-width: 100%;
    object-fit: contain;
    max-height: max-content;
    height: auto !important;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;

    @media screen and (max-width: 1200px) {
      font-size: 22px;
    }
    @media screen and (max-width: 640px) {
      font-size: 20px;
    }
  }

  ul, ol {
      list-style: inherit!important;
      padding: revert!important;
  }

  li {
      padding: 1px 0 !important;
  }

  p {
    line-height: 24px;
  }
`;

const CardActionsContainer = styled(Box)(
  () =>
    `
      display: flex;
      justify-content: space-between;
    `
);

export const CorporateDetailNewsCard = observer(
  ({ id, category, title, description, dataOfLikes, dataOfViews, content, ...props }) => {
    const handleChangeLikeCounter = async () => {
      await corporateNewsStore.changeLikeCounter({ newsId: id, data: dataOfLikes });
    };

    return (
      <CorporateWrapper>
        <CorporateCard>
          <CardTitleStyled>
            <CardTitleInfoContainer>
              <CorporateSubTitle modifiers={["md", "pink"]}>{category.name}</CorporateSubTitle>
              <CorporateSubTitle modifiers={["md", "gray"]}>
                {props.date} {props.time}
              </CorporateSubTitle>
            </CardTitleInfoContainer>
            <CorporateTitle>{title}</CorporateTitle>
          </CardTitleStyled>
          <DetailedContent dangerouslySetInnerHTML={{ __html: content }} />
          <CardActionsContainer>
            <CorporateLikeCounter
              value={dataOfLikes.count}
              active={dataOfLikes.isLiked}
              onClick={handleChangeLikeCounter}
            />
            <CorporateViewCounter value={dataOfViews.count} />
          </CardActionsContainer>
        </CorporateCard>
      </CorporateWrapper>
    );
  }
);
