import React, { useEffect } from "react";
import { reInitVoximplant, setVoximplantSupportData } from "../../utils/voximplantSupport";

export const SupportPage = () => {
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const clientId = params.get("clientId");
    const name = params.get("name");

    setVoximplantSupportData({
      client_id: clientId,
      client_display_name: name
    });

    if (window.VoxKitWidget) {
      reInitVoximplant();
    }
    if (!document.getElementsByClassName('kit-widget-container is-open').length) {
      document.getElementsByClassName('widget-btn')[0].click();
    }
  }, []);

  return <></>;
};
