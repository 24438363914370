//@flow
import * as React from "react";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";
import _ from "lodash";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  display: flex;
`;

export const DefaultTab = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  outline: none;

  &:focus > * {
    color: ${colors.magenta};
  }

  &:focus:not(:focus-visible) > * {
    color: inherit;
  }

  &:hover > * {
    color: ${colors.magenta};
  }
`;

const OverridableProps = {
  Container,
  DefaultTab
};

export type TabsMap = {
  [key: string]: { name: string }
};

export type TabsProps<T> = {|
  tabs: T,
  activeTab: $Keys<T>,
  handleClickOnTab: ($Keys<T>) => mixed
|};

type TabsOverridesProps = ExtractOverridesProps<typeof OverridableProps>;

export const Tabs = <T: TabsMap>(props: {| ...TabsProps<T>, overrides?: TabsOverridesProps |}) => {
  const { tabs, activeTab, handleClickOnTab, isMobileDevice } = props;
  return (
    <Container {...o}>
      {_.map(tabs, (tab, index) => (
        <DefaultTab
          {...o}
          key={index}
          tab={{ ...tab, id: index }}
          onClick={() => handleClickOnTab(index)}
          modifiers={activeTab === index && "active"}
          isMobileDevice={isMobileDevice}
        >
          {tab.render ? tab.render() : tab.name}
        </DefaultTab>
      ))}
    </Container>
  );
};
