import React, { useLayoutEffect, useState } from "react";
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  Box,
  Divider as MuiDivider
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { CorporateAccordionWrapper } from "../styled/CorporateWrapper.styled";
import { corporateTextPalette } from "../styled/common";
import { styled } from "@mui/material/styles";
import { colors } from "../../components/styleguide/colors";

export const ACCORDION_CONTENT_TYPES = {
  ACCORDION: "accordion",
  CONTENT: "content"
};

const MoreButtonStyled = styled(Box)`
  display: flex;
  padding: 0 16px;
  color: ${corporateTextPalette.pink};
  cursor: pointer;
  .MuiBox-root {
    margin: 16px 0;
  }
  &:hover {
    background-color: rgba(216, 216, 216, 0.15);
  }
`;

const Divider = styled(MuiDivider)`
  width: calc(100% - 32px);
  margin: 0 auto;
  background-color: ${colors.concrete};
`;

const Accordion = ({
  data,
  renderContent,
  handleOnOpeningAccordion,
  previousLevelExpanded,
  triggerForClosingAccordions,
  triggerForOpeningAccordions,
  ...otherProps
}) => {
  const [currentLevelExpanded, setCurrentLevelExpanded] = useState(false);

  const handleExpandedAccordion = (event, isExpanded) => setCurrentLevelExpanded(isExpanded);

  useLayoutEffect(() => {
    setCurrentLevelExpanded(false);
  }, [triggerForClosingAccordions]);

  useLayoutEffect(() => {
    handleOnOpeningAccordion({ data, setCurrentLevelExpanded });
  }, [triggerForOpeningAccordions]);

  /** Если один из предыдущих уровней свернулся (previousLevelExpanded), свернуть потомки */
  useLayoutEffect(() => {
    if (!previousLevelExpanded) {
      setCurrentLevelExpanded(false);
    }
  }, [previousLevelExpanded]);

  return (
    <MuiAccordion
      expanded={currentLevelExpanded}
      disableGutters
      elevation={0}
      onChange={handleExpandedAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{data.name}</AccordionSummary>
      <AccordionDetails>
        {data.type === ACCORDION_CONTENT_TYPES.ACCORDION ? (
          <CorporateAccordion
            data={data.details}
            currentLevel={data.level}
            isShowDivider={true}
            renderContent={renderContent}
            handleOnOpeningAccordion={handleOnOpeningAccordion}
            previousLevelExpanded={currentLevelExpanded}
            triggerForClosingAccordions={triggerForClosingAccordions}
            triggerForOpeningAccordions={triggerForOpeningAccordions}
            {...otherProps}
          />
        ) : (
          renderContent({ data, expanded: currentLevelExpanded })
        )}
      </AccordionDetails>
    </MuiAccordion>
  );
};

/** Компонент поддерживает вложенную структуру (см. пример на странице сотрудники, корпоративный портал);
 * previousLevelExpanded - протягивается по всем потомкам;
 * Компонент принимает renderContent для ACCORDION_CONTENT_TYPES === "content" - протягивается по всем потомкам */
export const CorporateAccordion = ({
  data,
  currentLevel,
  isShowDivider = false,
  maxVisibleElementsByLevel,
  moreButtonText = "Показать еще",
  previousLevelExpanded = true,
  searchedData,
  ...otherProps
}) => {
  const [isShowMoreButton, setIsShowMoreButton] = useState(false);
  const [visibleElements, setVisibleElements] = useState([]);
  const [invisibleElements, setInvisibleElements] = useState([]);

  /** Расчет видимых|невидимых элементов, основываясь на полученное значение maxVisibleElementsByLevel.
   * При 1 рендере подается первый уровень data в useLayoutEffect;
   * Последующий расчет происходит при нажатии на кнопку (метод handleShowInvisibleData) */
  const getMaxVisibleElements = elements => {
    const maxVisibleElements = maxVisibleElementsByLevel?.[currentLevel] || null;

    if (!maxVisibleElements) {
      return {
        visible: elements,
        invisible: []
      };
    }

    if (elements.length > maxVisibleElements) {
      setIsShowMoreButton(true);

      return {
        visible: elements.slice(0, maxVisibleElements),
        invisible: elements.slice(maxVisibleElements)
      };
    } else {
      setIsShowMoreButton(false);

      return {
        visible: elements,
        invisible: []
      };
    }
  };

  const handleShowInvisibleData = () => {
    const { visible, invisible } = getMaxVisibleElements(invisibleElements);

    setVisibleElements([...visibleElements, ...visible]);
    setInvisibleElements(invisible);
  };

  useLayoutEffect(() => {
    if (searchedData) {
      setIsShowMoreButton(false);
      setVisibleElements([...visibleElements, ...invisibleElements]);
      setInvisibleElements([]);
    } else {
      const { visible, invisible } = getMaxVisibleElements(data);

      setVisibleElements(visible);
      setInvisibleElements(invisible);
    }
  }, [data, previousLevelExpanded, searchedData]);

  if (!visibleElements.length) {
    return null;
  }

  /** Рендер visibleElements */
  return visibleElements.map((item, index) => {
    const numberItem = index + 1;
    const divider =
      isShowDivider && visibleElements.length !== 1 && visibleElements.length !== numberItem;

    return (
      <CorporateAccordionWrapper key={item.name}>
        {/** Рендер компонента Accordion происходит отдельным компонентом, т.к. необходимо использование хука useState */}
        <Accordion
          data={item}
          maxVisibleElementsByLevel={maxVisibleElementsByLevel}
          previousLevelExpanded={previousLevelExpanded}
          searchedData={searchedData}
          {...otherProps}
        />
        {isShowMoreButton && visibleElements.length === numberItem && (
          <MoreButtonStyled onClick={handleShowInvisibleData}>
            <Box>{moreButtonText}</Box>
          </MoreButtonStyled>
        )}
        {divider && <Divider sx={{ borderColor: colors.concrete }} />}
      </CorporateAccordionWrapper>
    );
  });
};
