// @flow
import React from "react";
import { clearNode, type Store } from "effector";

export const useAutoCleanedStore = <S, T: { store: Store<S> }, F: () => T>(
  creator: F,
  keys: Array<any>
): T => {
  const store = React.useMemo(creator, keys);

  React.useEffect(() => {
    return () => clearNode(store.store);
  }, [store]);

  React.useEffect(() => {
    return () => clearNode(store.store);
  }, []);

  return store;
};
