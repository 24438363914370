// @flow

import * as React from "react";

import type { LayoutFieldValue, TAppointment_id } from "../../domain/entities/Appointment";
import type { FieldDate } from "../../domain/entities/Field";
import { getField_id } from "../../domain/entities/Field";
import { setFieldValue } from "../../stores/appointmentFieldValues";
import { useFieldValue } from "./useFieldValue";
import moment from "moment";
import { READABLE_DATE_FORMAT, isValidReadableDate } from "../../domain/services/field";
import { getTextValue } from "./getTextValue";
import { DateInput } from "../form-elements/legacy/DateInput";
import { useErrorHighlighting } from "./useErrorHighlighting";

export const DateWidget = (props: {
  field: FieldDate,
  tabIndex?: number,
  showError?: boolean,
  appointment_id: TAppointment_id,
  layoutFieldValue?: LayoutFieldValue
}) => {
  const { appointment_id, field, tabIndex, layoutFieldValue } = props;
  const result = useFieldValue(appointment_id, field.id);
  const value = getTextValue(result, layoutFieldValue, value =>
    moment(value).format(READABLE_DATE_FORMAT)
  );

  const { error, handleBlur, handleFocus } = useErrorHighlighting(
    value,
    appointment_id,
    getField_id(field),
    isValidReadableDate,
    field.required
  );

  return (
    <DateInput
      value={value}
      tabIndex={tabIndex}
      handleChange={newValue => {
        setFieldValue({
          field_type: field.field_type,
          appointment_id,
          field_id: field.id,
          value: newValue
        });
      }}
      placeholder="ДД.ММ.ГГГГ"
      onBlur={handleBlur}
      onFocus={handleFocus}
      error={(props.showError || value) ? error.value : false}
    />
  );
};
