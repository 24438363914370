import React, { Fragment, useEffect, useMemo, useState } from "react";
import voxInformationPatientStore, {
  paymentTableConfig,
  TEMPLATE_TEXTS,
  TEMPLATES,
  LINK_TYPES,
  LINKS
} from "../../stores/voxInformationPatientStore";
import styled from "styled-components";
import { observer } from "mobx-react";
import {
  Accordion,
  AccordionSummary,
  Button,
  Tooltip,
  Link as MuiLink
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import dayjs from "dayjs";
import {
  READABLE_DATE_FORMAT,
  READABLE_DATE_TIME_FORMAT,
  READABLE_TIME_FORMAT_MOMENT,
  SERVER_DATE_FORMAT,
  SERVER_TIME_FORMAT
} from "../../utils/dates";
import { DynamicForm } from "../common/dynamic-form/DynamicForm";
import TextField from "@mui/material/TextField";
import { applicationFeatureEnabled, FEATURE } from "../../utils/applicationFeatures";
import AccordionDetails from "@mui/material/AccordionDetails";
import * as moment from "moment";
import { AbsoluteLoader } from "../loader/Loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PaymentsStatisticsPage } from "../payment-statistics-page/PaymentsStatisticsPage";
import { useStore } from "effector-react";
import { currentUser } from "../../stores/auth";
import _ from "lodash";
import paymentStatisticsStore from "../../stores/paymentStatisticsStore";
import { VoxAppointmentsTable } from "./modules/VoxAppointmentsTable";
import { AppointmentDirectionsPage } from "../appointment-directions-table/AppointmentDirectionsPage";
import appointmentDirectionsStore from "../../stores/appointmentDirectionsStore";

const VoxInformationPatientPage = styled.div`

`;

const InfoBlockTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const InfoBlock = styled.div`
  ${InfoBlockTitle} {
    margin-bottom: 15px;
  }
`;

const InfoItem = styled.div`
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: 768px) {
      gap: 4px;
    }
`;

const InfoBlockContent = styled.div`
  display: flex;
  gap: 10px;
  ${props => props.contentDirection === "row"
  ? `
      flex-direction: row;
      justify-content: space-between;
    `
  : `
      flex-direction: column;
    `
};
  flex-wrap: wrap;
  @media (max-width: 768px) {
    gap: 13px;
  }
  ${props => props.contentBacklight
  ? `
        gap: 0 !important;
        ${CommunicationBlock} {
          padding: 8px 6px;
          &:nth-child(2n + 1) {
            background: #ededed;
            &:not(:first-child) {
              border-top: 1px solid #EBEBEB;
            }
            &:not(:last-child) {
              border-bottom: 1px solid #EBEBEB;
            }
          }
        }
      `
  : ``};
`;

const InfoItemValue = styled.div`
    word-break: break-word;
`;

const CommunicationBlock = styled.div`
`;


const SendMessageBlock = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const InfoItemLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const InfoBlockContainer = ({
  title = "",
  contentDirection = "",
  contentBacklight = "",
  children
}) => {
  return (
    <InfoBlock>
      {!!title && <InfoBlockTitle>{title}</InfoBlockTitle>}
      <InfoBlockContent contentDirection={contentDirection} contentBacklight={contentBacklight}>
        {children}
      </InfoBlockContent>
    </InfoBlock>
  );
};

const MedicalFilesInfo = ({ medicalFile, isAuthorized = false }) => {
  if (!medicalFile) {
    return null;
  }
  const currentDate = moment().format(SERVER_DATE_FORMAT);

  return (
    <Fragment>
      <InfoItem>
        <InfoItemLabel>ФИО пациента:</InfoItemLabel>
        <InfoItemValue>
          {medicalFile.patient.last_name} {medicalFile.patient.first_name} {medicalFile.patient.middle_name}
        </InfoItemValue>
      </InfoItem>
      <InfoItem>
        <InfoItemLabel>Дата рождения:</InfoItemLabel>
        <InfoItemValue>{medicalFile.patient.birthdate}</InfoItemValue>
      </InfoItem>
      <InfoItem>
        <InfoItemLabel>Номер телефона:</InfoItemLabel>
        <InfoItemValue>{medicalFile.patient.phone_number}</InfoItemValue>
      </InfoItem>
      <InfoItem>
        <InfoItemLabel>Организация:</InfoItemLabel>
        <InfoItemValue>{medicalFile.organization.name}</InfoItemValue>
      </InfoItem>
      {!!medicalFile.badge && (
        <InfoItem>
          <InfoItemLabel>Badge пациента:</InfoItemLabel>
          <InfoItemValue>{medicalFile.badge}</InfoItemValue>
        </InfoItem>
      )}
      <InfoItem>
        <InfoItemLabel>Есть действующий полис ДМС:</InfoItemLabel>
        <InfoItemValue>
          {medicalFile.patient.policies.some(
            policy => currentDate <= moment(policy.end_date).format(SERVER_DATE_FORMAT)
          )
            ? "Да"
            : "Нет"}
        </InfoItemValue>
      </InfoItem>

      {isAuthorized && (
        <Fragment>
          <MuiLink
            href={LINKS[LINK_TYPES.MEDICAL_FILE]({ id: medicalFile.id })}
            target="_blank"
            rel="noreferrer"
          >
            Ссылка на медицинскую карту пациента
          </MuiLink>
          <MuiLink
            href={LINKS[LINK_TYPES.PATIENT_GROUPS]({ id: medicalFile.patient.patient_group_id })}
            target="_blank"
            rel="noreferrer"
          >
            Ссылка на группы пациента
          </MuiLink>
        </Fragment>
      )}
    </Fragment>
  );
};

const ScheduleInfo = (
  {
    title = "",
    data = [],
    enablePagination = true,
    isAuthorized = false,
    countShowedScheduleItems,
    setCountShowedScheduleItems
  }) => {
  const [list, setList] = useState(data);

  useEffect(() => {
    if (enablePagination) {
      setList(data.slice(0, countShowedScheduleItems));
    }
  }, [countShowedScheduleItems])


  if (!list.length) {
    return null;
  }

  return (
    <InfoBlockContainer title={title} contentBacklight>
      {list.map((item) => (
        <CommunicationBlock key={item.id}>
          <InfoItem>
            <InfoItemLabel>Дата записи:</InfoItemLabel>
            <InfoItemValue>{dayjs(item.date).format(READABLE_DATE_FORMAT)}</InfoItemValue>
          </InfoItem>
          <InfoItem>
            <InfoItemLabel>Время записи:</InfoItemLabel>
            <InfoItemValue>{item.time ? dayjs(item.time, SERVER_TIME_FORMAT).format(READABLE_TIME_FORMAT_MOMENT) : "вне расписания"}</InfoItemValue>
          </InfoItem>
          <InfoItem>
            <InfoItemLabel>Организация:</InfoItemLabel>
            <InfoItemValue>{item.organization.name}</InfoItemValue>
          </InfoItem>
          <InfoItem>
            <InfoItemLabel>Доктор:</InfoItemLabel>
            <InfoItemValue>{item.doctor_organization.doctor.last_name} {item.doctor_organization.doctor.first_name} {item.doctor_organization.doctor.middle_name}</InfoItemValue>
          </InfoItem>
          <InfoItem>
            <InfoItemLabel>Специализации врача:</InfoItemLabel>
            <InfoItemValue>{item.doctor_organization.doctor.specialities.map((speciality) => speciality.name).join(', ')}</InfoItemValue>
          </InfoItem>
          { item.online_link_for_patient && (
            <InfoItem>
              <InfoItemLabel>Ссылка на онлайн звонок:</InfoItemLabel>
              <InfoItemValue>{item.online_link_for_patient}</InfoItemValue>
            </InfoItem>
          )}
          <InfoItem>
            <InfoItemLabel>Тип приема:</InfoItemLabel>
            <InfoItemValue>{item.type}</InfoItemValue>
          </InfoItem>
          {isAuthorized && (
            <MuiLink
              href={LINKS[LINK_TYPES.SCHEDULE]({ id: item.schedule_insert.id })}
              target="_blank"
              rel="noreferrer"
            >
              Ссылка на запись в Django
            </MuiLink>
          )}
        </CommunicationBlock>
      ))}
      {(enablePagination && countShowedScheduleItems < data.length) && (
        <Button variant={"outlined"} sx={{mt: 1}} onClick={() => setCountShowedScheduleItems(countShowedScheduleItems + 3)}>Загрузить еще</Button>
      )}
    </InfoBlockContainer>
  )
}

export const VoxInformationPatient = observer(() => {
  const user = useStore(currentUser);
  const isAuthorized = !!user;
  const TABS = useMemo(() => {
    return [
      {
        value: "1",
        label: "Информация"
      },
      {
        value: "2",
        label: "Рассылки"
      },
      {
        value: "3",
        label: "Записи"
      },
      {
        value: "4",
        label: "Платежи",
        isHiddenTab: () => !isAuthorized
      },
      {
        value: "5",
        label: "Приемы",
        isHiddenTab: () => !isAuthorized
      },
      {
        value: "6",
        label: "Направления",
        isHiddenTab: () => !isAuthorized
      }
    ].filter(tab => (tab.isHiddenTab ? !tab.isHiddenTab() : tab));
  }, [isAuthorized]);
  const [activeTab, setActiveTab] = React.useState(TABS[0]);
  const [countCommunication, setCountCommunication] = useState(5);
  const [countShowedScheduleItems, setCountShowedScheduleItems] = useState(3);

  const handleChange = (event, newValue) => {
    if (newValue === "4") {
      paymentStatisticsStore.setGlobalFiltersTable({
        patient__phone_number: voxInformationPatientStore.phone_number
      });
      paymentStatisticsStore.additionalParamsValues = ["id"];
    }
    setActiveTab(_.find(TABS, { value: newValue }));
  };

  useEffect(() => {
    (async () => {
      const phone_number = new URLSearchParams(location.search).get('phone_number');
      if (phone_number) {
        voxInformationPatientStore.setPhoneNumber(new URLSearchParams(location.search).get('phone_number'));
        await voxInformationPatientStore.initialize();
      }
    })()
  }, []);

  const defaultData = {
    phone_number: '',
  }

  const fields = useMemo(
    () => [
      {
        key: "phone_number",
        type: "phoneNumber",
        name: "phone_number",
        label: "Телефон пациента",
        xs: 12
      },
    ],
    []
  );

  const COMMUNICATION_STATUS = {
    CREATED: "Создано для отправки",
    SENT: "Отправлено",
    DELIVERED: "Доставлено",
    READ: "Прочитано",
    UNDELIVERED: "Отправлено, но не доставлено",
    CANCELLED: "Отменено",
    EXPIRED: "Истекло время ожидания",
    FAILED: "Произошла ошибка",
    TOO_MANY_MAILING: "Слишком много рассылок в месяц"
  }

  const submitHandler = (data) => {
    voxInformationPatientStore.setPhoneNumber(data.phone_number.replaceAll(' ', '').replaceAll('+', ''));
    voxInformationPatientStore.initialize();
  }

  const handleOnSetNewNumber = () => {
    paymentStatisticsStore.dataTable = [];
    paymentStatisticsStore.globalFiltersTable = {};
    appointmentDirectionsStore.isLoading = false;
    appointmentDirectionsStore.isInitialized = false;
    appointmentDirectionsStore.dataTable = [];
    handleChange(null, "1");
    voxInformationPatientStore.setPhoneNumber(null);
    setCountCommunication(5);
    setCountShowedScheduleItems(3);
  }

  const EmptyData = () => {
    return (
      <Fragment>
        <InfoItemLabel>
          Данные по табу <span style={{ textTransform: "lowercase" }}>{activeTab.label}</span> не найдены
        </InfoItemLabel>
        <Button variant="outlined" sx={{ mt: 2 }} onClick={handleOnSetNewNumber}>
          Ввести другой номер
        </Button>
      </Fragment>
    );
  };

  return (
    <VoxInformationPatientPage>
      {voxInformationPatientStore.phone_number ? (
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={activeTab.value}>
            {voxInformationPatientStore.isInitialized && (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable" scrollButtons="auto">
                  {TABS.map(({ value, label }) => (
                    <Tab key={`${label}_${value}`} label={label} value={value} />
                  ))}
                </TabList>
              </Box>
            )}
            <TabPanel value="1">
              {!voxInformationPatientStore.isInitialized ? (
                <AbsoluteLoader />
              ) : !voxInformationPatientStore.medicalFiles.length ? (
                <EmptyData />
              ) : (
                <Fragment>
                  <InfoBlockContainer title="Информация о пациенте">
                    {voxInformationPatientStore.medicalFiles.length > 1 ? (
                      <Fragment>
                        <InfoItemLabel>Медицинские карты пациента:</InfoItemLabel>
                        {voxInformationPatientStore.medicalFiles.map(medicalFile => (
                          <Accordion key={medicalFile.number} disableGutters square>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              sx={{ fontWeight: 500 }}
                            >
                              АК №{medicalFile.number},{" "}
                              {medicalFile.organization.name},{" "}
                              {medicalFile.patient.last_name} {medicalFile.patient.first_name} {medicalFile.patient.middle_name}
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                              <MedicalFilesInfo medicalFile={medicalFile} isAuthorized={isAuthorized} />
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </Fragment>
                    ) : (
                      voxInformationPatientStore.medicalFiles.map(medicalFile => (
                        <Fragment key={medicalFile.number}>
                          <InfoItem>
                            <InfoItemLabel>Медицинская карта пациента:</InfoItemLabel>
                            <InfoItemValue>АК №{medicalFile.number}</InfoItemValue>
                          </InfoItem>
                          <MedicalFilesInfo medicalFile={medicalFile} isAuthorized={isAuthorized} />
                        </Fragment>
                      ))
                    )}
                  </InfoBlockContainer>

                  <Button variant="outlined" sx={{ mt: 2 }} onClick={handleOnSetNewNumber}>
                    Ввести другой номер
                  </Button>
                </Fragment>
              )}
              {voxInformationPatientStore.isInitialized && (
                <>
                  <SendMessageBlock>
                    <InfoBlockTitle>Отмена приема</InfoBlockTitle>
                    <Tooltip
                      title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_CANCEL]}
                      slotProps={{
                        tooltip: {
                          sx: { whiteSpace: "pre-wrap"}
                        }
                      }}
                    >
                      <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationCancel()}>Отправить сообщение о отмене приема</Button>
                    </Tooltip>
                  </SendMessageBlock>

                  <SendMessageBlock>
                    <InfoBlockTitle>Перенос приема</InfoBlockTitle>
                    <Tooltip
                      title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_TRANSFER]}
                      slotProps={{
                        tooltip: {
                          sx: { whiteSpace: "pre-wrap"}
                        }
                      }}
                    >
                      <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationTransfer()}>Отправить сообщение о переносе приема</Button>
                    </Tooltip>
                  </SendMessageBlock>

                  <SendMessageBlock>
                    <InfoBlockTitle>Открытие расписания (лист ожидания)</InfoBlockTitle>
                    <Tooltip
                      title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_OPENING_SCHEDULE]}
                      slotProps={{
                        tooltip: {
                          sx: { whiteSpace: "pre-wrap"}
                        }
                      }}
                    >
                      <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationOpeningSchedule()}>Отправить сообщение о открытом расписании</Button>
                    </Tooltip>
                  </SendMessageBlock>

                  <SendMessageBlock>
                    <InfoBlockTitle>Освободившийся слот (лист ожидания)</InfoBlockTitle>
                    <Tooltip
                      title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_VACANT_SLOT]}
                      slotProps={{
                        tooltip: {
                          sx: { whiteSpace: "pre-wrap"}
                        }
                      }}
                    >
                      <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationVacantSlot()}>Отправить сообщение о освободившемся слоте</Button>
                    </Tooltip>
                  </SendMessageBlock>

                  <SendMessageBlock>
                    <InfoBlockTitle>Подготовка к процедурам</InfoBlockTitle>
                    <TextField
                      onChange={(event) => voxInformationPatientStore.setLinkToInstructions(event.target.value)}
                      value={voxInformationPatientStore.linkToInstructions || ''}
                      fullWidth
                      variant="outlined"
                      multiline
                      maxRows={5}
                      placeholder="Укажите ссылку на инструкцию"
                    />
                    <Tooltip
                      title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_PREPARATION_PROCEDURES].replaceAll("{{link}}", voxInformationPatientStore.linkToInstructions || "")}
                      slotProps={{
                        tooltip: {
                          sx: { whiteSpace: "pre-wrap"}
                        }
                      }}
                    >
                      <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendMessageInitiationPreparationProcedures()}>Отправить сообщение о подготовке к процедурам</Button>
                    </Tooltip>
                  </SendMessageBlock>

                  <SendMessageBlock>
                    <InfoBlockTitle>Инициация сообщений пациенту</InfoBlockTitle>
                    <TextField
                      onChange={(event) => voxInformationPatientStore.setDynamicText(event.target.value)}
                      value={voxInformationPatientStore.dynamicText || ''}
                      fullWidth
                      variant="outlined"
                      multiline
                      helperText={'Текст для сообщения надо вводить в одну строку иначе сообщение не дойдёт.'}
                      maxRows={5}
                      placeholder={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_DYNAMIC_TEXT].replaceAll("{{message}}", "Тут будет вводимый текст из поля")}
                    />
                    <Tooltip
                      title={TEMPLATE_TEXTS[TEMPLATES.MESSAGE_INITIATION_DYNAMIC_TEXT].replaceAll("{{message}}", voxInformationPatientStore.dynamicText || "")}
                      slotProps={{
                        tooltip: {
                          sx: { whiteSpace: "pre-wrap"}
                        }
                      }}
                    >
                      <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendDynamicMessagePatient()}>Отправить динамическое сообщение</Button>
                    </Tooltip>
                  </SendMessageBlock>

                  {!!applicationFeatureEnabled(FEATURE.ENABLE_VOX_TEMPLATE_T_FEEDBACK_NEW) && (
                    <SendMessageBlock>
                      <InfoBlockTitle>Сообщение с ссылкой на обратную связь</InfoBlockTitle>
                      <TextField
                        onChange={(event) => voxInformationPatientStore.setFeedbackData("last_name", event.target.value)}
                        value={voxInformationPatientStore.feedbackData.last_name || ''}
                        fullWidth
                        variant="outlined"
                        placeholder="Укажите фамилию пациента"
                      />
                      <TextField
                        onChange={(event) => voxInformationPatientStore.setFeedbackData("first_name", event.target.value)}
                        value={voxInformationPatientStore.feedbackData.first_name || ''}
                        fullWidth
                        variant="outlined"
                        placeholder="Укажите имя пациента"
                      />
                      <TextField
                        onChange={(event) => voxInformationPatientStore.setFeedbackData("link", event.target.value)}
                        value={voxInformationPatientStore.feedbackData.link || ''}
                        fullWidth
                        variant="outlined"
                        multiline
                        maxRows={5}
                        placeholder="Укажите ссылку для обратной связи"
                      />
                      <Tooltip
                        title={
                          TEMPLATE_TEXTS[TEMPLATES.T_FEEDBACK_NEW]
                            .replace("{{last_name}}", voxInformationPatientStore.feedbackData?.last_name || "")
                            .replace("{{first_name}}", voxInformationPatientStore.feedbackData?.first_name || "")
                            .replace("{{link}}", voxInformationPatientStore.feedbackData?.link || "")
                        }
                        slotProps={{
                          tooltip: {
                            sx: { whiteSpace: "pre-wrap"}
                          }
                        }}
                      >
                        <Button variant={"outlined"} sx={{mt: 1}} onClick={() => voxInformationPatientStore.sendFeedbackNew()}>Отправить ссылку на обратную связь</Button>
                      </Tooltip>
                    </SendMessageBlock>
                  )}
                </>
              )}
            </TabPanel>
            <TabPanel value="2">
              {voxInformationPatientStore.communications.length > 0 ? (
                <InfoBlockContainer title="Рассылки пациенту" contentBacklight>
                  {voxInformationPatientStore.communications.slice(0, countCommunication).map((item) => (
                    <CommunicationBlock key={item.id}>
                      <InfoItem>
                        <InfoItemLabel>Дата рассылки:</InfoItemLabel>
                        <InfoItemValue>{dayjs(item.communication_mailing.date).format(READABLE_DATE_TIME_FORMAT)}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>Токен рассылки:</InfoItemLabel>
                        <InfoItemValue>{item.communication_mailing.token}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>Статус доставки:</InfoItemLabel>
                        <InfoItemValue>{COMMUNICATION_STATUS[item.status]}</InfoItemValue>
                      </InfoItem>
                      <InfoItem>
                        <InfoItemLabel>Текст рассылки:</InfoItemLabel>
                        <InfoItemValue>{item.templateWithVariables}</InfoItemValue>
                      </InfoItem>
                    </CommunicationBlock>
                  ))}
                  {countCommunication <= voxInformationPatientStore.communications.length && (
                    <Button variant={"outlined"} sx={{mt: 1}} onClick={() => setCountCommunication(countCommunication + 5)}>Загрузить еще</Button>
                  )}
                </InfoBlockContainer>
              ) : (
                <EmptyData />
              )}
            </TabPanel>
            <TabPanel value="3">
              {!voxInformationPatientStore.scheduleFuture.length && !voxInformationPatientStore.schedulePast.length && (
                <EmptyData />
              )}
              <ScheduleInfo
                title="Будущие записи пациента"
                data={voxInformationPatientStore.scheduleFuture}
                enablePagination={false}
                isAuthorized={isAuthorized}
              />
              <ScheduleInfo
                title="Прошедшие записи пациента"
                data={voxInformationPatientStore.schedulePast}
                countShowedScheduleItems={countShowedScheduleItems}
                setCountShowedScheduleItems={setCountShowedScheduleItems}
                isAuthorized={isAuthorized}
              />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: 0 }}>
              <PaymentsStatisticsPage
                enableShowingTabs={false}
                enableShowingChart={false}
                enableShowingAdditionalFilters={false}
                enableSettingDefaultDate={false}
                enableDisableSavingPresets
                notPadding
                renderRowActions={paymentTableConfig.renderRowActions}
                columnOrder={paymentTableConfig.columnOrder}
                additionalColumns={paymentTableConfig.additionalColumns}
                columnHidden={paymentTableConfig.columnHidden}
              />
            </TabPanel>
            <TabPanel value="5" sx={{ padding: 0 }}>
              {!voxInformationPatientStore.appointmentsTableDataInitialized && <AbsoluteLoader />}
              <VoxAppointmentsTable />
            </TabPanel>
            <TabPanel value="6" sx={{ padding: 0 }}>
              {(appointmentDirectionsStore.isLoading || !appointmentDirectionsStore.isInitialized) && <AbsoluteLoader />}
              <AppointmentDirectionsPage isWithoutLayout isWithoutBottomIndent />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <Box sx={{ margin: 2 }}>
          <DynamicForm
            textButton="Сохранить"
            submitHandler={submitHandler}
            data={defaultData}
            fields={fields}
            hiddenButtonCancel={true}
          />
        </Box>
      )}
    </VoxInformationPatientPage>
  );
});
