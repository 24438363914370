import React, { useCallback, useEffect, useRef } from "react";
import { useAsyncFn } from "react-use";
import { useStore } from "effector-react";
import { Link } from "react-router-dom";

import { useToast } from "../toast/useToast";
import { tokenStore } from "../../stores/auth";

import { Toast, ToastTitle, ToastText, ToastBody } from "./styled/PatientReminder.styled";
import {socketErrorHandler} from '../../utils/sockerErrorHandler';
import apiv2 from '../../apiv2';

export const usePatientReminders = () => {
  const token = useStore(tokenStore);

  const showedRemindersOnMount = useRef();

  const webSocket = useRef();

  const { showToast, closeToast } = useToast();

  const [{ value: reminders }, fetchReminders] = useAsyncFn(
    () => apiv2.notifications.getPatientReminders(),
    [token]
  );

  const handleReminderClick = useCallback(
    id => {
      apiv2.notifications.setPatientReminderRead(id);
    },
    [token]
  );

  const showReminder = useCallback(
    (id, name, text, appointmentId, animation) => {
      return showToast(
        <Toast>
          <ToastTitle>Напоминание - {name}</ToastTitle>
          <ToastText>{text}</ToastText>
        </Toast>,
        {
          container: {
            component: ToastBody,
            props: {
              to: `/appointment/${appointmentId}`,
              as: Link
            }
          },
          onClick: toast => {
            closeToast(toast.id);
            handleReminderClick(id);
          },
          onClose: () => handleReminderClick(id),
          duration: 1000000,
          animation
        }
      );
    },
    [showToast, handleReminderClick, closeToast]
  );

  const initSocket = () => {
    if (webSocket.current && webSocket.current instanceof WebSocket) {
      webSocket.current.close();
    }

    webSocket.current = new WebSocket(apiv2.notifications.getPatientRemindersUrl(token));

    webSocket.current.onclose = socketErrorHandler(webSocket.current, initSocket);

    webSocket.current.onmessage = event => {
      const parsedData =
        event && event.data && typeof event.data === "string" ? JSON.parse(event.data) : {};

      if (!parsedData?.data) {
        return;
      }

      showReminder(
        parsedData.data.id,
        parsedData.data.patient_name,
        parsedData.data.text,
        parsedData.data.appointment_id
      );
    };
  };

  useEffect(() => {
    if (reminders && !showedRemindersOnMount.current) {
      showedRemindersOnMount.current = true;

      for (const item of reminders) {
        showReminder(item.id, item.patient_name, item.text, item.appointment, visible =>
          visible
            ? "toast-anim-fade-in 0.3s ease-in-out forwards"
            : "toast-anim-out 0.8s ease-in-out forwards"
        );
      }
    }
  }, [reminders, showToast, showReminder]);

  useEffect(() => {
    if (token) {
      fetchReminders();
      initSocket();
    }
  }, [token]);
};
