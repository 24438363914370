import React from "react";
import { colors } from "../../../styleguide/colors";

const Icon = props => {
  const { color = "#979797", active = false } = props;
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9799 5.78594C14.9317 5.63743 14.8033 5.52924 14.6488 5.50679L10.5169 4.90634C10.3866 4.88741 10.274 4.80559 10.2157 4.68753L8.36781 0.943342C8.29871 0.803343 8.15614 0.714722 8.00001 0.714722C7.84391 0.714722 7.70131 0.80337 7.63221 0.943342L5.78421 4.68756C5.72594 4.80562 5.61332 4.88744 5.48304 4.90637L1.35117 5.50679C1.19668 5.52924 1.06833 5.63746 1.02009 5.78594C0.971833 5.93441 1.01208 6.09741 1.12386 6.20638L4.11366 9.12086C4.20793 9.21275 4.25094 9.34514 4.22869 9.47489L3.523 13.5902C3.49661 13.7441 3.55989 13.8996 3.68619 13.9913C3.81246 14.0831 3.97986 14.0952 4.11814 14.0226L7.81384 12.0796C7.93037 12.0183 8.06959 12.0183 8.18612 12.0796L11.8817 14.0226C11.9417 14.0542 12.0073 14.0697 12.0726 14.0697C12.1576 14.0697 12.2422 14.0433 12.3137 13.9914C12.44 13.8996 12.5032 13.7441 12.4769 13.5902L11.771 9.47492C11.7487 9.34515 11.7917 9.21275 11.886 9.12085L14.8761 6.2064C14.9879 6.09741 15.0282 5.93441 14.9799 5.78594Z"
        fill={active ? colors.magenta : "transparent"}
        stroke={active ? colors.magenta : color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
