import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { GeneralScheduleCardList, GeneralScheduleCardNew, GeneralScheduleCardBlockList } from "./GeneralScheduleCardNew";
import { ScheduleInsertContext, ScheduleInsertContextProvider } from "./ScheduleInsertContext";
import { ModalPortal } from "../modal/ModalPortal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import generalScheduleStore from "../../stores/generalScheduleStore";
import { scrollbars } from "../chats/style-elements";
import { GeneralSchedulePlaceholderNew } from "./GeneralSchedulePlaceholderNew";
import { observer } from "mobx-react";
import { AbsoluteLoader } from "../loader/Loader";
import scheduleInsertsStore from "../../stores/scheduleInsertsStore";

const Container = styled.div`
  border-top: 1px solid #F3F3F3;
  height: 90vh;
  ${scrollbars};
  position: relative;
`;

const LoadingScreen = styled.div`
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
  color: #979797;
`;

const TimeSlot = styled.div`
    height: 30px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #F3F3F3;
    font-size: 12px;
    box-sizing: border-box;
    &:first-child {
        border-top: 1px solid #F3F3F3;
    }
`;

const TimeSlots = styled.div`
    width: 54px;
    background: white;
    display: flex;
    flex-direction: column;
    ${props => (props.paddingTop ? `padding-top: ${props.paddingTop}px;` : "padding-top: 176px;")}
    flex-shrink: 0;
    border-right: 1px solid #F3F3F3;
    height: max-content;
    position: sticky;
    left: 0;
    z-index: 8;
`;

export const GeneralScheduleContentNew = observer((props: {
  schedule: any,
  isLoading: boolean,
  groupType?: any,
}) => {
  const { schedule, isLoading, groupType } = props;
  const [heightContainerActions, setHeightContainerActions] = useState(0);

  const timeSlots = () => {
    const date = moment().format("YYYY-MM-DD");
    const startDate = new Date(`${date} 08:00`);
    const endDate = new Date(`${date} 21:00`);

    const minimalTimeSlot = 15;
    const timeSlots = [];
    const timeSlotsCount = moment(endDate).diff(startDate, "minutes") / minimalTimeSlot;
    let slotIndex = 0;
    while (slotIndex < timeSlotsCount) {
      const momentTime = moment(startDate).add(minimalTimeSlot * slotIndex, "m");
      timeSlots.push(momentTime.format("HH:mm"));
      slotIndex += 1;
    }
    return timeSlots;
  }

  let paddingTopTimeSlots = 129;
  if (generalScheduleStore.settingsScheduleView?.doctorOrganization?.checked) {
    paddingTopTimeSlots += generalScheduleStore.settingsScheduleView.doctorOrganization.height;
  }
  if (generalScheduleStore.settingsScheduleView?.doctorSpecialization?.checked) {
    paddingTopTimeSlots += generalScheduleStore.settingsScheduleView.doctorSpecialization.height;
  }

  const handleChangeHeightContainerActions = () => {
    const containerActions =
      document.getElementsByClassName("general-page-new__container-actions")[0];
    setHeightContainerActions(containerActions.getBoundingClientRect().height);
  };

  useEffect(() => handleChangeHeightContainerActions(), [scheduleInsertsStore.enableMultipleAppointmentsMode]);

  useEffect(() => {
    window.addEventListener("resize", handleChangeHeightContainerActions);
    return () => window.removeEventListener("resize", handleChangeHeightContainerActions);
  }, [window.innerWidth, window.innerHeight]);

  return (
    <ScheduleInsertContextProvider>
      <ScheduleInsertContext.Consumer>
        {({ scheduleInsertModal }) => (
          <Container>
            {generalScheduleStore.isLoading && <AbsoluteLoader zIndex={5} />}
            <GeneralScheduleCardList heightContainerActions={heightContainerActions}>
              {schedule && schedule.length ? (
                <>
                  <TimeSlots paddingTop={paddingTopTimeSlots}>
                    <TimeSlot />
                    {timeSlots().map((time, i) => {
                      return (
                        <TimeSlot key={i}>
                          {time.split(':')[1] === "00" ? time : ''}
                        </TimeSlot>
                      );
                    })}
                  </TimeSlots>
                  <GeneralScheduleCardBlockList>
                    {schedule.map((card, i) => {
                      return (
                        <GeneralScheduleCardNew
                          groupType={groupType}
                          key={i}
                          cardData={card}
                        />
                      );
                    })}
                  </GeneralScheduleCardBlockList>
                </>
              ) : isLoading ? (
                <GeneralSchedulePlaceholderNew />
              ) : (
                <LoadingScreen>Расписание по выставленным фильтрам не найдено</LoadingScreen>
              )}
            </GeneralScheduleCardList>
            <ModalPortal>{scheduleInsertModal}</ModalPortal>
          </Container>
        )}
      </ScheduleInsertContext.Consumer>
    </ScheduleInsertContextProvider>
  );
});
