import { css } from 'styled-components';

export const scrollbars = css`
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-left: 1px solid rgba(220, 211, 210, 0.4);
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border: 4px solid transparent;
    background-color: rgba(0, 0, 0, 0.05);
    background-clip: content-box;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-out;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .1);
  }
`;
