// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { colors } from "../styleguide/colors";
import { PNormal } from "../styleguide/typography";

const containerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
  gap: 10px;
  opacity: ${(props) => props?.disabled && 0.7};
  :hover {
    opacity: 1;
    .MuiSvgIcon-root {
      &:not(.MuiSvgIcon-colorSuccess) {
        fill: ${colors.darkBlack};
      }
    }
  } 
  .MuiSvgIcon-root {
    &:not(.MuiSvgIcon-colorSuccess) {
      fill: ${colors.nearBlack};
    }
    opacity: ${props => props?.transparent_icon === "true" ? .5 : 1};
  }
`;

const LinkContainer = styled(Link)`
  ${containerStyles}
`;

const Container = styled.div`
  ${containerStyles}
`;

const Text = styled(PNormal)`
  line-height: 1.3;
  color: ${colors.black};
  border-bottom: 1px solid ${colors.alto};
  border: ${p => !p.underline && "none"};

  ${Container}:hover &,
  ${LinkContainer}:hover & {
    color: ${colors.black};
    border-color: ${colors.black};
  }
`;

export const AsideButton = ({ to, onClick, transparentIcon, disabled, label, icon }) => {
  // ToDo ! disabled не несет за собой логики !
  const Wrapper = to ? LinkContainer : Container;

  return (
    <Wrapper
      to={to}
      onClick={onClick}
      disabled={disabled}
      transparent_icon={transparentIcon}
    >
      {icon && icon}
      <Text underline={to} modifiers={"left"}>
        {label}
      </Text>
    </Wrapper>
  );
};
