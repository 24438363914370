import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class MedicalGenomics {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api/medical-genomics', null, null, API_URL);
  }

  createOrder({ folderId }) {
    return this.apiService.post(`orders`, { folder_id: folderId }, {});
  }

  updateOrder({ orderId, orderData }) {
    return this.apiService.patch(`orders/${orderId}`, {}, orderData);
  }

  sendOrder({ orderId }) {
    return this.apiService.post(`orders/${orderId}/send`, {}, {});
  }

  getOrderList(folderId) {
    return this.apiService.get(`orders`, { folder_id: folderId });
  }
}

export default new MedicalGenomics();
