// @flow
import React from "react";
import { Container, FormSection } from "../form/FormSection";
import { DecisionSupportFormSection } from "./DecisionSupportFormSection";
import styled from "styled-components";

const ExtendedContainer = styled(Container)`
  padding: 0 0 39px;
  pointer-events: none;
`;

export const ReadonlyDecisionSupportFormSection = (
  props: $Diff<React$ElementConfig<typeof FormSection>, { expand_by_default: any }>
) => {
  return (
    <DecisionSupportFormSection
      {...props}
      expand_by_default
      overrides={{
        Container: {
          component: ExtendedContainer
        },
        ArrowButton: {
          component: React.Fragment
        }
      }}
    />
  );
};
