// @flow
import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { colors } from "../styleguide/colors";
import { HeadingH4 } from "../styleguide/typography";
import type { Section } from "../../domain/entities/Section";
import arrowUp from "./icons/arrow-up.svg";
import o, { type ExtractOverridesProps } from "react-overrides";
import { Checkmark } from '../buttons/Checkmark';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

export const Container = styled(RoundedRectangleWithShadow)`
  padding: ${props => props.isFullContentSize ? "0" : "0 30px 30px"};

  opacity: ${props => !props.isOpen && props.isEmpty && "0.5"};
  @media (max-width: 768px) {
    padding: ${props => props.isFullContentSize ? "0" : "0 20px 20px"};
  }
  @media (max-width: 480px) {
    padding: ${props => props.isFullContentSize ? "0" : "0 15px 15px"};
  }
  padding-bottom: ${props => !props.isOpen && 0};
`;

const SectionHeadingH4 = styled(HeadingH4)`
  display: flex;
  align-items: center;
`

const HelpIconContainer = styled.div`
  display: inline-flex;
  color: rgba(0, 0, 0, 0.26);
  margin-left: 8px;
  
  svg {
    width: 20px;
    height: 20px;
  }
`;

const HelpTextContainer = styled.div`
  text-align: center;
  
`;

export const HeaderContainer = styled.div`
  border: none;
  background: none;
  outline: none;
  width: 100%;
  min-height: 70px;
  box-sizing: border-box;
  text-align: left;

  display: flex;
  align-items: center;
  padding: ${props => props.isFullContentSize ? "14px 30px 13px" : "14px 0 13px"};
  border-bottom: solid 1px ${props => (props.isOpen ? colors.transparentGray : "transparent")};
  cursor: ${props => (props.onClick ? "pointer" : "auto")};

  @media (max-width: 768px) {
    min-height: auto;
	  padding: ${props => props.isFullContentSize ? "14px 20px 13px" : "14px 0 13px"};
  }
	@media (max-width: 480px) {
	  padding: ${props => props.isFullContentSize ? "14px 15px 13px" : "14px 0 13px"};
	}
`;

export const TitleContainer = styled.div`
  flex: 1;

  ${HeaderContainer}:focus-visible & > * {
    color: ${colors.magenta};
  }
  @media screen and (max-width: 768px) {
    & * {
      font-size: 18px;
    }
  }
`;

export const ChildrenContainer = styled.div`
  margin-top: ${props => props.isFullContentSize ? "0" : "22px"};
  @media (max-width: 768px) {
    margin-top: ${props => props.isFullContentSize ? "0" : "15px"};
  }
  @media (max-width: 480px) {
    margin-top: ${props => props.isFullContentSize ? "0" : "10px"};
  }
`;

const ArrowButton = styled.div`
  width: 18px;
  height: 18px;
  border: none;

  background: url(${arrowUp});
  background-size: contain;

  transition: transform 0.2s;
  transform: ${props => !props.isOpen && "rotate(180deg)"};

  cursor: pointer;
`;

const ToolButtonContainer = styled.div`
  padding: ${props => (props.noToolButtonPadding ? 0 : "0 14px")};
  display: flex;

  & > * + * {
    margin-left: 22px;
  }
`;

const Overrides = {
  Container,
  ArrowButton,
  HeaderContainer,
  ChildrenContainer
};

export type FormSectionProps = {
  id?: string,
  helpText?: string,
  helpLink?: string,
  name: $ElementType<Section, "name">,
  isEmpty?: boolean,
  expand_by_default: $ElementType<Section, "expand_by_default">,
  children: React.Node,
  shouldExpand?: boolean,
  toolButton?: React.Node,
  showPrintCheckmark?: boolean,
  printCheckmarkChecked?: boolean,
  swapArrowAndCheckmark?: boolean,
  noToolButtonPadding?: boolean,
  overrides?: ExtractOverridesProps<typeof Overrides>
};

const PrintCheckmarkContainer = styled.div`
  pointer-events: all;
  margin-left: ${props => (props.swap ? "16px" : 0)};
`;

const PrintCheckmark = props => {
  const localStorageKey = "checkedSections";

  const setLocalStorage = value => {
    const rawCheckedSections = localStorage.getItem(localStorageKey);
    let checkedSections = {};
    if (rawCheckedSections) {
      checkedSections = JSON.parse(rawCheckedSections);
    }
    checkedSections[props.sectionId] = value;
    localStorage.setItem(localStorageKey, JSON.stringify(checkedSections));
  };

  const onClick = (value) => {
    setLocalStorage(value);
  };

  return (
    <PrintCheckmarkContainer swap={props.swap}>
      <Checkmark checked={props.checked} onClick={onClick} tooltip="Печатать для пациента" />
    </PrintCheckmarkContainer>
  );
};

export const FormSection = (props: FormSectionProps) => {
  const [isOpen, setOpen] = useState(props.expand_by_default);

  useEffect(() => {
    props.shouldExpand && setOpen(true);
  }, [props.shouldExpand]);

  useEffect(() => {
    setOpen(props.expand_by_default);
  }, [props.expand_by_default]);

  const checkmark = props.showPrintCheckmark && props.id && (
    <PrintCheckmark
      sectionId={props.id}
      swap={props.swapArrowAndCheckmark}
      checked={props.printCheckmarkChecked || false}
    />
  );

  return (
    <Container {...o} isOpen={isOpen} isEmpty={props.isEmpty} id={props.name} isFullContentSize={props.isFullContentSize}>
      <HeaderContainer
        isOpen={isOpen}
        isFullContentSize={props.isFullContentSize}
        aria-label={`${isOpen ? "Закрыть" : "Открыть"} секцию ${props.name}`}
        onClick={() => setOpen(!isOpen)}
        {...o}>
        <TitleContainer>
          <SectionHeadingH4>
            {props.name}
            {props.helpText && <HelpIconContainer>
              <Tooltip onClick={(ev) => {
                ev.stopPropagation();
                if (props.helpLink) {
                  window.open(props.helpLink, "_blank");
                }
                return false;
              }} title={
                <HelpTextContainer onClick={(ev) => {
                  ev.stopPropagation();
                  return false;
                }}>
                  {props.helpText}
                </HelpTextContainer>
              }>
                <HelpOutlineIcon color="primary"/>
              </Tooltip>
            </HelpIconContainer>
            }
          </SectionHeadingH4>
        </TitleContainer>
        {isOpen && props.toolButton && (
          <ToolButtonContainer
            onClick={e => e.stopPropagation()}
            noToolButtonPadding={props.noToolButtonPadding}>
            {props.toolButton}
          </ToolButtonContainer>
        )}
        {props.swapArrowAndCheckmark ? null : checkmark}
        <ArrowButton {...o} isOpen={isOpen} />
        {props.swapArrowAndCheckmark ? checkmark : null}
      </HeaderContainer>
      {isOpen && <ChildrenContainer {...o} isFullContentSize={props.isFullContentSize}>{props.children}</ChildrenContainer>}
    </Container>
  );
};
