// @flow
import * as React from "react";
import moment from "moment";
import styled from "styled-components";
import { withModifiers } from "with-modifiers";
import fileIcon from "./icons/file.svg";
import pencilIcon from "./icons/pencil.svg";
import { colors } from "../styleguide/colors";
import { TextView } from "./TextView";
import { READABLE_DATE_FORMAT } from "../../domain/services/field";

const Container = styled.div`
  position: relative;
  padding: 6px 40px 13px 48px;
  background: url(${fileIcon});
  background-size: 37px;
  background-position: 0 9px;
  background-repeat: no-repeat;
  margin-top: 25px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: border 0.15s ease-in-out;

  :hover {
    border: 1px solid ${colors.gray100};
  }
`;

const NameContainer = withModifiers({
  textOverflow: () => `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
})(
  modifier => styled.div`
    font-size: 14px;
    line-height: 14px;
    transition: color 0.15s ease-in-out;

    ${modifier}

    & span {
      color: ${colors.black};
      word-break: break-all;
    }

    ${Container}:hover & {
      & span {
        color: ${colors.magenta};
      }
    }
  `
);

const AttributesContainer = styled.div`
  margin-top: 2px;
  font-size: 12px;
  line-height: 12px;
  color: ${colors.gray500};
  opacity: 0.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > span {
    margin-right: 14px;
  }
`;

const PencilIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 16px;
  height: 16px;
  background: url(${pencilIcon});
  display: none;
  @media (max-width: 768px) {
    display: ${props => (props.editable ? "block" : "none")};;
  }

  ${Container}:hover & {
    display: ${props => (props.editable ? "block" : "none")};
  }
`;

type FilesFieldsetTypes = {
  id?: number,
  key?: string,
  name: string,
  date?: string,
  size?: number,
  file_url?: string,
  mime_type?: string,
  editable?: boolean,
  textOverflow?: boolean,
  owner?: string,
  onEdit?: ({
    id?: number,
    key?: string,
    name: string,
    date?: string,
    size?: number,
    file_url?: string,
    mime_type?: string,
    editable?: boolean
  }) => mixed
};

export const FilesFieldset = (props: FilesFieldsetTypes) => {
  const hasOverflow = props.textOverflow || false;
  const handleOnClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: "drawBackButton" }));
      window.open(props.file_url, "_self");
    } else {
      window.open(props.file_url, "_blank");
    }
  }

  return (
    <Container
      onClick={handleOnClick}>
      <NameContainer modifiers={[hasOverflow && "textOverflow"]}>
        <TextView>{props.name}</TextView>
      </NameContainer>
      <AttributesContainer>
        {props.date && <TextView>{moment(props.date).format(READABLE_DATE_FORMAT)}</TextView>}
        {props.owner && <TextView>{props.owner}</TextView>}
      </AttributesContainer>
      <PencilIcon
        editable={props.editable}
        onClick={e => {
          e.stopPropagation();
          props.onEdit && props.onEdit(props);
        }}
      />
    </Container>
  );
};
