// @flow
import { useState, useEffect } from "react";

export const usePosition = (ref: { current: ?HTMLElement }): [ClientRect | null, () => mixed] => {
  const [position, setPosition] = useState(null);

  const update = () => {
    if (!ref.current) {
      return;
    }
    setPosition(ref.current.getBoundingClientRect());
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(update);
    resizeObserver.observe(ref.current);
    window.addEventListener("scroll", update);
    update();
    return () => {
      if (!ref.current) {
        return;
      }
      resizeObserver.unobserve(ref.current);
      window.removeEventListener("scroll", update);
    };
  }, [ref.current]);

  return [position, update];
};
