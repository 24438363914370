import * as React from "react";
import { ButtonCheckboxGroup } from "../form-elements/ButtonCheckboxGroup";
import { possibleValuesToButtonGroupItems } from "../appointment-widgets/possibleValuesToButtonGroupItems";

// @flow

import type { TFieldValue } from "../../stores/appointmentFieldValues";
import type { LayoutFieldValue } from "../../domain/entities/Appointment";
import type { Prescription } from "../../domain/value-objects/Prescription";

function toString(v: Prescription | string): ?string {
  return typeof v === "string" ? v : undefined;
}

export const getTextArrayValue = (
  fieldValue: ?TFieldValue,
  layoutFieldValue?: LayoutFieldValue
): Array<string> => {
  if (fieldValue && fieldValue.value) {
    if (!(fieldValue.value instanceof Array)) {
      return [fieldValue.value.toString()];
    }

    return fieldValue.value.map(toString).filter(Boolean);
  }

  if (layoutFieldValue && layoutFieldValue.value instanceof Array) {
    return layoutFieldValue.value.map(toString).filter(Boolean);
  }

  return [];
};

export const CheckboxGroupWidget = ({ field, onChange, tabIndex, layoutFieldValue }) => {
  const value = getTextArrayValue(field, layoutFieldValue);

  const handleChange = key => {
    const newValue = value.indexOf(key) >= 0 ? value.filter(item => item !== key) : [...value, key];
    onChange({
      field_type: field.field_type,
      field_id: field.id,
      value: newValue
    });
  };

  return (
    <ButtonCheckboxGroup
      tabIndex={tabIndex}
      checked={value}
      items={possibleValuesToButtonGroupItems(field.possible_values)}
      onChange={handleChange}
    />
  );
};
