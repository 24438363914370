import * as React from "react";

export const IconMicOff = ({style}) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g clipPath="url(#clip0_101_8603)">
        <path d="M18.5 10.5C18.0858 10.5 17.75 10.8358 17.75 11.25V12.75C17.75 14.1424 17.1969 15.4777 16.2123 16.4623C15.2277 17.4469 13.8924 18 12.5 18C11.1076 18 9.77226 17.4469 8.78769 16.4623C7.80312 15.4777 7.25 14.1424 7.25 12.75V11.25C7.25 10.8358 6.91421 10.5 6.5 10.5C6.08579 10.5 5.75 10.8358 5.75 11.25V12.75C5.75079 14.4098 6.36309 16.0111 7.46991 17.248C8.57674 18.4849 10.1005 19.2706 11.75 19.455V21H9.5C9.08579 21 8.75 21.3358 8.75 21.75C8.75 22.1642 9.08579 22.5 9.5 22.5H15.5C15.9142 22.5 16.25 22.1642 16.25 21.75C16.25 21.3358 15.9142 21 15.5 21H13.25V19.455C14.8995 19.2706 16.4233 18.4849 17.5301 17.248C18.6369 16.0111 19.2492 14.4098 19.25 12.75V11.25C19.25 10.8358 18.9142 10.5 18.5 10.5Z" fill="var(--svg-fill, #000)"/>
        <path d="M12.5 16.5C13.4946 16.5 14.4484 16.1049 15.1517 15.4017C15.8549 14.6984 16.25 13.7446 16.25 12.75V5.25C16.25 4.25544 15.8549 3.30161 15.1517 2.59835C14.4484 1.89509 13.4946 1.5 12.5 1.5C11.5054 1.5 10.5516 1.89509 9.84835 2.59835C9.14509 3.30161 8.75 4.25544 8.75 5.25V12.75C8.75 13.7446 9.14509 14.6984 9.84835 15.4017C10.5516 16.1049 11.5054 16.5 12.5 16.5ZM10.25 5.25C10.25 4.65326 10.4871 4.08097 10.909 3.65901C11.331 3.23705 11.9033 3 12.5 3C13.0967 3 13.669 3.23705 14.091 3.65901C14.5129 4.08097 14.75 4.65326 14.75 5.25V12.75C14.75 13.3467 14.5129 13.919 14.091 14.341C13.669 14.7629 13.0967 15 12.5 15C11.9033 15 11.331 14.7629 10.909 14.341C10.4871 13.919 10.25 13.3467 10.25 12.75V5.25Z" fill="var(--svg-fill, #000)"/>
        <path d="M21.8433 3.63647L3.84326 20.6365" stroke="var(--svg-fill, #000)" strokeWidth="1.5" strokeLinecap="round"/>
      </g>
      <defs>
        <clipPath id="clip0_101_8603">
          <rect width="24" height="24" fill="var(--svg-fill, #000)" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
};
