import { makeAutoObservable } from 'mobx';

class AppointmentCreateStore {
  copyAppointment = null;

  constructor() {
    makeAutoObservable(this);
  }

  changeCopyAppointment = (value) => {
    this.copyAppointment = value;
  }
}

export default new AppointmentCreateStore();
