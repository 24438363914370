// @flow

import * as React from "react";
import styled from "styled-components";
import { useStore } from "effector-react";
import { useTrigger } from "react-use-trigger";
import useReactRouter from "use-react-router";
import _ from "lodash";

import { tokenStore } from "../../stores/auth";
import type { Prescription } from "../../domain/value-objects/Prescription";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import { getPatientFullName } from "../../domain/services/patient";
import {
  formatAppointmentDateToDateYearWithWords,
  formatAppointmentDateToDateWithWords
} from "../../domain/services/appointments";
import {
  AppointmentPrintableHeaderLayout,
  AppointmentPrintableHeaderItem
} from "../appointment-print/AppointmentPrintableHeader";
import { AppointmentPrintLayout } from "../appointment-print/AppointmentPrintLayout";
import { Logo } from "../appointment-print/Logo";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import { PNormal, HeadingH4 } from "../styleguide/typography";
import { AppointmentSign } from "../appointment-print/AppointmentSign";
import { useOrganization } from "../appointment-print/useOrganization";
import { useContacts } from "../appointment-print/useContacts";
import { useAfterPrint } from "../appointment-print/useAfterPrint";
import { useBeforePrint } from "../appointment-print/useBeforePrint";
import { useLogo } from "../appointment-print/useLogo";
import { ReadablePrescriptionTable } from "../appointment-page/ReadablePrescriptionTable";
import { getPrescriptionFromSection } from "../../domain/services/prescription";
import { PrescriptionTableCell, PrescriptionTableCellFixed } from "./PrescriptionTableCell";
import * as R from "ramda";
import { useMemo } from "react";
import { getDrugs } from "./getDrugs";
import moment from "moment";
import { useApiRequest } from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

const Container = styled.table`
  padding: 68px 85px;
  width: 1520px;
  overflow-x: hidden;

  ${p => (p.breakAfter ? "@media print {page-break-after: always;}" : null)}
`;

const Title = styled(HeadingH4)`
  margin: 37px 0 34px;
`;

const HistoryTableContainer = styled.div`
  width: 1520px;
  overflow: hidden;
`;

const PrintablePrescriptionTableCellFixed = styled(PrescriptionTableCellFixed)`
  height: 51px;
`;

const PrintablePrescriptionTableCell = styled(PrescriptionTableCell)`
  height: 51px;
`;

const readablePrescriptionTableOverrides = {
  PrescriptionTableCellFixed: {
    component: PrintablePrescriptionTableCellFixed
  },
  Cell: {
    props: {
      overrides: {
        PrescriptionTableCell: {
          component: PrintablePrescriptionTableCell
        }
      }
    }
  },
  ScrollDetector: {
    component: React.Fragment
  },
  TableContainer: {
    component: React.Fragment
  }
};

const filterFirstValues = R.filter(
  R.where({
    table_column: table_column => table_column < 19
  })
);

const filterSecondValues = R.filter(
  R.where({
    table_column: table_column => table_column >= 19 && table_column < 38
  })
);

export const getPrescriptionTableLength = (prescriptions: Array<string | Prescription>): number => {
  const prescriptionsMoments = prescriptions
    .map((prescription: string | Prescription) =>
      typeof prescription === "string" ? null : moment(prescription.date)
    )
    .filter(Boolean);

  const maxPrescriptionDate = moment.max(prescriptionsMoments);
  const minPrescriptionDate = moment.min(prescriptionsMoments);

  return maxPrescriptionDate.diff(minPrescriptionDate, "days") + 1;
};

export const PrintablePrescriptionTablePage = () => {
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();

  const patientAppointmentsTrigger = useTrigger(PatientAppointmentsRefreshTrigger);
  const appointmentResult = useApiRequest(async () => {
    if (params.appointment_id) {
      return await apiv2.appointments.getAppointmentById(params.appointment_id);
    }
  }, [patientAppointmentsTrigger, token, params.appointment_id]);
  const layoutResult = useApiRequest(async () => {
    if (appointmentResult.data && appointmentResult.data.layout.id) {
      return await apiv2.appointments.getLayout(parseInt(appointmentResult.data && appointmentResult.data.layout.id));
    }
  }, [appointmentResult.data && appointmentResult.data.layout.id]);

  const { logoEl, isLogoLoaded, setLogoLoaded } = useLogo();

  const appointment = appointmentResult.data;
  const layout = layoutResult.data;

  const organization = useOrganization(appointment);
  const contacts = useContacts(organization);
  useBeforePrint(
    (isLogoLoaded && appointment && layout && contacts && !appointment.in_progress) || false
  );
  useAfterPrint((appointment && `/appointment/${appointment.id}`) || "");

  const section = _.find(
    layout && layout.sections,
    item => item.id === parseInt(params.section_id)
  );

  const prescription = section && getPrescriptionFromSection(section);

  const fieldValuesMap = _.keyBy(
    appointment && appointment.layout_field_values,
    item => item.field
  );

  const prescriptions =
    (prescription &&
      fieldValuesMap[prescription.id].value instanceof Array &&
      fieldValuesMap[prescription.id].value) ||
    [];
  const drugs = useMemo(() => getDrugs((prescriptions: any)), [prescriptions]);

  if (!appointment || !layout || !contacts) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  if (appointment.in_progress) {
    return <PNormal>Прием не завершен</PNormal>;
  }

  if (!section) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  const logo = <Logo ref={logoEl} onLoad={() => setLogoLoaded(true)} />;

  const header = (
    <AppointmentPrintableHeaderLayout logo={logo}>
      <AppointmentPrintableHeaderItem
        label="Врач"
        content={getLastNameWithInitials(appointment.doctor)}
      />
      <AppointmentPrintableHeaderItem
        label="Дата"
        content={formatAppointmentDateToDateYearWithWords(appointment.date)}
      />
      <AppointmentPrintableHeaderItem
        label="Пациент"
        content={getPatientFullName(appointment.medical_file.patient)}
      />
      <AppointmentPrintableHeaderItem label="Контакты" content={contacts} />
    </AppointmentPrintableHeaderLayout>
  );

  const title = <Title>Таблица назначения препаратов</Title>;

  const firstValuesArray = filterFirstValues(prescriptions);
  const secondValuesArray = filterSecondValues(prescriptions);

  const firstValues = firstValuesArray.length > 0 && (
    <HistoryTableContainer>
      <ReadablePrescriptionTable
        rangeLength={getPrescriptionTableLength(firstValuesArray)}
        value={firstValuesArray}
        drugs={drugs}
        overrides={readablePrescriptionTableOverrides}
      />
    </HistoryTableContainer>
  );

  const secondValues = secondValuesArray.length > 0 && (
    <HistoryTableContainer>
      <ReadablePrescriptionTable
        startOffset={getPrescriptionTableLength(secondValuesArray)}
        value={secondValuesArray}
        drugs={drugs}
        overrides={readablePrescriptionTableOverrides}
      />
    </HistoryTableContainer>
  );

  const sign = (
    <AppointmentSign
      date={formatAppointmentDateToDateWithWords(appointment.date)}
      doctor={{
        name: getLastNameWithInitials(appointment.doctor),
        regalia: appointment.doctor.regalia || null
      }}
    />
  );

  return (
    <>
      <Container breakAfter={secondValues}>
        <AppointmentPrintLayout
          isHorizontal
          header={header}
          title={title}
          values={firstValues}
          sign={sign}
        />
      </Container>
      {secondValues && (
        <Container>
          <AppointmentPrintLayout
            isHorizontal
            header={header}
            title={title}
            values={secondValues}
            sign={sign}
          />
        </Container>
      )}
    </>
  );
};
