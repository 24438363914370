// @flow
import React, { useState } from "react";
import { AddGroupButton } from "./AddGroupButton";
import { AddGroupContainer, NewGroupInputContainer } from "./AddGroupContainer";
import { GroupTitle } from "./GroupTitle";
import { PointsContainer } from "./PointsContainer";
import { GroupContainer } from "./GroupContainer";
import { NewGroupInput } from "./NewGroupInput";
import { AddingHOC } from "./AddingHOC";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import { getAppointment_id } from "../../domain/entities/Appointment";
import nanoid from "nanoid";
import { getChecklistGuid } from "../../domain/entities/Checklist";
import { PointTemplate } from "./PointTemplate";
import { CheckboxLabel } from "./CheckboxLabel";
import { useChecklist, useChildrenChecklist } from "./useChecklist";
import {
  appointmentExaminationsStore,
  dragExaminations,
  // processExaminationDateRange
} from "../../stores/decisionSupportStores";
import { useList, useStore } from "effector-react";
import { PointTitle } from "./PointTitle";
import { ExaminationsSuggestProvider } from "./ExaminationsSuggestProvider";
import { tokenStore } from "../../stores/auth";
import { PointAdding } from "./PointAdding";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GroupActionsLayout } from "./GroupActionsLayout";
import { DeleteButton } from "./DeleteButton";
import { useConfirm } from "../alert-warning/useConfirm";
import { AlertWarning } from "../alert-warning/AlertWarning";
import { HeadingH3 } from "../styleguide/typography";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { ModalPortal } from "../modal/ModalPortal";
import { EditButton } from "./EditButton";
import { GroupEditProvider } from "./GroupEditProvider";
import { GroupMoveButton } from "./GroupMoveButton";
import * as R from "ramda";
import styled from "styled-components";
import { useSubscribe, dispatchEvent } from "../../utils/useEvents";
import removeIcon from "./icons/remove.svg";
import { Container } from "./CommonHintedButton";
import removeHoverIcon from "./icons/remove-hover.svg";
import { colors } from "../styleguide/colors";
import { DateRange } from "./DateRange";
import { PointHintExamination } from "./PointHintExamination";
import { AddCommentButton } from "./AddCommentButton";
import { CommentsModal } from "./CommentsModal";
import { CommentForm } from "./CommentForm";
import { HintedButtonsContainer } from "./HintedButtonsContainer";
import { CommentsModalProvider } from "./CommentsModalProvider";
import apiv2 from '../../apiv2';

const CheckboxLabelWithMargin = styled(CheckboxLabel)`
  margin-right: 12px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const DeleteButtonIcon = styled.div`
  width: 17px;
  height: 20px;
  background: url(${removeIcon});
  background-size: cover;

  position: relative;
  right: 5px;
  top: -2px;

  ${Container}:hover & {
    background: url(${removeHoverIcon});
    background-size: cover;
  }
`;

const Ellipse = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 0 8px;
  flex-shrink: 0;
  background: ${colors.darkGray};
`;

const proposeExamination = name => {
  const token = tokenStore.getState();
  if (!token) {
    return;
  }
  apiv2.examinations.propose(name);
};

const AppointmentExaminationsChildren = props => {
  const { store, guid, appointment, updateChecked, inEdit } = props;
  const {
    store: checklistStore,
    addChecklist,
    toggleChecklist,
    setChecklistIsChecked,
    setComment,
    removeComment,
    setDateRange,
    removeDateRange,
    changeCount,
    remove
  } = useChildrenChecklist(store, guid);

  const [preserveHoverPoint, setPreserveHoverPoint] = useState(null);

  const onToggleOpenDateRangePicker = index => {
    setPreserveHoverPoint(index);
  };

  const onCheckboxClick = checklistItem => {
    const checklistGuid = getChecklistGuid(checklistItem)
    toggleChecklist(checklistGuid);

    const checklistStoreState = checklistStore.getState();

    const allChecked = checklistStoreState.reduce((acc, next) => acc && next.is_checked, true);

    dispatchEvent("checklistChange", {
      guid: guid,
      value: allChecked
    });

    dispatchEvent("examinationCheckChange", {
      item: checklistItem,
      value: !checklistItem.is_checked
    });
  };

  const onRemoveItem = checklistItem => {
    const checklistGuid = getChecklistGuid(checklistItem);

    dispatchEvent("examinationCheckChange", {
      item: checklistItem,
      value: false
    });

    remove(checklistGuid);
  };

  useSubscribe("parentChecklistCheck", ({ guid: changedGuid, value }) => {
    if (changedGuid === guid) {
      const checklist = checklistStore.getState();
      checklist.forEach(item => {
        setChecklistIsChecked({ guid: getChecklistGuid(item), value });
        dispatchEvent("examinationCheckChange", {
          item: item,
          value: value
        });
      });
    }
  });

  useSubscribe("removeParentChecklist", ({ guid: changedGuid }) => {
    if (changedGuid === guid) {
      const checklist = checklistStore.getState();
      checklist.forEach(item => {
        dispatchEvent("examinationCheckChange", {
          item: item,
          value: false
        });
      });
    }
  });

  const handleChangeCount = (value, checklistItem) => {
    changeCount({ value, id: getChecklistGuid(checklistItem) });
    updateChecked(store.getState());
  };

  const list = checklistStore.getState().map((checklistItem, index) => (
    <Draggable key={checklistItem.guid} draggableId={checklistItem.guid.toString()} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <PointTemplate
            icon={
              <CheckboxLabel
                type="button"
                modifiers={(!checklistItem.is_checked && "disabled") || undefined}
                onClick={() => onCheckboxClick(checklistItem)}
              />
            }
            isChecked={checklistItem.is_checked}
            headerBottom={
                <>
                  <HintedButtonsContainer>
                    {checklistItem.is_checked && (
                      <>
                        <DateRange
                          isLeftContainer
                          useFixedPosition={inEdit}
                          value={checklistItem.dateRange}
                          onSetDateRange={value => {
                            setDateRange({value, id: getChecklistGuid(checklistItem)});
                            dispatchEvent("examinationDateRangeChange", {
                              item: checklistItem,
                              value: value
                            });
                          }}
                          onResetDateRange={() => removeDateRange(getChecklistGuid(checklistItem))}
                          hideIcon
                          containerNotPadding
                          defaultTitle="Добавить дату"
                          modifiers="averageSize"
                          color="darkGray"
                          onToggleOpen={open => onToggleOpenDateRangePicker(open ? index : null)}
                        />
                        <Ellipse />
                      </>
                    )}
                    <CommentsModalProvider>
                      {({ isModalShow, showModal, hideModal }) => (
                        <>
                          {checklistItem.comment ? (
                            <PointHintExamination
                              disabled={!checklistItem.is_checked}
                              onClick={() => {
                                if (checklistItem.is_checked) {
                                  showModal();
                                }
                              }}
                            >
                              {checklistItem.comment}
                            </PointHintExamination>
                          ) : checklistItem.is_checked ? (
                            <AddCommentButton
                              modifiers="averageSize"
                              color="darkGray"
                              containerNotPadding
                              hideIcon
                              onClick={showModal}
                            />
                          ) : null}
                          <ModalPortal>
                            <CommentsModal isShow={isModalShow} hide={hideModal}>
                              <CommentForm
                                defaultValue={checklistItem.comment || ""}
                                onClose={hideModal}
                                onSubmit={value => {
                                  setComment({ value, id: getChecklistGuid(checklistItem) });
                                  dispatchEvent("examinationCommentChange", {
                                    item: checklistItem,
                                    value: value
                                  });
                                  hideModal();
                                }}
                                onDelete={() => {
                                  removeComment(getChecklistGuid(checklistItem));
                                  hideModal();
                                }}
                                isAutofocus
                              />
                            </CommentsModal>
                          </ModalPortal>
                        </>
                      )}
                    </CommentsModalProvider>
                  </HintedButtonsContainer>
                </>
            }
            buttons={
              <DeleteButton
                icon={DeleteButtonIcon}
                containerNotPadding={true}
                withoutTitle
                onClick={() => {
                  onRemoveItem(checklistItem)
                }}
              />
            }
            rightBlock={ null
              // <ServicePriceInput
              //   isActive={checklistItem.is_checked}
              //   count={checklistItem.count}
              //   handleChange={_debounce(value => handleChangeCount(value || 1, checklistItem), 300)}
              //   price={checklistItem.services?.[0]?.price}
              // />
            }
            title={<PointTitle disabled={!checklistItem.is_checked} text={checklistItem.name} />}
            isDraggable
            buttonsAlwaysVisible={true}
            preserveHover={index === preserveHoverPoint}
            dragHandleProps={provided.dragHandleProps}
          />
        </div>
      )}
    </Draggable>
  ));

  return (
    <>
      <PointsContainer>
        <Droppable type="examinations" droppableId={guid}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {list}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </PointsContainer>
      <PointAdding
        title="Добавить обследование"
        SuggestProvider={ExaminationsSuggestProvider}
        select={item => {
          const exam = {
            guid: nanoid(),
            name: item.name,
            is_checked: true,
            examination_id: item.id,
            count: 1,
            services: item.services,
            dateRange: null
          };
          addChecklist(exam);
          dispatchEvent("examinationCheckChange", {
            item: exam,
            value: true
          });
        }}
        propose={value => {
          addChecklist({
            guid: nanoid(),
            name: value,
            services: [],
            count: 1,
            is_checked: true
          });
          proposeExamination(value);
        }}
      />
    </>
  );
};

const RemoveWrapper = props => {
  const { onConfirm, children } = props;
  const removeConfirm = useConfirm();

  const handleRemove = async () => {
    if (!removeConfirm.requestConfirm) {
      return;
    }
    try {
      const confirmResult = await removeConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      onConfirm();
    } catch (e) {
      console.log(e);
    }
  };

  const removeWarning = (
    <AlertWarning
      isNeedConfirm={removeConfirm.isNeedConfirm}
      sendConfirm={removeConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Вы точно хотите удалить группу?</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
      )}
    />
  );

  return (
    <>
      {children(handleRemove)}
      <ModalPortal>{removeWarning}</ModalPortal>
    </>
  );
};

const ChecklistCountProvider = props => {
  const { checklistStore } = props;
  const checklistCountStore = checklistStore.map(R.length);
  const checklistCount = useStore(checklistCountStore);

  return props.children(checklistCount);
};

export const AppointmentExaminations = (props: {
  appointment: AppointmentWithDetails,
  updateChecked: () => mixed
}) => {
  const { appointment, checklist, store, inEdit } = props;
  const appointment_id = getAppointment_id(appointment);

  const defaultExaminationsChecklists = checklist ? checklist :
    appointment.examination_checklist && appointment.examination_checklist.items;
  const examinationsStore = store ? store : appointmentExaminationsStore;

  const {
    addChecklist,
    store: checklistStore,
    toggleChecklist,
    setChecklistIsChecked,
    remove,
    rename,
    reorder
  } = useChecklist(examinationsStore, appointment_id, defaultExaminationsChecklists);
  useStore(examinationsStore);

  const toggleAllItems = guid => {
    toggleChecklist(guid);

    const checklistStoreState = checklistStore.getState();

    const checklist = checklistStoreState.find(checklist => checklist.guid === guid);

    if (checklist) {
      dispatchEvent("parentChecklistCheck", {
        guid: guid,
        value: checklist.is_checked
      });
    }
  };

  const onRemoveChecklist = (checklist) => {
    const checklistGuid = getChecklistGuid(checklist);

    dispatchEvent("removeParentChecklist", {
      guid: checklistGuid
    });

    remove(checklistGuid);
  };

  useSubscribe("checklistChange", ({ guid, value }) => {
    setChecklistIsChecked({ guid, value });
    props.updateChecked(checklistStore.getState());
  });

  return (
    <>
      <DragDropContext
        onDragEnd={result =>
          dragExaminations({
            appointmentId: appointment_id,
            default: defaultExaminationsChecklists,
            from: {
              groupGuid: result.source.droppableId,
              index: result.source.index
            },
            to: {
              groupGuid: result.destination.droppableId,
              index: result.destination.index
            }
          })
        }>
        {checklistStore.getState().map((checklist, index) => (
          <GroupContainer key={checklist.guid}>
            <GroupEditProvider defaultText={checklist.name}>
              {({ value, onChange, isEdit, setIsEdit }) => (
                <GroupTitle
                  title={value}
                  onChange={onChange}
                  isEdit={isEdit}
                  checkbox={
                    <CheckboxLabelWithMargin
                      type="button"
                      modifiers={(!checklist.is_checked && "disabled") || undefined}
                      onClick={() => toggleAllItems(getChecklistGuid(checklist))}
                    />
                  }
                  onBlur={() => {
                    setIsEdit(false);
                    if (!value) {
                      onChange(checklist.name);
                      return;
                    }
                    rename({ guid: getChecklistGuid(checklist), newName: value });
                  }}>
                  <GroupActionsLayout
                    moveDown={
                      <ChecklistCountProvider checklistStore={checklistStore}>
                        {checklistCount =>
                          index < checklistCount - 1 && (
                            <GroupMoveButton
                              modifiers="down"
                              onClick={() => reorder({ start: index, end: index + 1 })}
                            />
                          )
                        }
                      </ChecklistCountProvider>
                    }
                    moveUp={
                      index > 0 && (
                        <GroupMoveButton onClick={() => reorder({ start: index, end: index - 1 })} />
                      )
                    }
                    edit={<EditButton type="button" onClick={() => setIsEdit(true)} />}
                    remove={
                      <RemoveWrapper onConfirm={() => {
                        onRemoveChecklist(checklist);
                      }}>
                        {handler => <DeleteButton onClick={handler} />}
                      </RemoveWrapper>
                    }
                  />
                </GroupTitle>
              )}
            </GroupEditProvider>

            <AppointmentExaminationsChildren
              appointment={appointment}
              updateChecked={props.updateChecked}
              store={checklistStore}
              guid={getChecklistGuid(checklist)}
              defaultValue={checklist.items}
              inEdit={inEdit}
            />
          </GroupContainer>
        ))}
      </DragDropContext>
      <AddingHOC>
        {({ value, onChange, isAdding, setAdding }) => {
          const adding = () => {
            const trimmedValue = value.trim();
            if (trimmedValue && trimmedValue.length) {
              addChecklist({
                guid: nanoid(),
                name: value,
                is_checked: true
              });
            }
            setAdding(false);
          };
          return (
            <>
              {isAdding && (
                <NewGroupInputContainer>
                  <NewGroupInput
                    value={value}
                    onChange={onChange}
                    onBlur={adding}
                    onEnter={adding}
                  />
                </NewGroupInputContainer>
              )}
              <AddGroupContainer>
                <AddGroupButton onClick={() => setAdding(true)} />
              </AddGroupContainer>
            </>
          );
        }}
      </AddingHOC>
    </>
  );
};
