import React, { useEffect, useRef, useState } from "react";
import { ContainerComponent } from "../dynamic-form/styled/Container.styled";
import { LineChartMUI } from "./components/LineChartMUI";
import { BarChartMUI } from "./components/BarChartMUI";
import { GaugeChartMUI } from "./components/GaugeChartMUI";
import { HorizontalBarChartMUI } from "./components/HorizontalBarChartMUI";
import { LabelWithFiltersModal } from "../widgets-components-common/LabelWithFiltersModal";
import styled from "styled-components";
import { Box, Typography } from "@mui/material";

const LegendLabel = styled.div`
    font-size: 12px;
`;

const LegendColor = styled.div`
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => props.color};
  flex-shrink: 0;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DynamicComponentLegends = styled.div`
  height: 60px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const DynamicComponentContainer = styled.div`
    height: ${props => `calc(100% - ${props.heightLabel}px)`};
`;

const IncorrectDataContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const TYPE_COMPONENTS = {
  bar: ({ label, data, options, margin, interval }) => <BarChartMUI label={label} margin={margin} data={data} options={options} interval={interval} />,
  line: ({ label, data, options, margin, interval }) => <LineChartMUI label={label} margin={margin} data={data} options={options} interval={interval} />,
  gauge: ({label, data, options, margin}) => <GaugeChartMUI label={label} margin={margin} data={data} options={options} />,
  horizontalBar: ({label, data, options, margin}) => <HorizontalBarChartMUI label={label} margin={margin} data={data} options={options} />
}

const Legend = ({ data, isCollapse = false }) => {
  return data.map((item, index) => {
    const label = item.label?.split('-') || [];

    return (
      <LegendItem key={`${item.label}__${index}`}>
        <LegendColor color={item.color}></LegendColor>
        <LegendLabel>{isCollapse ? label[label.length - 1] : item.label} </LegendLabel>
      </LegendItem>
    )
  })
}

export const ChartComponent = ({
  type,
  label,
  options,
  data,
  nameDiagram,
  filtersData,
  filtersFields,
  action,
  textButtonFilter,
  margin,
  filtersDataDiagram,
  interval,
  errors = [],
  fullHeight = false
}) => {
  const setLocalStorageDataDiagram = (newFilters) => {
    localStorage.setItem(nameDiagram, JSON.stringify(newFilters));
  }
  const getLocalStorageFilterDiagram = () => {
    if (localStorage.getItem(nameDiagram)) {
      return JSON.parse(localStorage.getItem(nameDiagram));
    }
  };


  const [dynamicComponent, setDynamicComponent] = useState();
  const [isInitialized, setIsInitialized] = useState(false);
  const [dataFilters, setDataFilters] = useState( {});
  const [heightLabel, setHeightLabel] = useState(fullHeight ? 0 : 35);
  const refLabel = useRef(null);


  const submitHandler = (newFilters, callback) => {
    setDataFilters(newFilters);
    callback();
  }

  useEffect(() => {
    setDataFilters(filtersData);
  }, [filtersData]);

  useEffect(() => {
    if (filtersData) {
      const filterDiagramLocaleStorage = getLocalStorageFilterDiagram();
      const filters = filterDiagramLocaleStorage || filtersData || {};
      setDataFilters(filters);
    }
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (isInitialized && dataFilters) {
      if (filtersData) {
        setLocalStorageDataDiagram(dataFilters);
      }
      if (action) {
        action(dataFilters)
      }
    }
  }, [dataFilters]);

  useEffect(() => {
    if (refLabel.current) {
      setHeightLabel(refLabel.current.getBoundingClientRect().height);
    }
  }, [refLabel.current]);

  useEffect(() => {
    if (data?.datasets) {
      data.datasets = data.datasets.map((item) => {
        if (filtersDataDiagram?.isCollapse) {
          const label = item.label.split('-');
          item.label = label[label.length - 1];
        }
        return item;
      })
    }
    setDynamicComponent(
      TYPE_COMPONENTS[dataFilters?.type || type](
        { label: filtersFields ? '' : label, data, options, margin, interval: interval || 'day' }
      ))
  }, [data, type]);

  if (errors.length || (data?.labels && !data.labels.length) || (data?.datasets && !data.datasets.length)) {
    return (
      <IncorrectDataContainer>
        <Typography variant="subtitle2">{errors.length ? "Установлены некорректные фильтры" : "Данные не найдены"}</Typography>
        {!errors.length ? (
          <Typography variant="caption">Попробуйте изменить фильтры</Typography>
        ) : (
          <Typography variant="caption" color="error">
            Проверьте фильтры
            {errors.map((error, index) => {
              return `${index === 0 ? ":" : ""} ${error}${index === errors.length - 1 ? ";" : ","}`;
            })}
          </Typography>
        )}
      </IncorrectDataContainer>
    )
  }

  return (
    <ContainerComponent fullHeight={!!label}>
      {filtersFields && (
        <LabelWithFiltersModal ref={refLabel} fullWidth={true} type={"filter"} filtersData={dataFilters}
          filtersFields={filtersFields} label={label} textButtonFilter={textButtonFilter}
          submitHandler={submitHandler}
        />
      )}
      <DynamicComponentContainer heightLabel={heightLabel} className="chart-component">
        {dynamicComponent}
      </DynamicComponentContainer>
      {!!data?.datasets?.length && (
        <DynamicComponentLegends className="chart-component-legends">
          <Legend data={!data.legend ? data.datasets : data.legend} isCollapse={filtersDataDiagram?.isCollapse} />
        </DynamicComponentLegends>
      )}
    </ContainerComponent>
  );
};
