//@flow
import * as React from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { colors } from "../styleguide/colors";
import { ButtonCaps } from "../styleguide/typography";
import PlusImg from "./icons/plus.svg";

const Label = styled.label`
  width: 100%;
  height: 79px;
  margin-top: 30px;
  border: 1px dashed ${rgba(colors.black, 0.3)};
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  @media (max-width: 768px) {
    height: 50px;
  }
`;

const Icon = styled.div`
  width: 17px;
  height: 17px;
  margin-right: 10px;

  background: url(${PlusImg}) no-repeat;
  background-size: contain;
`;

const FileInput = styled.input.attrs(() => ({
  type: "file"
}))`
  display: none;
`;

export const FileUpload = (props: {| onAddFile: FileList => mixed |}) => (
  <Label>
    <Icon />
    <ButtonCaps modifiers={["left", "uppercase"]}>Добавить файл</ButtonCaps>
    <FileInput multiple onChange={event => props.onAddFile(event.target.files)} />
  </Label>
);
