// @flow
import * as React from "react";
import styled from "styled-components";

import { ClinicLogo } from "../../clinicLogo/ClinicLogo";
import {RoundedRectangleWithShadow} from "../../styleguide/RoundedRectangleWithShadow";
import {AuthForm} from "./AuthForm";

const Container = styled(RoundedRectangleWithShadow)`
  width: 610px;
  padding: 63px 0 101px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  
  @media screen and (max-width: 768px) {
    width: calc(100% - 24px);
    box-sizing: border-box;
  }
`;

export const Auth = () => {
  return (
    <Container>
      <ClinicLogo isShowName />
      <AuthForm/>
    </Container>
  )
};
