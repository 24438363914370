import styled from "styled-components";

import { colors } from "../../styleguide/colors";

export const Item = styled.span`
  background: ${colors.concrete};
  padding: 6.5px 8px;
  border-radius: 4px;
  color: ${colors.black};
  font-size: 14;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;
