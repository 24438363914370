// @flow
import { setShortcutsPointer, shortcutsPointer, allShortcuts } from "./shortcutsShared";
import { isShortcutForServices } from "../../domain/value-objects/TShortcuts";
import * as R from "ramda";
import type { TShortcutsForServices } from "../../domain/value-objects/TShortcuts";
import { createEvent, forward, merge } from "effector";
import {
  openShortcutsCreate,
  openShortcutsForField,
  shortcutsSidebarSectionStore
} from "./shortcutsSections";

export const isCurrentServices = shortcutsPointer.map<*>(
  pointer => (pointer && pointer.type === "services") || false
);

export const servicesShortcuts = allShortcuts.map<Array<TShortcutsForServices>>(
  R.filter(isShortcutForServices)
);

export const isHaveServicesShortcuts = servicesShortcuts.map<*>(shortcuts => shortcuts.length > 0);

export const isShortcutsSectionOpen = (): boolean =>
  shortcutsSidebarSectionStore.thru<boolean>(store => store.getState() !== null);

export const createShortcuts = createEvent<void>();
export const openShortcuts = createEvent<void>();

forward({
  from: createShortcuts,
  to: openShortcutsCreate
});
forward({
  from: openShortcuts,
  to: openShortcutsForField
});

forward({
  from: merge([createShortcuts, openShortcuts]).map(() => ({ type: "services" })),
  to: setShortcutsPointer
});
