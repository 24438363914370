//@flow
import * as R from "ramda";
import { useEffect, useState } from "react";
import { useStore } from "effector-react";
import type { TAppointment_id } from "../../domain/entities/Appointment";
import type { TField_id } from "../../domain/entities/Field";
import {
  appointmentFieldValuesStore,
  getFieldValueDescription
} from "../../stores/appointmentFieldValues";

export const useErrorHighlighting = (
  value: any,
  appointment_id: TAppointment_id,
  field_id: TField_id,
  isValid: any => boolean,
  isRequired: boolean,
) => {
  const [isFocused, setIsFocused] = useState();
  const [error, setError] = useState({value: false});

  const errorStore = useStore(
    appointmentFieldValuesStore.map(
      R.compose(
        field => {
          if (!field) {
            if (value) {
              return isRequired && (isValid ? !isValid(value) : false);
            }
            return isRequired && true;
          }
          if (!field.value && isRequired) {
            return true
          }
          return isValid ? !isValid(field.value) : false;
        },
        getFieldValueDescription(appointment_id, field_id)
      )
    )
  );
  useEffect(() => {
    setError({ value: errorStore });
  }, [errorStore]);

  return {
    error: isFocused ? false : error,
    handleFocus: () => setIsFocused(true),
    handleBlur: () => setIsFocused(false)
  };
};
