import { makeAutoObservable } from "mobx";
import apiv2 from "../apiv2";


class BonusSchemaStore {
  bonusSchemaData = null;
  currentLevel = null;
  nextLevel = null;
  showLevelBonusPopup = false;

  constructor() {
    makeAutoObservable(this);
  }

  changeShowLevelBonusPopup(value) {
    this.showLevelBonusPopup = value;
  }

  async getBonusSchemaData() {
    try {
      const response = await apiv2.doctors.getBonusSchemaData();
      if (response.currentLevel) {
        this.bonusSchemaData = response;
        this.bonusSchemaData.levels.forEach((item, index) => {
          if (item.current) {
            this.currentLevel = item;
            this.nextLevel = this.bonusSchemaData.levels[index + 1] ? this.bonusSchemaData.levels[index + 1] : null;
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  getCurrentLevel() {
    return this.bonusSchemaData.levels.find((item) => item.current);
  }

}

export default new BonusSchemaStore();
