// @flow
import styled from "styled-components";
import { withModifiers } from "with-modifiers";
import { colors } from "../styleguide/colors";
import { HeadingH2, PLarge } from "../styleguide/typography";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 97px;
  height: 97px;
  background: url(${props => props.avatar}) no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 100%;
  flex-shrink: 0;
  @media screen and (max-width: 1200px) {
    width: 67px;
    height: 67px;
  }
`;

export const AvatarPlaceholder = styled.div`
  width: 97px;
  height: 97px;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  line-height: 40px;
  color: #7d7d7d;
  text-transform: uppercase;
  border-radius: 100%;
  flex-shrink: 0;

  @media screen and (max-width: 1200px) {
    width: 67px;
    height: 67px;
    font-size: 30px;
  }
`;

export const InnerContainer = styled.div`
  margin-left: 27px;
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-left: 15px;
    ${HeadingH2} {
      font-size: 28px;
    }
    ${PLarge} {
      font-size: 16px;
    }
  }
`;

export const TextContainer = styled.div`
  margin-top: 9px;
  @media screen and (max-width: 1200px) {
    margin-top: 0;
  }
`;

export const ContainerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = withModifiers({
  bold: () => `
        font-weight: 500;
    `,
  color: () => `
        color: ${colors.magenta};
    `
})(
  modifier => styled(PLarge)`
    ${modifier};
  `
);
