// @flow
import * as React from "react";
import styled from "styled-components";

import { PSmall } from "../styleguide/typography";
import { PrimaryButtonContainer } from "../buttons/PrimaryButton";

const Form = styled.form`
  padding-top: 50px;
  @media (max-width: 769px) {
    padding-top: 20px;
  }
  @media (max-width: 480px) {
    padding-top: 10px;
  }
`;

export const Note = styled(PSmall)`
  position: relative;
  margin-top: 25px;
  opacity: 0.6;
  :before {
    content: "*";
    position: absolute;
    top: 0;
    left: -8px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 21px;
  align-items: center;
  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const OverridedPrimaryButtonContainer = styled(PrimaryButtonContainer)`
  width: auto;
  padding: 0 40px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const FileEditFormLayout = (props: { children: React$Node, buttons: React$Node }) => {
  return (
    <Form>
      {React.Children.map(props.children, field => field)}
      <ButtonContainer>{props.buttons}</ButtonContainer>
      <Note modifiers="1">
        При изменении даты, файл переместится в списке анализов в соответствии с новой датой.
      </Note>
    </Form>
  );
};
