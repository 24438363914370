// @flow
import styled from "styled-components";
import * as React from "react";
import { PSmall } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

export const RequiredField = styled.span`
  color: ${colors.watermelon};
  position: absolute;
  left: -6px;
  top: -2px;
`;

const LabelContainer = styled.label`
  position: relative;
  & > * {
    opacity: 0.6;
  }
  ${RequiredField} {
    opacity: 1;
  }
`;

const ChildrenContainer = styled.div`
  margin-top: 8px;
`;

export const FieldGroupItemFieldSet = (props: { label: string, required: boolean, children: React.Node }) => (
  <fieldset>
    <LabelContainer>
      {props.required && (<RequiredField>*</RequiredField>)}
      <PSmall modifiers="1">
        {props.label}
      </PSmall>
    </LabelContainer>
    <ChildrenContainer>{props.children}</ChildrenContainer>
  </fieldset>
);
