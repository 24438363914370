import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Stories {
	apiService;
	constructor() {
		this.apiService = new ApiService('/api', null, null, API_URL);
	}

	getStories() {
		return this.apiService.get(`stories`, {});
	}

	getAnalyticsStories(filters) {
		return this.apiService.post(`common/metric/analytics/stories`, {}, filters);
	}
}

export default new Stories();
