// @flow
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { styled as styledMaterial } from "@mui/material/styles";
import { rgba } from "polished";
import Menu from "@mui/material/Menu";
import { Typography } from "@mui/material";
import { DefaultButton } from "../buttons/DefaultButton";
import { colors } from '../styleguide/colors';
import printIcon from "./icons/print.svg";

const Icon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${printIcon});
`;

const IconContainer = styled.div`
  margin-left: -2px;
  margin-right: 6px;
`;

const HeaderTypography = styledMaterial(Typography)(() => ({
  textTransform: "uppercase",
  fontSize: "13px",
  fontWeight: 500,
  paddingInline: "15px",
  color: colors.darkGray,
}));

const LinkContainer = styled(Link)`
  display: flex;
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 20px;
  color: ${colors.darkBlack};
  &:hover {
    background: ${rgba(colors.lightMagenta, 0.05)};
  }
`;

export const PrintButtonDropDown = ({ menuItems = [], withHeader = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;
  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <DefaultButton
        onClick={handleClick}
        overrides={{
          Icon: {
            component: Icon
          },
          IconContainer: {
            component: IconContainer
          }
        }}>
        печать
      </DefaultButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {!withHeader ? (
          menuItems.map(({ to, title }, index) => (
            <LinkContainer key={`${index}_${to}`} to={to}>{title}</LinkContainer>
          ))
        ) : (
          menuItems.map(({ header, links }, index) => (
            <React.Fragment key={`${index}_${header}`}>
              <HeaderTypography>{header}</HeaderTypography>
              {links.map(({ to, title }, index) => (
                <LinkContainer key={`${index}_${to}`} to={to}>{title}</LinkContainer>
              ))}
            </React.Fragment>
          ))
        )}
      </Menu>
    </>
  );
};
