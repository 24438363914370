import React, { useEffect, useState } from "react";
import moment from "moment";
import { observer } from "mobx-react";
import { SERVER_DATE_TIME_FORMAT } from "../../../utils/dates";
import styled from "styled-components";
import { GridLayoutComponent } from "../grid-layout/GridLayoutComponent";
import { Accordion, AccordionSummary } from "@mui/material";
import { DynamicForm } from "../dynamic-form/DynamicForm";
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LabelWithFiltersModal } from "./LabelWithFiltersModal";
import { getLocalStorage, removeItemLocalStorage, setLocalStorage } from "../../../utils/localStorageUtils";
import organizationsStore from "../../../stores/organizationsStore";

const DiagramsContainer = styled.div`
    box-sizing: border-box;
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    max-width: 100%;
    
    @media(max-width: 576px) {
        padding: 30px 10px;
    }
`;

const DEFAULT_FILTERS_DATA_CREATE_WIDGET = {
  date_start: moment().set({ hour: 0, minute: 0, second: 0, month: moment().get('month') - 1}).format(SERVER_DATE_TIME_FORMAT),
  date_end: moment().set({ hour: 23, minute: 59, second: 59 }).format(SERVER_DATE_TIME_FORMAT),
  interval: 'week',
  type: 'bar',
};

export const WidgetsComponent = observer((props: {
  optionsField: Array,
  enableExpandAll?: boolean,
  getSubRows?: (id: number) => mixed,
  dashboardLabel?: string,
  generateWidgets: () => ({}),
  fieldsWidgets: () => ({}),
  nameLocalStorage: string,
  defaultData?: () => ({})
}) => {
  const defaultValues = {
    fields: [
      {
        type: 'select',
        name: 'widget',
        label: 'Название виджета',
        xs: 12,
        options: props.optionsField
      }
    ],
    data: {
      widget: null
    }
  }
  const [widgets, setWidgets] = useState([]);
  const [widgetsLocalStorage, setWidgetsLocalStorage] = useState([]);
  const [widgetsFields, setWidgetsFields] = useState(defaultValues.fields);
  const [widgetsData, setWidgetsData] = useState(defaultValues.data);
  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);
  const [loadingFilterWidgets, setLoadingFilterWidgets] = useState(false);
  const [enableEditingMode, setEnableEditingMode] = useState(false);
  const handleEnableEditingMode = () => setEnableEditingMode(!enableEditingMode);

  const resetWidgetData = () => {
    setWidgetsFields(defaultValues.fields);
    setWidgetsData(defaultValues.data);
  }

  const onCloseHandler = (propsWidget) => {
    const newWidgets = [...widgets];
    let indexWidget = null;
    newWidgets.forEach((widget, index) => {
      if (widget.key === propsWidget.keyWidget) {
        indexWidget = index;
      }
    });
    newWidgets.splice(indexWidget, 1);
    setWidgets(newWidgets);
    const newWidgetLocalStorage = widgetsLocalStorage.filter((widget) => widget.key !== propsWidget.keyWidget)
    setWidgetsLocalStorage(newWidgetLocalStorage)
    setLocalStorage(props.nameLocalStorage, newWidgetLocalStorage);
    removeItemLocalStorage(propsWidget.localeStorageName + propsWidget.keyWidget + location.pathname.replaceAll('/', ''));
  }

  let filtersFields = [
    {
      type: 'date',
      typePicker: 'dateTime',
      name: 'date_start',
      label: 'Дата от',
      xs: 12,
      sm: 6,
      md: 4,
    },
    {
      type: 'date',
      typePicker: 'dateTime',
      name: 'date_end',
      label: 'до',
      xs: 12,
      sm: 6,
      md: 4,
    },
    {
      type: 'select',
      name: 'interval',
      label: 'Интервал',
      xs: 12,
      sm: 6,
      md: 4,
      options: [
        {value: 'day', label: 'По дням'},
        {value: 'week', label: 'По неделям'},
        {value: 'month', label: 'По месяцам'},
        {value: 'year', label: 'По годам'},
      ]
    },
  ]

  useEffect(() => {
    (async () => {
      const localFilterParams = getLocalStorage('widgetFilterParams');
      await organizationsStore.getOrganizations();
      const newFilters = localFilterParams ? localFilterParams : {...DEFAULT_FILTERS_DATA_CREATE_WIDGET};
      delete newFilters.type;
      setFiltersDataDiagram(newFilters)
      setLocalStorage('widgetFilterParams', newFilters);
      resetWidgetData();
      const localStorageData = getLocalStorage(props.nameLocalStorage) || props.defaultData?.map((item) => {
        if (!item.filters?.date_start) {
          item.filters.date_start = DEFAULT_FILTERS_DATA_CREATE_WIDGET.date_start;
        }
        if (!item.filters?.date_end) {
          item.filters.date_end = DEFAULT_FILTERS_DATA_CREATE_WIDGET.date_end;
        }
        if (!item.filters?.interval) {
          item.filters.interval = DEFAULT_FILTERS_DATA_CREATE_WIDGET.interval;
        }
        return item;
      });
      if (localStorageData) {
        const defaultWidgets = [];
        localStorageData.forEach((item) => {
          defaultWidgets.push(props.generateWidgets[item.name](item.filters, item.key, item.grid));
        })
        setWidgetsLocalStorage(localStorageData)
        setWidgets(defaultWidgets);
      }
      setIsInitialized(true);
    })()
  }, []);

  const submitFilters = (filters) => {
    setFiltersDataDiagram({...filters});
    setLocalStorage('widgetFilterParams', filters);
    const newWidgets = [];
    widgetsLocalStorage.forEach((item) => {
      newWidgets.push(props.generateWidgets[item.name](item.filters, item.key, item.grid, filters));
    })
    setWidgets(newWidgets)
  }

  const addWidget = (filters, callback) => {
    callback();
    resetWidgetData();
    const componentFilters = {...filters};
    delete componentFilters.widget;
    let positionY = -9999999;
    let heightLastWidget = 0;
    const layoutData = getLocalStorage(`widgetsComponentsGridLayout${props.nameLocalStorage}`);
    const breakpoint = getLocalStorage(`widgetsComponentsGridLayout${props.nameLocalStorage}Breakpoint`) || 'lg'
    layoutData[breakpoint].forEach((item) => {
      if (item.y > positionY) {
        positionY = item.y;
        heightLastWidget = item.h;
      }
    })
    const newIndex = widgets.length ? parseInt(widgets[widgets.length - 1].key.split('w')[1]) + 1 : 0;
    const availableHandles = ["s", "w", "e", "n", "sw", "nw", "se"];
    const dataGrid = {
      i: "w" + newIndex,
      x: 0,
      y: positionY + heightLastWidget,
      w: 6,
      h: 3,
      resizeHandles: availableHandles
    };
    const newWidgets = [...widgets, props.generateWidgets[filters.widget](componentFilters, `w${newIndex}`, dataGrid)]
    setWidgets(newWidgets)
    const newWidgetLocalStorage = [...widgetsLocalStorage, {
      name: filters.widget,
      filters: componentFilters,
      key: `w${newIndex}`,
      grid: dataGrid,
    }];
    setWidgetsLocalStorage(newWidgetLocalStorage)
    setLocalStorage(props.nameLocalStorage, newWidgetLocalStorage);
  }

  const submitHandlerWidgets = async (filters, callback) => {
    if (!filters.widget) {
      return;
    }
    if (widgetsData.widget !== filters.widget) {
      setLoadingFilterWidgets(true);
      const addFilters = await props.fieldsWidgets[filters.widget]();
      setLoadingFilterWidgets(false);
      if (addFilters.length) {
        setWidgetsFields([...defaultValues.fields, ...addFilters])
        setWidgetsData({...DEFAULT_FILTERS_DATA_CREATE_WIDGET, ...filters });
      } else {
        addWidget(filters, callback);
      }
    } else {
      addWidget(filters, callback);
    }
  }

  const watcherOnChangeData = async (filters) => {
    if (!filters.widget) {
      return resetWidgetData();
    }

    setLoadingFilterWidgets(true);
    const addFilters = await props.fieldsWidgets[filters.widget]();
    if (addFilters.length) {
      setWidgetsFields([...defaultValues.fields, ...addFilters])
      setWidgetsData({...DEFAULT_FILTERS_DATA_CREATE_WIDGET, ...filters });
    }
    setLoadingFilterWidgets(false);
  }

  return (
      <DiagramsContainer>
        {isInitialized && (
          <>
            <LabelWithFiltersModal
              type="widget"
              loading={loadingFilterWidgets}
              filtersData={widgetsData}
              filtersFields={widgetsFields}
              callbackOnCloseFilter={resetWidgetData}
              label={props.dashboardLabel}
              textButtonFilter={"Добавить"}
              submitHandler={submitHandlerWidgets}
              debounceTime={0}
              watcherOnChangeData={watcherOnChangeData}
              fullWidth={true}
              wrap={true}
              editingMode={{
                isShow: !!widgets.length,
                isActive: enableEditingMode,
                handleEnableEditingMode
              }}
            />
            <Accordion style={{
              width: '100%'
            }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Глобальные фильтры
              </AccordionSummary>
              <AccordionDetails>
                <DynamicForm textButton="Применить" data={{...filtersDataDiagram}} submitHandler={submitFilters} fields={filtersFields} hiddenButtonCancel={true} notNeedSaveMoveToHistory={true} />
              </AccordionDetails>
            </Accordion>
            <GridLayoutComponent onCloseHandler={onCloseHandler} enableEditingMode={enableEditingMode} keyLocalStorage={`widgetsComponentsGridLayout${props.nameLocalStorage}`}>
              {widgets}
            </GridLayoutComponent>
          </>
        )}
    </DiagramsContainer>
  )
});
