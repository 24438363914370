import apiv2 from '../apiv2';
import DynamicTableStore from "./dynamicTableStore";
import { action, makeObservable, observable } from "mobx";

class SurveyCorporatePortalResultStore extends DynamicTableStore {
  surveyResultId = null;
  surveyResultData = null;
  statusResult = [
    {
      label: 'Новый результат',
      value: 'new'
    },
    {
      label: 'В работе',
      value: 'inprogress'
    },
    {
      label: 'Обработан',
      value: 'done'
    },
    {
      label: 'Просмотрен',
      value: 'viewed'
    }
  ];

  constructor() {
    super(apiv2.surveyResult.getSurveyResultsForIdList.bind(apiv2.surveyResult), ['id'], undefined);
    makeObservable(this, {
      setSurveyResultId: action,
      surveyResultId: observable,
      surveyResultData: observable,
    })
  }

  async getResultById() {
    try {
      const response = await apiv2.surveyResult.getSurveyResultById(this.surveyResultId);
      this.surveyResultData = response;
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async postSurveyResult(data) {
    try {
      await apiv2.surveyResult.postSurveyResult([data]);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  setSurveyResultId(value) {
    this.surveyResultId = value;
  }

}

export default new SurveyCorporatePortalResultStore();
