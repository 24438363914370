//@flow
import * as React from "react";
import styled from "styled-components";

import { PNormal, PXSmall } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import FileImg from "./icons/file-pink.svg";
import AnalysisImg from "./icons/analysis.svg";
import UltrasoundScanImg from "./icons/ultrasoundScan.svg";

const Container = styled.div`
  width: 100%;
  min-width: 295px;
  min-height: 64px;
  padding: 8px 10px 14px 4px;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  max-width: 334px;

  display: flex;

  :hover {
    border: 1px solid ${colors.gray100};
  }
  @media screen and (max-width: 1600px) {
    min-width: 220px;
  }
`;

const Picture = styled.div`
  width: 39px;
  height: 39px;

  background: url(${props => props.icon}) no-repeat;
  background-size: contain;

  flex-shrink: 0;
`;

const RightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

const TextContainer = styled.div`
  padding: 0 10px;
`;

const ToolsContainer = styled.div`
  display: flex;
  transition: color 0.15s ease-in-out;
  * + * {
    margin-left: 10px;
  }
  @media (min-width: 769px) {
    opacity: 0;
    ${Container}:hover & {
      opacity: 1;
    }
  }
`;

export const FileToolButton = styled.button`
  width: 16px;
  height: 16px;
  background: url(${props => props.icon}) no-repeat;
  background-size: contain;
  border: none;
  outline: none;

  flex-shrink: 0;
  cursor: pointer;
`;

const InfoText = styled(PXSmall)`
  color: ${colors.gray500};
  line-height: 1.53;
`;

const InfoContainer = styled.div`
  display: flex;
  * + * {
    margin-left: 10px;
  }
`;

const Title = styled(PNormal)`
  transition: color 0.15s ease-in-out;
  word-break: break-all;
  font-size: 14px;

  ${Container}:hover & {
    color: ${colors.magenta};
  }
`;

export const FileLayout = (props: {
  date: string,
  size?: string,
  iconType?: "file" | "analysis" | "ultrasound",
  title: string,
  tools?: React$Node,
  onDownload: () => mixed
}) => {
  return (
    <Container onClick={props.onDownload}>
      <Picture
        icon={
          props.iconType === "analysis"
            ? AnalysisImg
            : props.iconType === "ultrasound"
            ? UltrasoundScanImg
            : FileImg
        }
      />
      <RightContainer>
        <InnerContainer>
          <TextContainer>
            <Title modifiers={"left"}>{props.title}</Title>
            <InfoContainer>
              <InfoText modifiers={"left"}>{props.date}</InfoText>
              {props.size && <InfoText modifiers={"left"}>{props.size}</InfoText>}
            </InfoContainer>
          </TextContainer>
          <ToolsContainer onClick={e => e.stopPropagation()}>{props.tools}</ToolsContainer>
        </InnerContainer>
      </RightContainer>
    </Container>
  );
};
