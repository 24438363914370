// @flow
import * as React from "react";
import styled from "styled-components";

import { PSmall } from "../styleguide/typography";
import DownloadImg from "./download.svg";

const StyledLink = styled.a`
  text-decoration: none;
  opacity: 0.6;

  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  width: 18px;
  height: 18px;
  background: url(${DownloadImg}) no-repeat;
  background-size: contain;

  margin-left: 6px;
`;

export const DownloadTemplate = (props: {| href: string |}) => (
  <StyledLink download href={props.href} target="_blank">
    <PSmall modifiers={"1"}>Скачать шаблон</PSmall>
    <Icon />
  </StyledLink>
);
