// @flow
import { useEffect } from "react";

export const useBeforePrint = (condition: boolean, id: mixed) => {
  useEffect(() => {
    if (process.env.DISABLE_SHOW_PRINT_DIALOG_ONLOAD || id === 1055718) {
      return;
    }

    if (condition) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ html: window.document.body.innerHTML, print: true }));
      } else {
        window.print();
      }
    }
  }, [condition, id]);
};
