import { observer } from "mobx-react";
import BonusMarkupIcon from "../../../assets/icons/bonusMarkupIcon.inline.svg";
import BonusStretchingSilverIcon from "../../../assets/icons/bonusStretchingSilverIcon.inline.svg";
import BonusStretchingGoldIcon from "../../../assets/icons/bonusStretchingGoldIcon.inline.svg";
import BonusStretchingPlatinumIcon from "../../../assets/icons/bonusStretchingPlatinumIcon.inline.svg";
import BonusStretchingBrilliantIcon from "../../../assets/icons/bonusStretchingBrilliantIcon.inline.svg";
import CalendarIcon from "../../../assets/icons/calendarIcon.inline.svg";
import React, { Suspense, useEffect, useState } from "react";
import BonusUsersIcon from "../../../assets/icons/bonusUsersIcon.inline.svg";
import debounce from 'lodash.debounce';
import {Canvas, useThree} from '@react-three/fiber'
import {
  BonusPaper,
  FlexBlock,
  BonusSubtitle, FlexBlockGapMini, BonusText
} from "./bonusSchema.styled";
import styled from "styled-components";
import * as moment from "moment";
import { withModifiers } from "with-modifiers";
import bonusSchemaStore from "../../../stores/bonusSchemaStore";
import { ModelStand } from "./ModelStand";
import {Environment, PerspectiveCamera} from "@react-three/drei";
import {Box} from "@mui/material";
import BonusHelpIcon from "../../../assets/icons/bonusHelpIcon.inline.svg";
import MuiTooltip from "@mui/material/Tooltip";

const BonusModelCointainer = styled.div`
    height: 110px;
    width: 75px;
    position: absolute;
    left: ${props => props.left};
    transform: translate(-50%, 50%);
    top: -57px;
    z-index: 4;
    canvas {
        //height: 230px !important;
        //width: 40px !important;
    }
`;

const BonusChartPatientsCointainer = styled.div`
    width: 100%;
    position: relative;
    padding-top: 100px;
`;

const BonusRoadMarkup = styled(BonusMarkupIcon)`
    position: absolute;
    right: 0;
    top: 0;
`;

const BonusRoadStretchingSilver = styled(BonusStretchingSilverIcon)`
    position: absolute;
    right: 0;
    top: -34px;
`;

const BonusRoadStretchingGold = styled(BonusStretchingGoldIcon)`
    position: absolute;
    right: 0;
    top: -34px;
`;

const BonusRoadStretchingPlatinum = styled(BonusStretchingPlatinumIcon)`
    position: absolute;
    right: 0;
    top: -34px;
`;

const BonusRoadStretchingBrilliant = styled(BonusStretchingBrilliantIcon)`
    position: absolute;
    right: 0;
    top: -34px;
`;

const BonusRoads = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 0;
`;

const BonusUsersRoadIcon = styled(BonusUsersIcon)`
    width: 16px;
    height: 16px;
`;

const BonusCurrentPatients = styled.div`
    border-radius: 10px;
    background-color: #0288D1;
    padding: 8px;
    font-size: 20px;
    line-height: 11px;
    position: absolute;
    left: ${props => props.left};
    transform: translateX(-50%);
    bottom: -9px;
    z-index: 3;
    color: #FFFFFF;
    
    &:before {
        content: '';
        position: absolute;
        top: -32px;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 30px;
        display: block;
        background-color: #0288D1;
    }
`;

const BonusLineLevels = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  top: -22px;
  z-index: 1;
`;

const BonusLineLevel = withModifiers({
  bronze: () => `
    background-color: #C3946F;
  `,
  silver: () => `
    background-color: #ACADB0;
  `,
  gold: () => `
    background-color: #D5B371;
  `,
  platinum: () => `
    background-color: #8D9DA8;
  `,
  brilliant: () => `
    background-color: #6CB6DA;
  `,
  pink: () => `
    background-color: #BD0060;
  `,
  active: () => `
    box-shadow: 0px 2px 8px 0px rgba(2, 136, 209, 0.40);
  `
})(
  modifier => styled.div`
    background-color: #ACADB0;
    height: 28px;
    padding: 4px 16px;
    position: relative;
    width: ${props => props.widthLevel}px;
    flex-shrink: 0;
    &:first-child {
        border-radius: 100px 0 0 100px;
    }  
      
    &:last-child {
        border-radius: 0 100px 100px 0;
    }
      
    ${modifier}
`);

const ROAD_STRETCHING_LEVELS = {
  bronze: {
    levels: ['bronze'],
    component: <BonusRoadStretchingSilver />
  },
  silver: {
    levels: ['bronze', 'silver'],
    component: <BonusRoadStretchingGold />
  },
  gold: {
    levels: ['bronze', 'silver', 'gold'],
    component: <BonusRoadStretchingPlatinum />
  },
  platinum: {
    levels: ['bronze', 'silver', 'gold', 'platinum'],
    component: <BonusRoadStretchingBrilliant />
  },
  brilliant: {
    levels: [],
    component: null
  },
}


export const BonusSchemaChartPatients = observer(() => {
  const [leftCurrentPatients, setLeftCurrentPatients] = useState();
  const [currentPatients, setCurrentPatients] = useState(0);
  const [onePatientWidth, setOnePatientWidth] = useState();
  const activeLevel = bonusSchemaStore.currentLevel.name;

  const setWidth = (_currentPatients) => {
    if (document.getElementsByClassName('bonus-chart-patients-container')[0]) {
      const width = document.getElementsByClassName('bonus-chart-patients-container')[0].offsetWidth / bonusSchemaStore.bonusSchemaData.levels[bonusSchemaStore.bonusSchemaData.levels.length - 1].max_count
      setOnePatientWidth(width);
      // setTimeout(() => {
        setLeftCurrentPatients(width * _currentPatients);
      // },100)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPatients((oldValue) => {
        if (oldValue + 1 >= bonusSchemaStore.bonusSchemaData.consultationsCount) {
          clearInterval(interval);
          setWidth(bonusSchemaStore.bonusSchemaData.consultationsCount);
          return bonusSchemaStore.bonusSchemaData.consultationsCount;
        }
        setWidth(oldValue + 1);
        return oldValue + 1;
      });
    }, 1000 / 30);
  }, []);
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setWidth(bonusSchemaStore.bonusSchemaData.consultationsCount)
    }, 0);

    window.addEventListener('resize', debouncedResizeHandler);
    return () => {
      debouncedResizeHandler.cancel();
      window.removeEventListener('resize', debouncedResizeHandler);
    };
  }, [document.getElementsByClassName('bonus-chart-patients-container')[0]]);
  const [mX, setmX] = useState(0)
  const [mY, setmY] = useState(1.1)
  const [mZ, setmZ] = useState(6)
  const [mX2, setmX2] = useState(0.2)
  const [mY2, setmY2] = useState(0)
  const [mZ2, setmZ2] = useState(0)
  const [mX3, setmX3] = useState(90)
  const [mY3, setmY3] = useState(0)
  const [mZ3, setmZ3] = useState(49)

  return (
    <>
      <BonusPaper>
        {/*<input type={"range"} step={0.1} min={-5} max={5} value={mX} onInput={(ev) => setmX(parseFloat(ev.target.value))}/>*/}
        {/*<input type={"range"} step={0.1} min={-5} max={5} value={mY} onInput={(ev) => setmY(parseFloat(ev.target.value))}/>*/}
        {/*<input type={"range"} step={0.1} min={-5} max={10} value={mZ} onInput={(ev) => setmZ(parseFloat(ev.target.value))}/>*/}
        {/*<div>*/}
        {/*  mX = {mX}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  mY = {mY}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  mZ = {mZ}*/}
        {/*</div>*/}
        {/*<input type={"range"} step={0.1} min={-5} max={5} value={mX2} onInput={(ev) => setmX2(parseFloat(ev.target.value))}/>*/}
        {/*<input type={"range"} step={0.1} min={-5} max={5} value={mY2} onInput={(ev) => setmY2(parseFloat(ev.target.value))}/>*/}
        {/*<input type={"range"} step={0.1} min={-5} max={5} value={mZ2} onInput={(ev) => setmZ2(parseFloat(ev.target.value))}/>*/}
        {/*<div>*/}
        {/*  mX2 = {mX2}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  mY2 = {mY2}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  mZ2 = {mZ2}*/}
        {/*</div>*/}

        {/*<input type={"range"} step={0.1} min={-5} max={5} value={mX3} onInput={(ev) => setmX3(parseFloat(ev.target.value))}/>*/}
        {/*<input type={"range"} step={0.1} min={-5} max={5} value={mY3} onInput={(ev) => setmY3(parseFloat(ev.target.value))}/>*/}
        {/*<input type={"range"} step={0.1} min={-5} max={5} value={mZ3} onInput={(ev) => setmZ3(parseFloat(ev.target.value))}/>*/}
        {/*<div>*/}
        {/*  mX3 = {mX3}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  mY3 = {mY3}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  mZ3 = {mZ3}*/}
        {/*</div>*/}
        <FlexBlock>
          <CalendarIcon />
          <div>
            <BonusSubtitle>
              Сегодня
              <MuiTooltip
                  componentsProps={{
                    tooltip: { sx: { background: "#ffffff!important" } }
                  }}
                  style={{paddingLeft: 6}}
                  title={'Ваш пациентопоток - количество оказанных приёмов с выставленной услугой на текущий день (приемы, в которых услуга не выставлена - не учитываются в расчете)'}
              >
                <Box sx={{ display: "flex", cursor: "pointer" }}>
                  <BonusHelpIcon />
                </Box>
              </MuiTooltip>
            </BonusSubtitle>
            <div>{moment().format('DD MMMM YYYY')}</div>
          </div>
        </FlexBlock>
        <BonusChartPatientsCointainer className={'bonus-chart-patients-container'}>
          <BonusModelCointainer left={leftCurrentPatients + 'px'}>
            <Canvas>
              <PerspectiveCamera position={[0, 1.1, 6]} rotation={[0.2,0,0]} makeDefault />
              {/*<ModelStand x={90} y={0} z={49}/>*/}
              <ModelStand run={currentPatients !== bonusSchemaStore.bonusSchemaData.consultationsCount}/>
              <Environment files="/Sunset.hdr" />
            </Canvas>
          </BonusModelCointainer>
          <BonusCurrentPatients left={leftCurrentPatients + 'px'}>{currentPatients}</BonusCurrentPatients>
          <BonusRoads>
            {bonusSchemaStore.bonusSchemaData.levels.map((item, index) => (
              <BonusLineLevel widthLevel={onePatientWidth * (item.max_count - item.min_count + 1)} key={item.name} modifiers={'pink'}>
                {(!!ROAD_STRETCHING_LEVELS[item.name].component && index !== bonusSchemaStore.bonusSchemaData.levels.length - 1) && (
                  <>
                    <BonusRoadMarkup />
                    {ROAD_STRETCHING_LEVELS[item.name].levels.includes(activeLevel) && (
                      <>
                        {ROAD_STRETCHING_LEVELS[item.name].component}
                      </>
                    )}
                  </>
                )}
              </BonusLineLevel>
            ))}
          </BonusRoads>
          <BonusLineLevels>
            {bonusSchemaStore.bonusSchemaData.levels.map((item) => (
              <BonusLineLevel widthLevel={onePatientWidth * (item.max_count - item.min_count + 1)} key={item.name} className={`bonus-chart-patients-${item.name}`} modifiers={[item.name, bonusSchemaStore.currentLevel.name === item.name && 'active']}>
                <FlexBlockGapMini modifiers={'right'}>
                  <BonusUsersRoadIcon />
                  <BonusText fontWeight="500">
                    {item.max_count}
                  </BonusText>
                </FlexBlockGapMini>
              </BonusLineLevel>
            ))}
          </BonusLineLevels>
        </BonusChartPatientsCointainer>
      </BonusPaper>
    </>
  )
})
