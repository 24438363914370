// @flow
import * as React from "react";
import styled from "styled-components";
import { LinearGradientBackground } from "../styleguide/LinearGradientBackground";
import { Login } from "../login/Login";
import { ComponentFabric } from "../component-fabric/ComponentFabric";
import Telemed from "../telemed";
import { AvailableApplicationFlavors } from '../../utils/getApplicationFlavor';

const Container = styled(LinearGradientBackground)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginPage = () => {
  return (
    <Container>
      <ComponentFabric flavors={{
        [AvailableApplicationFlavors.DEFAULT]: (
          <Login/>
        ),
        [AvailableApplicationFlavors.TELEMED]: (
          <Telemed.Auth/>
        ),
        [AvailableApplicationFlavors.SUNRISE]: (
          <Login/>
        )
      }} />
    </Container>
  );
};
