// @flow
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { ModalWithCloseButtonLayout } from "../modal/ModalWithCloseButtonLayout";
import { HeadingH3 } from "../styleguide/typography";
import { Textarea } from "../form-elements/Textarea";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { DeleteButton } from "../buttons/DeleteButton";

const Container = styled.div`
  width: 653px;
  padding: 30px 48px 40px;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    max-width: 653px;
    width: 100%;
  }
`;

const TextAreaContainer = styled.div`
  margin-top: 54px;
`;

const CommentFormTextarea = styled(Textarea)`
  min-height: 240px;
`;

const ButtonsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isTwoButtons ? "space-between" : "center")};

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const CommentForm = (props: {
  defaultValue?: string,
  onClose: () => mixed,
  onSubmit: string => mixed,
  onDelete?: () => mixed,
  isAutofocus?: boolean
}) => {
  const refTextareaComment = useRef(null);
  const { defaultValue } = props;
  const [value, setValue] = React.useState(defaultValue || "");
  useEffect(() => {
    if (props.isAutofocus && refTextareaComment.current) {
      const field = refTextareaComment.current;
      const fieldLength = field.value.length;
      field.focus();
      /** setSelectionRange - устанавливает начальное и конечное положение выделения текста в элементе */
      field.setSelectionRange(fieldLength, fieldLength);
    }
  }, []);

  const onSubmit = () => props.onSubmit(value);

  const saveButton = <PrimaryButton onClick={onSubmit}>Сохранить</PrimaryButton>;

  const deleteButton = defaultValue && (
    <DeleteButton onClick={props.onDelete}>Удалить комментарий</DeleteButton>
  );

  return (
    <ModalWithCloseButtonLayout handleClose={props.onClose}>
      <Container>
        <HeadingH3>
          {(defaultValue && "Редактирование комментария") || "Новый комментарий"}
        </HeadingH3>
        <TextAreaContainer>
          <CommentFormTextarea refTextarea={refTextareaComment} value={value} handleChange={setValue} />
        </TextAreaContainer>
        <ButtonsContainer isTwoButtons={defaultValue}>
          {saveButton}
          {deleteButton}
        </ButtonsContainer>
      </Container>
    </ModalWithCloseButtonLayout>
  );
};
