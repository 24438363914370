// @flow
import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { withModifiers } from "with-modifiers";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import { colors } from "../styleguide/colors";
import { NOTICE_TYPES } from "../../stores/noticesStore";
import InfoIcon from "../../assets/icons/notifications/infoIcon.inline.svg";
import AnalysisIcon from "../../assets/icons/notifications/analysisIcon.inline.svg";
import QuestionnaireIcon from "../../assets/icons/notifications/questionnaireIcon.inline.svg";
import LinkIcon from "../../assets/icons/notifications/linkIcon.inline.svg";
import { MarkdownBlock } from "../markdown/Markdown";

const IconByType = {
  [NOTICE_TYPES.INFO]: <InfoIcon />,
  [NOTICE_TYPES.ANALYSIS]: <AnalysisIcon />,
  [NOTICE_TYPES.QUESTIONNAIRE]: <QuestionnaireIcon />,
  [NOTICE_TYPES.LINK]: <LinkIcon />
};

export const NoticeContainer = withModifiers({
  unread: () => `background: #FAFAFA;`
})(
  modifier => styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    gap: 8px;
    padding: 8px 16px;
    ${modifier};
  `
);

const NoticeHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = withModifiers({
  info: () => `background: #FAAB37`,
  analysis: () => `background: #D46CE4`,
  questionnaire: () => `background: #5A98E0`,
  link: () => `background: #5AE0D0`
})(
  modifier => styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: #ffffff;
      }
    }

    ${modifier};
  `
);

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.gray700};
`;

const Caption = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #b0b0b0;
`;

const NoticeLink = styled(Link)`
    text-decoration: none;
`;

export const Notice = (props: {|
  id?: string,
  title?: string,
  type: string,
  description?: string,
  is_read?: boolean,
  created_at: string,
  onReadNotification: (id: string) => void
|}) => {
  const { id, title = "", type, description = "", is_read: isRead = true, created_at, params } = props;
  const noticeMoment = dayjs(created_at).locale("ru");
  const NoticeWrapper = params?.link ? NoticeLink : React.Fragment;
  const wrapperProps = params?.link ? {to: params.link} : {};

  const handleOnReadNotification = (e) => {
    e.preventDefault();
    props.onReadNotification && props.onReadNotification(id);
  };

  return (
    <NoticeWrapper {...wrapperProps}>
      <NoticeContainer modifiers={!isRead && "unread"}>
        <IconContainer modifiers={type}>{IconByType[type]}</IconContainer>
        <Content>
          <NoticeHeader>
            <Title>{title}</Title>
            {!isRead && (
              <Tooltip title="Прочитать уведомление">
                <IconButton onClick={handleOnReadNotification} size="small">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </NoticeHeader>
          <Description>
            <MarkdownBlock data={description} />
          </Description>
          <Caption>
            {noticeMoment.format("DD MMM")} в {noticeMoment.format("HH:mm")}
          </Caption>
        </Content>
      </NoticeContainer>
    </NoticeWrapper>
  );
};
