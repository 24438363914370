import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react";

import chatStore from "../../../stores/chatStore";
import { AvatarPlaceholder } from "../../greeting/greetingCommon";
import authStore from '../../../stores/authStore';
import SignalStrengthIndicator from "./SignalStrengthIndicator";

const MyViewWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 0;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  background: #3e2337;

  @media screen and (max-width: 1024px) {
    order: 1;
  }

  ${props =>
    (!props.inSettingsScreen && !!props.notAlone) &&
    css`
      aspect-ratio: 16/9;
      align-self: center;
      max-width: 20%;
  `}

  video {
    width: 100%;
  }

  ${props =>
    props.notAlone &&
    css`
      position: absolute;
      width: auto;
      bottom: 32px;
      right: 32px;
      height: 20%;
      padding: 0;
      border-radius: 24px;
      overflow: hidden;
      z-index: 4;
    `}
`;

const MyViewAvatarWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 0;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: #3e2337;

  ${props =>
          props.notAlone &&
          css`
      position: absolute;
      width: auto;
      bottom: 32px;
      right: 32px;
      height: 20%;
      padding: 0;
      border-radius: 24px;
      overflow: hidden;
      z-index: 4;
      aspect-ratio: 16/9;
    `}
`;

export const MyView = observer(() => {
  const videoRef = useRef();
  useEffect(() => {
    if (!chatStore.currentDevices.cameraId || !chatStore.videoEnabled) {
      return;
    }
    let mediaStream = null;
    (async () => {
      const constraints = {
        audio: false,
        video: {
          deviceId: chatStore.currentDevices.cameraId
        }
      };
      mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    })();

    return () => {
      // Остановка потока при размонтировании компонента
      if (mediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, [chatStore.currentDevices.cameraId, chatStore.videoEnabled]);
  if (!chatStore.currentDevices.cameraId || !chatStore.videoEnabled) {
    return (
      <MyViewAvatarWrapper inSettingsScreen={chatStore.callSettingsScreenShowed} notAlone={!chatStore.callSettingsScreenShowed && chatStore.endpoints.length}>
        <AvatarPlaceholder>
          {(authStore.user?.doctor
            ? authStore.user.doctor.first_name.slice(0, 1) + authStore.user.doctor.last_name.slice(0, 1)
            : "")}
        </AvatarPlaceholder>
      </MyViewAvatarWrapper>
    );
  }

  return (
    <MyViewWrapper inSettingsScreen={chatStore.callSettingsScreenShowed} notAlone={!chatStore.callSettingsScreenShowed && chatStore.endpoints.length}>
      <video style={{ display: "block" }} ref={videoRef} autoPlay playsInline />
      <SignalStrengthIndicator/>
    </MyViewWrapper>
  );
});
