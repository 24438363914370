import React, { useEffect } from "react";
import { FormLabel, Slider } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormInputSlider = (props) => {
  const [sliderValue, setSliderValue] = React.useState<number>(30);

  useEffect(() => {
    if (sliderValue) props.setValue(props.name, sliderValue);
  }, [sliderValue]);

  const handleChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <>
      <FormLabel component="legend">{props.label}</FormLabel>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field, fieldState: { error }, }) => (
          <Slider
            helperText={error ? error.message : null}
            error={!!error}
            value={sliderValue}
            onChange={handleChange}
            valueLabelDisplay="auto"
            min={0}
            max={100}
            step={1}
            {...props}
          />
        )}
      />
    </>
  );
};
