import React from "react";
import styled from "styled-components";
import { useStore } from "effector-react";
import { rgba } from "polished";
import { colors } from "../../styleguide/colors";

import FavoriteIcon from "./icons/Favorite";
import PlusIcon from "./icons/Plus";
import SearchIcon from "./icons/Search";

import { tokenStore, currentUser } from "../../../stores/auth";
import { setSelectedDoctor, setActiveSpeciality, activeSpecialityStore } from "../fullScheduleStore";
import doctorsStore from "../../../stores/doctorsStore";

const Container = styled.div`
  pointer-events: auto;
`;

const PopupHeader = styled.div`
  padding: 12px 13px 15px;
  font-size: 13px;
  line-height: 19px;
  border-bottom: 1px solid ${rgba(colors.black, 0.2)};
`;

const PopupControls = styled.div`
  padding: 8px 12px 6px;
`;

const PopupControl = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 0;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  &:hover {
    fill: ${colors.magenta};
    color: ${colors.magenta};

    svg > path {
      fill: ${colors.magenta};

      &[stroke] {
        stroke: ${colors.magenta};
      }
    }
  }
  ${props => props.disabled 
    ? `
      pointer-events: none;
      opacity: 0.5;
    `
    : ``      
  };
`;

const ButtonBadge = styled.div`
  position: absolute;
  right: 0;
  background-color: ${colors.white};
  color: ${colors.magenta};
  border: 1px solid ${colors.magenta};
  padding: 0 6px;
  font-size: 12px;
  line-height: 19px;
  border-radius: 99em;
  min-width: 21px;
  box-sizing: border-box;
  text-align: center;
`;

const ListButton = props => {
  const { icon, children, onClick, isDisabled = false } = props;

  return (
    <PopupControl onClick={onClick} disabled={isDisabled}>
      {icon ? icon : ""}
      {children}
    </PopupControl>
  );
};

const Speciality = styled.div`
  font-size: 11px;
  line-height: 14px;
  color: ${colors.gray400};
  margin-top: 6px;
`;

export const DoctorMenu = props => {
  const {
    doctor,
    inFavourites,
    offScheduleEntriesCount,
    openScheduleInsertWithoutTime,
    isAllowedScheduleInsertWithoutTime
  } = props;
  const activeSpeciality = useStore(activeSpecialityStore);
  const token = useStore(tokenStore);
  const { doctor: currentDoctor } = useStore(currentUser);

  const getSpecialitiesNames = specialities => {
    return specialities.map(speciality => speciality.name);
  };

  return (
    <Container>
      <PopupHeader>
        {doctor.last_name} {doctor.first_name} {doctor.middle_name}
        <Speciality>{getSpecialitiesNames(doctor.specialities).join(", ")}</Speciality>
      </PopupHeader>
      <PopupControls>
        {currentDoctor.id !== doctor.id ? (
          <>
            <ListButton
              icon={<FavoriteIcon active={inFavourites} />}
              onClick={async () => {
                if (inFavourites) {
                  await doctorsStore.removeDoctorFromFavourites({ doctor, isForce: activeSpeciality === "favourites" });
                } else {
                  await doctorsStore.addDoctorToFavourites({ doctor, isForce: activeSpeciality === "favourites" });
                }
              }}>
              {inFavourites ? "Убрать из избранного" : "Добавить в избранное"}
            </ListButton>
          </>
        ) : null}
        <ListButton
          isDisabled={!isAllowedScheduleInsertWithoutTime}
          icon={<PlusIcon />}
          onClick={openScheduleInsertWithoutTime}
        >
          Прием вне расписания
          {offScheduleEntriesCount > 0 ? <ButtonBadge>{offScheduleEntriesCount}</ButtonBadge> : ""}
        </ListButton>
        <ListButton
          icon={<SearchIcon />}
          onClick={() => {
            setSelectedDoctor(doctor);
            setActiveSpeciality(null);
          }}>
          Расписание врача
        </ListButton>
      </PopupControls>
    </Container>
  );
};
