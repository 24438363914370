import React from "react";
import { Box, LinearProgress, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CorporateLoginWrapper } from "../styled/CorporateWrapper.styled";
import logotype from "../../assets/logo/logoFominWithName.svg";
import { observer } from "mobx-react";
import corporateLoginStore, { STAGES } from "../stores/corporateLoginStore";
import { CorporateAuthorizationForm } from "../modules/login/CorporateAuthorizationForm";
import { CorporateRegistrationForm } from "../modules/login/CorporateRegistrationForm";

const Wrapper = styled(Paper)(
  ({ theme }) => `
    position: relative;
    box-sizing: border-box;
    height: 100vh;
    background: ${theme.palette.background.layout};
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

const Logotype = styled("img")`
  width: auto;
  height: 60px;
`;

const LoaderContainer = styled(Box)`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
`;

export const CorporateLoginPage = observer(() => {
  const FORM_BY_STAGE = {
    [STAGES.AUTHORIZATION]: <CorporateAuthorizationForm />,
    [STAGES.REGISTRATION]: <CorporateRegistrationForm />
  };

  return (
    <Wrapper>
      <CorporateLoginWrapper>
        <Logotype src={logotype} />
        {FORM_BY_STAGE[corporateLoginStore.stage]}
        {corporateLoginStore.isLoading && (
          <LoaderContainer>
            <LinearProgress />
          </LoaderContainer>
        )}
      </CorporateLoginWrapper>
    </Wrapper>
  );
});
