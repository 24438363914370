import React, { useRef, useState, useMemo, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import voxInformationPatientStore, {
  LINK_TYPES,
  LINKS
} from "../../../stores/voxInformationPatientStore";
import { Chip, Link as MuiLink } from "@mui/material";
import Button from "@mui/material/Button";
import { scrollbars } from "../../chats/style-elements";

const TableContainer = styled.div`
  width: 100%;
  position: relative;
  display: block;
  height: ${props => (props.height ? `${props.height}px` : "100%")};
  .MuiTableContainer-root {
    ${scrollbars}
  }
`;

export const VoxAppointmentsTable = observer(() => {
  const tableRef = useRef(null);
  const rowVirtualizerInstanceRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);

  const defaultColumnVisible = localStorage.getItem(`${location.pathname}AppointmentsTableColumn`)
    ? JSON.parse(localStorage.getItem(`${location.pathname}AppointmentsTableColumn`))
    : { fullNamePatient: voxInformationPatientStore.patients.length > 1 };

  const [columnVisibility, setColumnVisibility] = useState(defaultColumnVisible);

  useLayoutEffect(() => {
    handleChangeTableHeightOnResize();

    if (voxInformationPatientStore.appointmentsTableDataInitialized) {
      return;
    }

    (async () => {
      await voxInformationPatientStore.getAppointmentsByPatient();
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullNamePatient",
        header: "ФИО пациента",
        size: 300
      },
      {
        accessorKey: "linkDjango",
        header: "Ссылка на Django",
        size: 150,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableGrouping: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          sx: () => ({
            justifyContent: "center",
            [`.Mui-TableHeadCell-Content`]: {
              justifyContent: "center"
            }
          })
        },
        Cell: ({ row }) => (
          <Button sx={{ width: "100%" }} size="small">
            <MuiLink
              href={LINKS[LINK_TYPES.APPOINTMENT_DJANGO]({ id: row.original.appointmentId })}
              target="_blank"
              rel="noreferrer"
              underline="none">
              Прием в Django
            </MuiLink>
          </Button>
        )
      },
      {
        accessorKey: "linkAsya",
        header: "Ссылка на Асю",
        size: 150,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableGrouping: false,
        enableSorting: false,
        muiTableHeadCellProps: {
          sx: () => ({
            justifyContent: "center",
            [`.Mui-TableHeadCell-Content`]: {
              justifyContent: "center"
            }
          })
        },
        Cell: ({ row }) => (
          <Button sx={{ width: "100%" }} size="small">
            <MuiLink
              href={LINKS[LINK_TYPES.APPOINTMENT_WEB]({ id: row.original.appointmentId })}
              target="_blank"
              rel="noreferrer"
              underline="none">
              Прием в асе
            </MuiLink>
          </Button>
        )
      },
      {
        accessorKey: "fullNameDoctor",
        header: "ФИО врача",
        size: 300
      },
      {
        accessorKey: "inProgress",
        header: "Завершен",
        accessorFn: originalRow =>
          originalRow.inProgress ? "Прием завершен" : "Прием не завершен",
        filterVariant: "select",
        filterSelectOptions: ["Прием завершен", "Прием не завершен"],
        Cell: ({ row }) => (
          <Chip
            variant="outlined"
            size="small"
            label={row.original.inProgress ? "Прием завершен" : "Прием не завершен"}
            color={row.original.inProgress ? "success" : "error"}
          />
        )
      },
      {
        accessorKey: "isCancelled",
        header: "Отменен",
        accessorFn: originalRow => (originalRow.isCancelled ? "Прием отменен" : "Прием не отменен"),
        filterVariant: "select",
        filterSelectOptions: ["Прием отменен", "Прием не отменен"],
        Cell: ({ row }) => (
          <Chip
            variant="outlined"
            size="small"
            label={row.original.isCancelled ? "Прием отменен" : "Прием не отменен"}
            color={row.original.isCancelled ? "errors" : "success"}
          />
        )
      },
      {
        accessorFn: originalRow => new Date(originalRow.date),
        accessorKey: "date",
        sortingFn: "datetime",
        header: "Дата приема",
        size: 200,
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString()
      },
      {
        accessorKey: "time",
        header: "Время приема",
        size: 200
      }
    ],
    [voxInformationPatientStore.appointmentsTableData]
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: voxInformationPatientStore.appointmentsTableDataInitialized
      ? voxInformationPatientStore.appointmentsTableData
      : [],
    rowVirtualizerInstanceRef,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 5 },
    localization: { ...MRT_Localization_RU, noRecordsToDisplay: "Приемы не найдены" },
    isMultiSortEvent: () => true,
    enableFullScreenToggle: true,
    enableGlobalFilter: false,
    enableGrouping: true,
    groupedColumnMode: false,
    enablePagination: false,
    enableBottomToolbar: false,
    layoutMode: "grid",
    displayColumnDefOptions: {
      "mrt-row-expand": {
        size: 40,
        muiTableHeadCellProps: {
          align: "center"
        },
        muiTableBodyCellProps: {
          align: "center"
        }
      }
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px)`
      }
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%"
      }
    },
    onColumnVisibilityChange: setColumnVisibility,
    initialState: {
      showColumnFilters: true,
      density: "compact",
      sorting: [
        { id: "fullNamePatient", desc: false },
        { id: "date", desc: true },
        { id: "time", desc: true }
      ],
      grouping:
        voxInformationPatientStore.patients.length > 1 && !!columnVisibility?.fullNamePatient
          ? ["fullNamePatient"]
          : []
    },
    state: {
      columnVisibility
    }
  });

  const handleChangeTableHeightOnResize = () => {
    setTableHeight(window.innerHeight - tableRef?.current?.getBoundingClientRect().top);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleChangeTableHeightOnResize);
    return () => window.removeEventListener("resize", handleChangeTableHeightOnResize);
  }, [window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    localStorage.setItem(
      `${location.pathname}AppointmentsTableColumn`,
      JSON.stringify(columnVisibility)
    );
  }, [columnVisibility]);

  return (
    <TableContainer ref={tableRef} height={tableHeight}>
      <MaterialReactTable table={table} />
    </TableContainer>
  );
});
