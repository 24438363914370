import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";

export const useCalcWidthContainer = (containerRef) => {
  const isMobileDevice = useMediaQuery("(max-width: 768px)");
  const isContainerRef = !!containerRef?.current;
  const [width, setWidth] = useState(0);
  const widthContainer = containerRef?.current?.getBoundingClientRect().width || null;

  /** значение "-200" - кнопки панели управления для слайдера + отступы */
  const calc = () => {
    if (!isContainerRef) {
      setWidth(1000);
    }
    setWidth(isMobileDevice ? widthContainer : widthContainer - 200);
  };

  useEffect(() => {
    window.addEventListener("resize", calc);
    return () => window.removeEventListener("resize", calc);
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => calc(), [isContainerRef]);

  return `${width}px`;
};
