import React, { useEffect, useMemo, useState } from "react";
import ReactDOMServer from 'react-dom/server';
import styled, { css } from "styled-components";
import useReactRouter from "use-react-router";
import { useStore } from "effector-react";
import { useAsync } from "react-use";

import { tokenStore } from "../../../stores/auth";
import { VIEW_TYPES, colorsOptions } from "../constants";
import { fillLine } from "./constants/fillers";

import { documentsTypes } from "../types";

import { arrayToEntriesById, uniqueFilter } from "../../../utils/arrays";

import { useAfterPrint } from "../../appointment-print/useAfterPrint";
import { useBeforePrint } from "../../appointment-print/useBeforePrint";

import { getPatientFullName } from "../../../domain/services/patient";
import { formatDateToReadableDateFormat } from "../../../utils/dates";

import { colors } from "../../styleguide/colors";

import { Logo as PrintLogo } from "../../appointment-print/Logo";

import * as S from "./styled/Document.styled";
import printEditorStore, {PrintEditorEvents} from '../../../stores/printEditorStore';
import apiv2 from '../../../apiv2';

const Heading = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 0 16px;
    
  ${({ editable }) =>
    !editable &&
    css`
      padding-left: 273px;
    `}

  ${({ loading }) =>
    loading &&
    css`
      visibility: hidden;
    `}

  &::before,
  &::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
  }

  &::before {
    top: 8px;
    left: 240px;
    border-left: 1px solid ${colors.gray800};
    height: 92px;
  }

  &::after {
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid ${colors.gray800};
  }
`;

const JustifiedTextBlock = styled(S.TextBlock)`
  display: flex;
  justify-content: space-between;
`;

const H1 = styled.h1`
  padding: 60px 0 24px;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
`;

const Logo = styled(PrintLogo)`
  position: absolute;
  top: 8px;
  left: 0;
  width: 155px;
  height: 58px;
`;

const OrgFieldsColumn = styled.div`
  display: table;
`;

const OrgFieldValue = styled.div`
  display: table-row;
`;

const OrgField = styled.div`
  display: table-cell;
  height: 23px;
  font-size: 15px;
  line-height: 23px;
  color: ${colors.gray700};
`;

const OrgValue = styled(OrgField)`
  padding-left: 12px;
  color: ${colors.black};
  max-width: 258px;
`;

const List = styled.ul`
  list-style: disc;
  padding: 8px 0 8px 27px;
  font-size: 17px;
  line-height: 24px;
`;

const NumberList = styled.ul`
  list-style: decimal;
  padding: 8px 0 8px 22px;
  font-size: 14px;
  line-height: 20px;
`;

const Signs = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
`;

const Sign = styled.div`
  padding: 46px 12px 0;
  font-size: 14px;
  line-height: 18px;
  min-width: 512px;
  page-break-inside: avoid;
`;

const BreakAllLine = styled.span`
  word-break: break-all;
`;

const Value = styled.span`
  font-weight: 500;
`;

const Passport = ({ series, number, date_of_issue, issued_by }) => (
  <>
    Паспорт гражданина Российской Федерации, серия: {series}, № {number} выдан:{" "}
    {formatDateToReadableDateFormat(date_of_issue)}, {issued_by}
  </>
);

const passportFiller = (
  <span>
    Паспорт гражданина Российской Федерации, серия: ____, № ______ выдан: ,{" "}
    <BreakAllLine>____________________________________________________________</BreakAllLine>
  </span>
);

const brithdayFiller = <>__.__.____ г.р.</>;

const nameFiller = <BreakAllLine>_______________________________________________</BreakAllLine>;

const courierNameFiller = <>_______________________________________</>;

const colorsOptionsByValue = arrayToEntriesById(colorsOptions, "value");

export const CoverLetter = () => {
  const {
    history,
    match: { params }
  } = useReactRouter();

  const searchParams = new URLSearchParams(location.search);
  const needEdit = searchParams.get('needEdit') === 'true';

  const [logoLoaded, setLogoLoaded] = useState(needEdit);

  const token = useStore(tokenStore);

  const { value: { medicalFile, patientCryoEmbryos, documentData } = {}, loading } = useAsync(
    async () => {
      const medicalFile = await apiv2.medicalFiles.getById(params.medical_file_id);

      const organization = await apiv2.organizations.getOrganization(medicalFile.organization.id);

      const patientCryoEmbryos = await apiv2.embryos.get({
        viewType: VIEW_TYPES.CRYOPRESERVATIONS,
        patientId: medicalFile.patient.id
      });

      const documentData = await apiv2.embryos.getEmbryosPrintDocumentData({
        patientId: medicalFile.patient.id,
        type: "covering_letter"
      });

      return { medicalFile, organization, patientCryoEmbryos, documentData };
    }
  );

  const printDataPrepared = !loading && logoLoaded;

  const captions = useMemo(
    () =>
      printDataPrepared && {
        belongs: {
          single: (
            <>
              {getPatientFullName(medicalFile.patient)}
              {" ("}
              {formatDateToReadableDateFormat(medicalFile.patient.birthdate)} г.р.,{" "}
              {(documentData.result.patient_passport_data && (
                <Passport {...documentData.result.patient_passport_data} />
              )) ||
                passportFiller}
              {")"}
            </>
          ),
          pair: (
            <>
              {getPatientFullName(medicalFile.patient)}
              {" ("}
              {formatDateToReadableDateFormat(medicalFile.patient.birthdate)} г.р.,{" "}
              {(documentData.result.patient_passport_data && (
                <Passport {...documentData.result.patient_passport_data} />
              )) ||
                passportFiller}
              {")"} <br /> и{" "}
              {(documentData.result.partner &&
                typeof documentData.result.partner === "object" &&
                getPatientFullName(documentData.result.partner)) ||
                documentData.result.partner ||
                nameFiller}{" "}
              {" ("}
              {(documentData.result.partner &&
                typeof documentData.result.partner === "object" &&
                formatDateToReadableDateFormat(documentData.result.partner.birthdate)) ||
                brithdayFiller}
              {", "}
              {(documentData.result.partner_passport_data && (
                <Passport {...documentData.result.partner_passport_data} />
              )) ||
                passportFiller}
              {")"}
            </>
          )
        },
        agreement: {
          single: (
            <>
              Я, {getPatientFullName(medicalFile.patient)}, внимательно прочитала весь текст
              сопроводительного письма. На все поставленные вопросы получила исчерпывающие ответы.
              Претензий (материальных, моральных и каких-либо других) к{" "}
              {medicalFile.organization.official_name} не имею.
            </>
          ),
          pair: (
            <>
              Мы, {getPatientFullName(medicalFile.patient)},{" "}
              {(documentData.result.partner &&
                typeof documentData.result.partner === "object" &&
                getPatientFullName(documentData.result.partner)) ||
                documentData.result.partner ||
                nameFiller}
              , внимательно прочитали весь текст сопроводительного письма. На все поставленные
              вопросы получили исчерпывающие ответы. Претензий (материальных, моральных и каких-либо
              других) к {medicalFile.organization.official_name} не имеем.
            </>
          )
        },
        recieves: {
          single: (
            <>
              {formatDateToReadableDateFormat(new Date())} {getPatientFullName(medicalFile.patient)}{" "}
              получила {documentData.result.unique_cryocarriers_count} крионоситель(ей) с{" "}
              {patientCryoEmbryos.length} эмбрионом(ами) / ооцитами в собственное траснпортное
              устройство заполненное жидким азотом.
            </>
          ),
          singleCourier: (
            <>
              {formatDateToReadableDateFormat(new Date())} Курьер {courierNameFiller}, действующий
              на основании доверенности от {getPatientFullName(medicalFile.patient)}, получил{" "}
              {documentData.result.unique_cryocarriers_count} крионоситель(ей) с{" "}
              {patientCryoEmbryos.length} эмбрионом(ами) / ооцитами в собственное транспортное
              устройство, заполненное жидким азотом.
            </>
          ),
          pair: (
            <>
              {formatDateToReadableDateFormat(new Date())} {getPatientFullName(medicalFile.patient)}
              {", "}
              {(documentData.result.partner &&
                typeof documentData.result.partner === "object" &&
                getPatientFullName(documentData.result.partner)) ||
                documentData.result.partner ||
                nameFiller}{" "}
              получили {documentData.result.unique_cryocarriers_count} крионоситель(ей) с{" "}
              {patientCryoEmbryos.length} эмбрионом(ами) в собственное траснпортное устройство
              заполненное жидким азотом.
            </>
          ),
          pairCourier: (
            <>
              {formatDateToReadableDateFormat(new Date())} Курьер {courierNameFiller}, действующий
              на основании доверенности от {getPatientFullName(medicalFile.patient)},{" "}
              {(documentData.result.partner &&
                typeof documentData.result.partner === "object" &&
                getPatientFullName(documentData.result.partner)) ||
                documentData.result.partner ||
                nameFiller}{" "}
              получил {documentData.result.unique_cryocarriers_count} крионоситель(ей) с{" "}
              {patientCryoEmbryos.length} эмбрионом(ами) в собственное транспортное устройство,
              заполненное жидким азотом.
            </>
          )
        }
      },
    [medicalFile, documentData, patientCryoEmbryos, printDataPrepared]
  );

  const captionsByDocumentType = useMemo(
    () =>
      printDataPrepared && {
        [documentsTypes.coverLetterWoman]: {
          belongs: captions.belongs.single,
          agreement: captions.agreement.single,
          recieves: captions.recieves.single
        },
        [documentsTypes.coverLetterWomanCourier]: {
          belongs: captions.belongs.single,
          agreement: captions.agreement.single,
          recieves: captions.recieves.singleCourier
        },
        [documentsTypes.coverLetterPair]: {
          belongs: captions.belongs.pair,
          agreement: captions.agreement.pair,
          recieves: captions.recieves.pair
        },
        [documentsTypes.coverLetterPairCourier]: {
          belongs: captions.belongs.pair,
          agreement: captions.agreement.pair,
          recieves: captions.recieves.pairCourier
        }
      },
    [captions, printDataPrepared]
  );

  const signPlaceholders = useMemo(
    () => ({
      recieved: {
        single: (
          <Sign>
            ПРИНЯЛ <br />
            <br />
            <br />
            <br />
            ____________ /_________________________________ <br />
            <br />
            Подпись, ФИО Исполнителя собственноручно <br />
          </Sign>
        ),
        pair: (
          <>
            <Sign>
              ПРИНЯЛИ <br />
              <br />
              <br />
              <br />
              ____________ /_________________________________ <br />
              <br />
              Подпись, ФИО Исполнителя собственноручно <br />
              <br />
              <br />
              ____________ /_________________________________ <br />
              <br />
              Подпись, ФИО Исполнителя собственноручно
            </Sign>
          </>
        )
      }
    }),
    []
  );

  const signPlaceholdersByDocumentType = useMemo(
    () => ({
      [documentsTypes.coverLetterWoman]: {
        recieved: signPlaceholders.recieved.single
      },
      [documentsTypes.coverLetterWomanCourier]: {
        recieved: signPlaceholders.recieved.single
      },
      [documentsTypes.coverLetterPair]: {
        recieved: signPlaceholders.recieved.pair
      },
      [documentsTypes.coverLetterPairCourier]: {
        recieved: signPlaceholders.recieved.pair
      }
    }),
    [signPlaceholders]
  );

  useBeforePrint(printDataPrepared && !needEdit);

  useAfterPrint(`/appointment/embryos/${params.medical_file_id}`);

  let contentForPrint;

  useEffect(() => {
    if (needEdit && printDataPrepared && contentForPrint && !printEditorStore.isVisible) {
      const data = ReactDOMServer.renderToString(contentForPrint);
      printEditorStore.setData(data);
      printEditorStore.show();
    }
  }, [printDataPrepared, loading, contentForPrint]);

  useEffect(() => {
    const afterPrint = () => {
      if (!needEdit) {
        history.replace(`/appointment/embryos/${params.medical_file_id}`);
      }
    };
    window.addEventListener("afterprint", afterPrint, false);
    const unsubscribeBack = printEditorStore.addEventListener(PrintEditorEvents.back, () => {
      history.replace(`/appointment/embryos/${params.medical_file_id}`);
      printEditorStore.clearEventListeners(PrintEditorEvents.back);
    });
    return () => {
      window.removeEventListener("afterprint", afterPrint);
      unsubscribeBack();
    }
  }, []);

  if (needEdit && loading) {
    return "Загрузка...";
  }

  contentForPrint = (
    <S.Container>
      {!printDataPrepared && "Загрузка..."}
      <Heading loading={!printDataPrepared} editable={needEdit}>
        {!loading && (
          <Logo logoUrl={medicalFile.organization.logo} onLoad={() => setLogoLoaded(true)} />
        )}
        {printDataPrepared && (
          <>
            <OrgFieldsColumn>
              <OrgFieldValue>
                <OrgField>Организация:</OrgField>
                <OrgValue>{medicalFile.organization.official_name}</OrgValue>
              </OrgFieldValue>
              <OrgFieldValue>
                <OrgField>ОГРН:</OrgField>
                <OrgValue>{medicalFile.organization.ogrn}</OrgValue>
              </OrgFieldValue>
              <OrgFieldValue>
                <OrgField>ОКПО:</OrgField>
                <OrgValue>{medicalFile.organization.okpo}</OrgValue>
              </OrgFieldValue>
              <OrgFieldValue>
                <OrgField>ИНН/КПП:</OrgField>
                <OrgValue>
                  {medicalFile.organization.inn}/{medicalFile.organization.kpp}
                </OrgValue>
              </OrgFieldValue>
            </OrgFieldsColumn>
            <OrgFieldsColumn>
              <OrgFieldValue>
                <OrgField>Адрес:</OrgField>
                <OrgValue>{medicalFile.organization.address}</OrgValue>
              </OrgFieldValue>
              <OrgFieldValue>
                <OrgField>Тел./факс:</OrgField>
                <OrgValue>{medicalFile.organization.phone}</OrgValue>
              </OrgFieldValue>
              <OrgFieldValue>
                <OrgField>Email:</OrgField>
                <OrgValue>{medicalFile.organization.email}</OrgValue>
              </OrgFieldValue>
            </OrgFieldsColumn>
          </>
        )}
      </Heading>
      {printDataPrepared && (
        <>
          <H1>
            СОПРОВОДИТЕЛЬНОЕ ПИСЬМО <br /> (с актом приёма-передачи)
          </H1>
          <JustifiedTextBlock>
            <span>г.{medicalFile.organization.city.name}</span>
            <span>{formatDateToReadableDateFormat(new Date())} г.</span>
          </JustifiedTextBlock>
          <S.TextBlock height={168}>
            {" "}
            Биоматериал принадлежит: <br />
            <br />
            {captionsByDocumentType[params.document].belongs}
          </S.TextBlock>
          <S.Table columnWidth={146}>
            <S.TableBody>
              <S.Row>
                <S.HeaderCell bold center width={46}>
                  №
                </S.HeaderCell>
                <S.HeaderCell bold center width={153}>
                  Дата криоконсервации
                </S.HeaderCell>
                <S.HeaderCell bold center width={95}>
                  День развития
                </S.HeaderCell>
                <S.HeaderCell bold center width={132}>
                  Крионоситель
                </S.HeaderCell>
                <S.HeaderCell bold center width={199}>
                  Среда криоконсервации
                </S.HeaderCell>
                <S.HeaderCell bold center width={199}>
                  Вид и качество биоматериала
                </S.HeaderCell>
                <S.HeaderCell bold center>
                  Проведение биопсии/анализа ПГТ
                </S.HeaderCell>
              </S.Row>
              {patientCryoEmbryos.map(
                (
                  {
                    cryo_carrier_number,
                    date_crio,
                    days_of_cultivation_before_cryo,
                    cryo_carrier,
                    color,
                    crio_env,
                    pgt_status,
                    final_assessment,
                    date_start
                  },
                  i
                ) => (
                  <S.Row key={i}>
                    <S.CenterCell>{cryo_carrier_number}</S.CenterCell>
                    <S.CenterCell>
                      {date_crio && formatDateToReadableDateFormat(date_crio)}
                    </S.CenterCell>
                    <S.CenterCell>{days_of_cultivation_before_cryo}</S.CenterCell>
                    <S.CenterCell>
                      {[cryo_carrier, colorsOptionsByValue[color]?.label]
                        .filter(item => item)
                        .join(", ")}
                    </S.CenterCell>
                    <S.CenterCell>{crio_env}</S.CenterCell>
                    <S.CenterCell>
                      {date_start === date_crio ? "ооцит" : "эмбрион"} {final_assessment}
                    </S.CenterCell>
                    <S.CenterCell>{pgt_status || "–"}</S.CenterCell>
                  </S.Row>
                )
              )}
            </S.TableBody>
          </S.Table>
          <S.TextBlock>
            Использование донорского материала и его описание (для эмбрионов):{" "}
            <Value>{documentData.result.using_donor_material ? "Да" : "Нет"}</Value> <br />
            Номер анонимного донора/ФИО неанонимного донора ооцитов:{" "}
            <Value>
              {patientCryoEmbryos
                .map(({ woman_identifier }) => woman_identifier)
                .filter(item => item)
                .map(item => item.trim())
                .filter(item => /\d/.test(item))
                .filter(uniqueFilter)
                .join(", ")}
            </Value>{" "}
            <br />
            Номер анонимного донора/ФИО неанонимного донора спермы:{" "}
            <Value>
              {patientCryoEmbryos
                .map(({ man_identifier }) => man_identifier)
                .filter(item => item)
                .map(item => item.trim())
                .filter(item => /\d/.test(item))
                .filter(uniqueFilter)
                .join(", ")}
            </Value>
            <br />
            Описание транспортного устройства: <br />
            {fillLine} <br />
            Пломба: № (при наличии): <br />
            {fillLine}
            <br />
            Особенности материала: {fillLine}
            <br />
            {fillLine}
            <br />
            {fillLine}
            <br />
            {fillLine}
          </S.TextBlock>
          <List>
            <li>Результаты анализов на гемоконтактные инфекции прилагаются.</li>
            <li>
              Эмбриону проведена биопсия трофэктодермы, копия результата ПГТ-А прилагается. (опция)
            </li>
          </List>

          <NumberList>
            <li>
              Согласно Приказу Министерства здравоохранения РФ от 31 июля 2020 г. № 803н &quot;О
              порядке использования вспомогательных репродуктивных технологий, противопоказаниях и
              ограничениях к их применению&quot;: Ст. 48. Хранение криоконсервированных половых
              клеток, тканей репродуктивных органов и эмбрионов осуществляется в специальных
              маркированных контейнерах, помещенных в жидкий азот.Ст. 50. Транспортировка половых
              клеток, тканей репродуктивных органов и эмбрионов осуществляется медицинской
              организацией, имеющей лицензию на осуществление медицинской деятельности,
              предусматривающую выполнение работ (оказание услуг) по транспортировке половых клеток
              и (или) тканей репродуктивных органов.Ст. 52. По письменному заявлению пациента
              криоконсервированные половые клетки, ткани репродуктивных органов и эмбрионы выдаются
              на руки пациенту.
            </li>
            <li>
              Потребители проинструктированы о высоком риске, которому подвергается биоматериал при
              самостоятельно осуществляемой транспортировке
            </li>
            <li>
              За условия транспортировки, дальнейшее хранение и качество биоматериала{" "}
              {medicalFile.organization.official_name} ответственности не несет
            </li>
            <li>
              Настоящее Сопроводительное письмо составлено в двух экземплярах, имеющих равную
              юридическую силу, один экземпляр Исполнителю и один экземпляр Потребителям
            </li>
          </NumberList>

          <S.TextBlock>{captionsByDocumentType[params.document].recieves}</S.TextBlock>
          <S.TextBlock>{captionsByDocumentType[params.document].agreement}</S.TextBlock>
          <Signs>
            <Sign>
              ПЕРЕДАЛ <br />
              <br />
              <br />
              <br />
              ____________ /_________________________________ <br />
              <br />
              Подпись, ФИО Исполнителя собственноручно <br />
              <br />
              <br />
              <br />
              Дата__________ Время________
            </Sign>
            {signPlaceholdersByDocumentType[params.document].recieved}
          </Signs>
        </>
      )}
    </S.Container>
  );

  if (needEdit) {
    return;
  }

  return contentForPrint;
};
