// @flow
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ChartComponent } from "../common/charts/ChartComponent";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import moment from "moment";
import _ from "lodash";
import reviewStatisticsStore from "../../stores/reviewStatisticsStore";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = "widgetAverageRatingByReviews";
const PaperStyled = styled(Paper)(
  () => `
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
  `
);

export const FILTERS_FIELDS_WIDGET_AVERAGE_RATING_BY_REVIEWS = () => [
  {
    type: "text",
    name: "label",
    label: "Название графика",
    xs: 12
  }
];

export const WidgetAverageRatingByReviews = observer((props: {}) => {
  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [label, setLabel] = useState("Средний рейтинг отзывов по площадкам");
  const [isInitialized, setIsInitialized] = useState(false);
  const [chartsData, setChartsData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (getLocalStorage(NAME_WIDGET + props.keyWidget)) {
        setLocalStorage(
          NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", ""),
          getLocalStorage(NAME_WIDGET + props.keyWidget)
        );
        localStorage.removeItem(NAME_WIDGET + props.keyWidget);
      }

      const filterDiagramLocaleStorage = getLocalStorage(
        NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", "")
      );
      if (
        filterDiagramLocaleStorage &&
        moment(filterDiagramLocaleStorage.date_end).isBefore(moment())
      ) {
        filterDiagramLocaleStorage.date_end = moment();
      }
      const filters = props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
      if (filters.label) {
        setLabel(filters.label);
      }
      setFiltersDataDiagram(filters);
      setIsInitialized(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (_.size(filtersDataDiagram)) {
        setLoading(true);
        setLocalStorage(
          NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", ""),
          filtersDataDiagram
        );
        if (filtersDataDiagram.label) {
          setLabel(filtersDataDiagram.label);
        }
        const response = await reviewStatisticsStore.getRatingByReviews(filtersDataDiagram);
        setChartsData({
          value: response?.avg_rating || 0,
          valueMax: 5
        });
        setLoading(false);
      }
    })();
  }, [filtersDataDiagram]);

  useEffect(() => {
    if (props.rewriteFilters) {
      setFiltersDataDiagram({ ...filtersDataDiagram, ...props.rewriteFilters });
    }
  }, [props.rewriteFilters]);

  return (
    <PaperStyled>
      {(loading || !isInitialized) && <AbsoluteLoader />}
      <ChartComponent
        label={label}
        textButtonFilter="Применить"
        type="gauge"
        nameDiagram="ReviewDiagramRPR"
        data={chartsData}
        fullHeight
      />
    </PaperStyled>
  );
});
