import { ChartComponent } from "../common/charts/ChartComponent";
import { Box, Paper, Stack } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import _ from "lodash";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import { renderToolbarAlertBannerContent } from "../common/MaterialTable";
import storiesStore, { transformDataDiagram, transformTableData } from "../../stores/storiesStore";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = 'widgetStoriesAnalytics';
export const FILTERS_FIELDS_WIDGET_STORIES_ANALYTICS = async () => {
  await storiesStore.getStories();
  return [
    {
      type: 'select',
      name: 'type',
      label: 'Вид графика',
      xs: 12,
      options: [
        {value: 'line', label: 'Линейный'},
        {value: 'bar', label: 'Столбцами'},
        {value: 'table', label: 'Таблицей'},
      ]
    },
    {
      type: 'select',
      name: 'story_status',
      label: 'Статус',
      multiple: true,
      xs: 12,
      options: storiesStore.storiesStatusOptions
    },
    {
      type: 'text',
      name: 'label',
      label: 'Название графика',
      xs: 12,
    },
    {
      type: 'select',
      name: 'interval',
      label: 'Интервал',
      xs: 12,
      options: [
        {value: 'day', label: 'По дням'},
        {value: 'week', label: 'По неделям'},
        {value: 'month', label: 'По месяцам'},
        {value: 'year', label: 'По годам'},
      ]
    },
    {
      type: 'checkbox',
      name: 'isCollapse',
      label: 'Объединить выбранные сторисы (только для графиков)',
      xs: 12,
    },
    {
      type: 'checkbox',
      name: 'updateData',
      label: 'Реальное время',
      xs: 12,
    },
    {
      type: 'select',
      name: 'story_ids',
      label: 'Сторисы',
      needSelectAll: true,
      multiple: true,
      valueName: 'id',
      labelName: 'miniature_title',
      xs: 12,
      options: storiesStore.stories,
    },
  ];
}

export const WidgetStoriesAnalytics = observer((props: {
  filtersData: () => {},
  rewriteFilters: () => {},
  keyWidget: String
}) => {

  const styledPaper = {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [filterFields, setFilterFields] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableAllCount, setTableAllCount] = useState(0);
  const [label, setLabel] = useState('График по сторисам');
  const [chartsData, setChartsData] = useState();
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateDataInterval, setUpdateDataInterval] = useState(null);



  useEffect(() => {
    (async () => {
      const filterDiagramLocaleStorage = getLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''));
      if (filterDiagramLocaleStorage && moment(filterDiagramLocaleStorage.date_end).isBefore(moment())) {
        filterDiagramLocaleStorage.date_end = moment();
      }
      const filters = props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
      if (filters.label) {
        setLabel(filters.label);
      }
      if (moment(filters.date_end).isBefore(moment().set({ hour: 23, minute: 59, second: 59 }))) {
        filters.date_end = moment().set({ hour: 23, minute: 59, second: 59 });
      }
      setFiltersDataDiagram(filters)
      const filtersFields = await FILTERS_FIELDS_WIDGET_STORIES_ANALYTICS();
      setFilterFields([...filtersFields]);
      setIsInitialized(true);
    })()
  }, []);

  useEffect(() => {
    if (props.rewriteFilters) {
      setFiltersDataDiagram({...filtersDataDiagram, ...props.rewriteFilters});
    }
  }, [props.rewriteFilters]);

  useEffect(() => {
    (async () => {
      if (_.size(filtersDataDiagram)) {
        setLoading(true);
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), filtersDataDiagram);
        if (filtersDataDiagram.label) {
          setLabel(filtersDataDiagram.label);
        }
        const updateData = async () => {
          const response = await storiesStore.getAnalyticsStoriesData(filtersDataDiagram);
          setChartsData(transformDataDiagram({data: response, interval: filtersDataDiagram.interval || 'day', isCollapseData: filtersDataDiagram.isCollapse || filtersDataDiagram.story_ids?.length === 1}));
          setTableData(transformTableData({data: response, stories: storiesStore.stories, valueName: 'count',  storiesStore}));
          setLoading(false);
        }
        if (updateDataInterval) {
          clearInterval(updateDataInterval);
          setUpdateDataInterval(null);
        }
        if (filtersDataDiagram.updateData) {
          setUpdateDataInterval(setInterval(updateData, 30000));
        }
        updateData();
      }
    })()
  }, [filtersDataDiagram]);

  useEffect(() => {
    setTableAllCount(tableData.reduce((acc, item) => {
      acc[item.story_status_key] += item.count;
      return acc;
    }, {open: 0, targeted_action: 0, viewed_30: 0, viewed_70: 0}));
  }, [tableData, filtersDataDiagram]);

  const submitHandler = (filters, callback) => {
    setFiltersDataDiagram(filters);
    callback();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'story', //access nested data with dot notation
        header: 'Название сториса',
      },
      {
        accessorKey: 'story_status',
        header: 'Статус',
        Cell: ({ row }) => storiesStore.storiesStatus[row.original.story_status_key],
      },
      {
        accessorKey: 'count',
        size: 220,
        header: 'Количество',
        Footer: () => (
          <Stack>
            {(!filtersDataDiagram.story_status?.length || filtersDataDiagram.story_status.includes('open')) && (
              <Box color="primary.main">Всего открыто: {tableAllCount.open.toLocaleString()}</Box>
            )}
            {(!filtersDataDiagram.story_status?.length || filtersDataDiagram.story_status.includes('targeted_action')) && (
              <Box color="primary.main">Всего совершенно целевых действий: {tableAllCount.targeted_action.toLocaleString()}</Box>
            )}
            {(!filtersDataDiagram.story_status?.length || filtersDataDiagram.story_status.includes('viewed_30')) && (
              <Box color="primary.main">Всего просмотрено 30%: {tableAllCount.viewed_30.toLocaleString()}</Box>
            )}
            {(!filtersDataDiagram.story_status?.length || filtersDataDiagram.story_status.includes('viewed_70')) && (
              <Box color="primary.main">Всего просмотрено 70%: {tableAllCount.viewed_70.toLocaleString()}</Box>
            )}
          </Stack>
        ),
      },
    ],
    [tableAllCount, tableData, filtersDataDiagram],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    enableFullScreenToggle: false,
    groupedColumnMode: 'remove',
    layoutMode: 'grid',
    positionToolbarAlertBanner: 'bottom',
    renderToolbarAlertBannerContent: renderToolbarAlertBannerContent,
    enableGrouping: true,
    initialState: {
      density: 'compact',
      grouping: ['story'],
      pagination: {pageIndex: 0, pageSize: 300}
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  return (
    <Paper style={styledPaper}>
      {filterFields && (
        <LabelWithFiltersModal
          fullWidth={true}
          type={"filter"}
          filtersData={filtersDataDiagram}
          filtersFields={filterFields}
          label={label}
          textButtonFilter={"Применить"}
          submitHandler={submitHandler}
        />
      )}
      {(loading || !isInitialized) && <AbsoluteLoader />}
      {isInitialized && (
        <>
          {filtersDataDiagram.type !== 'table' && chartsData && (
            <ChartComponent
              textButtonFilter="Применить"
              filtersDataDiagram={filtersDataDiagram}
              type={filtersDataDiagram.type || 'bar'}
              nameDiagram={"WidgetStoriesAnalytics"}
              data={chartsData}
              margin={{
                top: 10,
              }}
              interval={filtersDataDiagram?.interval || 'day'}
            />
          )}
          {filtersDataDiagram.type === 'table' && tableData && (
            <div style={{
              maxHeight: '100%',
              overflow: "auto"
            }} className={'material-table'}>
              <MaterialReactTable table={table} />
            </div>
          )}
        </>
      )}
    </Paper>
  )
});
