import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FavoriteIcon from "./icons/Favorite";
import { ListItemIcon, ListItemText } from "@mui/material";
import apiv2 from "../../../apiv2";
import { getFavoriteDoctors, setActiveSpeciality, setSelectedDoctor } from "../fullScheduleStore";
import { useStore } from "effector-react";
import { currentUser } from "../../../stores/auth";
import styled from "styled-components";
import { colors } from "../../styleguide/colors";
import PlusIcon from "./icons/Plus";
import SearchIcon from "./icons/Search";
import MenuIcon from '@mui/icons-material/Menu';

const ButtonBadge = styled.div`
  position: absolute;
  right: 0;
  background-color: ${colors.white};
  color: ${colors.magenta};
  border: 1px solid ${colors.magenta};
  padding: 0 6px;
  font-size: 12px;
  line-height: 19px;
  border-radius: 99em;
  min-width: 21px;
  box-sizing: border-box;
  text-align: center;
`;

export const DoctorMenuNew = props => {
  const {
    doctor,
    inFavourites,
    offScheduleEntriesCount,
    openScheduleInsertWithoutTime
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { doctor: currentDoctor } = useStore(currentUser);

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {currentDoctor.id !== doctor.id && (
          <MenuItem onClick={async () => {
            if (inFavourites) {
              await apiv2.doctors.removeDoctorFromFavourites(doctor.id);
            } else {
              await apiv2.doctors.addDoctorToFavourites(doctor.id);
            }
            getFavoriteDoctors();
            // appendOrUpdateData({
            //     favourites_only: true
            // });
          }}>
            <ListItemIcon>
              <FavoriteIcon active={inFavourites} />
            </ListItemIcon>
            <ListItemText>
              {inFavourites ? "Убрать из избранного" : "Добавить в избранное"}
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={openScheduleInsertWithoutTime}>
          <ListItemIcon>
            <PlusIcon />
          </ListItemIcon>
          <ListItemText>
            Прием вне расписания
            {offScheduleEntriesCount > 0 && (
              <ButtonBadge>{offScheduleEntriesCount}</ButtonBadge>
            )}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          setSelectedDoctor(doctor);
          setActiveSpeciality(null);
        }}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText>
            Расписание врача
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}