import React, { useState } from "react";
import {observer} from 'mobx-react';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

export const BonusSchemaWelcomeMessageModal = observer(() => {
  const [opened, setOpened] = useState(true);

  const handleOnClickByLink = (link) => (event) => {
    if (!window.ReactNativeWebView) {
      return;
    }

    window.ReactNativeWebView.postMessage(JSON.stringify({ event: "openLink", link }));
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <Dialog
      onClose={() => setOpened(false)}
      open={opened}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
         Новый раздел "Мой прогресс"
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpened(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography marginTop={'10px'} gutterBottom>
          С 1 октября запускается прогрессивная шкала мотивации для медицинской команды.
          <br/>
          <br/>
          На данной странице можно будет отслеживать свой прогресс.
          <br/>
          <br/>
          На шкале с анимацией показан текущий процент врача, необходимое количество пациентов до преодоления следующего порога и другие показатели работы.
          <br/>
          <br/>
          Количество пациентов(пациентопоток) рассчитывается как:
          <br/>
          <Typography fontWeight="bold">количество оказанных приёмов в Асе с выставленной услугой</Typography> (приемы, в которых услуга не выставлена - не учитываются в расчете)
          <br/>
          <br/>
          Инструкция по работе с разделом "Мой прогресс":
          <a
            target="_blank"
            href="http://surl.li/bqbhgi"
            onClick={handleOnClickByLink("http://surl.li/bqbhgi")}
          >
            http://surl.li/bqbhgi
          </a>
        </Typography>
        <Typography fontSize={'14px'} color={'gray'} marginTop={'10px'} gutterBottom>
          Если вы видите неправильную работу страницы или неверные показатели на ней, то, пожалуйста, сообщите команде поддержке об этом.
          <br/>
          Кнопка "Помощь" снизу справа или через телеграмм(
          <a
            target="_blank"
            href="https://t.me/fominclinic_internalsupport_bot"
            onClick={handleOnClickByLink("https://t.me/fominclinic_internalsupport_bot")}
          >
            https://t.me/fominclinic_internalsupport_bot
          </a>
          )
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={() => setOpened(false)}>
          Хорошо
        </Button>
      </DialogActions>
    </Dialog>
  );
});
