//@flow
import React from "react";
import styled from "styled-components";
import { HeadingH2 } from "../styleguide/typography";

const EmbryosLayoutContainer = styled.div`
  padding: 30px 100px 100px;
  @media (max-width: 768px) {
    padding: 15px 40px;
    height: calc(100vh - 100px);
    overflow-y: auto;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

export const EmbryosLayout = (props: { children: React$Node, header: string }) => {
  return (
    <EmbryosLayoutContainer>
      <HeadingH2>{props.header}</HeadingH2>
      {props.children}
    </EmbryosLayoutContainer>
  );
};

export default EmbryosLayout;
