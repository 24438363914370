// @flow
import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { PrescribedServicesReportOnePage } from "./PrescribedServicesReportOnePage";
import { PrescribedServicesReportTwoPage } from "./PrescribedServicesReportTwoPage";
import { PrescribedServicesReportThreePage } from "./PrescribedServicesReportThreePage";
import {PrescribedServicesReportFourPage} from "./PrescribedServicesReportFourPage";
import {PrescribedServicesReportFivePage} from "./PrescribedServicesReportFivePage";

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const PrescribedServicesPage = observer(() => {
  const [activeTab, setActiveTab] = React.useState('table');
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Container>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="по пациентам" value={'table'} />
            <Tab label="по специалистам (врачам) назначавшим" value={'table2'} />
            <Tab label="по специализациям назначавшим" value={'table3'} />
            <Tab label="по услугам" value={'table4'} />
            <Tab label="по категориям" value={'table5'} />
          </Tabs>
        </Box>
        <div style={{ display: activeTab !== 'table' ? 'none' : 'block' }}>
          <PrescribedServicesReportOnePage />
        </div>
        <div style={{ display: activeTab !== 'table2' ? 'none' : 'block' }}>
          <PrescribedServicesReportTwoPage />
        </div>
        <div style={{ display: activeTab !== 'table3' ? 'none' : 'block' }}>
          <PrescribedServicesReportThreePage />
        </div>
        <div style={{ display: activeTab !== 'table4' ? 'none' : 'block' }}>
          <PrescribedServicesReportFourPage />
        </div>
        <div style={{ display: activeTab !== 'table5' ? 'none' : 'block' }}>
          <PrescribedServicesReportFivePage />
        </div>
      </Container>
    </>
  );
});
