import { useEffect, useMemo, useState } from "react";
import { useStore } from "effector-react";
import { currentUser } from "../stores/auth";
import moment from "moment";
import { READABLE_DATE_TIME_FORMAT, SERVER_DATE_FORMAT, SERVER_TIME_FORMAT } from "./dates";
import html2canvas from "html2canvas";
import { FEATURE, useApplicationFeatureEnabled } from "./applicationFeatures";
import ticketStore from "../stores/ticketStore";

const date = moment()
  .utc()
  .format(SERVER_DATE_FORMAT);
const time = moment()
  .utc()
  .format(SERVER_TIME_FORMAT);

// https://stackoverflow.com/a/48845206
function cleanStringify(object) {
  if (object && typeof object === "object") {
    object = copyWithoutCircularReferences([object], object);
  }
  return JSON.stringify(object);

  function copyWithoutCircularReferences(references, object) {
    let cleanObject = {};
    if (
      object.constructor &&
      object.constructor.name !== "Object" &&
      object.constructor.name !== "Array"
    ) {
      return { info: `Instance of ${object.constructor.name}` };
    }
    Object.keys(object).forEach(function(key) {
      let value = object[key];
      if (value && typeof value === "object") {
        if (references.indexOf(value) < 0) {
          references.push(value);
          cleanObject[key] = copyWithoutCircularReferences(references, value);
          references.pop();
        } else {
          cleanObject[key] = "###_Circular_###";
        }
      } else if (typeof value !== "function") {
        cleanObject[key] = value;
      }
    });
    return cleanObject;
  }
}

const getBlobJSONFile = (data, name, ...additionalData) => {
  const fileFormData = new FormData();

  const blobFile = new Blob(
    [
      cleanStringify({
        log: data,
        ...additionalData
      })
    ],
    { type: "application/json;charset=utf-8" }
  );

  fileFormData.append("name", name);
  fileFormData.append("file", blobFile, name);

  return fileFormData;
};

/** - html2canvas(document.body) - создание слепка дом-дерева
 * - .then(canvas) - сформированное изображение canvas
 * - canvas.toBlob - конвертация в blob-формат */
const getBlobCanvasScreenshotFile = async () => {
  const imageFormData = new FormData();
  const image = await new Promise(resolve => {
    return html2canvas(document.body).then(canvas => canvas.toBlob(resolve, "image/png"));
  });

  imageFormData.append("name", `screenshot.png`);
  imageFormData.append("file", image, `screenshot.png`);

  return imageFormData;
};

export const useActionsLoggingTool = () => {
  const ENABLE_ACTIONS_LOGGING_TOOL = useApplicationFeatureEnabled(
    FEATURE.ENABLE_ACTIONS_LOGGING_TOOL && process.env.NODE_ENV !== "development"
  );
  const [widgetSupport, setWidgetSupport] = useState(null);
  const [bodyObserver, setBodyObserver] = useState(null);
  const user = useStore(currentUser);
  const info = {
    userId: user?.id || "not authorized",
    datetime: `${date}, ${time} - utc`,
    url: location.href
  };
  setTimeout(() => setWidgetSupport(document.getElementById("KitWidgetContainer")), 100);

  useMemo(() => {
    if (!ENABLE_ACTIONS_LOGGING_TOOL) {
      return;
    }
    if (console.everything === undefined || window._loggingRequests === undefined) {
      /** defaultLog | defaultError - для того, чтобы не нарушить работу стандартных функций консоли */
      const getDateTime = () =>
        `${moment()
          .utc()
          .format(READABLE_DATE_TIME_FORMAT)} UTC`;
      console.everything = [];
      window._loggingRequests = [];

      console.defaultLog = console.log.bind(console);
      console.log = function() {
        console.everything.push({
          type: "LOG",
          datetime: getDateTime(),
          value: Array.from(arguments)
        });
        console.defaultLog(...arguments);
      };

      console.defaultInfo = console.info.bind(console);
      console.info = function() {
        console.everything.push({
          type: "INFO",
          datetime: getDateTime(),
          value: Array.from(arguments)
        });
        console.defaultInfo(...arguments);
      };

      console.defaultError = console.error.bind(console);
      console.error = function() {
        console.everything.push({
          type: "ERROR",
          datetime: getDateTime(),
          value: Array.from(arguments)
        });
        console.defaultError(...arguments);
      };

      window.loggingRequest = function() {
        const { url, status, params, body } = arguments[0];

        window._loggingRequests.push({
          type: "REST API",
          datetime: getDateTime(),
          value: {
            url,
            status,
            params,
            body
          }
        });
      };
    }
  }, [ENABLE_ACTIONS_LOGGING_TOOL]);

  /** Наблюдатель для изменения класса виджета (is-closed | is-open) */
  const observer = useMemo(() => {
    if (!ENABLE_ACTIONS_LOGGING_TOOL) {
      return null;
    }
    return new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === "class") {
          if (mutation.target.classList.contains("is-open")) {
            setBodyObserver(mutation.target.getElementsByClassName("kit-widget-messages")[0]);
          } else {
            setBodyObserver(null);
          }
        }
      });
    });
  }, [ENABLE_ACTIONS_LOGGING_TOOL]);

  useEffect(() => {
    if (!ENABLE_ACTIONS_LOGGING_TOOL || !widgetSupport || !observer) {
      return;
    }
    /** Наблюдаем за элементом widgetSupport */
    observer.observe(widgetSupport, { attributes: true });
  }, [widgetSupport, observer, ENABLE_ACTIONS_LOGGING_TOOL]);

  useEffect(() => {
    if (!ENABLE_ACTIONS_LOGGING_TOOL || !bodyObserver) {
      return;
    }

    /** Слушатель за нажатием на кнопку widgetSupport - "Оператор" */
    const handleOnClick = async event => {
      if (event.target.innerText !== "Оператор" || event.target.nodeName !== "BUTTON") {
        return;
      }

      const screenshotBlobFile = await getBlobCanvasScreenshotFile();
      const consoleBlobFile = getBlobJSONFile(console.everything, "console.json", info);
      const requestBlobFile = getBlobJSONFile(window._loggingRequests, "requests.json", info);

      const response = await ticketStore.postCreateTicket({
        status: "created",
        priority: "medium",
        author: user?.id || "",
        description: "vox-support",
        title: "Автоматическое создание обращения",
        area: "asya",
        new_files: [consoleBlobFile, requestBlobFile, screenshotBlobFile]
      });

      const input = widgetSupport.getElementsByClassName("kit-widget-chat-input")[0].querySelector("p");
      const button = widgetSupport.getElementsByClassName("kit-widget-chat-input__send-btn")[0];

      if (!response || !input || !button) {
        return;
      }

      input.textContent = `Мое обращение в техническую поддержку под номером ${response.id}.`;
      setTimeout(() => button.click());
    };

    bodyObserver.addEventListener("click", handleOnClick);
    return () => bodyObserver.removeEventListener("click", handleOnClick);
  }, [bodyObserver, ENABLE_ACTIONS_LOGGING_TOOL]);
};
