import styled, { css } from "styled-components";
import MaskedInput from "react-text-mask";

import { colors } from "../../styleguide/colors";

export const Container = styled.div``;

export const Input = styled(MaskedInput)`
  width: 85px;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  font-family: inherit;

  ${({ error }) =>
    error &&
    css`
      color: ${colors.pomegranate};
    `}

  &::placeholder {
    color: ${colors.silver};
  }
`;

export const CalendarWrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: calc(8px + 100%);
  left: -16px;
  box-shadow: 0px 2px 31px rgba(0, 0, 0, 0.09);
`;
