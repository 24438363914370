import ApiService from "../../../apiv2/apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class CorporateCompany {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  getCompanyInfo() {
    return this.apiService.get(`corporate-portal/company/articles`);
  }
}

export default new CorporateCompany();
