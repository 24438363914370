import { makeAutoObservable } from "mobx";
import apiv2 from '../../src/apiv2'

class AccountStore {
  activeTab = "services";
  serviceDeleteModalData = null;
  showAccountDeleteServiceModal = false;
  showAccountAddServiceModal = false;
  services = [];
  isInitialized = false;

  constructor() {
    makeAutoObservable(this);
  }

  setActiveTab(tab) {
    this.activeTab = tab;
  }

  setShowAccountDeleteServiceModal(value) {
    this.showAccountDeleteServiceModal = value;
    if (!value) {
      this.serviceDeleteModalData = null;
    }
  }

  setShowAccountAddServiceModal(value) {
    this.showAccountAddServiceModal = value;
  }

  setServiceDeleteModalData(data) {
    this.serviceDeleteModalData = data;
  }

  *fetchDoctorServices() {
    try {
      this.services = yield apiv2.services.list({ per_page: 1000 });
    } catch (e) {
      console.log(e);
    }
  }

  *deleteDoctorService() {
    try {
      yield apiv2.services.delete(this.serviceDeleteModalData.id);
      this.services = this.services.filter(item => item.id !== this.serviceDeleteModalData.id)
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }

  }

  *addDoctorService(formData) {
    try {
      const newServices = yield apiv2.services.create(formData);
      this.services = [...this.services, newServices];
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  *initialize() {
    if (!this.isInitialized) {
      try {
        yield this.fetchDoctorServices();
        this.isInitialized = true;
      } catch (e) {
        console.log(e);
      }
    }
  }
}

export default new AccountStore();
