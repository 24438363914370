//@flow
import React from "react";
import styled from "styled-components";

import { HeadingH3 } from "../styleguide/typography";
import { ModalWithCloseButtonLayout } from "../modal/ModalWithCloseButtonLayout";
import { useModalEscape } from "../modal/useModalEscape";
import { ModalFade } from "../modal/ModalFade";
import { ModalBackground } from "../modal/ModalBackground";
import { DefaultButton, Container as ButtonContainer } from "../buttons/DefaultButton";
import { PrimaryButton, PrimaryButtonContainer } from "../buttons/PrimaryButton";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  width: 653px;
  padding: 35px 48px 40px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: auto;
    padding: 25px 30px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

const ConfirmRemoveContainer = styled.div`
  width: 640px;
  padding: 106px 0 80px;
  box-sizing: border-box;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 61px;
`;

const CancelButtonContinaer = styled(ButtonContainer)`
  width: 180px;
  height: 60px;
  border: none;
  box-shadow: 2px 7px 9px rgba(0, 0, 0, 0.03);
  margin-right: 40px;
`;

const CancelButtonText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
  color: ${colors.black};
`;

const ConfrimRemoveHeadingContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;

const RemoveButtonContainer = styled(PrimaryButtonContainer)`
  width: 180px;
`;

export const FileEditModal = (props: {
  isShown: boolean,
  isShownConfirmRemove: boolean,
  hide: () => void,
  onConfirmRemove: () => Promise<mixed>,
  onCancelRemove: () => void,
  form?: React$Node
}) => {
  const { isShown, isShownConfirmRemove, onConfirmRemove, onCancelRemove, hide, form } = props;

  useModalEscape(hide);

  return (
    <ModalFade>
      {isShown && (
        <ModalBackground>
          {isShownConfirmRemove ? (
            <ModalWithCloseButtonLayout handleClose={onCancelRemove}>
              <ConfirmRemoveContainer>
                <ConfrimRemoveHeadingContainer>
                  <HeadingH3>Вы уверены что хотите удалить файл?</HeadingH3>
                </ConfrimRemoveHeadingContainer>
                <ButtonsContainer>
                  <DefaultButton
                    onClick={onCancelRemove}
                    overrides={{
                      Container: {
                        component: CancelButtonContinaer
                      },
                      Text: {
                        component: CancelButtonText
                      }
                    }}>
                    Отменить
                  </DefaultButton>
                  <PrimaryButton
                    onClick={onConfirmRemove}
                    primaryButtonContainer={RemoveButtonContainer}>
                    Удалить
                  </PrimaryButton>
                </ButtonsContainer>
              </ConfirmRemoveContainer>
            </ModalWithCloseButtonLayout>
          ) : (
            <ModalWithCloseButtonLayout handleClose={hide}>
              <Container>
                <HeadingH3>Редактировать файл</HeadingH3>
                {form}
              </Container>
            </ModalWithCloseButtonLayout>
          )}
        </ModalBackground>
      )}
    </ModalFade>
  );
};
