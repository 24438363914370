//@flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";

import { PSmall } from "../styleguide/typography";

const HeadCell = styled.th`
  padding: 14px 0;
  text-align: left;
  @media (max-width: 480px) {
    padding: 5px 0;
  }
`;

const Text = styled(PSmall)`
  font-weight: 500;
`;

export type AppointmentHistoryColumnType = { [string]: { name: string } };

export const AppointmentHistoryTableHead = (props: {| columns: AppointmentHistoryColumnType |}) => (
  <thead>
    <tr>
      {_.map(props.columns, (column, index) => (
        <HeadCell key={index}>
          <Text>{column.name}</Text>
        </HeadCell>
      ))}
    </tr>
  </thead>
);
