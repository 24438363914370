import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Doctors {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getAll() {
    return this.apiService.get(`doctors`, {}, {}, true, '', true);
  }

  getListDoctors({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params}) {
    return this.apiService.post(`dynamic/doctor/list`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params});
  }

  getListDoctorsOrganization({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params}) {
    return this.apiService.post(`dynamic/doctororganization/list`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params});
  }

  getFavouriteDoctors() {
    return this.apiService.get(`favorite-doctors`, {}, {}, true, '', true);
  }

  addDoctorToFavourites(doctorId) {
    return this.apiService.post(`doctors/${doctorId}/favorite`);
  }

  removeDoctorFromFavourites(doctorId) {
    return this.apiService.delete(`favorite-doctors/${doctorId}`);
  }

  patchDoctorPermission(doctorId, params) {
    return this.apiService.patch(`doctor/${doctorId}/add_permission`, {}, params);
  }

  getEmbryologists() {
    return this.apiService.get(`doctors/embryologists`);
  }

  async getSalary(filters) {
    return this.apiService.get(`salary`, filters, {}, true, '', true);
  }

  sendTestPushNotification() {
    return this.apiService.get(`test-push-doctor`);
  }

  sendPinCodeNotification() {
    return this.apiService.get(`push-pin-code`);
  }

  checkPinCode(pin) {
    return this.apiService.post(`check-pin-code`, {}, { pin });
  }

  analyticsDoctorsInChat() {
    return this.apiService.post(`chat_doctors/analytics/metric`);
  }

  getBonusSchemaData() {
    return this.apiService.get(`bonus-scheme`);
  }
}

export default new Doctors();
