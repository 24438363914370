import * as React from "react";

export const IconCamera = ({style}) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g clipPath="url(#clip0_101_5430)">
        <path d="M16.25 19.5H3.5C3.10218 19.5 2.72064 19.342 2.43934 19.0607C2.15804 18.7794 2 18.3978 2 18V6C2 5.60218 2.15804 5.22064 2.43934 4.93934C2.72064 4.65804 3.10218 4.5 3.5 4.5H16.25C16.6478 4.5 17.0294 4.65804 17.3107 4.93934C17.592 5.22064 17.75 5.60218 17.75 6V9.045L21.815 6.1425C21.9269 6.0628 22.0586 6.01538 22.1957 6.00543C22.3327 5.99547 22.4699 6.02336 22.5922 6.08605C22.7144 6.14874 22.8171 6.24382 22.8891 6.3609C22.961 6.47799 22.9994 6.61259 23 6.75V17.25C22.9994 17.3874 22.961 17.522 22.8891 17.6391C22.8171 17.7562 22.7144 17.8513 22.5922 17.9139C22.4699 17.9766 22.3327 18.0045 22.1957 17.9946C22.0586 17.9846 21.9269 17.9372 21.815 17.8575L17.75 14.955V18C17.75 18.3978 17.592 18.7794 17.3107 19.0607C17.0294 19.342 16.6478 19.5 16.25 19.5ZM3.5 6V18H16.25V13.5C16.2506 13.3626 16.289 13.228 16.3609 13.1109C16.4329 12.9938 16.5356 12.8987 16.6578 12.8361C16.7801 12.7734 16.9173 12.7455 17.0543 12.7554C17.1914 12.7654 17.3231 12.8128 17.435 12.8925L21.5 15.795V8.205L17.435 11.1075C17.3231 11.1872 17.1914 11.2346 17.0543 11.2446C16.9173 11.2545 16.7801 11.2266 16.6578 11.164C16.5356 11.1013 16.4329 11.0062 16.3609 10.8891C16.289 10.772 16.2506 10.6374 16.25 10.5V6H3.5Z" fill="var(--svg-fill, #000)"/>
      </g>
      <defs>
        <clipPath id="clip0_101_5430">
          <rect width="24" height="24" fill="var(--svg-fill, #000)" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
};
