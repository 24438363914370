// @flow
import closeIcon from "./icons/close.svg";
import styled from "styled-components";

export const InfoCloseButton = styled.button`
  border: none;
  width: 24px;
  height: 24px;
  background: url(${closeIcon});
`;
