// @flow
import * as React from "react";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";

import { BaseStyledInput as Input } from "./BaseStyledInput";
import { InputText } from "../styleguide/typography";
import { ErrorContainer, ErrorText } from "../styleguide/form";

const Container = styled.div`
  width: 239px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const Icon = () => null;

const OverridableProps = {
  Container,
  Input,
  Icon,
};

export const DefaultInput = (props: {|
  value?: string | number,
  name?: string,
  accept?: string,
  inputRef?: string,
  step?: string,
  tabIndex?: number,
  placeholder?: string | number,
  overrides?: ExtractOverridesProps<typeof OverridableProps>,
  handleChange: string => mixed,
  handleInput?: string => mixed,
  onBlur?: any => mixed,
  onPaste?: any => mixed,
  onFocus?: any => mixed,
  onKeyDown?: any => mixed,
  selectValueAfterFocus?: boolean,
  type?: "text" | "password" | "number" | "file",
  error?: string | boolean,
  disabled?: boolean,
  autoFocus?: boolean,
  suffix?: React$Node
|}) => {
  const onFocus = (event) => {
    if (props.onFocus) {
      return props.onFocus(event);
    }
    if (props.selectValueAfterFocus) {
      event.target.select();
    }
    return null;
  }
  return (
    <Container {...o}>
      <Icon {...o} />
      <Input
        as="input"
        name={props.name}
        tabIndex={props.tabIndex}
        type={props.type}
        accept={props.accept}
        ref={props.inputRef}
        className={props.error && 'error-field'}
        modifiers={[
          props.error && "error",
          ...((o: any).modifiers instanceof Array ? (o: any).modifiers : [(o: any).modifiers])
        ]}
        placeholder={props.placeholder}
        value={props.value}
        onChange={event => props.handleChange(event.currentTarget.value)}
        onInput={event => props.handleInput && props.handleInput(event)}
        onPaste={event => props.onPaste && props.onPaste(event.clipboardData.getData("text"))}
        onBlur={props.onBlur && props.onBlur}
        onFocus={onFocus}
        autoFocus={props.autoFocus}
        onKeyDown={props.onKeyDown && props.onKeyDown}
        disabled={props.disabled}
        {...o}
      />
      {props.suffix}
      {props.error && typeof props.error === "string" && (
        <ErrorContainer {...o}>
          <ErrorText {...o}>{props.error}</ErrorText>
        </ErrorContainer>
      )}
    </Container>
  );
};
