// @flow
import { useEffect, useMemo } from "react";
import { useStore } from "effector-react";
import {
  appointmentFieldValuesStore,
  setServicesDescription
} from "../../stores/appointmentFieldValues";
import type { TAppointment_id } from "../../domain/entities/Appointment";
import { createEvent, forward } from "effector";

export const useServiceDescription = (appointment_id: TAppointment_id, initialValue?: string) => {
  const valueStore = useMemo(
    () =>
      appointmentFieldValuesStore.map(store =>
        store[appointment_id] && typeof store[appointment_id].services_description === "string"
          ? store[appointment_id].services_description
          : initialValue || ""
      ),
    [appointment_id, initialValue]
  );
  const value = useStore(valueStore);

  const onChange = useMemo(() => createEvent<string>(), [appointment_id]);

  useEffect(() => {
    forward({
      from: onChange.map(payload => ({ appointment_id, services_description: payload })),
      to: setServicesDescription
    });
  }, [appointment_id]);

  return [value, onChange];
};
