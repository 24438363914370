// @flow
import React, { useEffect, useMemo } from "react";
import { MaterialTable } from "../common/MaterialTable";
import { useForm } from "react-hook-form";
import { FormInputDate } from "../common/dynamic-form/fields/FormInputDate";
import { Grid, Paper } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import { READABLE_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from "../../utils/dates";
import styled from "styled-components";
import { observer } from "mobx-react";
import organizationsStore from "../../stores/organizationsStore";
import voxPatientConsentStore from "../../stores/voxPatientConsentStore";

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const VoxPatientConsent = observer(() => {
  const date_end = moment().set({ hour: 23, minute: 59, second: 59 }).format(SERVER_DATE_TIME_FORMAT);
  const date_start = moment().set({ hour: 0, minute: 0, second: 0 }).set('month', moment().get('month') - 1).format(SERVER_DATE_TIME_FORMAT);

  const defaultValuesDates = {
    date_start,
    date_end
  }

  const methods = useForm({
    defaultValues: defaultValuesDates,
  });
  const { handleSubmit, control, setValue } = methods;

  const afterInitializeParams = (callback) => {
    if (!voxPatientConsentStore.globalFiltersTable.created_at__lte){
      voxPatientConsentStore.setGlobalFiltersTable({
        created_at__gte: date_start,
        created_at__lte: date_end,
      }, false)
    } else {
      setValue('date_start', voxPatientConsentStore.globalFiltersTable.created_at__gte)
      setValue('date_end', voxPatientConsentStore.globalFiltersTable.created_at__lte)
    }
    if (!voxPatientConsentStore.sortingTable.includes('-created_at')) {
      voxPatientConsentStore.setSortingTable([...voxPatientConsentStore.sortingTable, '-created_at'])
    }
    callback();
  }

  const onSubmit = (data) => {
    const filters = {
      created_at__gte: data.date_start,
      created_at__lte: data.date_end,
    };

    voxPatientConsentStore.setGlobalFiltersTable(filters);
  }

  const topToolbarCustomActions = [
    {
      key: "save_to_csv",
      options: {
        color: "success",
        variant: "outlined",
        onClick: () => voxPatientConsentStore.saveToFile()
      },
      text: "Скачать CSV"
    }
  ];

  const additionalFilters = (
    <Paper style={{
      padding: '20px'
    }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid alignItems={"center"} container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker="dateTime" label="Дата от"  name="date_start" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker="dateTime" label="до " name="date_end" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button type={"submit"} size={"large"} variant="contained" color={"success"}>
              Применить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'fio', //normal accessorKey
        header: 'ФИО',
        filterFn: 'icontains',
        size: 350,
      },
      {
        accessorKey: 'phone_number',
        filterFn: 'icontains',
        size: 350,
        header: 'Телефон пациента',
      },
      {
        accessorKey: 'token',
        filterFn: 'icontains',
        size: 350,
        header: 'Токен рассылки',
      },
      {
        accessorKey: 'created_at',
        header: 'Дата создания',
        enableColumnFilter: false,
        size: 250,
        Cell: ({ cell }) =>
          moment(cell.getValue()).format(READABLE_DATE_FORMAT),
      },
    ],
    [organizationsStore.organizations],
  );

  return (
    <Container>
      <MaterialTable
        isDisableSavingPresets={true}
        disableHeightResizing={true}
        initialState={{ showColumnFilters: true, }}
        additionalFilters={additionalFilters}
        afterInitializeParams={afterInitializeParams}
        store={voxPatientConsentStore}
        topToolbarCustomActions={topToolbarCustomActions}
        columns={columns} />
    </Container>
  );
});
