import React from "react";

import * as S from "./styled/ServicesTreeSearch.styled";

export const ServicesTreeSearch = ({ query, onReset, onChange }) => (
  <S.Container>
    <S.Input value={query} placeholder="Поиск по коду или названию услуги" onChange={onChange} />
    {query && <S.Reset onClick={onReset} />}
  </S.Container>
);
