import { makeAutoObservable } from 'mobx';

class QuestionnaireStore {
  isQuestionnaireSaving = false;

  constructor() {
    makeAutoObservable(this);
  }

  changeIsQuestionnaireSaving = (value) => {
    this.isQuestionnaireSaving = value;
  }
}

export default new QuestionnaireStore();
