// @flow
import styled from "styled-components";
import { colors } from "../styleguide/colors";

export const PointHint = styled.span`
  opacity: 0.8;
  font-size: 13px;
  line-height: 1.46;
  color: ${props => (props.disabled ? colors.gray400 : colors.black)};
  white-space: pre-line;
`;
