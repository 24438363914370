// @flow
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { CorporateContent } from "../components/CorporateContent";
import {
  CorporateBirthdaysWidget,
  CorporateSurveyWidget,
  CorporateVoteWidget
} from "../modules/widgets";
import { BackdropLoader } from "../../components/loader/Loader";
import corporateCompanyStore from "../stores/corporateCompanyStore";
import { CorporateControlPanel } from "../components/CorporateControlPanel";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import { styled } from "@mui/material/styles";

const sideBarContent = [
  {
    name: "survey",
    content: <CorporateSurveyWidget surveyId={11} />
  },
];

const ContentContainer = styled(CorporateWrapper)`
  font-family: "Graphik LCG";
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;

  * {
      font-family: "Graphik LCG" !important;
  }

  img {
      max-width: 100%;
      object-fit: contain;
      max-height: max-content;
      height: auto !important;
  }

  h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;

      @media screen and (max-width: 1200px) {
          font-size: 22px;
      }
      @media screen and (max-width: 640px) {
          font-size: 20px;
      }
  }
    
  ul, ol {
      list-style: inherit!important;
      padding: revert!important;
  }
    
  li {
      padding: 1px 0 !important;
  }

  p {
      line-height: 24px;
  }
`;

export const CorporateCompanyPage = observer(() => {
  useEffect(() => {
    if (corporateCompanyStore.isInitialized) {
      return;
    }
    (async () => {
      await corporateCompanyStore.getCompanyInfo();
    })();
  }, []);

  return (
    <CorporateContent rightSideBarContent={sideBarContent}>
      <BackdropLoader isOpen={!corporateCompanyStore.isInitialized} />
      <CorporateControlPanel
        isLoading={!!corporateCompanyStore.tabs.length}
        tabsParams={{
          isInitialized: !corporateCompanyStore.isInitialized,
          initialTabs: corporateCompanyStore.tabs,
          indexSelectedTab: corporateCompanyStore.selectedTab.index,
          changeSelectedTab: corporateCompanyStore.changeSelectedTab
        }}
      />
      {!!corporateCompanyStore.companyInfoByTab && (
        <ContentContainer
          dangerouslySetInnerHTML={{ __html: corporateCompanyStore.companyInfoByTab }}
        />
      )}
    </CorporateContent>
  );
});
