import { observer } from "mobx-react";
import MuiTooltip from "@mui/material/Tooltip";
import BonusCheckboxActiveIcon from "../../../assets/icons/bonusCheckboxActive.inline.svg";
import BonusCheckboxErrorIcon from "../../../assets/icons/bonusCheckboxError.inline.svg";
import BonusHelpIcon from "../../../assets/icons/bonusHelpIcon.inline.svg";
import BonusCheckboxIcon from "../../../assets/icons/bonusCheckbox.inline.svg";
import React from "react";
import bonusSchemaStore from "../../../stores/bonusSchemaStore";
import {
  BonusPaper,
  FlexBlock,
  BonusTitleBold,
  BonusTitleLevel,
  LevelItem,
  LevelItemTitle,
  ConditionsBlockLevel,
  FlexBlockGapMini,
  BonusText,
  AllLevelsText,
  ConditionsBlock,
  BonusLevelTextColor
} from "./bonusSchema.styled";
import styled from "styled-components";
import BonusUsersIcon from "../../../assets/icons/bonusUsersIcon.inline.svg";
import { Box } from "@mui/material";
import { HorizontalBar } from "./HorizontalBar";

const BonusUsersRoadIcon = styled(BonusUsersIcon)`
    width: 16px;
    height: 16px;
`;

const BonusPaperLevel = styled(BonusPaper)`
    gap: 8px;
`;

export const CurrentLevel = observer(() => {
  const maxCount = bonusSchemaStore.currentLevel.max_count;
  const widthBar = bonusSchemaStore.bonusSchemaData.consultationsCount / maxCount * 100;
  return (
    <>
      <BonusPaperLevel>
        <FlexBlock>
          <BonusTitleBold>Текущий уровень:</BonusTitleBold>
          <BonusTitleLevel>
            <BonusLevelTextColor modifiers={bonusSchemaStore.currentLevel.name}>
              {bonusSchemaStore.currentLevel.title}
            </BonusLevelTextColor>
            <MuiTooltip
              componentsProps={{
                tooltip: { sx: { background: "#ffffff!important" } }
              }}
              title={
                <LevelItem>
                  <LevelItemTitle><BonusLevelTextColor modifiers={bonusSchemaStore.currentLevel.name}>{bonusSchemaStore.currentLevel.title}</BonusLevelTextColor></LevelItemTitle>
                  {bonusSchemaStore.currentLevel.conditions && (
                    <ConditionsBlockLevel>
                      {bonusSchemaStore.currentLevel.conditions.map((item, index) => (
                        <FlexBlockGapMini key={index}>
                          {item.active ? (
                            <BonusCheckboxActiveIcon />
                          ) : (
                            <BonusCheckboxErrorIcon />
                          )}
                          <BonusText>{item.text}</BonusText>
                          {!!item.helpText && (
                            <MuiTooltip
                              title={item.helpText}
                              componentsProps={{
                                tooltip: {
                                  sx: { background: "#ffffff!important" }
                                }
                              }}>
                              <Box sx={{ display: "flex", cursor: "pointer" }}>
                                <BonusHelpIcon />
                              </Box>
                            </MuiTooltip>
                          )}
                        </FlexBlockGapMini>
                      ))}
                    </ConditionsBlockLevel>
                  )}
                  <AllLevelsText onClick={() => bonusSchemaStore.changeShowLevelBonusPopup(true)}>
                    Смотреть все уровни и условия достижения
                  </AllLevelsText>
                </LevelItem>
              }>
              <Box sx={{ display: "flex", cursor: "pointer" }}>
                <BonusHelpIcon />
              </Box>
            </MuiTooltip>
          </BonusTitleLevel>
        </FlexBlock>
        <HorizontalBar color={bonusSchemaStore.currentLevel.name} widthBar={`${widthBar}%`} minWidthBar="75px">
          <FlexBlockGapMini modifiers={'right'} style={{ height: "100%", paddingInline: "16px" }}>
            <BonusUsersRoadIcon />
            <BonusText fontWeight="500">{bonusSchemaStore.bonusSchemaData.consultationsCount}</BonusText>
          </FlexBlockGapMini>
        </HorizontalBar>
        {!!bonusSchemaStore.nextLevel && (
          <>
            <FlexBlock>
              <BonusTitleBold>Следующий уровень:</BonusTitleBold>
              <BonusTitleLevel>
                <BonusLevelTextColor modifiers={bonusSchemaStore.nextLevel.name}>
                  {bonusSchemaStore.nextLevel.title}
                </BonusLevelTextColor>
              </BonusTitleLevel>
            </FlexBlock>
            {bonusSchemaStore.nextLevel.conditions.length && (
              <ConditionsBlock>
                {bonusSchemaStore.nextLevel.conditions.map((item, index) => (
                  <FlexBlockGapMini key={index}>
                    {item.active ? (
                      <BonusCheckboxActiveIcon />
                    ) : (
                      <BonusCheckboxErrorIcon />
                    )}
                    <BonusText>
                      { item.text}
                    </BonusText>
                    {!!item.helpText && (
                      <MuiTooltip title={item.helpText} componentsProps={{
                        tooltip: {
                          sx: { background: '#ffffff!important' }
                        }
                      }}>
                        <Box sx={{ display: "flex", cursor: "pointer" }}>
                          <BonusHelpIcon />
                        </Box>
                      </MuiTooltip>
                    )}
                  </FlexBlockGapMini>
                ))}
              </ConditionsBlock>
            )}
          </>
        )}
      </BonusPaperLevel>
    </>
  )
})
