import React from "react";
export const CommentsModalProvider = (props: {
  children: ({ isModalShow: boolean, showModal: () => mixed, hideModal: () => void }) => React$Node
}) => {
  const [isModalShow, setIsModalShow] = React.useState(false);

  const showModal = () => setIsModalShow(true);
  const hideModal = () => setIsModalShow(false);

  return props.children({ isModalShow, showModal, hideModal });
};