// @flow
import React from "react";
import styled from "styled-components";
import { HeadingH6 } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

const Container = styled.button`
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  outline: none;

  &:focus > * {
    color: ${colors.magenta};
  }

  &:focus:not(:focus-visible) > * {
    color: inherit;
  }
`;

const Underline = styled.div`
  margin-top: 3px;

  width: 100%;
  height: 1px;
  background-color: ${colors.magenta};
`;

export const InfoDiagnosisTab = (props: {
  title: string,
  isActive?: boolean,
  onClick?: () => mixed
}) => {
  const { title, isActive, onClick } = props;
  return (
    <Container onClick={onClick}>
      <HeadingH6 modifiers={isActive && "color"}>{title}</HeadingH6>
      {isActive && <Underline />}
    </Container>
  );
};
