// @flow
import React from "react";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import type { Diagnosis, TDiagnosis_id } from "../../domain/entities/Diagnosis";
import type {
  ConclusionGuideField,
  ConclusionGuideItem
} from "../../domain/value-objects/ConclusionGuide";
import { useDiagnoses } from "./useDiagnoses";
import {
  createConclusionGuideItemsStore,
  primaryDiagnoses
} from "../../stores/decisionSupportStores";
import { getAppointment_id } from "../../domain/entities/Appointment";
import { useStore } from "effector-react";
import * as R from "ramda";
import { getDiagnosis_id } from "../../domain/entities/Diagnosis";
import { combine } from "effector";
import { infoType } from "../../stores/infoType";

const isNotEmpty = R.compose(
  value => !!value,
  R.find(item => item && (!!item.recommended || !!item.not_recommended)),
  R.values
);

export const ConclusionGuideProvider = (props: {
  children: ({
    conclusionGuideItems: { [TDiagnosis_id]: ConclusionGuideItem },
    diagnoses: Array<Diagnosis>,
    isNotEmpty: boolean
  }) => React$Node,
  appointment: AppointmentWithDetails,
  sectionType?: ConclusionGuideField
}) => {
  const { children, appointment, sectionType } = props;

  const type = useStore(infoType);
  const { store: primaryDiagnosesStore } = useDiagnoses(
    primaryDiagnoses,
    getAppointment_id(appointment),
    appointment.primary_diagnoses
  );
  const diagnosesIdsStore = React.useMemo(() => primaryDiagnosesStore.map(R.map(getDiagnosis_id)), [
    primaryDiagnosesStore
  ]);
  const diagnosesIds = useStore(diagnosesIdsStore);

  const conclusionGuideItemsStore = React.useMemo(
    () => createConclusionGuideItemsStore(diagnosesIds, sectionType ? sectionType : type),
    [diagnosesIds, type, sectionType]
  );

  const diagnosesStore = React.useMemo(
    () =>
      combine(
        conclusionGuideItemsStore,
        primaryDiagnosesStore,
        (conclusionGuideItems, allDiagnoses) => {
          return allDiagnoses.filter(
            diagnosis => Object.keys(conclusionGuideItems).indexOf("" + diagnosis.id) >= 0
          );
        }
      ),
    [conclusionGuideItemsStore, primaryDiagnosesStore]
  );

  const conclusionGuideItems = useStore(conclusionGuideItemsStore);
  const diagnoses = useStore(diagnosesStore);
  return children({
    conclusionGuideItems,
    diagnoses,
    isNotEmpty: isNotEmpty(conclusionGuideItems)
  });
};
