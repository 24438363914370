// @flow
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import useReactRouter from "use-react-router";
import type { MedicalFile } from "../../domain/entities/MedicalFile";
import { useApiRequest } from '../../apiv2/useApiRequest';
import { setMedicalFile } from './medicalFileStore';
import apiv2 from '../../apiv2';

export const useMedicalFileFromMedicalFileIdUrl = (): ?MedicalFile => {
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();

  const medicalFileResult = useApiRequest(async () => {
    const medicalFile = await apiv2.medicalFiles.getById(params.medical_file_id);
    setMedicalFile(medicalFile);
    return medicalFile;
  }, [ token, params.medical_file_id ]);
  if (medicalFileResult.data) {
    return medicalFileResult.data;
  }
};
