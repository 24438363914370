import styled from "styled-components";

import { BaseStyledInput } from "../../form-elements/BaseStyledInput";

export const Input = styled(BaseStyledInput)`
  width: 100%;
  box-sizing: border-box;
  border: none;
  resize: none;
  min-height: inherit !important;
  padding: 10px 15px;
  line-height: 1.475;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 15px;
  height: 44px;
`;
