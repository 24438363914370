import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";
import { useStore } from "effector-react";
import { currentUser as currentUserStore } from "../../stores/auth";
import { colors } from "../styleguide/colors";
import { scrollbars } from "../chats/style-elements";
import { DoctorViewNew } from "./doctor-view/DoctorViewNew";
import dayjs from "dayjs";
import "dayjs/locale/ru"
import generalScheduleStore from "../../stores/generalScheduleStore";
import { Tooltip } from "@mui/material";
import { observer } from "mobx-react";
import scheduleInsertsStore, { INSERTS_TYPE } from "../../stores/scheduleInsertsStore";
import { DropDownMenu } from "../drop-down-menu/DropDownMenu";
import AddIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import OnlineLinkIcon from '@mui/icons-material/PhoneForwardedOutlined';
import { READABLE_TIME_FORMAT_MOMENT, SERVER_DATE_FORMAT } from "../../utils/dates";
import doctorsStore from "../../stores/doctorsStore";
import { AppointmentInformationIcons } from "./appointment-view/AppointmentInformationIcons";
import ConfirmedByWhatsAppIcon from "../../assets/icons/general-shedule/confirmedByWhatsAppIcon.inline.svg";
import ConfirmedByCallCenterIcon from "../../assets/icons/general-shedule/confirmedByCallCenterIcon.inline.svg";
import ConfirmedByAdministratorIcon from "../../assets/icons/general-shedule/confirmedByAdministratorIcon.inline.svg";
import InitialAppointmentIcon from "../../assets/icons/general-shedule/initialAppointmentIcon.inline.svg";
import VipIcon from "../../assets/icons/general-shedule/vipIcon.inline.svg";
import IvbIcon from "../../assets/icons/general-shedule/ivbIcon.inline.svg";
import IvfIcon from "../../assets/icons/general-shedule/ivfIcon.inline.svg";
import PatronageIcon from "../../assets/icons/general-shedule/patronageIcon.inline.svg";

export const GeneralScheduleCardBlockList = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  height: max-content;
`;

export const GeneralScheduleCardList = styled.div`
  display: flex;
  position: relative;
  height: ${props => `calc(100% - ${props.heightContainerActions}px)`};
  overflow: auto;

  ${scrollbars};
`;

const GeneralScheduleCardContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  ${props => (props.count ? `width: ${props.densityWidth * props.count}px;` : `width: ${props.densityWidth}px;`)}
  box-sizing: border-box;
  height: max-content;
`;

const DoctorDayScheduleTitle = styled.div`
  flex-shrink: 0;
  border-right: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  background: #FAFAFA;
  font-family: "Graphik LCG";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #262626;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => (props.sticky ? `position: sticky; top: 176px; z-index: 7;` : '')}
`;

const DoctorDaysSchedule = styled.div`
  display: flex;
  height: 100%;
  flex-shrink: 0;
`;

const DoctorTopInfo = styled.div`
  position: sticky;
  top: 0;
  ${props => (props.densityWidth ? `width: ${props.densityWidth}px;` : 'width: 100%;')}
  flex-shrink: 0;
  z-index: 7;
  background: white;
`;
const DoctorDaySchedule = styled.div`
  display: flex;
  flex-direction: column;
  ${props => (props.densityWidth ? `width: ${props.densityWidth}px;` : 'width: 100%;')}
  flex-shrink: 0;
  position: relative;
`;

const DoctorSchedule = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
  list-style-type: none;
  flex-shrink: 0;
  width: 100%;
`;

const TimeSlot = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => (props.active ? `border-color: ${colors.gray200};` : "")}
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  background-color: white;  
  ${props => (props.isEmptyTime ? `background-color: ${colors.gray100};` : "")}
  ${props => (props.reserved ? `background-color: ${colors.green};` : "")}
  ${props => ((props.reserved && props.isOnline) ? `background-color: ${colors.lightBlue};` : "")}
  ${props => (props.selected ? `background-color: #FFF26D;` : "")}
  font-family: "Graphik LCG", monospace;
  z-index: 1;
  height: ${props => (props.count ? `${props.count * 30}px` : '30px')};
  font-size: 12px;
  box-sizing: border-box;
  overflow-y: auto;
  border-right: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  ${props => (props.reserved && props.hasMedicalFile ? "cursor: pointer;" : "")}
  ${props => ((props.reserved || props.top || props.top === 0) ? "position: absolute;" : "")}
  ${props => ((props.reserved || props.top || props.top === 0) ? "left: 0;" : "")}
  ${props => ((props.reserved || props.top || props.top === 0) ? "right: 0;" : "")}
  ${props => ((props.reserved || props.top || props.top === 0) ? `top: ${props.top}px;` : "")}
  ${props => ((props.reserved || props.top || props.top === 0) ? `z-index: 4;` : "")}
  ${scrollbars};

  ${props =>
    !props.disabled && props.active && !props.reserved && props.allowsForeignAppointments
      ? `
          cursor: pointer;
          transition: color 0.1s linear, border-color 0.1s linear;
          &:hover {
            border: 1px solid #F3F3F3;
            border-color: ${colors.magenta};
            color: ${colors.magenta};
          }
        `
      : ""}
    ${props =>
      props.disabled
        ? `
            background-color: ${colors.gray025};
            border-bottom: 1px solid ${colors.white};
            border-right: 1px solid ${colors.white};
            cursor: auto;
          `
        : ""}
  &:hover {
    z-index: 5;
  }
`;

const ReservedTooltipBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: "Graphik LCG";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 100%;
  justify-content: ${props => (props.flexStart ? `flex-start` : 'center')};
`;

const ReservedTooltipTitle = styled.div`
  color: ${colors.black};
`;

const ReservedTooltipComment = styled.div`
  color: #7d7d7d;
`;

const getOffScheduleEntriesCount = (schedule) => {
  return schedule.reduce((acc, item) => {
    if ((item.is_reserved || item.medical_file) && !item.time) {
      acc++;
    }
    return acc;
  }, 0);
};

export const GeneralScheduleCardNew = observer((props) => {
  const getCurrentTime = () => moment().format(READABLE_TIME_FORMAT_MOMENT);
  const [currentTime, setCurrentTime] = useState(getCurrentTime());
  const { groupType } = props;

  const { items, date } = props.cardData;
  let doctor = props.cardData.doctor;
  const currentDate = moment().format(SERVER_DATE_FORMAT);
  const currentUser = useStore(currentUserStore);
  const isMultipleAppointmentMode = scheduleInsertsStore.enableMultipleAppointmentsMode;

  /** Перерасчет текущего времени 1 раз в 10 минут */
  setInterval(() => {
    setCurrentTime(getCurrentTime());
  }, 600000);

  const inFavourites = id =>
    currentUser?.doctor?.id === id
      ? true
      : doctorsStore.favouriteDoctors.some(favouriteDoctor => favouriteDoctor.id === id);

  const getIsBlockedUnscheduledAppointment = () => {
    const isAvailableSlots = Object.keys(items).some((date) => {
      return items[date].filter((slot) => slot.type !== "chat").length;
    });
    const isAvailableDate = Object.keys(items).some((date) => date >= currentDate);
    return !isAvailableSlots || !isAvailableDate;
  };

  const getAvailableDatesForAppointment = () => {
    return Object.keys(items).filter((date) => {
      if (date >= currentDate && items[date].filter((slot) => slot.type !== "chat").length) {
        return date;
      }
    });
  }

  return (
    <GeneralScheduleCardContainer densityWidth={generalScheduleStore.densityCalendarWidth[generalScheduleStore.densityCalendar]} count={_.size(items)}>
      {groupType !== 'date' && (
        <DoctorTopInfo>
          <DoctorViewNew
            dates={getAvailableDatesForAppointment()}
            doctor={doctor}
            inFavourites={inFavourites(doctor.id)}
            offScheduleEntriesCount={getOffScheduleEntriesCount(_.flatten(Object.values(items)))}
            isBlockedUnscheduledAppointment={getIsBlockedUnscheduledAppointment()}
          />
        </DoctorTopInfo>
      )}
      <DoctorDaysSchedule>
        {Object.keys(items).map((dateSchedule, index) => {
          const slotsSchedule = items[dateSchedule];
          const startDate = new Date(`${currentDate} 08:00`);
          const endDate = new Date(`${currentDate} 21:00`);

          const minimalTimeSlot = 15;
          const timeSlotsCount = moment(endDate).diff(startDate, "minutes") / minimalTimeSlot;

          if (!props.cardData.doctor) {
            doctor = slotsSchedule[0].doctor;
          }

          return (
            <DoctorDaySchedule densityWidth={generalScheduleStore.densityCalendarWidth[generalScheduleStore.densityCalendar]} key={`${index}-${dateSchedule}-schedule-date`}>
              {groupType === 'date' ? (
                <DoctorTopInfo densityWidth={generalScheduleStore.densityCalendarWidth[generalScheduleStore.densityCalendar]}>
                  <DoctorViewNew
                    date={slotsSchedule[0].date}
                    doctor={doctor}
                    inFavourites={inFavourites(doctor.id)}
                    offScheduleEntriesCount={getOffScheduleEntriesCount(items[doctor.id])}
                    enableDensityWidth
                    isBlockedUnscheduledAppointment={!slotsSchedule.filter((item) => item.type !== "chat").length || slotsSchedule[0].date < currentDate}
                  />
                  <DoctorDayScheduleTitle>
                    {dayjs(slotsSchedule[0].date).locale('ru').format("dddd DD.MM")}
                  </DoctorDayScheduleTitle>
                </DoctorTopInfo>
              ) : (
                <DoctorDayScheduleTitle sticky={true}>
                  {dayjs(dateSchedule).locale('ru').format("dddd DD.MM")}
                </DoctorDayScheduleTitle>
              )}
              <DoctorSchedule>
                {[...Array(timeSlotsCount)].map((slot, i) => {
                  return (
                    <TimeSlot key={`time-slots-${i}`} active={false} reserved={false} />
                  );
                })}
                {slotsSchedule.map((slot, i) => {
                  let selectedSlot = false;
                  if (isMultipleAppointmentMode) {
                    selectedSlot = !!_.find(scheduleInsertsStore.multipleAppointmentsData, { id: slot.id });
                  }
                  const closestItemWithAppointment = _.find(
                    slotsSchedule,
                    item => slot.id !== item.id && (item.is_reserved || item.medical_file),
                    slotsSchedule.findIndex(
                      scheduleItem => scheduleItem.time === slot.time
                    )
                  );

                  const medicalFile = slot.medical_file;
                  const splitTime = slot.time?.split(':') || '';
                  const disabledSlot =
                    moment(currentDate, "YYYY-MM-DD") > moment(slot.date, "YYYY-MM-DD") ||
                    (moment(slot.date, "YYYY-MM-DD") <= moment(currentDate, "YYYY-MM-DD") && moment(currentTime, "HH:mm") > moment(slot.time, "HH:mm"));
                  /** 7196 Нужно убрать логику на блокировку перекрестных записей по времени и дню - такое может быть
                   * Анастасия сообщила, что время приемов может пересекаться, такой кейс реален */
                  const disabledSlotForMultipleMode = isMultipleAppointmentMode && !!scheduleInsertsStore.multipleAppointmentsData.some((item) => {
                    if (item.id !== slot.id && item.doctor.id === slot.doctor.id && item.date === slot.date) {
                      return item;
                    }
                  })

                  const reserved = slot.is_reserved || !!medicalFile;

                  const handleClickByTimeSlot = () => {
                    if (reserved || (!selectedSlot && disabledSlot) || disabledSlotForMultipleMode || !doctor.allows_foreign_appointments) {
                      return;
                    }

                    if (!isMultipleAppointmentMode) {
                      scheduleInsertsStore.setScheduleInsertDialogData({
                        type: INSERTS_TYPE.BY_TIME_SLOT,
                        slot,
                        closestTime: closestItemWithAppointment?.time || ''
                      });
                    } else {
                      scheduleInsertsStore.addMultipleAppointments({ slot, closestTime: closestItemWithAppointment?.time || "" });
                    }
                  }

                  const handleCreateNewAppointment = () => {
                    if (!window.ReactNativeWebView) {
                      const url =
                        window.location.origin +
                        `/appointment/new/${medicalFile.id}`;
                      window.open(url);
                    } else {
                      history.push(`/appointment/new/${medicalFile.id}`);
                    }
                  }

                  const handleEditingAppointment = () => {
                    scheduleInsertsStore.setScheduleInsertDialogData({
                      type: INSERTS_TYPE.EDITING,
                      slot,
                      closestTime: closestItemWithAppointment?.time || ''
                    });
                  }

                  const handleOpeningOnlineAppointment = () => {
                    window.open(slot.online_link_for_patient, "_blank");
                  }

                  if (!reserved) {
                    const nextSlot = slotsSchedule[i + 1];
                    const isDisabled = (!selectedSlot && disabledSlot) || disabledSlotForMultipleMode;

                    const TimeSlotComponent = (
                      <Tooltip title={doctor.organizationsName.length > 1 ? doctor.organization : ''}>
                        <TimeSlot
                          disabled={isDisabled}
                          isEmptyTime={true}
                          top={(parseInt(splitTime[0]) * 60 + parseInt(splitTime[1]) - 480) * 2}
                          count={doctor.schedule_step / 15}
                          active={true}
                          selected={selectedSlot}
                          allowsForeignAppointments={doctor.allows_foreign_appointments}
                          hasMedicalFile={!!medicalFile}
                          onClick={handleClickByTimeSlot}>
                          {(nextSlot?.is_reserved || nextSlot?.medical_file) ? (
                            <>
                              {slot.time ? moment(slot.time, 'HH:mm:ss').format("HH:mm") : ''} - {moment().set({hours: parseInt(splitTime[0])}).set({minute: parseInt(splitTime[1]) + parseInt(doctor.schedule_step)}).format("HH:mm")}
                            </>
                          ) : (
                            <>
                              {slot.time ? moment(slot.time, 'HH:mm:ss').format("HH:mm") : ''}
                            </>
                          )}
                        </TimeSlot>
                      </Tooltip>
                    );
                    return slot.type === 'chat' && slot.time === null ? '' : (
                      <React.Fragment key={i}>
                        {TimeSlotComponent}
                      </React.Fragment>
                    );
                  } else {
                    const TimeSlotRegistryComponent = (
                      <Tooltip title={doctor.organizationsName.length > 1 ? doctor.organization : ''}>
                        <TimeSlot
                          top={(parseInt(splitTime[0]) * 60 + parseInt(splitTime[1]) - 480) * 2}
                          count={doctor.schedule_step / 15}
                          active={slot.active}
                          allowsForeignAppointments={doctor.allows_foreign_appointments}
                          hasMedicalFile={!!medicalFile}>
                          Запись только через регистратуру
                        </TimeSlot>
                      </Tooltip>
                    );

                    const getMenuItems = slot => {
                      if (!reserved) {
                        return [];
                      }
                      return [
                        {
                          name: "Создать прием",
                          icon: <AddIcon />,
                          handleOnClick: handleCreateNewAppointment
                        },
                        {
                          name: "Редактировать запись",
                          icon: <EditIcon />,
                          handleOnClick: handleEditingAppointment
                        },
                        {
                          isHided: !slot.online_link_for_patient,
                          name: "Онлайн-прием (ссылка для пациента)",
                          icon: <OnlineLinkIcon />,
                          handleOnClick: handleOpeningOnlineAppointment
                        }
                      ].filter(item => !item.isHided);
                    };

                    const infoIconOptions = [
                      { isShow: () => slot.status_wa, icon: <ConfirmedByWhatsAppIcon />, tooltipText: "Запись подтверждена через WhatsApp" },
                      { isShow: () => slot.status_call_center, icon: <ConfirmedByCallCenterIcon />, tooltipText: "Запись подтверждена через колл-центр" },
                      { isShow: () => false, icon: <ConfirmedByAdministratorIcon />, tooltipText: "Запись подтверждена администратором" },
                      { isShow: () => false, icon: <ConfirmedByCallCenterIcon />, tooltipText: "Было направлено сообщение через бота для подтверждения, но подтверждения не было" },
                      { isShow: () => false, icon: <InitialAppointmentIcon />, tooltipText: "Первичный прием" },
                      { isShow: () => false, icon: <VipIcon />, tooltipText: "VIP" },
                      { isShow: () => false, icon: <IvbIcon />, tooltipText: "ИВБ" },
                      { isShow: () => false, icon: <IvfIcon />, tooltipText: "ЭКО" },
                      { isShow: () => false, icon: <PatronageIcon />, tooltipText: "Патронаж" }
                    ]
                      .filter((option) => option.isShow())
                      .map(({ icon, tooltipText }) => ({ icon, tooltipText }));

                    const TimeSlotReservedComponent = (
                      <Tooltip title={doctor.organizationsName.length > 1 ? doctor.organization : ''}>
                        <TimeSlot
                          isOnline={slot.online}
                          top={!slot?.time ? -100 : (parseInt(splitTime[0]) * 60 + parseInt(splitTime[1]) - 480) * 2}
                          count={doctor.schedule_step / 15 * slot.num_appointments}
                          active={slot.active}
                          allowsForeignAppointments={doctor.allows_foreign_appointments}
                          reserved={reserved}
                          hasMedicalFile={!!medicalFile}>
                          <DropDownMenu menuItems={getMenuItems(slot)} view="container" />
                          <ReservedTooltipBody count={doctor.schedule_step / 15} flexStart={slot.patient_full_name && slot.comment}>
                            <ReservedTooltipTitle>
                              {generalScheduleStore.settingsScheduleView?.patientShortFio?.checked ? (
                                <>
                                  {!!slot.patient_full_name && (
                                    <Tooltip title={slot.patient_full_name}>
                                      {`${slot.patient_full_name.split(' ')[0]}`}
                                      {slot.patient_full_name.split(' ')[1] ? ` ${slot.patient_full_name.split(' ')[1][0]}.` : ''}
                                      {slot.patient_full_name.split(' ')[2] ? ` ${slot.patient_full_name.split(' ')[2][0]}.` : ''}
                                    </Tooltip>
                                  )}
                                </>
                              ) : (
                                <>
                                  {slot.patient_full_name}
                                </>
                              )}
                              {(slot.medical_file && generalScheduleStore.settingsScheduleView?.patientBirthDate?.checked)
                                ? `, ${moment(
                                    slot.medical_file.patient.birthdate
                                  ).format("DD.MM.YYYY")}`
                                : ""}
                              <AppointmentInformationIcons data={infoIconOptions} />
                            </ReservedTooltipTitle>
                            {generalScheduleStore.settingsScheduleView?.patientComment?.checked && (
                              <ReservedTooltipComment>
                                {slot.comment}
                              </ReservedTooltipComment>
                            )}
                          </ReservedTooltipBody>
                        </TimeSlot>
                      </Tooltip>
                    );

                    return !doctor.allows_foreign_appointments ? (
                      <React.Fragment key={i}>
                        {TimeSlotRegistryComponent}
                      </React.Fragment>
                    ) : reserved && (
                      <React.Fragment key={i}>
                        {TimeSlotReservedComponent}
                      </React.Fragment>
                    );
                  }
                })}
              </DoctorSchedule>
            </DoctorDaySchedule>
          );
        })}
      </DoctorDaysSchedule>
    </GeneralScheduleCardContainer>
  );
});
