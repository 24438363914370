import { makeAutoObservable } from "mobx";

const API_URL = process.env.EMS_API_URL || location.origin;

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

class ConnectionStability {
  isConnectionModalVisible = false;
  isAsyaAvailable = true;
  isOuterServiceAvailable = true;
  requestCache = {};

  constructor() {
    makeAutoObservable(this);
  }

  async tryMakeRequest(name, URL) {
    try {
      if (!this.requestCache[name]) {
        this.requestCache[name] = fetch(URL, {
          mode: "no-cors",
          cache: "no-cache",
          redirect: "follow",
          referrerPolicy: "no-referrer"
        });
      }
      await this.requestCache[name];
      delete this.requestCache[name];
      return true;
    } catch(e) {
      if (this.isFetchError(e)) {
        delete this.requestCache[name];
        return false;
      }
    }
    return false;
  }

  async waitConnection() {
    this.setAsyaAvailable(await this.tryMakeRequest("asya", API_URL + "/mobile-api/configs"));
    this.setOuterServiceAvailable(await this.tryMakeRequest("outer", "https://google.com"));

    if (!this.isAsyaAvailable) {
      if (!this.isConnectionModalVisible) {
        this.showConnectionErrorModal();
      }
      await delay(5000);
      return await this.waitConnection();
    } else {
      this.closeConnectionErrorModal();
      return true;
    }
  }

  isFetchError(error) {
    return error && error.message === "Failed to fetch";
  }

  setAsyaAvailable(value) {
    this.isAsyaAvailable = value;
  }

  setOuterServiceAvailable(value) {
    this.isOuterServiceAvailable = value;
  }

  showConnectionErrorModal() {
    this.isConnectionModalVisible = true;
  }

  closeConnectionErrorModal() {
    this.isConnectionModalVisible = false;
  }
}

export default new ConnectionStability();