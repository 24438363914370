import * as React from "react";
import { NumericInput } from "../form-elements/NumericInput";
import { getNumberValue } from "../appointment-widgets/getNumberValue";

export const NumberWidget = ({ field, tabIndex, onChange, layoutFieldValue }) => {
  const value = getNumberValue(field, layoutFieldValue);

  return (
    <NumericInput
      value={value}
      tabIndex={tabIndex}
      handleChange={newValue => {
        onChange({
          field_type: field.field_type,
          field_id: field.id,
          value: newValue
        });
      }}
    />
  );
};
