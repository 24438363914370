// @flow
import * as React from "react";
import styled from "styled-components";
import editIcon from "./icons/edit.svg";
import { DefaultButton } from "../buttons/DefaultButton";
import { colors } from "../styleguide/colors";

const Icon = styled.div`
  width: 24px;
  height: 24px;

  mask: url(${editIcon});
  background: ${colors.magenta};
`;

const IconContainer = styled.div`
  margin-right: 6px;
`;

export const EditButton = (props: { onClick?: () => mixed }) => (
  <DefaultButton
    {...props}
    overrides={{
      Icon: {
        component: Icon
      },
      IconContainer: {
        component: IconContainer
      }
    }}>
    Редактировать
  </DefaultButton>
);
