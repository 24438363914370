// @flow
import React from "react";
import { Box, Fab, useScrollTrigger, Zoom } from "@mui/material";
import { KeyboardArrowUp as ArrowUp } from "@mui/icons-material";

export const CorporateScrollTop = ({ target = null }) => {
  const element = target || window;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    target: element,
    threshold: 200
  });

  const handleClick = () => element.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} sx={{ position: "fixed", bottom: 20, right: 20 }}>
        <Fab size="small" color="primary">
          <ArrowUp />
        </Fab>
      </Box>
    </Zoom>
  );
};
