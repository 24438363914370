import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { selectedDoctorStore, setActiveSpeciality, setSelectedDoctor } from "../fullScheduleStore";
import { useStore } from "effector-react";

import searchIcon from "./icons/search-big.svg";
import removeIcon from "./icons/remove.svg";

const DoctorSearchInput = styled.input`
  font-size: 17px;
  line-height: 23px;
  padding: 13px 65px 13px 17px;
  margin: -3px;
  border: none;
  background-color: transparent;
  border-radius: 3px;
  width: 479px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: auto;
  }

  transition: background 0.2s ease-in-out;

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const DoctorSearchInputContainer = styled.div`
    position: relative;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 3px;
    width: 483px;
    transition: border-color 0.2s ease-in-out;
    box-sizing: border-box;
    @media (max-width: 768px) {
      width: auto;
    }
    @media (max-width: 480px) {
      width: 100%;
    }

    ${props =>
      props.iconVisible
        ? `
        &::after {
            position: absolute;
            content: url(${searchIcon});
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
        }
    `
        : ""}

    ${props => (props.inputFocused ? "border: 2px solid transparent;" : "")}
    ${props =>
      props.disabled
        ? css`
            pointer-events: none;
            opacity: 0.5;
          `
        : ""}
`;

const DoctorSearchSuggestionsContainer = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: #fff;
  ${props => (props.visible ? "display: block;" : "display: none;")}
  z-index: 10;
  box-shadow: 0 2px 17px rgba(91, 91, 91, 0.259752);
`;

const DoctorSuggestionItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #f8f8f8;
  }

  ${props => (props.focused ? "background: #f8f8f8;" : "")}
`;

const DoctorSuggestionItemAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
`;

const DoctorSuggestionItemAvatar = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
`;

const DoctorSuggestionSpeciality = styled.div`
  display: flex;
  align-items: center;
  color: #595959;
  font-size: 12px;
  line-height: 12px;
`;

const DoctorSuggestionSpecialityWithOpacity = styled.span`
  opacity: 0.4;
`;

const SelectedDoctor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 14px 10px;
  background: #f8f8f8;
  border-radius: 2px;
`;

const SelectedDoctorRemoveButton = styled.button`
  display: flex;
  border: none;
  background: transparent;
  padding: 0;
  margin-left: 16px;
  outline: none;
  cursor: pointer;
`;

const SelectedDoctorRemoveButtonIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const DoctorAvatarPlaceholder = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: #7d7d7d;
  padding: 0;
  border: 1px solid transparent;
  transition: all 0.2s linear;
  text-transform: uppercase;
  background-clip: content-box;
  margin-right: 8px;
`;

const useKeyboardNavigation = (inputFocused, items, callback) => {
  const [focusedItem, setFocusedItem] = useState(0);
  const onKeyDown = React.useCallback(
    e => {
      if (!inputFocused) return;
      const lastIndex = items.length;
      if (e.key === "ArrowUp" && focusedItem > 0) {
        setFocusedItem(focusedItem - 1);
      }
      if (e.key === "ArrowUp" && focusedItem === 0) {
        setFocusedItem(lastIndex - 1);
      }
      if (e.key === "ArrowDown" && focusedItem < lastIndex) {
        setFocusedItem(focusedItem + 1);
      }
      if (e.key === "ArrowDown" && focusedItem === lastIndex) {
        setFocusedItem(0);
      }
      if (e.key === "Enter") {
        callback(items[focusedItem]);
      }
    },
    [callback, items, inputFocused, setFocusedItem, focusedItem]
  );
  const setFocusPosition = index => {
    setFocusedItem(index);
  };
  const resetFocusPosition = () => {
    setFocusedItem(0);
  };

  const setKeyDownListener = React.useCallback(() => {
    document.addEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  const unsetKeyDownListener = React.useCallback(() => {
    document.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  useEffect(() => {
    if (inputFocused) {
      setKeyDownListener();
    } else {
      unsetKeyDownListener();
    }

    return () => {
      unsetKeyDownListener();
    };
  }, [inputFocused, setKeyDownListener, unsetKeyDownListener]);

  return {
    focusedItem,
    setFocusPosition,
    resetFocusPosition
  };
};

export const DoctorSearch = props => {
  const { doctors, disabled, ...rest } = props;
  const selectedDoctor = useStore(selectedDoctorStore);
  const [suggestionShowed, setSuggestionShowed] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [filteredDoctors, setFilteredDoctors] = useState(doctors);

  const onDoctorSelect = doctor => {
    setSelectedDoctor(doctor);
    setActiveSpeciality(null);
    setSuggestionShowed(false);
    setInputFocused(false);
  };

  const { focusedItem, setFocusPosition, resetFocusPosition } = useKeyboardNavigation(
    inputFocused,
    filteredDoctors,
    item => {
      onDoctorSelect(item);
    }
  );

  return (
    <DoctorSearchInputContainer
      disabled={disabled}
      inputFocused={inputFocused}
      iconVisible={!selectedDoctor}>
      {selectedDoctor ? (
        <SelectedDoctor>
          <DoctorSuggestionItemAvatarContainer>
            {selectedDoctor.photo ? (
              <DoctorSuggestionItemAvatar src={selectedDoctor.photo} />
            ) : (
              <DoctorAvatarPlaceholder>
                {selectedDoctor.last_name.slice(0, 1) + selectedDoctor.first_name.slice(0, 1)}
              </DoctorAvatarPlaceholder>
            )}
            {selectedDoctor.last_name +
              " " +
              selectedDoctor.first_name +
              " " +
              selectedDoctor.middle_name}
          </DoctorSuggestionItemAvatarContainer>
          <DoctorSuggestionSpeciality>
            <DoctorSuggestionSpecialityWithOpacity>
              {selectedDoctor.specialities.map(speciality => speciality.name).join(", ")}
            </DoctorSuggestionSpecialityWithOpacity>
            <SelectedDoctorRemoveButton
              onClick={() => {
                setSelectedDoctor(null);
              }}>
              <SelectedDoctorRemoveButtonIcon src={removeIcon} alt="Remove icon" />
            </SelectedDoctorRemoveButton>
          </DoctorSuggestionSpeciality>
        </SelectedDoctor>
      ) : (
        <DoctorSearchInput
          {...rest}
          onKeyDown={e => {
            const ignoredKeys = ["ArrowUp", "ArrowUp", "Enter"];
            if (ignoredKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
          onInput={e => {
            const includes = target => {
              return target.toLowerCase().includes(e.target.value.trim().toLowerCase());
            };
            resetFocusPosition();
            if (e.target.value.trim().length >= 1) {
              setSuggestionShowed(true);
            } else {
              setSuggestionShowed(false);
            }
            setFilteredDoctors(
              doctors.filter(
                doctor =>
                  includes(doctor.first_name) ||
                  includes(doctor.middle_name) ||
                  includes(doctor.last_name)
              )
            );
          }}
          onFocus={() => {
            setInputFocused(true);
          }}
          onBlur={e => {
            e.target.value = "";
            setInputFocused(false);
            resetFocusPosition();
            setTimeout(() => {
              setSuggestionShowed(false);
            }, 100);
          }}
        />
      )}
      <DoctorSearchSuggestionsContainer visible={suggestionShowed}>
        {filteredDoctors.map((doctor, i) => {
          return (
            <DoctorSuggestionItem
              key={doctor.id}
              focused={focusedItem === i}
              onMouseOver={() => {
                setFocusPosition(i);
              }}
              onClick={() => {
                onDoctorSelect(doctor);
                resetFocusPosition();
              }}>
              <DoctorSuggestionItemAvatarContainer>
                {doctor.photo ? (
                  <DoctorSuggestionItemAvatar src={doctor.photo} />
                ) : (
                  <DoctorAvatarPlaceholder>
                    {doctor.last_name.slice(0, 1) + doctor.first_name.slice(0, 1)}
                  </DoctorAvatarPlaceholder>
                )}
                {doctor.last_name + " " + doctor.first_name + " " + doctor.middle_name}
              </DoctorSuggestionItemAvatarContainer>
              <DoctorSuggestionSpeciality>
                {doctor.specialities.map(speciality => speciality.name).join(", ")}
              </DoctorSuggestionSpeciality>
            </DoctorSuggestionItem>
          );
        })}
      </DoctorSearchSuggestionsContainer>
    </DoctorSearchInputContainer>
  );
};
