import styled from "styled-components";
import { colors } from "../styleguide/colors";

export const TableCell = styled.td`
  height: 61px;
  padding: 4px 12px;

  box-sizing: border-box;
  border-left: solid 1px ${colors.transparentGray};

  position: relative;
  vertical-align: middle;

  border-bottom: solid 1px ${props => props.hasBottomTitle ? colors.magenta : colors.transparentGray};

  &:first-child {
    border-left: none;
  }
`;
