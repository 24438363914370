import * as Sentry from '@sentry/react';

export const captureExceptionToSentry = (err) => {
  if (process.env.NODE_ENV === "production" && typeof Sentry !== "undefined") {
    Sentry.captureException(err);
  }
};

export const captureEventToSentry = (event) => {
  if (process.env.NODE_ENV === "production" && typeof Sentry !== "undefined") {
    Sentry.captureEvent(event);
  }
};
