import styled, { css } from "styled-components";
import { rgba } from "polished";

import { colors } from "../../styleguide/colors";

import checkIcon from "../icons/check.inline.svg";

export const Root = styled.div`
  position: relative;
  font-size: 15px;
  line-height: 23px;
`;

export const Value = styled.div`
  padding: 6px 12px;

  color: ${colors.gray700};
  background: ${colors.concrete};
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    color: ${colors.black};
  }

  ${({ focused }) =>
    focused &&
    css`
      color: ${colors.black};
    `}

  ${({ hasValue }) =>
    hasValue &&
    css`
      background: ${rgba(colors.magenta, 0.05)};
      color: ${colors.magenta};

      &:hover {
        color: ${colors.magenta};
      }
    `}
`;

export const Options = styled.ul`
  position: absolute;
  top: 39px;
  right: 0;
  min-width: 169px;
  background: ${colors.white};
  z-index: 1;
  box-shadow: 0px 2px 31px rgba(0, 0, 0, 0.09);
  border-radius: 6px;
`;

export const Option = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  cursor: pointer;
  color: ${colors.black};
  white-space: nowrap;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:hover {
    background: ${rgba(colors.magenta, 0.05)};
  }
`;

export const CheckIcon = styled(checkIcon)`
  margin-left: 15px;
`;
