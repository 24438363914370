// @flow
import styled from "styled-components";

export const NewGroupInputContainer = styled.div`
  margin-top: 12px;
`;

export const AddGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  ${NewGroupInputContainer} + & {
    margin-top: 42px;
  }
`;
