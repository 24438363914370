// @flow
import styled from "styled-components";
import {
  Button as BaseButtonWithTooltip,
  ButtonWithTooltip,
  Tooltip
} from "../buttons/ButtonWithTooltip";
import { StyledTextarea, Textarea } from "../form-elements/Textarea";
import { useEffect, useRef } from "react";
import * as React from "react";
import { useDebouncedCallback } from "use-debounce";
import CreateIcon from "./icons/create.svg";
import InsertIcon from "./icons/insert.svg";
import InsertIconColor from "./icons/insert-color.svg";
import { isShortcutsSectionOpen } from "../../stores/shortcuts/servicesShortcuts";
import { InputText } from "../styleguide/typography";
// import { colors } from "../styleguide/colors";

const ErrorContainer = styled.div`
  margin-top: 9px;
`;

const ErrorText = props => <InputText {...props} modifiers={["error", "small"]} />;

const StyledButtonWithTooltip = styled(BaseButtonWithTooltip)`
  opacity: ${props => (props.isHighlighted ? 1 : props.disabled ? 0.15 : 0.5)};

  :hover {
    opacity: 1;
  }

  ${props =>
    (!props.disabled &&
      `
            :hover {
                opacity: 1;
            }
       `) ||
    `
            opacity: 0.15;
            pointer-events: none;
       `}
`;

const TextareaWithButtons = styled(StyledTextarea)`
  padding-right: 95px;
`;

const TextareaContainer = styled.div`
  position: relative;
  textarea {
    min-width: 239px;
  }
`;

const InputButtonsContainer = styled.div`
  position: absolute;
  top: 19px;
  right: 22px;
  opacity: ${props => (props.isShown ? 1 : 0)};
  display: flex;

  & > * {
    margin-left: 16px;
  }
`;

export const ShortcutsTextarea = (props: {
  id?: string,
  value: string,
  placeholder?: string,
  tooltip?: string,
  tabIndex?: number,
  handleChange: string => mixed,
  storedValue: ?string,
  error?: [string, boolean],
  isCurrent: boolean,
  isHaveShortcuts: boolean,
  saveOnRefresh?: boolean,
  isDisabled?: boolean,
  onCreateShortcuts: () => mixed,
  onOpenShortcuts: () => mixed
}) => {
  const {
    value,
    tooltip,
    tabIndex,
    handleChange,
    storedValue,
    error,
    isCurrent,
    isHaveShortcuts,
    isDisabled,
    onCreateShortcuts,
    onOpenShortcuts,
    placeholder
  } = props;
  const refTextarea = useRef(null);

  const [isFocused, setFocused] = React.useState(false);
  const [tooltipShown, setTooltipShown] = React.useState(false);
  const [setTooltipShownDebounced, cancelSettingTooltipShown] = useDebouncedCallback(
    value => setTooltipShown(Boolean(value)),
    400
  );

  const handleMouseLeave = () => {
    cancelSettingTooltipShown();
    setTooltipShown(false);
  };

  const inputButtons = (
    <>
      <ButtonWithTooltip
        overrides={{ Button: { component: StyledButtonWithTooltip } }}
        icon={CreateIcon}
        tooltip="Создать заготовку"
        onClick={onCreateShortcuts}
        disabled={!value}
      />
      <ButtonWithTooltip
        overrides={{ Button: { component: StyledButtonWithTooltip } }}
        icon={!isCurrent ? InsertIcon : InsertIconColor}
        tooltip="Вставить заготовку"
        onClick={onOpenShortcuts}
        disabled={!isHaveShortcuts}
        isHighlighted={isHaveShortcuts}
      />
    </>
  );

  const handleFocus = () => {
    setFocused(true);
    if (storedValue && refTextarea.current && refTextarea.current.setSelectionRange) {
      const pos = storedValue ? storedValue.length : 0;
      refTextarea.current.setSelectionRange(pos, pos);
    }
    if (isShortcutsSectionOpen() && onOpenShortcuts) {
      onOpenShortcuts();
    }
  };

  const handleBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    isCurrent &&
      refTextarea.current &&
      refTextarea.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [isCurrent]);

  return (
    <>
      <TextareaContainer
        onMouseEnter={() => setTooltipShownDebounced(isDisabled)}
        onMouseLeave={handleMouseLeave}>
        <Textarea
          tabIndex={tabIndex}
          value={value}
          placeholder={placeholder}
          isDisabled={isDisabled}
          error={error ? error.toString() : ''}
          refTextarea={refTextarea}
          handleChange={handleChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          overrides={{
            StyledTextarea: {
              component: TextareaWithButtons
            }
          }}
        />
        <InputButtonsContainer isShown={isFocused || isCurrent}>
          {inputButtons}
        </InputButtonsContainer>
        {tooltip && tooltipShown && <Tooltip>{tooltip}</Tooltip>}
      </TextareaContainer>
      {error && typeof error === 'string' && (
        <ErrorContainer>
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
    </>
  );
};
