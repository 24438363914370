// @flow

import { createEvent, createStore } from "effector";

export type ShortcutsSections = "forLayout" | "forField" | "forChecklist" | "forPrescribedServices" | "forAppointmentServices" | "edit" | "create";

export const openShortcutsForLayout = createEvent<void>();
export const openShortcutsForField = createEvent<void>();
export const openShortcutsForChecklist = createEvent<void>();
export const openShortcutsForPrescribedServices = createEvent<void>();
export const openShortcutsForAppointmentServices = createEvent<void>();
export const openShortcutsCreate = createEvent<void>();
export const openShortcutsEdit = createEvent<void>();
export const closeShortcutsSidebar = createEvent<void>();

export const shortcutsSidebarSectionStore = createStore<?ShortcutsSections>(null);
shortcutsSidebarSectionStore
  .on(openShortcutsForLayout, () => "forLayout")
  .on(openShortcutsForField, () => "forField")
  .on(openShortcutsForChecklist, () => "forChecklist")
  .on(openShortcutsForPrescribedServices, () => "forPrescribedServices")
  .on(openShortcutsForAppointmentServices, () => "forAppointmentServices")
  .on(openShortcutsCreate, () => "create")
  .on(openShortcutsEdit, () => "edit")
  .on(closeShortcutsSidebar, () => null);
