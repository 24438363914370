//@flow
import React from "react";
import styled from "styled-components";
import { useState, useMemo, useEffect } from "react";
import { useStore } from "effector-react";
import useReactRouter from "use-react-router";
import InfiniteScroll from "react-infinite-scroller";
import { useDebounce } from "use-debounce";

import { formatPatientBirthDate, getPatientFullName, getPatientLastNameWithInitials } from "../../domain/services/patient";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import {formatAppointmentDateToNumericDate, getAppointmentDateTime} from "../../domain/services/appointments";
import { AppointmentHistoryDoctor } from "./AppointmentHistoryDoctor";
import { AppointmentHistoryLayout } from "./AppointmentHistoryLayout";
import { AppointmentHistoryTabs } from "./AppointmentHistoryTabs";
import { AppointmentHistoryTable } from "./AppointmentHistoryTable";
import {
  AppointmentHistoryPreloaderTable,
  AppointmentHistoryPreloaderTableRow
} from "./AppointmentHistoryPreloaderTable";
import { AppointmentHistoryTableStub } from "./AppointmentHistoryTableStub";
import { AppointmentHistoryTableRowAll } from "./AppointmentHistoryTableRowAll";
import { AppointmentHistoryTableRowMe } from "./AppointmentHistoryTableRowMe";
import { APPOINTMENT_HISTORY_COLUMNS_ALL, APPOINTMENT_HISTORY_COLUMNS_ME } from "./columns";
import { AppointmentHistorySearch, SearchButton } from "../search/AppointmentHistorySearch";
import {
  activeTabStore,
  changeSearchValue,
  dataStore,
  loadMore,
  loadMoreStore,
  reloadAppointments,
  searchValueStore,
  setActiveTab,
  setLastScheduleUrl,
  unfinishedAppointmentsStore
} from "./appointmentHistoryStore";
import { tokenStore } from "../../stores/auth";
import { setMedicalFile } from "../appointment-switch-page/medicalFileStore";
import { useMediaQuery } from "@mui/material";
import { setVoximplantSupportData } from "../../utils/voximplantSupport";

const OverflowPreloaderFix = styled.div`
  overflow: hidden;
`;

const AppointmentRow = ({ appointment, isMobileDevice }) => {
  const activeTab = useStore(activeTabStore);
  const { history, location } = useReactRouter();

  const onClickCell = () => {
    setLastScheduleUrl(location.pathname);
  };

  const commonAppointmentProps = useMemo(
    () => ({
      key: appointment.id,
      link: `/appointment/${appointment.id}`,
      onClickCell: onClickCell,
      time: getAppointmentDateTime(appointment),
      patient: (
        <span title={formatPatientBirthDate(appointment.medical_file.patient)}>
          {!isMobileDevice ? getPatientFullName(appointment.medical_file.patient) : getPatientLastNameWithInitials(appointment.medical_file.patient)}
        </span>
      ),
      birthdate: formatAppointmentDateToNumericDate(appointment.medical_file.patient.birthdate),
      badge: appointment.medical_file.badge,
      layout: appointment.layout.name
    }),
    [appointment, history]
  );

  if (activeTab === "me") {
    return (
      <AppointmentHistoryTableRowMe
        {...commonAppointmentProps}
        inProgress={appointment.in_progress}
      />
    );
  } else if (activeTab === "all") {
    return (
      <AppointmentHistoryTableRowAll
        {...commonAppointmentProps}
        doctor={
          <AppointmentHistoryDoctor
            name={getLastNameWithInitials(appointment.doctor)}
            avatar={appointment.doctor.photo || undefined}
            doctor={appointment.doctor}
            isHidingAvatar={isMobileDevice}
          />
        }
      />
    );
  } else {
    (activeTab: empty);
    return <div>Неизвестная вкладка</div>;
  }
};

export const AppointmentHistory = () => {
  const isMobileDevice = useMediaQuery('(max-width: 768px)');
  const token = useStore(tokenStore);
  const activeTab = useStore(activeTabStore);
  const data = useStore(dataStore);
  const unfinishedAppointments = useStore(unfinishedAppointmentsStore);
  const hasMore = useStore(loadMoreStore);
  const searchValue = useStore(searchValueStore);

  const [searchIsShown, setSearchIsShown] = useState(!!searchValue);
  const [searchText, setSearchText] = useState(searchValue);
  const [searchTextDebounced] = useDebounce(searchText, 700);

  useEffect(() => {
    setMedicalFile(null);
  }, []);

  useEffect(() => {
    if (searchTextDebounced.length >= 3) {
      changeSearchValue(searchTextDebounced);
    }
    if (!searchTextDebounced) {
      changeSearchValue("");
    }
  }, [searchTextDebounced]);

  useEffect(() => {
    reloadAppointments();
  }, []);

  useEffect(() => {
    const voximplantData = {
      "Appointment history tab": activeTab === "me" ? "Мои" : "Все",
      "User search value": searchValue || null
    };
    setVoximplantSupportData({
      client_email: voximplantData,
      ...window.VoxKitWidgetSettings.client_data
    }, false, true);
    return () => {
      setVoximplantSupportData({
        client_email: {}
      }, false, true);
    };
  }, [activeTab, searchValue]);

  const handleSearchClose = () => {
    setSearchText("");
    setSearchIsShown(false);
  };

  const search = (
    <AppointmentHistorySearch
      value={searchText}
      isAllTab={activeTab === "all"}
      handleSearchClose={handleSearchClose}
      handleSearchInputChanges={e => setSearchText(e.target.value)}
    />
  );

  const searchButton = <SearchButton onClick={() => setSearchIsShown(true)} />;

  const columns =
    activeTab === "me" ? APPOINTMENT_HISTORY_COLUMNS_ME : APPOINTMENT_HISTORY_COLUMNS_ALL;

  const allAppointments = [...((activeTab === "me" && unfinishedAppointments) || []), ...data];

  const tabsContent =
    allAppointments && allAppointments.length > 0 ? (
      <AppointmentHistoryTable columns={columns}>
        {allAppointments.map(appointment => (
          <AppointmentRow
            inProgress={appointment.in_progress}
            key={appointment.id}
            appointment={appointment}
            isMobileDevice={isMobileDevice}
          />
        ))}
      </AppointmentHistoryTable>
    ) : allAppointments && !allAppointments.length && !hasMore ? (
      <AppointmentHistoryTableStub />
    ) : (
      <OverflowPreloaderFix>
        <AppointmentHistoryPreloaderTable columns={columns}>
          {new Array(12).fill().map((value, index) => (
            <AppointmentHistoryPreloaderTableRow withDoctor={activeTab === "all"} key={index} />
          ))}
        </AppointmentHistoryPreloaderTable>
      </OverflowPreloaderFix>
    );

  const tabs = <AppointmentHistoryTabs activeTab={activeTab} handleClickOnTab={setActiveTab} />;

  return (
    (token && (
      <AppointmentHistoryLayout
        tabs={tabs}
        search={search}
        searchButton={searchButton}
        searchIsShown={searchIsShown}
        hidingTitle={isMobileDevice}
      >
        <InfiniteScroll
          pageStart={0}
          useWindow={!isMobileDevice}
          loadMore={() => setTimeout(loadMore)}
          hasMore={hasMore}
        >
          {tabsContent}
        </InfiniteScroll>
      </AppointmentHistoryLayout>
    )) ||
    React.Fragment
  );
};
