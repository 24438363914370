// @flow
import type { MedicalFile } from "../domain/entities/MedicalFile";
import { useStore } from "effector-react";
import { currentUser, tokenStore } from "../stores/auth";
import { useAsync } from "react-use";
import apiv2 from "../apiv2";

export const useCryoEmbryosData = (medicalFile: MedicalFile) => {
  const user = useStore(currentUser);
  const token = useStore(tokenStore);

  const embryosCountRequestResult = useAsync(async () => {
    if (medicalFile) {
      const result = await apiv2.embryos.getCryoEmbryosCount(medicalFile.patient.id);

      return result;
    }

    return null;
  }, [token, medicalFile]);

  const isDoctor = user && user.role === "doctor" && user.doctor;

  // $FlowFixMe
  const isEmbryologist = isDoctor && user.doctor.is_embryologist;
  // $FlowFixMe
  const isNotEmbrylogist = isDoctor && !user.doctor.is_embryologist;

  const cryoEmbryosExist =
    embryosCountRequestResult.value &&
    embryosCountRequestResult.value.crio + embryosCountRequestResult.value.defrosts > 1;

  const showEmbryosButton = isEmbryologist || (isNotEmbrylogist && cryoEmbryosExist);

  return {
    showEmbryosButton,
    cryoEmbryosExist
  };
};