// @flow
import * as React from "react";

type EmbryoDeleteFn = (row: number) => any;
type EmbryoDuplicateFn = (row: number) => any;
type EmbryoUpdateFn = (row: number, cell: { field: string, value: mixed }) => any;
type EmbryoEditAccess = boolean;

const defaultState: {
  onCellUpdate: EmbryoUpdateFn,
  onRowDelete: EmbryoDeleteFn,
  onRowDuplicate: EmbryoDuplicateFn,
  hasEditAccess: EmbryoEditAccess
} = {
  onCellUpdate: () => console.log("fix function"),
  onRowDelete: () => console.log("fix function"),
  onRowDuplicate: () => console.log("fix function"),
  hasEditAccess: false
};

export const EmbryosTableContext = React.createContext<{
  onCellUpdate: EmbryoUpdateFn,
  onRowDelete: EmbryoDeleteFn,
  onRowDuplicate: EmbryoDuplicateFn,
  hasEditAccess: EmbryoEditAccess
}>(defaultState);
