import React from "react";
import ApiService from "../apiService";
import { useToast } from "../../components/toast/useToast";
import { ChatFilters } from '../../stores/chatStore';

const toastManager = useToast();

const API_URL = process.env.EMS_API_URL || location.origin;
class Chatroom {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getAll() {
    return this.apiService.get("chatrooms", {per_page: 5000});
  }

  get(perPage, page = 0, search = '', filterBy = ChatFilters.ALL) {
    return this.apiService.get("chatrooms", {per_page: perPage, page, search, filter_by: filterBy}, {}, true, '', false, true);
  }

  startCall(id) {
    return this.apiService.post(`chatrooms/${id}/opponent_start_call`);
  }

  async getChatroom(params, withoutToast) {
    try {
      return await this.apiService.get("chatroom", params);
    } catch(e) {
      switch(e.code) {
        case 404: {
          if (!withoutToast) {
            toastManager.showToast(
              <div>
                { e.result.details === "Not Found user patient" ? "У пациента не установлено мобильное приложение" : "Не найден чат с пациентом" }
              </div>,
              {
                duration: 10000
              }
            );
          }
          return e.result;
        }
        default: {
          if (!withoutToast) {
            toastManager.showToast(
              <div>
                Неизвестная ошибка сервера
              </div>,
              {
                duration: 10000
              }
            );
          }
          return e.result;
        }
      }
    }
  }

  getByScheduleId(scheduleId, withoutToast) {
    return this.getChatroom({
      schedule_id: scheduleId
    }, withoutToast);
  }

  getByPatientId(patientId, getOrCreate, withoutToast) {
    return this.getChatroom({
      patient_id: patientId,
      get_or_create: getOrCreate
    }, withoutToast);
  }

  getUnreadChatsCount() {
    return this.apiService.get("chatrooms/counting-unread");
  }

  setSettingsChats(settings) {
    this.apiService.post('users/auth/current_user/doctor/status', {}, settings);
  }
}

export default new Chatroom();
