import { makeAutoObservable } from "mobx";
import Binder from "../../utils/binder";
import corporateCompany from "../api/entities/corporateCompany";

class CorporateCompanyStore {
  /** Табы */
  isInitialized = false;
  tabs = [];
  selectedTab = {
    index: 0,
    info: null
  };
  /** Контент */
  allCompanyInfo = null;
  companyInfoByTab = null;

  constructor() {
    Binder.bind(this, CorporateCompanyStore);
    makeAutoObservable(this);
  }

  setInitialized(flag) {
    this.isInitialized = flag;
  }

  async getCompanyInfo() {
    this.setInitialized(false);
    try {
      const response = await corporateCompany.getCompanyInfo();
      response.forEach(item => {
        this.tabs.push({ id: item.id, name: item.title });
        this.allCompanyInfo = { ...this.allCompanyInfo, [item.title]: item.content };
      });

      if (!this.tabs.length || !this.allCompanyInfo) {
        return;
      }

      this.changeSelectedTab(0);
    } catch (e) {
      console.error(e);
    }

    this.setInitialized(true);
  }

  changeSelectedTab(key) {
    this.selectedTab = {
      index: key,
      info: this.tabs[key]
    };
    this.setCompanyInfoByTab();
  }

  setCompanyInfoByTab() {
    this.companyInfoByTab = this.allCompanyInfo[this.selectedTab.info.name];
  }
}

export default new CorporateCompanyStore();
