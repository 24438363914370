import React, { useCallback } from "react";
import { useAsync } from "react-use";
import { useStore } from "effector-react";

import { tokenStore } from "../../stores/auth";

import { EmbryosTableContext } from "./EmbryosTableContext";
import { SelectEdit } from "./EmbryosTableSelect";

import { tableSelectColors } from "../styleguide/colors";

import { medicalFileType } from "./types";
import apiv2 from '../../apiv2';

export const EmbryosTableIdentifierEditSelect = props => {
  const { row, cell, gender } = props;

  const { onCellUpdate } = React.useContext(EmbryosTableContext);

  const token = useStore(tokenStore);

  const handleUpdate = useCallback(
    ({ type, value }) => {
      onCellUpdate(row, cell, { type, value });
    },
    [row, onCellUpdate, cell]
  );

  const getEmbryoMedicalFilesState = useAsync(async () => {
    return await apiv2.embryos.getEmbryoMedicalFiles({ gender, embryoId: cell.entityId });
  }, [cell.entityId]);

  const options = !getEmbryoMedicalFilesState.loading
    ? getEmbryoMedicalFilesState.value.medical_files
        .map(({ id, number, short_name }) => ({
          value: id,
          label: short_name || number,
          color: tableSelectColors.gray,
          type: medicalFileType.medicalFile
        }))
        .concat(
          getEmbryoMedicalFilesState.value.anonymous.map(value => ({
            value,
            label: value,
            color: tableSelectColors.gray,
            type: medicalFileType.anonymous
          }))
        )
    : [];

  return (
    <SelectEdit
      {...props}
      updateCell={false}
      options={options}
      onChange={handleUpdate}
      noOptionsMessage={
        getEmbryoMedicalFilesState.loading
          ? "Загрузка ..."
          : options.length === 0
          ? "Нет привязанных медицинских карт"
          : null
      }
    />
  );
};

export const WomanIdentifiersEditSelect = props => (
  <EmbryosTableIdentifierEditSelect {...props} gender="female" />
);

export const ManIdentifiersEditSelect = props => (
  <EmbryosTableIdentifierEditSelect {...props} gender="male" />
);
