// @flow
import React, { useEffect, useMemo, useState } from "react";
import ticketStore, {
  AREAS_FIELD,
  getTicketOptionsByAccessorKey,
  convertedFieldAdditionalInfo,
  TICKET_PRIORITIES, TICKET_SEGMENTS,
  TICKET_SEGMENTS_URL,
  TICKET_STATUSES
} from "../../stores/ticketStore";
import { MaterialTable } from "../common/MaterialTable";
import { useForm } from "react-hook-form";
import { FormInputDate } from "../common/dynamic-form/fields/FormInputDate";
import { Grid, Paper, useMediaQuery } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import { READABLE_DATE_FORMAT, SERVER_DATE_TIME_FORMAT } from "../../utils/dates";
import styled from "styled-components";
import { observer } from "mobx-react";
import { trimLongTextValueField } from "../../utils/tableUtils";
import useReactRouter from "use-react-router";
import { currentUser as currentUserStore } from "../../stores/auth";
import doctorsStore from "../../stores/doctorsStore";
import { getPatientLastNameWithInitials } from "../../domain/services/patient";
import organizationsStore from "../../stores/organizationsStore";

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const TicketPage = observer(
  ({
     segment,
     isNeedAdditionalFilters = true,
     enableAdaptiveSetting = true,
   }) => {
  const isNotPadding = enableAdaptiveSetting ? useMediaQuery('(max-width: 768px)') : false;
  const isDisableHeightResizing = enableAdaptiveSetting ? useMediaQuery('(max-height: 550px)') : false;
  const [doctors, setDoctors] = useState([]);
  const { history } = useReactRouter();
  const currentUser = currentUserStore.getState();
  const created_at__lte = moment().set({ hour: 23, minute: 59, second: 59 }).format(SERVER_DATE_TIME_FORMAT);
  const created_at__gte = "2024-03-19 00:00:00";
  const ticketParams = TICKET_SEGMENTS_URL[ticketStore.segment];

  useEffect(() => {
    (async () => {
      setTimeout(async () => {
        await organizationsStore.getOrganizations();
        if (!ticketParams.doctorAssignee) {
          return false;
        }
        const doctorAssignee = ticketParams.doctorAssignee();
        const response = await doctorsStore.fetchAllDoctorsList({ filtersParams: { id__in: doctorAssignee } });
        setDoctors(response.data || []);
      }, 300);
    })();
  }, []);


  const defaultValuesDates = {
    created_at__gte,
    created_at__lte
  }

  const methods = useForm({
    defaultValues: isNeedAdditionalFilters ? defaultValuesDates : {},
  });

  const { handleSubmit, control, setValue } = methods;

  const afterInitializeParams = (callback) => {
    const currentSegment = segment || ticketStore.segment;
    ticketStore.setSegment(currentSegment);
    ticketStore.changeOldParams({});
    if (TICKET_SEGMENTS_URL[currentSegment].annotateMtmParams) {
      ticketStore.setAnnotateMtmParams(TICKET_SEGMENTS_URL[currentSegment].annotateMtmParams)
    }
    ticketStore.setAdditionalFilterValues(TICKET_SEGMENTS_URL[currentSegment].additionalFilters(currentUser.id));

    if (!ticketStore.globalFiltersTable.created_at__lte){
      ticketStore.setGlobalFiltersTable({
        created_at__gte,
        created_at__lte,
      }, false)
    } else {
      setValue('created_at__gte', ticketStore.globalFiltersTable.created_at__gte)
      setValue('created_at__lte', ticketStore.globalFiltersTable.created_at__lte)
    }
    if (!ticketStore.sortingTable.includes('-created_at')) {
      ticketStore.setSortingTable([...ticketStore.sortingTable, '-created_at'])
    }
    callback(true);
  }

  const onSubmit = (data) => {
    const filters = {
      created_at__gte: data.created_at__gte,
      created_at__lte: data.created_at__lte,
    };
    ticketStore.setGlobalFiltersTable(filters);
  }

  const additionalFilters = (
    <Paper
      style={{ padding: "20px" }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid alignItems={"center"} container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker={'dateTime'} label="Дата от" name="created_at__gte" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker={'dateTime'} label="до " name="created_at__lte" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button type={"submit"} size={"large"} variant="contained" color={"success"}>
              Применить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );

  const topToolbarCustomActions = [
    {
      key: "create_ticket",
      options: {
        color: "success",
        variant: "outlined",
        onClick: () => history.push(`${location.pathname}/create`)
      },
      text: "Создать обращение"
    }
  ];

  const columns = useMemo(
    () => {
      if (ticketParams.tableColumns) {
        return ticketParams.tableColumns();
      }
      return [
        {
          accessorKey: "title", //normal accessorKey
          header: "Заголовок",
          size: 400,
          grow: false,
          filterFn: "icontains",
          Cell: ({ row }) => trimLongTextValueField(row.original.title)
        },
        {
          accessorKey: "status",
          header: "Статус",
          filterFn: "in",
          filterVariant: "multi-select",
          filterSelectOptions: getTicketOptionsByAccessorKey("status"),
          Cell: ({ row }) => TICKET_STATUSES[row.original.status],
          size: 300
        },
        {
          accessorKey: "area",
          filterFn: "in",
          header: "Область",
          filterVariant: 'multi-select',
          filterSelectOptions: Object.keys(AREAS_FIELD).map(key => ({ value: key, label: AREAS_FIELD[key] })),
          grow: false,
          size: 300,
          Cell: ({ renderedCellValue }) =>
            trimLongTextValueField(AREAS_FIELD[renderedCellValue] ? AREAS_FIELD[renderedCellValue] : renderedCellValue)
        },
        {
          accessorKey: "theme",
          filterFn: "icontains",
          header: "Тема",
          grow: false,
          size: 300,
          Cell: ({ renderedCellValue }) => trimLongTextValueField(renderedCellValue)
        },
        {
          accessorKey: "description",
          filterFn: "icontains",
          header: "Описание",
          grow: false,
          size: 400,
          Cell: ({ renderedCellValue }) => trimLongTextValueField(renderedCellValue)
        },
        {
          accessorKey: "additional_info",
          filterFn: "icontains",
          header: "Дополнительная информация",
          grow: false,
          size: 400,
          Cell: ({ row }) => {
            if (!row.original?.additional_info) {
              return "";
            }
            return trimLongTextValueField(convertedFieldAdditionalInfo(row.original.additional_info));
          }
        },
        {
          accessorKey: "author_text",
          header: "Автор текстом, если нет пользователя",
          filterFn: "icontains",
          size: 400,
          grow: false,
          Cell: ({ renderedCellValue }) => trimLongTextValueField(renderedCellValue)
        },
        {
          accessorKey: "author__username",
          header: "Автор",
          filterFn: "icontains",
          Cell: ({ row }) => {
            if (row.original.area === "Врачебная ошибка") {
              return "";
            }
            return row.original.author__username;
          },
          size: 300
        },
        {
          accessorKey: "priority",
          header: "Приоритет",
          filterFn: "in",
          filterVariant: "multi-select",
          filterSelectOptions: getTicketOptionsByAccessorKey("priority"),
          Cell: ({ row }) => TICKET_PRIORITIES[row.original.priority],
          size: 300
        },
        {
          accessorKey: 'assignee',
          header: 'Исполнитель',
          filterFn: "",
          filterVariant: 'select',
          filterSelectOptions: doctors.map(doctor => {
            return {
              value: doctor.id,
              label: getPatientLastNameWithInitials(doctor)
            };
          }),
          Cell: ({ row }) => {
            const doctor = doctors.find((doctor) => row.original.assignee === doctor.id);
            if (!doctor) {
              return "";
            }
            return getPatientLastNameWithInitials(doctor);
          },
          size: 300,
        },
        {
          accessorKey: "id",
          header: "Идентификатор",
          size: 200,
          filterFn: 'icontains',
          enableHiding: false,
        },
        {
          accessorKey: "progress_report",
          header: "Отчёт о проделанной работе",
          size: 400,
          grow: false,
          filterFn: 'icontains',
          Cell: ({ renderedCellValue }) => trimLongTextValueField(renderedCellValue)
        },
        {
          accessorKey: "created_at",
          header: "Дата отправления",
          size: 220,
          enableColumnFilter: false,
          Cell: ({ cell }) => moment(cell.getValue()).format(READABLE_DATE_FORMAT)
        },
        {
          accessorKey: "updated_at",
          header: "Дата последнего обновления",
          size: 220,
          enableColumnFilter: false,
          Cell: ({ cell }) => moment(cell.getValue()).format(READABLE_DATE_FORMAT)
        }
      ].filter((column) => !TICKET_SEGMENTS_URL[ticketStore.segment].hiddenColumns.includes(column.accessorKey))
    },
    [ticketStore.segment, doctors, organizationsStore.organizations]
  );

  return (
    <Container>
      <MaterialTable
        {...TICKET_SEGMENTS_URL[ticketStore.segment].optionsTable}
        columns={columns}
        topToolbarCustomActions={ticketStore.segment === TICKET_SEGMENTS.MANAGER_DMS.name ? null : topToolbarCustomActions}
        additionalFilters={isNeedAdditionalFilters ? additionalFilters : null}
        afterInitializeParams={afterInitializeParams}
        store={ticketStore}
        isDisableSavingPresets
        notPadding={isNotPadding}
        disableHeightResizing={isDisableHeightResizing}
      />
    </Container>
  );
});
