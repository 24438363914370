//@flow

export const APPOINTMENT_HISTORY_COLUMNS_ME = {
  time: { name: "Время" },
  patient: { name: "Пациент" },
  layout: { name: "Шаблон" }
};

export const APPOINTMENT_HISTORY_COLUMNS_ALL = {
  time: { name: "Время" },
  doctor: { name: "Врач" },
  patient: { name: "Пациент" },
  layout: { name: "Шаблон" }
};

export type TAppointmentHistoryColumnsMe = $Keys<typeof APPOINTMENT_HISTORY_COLUMNS_ME>;
export type TAppointmentHistoryColumnsAll = $Keys<typeof APPOINTMENT_HISTORY_COLUMNS_ALL>;
