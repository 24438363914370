// @flow
import React from "react";
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from "styled-components";
import useReactRouter from "use-react-router";
import { observer } from "mobx-react";
import { SurveyEmployeePage } from "./SurveyEmployeePage";
import reviewStatisticsStore from "../../stores/reviewStatisticsStore";
import { WidgetsComponent } from "../common/widgets-components-common/WidgetsComponent";
import {
  EMPLOYEES_SURVEY,
  GET_FIELDS_FOR_WIDGETS,
  GET_GENERATE_WIDGETS, GET_OPTIONS_FOR_WIDGET,
  REVIEW_KEYS
} from "../common/widgets-components-common/constants";

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const SurveyResultPage = observer(() => {
  const [activeTab, setActiveTab] = React.useState('surveyEmployee');

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Анкентирование сотрудников" value={'surveyEmployee'} />
          <Tab label="Графики" value={'charts'} />
        </Tabs>
      </Box>
      <div style={{ display: activeTab !== 'surveyEmployee' ? 'none' : 'block' }}>
        <SurveyEmployeePage />
      </div>
      <div style={{ display: activeTab !== 'charts' ? 'none' : 'block' }}>
        <WidgetsComponent
          dashboardLabel="Графики по анкентированию сотрудников"
          fieldsWidgets={GET_FIELDS_FOR_WIDGETS(EMPLOYEES_SURVEY)}
          generateWidgets={GET_GENERATE_WIDGETS(EMPLOYEES_SURVEY)}
          optionsField={GET_OPTIONS_FOR_WIDGET(EMPLOYEES_SURVEY)}
          nameLocalStorage="EmployeesSurveyWidgetComponents"
        />
      </div>
    </Container>
  );
});
