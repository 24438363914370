// @flow
import React from "react";
import type { Diagnosis } from "../../domain/entities/Diagnosis";
import { PNormal } from "../styleguide/typography";

export const PrintableDiagnosis = (props: { diagnosis: Diagnosis }) => {
  const { diagnosis } = props;
  return (
    <PNormal>
      {diagnosis.icd_code} {diagnosis.name} {diagnosis.comment && `. ${diagnosis.comment}`}
    </PNormal>
  );
};
