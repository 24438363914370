// @flow
import * as React from "react";
import { useState, useEffect } from "react";
import { useStore } from "effector-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { tokenStore } from "../../stores/auth";
import { BackButton } from "../aside/BackButton";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import { SidebarLayout } from "../sidebar/SidebarLayout";
import { SidebarFade } from "../sidebar/SidebarFade";
import { useSidebarFade } from "../sidebar/useSidebarFade";
import { ShortcutsShortcutItem } from "./ShortcutsShortcutItem";
import {
  closeShortcutsSidebar,
  openShortcutsForLayout
} from "../../stores/shortcuts/shortcutsSections";
import { editShortcut } from "../../stores/shortcuts/editingShortcut";
import type { ChecklistShortcut } from "../../domain/entities/Shortcut";
import { reorder } from "../../utils/reorder";
import apiv2 from '../../apiv2';

const ShortcutsForChecklist = (props: {
  name: string,
  shortcuts: Array<ChecklistShortcut>,
  onInsert: (shortcut: ChecklistShortcut) => mixed,
  reloadShortcuts: () => Promise<any>,
  transitionId: any
}) => {
  const token = useStore(tokenStore);
  const { name, onInsert, reloadShortcuts, transitionId } = props;
  const [shortcuts, setShortcuts] = useState<Array<ChecklistShortcut>>(props.shortcuts);
  const shouldStartTransition = useSidebarFade(transitionId);

  const backButton = <BackButton text="Заготовки" handleBackButtonClick={openShortcutsForLayout} />;
  const header = (
    <SidebarHeader onClose={closeShortcutsSidebar} title={name} breadcrumbs={backButton} />
  );

  useEffect(() => {
    setShortcuts(props.shortcuts);
  }, [props.shortcuts]);

  const onDragEnd = async result => {
    if (!result.destination) {
      return;
    }

    const items = reorder(result.source.index, result.destination.index)(shortcuts);

    setShortcuts(items);

    if (!token) return;
    const shortcutsOrderOptions = items.map((item, index) => ({
      shortcut: item.id,
      order: index * 50
    }));
    await apiv2.shortcuts.postShortcutOrder(shortcutsOrderOptions);
    await reloadShortcuts();
  };
  return (
    <SidebarFade>
      {shouldStartTransition && (
        <SidebarLayout header={header}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {shortcuts.map((shortcut, index) => (
                    <Draggable key={shortcut.id} draggableId={shortcut.id.toString()} index={index}>
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <ShortcutsShortcutItem
                            shortcutName={shortcut.name}
                            onEdit={() => editShortcut(shortcut)}
                            onInsert={() => onInsert(shortcut)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </SidebarLayout>
      )}
    </SidebarFade>
  );
};

export default ShortcutsForChecklist;
