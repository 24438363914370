import React from "react";
import moment from "moment";
import styled from "styled-components";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import useReactRouter from "use-react-router";
import { useBeforePrint } from "../appointment-print/useBeforePrint";
import { useAfterPrint } from "../appointment-print/useAfterPrint";
import { AppointmentSign } from "../appointment-print/AppointmentSign";
import { PNormal } from "../styleguide/typography";
import { AppointmentPrintLayout } from "../appointment-print/AppointmentPrintLayout";
import { AppointmentPrintableContacts } from "../appointment-print/AppointmentPrintContacts";
import {
  AppointmentPrintableHeaderItem,
  AppointmentPrintableHeaderLayout
} from "../appointment-print/AppointmentPrintableHeader";
import logo from "../appointment-print/icons/logo.svg";
import {
  formatPatientBirthDate,
  getAgeByBirthDate,
  getPatientFullName
} from "../../domain/services/patient";
import { getNounEndingByNumber } from "../../utils/getNounEndingByNumber";
import { useLogo } from "../appointment-print/useLogo";
import { useAsync } from "react-use";
import apiv2 from '../../apiv2';
import { patientAnalysesFilter } from "./patientAnalysesFilter";

const Logo = styled.img.attrs(props => ({
  src: props.logoUrl ? `${props.logoUrl}` : logo
}))`
  width: 175px;
  height: 77px;
  object-fit: scale-down;
  object-position: left;
`;

export const formatDateTime = date => {
  return moment(date)
    .locale("ru")
    .format("D MMMM YYYY, HH:mm");
};

export const formatDate = date => {
  return moment(date)
    .locale("ru")
    .format("D MMMM YYYY");
};

const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;
`;

const TheadCol = styled.th`
  font-weight: 500;
  padding: 10px 0;
  white-space: nowrap;
  text-align: left;
  border-bottom: 1px solid #c6007f;
  border-right: 1px solid #979797;
  font-size: 14px;
  ${props => props.center && `text-align: center; padding-left: 17px; padding-right: 17px;`}
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 22px;
  font-weight: 400;
  margin-top: 68px;
  margin-bottom: 10px;
`;

const TbodyCol = styled.td`
  padding: 10px 0;
  padding-right: 17px;
  text-align: left;
  border-bottom: 1px solid #979797;
  border-right: 1px solid #979797;
  vertical-align: middle;
  line-height: 23px;
  font-size: 15px;
  ${props => props.center && `text-align: center; padding: 10px 17px;`}
  ${props => props.noWrap && `white-space: nowrap;`}
`;

export const FolderPrintContent = ({
  data,
  logoEl,
  folder,
  medicalFile,
  setLogoLoaded,
  organization,
  type
}) => {
  const logo = (
    <Logo ref={logoEl} logoUrl={organization?.logo} onLoad={() => setLogoLoaded(true)} />
  );

  const patientAge = getAgeByBirthDate(medicalFile?.patient);

  const appointmentPrintableHeaderItems = (
    <>
      <AppointmentPrintableHeaderItem
        label="Врач"
        content={folder.doctor_short_name}
        notFixedWidth
      />
      <AppointmentPrintableHeaderItem
        label="Пациент"
        content={`${getPatientFullName(medicalFile.patient)} (№${medicalFile.number})`}
        notFixedWidth
      />
      <AppointmentPrintableHeaderItem
        label="Дата"
        content={formatDateTime(new Date())}
        notFixedWidth
      />
      <AppointmentPrintableHeaderItem
        label="Дата рождения"
        content={
          patientAge
            ? `${formatPatientBirthDate(
                medicalFile.patient
              )} (${patientAge} ${getNounEndingByNumber(patientAge, ["год", "года", "лет"])})`
            : `${formatPatientBirthDate(medicalFile.patient)}`
        }
        notFixedWidth
      />
    </>
  );

  const header = (
    <AppointmentPrintableHeaderLayout logo={logo}>
      {appointmentPrintableHeaderItems}
    </AppointmentPrintableHeaderLayout>
  );

  const sign = (
    <AppointmentSign
      doctor={{
        name: folder.doctor_short_name,
        regalia: ""
      }}
      chief={organization.chief_name}
    />
  );
  const contactsLayout = <AppointmentPrintableContacts organization={{}} />;

  const getValues = (data, gender) => (
    <>
      <Title>
        Анализы для {gender === "male" ? "мужчины" : "женщины"} &#8211; {folder.name}
      </Title>
      <Table>
        <thead>
          <tr>
            <TheadCol>Название</TheadCol>
            <TheadCol center>Дата забора</TheadCol>
            <TheadCol center>Годен, д</TheadCol>
            <TheadCol center>Дата истечения</TheadCol>
            <TheadCol center>Статус</TheadCol>
          </tr>
        </thead>
        <tbody>
          {data &&
            data
              .filter(el => (type === "not-passed" ? el.status !== "Годен" : true))
              .filter(el => (type === "not-passed" ? el.status !== "Не требуется" : true))
              .filter(el => el.gender === gender)
              .map(element => (
                <tr key={element.id}>
                  <TbodyCol>{element.name}</TbodyCol>
                  <TbodyCol center noWrap>
                    {element.date ? formatDate(element.date) : ""}
                  </TbodyCol>
                  <TbodyCol center>{element.shelf_life}</TbodyCol>
                  <TbodyCol center noWrap>
                    {element.last_day_valid ? formatDate(element.last_day_valid) : ""}
                  </TbodyCol>
                  <TbodyCol center noWrap>
                    {element.status}
                  </TbodyCol>
                </tr>
              ))}
        </tbody>
      </Table>
    </>
  );

  return (
    <React.Fragment>
      <AppointmentPrintLayout
        sign={sign}
        breakAfter
        values={getValues(data, "female")}
        header={header}
        contacts={contactsLayout}
      />
      <AppointmentPrintLayout
        sign={sign}
        values={getValues(data, "male")}
        header={header}
        contacts={contactsLayout}
      />
    </React.Fragment>
  );
};

export const FolderAnalysesPrintPage = () => {
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();

  const logo = useLogo();

  const { value: printData } = useAsync(async () => {
    const folder = await apiv2.appointments.getFolder(params.id);
    let analyses = await apiv2.appointments.getFolderAnalyses(params.id);
    const medicalFile = await apiv2.medicalFiles.getById(folder.medical_file);

    if (analyses && analyses.length) {
      analyses = analyses.filter(patientAnalysesFilter);
    }

    return { folder, analyses, medicalFile };
  });

  useBeforePrint((logo.isLogoLoaded && printData) || false);
  useAfterPrint((printData && `/appointment/folder/${params.id}`) || "");

  if (!printData) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  return (
    <FolderPrintContent
      data={printData.analyses}
      folder={printData.folder}
      medicalFile={printData.medicalFile}
      logoEl={logo.logoEl}
      setLogoLoaded={logo.setLogoLoaded}
      organization={printData.medicalFile.organization}
      type={params.type}
    />
  );
};
