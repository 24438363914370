// @flow
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTrigger } from "react-use-trigger";
import { createGate, useGate, useStore } from "effector-react";
import { combine, createEffect, createEvent, createStore } from "effector";

import { tokenStore } from "../../stores/auth";
import type { GenericAppointment } from "../../domain/entities/Appointment";
import { MedicalFileContext } from "./MedicalFileContext";
import { PatientAppointmentsRefreshTrigger } from "./PatientAppointmentsRefreshTrigger";
import apiv2 from '../../apiv2';
import { styled } from "@mui/material/styles";
import { Box, FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import appointmentStore from "../../stores/AppointmentStore";
import { observer } from "mobx-react";

const updatePatientAppointments = createEvent<void>("updatePatientAppointments");

const PatientIdGate = createGate("patientId");

const SwitchContainer = styled(FormControlLabel)(
  () =>
    `
      position: sticky;
      top: 0;
      z-index: 10;
      background: white;
      box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(151,151,151,0.3);
      width: 100%;
      box-sizing: border-box;
      margin: 0;
      height: 42px;
      padding: 0 5px;
      display: flex;
      justify-content: flex-start;
      .MuiTypography-root {
        font-size: .9rem;
        padding-right: 5px;
      }
    `
);

export const $patientAppointments = createStore([{isLoading: true}]);

const getPatientAppointmentsList = createEffect("getPatientAppointmentsList").use(
  async ({ patientId }) => {
    if (typeof patientId !== "number") {
      return;
    }
    return apiv2.appointments.getAppointmentsByPatientId(patientId, appointmentStore.isDisplayFolders);
  }
);

$patientAppointments.on(getPatientAppointmentsList.done, (state, { result }) => {
  if (!result) return state;
  return (result: any).map(obj => ({ ...obj.data, type: obj.type }));
});

const $patientAppointmentsParams = combine(tokenStore, PatientIdGate.state, (token, patientId) => ({
  token,
  patientId
}));

$patientAppointmentsParams.on(updatePatientAppointments, state => {
  getPatientAppointmentsList(state);
  return state;
});

export const PatientAppointmentsProvider = observer((props: {
  children: (Array<GenericAppointment>) => React$Node
}) => {
  const { medicalFile } = useContext(MedicalFileContext);
  const [appointments, setAppointments] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);
  const patientAppointmentsTrigger = useTrigger(PatientAppointmentsRefreshTrigger);
  const patientAppointments = useStore($patientAppointments);
  useGate(PatientIdGate, (medicalFile?.patient?.id: any));

  useEffect(() => {
    updatePatientAppointments();
  }, [patientAppointmentsTrigger]);

  const handleChangeSwitch = () => {
    setDataIsReady(false);
    appointmentStore.changeIsDisplayFolders(!appointmentStore.isDisplayFolders);
    getPatientAppointmentsList({patientId: medicalFile?.patient?.id})
  }

  useMemo(() => {
    setDataIsReady(false);
    if (patientAppointments.length === 1 && patientAppointments[0].isLoading) {
      return;
    }
    setAppointments(patientAppointments);
    setDataIsReady(true);
  }, [patientAppointments]);

  return (
    <Box sx={{ position: "relative", height: "100%", overflowY: "auto" }}>
      <SwitchContainer
        label="Группировать по папкам"
        labelPlacement="start"
        control={
          <Switch
            size="small"
            checked={!!appointmentStore.isDisplayFolders}
            onChange={handleChangeSwitch}
          />
        }
      />
      {props.children(appointments, dataIsReady)}
    </Box>
  );
});
