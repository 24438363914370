import { styled } from "@mui/material/styles";
import { Button, Stack } from "@mui/material";
import { IconContainerStyled } from "../../icon-containter-styles/IconContainer.styled";

const ContainerStyled = styled(Stack)(
  () => `
    border-top: 1px solid rgba(220, 211, 210, 0.4);
    justify-content: center;
    .MuiButtonBase-root:not(:last-child) {
      border-right: 1px solid rgba(220, 211, 210, 0.4) !important;
    }
  `
);

const ButtonStyled = styled(Button)(
  ({ theme }) => `
    flex-direction: column;
    border-radius: 0;
    width: 100%;
    min-height: auto;
    &.MuiButton-textSecondary {
      pointer-events: none;
      .MuiTypography-root {
        color: ${theme.palette.primary.main};
      }
      ${IconContainerStyled} > .MuiSvgIcon-root {
        fill: ${theme.palette.primary.main};
      }
    };
    .MuiTypography-root {
      color: ${theme.palette.black.main};
      font-size: 12px;
      text-transform: none;
    };
  `
);

export { ContainerStyled, ButtonStyled };
