// @flow
import React, { useEffect, useState, useRef } from "react";
import { Button as BaseButtonWithTooltip, ButtonWithTooltip } from "../buttons/ButtonWithTooltip";

import type { AppointmentWithDetails } from "../../domain/entities/Appointment";

import { getAppointment_id } from "../../domain/entities/Appointment";
import CreateIcon from "./icons/createIcon.svg";
import CreateIconHover from "./icons/createIconHover.svg";
import InsertIcon from "./icons/insert.svg";
import InsertIconColor from "./icons/insert-color.svg";
import styled, { css } from "styled-components";
import {
  createShortcuts,
  openShortcuts,
  isNotEmptyShortcut, prescribedServicesShortcuts
} from "../../stores/shortcuts/prescribedServicesShortcuts";
import type { TChecklistDestination } from "../../domain/value-objects/TShortcuts";
import {observer} from 'mobx-react';
import serviceStore from '../../stores/serviceStore';
import {useStore} from 'effector-react';

const StyledButtonWithTooltip = styled(BaseButtonWithTooltip)`
  opacity: ${({ disabled, isHighlighted }) => (isHighlighted ? 1 : disabled ? 0.15 : 1)};

  ${({ disabled }) =>
  disabled &&
  css`
      opacity: 0.15;
      pointer-events: none;
    `}
`;

const TooltipContainer = styled.div`
  display: flex;
  column-gap: 18px;
`;

type Props = {
  isNotEmpty: boolean,
  fieldName: TChecklistDestination,
  appointment: AppointmentWithDetails
};

const ServicesTooltip = observer(({ fieldName, appointment }: Props) => {
  const blockRef = useRef(null);
  const [isNotEmptyShrtc, setIsNotEmptyShrtc] = useState(false);
  const shortcuts = useStore(prescribedServicesShortcuts);
  const appointment_id = getAppointment_id(appointment);

  useEffect(() => {
    const isNotEmpty = isNotEmptyShortcut(fieldName);
    setIsNotEmptyShrtc(isNotEmpty);
  }, [shortcuts, serviceStore.prescribedServices, fieldName]);

  return (
    <TooltipContainer ref={blockRef}>
      <ButtonWithTooltip
        overrides={{ Button: { component: StyledButtonWithTooltip } }}
        icon={CreateIcon}
        iconHover={CreateIconHover}
        tooltip="Создать заготовку"
        onClick={() => createShortcuts({ fieldName, appointment_id })}
        disabled={!serviceStore.prescribedServices.length}
      />
      <ButtonWithTooltip
        overrides={{ Button: { component: StyledButtonWithTooltip } }}
        icon={InsertIcon}
        iconHover={InsertIconColor}
        tooltip="Вставить заготовку"
        onClick={() => openShortcuts({ fieldName, appointment_id })}
        disabled={!isNotEmptyShrtc}
        isHighlighted={isNotEmptyShrtc}
      />
    </TooltipContainer>
  );
});

export default ServicesTooltip;
