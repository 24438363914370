// @flow
import * as React from "react";
import styled from "styled-components";
import editIcon from "./icons/edit.svg";
import checkIcon from "./icons/check.svg";
import plusIcon from "./icons/plus.svg";
import { Container, DefaultButton } from "./DefaultButton";
import { colors } from "../styleguide/colors";
import { rgba } from "polished";
import { withModifiers } from "with-modifiers";

const ButtonContainer = withModifiers({
  noBorder: () => `
        border: none;
    `,
})(
  modifier => styled(Container)`
    height: 44px;
    ${modifier};
`);

export const Icon = withModifiers({
  check: () => `
        mask: url(${checkIcon});
    `,
  edit: () => `
        mask: url(${editIcon});
    `,
  smallPlus: () => `
    mask: url(${plusIcon});
    width: 12px;
    height: 12px;
  `
})(
  modifier => styled.div`
    width: 24px;
    height: 24px;

    ${modifier};
    background: ${rgba(colors.black, 0.5)};

    transition: all 0.15s ease-in-out;

    ${ButtonContainer}:hover & {
      background: ${colors.magenta};
    }
  `
);

const IconContainer = styled.div`
  margin-right: 8px;
`;

const TextContainer = styled.div`
  margin-right: 8px;
  white-space: nowrap;
`;

type TDefaultButtonOverrides = $ElementType<React$ElementConfig<typeof DefaultButton>, "overrides">;

export const ButtonWithIcon = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  children: ?React.Node,
  overridesDefaultButton?: TDefaultButtonOverrides
}) => (
  <DefaultButton
    {...props}
    overrides={{
      Container: {
        component:
          props.overridesDefaultButton?.Container?.component || ButtonContainer,
        props:
          props.overridesDefaultButton?.Container?.props || {}
      },
      Icon: {
        component:
          props.overridesDefaultButton?.Icon?.component || Icon,
        props:
          props.overridesDefaultButton?.Icon?.props || {}
      },
      IconContainer: {
        component:
          props.overridesDefaultButton?.IconContainer?.component || IconContainer
      },
      TextContainer: {
        component:
          props.overridesDefaultButton?.TextContainer?.component || TextContainer
      }
    }}
  />
);
