// @flow
import * as React from "react";

import { withRouter } from "react-router-dom";
import {observer} from "mobx-react";
import reviewStore, { CLINIC_CODE } from "../../stores/reviewStore";
import useReactRouter from "use-react-router";

export const generateSearchParams = (reviewStore) => {
  const search = new URLSearchParams();
  if (reviewStore.isMobile) {
    search.set('app', reviewStore.isMobile);
    if (reviewStore.city) {
      search.set('city', reviewStore.city);
    }
    if (reviewStore.rate) {
      search.set('rate', reviewStore.rate);
    }
  }
  search.set('clinic', reviewStore.clinic_code);
  return `?${search.toString()}`;
}

export const setStoreDataSearchParams = (reviewStore) => {
  const searchParams = new URLSearchParams(location.search);

  const app = searchParams.get('app') === 'true';
  if (app) {
    reviewStore.setIsMobile(true);
  }

  const city = searchParams.get('city');
  if (city) {
    reviewStore.setCity(city);
  }

  let rate = searchParams.get('rate');
  if (rate) {
    reviewStore.setRate(parseInt(rate));
  }
}

export const ReviewPage = withRouter(observer((props: {||}) => {

  const {
    match: { params }
  } = useReactRouter();

  if (params.token && reviewStore.token !== params.token) {
    reviewStore.setToken(params.token);
  }

  const notAlreadyReview = async () => {
    setStoreDataSearchParams(reviewStore);
    const notAlreadyReview = await reviewStore.getCompanyLink();
    if (notAlreadyReview) {
      if (reviewStore.rate) {
        if (reviewStore.rate < 4) {
          props.history.push(`/review/${reviewStore.token}/step-five-negative${generateSearchParams(reviewStore)}`);
        } else {
          props.history.push(`/review/${reviewStore.token}/step-four${generateSearchParams(reviewStore)}`);
        }
      } else {
        props.history.push(`/review/${reviewStore.token}/step-two${generateSearchParams(reviewStore)}`);
      }
    } else {
      props.history.push(`/review/${reviewStore.token}/already${generateSearchParams(reviewStore)}`);
    }
  }
  if (!reviewStore.isInitialized) {
    notAlreadyReview();
  }

  return null;
}));
