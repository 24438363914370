// @flow
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import DocumentIcon from "./icons/document.svg";

export const PrintDocumentsFixedLabel = styled.div`
  width: 65px;
  height: 59px;

  position: fixed;
  top: 284px;
  right: 0;

  background: url(${DocumentIcon}) no-repeat;
  background-color: ${colors.white};
  background-position: 25px center;

  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.09);
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;

  cursor: pointer;
  z-index: 9;
`;
