// @flow
import React from "react";
import { withModifiers } from "with-modifiers";
import styled from "styled-components";
import ArrowImg from "./arrow-long-left.svg";
import { PSmall } from "../styleguide/typography";
import o, { type ExtractOverridesProps } from "react-overrides";

const Container = withModifiers({
  disabled: () => `
        cursor: auto;
        opacity: 0.3;
    `
})(
  modifier => styled.button`
    margin-bottom: 30px;
    padding: 0;
    opacity: 0.6;
    display: flex;
    align-items: center;

    border: none;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;

    ${modifier};
  `
);

export const BackArrow = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 6px;
  background: url(${ArrowImg}) no-repeat;
  background-size: contain;
  flex-shrink: 0;
`;

const Overrides = {
  Container,
  BackArrow
};

export const BackButton = (props: {
  text: string,
  backButtonIsDisabled?: boolean,
  handleBackButtonClick?: () => mixed,
  overrides?: ExtractOverridesProps<typeof Overrides>
}) => {
  return (
    <Container
      {...o}
      disabled={props.backButtonIsDisabled}
      modifiers={props.backButtonIsDisabled && "disabled"}
      onClick={props.handleBackButtonClick}>
      <BackArrow {...o} />
      <PSmall>{props.text}</PSmall>
    </Container>
  );
};
