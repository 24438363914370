import * as React from "react";

import moment from "moment";
import { READABLE_DATE_FORMAT } from "../../domain/services/field";
import { getTextValue } from "../appointment-widgets/getTextValue";
import { DateInput } from "../form-elements/legacy/DateInput";

export const DateWidget = ({ field, tabIndex, layoutFieldValue, onChange }) => {
  const value = getTextValue(field, layoutFieldValue, value =>
    moment(value).format(READABLE_DATE_FORMAT)
  );

  return (
    <DateInput
      value={value}
      tabIndex={tabIndex}
      handleChange={newValue => {
        onChange({
          field_type: field.field_type,
          field_id: field.id,
          value: newValue
        });
      }}
      placeholder="ДД.ММ.ГГГГ"
    />
  );
};
