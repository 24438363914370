// @flow
import React from "react";
import styled from "styled-components";
import { PNormal } from "../styleguide/typography";

const BlockContainer = styled.div`
  page-break-inside: avoid;

  & + & {
    margin-top: 6px;
  }
`;

const InlineContainer = styled.div`
  page-break-inside: avoid;
  display: inline;
    
  p:not([class]) {
    display: inline;
  }

  & + & {
    :before {
      content: " ";
      display: block;
      margin-top: 6px;
    }
  }
`;

const LabelContainer = styled(PNormal)`
  opacity: 0.54;
  margin-right: 5px;
`;

export const PrintableField = (props: {
  label?: string,
  children: React$Node,
  inline?: boolean
}) => {
  const Container = props.inline ? InlineContainer : BlockContainer;

  return (
    <Container>
      {props.label && (
        <LabelContainer modifiers="left" as="span">
          {props.label}
        </LabelContainer>
      )}
      {props.children}
    </Container>
  );
};
