//@flow
import { createEvent, createStore } from "effector";
import toast from "react-hot-toast";

export type ToastObject = {
  toastId: number,
  data: any
};

type FunctionComparator = ToastObject => boolean;

export const toastStore = createStore<Array<ToastObject>>([]);

const setToastData = createEvent<Array<ToastObject>>("setToastData");
export const addToast = createEvent<ToastObject>("addToast");
export const removeToast = createEvent<FunctionComparator>("removeToast");
export const clearToasts = createEvent<void>("clearToasts");
export const findToast = (toastSeeker: FunctionComparator) =>
  toastStore.thru<ToastObject | typeof undefined>(store => store.getState().find(toastSeeker));

toastStore.on(setToastData, (state, payload) => payload);
toastStore.on(addToast, (state, newToast) => [...state, newToast]);
toastStore.on(removeToast, (state, comparator) => [
  ...state.filter(toastObject => !comparator(toastObject))
]);
toastStore.on(clearToasts, state => {
  state.forEach(toastObject => {
    toast.dismiss(toastObject.toastId);
  });
  return [];
});
