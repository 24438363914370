// @flow
import { restore, createEvent, forward, merge } from "effector";
import type { ConclusionGuideField } from "../domain/value-objects/ConclusionGuide";
import { openInfoRightSidebar } from "./rightSidebar";

export const openExaminationsInfoEvent = createEvent<void>();
export const openMedicationInfoEvent = createEvent<void>();
export const openRecommendationsInfoEvent = createEvent<void>();

export const changeInfoType = createEvent<ConclusionGuideField>();

export const infoType = restore<*>(changeInfoType, "examinations");

forward({
  from: merge([openExaminationsInfoEvent, openMedicationInfoEvent, openRecommendationsInfoEvent]),
  to: openInfoRightSidebar
});
forward({
  from: openExaminationsInfoEvent.map(() => "examinations"),
  to: changeInfoType
});
forward({
  from: openMedicationInfoEvent.map(() => "medication"),
  to: changeInfoType
});
forward({
  from: openRecommendationsInfoEvent.map(() => "recommendations"),
  to: changeInfoType
});
