// @flow
import * as React from "react";
import styled from "styled-components";

import { HeadingH6 } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  width: 100%;
  padding: 0 65px 0 41px;
  margin-bottom: 11px;
  box-sizing: border-box;

  position: relative;

  :last-child {
    margin-top: 18px;
    margin-bottom: 0;
  }

  :not(:last-child) ::before {
    content: "";
    width: 1px;
    height: calc(100% + 11px);
    background-color: ${colors.gray200};

    position: absolute;
    top: 11px;
    left: 28px;
    z-index: 1;
  }

  & + & ::after {
    content: "";
    width: 1px;
    height: 4px;
    background-color: ${colors.gray200};

    position: absolute;
    top: 0;
    left: 28px;
    z-index: 1;
  }

  & + &:last-child ::after {
    top: -7px;
    height: 11px;
  }
`;

const Text = styled(HeadingH6)`
  ${Container}:not(:last-child) & {
    opacity: 0.6;
    font-size: 12px;
    line-height: 1.42;
    color: ${colors.black};
    cursor: pointer;
  }
`;

const Dot = styled.div`
  position: absolute;
  top: 4px;
  left: 24px;

  width: 9px;
  height: 9px;
  background-color: ${colors.gray200};
  border-radius: 50%;
  z-index: 2;

  ${Container}:last-child > & {
    background-color: ${colors.magenta};
  }
`;

export const DiagnosesTreeBreadcrumbsItem = (props: {
  children: React$Node,
  onClick?: any => mixed
}) => {
  return (
    <Container onClick={props.onClick}>
      <Dot />
      <Text>{props.children}</Text>
    </Container>
  );
};
