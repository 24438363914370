// @flow
import React, { useCallback, useEffect, useState, useRef } from "react";
import { observer } from 'mobx-react';
import type { Section } from "../../domain/entities/Section";
import { useServiceDescription } from "./useServiceDescription";
import { getAppointment_id } from "../../domain/entities/Appointment";
import { HeadingH3, PNormal } from "../styleguide/typography";
import { formatAppointmentDateToDateTimeWithWords } from "../../domain/services/appointments";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import type { Field, FieldSingle } from "../../domain/entities/Field";
import { TextWidget } from "../appointment-widgets/TextWidget";
import { TextareaWidget } from "../appointment-widgets/TextareaWidget";
import { NumberWidget } from "../appointment-widgets/NumberWidget";
import { CheckboxGroupWidget } from "../appointment-widgets/CheckboxGroupWidget";
import { RadioGroupWidget } from "../appointment-widgets/RadioGroupWidget";
import { FileWidget } from "../appointment-widgets/FileWidget";
import { DateWidget } from "../appointment-widgets/DateWidget";
import { BodyMassIndexWidget } from "../appointment-widgets/BodyMassIndexWidget";
import { FormulaWidget } from "../appointment-widgets/FormulaWidget";
import {
  getDefaultFieldValues,
  getDefaultFieldValuesForSection,
  getEmptyFieldValues,
  getEmptyFieldValuesForSection,
  getDefaultFieldValuesWithoutFilled,
  checkIfThereAreDefaultFieldValuesInSection,
  isFieldWithoutLabel,
  isLabelNeededAtGroup,
  READABLE_DATE_FORMAT,
  isValidReadableDate,
  getRequiredFields,
  getRequiredFieldsForDecisionSupport
} from "../../domain/services/field";
import { FieldGroupItemFieldSet } from "../form/FieldGroupItemFieldSet";
import { FormSection } from "../form/FormSection";
import { Fieldset, Legend } from "../form/Fieldset";
import {
  cleanPrescriptionTable,
  clearAppointment,
  clearAppointmentStore,
  clearAppointmentSection,
  getAllFieldValuesDescription,
  serializeAppointment,
  setAppointmentDefaultValues,
} from "../../stores/appointmentFieldValues";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { RemoveButton } from "./RemoveButton";
import { ServicesFormSection } from "./ServicesFormSection";
import { ServicesFormLayout } from "./ServicesFormLayout";
import { AppointmentFormLayout } from "./AppointmentFormLayout";
import styled, { css } from "styled-components";
import type { LayoutDetailed } from "../../domain/entities/Layout";
import { useStore, useStoreMap } from "effector-react";
import { tokenStore } from "../../stores/auth";
import useReactRouter from "use-react-router";
import { NormButton, NormButtonAsIcon } from "./NormButton";
import { CleanButton, CleanButtonAsIcon } from "../buttons/CleanButton";
import { useBeforeUnload } from "../../utils/useBeforeUnload";
import { useDomEventsCounter } from "../../utils/useDomEventsCounter";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import { ModalPortal } from "../modal/ModalPortal";
import { useConfirm } from "../alert-warning/useConfirm";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { AlertWarning } from "../alert-warning/AlertWarning";
import _ from "lodash";
import { PrescriptionTableWidget } from "../appointment-widgets/PrescriptionTableWidget";
import { ButtonFormSection } from "./ButtonFormSection";
import { OnlineSectionButton } from "./OnlineSectionButton";
import { getPrescriptionFromSection } from "../../domain/services/prescription";
import { RemovePrescriptionTableWithConfirmButton } from "../prescription-table/RemovePrescriptionTableWithConfirmButton";
import { getField_id } from "../../domain/entities/Field";
import { useFieldValue } from "../appointment-widgets/useFieldValue";
import { AppointmentDiagnoses } from "../decision-support/AppointmentDiagnoses";
import { DecisionSupportFormSection } from "../decision-support/DecisionSupportFormSection";
import { AppointmentExaminations } from "../decision-support/AppointmentExaminations";
import {
  conclusionTypeStore,
  primaryDiagnoses,
  secondaryDiagnoses,
  setConclusionType,
  setSaveDecisionValues,
  appointmentExaminationsStore,
  appointmentMedicationStore,
  appointmentRecommendationsStore,
  isPrimaryDiagnosesEmpty,
  isSecondaryDiagnosesEmpty,
  updateAppointmentConclusions,
} from "../../stores/decisionSupportStores";
import { AppointmentMedication } from "../decision-support/AppointmentMedication";
import { AppointmentRecommendation } from "../decision-support/AppointmentRecommendation";
import { Shortcuts } from "../shortcuts/Shortcuts";
import { getLayout_id } from "../../domain/entities/Layout";
import { currentFieldSectionStore } from "../../stores/shortcuts/fieldShortcuts";
import { fetchShortcutsEffect } from "../../stores/shortcuts/shortcutsShared";
import { ShortcutsTextarea } from "../shortcuts/ShortcutsTextarea";
import {
  createShortcuts,
  isCurrentServices,
  isHaveServicesShortcuts,
  openShortcuts
} from "../../stores/shortcuts/servicesShortcuts";
import { ConclusionGuideProvider } from "../decision-support/ConclusionGuideProvider";
import { Info } from "../decision-support/Info";
import { RightSidebarPortal } from "../right-sidebar/RightSidebarPortal";
import { SidebarContainer } from "../sidebar/SidebarContainer";
import {
  changeRightSidebar,
  closeRightSidebar,
  STIMULATION_PROTOCOL_CONTENT,
  rightSidebarState,
  DIAGNOSES_TREE_CONTENT,
  INFO_CONTENT,
  SERVICES,
  PRINT_DOCUMENTS,
  openPrintDocumentsSidebar
} from "../../stores/rightSidebar";
import {
  openExaminationsInfoEvent,
  openMedicationInfoEvent,
  openRecommendationsInfoEvent
} from "../../stores/infoType";
import { DiagnosesTreeSidebar } from "../diagnoses-tree/DiagnosesTreeSidebar";
import { ScrollToTopButton } from "../buttons/ScrollToTopButton";
import { PatientIdSection } from "./PatientIdSection";
import { IsEmptySectionProvider } from "./IsEmptySectionProvider";
import { DateInput } from "../form-elements/legacy/DateInput";
import { useNextVisitDate } from "./useNextVisitDate";
import moment from "moment";
import { Checkbox } from "./Checkbox";
import { reloadFeedbacks } from "../appointment-feedback/feedbacksStore";
import { AIFormSection } from "../artificial-intelligence/AIFormSection";
import { AppointmentAISlicesView } from "../artificial-intelligence/AppointmentAISlicesView";
import ConclusionTooltip from "./ConclusionTooltip";
import { colors } from "../styleguide/colors";
import { LiveBirthProbability } from "./LiveBirthProbability";
import { AllServicesButton } from "./AllServicesButton";
import { ServicesTree } from "../services/ServicesTree";
import { ServicesSuggest } from "../services/ServicesSuggest";
import { StimulationProtocol, StimulationProtocolInfo } from "./StimulationProtocol";
import { PatientReminder } from "./PatientReminder";
import { formatDatetimeToDatetimeFormat, READABLE_TIME_FORMAT_MOMENT } from "../../utils/dates";
import { getConcatRequiredFields } from "../../utils/getConcatRequiredFields";
import { hasDateFormatError } from "../form-elements/DateInput";
import { hasTimeFormatError } from "../form-elements/TimeInput";
import { getTableFromSection, Table } from "../table/Table";
import { PrintDocumentsFixedLabel } from "../print-documents/PrintDocumentsFixedLabel";
import { appointmentStore } from "../../stores/appointment";
import {
  applicationFeatureEnabled,
  FEATURE,
  featureEnabledForOrganization,
  useApplicationFeatureEnabled,
  useApplicationFeatureValue
} from "../../utils/applicationFeatures";
import { DocumentsTree } from "../documents-tree/DocumentsTree";
import {dispatchEvent, useSubscribe} from "../../utils/useEvents";
import {useToast} from "../toast/useToast";
import serviceStore from "../../stores/serviceStore";
import ServicesTooltip from './ServicesTooltip';
import apiv2 from '../../apiv2';
import popularLayoutsStore from '../../stores/popularLayoutsStore';
import appointmentMobxStore from "../../stores/AppointmentStore";
import { AppointmentTitle } from "./AppointmentTitle";
import CheckUpTable, { ChecklistTypes } from "./CheckUpTable";
import { BlockLoader } from "../loader/Loader";
import ticketStore from "../../stores/ticketStore";
import AppointmentServicesTooltip from "./AppointmentServicesTooltip";
import Tooltip from "@mui/material/Tooltip";
import { TimePickerWidget } from "../appointment-widgets/TimePickerWidget";

const AllPriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border-top: 1px solid ${colors.gray100};
  margin-top: 16px;
  padding: 16px 32px 0;

  @media(max-width: 1180px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const AllPriceValue = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  color: ${colors.gray600};
  text-align: right;
  @media (max-width: 1180px) {
    margin-bottom: 12px;
    text-align: left;
  }
`;

const AllPriceMessage = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: ${colors.silver};
`;

const MetaInfoText = styled(PNormal)`
  opacity: 0.6;
  @media screen and (max-width: 1024px) {
    font-size: 15px;
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const MetaInfoContainer = styled.div`
  display: flex;
  gap: 30px;

  @media screen and (max-width: 1024px) {
    gap: 10px;
    justify-content: space-between;
    width: 100%;
  }
`;

const SectionsContainer = styled.div`
  > * + * {
    margin-top: 27px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 31px;

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`;

export const FieldsetContainer = styled.div`
  & + & {
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    & + & {
      margin-top: 20px;
    }
  }
  @media (max-width: 480px) {
    & + & {
      margin-top: 10px;
    }
  }
`;

const ServicesFormFieldsetContainer = styled.div`
  margin-top: 24px;
`;

const FormSectionExtendedTitle = styled.div`
  display: inline-flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

	@media (max-width: 640px) {
    gap: 4px;
    flex-direction: column;
    align-items: flex-start;
		justify-content: center;
	}
`;

const FormSectionTitleError = styled.span`
  font-size: 14px;
  color: ${colors.cinnabar};
  margin-right: 16px;
`;

const FieldsetGroupItemContainer = styled.div`
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin: 20px 0;
  }
  @media (max-width: 480px) {
    margin: 10px 0;
  }
`;

const FieldsetLegendCheckbox = styled(Legend)`
  top: 0;
`;

const FieldsetLegendGroup = styled(Legend)`
  top: 0;
`;

const ToolButtons = styled.div`
  display: flex;
  button {
    margin-left: 13px;
    @media screen and (max-width: 1024px) {
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

const ConclusionTypeContainer = styled.div`
  display: flex;
  aligh-items: center;
  line-height: 23px;
  margin-right: 15px;
  margin-bottom: 3px;

  & * {
    color: ${colors.gray700};
  }
`;

const ConclusionTypeSplash = styled.p`
  font-size: 13px;
`;

const ConclusionButton = styled.button`
  margin-left: 8px;
  padding: 0;
  background: none;
  border: none;
  font-size: 15px;
  text-transform: lowercase;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  :not(:last-child) {
    margin-right: 8px;
    margin-left: 0px;
  }

  ${({ active }) =>
    active
      ? css`
          color: ${colors.magenta};
        `
      : css`
          :hover {
            color: ${colors.lightMagenta};
          }
        `}
`;

const ConfirmationMessage = styled(HeadingH3)`
  line-height: 36px;
`;

const ServiceForm = props => {
  const {
    appointment,
    layout,
    submitForm,
    onDelete,
    serviceDescription,
    changeServiceDescription,
    thereIsNextVisitDate,
    nextVisitDate,
    changeNextVisitDate,
    feedbackDate,
    changeFeedbackDate,
    isAgreeWithFeedback,
    setAgreeWithFeedback,
    patientReminder,
    setPatientReminder,
    patientReminderValid,
    patientReminderDatetimeError,
    servicesSuggestRef,
    requiredFields,
    isInvoiceForPayment,
    setIsInvoiceForPayment,
    isAppointmentAmbulance,
    setIsAppointmentAmbulance
  } = props;
  const isCurrent = useStore(isCurrentServices);
  const isHaveShortcuts = useStore(isHaveServicesShortcuts);
  const [selectedServices, setSelectedServices] = useState(appointment.services);
  const isPaymentViaAppEnabled = useApplicationFeatureEnabled(FEATURE.PAYMENT_VIA_APP) && !appointment.has_been_reopened && appointment.medical_file.organization.id !== 30;
  const isAmbulanceEnabled = useApplicationFeatureEnabled(FEATURE.AMBULANCE);
  const canEditPrevServices = useApplicationFeatureEnabled(FEATURE.EDIT_PREV_SERVICES);
  // const appointmentHasErrors = useErrorsSearch(getAppointment_id(appointment));


  useSubscribe("appointmentServicesShortcutInsert", (services) => {
    services.forEach((s) => {
      servicesSuggestRef.current.handleSelectService(s);
    });
  })

  useEffect(() => {
    if (isInvoiceForPayment && !selectedServices.length) {
      setIsInvoiceForPayment(false);
    }
    serviceStore.setAppointmentServices(selectedServices);
  }, [selectedServices]);

  const changeDateByKey = {
    feedbackDate: changeFeedbackDate,
    nextVisitDate: changeNextVisitDate
  };

  const [serviceErrors, setServiceErrors] = useState({
    nextVisitDate: "",
    feedbackDate: ""
  });

  const [isSubmittedForm, setIsSubmitForm] = useState(false);

  const handleBlurNextVisitDate = (value: ?string) => {
    if (isSubmittedForm) {
      setServiceErrors({
        ...serviceErrors,
        nextVisitDate: validateDateInput(value)
      });
    }
  };

  const handleBlurFeedbackDate = (value: ?string) => {
    if (isSubmittedForm) {
      setServiceErrors({
        ...serviceErrors,
        feedbackDate: isAgreeWithFeedback && validateDateInput(value)
      });
    }
  };

  const validateDateInput = value => {
    if (!value) {
      return "";
    }

    const dateValue = value ? moment(value, READABLE_DATE_FORMAT) : value;

    if (!isValidReadableDate(value)) {
      return true;
    } else if (dateValue.isBefore(new Date(), "day")) {
      return "Дата должна быть в будущем";
    } else {
      return "";
    }
  };

  const handleChangeDate = type => value => {
    if (isSubmittedForm) {
      setServiceErrors({
        ...serviceErrors,
        [type]: (type === "feedbackDate" && !isAgreeWithFeedback) || validateDateInput(value)
      });
    }

    changeDateByKey[type](value);
  };

  const handleSubmit = () => {
    setIsSubmitForm(true);
    const nexVisitDateError = thereIsNextVisitDate && validateDateInput(nextVisitDate);
    const feedbackDateError = isAgreeWithFeedback && validateDateInput(feedbackDate);

    if ((nexVisitDateError && !appointment.has_been_reopened) || feedbackDateError) {
      setServiceErrors({
        ...serviceErrors,
        nextVisitDate: nexVisitDateError,
        feedbackDate: feedbackDateError
      });
    } else {
      submitForm();
    }
  };

  const appointmentCreateDateIsToday = () => {
    const dateCreate = new Date(appointment?.date).getTime();
    const dateNow = new Date(moment(Date.now()).format("YYYY-MM-DD")).getTime();

    return dateNow === dateCreate;
  };

  const calcAddServicesDisabled = () =>
    !canEditPrevServices && !appointmentCreateDateIsToday() && "Услуги можно редактировать только в день создания приема.";

  useEffect(() => {
    if (isSubmittedForm) {
      setServiceErrors({
        ...serviceErrors,
        nextVisitDate: !appointment.has_been_reopened && isValidReadableDate(nextVisitDate),
        feedbackDate: isValidReadableDate(feedbackDate)
      });
    }
  }, [appointment]);



  const requiredServicesText = selectedServices.length === 0 && (
      <>
        Выберите одну или несколько услуг, чтобы завершить прием.
      </>
  );

  const buttons = (
    <ButtonsContainer>
      <PrimaryButton
        onClick={handleSubmit}
        title={requiredServicesText}
        isLoading={appointmentMobxStore.isEndAppointmentLoading}
        disabled={selectedServices.length === 0 && !serviceDescription && !window.QUEST_MODE}>
        {appointment.has_been_reopened || window.QUEST_MODE ? "Сохранить" : "Завершить прием"}
      </PrimaryButton>
      {!window.QUEST_MODE && <RemoveButton onClick={onDelete}>Удалить</RemoveButton>}

    </ButtonsContainer>
  );

  const allServicesButton = <AllServicesButton onClick={() => changeRightSidebar(SERVICES)} />;

  const servicesFormSection = (
    <ServicesFormSection name={"Перечень оказанных услуг и проведенных обследований"}
                         key={appointment.id + 'services'}
                         toolButton={
                            <>
                               {allServicesButton}
                              { !window.QUEST_MODE && appointmentCreateDateIsToday() &&
                                 <AppointmentServicesTooltip
                                     fieldName="appointment_services"
                                     appointment={appointment}
                                     selectedServices={selectedServices}
                                 />
                              }
                            </>
                         }
    >
      <ServicesSuggest
        ref={servicesSuggestRef}
        disabled={calcAddServicesDisabled()}
        appointment={appointment}
        displayTeeth={layout.display_teeth}
        canChooseFromAll={layout.prev_prescribed_services}
        onChange={services => setSelectedServices(services)}
      />
      {isPaymentViaAppEnabled && (
        <ServicesFormFieldsetContainer>
          <Checkbox
            textTooltip={!appointment.medical_file.patient.is_mobile_user ? "Пациента нет в приложении" : !selectedServices.length ? "Нет выбранных услуг" : ""}
            inAppointmentPage
            text="Оплата через приложение"
            checked={isInvoiceForPayment}
            disabled={!selectedServices.length || !appointment.medical_file.patient.is_mobile_user}
            onChange={() => setIsInvoiceForPayment(!isInvoiceForPayment)}
          />
        </ServicesFormFieldsetContainer>
      )}
      {isAmbulanceEnabled && (
        <ServicesFormFieldsetContainer>
          <Checkbox
            inAppointmentPage
            text="Прием в рамках ОЭП"
            checked={isAppointmentAmbulance}
            onChange={() => setIsAppointmentAmbulance(!isAppointmentAmbulance)}
          />
        </ServicesFormFieldsetContainer>
      )}
      <ServicesFormFieldsetContainer>
        <ShortcutsTextarea
          value={serviceDescription}
          storedValue={serviceDescription}
          placeholder="Комментарий для администратора"
          handleChange={changeServiceDescription}
          isHaveShortcuts={isHaveShortcuts}
          isCurrent={isCurrent}
          onCreateShortcuts={createShortcuts}
          onOpenShortcuts={openShortcuts}
        />
      </ServicesFormFieldsetContainer>

      {thereIsNextVisitDate && (
        <ServicesFormFieldsetContainer>
          <Fieldset legend="Дата следующего приема">
            <DateInput
              value={nextVisitDate || ""}
              handleChange={handleChangeDate("nextVisitDate")}
              placeholder="ДД.ММ.ГГГГ"
              minDateTomorrow
              disabled={appointment.has_been_reopened}
              error={serviceErrors.nextVisitDate}
              onBlur={() => handleBlurNextVisitDate(nextVisitDate)}
            />
          </Fieldset>
        </ServicesFormFieldsetContainer>
      )}
      {layout && layout.feedback_survey && (
        <>
          <ServicesFormFieldsetContainer style={{ marginTop: 30 }}>
            <Checkbox
              inAppointmentPage
              text="Пациент разрешил собрать обратную связь"
              checked={appointment.notification_date ? true : isAgreeWithFeedback}
              disabled={!!appointment.notification_date}
              onChange={() => setAgreeWithFeedback(!isAgreeWithFeedback)}
            />
          </ServicesFormFieldsetContainer>
          {isAgreeWithFeedback && (
            <ServicesFormFieldsetContainer>
              <Fieldset legend="Дата сбора обратной связи">
                <DateInput
                  value={isAgreeWithFeedback ? feedbackDate || "" : ""}
                  handleChange={handleChangeDate("feedbackDate")}
                  placeholder="ДД.ММ.ГГГГ"
                  minDateToday
                  error={serviceErrors.feedbackDate}
                  onBlur={() => handleBlurFeedbackDate(feedbackDate)}
                />
              </Fieldset>
            </ServicesFormFieldsetContainer>
          )}
        </>
      )}
      <ServicesFormFieldsetContainer>
        {!appointment.has_been_reopened && (
          <PatientReminder
            {...patientReminder}
            datetimeError={patientReminderDatetimeError}
            valid={patientReminderValid}
            onChange={(field, value) => setPatientReminder(prev => ({ ...prev, [field]: value }))}
          />
        )}
      </ServicesFormFieldsetContainer>
    </ServicesFormSection>
  );


  const appointmentForPrescribedServices = layout.name === 'Ввод направлений для процедурного кабинета' || window.QUEST_MODE;

  if (appointmentForPrescribedServices) {
    return <ServicesFormLayout
        form={null}
        buttons={buttons}
        requiredFields={requiredFields}
        info={null}
    />
  }

  return (
    <ServicesFormLayout
      form={servicesFormSection}
      buttons={buttons}
      requiredFields={requiredFields}
      info={requiredServicesText}
    />
  );
};

const PrescriptionTable = props => {
  const { appointment, prescription, fieldValue } = props;
  const useFieldValueResult = useFieldValue(
    getAppointment_id(appointment),
    getField_id(prescription)
  );
  const isPrescriptionTableExist =
    useFieldValueResult &&
    useFieldValueResult.prescriptionTable &&
    useFieldValueResult.prescriptionTable.params;
  const cleanButton = (
    <RemovePrescriptionTableWithConfirmButton
      onClick={() =>
        cleanPrescriptionTable({
          appointment_id: getAppointment_id(appointment),
          field_id: getField_id(prescription)
        })
      }>
      Удалить
    </RemovePrescriptionTableWithConfirmButton>
  );
  return (
    <ButtonFormSection
      name="Таблица назначения препаратов"
      button={isPrescriptionTableExist && cleanButton}>
      <PrescriptionTableWidget
        field={prescription}
        appointment_id={getAppointment_id(appointment)}
        layoutFieldValue={fieldValue}
      />
    </ButtonFormSection>
  );
};

export const EditAppointmentPage = observer((props: {
  layout: LayoutDetailed
}) => {
  useBeforeUnload("Могут быть несохраненные данные", process.env.NODE_ENV !== "development");

  const toastManager = useToast();

  const { layout } = props;
  const token = useStore(tokenStore);
  const appointment = useStore(appointmentStore);
  // const currentUser = useStore(currentUserStore);
  const { history } = useReactRouter();

  const [feedbackDate, changeFeedbackDate] = React.useState(appointment.notification_date || "");
  const [requiredFieldsValidate, changeRequiredFieldsValidate] = React.useState(null);
  const [requiredFieldsForDecisionSupportValidate, changeRequiredFieldsForDecisionSupportValidate] = React.useState([]);
  const [isAgreeWithFeedback, setAgreeWithFeedback] = React.useState(false);
  const [isInvoiceForPayment, setIsInvoiceForPayment] = React.useState(false);
  const [isAppointmentAmbulance, setIsAppointmentAmbulance] = React.useState(!!appointment?.is_ambulance);
  const [checkup, setCheckup] = React.useState(null);
  const questionnaireSectionFemaleId = useApplicationFeatureValue(FEATURE.HC_QUESTIONNAIRE_SECTION_FEMALE_ID);
  const requiredFields = getRequiredFields(layout, appointment, questionnaireSectionFemaleId);
  const requiredFieldsForDecisionSupport = getRequiredFieldsForDecisionSupport(layout, appointment);

  useEffect(() => {
    popularLayoutsStore.initialize();
  }, []);

  const servicesSuggestRef = useRef();

  const addToServicesCallback = useCallback((...args) => {
    servicesSuggestRef.current?.handleSelectService(...args)
  }, [servicesSuggestRef.current]);

  const removeFromServicesCallback = useCallback((...args) => {
    servicesSuggestRef.current?.handleRemoveService(...args)
  }, [servicesSuggestRef.current]);
  const checkedItemsChecklistAllPrice = (items) => {
    let price = 0;
    items.forEach((checklistItem) => {
      if (checklistItem.items) {
        price += checkedItemsChecklistAllPrice(checklistItem.items);
      } else if (checklistItem.is_checked && checklistItem.services && checklistItem.services[0]) {
        price += checklistItem.services[0].price * (checklistItem.count || 1);
      }
    });
    return price;
  }

  const [checkedItemsExaminationChecklistAllPrice, setCheckedItemsExaminationChecklistAllPrice] = React.useState(checkedItemsChecklistAllPrice(appointment.examination_checklist?.items || []));

  useEffect(() => {
    serviceStore.setExaminationItems(appointment.examination_checklist?.items);

    if (serviceStore.createdAppointmentWithCopy) {
      serviceStore.setCreatedAppointmentWithCopy(false);
      setTimeout(() => {
        appointment.examination_checklist?.items.forEach(examinationGroup => {
          examinationGroup.items?.forEach(item => {
            if (item.is_checked) {
              dispatchEvent("examinationCheckChange", {
                item: item,
                value: true
              });
            }
          });
        })
      }, 5000);
    }
  }, []);

  useEffect(() => {
    serviceStore.setExaminationItems(appointment.examination_checklist?.items);
  }, [appointment.id]);

  const [patientReminder, setPatientReminder] = useState({
    text: "",
    date: "",
    time: ""
  });

  const patientReminderDatetime = formatDatetimeToDatetimeFormat(
    patientReminder.date,
    patientReminder.time
  );

  const patientReminderDatetimeError =
    !hasDateFormatError(patientReminder.date) &&
    !hasTimeFormatError(patientReminder.time) &&
    patientReminderDatetime.isValid() &&
    patientReminderDatetime.isBefore(new Date());

  const patientReminderValid =
    patientReminder.text !== "" &&
    !hasDateFormatError(patientReminder.date) &&
    !hasTimeFormatError(patientReminder.time) &&
    patientReminderDatetime.isValid() &&
    !patientReminderDatetimeError;

  const rightSidebar = useStore(rightSidebarState);
  const counters = useDomEventsCounter();
  const conclusionFetchedRef = useRef(null);

  useEffect(() => {
    fetchShortcutsEffect({ token: token || "", layout_id: getLayout_id(layout), organization_id: appointment.medical_file.organization.id });
  }, [layout]);

  useEffect(() => {
    (async () => {
      const checkData = (appointment.examination_checklist?.items?.length && !appointmentExaminationsStore.getState()[appointment.id]) ||
        (appointment.medication_checklist?.items?.length && !appointmentMedicationStore.getState()[appointment.id]) ||
        (appointment.recommendation_checklist?.items?.length && !appointmentRecommendationsStore.getState()[appointment.id]);
      if (appointment.primary_diagnoses.length && checkData && conclusionFetchedRef.current !== appointment.id) { // Проверяем что для текущего приема есть диагнозы и данные по диагнозам еще не запрашивались
        conclusionFetchedRef.current = appointment.id;
        await updateAppointmentConclusions(appointment);
      }
      const updateAllPrice = () => {
        setCheckedItemsExaminationChecklistAllPrice(checkedItemsChecklistAllPrice(appointment.examination_checklist?.items || []));
      };
      updateAllPrice();
    })();
  }, [appointment]);

  useEffect(() => {
    if (rightSidebar === SERVICES) {
      closeRightSidebar();
    }
  }, [appointment]);

  useEffect(() => {
    if (layout?.closing_check_up && appointment?.folder?.id) {
      (async () => {
        const result = await apiv2.appointments.getFinishCheckUp(appointment.folder.id);
        setCheckup(result);
      })();
    }
  }, [layout, appointment]);

  const currentSection = useStore(currentFieldSectionStore);

  const fieldValuesMap = _.keyBy(appointment.layout_field_values, item => item.field);

  const deleteConfirm = useConfirm();

  const finishConfirm = useConfirm();

  const cleanConfirm = useConfirm();

  const applyNormConfirm = useConfirm();

  const metaInfo =
    (appointment && (
      <React.Fragment>
        <MetaInfoContainer>
          <MetaInfoText>{formatAppointmentDateToDateTimeWithWords(appointment)}</MetaInfoText>
          {appointment.medical_file ? (
            <MetaInfoText>
              АК №{appointment.medical_file.number} {appointment.medical_file.organization.name || ""}
            </MetaInfoText>
          ) : null}
          <MetaInfoText>{getLastNameWithInitials(appointment.doctor)}</MetaInfoText>
        </MetaInfoContainer>
      </React.Fragment>
    )) ||
    null;

  const onClickNormButtonForSection = (section: Section) => {
    const appointment_id = getAppointment_id(appointment);
    const defaultValues = getDefaultFieldValuesForSection(section);
    const fieldValues = getAllFieldValuesDescription(appointment_id);
    const values = getDefaultFieldValuesWithoutFilled(defaultValues, fieldValues, appointment);
    setAppointmentDefaultValues({ appointment_id, values });
  };

  const onClickCleanButtonForSection = (section: Section) => {
    const appointment_id = getAppointment_id(appointment);
    const emptyValues = getEmptyFieldValuesForSection(section);
    clearAppointmentSection({ appointment_id, emptyValues });
  };

  const onClickNormButton = async () => {
    if (!applyNormConfirm.requestConfirm) {
      return;
    }

    try {
      const confirmResult = await applyNormConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }

      const appointment_id = getAppointment_id(appointment);
      const defaultValues = getDefaultFieldValues(layout);
      const fieldValues = getAllFieldValuesDescription(appointment_id);
      const values = getDefaultFieldValuesWithoutFilled(defaultValues, fieldValues, appointment);

      setAppointmentDefaultValues({ appointment_id, values });
    } catch (e) {
      console.log(e);
    }
  };

  const onClickCleanButton = async () => {
    if (!cleanConfirm.requestConfirm) {
      return;
    }

    try {
      const confirmResult = await cleanConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }

      const appointment_id = getAppointment_id(appointment);
      const emptyValues = getEmptyFieldValues(layout);

      clearAppointment({ appointment_id, emptyValues });
    } catch (e) {
      console.log(e);
    }
  };

  const toolButtons = (
    <ToolButtons>
      <NormButton onClick={onClickNormButton} />
      <CleanButton onClick={onClickCleanButton}>Очистить</CleanButton>
    </ToolButtons>
  );

  const conclusionType = useStore(conclusionTypeStore);

  const handleConclusionTypeClick = useCallback(
    type => () => {
      if (type === conclusionType) return;
      setConclusionType({ type, appointment });
    },
    [appointment, conclusionType]
  );

  const getWidgetSingle = (field: FieldSingle, tabIndex: number, withLabel: boolean) => {
    if (!appointment) {
      return null;
    }
    const showError = requiredFieldsValidate ? requiredFieldsValidate.some(item => item.id === field.id) : false;
    switch (field.field_type) {
      case "text":
        return (
          <TextWidget
            field={field}
            tabIndex={tabIndex}
            showError={showError}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "textarea":
        return (
          <TextareaWidget
            field={field}
            showError={showError}
            tabIndex={tabIndex}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "number":
        return (
          <NumberWidget
            field={field}
            showError={showError}
            tabIndex={tabIndex}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "date":
        return (
          <DateWidget
            field={field}
            showError={showError}
            tabIndex={tabIndex}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "time":
        return (
          <TimePickerWidget
            field={field}
            showError={showError}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "checkbox":
        return (
          <CheckboxGroupWidget
            showRequired={!withLabel}
            showError={showError}
            field={field}
            tabIndex={tabIndex}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "radiobutton":
        return (
          <RadioGroupWidget
            showRequired={!withLabel}
            showError={showError}
            field={field}
            tabIndex={tabIndex}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "file":
        return (
          <FileWidget
            field={field}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "prescription":
        return (
          <PrescriptionTableWidget
            field={field}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "body_mass_index":
        return (
          <BodyMassIndexWidget
            key={field.id}
            field={field}
            appointment_id={getAppointment_id(appointment)}
            layoutFieldValue={fieldValuesMap[field.id]}
          />
        );
      case "formula":
        return (
          <FormulaWidget key={field.id} field={field} appointment={appointment} layout={layout} />
        );
      default: {
        (field.field_type: empty); // check that all field_type is considered
        return (
          <div style={{ height: "50px" }}>Haven&quot;t widget for type {field.field_type}</div>
        );
      }
    }
  };

  const getFieldsetLegendComponent = (field: Field) => {
    if (
      field.field_type === "checkbox" ||
      field.field_type === "radiobutton" ||
      (field.field_type === "group" &&
        field.children.length > 0 &&
        (field.children[0].field_type === "checkbox" ||
          field.children[0].field_type === "radiobutton"))
    ) {
      return FieldsetLegendCheckbox;
    }
    if (field.field_type === "group" && field.children.length > 0) {
      return FieldsetLegendGroup;
    }
  };

  const getWidget = (field: Field, tabIndex: number) => {
    if (field.field_type === "group") {
      return (
        <React.Fragment>
          {field.children.map(child => {
            return (
              <FieldsetGroupItemContainer key={child.id}>
                {isLabelNeededAtGroup(child) ? (
                  <FieldGroupItemFieldSet required={child.required} label={child.name}>
                    {getWidgetSingle(child, tabIndex)}
                  </FieldGroupItemFieldSet>
                ) : (
                  getWidgetSingle(child, tabIndex)
                )}
              </FieldsetGroupItemContainer>
            );
          })}
        </React.Fragment>
      );
    } else {
      return getWidgetSingle(field, tabIndex, true);
    }
  };

  const prescribedServicesSuggestRef = useRef();

  const examinationUpdateChecked = (items) => {
    setCheckedItemsExaminationChecklistAllPrice(checkedItemsChecklistAllPrice(items));
    serviceStore.setExaminationItems(items);
  }

  useSubscribe("changeRadioGroup", ({ value, oldValue, field }) => {
    if (!prescribedServicesSuggestRef.current) {
      return;
    }
    const copy_ids = [
      'EJm1VbhPEe2ogFJecPaDog',
      'JcOoM/y8EeqcBrrMa1NaJw',
      'IxVudVmyEeuwj857RBxObw',
      'SnlSXXAPEemQPrrMa1NaJw',
      '1jLHcGFTEe+iPupojWYA2g',
      '1+M25mFSEe+OwKpY2t99PQ',
      '3TlktmFTEe+IWnJNyJ8/Pg',
      '4tGKkGFREe+ufNKHXgzmqA',
      '6wwmYmFQEe+jAA6Suw6nrA',
      'BTbZqGFUEe+258IdUfP83Q',
      'c1J9dmFTEe+ZFg6Suw6nrA',
      'D5zYImFSEe+w/nJNyJ8/Pg',
      'G1T/hGFQEe+CUw6Suw6nrA',
      'gx42gmFTEe+WApqoZTPGKg',
      'hGVjRmFREe+gadKHXgzmqA',
      'k3FSfmFQEe+PHupojWYA2g',
      'Ls/5sGFQEe+1Mq53z3eHBA',
      'mdz0HmFREe+8sZqoZTPGKg',
      'mLTRPGFSEe+yVXJNyJ8/Pg',
      'psGByGFQEe+yVXJNyJ8/Pg',
      'qQukEGFTEe+GKT6zh1vzfQ',
      'QwLfJGFQEe+r6VL48NkLAQ',
      's0pFmmFSEe+56oJ0btj4jw',
      '/uTDLmFQEe+GcK53z3eHBA',
      'v88AoGFQEe+IWnJNyJ8/Pg',
      'WWIpbmFQEe+Xs4J0btj4jw',
      'x7KXJmFSEe+kyOpojWYA2g',
      'XZdKkGFREe+MZQ6Suw6nrA',
      'h/bFomFTEe+OR1L48NkLAQ',
      'mlZJhGFTEe+61w6Suw6nrA',
      'ByagrmExEe+M1kbbL/3gRg',
      'PwUxE+aIEemQP7rMa1NaJw',
      'Ar1R71Q5EemswLrMa1NaJw',
      '30gfqmFSEe+UeA6Suw6nrA',
      '43SUHGFPEe+258IdUfP83Q',
      '9iaUEmFOEe+M/3JNyJ8/Pg',
      '9TkH+mFPEe+ZFg6Suw6nrA',
      '9Y92JmFPEe+YaXJNyJ8/Pg',
      'C3UGnGFTEe+Lo653z3eHBA',
      'ekz58mFPEe+T9apY2t99PQ',
      'FMD4LGFPEe+xYK53z3eHBA',
      'jDbcBmFOEe+mzqpY2t99PQ',
      'jxlokGFSEe+chq53z3eHBA',
      'Kz0RxmFPEe+LfT6zh1vzfQ',
      'lzrfXGFPEe+HDIJ0btj4jw',
      'olN5omFQEe+2C3JNyJ8/Pg',
      'q6NTKmFPEe+CGKpY2t99PQ',
      'qE1INmFSEe+HsVL48NkLAQ',
      'S7BxomFSEe+pnKqb6fjZ3A',
      'TntO8GFPEe+FeXJNyJ8/Pg',
      'u8UoJmFREe+VJg6Suw6nrA',
      'vaZd5mFQEe+HsVL48NkLAQ',
      'vHGYEGFPEe+udVL48NkLAQ',
      'wlY4FGFSEe+Vsa53z3eHBA',
      'zdm/dGFPEe+WwqpY2t99PQ',
      'ZSs2sGFPEe+YaXJNyJ8/Pg',
      'cRKhtmFKEe+/Hj6zh1vzfQ',
      '9ERcPmFSEe+SAaqb6fjZ3A',
    ];
    if (!copy_ids.includes(field.copy_id)) {
      return;
    }
    if (window.lastValueAr1R71Q5EemswLrMa1NaJw === value) {
      return;
    }
    window.lastValueAr1R71Q5EemswLrMa1NaJw = value;
    if (oldValue && oldValue.toLowerCase() === 'показано врт') {
      const gynecologistConsultation = serviceStore.serviceForCurrentOrganization.find(s => s.ministry_of_health_code === 'B01.001.001.1');
      if (gynecologistConsultation) {
        prescribedServicesSuggestRef.current?.handleRemoveService(gynecologistConsultation.id);
        toastManager.showToast(
            <div>
              Из "Направления" удалено:
              <div>
                {gynecologistConsultation.name}
              </div>
            </div>,
            {
              duration: 5000
            }
        )
      }
      const eko = serviceStore.serviceForCurrentOrganization.find(s => s.kdf_code.trim() === 'нап1');
      if (eko) {
        prescribedServicesSuggestRef.current?.handleRemoveService(eko.id);
      }
    }
    if (oldValue && oldValue.toLowerCase() === 'показано хирургическое лечение') {
      const surgicalConsultation = serviceStore.serviceForCurrentOrganization.find(s => s.ministry_of_health_code === 'B01.057.001');
      if (surgicalConsultation) {
        prescribedServicesSuggestRef.current?.handleRemoveService(surgicalConsultation.id);
        toastManager.showToast(
            <div>
              Из "Направления" удалено:
              <div>
                {surgicalConsultation.name}
              </div>
            </div>,
            {
              duration: 5000
            }
        )
      }
      const surgicalTreatment = serviceStore.serviceForCurrentOrganization.find(s => s.kdf_code.trim() === 'нап2');
      if (surgicalTreatment) {
        prescribedServicesSuggestRef.current?.handleRemoveService(surgicalTreatment.id);
      }
    }


    if (value && value.toLowerCase() === 'показано врт') {
      const gynecologistConsultation = serviceStore.serviceForCurrentOrganization.find(s => s.ministry_of_health_code === 'B01.001.001.1');
      if (gynecologistConsultation) {
        prescribedServicesSuggestRef.current?.handleSelectService(gynecologistConsultation);
        toastManager.showToast(
            <div>
              В "Направления" добавлено:
              <div>
                {gynecologistConsultation.name}
              </div>
            </div>,
            {
              duration: 5000
            }
        )
      }
      const eko = serviceStore.serviceForCurrentOrganization.find(s => s.kdf_code.trim() === 'нап1');
      if (eko) {
        prescribedServicesSuggestRef.current?.handleSelectService(eko);
      }
    }
    if (value && value.toLowerCase() === 'показано хирургическое лечение') {
      const surgicalConsultation = serviceStore.serviceForCurrentOrganization.find(s => s.ministry_of_health_code === 'B01.057.001');
      if (surgicalConsultation) {
        prescribedServicesSuggestRef.current?.handleSelectService(surgicalConsultation);
        toastManager.showToast(
            <div>
              В "Направления" добавлено:
              <div>
                {surgicalConsultation.name}
              </div>
            </div>,
            {
              duration: 5000
            }
        )
      }
      const surgicalTreatment = serviceStore.serviceForCurrentOrganization.find(s => s.kdf_code.trim() === 'нап2');
      if (surgicalTreatment) {
        prescribedServicesSuggestRef.current?.handleSelectService(surgicalTreatment);
      }
    }
  });

  useSubscribe("examinationDateRangeChange", ({ value, item }) => {
    prescribedServicesSuggestRef.current?.setDateRange(item.services.map(s => s.id), value);
  });

  useSubscribe("examinationCommentChange", ({ value, item }) => {
    prescribedServicesSuggestRef.current?.setComment(item.services.map(s => s.id), value);
  });

  useSubscribe("examinationCheckChange", ({ value, item }) => {
    if (!prescribedServicesSuggestRef.current) {
      return;
    }
    if (!item.services) {
      return;
    }
    let services = item.services.filter((s) => !serviceStore.consultationsCategoriesIds.includes(s.category_id) || (s.ministry_of_health_code.startsWith('B01') && (s.ministry_of_health_code.endsWith('.001') || s.ministry_of_health_code.endsWith('.1')) && s.name.includes('ервичн') && !s.name.includes('Заочн') && !s.name.includes('Мнение')));
    services = serviceStore.methodSortServices(services)
    if (services.length) {
      if (value) {
        prescribedServicesSuggestRef.current?.handleSelectService(services[0]);
        toastManager.showToast(
            <div>
              В "Направления" добавлено:
              <div>
                {services[0].name}
              </div>
            </div>,
            {
              duration: 5000
            }
        );
      } else {
        prescribedServicesSuggestRef.current?.handleRemoveService(services[0].id)
        toastManager.showToast(
            <div>
              Из "Направления" удалено:
              <div>
                {services[0].name}
              </div>
            </div>,
            {
              duration: 5000
            }
        );
      }
    }
  });

  useSubscribe("prescribedServicesShortcutInsert", (services) => {
    services.forEach((s) => prescribedServicesSuggestRef.current?.handleSelectService(s));
  });


  const sections =
    (layout && (
      <SectionsContainer>
        {layout.appointment_id_bar_enabled && (
          <PatientIdSection
            id={getAppointment_id(appointment)}
            text={layout.appointment_id_bar_text}
          />
        )}
        {layout.sections.map((section, index) => {
          if (section.id === questionnaireSectionFemaleId && appointment.medical_file.patient.sex === 'M') {
            return null;
          }
          const prescription = getPrescriptionFromSection(section);
          if (prescription) {
            return (
              <PrescriptionTable
                key={index}
                appointment={appointment}
                prescription={prescription}
                fieldValue={fieldValuesMap[prescription.id]}
              />
            );
          }

          const table = getTableFromSection(section);
          if (table) {
            return (
              <Table
                key={index}
                editable={true}
                appointment={appointment}
                table={table}
                fieldValue={fieldValuesMap[table.id]}
              />
            );
          }

          if (section.for_live_birth_probability) {
            return (
              // $FlowFixMe
              <FormSection {...section}
                           key={index}>
                <LiveBirthProbability />
              </FormSection>
            );
          }

          const shouldShowNormButton = checkIfThereAreDefaultFieldValuesInSection(section);
          const expand_by_default = section.fields.some((field) => requiredFields.map((item) => item.id).includes(field.id)) || section.expand_by_default;
          return (
            <IsEmptySectionProvider
              appointment={getAppointment_id(appointment)}
              section={section}
              savedFilledValues={fieldValuesMap}
              key={section.id}>
              {isEmpty => (
                // $FlowFixMe
                <FormSection
                  {...section}
                  isEmpty={isEmpty}
                  expand_by_default={expand_by_default}
                  shouldExpand={currentSection === section.id}
                  toolButton={ !window.QUEST_MODE &&
                    <>
                      {shouldShowNormButton && (
                        <NormButtonAsIcon onClick={() => onClickNormButtonForSection(section)} />
                      )}
                      <CleanButtonAsIcon onClick={() => onClickCleanButtonForSection(section)} />
                      {/* TODO: Add blanks button to this section */}
                    </>
                  }>
                  {section.fields.map(field => {
                    const widget = getWidget(field, index + 1);
                    const isWithoutLabel = isFieldWithoutLabel(field);
                    if (field.field_type === "body_mass_index") {
                      return widget;
                    }
                    return (
                      <FieldsetContainer key={field.id}>
                        {isWithoutLabel ? (
                          widget
                        ) : (
                          <Fieldset
                            required={field.required}
                            overrides={{
                              Legend: {
                                component: getFieldsetLegendComponent(field)
                              }
                            }}
                            legend={field.name}>
                            {widget}
                          </Fieldset>
                        )}
                      </FieldsetContainer>
                    );
                  })}
                </FormSection>
              )}
            </IsEmptySectionProvider>
          );
        })}

        {layout.slices_bar_enabled && appointment.medical_file.organization.slices_bar_enabled && (
          <AIFormSection name="Список обязательных срезов" expand_by_default>
            <AppointmentAISlicesView appointment={appointment} />
          </AIFormSection>
        )}

        {layout.decision_support && (
          <>
            <ConclusionGuideProvider appointment={appointment}>
              {({ isNotEmpty }) => {
                const name = "Диагноз по МКБ";
                const diagnosesIsEmpty = isPrimaryDiagnosesEmpty() && isSecondaryDiagnosesEmpty();
                const isRequired = requiredFieldsForDecisionSupport.some((field) => field.name === name);
                const isError = requiredFieldsForDecisionSupportValidate && requiredFieldsForDecisionSupportValidate.some((field) => field.name === name);
                const callbackOnChangeDiagnoses = (isClear) => {
                  if (!isClear) {
                    return;
                  }
                  changeRequiredFieldsForDecisionSupportValidate(requiredFieldsForDecisionSupportValidate.filter((field) => field.name !== name));
                };

                const primaryDiagnosesState = primaryDiagnoses.getState()[appointment.id] || appointment.primary_diagnoses || [];

                const hasNotInsuranceDiagnosis = primaryDiagnosesState.some(diagnosis => {
                  return diagnosis.is_not_insurance;
                });

                return (
                  <DecisionSupportFormSection
                    name={(
                      hasNotInsuranceDiagnosis ? (
                        <FormSectionExtendedTitle>
                          {name}
                          <FormSectionTitleError>
                            Услуги в рамках не страхового диагноза<br/>
                            пациент должен оплатить самостоятельно
                          </FormSectionTitleError>
                        </FormSectionExtendedTitle>
                      ) : name
                    )}
                    isEmpty={diagnosesIsEmpty && !isNotEmpty}
                    expand_by_default
                    toolButton={
                      conclusionType ? (
                        <>
                          <ConclusionTypeContainer>
                            <ConclusionButton
                              active={conclusionType === "ADULT"}
                              onClick={handleConclusionTypeClick("ADULT")}>
                              Взрослый
                            </ConclusionButton>
                            <ConclusionTypeSplash>/</ConclusionTypeSplash>
                            <ConclusionButton
                              active={conclusionType === "CHILDREN"}
                              onClick={handleConclusionTypeClick("CHILDREN")}>
                              Детский
                            </ConclusionButton>
                          </ConclusionTypeContainer>
                        </>
                      ) : (
                        undefined
                      )
                    }>
                    <AppointmentDiagnoses appointment={appointment} isRequired={isRequired} isError={isError} callbackOnChangeDiagnoses={callbackOnChangeDiagnoses} />
                  </DecisionSupportFormSection>
                );
              }}
            </ConclusionGuideProvider>

            {layout?.closing_check_up && appointment?.folder?.id && (
              checkup ? (
                <>
                  <FormSection
                    name={"Обследования в рамках чекапа"}
                    shouldExpand
                    expand_by_default={true}
                    isFullContentSize={true}
                    key={appointment.id + 'CheckUpExaminations'}
                  >
                    <CheckUpTable
                      checklist={checkup.examination_checklist}
                      checklistType={ChecklistTypes.examination}
                      appointment={appointment}
                      containerStyle={{
                        marginBottom: '16px'
                      }}
                    />
                  </FormSection>
                  <FormSection
                    name={ "Медикаментозное лечение в рамках чекапа" }
                    shouldExpand
                    expand_by_default={ true }
                    isFullContentSize={true}
                    key={ appointment.id + "CheckUpMedications" }
                  >
                    <CheckUpTable
                      checklist={checkup.medication_checklist}
                      checklistType={ChecklistTypes.medication}
                      // notColumns={['group']}
                      // grouping={['appointment']}
                      appointment={appointment}
                      containerStyle={{
                        marginBottom: '16px'
                      }}
                    />
                  </FormSection>
                  <FormSection
                    name={ "Рекомендации в рамках чекапа" }
                    shouldExpand
                    expand_by_default={ true }
                    isFullContentSize={true}
                    key={ appointment.id + "CheckUpRecommendations" }
                  >
                    <CheckUpTable
                      checklist={checkup.recommendation_checklist}
                      checklistType={ChecklistTypes.recommendation}
                      notColumns={['group']}
                      grouping={['appointment']}
                      appointment={appointment}
                      containerStyle={{
                        marginBottom: '16px'
                      }}
                    />
                  </FormSection>
                </>
              ) : (
                <BlockLoader />
              )
            ) }

            <ConclusionGuideProvider appointment={appointment} sectionType={"examinations"}>
              {({ isNotEmpty }) => {
                return (
                  <DecisionSupportFormSection
                    toolButton={
                      <ConclusionTooltip
                        isNotEmpty={isNotEmpty}
                        openInfoEvent={openExaminationsInfoEvent}
                        fieldName="examination_checklist"
                        appointment={appointment}
                      />
                    }
                    isEmpty={!isNotEmpty}
                    name="Обследования"
                    expand_by_default>
                    <AppointmentExaminations updateChecked={examinationUpdateChecked} appointment={appointment} />
                    {checkedItemsExaminationChecklistAllPrice > 0 && (
                      <AllPriceBlock>
                        {/*<PrintButton />*/}
                        <div></div>
                        <div>
                          <AllPriceValue>{checkedItemsExaminationChecklistAllPrice.toLocaleString()}₽</AllPriceValue>
                          <AllPriceMessage>Сумма с учетом только тех услуг, которые оказывают в клинике.</AllPriceMessage>
                        </div>
                      </AllPriceBlock>
                    )}
                  </DecisionSupportFormSection>
                );
              }}
            </ConclusionGuideProvider>

            <ConclusionGuideProvider appointment={appointment} sectionType={"medication"}>
              {({ isNotEmpty }) => (
                <DecisionSupportFormSection
                  toolButton={
                    <ConclusionTooltip
                      isNotEmpty={isNotEmpty}
                      openInfoEvent={openMedicationInfoEvent}
                      fieldName="medication_checklist"
                      appointment={appointment}
                    />
                  }
                  isEmpty={!isNotEmpty}
                  name="Медикаментозное лечение"
                  expand_by_default>
                  <AppointmentMedication appointment={appointment} />
                </DecisionSupportFormSection>
              )}
            </ConclusionGuideProvider>

            <ConclusionGuideProvider appointment={appointment} sectionType={"recommendations"}>
              {({ isNotEmpty }) => (
                <DecisionSupportFormSection
                  toolButton={
                    <ConclusionTooltip
                      isNotEmpty={isNotEmpty}
                      openInfoEvent={openRecommendationsInfoEvent}
                      fieldName="recommendation_checklist"
                      appointment={appointment}
                    />
                  }
                  isEmpty={!isNotEmpty}
                  name="Рекомендации"
                  expand_by_default>
                  <AppointmentRecommendation appointment={appointment} />
                </DecisionSupportFormSection>
              )}
            </ConclusionGuideProvider>
          </>
        )}

        {layout.prev_prescribed_services && featureEnabledForOrganization(FEATURE.PRESCRIBED_SERVICES, appointment.medical_file.organization.id) &&
            <>
              <FormSection
                  name={"Последние направления пациента"}
                  shouldExpand
                  expand_by_default={true}
                  key={appointment.id + 'isPrevPrescribedLastDay'}
              >
                <ServicesSuggest
                    displayTeeth={layout.display_teeth}
                    appointment={appointment}
                    isPrevPrescribedLastDay
                    addToServices={addToServicesCallback}
                    removeFromServices={removeFromServicesCallback}
                />
              </FormSection>

              <FormSection
                  name={"Предыдущие направления"}
                  shouldExpand
                  expand_by_default={false}
                  key={appointment.id + 'isPrevPrescribedPrevDays'}
              >
                <ServicesSuggest
                    displayTeeth={layout.display_teeth}
                    appointment={appointment}
                    isPrevPrescribedPrevDays
                    addToServices={addToServicesCallback}
                    removeFromServices={removeFromServicesCallback}
                />
              </FormSection>
            </>
        }

        {layout.prescribed_services && featureEnabledForOrganization(FEATURE.PRESCRIBED_SERVICES, appointment.medical_file.organization.id) &&
          <FormSection
              name={"Направления"}
              helpText={appointment.medical_file.organization.id !== 30 ? <>
                Полную инструкцию по блоку "Направления"<br/>
                можно прочитать <a href="https://public.app.shortcut.com/bf/fomin-clinic/docs/37jWJSiEqxcLuf6SQdvRDk/1" target="_blank">ЗДЕСЬ</a>
              </> : null}
              helpLink="https://public.app.shortcut.com/bf/fomin-clinic/docs/37jWJSiEqxcLuf6SQdvRDk/1"
              shouldExpand
              expand_by_default={true}
              key={appointment.id + 'isPrescribed'}
              toolButton={
                <ServicesTooltip
                  fieldName="prescribed_services"
                  appointment={appointment}
                />
              }
          >
            <ServicesSuggest
                displayTeeth={layout.display_teeth}
                appointment={appointment}
                isPrescribed
                ref={prescribedServicesSuggestRef}
            />
          </FormSection>
        }
      </SectionsContainer>
    )) ||
    null;

  const [ serviceDescription, changeServiceDescription] = useServiceDescription(
    appointment && getAppointment_id(appointment),
    (appointment.services.length || appointment.services_description) ? appointment.services_description : layout.default_services_description
  );

  const [nextVisitDate, changeNextVisitDate] = useNextVisitDate(
    appointment && getAppointment_id(appointment),
    appointment.next_visit_date || ""
  );

  const primaryDiagnosesValue = useStoreMap({
    store: primaryDiagnoses,
    keys: [getAppointment_id(appointment)],
    fn: (state, [id]) => state[id] || appointment.primary_diagnoses || []
  });
  const secondaryDiagnosesValue = useStoreMap({
    store: secondaryDiagnoses,
    keys: [getAppointment_id(appointment)],
    fn: (state, [id]) => state[id] || appointment.secondary_diagnoses || []
  });

  const examinationsChecklists = useStoreMap({
    store: appointmentExaminationsStore,
    keys: [getAppointment_id(appointment)],
    fn: (state, [id]) =>
      state[id] ||
      (appointment.examination_checklist && appointment.examination_checklist.items) ||
      []
  });

  const medicationChecklists = useStoreMap({
    store: appointmentMedicationStore,
    keys: [getAppointment_id(appointment)],
    fn: (state, [id]) =>
      state[id] ||
      (appointment.medication_checklist && appointment.medication_checklist.items) ||
      []
  });

  const recommendationChecklists = useStoreMap({
    store: appointmentRecommendationsStore,
    keys: [getAppointment_id(appointment)],
    fn: (state, [id]) =>
      state[id] ||
      (appointment.recommendation_checklist && appointment.recommendation_checklist.items) ||
      []
  });

  setSaveDecisionValues(appointment);

  const checkValidateAppointment = (needChangeRequiredFields) => {
    const checkFieldWithValue = (value, type) => {
      if (type === "time") {
        return moment(value, READABLE_TIME_FORMAT_MOMENT).isValid();
      }
      return (Array.isArray(value) && value.length) || (!Array.isArray(value) && value)
    }
    const fieldValues = Object.keys(fieldValuesMap).reduce((fields, key) => {
      if (checkFieldWithValue(fieldValuesMap[key].value, fieldValuesMap[key]._field_type)) {
        fields[parseInt(key)] = {
          field: key,
          value: fieldValuesMap[key].value,
        }
      }
      return fields;
    }, {});
    serializeAppointment(appointment.id).forEach((item) => {
      if (fieldValues[item.field]) {
        if (checkFieldWithValue(item.value)) {
          fieldValues[item.field].value = item.value;
        } else {
          delete fieldValues[item.field];
        }
      } else if (checkFieldWithValue(item.value)){
        fieldValues[item.field] = {
          field: item.field,
          value: item.value,
        }
      }
    })
    const filledFieldsIds = [];
    Object.keys(fieldValues).forEach((fieldKey) => {
      if (fieldValues[parseInt(fieldKey)].value) {
        filledFieldsIds.push(parseInt(fieldKey));
      }
    })
    const notFilledFields = requiredFields.filter((item) => !filledFieldsIds.includes(item.id));
    if (needChangeRequiredFields) {
      changeRequiredFieldsValidate(notFilledFields);
    }
    return notFilledFields;
  }

  const checkValidateAppointmentForDecisionSupport = (needChangeRequiredFields) => {
    if (!requiredFieldsForDecisionSupport.length) {
      return [];
    }

    const notFilledFields = requiredFieldsForDecisionSupport.filter((field) => {
      const fieldById = appointment[field.id];
      if (fieldById) {
        if (Array.isArray(fieldById)) {
          return !fieldById.length;
        }
      }
    })

    if (needChangeRequiredFields) {
      changeRequiredFieldsForDecisionSupportValidate(notFilledFields);
    }
    return notFilledFields;
  }

  const submitForm = async () => {
    if (!token || !appointment || !finishConfirm.requestConfirm) {
      return;
    }
    if (featureEnabledForOrganization(FEATURE.CHECK_DIAGNOSIS_FOR_DMS, appointment.medical_file.organization.id) &&
        layout.decision_support &&
        appointment.medical_file.patient.policies?.length &&
        !primaryDiagnosesValue?.length) {
      alert('Для ДМС пациента должен быть обязательно выбран Основной диагноз (Диагноз по МКБ)')
      return;
    }
    const notValidFieldsForDecisionSupport = checkValidateAppointmentForDecisionSupport(true);
    if (notValidFieldsForDecisionSupport.length) {
      setTimeout(() => {
        document.getElementById(notValidFieldsForDecisionSupport[0].name)?.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      })
      return;
    }

    const notValidFields = checkValidateAppointment(true);
    if (notValidFields.length) {
      setTimeout(() => {
        document.getElementsByClassName('error-field')[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
      })
      return;
    }
    try {
      const confirmResult = await finishConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      appointmentMobxStore.changeIsEndAppointmentLoading(true);
      const appointment_id = getAppointment_id(appointment);
      const doctor = appointment.doctor?.id;

      if (patientReminderValid) {
        apiv2.notifications.createPatientReminder({
          appointmentId: appointment.id,
          text: patientReminder.text,
          date: formatDatetimeToDatetimeFormat(
            patientReminder.date,
            patientReminder.time
          ).toISOString()
        });
      }

      await apiv2.appointments.postAnalytics({
        appointment: appointment_id,
        doctor,
        ...counters
      });
      await apiv2.appointments.postAppointmentsSetValues({
        appointment_id,
        fieldValues: serializeAppointment(appointment_id)
      });
      await apiv2.appointments.postSetConclusion({
        appointment_id,
        primary_diagnoses: primaryDiagnosesValue,
        secondary_diagnoses: secondaryDiagnosesValue,
        examination_checklist: { items: (examinationsChecklists: any) },
        medication_checklist: { items: (medicationChecklists: any) },
        recommendation_checklist: { items: (recommendationChecklists: any) }
      });
      const data = { token, appointment_id, services_description: serviceDescription, is_ambulance: isAppointmentAmbulance };
      if (nextVisitDate) {
        data.next_visit_date = moment(nextVisitDate, READABLE_DATE_FORMAT).format("YYYY-MM-DD");
      }
      if (!appointment.notification_date && feedbackDate) {
        data.notification_date = moment(feedbackDate, READABLE_DATE_FORMAT).format("YYYY-MM-DD");
      }
      await apiv2.appointments.postAppointmentsFinish(data);
      if (isInvoiceForPayment) {
        try {
          await apiv2.appointments.postInvoiceForPayment({ appointment_id });
        } catch (e) {
          toastManager.showToast(
            <div>
              {e.code === 400 && e.result.details
                ? e.result.details
                : 'Ошибка при создание стат талона, проведите оплату через администратора'
              }
            </div>,
            {
              duration: 5000
            }
          );
        }
      }
      // if (layout.feedback_layout && isAgreeWithFeedback && feedbackDate) {
      //   await apiv2.feedback.postFeedbacks({
      //     appointment_id,
      //     layout_id: layout.feedback_layout,
      //     notification_date: moment(feedbackDate, READABLE_DATE_FORMAT).format("YYYY-MM-DD")
      //   });
      //   reloadFeedbacks();
      // }
      PatientAppointmentsRefreshTrigger();
      clearAppointmentStore(getAppointment_id(appointment));
      appointmentMobxStore.changeIsEndAppointmentLoading(false);
    } catch (e) {
      appointmentMobxStore.changeIsEndAppointmentLoading(false);
      console.log(e);
    }
  };

  const onDelete = async () => {
    if (!token || !appointment || !deleteConfirm.requestConfirm) {
      return;
    }
    try {
      const confirmResult = await deleteConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      const appointment_id = getAppointment_id(appointment);
      await apiv2.appointments.deleteAppointment(appointment_id);
      PatientAppointmentsRefreshTrigger();
      history.replace(`/appointment/new/${appointment.medical_file.id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const fieldValidationOptions = () => {
    const notValidFields = [...checkValidateAppointment(), ...checkValidateAppointmentForDecisionSupport()];

    return {
      notValidFields,
      showError:
        !!((notValidFields.length && notValidFields.length < 4) || requiredFieldsValidate?.length || requiredFieldsForDecisionSupportValidate.length)
    };
  };

  const buttonForm = (
    <ServiceForm
      requiredFields={getConcatRequiredFields(fieldValidationOptions())}
      serviceDescription={serviceDescription}
      changeServiceDescription={changeServiceDescription}
      appointment={appointment}
      layout={layout}
      onDelete={onDelete}
      submitForm={submitForm}
      thereIsNextVisitDate={layout.display_next_visit_date}
      nextVisitDate={nextVisitDate}
      changeNextVisitDate={changeNextVisitDate}
      feedbackDate={feedbackDate}
      changeFeedbackDate={changeFeedbackDate}
      isAgreeWithFeedback={isAgreeWithFeedback}
      setAgreeWithFeedback={setAgreeWithFeedback}
      patientReminder={patientReminder}
      setPatientReminder={setPatientReminder}
      patientReminderValid={patientReminderValid}
      patientReminderDatetimeError={patientReminderDatetimeError}
      servicesSuggestRef={servicesSuggestRef}
      isInvoiceForPayment={isInvoiceForPayment}
      setIsInvoiceForPayment={setIsInvoiceForPayment}
      isAppointmentAmbulance={isAppointmentAmbulance}
      setIsAppointmentAmbulance={setIsAppointmentAmbulance}
    />
  );

  const onlineSection =
    appointment.online_link && typeof appointment.online_link === "string" ? (
      <OnlineSectionButton onlineLink={appointment.online_link} />
    ) : null;

  let finishDescription = '';
  if (isInvoiceForPayment) {
    finishDescription = <p>Вы указали оплату через приложение, убедитесь, что Вы выбрали все необходимые оказанные услуги, т.к. при дальнейшем изменении услуг, формирование нового стат талона через Асю невозможно.</p>;
  }
  if (serviceStore.prescribedServices.length && appointment.medical_file.organization.id !== 30) {
    finishDescription = <>
      {finishDescription}
      {finishDescription ? <br/> : null}
      <p>Убедитесь, что в разделе "Направления", выбраны правильные услуги в полном объеме и проставлены даты, если это необходимо.</p>
    </>
  }

  const finishWarning = (
    <AlertWarning
      isNeedConfirm={finishConfirm.isNeedConfirm}
      sendConfirm={finishConfirm.sendConfirm}
      heading={
        <ConfirmationMessage modifiers="center">
          {layout.text_for_finish
            ? layout.text_for_finish
            : appointment.has_been_reopened
            ? "Вы точно хотите сохранить изменения?"
            : "Вы точно хотите завершить заполнение?"}
        </ConfirmationMessage>
      }
      description={finishDescription}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отмена</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>
          {appointment.has_been_reopened ? "Сохранить" : "Завершить"}
        </AlertWarningPrimaryButton>
      )}
    />
  );

  const deleteAlertWarning = (
    <AlertWarning
      isNeedConfirm={deleteConfirm.isNeedConfirm}
      sendConfirm={deleteConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Удалить приём?</HeadingH3>}
      description={
        <PNormal modifiers={["left", "gray", "center"]}>
          Прием не будет отображаться в Асе, приложении <br /> пациента и статистике, но останется в
          базе данных.
        </PNormal>
      }
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
      )}
    />
  );

  const cleanAlertWarning = (
    <AlertWarning
      isNeedConfirm={cleanConfirm.isNeedConfirm}
      sendConfirm={cleanConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Вы уверены, что хотите очистить шаблон?</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Продолжить</AlertWarningPrimaryButton>
      )}
    />
  );

  const applyNormAlertWarning = (
    <AlertWarning
      isNeedConfirm={applyNormConfirm.isNeedConfirm}
      sendConfirm={applyNormConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Вы уверены, что хотите заполнить шаблон?</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Продолжить</AlertWarningPrimaryButton>
      )}
    />
  );

  const stimulationProtocol = <StimulationProtocol />;

  if (!token) {
    return null;
  }

  const getSidebarContainerWidth = () => {
    if (rightSidebar === STIMULATION_PROTOCOL_CONTENT) {
      return 768;
    }
    if (rightSidebar === INFO_CONTENT) {
      return 850
    }
    return 429;
  };

  return (
    <React.Fragment>
      <AppointmentFormLayout
        title={appointment && <AppointmentTitle title={layout.name} layoutId={layout.id} appointmentId={appointment.id} /> || null}
        metaInfo={metaInfo}
        toolButtons={!window.QUEST_MODE && toolButtons}
        sections={sections}
        buttonForm={buttonForm}
        stimulationProtocol={stimulationProtocol}
        onlineSection={onlineSection}
      />
      <Shortcuts
        appointment={appointment}
        appointment_id={getAppointment_id(appointment)}
        layout_id={getLayout_id(layout)}
        layout={layout}
        fieldValuesMap={fieldValuesMap}
      />
      { !window.QUEST_MODE && applicationFeatureEnabled(FEATURE.DOCUMENT_SIDEBAR) &&
          <PrintDocumentsFixedLabel onClick={openPrintDocumentsSidebar} />
      }
      <ModalPortal>
        {finishWarning}
        {deleteAlertWarning}
        {cleanAlertWarning}
        {applyNormAlertWarning}
      </ModalPortal>
      <RightSidebarPortal>
        <SidebarContainer closeOnClickOutside={rightSidebar === PRINT_DOCUMENTS} isShown={!!rightSidebar} containerMaxWidth={getSidebarContainerWidth()}>
          {rightSidebar === INFO_CONTENT && (
            <ConclusionGuideProvider appointment={appointment}>
              {({ conclusionGuideItems, diagnoses, isNotEmpty }) => (
                <Info
                  conclusionGuideItems={conclusionGuideItems}
                  diagnoses={diagnoses}
                  isNotEmpty={isNotEmpty}
                />
              )}
            </ConclusionGuideProvider>
          )}
          {rightSidebar === DIAGNOSES_TREE_CONTENT && (
            <DiagnosesTreeSidebar appointment={appointment} />
          )}
          {rightSidebar === SERVICES && <ServicesTree appointment={appointment} />}
          {rightSidebar === PRINT_DOCUMENTS && <DocumentsTree appointment={appointment} />}
          {rightSidebar === STIMULATION_PROTOCOL_CONTENT && <StimulationProtocolInfo />}
        </SidebarContainer>
      </RightSidebarPortal>
      <ScrollToTopButton />
    </React.Fragment>
  );
});
