// @flow
import FC, { useState, useMemo } from "react";
import { useStore } from "effector-react";
import _ from "lodash";
import moment from "moment";
import { useApiRequest } from "../../apiv2/useApiRequest";
import { $patientAppointments } from "../appointment-switch-page/PatientAppointmentsProvider";
import { isInProgress } from "../../domain/services/appointments";
import apiv2 from "../../apiv2";
import appointmentCreateStore from "../../stores/AppointmentCreateStore";

type TGroupSelectProps = {
  isHavePopularLayouts?: boolean,
  appointmentId?: string
};
interface IGroupSelect {
  options: { id: number, name: string }[];
  /** -> AutocompleteProps */
  defaultProps: mixed,
  controlOptions: {
    value: { id: number, name: string } | null,
    inputValue: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, newValue: { id: number, name: string }) => void,
    onInputChange: (event: React.ChangeEvent<HTMLInputElement>, newInputValue: string) => void
  },
}
interface IGroupCategoriesSelect extends IGroupSelect {
  isPopularLayoutSelected?: boolean;
}

const defaultProps = {
  getOptionLabel: (option) => option.name,
  isOptionEqualToValue: (option, value) => option.id === value.id,
  autoComplete: true,
};

const useGroupCategoriesAutocomplete: FC<TGroupSelectProps> = ({
  isHavePopularLayouts
}): IGroupCategoriesSelect => {
  const popularLayout = {
    id: "POPULAR_LAYOUT_ID",
    name: "Популярные шаблоны"
  };
  const { data: layoutGroups } = useApiRequest(async () => {
    return apiv2.appointments.getLayoutGroups();
  }, []);
  const options = [
    ...(isHavePopularLayouts ? [popularLayout] : []),
    ...(!layoutGroups?.length ? [] : layoutGroups)
  ];
  const [value, setValue] = useState(null);
  const handleOnChangeValue = (newValue) => setValue(newValue);

  useMemo(() => {
    if (options.length && isHavePopularLayouts) {
      handleOnChangeValue(popularLayout);
    }
  }, [isHavePopularLayouts])

  const controlOptions = {
    value,
    onChange: (event, newValue) => handleOnChangeValue(newValue),
  };

  return {
    options,
    defaultProps: {
      ...defaultProps,
      disableClearable: true,
      noOptionsText: "Категории отсутствуют",
    },
    controlOptions,
    isPopularLayoutSelected: value?.id === popularLayout.id
  };
};

const useGroupAppointmentsAutocomplete: FC<TGroupSelectProps> = (): IGroupSelect => {
  const filterAppointments = (appointments, folderName = '') => {
    return appointments.reduce((acc, currentAppointment) => {
      if (currentAppointment.layout && !isInProgress(currentAppointment)) {
        acc.push({...currentAppointment, name: currentAppointment.layout.name, folderName });
      }
      if (Array.isArray(currentAppointment.appointments) && currentAppointment.appointments.length) {
        acc = [...acc, ...filterAppointments(currentAppointment.appointments, currentAppointment.name)];
      }
      return acc;
    }, []);
  };
  const appointments = useStore($patientAppointments);
  const options = filterAppointments(appointments);

  const [value, setValue] = useState(null);
  const handleOnChangeValue = (newValue) => setValue(newValue);

  useMemo(() => {
    if (options.length && appointmentCreateStore.copyAppointment) {
      const optionById = _.find(options, { id: parseInt(appointmentCreateStore.copyAppointment.id) }) || null;
      if (!optionById) {
        return;
      }
      handleOnChangeValue(optionById);
    }
  }, [options.length, appointmentCreateStore.copyAppointment]);

  const controlOptions = {
    value,
    onChange: (event, newValue) => handleOnChangeValue(newValue),
  };

  return {
    options,
    defaultProps: {
      ...defaultProps,
      noOptionsText: "Консультации отсутствуют"
    },
    controlOptions
  };
};

export { useGroupCategoriesAutocomplete, useGroupAppointmentsAutocomplete };
