// @flow
import React, { useContext } from "react";
import type { MedicalFile } from "../../domain/entities/MedicalFile";

export const MedicalFileContext: React$Context<{ medicalFile: ?MedicalFile }> = React.createContext(
  {
    medicalFile: null
  }
);

export const MedicalFileConsumer = (props: { children: MedicalFile => React$Node }) => {
  const { medicalFile } = useContext(MedicalFileContext);

  return <React.Fragment>{(medicalFile && props.children(medicalFile)) || null}</React.Fragment>;
};
