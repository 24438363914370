// @flow
import * as R from "ramda";

type TCommentHandler = (Array<any>) => Array<any>;

export const setComment = (idProp: string) => ({
  value,
  id
}: {
  value: string,
  id: any
}): TCommentHandler => {
  return R.map(item => (R.propEq(idProp, id)(item) ? { ...item, comment: value } : item));
};

export const removeComment = (idProp: string) => (id: any): TCommentHandler =>
  R.map(item => (R.propEq(idProp, id)(item) ? R.omit(["comment"], item) : item));
