import styled from "styled-components";

export const Table = styled.table`
  margin-right: 30px;
  min-width: calc(100% - 30px);
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;
`;

export const Column = styled.th`
  border: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid #c6007f;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
  font-size: 15px;
  padding: 0 16px;
  max-width: 300px;
  height: 44px;
  font-weight: 500;
  vertical-align: middle;
  white-space: break-spaces;
  line-height: 1.35;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export const Cell = styled.td`
  vertical-align: middle;
  border-bottom: 1px solid rgb(68, 68, 68, 0.1);
  border-right: 1px solid rgb(68, 68, 68, 0.1);
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
  padding: 0 16px;
  font-size: 15px;
  max-width: 300px;
  height: 44px;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export const PlaceholderBase = styled.div`
  height: 10px;
  border-radius: 3px;
  background: #d4d4d4;
  opacity: 0.5;
`;

export const PlaceholderCol = styled(PlaceholderBase)`
  width: 169px;
  height: 30px;
`;
