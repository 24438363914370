import React, {useEffect, useRef} from "react";
import Draggable from "react-draggable";
import { observer } from "mobx-react";
import styled, { css } from "styled-components";

import chatStore from "../../../stores/chatStore";
import { EndpointView } from "./EndpointView";
import { MyView } from "./MyView";
import { CallSettings } from "./CallSettings";
import { IconCamera } from "./icons/Camera";
import { IconMic } from "./icons/Mic";
import { IconMicOff } from "./icons/MicOff";
import { IconCameraOff } from "./icons/CameraOff";
import { IconHangup } from "./icons/Hangup";
import { IconPip } from "./icons/Pip";
import { CallInitializationScreen } from './CallInitializationScreen';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: #f9eeed;
  ${props =>
    props.minimize &&
    css`
      right: 32px;
      bottom: 32px;
      left: auto;
      top: auto;
      width: auto;
      max-width: 460px;
      height: auto;
      max-height: 800px;
      border-radius: 16px;
      overflow: hidden;

      ${VideosWrapper} {
        padding: 0;
      }
    `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 24px;
`;

const VideosWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  justify-content: center;
  background: #361a2f;
  border-radius: 0 0 16px 16px;
  padding: 40px;
  max-height: calc(100% - 100px);

  @media screen and (max-width: 1024px) {
    padding: 0;
  }

  video {
    border-radius: 16px;
    background: #3e2337;
  }

  .wrapper {
      width: 100%;
  }

  ${props =>
    props.groupSize > 1 &&
    css`
      flex-wrap: wrap;
      gap: 32px;

      ${props.groupSize <= 3
        ? css`
            .wrapper {
              width: 40%;
            }
          `
        : css`
            .wrapper {
              width: calc(120% / ${props.groupSize});
            }
          `}
    `}
`;

export const RoundButton = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border: none;
  border-radius: 50%;
  background: #e7d6d6;
  cursor: pointer;

  ${props =>
    props.$altered
      ? css`
          background: #ea4e2c;
        `
      : null}

  &:hover {
    filter: saturate(1.1);
  }
  &:active {
    filter: saturate(0.8);
  }
  &[disabled] {
    opacity: 0.5;
  }
`;

export const VideoCall = observer(() => {
  const endpointsCount = useRef(0);

  useEffect(() => {
    if (endpointsCount.current < chatStore.endpoints.length) {
      // Если кто-то подключился
      const audio = new Audio('/call_join.mp3');
      audio.addEventListener('canplaythrough', () => {
        /* Если произошел этот ивент - аудио было загружено доконца и может проиграться без глюков */
        audio.play();
      });
    }
    if (endpointsCount.current > chatStore.endpoints.length) {
      // Если кто-то отключился
      const audio = new Audio('/call_leave.mp3');
      audio.addEventListener('canplaythrough', () => {
        audio.play();
      });
    }
    endpointsCount.current = chatStore.endpoints.length;
  }, [chatStore.endpoints]);

  if (chatStore.voxAndDevicesInitializationStarted && !chatStore.voxAndDevicesInitialized) {
    return <CallInitializationScreen/>;
  }

  if (chatStore.callSettingsScreenShowed) {
    return <CallSettings />;
  }

  if (!chatStore.voxCall) {
    return null;
  }

  const renderComponent = (
    <Wrapper minimize={chatStore.videoMinimized}>
      <VideosWrapper
        groupSize={
          chatStore.videoMinimized && chatStore.endpoints.length > 1
            ? 1
            : chatStore.endpoints.length
        }>
        {!!chatStore.endpoints.length &&
          (chatStore.videoMinimized ? (
            <EndpointView endpoint={chatStore.endpoints[0]} />
          ) : (
            chatStore.endpoints.map(endpoint => {
              const key = endpoint.id + endpoint.mediaRenderers.map(mr => mr.stream?.id).join("");
              return <EndpointView key={key} endpoint={endpoint} />;
            })
          ))}
        {!chatStore.videoMinimized && <MyView />}
      </VideosWrapper>
      <ButtonsWrapper>
        {!chatStore.isAnonymousMode && (
          <RoundButton
            onClick={() => {
              chatStore.toggleMinimize();
            }}>
            <IconPip
              style={{
                "--svg-fill": "#59304F"
              }}
            />
          </RoundButton>
        )}
        {/*<RoundButton onClick={() => chatStore.voxCall.shareScreen()}>ШАРИНГ</RoundButton>*/}
        <RoundButton
          $altered={!chatStore.videoEnabled}
          disabled={chatStore.disabledVideoButton || chatStore.videoStateChangeInProgress}
          onClick={async () => {
            await chatStore.toggleCamera();
          }}>
          {chatStore.videoEnabled ? (
            <IconCamera
              style={{
                "--svg-fill": "#59304F"
              }}
            />
          ) : (
            <IconCameraOff
              style={{
                "--svg-fill": "#fff"
              }}
            />
          )}
        </RoundButton>

        <RoundButton
          $altered={chatStore.microphoneMuted}
          disabled={chatStore.microphoneMuteInProgress}
          onClick={() => {
            chatStore.toggleMuteMicrophone();
          }}>
          {chatStore.microphoneMuted ? (
            <IconMicOff
              style={{
                "--svg-fill": "#fff"
              }}
            />
          ) : (
            <IconMic
              style={{
                "--svg-fill": "#59304F"
              }}
            />
          )}
        </RoundButton>

        {!chatStore.isAnonymousMode && (
          <RoundButton
            $altered={true}
            onClick={async () => {
              chatStore.hangup();
            }}>
            <IconHangup
              style={{
                "--svg-fill": "#fff"
              }}
            />
          </RoundButton>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
  if (!chatStore.videoMinimized) {
    return renderComponent;
  }

  return <Draggable bounds="parent">{renderComponent}</Draggable>;
});
