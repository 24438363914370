import React from "react";

import * as S from "./styled/ServicesTreeCategory.styled";

export const ServicesTreeCategory = ({ onOpen, title }) => (
  <S.Container onClick={onOpen}>
    <S.Title>{title}</S.Title>
    <S.Arrow />
  </S.Container>
);
