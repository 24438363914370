// @flow
import type { Diagnosis } from "../../domain/entities/Diagnosis";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import {useApiRequest} from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

export const DiagnosesSuggestProvider = (props: {
  value: string,
  children: (diagnoses: Array<Diagnosis>) => React$Node
}) => {
  const { value, children } = props;
  const token = useStore(tokenStore);
  const diagnosesResult = useApiRequest(async () => {
    if (value) {
      return await apiv2.diagnoses.getDiagnoses({ search: value });
    }
  }, [token, value]);
  return children(diagnosesResult.data || []);
};
