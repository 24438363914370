import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormControl, FormLabel } from "@mui/material";
import { Asterisk } from "../styled/Asterisk.styled";

export const FormInputNumber = (props) => {
  const newProps = {...props};
  delete newProps.subInfo;

  const handleOnChange = (onChange) => (event) => {
    const value = parseInt(event.target.value);

    if (isNaN(value)) {
      return onChange(event.target.value);
    }

    if (value < props.min) {
      return onChange(props.min.toString());
    }
    if (value > props.max) {
      return onChange(props.max.toString());
    }

    onChange(value);
  }
  return (
    <>
      <FormControl fullWidth className={'dynamic-form-input-control'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
        {(props.label && props.withLabel) && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
        <Controller
          name={props.name}
          control={props.control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <TextField
              type="number"
              helperText={error ? error.message : props.subInfo ? props.subInfo : null}
              error={!!error}
              onChange={handleOnChange(onChange)}
              value={value || ''}
              fullWidth
              variant="outlined"
              inputProps={
                { readOnly: props.readOnly, }
              }
              {...newProps}
              label={props.withLabel ? '' : props.label}
            />
          )}
        />
      </FormControl>
    </>
  );
};
