// @flow
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import { READABLE_DATE_FORMAT } from "../../utils/dates";
import organizationsStore from "../../stores/organizationsStore";
import { MaterialTable } from "../common/MaterialTable";
import { observer } from "mobx-react";
import { DynamicFilters } from "../common/DynamicFilters";
import { BackToScheduleButton } from "../appointment-switch-page/BackToScheduleButton";
import { Header } from "../header/Header";
import { trimLongTextValueField } from "../../utils/tableUtils";
import { Button, Paper } from "@mui/material";
import patientsStore from "../../stores/patientsStore";
import useReactRouter from "use-react-router";
import { PatientAddFieldModal } from "./PatientAddFieldModal";
import { PatientSendReesModal } from "./PatientSendReesModal";
import { Link } from "react-router-dom";
import { colors } from "../styleguide/colors";

const Container = styled.div`
    padding: 24px 27px 30px 30px;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media(max-width: 768px) {
        padding: 24px 0 30px 0;
    }
`;

const LinkButton = styled(Link)`
  text-decoration: none;
  color: ${colors.magenta};
`;
const BackButtonContainer = styled.div`
  margin-bottom: 0;

  a,
  button {
    margin-bottom: 0;
  }
`;

export const PatientsPage = observer(() => {
  const [isInitialized, setIsInitialized] = React.useState();
  const {
    history
  } = useReactRouter();

  const changeHandler = (filters) => {
    patientsStore.setGlobalFiltersTable(filters);
  }

  useEffect(() => {
    (async () => {
      organizationsStore.getOrganizations();
    })()
  }, []);

  const afterInitializeParams = (callback) => {
    callback();
    setIsInitialized(true);
  }

  const topToolbarCustomActions = [
    {
      key: "create_ticket",
      options: {
        color: "success",
        variant: "outlined",
        onClick: () => patientsStore.changeShowAddFieldPopup(true)
      },
      text: "Добавить поле из приема"
    },
    {
      key: "send_ress",
      options: {
        color: "success",
        variant: "outlined",
        onClick: () => patientsStore.changeShowSendReesPopup(true)
      },
      text: "Отправить рассылку"
    },
    {
      key: "save_to_csv",
      options: {
        color: "success",
        variant: "outlined",
        onClick: () => patientsStore.saveToFile()
      },
      text: "Скачать CSV"
    }
  ];

  const dynamicAllFilters = useMemo(() => {
    return [
      {
        type: 'text',
        name: 'fullNamePatient',
        label: 'Пациент',
        filterFn: 'icontains',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'date',
        typePicker: 'date',
        name: 'patient__birthdate',
        label: 'Дата рождения',
        clearable: true,
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'patient__phone_number',
        filterFn: 'icontains',
        label: 'Номер телефона',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'date',
        typePicker: 'date',
        name: 'created_at',
        label: 'Дата создания',
        clearable: true,
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'number',
        filterFn: 'icontains',
        label: 'Номер карты',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'diagnosis_name_first',
        filterFn: 'icontains',
        label: 'Диагноз',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'date',
        typePicker: 'date',
        name: 'diagnosis_date_first',
        label: 'Дата выставления диагноза',
        clearable: true,
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'select',
        filterFn: 'in',
        name: 'organization__name',
        label: 'Организация',
        labelName: 'label',
        valueName: 'value',
        options: organizationsStore.organizations.map((item) => ({ value: item.name, label: item.name })),
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'patient__mobile_user__id',
        filterFn: 'isnull',
        filterFnDisabled: true,
        label: 'Мобильный пользователь',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'checkbox',
        filterFnDisabled: true,
        name: 'have_concierge',
        label: 'Подключен к консьерж',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'date',
        typePicker: 'date',
        name: 'patient__mobile_user__account__last_login',
        label: 'Последний вход в приложение',
        clearable: true,
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'select',
        filterFnDisabled: true,
        options: [
          {
            value: true,
            label: 'Да'
          },
          {
            value: false,
            label: 'Нет'
          },
        ],
        name: 'have_concierge',
        label: 'Подключен к консьерж',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'select',
        filterFnDisabled: true,
        options: [
          {
            value: true,
            label: 'Да'
          },
          {
            value: false,
            label: 'Нет'
          },
        ],
        name: 'have_concierge_invitation',
        label: 'Приглашен в консьерж',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'badge',
        label: 'Признак пациента',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'select',
        name: 'patient__sex',
        label: 'Пол',
        labelName: 'label',
        valueName: 'value',
        options: patientsStore.sexOptions,
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'patient__email',
        label: 'E-mail',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'patient__registration_address',
        label: 'Адрес по прописке',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'patient__city',
        label: 'Город',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'patient__comment',
        label: 'Комментарий',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'patient__comment_dms',
        label: 'Комментарий по ДМС',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'date',
        typePicker: 'date',
        name: 'patient__last_review_date',
        filterFn: 'range',
        label: 'Дата последнего отзыва',
        xs: 12,
        md: 3,
        sm: 6,
      },
      ...patientsStore.customFilters,
    ]
  }, [patientsStore.customFilters, organizationsStore.organizations]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'Приемы',
        size: 140,
        Cell: ({ row }) => {
          return (
            <Button size={"small"} variant={"text"} color={"primary"}><LinkButton to={`/appointment/new/${row.original.id}`}>Перейти</LinkButton></Button>
          )
        },
      },
      {
        accessorKey: 'fullNamePatient',
        header: 'Пациент',
        size: 300,
      },
      {
        accessorKey: 'patient__birthdate',
        header: 'Дата рождения',
        Cell: ({ cell }) =>
          cell.getValue() ? moment(cell.getValue()).format(READABLE_DATE_FORMAT) : '',
        size: 250,
      },
      {
        accessorKey: 'patient__phone_number',
        header: 'Номер телефона',
        size: 200,
      },
      {
        accessorKey: 'number',
        header: 'Номер карты',
        size: 200,
      },
      {
        accessorKey: 'diagnosis_name_first',
        header: 'Диагноз',
        size: 200,
      },
      {
        accessorKey: 'organization__name',
        header: 'Организация',
        size: 200,
      },
      {
        accessorKey: 'patient__mobile_user__phone',
        additionalFields: ['patient__mobile_user__birthdate'],
        header: 'Мобильный пользователь',
        size: 300,
        Cell: ({ row }) => {
          if (row.original.patient__mobile_user__phone) {
            return `${row.original.patient__mobile_user__phone} | ${moment(row.original.patient__mobile_user__birthdate).format(READABLE_DATE_FORMAT)}`;
          }
          return '';
        },
      },
      {
        accessorKey: 'patient__mobile_user__account__last_login',
        header: 'Последний вход в приложение',
        size: 300,
        Cell: ({ row }) => {
          if (row.original.patient__mobile_user__account__last_login) {
            return moment(row.original.patient__mobile_user__account__last_login).format(READABLE_DATE_FORMAT);
          }
          return '';
        },
      },
      {
        accessorKey: 'have_concierge',
        header: 'Подключен к консьерж',
        size: 250,
        Cell: ({ cell }) => {
          return cell.getValue() === true ? "Да" : "Нет";
        },
      },
      {
        accessorKey: 'have_concierge_invitation',
        header: 'Приглашен в консьерж',
        size: 250,
        Cell: ({ cell }) => {
          return cell.getValue() === true ? "Да" : "Нет";
        },
      },
      {
        accessorKey: 'badge',
        header: 'Признак пациента',
        size: 250,
      },
      {
        accessorKey: 'patient__sex',
        header: 'Пол',
        size: 150,
        Cell: ({ cell }) => patientsStore.sexLabels[cell.getValue()]
      },
      {
        accessorKey: 'patient__email',
        header: 'E-mail',
        size: 200,
      },
      {
        accessorKey: 'patient__registration_address',
        header: 'Адрес по прописке',
        size: 300,
        Cell: ({ row }) => trimLongTextValueField(row.original.patient__registration_address)
      },
      {
        accessorKey: 'patient__city',
        header: 'Город',
        size: 200,
      },
      {
        accessorKey: 'patient__comment',
        header: 'Комментарий',
        size: 300,
        Cell: ({ row }) => trimLongTextValueField(row.original.patient__comment)
      },
      {
        accessorKey: 'patient__comment_dms', //normal accessorKey
        header: 'Комментарий по ДМС',
        size: 300,
        Cell: ({ row }) => trimLongTextValueField(row.original.patient__comment_dms)
      },
      {
        accessorKey: 'created_at',
        header: 'Дата создания',
        Cell: ({ cell }) =>
          cell.getValue() ? moment(cell.getValue()).format(READABLE_DATE_FORMAT) : '',
        size: 250,
      },
      {
        accessorKey: 'patient__last_review_date',
        header: 'Дата последнего отзыва',
        size: 300,
        Cell: ({ cell }) =>
          cell.getValue() ? moment(cell.getValue()).format(READABLE_DATE_FORMAT) : '',
      },
      ...patientsStore.customColumns,
    ],
    [patientsStore.customColumns],
  );

  return (
    <>
      <Header
        prependContent={
          (
            <BackButtonContainer>
              <BackToScheduleButton />
            </BackButtonContainer>
          )
        }
      />
      <Container>
        {!!isInitialized && (
          <DynamicFilters
            hiddenButtonSave
            data={patientsStore.globalFiltersTable}
            hiddenButtonCancel
            titleForm={"Фильтры"}
            changeHandler={changeHandler}
            submitHandler={changeHandler}
            nameLocalStorageSaveFilters={"generalSchedulePageSaveFilters"}
            disabledOptions={['date']}
            defaultFilters={['fullNamePatient__icontains', 'patient__birthdate', 'patient__phone_number__icontains']}
            defaultData={{fullNamePatient__icontains: '', patient__birthdate: '', patient__phone_number__icontains: ''}}
            isNeedAdvancedFilters={true}
            allFilters={dynamicAllFilters} />
        )}
        <Paper sx={{
          overflow: 'hidden',
        }}>
          <MaterialTable
            disableHeightResizing
            notPadding={true}
            afterInitializeParams={afterInitializeParams}
            enableColumnFilters={false}
            store={patientsStore}
            columns={columns}
            topToolbarCustomActions={topToolbarCustomActions}
            // actions={['edit']}
            initialState={{
              columnVisibility: {patient__is_private_access: false, patient__last_review_date: false, badge: false}
            }}
          />
        </Paper>
      </Container>
      <PatientAddFieldModal />
      <PatientSendReesModal />
    </>
  );
});
