// @flow
import type { MedicalFile } from "./MedicalFile";
import type { Doctor } from "./Doctor";
import type { UploadedFile } from "./UploadedFile";
import type { Prescription } from "../value-objects/Prescription";
import type { Diagnosis } from "./Diagnosis";
import type { Checklist } from "./Checklist";
import type { BodyMassIndex } from "../value-objects/BodyMassIndex";
import type { TFeedback_id } from "./Feedback";

export type Appointment = {
  ...$Exact<AppointmentLiveBirthProbability>,
  type: "appointment",
  id: number,
  doctor: Doctor,
  medical_file: MedicalFile,
  layout: {
    id: number,
    name: string
  },
  date: string,
  time: string,
  date_created: string,
  date_finished: ?string,
  in_progress: boolean,
  has_been_reopened: boolean,
  can_be_reopened: boolean,
  is_latest: boolean,
  is_cancelled: boolean,
  online_link?: string | boolean,
  feedback?: TFeedback_id
};

export type FolderQuestionnaire = {
  id: number,
  is_saved: boolean
};

export type OvarianResponse = {
  response_type: "low" | "hight" | "medium"
};

export type AppointmentFolder = {
  id: number,
  type: "folder",
  name: string,
  doctor: number,
  is_finished: boolean,
  appointments: Appointment[],
  questionnaire: FolderQuestionnaire,
  ovarian_response?: OvarianResponse
};

export type AppointmentLiveBirthProbability = {
  eko_chance: string | null,
  eko_do_chance: string | null,
  vmi_chance: string | null
};

export type GenericAppointment = Appointment | AppointmentFolder;

export type LayoutFieldValue = {
  field: number,
  value: number | string | Array<string | Prescription> | BodyMassIndex,
  files: Array<UploadedFile>,
  _field_type?: string
};

export type TypeOfConclusion = string | null;

export type Service = {
  id: number,
  name: string,
  ministry_of_health_code: string,
  kdf_code: string,
  count: number,
  dateRange: string,
};

export type AppointmentWithDetails = {
  ...$Exact<Appointment>,
  services_description: string,
  next_visit_date?: string,
  layout_field_values: Array<LayoutFieldValue>,
  primary_diagnoses?: Array<Diagnosis>,
  secondary_diagnoses?: Array<Diagnosis>,
  examination_checklist?: { items: Array<Checklist> },
  type_of_ppr: TypeOfConclusion,
  medication_checklist?: { items: Array<Checklist> },
  recommendation_checklist?: { items: Array<Checklist> },
  folder: AppointmentFolder,
  services: Array<Service>,
  display_ovarian_response?: boolean
};

export opaque type TAppointment_id: number = number;

export const getAppointment_id = (
  appointment: Appointment | AppointmentWithDetails
): TAppointment_id => {
  return appointment.id;
};
