// @flow
import * as R from "ramda";

type TCountHandler = (Array<any>) => Array<any>;

export const changeCount = (idProp: string) => ({
  value,
  id
}: {
  value: string,
  id: any
}): TCountHandler => {
  return R.map(item => (R.propEq(idProp, id)(item) ? { ...item, count: value } : item));
};
