// @flow
import React from "react";
import styled from "styled-components";
import type { Diagnosis, TDiagnosis_id } from "../../domain/entities/Diagnosis";
import type { ConclusionGuideItem } from "../../domain/value-objects/ConclusionGuide";
import { InfoLayout } from "./InfoLayout";
import { InfoCloseButton } from "./InfoCloseButton";
import { HeadingH3, HeadingH4 } from "../styleguide/typography";
import { InfoDiagnosisTabContainer } from "./InfoDiagnosisTabContainer";
import { InfoDiagnosisTab } from "./InfoDiagnosisTab";
import { InfoTitleLayout } from "./InfoTitleLayout";
import { NotRecommendedInfoTitleIcon, RecommendedInfoTitleIcon } from "./InfoTitleIcon";
import { InfoTextFormatter } from "./InfoTextFormatter";
import * as R from "ramda";
import { getDiagnosis_id } from "../../domain/entities/Diagnosis";
import { closeRightSidebar } from "../../stores/rightSidebar";

type TGetFirstKey = (Array<string>) => (Array<Diagnosis>) => ?TDiagnosis_id;

const diagnosisRecommendationsAreNotEmpty = R.compose(
  R.not,
  R.isEmpty,
  R.without([""]),
  R.values
);

export const getFirstKey: TGetFirstKey = conclusionGuideItemDiagnosis =>
  R.compose(
    getDiagnosis_id,
    R.defaultTo(({}: Diagnosis)),
    R.find(diagnosis => conclusionGuideItemDiagnosis.indexOf(diagnosis.id.toString()) >= 0),
    R.defaultTo(([]: Array<Diagnosis>))
  );

const useCurrentDiagnosis = (diagnoses, conclusionGuideItems) => {
  const conclusionGuideItemDiagnosis = R.compose(
    R.keys,
    R.filter(diagnosisRecommendationsAreNotEmpty)
  )(conclusionGuideItems);

  const [currentDiagnosis, setCurrentDiagnosis] = React.useState<?TDiagnosis_id>(
    getFirstKey(conclusionGuideItemDiagnosis)(diagnoses)
  );

  React.useEffect(() => {
    setCurrentDiagnosis(_currentDiagnosis =>
      diagnoses.indexOf(_currentDiagnosis) >= 0
        ? _currentDiagnosis
        : getFirstKey(conclusionGuideItemDiagnosis)(diagnoses)
    );
  }, [diagnoses]);

  return [currentDiagnosis, setCurrentDiagnosis];
};

const TextContainer = styled.div`
  margin-top: 21px;
`;

const TitleContainer = styled.div`
  ${TextContainer} + & {
    margin-top: 42px;
  }
`;

export const Info = (props: {
  diagnoses: Array<Diagnosis>,
  conclusionGuideItems: { [TDiagnosis_id]: ConclusionGuideItem },
  isNotEmpty: boolean
}) => {
  const { conclusionGuideItems, diagnoses, isNotEmpty } = props;
  const [currentDiagnosisId, setCurrentDiagnosisId] = useCurrentDiagnosis(
    diagnoses,
    conclusionGuideItems
  );

  React.useEffect(() => {
    if (!isNotEmpty) {
      closeRightSidebar();
    }
  }, [isNotEmpty]);

  const conclusionGuideItem = currentDiagnosisId ? conclusionGuideItems[currentDiagnosisId] : null;

  const currentDiagnosis = R.find(R.propEq("id", currentDiagnosisId), diagnoses);

  const close = <InfoCloseButton onClick={closeRightSidebar} />;

  const tabs = diagnoses && diagnoses.length > 1 && (
    <InfoDiagnosisTabContainer>
      {diagnoses.map(
        diagnosis =>
          diagnosisRecommendationsAreNotEmpty(conclusionGuideItems[getDiagnosis_id(diagnosis)]) && (
            <InfoDiagnosisTab
              key={diagnosis.id}
              isActive={diagnosis.id === currentDiagnosisId}
              onClick={() => setCurrentDiagnosisId(getDiagnosis_id(diagnosis))}
              title={diagnosis.icd_code || diagnosis.name}
            />
          )
      )}
    </InfoDiagnosisTabContainer>
  );

  const heading = <HeadingH3>{currentDiagnosis && currentDiagnosis.name}</HeadingH3>;

  const content = conclusionGuideItem && (
    <div>
      {conclusionGuideItem.recommended && (
        <>
          <TitleContainer>
            <InfoTitleLayout
              icon={<RecommendedInfoTitleIcon />}
              title={<HeadingH4>Рекомендуем</HeadingH4>}
            />
          </TitleContainer>
          <TextContainer>
            <InfoTextFormatter html={conclusionGuideItem.recommended} />
          </TextContainer>
        </>
      )}
      {conclusionGuideItem.not_recommended && (
        <>
          <TitleContainer>
            <InfoTitleLayout
              icon={<NotRecommendedInfoTitleIcon />}
              title={<HeadingH4>Не рекомендуем</HeadingH4>}
            />
          </TitleContainer>
          <TextContainer>
            <InfoTextFormatter html={conclusionGuideItem.not_recommended} />
          </TextContainer>
        </>
      )}
    </div>
  );

  return <InfoLayout close={close} content={content} heading={heading} tabs={tabs} />;
};
