export const organizationsColor = {
  0: '#e37475',
  1: '#957661',
  2: '#efd99d',
  3: '#cf679a',
  4: '#ea0000',
  5: '#953052',
  6: '#56f86e',
  7: '#d49e6b',
  8: '#8ea437',
  9: '#0a6067',
  10: '#cd8070',
  11: '#9a332a',
  12: '#93a1c4',
  13: '#666642',
  14: '#bddbcf',
  15: '#9f8ab3',
  16: '#314872',
  17: '#bf630c',
  18: '#f87b57',
  19: '#50e0ff',
  20: '#fe6f5e',
  21: '#7ba05b',
  22: '#df73ff',
  23: '#177b98',
  24: '#a8a8a8',
  25: '#656565',
  26: '#310062',
  27: '#78866b',
  28: '#5e8bf1',
  29: '#6e4234',
  30: '#c9a0dc',
  31: '#003153',
  32: '#ecc3b6',
  33: '#ff7f50',
  34: '#d1e231',
  35: '#50cdff',
  36: '#77d8b3',
  37: '#9ec48b',
  38: '#cbc784',
  39: '#e37475',
  40: '#d49e6b',
}