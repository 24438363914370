// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 1px ${colors.transparentGray};
  padding-bottom: 12px;
`;

const TitleContainer = styled.div`
  margin-left: 11px;
`;

export const InfoTitleLayout = (props: { icon: React$Node, title: React$Node }) => {
  const { icon, title } = props;
  return (
    <Container>
      {icon}
      <TitleContainer>{title}</TitleContainer>
    </Container>
  );
};
