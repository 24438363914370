// @flow
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import _ from "lodash";
import { observer } from "mobx-react";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ChartComponent } from "../common/charts/ChartComponent";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import dashboardStore from "../../stores/dashboardStore";
import { Paper } from "@mui/material";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = "widgetFirstOnlineAppSaleAnalytics";

const chartSettings = {
  open: {
    label: "Посмотрели сторис",
    color: "#EA0000"
  },
  online_consultation: {
    label: "Нажали записаться на онлайн консультацию",
    color: "#0430F6"
  },
  chat_consultation: {
    label: "Нажали записаться на консультацию в чате",
    color: "#24D313"
  },
  purchased_services_chat: {
    label: "Записался на чат",
    color: "#CF679A"
  },
  purchased_services_online: {
    label: "Записался на онлайн",
    color: "#E7E308"
  },
};

const transformTableData = data => {
  if (!data) {
    return [];
  }
  return Object.entries(dashboardStore.firstOnlineAppSaleStatus)
    .map(([status, label]) => {
      return {
        status: label,
        count: data.reduce((acc, current) => {
          if (current.story_status === status) {
            acc += current.count;
          }
          return acc;
        }, 0)
      };
    })
    .filter(item => item.count);
};

const transformDataDiagram = ({ data, interval }) => {
  const sortedData = _.sortBy(data, "date_trunc");
  const groupedData = Object.values(_.groupBy(sortedData, "date_trunc"));
  const labels = Object.keys(_.groupBy(sortedData, "date_trunc"));

  const initialState = Object.entries(dashboardStore.firstOnlineAppSaleStatus).reduce(
    (acc, [status, name]) => {
      acc[status] = {
        data: [],
        label: name,
        color: chartSettings[status].color,
        highlightScope: {
          highlighted: "series",
          faded: "global"
        }
      };
      return acc;
    },
    {}
  );

  groupedData.forEach(item => {
    return Object.keys(chartSettings).forEach(key => {
      if (item.find(el => el.story_status === key)) {
        return;
      }
      item.push({
        story_status: key,
        count: 0,
        date_trunc: null
      });
    });
  });

  const datasets = _.flatten(groupedData).reduce((acc, current) => {
    acc[current.story_status].data.push(current.count);
    return acc;
  }, initialState);

  return { labels, datasets: Object.values(datasets) };
};

export const FILTERS_FIELDS_WIDGET_FIRST_ONLINE_APP_SALE_ANALYTICS = () => [
  {
    type: "select",
    name: "type",
    label: "Вид графика",
    xs: 12,
    options: [
      { value: "line", label: "Линейный" },
      { value: "bar", label: "Столбцами" },
      { value: "table", label: "Таблицей" }
    ]
  },
  {
    type: "select",
    name: "story_status",
    label: "Статус",
    multiple: true,
    xs: 12,
    options: dashboardStore.firstOnlineAppSaleStatusOptions
  },
  {
    type: "text",
    name: "label",
    label: "Название графика",
    xs: 12
  }
];

export const WidgetFirstOnlineAppSaleAnalytics = observer(
  (props: { filtersData: () => {}, rewriteFilters: () => {}, keyWidget: String }) => {
    const styledPaper = {
      padding: "20px",
      height: "100%",
      display: "flex",
      flexDirection: "column"
    };

    const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
    const [filterFields, setFilterFields] = useState({});
    const [tableData, setTableData] = useState([]);
    const [label, setLabel] = useState("Виджет по акции \"Первая онлайн консультация любого специалиста из приложения со скидкой 50%");
    const [chartsData, setChartsData] = useState();
    const [isInitialized, setIsInitialized] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      (async () => {
        const filterDiagramLocaleStorage = getLocalStorage(
          NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", "")
        );
        if (
          filterDiagramLocaleStorage &&
          moment(filterDiagramLocaleStorage.date_end).isBefore(moment())
        ) {
          filterDiagramLocaleStorage.date_end = moment();
        }
        const filters =
          props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
        if (filters.label) {
          setLabel(filters.label);
        }
        if (moment(filters.date_end).isBefore(moment().set({ hour: 23, minute: 59, second: 59 }))) {
          filters.date_end = moment().set({ hour: 23, minute: 59, second: 59 });
        }
        setFiltersDataDiagram(filters);
        setFilterFields([...FILTERS_FIELDS_WIDGET_FIRST_ONLINE_APP_SALE_ANALYTICS()]);
        setIsInitialized(true);
      })();
    }, []);

    useEffect(() => {
      if (props.rewriteFilters) {
        setFiltersDataDiagram({ ...filtersDataDiagram, ...props.rewriteFilters });
      }
    }, [props.rewriteFilters]);

    useEffect(() => {
      (async () => {
        if (_.size(filtersDataDiagram)) {
          setLoading(true);
          setLocalStorage(
            NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", ""),
            filtersDataDiagram
          );
          if (filtersDataDiagram.label) {
            setLabel(filtersDataDiagram.label);
          }
          const response = await dashboardStore.getAnalyticsFirstOnlineAppSaleData(filtersDataDiagram);
          setChartsData(
            transformDataDiagram({
              data: response,
              interval: filtersDataDiagram.interval || "day"
            })
          );
          setTableData(transformTableData(response));
          setLoading(false);
        }
      })();
    }, [filtersDataDiagram]);

    const submitHandler = (filters, callback) => {
      setFiltersDataDiagram(filters);
      callback();
    };

    const columns = useMemo(
      () => [
        {
          accessorKey: "status",
          header: "Статус"
        },
        {
          accessorKey: "count",
          header: "Количество",
          size: 220
        }
      ],
      []
    );

    const table = useMaterialReactTable({
      columns: columns,
      data: tableData,
      enableFullScreenToggle: false,
      enableBottomToolbar: false,
      enableGlobalFilter: false,
      enableHiding: false,
      layoutMode: "grid",
      initialState: {
        density: "compact"
      },
      localization: MRT_Localization_RU,
      isMultiSortEvent: () => true
    });

    return (
      <Paper style={styledPaper}>
        {filterFields && (
          <LabelWithFiltersModal
            fullWidth={true}
            type={"filter"}
            filtersData={filtersDataDiagram}
            filtersFields={filterFields}
            label={label}
            textButtonFilter={"Применить"}
            submitHandler={submitHandler}
          />
        )}
        {(loading || !isInitialized) && <AbsoluteLoader />}
        {isInitialized && (
          <>
            {filtersDataDiagram.type !== "table" && chartsData && (
              <ChartComponent
                textButtonFilter="Применить"
                filtersDataDiagram={filtersDataDiagram}
                type={filtersDataDiagram.type || "bar"}
                nameDiagram={"widgetFirstOnlineAppSaleAnalytics"}
                data={chartsData}
                margin={{
                  top: 10
                }}
                interval={filtersDataDiagram?.interval || 'day'}
              />
            )}
            {filtersDataDiagram.type === "table" && tableData && (
              <div
                style={{
                  maxHeight: "100%",
                  overflow: "auto"
                }}
                className={"material-table"}>
                <MaterialReactTable table={table} />
              </div>
            )}
          </>
        )}
      </Paper>
    );
  }
);
