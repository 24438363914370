// @flow
import { useState, useRef } from "react";

export const useLogo = () => {
  const logoEl = useRef<?HTMLImageElement>(null);
  const [isLogoLoaded, setLogoLoaded] = useState<boolean>(
    (logoEl.current && logoEl.current.complete) || false
  );

  return {
    logoEl,
    isLogoLoaded,
    setLogoLoaded
  };
};
