// @flow
import * as React from "react";
import styled from "styled-components";
import { Container, DefaultButton } from "./DefaultButton";
import { colors } from "../styleguide/colors";
import { rgba } from "polished";

export const PrimaryButtonContainer = styled(Container)`
  width: 256px;
  height: 60px;
  background: ${props => (props.disabled ? colors.gray200 : colors.magenta)};

  &:hover {
    background: ${colors.lightMagenta};
    box-shadow: 0 3px 5px 0 ${rgba(colors.magenta, 0.25)};
  }
`;

export const PrimaryButtonText = styled.span`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${props => (props.disabled && colors.black) || colors.white};
`;

export const PrimaryButton = (props: {
  disabled?: boolean,
  isLoading?: boolean,
  onClick?: () => mixed,
  type?: "button" | "reset" | "submit",
  children: ?React.Node,
  primaryButtonContainer?: React.ComponentType<{}>,
  primaryButtonText?: React.ComponentType<{}>
}) => (
  <DefaultButton
    {...props}
    overrides={{
      Container: {
        component: props.primaryButtonContainer || PrimaryButtonContainer
      },
      Text: {
        component: props.primaryButtonText || PrimaryButtonText,
        props: {
          disabled: props.disabled
        }
      }
    }}
  />
);
