import React from "react";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import { useScheduleSocket } from "../schedule/useScheduleSocket";
import { useToast } from "../toast/useToast";
import { getPatientFullName, getPatientLastNameWithInitials } from "../../domain/services/patient";
import { colors } from "../styleguide/colors";

import { updateScheduleData } from "./fullScheduleStore";
import { findToast } from "../toast/toastStore";
import { currentUser } from "../../stores/auth";

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 18px;
`;

const BoldText = styled.span`
  font-weight: 500;
  color: ${colors.black};
`;

const ToastText = styled.div`
  color: ${colors.gray500};
`;

const debouncedUpdateGeneralSchedule = _.debounce(data => {
  updateScheduleData(data);
}, 200);

export const useGeneralScheduleUpdates = () => {
  const { showToast, closeToast } = useToast();

  const getPatientFullNameFromSchedule = schedule => {
    if (schedule.medical_file) {
      return getPatientFullName(schedule.medical_file.patient);
    } else if (schedule.patient_full_name) {
      return schedule.patient_full_name;
    }
  };

  const onPatientArrived = schedule => {
    showToast(
      <ToastContainer>
        <BoldText>{getPatientFullNameFromSchedule(schedule)}</BoldText> пришёл в клинику
      </ToastContainer>
    );
  };

  const onPatientLeft = schedule => {
    showToast(
      <ToastContainer>
        <BoldText>{getPatientFullNameFromSchedule(schedule)}</BoldText> покинул клинику
      </ToastContainer>
    );
  };

  const onScheduleInsertConfirmed = schedule => {
    const user = currentUser.getState();

    const toastToRemove = findToast(toastObject => {
      const toastData = toastObject.data;
      if (
        user.doctor &&
        toastData.triggeredDoctor &&
        user.doctor.id === toastData.triggeredDoctor.id &&
        (schedule.medical_file || schedule.patient_full_name)
      ) {
        const { scheduleId, scheduleValues } = toastData;
        const scheduleIdEquals = scheduleId === schedule.id;
        const doctorEquals = schedule.doctor === scheduleValues.doctor_id;
        const organizationEquals = schedule.organization === scheduleValues.organization_id;
        let medicalFileOrPatientNameEquals = false;
        if (schedule.medical_file) {
          medicalFileOrPatientNameEquals =
            schedule.medical_file.id === scheduleValues.medical_file_id;
        } else if (schedule.patient_full_name) {
          medicalFileOrPatientNameEquals =
            schedule.patient_full_name === getPatientFullName(scheduleValues.medical_file.patient);
        }

        const dateEquals = schedule.date === scheduleValues.date;
        const timeEquals = schedule.time === scheduleValues.time;

        if (
          scheduleIdEquals &&
          doctorEquals &&
          organizationEquals &&
          medicalFileOrPatientNameEquals &&
          dateEquals &&
          timeEquals
        ) {
          return true;
        }
      }
      return false;
    });
    if (toastToRemove) {
      closeToast(toastToRemove.toastId);
      showToast(
        <ToastContainer>
          <BoldText>{getPatientFullNameFromSchedule(schedule)} записан(а)</BoldText>
          <ToastText>
            {moment(`${schedule.date} ${schedule.time}`).format("DD MMMM, в HH:mm")} к{" "}
            {getPatientLastNameWithInitials(toastToRemove.data.doctor)}
          </ToastText>
        </ToastContainer>
      );
    }
  };

  useScheduleSocket((msg_type, data) => {
    switch (msg_type) {
      case "schedule_updated":
        onScheduleInsertConfirmed(data);
        debouncedUpdateGeneralSchedule(data);
        break;
      case "schedule_deleted":
        debouncedUpdateGeneralSchedule(data);
        break;
      case "patient_arrived":
        onPatientArrived(data);
        break;
      case "patient_left":
        onPatientLeft(data);
        break;
    }
  });
};
