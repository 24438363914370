// @flow
import React from "react";
import { PNormal } from "../styleguide/typography";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const Text = styled(PNormal)`
  color: ${props => (props.disabled ? colors.gray400 : colors.black)};

  ${props =>
    props.inline &&
    `
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
`;

export const PointTitle = (props: { disabled?: boolean, text: React$Node, inline?: boolean }) => {
  const { disabled, text, inline } = props;
  return (
    <Text disabled={disabled} inline={inline} modifiers="left">
      {text}
    </Text>
  );
};
