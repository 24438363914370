// @flow
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary, Box,
  Collapse,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { observer } from "mobx-react";
import { DynamicForm } from "./dynamic-form/DynamicForm";
import Button from "@mui/material/Button";
import SettingsIcon from '@mui/icons-material/Settings';
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { styled as styledMaterial } from "@mui/material/styles";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { ModalPortal } from "../modal/ModalPortal";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import _ from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dynamicFiltersStore from "../../stores/dynamicFiltersStore";
import MenuItem from '@mui/material/MenuItem';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Select from '@mui/material/Select';
import { NUMBER_FIELD_STRING_PARAMS } from "../../utils/tableUtils";
import dayjs from "dayjs";
import { SERVER_DATE_FORMAT } from "../../utils/dates";
import MoreIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import {
  ExpandLess,
  ExpandMore,
  SaveOutlined as SaveIcon,
  FilterAltOutlined as SaveFiltersIcon,
  DeleteOutlineOutlined as DeleteIcon,
  FilterListOutlined as AdvancedFilterIcon,
  FilterListOffOutlined as StandardFilterIcon
} from "@mui/icons-material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DynamicFiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const DynamicFiltersActions = styledMaterial(Box)(() =>({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: "10px"
}));

const AccordionSummaryStyled = styledMaterial(AccordionSummary)(() =>({
  minHeight: "40px",
  [`&.Mui-expanded`]: {
    minHeight: "40px"
  },
  [`.MuiAccordionSummary-content`]: {
    margin: '0',
    [`&.Mui-expanded`]: {
      margin: '0'
    }
  }
}));

const AccordionDetailsStyled = styledMaterial(Box)(() =>({
  padding: '0 10px 10px'
}));

export const DynamicFilters = observer((props: {
  data?: Object,
  titleForm?: string,
  allFilters: Array,
  labelName?: string,
  nameLocalStorageSaveFilters?: string,
  textButton?: string,
  hiddenButtonSave?: boolean,
  hiddenButtonCancel?: boolean,
  disabledOptions?: Array,
  defaultFilters?: Array,
  defaultData?: () => ({}),
  submitHandler?: () => any,
  changeHandler?: () => any,
  isNeedAdvancedFilters?: boolean,
}) => {
  const [filtersFieldForm, setFiltersFieldForm] = useState([]);
  const [selectedFieldsModal, setSelectedFieldsModal] = useState([]);
  const [saveFilters, setSaveFilters] = useState([]);
  const [dataFilters, setDataFilters] = useState();
  const [dataAdvancedFilters, setDataAdvancedFilters] = useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [showModalFiltersFields, setShowModalFiltersFields] = React.useState(false);
  const [nameFilter, setNameFilter] = React.useState('');
  const [fieldsNotStandard, setFieldsNotStandard] = React.useState([]);
  const [namesAllFilters, setNamesAllFilters] = React.useState(props.allFilters.map((item) => item.name));
  const [allFilters, setAllFilters] = React.useState([]);
  const [dropDownAnchorEl, setDropDownAnchorEl] = useState(null);
  const [isCollapseSaveFilters, setIsCollapseSaveFilters] = useState(false);
  const labelName = props.labelName || 'label';

  const advancedFilters = [
    {name: 'advancedFilter', needSubmitEnter: true, [labelName]: 'Продвинутый фильтр', type: 'text', xs: 12, md: 10}
  ];

  const handleOnOpenDropDownMenu = (event) => setDropDownAnchorEl(event.currentTarget);

  const handleOnClickSaveFilters = () => setIsCollapseSaveFilters(!isCollapseSaveFilters);

  const handleOnCloseDropDownMenu = () => setDropDownAnchorEl(null);

  const transformDataToAdvancedFilters = (data) => {
    const newData = data || dataFilters;
    let filterString = '';
    Object.keys(newData).forEach((key) => {
      if (filterString) {
        filterString += ` AND ${key} = ${newData[key]}`;
      } else {
        filterString += `${key} = ${newData[key]}`;
      }
    })
    setDataAdvancedFilters(filterString);
    return filterString;
  }

  const getFiltersLabel = (field) => {
    if (field.filterFn !== 'iexact') {
      return `(${dynamicFiltersStore.FILTERS_FN_LABEL[field.filterFn].toLowerCase()})`;
    }
    return '';
  }

  const advancedStringToFilter = (filters) => {
    let newFilters = {};
    const filtersData = filters.advancedFilter.split('AND')
    filtersData.forEach((item) => {
      if (item && item.trim() && item.split('=').length) {
        const splitValue = item.split('=');
        if (splitValue[0].trim().includes('__in')) {
          if (NUMBER_FIELD_STRING_PARAMS.includes(splitValue[0].trim())) {
            newFilters[splitValue[0].trim()] = splitValue[1].trim().split(',').map((val) => val ? parseInt(val) : "");
          } else {
            newFilters[splitValue[0].trim()] = splitValue[1].trim().split(',');
          }
        } else {
          newFilters[splitValue[0].trim()] = splitValue[1]?.trim() || "";
        }
      }
    })
    return newFilters;
  }

  const createFiltersFieldsForm = ({data, needChangeAdvancedMode, needUpdateDataFilters = true, filtersData, isCloseModalFilterFields = true, isResetFilters = false}) => {
    const newFilters = [];
    const fields = [];
    const selectedFieldsModalObject = selectedFieldsModal?.reduce((acc, item) => {
      acc[item.name] = item;
      return acc;
    }, {}) || {};
    const dataObject = data?.reduce((acc, item) => {
      acc[item.name] = item;
      return acc;
    }, {}) || {};
    let allFields = {};
    if (isResetFilters) {
      allFields = dataObject;
    } else {
      allFields = {...selectedFieldsModalObject, ...dataObject};
    }
    Object.keys(allFields).forEach((key) => fields.push(allFields[key]))
    fields.forEach((field) => {
      if (field.filterFn === 'in') {
        newFilters.push({...field, name: `${field.name}__${field.filterFn}`, label: `${field.label} ${getFiltersLabel(field)}`, multiple: true});
      } else if (field.filterFn === 'isnull') {
        newFilters.push({...field, type: 'select', options: [
          {
            value: true,
            label: 'Да'
          },
          {
            value: false,
            label: 'Нет'
          }
        ], name: `${field.name}__${field.filterFn}`, label: `${field.label} ${getFiltersLabel(field)}`});
      } else if (field.filterFn !== 'range') {
        newFilters.push({...field, name: field.filterFn !== 'iexact' ? `${field.name}__${field.filterFn}` : field.name, label: `${field.label} ${getFiltersLabel(field)}`});
      } else {
        newFilters.push({...field, name: `${field.name}__gte`, label: `${field.label} от`})
        newFilters.push({...field, name: `${field.name}__lte`, label: `${field.label} до`})
      }
    })
    let newDataFilters = {};
    if (needUpdateDataFilters) {
      const oldData = filtersData || (dynamicFiltersStore.isAdvancedMode ? advancedStringToFilter({advancedFilter: dataAdvancedFilters }) : dataFilters);
      if (oldData) {
        Object.keys(oldData).forEach((filterName) => {
          let splitName = filterName.split('__');
          splitName = splitName.length > 1 ? splitName.slice(0, dynamicFiltersStore.FILTERS_FN.includes(splitName[splitName.length -1]) ? splitName.length -1 : splitName.length).join('__') : splitName[0];
          const findItem = newFilters.find((item) => item.name === splitName || item.name === filterName || item.name === (`${splitName}__${item?.filterFn}`));
          const setData = (findItem, nameData) => {
            if (findItem.filterFn === 'in') {
              if (!Array.isArray(oldData[nameData])) {
                newDataFilters[findItem.name] = [oldData[nameData]];
              } else {
                newDataFilters[findItem.name] = oldData[nameData] !== undefined ? oldData[nameData] : nameData;
              }
            } else {
              if (Array.isArray(oldData[nameData])) {
                newDataFilters[findItem.name] = oldData[nameData][0];
              } else {
                newDataFilters[findItem.name] = oldData[nameData] !== undefined ? oldData[nameData] : nameData;
              }
            }
          }
          if (findItem) {
            if (oldData[findItem?.name] !== undefined) {
              setData(findItem, findItem.name);
            } else if (oldData[filterName] !== undefined) {
              setData(findItem, filterName);
            } else if (oldData[splitName] !== undefined) {
              setData(findItem, splitName);
            } else if (oldData[`${splitName}__${findItem?.filterFn}`] !== undefined) {
              setData(findItem, `${splitName}__${findItem?.filterFn}`);
            } else {
              setData(findItem, null);
            }
          }
        })
        setDataFilters(newDataFilters);
      }
    }
    setFiltersFieldForm(newFilters);
    if (needChangeAdvancedMode) {
      dynamicFiltersStore.changeAdvancedMode(false);
    }
    if (isCloseModalFilterFields) {
      setShowModalFiltersFields(false);
    }
  }

  const filtersToFieldsModal = (filters, needUpdateDataFilters, needSetSelectedFieldsModal) => {
    const filtersNames = [];
    Object.keys(filters).forEach((filterName) => {
      const splitName = filterName.split('__');
      const filterFn = (splitName.length > 1 && dynamicFiltersStore.FILTERS_FN.includes(splitName[splitName.length -1])) ? splitName[splitName.length - 1] : '';
      const nameField = splitName.length > 1 ? splitName.slice(0, dynamicFiltersStore.FILTERS_FN.includes(splitName[splitName.length -1]) ? splitName.length -1 : splitName.length).join('__') : splitName[0];
      const findIndexItem = filtersNames.map((field) => field.name).indexOf(nameField);
      if (findIndexItem > -1 && ((filtersNames[findIndexItem].filterFn === 'lte' && filterFn === 'gte') || (filtersNames[findIndexItem].filterFn === 'gte' && filterFn === 'lte'))) {
        filtersNames[findIndexItem].filterFn = 'range';
      } else {
        filtersNames.push({
          name: nameField,
          filterFn: filterFn || 'iexact'
        })
      }
    })
    const fieldsModal = props.allFilters.filter((item) => {
      const findItem = filtersNames.find((field) => field.name === item.name);
      if (findItem) {
        item.filterFn = findItem.filterFn;
        return true;
      }
      return false;
    });
    if (needSetSelectedFieldsModal) {
      setSelectedFieldsModal(fieldsModal);
    }
    createFiltersFieldsForm({filtersData: filters, data: fieldsModal, needUpdateDataFilters: needUpdateDataFilters});
  }

  const checkFieldsForStandard = (filters) => {
    let fieldsNotStandardName = [];
    Object.keys(filters).forEach((fieldName) => {
      const splitName = fieldName.split('__');
      const nameField = splitName.length > 1 ? splitName.slice(0, dynamicFiltersStore.FILTERS_FN.includes(splitName[splitName.length -1]) ? splitName.length -1 : splitName.length).join('__') : splitName[0];
      if (!namesAllFilters.includes(nameField)) {
        fieldsNotStandardName.push(nameField);
      }
    })
    setFieldsNotStandard(fieldsNotStandardName);
    return !!fieldsNotStandardName.length;
  };

  const changeViewMode = () => {
    if (!dynamicFiltersStore.isAdvancedMode) {
      dynamicFiltersStore.changeAdvancedMode(!dynamicFiltersStore.isAdvancedMode);
      setTimeout(() => {
        setDataAdvancedFilters(transformDataToAdvancedFilters(dataFilters));
      }, 200);
    } else {
      const filters = advancedStringToFilter({advancedFilter: dataAdvancedFilters });
      checkFieldsForStandard(filters);
      if (!fieldsNotStandard.length) {
        dynamicFiltersStore.changeAdvancedMode(!dynamicFiltersStore.isAdvancedMode);
        setTimeout(() => {
          filtersToFieldsModal(filters, true, true);
        }, 200);
      }
    }
  }

  useEffect(() => {
    const data = _.size(props.data) ? props.data : {};
    if (!_.isEqual(dataFilters, data) && isInitialized) {
      const needChangeToAdvancedMode = checkFieldsForStandard(data);
      if (needChangeToAdvancedMode) {
        const advancedData = transformDataToAdvancedFilters(data);
        setDataAdvancedFilters(transformDataToAdvancedFilters(data));
        dynamicFiltersStore.changeAdvancedMode(true);
        submitHandler({advancedFilter: advancedData})
      } else {
        createFiltersFieldsForm({filtersData: props.data, needChangeAdvancedMode: true})
      }
    }
  }, [props.data]);

  useEffect(() => {
    const localStorageSaveFilters = getLocalStorage(props.nameLocalStorageSaveFilters)
    if (localStorageSaveFilters) {
      setSaveFilters(localStorageSaveFilters || []);
    }

    dynamicFiltersStore.changeAdvancedMode(false);
    const data = _.size(props.data) ? {...props.defaultData, ...props.data} : (props.defaultData || {});
    setDataFilters(data);
    filtersToFieldsModal(data, false, true);
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if ((dataFilters || props.data) && !_.isEqual(allFilters, props.allFilters) && isInitialized) {
      setAllFilters(props.allFilters);
      setNamesAllFilters(props.allFilters.map((item) => item.name));
      createFiltersFieldsForm({filtersData: props.data, needChangeAdvancedMode: true})
    }
  }, [props.allFilters]);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const changeHandler = (filters) => {
    if (!dynamicFiltersStore.isAdvancedMode) {
      props.changeHandler(filters);
    }
  }

  const submitHandler = (filters) => {
    if (dynamicFiltersStore.isAdvancedMode) {
      setDataAdvancedFilters(filters.advancedFilter);
      const newFilters = advancedStringToFilter(filters);
      props.submitHandler(newFilters);
      checkFieldsForStandard(newFilters);
    } else if (props.submitHandler) {
      props.submitHandler(filters);
    }
  }

  const handleClearFilters = () => {
    if (props.defaultFilters) {
      const filters = props.allFilters.filter((item) => props.defaultFilters.includes(item.name));
      setSelectedFieldsModal(filters);
      createFiltersFieldsForm({data: filters, needUpdateDataFilters: false, isCloseModalFilterFields: false, isResetFilters: true});
    } else {
      setFiltersFieldForm([]);
    }
    if (props.defaultData) {
      setDataFilters(props.defaultData);
      setDataAdvancedFilters(transformDataToAdvancedFilters(props.defaultData));
    } else {
      setDataFilters({});
      setDataAdvancedFilters('');
    }
    if (props.changeHandler) {
      props.changeHandler(props.defaultData);
    }
    if (props.submitHandler) {
      props.submitHandler(props.defaultData);
    }
  }

  const saveFiltersHandler = () => {
    const data = {
      name: nameFilter,
      data: dynamicFiltersStore.isAdvancedMode ? dataAdvancedFilters : dataFilters,
      filters: selectedFieldsModal,
      isAdvancedMode: dynamicFiltersStore.isAdvancedMode
    }
    setSaveFilters([...saveFilters, data]);
    setLocalStorage(props.nameLocalStorageSaveFilters, [...saveFilters, data]);
    handleCloseModal();
  }

  const deleteSaveFilter = (filter) => {
    const newSaveFilters = saveFilters.filter((item) => item.name !== filter.name);
    setSaveFilters(newSaveFilters);
    setLocalStorage(props.nameLocalStorageSaveFilters, newSaveFilters);
  }
  const selectSaveFilter = (filter) => {
    if (filter.isAdvancedMode) {
      const filters = advancedStringToFilter({advancedFilter: filter.data });
      if (filters.date__lte && dayjs(filters.date__lte).diff(dayjs()) < 1) {
        filters.date__lte = dayjs().format(SERVER_DATE_FORMAT);
      }
      const advancedFilters = transformDataToAdvancedFilters(filters);
      setDataAdvancedFilters(advancedFilters);
      dynamicFiltersStore.changeAdvancedMode(true);
      submitHandler({advancedFilter: advancedFilters})
    } else {
      if (filter.data.date__lte && dayjs(filter.data.date__lte).diff(dayjs()) < 1) {
        filter.data.date__lte = dayjs().format(SERVER_DATE_FORMAT);
      }
      setSelectedFieldsModal(filter.filters);
      createFiltersFieldsForm({filtersData: filter.data, data: filter.filters, needChangeAdvancedMode: true });
      submitHandler(filter.data)
    }
  }

  return (
    <DynamicFiltersContainer className="containerDynamicFilters">
      <Accordion>
        <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
          { props.titleForm }
        </AccordionSummaryStyled>
        <AccordionDetailsStyled>
          <DynamicFiltersActions>
            <Button size="small" startIcon={<SettingsIcon />} disableRipple onClick={() => setShowModalFiltersFields(true)}>
              Изменить фильтры
            </Button>
            <IconButton size="small" onClick={handleOnOpenDropDownMenu}>
              <MoreIcon fontSize="medium" />
            </IconButton>

            <Menu
              anchorEl={dropDownAnchorEl}
              open={!!dropDownAnchorEl}
              onClose={() => {
                handleOnCloseDropDownMenu();
                setIsCollapseSaveFilters(false);
              }}
              slotProps={{
                paper: {
                  style: {
                    maxHeight: 450,
                    minWidth: 350,
                  }
                }
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClickOpenModal();
                  handleOnCloseDropDownMenu();
                }}>
                <ListItemIcon><SaveIcon /></ListItemIcon>
                <ListItemText>Сохранить фильтр</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  changeViewMode();
                  handleOnCloseDropDownMenu();
                }}>
                <ListItemIcon>{dynamicFiltersStore.isAdvancedMode ? <StandardFilterIcon /> : <AdvancedFilterIcon />}</ListItemIcon>
                <ListItemText sx={{ pr: dynamicFiltersStore.isAdvancedMode && !!fieldsNotStandard.length ? 2 : 0 }}>
                  {dynamicFiltersStore.isAdvancedMode ? 'Стандартные фильтры' : 'Продвинутые фильтры'}
                </ListItemText>
                {(dynamicFiltersStore.isAdvancedMode && !!fieldsNotStandard.length) && (
                  <Tooltip
                    arrow
                    placement="left"
                    title={`В стандартных фильтрах нет ${fieldsNotStandard.length === 1 ? 'такого поля' : 'таких полей'}, как "${fieldsNotStandard.join(', ')}". Переход не возможен`}
                  >
                    <ErrorOutlineIcon color={"error"} />
                  </Tooltip>
                )}
              </MenuItem>
              {!!saveFilters.length && <Divider />}
              {!!saveFilters.length && (
                <MenuItem onClick={handleOnClickSaveFilters}>
                  <ListItemIcon sx={{ minWidth: "36px" }}>
                    <SaveFiltersIcon />
                  </ListItemIcon>
                  <ListItemText>Сохраненные фильтры</ListItemText>
                  {isCollapseSaveFilters ? <ExpandLess sx={{ pl: 2 }}/> : <ExpandMore sx={{ pl: 2 }}/>}
                </MenuItem>
              )}
              <Collapse in={isCollapseSaveFilters} timeout="auto" unmountOnExit>
                {saveFilters.map((filter, index) => (
                  <List key={filter.name + index} component="div" disablePadding dense>
                    <ListItemButton sx={{ pl: 5 }}>
                      <ListItemText onClick={() => selectSaveFilter(filter)}>{filter.name}</ListItemText>
                      <IconButton onClick={() => deleteSaveFilter(filter)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ListItemButton>
                  </List>
                ))}
              </Collapse>
            </Menu>

            <ModalPortal>
              <Dialog
                maxWidth={"sm"}
                fullWidth
                onClose={handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={openModal}
              >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  Сохранить текущий фильтр
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleCloseModal}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers className="dialogSaveDynamicFilters">
                  <TextField
                    autoFocus
                    required
                    onChange={(event) => setNameFilter(event.target.value)}
                    margin="dense"
                    name="nameFilter"
                    label="Наименование фильтра"
                    type="text"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleCloseModal}>
                    Отмена
                  </Button>
                  <Button autoFocus onClick={saveFiltersHandler}>
                    Сохранить
                  </Button>
                </DialogActions>
              </Dialog>
            </ModalPortal>
          </DynamicFiltersActions>
          {filtersFieldForm.length || dynamicFiltersStore.isAdvancedMode ? (
             <DynamicForm
               textButton={props.textButton || "Применить"}
               isInlineButtonSave={true}
               hiddenButtonCancel={props.hiddenButtonCancel}
               hiddenButtonSave={dynamicFiltersStore.isAdvancedMode ? !dynamicFiltersStore.isAdvancedMode : props.hiddenButtonSave}
               data={dynamicFiltersStore.isAdvancedMode ? { advancedFilter: dataAdvancedFilters} : dataFilters}
               changeHandler={props.changeHandler ? (filters) => changeHandler(filters) : null}
               submitHandler={(filters) => submitHandler(filters)}
               fields={dynamicFiltersStore.isAdvancedMode ? advancedFilters : filtersFieldForm}
               parentName="formDynamicFilters"
             />
          ) : (
            <Typography>Вы можете фильтровать таблицу, для этого измените фильтры</Typography>
          )}
        </AccordionDetailsStyled>
      </Accordion>
      <ModalPortal>
        <Dialog
          maxWidth={"sm"}
          fullWidth
          onClose={() => setShowModalFiltersFields(false)}
          open={showModalFiltersFields}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} >
            Изменение полей фильтров
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setShowModalFiltersFields(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="dialogChangeDynamicFilters">
            <Autocomplete
              multiple
              id="formDynamicFilters"
              getOptionDisabled={(option) => !!props.disabledOptions?.includes(option.name)}
              value={props.allFilters.filter((item) => selectedFieldsModal.map((field) => field.name).includes(item.name))}
              onChange={(event, newValue) => {
                setSelectedFieldsModal(newValue.map((item) => {
                  if (!item.filterFn) {
                    item.filterFn = 'iexact';
                  }
                  return item;
                }));
              }}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option[labelName]}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label={props.label} />}
              options={props.allFilters}
              getOptionLabel={(option) => option[labelName]}
            />
            {!!selectedFieldsModal.length && (
              <>
                {selectedFieldsModal.map((field) => {
                  const showFiltersFn = field.optionsFilterFn || dynamicFiltersStore.FILTERS_FN_FOR_TYPE_FIELD[field.type];
                  return (
                    <Grid alignItems={"center"} key={field.name} container marginTop={'10px'} spacing={1.5}>
                      <Grid item xs={12} md={4}>
                        <Typography style={{
                          fontSize: ".9rem",
                          wordBreak: 'break-all'
                        }}>{field.label}</Typography>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Select
                          fullWidth
                          disabled={field.filterFnDisabled}
                          labelId="simple-select-standard-label"
                          value={field.filterFn}
                          onClick={(e) => e.preventDefault()}
                          onChange={(event) => setSelectedFieldsModal(selectedFieldsModal.map((item) => {
                            if (item.name === field.name) {
                              item.filterFn = event.target.value;
                            }
                            return item;
                          }))}
                        >
                          {showFiltersFn.includes('iexact') && (
                            <MenuItem value="iexact">
                              {dynamicFiltersStore.FILTERS_FN_LABEL['iexact']}
                            </MenuItem>
                          )}
                          {showFiltersFn.includes('icontains') && (
                            <MenuItem value="icontains">
                              {dynamicFiltersStore.FILTERS_FN_LABEL['icontains']}
                            </MenuItem>
                          )}
                          {showFiltersFn.includes('gte') && (
                            <MenuItem value="gte">
                              {dynamicFiltersStore.FILTERS_FN_LABEL['gte']}
                            </MenuItem>
                          )}
                          {showFiltersFn.includes('lte') && (
                            <MenuItem value="lte">
                              {dynamicFiltersStore.FILTERS_FN_LABEL['lte']}
                            </MenuItem>
                          )}
                          {showFiltersFn.includes('range') && (
                            <MenuItem value="range">
                              {dynamicFiltersStore.FILTERS_FN_LABEL['range']}
                            </MenuItem>
                          )}
                          {showFiltersFn.includes('in') && (
                            <MenuItem value="in">
                              {dynamicFiltersStore.FILTERS_FN_LABEL['in']}
                            </MenuItem>
                          )}
                          {showFiltersFn.includes('isnull') && (
                            <MenuItem value="isnull">
                              {dynamicFiltersStore.FILTERS_FN_LABEL['isnull']}
                            </MenuItem>
                          )}
                        </Select>
                      </Grid>
                    </Grid>
                  )
                })}
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-start" }}>
            <Button sx={{ justifySelf: "start" }} autoFocus onClick={handleClearFilters}>
              Сбросить фильтр
            </Button>
            <Button autoFocus sx={{ color: "#757575", marginLeft: "auto !important" }} onClick={() => setShowModalFiltersFields(false)}>
              Отмена
            </Button>
            <Button autoFocus variant="contained" onClick={() => createFiltersFieldsForm({needChangeAdvancedMode: true})}>
              Сохранить
            </Button>
          </DialogActions>
        </Dialog>
      </ModalPortal>
    </DynamicFiltersContainer>
  );
});
