// @flow
import { combine, createEffect, createEvent, createStore, restore, sample } from "effector";
import { getAppointmentsHistoryInterval } from "../../domain/services/appointments";
import { tokenStore, currentUser } from "../../stores/auth";
import { initializePaginationStoresAndEvents } from "../../utils/effectorPagination";
import type { Appointment } from "../../domain/entities/Appointment";
import filterAppointmentsStore from "../../stores/filterAppointmentsStore";
import apiv2 from '../../apiv2';

const limit = 12;

export const searchValueStore = createStore<string>("");
export const changeSearchValue = createEvent<string>("changeSearchValue");
searchValueStore.on(changeSearchValue, (state, payload) => payload);

type TPossibleTabs = "me" | "all";
export const activeTabStore = createStore<TPossibleTabs>("me");
export const setActiveTab = createEvent<TPossibleTabs>("setActiveTab");
activeTabStore.on(setActiveTab, (state, payload) => payload);

export const lastScheduleUrlStore = createStore<string>("/schedule");
export const setLastScheduleUrl = createEvent<string>("setLastScheduleUrl");
lastScheduleUrlStore.on(setLastScheduleUrl, (state, payload) => payload);

export const reloadAppointments = createEvent<void>("reloadAppointments");

export const {
  dataStore,
  appendData,
  offsetStore,
  loadMore,
  loadMoreStore,
  setLoadMore,
  clean
} = initializePaginationStoresAndEvents(limit);

const cleanerStore = combine(searchValueStore, activeTabStore, (searchString, activeTab) => ({
  searchString,
  activeTab
}));

cleanerStore.watch(() => {
  clean();
});

currentUser.watch(user => {
  if (!user) {
    clean();
  }
});

reloadAppointments.watch(() => {
  clean();
});

const getUnfinishedAppointments = createEffect("getUnfinishedAppointments").use(
  async ({ token, tab, searchValue }) => {
    if (!token || tab === "all") {
      return;
    }

    const result = await apiv2.appointments.getAppointments({
      doctor: "me",
      search: searchValue,
      search_fields: "patient,layout",
      only_patient: true,
      in_progress: true
    });

    return result;
  }
);

export const unfinishedAppointmentsStore = restore<?Array<Appointment>>(
  getUnfinishedAppointments,
  []
);

sample(
  combine(tokenStore, activeTabStore, searchValueStore, (token, tab, searchValue) => ({
    token,
    tab,
    searchValue
  })),
  reloadAppointments
).watch(getUnfinishedAppointments);

combine(tokenStore, activeTabStore, searchValueStore, (token, tab, searchValue) => ({
  token,
  tab,
  searchValue
})).watch(getUnfinishedAppointments);

const getAppointmentsEffect = createEffect("getAppointmentsEffect").use(
  async ({ token, tab, searchValue, offset }) => {
    if (!token) {
      return;
    }
    const { from_date } = getAppointmentsHistoryInterval();

    const params =
      tab === "me"
        ? {
            token: token || "",
            from_date,
            doctor: "me",
            in_progress: false,
            search: searchValue,
            only_patient: true,
            search_fields: "patient,layout",
          }
        : {
            token: token || "",
            from_date,
            doctor: "all",
            in_progress: false,
            search: searchValue,
            only_patient: filterAppointmentsStore.onlyPatient,
            search_fields: "doctor,patient,layout"
          };

    setLoadMore(false);
    return apiv2.appointments.getAppointments({ ...params, limit, offset });
  }
);

getAppointmentsEffect.done.watch(({ result: appointments, params }) => {
  if (!appointments) {
    return;
  }
  setLoadMore(appointments.length === limit);
  appendData({ data: appointments, offset: params.offset + 1 });
});

const getAppointmentsStore = combine(
  tokenStore,
  activeTabStore,
  searchValueStore,
  offsetStore,
  (token, tab, searchValue, offset) => ({ token, tab, searchValue, offset })
);
sample(getAppointmentsStore, reloadAppointments).watch(getAppointmentsEffect);
getAppointmentsStore.watch(data => {
  if (!data.token) {
    return;
  }
  getAppointmentsEffect(data);
});
