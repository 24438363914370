import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Services {
	apiService;
	constructor() {
		this.apiService = new ApiService('/api', null, null, API_URL);
	}

	list({ per_page }) {
		return this.apiService.get('services', {
			per_page,
		});
	}

	create(data) {
		return this.apiService.post('services', {}, data);
	}

	delete(serviceId) {
		return this.apiService.delete(`services/${serviceId}`);
	}

	getOrganizationServices(organizationId, params) {
		return this.apiService.get(`organizations/${organizationId}/services`, params, {}, true, '', true);
	}

	getServiceCategories(organizationId) {
		return this.apiService.get(`organizations/${organizationId}/categories`, {}, {}, true, '', true);
	}


	clearCacheOrganizationServices(organizationId, params) {
		return this.apiService.clearCacheForRequest(`organizations/${organizationId}/services`, params, {}, true, '');
	}

}

export default new Services();
