import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { observer } from "mobx-react";

import { PNormal } from "../styleguide/typography";
import { PrimaryButton, PrimaryButtonContainer } from "../buttons/PrimaryButton";
import { colors } from "../styleguide/colors";
import { appointmentHeaderTableStyles } from '../print-styles/AppointmentHeaderTable';
import printEditorStore from "../../stores/printEditorStore";
import { Tooltip, useMediaQuery } from "@mui/material";

import PrintInlineIcon from "../../assets/icons/print.inline.svg";
import backArrowIcon from "./icons/arrow-back.svg";

const FormattedText = styled.div`
  font-size: 13px;
  line-height: 21px;
  word-wrap: break-word;

  table td {
    vertical-align: middle;
  }

  p {
    margin-block-start: 13px;
    margin-block-end: 13px;
  }

  strong,
  b {
    font-weight: 700 !important;
  }
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: ${colors.lightPink};
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1190px 1fr;
  padding: 20px 52px;
  box-sizing: border-box;
  z-index: 1000;
  
  @media print {
    position: relative;
    display: block;
    padding: 0;
    width: auto;
    height: auto;

    button {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 50px 40px 40px;
    grid-template-rows: .1fr auto .1fr;
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 480px) {
    padding: 50px 20px 20px;
  }
`;

const Editor = styled.div``;

const Content = styled.div``;

const PrintContent = styled.div`
  display: none;
  
  @media print {
    display: block;
    padding: 1mm;
  }
`;

const Styles = styled.div`
  h1 {
    box-sizing: border-box;
    padding: 32px 0 8px;
    font-size: 32px;
    line-height: 48px;
  }
  
  h2 {
    box-sizing: border-box;
    padding: 32px 0 8px;
    font-size: 25px;
    line-height: 32px;
  }
  
  h3 {
    box-sizing: border-box;
    padding: 32px 0 8px;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    box-sizing: border-box;
    font-size: 17px;
    line-height: 24px;
    padding: 8px 0;
  }
  
  i {
    font-style: italic;
  }
  
  u {
    text-decoration: underline;
  }

  strong {
    font-weight: 500;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    td {
      box-sizing: border-box;
      border: 1px solid ${colors.alto};
      height: 44px;
      padding: 0 12px;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      vertical-align: middle;
      word-break: break-word;
    }

    th {
      font-weight: 500 !important;
      background: unset !important;
    }
  }

  table {
    &.plan {
      td {
        width: 170px;

        &:nth-child(even) {
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }

  table {
    &.cult {
      td,
      th {
        font-weight: 400;
        text-align: center !important;
      }
    }
  }

  table.ppr-table {
    border: 1px double #cecece;

    td, th {
      border: 1px solid #cecece;
      padding: 14px 12px;
    }

    thead th {
      border: 1px solid #cecece;
      border-bottom-color: #c6007f;
      text-align: center !important;
    }

    .ppr-table__service-name {
      color: #888;
    }

    .ppr-table__footer td {
      border-color: transparent;
    }
  }

  table[border="0"] {
    border-color: transparent;

    td, th {
      border-color: transparent;
    }
  }

  figure {
    width: 100%;
    margin: 0;
  }
  
  h3.appointment-title {
    font-size: 30px;
    color: #262626;
    margin: 30px 0 13px;
    padding: 0;
  }
  
  .application-promo {
    p {
      display: flex;
      padding: 0;
    }
  }

  ${appointmentHeaderTableStyles}
`;

const EditorContainer = styled(Styles)`
  position: relative;
  height: 100%;
  overflow-y: auto;

  .ck.ck-editor {
    height: 100%;
    display: flex;
    flex-direction: column;
    
    @media print {
      display: none;
    }
  }
  
  .ck.ck-toolbar {
    padding: 16px 40px;
    border-radius: 8px 8px 0 0 !important;
    border: none;

    .ck.ck-button.ck-on, a.ck.ck-button.ck-on {
      color: #c6007f;
      background: ${rgba('#c6007f', 0.1)};
    }
    .ck .ck-insert-table-dropdown-grid-box.ck-on {
      border-color: #c6007f;
    }
  }
  
  .ck.ck-editor__main {
    overflow-y: auto;
  }
  
  .ck.ck-editor__editable {
    padding: 32px 40px 60px;
    border: none;
    border-radius: 0 0 8px 8px !important;
    box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.03);
  }

  .ck.ck-editor__editable .image, .ck.ck-editor__editable .image-inline {
      img {
          width: 100%;
      }
  }

  @media print {
    height: auto;
    overflow-y: visible;
    
    .ck.ck-editor {
      display: none;
    }
  }
`;

const CustomButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 10px;
    right: 40px;

    @media print {
        display: none;
    }
    @media screen and (max-width: 768px) {
      left: 40px;
    }
    @media screen and (max-width: 480px) {
      left: 20px;
    }
`;

const PrintButtonContainer = styled(PrimaryButtonContainer)`
  width: 80px;
  height: 50px;
  cursor: pointer;
    
  --svg-stroke: #fff;

  @media print {
    display: none;
  }
  @media screen and (max-width: 768px) {
    width: auto;
    height: 40px;
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  border: none;
  align-self: flex-start;
  background: none;
  cursor: pointer;
  color: #262626;
  opacity: 0.6;
  margin-top: 36px;
  text-align: left;
  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }
`;

const PRINT_MODE_CLASS = 'in-print-mode';

export const PrintEditor = observer(() => {
  const isMobileDevice = useMediaQuery('(max-width: 768px)');
  const $content = React.useRef();
  const $printContent = React.useRef();

  const editor = React.useRef();

  const handlePrint = React.useCallback(() => {
    if ($printContent.current) {
      $printContent.current.innerHTML = "";
      $printContent.current.innerHTML = editor.current.getData();
      document.body.classList.add(PRINT_MODE_CLASS);

      setTimeout(() => window.print(), 100);
    }
  }, []);

  const handleBack = React.useCallback(() => {
    printEditorStore.close();
    printEditorStore.back();
  }, []);

  React.useEffect(() => {
    const afterPrint = () => {
      if ($printContent.current) {
        $printContent.current.innerHTML = "";
        document.body.classList.remove(PRINT_MODE_CLASS);
      }
    };
    window.addEventListener("afterprint", afterPrint, false);
    return () => window.removeEventListener("afterprint", afterPrint);
  }, []);

  React.useEffect(() => {
    if (printEditorStore.isVisible && printEditorStore.data) {
      // eslint-disable-next-line no-undef
      CKEDITOR.ClassicEditor.create(document.querySelector( '#editor' ), {
        language: 'ru',
        initialData: printEditorStore.data,
        toolbar: {
          items: ["undo", "redo", "heading", "|", "bold", "italic", "underline", "strikethrough", "|", "insertTable", "insertImage", "|", "bulletedList", "numberedList"],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Обычный текст', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Заголовок 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Заголовок 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Заголовок 3', class: 'ck-heading_heading3' }
          ]
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },
        removePlugins: [
          "CKBox",
          "CKFinder",
          "EasyImage",
          "RealTimeCollaborativeComments",
          "RealTimeCollaborativeTrackChanges",
          "RealTimeCollaborativeRevisionHistory",
          "PresenceList",
          "Comments",
          "TrackChanges",
          "TrackChangesData",
          "RevisionHistory",
          "Pagination",
          "WProofreader",
          "MathType",
          "SlashCommand",
          "Template",
          "DocumentOutline",
          "FormatPainter",
          "TableOfContents"
        ]
      }).then(ckeditor => {
        editor.current = ckeditor;
      });
    }
  }, [printEditorStore.isVisible, printEditorStore.data]);

  if (!printEditorStore.isVisible) {
    return null;
  }

  if (!printEditorStore.data) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  const values = <FormattedText dangerouslySetInnerHTML={{ __html: printEditorStore.data }} />;

  const ButtonContainers = () => (
    <CustomButtonsContainer>
      {Object.entries(printEditorStore.customButtons).map(([key, CustomButtonComponent]) => {
        return <CustomButtonComponent key={key} editorRef={editor} />;
      })}
      <Tooltip title="Распечатать">
        <div>
          <PrimaryButton onClick={handlePrint} primaryButtonContainer={PrintButtonContainer}>
            <PrintInlineIcon />
          </PrimaryButton>
        </div>
      </Tooltip>
    </CustomButtonsContainer>
  )

  return (
    <Container id="print-editor">
      <BackButton onClick={handleBack}>
        <img src={backArrowIcon} alt="Back icon"/>
        Вернуться к приему
      </BackButton>
      {isMobileDevice && <ButtonContainers />}
      <EditorContainer>
        <Editor id="editor"/>
        <Content ref={$content} style={{ display: "none" }}>
          {values}
        </Content>
        {!isMobileDevice && <ButtonContainers />}
      </EditorContainer>
      <Styles>
        <PrintContent id="print-content" ref={$printContent} />
      </Styles>
    </Container>
  );
});
