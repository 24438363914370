// @flow
import React, { Fragment, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Box, Paper } from "@mui/material";
import { scrollbars } from "../../../components/chats/style-elements";
import { CorporateHeader } from "../CorporateHeader";
import { CorporateNavigation } from "../../modules/navigation/CorporateNavigation";
import { CorporateScrollTop } from "../../modules/navigation/CorporateScrollTop";

const Wrapper = styled(Paper)(
  ({ theme }) => `
    position: relative;
    box-sizing: border-box;
    height: calc(100vh - 60px);
    overflow-x: auto;
    background: ${theme.palette.background.layout};    
    padding: 30px;
    ${theme.breakpoints.down("lg")} {
      height: calc(100vh - 55px);
      padding: 25px;
    }
    ${theme.breakpoints.down("sm")} {
      height: calc(100vh - 45px);
      padding: 10px;
    }

    ${scrollbars};
  `
);

const NavigationContainer = styled(Box)(
  ({ theme }) => `
    position: fixed;
    width: 290px;
    ${theme.breakpoints.down("lg")} {
      width: 200px;
    }
    ${theme.breakpoints.down("md")} {
      width: 52px;
    }
  `
);

const ContentContainer = styled(Box)(
  ({ theme }) => `
    display: flex;
    gap: 24px;
    padding-left: calc(290px + 24px);
    ${theme.breakpoints.down("lg")} {
      gap: 16px;
      padding-left: calc(200px + 16px);
    }
    ${theme.breakpoints.down("md")} {
      gap: 10px;
      padding-left: calc(52px + 10px);
    }
  `
);

export const CorporatePortalLayout = ({ children }) => {
  const wrapperRef = useRef(null);

  return (
    <Fragment>
      <CorporateHeader targetForScrollTrigger={wrapperRef.current} />
      <Wrapper ref={wrapperRef}>
        <NavigationContainer>
          <CorporateNavigation target={wrapperRef.current} />
        </NavigationContainer>
        <ContentContainer>{children}</ContentContainer>
      </Wrapper>
      <CorporateScrollTop target={wrapperRef.current} />
    </Fragment>
  );
};
