//@flow
import * as React from "react";
import { useStore } from "effector-react";
import useReactRouter from "use-react-router";
import _ from "lodash";

import { formatHistoryTableDate } from "../../domain/services/appointments";
import type { SectionHistory } from "../../domain/entities/SectionHistory";
import { tokenStore } from "../../stores/auth";
import { PNormal } from "../styleguide/typography";
import { HistoryTableLayout } from "./HistoryTableLayout";
import { HistoryTableRow } from "./HistoryTableRow";
import { HistoryCellDate, CellFixed, NumberCell, TextCell } from "./HistoryCell";
import {useApiRequest} from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

export const HistoryTable = (props: {
  appointment_id: number,
  section_id: number,
  sectionHistory?: ?SectionHistory,
  withFixedColumn?: boolean
}) => {
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();

  const sectionHistoryResult = useApiRequest(async () => {
    return await apiv2.appointments.getSectionHistory({
      id: params.appointment_id,
      section_id: props.section_id
    });
  }, [token, params.appointment_id, !props.sectionHistory]);

  if (!sectionHistoryResult.data && !props.sectionHistory) {
    return null;
  }

  const { headers, rows } = sectionHistoryResult.data || props.sectionHistory || {};
  return (
    <HistoryTableLayout
      columns={headers[0].map((header, index) => ({ name: header, fixed: index === 0 }))}>
      {rows.map((row, index) => (
        <HistoryTableRow key={index}>
          {_.map(row, cell => {
            if (cell.type === "date") {
              return (
                <HistoryCellDate isFixed={props.withFixedColumn && CellFixed}>
                  {cell.value && formatHistoryTableDate(cell.value)}
                </HistoryCellDate>
              );
            } else if (cell.type === "number") {
              return (
                <NumberCell>
                  <PNormal modifiers={"left"}>{cell.value}</PNormal>
                </NumberCell>
              );
            } else if (cell.type === "checkbox") {
              return (
                <TextCell>
                  <PNormal modifiers={"left"}>{cell.value.join(", ")}</PNormal>
                </TextCell>
              );
            } else {
              return (
                <TextCell>
                  <PNormal modifiers={"left"}>{cell.value}</PNormal>
                </TextCell>
              );
            }
          })}
        </HistoryTableRow>
      ))}
    </HistoryTableLayout>
  );
};
