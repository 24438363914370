// @flow
import * as React from "react";
import styled from "styled-components";
import printIcon from "./icons/print.svg";
import { DefaultButton } from "../buttons/DefaultButton";
import { Link } from "react-router-dom";

const Icon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${printIcon});
`;

const IconContainer = styled.div`
  margin-left: -2px;
  margin-right: 6px;
`;

export const PrintButton = (props: { to?: string, onClick?: () => mixed }) => (
  <DefaultButton
    {...props}
    overrides={{
      ...(props.to && {
        Container: {
          props: {
            to: props.to,
            as: Link
          }
        }
      }),
      Icon: {
        component: Icon
      },
      IconContainer: {
        component: IconContainer
      }
    }}>
    печать
  </DefaultButton>
);
