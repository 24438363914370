import React from "react";

const Icon = props => {
  const { color = "#979797" } = props;
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49992 1.36841C8.49992 1.09227 8.27606 0.868408 7.99992 0.868408C7.72378 0.868408 7.49992 1.09227 7.49992 1.36841H8.49992ZM7.49992 14.7017C7.49992 14.9779 7.72378 15.2017 7.99992 15.2017C8.27606 15.2017 8.49992 14.9779 8.49992 14.7017H7.49992ZM14.6666 8.53507C14.9427 8.53507 15.1666 8.31122 15.1666 8.03507C15.1666 7.75893 14.9427 7.53507 14.6666 7.53507V8.53507ZM1.33325 7.53507C1.05711 7.53507 0.833252 7.75893 0.833252 8.03507C0.833252 8.31122 1.05711 8.53507 1.33325 8.53507V7.53507ZM7.49992 1.36841V14.7017H8.49992V1.36841H7.49992ZM14.6666 7.53507H1.33325V8.53507H14.6666V7.53507Z"
        fill={color}
      />
    </svg>
  );
};

export default Icon;
