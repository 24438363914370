// @flow

import * as R from "ramda";

export const appendIfNot = <T>(value: T, predicat: T => boolean) => (list: Array<T>): Array<T> => {
  if (!R.find(predicat)(list)) {
    return R.append(value)(list);
  }
  return list;
};
