// @flow
import { useMemo } from "react";
import type { Medicine } from "../../domain/entities/Medicine";
import * as R from "ramda";
import { useDebounce } from 'use-debounce';
import { useApiRequest } from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

export type MedicineSuggest =
  | {
      type: "MEDICINE",
      ...$Exact<Medicine>
    }
  | {
      type: "VARIANT",
      syntheticId: string,
      id: number,
      name: string,
      is_main: boolean,
      parent: Medicine
    };

const transformVariants = item =>
  R.map(
    R.compose(
      R.set(R.lensProp("parent"), item),
      R.set(R.lensProp("type"), "VARIANT")
    ),
    item.variants
  );

const testSuggest = (value: string) => (item: MedicineSuggest) => {
  const itemPass = item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
  const itemParentPass =
    item.parent &&
    item.parent.name &&
    typeof item.parent.name === "string" &&
    item.parent.name.toLowerCase().indexOf(value.toLowerCase()) >= 0;

  if (item.parent) {
    return !!(itemPass || itemParentPass);
  } else return !!itemPass;
};

export const transformToMedicineSuggest = (value: string) =>
  R.compose(
    R.map(item =>
      item.type === "VARIANT"
        ? R.compose(
            R.set(R.lensProp("syntheticId"), `${item.parent.id}-${item.id}`),
            R.over(R.lensProp("name"), name => `${item.parent.name} (${name})`)
          )(item)
        : item
    ),
    R.filter(testSuggest(value)),
    R.flatten,
    R.map(item => (item.variants ? [item, ...transformVariants(item)] : item)),
    R.map(R.set(R.lensProp("type"), "MEDICINE"))
  );

export const MedicinesSuggestProvider = (props: {
  value: string,
  withoutVariant?: boolean,
  children: (medicines: Array<MedicineSuggest>) => React$Node
}) => {
  const { value, children, withoutVariant } = props;
  const [ debouncedValue ] = useDebounce(value, 200);
  const medicinesResult = useApiRequest(async () => {
    return await apiv2.medicines.get(debouncedValue);
  }, [ debouncedValue ]);
  const result = useMemo(() => {
    if (!medicinesResult.data) {
      return [];
    }
    let data = medicinesResult.data;
    if (withoutVariant) {
      data = data.map(item => ({ ...item, variants: null }));
    }
    return transformToMedicineSuggest(debouncedValue)(data);
  }, [debouncedValue, medicinesResult, withoutVariant]);
  return children(result);
};
