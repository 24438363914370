//@flow
import * as React from "react";
import styled from "styled-components";
import moment from "moment";

import { getPatientFullName } from "../../domain/services/patient";
import { READABLE_DATE_FORMAT } from "../../domain/services/field";
import { useAutoFocusRef } from "../../utils/useAutoFocusRef";
import { InputSelectLayout } from "./InputSelectLayout";
import { AddButton } from "../select/AddButton";
import { Option } from "../select/Option";
import { BaseStyledInput } from "../form-elements/BaseStyledInput";
import { useCancelableTimeout } from "../../utils/useCancelableTimeout";
import { useMedicalFileSelect } from "./useMedicalFileSelect";

import type { MedicalFile } from "../../domain/entities/MedicalFile";

const InputWithSelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const MedicalFileSelect = (props: {
  value?: string,
  organizationId?: number,
  onSelect: (item: any) => mixed,
  onAdd: (item: any) => mixed,
  onClear: () => mixed
}) => {
  const { value, setValue, items } = useMedicalFileSelect(props.value);
  const inputRef = useAutoFocusRef();

  const [isSelectShown, setSelectShown] = React.useState(false);

  const createPatientItem = (medicalFile: MedicalFile) =>
    `${getPatientFullName(medicalFile.patient)}, ${moment(medicalFile.patient.birthdate).format(
      READABLE_DATE_FORMAT
    )}, AK №${medicalFile.number}`;

  const addNewPatient = () => {
    cancelOnBlur();
    setSelectShown(false);
    props.onAdd(value);
  };

  const onClickAddButton = () => {
    addNewPatient();
  };

  const onClickSelectItem = item => {
    cancelOnBlur();
    setSelectShown(false);
    setValue(getPatientFullName(item.patient));
    props.onSelect(item);
  };

  const onInputKeyUp = event => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
      addNewPatient();
    }
  };

  const [onBlur, cancelOnBlur] = useCancelableTimeout(() => {
    setValue("");
    props.onClear();
    setSelectShown(false);
  }, 200);

  React.useEffect(() => {
    value.length > 0 && props.value !== value ? setSelectShown(true) : setSelectShown(false);
  }, [value]);

  return (
    <InputWithSelectContainer>
      <BaseStyledInput
        as="input"
        inputRef={inputRef}
        value={value}
        onChange={event => setValue(event.currentTarget.value)}
        onKeyUp={onInputKeyUp}
        onFocus={() => value.length > 0 && setSelectShown(true)}
        onBlur={onBlur}
      />
      {isSelectShown && (
        <InputSelectLayout addButton={<AddButton text={value} onClick={onClickAddButton} />}>
          {(props.organizationId
            ? items.filter(item => item.organization.id === props.organizationId)
            : items
          ).map(item => (
            <Option
              key={item.id}
              text={createPatientItem(item)}
              onClick={() => onClickSelectItem(item)}
            />
          ))}
        </InputSelectLayout>
      )}
    </InputWithSelectContainer>
  );
};
