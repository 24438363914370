// @flow
import { createEvent, createStore } from "effector";
import type { TFeedback_id } from "../../domain/entities/Feedback";

export type FeedbackSections = "forView";

export const openFeedbackForView = createEvent<TFeedback_id>();
export const closeFeedbackSidebar = createEvent<void>();

export const feedbackSidebarSectionStore = createStore<?FeedbackSections>(null);
feedbackSidebarSectionStore
  .on(openFeedbackForView, () => "forView")
  .on(closeFeedbackSidebar, () => null);
