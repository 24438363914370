// @flow
import React from "react";

export const AddingHOC = (props: {
  children: ({
    value: string,
    onChange: string => mixed,
    isAdding: boolean,
    setAdding: boolean => mixed
  }) => React$Node
}) => {
  const [value, onChange] = React.useState("");
  const [isAdding, setAdding] = React.useState(false);

  React.useEffect(() => {
    if (!isAdding) {
      onChange("");
    }
  }, [isAdding]);

  return props.children({ value, onChange, isAdding, setAdding });
};
