import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { FormSection } from "../form/FormSection";
import fileIcon from "../appointment-page/icons/file.svg";

export const Container = styled.div`
  &:last-child > *:last-child {
    border-bottom: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const HeaderContainer = styled.div`
  border: none;
  background: none;
  outline: none;
  width: fit-content;
  height: 55px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 30px 20px 12px 0;
  border: none;
  cursor: auto;
  /* background: url(${fileIcon});
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: right 28px; */

  & h4 {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const ChildrenContainer = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.gray150};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(${props => props.halfFieldsLength}, auto);
  grid-auto-flow: column;
  column-gap: 5px;
`;

export const SummaryFormSection = props => {
  return (
    <FormSection
      {...props}
      expand_by_default
      overrides={{
        ArrowButton: {
          component: () => null
        },
        Container: {
          component: Container
        },
        HeaderContainer: {
          component: HeaderContainer,
          props: {
            onClick: null,
            as: "div",
            "aria-label": null
          }
        },
        ChildrenContainer: {
          component: ChildrenContainer,
          props: {
            halfFieldsLength: props.halfFieldsLength
          }
        }
      }}
    />
  );
};
