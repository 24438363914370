// @flow
import type { TAppointment_id } from "../domain/entities/Appointment";
import type { Store } from "effector";
import * as R from "ramda";

export const createAppointmentStore = <T>(appointment_id: TAppointment_id, defaultValues: T) => (
  store: Store<{
    [appointment_id: TAppointment_id]: ?T
  }>
): Store<T> => {
  const derivedStore = store.map<T>(store => (store && store[appointment_id]) || defaultValues);
  store.on(derivedStore, (state, derivedStoreValue) =>
    R.set(R.lensProp("" + appointment_id), derivedStoreValue)(state)
  );
  return derivedStore;
};
