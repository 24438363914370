export const arrayToEntriesById = (arr, key = "id") =>
  Object.fromEntries(arr.map(item => [item[key], item]));

export const entriesToArrayById = (entries, convertId, key = "id") =>
  Object.entries(entries).map(([id, item]) => ({
    [key]: convertId ? convertId(id) : id,
    ...item
  }));

export const uniqueFilter = (value, index, arr) => arr.indexOf(value) === index;

export const declOfNum = (n, titles) => {
    return titles[n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2];
}