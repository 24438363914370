import { ChartComponent } from "../common/charts/ChartComponent";
import reviewStatisticsStore, { transformDiagramDataPercentage, transformTableDataPercentage } from "../../stores/reviewStatisticsStore";
import { Paper } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import _ from "lodash";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import CircularProgress from "@mui/material/CircularProgress";
import organizationsStore from "../../stores/organizationsStore";
import * as yup from "yup";
import { filterValidation } from "../common/widgets-components-common/constants";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = 'widgetNPS';

export const FILTERS_FIELDS_WIDGET_NPS = () => [
  {
    type: 'select',
    name: 'type',
    label: 'Вид графика',
    xs: 12,
    options: [
      {value: 'line', label: 'Линейный'},
      {value: 'bar', label: 'Столбцами'},
      {value: 'table', label: 'Таблицей'},
    ]
  },
  {
    type: 'select',
    name: 'map_names',
    label: 'Источник',
    multiple: true,
    xs: 12,
    options: reviewStatisticsStore.mapNamesOptions
  },
  {
    type: 'checkbox',
    name: 'isCollapse',
    label: 'Объединить выбранные организации (только для графиков)',
    xs: 12,
  },
  {
    type: 'text',
    name: 'label',
    label: 'Название графика',
    xs: 12,
  },
  {
    type: 'select',
    name: 'organization_ids',
    label: 'Организации',
    multiple: true,
    needSelectAll: true,
    valueName: 'id',
    labelName: 'name',
    xs: 12,
    options: organizationsStore.organizations,
  },
];

export const WidgetNPS = observer((props: {
  filtersData: () => {},
  rewriteFilters: () => {},
  keyWidget: String
}) => {

  const styledPaper = {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [filterFields, setFilterFields] = useState({});
  const [tableData, setTableData] = useState([]);
  const [chartsData, setChartsData] = useState();
  const [label, setLabel] = useState('График NPS');
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const validateSchema = yup.object().shape({
    organization_ids: yup.array().required("организации").min(1, "организации")
  });

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
      if (getLocalStorage(NAME_WIDGET + props.keyWidget)) {
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), getLocalStorage(NAME_WIDGET + props.keyWidget));
        localStorage.removeItem(NAME_WIDGET + props.keyWidget);
      }
      const filterDiagramLocaleStorage = getLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''));
      if (filterDiagramLocaleStorage && moment(filterDiagramLocaleStorage.date_end).isBefore(moment())) {
        filterDiagramLocaleStorage.date_end = moment();
      }
      const filters = props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
      if (filters.label) {
        setLabel(filters.label);
      }
      setFiltersDataDiagram(filters)
      setFilterFields([...FILTERS_FIELDS_WIDGET_NPS()]);
      setIsInitialized(true);
    })()
  }, []);

  useEffect(() => {
    (async () => {
      if (_.size(filtersDataDiagram)) {
        setLoading(true);
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), filtersDataDiagram);
        if (filtersDataDiagram.label) {
          setLabel(filtersDataDiagram.label);
        }
        const response = await reviewStatisticsStore.getNpsDiagram(filtersDataDiagram);
        if (response.error) {
          filterValidation({ validateSchema, filtersDataDiagram, setErrors });
        }
        setChartsData(transformDiagramDataPercentage({data: response, valueName: 'nps', interval: filtersDataDiagram.interval || 'day', isCollapseData: filtersDataDiagram.isCollapse}));
        setTableData(transformTableDataPercentage(response, filtersDataDiagram.organization_ids, 'nps', organizationsStore.organizations));
        setLoading(false);
      }
    })()
  }, [filtersDataDiagram]);

  useEffect(() => {
    if (props.rewriteFilters) {
      setFiltersDataDiagram({...filtersDataDiagram, ...props.rewriteFilters});
    }
  }, [props.rewriteFilters]);

  const submitHandler = (filters, callback) => {
    setErrors([]);
    setFiltersDataDiagram(filters);
    callback();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'organization', //access nested data with dot notation
        header: 'Название организации',
      },
      {
        accessorKey: 'count',
        header: 'nps',
        Cell: ({ row }) => `${row.original.count}%`
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    enableFullScreenToggle: false,
    initialState: {
      density: 'compact',
      pagination: {pageIndex: 0, pageSize: 300}
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  return (
    <Paper style={styledPaper}>
      {filterFields && (
        <LabelWithFiltersModal
          fullWidth={true}
          type={"filter"}
          filtersData={filtersDataDiagram}
          filtersFields={filterFields}
          label={label}
          textButtonFilter={"Применить"}
          submitHandler={submitHandler}
        />
      )}
      {(loading || !isInitialized) && <AbsoluteLoader />}
      {isInitialized && (
        <>
          {filtersDataDiagram.type !== 'table' && chartsData && (
            <ChartComponent
              textButtonFilter="Применить"
              type={filtersDataDiagram.type || 'bar'}
              nameDiagram="ReviewDiagramNPS"
              data={chartsData}
              interval={filtersDataDiagram?.interval || 'day'}
              errors={errors}
            />
          )}
          {filtersDataDiagram.type === 'table' && tableData && (
            <div style={{
              maxHeight: '100%',
              overflow: "auto"
            }} className={'material-table'}>
              <MaterialReactTable table={table} />
            </div>
          )}
        </>
      )}
    </Paper>
  )
});
