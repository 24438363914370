import React, {useEffect} from "react";
import apiv2 from "../../apiv2";

export const PaymentPage = ({ id }) => {
    useEffect(() => {
        (async () => {
            const response = await apiv2.payment.getPayment(id);

            const script = document.createElement("script");
            script.onload = () => {
                const params = new URLSearchParams(location.search)
                //Инициализация виджета. Все параметры обязательные.
                const checkout = new window.YooMoneyCheckoutWidget({
                    customer: {
                        email: params.get('email') || ''
                    },
                    confirmation_token: response.payment_token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
                    return_url: location.origin + '/finish-payment', //Ссылка на страницу завершения оплаты
                    error_callback: function (error) {
                        console.error(error);
                        alert(JSON.stringify(error));
                    }
                });

                //Отображение платежной формы в контейнере
                checkout.render('payment-form')
                    //Метод возвращает Promise, исполнение которого говорит о полной загрузке платежной формы (можно не использовать).
                    .then(() => {
                        //Код, который нужно выполнить после отображения платежной формы.
                    });
            };
            script.src = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";
            document.body.appendChild(script);
        })();
    }, []);


    return <div id="payment-form"></div>;
}
