// @flow
import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { CaptionSmall } from "../styleguide/typography";

import { ScrollContext } from "../scroll-detector/ScrollDetector";

export const Container = styled.th`
  min-width: 162px;
  max-width: 162px;
  height: 81px;
  box-sizing: border-box;
  padding: 14px 20px 18px;
  text-align: left;

  border-right: ${props => !props.disableBorderRight && `solid 1px ${colors.transparentGray}`};
  background: ${colors.white};

  position: sticky;
  left: 0;

  z-index: 10;

  border-bottom: solid 1px ${colors.magenta};
`;

const ValueContainer = styled.div`
  & + & {
    margin-top: 9px;
  }
`;

export const PrescriptionTableHeadCellFixed = (props: {
  values: Array<string>,
  disableBorderRight?: boolean
}) => {
  const { setFixedColumnRef } = useContext(ScrollContext);
  return (
    <Container ref={setFixedColumnRef} disableBorderRight={props.disableBorderRight}>
      {props.values.map((value, id) => {
        return (
          <ValueContainer key={id}>
            <CaptionSmall>{value}</CaptionSmall>
          </ValueContainer>
        );
      })}
    </Container>
  );
};
