// @flow
import React from "react";
import styled from "styled-components";
import { FormSection, HeaderContainer } from "../form/FormSection";
import type { Section } from "../../domain/entities/Section";

const ButtonHeaderContainer = styled(HeaderContainer)`
  min-height: 78px;
  box-sizing: border-box;
  padding: 20px 0 10px;
  align-items: center;
`;

const ChildrenContainer = styled.div``;

type ButtonFormSectionProps = $Diff<
  React$ElementConfig<typeof FormSection>,
  { expand_by_default: $ElementType<Section, "expand_by_default"> }
> & {
  button?: React$Node
};

export const ButtonFormSection = (props: ButtonFormSectionProps) => {
  const { button, ...otherProps } = props;
  return (
    <FormSection
      {...otherProps}
      expand_by_default
      swapArrowAndCheckmark={true}
      overrides={{
        ArrowButton: {
          component: () => button || null
        },
        HeaderContainer: {
          component: ButtonHeaderContainer,
          props: {
            onClick: null,
            as: "div",
            "aria-label": null
          }
        },
        ChildrenContainer: {
          component: ChildrenContainer
        }
      }}
    />
  );
};
