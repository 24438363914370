// @flow
import * as React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import moment from "moment";
import { useStore } from "effector-react";
import * as R from "rambda";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";

import { tokenStore } from "../../stores/auth";
import { PrimaryButton, PrimaryButtonContainer } from "../buttons/PrimaryButton";
import { FieldsetVertical } from "../form/FieldsetVertical";
import { FieldsetInline, InlineContainer, InlineLegendContainer } from "../form/FieldsetInline";
import { DefaultInput } from "../form-elements/DefaultInput";
import { PNormal } from "../styleguide/typography";
import { ButtonRadioGroup } from "../form-elements/ButtonRadioGroup";
import { possibleValuesToButtonGroupItems } from "../appointment-widgets/possibleValuesToButtonGroupItems";
import { READABLE_DATE_FORMAT } from "../../domain/services/field";
import { MedicalFileSelect } from "../input-select/MedicalFileSelect";
import { getPatientFullName } from "../../domain/services/patient";
import {
  formatScheduleTimeForDisplay,
  getAppointmentDurationsByStep,
  getNumAppointmentsByDuration
} from "../../domain/services/schedule";
import type { MedicalFile } from "../../domain/entities/MedicalFile";
import { useOrganizationSelect } from "./useOrganizationSelect";
import { Select } from "../form-elements/Select";
import { PhoneInput } from "../form-elements/PhoneInput";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import type { Doctor } from "../../domain/entities/Doctor";
import type { ScheduleInsertParams } from "../../domain/value-objects/scheduleInsert";
import apiv2 from '../../apiv2';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 42px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
`;

const OverridedPrimaryButtonContainer = styled(PrimaryButtonContainer)`
  width: 256px;
  padding: 0 40px;
`;

const FieldContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    gap: 10px;
    margin-bottom: 10px;
  }
`;

const RadioGroupLegend = styled(InlineLegendContainer)`
  margin-right: 40px;
  @media (max-width: 768px) {
    margin-right: 5px;
  }
`;

const RadioGroupContainer = styled(InlineContainer)`
  margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

const inputOverrides = {
  Container: {
    component: InputContainer
  },
  Input: {
    props: {
      modifiers: "big"
    }
  }
};

const usePatientsPhoneForInsert = () => {
  const [phone, setPhone] = React.useState<string>("");
  const [isPhoneShown, setPhoneShown] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isPhoneShown) setPhone("");
  }, [isPhoneShown]);

  return {
    phone,
    setPhone,
    isPhoneShown,
    setPhoneShown
  };
};

const useOrganizationsForScheduleInsert = (
  organizationFromCell: ?number
): {
  organizationId: number,
  organizationSelect: ?React$Element<any>
} => {
  const organizationSelectProps = useOrganizationSelect();

  if (organizationFromCell) {
    return { organizationId: organizationFromCell, organizationSelect: null };
  }

  if (organizationSelectProps.items && Object.keys(organizationSelectProps.items).length === 1) {
    const items = R.values(organizationSelectProps.items);
    return { organizationId: items[0].id, organizationSelect: null };
  }

  const select = <Select {...organizationSelectProps} optionsModifiers={['overflowScroll']} />;

  return { organizationId: parseInt(organizationSelectProps.active), organizationSelect: select };
};

export const ScheduleInsertForm = (props: {
  date: string,
  time: ?string,
  doctor: ?Doctor,
  closestTime: ?string,
  scheduleStep: number,
  organization: ?number,
  afterSubmit: ScheduleInsertParams => mixed
}) => {
  const IS_OUT_OF_SCHEDULE = !props.time;

  const token = useStore(tokenStore);

  const { phone, setPhone, isPhoneShown, setPhoneShown } = usePatientsPhoneForInsert();
  const { organizationId, organizationSelect } = useOrganizationsForScheduleInsert(
    parseInt(props.organization)
  );

  const [duration, setDuration] = React.useState<string>("");
  const [medicalFile, setMedicalFile] = React.useState<?MedicalFile>();
  const [patientName, setPatientName] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  const [isShowDropDownPossibleDuration, setIsShowDropDownPossibleDuration] = useState(false);
  const [displayingPossibleDurations, setDisplayingPossibleDurations] = useState([]);

  const onSelectPatient = (medicalFile: MedicalFile) => {
    setMedicalFile(medicalFile);
    setPatientName(getPatientFullName(medicalFile.patient));

    setPhoneShown(false);
  };

  const onAddPatient = (name: string) => {
    setPatientName(name);
    setPhoneShown(true);

    setMedicalFile(null);
  };

  const onClearPatientField = () => {
    setMedicalFile(null);
    setPatientName("");
    setPhoneShown(false);
  };

  let maxDuration = 240;

  if (props.closestTime) {
    const timeFormat = "HH:mm:ss";
    const timeMoment = moment(props.time, timeFormat);
    const closestTimeMoment = moment(props.closestTime, timeFormat);
    const maxTimeDifferenceInMinutes = closestTimeMoment.diff(timeMoment, "minutes");
    maxDuration =
      maxTimeDifferenceInMinutes <= maxDuration ? maxTimeDifferenceInMinutes : maxDuration;
  }

  const possibleDurations = getAppointmentDurationsByStep(props.scheduleStep, maxDuration);

  useEffect(() => {
    if (possibleDurations.length === 1) {
      setDuration(possibleDurations[0]);
    }
    if (displayingPossibleDurations.length < 4 && possibleDurations.length > 4) {
      setIsShowDropDownPossibleDuration(true);
    }
  }, [possibleDurations]);

  useEffect(() => {
    if (isShowDropDownPossibleDuration) {
      setDisplayingPossibleDurations(possibleDurations.slice(0, 4));
    } else {
      setDisplayingPossibleDurations(possibleDurations);
    }
  }, [isShowDropDownPossibleDuration]);

  const submitForm = async () => {
    if (!token || !organizationId) {
      return;
    }
    const scheduleValues = medicalFile
      ? {
          organization_id: organizationId,
          medical_file_id: medicalFile.id,
          medical_file: medicalFile,
          date: props.date,
          time: props.time,
          num_appointments: IS_OUT_OF_SCHEDULE
            ? 1
            : getNumAppointmentsByDuration(duration, possibleDurations),
          comment: comment
        }
      : {
          organization_id: organizationId,
          patient_full_name: patientName,
          phone_number: phone,
          date: props.date,
          time: props.time,
          num_appointments: IS_OUT_OF_SCHEDULE
            ? 1
            : getNumAppointmentsByDuration(duration, possibleDurations),
          comment: comment
        };

    if (props.doctor) {
      if (props.doctor.id || props.doctor.id === 0) {
        // $FlowFixMe
        scheduleValues.doctor_id = props.doctor.id;
      }
    }
    await apiv2.scheduleInserts.postScheduleInserts(scheduleValues);
    props.afterSubmit(scheduleValues);
  };

  const getDoctorOrganization = () => {
    const doctorOrganizations = props.doctor ? props.doctor.organizations : null;

    if (doctorOrganizations && doctorOrganizations.length) {
      return doctorOrganizations.find(organization => organization.id === parseInt(organizationId));
    } else {
      return null;
    }
  };

  const button = (
    <PrimaryButton
      disabled={
        !organizationId ||
        !(medicalFile || patientName) ||
        (!IS_OUT_OF_SCHEDULE && !duration) ||
        (isPhoneShown && !phone)
      }
      primaryButtonContainer={OverridedPrimaryButtonContainer}
      onClick={submitForm}>
      Сохранить
    </PrimaryButton>
  );

  const doctorOrganization = getDoctorOrganization();

  return (
    <form>
      {props.doctor ? (
        <FieldContainer>
          <FieldsetInline legend={"Врач:"}>
            <PNormal as="span" modifiers={"left"}>
              {getLastNameWithInitials(props.doctor)}
            </PNormal>
          </FieldsetInline>
          {doctorOrganization ? (
            <FieldsetInline legend={"Клиника:"}>
              <PNormal as="span" modifiers={"left"}>
                {doctorOrganization.name}
              </PNormal>
            </FieldsetInline>
          ) : null}
        </FieldContainer>
      ) : null}

      <FieldContainer>
        <FieldsetInline legend={"Дата приема:"}>
          <PNormal as="span" modifiers={"left"}>
            {moment(props.date).format(READABLE_DATE_FORMAT)}
          </PNormal>
        </FieldsetInline>
        {props.time ? (
          <FieldsetInline legend={"Время приема:"}>
            <PNormal as="span" modifiers={"left"}>
              {formatScheduleTimeForDisplay(props.time)}
            </PNormal>
          </FieldsetInline>
        ) : (
          <PNormal as="span" modifiers={"left"}>
            Прием вне расписания
          </PNormal>
        )}
      </FieldContainer>

      {!IS_OUT_OF_SCHEDULE && (
        <FieldsetInline
          legend={"Длительность приема:"}
          overrides={{
            InlineContainer: {
              component: RadioGroupContainer
            },
            InlineLegendContainer: {
              component: RadioGroupLegend
            }
          }}
        >
          <Grid container flexDirection="column">
            <ButtonRadioGroup
              checked={duration}
              name={""}
              items={possibleValuesToButtonGroupItems(displayingPossibleDurations)}
              onChange={newValue => setDuration(newValue || "")}
            />
            {isShowDropDownPossibleDuration && (
              <Typography
                variant="caption"
                sx={{ cursor: "pointer", color: colors.magenta, paddingTop: '.5rem' }}
                onClick={() => setIsShowDropDownPossibleDuration(false)}
              >
                Показать все
              </Typography>
            )}
          </Grid>
        </FieldsetInline>
      )}

      {organizationSelect && (
        <FieldsetVertical legend={"Организация"}>{organizationSelect}</FieldsetVertical>
      )}

      <FieldsetVertical legend={"Пациент"}>
        <MedicalFileSelect
          value={patientName}
          organizationId={organizationId}
          onSelect={onSelectPatient}
          onAdd={onAddPatient}
          onClear={onClearPatientField}
        />
      </FieldsetVertical>

      <FieldsetVertical legend={"Комментарий"}>
        <DefaultInput value={comment} handleChange={setComment} overrides={inputOverrides} />
      </FieldsetVertical>

      {isPhoneShown && (
        <FieldsetVertical legend={"Телефон"}>
          <PhoneInput value={phone} handleChange={setPhone} overrides={inputOverrides} />
        </FieldsetVertical>
      )}

      <ButtonContainer>{button}</ButtonContainer>
    </form>
  );
};
