import styled, { css } from "styled-components";

import { colors } from "../../../styleguide/colors";

export const Container = styled.div`
  position: relative;
  margin-right: 18px;
`;

export const Breadcrumb = styled.div`
  position: relative;
  padding-left: 18px;
  font-size: 12px;
  line-height: 23px;
  color: ${colors.black};
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 8px;
  }

  ${({ active }) =>
    !active &&
    css`
      &:before {
        position: absolute;
        left: 4px;
        top: 7px;
        content: "";
        height: calc(100% + 14px);
        background: ${colors.gray200};
        width: 1px;
      }
    `}

  ${({ active }) =>
    active &&
    css`
      font-size: 17px;
      opacity: 1;

      &:not(:first-child) {
        margin-top: 14px;
      }
    `}
`;

export const Dot = styled.div`
  position: absolute;
  left: 0;
  top: 7px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: ${colors.gray200};

  ${({ active }) =>
    active &&
    css`
      background: ${colors.magenta};
    `}
`;
