//@flow
import React from "react/index";
import { PSmall } from "../components/styleguide/typography";

interface IGetConcatRequiredFieldsProps {
  notValidFields: { name: string }[];
  showError: boolean;
  modifier?: "1" | "2";
}

/** Получить объединенное текстовое значение для валидирования полей формы */
export const getConcatRequiredFields: React.FC<IGetConcatRequiredFieldsProps> = ({
  notValidFields,
  showError,
  modifier = "1"
}) => {
  if (!showError) {
    return null;
  }

  let text = "Обязательные поля: ";
  notValidFields.forEach((item, index) => {
    text += index === 0 ? item.name : ", " + item.name;
  });

  return (
    <PSmall modifiers={modifier} id={"required_field"}>
      {text + "."}
    </PSmall>
  );
};
