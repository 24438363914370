// @flow
import * as React from "react";

import { DefaultInput } from "./DefaultInput";
import styled from "styled-components";
import { useRef, useState } from "react";
import { colors } from "../styleguide/colors";
import ImagePlaceholderIcon from "./icons/image-placeholder.inline.svg";

const ImageInputContainer = styled.div`
  width: 0;

  input {
    width: 0;
    height: 0;
    padding: 0;
  }
`;

const ImageField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextBlock = styled.div`
  font-size: 12px;
  color: ${colors.gray700};
  margin-top: 12px;
`;

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${colors.gray050};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  svg {
    display: ${props => (props.isFilled ? "none" : "block")};
  }
  
  input {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

const DeleteText = styled(TextBlock)`
  color: ${colors.watermelon};
  cursor: pointer;
`;

export const ImageInput = (props: {
  label?: string,
}) => {
  const [valueInput, setValueInput] = useState("");
  const imageInput = useRef(null);

  const setValue = (file) => {
    if (typeof file === 'object') {
      const reader = new FileReader();
      reader.onload = function(){
        setValueInput(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setValueInput(file);
    }
  }
  if (props.value && props.value !== valueInput) {
    setValue(props.value);
  }

  const clearData = () => {
    setValueInput("");
    props.handleChange("");
  }

  const handleChange = () => {
    const file = imageInput.current.files[0];
    if (!file) {
      return clearData();
    }
    var reader = new FileReader();
    reader.onload = function(){
      setValueInput(reader.result);
    };
    reader.readAsDataURL(file);
    if (props.handleChange) {
      props.handleChange(file);
    }
  }

  return (
    <ImageField>
      <ImageWrapper className="wrapper-image-input" isFilled={!!valueInput} onClick={() => imageInput.current.click()}>
        {valueInput && (
          <img src={valueInput} alt={""} />
        )}
        <DefaultInput
          inputRef={imageInput}
          type="file"
          accept="image/*"
          handleChange={handleChange}
          overrides={{
            Container: {
              component: ImageInputContainer,
            }
          }}
        />
        <ImagePlaceholderIcon />
      </ImageWrapper>
      {props.label && !valueInput && (
        <TextBlock>{props.label}</TextBlock>
      )}
      { valueInput && (
        <DeleteText onClick={clearData}>Удалить фото</DeleteText>
      )}
    </ImageField>
  );
};
