// @flow
import React from "react";

export const GroupEditProvider = (props: {
  defaultText: string,
  children: ({
    setIsEdit: boolean => mixed,
    isEdit: boolean,
    value: string,
    onChange: string => mixed
  }) => React$Node
}) => {
  const { defaultText, children } = props;
  const [value, setValue] = React.useState(defaultText);
  const [isEdit, setIsEdit] = React.useState(false);

  React.useEffect(() => {
    setValue(defaultText);
  }, [defaultText]);

  return children({
    value,
    onChange: setValue,
    isEdit,
    setIsEdit
  });
};
