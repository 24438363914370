// @flow
import React from "react";
import styled from "styled-components";
import removeIcon from "./icons/remove.svg";
import removeHoverIcon from "./icons/remove-hover.svg";
import { CommonHintedButton, Container } from "./CommonHintedButton";

const Icon = styled.div`
  width: 16px;
  height: 18px;
  background: url(${removeIcon});
  background-size: cover;

  ${Container}:hover & {
    background: url(${removeHoverIcon});
    background-size: cover;
  }
`;

type DeleteButtonProps = {
  ...React$ElementConfig<typeof CommonHintedButton>,
  withoutTitle?: boolean,
  icon?: React$Node,
};

export const DeleteButton = (props: DeleteButtonProps) => {
  return (
    <CommonHintedButton
      {...props}
      overrides={{
        Icon: {
          component: props.icon || Icon
        },
        Text: {
          props: {
            children: "Удалить",
            style: props.withoutTitle && {
              display: "none"
            }
          }
        }
      }}
    />
  );
};
