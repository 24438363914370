import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Examinations {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api/decision-support/examinations', null, null, API_URL);
  }

  get({ search, organizationId }) {
    return this.apiService.get('', {search, organization_id: organizationId}, {}, true, "", false, true);
  }

  propose(name) {
    return this.apiService.post('propose', {}, {name});
  }
}

export default new Examinations();
