import React from "react";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

export function PieChartMUI(props: {
  data: any,
  width?: number,
  height?: number,
  hiddenLegend?: boolean,
}) {

  const hidden = props.hiddenLegend !== undefined ? props.hiddenLegend : true;
  const data = props.data || [];
  const sizing = {
    margin: { bottom: 0, top: 0, right: 0, left: 0 },
    width: props.width || 300,
    height: props.height || 300,
  };
  const total = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    const percent = params.value / total;
    return `${(percent * 100).toFixed(0)}%`;
  };

  return (
    <PieChart
      series={[
        {
          data,
          arcLabel: getArcLabel,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontSize: 14,
        },
      }}
      {...sizing}
      tooltip={{ trigger: "item", slotProps: {
          popper: {
            placement: "auto",
          }
        }}}
      slotProps={{
        legend: {
          hidden: hidden,
          direction: 'row',
          position: {
            vertical: 'bottom',
            horizontal: 'left'
          },
        }
      }}
    />
  )
}
