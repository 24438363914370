// @flow
import * as React from "react";
import { Link } from "react-router-dom";
import {Container, Title, ContainerShadow} from "./checkupsStyled"
import styled from "styled-components";

const LinkQuest = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const CheckupsPageThanks = () => {
  return (
    <Container>
      <ContainerShadow>
        <Title>Данные успешно сохранены</Title>
        <LinkQuest to={'/quest'}>Вернуться</LinkQuest>
      </ContainerShadow>
    </Container>
  );
};
