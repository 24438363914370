// @flow
import * as React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import ArrowIcon from "./icons/arrow-long-right.svg";

const Button = styled.button`
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 100%;
  background-color: ${colors.lightMagenta};

  cursor: pointer;
  outline: none;

  position: fixed;
  right: 56px;
  bottom: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 0;

  @media (max-width: 768px) {
    right: 15px;
    bottom: 15px;
  }
`;

const Arrow = styled.div`
  background: url(${ArrowIcon}) no-repeat;
  background-size: contain;
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
`;

export const ScrollToTopButton = () => (
  <Button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
    <Arrow />
  </Button>
);
