import styled from "styled-components";
import { rgba } from "polished";

import { colors } from "../../styleguide/colors";

import { Close } from "./ServicesTree.styled";

export const Container = styled.div`
  margin-top: 24px;
  position: relative;
`;

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 19px 44px 18px 18px;
  font-size: 17px;
  background: ${colors.white};
  border: 2px solid ${colors.gray100};
  border-radius: 4px;
  outline: none;

  ::placeholder {
    color: ${rgba(colors.black, 0.2)};
  }

  &:focus {
    border-color: ${colors.gray300};
  }
`;

export const Reset = styled(Close)`
  width: 14px;
  height: 14px;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
`;
