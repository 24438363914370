// @flow
import { useStore } from "effector-react";
import type { Examination } from "../../domain/entities/Examination";
import { medicalFileStore } from "../appointment-switch-page/medicalFileStore";
import { useDebounce } from 'use-debounce';
import { useApiRequest } from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

export const ExaminationsSuggestProvider = (props: {
  value: string,
  children: (examinations: Array<Examination>) => React$Node
}) => {
  const { value, children } = props;
  const [ debouncedValue ] = useDebounce(value, 200);
  const medicalFile = useStore(medicalFileStore);
  const examinationsResult = useApiRequest(async () => {
    return await apiv2.examinations.get({ search: debouncedValue, organizationId: medicalFile.organization.id });
  }, [ debouncedValue ]);
  return children(examinationsResult.data || []);
};
