import { rgba } from "polished";
import styled, { css } from "styled-components";
import { colors } from "../../styleguide/colors";

import plusIcon from "../icons/plus.inline.svg";
import trashIcon from "../icons/trash.inline.svg";
import { withModifiers } from "with-modifiers";

export const hide = css`
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`;

export const Container = styled.div``;

export const Add = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 10px 8px 10px 40px;
  font-size: 15px;
  line-height: 24px;
  color: ${colors.silver};
  border-radius: 8px;

  ${hide}

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          padding-left: 8px;
        `
      : css`
          cursor: pointer;

          &:hover {
            color: ${colors.gray600};
            background: ${colors.gray900};
          }
        `}
`;

export const InputContainer = styled.div`
  position: relative;

  ${hide}
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 10px 24px 10px 24px;
  background: ${colors.gray900};
  line-height: 24px;
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 15px;
  line-height: 24px;

  &::placeholder {
    font-size: 15px;
    line-height: 24px;
    color: ${rgba(colors.black, 0.2)};
  }
`;

export const Plus = styled(plusIcon)`
  position: absolute;
  top: calc(50% - 1px);
  left: 13px;
  transform: translateY(-50%);

  ${Add}:hover & {
    path {
      fill: ${colors.gray600};
    }
  }
`;

export const ServiceRow = styled.div`
  display: flex;
  position: relative;
  padding-right: 73px;
  justify-content: ${props => (props.isJustifyLeft ? "left" : "space-between")};
  align-items: center;
  gap: 4px;

  @media (max-width: 768px) {
    flex-direction: ${props => (props.isInline ? "row" : "column")};
    gap: ${props => (props.isInline ? "0" : "16px")};
    align-items: ${props => (props.isInline ? "center" : "flex-start")};
  }
`

export const ServiceItem = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0 10px 8px;
  border-radius: 8px;
  font-size: 15px;
  line-height: 23px;

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
        `
      : css`
          cursor: pointer;

          &:hover {
            background: ${colors.gray900};
          }
        `}

  ${({ isAppointment }) =>
        isAppointment &&        
        css`
          font-size: 17px;
          padding-bottom: 11px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom: 1px ${colors.transparentGray} solid;
        `}
`;

export const Remove = styled(trashIcon)`
  position: absolute;
  top: 50%;
  right: -2px;
  padding: 14px 14px;
  transform: translateY(-50%);
  cursor: pointer;

  @media (min-width: 769px) {
    visibility: hidden;
    ${ServiceItem}:hover & {
      visibility: visible;
    }
  }

  &:hover {
    path {
      fill: ${colors.black};
    }
  }
`;

export const SuggestList = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 4px);
  left: 8px;
  width: 100%;
  max-width: 736px;
  max-height: 281px;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0px 2px 17px rgba(91, 91, 91, 0.25);
  overflow-y: auto;
  z-index: 2;

  @media (max-width: 768px) {
    left: 0;
    top: calc(100% + 6px);
  }
  
  ${({ empty }) =>
    empty &&
    css`
      padding: 55px 110px;
      @media (max-width: 768px) {
        padding: 20px;
      }
    `}
`;

export const SuggestItem = withModifiers({
  active: () => `
        background: ${colors.gray050};
    `
})(
  modifier => styled(ServiceItem)`
  display: flex;
  justify-content: flex-start;
  padding: 10px 16px 10px 16px;
  border-radius: 0;
  cursor: pointer;

  &:hover {
    background: ${colors.gray900};
  }
    
  ${modifier}
`);

export const ServiceName = styled.span`
  overflow-wrap: anywhere;
  small {
    display: block;
    font-size: 12px;
    color: ${colors.darkGray};
  }
`
export const ServiceCode = styled.div`
  align-self: flex-start;
  box-sizing: border-box;
  padding: 5px 3px;
  margin-right: 12px;
  border: 1px solid ${colors.magenta};
  border-radius: 2px;
  background: ${colors.lavenderBlush};
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: ${colors.magenta};

  ${({ empty }) =>
    empty &&
    css`
      text-align: center;
    `}

  ${SuggestItem} & {
    left: 16px;
  }
`;

export const EmptyMessage = styled.div`
  max-width: 516px;
  font-size: 17px;
  line-height: 23px;
  color: ${colors.gray700};
  text-align: center;
  @media (max-width: 768px) {
    max-width: max-content;
  }
`;

export const AllPriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 33px;
  padding-right: 73px;
  font-size: 15px;
  font-weight: 500;
  gap: 8px;
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

export const FlexBlockService = styled.div`
  max-width: 575px;
  display: flex;
  gap: 20px;
  align-items: center;
`;
