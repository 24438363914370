export const AvailableApplicationFlavors = {
	DEFAULT: 'default',
	TELEMED: 'telemed',
	SUNRISE: 'sunrise',
};

export const getApplicationFlavor = () => process.env.APP_FLAVOR || AvailableApplicationFlavors.DEFAULT;

export const getValueByFlavor = (valuesMap) => {
	const appFlavour = getApplicationFlavor();
	const value = valuesMap[appFlavour];
	if (value !== undefined) {
		return value;
	}
	return valuesMap[AvailableApplicationFlavors.DEFAULT];
};
