// @flow
import * as React from "react";
import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea";
import o, { type ExtractOverridesProps } from "react-overrides";
import { colors } from "../styleguide/colors";

import { BaseStyledInput } from "./BaseStyledInput";

export const StyledTextarea = styled(BaseStyledInput)`
  min-height: 60px;
  resize: none;
  line-height: 1.35;
  box-sizing: border-box;
  padding: 18px;

  color: ${props => (props.disabled ? colors.gray300 : colors.black)};
  ${props => props.disabled && "cursor: pointer;"}
`;

const OverridableProps = {
  StyledTextarea
};

export const Textarea = (props: {|
  value: string,
  tabIndex?: number,
  placeholder?: string,
  isDisabled?: boolean,
  handleChange?: string => mixed,
  handleFocus?: string => mixed,
  handleBlur?: any => mixed,
  refTextarea?: React.ElementRef<*>,
  error?: [string, boolean],
  overrides?: ExtractOverridesProps<typeof OverridableProps>
|}) => {
  return (
    <StyledTextarea
      {...o}
      tabIndex={props.tabIndex}
      ref={props.refTextarea}
      as={TextareaAutosize}
      error={props.error}
      className={props.error && 'error-field'}
      modifiers={[props.error && "error"]}
      async={true}
      placeholder={props.placeholder}
      value={props.value}
      disabled={props.isDisabled}
      onChange={event => props.handleChange && props.handleChange(event.currentTarget.value)}
      onFocus={event => props.handleFocus && props.handleFocus(event.currentTarget.value)}
      onBlur={event => props.handleBlur && props.handleBlur(event)}
    />
  );
};
