import styled from "styled-components";
import { Box, Paper } from "@mui/material";
import { colors } from "../styleguide/colors";

export const ReportsPageContainer = styled.div`
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  position: relative;

  .MuiTabs-root {
    background: ${colors.white};
    border-bottom: 1px solid ${colors.transparentGray};
    .MuiTabs-scrollButtons.Mui-disabled {
      opacity: 0.3;
    }
  }
`;

export const ReportsTabContent = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

export const ReportsCalculationWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 15px;
`;

export const ReportsTableWrapper = styled(Paper)`
  .MuiPaper-root {
    box-shadow: none;
  }
`;
