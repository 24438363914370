import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useStore } from "effector-react";
import { medicalFileStore } from "../appointment-switch-page/medicalFileStore";

import { rgba } from "polished";
import { FolderIcon } from "./FolderIcon";
import { useClickOutside } from "../../utils/useClickOutside";
import { getAbsolutePosition } from "../../utils/getAbsolutePosition";
import { usePortal } from "../../utils/usePortal";
import { useGetFolders } from "./useGetFolders";
import { tokenStore } from "../../stores/auth";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import { openModal } from "../../stores/modals/appointmentMoveModalStore";
import apiv2 from '../../apiv2';

const ButtonContainer = styled.div`
  position: relative;

  button {
    outline: none;
  }
`;

const ButtonsContainer = styled.div`
  min-width: 199px;
  position: absolute;
  display: ${props => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 2px 17px rgba(91, 91, 91, 0.259752);
  z-index: 101;
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;

  button {
    font-size: 13px;
    line-height: 20px;
    color: #262626;
    padding: 12px 20px;
    white-space: nowrap;
    text-decoration: none;
    background: #fff;
    border-radius: 0;
    border: none;
    text-align: left;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid #cecece;
    }

    &:visited {
      color: #262626;
    }

    &:hover {
      color: #c6007f;
      background: ${rgba("#ebebeb", 0.3)};
    }
  }
`;

export const FolderSelect = ({ folderId, appointment, inFolder }) => {
  const wrapperRef = useRef(null);
  const btnIcon = useRef(null);

  const token = useStore(tokenStore);
  const medicalFile = useStore(medicalFileStore);

  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(null);

  const patientId = medicalFile?.patient?.id;

  const { folders } = useGetFolders({
    patientId,
    triggerFetch: isOpen,
    layoutId: appointment.layout.id,
    currentFolderId: folderId || null
  });

  const node = usePortal("calendar-elements-root");

  const dropdownStyles = React.useMemo(() => {
    if (!position) return null;
    return {
      left: position.left,
      top: position.top + 30
    };
  }, [position]);

  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setPosition(isOpen ? getAbsolutePosition(btnIcon.current) : null);
  }, [isOpen]);

  const setFolder = async id => {
    if (id === null) {
      await apiv2.appointments.removeAppointmentFromFolder(folderId, appointment.id);
    } else {
      await apiv2.appointments.moveAppointmentFolder({ folderId: id, appointmentId: appointment.id });
    }

    PatientAppointmentsRefreshTrigger();
  };

  const handleIconClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleFolderCreate = () => {
    openModal({ layoutId: appointment.layout.id, appointmentId: appointment.id, patientId });
  };

  return (
    <ButtonContainer>
      <FolderIcon onClick={handleIconClick} ref={btnIcon} />
      {ReactDOM.createPortal(
        <ButtonsContainer isOpen={isOpen} style={dropdownStyles} ref={wrapperRef}>
          <button type="button" onClick={handleFolderCreate}>
            Новое событие
          </button>

          {inFolder && !appointment.layout.only_with_folder && (
            <button type="button" onClick={() => setFolder(null)}>
              Без события
            </button>
          )}

          {folders &&
            folders.map(folder => (
              <button key={folder.id} type="button" onClick={() => setFolder(folder.id)}>
                {folder.name}
              </button>
            ))}
        </ButtonsContainer>,
        node
      )}
    </ButtonContainer>
  );
};
