import React, { useMemo, useEffect } from "react";
import ReactDOMServer from 'react-dom/server';
import { useAsync } from "react-use";
import useReactRouter from "use-react-router";
import { useStore } from "effector-react";
import moment from "moment";

import { phases } from "../EmbryosManipulations/EmbryosManipulations";
import { VIEW_TYPES, STATUS, statusOptions, colorsOptions } from "../constants";

import { tokenStore } from "../../../stores/auth";

import { getPatientFullName, formatPatientBirthDate } from "../../../domain/services/patient";
import { getLastNameWithInitials } from "../../../domain/services/doctor";

import { formatDateToReadableDateFormat } from "../../../utils/dates";
import { arrayToEntriesById, uniqueFilter } from "../../../utils/arrays";

import { useBeforePrint } from "../../appointment-print/useBeforePrint";
import { useAfterPrint } from "../../appointment-print/useAfterPrint";

import * as S from "./styled/Document.styled";
import printEditorStore, {PrintEditorEvents} from '../../../stores/printEditorStore';
import apiv2 from '../../../apiv2';

const statusOptionsByValue = arrayToEntriesById(statusOptions, "value");
const colorsOptionsByValue = arrayToEntriesById(colorsOptions, "value");

export const EmbryosProtocol = () => {
  const {
    history,
    match: { params }
  } = useReactRouter();

  const token = useStore(tokenStore);

  const searchParams = new URLSearchParams(location.search);
  const needEdit = searchParams.get('needEdit') === 'true';

  const {
    value: { medicalFile, folder, cultEmbryos, cryoEmbryos, documentData, manipulations } = {},
    loading: loading
  } = useAsync(async () => {
    const medicalFile = await apiv2.medicalFiles.getById(params.medical_file_id);

    return {
      medicalFile,
      folder: await apiv2.appointments.getFolder(params.folder_id),
      cultEmbryos: await apiv2.embryos.getEmbryosByFolderId(params.folder_id, VIEW_TYPES.CULTIVATION_AND_FERTILIZATION),
      cryoEmbryos: await apiv2.embryos.getEmbryosByFolderId(params.folder_id, VIEW_TYPES.CRYOPRESERVATIONS),
      documentData: await apiv2.embryos.getEmbryosPrintDocumentData({
        folderId: params.folder_id,
        patientId: medicalFile.patient.id,
        type: "by_order_803"
      }),
      manipulations: await apiv2.embryos.getEmbryosManipulations(params.folder_id)
    };
  });

  const manipulationsByPhase = useMemo(
    () => manipulations && arrayToEntriesById(manipulations, "phase"),
    [manipulations]
  );

  const cultEmbryosFinalAssesmentsByCryoNumberId = useMemo(
    () =>
      cultEmbryos &&
      cultEmbryos.reduce((acc, item) => {
        if (item.cryo_carrier_number) {
          return {
            ...acc,
            [item.cryo_carrier_number]: acc[item.cryo_carrier_number]
              ? [...acc[item.cryo_carrier_number], item.final_assessment]
              : [item.final_assessment]
          };
        }

        return acc;
      }, {}),
    [cultEmbryos]
  );

  useBeforePrint(!loading && !needEdit);

  useAfterPrint(`/appointment/folder/${params.folder_id}/embryos/${params.medical_file_id}`);

  let contentForPrint;

  useEffect(() => {
    if (needEdit && contentForPrint && !printEditorStore.isVisible) {
      const data = ReactDOMServer.renderToString(contentForPrint);
      printEditorStore.setData(data);
      printEditorStore.show();
    }
  }, [loading, contentForPrint]);

  useEffect(() => {
    const afterPrint = () => {
      if (!needEdit) {
        history.replace(`/appointment/folder/${params.folder_id}/embryos/${params.medical_file_id}`);
      }
    };
    window.addEventListener("afterprint", afterPrint, false);
    const unsubscribeBack = printEditorStore.addEventListener(PrintEditorEvents.back, () => {
      history.replace(`/appointment/folder/${params.folder_id}/embryos/${params.medical_file_id}`);
      printEditorStore.clearEventListeners(PrintEditorEvents.back);
    });
    return () => {
      window.removeEventListener("afterprint", afterPrint);
      unsubscribeBack();
    }
  }, []);

  if (loading) {
    return "Загрузка ...";
  }

  console.info("medical file", medicalFile, loading);

  contentForPrint =  (
    <S.Container>
      <S.H2>
        № карты {medicalFile.number} № попытки {documentData.result.attempt_number || "_____"}
      </S.H2>
      <S.TextBlock height={136}>
        {getPatientFullName(medicalFile.patient)}, {formatPatientBirthDate(medicalFile.patient)} г.
        рождения <br />
        Диагноз:{" "}
        {documentData.result.diagnoses ||
          "_________________________________________________________________________________________________________"}{" "}
        <br />
        Предполагаемая программа вспомогательных репродуктивных технологий (искусственной
        инсеминации): <br />
        {documentData.result.program ||
          "____________________________________________________________________________________________________________________"}
      </S.TextBlock>
      <S.Table columnWidth={170}>
        <S.TableBody>
          <S.Row>
            <S.Cell>В естественном цикле</S.Cell>
            <S.CenterCell bold>{documentData.result.plan.natural_cycle && "Да"}</S.CenterCell>
            <S.Cell>Сперма мужа (партнера)</S.Cell>
            <S.Cell bold>{documentData.result.plan.husband_sperm && "Да"}</S.Cell>
            <S.Cell>Ооциты пациентки</S.Cell>
            <S.CenterCell bold>{documentData.result.plan.patient_oocytes && "Да"}</S.CenterCell>
          </S.Row>
          <S.Row>
            <S.Cell>С овариальной стимуляцией</S.Cell>
            <S.Cell bold>{documentData.result.plan.with_ovarian_stimulation && "Да"}</S.Cell>
            <S.Cell>Сперма донора</S.Cell>
            <S.CenterCell bold>{documentData.result.plan.donor_sperm && "Да"}</S.CenterCell>
            <S.Cell>Ооциты донора</S.Cell>
            <S.Cell bold>{documentData.result.plan.donor_oocytes && "Да"}</S.Cell>
          </S.Row>
        </S.TableBody>
      </S.Table>
      <S.Sign doctor={folder.doctor_short_name} />
      <S.H2>Культивирование ооцитов и эмбрионов</S.H2>
      <S.TextBlock height={112}>
        Условия культивирования, инсеминация in vitro <br /> Программа вспомогательных
        репродуктивных технологий:{" "}
        {documentData.result.program ||
          "_____________________________________________________________________________"}
        <br />
        Среда: {documentData.result.culture_medium || "________________"}
      </S.TextBlock>
      <S.Table fixed={false} columnWidth={103}>
        <S.TableBody>
          <S.Row>
            <S.CenterCell>Дата</S.CenterCell>
            <S.CenterCell>
              {[manipulationsByPhase[phases.eco].date, manipulationsByPhase[phases.ixi_pixi].date]
                .filter(date => date)
                .map(date => moment(date).format("DD.MM.YY"))
                .filter(uniqueFilter)
                .join(", ")}
            </S.CenterCell>
            {[
              phases.evaluation_of_zygotes,
              phases.crushing_score,
              phases.estimated_5,
              phases.estimated_6,
              phases.estimated_7,
              phases.moved
            ].map(phase => (
              <S.CenterCell key={phase}>
                {manipulationsByPhase[phase].date &&
                  moment(manipulationsByPhase[phase].date).format("DD.MM.YY")}
              </S.CenterCell>
            ))}
            <S.CenterCell />
            <S.CenterCell />
          </S.Row>
          <S.Row>
            <S.HeaderCell bold center>
              №
            </S.HeaderCell>
            <S.HeaderCell bold center>
              Оценка ооцитов
            </S.HeaderCell>
            <S.HeaderCell bold center>
              Оплодотворение
            </S.HeaderCell>
            <S.HeaderCell bold center>
              Дробление
            </S.HeaderCell>
            <S.HeaderCell bold center>
              5 сутки
            </S.HeaderCell>
            <S.HeaderCell bold center>
              6 сутки
            </S.HeaderCell>
            <S.HeaderCell bold center>
              7 сутки
            </S.HeaderCell>
            <S.HeaderCell bold center>
              Эмбриоперенос
            </S.HeaderCell>
            <S.HeaderCell bold center>
              GR
            </S.HeaderCell>
            <S.HeaderCell bold center>
              Примеча
              <br />
              ние
            </S.HeaderCell>
          </S.Row>
          {cultEmbryos.map(
            (
              {
                attempt_id,
                oocytes_assessment,
                zygote_assessment,
                crushing,
                fifth_day,
                sixth_day,
                seventh_day,
                final_assessment,
                status
              },
              i
            ) => (
              <S.Row key={i}>
                <S.CenterCell>{attempt_id}</S.CenterCell>
                <S.CenterCell>{oocytes_assessment}</S.CenterCell>
                <S.CenterCell>{zygote_assessment}</S.CenterCell>
                <S.CenterCell>{crushing}</S.CenterCell>
                <S.CenterCell>{fifth_day}</S.CenterCell>
                <S.CenterCell>{sixth_day}</S.CenterCell>
                <S.CenterCell>{seventh_day}</S.CenterCell>
                <S.CenterCell>
                  {status === STATUS.TRANSFERRED &&
                    statusOptionsByValue[STATUS.TRANSFERRED].label.toLowerCase()}
                </S.CenterCell>
                <S.CenterCell>{final_assessment}</S.CenterCell>
                <S.CenterCell nowrap>
                  {statusOptionsByValue[status].label.toLowerCase()}
                </S.CenterCell>
              </S.Row>
            )
          )}
        </S.TableBody>
      </S.Table>
      <S.Notes />
      <S.Sign specialization="Эмбриолог" doctor={documentData.result.embryologist} />
      <S.H2>Протокол переноса свежих эмбрионов в полость матки</S.H2>
      <S.Table columnWidth={172}>
        <S.TableBody>
          <S.Row>
            <S.Cell>Дата</S.Cell>
            <S.CenterCell bold>
              {documentData.result.transfer_features.transfer_date &&
                formatDateToReadableDateFormat(documentData.result.transfer_features.transfer_date)}
            </S.CenterCell>
            <S.Cell>День цикла</S.Cell>
            <S.CenterCell bold>{documentData.result.transfer_features.cycle_day}</S.CenterCell>
            <S.Cell>Перенос эмбрионов</S.Cell>
            <S.CenterCell bold>{documentData.result.transfer_features.transfer_count}</S.CenterCell>
          </S.Row>
          <S.Row>
            <S.Cell>Отменен по причине: </S.Cell>
            <S.Cell colSpan={5} bold>
              {documentData.result.transfer_features.reason_for_cancellation}
            </S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell colSpan={6}>Особенности переноса</S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>Пулевые щипцы</S.Cell>
            <S.CenterCell bold>
              {documentData.result.transfer_features.bullet_forceps ? "Да" : "Нет"}
            </S.CenterCell>
            <S.Cell>Смена катетера</S.Cell>
            <S.CenterCell bold>
              {documentData.result.transfer_features.catheter_change ? "Да" : "Нет"}
            </S.CenterCell>
            <S.CenterCell>Повторный перенос</S.CenterCell>
            <S.CenterCell bold>
              {documentData.result.transfer_features.retransfer ? "Да" : "Нет"}
            </S.CenterCell>
          </S.Row>
        </S.TableBody>
      </S.Table>
      <S.Sign specialization="Врач" doctor={documentData.result.doctor} />
      <S.H2>Криоконсервация эмбрионов/ооцитов</S.H2>
      <S.Table>
        <S.TableBody>
          <S.Row>
            <S.Cell>Дата криоконсервации</S.Cell>
            <S.CenterCell bold>
              {[
                manipulationsByPhase[phases.cryopreservation_1].date,
                manipulationsByPhase[phases.cryopreservation_2].date,
                manipulationsByPhase[phases.cryopreservation_3].date
              ]
                .filter(date => date)
                .map(date => moment(date).format("DD.MM.YYYY"))
                .filter(uniqueFilter)
                .join(", ")}
            </S.CenterCell>
            <S.Cell>Число замороженных эмбрионов/ооцитов</S.Cell>
            <S.CenterCell bold>
              {cultEmbryos.filter(({ status }) => status === STATUS.CRIO).length}
            </S.CenterCell>
          </S.Row>
        </S.TableBody>
      </S.Table>
      <S.Table>
        <S.TableBody>
          <S.Row>
            <S.WithoutTopBorderCell colSpan={6}>
              Стадии и морфологическая оценка эмбрионов/ооцитов (по соломинкам) (выделить нужное)
            </S.WithoutTopBorderCell>
          </S.Row>
          {[1, 4, 7].map(i => (
            <S.Row key={i}>
              {[1, 2, 3].map((_, j) => (
                <React.Fragment key={i + j}>
                  <S.Cell>{i + j}.</S.Cell>
                  <S.Cell bold>
                    {cultEmbryosFinalAssesmentsByCryoNumberId[i + j] &&
                      cultEmbryosFinalAssesmentsByCryoNumberId[i + j]
                        .filter(item => item)
                        .join(", ")}
                  </S.Cell>
                </React.Fragment>
              ))}
            </S.Row>
          ))}
          <S.Row>
            <S.Cell colSpan={3}>Время культивирования до криоконсервации, дни</S.Cell>
            <S.Cell colSpan={3} bold>
              {cryoEmbryos
                .map(({ days_of_cultivation_before_cryo }) => days_of_cultivation_before_cryo)
                .filter(item => item)
                .filter(uniqueFilter)
                .join(", ")}
            </S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell colSpan={3}>Криопротектор/Криосреда</S.Cell>
            <S.Cell colSpan={3} bold>
              {cryoEmbryos
                .map(({ cryo_carrier }) => cryo_carrier)
                .filter(item => item)
                .filter(uniqueFilter)
                .join(", ")}
            </S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell colSpan={3}>№ Дьюара/пенала</S.Cell>
            <S.Cell colSpan={3} bold>
              {cryoEmbryos
                .map(({ tank }) => tank)
                .filter(item => item)
                .filter(uniqueFilter)
                .join(", ")}
            </S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell colSpan={3}>Кодировка/цвет</S.Cell>
            <S.Cell colSpan={3} bold>
              {cryoEmbryos
                .map(({ color }) => color)
                .filter(item => item)
                .filter(uniqueFilter)
                .map(color => colorsOptionsByValue[color].label)
                .join(", ")}
            </S.Cell>
          </S.Row>
        </S.TableBody>
      </S.Table>
      <S.Notes />
      <S.Sign
        specialization="Эмбриолог"
        doctor={
          manipulationsByPhase[phases.cryopreservation_1].doctor &&
          getLastNameWithInitials(manipulationsByPhase[phases.cryopreservation_1].doctor)
        }
      />
    </S.Container>
  );

  if (needEdit) {
    return;
  }

  return contentForPrint;
};
