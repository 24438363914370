// @flow
import type { Schedule, ScheduleByDays } from "../entities/Schedule";
import _ from "lodash";
import moment from "moment";
import { getDateString } from "../dates";
import type { DateString } from "../dates";

export const getScheduleInterval = (): { from_date: DateString, till_date: DateString } => {
  const today = moment.utc();
  const past_two_week = today.clone().subtract(2, "w");
  const from_date = getDateString(past_two_week);
  const next_two_week = today.clone().add(2, "w");
  const till_date = getDateString(next_two_week);
  return { from_date, till_date };
};

export const getScheduleIntervalForToday = (): { from_date: DateString, till_date: DateString } => {
  const today = moment.utc();
  const today_date = getDateString(today);
  return { from_date: today_date, till_date: today_date };
};

export const groupScheduleByDate = (
  scheduleArray: Array<Schedule>
): { days: Array<string>, grouped: { [string]: Array<Schedule> } } => {
  const grouped = _.groupBy(scheduleArray, schedule => schedule.date);
  const days = Object.keys(grouped);
  return { days, grouped };
};

export const getNumberOfPatients = (scheduleArray: Array<Schedule>): number => scheduleArray.length;

export const formatScheduleTimeForDisplay = (time: string): string => {
  // HH:mm:ss -> HH:mm
  if (time) {
    return time.substring(0, 5);
  }
  return "";
};

export const isSameOrAfterDate = (date: string) => {
  const today = moment.utc();
  const mDate = moment.utc(date);

  return mDate.isSameOrAfter(today, "d");
};

export const isSameOrAfterDateAndTime = (date: string, time: string) => {
  if (!date || !time) {
    return false;
  }

  const today = moment();
  const mDate = moment(`${date} ${time}`);

  return mDate.isSameOrAfter(today);
};

export const formatScheduleDate = (date: string) => {
  const mDate = moment.utc(date);
  const today = moment.utc();
  const isToday = today.isSame(mDate, "d");
  return mDate.locale("ru").format(`${isToday ? "сегодня" : "dddd"}, D MMMM`);
};

export const findFirstActiveScheduleDayIndex = (scheduleArray: ScheduleByDays): number | void =>
  scheduleArray.findIndex(({ date }) => moment().isSameOrBefore(date, "d"));

export const getAppointmentDurationsByStep = (
  schedule_step: number,
  max_duration: number = 60
): Array<string> => {
  const list = [];
  if (schedule_step > max_duration) {
    return [`${schedule_step} мин.`];
  }
  for (let i = 1; i <= max_duration / schedule_step; i++) {
    list.push(schedule_step * i);
  }
  return list.map((minutes) => {
    const hour = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hour ? `${hour} ч.` : ""} ${remainingMinutes ? `${remainingMinutes} мин.` : ""}`;
  });
};

export const getNumAppointmentsByDuration = (
  durationString: string,
  durationList: Array<string>
): number => {
  return durationList.indexOf(durationString) + 1;
};
