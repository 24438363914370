// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { PSignNormal } from "../styleguide/typography";

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  flex-shrink: 0;
  
  svg {
    max-width: 24px;
    max-height: 24px;
    opacity: 0.6;
  }
`;

const TextWrapper = styled(PSignNormal)`
  opacity: 0.6;
`;

const InfoBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 32px;
  background-color: ${colors.white};
  box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
`;

export const InfoBlock = (props: {
  text: string,
  icon?: React.Node,
}) => {
  return (
    <InfoBlockWrapper>
      { props.icon && (
        <IconWrapper>
          { props.icon }
        </IconWrapper>
      )}
      <TextWrapper>
        { props.text }
      </TextWrapper>
    </InfoBlockWrapper>
  );
};
