// @flow
import React from "react";
import type { AISlice } from "../../domain/entities/AISlice";
import styled from "styled-components";
import { AISliceListItem } from "./AISliceListItem";

const Container = styled.div``;

const ItemWrapper = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

export const AISlicesList = ({ items }: { items: Array<AISlice> }) => {
  return (
    <Container>
      {items.map(item => {
        return (
          <ItemWrapper key={item.id}>
            <AISliceListItem item={item} />
          </ItemWrapper>
        );
      })}
    </Container>
  );
};
