// @flow
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { HeadingH6, InputText, PList } from "../styleguide/typography";
import arrowIcon from "./icons/arrow-down.svg";
import { withModifiers } from "with-modifiers/dist/index";
import { ErrorContainer, ErrorText } from "../styleguide/form";

const Container = withModifiers({
  opened: () => `
        border-radius: 3px 3px 0 0;
    `
})(
  modifier => styled.div`
    position: relative;
    background-color: ${colors.white};
    border-radius: 4px;

    ${modifier};
  `
);

const Button = withModifiers({
  opened: () => `
        border: none;
        border-bottom: solid 1px ${colors.gray100};
        border-radius: 3px 3px 0 0;
        padding: 2px 20px 1px;
        outline: none;
    `
})(
  modifier => styled.button`
    width: 100%;
    height: 56px;
    padding: 0 18px;
    box-sizing: border-box;
    text-align: left;
    border-radius: 4px;
    border: solid 2px ${colors.gray100};

    display: flex;
    align-items: center;

    background: ${colors.white};

    cursor: pointer;

    ${modifier};
  `
);

const ValueContainer = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Icon = withModifiers({
  opened: () => `
        transform: rotate(180deg);
    `
})(
  modifier => styled.div`
    width: 18px;
    height: 18px;

    background: url(${arrowIcon});
    background-size: contain;
    transition: transform 0.2s;

    ${modifier};
  `
);

const IconContainer = styled.div`
  margin-left: 30px;
`;

const OptionsContainer = withModifiers({
  overflowScroll: () => `
        max-height: 240px;
        overflow-y: auto;
    `
})(
  modifier => styled.ul`
    width: 100%;
    box-sizing: border-box;
    padding: 12px 0;
    background-color: ${colors.white};
  
    border-radius: 0 0 3px 3px;
    list-style: none;
  
    pointer-events: auto;
    cursor: auto;
    
    ${modifier}
`);

const BoxShadowContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  padding-top: 56px;
  z-index: 2;
  box-shadow: 0 2px 8px 0 rgba(189, 189, 189, 0.5);
  border-radius: 0 0 3px 3px;
  pointer-events: none;
  cursor: pointer;
`;

const Option = withModifiers({
  active: () => `
    background-color: ${colors.gray050};
  `
})(
  modifier => styled.li`
  height: 33px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  outline: none;
  cursor: pointer;
  user-select: none;
    
  ${modifier}
  
  :hover,
  :focus {
    background-color: ${colors.gray050};
  }
`);

const SelectPListNormal = withModifiers({
  active: () => `
    color: ${colors.magenta};
  `
})(
  modifier => styled(PList)`
  opacity: 0.8;
  ${Option}:hover & {
    color: ${colors.magenta};
  }

  ${Option}:focus & {
    color: ${colors.magenta};
  }
    
  ${modifier}
`);

export type TItems = { [string]: { name: string } };

type TSelectProps<T: TItems> = {
  items: T,
  active?: $Keys<T>,
  onSelect?: ($Keys<T>) => mixed,
  placeholder?: string,
  order?: Array<$Keys<T>>,
  containerStyle?: any,
  style?: any,
  headingStyle?: any,
  arrowStyle?: any,
  error?: string,
  optionsModifiers?: Array<string>,
  showTitles?: boolean
};

const ESC_CODE = 27;
const ENTER_CODE = 13;
const SPACE_CODE = 32;

export const useSelectState = (onSelect?: string => mixed) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [focused, setFocused] = useState<?string>();

  const blurFocus = () => setFocused(undefined);

  const clickBackground = () => setOpened(false);

  const handleKeyboard = e => {
    if (e.keyCode === ESC_CODE) {
      setOpened(false);
    }
    if ((focused && e.keyCode === ENTER_CODE) || (focused && e.keyCode === SPACE_CODE)) {
      onSelect && onSelect(focused);
      setOpened(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", clickBackground);
    window.addEventListener("keyup", handleKeyboard);
    return function cleanup() {
      window.removeEventListener("click", clickBackground);
      window.removeEventListener("keyup", handleKeyboard);
    };
  });

  return { opened, setOpened, setFocused, blurFocus };
};

export const Select = <T: TItems>(props: TSelectProps<T>) => {
  const {
    items,
    active,
    placeholder,
    onSelect,
    order,
    containerStyle,
    style,
    headingStyle,
    arrowStyle,
    optionsModifiers,
    showTitles
  } = props;
  const activeItem = active && items[active];
  const { opened, setOpened, setFocused, blurFocus } = useSelectState(onSelect);
  const optionsContainer = useRef(null);

  const onClick = e => {
    e.preventDefault();
    setOpened(!opened);
  };
  useEffect(() => {
    opened &&
    optionsContainer.current &&
    [...optionsContainer.current.children].find((item) => item.dataset.active === "true") &&
    [...optionsContainer.current.children].find((item) => item.dataset.active === "true").scrollIntoView({ behavior: "auto", block: "center" });
  }, [opened]);

  return (
    <Container
      modifiers={opened && "opened"}
      onClick={e => e.stopPropagation()}
      style={containerStyle}>
      <Button
        role="button"
        aria-haspopup="true"
        modifiers={opened && "opened"}
        onClick={onClick}
        style={style}>
        <ValueContainer>
          {activeItem ? (
            <HeadingH6 style={headingStyle}>{activeItem.name}</HeadingH6>
          ) : (
            <InputText modifiers="placeholder">{placeholder}</InputText>
          )}
        </ValueContainer>
        <IconContainer style={arrowStyle}>
          <Icon aria-hidden="true" modifiers={opened && "opened"} />
        </IconContainer>
      </Button>
      {props.error && (
        <ErrorContainer>
          <ErrorText small>{props.error}</ErrorText>
        </ErrorContainer>
      )}
      {opened && (
        <BoxShadowContainer aria-hidden="true">
          <OptionsContainer ref={optionsContainer} modifiers={optionsModifiers} role="menu">
            {(order || Object.keys(items)).map(itemKey => {
              const item = items[itemKey];
              const onSelectOption = () => {
                onSelect && onSelect(itemKey);
                setOpened(false);
              };
              return (
                <Option
                  modifiers={active === itemKey && "active"}
                  key={itemKey}
                  tabIndex="0"
                  data-active={active === itemKey}
                  aria-label={item.name}
                  role="menuitemradio"
                  onFocus={() => setFocused(itemKey)}
                  onBlur={blurFocus}
                  onClick={onSelectOption}>
                  <SelectPListNormal modifiers={active === itemKey && "active"} title={showTitles ? item.name : ''}>{item.name}</SelectPListNormal>
                </Option>
              );
            })}
          </OptionsContainer>
        </BoxShadowContainer>
      )}
    </Container>
  );
};
