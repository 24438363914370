// @flow
import React from "react";
import styled from "styled-components";
import { AddButton, Icon } from "../buttons/AddButton";
import { Container } from "../buttons/DefaultButton";
import { colors } from "../styleguide/colors";

const NewTableButtonContainer = styled(Container)`
  width: 256px;
  height: 52px;
`;

const NewTableIcon = styled(Icon)`
  background: ${colors.magenta};
`;

const TextContainer = styled.div`
  margin-right: -4px;
`;

export const NewTableButton = (props: { onClick: () => mixed }) => {
  return (
    <AddButton
      onClick={props.onClick}
      overridesDefaultButton={{
        Container: {
          component: NewTableButtonContainer
        },
        Icon: {
          component: NewTableIcon
        },
        TextContainer: {
          component: TextContainer
        }
      }}>
      Создать таблицу
    </AddButton>
  );
};
