// @flow
import * as React from "react";
import styled from "styled-components";
import trashIcon from "./icons/trash-with-lines.svg";
import { DefaultButton } from "./DefaultButton";
import { Container } from "./DefaultButton";

const ExtendedContainer = styled(Container)`
  border-color: transparent !important;

  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${trashIcon});
`;

const IconContainer = styled.div`
  margin-right: 6px;
`;

const TextContainer = styled.div`
  margin-right: 8px;
`;

export const DeleteButton = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  children: ?React.Node
}) => (
  <DefaultButton
    {...props}
    overrides={{
      Container: {
        component: ExtendedContainer
      },
      Icon: {
        component: Icon
      },
      IconContainer: {
        component: IconContainer
      },
      TextContainer: {
        component: TextContainer
      }
    }}
  />
);
