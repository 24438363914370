// @flow
import styled from "styled-components";
import iconFeedback from "./icons/icon-feedback.svg";
import iconFeedbackCompleted from "./icons/icon-feedback-completed.svg";

export const FeedbackIcon = styled.div`
  width: 22px;
  height: 22px;
  background: url(${props => (props.isCompleted ? iconFeedbackCompleted : iconFeedback)});
`;
