import { makeAutoObservable } from "mobx";
import apiv2 from "../apiv2";
import * as moment from "moment";
import {
  READABLE_DATE_FORMAT,
  READABLE_TIME_FORMAT_MOMENT,
  SERVER_TIME_FORMAT
} from "../utils/dates";
import { tokenStore as eff_tokenStore } from "./auth";

const transformData = data => {
  return {
    id: data.id,
    date: moment(data.date).format(READABLE_DATE_FORMAT),
    time: moment(data.time, SERVER_TIME_FORMAT).format(READABLE_TIME_FORMAT_MOMENT),
    patient: data.medical_file.patient,
    doctor: data.doctor,
    organization: data.medical_file.organization.name,
    sample: data.layout.name
  };
};

class FavoritesAppointmentStore {
  appointmentList = [];
  isInitialized = false;

  constructor() {
    eff_tokenStore.watch((state) => {
      if (state) {
        this.init();
      }
    });

    makeAutoObservable(this);
  }

  init() {
    this.getFavoritesAppointmentList();
  }

  isFavoriteAppointmentById(id) {
    return this.appointmentList.some(appointment => appointment.id === id);
  }

  async getFavoritesAppointmentList() {
    try {
      const response = await apiv2.appointments.getFavoritesAppointmentList();
      this.appointmentList = response.map(item => transformData(item));
      this.isInitialized = true;
    } catch (e) {
      console.error(e);
    }
  }

  async addFavoriteAppointment(id) {
    try {
      const response = await apiv2.appointments.addFavoriteAppointment(id);
      this.appointmentList = [...this.appointmentList, transformData(response)];
    } catch (e) {
      console.error(e);
    }
  }

  async deleteFavoriteAppointment(id) {
    try {
      await apiv2.appointments.deleteFavoriteAppointment(id);
      this.appointmentList = this.appointmentList.filter(appointment => appointment.id !== id);
    } catch (e) {
      console.error(e);
    }
  }
}

export default new FavoritesAppointmentStore();
