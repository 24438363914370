import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { colors } from "../../styleguide/colors";

export const LinkButton = styled(Link)`
  text-decoration: none;
  color: ${colors.magenta};
`;

export const ReportLinkButton = ({ path = "", linkText = "Перейти" }) => {
  return (
    <Button size={"small"} variant={"text"} color={"primary"}>
      <LinkButton to={path} target="_blank">
        {linkText}
      </LinkButton>
    </Button>
  );
};
