// @flow
import React, { useEffect } from "react";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import { AISlicesList } from "./AISlicesList";
import { useStoreMap } from "effector-react";
import { getAppointment_id } from "../../domain/entities/Appointment";
import { appointmentAISlicesStore, refetchAISlices } from "../../stores/appointmentIDSlices";

export const AppointmentAISlicesView = ({
  appointment
}: {
  appointment: AppointmentWithDetails
}) => {
  const appointmentId = getAppointment_id(appointment);
  const slicesList = useStoreMap({
    store: appointmentAISlicesStore,
    keys: [appointmentId],
    fn: (state, [id]) => state[id] || []
  });

  useEffect(() => {
    refetchAISlices(appointmentId);
    const interval = setInterval(() => {
      refetchAISlices(appointmentId);
    }, 15 * 1000);
    return () => clearInterval(interval);
  }, [appointmentId]);

  return <AISlicesList items={slicesList} />;
};
