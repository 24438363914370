import React, { useState } from "react";
import {observer} from 'mobx-react';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import doctorsStore from '../../stores/doctorsStore';
import TextField from "@mui/material/TextField";

export const PushNotificationPinCodeModal = observer(() => {
  return (
    <Dialog
      onClose={() => doctorsStore.changeShowNotificationPinCodeModal(false)}
      open={doctorsStore.showNotificationPinCodeModal}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
         Вход
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => doctorsStore.changeShowNotificationPinCodeModal(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <TextField
          label={"Введите пин-код"}
          autoComplete='off'
          error={doctorsStore.errorPin}
          helperText={doctorsStore.errorPin}
          onChange={(event) => {
            doctorsStore.changePinCode(event.target.value);
          }}
          value={doctorsStore.pinCode}
          fullWidth
          variant="outlined"
        />
        <Typography marginTop={'10px'} gutterBottom>
          Нажав на кнопку "Получить пин-код" вы получите push-уведомление с пин-кодом на мобильное приложение
        </Typography>
        <Typography fontSize={'14px'} color={'gray'} marginTop={'10px'} gutterBottom>
          Если уведомление не приходит, то воспользуйтесь пунктом меню "Проверить пуш уведомление"
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button marginTop={'10px'} onClick={() => doctorsStore.sendPinCodeNotification()}>
          Получить пин-код
        </Button>
        <Button variant={"contained"} onClick={() => doctorsStore.checkPinCode()}>
          Войти
        </Button>
      </DialogActions>
    </Dialog>
  );
});
