import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class VoxPatientConsent {
  apiService;
  presetFilterParams;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  getListPatientConsent({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params, is_after_get}) {
    return this.apiService.post(`vox_patient_consent/list`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params, is_after_get});
  }
}

export default new VoxPatientConsent();
