// @flow
import React, { useEffect, useMemo } from "react";
import paymentStatisticsStore from "../../stores/paymentStatisticsStore";
import { MaterialTable } from "../common/MaterialTable";
import { useForm } from "react-hook-form";
import { FormInputDate } from "../common/dynamic-form/fields/FormInputDate";
import { Grid, Paper } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  SERVER_DATE_FORMAT,
  READABLE_DATE_FORMAT,
  SERVER_DATE_TIME_FORMAT
} from "../../utils/dates";
import { trimLongTextValueField } from "../../utils/tableUtils";
import { WidgetsComponent } from "../common/widgets-components-common/WidgetsComponent";
import styled from "styled-components";
import { FormInputCheckbox } from "../common/dynamic-form/fields/FormInputCheckbox";
import { observer } from "mobx-react";
import { GET_FIELDS_FOR_WIDGETS, GET_GENERATE_WIDGETS, GET_OPTIONS_FOR_WIDGET, PAYMENT_KEYS } from "../common/widgets-components-common/constants";
import organizationsStore from "../../stores/organizationsStore";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const PaymentsStatisticsPage = observer(
  ({
     enableShowingTabs = true,
     enableShowingChart = true,
     enableShowingAdditionalFilters = true,
     enableSettingDefaultDate = true,
     enableDisableSavingPresets = false,
     notPadding = false,
     renderRowActions,
     columnOrder,
     additionalColumns = [],
     columnHidden = []
  }) => {
  const date_end = moment().set({ hour: 23, minute: 59, second: 59 }).format(SERVER_DATE_TIME_FORMAT);
  const date_start = moment().set({ hour: 0, minute: 0, second: 0, month: moment().get('month') - 1 }).format(SERVER_DATE_FORMAT);
  const [activeTab, setActiveTab] = React.useState('table');


  const defaultValuesDates = {
    date_start,
    date_end
  }

  const methods = useForm({
    defaultValues: defaultValuesDates,
  });
  const { handleSubmit, control, setValue } = methods;

  const afterInitializeParams = (callback) => {
    if (enableSettingDefaultDate) {
      if (!paymentStatisticsStore.globalFiltersTable.date__lte) {
        paymentStatisticsStore.setGlobalFiltersTable({
          created_at__gte: date_start,
          created_at__lte: date_end
        }, false);
      } else {
        setValue("date_start", paymentStatisticsStore.globalFiltersTable.created_at__gte);
        setValue("date_end", paymentStatisticsStore.globalFiltersTable.created_at__lte);
      }
    }
    callback();
  }

  const onSubmit = (data) => {
    const filters = {
      created_at__gte: data.date_start,
      created_at__lte: data.date_end,
    };

    paymentStatisticsStore.setGlobalFiltersTable(filters);
  }

  const additionalFilters = (
    <Paper style={{
      padding: '20px'
    }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid alignItems={"center"} container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate label="Дата от" typePicker="dateTime" name="date_start" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate label="до " typePicker="dateTime" name="date_end" control={control} />
          </Grid>
          <Grid item xs={'auto'}>
            <FormInputCheckbox label="Нужна модерация" name="patient_reviews__review_found" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button type={"submit"} size={"large"} variant="contained" color={"success"}>
              Применить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
    })()
  }, []);


  const columns = useMemo(() => {
      const initColumns = [
        {
          accessorKey: 'payment_status',
          header: 'Статус платежа',
          Cell: ({ row }) => paymentStatisticsStore.statusKeys[row.original.payment_status],
          filterFn: 'in',
          filterVariant: 'multi-select',
          filterSelectOptions: paymentStatisticsStore.statusOptions,
          size: 300,
        },
        {
          accessorKey: 'fullNamePatient',
          header: 'ФИО пациента',
          filterFn: 'icontains',
          size: 350,
        },
        {
          accessorKey: 'service__name',
          additionalFields: ['service__kdf_code'],
          filterFn: 'icontains',
          header: 'Услуга',
          size: 400,
          Cell: ({ row }) => trimLongTextValueField(`(${row.original.service__kdf_code}) ${row.original.service__name}`)
        },
        {
          accessorKey: 'fullNameDoctor',
          header: 'ФИО врача',
          filterFn: 'icontains',
          size: 350,
        },
        {
          accessorKey: 'service_amount',
          filterFn: 'icontains',
          header: 'Стоимость',
          size: 300,
        },
        {
          accessorKey: 'format',
          header: 'Формат',
          Cell: ({ row }) => paymentStatisticsStore.formatKeys[row.original.format],
          filterFn: 'in',
          filterVariant: 'multi-select',
          filterSelectOptions: paymentStatisticsStore.formatOptions,
          size: 300,
        },
        {
          accessorKey: 'schedule__date',
          header: 'Дата записи',
          enableColumnFilter: false,
          Cell: ({ row }) => {
            return row.original.schedule__date ? moment(row.original.schedule__date).format(READABLE_DATE_FORMAT) : '';
          }
        },
        {
          accessorKey: 'payment_provider__name',
          header: 'Платежный сервис',
          filterFn: 'icontains',
          size: 350,
        },
        {
          accessorKey: 'payment_id',
          header: 'ID в платежном сервисе',
          filterFn: 'icontains',
          size: 350,
        },
        {
          accessorKey: 'created_at',
          header: 'Дата создания',
          enableColumnFilter: false,
          size: 250,
          Cell: ({ cell }) =>
            moment(cell.getValue()).format(READABLE_DATE_FORMAT),
        },
        {
          accessorKey: 'updated_at',
          header: 'Дата последнего обновления',
          size: 350,
          enableColumnFilter: false,
          Cell: ({ cell }) =>
            moment(cell.getValue()).format(READABLE_DATE_FORMAT),
        },
        {
          accessorKey: 'comment',
          header: 'Комментарий при записи',
          size: 400,
          filterFn: 'icontains',
          Cell: ({ row }) => trimLongTextValueField(row.original.comment)
        }
      ];

      return !additionalColumns.length
        ? initColumns
        : [...initColumns, ...additionalColumns].filter((column) => !columnHidden.includes(column.accessorKey));
    },
    [paymentStatisticsStore.organizations, paymentStatisticsStore.reviewTags],
  );

  return (
    <Container>
      {enableShowingTabs && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Таблица" value={'table'} />
            <Tab label="Графики" value={'charts'} />
          </Tabs>
        </Box>
      )}
      <div style={{display: activeTab !== 'table' ? 'none' : 'block'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialTable
            initialState={
              {
                showColumnFilters: true,
                columnVisibility: {
                  updated_at: false
                }
              }
            }
            columns={columns}
            additionalFilters={enableShowingAdditionalFilters ? additionalFilters : null}
            afterInitializeParams={afterInitializeParams}
            store={paymentStatisticsStore}
            isDisableSavingPresets={enableDisableSavingPresets}
            notPadding={notPadding}
            columnOrder={columnOrder}
            renderRowActions={renderRowActions}
          />
        </LocalizationProvider>
      </div>
      {enableShowingChart && (
        <div style={{display: activeTab !== 'charts' ? 'none' : 'block'}}>
          <WidgetsComponent
            dashboardLabel="Графики по платежам"
            fieldsWidgets={GET_FIELDS_FOR_WIDGETS(PAYMENT_KEYS)}
            generateWidgets={GET_GENERATE_WIDGETS(PAYMENT_KEYS)}
            optionsField={GET_OPTIONS_FOR_WIDGET(PAYMENT_KEYS)}
            nameLocalStorage="PaymentWidgetComponents"
          />
        </div>
      )}
    </Container>
  );
});
