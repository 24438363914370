import * as yup from "yup";
import moment from "moment";
import { SERVER_DATE_FORMAT } from "./dates";

const message = "Обязательное поле";

export const validationSchemaTemplates = {
  string: yup.string().required(message),
  array: yup.array().min(1, message),
  date: yup.mixed().nullable().test({
    name: "validator-date",
    test: function(date) {
      if (!date || date === "Invalid date" || !moment(date).isValid()) {
        return this.createError({
          message,
          path: this.path,
        });
      } else {
        const momentDate = moment(date).format(SERVER_DATE_FORMAT);
        if (momentDate < "1900-01-01" || momentDate > "2099-12-31") {
          return this.createError({
            message: momentDate < "1900-01-01" ? "Введите дату, которая больше, чем 01.01.1900" : "Введите дату, которая меньше, чем 31.12.2099",
            path: this.path,
          });
        }
        return true;
      }
    }
  })
}
