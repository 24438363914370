//@flow
import * as React from "react";

import type { UltrasoundScan } from "../../domain/entities/UltrasoundScan";
import { formatFileDateToNumericDateFullYear } from "../../domain/services/uploadedFile";
import { FileLayout } from "./FileLayout";

export const UltrasoundScanFile = (props: { file: UltrasoundScan, patientName: string }) => {
  const { file, patientName } = props;

  const handleOnClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: "openLink", link: file.viewer_url }));
    } else {
      window.open(file.viewer_url, "_blank");
    }
  }

  return (
    <FileLayout
      date={formatFileDateToNumericDateFullYear(file.date)}
      title={patientName}
      iconType={"ultrasound"}
      onDownload={handleOnClick}
    />
  );
};
