import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 6px;
  background: #fff;
  height: 100%;
  width: 48px;
  position: absolute;
  top: 0;
  z-index: 9;
  box-shadow: 0 30px 50px #f7e5e6;
  cursor: pointer;
  outline: none;
  ${props => (props.isLeft ? "left: 0;" : "right: 0;")}

  &:hover > svg > path {
    transition: stroke 0.2s linear;
    stroke: ${colors.magenta};
  }

  &[disabled] {
    opacity: 0.7;
    cursor: default;

    &:hover > svg > path {
      stroke: ${colors.gray600};
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SliderButton = props => {
  const { icon, position, disabled, ...rest } = props;

  return (
    <Button {...rest} disabled={disabled && "disabled"} isLeft={position === "left"}>
      {icon ? icon : ""}
    </Button>
  );
};
