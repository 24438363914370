import React from "react";
import styled from "styled-components";
import { colors } from "../../../styleguide/colors";

const AsteriskContainer = styled.span`
    color: ${colors.cinnabar}
`;

export const Asterisk = () => {
  return (
    <AsteriskContainer>
      *
    </AsteriskContainer>
  );
};