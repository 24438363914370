import * as React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import chatStore from "../../../stores/chatStore";

const AudioWavesContainer = styled.span`
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  gap: 8px;
  height: 24px;
  
  & .point {
    transition: 0.3s;
    width: 4px;
    height: 4px;
    min-height: 4px;
    max-height: 24px;
    border-radius: 10px;
    background-color: #CAB5BE;
  }
`;

function changeSoundLevel(event, points) {
  if (event.data.level * 200 >= 1) {
    points[2].style.height = 'calc(' + Math.ceil(event.data.level * 200) + 'px + 4px)';
  } else {
    points[2].style.height = '4px';
  }
  if (event.data.level * 200 >= 24) {
    points[1].style.height = 'calc(' + Math.ceil(event.data.level * 150) + 'px + 4px)';
    points[3].style.height = 'calc(' + Math.ceil(event.data.level * 150) + 'px + 4px)';
  } else {
    points[1].style.height = '4px';
    points[3].style.height = '4px';
  }
  if (event.data.level * 200 >= 48) {
    points[0].style.height = 'calc(' + Math.ceil(event.data.level * 100) + 'px + 4px)';
    points[4].style.height = 'calc(' + Math.ceil(event.data.level * 100) + 'px + 4px)';
  } else {
    points[0].style.height = '4px';
    points[4].style.height = '4px';
  }
}

export const AudioWaves = observer(() => {
  React.useEffect(() => {
    let unsubscribe = null;

    if (chatStore.currentDevices.micId !== 0) {
      unsubscribe = chatStore.on(chatStore.events.AudioLevelChange, (event) => {
        const points = document.querySelectorAll('.point');
        if (points.length) changeSoundLevel(event, points);
      });
      chatStore.initAudioWaves();
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
        chatStore.stopAudioWaves();
      }
    };
  }, [ chatStore.currentDevices.micId ]);

  return (
    <AudioWavesContainer className="sound">
      <span className="point"></span>
      <span className="point"></span>
      <span className="point"></span>
      <span className="point"></span>
      <span className="point"></span>
    </AudioWavesContainer>
  );
});
