// @flow
import type { UploadedFile } from "./UploadedFile";

type FieldBase = {
  id: number,
  name: string,
  multi_value: false,
  possible_values: [],
  field_key?: string,
  section: number,
  required: false,
};

export type FieldText = {
  ...$Exact<FieldBase>,
  field_type: "text",
  default_value: string
};

export type FieldTextArea = {
  ...$Exact<FieldBase>,
  field_type: "textarea",
  default_value: string
};

export type FieldDate = {
  ...$Exact<FieldText>,
  field_type: "date"
};

export type FieldCheckbox = {
  ...$Exact<FieldBase>,
  field_type: "checkbox",
  default_value: Array<string>,
  multi_value: true,
  possible_values: Array<string>
};

export type FieldRadiobutton = {
  ...$Exact<FieldBase>,
  field_type: "radiobutton",
  default_value: string,
  possible_values: Array<string>
};

export type FieldNumber = {
  ...$Exact<FieldBase>,
  field_type: "number",
  default_value: string
};

export type FieldFile = {
  ...$Exact<FieldBase>,
  field_type: "file",
  template_file?: UploadedFile
};

export type FieldPrescription = {
  ...$Exact<FieldBase>,
  field_type: "prescription"
};

export type FieldBodyMassIndex = {
  ...$Exact<FieldBase>,
  field_type: "body_mass_index"
};

export type FormulaParameter = {
  id: number,
  parameter_identifier: string,
  type: string,
  value: string
};

export type FieldFormula = {
  ...$Exact<FieldBase>,
  field_type: "formula",
  formula: string,
  parameters: FormulaParameter[],
  default_value: string
};

export type FieldSingle =
  | FieldText
  | FieldTextArea
  | FieldCheckbox
  | FieldRadiobutton
  | FieldDate
  | FieldNumber
  | FieldFile
  | FieldPrescription
  | FieldBodyMassIndex
  | FieldFormula;

export type FieldGroup = {
  ...$Exact<FieldBase>,
  field_type: "group",
  children: Array<FieldSingle>
};

export type Field = FieldSingle | FieldGroup;

export opaque type TField_id: number = number;

export const getField_id = (field: Field): TField_id => {
  return field.id;
};
