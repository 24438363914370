// @flow
import React from "react";
import styled from "styled-components";
import commentIcon from "./icons/comment.svg";
import commentHoverIcon from "./icons/comment-hover.svg";
import { CommonHintedButton, Container } from "./CommonHintedButton";

const Icon = styled.div`
  width: 18px;
  height: 17px;
  background: url(${commentIcon}) no-repeat;
  background-size: contain;

  ${Container}:hover & {
    background: url(${commentHoverIcon}) no-repeat;
    background-size: contain;
  }
`;

export const AddCommentButton = (props: React$ElementConfig<typeof CommonHintedButton>) => {
  return (
    <CommonHintedButton
      {...props}
      overrides={{
        Icon: {
          component: Icon
        },
        Text: {
          props: {
            children: "Комментарий"
          }
        }
      }}
    />
  );
};
