import { makeAutoObservable } from 'mobx';
import apiv2 from '../apiv2';

export const COMPANY_LINKS = {
  yandex: 'yandex_link',
  google: 'google_link',
  twogis: 'twogis_link',
  aboutDoctorsLink: 'about_doctors_link'
}

export const CLINIC_NAME = {
  rassvet: 'Рассвет',
  fomina: 'Фомина',
};

export const CLINIC_CODE = {
  rassvet: 'rassvet',
  fomina: 'fomina',
};

class ReviewStore {
  token = null;
  rate = null;
  doctor_rate = 0;
  administrators_rate = 0;
  middle_medical_staff_rate = 0;
  service_rate = 0;
  company = "";
  current_page = "";
  review = "";
  is_need_callback = null;
  yandex_link = null;
  google_link = null;
  twogis_link = null;
  about_doctors_link = null;
  city = null;
  is_loyalty_card = true;
  isInitialized = false;
  clinic_code = CLINIC_CODE.fomina;
  isMobile = false;

  constructor() {
    makeAutoObservable(this);
  }

  setToken(token) {
    this.token = token;
  }

  setIsMobile(value) {
    this.isMobile = value;
  }

  setClinicCode(name) {
    this.clinic_code = name;
  }

  setRate(rate) {
    this.rate = rate;
    this.doctor_rate = rate;
    this.administrators_rate = rate;
    this.middle_medical_staff_rate = rate;
    this.service_rate = rate;
  }

  setCompany(company) {
    this.company = company;
  }

  setCurrentPage(current_page) {
    this.current_page = current_page;
  }

  setReview(review) {
    this.review = review;
  }

  setCity(city) {
    this.city = city;
  }

  setIsNeedCallback(is_need_callback) {
    this.is_need_callback = is_need_callback;
  }

  *getCompanyLink() {
    try {
      this.isInitialized = true;
      const response = yield apiv2.review.getLink(this.token, this.isMobile, this.city); // {map_link: ''} - по нажатию на yandex дергаем этот url
      this.yandex_link = response.yandex_link;
      this.google_link = response.google_link;
      this.twogis_link = response.twogis_link;
      this.about_doctors_link = response.about_doctors_link;
      this.city = response.city;
      this.is_loyalty_card = response.is_loyalty_card;
      return true;
    } catch(e) {
      console.error(e);
      return false;
    }
  }
  *setProgress() {
    try {
      yield apiv2.review.putProgress({
        token: this.token,
        rate: this.rate || undefined,
        doctor_rate: this.doctor_rate || undefined,
        administrators_rate: this.administrators_rate || undefined,
        middle_medical_staff_rate: this.middle_medical_staff_rate || undefined,
        service_rate: this.service_rate || undefined,
        company: this.company || undefined,
        current_page: this.current_page || undefined,
        review: this.review || undefined,
        isMobile: this.isMobile || undefined,
        city: this.city || undefined,
        is_need_callback: this.is_need_callback === null ? undefined : this.is_need_callback
      });
      return true
    } catch(e) {
      console.error(e);
      return false
    }
  }
}

export default new ReviewStore();
