// @flow
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormInputDate } from "../common/dynamic-form/fields/FormInputDate";
import { Chip, Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box'
import { READABLE_DATE_FORMAT, SERVER_DATE_FORMAT } from "../../utils/dates";
import { trimLongTextValueField } from "../../utils/tableUtils";
import styled from "styled-components";
import { observer } from "mobx-react";
import doctorsStore from "../../stores/doctorsStore";
import { MaterialReactTable, MRT_ExpandAllButton, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { PieChartMUI } from "../common/charts/components/PieChartMUI";
import _ from "lodash";
import { ProfileLayout } from "../layouts/ProfileLayout";
import { renderToolbarAlertBannerContent } from "../common/MaterialTable";
import { PushNotificationPinCodeModal } from "../doctor/PushNotificationPinCodeModal";
import { BonusSchema } from "./bonus-schema/BonusSchema";
import { colors } from "../styleguide/colors";
import { FEATURE, useApplicationFeatureEnabled } from "../../utils/applicationFeatures";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    padding-bottom: 70px;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

const TitleBold = styled.div`
    font-weight: bold;
    font-size: 22px;
    color: ${colors.black};

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const SubtitleBold = styled(Typography)`
    font-size: 20px;
    line-height: 23px;
    font-weight: bold;
    color: ${colors.black};

    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
    }
`;

const ChartItemBottom = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`;
const ChartItem = styled(Paper)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
    padding: 15px;
    height: 100%;
    justify-content: space-between;
`;

const DoctorSalaryQuickFilters = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;


export const DoctorSalaryPage = observer(() => {
  const start_date = moment().startOf('month').format(SERVER_DATE_FORMAT);
  const end_date = moment().format(SERVER_DATE_FORMAT);

  const defaultValuesDates = {
    start_date,
    end_date
  }

  const methods = useForm({
    defaultValues: defaultValuesDates,
  });
  const { handleSubmit, control, setValue } = methods;

  const onSubmit = (filters) => {
    doctorsStore.setSalaryFilters(filters);
  }

  const handlerQuickFilter = (type) => {
    if (type === 'current') {
      const start_date = moment().startOf('month').format(SERVER_DATE_FORMAT);
      const end_date = moment().format(SERVER_DATE_FORMAT);
      setValue('start_date', start_date);
      setValue('end_date', end_date);
      onSubmit({
        start_date,
        end_date
      })
    }
    if (type === 'previous') {
      const start_date = moment().set('month', moment().get('month') - 1).startOf('month').format(SERVER_DATE_FORMAT);
      const end_date = moment().set('month', moment().get('month') - 1).endOf('month').format(SERVER_DATE_FORMAT);
      setValue('start_date', start_date);
      setValue('end_date', end_date);
      onSubmit({
        start_date,
        end_date
      })
    }
  }


  useEffect(() => {
    (async () => {
      doctorsStore.setSalaryFilters(defaultValuesDates);
      if (!doctorsStore.accessIsAllowed && !window.ReactNativeWebView) {
        doctorsStore.changeShowNotificationPinCodeModal(true);
      }
    })()
  }, []);


  const columns = useMemo(
    () => [
      {
        accessorKey: 'Организация', //normal accessorKey
        header: 'Организация',
        filterFn: 'contains',
      },
      {
        accessorKey: 'ВидНачисления', //normal accessorKey
        header: 'Вид начисления',
        filterFn: 'contains',
      },
      {
        accessorKey: 'ИсточникФинансирования', //normal accessorKey
        header: 'Источник финансирования',
        filterFn: 'contains',
      },
      {
        accessorKey: 'Услуга', //normal accessorKey
        header: 'Услуга',
        filterFn: 'contains',
        expanded: false,
        Cell: ({ row }) => trimLongTextValueField(row.original['Услуга'])
      },
      {
        accessorKey: 'Количество', //normal accessorKey
        header: 'Количество',
        filterFn: 'contains',
        aggregationFn: 'sum',
        Cell: ({ cell }) => (
          cell.getValue().toLocaleString()
        ),
        AggregatedCell: ({ cell }) => (
          <Stack>
            <Box color="primary.main">Всего: {cell.getValue().toLocaleString()}</Box>
          </Stack>
        ),
        Footer: () => (
          <Stack>
            <Box fontWeight={"normal"} fontSize={14} color="primary.main">Всего: {parseInt(doctorsStore.salaryData.reduce((acc, item) => {
              acc += item['Количество']
              return acc;
            }, 0)).toLocaleString()}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'Начислено', //normal accessorKey
        header: 'Начислено',
        filterFn: 'contains',
        aggregationFn: 'sum',
        Cell: ({ cell }) => (
          cell.getValue().toLocaleString()
        ),
        AggregatedCell: ({ cell }) => (
          <Stack>
            <Box color="primary.main">Всего: {parseInt(cell.getValue()) .toLocaleString()}</Box>
          </Stack>
        ),
        Footer: () => (
          <Stack>
            <Box fontWeight={"normal"} fontSize={14} color="primary.main">Всего: {parseInt(doctorsStore.salaryData.reduce((acc, item) => {
              acc += item['Начислено']
              return acc;
            }, 0)).toLocaleString()}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'Начислено после вычета НДФЛ', //normal accessorKey
        header: 'Начислено после вычета НДФЛ',
        filterFn: 'contains',
        aggregationFn: 'sum',
        size: 350,
        Cell: ({ cell }) => (
          cell.getValue().toLocaleString()
        ),
        AggregatedCell: ({ cell }) => (
          <Stack>
            <Box color="primary.main">Всего: {parseInt(cell.getValue()).toLocaleString()}</Box>
          </Stack>
        ),
        Footer: () => (
          <Stack>
            <Box fontWeight={"normal"} fontSize={14} color="primary.main">Всего после вычета НДФЛ: {parseInt(doctorsStore.salaryData.reduce((acc, item) => {
              acc += item['Начислено после вычета НДФЛ']
              return acc;
            }, 0)).toLocaleString()}</Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'ПризнакДоговораЭКО', //normal accessorKey
        header: 'Признак договора ЭКО',
        filterFn: 'contains',
      },
      {
        accessorKey: 'СтоимостьУслуг', //normal accessorKey
        header: 'Стоимость Услуг',
        filterFn: 'contains',
        Cell: ({ cell }) => (
          cell.getValue().toLocaleString()
        ),
      },
    ],
    [doctorsStore.salaryData],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: doctorsStore.salaryData,
    enableFullScreenToggle: false,
    enableGrouping: true,
    enableExpandAll: true,
    groupedColumnMode: 'remove',
    paginateExpandedRows: false,
    positionToolbarAlertBanner: 'bottom',
    renderToolbarAlertBannerContent: renderToolbarAlertBannerContent,
    displayColumnDefOptions: {
      'mrt-row-expand': {
        Header: () => (
          <Stack direction="row" alignItems="center">
            <MRT_ExpandAllButton table={table} />
            <Box>Группировка</Box>
          </Stack>
        ),
        muiTableBodyCellProps: ({ row }) => ({
          sx: (theme) => {
            const rowColors = [
              theme.palette.primary.main,
              theme.palette.secondary.main,
              theme.palette.info.main,
              theme.palette.info.dark,
            ]
            return {
              color: rowColors[row.depth]
            }
          },
        }),
      },
    },
    initialState: {
      density: 'compact',
      pagination: {
        pageSize: 10000,
      },
      expanded: true,
      grouping: ['Организация', 'ПризнакДоговораЭКО', 'ВидНачисления', 'ИсточникФинансирования'],
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  const totalSalary = doctorsStore.salaryData.reduce((acc, salary) => {
    acc += salary['Начислено после вычета НДФЛ'];
    return acc;
  }, 0)

  const pieChartData = useMemo(() => {
    if (doctorsStore.salaryData && doctorsStore.salaryData.length) {
      const data = [];
      const groupData = _.groupBy(doctorsStore.salaryData, 'ВидНачисления');
      Object.keys(groupData).map((key) => {
        const groupDataTwo = _.groupBy(groupData[key], 'ИсточникФинансирования');
        Object.keys(groupDataTwo).map((financeKey) => {
          data.push({
            label: `${key} - ${financeKey}`,
            value: groupDataTwo[financeKey].reduce((acc, item) => {
              acc += item['Начислено'];
              return acc;
            }, 0)
          })
        })
      })
      return _.sortBy(data, 'value').reverse();
    }
    return []
  }, [doctorsStore.salaryData])

  const pieChartDataPerformer = useMemo(() => {
    if (doctorsStore.salaryData && doctorsStore.salaryData.length) {
      const data = [];
      const groupData = _.groupBy(doctorsStore.salaryData, 'ВидНачисления');
      Object.keys(groupData).map((key) => {
        if (key === 'Исполнителю') {
          const groupDataTwo = _.groupBy(groupData[key], 'Услуга');
          Object.keys(groupDataTwo).map((serviceKey) => {
            data.push({
              label: serviceKey,
              value: groupDataTwo[serviceKey].reduce((acc, item) => {
                acc += item['Начислено'];
                return acc;
              }, 0)
            })
          })
        }
      })
      return _.sortBy(data, 'value').reverse();
    }
    return []
  }, [doctorsStore.salaryData])

  const pieChartDataGuide = useMemo(() => {
    if (doctorsStore.salaryData && doctorsStore.salaryData.length) {
      const data = [];
      const groupData = _.groupBy(doctorsStore.salaryData, 'ВидНачисления');
      Object.keys(groupData).map((key) => {
        if (key === 'Направляющему') {
          const groupDataTwo = _.groupBy(groupData[key], 'Услуга');
          Object.keys(groupDataTwo).map((serviceKey) => {
            data.push({
              label: serviceKey,
              value: groupDataTwo[serviceKey].reduce((acc, item) => {
                acc += item['Начислено'];
                return acc;
              }, 0)
            })
          })
        }
      })
      return _.sortBy(data, 'value').reverse();
    }
    return []
  }, [doctorsStore.salaryData])

  return (
    <ProfileLayout activeIndexMenu={1}>
      {(!doctorsStore.accessIsAllowed && !window.ReactNativeWebView) ? (
        <PushNotificationPinCodeModal />
      ) : (
        <Container>
          {!!doctorsStore.salaryFilters.start_date && (
            <TitleBold>
              Моя зарплата с {moment(doctorsStore.salaryFilters.start_date).format(READABLE_DATE_FORMAT)} по {moment(doctorsStore.salaryFilters.end_date).format(READABLE_DATE_FORMAT)}
              {!!doctorsStore.salaryData.length && (
                <>
                  <SubtitleBold> после вычета НДФЛ — </SubtitleBold>
                  <SubtitleBold color="primary.main">{parseInt(totalSalary).toLocaleString()}₽</SubtitleBold>
                </>
              )}
            </TitleBold>
          )}
          <Paper style={{
            padding: '20px',
            display: "flex",
            gap: "20px",
            flexDirection: "column"
          }}>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid alignItems={"center"} container spacing={2}>
                <Grid item xs={12} md={3} sm={6}>
                  <FormInputDate label="Дата от" name="start_date" control={control} />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <FormInputDate label="до " name="end_date" control={control} />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <Button type={"submit"} size={"large"} variant="contained" color={"success"}>
                    Применить
                  </Button>
                </Grid>
              </Grid>
            </form>
            <DoctorSalaryQuickFilters>
              <Chip onClick={() => handlerQuickFilter('current')} label="Текущий месяц" />
              <Chip onClick={() => handlerQuickFilter('previous')} label="Прошлый месяц" />
            </DoctorSalaryQuickFilters>
          </Paper>
          {!doctorsStore.loading ? (
            <>
              {!!doctorsStore.salaryData.length ? (
                <>
                  <Grid container spacing={2}>
                    {!!pieChartData.length && (
                      <Grid item md={6} lg={4} xs={12}>
                        <ChartItem>
                          <Typography textAlign={"center"} fontSize={16}>Диаграмма по исполнителю и направляющему</Typography>
                          <ChartItemBottom>
                            <PieChartMUI data={pieChartData} />
                            <Typography>
                              Итого после вычета НДФЛ: <Typography component={"span"} color="primary.main">{parseInt(totalSalary).toLocaleString()}₽</Typography></Typography>
                          </ChartItemBottom>
                        </ChartItem>
                      </Grid>
                    )}
                    {!!pieChartDataPerformer.length && (
                      <Grid item md={6} lg={4} xs={12}>
                        <ChartItem>
                          <Typography textAlign={"center"} fontSize={16}>Диаграмма по услугам исполнителя</Typography>
                          <ChartItemBottom>
                            <PieChartMUI data={pieChartDataPerformer} />
                            <Typography>
                              Итого после вычета НДФЛ:{' '}
                              <Typography component={"span"} color="primary.main">
                                {parseInt(pieChartDataPerformer
                                  .reduce((acc, item) => {
                                    acc += item.value;
                                    return acc;
                                  }, 0) * 0.87)
                                  .toLocaleString()}₽
                              </Typography>
                            </Typography>
                          </ChartItemBottom>
                        </ChartItem>
                      </Grid>
                    )}
                    {!!pieChartDataGuide.length && (
                      <Grid item md={6} lg={4} xs={12}>
                        <ChartItem>
                          <Typography textAlign={"center"} fontSize={16}>Диаграмма по услугам направляющего</Typography>
                          <ChartItemBottom>
                            <PieChartMUI data={pieChartDataGuide} />
                            <Typography>
                              Итого после вычета НДФЛ:{' '}
                              <Typography component={"span"} color="primary.main">
                                {parseInt(pieChartDataGuide
                                  .reduce((acc, item) => {
                                    acc += item.value;
                                    return acc;
                                  }, 0) * 0.87)
                                  .toLocaleString()}₽</Typography></Typography>
                          </ChartItemBottom>
                        </ChartItem>
                      </Grid>
                    )}
                  </Grid>
                  <MaterialReactTable table={table} />
                </>
              ) : (
                <>
                  {doctorsStore.isInitialized && (
                    <Typography textAlign={"center"} marginTop={'50px'} fontSize={'20px'}>За выбранный вами период зарплаты нет.</Typography>
                  )}
                </>
              )}
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '100px' }}>
              <CircularProgress />
            </Box>
          )}
        </Container>
      )}
    </ProfileLayout>
  );
});
