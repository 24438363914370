// @flow
import React from "react";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const GaugeWrapper = styled(Box)(
  () => `
    position: relative;
    width: 100%;
    min-width: 150px;
    max-width: 250px;
    height: 100%;
    margin: 0 auto;
`
);

const ContainerWithoutChart = styled(Box)(
  () => `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiTypography-root {
      font-size: 5rem;
      font-weight: 500;
      line-height: normal;
    }
`
);

export function GaugeChartMUI(props: {
  label: string,
  data: any,
  width?: number,
  height?: number
}) {
  if (!props.data) {
    return null;
  }

  return (
    <Stack sx={{ height: "100%" }}>
      <Typography sx={{ fontSize: "24px", lineHeight: "normal" }}>{props.label}</Typography>
      <Typography>За выбранный период</Typography>
      {props.data.valueMax ? (
        <GaugeWrapper>
          <Gauge
            {...props.data}
            startAngle={-110}
            endAngle={110}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
                fontWeight: 500,
                transform: "translate(0px, 0px)"
              }
            }}
            text={({ value }) => `${value}`}
          />
        </GaugeWrapper>
      ) : (
        <ContainerWithoutChart>
          <Typography>{props.data.value || 0}</Typography>
        </ContainerWithoutChart>
      )}
    </Stack>
  );
}
