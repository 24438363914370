import { createEvent, createStore } from "effector";
import * as R from "ramda";

export const setLayoutSectionsFieldsMap = createEvent();

export const layoutSectionsFieldsMapStore = createStore({});

layoutSectionsFieldsMapStore.on(setLayoutSectionsFieldsMap, (state, layout) => {
  return {
    ...state,
    ...R.compose(
      R.indexBy(R.prop("copy_id")),
      R.map(R.over(R.lensProp("fields"), R.indexBy(R.prop("copy_id")))),
      R.map(
        R.over(
          R.lensProp("fields"),
          R.map(
            R.when(R.prop("children"), R.over(R.lensProp("children"), R.indexBy(R.prop("copy_id"))))
          )
        )
      )
    )(layout.sections)
  };
});
