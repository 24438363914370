import React, { useRef } from "react";

import { PreloaderBody } from "../prealoader/PreloaderBody";

import { headerHeight } from "./styled/ServicesTree.styled";
import * as S from "./styled/ServicesTreePreloader.styled";

export const ServicesTreePreloader = () => {
  const $root = useRef();

  return (
    <S.Root ref={$root}>
      <PreloaderBody>
        {new Array(Math.ceil((window.innerHeight - headerHeight) / S.itemHeight))
          .fill()
          .map((_, i) => i + 1)
          .map(i => (
            <S.Item key={i}>
              <S.ShortCaption />
              <S.LongCaption />
              <S.Star />
            </S.Item>
          ))}
      </PreloaderBody>
    </S.Root>
  );
};
