// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { CaptionSmall } from "../styleguide/typography";
import { Container as FixedCellContainer } from "./PrescriptionTableHeadCellFixed";

const Container = styled.th`
  min-width: 72px;
  height: 81px;
  box-sizing: border-box;
  padding: 15px 8px 15px 11px;
  border-left: solid 1px ${colors.transparentGray};

  border-bottom: solid 1px ${colors.magenta};

  position: relative;

  ${FixedCellContainer} + & {
    border-left: none;
  }
`;

const ValueContainer = styled.div`
  & + & {
    margin-top: 11px;
  }
`;

export const PrescriptionTableHeadCell = (props: { values: Array<string> }) => {
  return (
    <Container>
      {props.values.map((value, id) => {
        return (
          <ValueContainer key={id}>
            {value ? <CaptionSmall>{value}</CaptionSmall> : <CaptionSmall>&nbsp;</CaptionSmall>}
          </ValueContainer>
        );
      })}
    </Container>
  );
};
