import React, { useContext, useEffect } from "react";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import { HeadingH2 } from "../styleguide/typography";
import styled from "styled-components";
import { Summary } from "./Summary";
import { FolderPageContext } from "./FolderPageContext";
import { SummaryPrintButton } from "./GetSummaryPrintButton";
import { MedicalFileContext } from "../appointment-switch-page/MedicalFileContext";
import { SummaryContextProvider } from './SummaryContext';
import apiv2 from '../../apiv2';
import { BlockLoader } from "../loader/Loader";

const ContentContainer = styled.div`
  padding: 15px 50px 100px 100px;
  max-width: 1174px;
  min-width: 1044px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    min-width: auto;
    padding: 40px 40px 80px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px 50px;
  }
`;

const SummariesContainer = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px
  }
`;

export const FolderPage = () => {
  const { folder } = useContext(FolderPageContext);
  const { medicalFile } = useContext(MedicalFileContext);
  const token = useStore(tokenStore);

  const [summaries, setSummaries] = React.useState([]);

  useEffect(() => {
    (async () => {
      const data = await apiv2.appointments.getFolderSummary(folder.id);
      setSummaries(data);
    })();
  }, [folder.id, token]);

  const title = <HeadingH2>Вспомогательные Репродуктивные Технологии</HeadingH2>;

  if (!token) return (
    <BlockLoader transparent />
  );

  return (
    <ContentContainer>
      <SummaryContextProvider>
        <TitleContainer>
          {title}
          <div>{medicalFile && <SummaryPrintButton patientId={medicalFile.patient.id} />}</div>
        </TitleContainer>
        <SummariesContainer style={{minHeight: 'calc(100vh - 300px)'}}>
          {summaries &&
            summaries.map((summary, index) => (
              <Summary notNeedAddMinHeight={true} key={summary.id} summary={summary} primary={index === 0} />
            ))}
        </SummariesContainer>
      </SummaryContextProvider>
    </ContentContainer>
  );
};
