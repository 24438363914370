// @flow
import styled from "styled-components";
import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { HeadingH2 } from "../styleguide/typography";
import { ButtonRadioGroup } from "../form-elements/ButtonRadioGroup";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { AppointmentStartFormLayout } from "./AppointmentStartFormLayout";
import { AppointmentStartLayout } from "./AppointmentStartLayout";
import _ from "lodash";
import { useGroupCategoriesAutocomplete, useGroupAppointmentsAutocomplete } from "./useGroupSelect";
import { useLayoutRadioGroups } from "./useLayoutRadioGroups";
import useReactRouter from "use-react-router";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import { createAppointmentCreationOptionsOutsideSchedule } from "../../domain/value-objects/appointment";
import { reloadPopularLayouts, usePopularLayoutRadioGroups } from "./usePopularLayoutRadioGroups";
import { getConcatRequiredFields } from "../../utils/getConcatRequiredFields";

import type { MedicalFile } from "../../domain/entities/MedicalFile";
import { CorrectMedicalFilesContext } from "../appointment-switch-page/CorrectMedicalFilesContext";
import { useFolderSelection } from "./useFolderSelection";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { CustomOptionWrapper, Option } from "../autocomplete/CustomOption";

import { colors } from "../styleguide/colors";
import appointmentCreateStore from "../../stores/AppointmentCreateStore";
import moment from "moment";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import serviceStore from "../../stores/serviceStore";
import apiv2 from '../../apiv2';
import popularLayoutsStore from '../../stores/popularLayoutsStore';
import { BlockLoader } from "../loader/Loader";

const getConvertedTextForCopiedAppointment = ({ date, time, doctor, folderName }) => {
  return `${moment(date).format("DD.MM.YY")} - 
    ${moment(time, "HH:mm:ss").format("HH:mm")} | 
    ${getLastNameWithInitials(doctor)} 
    ${folderName && `| ${folderName}`}
    `;
}

const getOnSubmit = ({ history, medical_file_id, token, layout, folder, folderType, copy_appointment_id }) => {
  const medical_file = medical_file_id;
  if (!layout || !token || !medical_file) {
    return;
  }

  const options: any = {
    layout: parseInt(layout),
    medical_file: parseInt(medical_file),
    copy_appointment_id
  };

  if (folder) {
    const isNewFolder = folder.id === -1;
    if (isNewFolder) {
      options.f_layout = folderType.id;
    } else {
      options.folder = folder.id;
    }
  }

  return async () => {
    const creationOptions = createAppointmentCreationOptionsOutsideSchedule(options);
    try {
      const result = await apiv2.appointments.createAppointment(creationOptions);
      if (copy_appointment_id) {
        serviceStore.setCreatedAppointmentWithCopy(true)
      }
      history.push(`/appointment/${result.id}`);
    } catch (e) {
      console.log(e);
    }
  };
};

const SubmitRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 15px;
  }
`;

const SubmitButtonContainer = styled.div`
  flex-shrink: 0;
`;

const RequiredFields = styled.div`
  margin-top: 24px;
  > * {
    color: ${colors.watermelon};
  }
`;

const Notion = styled.div`
  max-width: 654px;
  margin-left: 36px;
  font-size: 17px;
  line-height: 23px;
  color: #7d7d7d;
  @media (max-width: 768px) {
    max-width: none;
    margin-left: 0;
    font-size: 14px;
  }

  & {
    a {
      color: ${colors.magenta};
    }
  }
`;

const Alert = styled.div`
  flex: 1;
  padding-left: 36px;
  font-size: 17px;
  line-height: 23px;
  color: #7d7d7d;
`;

const RemoveIconContainer = styled.div`
  position: absolute;
  right: 6px;
  display: inline-flex;
  margin-left: 8px;
  z-index: 1;
  color: rgba(0, 0, 0, 0.26);
    
  &:hover {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const useLayoutGroupsBindedWithLayout = () => {
  let radioGroupProps = {};
  const popularLayoutRadioGroupsProps = usePopularLayoutRadioGroups();
  const isHavePopularLayouts = Object.keys(popularLayoutRadioGroupsProps.items).length > 0;
  const groupAutocompleteCategoriesProps = useGroupCategoriesAutocomplete({ isHavePopularLayouts });
  const groupAutocompleteAppointmentsProps = useGroupAppointmentsAutocomplete();
  const layoutRadioGroupsProps = useLayoutRadioGroups(
      (!groupAutocompleteCategoriesProps.isPopularLayoutSelected && groupAutocompleteCategoriesProps.controlOptions.value?.id) || undefined
  );
  if (groupAutocompleteCategoriesProps.isPopularLayoutSelected) {
    radioGroupProps = popularLayoutRadioGroupsProps;
  } else if (!groupAutocompleteCategoriesProps.isPopularLayoutSelected && groupAutocompleteCategoriesProps.controlOptions.value) {
    radioGroupProps = layoutRadioGroupsProps;
  }

  if (appointmentCreateStore.copyAppointment && radioGroupProps.onChange) {
    const isFoundCopyLayoutById = _.find(radioGroupProps.items, { id: appointmentCreateStore.copyAppointment?.layout?.id });

    if ((!isFoundCopyLayoutById && radioGroupProps.checked && groupAutocompleteCategoriesProps.isPopularLayoutSelected) || (isFoundCopyLayoutById && !radioGroupProps.checked)) {
      radioGroupProps.onChange(isFoundCopyLayoutById ? `${isFoundCopyLayoutById.id}`: undefined);
    }
  }

  useEffect(() => {
    radioGroupProps.onChange && radioGroupProps.onChange(undefined);
  }, [groupAutocompleteCategoriesProps.isPopularLayoutSelected]);

  return [groupAutocompleteCategoriesProps, groupAutocompleteAppointmentsProps, radioGroupProps];
};

export const AppointmentStartPage = () => {
  const token = useStore(tokenStore);
  const { history, match } = useReactRouter();

  const { medicalFiles } = useContext<{ medicalFiles: Array<MedicalFile> }>(
    CorrectMedicalFilesContext
  );

  const medicalFileId = (appointmentCreateStore.copyAppointment && appointmentCreateStore.copyAppointment.medical_file) ? appointmentCreateStore.copyAppointment.medical_file.id : parseInt(match.params.medical_file_id);
  const patientId = React.useMemo(() => {
    const file = medicalFiles.find(file => file.id === medicalFileId);
    return file?.patient?.id;
  }, [medicalFileId, medicalFiles]);

  const [
    groupAutocompleteCategoriesProps,
    groupAutocompleteAppointmentsProps,
    radioGroupProps
  ] = useLayoutGroupsBindedWithLayout();
  // $FlowFixMe
  const [selectedMedicalFileId, setSelectedMedicalFileId] = useState(null);

  const folderRadioGroupProps = useFolderSelection({
    layoutId: radioGroupProps.checked,
    patientId
  });

  const [submitOnProgress, setSubmitOnProgress] = useState(false);
  const [requiredFieldsValidate, setRequiredFieldsValidate] = useState(false);

  /** 2 обязательных поля */
  const requiredFields = [
    { fieldKey: "sample", name: "Шаблон" },
    { fieldKey: "outpatientCard", name: "Амбулаторная карта" }
  ];

  const medicalFilesToRadioGroupProps = medicalFiles => {
    const base = {
      // $FlowFixMe
      items: {},
      onChange() {}
    };
    if (medicalFiles && medicalFiles.length) {
      base.items = medicalFiles.reduce((acc, next) => {
        acc[next.id] = {
          id: next.id,
          name: `АК №${next.number} ${next.organization.name || ""}`.trim(),
          is_editable: true
        };
        return acc;
      }, {});
      base.onChange = medicalFileId => {
        // $FlowFixMe
        setSelectedMedicalFileId(medicalFileId);
      };
      return base;
    }
    return base;
  };

  useEffect(() => {
    if (medicalFiles && medicalFiles.length) {
      if (medicalFiles.length === 1) {
        setSelectedMedicalFileId(medicalFiles[0].id.toString());
        return;
      }
      const currentURL = new URL(window.location.href);
      if (
        medicalFiles.length > 1 &&
        (currentURL.searchParams.size > 0 &&
        currentURL.searchParams.get("autoSelectMedicalFile") === "true" || (appointmentCreateStore.copyAppointment && medicalFileId))
      ) {
        const currentMedicalFile = medicalFiles.find(
          medical_file => medical_file.id === medicalFileId);
        if (currentMedicalFile) {
          setSelectedMedicalFileId(currentMedicalFile.id.toString());
        }
      }
    }
  }, [medicalFileId, medicalFiles]);

  useLayoutEffect(() => {
    setRequiredFieldsValidate(false);
  }, [selectedMedicalFileId, radioGroupProps.checked]);

  const onSubmit = getOnSubmit({
    history,
    medical_file_id: selectedMedicalFileId,
    token,
    layout: radioGroupProps.checked,
    folder: folderRadioGroupProps.folders[folderRadioGroupProps.selectedFolder],
    folderType: folderRadioGroupProps.folderTypes[folderRadioGroupProps.selectedFolderType],
    copy_appointment_id: groupAutocompleteAppointmentsProps.controlOptions.value?.id || null
  });

  const onSubmitControlled = async () => {
    if (!onSubmit || createAppointmentDisabled) {
      setRequiredFieldsValidate(true);
      setTimeout(() => {
        document
          .getElementById("required_field")
          ?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      });
      return;
    }

    setRequiredFieldsValidate(false);
    try {
      setSubmitOnProgress(true);
      appointmentCreateStore.changeCopyAppointment(null);
      await onSubmit();
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitOnProgress(false);
    }
  };

  const title = <HeadingH2>Новая запись</HeadingH2>;

  const medicalFilesRadioGroupProps = medicalFilesToRadioGroupProps(medicalFiles);

  const onlyWithFolder: boolean =
    //$FlowFixMe
    radioGroupProps.checked && radioGroupProps.items[radioGroupProps.checked]?.only_with_folder;

  const maxAppointmentsOfTypeInFolder =
    folderRadioGroupProps.selectedFolder &&
    folderRadioGroupProps.folders[folderRadioGroupProps.selectedFolder].appointment_max;

  const createAppointmentDisabled =
    !radioGroupProps.checked ||
    !selectedMedicalFileId ||
    submitOnProgress ||
    (onlyWithFolder && !folderRadioGroupProps.selectedFolder) ||
    maxAppointmentsOfTypeInFolder;

  const checkValidateAppointment = fields => {
    return requiredFields.filter(field => fields[field.fieldKey]).map(field => field);
  };

  const fieldValidationOptions = () => {
    const notValidFields = checkValidateAppointment({
      sample: !radioGroupProps?.checked,
      outpatientCard: !selectedMedicalFileId
    });

    return {
      notValidFields,
      showError: !!notValidFields.length,
      modifier: "2"
    };
  };

  const showCreateWithFolderNotion = onlyWithFolder && !folderRadioGroupProps.selectedFolder;

  const onRemoveItem = async (layout) => {
    try {
      await popularLayoutsStore.deletePopularLayout(layout.id);
      reloadPopularLayouts();
    } catch(e) {
      console.error(e);
    }
  };

  const getRemoveItemButton = (item) => (
    <RemoveIconContainer>
      <CancelIcon style={{
        zIndex: 10
      }} onClick={async (e) => {
        e.preventDefault();
        await onRemoveItem(item);
      }}/>
    </RemoveIconContainer>
  );

  const form = (
    <AppointmentStartFormLayout
      categoriesAutocomplete={
        <Autocomplete
            {...groupAutocompleteCategoriesProps.defaultProps}
            {...groupAutocompleteCategoriesProps.controlOptions}
            options={groupAutocompleteCategoriesProps.options}
            renderInput={params => <TextField {...params} />}
            renderOption={(props, option ) => {
              const { id, name } = option;
              return (
                  <CustomOptionWrapper {...props} key={id}>
                    <Option name={name} />
                  </CustomOptionWrapper>
              );
            }}
        />
      }
      appointmentsAutocomplete={
        <Autocomplete
          {...groupAutocompleteAppointmentsProps.defaultProps}
          {...groupAutocompleteAppointmentsProps.controlOptions}
          options={groupAutocompleteAppointmentsProps.options}
          renderInput={(params) => {
            const selectedValue = groupAutocompleteAppointmentsProps.controlOptions.value;
            return <TextField {...params} label={selectedValue ? getConvertedTextForCopiedAppointment(selectedValue) : 'Выберите консультацию'} />;
          }}
          renderOption={(props, option ) => {
            return (
              <CustomOptionWrapper {...props} key={option.id}>
                <Option name={option.name} caption={getConvertedTextForCopiedAppointment(option)} />
              </CustomOptionWrapper>
            );
          }}
        />
      }
      layoutButtonRadioGroup={
        !_.isEmpty(radioGroupProps.items) && (
          <ButtonRadioGroup buttonIcon={groupAutocompleteCategoriesProps.isPopularLayoutSelected && getRemoveItemButton} {...radioGroupProps} name={"layout"} />
        )
      }
      medicalFilesButtonRadioGroup={
        !_.isEmpty(medicalFilesRadioGroupProps.items) && (
          <ButtonRadioGroup
            {...medicalFilesRadioGroupProps}
            checked={selectedMedicalFileId}
            name={"medicalFile"}
          />
        )
      }
      folderTypeButtonRadioGroup={
        !_.isEmpty(folderRadioGroupProps.folderTypes) && (
          <ButtonRadioGroup
            items={folderRadioGroupProps.folderTypes}
            order={folderRadioGroupProps.folderTypesOrder}
            onChange={folderRadioGroupProps.onFolderTypeChange}
            checked={folderRadioGroupProps.selectedFolderType}
            name={"folderType"}
          />
        )
      }
      folderButtonRadioGroup={
        !_.isEmpty(folderRadioGroupProps.folders) && (
          <ButtonRadioGroup
            items={folderRadioGroupProps.folders}
            order={folderRadioGroupProps.foldersOrder}
            onChange={folderRadioGroupProps.onFolderChange}
            checked={folderRadioGroupProps.selectedFolder}
            name={"folder"}
          />
        )
      }
      submitButton={
        <React.Fragment>
          <SubmitRow>
            <SubmitButtonContainer>
              <PrimaryButton onClick={onSubmitControlled}>Создать запись</PrimaryButton>
            </SubmitButtonContainer>
            {maxAppointmentsOfTypeInFolder && (
              <Notion>
                В этой папке уже есть такой шаблон. В папке может быть только один прием пункция,
                оплодотворение, перенос эмбрионов и исход программы ВРТ.
              </Notion>
            )}
            {showCreateWithFolderNotion && (
              <Notion>
                <span>Чтобы создать прием, выберите событие или создайте новое.</span>
                <br /> <span>Зачем добавлять приемы в события читайте </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.notion.so/4c5aff59cdf642a09a895f8ac8e353b9#e69cf816ef4f4bffa4f1a4d610eddc64">
                  здесь
                </a>
                <span>.</span>
              </Notion>
            )}
            {folderRadioGroupProps.selectedFolderType &&
              folderRadioGroupProps.selectedFolder === "folder-new" && (
                <Alert>
                  <div>При создании нового события, предыдущее завершится автоматически.</div>
                </Alert>
              )}
          </SubmitRow>
          {requiredFieldsValidate && (
            <RequiredFields>{getConcatRequiredFields(fieldValidationOptions())}</RequiredFields>
          )}
        </React.Fragment>
      }
    />
  );

  if (!medicalFiles.length) {
    return (
      <BlockLoader transparent />
    );
  }

  return <AppointmentStartLayout title={title} form={form} />;
};
