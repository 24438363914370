import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: rgba(243, 243, 243, 0.5);
  border-radius: 6px;
  padding: 18px;
  margin-top: 35px;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
`;

const CellSeparator = styled.div`
  margin: 0px 24px;
  font-size: 18px;
  line-height: 23px;
  color: #979797;
`;

const CellValue = styled.div`
  color: #262626;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
`;

const CellName = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #262626;
  margin-left: 4px;
`;

export const SummaryCells = ({ cells }) => {
  const filtered = cells.filter(c => c.value !== null && c.value !== 0);
  return (
    <Wrapper>
      {filtered.map((cell, index) => (
        <>
          <Cell key={cell.name}>
            <CellValue>{cell.value}</CellValue>
            <CellName>{cell.name}</CellName>
          </Cell>
          {index !== filtered.length - 1 && <CellSeparator>/</CellSeparator>}
        </>
      ))}
    </Wrapper>
  );
};
