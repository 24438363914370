// @flow
import * as React from "react";

import { useScrollDetector } from "./useScrollDetector";
import type { ScrollData } from "./useScrollDetector";
import { ShadowContainerDefault } from "./ShadowContainerDefault";
import { ScrollContainerDefault } from "./ScrollContainerDefault";

const TableContainer = (props: {
  shadowContainer?: React.ComponentType<any>,
  left?: boolean,
  right?: boolean,
  top?: boolean,
  bottom?: boolean,
  innerRef?: React.ElementRef<*>,
  fixedColumnWidth?: number,
  children: React.Node,
  onShadowContainerMount?: (element: React.ElementRef<*>) => void
}) => {
  const { shadowContainer, onShadowContainerMount, innerRef, ...rest } = props;
  const ShadowContainer = shadowContainer || ShadowContainerDefault;

  React.useEffect(() => {
    if (onShadowContainerMount) {
      onShadowContainerMount(innerRef);
    }
  }, []);

  return (
    <ShadowContainer {...rest} ref={innerRef} width={rest.fixedColumnWidth}>
      {rest.children}
    </ShadowContainer>
  );
};

const TableContainerMemoized = React.memo(TableContainer);

export const ScrollContext: React.Context<{
  setTableRef: React.ElementRef<*>,
  setFixedColumnRef: React.ElementRef<*>,
  setTableContainerRef: React.ElementRef<*>,
  setScrollContainerRef: React.ElementRef<*>
  // toggleTableRef: () => mixed
}> = React.createContext({
  setTableRef: undefined,
  setFixedColumnRef: undefined,
  setTableContainerRef: undefined,
  setScrollContainerRef: undefined
  // toggleTableRef: () => {}
});

export const ScrollDetector = (props: {
  children: React.Node,
  shadowContainer?: React.ComponentType<any>,
  scrollContainer?: React.ComponentType<any>,
  onShadowContainerMount?: (element: React.ElementRef<*>) => void,
  callback?: (scrollData: ScrollData) => mixed
}) => {
  const {
    canScrollLeft,
    canScrollRight,
    canScrollTop,
    canScrollBottom,
    table,
    tableContainer,
    scrollContainer
  } = useScrollDetector(props.callback);
  const ScrollContainer = props.scrollContainer || ScrollContainerDefault;
  const fixedColumn = React.useRef<React.ElementRef<*>>(null);

  const [fixedColumnWidth, setFixedColumnWidth] = React.useState(0);

  React.useEffect(() => {
    if (fixedColumn && fixedColumn.current) {
      setFixedColumnWidth(fixedColumn.current.offsetWidth);
    }
  });

  return (
    <ScrollContext.Provider
      value={{
        setScrollContainerRef: scrollContainer,
        setTableContainerRef: tableContainer,
        setTableRef: table,
        setFixedColumnRef: fixedColumn
      }}>
      <TableContainerMemoized
        shadowContainer={props.shadowContainer}
        onShadowContainerMount={props.onShadowContainerMount}
        innerRef={tableContainer}
        left={canScrollLeft}
        right={canScrollRight}
        top={canScrollTop}
        bottom={canScrollBottom}
        fixedColumnWidth={fixedColumnWidth}>
        <ScrollContainer ref={scrollContainer}>{props.children}</ScrollContainer>
      </TableContainerMemoized>
    </ScrollContext.Provider>
  );
};
