//@flow
import * as React from "react";
import {useState, useEffect} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";

import UserImg from "./icons/user.svg";
import LockImg from "./icons/lock.svg";
import {PhoneInputWithIcon, PhoneInputWithIconContainer} from "../../form-elements/PhoneInputWithIcon";
import {PrimaryButton, PrimaryButtonContainer} from "../../buttons/PrimaryButton";
import {DefaultButton} from "../../buttons/DefaultButton";
import {InputWithIcon, InputWithIconContainer} from "../../form-elements/InputWithIcon";
import {secondsToTime} from "../../../utils/secondsToTime";
import authStore from "../../../stores/authStore";

const Form = styled.form`
  margin-top: 43px;
  width: 398px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
  }

  ${InputWithIconContainer}, ${PhoneInputWithIconContainer} {
    margin-bottom: 13px;
  }

  ${InputWithIconContainer}:last-of-type, ${PhoneInputWithIconContainer}:last-of-type {
    margin-bottom: 32px;
  }
`;

const ButtonContainer = styled(PrimaryButtonContainer)`
  width: 244px;
  margin-bottom: 24px;
`;

const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const AuthForm = observer(() => {
  const [phone, setPhone] = useState("+7");
  const [code, setCode] = useState("");

  useEffect(() => {
    authStore.cleanFieldError('phone');
  }, [phone]);
  useEffect(() => {
    authStore.cleanFieldError('code');
  }, [code]);

  const handleSendSMS = async (event) => {
    event.preventDefault();

    try {
      await authStore.sendSms(phone);
    } catch(e) {
      console.error(e);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      await authStore.login(phone, code);
    } catch(e) {
      console.error(e);
    }
  };

  const phoneKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendSMS(e)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {
        !authStore.isCodeRequested ? (
          <>
            <PhoneInputWithIcon
              value={phone}
              icon={UserImg}
              onKeyDown={phoneKeyDown}
              name="phone"
              placeholder="Номер телефона"
              handleChange={setPhone}
              error={authStore.fieldErrors.phone}
            />
            <PrimaryButton onClick={handleSendSMS} primaryButtonContainer={ButtonContainer} type="button">
              Получить код в SMS
            </PrimaryButton>
          </>
        ) : (
          <>
            <InputWithIcon
              value={code}
              placeholder="Код из SMS"
              icon={LockImg}
              handleChange={setCode}
              type="password"
              autoFocus={true}
              overrides={{
                props: {
                  maxlength: 4
                }
              }}
              error={authStore.fieldErrors.code}
            />
            <VerticalLayout>
              {
                authStore.secondsToNextSend > 0 ? (
                  <span>
                    Код отправлен на указанный вами номер,<br/>
                    время до повторной отправки: {secondsToTime(authStore.secondsToNextSend)}
                  </span>
                ) : (
                  <DefaultButton type="button" onClick={handleSendSMS} disabled={!authStore.isSendButtonActive}>
                    Отправить код повторно
                  </DefaultButton>
                )
              }
            </VerticalLayout>
            <PrimaryButton primaryButtonContainer={ButtonContainer} type="submit">
              Войти
            </PrimaryButton>
          </>
        )
      }
    </Form>
  );
});
