import React from "react";
import { colors } from "../../styleguide/colors";

const Icon = props => {
  const { color = colors.gray600, size = 26 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.5684 31.5803H20.6425M41.5684 31.5803L36.0439 25.4955M41.5684 31.5803L36.0439 37.6659M62.0591 32C62.0591 48.601 48.6013 62.0588 32.0002 62.0588C15.3992 62.0588 1.94141 48.601 1.94141 32C1.94141 15.399 15.3992 1.94116 32.0002 1.94116C48.6013 1.94116 62.0591 15.399 62.0591 32Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
