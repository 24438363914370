import * as Sentry from "@sentry/react";

export const socketErrorHandler = (socket, onReconnect, timeout = 5000) => {
  return (event) => {
    const reconnectWebSocket = () => {
      setTimeout(() => onReconnect(), timeout);
    };

    switch (event.code) {
      case 1000:
        // Нормальное закрытие, возможно, переподключение не требуется
        break;
      case 1001:
      case 1012:
      case 1013:
        // Сервер уходит или перегружен, переподключение через некоторое время
        console.log(`WebSocket[${socket.url}]: Сервер уходит или перегружен, пытаемся переподключиться...`);
        reconnectWebSocket();
        break;
      case 1002:
      case 1003:
      case 1007:
      case 1008:
      case 1009:
      case 1010:
        // Эти коды могут указывать на проблему с протоколом или данными,
        // возможно, переподключение сразу не имеет смысла, нужно разбираться в причинах
        console.error(`WebSocket[${socket.url}]: Проблема с протоколом или данными, требуется диагностика.`);
        break;
      case 1011:
        // Внутренняя ошибка сервера, переподключение через некоторое время
        console.log(`WebSocket[${socket.url}]: Внутренняя ошибка сервера, пытаемся переподключиться...`);
        reconnectWebSocket();
        break;
      case 1006:
      default:
        // Ненормальное закрытие или неизвестный код, переподключение через некоторое время
        console.log(`WebSocket[${socket.url}]: Ненормальное закрытие или неизвестный код ошибки, пытаемся переподключиться...`);
        reconnectWebSocket();
        break;
    }

    if (process.env.NODE_ENV === "production" && typeof Sentry !== "undefined") {
      console.log(event);
      Sentry.captureEvent(event);
    }
  };
};