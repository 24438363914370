// @flow
import type { Doctor } from "../entities/Doctor";

export const getLastNameWithInitials = (doctor: Doctor): string => {
  if (!doctor) {
    return "";
  }
  const first_nameLetter = doctor.first_name.length > 0 && doctor.first_name[0];
  const middle_nameLetter = doctor.last_name.length > 0 && doctor.middle_name[0];
  return `${doctor.last_name} ${first_nameLetter ? first_nameLetter + "." : ""}${
    middle_nameLetter ? middle_nameLetter + "." : ""
  }`;
};

export const getFullNameWithInitials = (fullName): string => {
  if (!fullName) {
    return "";
  }
  const splitFullName = fullName.split(' ');
  return `${splitFullName[0]} ${splitFullName[1][0] + ". "}${splitFullName[2][0] + "."}`;
};
