import React, { useEffect, useMemo } from "react";
import ReactDOMServer from 'react-dom/server';
import { useAsync } from "react-use";
import useReactRouter from "use-react-router";
import { useStore } from "effector-react";

import { phases } from "../EmbryosManipulations/EmbryosManipulations";
import { VIEW_TYPES } from "../constants";

import { tokenStore } from "../../../stores/auth";

import { getPatientFullName } from "../../../domain/services/patient";
import { getLastNameWithInitials } from "../../../domain/services/doctor";

import { formatDateToReadableDateFormat } from "../../../utils/dates";
import { arrayToEntriesById, uniqueFilter } from "../../../utils/arrays";

import { useBeforePrint } from "../../appointment-print/useBeforePrint";
import { useAfterPrint } from "../../appointment-print/useAfterPrint";

import * as S from "./styled/Document.styled";
import printEditorStore, {PrintEditorEvents} from '../../../stores/printEditorStore';
import apiv2 from '../../../apiv2';

export const CryoTransfer = () => {
  const {
    history,
    match: { params }
  } = useReactRouter();

  const token = useStore(tokenStore);

  const searchParams = new URLSearchParams(location.search);
  const needEdit = searchParams.get('needEdit') === 'true';

  const {
    value: { medicalFile, defrostEmbryos, documentData, manipulations } = {},
    loading: loading
  } = useAsync(async () => {
    const medicalFile = await apiv2.medicalFiles.getById(params.medical_file_id);

    return {
      medicalFile,
      defrostEmbryos: await apiv2.embryos.getEmbryosByFolderId(params.folder_id, VIEW_TYPES.DEFROSTS),
      documentData: await apiv2.embryos.getEmbryosPrintDocumentData({
        folderId: params.folder_id,
        patientId: medicalFile.patient.id,
        type: "by_order_107"
      }),
      manipulations: await apiv2.embryos.getEmbryosManipulations(params.folder_id)
    };
  });

  const manipulationsByPhase = useMemo(
    () => manipulations && arrayToEntriesById(manipulations, "phase"),
    [manipulations]
  );

  useBeforePrint(!loading && !needEdit);

  useAfterPrint(`/appointment/folder/${params.folder_id}/embryos/${params.medical_file_id}`);

  let contentForPrint;

  useEffect(() => {
    if (needEdit && contentForPrint && !printEditorStore.isVisible) {
      const data = ReactDOMServer.renderToString(contentForPrint);
      printEditorStore.setData(data);
      printEditorStore.show();
    }
  }, [loading, contentForPrint]);

  useEffect(() => {
    const afterPrint = () => {
      if (!needEdit) {
        history.replace(`/appointment/folder/${params.folder_id}/embryos/${params.medical_file_id}`);
      }
    };
    window.addEventListener("afterprint", afterPrint, false);
    const unsubscribeBack = printEditorStore.addEventListener(PrintEditorEvents.back, () => {
      history.replace(`/appointment/folder/${params.folder_id}/embryos/${params.medical_file_id}`);
      printEditorStore.clearEventListeners(PrintEditorEvents.back);
    });
    return () => {
      window.removeEventListener("afterprint", afterPrint);
      unsubscribeBack();
    }
  }, []);

  if (loading) {
    return "Загрузка ...";
  }

  contentForPrint = (
    <S.Container>
      <S.H2 height={104}>Перенос криоконсервированных эмбрионов</S.H2>
      <S.Table>
        <S.TableBody>
          <S.Row>
            <S.Cell>ФИО</S.Cell>
            <S.Cell bold>{getPatientFullName(medicalFile.patient)}</S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>Дата размораживания</S.Cell>
            <S.Cell bold>
              {defrostEmbryos
                .map(({ defrost_date }) => defrost_date)
                .filter(item => item)
                .map(defrost_date => defrost_date && formatDateToReadableDateFormat(defrost_date))
                .filter(uniqueFilter)
                .join(", ")}
            </S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>Число размороженных эмбрионов</S.Cell>
            <S.Cell bold>{documentData.result.defrost_embryo_count}</S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>Выживаемость</S.Cell>
            <S.Cell bold>
              {defrostEmbryos
                .map(({ survival }) => survival)
                .filter(item => item)
                .map(survival => `${survival}%`)
                .filter(uniqueFilter)
                .join(", ")}
            </S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell colSpan={2}>Фрагментация эмбрионов:</S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>{"<50%"}</S.Cell>
            <S.Cell bold>
              {defrostEmbryos.some(({ fragmentation }) => fragmentation === "less50") && "+"}
            </S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>{">50%"}</S.Cell>
            <S.Cell bold>
              {defrostEmbryos.some(({ fragmentation }) => fragmentation === "more50") && "+"}
            </S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>{"100%"}</S.Cell>
            <S.Cell bold>
              {defrostEmbryos.some(({ fragmentation }) => fragmentation === "full") && "+"}
            </S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>Дата ЭТ</S.Cell>
            <S.Cell bold>{documentData.result.transfer_embryo_date}</S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>Число переносимых эмбрионов</S.Cell>
            <S.Cell bold>{documentData.result.transfer_embryo_count}</S.Cell>
          </S.Row>
          <S.Row>
            <S.Cell>Стадии развития эмбрионов на момент переноса</S.Cell>
            <S.Cell bold>
              {defrostEmbryos
                .map(({ final_assessment }) => final_assessment)
                .filter(item => item)
                .filter(uniqueFilter)
                .join(", ")}
            </S.Cell>
          </S.Row>
        </S.TableBody>
      </S.Table>
      <S.Notes />
      <S.Sign
        specialization="Эмбриолог"
        doctor={
          manipulationsByPhase[phases.defrost].doctor &&
          getLastNameWithInitials(manipulationsByPhase[phases.defrost].doctor)
        }
      />
    </S.Container>
  );

  if (needEdit) {
    return;
  }

  return contentForPrint;
};
