import styled from "styled-components";
import {HeadingH2} from "../styleguide/typography";
import {RoundedRectangleWithShadow} from "../styleguide/RoundedRectangleWithShadow";

export const Container = styled.div`
  max-width: 1024px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: auto;
`;

export const ContainerShadow = styled(RoundedRectangleWithShadow)`
  max-width: 610px;
  width: 100%;
  padding: 63px 0 101px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(HeadingH2)`
  margin-bottom: 30px;
  text-align: center;
`;