// @flow
import * as React from "react";
import styled from "styled-components";
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { Fieldset, Container as FieldsetContainer, Legend } from "../form/Fieldset";
import { colors } from "../styleguide/colors";
import { Link } from 'react-router-dom';

const Container = styled(RoundedRectangleWithShadow)`
  max-width: 1023px;
  width: 100%;
  box-sizing: border-box;
  padding: 26px 39px 33px 33px;
  @media (max-width: 768px) {
    width: auto;
    padding: 25px 30px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

const SelectContainer = styled.div`
  max-width: 516px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 79px;
  padding-top: 38px;
  border-top: solid 1px ${colors.transparentGray};

  @media screen and (max-width: 768px) {
    margin-top: 40px;
    padding-top: 30px;
  }

  @media screen and (max-width: 480px) {
    margin-top: 25px;
    padding-top: 15px;
  }
`;

const LayoutFieldsetContainer = styled(FieldsetContainer)`
  margin-top: 28px;
`;

const LayoutLegend = styled(Legend)`
  top: 0;
`;

const LegendWithHelp = styled.div`
    display: inline-flex;
    align-items: center;
`;

const HelpIconContainer = styled.div`
  display: inline-flex;
  color: rgba(0, 0, 0, 0.26);
  margin-left: 8px;
  
  svg {
    width: 20px;
    height: 20px;
  }
`;

const HelpTextContainer = styled.div`
  text-align: center;
  
  a {
    color: ${colors.paleGrey};
  }
`;

export const AppointmentStartFormLayout = (props: {
  categoriesAutocomplete: React.Node,
  appointmentsAutocomplete: React.Node,
  layoutButtonRadioGroup?: React.Node,
  medicalFilesButtonRadioGroup?: React.Node,
  folderButtonRadioGroup?: React.Node,
  folderTypeButtonRadioGroup?: React.Node,
  submitButton: React.Node
}) => {
  return (
    <Container as="form" onSubmit={e => e.preventDefault()}>
      <Fieldset
        legend="Категория"
        required={false}
        overrides={{
          Container: {
            component: LayoutFieldsetContainer
          },
        }}>
        <SelectContainer>{props.categoriesAutocomplete}</SelectContainer>
      </Fieldset>
      {(props.layoutButtonRadioGroup && (
        <Fieldset
          legend={(
            <LegendWithHelp>
              Шаблон
              <HelpIconContainer>
                <Tooltip title={
                  <HelpTextContainer>
                    Добавить шаблон в популярные можно <br/>
                    <Link to="/profile/popular-layouts">на странице профиля</Link>
                  </HelpTextContainer>
                }>
                  <HelpOutlineIcon/>
                </Tooltip>
              </HelpIconContainer>
            </LegendWithHelp>
          )}
          required={true}
          overrides={{
            Container: {
              component: LayoutFieldsetContainer
            },
            Legend: {
              component: LayoutLegend
            }
          }}>
          {props.layoutButtonRadioGroup}
        </Fieldset>
      )) ||
        null}
      <Fieldset
          legend="Создать на основе"
          required={false}
          overrides={{
              Container: {
                  component: LayoutFieldsetContainer
              },
          }}>
          <SelectContainer>{props.appointmentsAutocomplete}</SelectContainer>
      </Fieldset>
      {(props.medicalFilesButtonRadioGroup && (
        <Fieldset
          legend="Амбулаторная карта"
          required={true}
          overrides={{
            Container: {
              component: LayoutFieldsetContainer
            },
            Legend: {
              component: LayoutLegend
            }
          }}>
          {props.medicalFilesButtonRadioGroup}
        </Fieldset>
      )) ||
        null}
      {(props.folderTypeButtonRadioGroup && (
        <Fieldset
          legend="Тип события"
          overrides={{
            Container: {
              component: LayoutFieldsetContainer
            },
            Legend: {
              component: LayoutLegend
            }
          }}>
          {props.folderTypeButtonRadioGroup}
        </Fieldset>
      )) ||
        null}

      {(props.folderButtonRadioGroup && (
        <Fieldset
          legend="Событие"
          overrides={{
            Container: {
              component: LayoutFieldsetContainer
            },
            Legend: {
              component: LayoutLegend
            }
          }}>
          {props.folderButtonRadioGroup}
        </Fieldset>
      )) ||
        null}
      <ButtonContainer>{props.submitButton}</ButtonContainer>
    </Container>
  );
};
