import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  MoreHoriz as MoreHorizIcon,
  ExpandLess,
  ExpandMore
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

const DropDownContainer = styled(Box)(
  ({ view, backlight }) => `
    position: ${view === "iconButton" || view === "container" ? "absolute" : "static"};
    ${
      view === "iconButton"
        ? `
            top: 0;
            right: 0;
          `
        : view === "container"
        ? `
            width: 100%;
            height: 100%;
          `
        : ``
    }
    ${!!backlight &&
      `
        .MuiButtonBase-root {
          background: ${
            backlight === "active" ? "rgba(198, 0, 127, 0.1)" : "rgba(38, 38, 38, 0.1)"
          };
          &:hover {
            background: ${
              backlight === "active" ? "rgba(198, 0, 127, 0.2)" : "rgba(38, 38, 38, 0.2)"
            } ;
          }
        }
      `}
  `
);

const MenuItemStyled = styled(MenuItem)(
  ({ highlighted, left_indent }) => `
    ${
      highlighted === "true"
        ? `
          background: rgba(252, 235, 246, 1);
          &:hover {
            background: rgba(251, 219, 240, 1);
          }
        `
        : ``
    }
    ${left_indent === "true" ? `padding-left: 30px;` : ``}
  `
);

const Badge = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.primary.main};
    color: white;
    font-weight: 500;
    font-size: 12px;
    border-radius: 99em;
    padding-inline: 6px;
    line-height: 18px;
    min-width: 20px;
    margin-left: 10px;
    text-align: center;
    box-sizing: border-box;
  `
);

const MenuItems = ({ items, close }) => {
  const [expanded, setExpanded] = useState({});

  const handleExpandClick = item => {
    setExpanded({ ...expanded, [item.name]: !expanded[item.name] });
  };
  const handleItemClick = item => {
    if (item.handleOnClick) {
      item.handleOnClick();
      !item.disabledCloseAfterClick && close();
    }
    if (item.enabledCollapse) {
      handleExpandClick(item);
    }
  };

  return items.map((item, index) => {
    if (item.name === "divider") {
      return <Divider key={item.name} />;
    }

    if (item.enabledCollapse && item.collapseData.length) {
      return (
        <React.Fragment key={`collapse__${item.name}`}>
          <MenuItemStyled onClick={() => handleItemClick(item)}>
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>{item.name}</ListItemText>
            {!!item.enabledCollapse && (expanded[item.name] ? <ExpandLess /> : <ExpandMore />)}
          </MenuItemStyled>
          <Collapse in={expanded[item.name]} timeout="auto" unmountOnExit>
            {item.collapseData.map((item, index) => {
              return (
                <MenuItemStyled
                  key={`${index}_${item.text}`}
                  left_indent="true"
                  onClick={() => handleItemClick(item)}>
                  <ListItemText>{item.text}</ListItemText>
                </MenuItemStyled>
              );
            })}
          </Collapse>
        </React.Fragment>
      );
    }

    return (
      <Tooltip key={`${index}_${item.name}`} title={item.tooltipText ? item.tooltipText : ""} placement={item.tooltipPlacement ? item.tooltipPlacement : "bottom"}>
        <MenuItemStyled
          highlighted={item?.highlighted || "false"}
          onClick={() => handleItemClick(item)}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText primary={item.name} secondary={item?.subText || ""} />
          {!!item.badgeCount && <Badge>{item.badgeCount}</Badge>}
        </MenuItemStyled>
      </Tooltip>
    );
  });
};

export const DropDownMenu = ({
  menuItems,
  view = "iconButton",
  variant = "vertical",
  anchorOrigin = {
    vertical: "center",
    horizontal: "right"
  },
  transformOrigin = {
    vertical: "center",
    horizontal: "left"
  },
  backlight = "",
  textButton = "",
  disabled = false,
  maxHeight = 450,
  minWidth = 300
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const open = event => {
    setAnchorEl(event.currentTarget);
  };
  const close = () => {
    setAnchorEl(null);
  };

  return (
    <DropDownContainer view={view} backlight={backlight}>
      {view === "iconButton" || view === "item" ? (
        <IconButton onClick={open} size="small">
          {variant === "vertical" ? <MoreVertIcon /> : <MoreHorizIcon />}
        </IconButton>
      ) : view === "button" ? (
        <Button variant="outlined" onClick={open} disabled={disabled}>{textButton}</Button>
        ) : (
        <Button sx={{ width: "100%", height: "100%" }} color="info" onClick={open} />
      )}
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={close}
        disableScrollLock
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={{
          paper: {
            style: {
              maxHeight,
              minWidth,
            }
          }
        }}>
        <MenuItems items={menuItems} open={open} close={close} />
      </Menu>
    </DropDownContainer>
  );
};
