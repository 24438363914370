import { css } from 'styled-components';

export const appointmentHeaderTableStyles = css`
    hr {
        height: 0;
        border: none;
        border-bottom: solid 1px rgba(151,151,151,0.3);
    }

    .appointment-header-table, .table table.appointment-header-table {
        width: 100%;
        border: none;
        border-bottom: double 1px rgba(151,151,151,0.3) !important;

        td, th {
            padding: 0;
            height: auto;
            border-color: transparent;
        }

        .table {
            margin: 0;

            table {
                border: none;
            }
        }

        .table table td, .table table th {
            padding: 0;
            border-color: transparent;
        }

        figure.table {
            margin: 0;
        }

        > tbody > tr > td {
            padding-bottom: 24px !important;

            &:first-child {
                vertical-align: top;
            }
        }

        .appointment-header-table__values[border="0"] {
            width: 90%;
            float: right;
            border-left: double 1px rgba(151,151,151,0.3) !important;

            td:first-child {
                vertical-align: top;
                width: 45%;
            }

            table {
                td {
                    vertical-align: top;

                    &:first-child {
                        padding-left: 32px;
                        width: 0;
                        white-space: nowrap;
                    }

                    &:last-child {
                        padding-left: 8px;
                    }
                }
            }
        }
    }
`;