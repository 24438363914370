import styled from "styled-components";
import { rgba } from "polished";

import searchIcon from "./icons/search-big.svg";

import { colors } from "../styleguide/colors";

export const Container = styled.div`
  position: relative;
  width: 427px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 13px 17px;
  border: 1px solid ${rgba(colors.darkBlack, 0.2)};
  border-radius: 3px;
  background: transparent;
  font-size: 17px;

  &:focus {
    background: ${colors.white};
    outline: none;
    // border-color: ${rgba(colors.darkBlack, 0.2)};
  }
`;

export const SearchIcon = styled.div`
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  background: url(${searchIcon}) no-repeat;
  background-size: contain;
`;
