import React from "react";

export const CrossIcon = ({style}) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.52018 3.81295C4.32492 3.61769 4.00833 3.61769 3.81307 3.81295C3.61781 4.00821 3.61781 4.3248 3.81307 4.52006L20.1262 20.8332L4.63583 36.3236C4.44056 36.5189 4.44056 36.8355 4.63583 37.0307C4.83109 37.226 5.14767 37.226 5.34293 37.0307L20.8333 21.5403L36.3237 37.0307C36.519 37.226 36.8356 37.226 37.0309 37.0307C37.2261 36.8355 37.2261 36.5189 37.0309 36.3236L21.5405 20.8332L37.8536 4.52006C38.0489 4.3248 38.0489 4.00821 37.8536 3.81295C37.6584 3.61769 37.3418 3.61769 37.1465 3.81295L20.8333 20.1261L4.52018 3.81295Z"
            fill="var(--svg-fill, #000)"/>
    </svg>
  );
};
