import { makeAutoObservable } from 'mobx';
import { reloadAppointments } from "../components/appointment-history/appointmentHistoryStore";

class FilterAppointmentsStore {
    onlyPatient = false;

    constructor() {
        makeAutoObservable(this);
    }

    setOnlyPatient(onlyPatient) {
        this.onlyPatient = onlyPatient;
        reloadAppointments();
    }
}

export default new FilterAppointmentsStore();
