//@flow
import React from "react";
import Dialog from "@mui/material/Dialog";
import { IconButton, useMediaQuery } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";

export const ScheduleInsertModal = ({ title, isShown, hide, form, afterFormContent }) => {
  const isMobileDevice = useMediaQuery("(max-width: 768px)");

  return (
    <Dialog
      open={isShown}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          return hide();
        }
      }}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobileDevice}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={hide}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {form}
        {afterFormContent ? afterFormContent : null}
      </DialogContent>
    </Dialog>
  );
};
