export const ScheduleType = {
  ONLINE: 'online',
  IN_CLINIC: 'in_clinic',
  ONLINE_AND_IN_CLINIC: 'online_and_in_clinic',
  CHAT: 'chat',
};

export const isOnlineSchedule = (type) => {
  return type === ScheduleType.ONLINE;
};

export const isChatSchedule = (type) => {
  return type === ScheduleType.CHAT;
};

export const isScheduleWithChat = (type) => {
  return isOnlineSchedule(type) || isChatSchedule(type);
};
