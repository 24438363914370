// @flow

export type Drug = {
  id: number,
  name: string
};

export opaque type TDrugId: number = number;

export const getDrugId = (drug: Drug): TDrugId => drug.id;
