import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ContentContainer = styled(Box)(
  ({ theme }) => `
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${theme.breakpoints.down("tablet")} {
    gap: 25px;
  }
`
);

export const FormContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  ${theme.breakpoints.down("tablet")} {
    width: auto;
  }
`
);

export const ActionsContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  .MuiButtonBase-root {
    min-width: 100px;
  }
  ${theme.breakpoints.down("tablet")} {
    flex-direction: column-reverse;
  }
`
);
