import React, { useCallback, useContext, useEffect, useRef } from "react";

import styled from "styled-components";

import * as S from "./EmbryosManipulations.styled";
import { BaseStyledInput } from "../../form-elements/BaseStyledInput";

import { EmbryosManipulationsContext } from "./EmbryosManipulationsContext";

const Input = styled(BaseStyledInput)`
  width: 100%;
  box-sizing: border-box;
  border: none;
  resize: none;
  min-height: inherit !important;
  padding: 10px 15px;
  line-height: 1.475;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 15px;
  height: 44px;
`;

export const EmbryosManipulationsTimeInput = ({ cell }) => {
  const { onUpdate } = useContext(EmbryosManipulationsContext);

  const $inputRef = useRef(null);

  const handleUpdate = useCallback(
    e => {
      onUpdate({
        ...cell,
        value: e.currentTarget.value
      });
    },
    [onUpdate, cell]
  );

  useEffect(() => {
    const selection = window.getSelection();

    if (selection) {
      window.getSelection().removeAllRanges();
    }

    $inputRef.current.focus();
  }, []);

  return (
    <S.InputContainer width={110}>
      <Input ref={$inputRef} as="input" type="time" value={cell.value} onInput={handleUpdate} />
    </S.InputContainer>
  );
};
