import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class ScheduleInserts {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  postScheduleInserts(scheduleValues) {
    return this.apiService.post("schedule-inserts", {}, scheduleValues);
  }

  getListScheduleInserts({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params}) {
    return this.apiService.post(`dynamic/scheduleinsert/list`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params}, {}, true, "", true);
  }

  postRecordScheduleInserts(params) {
    return this.apiService.post("schedule-inserts-v2", {}, { ...params });
  }

  postCancelScheduleInserts(params) {
    return this.apiService.post("integration/schedule/cancel", {}, { ...params }, { Secretkey: 'secret' });
  }

  postAdditionalRecordingAction(params) {
    return this.apiService.post('schedule-inserts-v2/buttons', {}, { ...params });
  }

  getDocumentForPrint(params) {
    return this.apiService.get("schedule-inserts-v2/document-print", { ...params });
  }
}

export default new ScheduleInserts();
