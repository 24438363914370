// @flow
import * as React from "react";
import styled from "styled-components";
import { SidebarHeader } from "../sidebar/SidebarHeader";
import { SidebarLayout } from "../sidebar/SidebarLayout";
import { SidebarFade } from "../sidebar/SidebarFade";
import { useSidebarFade } from "../sidebar/useSidebarFade";
import { closeFeedbackSidebar } from "../../stores/feedbacks/feedbackSections";
import { useStore } from "effector-react";
import {
  currentFeedbackFieldsStore,
  currentFeedbackResponseStore,
  currentFeedbackStore,
  sendFeedbackComplete,
  sendFeedbackLoad
} from "../../stores/feedbacks/currentFeedback";
import { PPrintNormal, PSmall } from "../styleguide/typography";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { geFeedbackId } from "../../domain/entities/Feedback";
import completeIcon from "./icons/icons-clock.svg";
import phoneIcon from "./icons/phone.svg";
import { colors } from "../styleguide/colors";
import { Textarea } from "../form-elements/Textarea";
import arrowIcon from "./icons/arrow.svg";
import { PrimaryButtonSmall } from "../buttons/PrimaryButtonSmall";
import * as R from "ramda";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";

const FEEDBACK_URL = process.env.FEEDBACK_URL;

const Container = styled.div`
  padding: 33px 38px 41px 24px;
`;

const ResponseContainer = styled.div`
  margin-top: 6px;
`;

const QuestionContainer = styled.div`
  & + & {
    margin-top: 41px;
  }

  & > & {
    margin-top: 20px;
  }
`;

const QuestionTitle = styled(PSmall)`
  opacity: 0.6;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.3;
  background: #979797;
`;

const ButtonContainer = styled.div`
  padding: 42px 38px 42px 24px;
`;

const CompleteIcon = styled.div`
  width: 23px;
  height: 23px;
  background: url(${completeIcon});
`;

const CallbackContainer = styled.div`
  border-left: solid 4px ${colors.magenta};

  width: 100%;
  box-sizing: border-box;
  padding: 27px 24px 46px 20px;
`;

const PhoneContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`;

const PhoneIcon = styled.div`
  width: 17px;
  height: 17px;
  background: url(${phoneIcon}) no-repeat;
  background-size: cover;

  margin-right: 12px;
`;

const CommentContainer = styled.div`
  margin-top: 32px;
`;

const HintText = styled(PSmall)`
  opacity: 0.6;

  margin-bottom: 6px;
`;

const SaveContainer = styled.div`
  margin-top: 30px;
`;

const CallbackViewContainer = styled.div`
  padding: 30px 24px 49px;
`;

const FeedbackLink = styled.a`
  font-size: 16px;
  line-height: 1.69;
  color: ${colors.magenta};
  display: block;
  position: relative;

  &:after {
    content: "";
    background: url(${arrowIcon});
    width: 24px;
    height: 24px;
    margin-left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
  }
`;

const FeedbackLinkContainer = styled.div`
  width: 262px;
  margin-top: 12px;
`;

const DoctorFeedbackHintContainer = styled.div`
  margin-top: 29px;
  opacity: 0.6;
`;

const DoctorFeedbackButtonContainer = styled.div`
  margin-top: 23px;
`;

const DoctorFeedbackCallbackContainer = styled.div`
  padding: 26px 24px;
`;

const getPhoneNumber = R.view(R.lensPath(["medical_file", "patient", "phone_number"]));

export const ShortcutsForView = (props: {
  title?: string,
  appointment?: AppointmentWithDetails
}) => {
  const { title, appointment } = props;
  const shouldStartTransition = useSidebarFade();
  const [comment, setComment] = React.useState("");

  const currentFeedback = useStore(currentFeedbackStore);
  const currentFeedbackFields = useStore(currentFeedbackFieldsStore);
  const currentFeedbackResponse = useStore(currentFeedbackResponseStore);

  const header = (
    <SidebarHeader
      onClose={closeFeedbackSidebar}
      icon={currentFeedback && currentFeedback.completed_at ? <CompleteIcon /> : null}
      title={title || "Обратная связь"}
    />
  );

  const renderFields = (allFields, depends_on) => {
    const fields =
      allFields &&
      allFields.filter(
        field =>
          (!depends_on && !field.depends_on) ||
          (field.depends_on && depends_on && field.depends_on.field_id === depends_on.field_id)
      );
    return (
      fields &&
      fields.map(currentFeedbackField => {
        const response =
          currentFeedbackResponse &&
          currentFeedbackResponse.values.find(fieldResponse => {
            return fieldResponse.field_id === currentFeedbackField.id;
          });
        return (
          <>
            {response && response.value && (
              <QuestionContainer>
                <QuestionTitle>{currentFeedbackField.name}</QuestionTitle>
                <ResponseContainer>
                  <PPrintNormal>{response.value.join(", ")}</PPrintNormal>
                </ResponseContainer>
                {renderFields(allFields, { field_id: currentFeedbackField.id })}
              </QuestionContainer>
            )}
          </>
        );
      })
    );
  };

  return (
    <SidebarFade>
      {shouldStartTransition && (
        <SidebarLayout header={header}>
          {currentFeedback && !currentFeedback.response && (
            <>
              <CallbackContainer>
                <PPrintNormal>
                  Пациент пришел на повторный прием, но не заполнил форму обратной связи. Если опрос
                  еще актуален, пожалуйста, заполните анкету
                </PPrintNormal>
                <FeedbackLinkContainer>
                  <FeedbackLink
                    target="_blank"
                    href={`${FEEDBACK_URL || ""}/form/d/${currentFeedback.id}`}>
                    Заполнить анкету обратной связи
                  </FeedbackLink>
                </FeedbackLinkContainer>
                <DoctorFeedbackHintContainer>
                  <PSmall>
                    После заполнения анкеты нажмите кнопку загрузить ответы в карту, чтобы они
                    прикрепились к завершенному приему:
                  </PSmall>
                </DoctorFeedbackHintContainer>
                <DoctorFeedbackButtonContainer>
                  <PrimaryButtonSmall
                    onClick={() => {
                      currentFeedback &&
                        sendFeedbackLoad({
                          id: geFeedbackId(currentFeedback),
                          doctors_comment: comment
                        });
                    }}>
                    загрузить ответы в карту
                  </PrimaryButtonSmall>
                </DoctorFeedbackButtonContainer>
              </CallbackContainer>
              <Separator />
              <DoctorFeedbackCallbackContainer>
                <PPrintNormal>Если опрос неактуален, пожалуйста, скажите почему</PPrintNormal>
                <CommentContainer>
                  <HintText>Комментарий</HintText>
                  <Textarea value={comment} handleChange={setComment} />
                </CommentContainer>
                <SaveContainer>
                  <PrimaryButton
                    disabled={comment.length === 0}
                    onClick={() =>
                      currentFeedback &&
                      sendFeedbackComplete({
                        id: geFeedbackId(currentFeedback),
                        doctors_comment: comment
                      })
                    }>
                    Сохранить
                  </PrimaryButton>
                </SaveContainer>
              </DoctorFeedbackCallbackContainer>
            </>
          )}
          <Container>
            {currentFeedback && currentFeedback.response && currentFeedbackResponse && (
              <>
                {renderFields(currentFeedbackFields, null)}
                {currentFeedbackFields &&
                  currentFeedbackResponse &&
                  currentFeedbackResponse.rating && (
                    <QuestionContainer>
                      <QuestionTitle>Оцените ваше лечение от 1 до 5</QuestionTitle>
                      <ResponseContainer>
                        <PPrintNormal>{currentFeedbackResponse.rating}</PPrintNormal>
                      </ResponseContainer>
                    </QuestionContainer>
                  )}
                {currentFeedbackFields && (
                  <QuestionContainer>
                    <QuestionTitle>
                      Вы хотели бы, чтобы ваш лечащий врач связался с вами для обсуждения
                      проведенного лечения?
                    </QuestionTitle>
                    <ResponseContainer>
                      <PPrintNormal>
                        {currentFeedbackResponse && currentFeedbackResponse.needs_callback
                          ? "Да"
                          : "Нет"}
                      </PPrintNormal>
                    </ResponseContainer>
                  </QuestionContainer>
                )}
              </>
            )}
          </Container>
          {currentFeedback &&
          currentFeedback.response &&
          !currentFeedback.completed_at &&
          (currentFeedbackResponse && !currentFeedbackResponse.needs_callback) &&
          (currentFeedbackResponse && !currentFeedbackResponse.created_by_doctor) ? (
            <>
              <Separator />
              <ButtonContainer>
                <PrimaryButton
                  onClick={() =>
                    currentFeedback &&
                    sendFeedbackComplete({
                      id: geFeedbackId(currentFeedback)
                    })
                  }>
                  Просмотрено
                </PrimaryButton>
              </ButtonContainer>
            </>
          ) : null}
          {currentFeedback &&
          currentFeedback.response &&
          !currentFeedback.completed_at &&
          (currentFeedbackResponse && currentFeedbackResponse.needs_callback) ? (
            <>
              <Separator />
              <CallbackContainer>
                <PPrintNormal>Пациент запросил звонок по поводу лечения</PPrintNormal>
                <PhoneContainer>
                  <PhoneIcon />
                  <PPrintNormal>{getPhoneNumber(appointment)}</PPrintNormal>
                </PhoneContainer>
                <CommentContainer>
                  <HintText>Комментарий после звонка пациенту</HintText>
                  <Textarea value={comment} handleChange={setComment} />
                </CommentContainer>
                <SaveContainer>
                  <PrimaryButton
                    disabled={comment.length === 0}
                    onClick={() =>
                      currentFeedback &&
                      sendFeedbackComplete({
                        id: geFeedbackId(currentFeedback),
                        doctors_comment: comment
                      })
                    }>
                    Сохранить
                  </PrimaryButton>
                </SaveContainer>
              </CallbackContainer>
            </>
          ) : null}
          {currentFeedback &&
          currentFeedback.response &&
          currentFeedback.completed_at &&
          currentFeedback.doctors_comment ? (
            <>
              <Separator />
              <CallbackViewContainer>
                <HintText>Комментарий после звонка пациенту</HintText>
                <PPrintNormal>{currentFeedback.doctors_comment}</PPrintNormal>
              </CallbackViewContainer>
            </>
          ) : null}
        </SidebarLayout>
      )}
    </SidebarFade>
  );
};
