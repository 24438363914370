import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class MedicalFiles {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  get({
    search,
    patientId
  }) {
    return this.apiService.get(`medical-files`, {
      search: search,
      patient_id: patientId
    }, {}, true, '');
  }

  getListMedicalFiles({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params, is_after_get}) {
    return this.apiService.post(`medical-files-search`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params, is_after_get}, {}, true, "", true);
  }

  getDynamicMedicalFileById({id}) {
    return this.apiService.get(`dynamic/medicalfile/retrieve/${id}`, {}, {}, true, '', true);
  }

  postDynamicMedicalFileById(data) {
    return this.apiService.post(`dynamic/medicalfile/update`, {}, data);
  }

  sendRees({filter_params, values_params, order_by_params, annotate_mtm_params, communication}) {
    return this.apiService.post(`medical-files-search/communication/send`, {}, {filter_params, values_params, order_by_params, annotate_mtm_params, communication}, {}, true, "", true);
  }


  getById(id) {
    return this.apiService.get(`medical-files/${id}`, {}, {}, true, '', true);
  }

  getByNumber(number) {
    return this.apiService.get(`medical-files-by-number/${number}`, {}, {}, true, '', true);
  }
}

export default new MedicalFiles();
