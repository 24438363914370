// @flow
import React, { useRef } from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { usePosition } from "../../utils/usePosition";
import { ListContainer } from "../select/ListContainer";
import { OptionsSeparator } from "../select/OptionsSeparator";

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 61px;
  position: relative;

  background: ${colors.white};
  box-shadow: inset 0 -4px 6px 0 rgba(0, 0, 0, 0.06);
`;

const ShadowContainer = styled.div`
  position: fixed;
  z-index: 100;
  width: 162px;
  padding-top: 60px;
  border-radius: 3px;
  box-shadow: 0 2px 17px 0 rgba(91, 91, 91, 0.26);
  pointer-events: none;
`;

const PortalStub = ({ children }) => <>{children}</>;

export const DrugSelectLayout = (props: {
  input: React$Node,
  children: React$Node,
  addButton: React$Node,
  Portal?: React$ComponentType<any>
}) => {
  const { input, children, addButton } = props;
  const Portal = props.Portal || PortalStub;
  const ref = useRef(null);
  const [position] = usePosition(ref);

  return (
    <InputContainer ref={ref}>
      {input}
      <Portal>
        <ShadowContainer
          aria-hidden="true"
          style={{
            left: `${(position && position.left) || 0}px`,
            top: `${(position && position.top) || 0}px`
          }}>
          <ListContainer role="menu">
            {children}
            <OptionsSeparator />
            {addButton}
          </ListContainer>
        </ShadowContainer>
      </Portal>
    </InputContainer>
  );
};
