//@flow
import React from "react";
import styled from "styled-components";
import { useStoreMap } from "effector-react";
import { ReadOnlyInput } from "../form-elements/ReadOnlyInput";

import {
  appointmentFieldValuesStore,
  getAllFieldValuesDescription,
  setFieldValue
} from "../../stores/appointmentFieldValues";

import type { FieldFormula, FormulaParameter } from "../../domain/entities/Field";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import { getAppointment_id } from "../../domain/entities/Appointment";
import { getNumberValue } from "./getNumberValue";
import { round, create, all } from "mathjs";
import { colors } from "../styleguide/colors";
import customFormulaFunctions from "./custom-formula-functions";

const math = create(all);
math.import(customFormulaFunctions);

const parser = math.parser();

const ErrorReadOnlyInput = styled(ReadOnlyInput)`
  padding: 12px 0;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 23px;
  vertical-align: top;
  color: ${colors.watermelon};
`;

const EmptyFieldErrorReadOnlyInput = styled(ErrorReadOnlyInput)`
  color: ${colors.black};
  opacity: 0.6;
`;

export const FormulaWidget = (props: {
  field: FieldFormula,
  appointment: AppointmentWithDetails,
  layout: any
}) => {
  const { field, appointment, layout } = props;
  const appointment_id = getAppointment_id(appointment);
  const fieldValues = getAllFieldValuesDescription(appointment_id);
  const fieldKeyIdMap = {};
  const fieldKeyNameMap = {};
  const fieldValuesMap = {};
  const previousAppointmentId = React.useRef(null);
  let sameAppointment = appointment_id === previousAppointmentId.current;
  if (!sameAppointment) {
    previousAppointmentId.current = appointment_id;
  }

  let shouldShowDateResult = false;

  useStoreMap({
    store: appointmentFieldValuesStore,
    keys: [],
    fn: state => {
      if (!state) {
        return {};
      }
      const appointmentState = state[appointment_id];
      if (appointmentState) {
        for (let fieldKey in fieldKeyIdMap) {
          const fieldValue = appointmentState.fieldValues[fieldKeyIdMap[fieldKey]];
          if (fieldValue) {
            fieldValuesMap[fieldKeyIdMap[fieldKey]] = fieldValue.value;
          }
        }
        const fieldValue = appointmentState.fieldValues[field.id];
        if (fieldValue) {
          fieldValuesMap[field.id] = fieldValue.value;
        }
        return JSON.stringify(fieldValuesMap);
      }
      return state;
    }
  });

  const checkFieldIsParameterAndSetToMap = field_to_check => {
    if (
      field_to_check.field_key &&
      field.parameters.map(param => param.value).includes(field_to_check.field_key)
    ) {
      const formulaParameter: FormulaParameter | void = field.parameters.find(
        param => param.value === field_to_check.field_key
      );
      if (formulaParameter) {
        fieldKeyIdMap[formulaParameter.parameter_identifier] = field_to_check.id;
        fieldKeyNameMap[formulaParameter.parameter_identifier] = field_to_check.name;
      }
    } else if (
      field_to_check.field_type === "group" &&
      field_to_check.children &&
      field_to_check.children.length
    ) {
      field_to_check.children.forEach(child_field => {
        checkFieldIsParameterAndSetToMap(child_field);
      });
    }
  };

  layout.sections.forEach(section => {
    section.fields.forEach(section_field => {
      checkFieldIsParameterAndSetToMap(section_field);
    });
  });

  parser.clear();

  const cleanNumberValue = (value) => {
    if (typeof value === "string" && !isNaN(parseInt(value)) && value.includes(',')) {
      return value.replaceAll(',', '.');
    }

    return value;
  };

  const setParserValueByFieldKey = field_key => {
    if (fieldValues && fieldValues[fieldKeyIdMap[field_key]]) {
      if (fieldValues[fieldKeyIdMap[field_key]].field_type === "date") {
        shouldShowDateResult = true;
      }

      const cleanedNumberValue = cleanNumberValue(getNumberValue(fieldValues[fieldKeyIdMap[field_key]]));
      parser.set(field_key, cleanedNumberValue);
      // $FlowFixMe
    } else if (appointment.layout_field_values) {
      // $FlowFixMe
      appointment.layout_field_values.forEach(field_value => {
        if (field_value.field === fieldKeyIdMap[field_key]) {
          if (field_value && field_value._field_type === "date") {
            if (typeof field_value.value === "string") {
              const dateString = field_value.value
                .split(".")
                .reverse()
                .join("-");

              const date = (new Date(dateString).getTime() / (24 * 3600 * 1000)).toString();

              shouldShowDateResult = true;
              parser.set(field_key, date);

              return;
            }
          }
          parser.set(field_key, cleanNumberValue(field_value.value));
        }
      });
    }
  };

  field.parameters.forEach(parameter => {
    if (parameter.type === "field_val") {
      setParserValueByFieldKey(parameter.parameter_identifier);
    } else {
      parser.set(parameter.parameter_identifier, parameter.value);
    }
  });

  let result = "";

  try {
    result = parser.evaluate(field.formula);
    if (typeof result === "number") {
      result = round(result, 2).toString();
    }

    if (shouldShowDateResult) {
      result = new Date(result * 24 * 3600 * 1000).toLocaleDateString();
    }
  } catch (e) {
    void e;
  }

  const emptyFields = field.parameters
    .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
    .filter(parameter => {
      const varValue = parser.get(parameter.parameter_identifier);
      return !(varValue || varValue === 0);
    }, [])
    .map(parameter => fieldKeyNameMap[parameter.parameter_identifier]);

  if (sameAppointment) {
    if (emptyFields && emptyFields.length) {
      setFieldValue({
        field_type: field.field_type,
        appointment_id,
        field_id: field.id,
        value: ""
      });
    } else {
      setFieldValue({
        field_type: field.field_type,
        appointment_id,
        field_id: field.id,
        value: result
      });
    }
  }

  const hasEmptyFields = emptyFields && emptyFields.length;
  const hasNoFormula = !field.formula;
  const hasNoResult = !result && result !== 0 && !hasEmptyFields;
  const hasErrors = hasNoFormula || hasNoResult;

  if (hasEmptyFields) {
    result = "Заполните поля: " + emptyFields.join(", ").toLowerCase();
  }
  if (hasNoResult) {
    result = "Некорректная формула или значения";
  }
  if (hasNoFormula) {
    result = "Формула не получена";
  }

  return hasErrors ? (
    <ErrorReadOnlyInput>{result.toString() || ""}</ErrorReadOnlyInput>
  ) : hasEmptyFields ? (
    <EmptyFieldErrorReadOnlyInput>{result.toString() || ""}</EmptyFieldErrorReadOnlyInput>
  ) : (
    <ReadOnlyInput>{result.toString() || ""}</ReadOnlyInput>
  );
};
