import React, { useState } from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormControl, FormLabel } from "@mui/material";
import { makeAnimationStartHandler } from "../DynamicForm";
import { Asterisk } from "../styled/Asterisk.styled";

export const FormInputText = (props) => {
  const [fieldHasValue, setFieldHasValue] = useState(false);
  const [isFieldInFocus, setIsFieldInFocus] = useState(false);

   return (
    <FormControl fullWidth className={'dynamic-form-input-control'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
      {(props.label && props.withLabel) && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
      <Controller
        name={props.name}
        control={props.control}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => {
          if (value === null) {
            onChange({target: {value: ''}})
          }
          const newProps = {...props};
          const needSubmitEnter = props.needSubmitEnter;
          const submit = props.submit;
          delete newProps.submit;
          delete newProps.needSubmitEnter;
          delete newProps.transformValue;
          delete newProps.subInfo;
          delete newProps.enableInstalledShrink;
          return (
            <TextField
              id={props.name}
              helperText={error ? error.message : props.subInfo ? props.subInfo : null}
              error={!!error}
              fullWidth
              value={props.transformValue ? props.transformValue(value || '') : value || ''}
              onChange={(event) => {
                onChange(event.target.value);
                if (props.enableInstalledShrink && !isFieldInFocus) {
                  setFieldHasValue(event.target.value !== "");
                }
              }}
              onKeyUp={(submit && needSubmitEnter) && ((e) => {
                if (e.code === "Enter") {
                  submit();
                }
              })}
              onFocus={() => {
                setFieldHasValue(true);
                setIsFieldInFocus(true);
              }}
              onBlur={() => {
                setFieldHasValue(!!value);
                setIsFieldInFocus(false);
              }}
              InputLabelProps={{
                shrink: props.enableInstalledShrink && fieldHasValue
              }}
              inputProps={
                { readOnly: props.readOnly, onAnimationStart: makeAnimationStartHandler(setFieldHasValue, value) }
              }
              variant="outlined"
              {...newProps}
              label={props.withLabel ? '' : props.label}
            />
          )
        }}
      />
    </FormControl>
  );
};
