// @flow
import type { TAppointment_id } from "./Appointment";
import type { DateString } from "../dates";
import type { Patient } from "./Patient";

export type TFeedback = {
  id: string,
  appointment_id: TAppointment_id,
  doctor_id: number,
  notification_date: DateString,
  created_at: DateString,
  completed_at?: DateString,
  has_response: boolean,
  doctors_comment?: string,
  response?: {
    created_at: string,
    id: number,
    needs_callback: boolean,
    rating: null | number,
    created_by_doctor: boolean
  },
  patient?: Patient,
  layout: {
    collect_callback: boolean,
    collect_rating: boolean,
    id: number,
    name: string,
    subtitle: string
  }
};

export opaque type TFeedback_id: string = string;

export const geFeedbackId = (feedback: TFeedback): TFeedback_id => feedback.id;
