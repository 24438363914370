import { makeAutoObservable } from 'mobx';

export const PrintEditorEvents = {
  back: 'back',
};

class PrintEditorStore {
  isVisible = false;
  data = null;
  eventListeners = {
    [PrintEditorEvents.back]: []
  };
  customButtons = {};

  constructor() {
    makeAutoObservable(this);

    this.addEventListener = this.addEventListener.bind(this);
    this.back = this.back.bind(this);
    this.setData = this.setData.bind(this);
    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
  }

  addEventListener(event, callback) {
    if (event && this.eventListeners[event] && callback) {
      this.eventListeners[event].push(callback);
    }

    return () => {
      this.removeEventListener(event, callback);
    };
  }

  removeEventListener(event, callback) {
    if (event && this.eventListeners[event] && callback) {
      this.eventListeners[event] = this.eventListeners[event].filter(listener => listener !== callback);
    }
  }

  clearEventListeners(event) {
    if (event && this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }
  }

  *back() {
    for (let listener of this.eventListeners[PrintEditorEvents.back]) {
      yield listener();
    }
  }

  setData(data) {
    this.data = data;
  }

  addCustomButton(key, buttonComponent) {
    if (this.customButtons[key]) {
      console.error(`PrintEditor already have custom button with key [${key}]`);
      return;
    }
    this.customButtons[key] = buttonComponent;
  }

  removeCustomButton(key) {
    if (!this.customButtons[key]) {
      console.error(`PrintEditor don't have a custom button with key [${key}]`);
      return;
    }
    delete this.customButtons[key];
  }

  show() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
  }
}

export default new PrintEditorStore();
