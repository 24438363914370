import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Accordion, AccordionSummary, Autocomplete, Box, Chip, FormControl, FormLabel } from "@mui/material";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { observer } from "mobx-react";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "use-debounce";
import _ from 'lodash';
import styled from 'styled-components';
import { colors } from "../../../styleguide/colors";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Asterisk } from "../styled/Asterisk.styled";

const OptionChip = styled(Chip)`
    margin: 1px!important;
  .MuiChip-label {
      padding: 2px 10px;
  }
`;

const GroupHeader = styled.div`
  min-height: 36px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const FormInputSelectDoctor = observer((props) => {
  const [options, setOptions] = useState(props.options || []);
  const [inputValue, setInputValue] = useState('');
  const [oldInputValue, setOldInputValue] = useState('');
  const [searchTextDebounced] = useDebounce(inputValue, 700);
  const [loading, setLoading] = useState(false);
  const [expendedGroup, setExpendedGroup] = useState(false);
  const valueName = props.valueName || 'value';
  const labelName = props.labelName || 'label';

  const fieldProps = {...props};
  delete fieldProps.valueName;
  delete fieldProps.labelName;
  delete fieldProps.needSearchOptions;
  delete fieldProps.getOptions;
  delete fieldProps.needSelectAll;
  delete fieldProps.transformInputValueClick;
  delete fieldProps.filterFnDisabled;
  delete fieldProps.minSearchLength;
  delete fieldProps.filterFn;
  delete fieldProps.parentName;

  useEffect(() => {
    if (props.control._formValues[props.name] && props.needSearchOptions && props.getOptions) {
      setInputValue(props.control._formValues[props.name]);
    }
  }, [props.control._formValues]);


  useEffect(() => {
    (async () => {
      if (props.getOptions && !props.needSearchOptions) {
        setLoading(true);
        const asyncOptions = await props.getOptions();
        setOptions(asyncOptions);
        setLoading(false);
      }
    })();
  }, [props.getOptions]);

  useEffect(() => {
    (async () => {
      if (props.getOptions && inputValue.length > (props.minSearchLength || 0) && props.needSearchOptions) {
        setLoading(true);
        const asyncOptions = await props.getOptions(inputValue);
        if (asyncOptions) {
          setOptions(asyncOptions);
        }
        setLoading(false);
      }
    })();
  }, [searchTextDebounced]);

  useEffect(() => {
    (() => {
      setOptions(props.options || []);
    })();
  }, [props.options]);


  const changeSelect = (data, onChange) => {
    if (fieldProps.multiple) {
      if (data.find(option => option.all)) {
        onChange(_.uniq(data.length - 1 === options.length ? [] : options.map((item) => item[valueName])))
      } else {
        onChange(_.uniq(data.map((item) => item[valueName])));
      }
    } else {
      onChange(data?.[valueName]);
    }
  }

  const removeSelect = (value, currentValue, onChange) => {
    onChange(_.uniq(value.filter((item) => item !== currentValue)));
  }

  const checkGroup = (specialitiesGroup, value = []) => {
    const groupLength = props.options.filter((c) => c.specialitiesGroup === specialitiesGroup).length;
    const optionsValue = props.options.filter((item) => value.includes(item[valueName]));
    const selectedGroupLength = _.uniq(optionsValue.filter(
      (c) => c.specialitiesGroup === specialitiesGroup
    ).map((item) => item.id)).length;
    return groupLength === selectedGroupLength;
  };

  const selectedGroupLength = (specialitiesGroup, value = []) => {
    const groupLength = props.options.filter((c) => c.specialitiesGroup === specialitiesGroup).length;
    const optionsValue = props.options.filter((item) => value.includes(item[valueName]));
    const selectedGroupLength = _.uniq(optionsValue.filter(
      (c) => c.specialitiesGroup === specialitiesGroup
    ).map((item) => item.id)).length;
    return selectedGroupLength > 0 && selectedGroupLength !== groupLength
  };

  const checkGroupAll = (specialitiesGroup, value = []) => {
    return _.uniq(props.options.map((item) => item[valueName])).length === value.length;
  };

  const selectGroup = (specialitiesGroup, value = [], onChange) => {
    const filterAllOptions = props.options.filter((c) => c.specialitiesGroup === specialitiesGroup);
    const filterAllOptionsNames = filterAllOptions.map((item) => item[valueName]);
    const optionsValue = props.options.filter((item) => value.includes(item[valueName]));
    const selectedGroupValue = optionsValue.filter(
      (c) => c.specialitiesGroup === specialitiesGroup
    );
    if (filterAllOptions.length === selectedGroupValue.length) {
      onChange(_.uniq(optionsValue.filter((c) => !filterAllOptionsNames.includes(c[valueName])).map((item) => item[valueName])));
    } else {
      onChange(_.uniq([...value, ...filterAllOptions.map((item) => item[valueName])]));
    }
  };

  const selectGroupAll = (specialitiesGroup, value = [], onChange) => {
    if (_.uniq(props.options.map((item) => item[valueName])).length === value.length) {
      onChange([]);
    } else {
      onChange(_.uniq(props.options.map((item) => item[valueName])));
    }
  };

  return (
    <FormControl fullWidth className={'dynamic-form-input-control'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
      {(props.label && props.withLabel) && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
      <Controller
        render={({ field: { onChange, value }, fieldState: { error }}) => {
          const optionsValue = [];
          const optionsValueName = [];
          if (value) {
            props.options.forEach((item) => {
              if (props.multiple) {
                if (value.includes(item[valueName]) && !optionsValueName.includes(item[valueName]) ) {
                  optionsValueName.push(item[valueName]);
                  optionsValue.push(item);
                }
              } else {
                if (value === item[valueName] && !optionsValueName.includes(item[valueName]) ) {
                  optionsValueName.push(item[valueName]);
                  optionsValue.push(item);
                }
              }
            });
          }
          return (
          <Autocomplete
            {...fieldProps}
            label={props.withLabel ? '' : props.label}
            id={props.parentName || ""}
            options={options}
            filterOptions={(options, params) => { // <<<--- inject the Select All option
              const filtered = options.filter((item) => params.getOptionLabel(item).toLowerCase().includes(params.inputValue.toLowerCase()) || item.specialitiesGroup.toLowerCase().includes(params.inputValue.toLowerCase()))
              if (filtered.length && params.inputValue && params.inputValue !== oldInputValue && filtered[0].specialitiesGroup !== expendedGroup) {
                setExpendedGroup(filtered[0].specialitiesGroup);
              }
              setOldInputValue(params.inputValue);
              return props.multiple ? [{ [labelName]: 'Выбрать все', all: true, specialitiesGroup: 'Выбрать все' }, ...filtered] : filtered;
            }}
            disableCloseOnSelect={fieldProps.multiple}
            onChange={(e, data, reason, details) => {
              if (reason === 'removeOption') {
                data = data.filter((item) => item.id !== details.option.id);
              }
              changeSelect(data, onChange, details);
            }}
            getOptionLabel={(option) => option[labelName] || ''}
            defaultValue={fieldProps.multiple ? value || [] : ((value || value === false) ? value : '')}
            inputValue={inputValue}
            clearOnBlur={false}
            onInputChange={(event, inputVal) => {
              if (event) {
                if (event.type === 'click' && props.transformInputValueClick) {
                  setInputValue(props.transformInputValueClick(inputVal));
                } else {
                  setInputValue(inputVal);
                }
              }
            }}
            value={(fieldProps.multiple ? options.filter((option) => value?.includes(option[valueName])) : options.find((option) => value === option[valueName])) || null}
            renderOption={fieldProps.multiple ? (props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={option.all ? !!(value?.length === options.length) : selected}
                />
                {option[labelName]}
              </li>
            ) : undefined}
            groupBy={(option) => option.specialitiesGroup}
            renderGroup={(params) => (
                <li key={params.key}>
                  {params.group !== 'Выбрать все' ? (
                    <Accordion expanded={expendedGroup === params.group} onChange={(event, expended) => {
                      if (event.target.classList[0] !== 'PrivateSwitchBase-input') {
                        setExpendedGroup(expended ? params.group : false);
                      }
                    }} square sx={{
                      [`&.MuiAccordion-gutters`]: {
                        boxShadow: 'none',
                      },
                      [`& .MuiAccordionSummary-contentGutters`]: {
                        margin: '0px !important'
                      },
                      [`& .MuiAccordionDetails-root`]: {
                        padding: '0 16px 0px'
                      },
                      [`& .MuiAccordionSummary-gutters`]: {
                        minHeight: '36px!important'
                      },
                      [`& .MuiAutocomplete-option`]: {
                        color: colors.black,
                      },
                    }}>
                      <AccordionSummary
                        sx={{
                          margin: 0,
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Box display={"flex"} alignItems={"center"} position={"relative"}>
                          {props.multiple && (
                            <Checkbox
                              sx={{
                                [`& .MuiSvgIcon-root`]: {
                                  fontSize: '1.25rem'
                                },
                              }}
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={checkGroup(params.group, value, onChange)}
                              onChange={() => {
                                selectGroup(params.group, value, onChange);
                              }}
                              indeterminate={selectedGroupLength(params.group, value)}
                            />
                          )}
                          {params.group}
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {expendedGroup === params.group && params.children}
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <GroupHeader>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={checkGroupAll(params.group, value, onChange)}
                        onChange={() => selectGroupAll(params.group, value, onChange)}
                        // checked={option[params.group] ? !!(value?.length === options.length) : selected}
                      />
                      {params.group}
                    </GroupHeader>
                  )}
                </li>
              )
            }
            renderTags={() => {
              return optionsValue.map((tag) => (
                <OptionChip
                  key={tag[valueName]}
                  color={"default"}
                  label={tag[labelName]}
                  onDelete={() => removeSelect(value, tag[valueName], onChange)}
                  onClick={() => removeSelect(value, tag[valueName], onChange)}
                />
              ));
            }}
            renderInput={(params) => <TextField {...params} error={!!error} helperText={error ? error.message : null} label={props.label} InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }} />}
          />
        )}}
        control={props.control}
        name={props.name}
      />
    </FormControl>
  );
});
