// @flow
import React from "react";
import type { Checklist } from "../../domain/entities/Checklist";
import { CommaSeparatedWrapper } from "./CommaSeparatedWrapper";

export const ChecklistWithCommaSeparatedChildrenInParentheses = (props: {
  checklist: Checklist
}) => {
  const { checklist } = props;
  const checkedItems = checklist.items && checklist.items.filter(item => item.is_checked);

  return (
    <>
      {checklist.name}
      {checkedItems && checkedItems.length > 0 && (
        <>
          {" ("}
          {checkedItems.map(item => (
            <CommaSeparatedWrapper key={item.guid}>{item.name}</CommaSeparatedWrapper>
          ))}
          {") "}
        </>
      )}
      {checklist.comment && ` ${checklist.comment}`}
    </>
  );
};
