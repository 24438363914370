// @flow
import React from "react";
import { BackButton } from "../aside/BackButton";
import { Link } from "react-router-dom";
import { lastScheduleUrlStore } from "../appointment-history/appointmentHistoryStore";

export const BackToScheduleButton = () => {
  return (
    <BackButton
      text="Расписание"
      overrides={{
        Container: {
          props: {
            as: Link,
            to: lastScheduleUrlStore.getState()
          }
        }
      }}
    />
  );
};
