import React, { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import noticeStore from "../../stores/noticesStore";
import { Badge, Popover, IconButton, Divider as MuiDivider } from "@mui/material";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { scrollbars } from "../chats/style-elements";
import BellIcon from "../../assets/icons/notifications/bellIcon.inline.svg";
import { Notice } from "./Notice";

const IconButtonStyled = styled(IconButton)`
  svg {
    width: 24px;
    height: 24px;
  }
`;

const PopoverContainer = styled(Popover)`
  .MuiPaper-root {
    position: relative;
    padding-block-end: 8px;
    max-width: 400px;
    max-height: calc(100% - 200px);
    box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.09);
    color: ${colors.black};

    @media screen and (max-width: 700px) {
      max-width: 350px;
    }

    @media screen and (max-width: 500px) {
      max-width: none;
      top: 60px !important;
      left: 0 !important;
      max-height: calc(100% - 68px);
    }

    ${scrollbars};
  }
`;

const HeaderPopover = styled.div`
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  background: rgb(255 255 255 / 97%);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  a {
    font-weight: 400;
    text-decoration: none;
    color: ${colors.pink100};
  }
`;

const Divider = styled(MuiDivider)`
  &.MuiDivider-root {
    border-color: #f5f5f5;
  }
`;

const EmptyNotificationsBlock = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #b0b0b0;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  text-align: center;
`;

const ReadBlock = styled(EmptyNotificationsBlock)`
  color: ${colors.gray700};
  line-height: 20px;
  font-size: 14px;
  padding: 8px 16px;
  text-align: left;
`;

export const Notices = observer(
  ({
    anchorOrigin = { vertical: "bottom", horizontal: "right" },
    transformOrigin = { vertical: "top", horizontal: "right" }
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOnOpenNotifications = event => setAnchorEl(event.currentTarget);
    const handleOnCloseNotifications = () => setAnchorEl(null);

    const handlerOnReadNotification = async id => {
      await noticeStore.readNotice(id);
    };

    useEffect(() => {
      noticeStore.initialize();
    }, []);

    return (
      <Fragment>
        <IconButtonStyled onClick={handleOnOpenNotifications}>
          <Badge badgeContent={noticeStore.unreadNoticesCount} color="primary">
            <BellIcon />
          </Badge>
        </IconButtonStyled>
        <PopoverContainer
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleOnCloseNotifications}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}>
          <HeaderPopover>
            Уведомления
            <Link to="/profile/notifications">Все уведомления</Link>
          </HeaderPopover>
          {!noticeStore.notices.length && (
            <EmptyNotificationsBlock>Уведомления отсутствуют</EmptyNotificationsBlock>
          )}
          {noticeStore.unreadNotices.map(notification => (
            <Fragment key={notification.id}>
              <Notice {...notification} onReadNotification={handlerOnReadNotification} />
              <Divider />
            </Fragment>
          ))}
          {!!noticeStore.readedNotices.length && (
            <Fragment>
              <ReadBlock>Просмотренные</ReadBlock>
              <Divider />
            </Fragment>
          )}
          {noticeStore.readedNotices.slice(0, 5).map(notification => (
            <Fragment key={notification.id}>
              <Notice {...notification} />
              <Divider />
            </Fragment>
          ))}
        </PopoverContainer>
      </Fragment>
    );
  }
);
