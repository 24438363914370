import styled, { css } from "styled-components";
import { rgba } from "polished";

import { colors } from "../../styleguide/colors";

import arrowIcon from "../icons/arrow.inline.svg";

export const itemWidth = 35;

export const activeDayStyles = css`
  background: ${rgba(colors.magenta, 0.7)};
  color: ${colors.white};
  border-radius: 4px;
`;

export const Container = styled.div`
  width: fit-content;
  padding: 16px;
  border-radius: 4px;
  background: ${colors.white};
  color: ${colors.black};
  font-size: 15px;

  @media (max-width: 650px) {
    padding: 5px 10px;
  }
`;

export const Header = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 8px;
  padding: 8px 0;
`;

export const Month = styled.div`
  text-transform: capitalize;
  line-height: 23px;
`;

export const DayOfWeek = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${itemWidth}px;
  height: 39px;
`;

export const Week = styled.div`
  display: flex;
  width: 245px;
`;

export const Days = styled.div`
  display: flex;
  width: 245px;
  flex-wrap: wrap;
`;

export const Day = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 35px;
  height: 39px;
  cursor: pointer;

  margin-left: ${({ offset }) => offset}px;

  ${({ today }) =>
    today &&
    css`
      font-weight: 500;
      color: ${colors.magenta};
    `}

  ${({ disabled, range }) =>
    !disabled &&
    range &&
    css`
      &:hover {
        border: 1px solid ${colors.magenta};
        border-radius: 4px;
      }
    `}

  ${({ disabled, range }) =>
    !disabled &&
    !range &&
    css`
      &:hover {
        ${activeDayStyles}
      }
    `} 

  ${({ active }) => active && activeDayStyles}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${colors.gray700};
    `}

  
  ${({ inRange }) =>
    inRange &&
    css`
      background: ${rgba(colors.magenta, 0.05)};
    `}
`;

export const Arrow = styled(arrowIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 7px 10px 6px 8px;
`;

export const RightArrow = styled(Arrow)`
  right: 0;
`;

export const LeftArrow = styled(Arrow)`
  left: 0;
  transform: translateY(-50%) rotate(180deg);
`;
