// @flow
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import FeedbackIcon from "./icons/feedback.svg";
import FeedbackCompletedIcon from "./icons/icon-feedback-completed.svg";

export const FeedbackFixedLabel = styled.div`
  width: 65px;
  height: 59px;

  position: fixed;
  top: 140px;
  right: 0;

  background: url(${props => (props.isCompleted ? FeedbackCompletedIcon : FeedbackIcon)}) no-repeat;
  background-color: ${colors.white};
  background-position: 25px center;

  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.09);
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;

  cursor: pointer;
  z-index: 9;
`;
