import { makeAutoObservable } from "mobx";
import moment from "moment";
import { formatScheduleTimeForDisplay } from "../domain/services/schedule";
import { READABLE_TIME_FORMAT_MOMENT } from "../utils/dates";
import apiv2 from "../apiv2";
import _ from "lodash";
import generalScheduleStore from "./generalScheduleStore";
import { useToast } from "../components/toast/useToast";
import React from "react";
import { ScheduleInsertToast } from "../components/general-schedule-page/ScheduleInsertToast";

const toastManager = useToast();

export const INSERTS_TYPE = {
  BY_TIME_SLOT: "by_time_slot",
  WITHOUT_TIME_SLOT: "without_time_slot",
  EDITING: "editing",
  MULTIPLE: "multiple"
};

export const INSERTS_TYPE_RU = {
  [INSERTS_TYPE.BY_TIME_SLOT]: "Запись пациента",
  [INSERTS_TYPE.WITHOUT_TIME_SLOT]: "Запись пациента вне расписания",
  [INSERTS_TYPE.EDITING]: "Редактирование записи пациента",
  [INSERTS_TYPE.MULTIPLE]: "Множественная запись пациента"
};

export const TYPE_PRINTING_DOCUMENTS = [
  { key: 1, text: "Пакет документов" },
  { key: 2, text: "Титульный лист Форма №025/у-04" },
  { key: 3, text: "Договор трехсторонний" },
  { key: 4, text: "Заявление на отзыв персональных данных" },
  { key: 5, text: "Карта беременной титульный лист" },
  { key: 6, text: "Карта стационар тит лист" },
  { key: 7, text: "Отдельно договор сводный Калуга" },
  { key: 8, text: "Отдельно ИДС" },
  { key: 9, text: "Отказ от вмешательств" },
  { key: 10, text: "Правила программы депозит" },
  { key: 11, text: `Согласие на эл.чеки и счет заказы` },
  { key: 12, text: "Согласие ЭЛН" },
  { key: 13, text: "Отдельно персональные данные" }
];

const COMMON_CONFIG = {
  hiddenFields: (data, type) => {
    const fields = [];
    if (type !== INSERTS_TYPE.WITHOUT_TIME_SLOT) {
      fields.push("date_select");
    } else {
      fields.push("date");
    }
    return fields;
  },
  disabledFields: (data, additionalData) => {
    const fields = ["organization", "date", "time_start"];
    if (additionalData) {
      // if (additionalData.organizationOptions.length === 1) {
      //   fields.push("organization");
      // }
      // if (!additionalData.timeEndOptions.length || !additionalData.timeStartOptions.length) {
      //   fields.push("date");
      // }
      if (!additionalData.timeStartOptions.length) {
        fields.push("time_start");
      }
      if (!data.time_start || !additionalData.timeEndOptions.length) {
        fields.push("time_end");
      }
    }
    return fields;
  },
  actions: () => ["telemed", "linkToOnlineAndPayment", "linkToPayment", "linkToOnline"]
};

export const INSERTS_CONFIG = {
  [INSERTS_TYPE.BY_TIME_SLOT]: {
    hiddenFields: (data, type) => [...COMMON_CONFIG.hiddenFields(data, type)],
    disabledFields: (data, additionalData) => {
      return ["doctor", "entry_source", ...COMMON_CONFIG.disabledFields(data, additionalData)];
    },
    actions: () => ["record"]
  },
  [INSERTS_TYPE.WITHOUT_TIME_SLOT]: {
    hiddenFields: (data, type) => [
      ...COMMON_CONFIG.hiddenFields(data, type),
      "time_start",
      "time_end",
      "services",
      "duration",
      "payment_type",
      "notification_type"
    ],
    disabledFields: (data, additionalData) => {
      const fields = ["doctor", "entry_source", "date", "time_start"];
      if (additionalData?.organizationOptions.length === 1) {
        fields.push("organization");
      }
      if (additionalData?.dateOptions.length === 1) {
        fields.push("date_select");
      }
      return fields;
    },
    actions: () => ["record"]
  },
  [INSERTS_TYPE.EDITING]: {
    hiddenFields: (data, type) => [...COMMON_CONFIG.hiddenFields(data, type), "duration"],
    disabledFields: (data, additionalData) => {
      const currentTime = moment().format("HH:mm");
      const currentDate = moment().format("YYYY-MM-DD");
      const fields = [
        "patient",
        "phone_number",
        "birthdate",
        "how_did_learn",
        "organization",
        "entry_source",
        "doctor"
      ];

      if (!additionalData?.timeStartOptions.length) {
        fields.push("time_start");
      }

      if (!data.time_start || !additionalData?.timeEndOptions.length) {
        fields.push("time_end");
      }

      if (moment(currentDate, "YYYY-MM-DD") > moment(data.date, "YYYY-MM-DD") ||
          (moment(data.date, "YYYY-MM-DD") <= moment(currentDate, "YYYY-MM-DD") && moment(currentTime, "HH:mm") > moment(data.time_start, "HH:mm"))) {
        fields.push("date", "time_end", "time_start", "services", "comment", "payment_type", "notification_type");
      }

      return fields;
    },
    actions: () => [...COMMON_CONFIG.actions(), "editing", "remove"]
  },
  [INSERTS_TYPE.MULTIPLE]: {
    hiddenFields: (data, type) => [...COMMON_CONFIG.hiddenFields(data, type)],
    disabledFields: (data, additionalData) => {
      return [
        "doctor",
        "entry_source",
        "organization",
        "time_start",
        ...COMMON_CONFIG.disabledFields(data, additionalData)
      ];
    },
    actions: () => ["record"]
  }
};

class ScheduleInsertsStore {
  scheduleInsertsType = "";
  isShowScheduleInsertDialog = false;
  scheduleInsertDialogData = null;
  scheduleList = [];

  enableMultipleAppointmentsMode = false;
  multipleAppointmentsData = [];
  multipleAppointmentsClosestTime = {};
  multipleAppointmentsDataByFilter = [];
  appointments = [];
  initialData = {
    patient: null,
    visits: null
  };
  patientData = {};
  visitsData = {};
  additionalDataById = {};
  patientList = [];
  isLoading = false;
  actualDataAfterChangedField = [];
  requestForActualDataAfterChangedFieldCompleted = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPatientList(patients) {
    this.patientList = patients;
  }

  setPatientData(data) {
    this.patientData = data;
  }

  setVisitsData(data) {
    this.visitsData = data;
  }

  multipleAppointmentsMode() {
    this.enableMultipleAppointmentsMode = !this.enableMultipleAppointmentsMode;
  }

  async getPatientListByValue(value) {
    try {
      let filterParams = {};
      if (typeof value === "string") {
        filterParams = { fullNamePatient__icontains: value };
      } else {
        filterParams = {
          patient__id: value.id,
          number: value.number
        };
      }

      const response = await apiv2.medicalFiles.getListMedicalFiles({
        filter_params: filterParams,
        annotate_mtm_params: {
          patient: [
            "patient__birthdate",
            "patient__medical_files__number",
            "patient__phone_number",
            "patient__medical_files__id"
          ]
        },
        values_params: [
          "patient__id",
          "fullNamePatient",
          "patient__birthdate",
          "patient__phone_number",
          "patient__medical_files__number",
          "patient__medical_files__id"
        ]
      });
      let patients = [];

      response.data.forEach(patient => {
        const medicalFiles = patient.patient__medical_files__number.map(number => {
          return {
            id: patient.patient__id,
            fullName: patient.fullNamePatient,
            birthdate: patient.patient__birthdate[0],
            phoneNumber: patient.patient__phone_number[0],
            medicalFileId: patient.patient__medical_files__id[0],
            medicalFileNumber: number
          };
        });
        patients = [...patients, ...medicalFiles];
      });

      this.setPatientList(patients);
    } catch (e) {
      console.error(e);
    }
  }

  async getPossibleData({ doctorId, organizationId, date }) {
    const params = {
      filter_params: {
        date: date,
        doctor_organization__organization: organizationId,
        doctor_organization__doctor: doctorId,
        time__isnull: false
      },
      values_params: [
        "id",
        "type",
        "time",
        "date",
        "is_reserved",
        "medical_file",
        "doctor_organization__doctor",
        "doctor_organization__schedule_step"
      ],
      meta: {
        current_page: 1,
        per_page: 100
      }
    };

    this.isLoading = true;
    try {
      this.requestForActualDataAfterChangedFieldCompleted = true;
      const response = await apiv2.schedule.scheduleListWithValues({ ...params });
      this.actualDataAfterChangedField = response.data;
      setTimeout(() => {
        this.requestForActualDataAfterChangedFieldCompleted = false;
      }, 1000);
    } catch (e) {
      console.error(e);
    }
    this.isLoading = false;
  }

  async setScheduleInsertDialogData({ type, slot = null, closestTime = "" }) {
    /** 6439 Поле, на которое уже нельзя записаться, отображается в выбранные слоты (на данный момент закомментировал)
     * Анастасия сообщила, что если время вышло, давать пользователю сделать запись */
    // if (type === INSERTS_TYPE.MULTIPLE) {
    //   const timeAppointmentsIsUp = [];
    //   const currentDate = moment().format("YYYY-MM-DD");
    //   const currentTime = moment().format("HH:mm");
    //   this.multipleAppointmentsData.forEach((item) => {
    //     if (item.date === currentDate && item.time < currentTime) {
    //       timeAppointmentsIsUp.push(item);
    //       this.removeMultipleAppointments({ id: item.id });
    //     }
    //   });
    //
    //   if (timeAppointmentsIsUp.length) {
    //     toastManager.showToast(
    //       <div>
    //         Выбранные слоты:
    //         <ul style={{ listStyle: "inside" }}>
    //           {timeAppointmentsIsUp.map(appointment => (
    //             <li key={appointment.id}>
    //               {getPatientLastNameWithInitials(appointment.doctor)},{" "}
    //               {moment(appointment.time, "HH:mm:ss").format("HH:mm")}
    //             </li>
    //           ))}
    //         </ul>
    //         были удалены, т.к. время на их запись вышло.
    //       </div>,
    //       {
    //         duration: 10000
    //       }
    //     );
    //   }
    //   if (!this.multipleAppointmentsData.length || this.multipleAppointmentsData.length === 1) {
    //     return;
    //   }
    // }
    if (type === INSERTS_TYPE.MULTIPLE) {
      this.transformMultipleAppointmentsClosestTime();
    }
    const data =
      type === INSERTS_TYPE.MULTIPLE
        ? _.sortBy(this.multipleAppointmentsData, ["date", "time"])
        : [slot];
    this.scheduleInsertsType = type;
    this.scheduleInsertDialogData = {};
    const appointments = [];
    const visitsData = {};
    let patientData = {};
    let additionalData = {};

    if (type === INSERTS_TYPE.EDITING && slot.medical_file) {
      await this.getPatientListByValue({
        id: slot.medical_file.patient.id,
        number: slot.medical_file.number
      });
    }

    const getOrganizationIdByName = (organizations, name) => {
      const organization = _.find(organizations, { name });
      return organization?.id || "";
    };
    const getDateOptions = data => {
      if (type !== INSERTS_TYPE.WITHOUT_TIME_SLOT) {
        return [];
      }
      if (data.dates?.length) {
        return data.dates.map(date => ({ value: date, label: date }));
      } else {
        return [{ value: data.date, label: data.date }];
      }
    };

    data.forEach(item => {
      const key = type === INSERTS_TYPE.WITHOUT_TIME_SLOT ? 0 : item.id;
      appointments.push(key);
      const getTimeEnd = () => {
        if (type === INSERTS_TYPE.EDITING) {
          return moment(item.time, "HH:mm:ss")
            .add(item.num_appointments * item.doctor.schedule_step, "minute")
            .format("HH:mm");
        }
        if ([INSERTS_TYPE.BY_TIME_SLOT, INSERTS_TYPE.MULTIPLE].includes(type)) {
          return additionalData[key].timeEndOptions[0].value;
        }
        return "";
      };
      additionalData[key] = {
        paymentTypeOptions: [
          { value: "PAID", label: "Платные" },
          { value: "DMS", label: "ДМС" },
          { value: "OMS", label: "ОМС" }
        ],
        notificationTypeOptions: [
          { value: "sms", label: "СМС" },
          { value: "email", label: "По email-почте" },
          { value: "whats_app", label: "WhatsApp" }
        ],
        organizationOptions: item.doctor.organizationsName.map(organization => {
          return {
            name: organization.name,
            id: organization.id
          };
        }),
        dateOptions: getDateOptions(item),
        timeStartOptions:
          !item.time || type === INSERTS_TYPE.WITHOUT_TIME_SLOT
            ? []
            : this.getTimeStartOptions({
                appointmentId: key,
                data:
                  type === INSERTS_TYPE.MULTIPLE
                    ? this.multipleAppointmentsDataByFilter
                    : generalScheduleStore.dataTable,
                doctorId: item.doctor.id,
                date: item.date
              }),
        serviceOptions: item.doctor.favoritesServices.map(service => {
          return {
            value: service.id,
            label: `${service.kdfCode.trim()}, ${service.name}`
          };
        }),
        timeEndOptions: !item.time
          ? []
          : this.getTimeEndOptions({
              timeStart: formatScheduleTimeForDisplay(item.time),
              stepTime: item.doctor.schedule_step,
              closestTime:
                type === INSERTS_TYPE.MULTIPLE
                  ? this.multipleAppointmentsClosestTime[key].closestTime
                  : closestTime || ""
            })
      };
      patientData = !item.patient_full_name
        ? {
            patient: "",
            phone_number: "",
            birthdate: "",
            how_did_learn: ""
          }
        : {
            patient: {
              id: item?.medical_file?.patient?.id,
              medicalFileNumber: item?.medical_file?.number || ""
            },
            phone_number: item?.patient_phone_number || "",
            birthdate: item?.medical_file?.patient?.birthdate || "",
            how_did_learn: ""
          };
      visitsData[key] = {
        comment: item.comment,
        services: item.services?.map((service) => service.id) ?? [],
        organization: getOrganizationIdByName(
          item.doctor.organizationsName,
          item.doctor.organization
        ),
        doctor: item.doctor,
        entry_source: item.entry_source && item.entry_source !== "-" ? item.entry_source : "Ася",
        date: item.date,
        date_select: item.dates?.length === 1 ? item.dates[0] : item.date,
        time_start: item.time ? formatScheduleTimeForDisplay(item.time) : "",
        time_end: getTimeEnd(),
        payment_type: "PAID",
        notification_type: "sms"
      };
    });

    this.setPatientData(patientData);
    this.setVisitsData(visitsData);
    this.initialData = {
      patient: patientData,
      visits: visitsData
    };
    this.additionalDataById = additionalData;
    this.appointments = appointments;

    this.isShowScheduleInsertDialog = true;
  }

  updateAdditionalFields({ fields, visitId, ...otherParams }) {
    if (!fields.length) {
      return;
    }
    if (fields.includes('timeStartOptions')) {
      if (!this.actualDataAfterChangedField.length) {
        this.additionalDataById[visitId] = { ...this.additionalDataById[visitId], timeStartOptions: [] };
      } else {
        const { doctorId = "", date = "" } = otherParams;
        this.additionalDataById[visitId] = {
          ...this.additionalDataById[visitId],
          timeStartOptions: this.getTimeStartOptions({
            appointmentId: visitId,
            data: this.actualDataAfterChangedField,
            doctorId,
            date
          })
        };
      }
    }
    if (fields.includes('timeEndOptions')) {
      if (!this.actualDataAfterChangedField.length) {
        this.additionalDataById[visitId] = { ...this.additionalDataById[visitId], timeEndOptions: [] };
      } else {
        const { timeStart = "", stepTime = "" } = otherParams;
        const filteredData = this.actualDataAfterChangedField.filter((item) => moment(item.time, "HH:mm:ss").format("HH:mm") > timeStart);
        const closestTime = filteredData.find((item) => item.is_reserved || item.medical_file)?.time || "";

        this.additionalDataById[visitId] = {
          ...this.additionalDataById[visitId],
          timeEndOptions: this.getTimeEndOptions({
            timeStart,
            stepTime,
            closestTime
          })
        };
      }
    }
  }

  getTimeStartOptions({ appointmentId = null, data, doctorId, date }) {
    const currentTime = moment().format("HH:mm:ss");
    const currentDate = moment().format("YYYY-MM-DD");

    if (
      this.scheduleInsertsType !== INSERTS_TYPE.EDITING &&
      moment(date, "YYYY-MM-DD") < moment(currentDate, "YYYY-MM-DD")
    ) {
      return [];
    }

    return data
      .filter(item => {
        const isCorrectDateTime =
          moment(date, "YYYY-MM-DD") > moment(currentDate, "YYYY-MM-DD") ||
          (moment(date, "YYYY-MM-DD") >= moment(currentDate, "YYYY-MM-DD") &&
            moment(currentTime, "HH:mm") < moment(item.time, "HH:mm"));
        const isValidParams =
          item.doctor_organization__doctor === doctorId && item.date === date && !!item.time;

        if (item.id !== appointmentId && (!isCorrectDateTime || !isValidParams)) {
          return null;
        }

        if (item.id === appointmentId || !item.is_reserved) {
          return item;
        }
      })
      .map(item => {
        const time = moment(item.time, "HH:mm:ss").format("HH:mm");
        return {
          label: time,
          value: time
        };
      });
  }

  getTimeEndOptions({ timeStart, stepTime, closestTime }) {
    /** 7196 Нужно убрать логику на блокировку перекрестных записей по времени и дню - такое может быть (на данный момент закомментировал)
     * Анастасия сообщила, что время приемов может пересекаться, такой кейс реален */
    // if (this.enableMultipleAppointmentsMode) {
    //   /** При включенном режиме есть возможность пересечений приема по времени (пациент не может находиться в 2 местах одновременно) */
    //   const timeCrossed = this.multipleAppointmentsData.find((item) =>
    //     item.id !== visitId && item.date === date && item.time > timeStart
    //   );
    //   if (timeCrossed && (!closestTime || timeCrossed.time < closestTime)) {
    //     closestTime = timeCrossed.time;
    //   }
    // }

    let maxDuration = 240;
    const minutes = [];

    if (closestTime) {
      const timeMoment = moment(timeStart, "HH:mm:ss");
      const closestTimeMoment = moment(closestTime, "HH:mm:ss");
      const maxTimeDifferenceInMinutes = closestTimeMoment.diff(timeMoment, "minutes");
      maxDuration =
        maxTimeDifferenceInMinutes <= maxDuration ? maxTimeDifferenceInMinutes : maxDuration;
    }

    if (stepTime > maxDuration) {
      const time = moment(timeStart, "HH:mm")
        .add(stepTime, "minute")
        .format("HH:mm");
      return [{ label: time, value: time }];
    }

    for (let i = 1; i <= maxDuration / stepTime; i++) {
      minutes.push(stepTime * i);
    }

    return minutes.map(minute => {
      const time = moment(timeStart, "HH:mm")
        .add(minute, "minute")
        .format("HH:mm");
      return { label: time, value: time };
    });
  }

  resetScheduleInsertDialogData() {
    this.isShowScheduleInsertDialog = false;

    setTimeout(() => {
      this.actualDataAfterChangedField = [];
      this.scheduleInsertDialogData = null;
      this.setPatientList([]);
      this.appointments = [];
      this.setPatientData({});
      this.setVisitsData({});
      this.initialData = {
        patient: null,
        visits: null
      };
      this.additionalDataById = {};
    }, 300);
  }

  async getScheduleList(date) {
    const currentTime = moment().format("HH:mm:ss");
    const currentDate = moment().format("YYYY-MM-DD");
    const isBlockedFetch = moment(currentDate, "YYYY-MM-DD") > moment(date, "YYYY-MM-DD");

    const params = {
      filter_params: {
        date__gte: date,
        date__lte: date,
        doctor_organization__organization: this.scheduleInsertDialogData.clinic.id,
        doctor_organization__doctor: this.scheduleInsertDialogData.doctor.id,
        time__isnull: false,
        time__gte: date === currentDate ? currentTime : "8:00:00",
        time__lte: "20:30:00"
      },
      values_params: [
        "id",
        "type",
        "time",
        "is_reserved",
        "medical_file",
        "doctor_organization__schedule_step"
      ],
      meta: {
        current_page: 1,
        per_page: 100
      }
    };
    try {
      let scheduleList = [];
      if (isBlockedFetch) {
        this.scheduleList = scheduleList;
      } else {
        scheduleList = await apiv2.schedule.scheduleListWithValues({ ...params });
      }
      this.scheduleList = isBlockedFetch ? scheduleList : scheduleList.data;
      const timeIntervals = this.scheduleList
        .filter(item => !item.is_reserved)
        .map(item => {
          const time = moment(item.time, "HH:mm:ss").format(READABLE_TIME_FORMAT_MOMENT);
          return { value: time, label: time };
        });
      this.scheduleInsertDialogData = { ...this.scheduleInsertDialogData, timeIntervals };
    } catch (e) {
      console.error(e);
    }
  }

  addMultipleAppointments({ slot, closestTime = "" }) {
    if (_.find(this.multipleAppointmentsData, { id: slot.id })) {
      this.removeMultipleAppointments({ id: slot.id });
    } else {
      this.multipleAppointmentsData.push(slot);
      this.multipleAppointmentsClosestTime = {
        ...this.multipleAppointmentsClosestTime,
        [slot.id]: {
          doctorId: slot.doctor.id,
          timeStart: slot.time,
          date: slot.date,
          closestTime
        }
      };
      this.multipleAppointmentsDataByFilter = _.uniq([ ...this.multipleAppointmentsDataByFilter, ...generalScheduleStore.dataTable], 'id');
    }
  }

  transformMultipleAppointmentsClosestTime() {
    const groupedClosestTime = Object.values(this.multipleAppointmentsClosestTime).reduce(
      (acc, item) => {
        const key = `${item.date}_${item.doctorId}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      },
      {}
    );

    Object.values(groupedClosestTime).forEach(data => {
      Object.values(data).forEach((item, index) => {
        const nextItem = data[index + 1];
        if (
          !nextItem ||
          (item.closestTime &&
            moment(item.closestTime, "HH:mm:ss") < moment(nextItem.timeStart, "HH:mm:ss"))
        ) {
          return;
        }
        item.closestTime = nextItem.timeStart;
      });
    });
  }

  removeMultipleAppointments({ id, clearAll = false }) {
    if (clearAll) {
      this.multipleAppointmentsData = [];
      this.multipleAppointmentsClosestTime = {};
    } else {
      this.multipleAppointmentsData = this.multipleAppointmentsData.filter(
        appointment => appointment.id !== id
      );
      delete this.multipleAppointmentsClosestTime[id];
    }
    if (!this.multipleAppointmentsData.length) {
      this.multipleAppointmentsDataByFilter = [];
    }
  }

  async recordScheduleInserts({ callback }) {
    let patient = {};
    if (!this.patientList.length) {
      patient = {
        fullName: this.patientData.patient || "",
        birthdate: this.patientData.birthdate || "",
        phoneNumber: this.patientData.phone_number?.replace(/\s/g, "") || "",
        patient_know_about_us: this.patientData.how_did_learn || ""
      };
    } else if (this.patientData?.patient) {
      patient = _.find(this.patientList, {
        medicalFileNumber: this.patientData?.patient?.medicalFileNumber
      });
    }

    const getNumAppointments = ({ timeStart, timeEnd, step }) => {
      if (this.scheduleInsertsType === INSERTS_TYPE.WITHOUT_TIME_SLOT) {
        return 1;
      }
      const differenceTime = moment(timeEnd, "HH:mm").diff(moment(timeStart, "HH:mm"), "minutes");
      return Math.ceil(differenceTime / step);
    };

    // ToDo обработать format (где у каждого сервиса может быть свой формат приема)
    const params = {};
    Object.entries(this.visitsData).forEach(([visitId, data]) => {
      let scheduleId = parseInt(visitId);
      // ToDo Нужно переделать формат { [`visitId`]: {} }, на данный момент нахождение нового schedule_id реализую так, т.к. будет с наименьшим уроном
      if (this.scheduleInsertsType === INSERTS_TYPE.EDITING && this.actualDataAfterChangedField.length) {
        scheduleId = this.actualDataAfterChangedField.find((item) => item.date === data.date && moment(item.time, "HH:mm:ss").format("HH:mm") === data.time_start).id;
      }

      params[visitId] = {
        medical_file_id: patient?.medicalFileId || undefined,
        patient_full_name: patient?.fullName || undefined,
        patient_birthdate: patient?.birthdate || undefined,
        phone_number: patient?.phoneNumber || undefined,
        patient_know_about_us: patient?.patient_know_about_us || undefined,
        num_appointments: getNumAppointments({
          timeStart: data.time_start,
          timeEnd: data.time_end,
          step: data.doctor.schedule_step
        }),
        comment: data?.comment || undefined,
        entry_source: data.entry_source,
        schedule_id: scheduleId,
        format: "clinic", // возможные варианты ("online", "clinic", "chat")
        status_insurance: data?.payment_type || undefined,
        notification_method: data?.notification_type || undefined,
        service_ids: !data?.services || !data?.services.length ? undefined : data.services,
        date: data?.date,
        organization_id: data?.organization,
        doctor_id: data?.doctor?.id
      };
    });

    this.isLoading = true;
    for (const id in params) {
      try {
        await apiv2.scheduleInserts.postRecordScheduleInserts(params[id]);
        const visitById = this.visitsData[id];
        const insertMoment = moment(`${visitById.date} ${visitById.time_start}`);
        toastManager.showToast(
          <ScheduleInsertToast doctor={visitById.doctor} insertMoment={insertMoment} />,
          {
            duration: 10000
          }
        );

        generalScheduleStore.fetchDataTable(true);
      } catch (e) {
        toastManager.showToast(
          // ToDo нужно подумать насчет вывода уведомлений с ошибками
          <div>Ошибка на стороне 1С</div>,
          {
            duration: 10000
          }
        );
      }
    }

    this.removeMultipleAppointments({ clearAll: true })
    this.isLoading = false;
    if (callback) {
      callback();
    }
  }

  async cancelScheduleInserts({ callback }) {
    const scheduleId = parseInt(Object.keys(this.visitsData)[0]);
    const entrySource = this.visitsData[scheduleId].entry_source;
    this.isLoading = true;

    try {
      const response = await apiv2.scheduleInserts.postCancelScheduleInserts({
        schedule_id: scheduleId,
        entry_source: entrySource
      });
      // ToDo
      //  null value in column "type" violates not-null constraint
      //  entry_source: [{message: ""Врачи (Ася)" не является корректным значением.", code: "invalid_choice"}]
      toastManager.showToast(
        // ToDo нужно подумать насчет вывода уведомлений с ошибками
        <div>Запись удалена</div>,
        {
          duration: 10000
        }
      );

      generalScheduleStore.fetchDataTable(true);
    } catch (e) {
      console.error(e);
    }

    this.isLoading = false;
    if (callback) {
      callback();
    }
  }

  async handlerAdditionalRecordingAction(key) {
    const notificationByKey = [
      "Телемед создан",
      "Отправлена ссылка на онлайн и оплату",
      "Отправлена ссылка на оплату",
      "Отправлена ссылка на онлайн"
    ];

    const scheduleId = parseInt(Object.keys(this.visitsData)[0]);
    try {
      await apiv2.scheduleInserts.postAdditionalRecordingAction({
        schedule_id: scheduleId,
        number: key
      });
      toastManager.showToast(
        <div>{notificationByKey[key]}</div>,
        {
          duration: 10000
        }
      );
    } catch (e) {
      toastManager.showToast(
        <div>Ошибка на стороне 1С</div>,
        {
          duration: 10000
        }
      );
    }
  }

  async getDocumentForPrintByType(key) {
    try {
      const response = await apiv2.scheduleInserts.getDocumentForPrint({
        schedule_id: Object.keys(this.visitsData)[0],
        number: key
      });
      window.open(response.url, "_blank");
    } catch (e) {
      toastManager.showToast(<div>Ошибка на стороне 1С</div>, {
        duration: 10000
      });
    }
  }
}

export default new ScheduleInsertsStore();
