import React, { useCallback } from "react";

import useReactRouter from "use-react-router";

import * as S from "./MyPatientsTableViewersPatient.styled";

export const MyPatientsTableViewersPatient = ({ cell }) => {
  const { history } = useReactRouter();

  const handleLinkClick = useCallback(() => {
    history.push(`/appointment/${cell.appointmentId}`);
  }, [history, cell]);

  return (
    <S.Container>
      <span>{cell.value}</span>
      {cell.badge && <S.Badge>{cell.badge}</S.Badge>}
      <S.LinkBadge onMouseDown={handleLinkClick} className="sc-link-badge">
        <S.FileIcon to={`/appointments/${cell.appointmentId}`} />
      </S.LinkBadge>
    </S.Container>
  );
};
