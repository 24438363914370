import React from "react";
import styled from "styled-components";
import { GeneralSchedulePlaceholderCard } from "./GeneralSchedulePlaceholderCard";

const GeneralSchedulePlaceholderContent = styled.div`
  display: flex;
`;

export const GeneralSchedulePlaceholder = () => {
  return (
    <GeneralSchedulePlaceholderContent>
      {new Array(4).fill().map((value, index) => (
        <GeneralSchedulePlaceholderCard key={index} />
      ))}
    </GeneralSchedulePlaceholderContent>
  );
};
