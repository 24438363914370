// @flow
import * as React from "react";
import styled from "styled-components";
import trashIcon from "./icons/trash.svg";
import trashIconGrey from "./icons/trash-grey.svg";
import { DefaultButton } from "./DefaultButton";
import { ButtonWithTooltip } from "./ButtonWithTooltip";

const Icon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${trashIcon});
`;

const IconContainer = styled.div`
  margin-right: 6px;
`;

const TextContainer = styled.div`
  margin-right: 8px;
`;

export const CleanButton = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  children: ?React.Node
}) => (
  <DefaultButton
    {...props}
    overrides={{
      Icon: {
        component: Icon
      },
      IconContainer: {
        component: IconContainer
      },
      TextContainer: {
        component: TextContainer
      }
    }}
  />
);

export const CleanButtonAsIcon = (props: { onClick?: () => mixed }) => (
  <ButtonWithTooltip
    icon={trashIconGrey}
    iconHover={trashIcon}
    tooltip="Очистить"
    onClick={props.onClick}
  />
);
