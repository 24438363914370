//@flow
import * as React from "react";
import styled from "styled-components";

import type { TAppointment_id } from "../../domain/entities/Appointment";
import { colors } from "../styleguide/colors";
import { PSmall, HeadingH4 } from "../styleguide/typography";
import { Container } from "../form/FormSection";

const StyledContainer = styled(Container)`
  height: 70px;
  border-left: 3px solid ${colors.magenta};
  opacity: 1;
  display: grid;
  grid-template-columns: 250px auto;
  align-items: center;
`;

const Text = styled(PSmall)`
  opacity: 0.6;
`;

export const PatientIdSection = (props: { id: TAppointment_id, text: string }) => (
  <StyledContainer>
    <HeadingH4>{props.id}</HeadingH4>
    <Text>{props.text}</Text>
  </StyledContainer>
);
