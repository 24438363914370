import styled from "styled-components";
import { colors } from "../../styleguide/colors";

export const List = styled.ul`
  padding-left: 28px;
  font-size: 15px;
  line-height: 24px;
`;

export const Item = styled.li`
  position: relative;
  overflow-wrap: anywhere;

  &::before {
    content: "";
    position: absolute;
    top: 8px;
    right: calc(100% + 13px);
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${colors.gray200};
  }
`;

export const Comment = styled.span`
  font-size: 13px;
  color: #979797;
`;
