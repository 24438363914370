// @flow
import type { TAppointment_id } from "../../domain/entities/Appointment";
import * as R from "ramda";
import { createAppointmentStore } from "../../stores/createAppointmentStore";
import { type Store } from "effector";
import type { Checklist, TChecklistGuid } from "../../domain/entities/Checklist";
import { createChildrenStore, initializeChecklistStore } from "../../stores/appointmentChecklists";
import { useAutoCleanedStore } from "../../utils/useAutoCleanedStore";

export const useChecklist = (
  rootStore: Store<{ [TAppointment_id]: ?Array<Checklist> }>,
  appointment_id: TAppointment_id,
  defaultValues?: Array<Checklist>
) => {
  const storeCreator = R.compose(
    initializeChecklistStore,
    (createAppointmentStore(appointment_id, defaultValues || []): any)
  );
  return useAutoCleanedStore<*, *, *>(() => storeCreator(rootStore), [appointment_id]);
};

export const useChildrenChecklist = (
  rootStore: Store<Array<Checklist>>,
  guid: TChecklistGuid,
  isDependent?: boolean
) => {
  const storeCreator = R.compose(
    initializeChecklistStore,
    (createChildrenStore(guid, [], isDependent): any)
  );
  return useAutoCleanedStore<*, *, *>(() => storeCreator(rootStore), [guid]);
};
