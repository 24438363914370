import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class OldAuth {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api/auth', null, null, API_URL);
  }

  getCurrentUser() {
    return this.apiService.get('current-user');
  }

  postObtainToken({ username, password }) {
    return this.apiService.post('obtain-token', {}, { username, password });
  }

  postRefreshToken(token) {
    return this.apiService.post('refresh-token', {}, { token });
  }

  logout() {
    return this.apiService.post('logout');
  }
}

export default new OldAuth();
