import * as React from "react";

import * as S from "./styled/SidebarTreeBreadcrumbs.styled";

export const SidebarTreeBreadcrumbs = ({ items, onSelectLevel }) => {
  return (
    <S.Container>
      {items.length > 0 &&
        items.map(({ id, title, active }, index) => (
          <S.Breadcrumb onClick={() => onSelectLevel(index)} key={id} active={active}>
            <S.Dot active={active} />
            {title}
          </S.Breadcrumb>
        ))}
    </S.Container>
  );
};
