// @flow
import * as React from "react";
import {
  Avatar,
  AvatarPlaceholder,
  Container,
  ContainerWrapper,
  InnerContainer,
  Text,
  TextContainer
} from './greetingCommon';
import {HeadingH2} from '../styleguide/typography';
import {getNounEndingByNumber} from '../../utils/getNounEndingByNumber';
import {observer} from 'mobx-react';
import authStore from '../../stores/authStore';
import chatStore from '../../stores/chatStore';

const Greeting = (props: {|
  firstName: string,
  lastName?: string,
  avatar: ?string,
  unreadMessagesCount: ?number,
|}) => {
  const { firstName, lastName, unreadMessagesCount } = props;

  return (
    <Container>
      <ContainerWrapper>
        {props.avatar && !props.avatar.startsWith("#") ? (
          <Avatar avatar={props.avatar} />
        ) : (
          <AvatarPlaceholder>
            {(lastName ? lastName.slice(0, 1) : "") + firstName.slice(0, 1)}
          </AvatarPlaceholder>
        )}
        <InnerContainer>
          <HeadingH2>Чаты</HeadingH2>
          <TextContainer>
            <Text as="span">
              Добрый день, {firstName}! {unreadMessagesCount > 0 ? "У вас " : ""}
            </Text>
            {unreadMessagesCount ? (
              <Text as="span" modifiers={["bold", "color"]}>
                {unreadMessagesCount}{" "}
                {getNounEndingByNumber(unreadMessagesCount, ["непрочитанное сообщение", "непрочитанных сообщения", "непрочитанных сообщений"])}
              </Text>
            ) : ""}
          </TextContainer>
        </InnerContainer>
      </ContainerWrapper>
    </Container>
  );
};

export const GreetingContentChats = observer(() => {
  const user = authStore.user;

  if (!user) {
    return null;
  }

  return (
    <Greeting
      firstName={user.doctor.first_name}
      lastName={user.doctor.last_name}
      avatar={user.doctor.photo}
      unreadMessagesCount={chatStore.unreadMessagesCount}
    />
  );
});
