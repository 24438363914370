import styled from "styled-components";
import { NumericInput } from "../form-elements/NumericInput";
import { colors } from "../styleguide/colors";
import React from "react";

const ServicePriceInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Price = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: ${props => (props.isActive ? colors.gray600 : colors.gray400)};
  min-width: 81px;
  text-align: right;
  flex-shrink: 0;
`;

const InputContainer = styled.div`
  height: 31px;
  position: relative;
  flex-shrink: 0;

  div {
    max-width: 59px;
    width: auto;
  }

  input {
    padding: 4px 28px 4px 0;
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    position: relative;
    height: 31px;

    &:focus + span {
      display: none;
    }
  }
`;

const Measure = styled.span`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  z-index: 1;
`;


export const ServicePriceInput = props => {
  return (
    <ServicePriceInputWrapper>
      {props.isActive && (
        <InputContainer>
          <NumericInput
            readonly={props.readonly && 'readonly'}
            min={1}
            step="1"
            selectValueAfterFocus
            value={props.count}
            handleChange={(value) => props.handleChange(value)}
            suffix={<Measure>шт.</Measure>}
          />
        </InputContainer>
      )}
      <Price
        isActive={props.isActive}>{props.price ? `${(props.price * (props.count)).toLocaleString()}₽` : "Нет цены"}
      </Price>
    </ServicePriceInputWrapper>
  )
};