// @flow

type TResult<T> = (list: $ReadOnlyArray<T>) => Array<T>;

export const reorder = <T>(startIndex: number, endIndex: number): TResult<T> => list => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
