import * as React from "react";
import { DefaultInput } from "../form-elements/DefaultInput";
import { getTextValue } from "../appointment-widgets/getTextValue";

export const TextWidget = ({ field, tabIndex, layoutFieldValue, onChange }) => {
  const value = getTextValue(field, layoutFieldValue);

  return (
    <DefaultInput
      value={value}
      tabIndex={tabIndex}
      handleChange={newValue => {
        onChange({
          field_type: field.field_type,
          field_id: field.id,
          value: newValue
        });
      }}
    />
  );
};
