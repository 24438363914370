// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  width: ${props => (props.isHorizontal ? "1520px" : "1025px")};
  ${p => (p.width ? `width: ${p.width};` : null)}

  ${p => (p.breakAfter ? "@media print {page-break-after: always;}" : null)}
`;

const HeaderContainer = styled.div`
  padding-bottom: 13px;
  border-bottom: solid 1px ${colors.transparentGray};
`;

const SignContainer = styled.div`
  page-break-inside: avoid;
  display: flex;
  justify-content: flex-end;
`;

export const AppointmentPrintLayout = (props: {
  width?: string,
  breakAfter?: boolean,
  isHorizontal?: boolean,
  header: React$Node,
  contacts?: React$Node,
  title: React$Node,
  values: React$Node,
  sign?: React$Node,
  userAgreement?: React$Node
}) => {
  return (
    <Container isHorizontal={props.isHorizontal} breakAfter={props.breakAfter} width={props.width}>
      {props.header && <HeaderContainer>{props.header}</HeaderContainer>}
      {props.contacts}
      {props.title}
      {props.values}
      {props.sign && <SignContainer>{props.sign}</SignContainer>}
      {props.userAgreement}
    </Container>
  );
};
