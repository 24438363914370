// @flow
import * as React from "react";

import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {
  DescriptionText,
  FlexContainer,
  HeaderText,
  LogoWrapper,
  SectionBottom,
  SectionTop
} from "./reviewStyled";

import logo from "./assets/logo-already.png";
import logoRassvet from "./assets/logo-already-rassvet.png";
import reviewStore, { CLINIC_CODE } from "../../stores/reviewStore";

export const ReviewAlready = withRouter(observer(() => {

  const handleClick = () => {
    window.close();
  };

  return (
    <FlexContainer>
      <SectionTop>
        <LogoWrapper>
          <img src={reviewStore.clinic_code === CLINIC_CODE.rassvet ? logoRassvet : logo} />
        </LogoWrapper>
        <HeaderText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
          По данной ссылке бонусные рубли уже начислены
        </HeaderText>
        <DescriptionText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
          Спасибо за ваш отзыв!
        </DescriptionText>
      </SectionTop>
      <SectionBottom>
      </SectionBottom>
    </FlexContainer>
  );
}));
