// @flow
import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const StyledDialog = styled(Dialog)(({ theme, variant }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 20,
    background: "#fff"
  },
  "& .MuiDialogTitle-root": {
    padding: `${theme.spacing(4)} ${theme.spacing(4)} 0`
  },
  "& .MuiDialogContent-root": {
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`
  },
  "& .MuiDialogActions-root": {
    padding: `0 ${theme.spacing(4)} ${theme.spacing(4)}`,
    gap: theme.spacing(1.5)
  },
  "& .MuiDialogActions-root button": {
    flex: 1
  }
}));

export const CorporateModalWindow = (props: {
  open: boolean,
  header: string | React$Node,
  content: string | React$Node,
  actions?: React$Node,
  onClose: () => mixed,
  maxWidth?: string | boolean,
  variant?: "dark"
}) => {
  const { variant, header, content, actions, open, maxWidth, onClose } = props;

  return (
    <StyledDialog
      onClose={onClose}
      open={open}
      variant={variant}
      maxWidth={maxWidth}
      className="corporate-portal-modal">
      <DialogTitle sx={{ m: 0, p: 2 }}>{header}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.black.main
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>{content}</DialogContent>
      {actions && <DialogActions disableSpacing>{actions}</DialogActions>}
    </StyledDialog>
  );
}
