// @flow
import { useState } from "react";
import { useStore } from "effector-react";
import _ from "lodash";

import { tokenStore } from "../../stores/auth";
import { useApiRequest } from "../../apiv2/useApiRequest";
import apiv2 from "../../apiv2";

export const useOrganizationSelect = (): {
  items?: { [string]: { name: string } },
  active?: string,
  onSelect?: (key: string) => mixed
} => {
  const [active, onSelect] = useState();
  const emptyResult = { items: {} };
  const token = useStore(tokenStore);

  const { data: organizations } = useApiRequest(async () => {
    return apiv2.organizations.getOrganizations();
  }, []);

  if (!organizations) {
    return emptyResult;
  }

  const items = _.chain(organizations)
    .map(item => ({ id: item.id, name: item.name }))
    .keyBy(item => `${item.id}`)
    .value();

  return { items, active, onSelect };
};
