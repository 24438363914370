import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Tooltip } from "@mui/material";
import { colors } from "../../styleguide/colors";

const IconsWrapper = styled(Box)(
  ({ length }) => `
    position: relative;
    height: 20px;
    width: ${length * 11}px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  `
);

const IconContainer = styled("div")(
  ({ number }) => `
    cursor: pointer;
    width: 14px;
    height: 14px;
    background: ${colors.white};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: ${number * 10}px;
    z-index: 1;
    :hover {
      z-index: 10;
      width: 16px;
      height: 16px;
    }
    transition: all .15s ease-in-out;
    `
);

export const AppointmentInformationIcons = ({ data }) => {
  return !data.length ? null : (
    <IconsWrapper length={data.length}>
      {data.map((item, index) => (
        <Tooltip key={index} title={item?.tooltipText || ""}>
          <IconContainer number={index}>{item.icon}</IconContainer>
        </Tooltip>
      ))}
    </IconsWrapper>
  );
};
