import React from "react";
import { BaseLayout } from "../layouts/BaseLayout";
import { NavigationTabs } from "../navigation-tabs/NavigationTabs";

export const MoreNavigationTabs = () => {
  return (
    <BaseLayout>
      <NavigationTabs isMobileDevice isShowMoreTabs />
    </BaseLayout>
  )
}