// @flow
import React from "react";
import { PointsContainer } from "./PointsContainer";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import { getAppointment_id } from "../../domain/entities/Appointment";
import nanoid from "nanoid";
import { getChecklistGuid } from "../../domain/entities/Checklist";
import { PointTemplate } from "./PointTemplate";
import { CheckboxLabel } from "./CheckboxLabel";
import { useChecklist, useChildrenChecklist } from "./useChecklist";
import { appointmentMedicationStore } from "../../stores/decisionSupportStores";
import { useList, useStore } from "effector-react";
import { PointButtons } from "./PointButtons";
import { PointHint } from "./PointHint";
import { PointTitle } from "./PointTitle";
import { PointAdding } from "./PointAdding";
import { MedicinesSuggestProvider } from "./MedicinesSuggestProvider";

import * as R from "ramda";
import { tokenStore } from "../../stores/auth";
import { PointTitleWithButtonTemplate } from "./PointTitleWithButtonTemplate";
import { SubpointsToggleButton } from "./SubpointsToggleButton";
import { SubpointsProvider } from "./SubpointsProvider";
import { SubpointsContainer } from "./SubpointsContainer";
import { SubpointTemplate } from "./SubpointTemplate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import type { MedicineSuggest } from "./MedicinesSuggestProvider";
import { ChecklistWithCommaSeparatedChildren } from "./ChecklistWithCommaSeparatedChildren";
import apiv2 from '../../apiv2';
import { dispatchEvent, useSubscribe } from "../../utils/useEvents";

const transformStringToChecklist = (value: string, is_checked: boolean) =>
  R.compose(
    R.set(R.lensProp("guid"), nanoid()),
    R.set(R.lensProp("name"), value),
    R.set(R.lensProp("is_checked"), is_checked)
  );

const transformMedicineSuggestToChecklist = (medicineSuggest: MedicineSuggest) => {
  if (medicineSuggest.type === "MEDICINE") {
    return R.compose(
      R.set(
        R.lensProp("items"),
        R.map(
          value => transformStringToChecklist(value.name, value.is_main)({}),
          medicineSuggest.variants
        )
      ),
      transformStringToChecklist(medicineSuggest.name, true)
    );
  } else {
    return R.compose(
      R.set(
        R.lensProp("items"),
        R.map(
          value => transformStringToChecklist(value.name, medicineSuggest.id === value.id)({}),
          medicineSuggest.parent.variants
        )
      ),
      transformStringToChecklist(medicineSuggest.parent.name, true)
    );
  }
};

const proposeMedicine = name => {
  const token = tokenStore.getState();
  if (!token) {
    return;
  }
  apiv2.medicines.propose(name);
};

const AppointmentMedicationChildren = props => {
  const { store, guid } = props;
  const { store: checklistStore, toggleChecklist, setChecklistIsChecked } = useChildrenChecklist(store, guid, true);

  useSubscribe("parentChecklistCheck", ({ guid: changedGuid, value }) => {
    if (changedGuid === guid) {
      const checklist = checklistStore.getState();
      checklist.forEach(item => {
        setChecklistIsChecked({ guid: getChecklistGuid(item), value });
        dispatchEvent("medicationCheckChange", {
          item: item,
          value: value
        });
      });
    }
  });

  const list = useList(checklistStore, checklistItem => (
    <SubpointTemplate
      icon={
        <CheckboxLabel
          type="button"
          modifiers={(!checklistItem.is_checked && "disabled") || undefined}
          onClick={() => toggleChecklist(getChecklistGuid(checklistItem))}
        />
      }
      title={<PointTitle disabled={!checklistItem.is_checked} text={checklistItem.name} />}
    />
  ));

  return <SubpointsContainer>{list}</SubpointsContainer>;
};

export const AppointmentMedication = (props: { appointment: AppointmentWithDetails }) => {
  const { appointment, checklist, store } = props;
  const appointment_id = getAppointment_id(appointment);

  const defaultMedicationChecklists = checklist ? checklist :
    (appointment.medication_checklist && appointment.medication_checklist.items) || [];
  const medicationStore = store ? store : appointmentMedicationStore;

  const {
    addChecklist,
    toggleChecklist,
    setComment,
    remove,
    removeComment,
    store: checklistStore,
    reorder
  } = useChecklist(medicationStore, appointment_id, defaultMedicationChecklists);
  useStore(medicationStore);

  const toggleAllItems = guid => {
    toggleChecklist(guid);

    const checklistStoreState = checklistStore.getState();

    const checklist = checklistStoreState.find(checklist => checklist.guid === guid);

    if (checklist) {
      dispatchEvent("parentChecklistCheck", {
        guid: guid,
        value: checklist.is_checked
      });
    }
  };

  return (
    <>
      <PointsContainer>
        <DragDropContext
          onDragEnd={result =>
            reorder({ start: result.source.index, end: result.destination.index })
          }>
          <Droppable droppableId="medications">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {checklistStore.getState().map((checklist, index) => (
                  <SubpointsProvider key={checklist.guid}>
                    {({ isOpen, toggleOpen }) => (
                      <Draggable key={checklist.guid} draggableId={checklist.guid.toString()} index={index}>
                        {provided => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <PointTemplate
                              icon={
                                <CheckboxLabel
                                  type="button"
                                  modifiers={(!checklist.is_checked && "disabled") || undefined}
                                  onClick={() => toggleAllItems(getChecklistGuid(checklist))}
                                />
                              }
                              buttons={
                                <PointButtons
                                  comment={checklist.comment}
                                  setComment={value => setComment({ value, id: getChecklistGuid(checklist) })}
                                  removeComment={() => removeComment(getChecklistGuid(checklist))}
                                  remove={() => remove(getChecklistGuid(checklist))}
                                />
                              }
                              hint={
                                checklist.comment && (
                                  <PointHint disabled={!checklist.is_checked}>{checklist.comment}</PointHint>
                                )
                              }
                              title={
                                <PointTitleWithButtonTemplate
                                  title={
                                    <PointTitle
                                      inline={true}
                                      disabled={!checklist.is_checked}
                                      text={<ChecklistWithCommaSeparatedChildren checklist={checklist} />}
                                    />
                                  }
                                  button={
                                    checklist.items &&
                                    checklist.items.length > 0 && (
                                      <SubpointsToggleButton type="button" isOpen={isOpen} onClick={toggleOpen} />
                                    )
                                  }
                                />
                              }
                              isDraggable
                              dragHandleProps={provided.dragHandleProps}
                            />
                            {isOpen && (
                              <AppointmentMedicationChildren
                                store={checklistStore}
                                guid={getChecklistGuid(checklist)}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    )}
                  </SubpointsProvider>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </PointsContainer>
      <PointAdding
        title="Добавить препарат"
        SuggestProvider={MedicinesSuggestProvider}
        select={item => {
          addChecklist(transformMedicineSuggestToChecklist(item)({}));
        }}
        propose={value => {
          addChecklist(transformStringToChecklist(value, true)({}));
          proposeMedicine(value);
        }}
      />
    </>
  );
};
