import DynamicTableStore from "../dynamicTableStore";
import apiv2 from "../../apiv2";

class ReportPunctureStore extends DynamicTableStore {
  constructor() {
    super(apiv2.reports.getReportPuncture.bind(apiv2.reports));
    this.enableSavingPresets(false);
  }

  setOthersTableData(response) {
    this.othersTableData = response.statistics;
  }
}

export default new ReportPunctureStore();
