import ApiService from "../apiService";
import authStore from '../../stores/authStore';
import apiv2 from '../index';

const API_URL = process.env.EMS_API_URL || location.origin;
class Chat {
  apiService;
  constructor() {
    this.apiService = new ApiService("/doctor_mobile_api", null, null, API_URL);
  }

  getMessages(chatId, per_page, page) {
    return this.apiService.get("messages", {
      receiver_id: chatId,
      receiver_model_name: "ChatRoom",
      per_page,
      page
    });
  }

  sendMessage(data) {
    return this.apiService.post("messages", {}, data);
  }

  updateMessage(id, text) {
    return this.apiService.patch(`messages/${id}`, {}, { text });
  }
  deleteMessage(id) {
    return this.apiService.delete(`messages/${id}`);
  }

  // readMessageById(messageId) {
  //   return this.apiService.patch(`mobile-api/messages/${messageId}`, {}, { read: true });
  // }

  readAllMessages(chatId) {
    return this.apiService.patch(`chatroom/${chatId}/unread`);
  }

  async changeChatOpenState(patientId, canChat, closingDate) {
    return await apiv2.doctors.patchDoctorPermission(authStore.user.doctor.id, {
      patient_id: patientId,
      patient_can_chat: canChat,
      close_date: closingDate
    });
  }
}

export default new Chat();
