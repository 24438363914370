import React from "react";
import styled from "styled-components";
import { PreloaderBody } from "../prealoader/PreloaderBody";

const ModifiedPreloaderBody = styled(PreloaderBody)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PlaceholderBase = styled.div`
  height: 10px;
  border-radius: 3px;
  background: #d4d4d4;
  opacity: 0.5;
  width: 260px;
`;

const PlaceholderCardBody = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  height: 100%;
  width: 260px;
  box-sizing: border-box;
  padding-top: 10px;

  &:not(:last-child) {
    margin-right: 6px;
  }
`;

const PlaceholderCardDate = styled(PlaceholderBase)`
  width: 182px;
  height: 23px;
  margin-bottom: 10px;
`;

const PlaceholderCardName = styled(PlaceholderBase)`
  width: 182px;
  height: 12px;
  margin-bottom: 30px;
`;

const PlaceholderCardOrganization = styled(PlaceholderBase)`
  width: 182px;
  height: 12px;
  margin-bottom: 10px;
`;

const PlaceholderCardSpecializations = styled(PlaceholderBase)`
  width: 182px;
  height: 24px;
  margin-bottom: 10px;
`;

const PlaceholderCardDoctors = styled.div`
  display: flex;
  flex: 1;
`;

const PlaceholderCardDoctorColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const PlaceholderCardDoctorAvatar = styled(PlaceholderBase)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const PlaceholderCardDoctorTimesheet = styled(PlaceholderBase)`
  width: 260px;
  flex: 1;
`;

const PlaceholderCardDoctor = () => {
  return (
    <PlaceholderCardDoctorColumn>
      <PlaceholderCardDoctorAvatar />
      <PlaceholderCardName />
      <PlaceholderCardOrganization />
      <PlaceholderCardSpecializations />
      <PlaceholderCardDate />
      <PlaceholderCardDoctorTimesheet />
    </PlaceholderCardDoctorColumn>
  );
};

export const GeneralSchedulePlaceholderCardNew = () => {
  return (
    <PlaceholderCardBody>
      <ModifiedPreloaderBody>
        <PlaceholderCardDoctors>
          <PlaceholderCardDoctor />
        </PlaceholderCardDoctors>
      </ModifiedPreloaderBody>
    </PlaceholderCardBody>
  );
};
