import React from "react";
import { MuiTelInput } from "mui-tel-input";
import { Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { FormControl, FormLabel } from "@mui/material";
import { Asterisk } from "../styled/Asterisk.styled";

const TelInput = styled(MuiTelInput)(
  () => `
    .MuiInputBase-input.Mui-disabled {
      color: rgba(0, 0, 0, 0.38);
    }
    .Mui-disabled .MuiInputAdornment-root {
      opacity: 0.7;
    }
  `
);

export const FormInputPhoneNumber = props => {
  const newProps = { ...props };
  delete newProps.type;
  delete newProps.subInfo;

  return (
    <FormControl fullWidth className={'dynamic-form-input-control'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
      {(props.label && props.withLabel) && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
          return (
            <TelInput
              {...fieldProps}
              {...newProps}
              label={props.withLabel ? '' : props.label}
              value={value || ""}
              defaultCountry="RU"
              fullWidth
              variant="outlined"
              inputProps={{ readOnly: props.readOnly }}
              helperText={error ? error.message : props.subInfo ? props.subInfo : null}
              error={!!error}
            />
          );
        }}
      />
    </FormControl>
  );
};
