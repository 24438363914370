// @flow
import type { Field, FieldSingle } from "../entities/Field";
import _ from "lodash";
import type { LayoutFieldValue, AppointmentWithDetails } from "../entities/Appointment";
import type { Section } from "../entities/Section";
import type { TFieldValuesMap, TFieldValue } from "../../stores/appointmentFieldValues";
import type { LayoutDetailed } from "../entities/Layout";
import moment from "moment";
import * as R from "ramda";
import { isValidBodyMassIndex } from "./bodyMassIndex";
import {Appointment} from "../entities/Appointment";
import { FEATURE, featureEnabledForOrganization } from "../../utils/applicationFeatures";

export const isLabelNeededAtGroup = (field: FieldSingle): boolean => {
  return (
    field.field_type === "text" ||
    field.field_type === "textarea" ||
    field.field_type === "date" ||
    field.field_type === "number"
  );
};

const fieldSingleIsEmpty = (field: FieldSingle, fieldValue?: LayoutFieldValue): boolean => {
  if (field.field_type === "file") {
    if (!fieldValue || !fieldValue.files) {
      return true;
    }
    return fieldValue.files.length === 0;
  } else if (field.field_type === "body_mass_index") {
    if (!fieldValue) {
      return true;
    }
    // $FlowFixMe
    return Boolean(fieldValue.value && (!fieldValue.value.height || !fieldValue.value.weight));
  } else if (field.field_type === "formula") {
    if (!fieldValue) {
      return true;
    }
    // $FlowFixMe
    return Boolean(
      fieldValue.value === undefined || fieldValue.value === null || !fieldValue.value.toString()
    );
  } else {
    if (field.field_type === "number" && fieldValue && typeof fieldValue.value === "number") {
      return false;
    }
    if (!fieldValue || !fieldValue.value) {
      return true;
    }
    if (typeof fieldValue.value === "string") {
      return fieldValue.value.trim().length === 0;
    }
    if (fieldValue.value instanceof Array) {
      return fieldValue.value.length === 0;
    }
  }
  return false;
};

export const fieldIsEmpty = (
  field: Field,
  fieldValuesMap: { [id: number]: LayoutFieldValue }
): boolean => {
  if (field.field_type === "group") {
    return !_.find(field.children, child => !fieldSingleIsEmpty(child, fieldValuesMap[child.id]));
  } else {
    return fieldSingleIsEmpty(field, fieldValuesMap[field.id]);
  }
};

export const sectionIsEmpty = (
  section: Section,
  fieldValuesMap: { [id: number]: LayoutFieldValue }
): boolean => {
  return !_.find(section.fields, field => !fieldIsEmpty(field, fieldValuesMap));
};

export const sectionShouldBeHidden = (section: Section): boolean => {
  return !!_.find(section.fields, field => field.field_type === "prescription");
};

export type TDefaultValuesMap = {
  [field_id: number]: {
    field_type: $ElementType<FieldSingle, "field_type">,
    value: string | Array<string>
  }
};

export const isFieldWithoutLabel = (field: Field) => {
  return field.field_type === "prescription";
};

export const getDefaultFieldValuesForSection = (section: Section): TDefaultValuesMap => {
  const result = {};
  for (let field of section.fields) {
    if (field.field_type === "group") {
      for (let child of field.children) {
        if (
          child.field_type !== "file" &&
          child.field_type !== "prescription" &&
          child.field_type !== "body_mass_index"
        ) {
          result[child.id] = { field_type: child.field_type, value: child.default_value };
        }
      }
    } else if (
      field.field_type !== "file" &&
      field.field_type !== "prescription" &&
      field.field_type !== "body_mass_index"
    ) {
      result[field.id] = { field_type: field.field_type, value: field.default_value };
    }
  }
  return result;
};

export const getDefaultFieldValues = (layout: LayoutDetailed): TDefaultValuesMap => {
  const result = {};
  for (let section of layout.sections) {
    Object.assign(result, getDefaultFieldValuesForSection(section));
  }
  return result;
};

export const getRequiredFieldForSection = (section: Section): TDefaultValuesMap => {
  const result = [];
  const checkField = (field) => {
    return field.field_type !== "file" &&
      field.field_type !== "prescription" &&
      field.field_type !== "body_mass_index" &&
      field.required
  }
  for (let field of section.fields) {
    if (field.field_type === "group") {
      for (let child of field.children) {
        if (checkField(child)) {
          result.push({ id: child.id, name: child.name, parent: field.id });
        }
      }
    } else if (checkField(field)) {
      result.push({id: field.id, name: field.name });
    }
  }
  return result;
};

export const getRequiredFields = (layout: LayoutDetailed, appointment: Appointment, questionnaireSectionFemaleId): TDefaultValuesMap => {
  let result = [];
  for (let section of layout.sections) {
    if (section.id !== questionnaireSectionFemaleId) {
      result = [...result, ...getRequiredFieldForSection(section)];
    } else if (appointment.medical_file.patient.sex === 'F') {
      result = [...result, ...getRequiredFieldForSection(section)];
    }
  }
  return result;
};

export const getRequiredFieldsForDecisionSupport = (layout, appointment) => {
  const result = [];
  /** Исключение для шаблона "Консультация психолога, психотерапевта", т.к. мед.деп сообщил, что здесь может не быть диагноза */
  const isException = appointment.layout.name === "Консультация психолога, психотерапевта";
  if (!layout.decision_support || isException) {
    return result;
  }

  if (
    featureEnabledForOrganization(FEATURE.REQUIRED_FIELD_DIAGNOSIS_MKB, appointment.medical_file.organization.id) &&
    layout.decision_support
  ) {
    result.push({
      name: "Диагноз по МКБ",
      id: 'primary_diagnoses'
    });
  }
  return result;
}

export const getFilledLayoutFieldValues = (
  appointment: AppointmentWithDetails
): Array<LayoutFieldValue> =>
  R.filter(
    item => R.not(R.isNil(item.value) || R.isEmpty(item.value)),
    appointment.layout_field_values
  );

export const getDefaultFieldValuesWithoutFilled = (
  defaultValues: TDefaultValuesMap,
  fieldValues: TFieldValuesMap,
  appointment: AppointmentWithDetails
): TDefaultValuesMap => {
  const updatedEmptyValuesIds = fieldValues
    ? R.compose(
        R.keys,
        R.filter(item => R.isNil(item.value) || R.isEmpty(item.value))
      )(fieldValues)
    : [];

  const filledValuesIds = R.compose(
    R.without(updatedEmptyValuesIds),
    R.map(item => "" + item.field)
  )(getFilledLayoutFieldValues(appointment));

  return R.omit(filledValuesIds, defaultValues);
};

export const getEmptyFieldValuesForSection = (section: Section): TDefaultValuesMap => {
  const result = {};
  const empty_BMI = { height: null, weight: null };
  for (let field of section.fields) {
    if (field.field_type === "group") {
      for (let child of field.children) {
        if (child.field_type !== "file" && child.field_type !== "prescription") {
          result[child.id] = {
            field_type: child.field_type,
            value:
              child.field_type === "checkbox"
                ? []
                : child.field_type === "body_mass_index"
                ? empty_BMI
                : ""
          };
        }
      }
    } else if (field.field_type !== "file" && field.field_type !== "prescription") {
      result[field.id] = {
        field_type: field.field_type,
        value:
          field.field_type === "checkbox"
            ? []
            : field.field_type === "body_mass_index"
            ? empty_BMI
            : ""
      };
    }
  }
  return result;
};

export const getEmptyFieldValues = (layout: LayoutDetailed): TDefaultValuesMap => {
  const result = {};
  for (let section of layout.sections) {
    Object.assign(result, getEmptyFieldValuesForSection(section));
  }
  return result;
};

const fieldSingleHasDefaultValue = (field: FieldSingle): boolean => {
  if (
    field.field_type !== "file" &&
    field.field_type !== "prescription" &&
    field.default_value !== ""
  ) {
    return true;
  } else if (field.default_value instanceof Array && field.default_value.length !== 0) {
    return true;
  }

  return false;
};

export const checkIfThereAreDefaultFieldValuesInSection = (section: Section): boolean => {
  for (let field of section.fields) {
    if (field.field_type === "group") {
      for (let child of field.children) {
        if (fieldSingleHasDefaultValue(child) === true) return true;
      }
    } else {
      if (fieldSingleHasDefaultValue(field) === true) return true;
    }
  }

  return false;
};

export const READABLE_DATE_FORMAT = "DD.MM.YYYY";

export const formatDateToReadableDateFormat = (date: string) =>
  moment(date).format(READABLE_DATE_FORMAT);

export const parseDateFromReadableFormat = (date: string) => moment(date, READABLE_DATE_FORMAT);

export const isValidReadableDate = (date: any): boolean =>
  !date || (date.length === 10 && moment(date, READABLE_DATE_FORMAT).isValid());

export const getFieldSingleTextValue = (
  field: FieldSingle,
  valueWrapper: LayoutFieldValue
): ?string => {
  if (
    field.field_type === "text" ||
    field.field_type === "textarea" ||
    field.field_type === "radiobutton" ||
    field.field_type === "time"
  ) {
    if (typeof valueWrapper.value !== "string") {
      console.warn("Expected text field value");
      return;
    }
    return valueWrapper.value;
  } else if (field.field_type === "number") {
    if (typeof valueWrapper.value !== "number") {
      console.warn("Expected number field value");
      return;
    }
    return valueWrapper.value.toString();
  } else if (field.field_type === "date") {
    if (typeof valueWrapper.value !== "string") {
      console.warn("Expected text field value");
      return;
    }
    try {
      return moment(valueWrapper.value).format(READABLE_DATE_FORMAT);
    } catch (e) {
      console.warn(e);
    }
  } else if (field.field_type === "checkbox") {
    if (!(valueWrapper.value instanceof Array)) {
      console.warn("Expected array field value");
      return;
    }
    return valueWrapper.value.join(", ");
  } else if (field.field_type === "file") {
    if (!(valueWrapper.files instanceof Array)) {
      console.warn("Expected array field files");
      return;
    }
    return valueWrapper.files.map(file => file.name).join(", ");
  } else if (field.field_type === "prescription") {
    return undefined;
  } else if (field.field_type === "body_mass_index") {
    return undefined;
  } else if (field.field_type === "formula") {
    if (typeof valueWrapper.value !== "string" && typeof valueWrapper.value !== "number") {
      console.warn("Expected formula field value");
      return;
    }
    return valueWrapper.value.toString();
  } else {
    (field.field_type: empty); // check that all field_type is considered
    return undefined;
  }
};

export const validateFieldByType = (field: TFieldValue): ?boolean => {
  if (field.field_type === "date") {
    return isValidReadableDate(field.value);
  } else if (field.field_type === "body_mass_index") {
    return isValidBodyMassIndex(field.value);
  }
};
