import React, { useCallback } from "react";

import { VIEW_TYPES, VIEW_TYPES_FILTERS } from "./constants";

import { ToolsContainer } from "./EmbryosByFolderPage";
import { PrintButtonDropDown } from "../appointment-page/PrintButtonDropDown";
import { EmbryosPage } from "./EmbryosPage";
import { createDocumentLinks } from "./utils";
import apiv2 from '../../apiv2';

export const CryoEmbryosPage = props => {
  const onGetEmbryos = useCallback(({ filter, patientId }) => {
    return apiv2.embryos.get({
      patientId,
      viewType: VIEW_TYPES_FILTERS[filter]
    });
  }, []);

  const menuItems = [
    {
      header: "Таблицы",
      links: [
        { title: "Все таблицы", to: `/embryos/${props.medicalFile.id}/print/${VIEW_TYPES.ALL}` },
        {
          title: "Криохранилище",
          to: `/embryos/${props.medicalFile.id}/print/${VIEW_TYPES.CRYOPRESERVATIONS}`
        },
        {
          title: "Размораживание",
          to: `/embryos/${props.medicalFile.id}/print/${VIEW_TYPES.DEFROSTS}`
        }
      ]
    },
    {
      header: "Документы",
      links: createDocumentLinks(props.medicalFile.id)
    }
  ];

  const tools = (
    <ToolsContainer>
      <PrintButtonDropDown menuItems={menuItems} withHeader />
    </ToolsContainer>
  );

  return (
    <EmbryosPage
      header="Криохранилище"
      tools={tools}
      onGetEmbryos={onGetEmbryos}
      {...props}
    />
  );
};
