//@flow
import React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import { PSmall } from "../styleguide/typography";

const HeadCell = styled.th`
  padding: 17px 0 13px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid ${colors.magenta};
  background-color: ${colors.white};
`;

const HeadCellFixed = styled(HeadCell)`
  position: sticky;
  left: 0;
`;

const Text = styled(PSmall)`
  font-weight: 500;
`;

export type HistoryColumnType = Array<{ name: string, fixed?: boolean }>;

export const HistoryTableHead = (props: {| columns: HistoryColumnType |}) => {
  return (
    <thead>
      <tr>
        {props.columns.map((column, index) => (
          <HeadCell key={index} as={column.fixed && HeadCellFixed}>
            <Text modifiers="1">{column.name}</Text>
          </HeadCell>
        ))}
      </tr>
    </thead>
  );
};
