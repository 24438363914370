import DynamicTableStore from "../dynamicTableStore";
import apiv2 from "../../apiv2";

class ReportTransferStore extends DynamicTableStore {
  constructor() {
    super(apiv2.reports.getReportTransfer.bind(apiv2.reports));
    this.enableSavingPresets(false);
  }

  setOthersTableData(response) {
    this.othersTableData = response.statistics;
  }
}

export default new ReportTransferStore();
