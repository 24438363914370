import React from 'react';
import styled from 'styled-components';

import loaderIcon from '../../schedule-loader-modal/icons/loader.svg';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background: #f9eeed;
    justify-content: center;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Loader = styled.img`
  margin-bottom: 16px;
  animation: rotate 2s linear infinite;
`;

export const CallInitializationScreen = () => {
  return (
    <Wrapper>
      <ContentContainer>
        <Loader src={loaderIcon} alt="Loading..."/>
        Настраиваем звонки, пожалуйста подождите...
      </ContentContainer>
    </Wrapper>
  );
};