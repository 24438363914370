// @flow
import * as React from "react";
import type { Drug, TDrugId } from "../../domain/entities/Drug";
import { useDrugSelect } from "../drug-select/useDrugSelect";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import { DrugSelectLayout } from "../drug-select/DrugSelectLayout";
import { ModalPortal } from "../modal/ModalPortal";
import { Input } from "../drug-select/Input";
import { useAutoFocusRef } from "../../utils/useAutoFocusRef";
import { useCancelableTimeout } from "../../utils/useCancelableTimeout";
import { AddButton } from "../select/AddButton";
import { Option } from "../select/Option";
import apiv2 from '../../apiv2';

export const DrugSelect = (props: {
  ignore?: Array<TDrugId>,
  onSelect: (drug: Drug) => mixed,
  onBlur?: () => mixed
}) => {
  const { value, setValue, items } = useDrugSelect(props.ignore);
  const token = useStore(tokenStore);
  const inputRef = useAutoFocusRef();

  const [onBlur, cancelBlur] = useCancelableTimeout(() => {
    props.onBlur && props.onBlur();
  }, 200);

  const onClickAddButton = async () => {
    const newDrug = await apiv2.drugs.postDrugs(value);
    props.onSelect(newDrug);
  };

  return (
    <div onClick={cancelBlur}>
      <DrugSelectLayout
        Portal={ModalPortal}
        addButton={<AddButton text={value} onClick={onClickAddButton} />}
        input={<Input value={value} onChange={setValue} inputRef={inputRef} onBlur={onBlur} />}>
        {items.map(drug => (
          <Option key={drug.id} text={drug.name} onClick={() => props.onSelect(drug)} />
        ))}
      </DrugSelectLayout>
    </div>
  );
};
