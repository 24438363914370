// @flow
import React from "react";
import styled from "styled-components";

import { CorporateCounter } from "../common/CorporateCounter";

import HeartIcon from "../../../assets/icons/corporate-portal/heart.inline.svg";
import HeartOutlineIcon from "../../../assets/icons/corporate-portal/heart-outline.inline.svg";

const LikeCounterContainer = styled.div`
  .counter {
    &__value {
      color: #262626;
    }
  }
`;

export const CorporateLikeCounter = (props: {|
  value: number | string,
  active: boolean,
  onClick: () => void
|}) => {
  const { value, active, onClick } = props;

  return (
    <LikeCounterContainer>
      <CorporateCounter
        icon={active ? <HeartIcon /> : <HeartOutlineIcon />}
        value={value}
        onClick={onClick}
      />
    </LikeCounterContainer>
  );
};
