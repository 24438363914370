// @flow
import { useState, useRef } from "react";

type TUseConfirmResult = {
  requestConfirm?: () => Promise<boolean>,
  sendConfirm?: boolean => void,
  isNeedConfirm: boolean
};

export const useConfirm = (): TUseConfirmResult => {
  const [isNeedConfirm, setNeedConfirm] = useState(false);

  const sendConfirmRef = useRef();
  const requestConfirmRef = useRef(() => {
    setNeedConfirm(true);

    return new Promise(resolve => {
      sendConfirmRef.current = value => {
        resolve(value);
        setNeedConfirm(false);
      };
    });
  });

  return {
    isNeedConfirm,
    requestConfirm: requestConfirmRef.current,
    sendConfirm: sendConfirmRef.current
  };
};
