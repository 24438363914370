// @flow
import React from "react";

export const SubpointsProvider = (props: {
  children: ({ isOpen: boolean, toggleOpen: () => mixed }) => React$Node
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleOpen = () => setIsOpen(value => !value);

  return props.children({
    isOpen,
    toggleOpen
  });
};
