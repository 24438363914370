import ApiService from "../../../apiv2/apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class CorporateDocuments {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  getDocumentsById(documentId, search) {
    return this.apiService.get(`corporate-portal/file-manager/directories/${documentId}`, {search: search});
  }

  getDetailsInfoFile(fileId) {
    return this.apiService.get(`corporate-portal/file-manager/files/${fileId}`);
  }
}

export default new CorporateDocuments();
