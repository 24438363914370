import { ChartComponent } from "../common/charts/ChartComponent";
import { Box, Paper, Stack } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import _ from "lodash";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import organizationsStore from "../../stores/organizationsStore";
import { renderToolbarAlertBannerContent } from "../common/MaterialTable";
import dashboardStore, { transformDataDiagram, transformTableData } from "../../stores/dashboardStore";
import * as yup from "yup";
import { filterValidation } from "../common/widgets-components-common/constants";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = 'widgetConciergeAnalyticsMetric';
export const FILTERS_FIELDS_WIDGET_CONCIERGE_ANALYTICS_METRIC = () => [
  {
    type: 'select',
    name: 'type',
    label: 'Вид графика',
    xs: 12,
    options: [
      {value: 'line', label: 'Линейный'},
      {value: 'bar', label: 'Столбцами'},
      {value: 'table', label: 'Таблицей'},
    ]
  },
  {
    type: 'select',
    name: 'chat_status',
    label: 'Статус',
    multiple: true,
    xs: 12,
    options: dashboardStore.chatStatusOptions
  },
  {
    type: 'checkbox',
    name: 'isCollapse',
    label: 'Объединить выбранные организации (только для графиков)',
    xs: 12,
  },
  {
    type: 'text',
    name: 'label',
    label: 'Название графика',
    xs: 12,
  },
  {
    type: 'select',
    name: 'organization_ids',
    label: 'Организации',
    needSelectAll: true,
    multiple: true,
    valueName: 'id',
    labelName: 'name',
    xs: 12,
    options: [...organizationsStore.organizations, {id: 0, name: 'Без карты'}],
  },
];

export const WidgetConciergeAnalyticsMetric = observer((props: {
  filtersData: () => {},
  rewriteFilters: () => {},
  keyWidget: String
}) => {

  const styledPaper = {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [filterFields, setFilterFields] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableAllCount, setTableAllCount] = useState(0);
  const [label, setLabel] = useState('График по консьержу');
  const [chartsData, setChartsData] = useState();
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const validateSchema = yup.object().shape({
    organization_ids: yup.array().required("организации").min(1, "организации")
  });

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
      const filterDiagramLocaleStorage = getLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''));
      if (filterDiagramLocaleStorage && moment(filterDiagramLocaleStorage.date_end).isBefore(moment())) {
        filterDiagramLocaleStorage.date_end = moment();
      }
      const filters = props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
      if (filters.label) {
        setLabel(filters.label);
      }
      if (moment(filters.date_end).isBefore(moment().set({ hour: 23, minute: 59, second: 59 }))) {
        filters.date_end = moment().set({ hour: 23, minute: 59, second: 59 });
      }
      setFiltersDataDiagram(filters)
      setFilterFields([...FILTERS_FIELDS_WIDGET_CONCIERGE_ANALYTICS_METRIC()]);
      setIsInitialized(true);
    })()
  }, []);

  useEffect(() => {
    if (props.rewriteFilters) {
      setFiltersDataDiagram({...filtersDataDiagram, ...props.rewriteFilters});
    }
  }, [props.rewriteFilters]);

  useEffect(() => {
    (async () => {
      if (_.size(filtersDataDiagram)) {
        setLoading(true);
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), filtersDataDiagram);
        if (filtersDataDiagram.label) {
          setLabel(filtersDataDiagram.label);
        }
        const response = await dashboardStore.getAnalyticsMetricData(filtersDataDiagram);
        if (response.error) {
          filterValidation({ validateSchema, filtersDataDiagram, setErrors });
        }
        setChartsData(transformDataDiagram({data: response, interval: filtersDataDiagram.interval || 'day', isCollapseData: filtersDataDiagram.isCollapse || filtersDataDiagram.organization_ids?.length === 1}));
        setTableData(transformTableData(response, filtersDataDiagram.organization_ids, 'count',  organizationsStore.organizations, dashboardStore));
        setLoading(false);
      }
    })()
  }, [filtersDataDiagram]);

  useEffect(() => {
    setTableAllCount(tableData.reduce((acc, item) => {
      acc[item.chat_status_key] += item.count;
      return acc;
    }, {patient_accepted: 0, patient_text: 0, patient_invited: 0, chat_created: 0}));
  }, [tableData, filtersDataDiagram]);

  const submitHandler = (filters, callback) => {
    setErrors([]);
    setFiltersDataDiagram(filters);
    callback();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'organization', //access nested data with dot notation
        header: 'Название организации',
      },
      {
        accessorKey: 'chat_status',
        header: 'Статус',
        Cell: ({ row }) => dashboardStore.chatStatus[row.original.chat_status_key],
      },
      {
        accessorKey: 'count',
        size: 220,
        header: 'Количество',
        Footer: () => (
          <Stack>
            {(!filtersDataDiagram.chat_status?.length || filtersDataDiagram.chat_status.includes('patient_invited')) && (
              <Box color="primary.main">Всего заполнили форму на сайте: {tableAllCount.patient_invited.toLocaleString()}</Box>
            )}
            {(!filtersDataDiagram.chat_status?.length || filtersDataDiagram.chat_status.includes('chat_created')) && (
              <Box color="primary.main">Всего создано чатов: {tableAllCount.chat_created.toLocaleString()}</Box>
            )}
            {(!filtersDataDiagram.chat_status?.length || filtersDataDiagram.chat_status.includes('patient_accepted')) && (
              <Box color="primary.main">Всего присоединилось: {tableAllCount.patient_accepted.toLocaleString()}</Box>
            )}
            {(!filtersDataDiagram.chat_status?.length || filtersDataDiagram.chat_status.includes('patient_text')) && (
              <Box color="primary.main">Всего обратившихся впервые: {tableAllCount.patient_text.toLocaleString()}</Box>
            )}
          </Stack>
        ),
      },
    ],
    [tableAllCount, tableData, filtersDataDiagram],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    enableFullScreenToggle: false,
    groupedColumnMode: 'remove',
    layoutMode: 'grid',
    positionToolbarAlertBanner: 'bottom',
    renderToolbarAlertBannerContent: renderToolbarAlertBannerContent,
    enableGrouping: true,
    initialState: {
      density: 'compact',
      grouping: ['organization'],
      pagination: {pageIndex: 0, pageSize: 300}
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  return (
    <Paper style={styledPaper}>
      {filterFields && (
        <LabelWithFiltersModal
          fullWidth={true}
          type={"filter"}
          filtersData={filtersDataDiagram}
          filtersFields={filterFields}
          label={label}
          textButtonFilter={"Применить"}
          submitHandler={submitHandler}
        />
      )}
      {(loading || !isInitialized) && <AbsoluteLoader />}
      {isInitialized && (
        <>
          {filtersDataDiagram.type !== 'table' && chartsData && (
            <ChartComponent
              textButtonFilter="Применить"
              filtersDataDiagram={filtersDataDiagram}
              type={filtersDataDiagram.type || 'bar'}
              nameDiagram={"WidgetConciergeAnalyticsMetric"}
              data={chartsData}
              margin={{
                top: 10,
              }}
              interval={filtersDataDiagram?.interval || 'day'}
              errors={errors}
            />
          )}
          {filtersDataDiagram.type === 'table' && tableData && (
            <div style={{
              maxHeight: '100%',
              overflow: "auto"
            }} className={'material-table'}>
              <MaterialReactTable table={table} />
            </div>
          )}
        </>
      )}
    </Paper>
  )
});
