// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { rgba } from "polished";
import {
  favouritesKey,
  activeSpecialityStore,
  setActiveSpeciality,
  selectedDoctorStore,
  setSelectedDoctor
} from "./fullScheduleStore";

import favouriteIcon from "./icons/favorite-active.svg";
import { useStore } from "effector-react";
import { BlockLoader } from "../loader/Loader";

type SpecialtyItemProps = {
  id: number,
  name: string
};

const SpecialtyItem = styled.li`
  position: relative;
  padding: 10px 22px 10px 28px;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  transition: all 0.15s ease-in-out;

  &:not(:first-child) {
    border-top: 1px solid ${rgba(colors.gray400, 0.3)};
  }

  &.active {
    border-top-color: transparent;
    background-color: ${colors.palePink};
    color: ${colors.magenta};

    & + li {
      border-top-color: transparent;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 3px;
      height: 100%;
      background-color: ${colors.magenta};
    }
  }

  &:hover {
    color: ${colors.magenta};

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 3px;
      height: 100%;
      background-color: ${colors.magenta};
    }
  }
`;

const SpecialtyListComponent = styled.ul`
  list-style-type: none;
  padding: 14px 0 0;
  border-bottom: 1px solid ${rgba(colors.gray400, 0.3)};
`;

const FavouriteIcon = styled.img`
  position: absolute;
  right: 12px;
  top: 8px;
`;

export const SpecialtyList = (props: {| items: Array<SpecialtyItemProps>, isLoading?: boolean |}) => {
  const activeClassName = "active";
  const selectedDoctor = useStore(selectedDoctorStore);
  const activeItem = useStore(activeSpecialityStore);

  const toggleItem = id => {
    id === activeItem ? void 0 : setActiveSpeciality(id);
    if (selectedDoctor) {
      setSelectedDoctor(null);
    }
  };

  const items = props.items
    .sort((a, b) => {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    })
    .map(item => (
      <SpecialtyItem
        key={item.id}
        onClick={() => {
          toggleItem(item.id);
        }}
        className={item.id === activeItem ? activeClassName : ""}>
        {item.name}
      </SpecialtyItem>
    ));
  return (
    <SpecialtyListComponent>
      <SpecialtyItem
        key={favouritesKey}
        onClick={() => {
          toggleItem(favouritesKey);
        }}
        className={favouritesKey === activeItem ? activeClassName : ""}>
        Избранное
        <FavouriteIcon src={favouriteIcon} alt="Favourite icon" />
      </SpecialtyItem>
      {
        props.isLoading ? (
          <BlockLoader transparent />
        ) : (
          items
        )
      }
    </SpecialtyListComponent>
  );
};
