// @flow

export type AIStatus = "not_found" | "poor_quality" | "sufficient_quality";

export const AI_STATUS_NOT_FOUND: AIStatus = "not_found";
export const AI_STATUS_POOR_QUALITY: AIStatus = "poor_quality";
export const AI_STATUS_SUFFICIENT_QUALITY: AIStatus = "sufficient_quality";

export type AISlice = {
  id: number,
  name: string,
  status: AIStatus
};
