import _ from "lodash";

export const generatedDocumentsData = {
  1: [
    {
      id: _.uniqueId(),
      type: "folder",
      folder_id: 2,
      name: "Галерея"
    },
    {
      id: _.uniqueId(),
      type: "folder",
      folder_id: 5,
      name: "Документы"
    },
    {
      id: _.uniqueId(),
      type: "unknown_format",
      name: "Файл с неизвестным форматом",
      file_url: ""
    },
    {
      id: _.uniqueId(),
      type: "video",
      name: "Файл с video-форматом",
      file_url: ""
    }
  ],
  2: [
    {
      id: _.uniqueId(),
      type: "folder",
      folder_id: 3,
      name: "Изображения"
    },
    {
      id: _.uniqueId(),
      type: "folder",
      folder_id: 4,
      name: "Видео"
    }
  ],
  3: [
    {
      id: _.uniqueId(),
      type: "folder",
      folder_id: 6,
      name: "Врачи"
    },
    {
      id: _.uniqueId(),
      type: "image",
      name: "Логотип",
      file_url: ""
    },
    {
      id: _.uniqueId(),
      type: "image",
      name: "Ценности",
      file_url: ""
    },
    {
      id: _.uniqueId(),
      type: "image",
      name: "Аватар врача",
      file_url: ""
    }
  ],
  4: [
    {
      id: _.uniqueId(),
      type: "video",
      name: "Приветствие",
      file_url: ""
    },
    {
      id: _.uniqueId(),
      type: "video",
      name: "Первый день в компании",
      file_url: ""
    }
  ],
  6: [
    {
      id: _.uniqueId(),
      type: "image",
      name: "Врач 1",
      file_url: ""
    },
    {
      id: _.uniqueId(),
      type: "image",
      name: "Врач 2",
      file_url: ""
    }
  ]
};
