// @flow
import React, { useEffect, useState, useRef } from "react";
import { Button as BaseButtonWithTooltip, ButtonWithTooltip } from "../buttons/ButtonWithTooltip";

import type { AppointmentWithDetails } from "../../domain/entities/Appointment";

import { getAppointment_id } from "../../domain/entities/Appointment";
import { useStore } from "effector-react";
import { InfoButton } from "../decision-support/InfoButton";
import CreateIcon from "./icons/createIcon.svg";
import CreateIconHover from "./icons/createIconHover.svg";
import InsertIcon from "./icons/insert.svg";
import InsertIconColor from "./icons/insert-color.svg";
import styled, { css } from "styled-components";
import {
  createShortcuts,
  openShortcuts,
  currentFieldStore,
  isNotEmptyShortcut,
  checklistShortcut
} from "../../stores/shortcuts/checklistShortcuts";
import type { TChecklistDestination } from "../../domain/value-objects/TShortcuts";
import { isNotEmptyCheckedCurrentChecklist } from "../../stores/decisionSupportStores";

const StyledButtonWithTooltip = styled(BaseButtonWithTooltip)`
  opacity: ${({ disabled, isHighlighted }) => (isHighlighted ? 1 : disabled ? 0.15 : 1)};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.15;
      pointer-events: none;
    `}
`;

const TooltipContainer = styled.div`
  display: flex;
  column-gap: 18px;
`;

type Props = {
  isNotEmpty: boolean,
  fieldName: TChecklistDestination,
  appointment: AppointmentWithDetails,
  openInfoEvent: () => mixed
};

const ConclusionTooltip = ({ isNotEmpty, openInfoEvent, fieldName, appointment }: Props) => {
  const blockRef = useRef(null);
  const isNotEmptyChecklist = isNotEmptyCheckedCurrentChecklist(
    getAppointment_id(appointment),
    fieldName
  );

  const checklists = useStore(checklistShortcut);

  const [isNotEmptyShrtc, setIsNotEmptyShrtc] = useState(false);

  const isCurrent = useStore(currentFieldStore.map(store => store && store === fieldName));

  const appointment_id = getAppointment_id(appointment);

  useEffect(() => {
    isCurrent &&
      blockRef.current &&
      blockRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [isCurrent]);

  useEffect(() => {
    const isNotEmpty = isNotEmptyShortcut(fieldName);
    setIsNotEmptyShrtc(isNotEmpty);
  }, [checklists, fieldName]);

  return (
    <TooltipContainer ref={blockRef}>
      <ButtonWithTooltip
        overrides={{ Button: { component: StyledButtonWithTooltip } }}
        icon={CreateIcon}
        iconHover={CreateIconHover}
        tooltip="Создать заготовку"
        onClick={() => createShortcuts({ fieldName, appointment_id })}
        disabled={!isNotEmptyChecklist}
      />
      <ButtonWithTooltip
        overrides={{ Button: { component: StyledButtonWithTooltip } }}
        icon={InsertIcon}
        iconHover={InsertIconColor}
        tooltip="Вставить заготовку"
        onClick={() => openShortcuts({ fieldName, appointment_id })}
        disabled={!isNotEmptyShrtc}
        isHighlighted={isNotEmptyShrtc}
      />
      {isNotEmpty && <InfoButton onClick={openInfoEvent} />}
    </TooltipContainer>
  );
};

export default ConclusionTooltip;
