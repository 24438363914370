import * as React from "react";
import { EmbryosCalendarInput } from "./EmbryosCalendarInput";
import { WideTextEditContainer } from "./EmbryosTableTextEdit";
import { EmbryosTableContext } from "./EmbryosTableContext";

export const CalendarTextEdit = ({ onChange, cell, row, exitEditMode, inputDisabled = false }) => {
  const { onCellUpdate } = React.useContext(EmbryosTableContext);

  const handleChange = React.useCallback(
    value => {
      onChange({ ...cell, value });
      onCellUpdate(row, { field: cell.field, value });
      exitEditMode();
    },
    [onChange, cell, row, onCellUpdate, exitEditMode]
  );

  return (
    <WideTextEditContainer style={{ height: "100%" }}>
      <EmbryosCalendarInput
        value={cell.value}
        handleChange={handleChange}
        inputDisabled={inputDisabled}
        placeholder="ДД.ММ.ГГГГ"
      />
    </WideTextEditContainer>
  );
};
