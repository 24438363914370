import React, { useCallback, useEffect, useState, useRef } from "react";
import { useAsyncFn } from "react-use";
import Spreadsheet from "@blessmesanta/react-spreadsheet/dist/index";

import { ModalFade } from "../../modal/ModalFade";
import { ModalBackground } from "../../modal/ModalBackground";
import { ModalPortal } from "../../modal/ModalPortal";
import { ModalWithCloseButtonLayout } from "../../modal/ModalWithCloseButtonLayout";
import { EmbryosManipulationsTimeInput } from "./EmbryosManipulationsTimeInput";
import {
  EmbryosManipulationsCalendarInput,
  EmbryosManipulationsDateViewer
} from "./EmbryosManipulationsCalendar";
import {
  EmbryosManipulationsDoctorSelect,
  EmbryosManipulationsDoctorSelectView
} from "./EmbryosManipulationsDoctorSelect";
import { EmbryosManipulationsContext } from "./EmbryosManipulationsContext";
import { EmbryosManipulationsTablePlaceholder } from "./EmbryosManipulationsTablePlaceholder";

import { PrimaryButton } from "../../buttons/PrimaryButton";

import { formatDateToReadableDateFormat } from "../../../utils/dates";

import * as S from "./EmbryosManipulations.styled";
import apiv2 from '../../../apiv2';

const getDoctorName = ({ first_name, middle_name, last_name }) =>
  `${last_name ? `${last_name} ` : ""}${first_name ? `${first_name[0]}.` : ""}${
    middle_name ? `${middle_name[0]}.` : ""
  }`;

export const phases = {
  puncture: "puncture",
  eco: "eco",
  ixi_pixi: "ixi_pixi",
  evaluation_of_zygotes: "evaluation_of_zygotes",
  crushing_score: "crushing_score",
  estimated_5: "estimated_5",
  estimated_6: "estimated_6",
  estimated_7: "estimated_7",
  cryopreservation_1: "cryopreservation_1",
  biopsy_1: "biopsy_1",
  defrost: "defrost",
  moved: "moved",
  cryopreservation_2: "cryopreservation_2",
  biopsy_2: "biopsy_2",
  cryopreservation_3: "cryopreservation_3",
  biopsy_3: "biopsy_3"
};

const phaseTitle = {
  [phases.puncture]: "Пункция",
  [phases.eco]: "ЭКО",
  [phases.ixi_pixi]: "ИКСИ/ПИКСИ",
  [phases.evaluation_of_zygotes]: "Оценка зигот",
  [phases.crushing_score]: "Оценка дробления",
  [phases.estimated_5]: "Оценка на 5 сутки",
  [phases.estimated_6]: "Оценка на 6 сутки",
  [phases.estimated_7]: "Оценка на 7 сутки",
  [phases.cryopreservation_1]: "Криоконсервация",
  [phases.biopsy_1]: "Биопсия",
  [phases.defrost]: "Размораживание",
  [phases.moved]: "Перенесен",
  [phases.cryopreservation_2]: "Криоконсервация №2",
  [phases.biopsy_2]: "Биопсия №2",
  [phases.cryopreservation_3]: "Криоконсеварция №3",
  [phases.biopsy_3]: "Биопсия №3"
};

const field = {
  phase: "phase",
  time: "time",
  doctor: "doctor",
  date: "date"
};

const fieldIncomeHandlers = {
  [field.date]: value => (value ? new Date(value) : null),
  [field.doctor]: value => (value ? { value: value.id, label: getDoctorName(value) } : null),
  [field.time]: value => (value ? value.slice(0, -3) : null)
};

const fieldOutcomeHandlers = {
  [field.doctor]: value => ({ doctorId: value.value }),
  [field.time]: value => ({ time: value }),
  [field.date]: value => ({ date: formatDateToReadableDateFormat(value) })
};

const fieldTitle = {
  [field.phase]: "Этап",
  [field.doctor]: "Эмбриолог",
  [field.date]: "Дата",
  [field.time]: "Время"
};

const windowVerticalPadding = 114;

export const EmbryosManipulations = ({ token, folderId, open, onClose }) => {
  const [tableData, setTableData] = useState([]);
  const [inEditMode, setInEditMode] = useState(false);

  const selectedCellsRef = useRef(null);
  const $containerRef = useRef(null);

  const [, updateEmbryosManipulations] = useAsyncFn(async ({ id, doctorId, time, date, clear }) => {
    await apiv2.embryos.patchEmbryosManipulations({ id, folderId, doctorId, time, date, clear });
  }, []);

  const [, fetchTableData] = useAsyncFn(async () => {
    const manipulations = await apiv2.embryos.getEmbryosManipulations(folderId);
    const embryologists = await apiv2.doctors.getEmbryologists();

    setTableData(
      manipulations.map((item, i) => [
        {
          value: phaseTitle[item[field.phase]],
          readOnly: true,
          index: [i, 0],
          manipulationId: item.id,
          removable: false,
          fieldType: field.phase
        },
        {
          index: [i, 1],
          value: fieldIncomeHandlers.doctor(item[field.doctor]),
          manipulationId: item.id,
          DataEditor: EmbryosManipulationsDoctorSelect,
          DataViewer: EmbryosManipulationsDoctorSelectView,
          removable: true,
          fieldType: field.doctor,
          options: embryologists.map(doctor => ({
            value: doctor.id,
            label: getDoctorName(doctor)
          }))
        },
        {
          value: fieldIncomeHandlers.date(item[field.date]),
          DataEditor: EmbryosManipulationsCalendarInput,
          DataViewer: EmbryosManipulationsDateViewer,
          index: [i, 2],
          manipulationId: item.id,
          removable: true,
          fieldType: field.date
        },
        {
          value: fieldIncomeHandlers.time(item[field.time]),
          DataEditor: EmbryosManipulationsTimeInput,
          index: [i, 3],
          manipulationId: item.id,
          removable: true,
          fieldType: field.time
        }
      ])
    );
  });

  const handleTableBlur = useCallback(() => {
    setInEditMode(false);
  }, []);

  const handleTableModeChange = useCallback(mode => {
    setInEditMode(mode === "edit");
  }, []);

  const handleSelect = useCallback(cells => {
    selectedCellsRef.current = cells.map(({ row, column }) => [row, column]);
  }, []);

  const handleTableUpdate = useCallback(({ value, index }) => {
    setTableData(tableData =>
      tableData.map(row =>
        row.map(cell =>
          cell.index[0] === index[0] && cell.index[1] === index[1] ? { ...cell, value } : cell
        )
      )
    );
  }, []);

  const handleMultipleUpdate = useCallback(
    ({ manipulationId, items }) => {
      updateEmbryosManipulations({
        id: manipulationId,
        ...items.reduce(
          (acc, { fieldType, value }) => ({
            ...acc,
            ...fieldOutcomeHandlers[fieldType](value)
          }),
          {}
        )
      });

      for (const item of items) {
        handleTableUpdate(item);
      }
    },
    [handleTableUpdate, updateEmbryosManipulations]
  );

  const handleUpdate = useCallback(
    ({ index, manipulationId, value, fieldType }) => {
      if (value) {
        updateEmbryosManipulations({
          id: manipulationId,
          ...fieldOutcomeHandlers[fieldType](value)
        });
      } else {
        updateEmbryosManipulations({ id: manipulationId, clear: [fieldType] });
      }

      handleTableUpdate({ index, value });
    },
    [handleTableUpdate, updateEmbryosManipulations]
  );

  const handleDoctorUpdate = useCallback(
    ({ index, manipulationId, ...rest }) => {
      const dateCell = tableData[index[0]].find(({ fieldType }) => fieldType === field.date);

      if (!dateCell.value) {
        handleMultipleUpdate({
          manipulationId,
          items: [
            { ...rest, index },
            { index: [index[0], index[1] + 1], value: new Date(), fieldType: field.date }
          ]
        });
      } else {
        handleUpdate({ ...rest, manipulationId, index });
      }
    },
    [tableData, handleUpdate, handleMultipleUpdate]
  );

  const handleKeyDown = useCallback(
    e => {
      if (
        e.code === "Backspace" &&
        tableData.length > 0 &&
        selectedCellsRef.current &&
        selectedCellsRef.current.length === 1 &&
        !inEditMode
      ) {
        e.stopPropagation();

        const index = selectedCellsRef.current[0];
        const item = tableData[index[0]][index[1]];

        if (item.removable) {
          handleUpdate({ ...item, value: null });
        }
      }
    },
    [inEditMode, tableData, handleUpdate]
  );

  useEffect(() => {
    fetchTableData();
  }, [fetchTableData]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, { capture: true });

    return () => {
      document.removeEventListener("keydown", handleKeyDown, { capture: true });
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (open && $containerRef.current) {
      $containerRef.current.style.height = `${window.innerHeight - 2 * windowVerticalPadding}px`;
    }
  }, [open]);

  return (
    <ModalPortal>
      <ModalFade>
        {open && (
          <ModalBackground>
            <ModalWithCloseButtonLayout handleClose={onClose}>
              <EmbryosManipulationsContext.Provider
                value={{
                  onUpdate: handleUpdate,
                  onDoctorUpdate: handleDoctorUpdate
                }}>
                <S.Container ref={$containerRef}>
                  <S.Title>Манипуляции</S.Title>
                  {tableData.length > 0 ? (
                    <S.TableContainer>
                      <Spreadsheet
                        data={tableData}
                        hideRowIndicators
                        columnLabels={Object.values(fieldTitle)}
                        ColumnIndicator={({ label }) => (
                          <S.ColumnIndicator>{label}</S.ColumnIndicator>
                        )}
                        onSelect={handleSelect}
                        onModeChange={handleTableModeChange}
                        onBlur={handleTableBlur}
                        className="Spreadsheet-manipulations"
                      />
                    </S.TableContainer>
                  ) : (
                    <EmbryosManipulationsTablePlaceholder rows={Object.keys(phaseTitle).length} />
                  )}
                  <S.SaveButtonContainer>
                    <PrimaryButton onClick={onClose}>Сохранить</PrimaryButton>
                  </S.SaveButtonContainer>
                </S.Container>
              </EmbryosManipulationsContext.Provider>
            </ModalWithCloseButtonLayout>
          </ModalBackground>
        )}
      </ModalFade>
    </ModalPortal>
  );
};
