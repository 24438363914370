//@flow
import * as React from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { colors } from "../styleguide/colors";
import { ButtonCaps } from "../styleguide/typography";
import DownloadImg from "./icons/download.svg";

const Label = styled.label`
  padding: 8px 15px;
  border: 1px solid ${rgba(colors.black, 0.1)};
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  transition: border-color 0.15s ease-in-out;

  span {
    transition: color 0.15s ease-in-out;
    text-align: center;
  }

  &:hover {
    border-color: ${rgba(colors.magenta, 0.3)};

    span {
      color: ${colors.magenta};
    }
  }
`;

const Icon = styled.div`
  min-width: 24px;
  height: 24px;
  transition: all 0.15s ease-in-out;

  mask: url(${DownloadImg}) no-repeat center / contain;
  background: ${colors.black};

  ${Label}:hover & {
    background: ${colors.magenta};
  }
  margin-right: 6px;
`;

const FileInput = styled.input.attrs(() => ({
  type: "file"
}))`
  display: none;
`;

export const FileUploadButton = (props: {| onAddFile: FileList => mixed, text?: string |}) => (
  <Label>
    <Icon />
    <ButtonCaps modifiers={["left", "uppercase"]}>
      {props.text ? props.text : "Загрузить файлы"}
    </ButtonCaps>
    <FileInput multiple onChange={event => props.onAddFile(event.target.files)} />
  </Label>
);
