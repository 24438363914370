import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';

import {SelectContainer} from '../decision-support/SelectContainer';
import {ListContainer} from '../select/ListContainer';
import {ListPointsContainer} from '../select/ListPointsContainer';
import {OptionsSeparator} from '../select/OptionsSeparator';
import {AddButton} from '../select/AddButton';
import {MedicinesSuggestProvider} from '../decision-support/MedicinesSuggestProvider';
import {Option} from '../select/Option';
import {AutosuggestAbsoluteContainer} from '../decision-support/AutosuggestAbsoluteContainer';
import {BaseInput, Container} from '../decision-support/BaseInput';
import {PNormal} from '../styleguide/typography';
import {useClickOutside} from '../../utils/useClickOutside';
import apiv2 from '../../apiv2';

const MedicationInputContainer = styled.div`
  position: relative;
`;

export const PointAutosuggestFixedContainer = styled(AutosuggestAbsoluteContainer)`
  position: fixed;
  top: calc(100% - 4px);
  z-index: 9;
  max-width: 320px;
`;

const ExtendedContainer = styled(Container)`
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  
  input {
    font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    line-height: 16px;
    padding: 0;
  }
`;

const Input = styled(PNormal)`
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
`;

const CustomSelectContainer = styled(SelectContainer)`
  width: 100%;
`;

const OptionsList = (props) => {
  const {items, onClick} = props;
  return (
    <>
      {items.map(item => {
        const key = item.syntheticId || item.id;
        return <Option onClick={(e) => {
          e.stopPropagation();
          onClick(item)
        }} key={key} text={item.name}/>;
      })}
    </>
  );
};

export const MedicationInput = props => {
  const { initialValue, onChange, onKeyDown, cellPosition } = props;
  const [suggestPosition, setSuggestPosition] = useState({});
  const [value, onValueChange] = useState(initialValue?.name || "");
  const $menuRef = useRef(null);

  useClickOutside($menuRef, () => {
    onChange?.(initialValue);
  });

  useEffect(() => {

    const suggestContainer = $menuRef.current;
    if (suggestContainer) {
      const observer = new MutationObserver(mutationRecords => {
        if (mutationRecords.length) {
          const newSuggestPosition = {};
          if (cellPosition.x + suggestContainer.clientWidth >= window.innerWidth) {
            newSuggestPosition.left = cellPosition.x + cellPosition.width - suggestContainer.clientWidth + "px";
          } else {
            newSuggestPosition.left = cellPosition.x + "px"
          }
          if (cellPosition.y + cellPosition.height + suggestContainer.clientHeight > window.innerHeight) {
            newSuggestPosition.top = cellPosition.y - suggestContainer.clientHeight + "px";
          } else {
            newSuggestPosition.top = cellPosition.y + cellPosition.height + "px";
          }
          setSuggestPosition(newSuggestPosition);
        }
      });
      observer.observe(suggestContainer, {
        childList: true,
        subtree: true
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [$menuRef.current]);

  return (
    <MedicationInputContainer>
      <MedicinesSuggestProvider value={value} withoutVariant={true}>
        {items => (
          <>
            <BaseInput
              value={value}
              onChange={onValueChange}
              onKeyDown={(e) => {
                if (e.key === 'Tab' || e.key === 'Enter') {
                  if (items.length > 0) {
                    onChange?.(items[0]);
                  } else {
                    onChange?.(null);
                  }
                }
                onKeyDown?.(e);
              }}
              onBlur={() => {
                if (!value) {
                  onChange?.(null);
                }
              }}
              overrides={{
                Container: {
                  component: ExtendedContainer
                },
                Input: {
                  component: Input,
                  props: {
                    modifiers: "left"
                  }
                }
              }}
            />
            {(items.length > 0 || value.length > 0) && (
              <PointAutosuggestFixedContainer
                style={suggestPosition}>
                <CustomSelectContainer ref={$menuRef}>
                  <ListContainer role="menu">
                    <ListPointsContainer>
                      <OptionsList
                        items={items}
                        onClick={item => {
                          onChange?.(item);
                        }}
                      />
                    </ListPointsContainer>
                    <OptionsSeparator/>
                    <AddButton
                      text={value}
                      onClick={async (e) => {
                        e.stopPropagation();
                        const trimmedValue = value.trim();
                        if (trimmedValue && trimmedValue.length) {
                          await apiv2.medicines.propose(trimmedValue);
                          onChange?.({name: trimmedValue});
                        }
                      }}
                    />
                  </ListContainer>
                </CustomSelectContainer>
              </PointAutosuggestFixedContainer>
            )}
          </>
        )}
      </MedicinesSuggestProvider>
    </MedicationInputContainer>
  );
};
