import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FormLabel, FormControl } from "@mui/material";
import { Asterisk } from "../styled/Asterisk.styled";

export const FormInputTextarea = (props) => {
  const newProps = {...props};
  delete newProps.subInfo;
  delete newProps.description;
  delete newProps.transformValue;

  return (
    <>
      <FormControl fullWidth className={props.withLabel ? 'dynamic-form-input-control' : 'dynamic-form-input-control-max'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
        {(props.label && props.withLabel)  && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
        <Controller
          name={props.name}
          control={props.control}
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => {
            if (value === null) {
              onChange({target: {value: ''}})
            }
            return (
              <TextField
                className={'form-input-textarea'}
                helperText={error ? error.message : props.subInfo ? props.subInfo : null}
                error={!!error}
                onChange={onChange}
                value={props.transformValue ? props.transformValue(value || '') : value || ''}
                fullWidth
                variant="outlined"
                multiline
                maxRows={5}
                inputProps={
                  { readOnly: props.readOnly, }
                }
                {...newProps}
                label={props.withLabel ? '' : props.label}

              />
            )
          }}
        />
      </FormControl>
        {/*{!!props.subInfo && <Typography variant="caption">{props.subInfo}</Typography>}*/}
    </>
  );
};
