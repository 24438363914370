//@flow
import React, { useMemo, useState } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import moment from "moment";
import { styled } from "@mui/material/styles";
import scheduleInsertsStore, {
  INSERTS_CONFIG,
  INSERTS_TYPE,
  INSERTS_TYPE_RU,
  TYPE_PRINTING_DOCUMENTS
} from "../../stores/scheduleInsertsStore";
import { READABLE_DATE_FORMAT } from "../../utils/dates";
import {
  Box,
  Button,
  DialogContentText,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  Typography,
  Stack
} from "@mui/material";
import { DynamicForm } from "../common/dynamic-form/DynamicForm";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { DropDownMenu } from "../drop-down-menu/DropDownMenu";
import { PrintOutlined as PrintIcon } from "@mui/icons-material";
import { AbsoluteLoader } from "../loader/Loader";

const ActionsContainer = styled(Box)(
  () => `
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  `
);

const DialogContentStyled = styled(DialogContent)(
  () => `
    display: flex;
    flex-direction: column;
    gap: 15px;
  `
);

const initialStateConfirmationDialog = {
  show: false,
  title: "",
  desc: "",
  agreement: {
    text: ""
  }
};

const howDidLearnOptions = [
  "Блогеры",
  "Живу рядом (вывеска клиники)",
  "Интернет (Поиск: Яндекс, Гугл)",
  "Карты (Яндекс, Гугл, 2ГИС)",
  "На поправку",
  "Направление врача",
  "Наружная реклама (не у клиники)",
  "Повторный визит в клинику",
  "Радио",
  "Рекомендации докторов других клиник",
  "Рекомендации докторов НАШИХ клиник",
  "Рекомендации знакомых",
  "Сайт Про Докторов",
  "СберЗдоровье",
  "Соц.сети",
  "Телевидение",
  "Техническая запись",
  "Другое"
];

const SUBMIT_TYPES = {
  RECORD: "record",
  EDITING: "editing",
  REMOVE: "remove"
};

const CloseButton = ({ onClose }) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{ marginLeft: "auto" }}>
      <CloseIcon />
    </IconButton>
  );
};

const ConfirmationOfAction = ({ data, onDisagree }) => {
  const onAgree = () => {
    data.agreement.action();
    onDisagree();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={!!data.show} onClose={onDisagree}>
      <DialogTitle>{data.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{data.desc}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAgree}>{data.agreement.text}</Button>
        <Button variant="contained" onClick={onDisagree} autoFocus>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// ToDo нужно проверить отображение этих полей при созданной записи на нового пациента
const additionalPatientFields = [
  {
    type: "phoneNumber",
    name: "phone_number",
    label: "Номер телефона",
    md: 12
  },
  {
    type: "date",
    name: "birthdate",
    label: "Дата рождения",
    md: 12
  },
  {
    type: "select",
    name: "how_did_learn",
    label: "Откуда пациент узнал о нас?",
    options: howDidLearnOptions.map(option => ({ label: option, value: option })),
    md: 12
  }
];

const getAdditionalPatientsField = ({ data, patients }) => {
  const patient = patients.find(
    item =>
      data?.patient?.id === item.id && data?.patient?.medicalFileNumber === item.medicalFileNumber
  );
  if (!data?.patient || patient) {
    return [];
  }
  return additionalPatientFields;
};

const PatientForm = observer(({ type, config }) => {
  const formData = useMemo(() => {
    return scheduleInsertsStore.patientData;
  }, [scheduleInsertsStore.patientData]);

  const watcherOnChangeField = (updatedData, reset) => {
    if (!updatedData.patient) {
      const resetData = {
        patient: "",
        phone_number: "",
        birthdate: "",
        how_did_learn: ""
      };

      reset({
        ...updatedData,
        ...resetData
      });
    }
    if (
      (typeof updatedData.patient === "string" && formData?.patient !== updatedData.patient) ||
      (typeof updatedData.patient !== "string" && formData?.patient?.medicalFileNumber !== updatedData?.patient?.medicalFileNumber)
    ) {
      const resetData = {
        phone_number: "",
        birthdate: "",
        how_did_learn: ""
      };

      reset({
        ...updatedData,
        ...resetData
      });
    }

    scheduleInsertsStore.patientData = {
      ...scheduleInsertsStore.patientData,
      ...updatedData
    };
  };

  const fields = useMemo(() => {
    return [
      {
        type: "selectFreeSolo",
        name: "patient",
        label: "Пациент",
        getOptionLabel: data => {
          return `${data.label}, ${data.birthdate}, ${data.medicalFileNumber}`;
        },
        needUpdateSearchValue: type === INSERTS_TYPE.EDITING,
        getOptions: () => {
          if (type !== INSERTS_TYPE.EDITING) {
            return [];
          }
          return scheduleInsertsStore.patientList.map(patient => {
            return {
              value: { id: patient.id, medicalFileNumber: patient.medicalFileNumber },
              label: patient.fullName,
              birthdate: `${moment(patient.birthdate).format(READABLE_DATE_FORMAT)}`,
              medicalFileNumber: `АК №${patient.medicalFileNumber}`
            };
          });
        },
        getSearchOptions: async value => {
          await scheduleInsertsStore.getPatientListByValue(value);
          return scheduleInsertsStore.patientList.map(patient => {
            return {
              value: { id: patient.id, medicalFileNumber: patient.medicalFileNumber },
              label: patient.fullName,
              birthdate: `${moment(patient.birthdate).format(READABLE_DATE_FORMAT)}`,
              medicalFileNumber: `АК №${patient.medicalFileNumber}`
            };
          });
        },
        xs: 12
      },
      ...getAdditionalPatientsField({
        data: formData,
        patients: scheduleInsertsStore.patientList
      })
    ]
      .filter(field => !config.hiddenFields(formData, type).includes(field.name))
      .map(field => {
        if (config.disabledFields(formData).includes(field.name)) {
          field.readOnly = true;
          field.disabled = true;
        }
        return field;
      });
  }, [formData, scheduleInsertsStore.patientList]);

  return (
    <Box>
      <Typography sx={{ color: "gray", marginBottom: "10px" }}>Данные пациента</Typography>
      <DynamicForm
        fields={fields}
        data={formData}
        changeHandler={watcherOnChangeField}
        hiddenButtonSave={true}
        hiddenButtonCancel={true}
        debounceTime={0}
      />
    </Box>
  );
});

const VisitForm = observer(({ type, config, title = "", visitId }) => {
  const additionalDataById = scheduleInsertsStore.additionalDataById[visitId];
  const initialDataById = scheduleInsertsStore.visitsData[visitId];

  const formData = useMemo(() => {
    return scheduleInsertsStore.visitsData[visitId];
  }, [initialDataById]);

  const additionalFormData = useMemo(() => {
    return scheduleInsertsStore.additionalDataById[visitId];
  }, [additionalDataById]);

  const watcherOnChangeField = async (updatedData, reset, changedKey) => {
    const debounceTime = ["date", "time_start"].includes(changedKey) ? 300 : 0;
    const getPossibleData = async () => {
      await scheduleInsertsStore.getPossibleData({
        doctorId: updatedData.doctor.id,
        organizationId: updatedData.organization,
        date: updatedData.date
      });
    };

    return _.debounce(async () => {
      if (changedKey === "date") {
        await getPossibleData();

        scheduleInsertsStore.updateAdditionalFields({
          fields: ["timeStartOptions"],
          visitId,
          date: updatedData.date,
          doctorId: updatedData.doctor.id
        });
        reset({
          ...updatedData,
          time_start: undefined,
          time_end: undefined
        });
      }
      if (changedKey === "time_start" && formData.time_start !== updatedData.time_start) {
        if (!scheduleInsertsStore.actualDataAfterChangedField.length && !scheduleInsertsStore.requestForActualDataAfterChangedFieldCompleted) {
          await getPossibleData();
        }

        scheduleInsertsStore.updateAdditionalFields({
          fields: ["timeEndOptions"],
          visitId,
          timeStart: updatedData.time_start,
          stepTime: formData.doctor.schedule_step
        });
        reset({
          ...updatedData,
          time_end: undefined
        });
      }

      scheduleInsertsStore.setVisitsData({
        ...scheduleInsertsStore.visitsData,
        [visitId]: { ...updatedData }
      });
    }, debounceTime)();
  };

  const fields = useMemo(() => {
    if (!additionalFormData || !formData) {
      return [];
    }
    return [
      {
        type: "select",
        name: "organization",
        label: "Организация",
        valueName: "id",
        labelName: "name",
        options: additionalFormData.organizationOptions,
        xs: 4
      },
      {
        type: "text",
        name: "doctor",
        label: "Врач",
        transformValue: doctor => `${doctor.last_name} ${doctor.first_name} ${doctor.middle_name}`,
        xs: 4
      },
      {
        type: "text",
        name: "entry_source",
        label: "Запись через",
        xs: 4
      },
      {
        type: "select",
        name: "date_select",
        label: "Дата приема",
        options: additionalFormData.dateOptions,
        xs: 4
      },
      {
        type: "date",
        name: "date",
        label: "Дата приема",
        xs: 4
      },
      {
        type: "select",
        name: "time_start",
        label: "Время начала приема",
        options: additionalFormData.timeStartOptions,
        xs: 4
      },
      {
        type: "select",
        name: "time_end",
        label: "Время завершения приема",
        options: additionalFormData.timeEndOptions,
        xs: 4
      },
      {
        type: "select",
        name: "services",
        label: "Услуги",
        options: additionalFormData.serviceOptions,
        multiple: true,
        xs: 6
      },
      {
        type: "textarea",
        name: "comment",
        label: "Комментарий",
        xs: 6
      },
      {
        type: "select",
        name: "payment_type",
        label: "Способ оплаты",
        options: additionalFormData.paymentTypeOptions,
        md: 4,
        xs: 12
      },
      {
        type: "select",
        name: "notification_type",
        label: "Способ оповещения",
        options: additionalFormData.notificationTypeOptions,
        md: 4,
        xs: 12
      }
    ]
      .filter(field => !config.hiddenFields(formData, type).includes(field.name))
      .map(field => {
        if (config.disabledFields(formData, additionalFormData).includes(field.name)) {
          field.readOnly = true;
          field.disabled = true;
        }
        return field;
      });
  }, [formData, additionalFormData]);

  return (
    <Box>
      <Typography sx={{ color: "gray", marginBottom: "10px" }}>{title}</Typography>
      <DynamicForm
        fields={fields}
        data={formData}
        changeHandler={watcherOnChangeField}
        hiddenButtonSave={true}
        hiddenButtonCancel={true}
        debounceTime={0}
      />
    </Box>
  );
});

export const ScheduleInsertDialog = observer(() => {
  const initialState = scheduleInsertsStore.initialData;
  const { patient: initialPatientData, visits: initialVisitsData } = initialState;
  const { patientData: installedPatientData, visitsData: installedVisitsData } = scheduleInsertsStore;
  const [dataConfirmationDialog, setDataConfirmationDialog] = useState(initialStateConfirmationDialog);
  const type = scheduleInsertsStore.scheduleInsertsType;
  const config = INSERTS_CONFIG[type];
  const data = scheduleInsertsStore.scheduleInsertDialogData;

  const detectedChanges = () => {
    return (
      !_.isEqual(initialPatientData, installedPatientData) ||
      !_.isEqual(initialVisitsData, installedVisitsData)
    );
  };

  const handleOnCloseDialog = ({ isForce = false }) => {
    if (scheduleInsertsStore.isLoading) {
      return;
    }
    const closeDialog = () => scheduleInsertsStore.resetScheduleInsertDialogData();

    if (isForce || !detectedChanges()) {
      return closeDialog();
    }

    setDataConfirmationDialog({
      show: true,
      title:
        scheduleInsertsStore.scheduleInsertsType === INSERTS_TYPE.EDITING
          ? "Вы точно хотите закрыть форму редактирования записи?"
          : "Вы точно хотите закрыть форму записи пациента?",
      desc:
        scheduleInsertsStore.scheduleInsertsType === INSERTS_TYPE.EDITING
          ? "Если вы закроете форму редактирования записи, измененные данные не сохранятся"
          : "Если вы закроете форму записи пациента, внесенные данные не сохранятся",
      agreement: {
        text: "Закрыть",
        action: closeDialog
      }
    });
  };

  const closeConfirmationDialog = () => {
    setDataConfirmationDialog({ ...dataConfirmationDialog, show: false });
    setTimeout(() => {
      setDataConfirmationDialog(initialStateConfirmationDialog);
    }, 300);
  };

  const submitHandler = type => {
    if (type === SUBMIT_TYPES.RECORD) {
      scheduleInsertsStore.recordScheduleInserts({
        callback: () => handleOnCloseDialog({ isForce: true })
      });
    }
    if (type === SUBMIT_TYPES.EDITING) {
      scheduleInsertsStore.cancelScheduleInserts({});
      scheduleInsertsStore.recordScheduleInserts({
        callback: () => handleOnCloseDialog({ isForce: true })
      });
    }
    if (type === SUBMIT_TYPES.REMOVE) {
      scheduleInsertsStore.cancelScheduleInserts({
        callback: () => handleOnCloseDialog({ isForce: true })
      });
    }
  };

  const actionItems = useMemo(() => {
    if (!config) {
      return [];
    }
    return [
      {
        key: "record",
        text: "Записать",
        onClick: () => submitHandler(SUBMIT_TYPES.RECORD)
      },
      {
        key: "editing",
        text: "Сохранить запись",
        onClick: () => {
          if (!detectedChanges()) {
            return handleOnCloseDialog({ isForce: true });
          }
          setDataConfirmationDialog({
            show: true,
            title: "Вы точно хотите сохранить изменения в записи?",
            agreement: {
              text: "Сохранить",
              action: () => submitHandler(SUBMIT_TYPES.EDITING)
            }
          });
        }
      },
      {
        key: "remove",
        text: "Удалить запись",
        variant: "outlined",
        onClick: () =>
          setDataConfirmationDialog({
            show: true,
            title: "Вы точно хотите удалить запись?",
            agreement: {
              text: "Удалить",
              action: () => submitHandler(SUBMIT_TYPES.REMOVE)
            }
          })
      },
      {
        key: "telemed",
        text: "Создать телемед",
        variant: "outlined",
        onClick: () => scheduleInsertsStore.handlerAdditionalRecordingAction(0)
      },
      {
        key: "linkToOnlineAndPayment",
        variant: "outlined",
        text: "Отправить ссылку на онлайн и оплату",
        onClick: () => scheduleInsertsStore.handlerAdditionalRecordingAction(1)
      },
      {
        key: "linkToPayment",
        text: "Отправить ссылку на оплату",
        variant: "outlined",
        onClick: () => scheduleInsertsStore.handlerAdditionalRecordingAction(2)
      },
      {
        key: "linkToOnline",
        text: "Отправить ссылку на онлайн",
        variant: "outlined",
        onClick: () => scheduleInsertsStore.handlerAdditionalRecordingAction(3)
      }
    ].filter(action => config.actions().includes(action.key));
  }, [initialPatientData, installedPatientData, initialVisitsData, installedVisitsData, config]);

  const additionalActions = [
    {
      name: "Печать",
      icon: <PrintIcon />,
      enabledCollapse: true,
      collapseData: TYPE_PRINTING_DOCUMENTS.map((document) => {
        return {
          text: document.text,
          handleOnClick: () => scheduleInsertsStore.getDocumentForPrintByType(document.key)
        };
      })
    }
  ];

  return (
    <Dialog
      onClose={handleOnCloseDialog}
      open={scheduleInsertsStore.isShowScheduleInsertDialog}
      fullWidth
      maxWidth="xl">
      {scheduleInsertsStore.isLoading && <AbsoluteLoader />}
      <Box sx={{ display: "flex", alignItems: "center", padding: "16px 16px 0", columnGap: "5px" }}>
        {type === INSERTS_TYPE.EDITING && (
          <DropDownMenu menuItems={additionalActions} view="item" maxHeight={400} minWidth={400} />
        )}
        <DialogTitle sx={{ p: 0 }}>{INSERTS_TYPE_RU[type] || ""} - в разработке</DialogTitle>
        <CloseButton onClose={handleOnCloseDialog} />
      </Box>
      {data && (
        <DialogContentStyled>
          <Grid container spacing={2}>
            <Grid xs={4}>
              <PatientForm type={type} config={config} />
            </Grid>
            <Grid xs={8}>
              <Stack spacing={2}>
                {scheduleInsertsStore.appointments.map((id, index) => {
                  let title = "";
                  if (scheduleInsertsStore.appointments.length === 1) {
                    title = !id ? "Прием вне расписания" : "Прием";
                  } else {
                    title = `${index + 1} прием`;
                  }
                  return (
                    <VisitForm key={index} type={type} config={config} title={title} visitId={id} />
                  );
                })}
              </Stack>
            </Grid>
          </Grid>
          <Divider />
          <ActionsContainer>
            {actionItems.map(action => (
              <Button
                key={action.text}
                variant={action?.variant || "contained"}
                disabled={action.disabled}
                onClick={action.onClick}>
                {action.text}
              </Button>
            ))}
          </ActionsContainer>
        </DialogContentStyled>
      )}
      <ConfirmationOfAction data={dataConfirmationDialog} onDisagree={closeConfirmationDialog} />
    </Dialog>
  );
});
