import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import { makeAnimationStartHandler } from "../DynamicForm";

export const FormInputPassword = props => {
  const [fieldHasValue, setFieldHasValue] = useState(false);
  const [isFieldInFocus, setIsFieldInFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleMouseDownPassword = event => event.preventDefault();
  const handleMouseUpPassword = event => event.preventDefault();

  return (
    <FormControl
      fullWidth
      className={"dynamic-form-input-control"}
      error={!!props.control._formState.errors[props.name]}
      size={"small"}
      variant={"outlined"}>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { value, onChange, ...fieldProps }, fieldState: { error } }) => {
          return (
            <>
              <TextField
                {...fieldProps}
                id={props.name}
                size={"small"}
                disabled={props.disabled}
                error={!!error}
                type={showPassword ? "text" : "password"}
                label={props.label ? props.label : "Пароль"}
                value={value || ""}
                onChange={(event) => {
                  onChange(event.target.value);
                  if (props.enableInstalledShrink && !isFieldInFocus) {
                    setFieldHasValue(event.target.value !== "");
                  }
                }}
                onKeyUp={e => {
                  if (props.submit && e.code === "Enter") {
                    props.submit();
                  }
                }}
                onFocus={() => {
                  setFieldHasValue(true);
                  setIsFieldInFocus(true);
                }}
                onBlur={() => {
                  setFieldHasValue(!!value);
                  setIsFieldInFocus(false);
                }}
                InputLabelProps={{ shrink: props.enableInstalledShrink && fieldHasValue }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                        size="small">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  onAnimationStart: makeAnimationStartHandler(setFieldHasValue, value)
                }}
              />
              {(props.subInfo || !!error) && (
                <FormHelperText>
                  {error?.message ? error.message : props.subInfo || ""}
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};
