// @flow
import { createEvent, createStore } from "effector";
import * as R from "ramda";
import type {
  LayoutFieldValue,
  TAppointment_id,
  AppointmentLiveBirthProbability
} from "../domain/entities/Appointment";
import type { UploadedFile } from "../domain/entities/UploadedFile";
import type { FiledValuesForSave } from "../domain/value-objects/appointment";
import type { FieldSingle, TField_id } from "../domain/entities/Field";
import moment from "moment";
import type { TDefaultValuesMap } from "../domain/services/field";
import { READABLE_DATE_FORMAT } from "../domain/services/field";
import _ from "lodash";
import type { Prescription, PrescriptionTableParams } from "../domain/value-objects/Prescription";
import type { BodyMassIndexParams } from "../domain/value-objects/BodyMassIndex";
import type { Drug, TDrugId } from "../domain/entities/Drug";
import {
  createPrescriptionFromTableParamsAndDrug,
  filterPrescriptions,
  getMaxOrder,
  getMinCycleDay,
  getMinDate,
  prescriptionToDrug,
  whereTableColumnAndDrugId
} from "../domain/services/prescription";
import { tokenStore } from "./auth";
import apiv2 from '../apiv2';

type TFieldType = $ElementType<FieldSingle, "field_type">;

export type TFieldValue = {
  field_type: TFieldType,
  value?: string | Array<string | Prescription> | BodyMassIndexParams,
  files?: Array<UploadedFile>,
  prescriptionTable?: {
    params?: PrescriptionTableParams,
    drugs?: Array<Drug>,
    wasCleaned?: boolean
  }
};

export type TFieldValueBodyMassIndex = {
  field_type: TFieldType,
  value?: BodyMassIndexParams
};

export type TFieldValuesMap = {
  [field_id: number]: TFieldValue
};

export type TAppointmentFieldValuesStore = {
  [appointment_id: TAppointment_id]: ?{
    services_description: string,
    next_visit_day?: string,
    fieldValues: {
      [field_id: number]: TFieldValue
    }
  }
};

const fieldValuePath = (appointment_id, field_id) =>
  R.lensPath([appointment_id, "fieldValues", field_id]);
const fieldValueValuePath = (appointment_id, field_id) =>
  R.lensPath([appointment_id, "fieldValues", field_id, "value"]);
const fieldValueFilesPath = (appointment_id, field_id) =>
  R.lensPath([appointment_id, "fieldValues", field_id, "files"]);
const fieldValueFieldTypePath = (appointment_id, field_id) =>
  R.lensPath([appointment_id, "fieldValues", field_id, "field_type"]);
const fieldValuePrescriptionTable = (appointment_id, field_id) =>
  R.lensPath([appointment_id, "fieldValues", field_id, "prescriptionTable"]);
const fieldValuePrescriptionTableParams = (appointment_id, field_id) =>
  R.lensPath([appointment_id, "fieldValues", field_id, "prescriptionTable", "params"]);
const fieldValuePrescriptionTableDrugs = (appointment_id, field_id) =>
  R.lensPath([appointment_id, "fieldValues", field_id, "prescriptionTable", "drugs"]);
export const fieldValuesPath = (appointment_id: TAppointment_id) =>
  R.lensPath([appointment_id, "fieldValues"]);
const servicesDescriptionPath = appointment_id =>
  R.lensPath([appointment_id, "services_description"]);
const nextVisitDayPath = appointment_id => R.lensPath([appointment_id, "next_visit_day"]);

const setEmptyAppointmentIfNeeded = appointment_id =>
  R.over(
    R.lensProp("" + appointment_id),
    appointment =>
      appointment || {
        services_description: null,
        fieldValues: {}
      }
  );

const setEmptyFiledValueIfNeeded = (appointment_id, field_id) =>
  R.over(fieldValuePath(appointment_id, field_id), filedValue => filedValue || {});

const setEmptyFiledValueValueArrayIfNeeded = (appointment_id, field_id) =>
  R.over(fieldValueValuePath(appointment_id, field_id), filedValue => filedValue || []);

const setEmptyFieldValuePrescriptionTableIfNeeded = (appointment_id, field_id) =>
  R.over(fieldValuePrescriptionTable(appointment_id, field_id), filedValue => filedValue || {});

const setEmptyFieldValuePrescriptionTableDrugsIfNeeded = (appointment_id, field_id) =>
  R.over(
    fieldValuePrescriptionTableDrugs(appointment_id, field_id),
    filedValue => filedValue || []
  );

const setFiledValueFieldType = (appointment_id, field_id, field_type: TFieldType) =>
  R.set(fieldValueFieldTypePath(appointment_id, field_id), field_type);

const setFieldFilesByLayout = (appointment_id, field_id, layoutFiles?: Array<UploadedFile>) =>
  R.over(fieldValueFilesPath(appointment_id, field_id), files => files || layoutFiles || []);

const debouncedSaveAppointmentFieldValues = _.debounce(async (appointment_id: TAppointment_id) => {
  const token = tokenStore.getState();
  if (!token || !appointment_id) {
    return;
  }
  try {
    const result = await apiv2.appointments.postAppointmentsSetValues({
      appointment_id,
      fieldValues: serializeAppointment(appointment_id)
    });

    appointmentFieldValuesSet({ appointment_id, ...result });
  } catch (e) {
    console.log(e);
  }
}, 2000);

export const setFieldValue = createEvent<{
  ...$Exact<TFieldValue>,
  appointment_id: TAppointment_id,
  field_id: number
}>("setFieldValue");

export const removeFieldValue = createEvent<{
  appointment_id: TAppointment_id,
  field_id: string
}>("removeFieldValue");

export const appendFileToField = createEvent<{
  file: UploadedFile,
  appointment_id: TAppointment_id,
  field_id: number,
  layoutFieldValue?: Array<UploadedFile>
}>("appendFileToField");

export const removeFileFromField = createEvent<{
  file_id: number,
  appointment_id: TAppointment_id,
  field_id: number,
  layoutFieldValue?: Array<UploadedFile>
}>("removeFileFromField");

export const editFileInField = createEvent<{
  file: UploadedFile,
  appointment_id: TAppointment_id,
  field_id: number,
  layoutFieldValue?: Array<UploadedFile>
}>("editFileInField");

export const setPrescriptionTableParams = createEvent<{
  appointment_id: TAppointment_id,
  field_id: number,
  params: PrescriptionTableParams
}>("setPrescriptionTableParams");

export const deserializeLayoutFieldValueToPrescriptionTable = createEvent<{
  appointment_id: TAppointment_id,
  field_id: TField_id,
  layoutFieldValue: LayoutFieldValue
}>("deserializeLayoutFieldValueToPrescriptionTable");

export const addDrugToPrescriptionTable = createEvent<{
  appointment_id: TAppointment_id,
  field_id: number,
  drug: Drug
}>("addDrugToPrescriptionTable");

export const removeDrugFromPrescriptionTable = createEvent<{
  appointment_id: TAppointment_id,
  field_id: TField_id,
  drug_id: TDrugId
}>("removeDrugFromPrescriptionTable");

export const changePrescriptionTableValue = createEvent<{
  appointment_id: TAppointment_id,
  field_id: number,
  prescription: Prescription
}>("changePrescriptionTableValue");

export const changeTableValue = createEvent<{
  appointment_id: TAppointment_id,
  field_id: number,
  tableValues: any
}>("changeTableValue");

export const cleanPrescriptionTable = createEvent<{
  appointment_id: TAppointment_id,
  field_id: TField_id
}>("cleanPrescriptionTable");

export const setServicesDescription = createEvent<{
  appointment_id: TAppointment_id,
  services_description: string
}>("setServicesDescription");

export const setNextVisitDay = createEvent<{
  appointment_id: TAppointment_id,
  next_visit_day: string
}>("setNextVisitDay");

export const clearAppointmentStore = createEvent<TAppointment_id>("clearAppointmentStore");

export const clearAppointment = createEvent<{
  appointment_id: TAppointment_id,
  emptyValues: TDefaultValuesMap
}>("clearAppointment");

export const clearAppointmentSection = createEvent<{
  appointment_id: TAppointment_id,
  emptyValues: TDefaultValuesMap
}>("clearAppointmentSection");

export const setAppointmentDefaultValues = createEvent<{
  appointment_id: TAppointment_id,
  values: TDefaultValuesMap
}>("setAppointmentDefaultValues");

export const resetAllAppointmentsFieldValues = createEvent<empty>(
  "resetAllAppointmentsFieldValues"
);

export const appointmentFieldValuesSet = createEvent<AppointmentLiveBirthProbability>(
  "appointmentFieldValuesSet"
);

export const appointmentFieldValuesStore = createStore<TAppointmentFieldValuesStore>({});

appointmentFieldValuesStore
  .on(setFieldValue, (state, valueWrapper) => {
    const { appointment_id, field_id, ...fieldValue } = valueWrapper;

    return R.compose(
      R.set(fieldValuePath(appointment_id, field_id), fieldValue),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(setFieldValue, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(removeFieldValue, (state, valueWrapper) => {
    const { appointment_id, field_id } = valueWrapper;

    return R.over(
      R.lensPath([appointment_id, "fieldValues"]),
      R.compose(
        R.omit(["" + field_id]),
        R.defaultTo({})
      )
    )(state);
  })
  .watch(removeFieldValue, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

const prepareBeforeFilesOperation = (valueWrapper: {
  appointment_id: TAppointment_id,
  field_id: number,
  layoutFieldValue?: Array<UploadedFile>
}) => {
  const { appointment_id, field_id, layoutFieldValue } = valueWrapper;

  return R.compose(
    setFieldFilesByLayout(appointment_id, field_id, layoutFieldValue),
    setFiledValueFieldType(appointment_id, field_id, "file"),
    setEmptyFiledValueIfNeeded(appointment_id, field_id),
    setEmptyAppointmentIfNeeded(appointment_id)
  );
};

appointmentFieldValuesStore
  .on(appendFileToField, (state, valueWrapper) => {
    const { appointment_id, field_id, file, layoutFieldValue } = valueWrapper;

    return R.compose(
      R.over(fieldValueFilesPath(appointment_id, field_id), files => [...files, file]),
      prepareBeforeFilesOperation({ appointment_id, field_id, layoutFieldValue })
    )(state);
  })
  .watch(appendFileToField, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(removeFileFromField, (state, valueWrapper) => {
    const { appointment_id, field_id, file_id, layoutFieldValue } = valueWrapper;

    return R.compose(
      R.over(fieldValueFilesPath(appointment_id, field_id), files =>
        _.filter(files, file => file.id !== file_id)
      ),
      prepareBeforeFilesOperation({ appointment_id, field_id, layoutFieldValue })
    )(state);
  })
  .watch(removeFileFromField, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(editFileInField, (state, valueWrapper) => {
    const { appointment_id, field_id, file, layoutFieldValue } = valueWrapper;
    const replaceFile = item => (item.id === file.id ? file : item);

    return R.compose(
      R.over(fieldValueFilesPath(appointment_id, field_id), files => R.map(replaceFile, files)),
      prepareBeforeFilesOperation({ appointment_id, field_id, layoutFieldValue })
    )(state);
  })
  .watch(editFileInField, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(setServicesDescription, (state, valueWrapper) => {
    const { appointment_id, services_description } = valueWrapper;

    return R.compose(
      R.set(servicesDescriptionPath(appointment_id), services_description),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(setServicesDescription, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(setNextVisitDay, (state, valueWrapper) => {
    const { appointment_id, next_visit_day } = valueWrapper;
    return R.compose(
      R.set(nextVisitDayPath(appointment_id), next_visit_day),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(setNextVisitDay, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore.on(clearAppointmentStore, (state, appointment_id) =>
  R.omit(["" + appointment_id])(state)
);

appointmentFieldValuesStore
  .on(clearAppointment, (state, valueWrapper) => {
    const { appointment_id, emptyValues } = valueWrapper;

    return R.compose(
      R.over(fieldValuesPath(appointment_id), () => ({ ...emptyValues })),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(clearAppointment, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(clearAppointmentSection, (state, valueWrapper) => {
    const { appointment_id, emptyValues } = valueWrapper;

    return R.compose(
      R.over(fieldValuesPath(appointment_id), currentValues => ({
        ...currentValues,
        ...emptyValues
      })),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(clearAppointmentSection, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(setPrescriptionTableParams, (state, valueWrapper) => {
    const { appointment_id, field_id, params } = valueWrapper;

    return R.compose(
      R.set(fieldValuePrescriptionTableParams(appointment_id, field_id), params),
      R.over(fieldValuePrescriptionTable(appointment_id, field_id), R.omit(["wasCleaned"])),
      setEmptyFieldValuePrescriptionTableIfNeeded(appointment_id, field_id),
      setFiledValueFieldType(appointment_id, field_id, "prescription"),
      setEmptyFiledValueIfNeeded(appointment_id, field_id),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(setPrescriptionTableParams, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore.on(
  deserializeLayoutFieldValueToPrescriptionTable,
  (state, valueWrapper) => {
    const { appointment_id, field_id, layoutFieldValue } = valueWrapper;

    if (!(layoutFieldValue.value instanceof Array)) {
      return;
    }
    const prescriptions = filterPrescriptions(layoutFieldValue.value);

    const minDate = getMinDate(prescriptions);
    const minCycleDay = getMinCycleDay(prescriptions);
    const maxOrder = getMaxOrder(prescriptions);

    const params = {
      startDate: minDate && minDate.date,
      cycleDay: minCycleDay && minCycleDay.cycle_day,
      duration: (maxOrder && maxOrder.table_column + 1) || 0
    };

    const drugs = R.compose(
      R.uniqBy(R.prop("id")),
      R.map(prescriptionToDrug)
    )(prescriptions);

    return R.compose(
      R.set(fieldValueValuePath(appointment_id, field_id), prescriptions),
      R.set(fieldValuePrescriptionTableDrugs(appointment_id, field_id), drugs),
      R.set(fieldValuePrescriptionTableParams(appointment_id, field_id), params),
      setEmptyFieldValuePrescriptionTableIfNeeded(appointment_id, field_id),
      setFiledValueFieldType(appointment_id, field_id, "prescription"),
      setEmptyFiledValueIfNeeded(appointment_id, field_id),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  }
);

appointmentFieldValuesStore
  .on(addDrugToPrescriptionTable, (state, valueWrapper) => {
    const { appointment_id, field_id, drug } = valueWrapper;

    const prescriptionTableParams = R.view(
      fieldValuePrescriptionTableParams(appointment_id, field_id),
      state
    );

    const emptyValues = R.compose(
      R.map(offset =>
        createPrescriptionFromTableParamsAndDrug({
          drug,
          offset,
          value: "",
          prescriptionTableParams
        })
      ),
      (params: PrescriptionTableParams) => R.range(0, params.duration),
      R.defaultTo({ duration: 0 }),
      R.view(fieldValuePrescriptionTableParams(appointment_id, field_id))
    )(state);

    return R.compose(
      R.over(fieldValueValuePath(appointment_id, field_id), values => [
        ...(values || []),
        ...emptyValues
      ]),
      R.over(fieldValuePrescriptionTableDrugs(appointment_id, field_id), drugs => [...drugs, drug]),
      setEmptyFieldValuePrescriptionTableDrugsIfNeeded(appointment_id, field_id),
      setEmptyFieldValuePrescriptionTableIfNeeded(appointment_id, field_id),
      setFiledValueFieldType(appointment_id, field_id, "prescription"),
      setEmptyFiledValueIfNeeded(appointment_id, field_id),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(addDrugToPrescriptionTable, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(removeDrugFromPrescriptionTable, (state, valueWrapper) => {
    const { appointment_id, field_id, drug_id } = valueWrapper;
    return R.compose(
      R.over(
        fieldValueValuePath(appointment_id, field_id),
        R.compose(
          R.reject(
            R.where({
              drug_id: R.equals(drug_id)
            })
          ),
          R.defaultTo([])
        )
      ),
      R.over(
        fieldValuePrescriptionTableDrugs(appointment_id, field_id),
        R.reject(
          R.where({
            id: R.equals(drug_id)
          })
        )
      )
    )(state);
  })
  .watch(removeDrugFromPrescriptionTable, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

export const getPrescriptionTableValue = (
  appointment_id: TAppointment_id,
  field_id: TField_id,
  table_column: number,
  drug_id: TDrugId
): ?Prescription => {
  return R.compose(
    R.find(whereTableColumnAndDrugId(table_column, drug_id)),
    value => value || [],
    R.view(fieldValueValuePath(appointment_id, field_id))
  )(appointmentFieldValuesStore.getState());
};

appointmentFieldValuesStore
  .on(changePrescriptionTableValue, (state, valueWrapper) => {
    const { appointment_id, field_id, prescription } = valueWrapper;
    const { table_column, drug_id } = prescription;

    return R.compose(
      R.over(
        fieldValueValuePath(appointment_id, field_id),
        R.compose(
          R.append(prescription),
          R.reject(whereTableColumnAndDrugId(table_column, drug_id))
        )
      ),
      setFiledValueFieldType(appointment_id, field_id, "prescription"),
      setEmptyFiledValueValueArrayIfNeeded(appointment_id, field_id),
      setEmptyFiledValueIfNeeded(appointment_id, field_id),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(changePrescriptionTableValue, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(changeTableValue, (state, valueWrapper) => {
    const { appointment_id, field_id, tableValues } = valueWrapper;

    return R.compose(
      R.set(fieldValueValuePath(appointment_id, field_id), tableValues),
      setFiledValueFieldType(appointment_id, field_id, "table"),
      setEmptyFiledValueValueArrayIfNeeded(appointment_id, field_id),
      setEmptyFiledValueIfNeeded(appointment_id, field_id),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(changeTableValue, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(cleanPrescriptionTable, (state, valueWrapper) => {
    const { appointment_id, field_id } = valueWrapper;

    return R.compose(
      R.set(fieldValuePrescriptionTable(appointment_id, field_id), {
        wasCleaned: true
      }),
      R.set(fieldValueValuePath(appointment_id, field_id), []),
      setFiledValueFieldType(appointment_id, field_id, "prescription"),
      setEmptyFiledValueValueArrayIfNeeded(appointment_id, field_id),
      setEmptyFiledValueIfNeeded(appointment_id, field_id),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(cleanPrescriptionTable, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore
  .on(setAppointmentDefaultValues, (state, valueWrapper) => {
    const { appointment_id, values } = valueWrapper;

    const notEmptyFieldValuesKeys = R.compose(
      R.keys(),
      R.reject(value => R.isEmpty(value.value)),
      R.defaultTo({}),
      R.view(fieldValuesPath(appointment_id))
    )(state);

    const defaultValuesForEmptyFields = R.compose(
      R.omit(notEmptyFieldValuesKeys),
      R.reject(value => R.isEmpty(value.value))
    )(values);

    return R.compose(
      R.over(fieldValuesPath(appointment_id), currentValues => ({
        ...currentValues,
        ...defaultValuesForEmptyFields
      })),
      setEmptyAppointmentIfNeeded(appointment_id)
    )(state);
  })
  .watch(setAppointmentDefaultValues, (state, event) => {
    debouncedSaveAppointmentFieldValues(event.appointment_id);
  });

appointmentFieldValuesStore.reset(resetAllAppointmentsFieldValues);

const removeFieldType = R.omit(["field_type"]);

const removePrescriptionTableFromField = R.omit(["prescriptionTable"]);

const filterNotValidFieldDate = field => {
  return (
    field.field_type !== "date" ||
    field.value === "" ||
    (typeof field.value === "string" && moment(field.value, READABLE_DATE_FORMAT).isValid())
  );
};

const transformDateToISOFormat = (field: TFieldValue) => {
  if (field.field_type === "date" && typeof field.value === "string" && field.value !== "") {
    return { ...field, value: moment(field.value, READABLE_DATE_FORMAT).format("YYYY-MM-DD") };
  } else {
    return field;
  }
};

const transformUploadedFileToId = (field: TFieldValue) => {
  return field.files ? { ...field, files: field.files.map(file => file.id) } : field;
};

export const serializeAppointment = (appointment_id: TAppointment_id): FiledValuesForSave => {
  return R.compose(
    R.map(removePrescriptionTableFromField),
    R.map(removeFieldType),
    R.map(transformDateToISOFormat),
    R.filter(filterNotValidFieldDate),
    R.map(transformUploadedFileToId),
    R.map(([field, fieldValues]) => ({ field: parseInt(field), ...fieldValues })),
    R.toPairs,
    R.view(fieldValuesPath(appointment_id))
  )(appointmentFieldValuesStore.getState());
};

export const getFieldValueDescription = (appointment_id: TAppointment_id, field_id: number) =>
  R.view(fieldValuePath(appointment_id, field_id));

export const getAllFieldValuesDescription = (appointment_id: TAppointment_id) =>
  R.view(fieldValuesPath(appointment_id), appointmentFieldValuesStore.getState());

export const getServiceDescription = (appointment_id: TAppointment_id) =>
  R.view(servicesDescriptionPath(appointment_id), appointmentFieldValuesStore.getState());
