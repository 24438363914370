// @flow
import React, { Fragment, useEffect } from "react";
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import FolderIcon from "../../assets/icons/corporate-portal/fileManager/folderIcon.inline.svg";
import PdfIcon from "../../assets/icons/corporate-portal/fileManager/pdfIcon.inline.svg";
import DocIcon from "../../assets/icons/corporate-portal/fileManager/docIcon.inline.svg";
import TxtIcon from "../../assets/icons/corporate-portal/fileManager/txtIcon.inline.svg";
import VideoIcon from "../../assets/icons/corporate-portal/fileManager/videoIcon.inline.svg";
import ImageIcon from "../../assets/icons/corporate-portal/fileManager/imageIcon.inline.svg";
import UnknownFormatIcon from "../../assets/icons/corporate-portal/fileManager/unknownFormatIcon.inline.svg";
import corporateDocumentsStore, { FILE_TYPES } from "../stores/corporateDocumentsStore";
import { observer } from "mobx-react";
import { CorporateSubTitle, CorporateTitle } from "../styled/CorporateTypography.styled";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import useReactRouter from "use-react-router";

const ContentContainer = styled(CorporateWrapper)`
  margin-top: 24px;
`;

const ICON_BY_TYPE = {
  [FILE_TYPES.FOLDER]: <FolderIcon />,
  [FILE_TYPES.PDF]: <PdfIcon />,
  [FILE_TYPES.DOC]: <DocIcon />,
  [FILE_TYPES.TXT]: <TxtIcon />,
  [FILE_TYPES.VIDEO]: <VideoIcon />,
  [FILE_TYPES.IMAGE]: <ImageIcon />,
  [FILE_TYPES.UNKNOWN_FORMAT]: <UnknownFormatIcon />
};

const FileManagerContainer = styled(List)`
  .MuiButtonBase-root {
    padding: 12px 16px;
  }
  .MuiListItemIcon-root {
    min-width: auto;
    padding-right: 8px;
  }
`;

export const CorporateFileManager = observer(() => {

  const {
    match: { params },
    history,
  } = useReactRouter();

  if (!corporateDocumentsStore.isInitialized) {
    return null;
  }

  const handleOnClickFile = (file) => async () => {
    if (file.type === FILE_TYPES.FOLDER) {
      history.push(`/corporate-portal/documents/${file.id}`);
    } else {
      const infoFile = await corporateDocumentsStore.getDetailsInfoFile(file);
      const link = document.createElement("a");
      const url = infoFile.file || infoFile.file_url;
      link.setAttribute('href', infoFile.file || infoFile.file_url);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', `${infoFile.name}.${url.split('/').pop().split('.').pop()}`);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <ContentContainer>
      {!corporateDocumentsStore.files.length ? (
        <CorporateSubTitle modifiers={["gray"]}>Файлы не найдены</CorporateSubTitle>
      ) : (
        <Fragment>
          <CorporateTitle modifiers={"md"}>Все файлы</CorporateTitle>
          <FileManagerContainer>
            {corporateDocumentsStore.files.map(
              (file, index) => {
                return (
                  <Fragment key={file.id}>
                    {!index && <Divider />}
                    <ListItem disablePadding>
                      <ListItemButton onClick={handleOnClickFile(file)}>
                        <ListItemIcon>{ICON_BY_TYPE[file.type]}</ListItemIcon>
                        <ListItemText primary={file.name} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              }
            )}
          </FileManagerContainer>
        </Fragment>
      )}
    </ContentContainer>
  );
});
