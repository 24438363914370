//@flow
import * as React from "react";
import { css } from "styled-components";
import { Doctor } from "../doctor/Doctor";
import type { Doctor as DoctorType } from "../../domain/entities/Doctor";

export const AppointmentHistoryDoctor = (props: {|
  name: string,
  avatar?: string,
  avatarStyle?: any,
  placeholderStyle?: any,
  doctor?: DoctorType
|}) => (
  <Doctor
    {...props}
    avatarStyle={css`
      width: 34px;
      height: 34px;
      margin-right: 11px;
    `}
    placeholderStyle={css`
      width: 34px;
      height: 34px;
      margin-right: 11px;
      font-size: 14px;
      line-height: 14px;
    `}
  />
);
