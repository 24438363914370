import { makeAutoObservable } from 'mobx';

class AppointmentStore {
  appointments = [];
  isEndAppointmentLoading = false;
  isDisplayFolders = true;

  constructor() {
    makeAutoObservable(this);
  }

  changeIsEndAppointmentLoading = (value) => {
    this.isEndAppointmentLoading = value;
  }

  changeIsDisplayFolders = (value) => {
    this.isDisplayFolders = value;
  }

  setAppointments(appointments) {
    this.appointments = appointments;
  }

  getOpeningCheckupAppointments() {
    if (!this.isDisplayFolders) {
      const checkupAppointments = this.appointments.filter(
        appointment => appointment.folder && appointment.folder.name.startsWith("Чекап")
      );
      let counterOpeningCheckUp = 0;
      let counterClosingCheckUp = 0;
      return checkupAppointments.reduce((acc, appointment) => {
        appointment.layout.opening_check_up && counterOpeningCheckUp++;
        appointment.layout.closing_check_up && counterClosingCheckUp++;
        if (counterOpeningCheckUp !== counterClosingCheckUp || !appointment.date_finished) {
          acc.push(appointment);
        }
        return acc;
      }, []);
    }
    const checkupAppointments = this.appointments.filter(
      appointment => appointment.type === "folder" && appointment.name.startsWith("Чекап")
    );
    return checkupAppointments.reduce((acc, current) => {
      let counterOpeningCheckUp = 0;
      let counterClosingCheckUp = 0;
      current.appointments.forEach(item => {
        item.layout.opening_check_up && counterOpeningCheckUp++;
        item.layout.closing_check_up && counterClosingCheckUp++;
      });
      const isFinishedAppointmentClosingCheckUp = current.appointments.every((appointment) => appointment.date_finished);
      if (counterOpeningCheckUp !== counterClosingCheckUp || !isFinishedAppointmentClosingCheckUp) {
        acc.push(current);
      }
      return acc;
    }, []);
  }
}

export default new AppointmentStore();
