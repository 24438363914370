// @flow
import * as React from "react";

import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import { ButtonReview, FlexContainer, HeaderText, LogoWrapper, SectionBottom, SectionTop } from "./reviewStyled";
import reviewStore, { CLINIC_CODE, CLINIC_NAME } from "../../stores/reviewStore";
import useReactRouter from "use-react-router";
import { ClinicLogo } from "../clinicLogo/ClinicLogo";
import { generateSearchParams, setStoreDataSearchParams } from "./ReviewPage";

export const ReviewStepTwo = withRouter(observer((props: {||}) => {

  if (!reviewStore.token) {
    const { match: { params } } = useReactRouter();
    setStoreDataSearchParams(reviewStore);
    props.history.push(`/review/${params.token}${generateSearchParams(reviewStore)}`);
    return null;
  }

  reviewStore.setCurrentPage('step-two');
  reviewStore.setProgress();

  const handleClickRate = (rate) => {
    reviewStore.setRate(rate);
    if (rate > 3) {
      props.history.push(`/review/${reviewStore.token}/step-three${generateSearchParams(reviewStore)}`);
    } else {
      props.history.push(`/review/${reviewStore.token}/step-three-negative${generateSearchParams(reviewStore)}`);
    }

  };

  return (
    <FlexContainer>
      <SectionTop>
        <LogoWrapper>
          <ClinicLogo name={reviewStore.clinic_code} isShowName={false} size={{width:"79px", height:"79px"}} />
        </LogoWrapper>
        <HeaderText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code, "center"]}>
          Вам понравилось обслуживание в клинике {CLINIC_NAME[reviewStore.clinic_code]}?
        </HeaderText>
      </SectionTop>
      <SectionBottom>
        <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickRate(5)}>5 — супер</ButtonReview>
        <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickRate(4)}>4 — хорошо</ButtonReview>
        <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickRate(3)}>3 — так себе</ButtonReview>
        <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickRate(2)}>2 — плохо</ButtonReview>
        <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickRate(1)}>1 — ужасно</ButtonReview>
      </SectionBottom>
    </FlexContainer>
  );
}));
