// @flow
import React from "react";
import styled from "styled-components";
import o from "react-overrides";
import type { ExtractOverridesProps } from "react-overrides";

import { PNormal } from "../styleguide/typography";
import { Tooltip as MaterialTooltip } from "@mui/material";

export const InlineContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InlineLegendContainer = styled(PNormal)`
  opacity: 0.6;
  margin-right: 5px;
`;

const overrides = {
  InlineContainer,
  InlineLegendContainer
};

export const FieldsetInline = (props: {
  legend: string,
  tooltipLegendText?: string,
  children: React$Node,
  overrides?: ExtractOverridesProps<typeof overrides>
}) => {
  return (
    <InlineContainer {...o}>
      {props.legend && (
        <>
          {props.tooltipLegendText ? (
            <MaterialTooltip animation="zoom" placement={"bottomLeft"} title={<span>{props.tooltipLegendText}</span>}>
              <InlineLegendContainer {...o} modifiers="left" as="span">
                {props.legend}
              </InlineLegendContainer>
            </MaterialTooltip>
          ) : (
            <InlineLegendContainer {...o} modifiers="left" as="span">
              {props.legend}
            </InlineLegendContainer>
          )}
        </>
      )}
      {props.children}
    </InlineContainer>
  );
};
