import { ChartComponent } from "../common/charts/ChartComponent";
import { Box, Paper, Stack } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import _ from "lodash";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import organizationsStore from "../../stores/organizationsStore";
import paymentStatisticsStore, { transformDataDiagram, transformTableData } from "../../stores/paymentStatisticsStore";
import { renderToolbarAlertBannerContent } from "../common/MaterialTable";
import * as yup from "yup";
import { filterValidation } from "../common/widgets-components-common/constants";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = 'widgetPaymentSummary';
export const FILTERS_FIELDS_WIDGET_PAYMENT_SUMMARY = () => [
  {
    type: 'select',
    name: 'type',
    label: 'Вид графика',
    xs: 12,
    options: [
      {value: 'line', label: 'Линейный'},
      {value: 'bar', label: 'Столбцами'},
      {value: 'table', label: 'Таблицей'},
    ]
  },
  {
    type: 'select',
    name: 'schedule_formats',
    label: 'Формат',
    multiple: true,
    xs: 12,
    options: paymentStatisticsStore.formatOptions
  },
  {
    type: 'select',
    name: 'valueField',
    label: 'Группировать по',
    xs: 12,
    options: [
      {value: 'count', label: 'Количеству'},
      {value: 'amount', label: 'Стоимости'}
    ]
  },
  {
    type: 'checkbox',
    name: 'isCollapse',
    label: 'Объединить выбранные организации (только для графиков)',
    xs: 12,
  },
  {
    type: 'text',
    name: 'label',
    label: 'Название графика',
    xs: 12,
  },
  {
    type: 'select',
    name: 'interval',
    label: 'Интервал',
    xs: 12,
    options: [
      {value: 'hour', label: 'По часам'},
      {value: 'day', label: 'По дням'},
      {value: 'week', label: 'По неделям'},
      {value: 'month', label: 'По месяцам'},
      {value: 'year', label: 'По годам'},
    ]
  },
  {
    type: 'checkbox',
    name: 'updateData',
    label: 'Реальное время',
    xs: 12,
  },
  {
    type: 'select',
    name: 'organization_ids',
    label: 'Организации',
    multiple: true,
    needSelectAll: true,
    valueName: 'id',
    labelName: 'name',
    xs: 12,
    options: organizationsStore.organizations,
  },
];

export const WidgetPaymentSummary = observer((props: {
  filtersData: () => {},
  rewriteFilters: () => {},
  keyWidget: String
}) => {

  const styledPaper = {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [filterFields, setFilterFields] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableAllCount, setTableAllCount] = useState(0);
  const [label, setLabel] = useState('График по онлайн платежам');
  const [chartsData, setChartsData] = useState();
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateDataInterval, setUpdateDataInterval] = useState(null);
  const [errors, setErrors] = useState([]);
  const validateSchema = yup.object().shape({
    organization_ids: yup.array().required("организации").min(1, "организации")
  });


  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
      const filterDiagramLocaleStorage = getLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''));
      if (filterDiagramLocaleStorage && moment(filterDiagramLocaleStorage.date_end).isBefore(moment())) {
        filterDiagramLocaleStorage.date_end = moment();
      }
      const filters = props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
      if (filters.label) {
        setLabel(filters.label);
      }
      if (moment(filters.date_end).isBefore(moment().set({ hour: 23, minute: 59, second: 59 }))) {
        filters.date_end = moment().set({ hour: 23, minute: 59, second: 59 });
      }
      if (!filters.valueField) {
        filters.valueField = 'amount';
      }
      setFiltersDataDiagram(filters)
      setFilterFields([...FILTERS_FIELDS_WIDGET_PAYMENT_SUMMARY()]);
      setIsInitialized(true);
    })()
  }, []);

  useEffect(() => {
    if (props.rewriteFilters) {
      setFiltersDataDiagram({...filtersDataDiagram, ...props.rewriteFilters});
    }
  }, [props.rewriteFilters]);

  useEffect(() => {
    (async () => {
      if (_.size(filtersDataDiagram)) {
        setLoading(true);
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), filtersDataDiagram);
        if (filtersDataDiagram.label) {
          setLabel(filtersDataDiagram.label);
        }
        const updateData = async () => {
          const response = await paymentStatisticsStore.getAmountChartData(filtersDataDiagram);
          if (response.error) {
            filterValidation({ validateSchema, filtersDataDiagram, setErrors });
          }
          setChartsData(transformDataDiagram({data: response, interval: filtersDataDiagram.interval || 'day', isCollapseData: filtersDataDiagram.isCollapse || filtersDataDiagram.organization_ids?.length === 1, valueField: filtersDataDiagram.valueField || 'amount'}));
          setTableData(transformTableData(response, filtersDataDiagram.organization_ids, filtersDataDiagram.valueField || 'amount',  organizationsStore.organizations, paymentStatisticsStore));
          setLoading(false);
        }
        if (updateDataInterval) {
          clearInterval(updateDataInterval);
          setUpdateDataInterval(null);
        }
        if (filtersDataDiagram.updateData) {
          setUpdateDataInterval(setInterval(updateData, 1800000));
        }
        updateData();
      }
    })()
  }, [filtersDataDiagram]);

  useEffect(() => {
    setTableAllCount(tableData.reduce((acc, item) => {
      acc[item.formatKey] += item[filtersDataDiagram.valueField || 'amount'];
      return acc;
    }, {chat: 0, online: 0, in_clinic: 0}));
  }, [tableData, filtersDataDiagram]);

  const submitHandler = (filters, callback) => {
    setErrors([]);
    setFiltersDataDiagram(filters);
    callback();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'organization', //access nested data with dot notation
        header: 'Название организации',
      },
      {
        accessorKey: 'format',
        header: 'Формат приема',
      },
      {
        accessorKey: filtersDataDiagram.valueField,
        size: 220,
        header: filtersDataDiagram.valueField === 'amount' ? 'Стоимость': 'Количество',
        Cell: ({ row }) => row.original[filtersDataDiagram.valueField] ? row.original[filtersDataDiagram.valueField]?.toLocaleString() : '',
        AggregatedCell: ({ cell, table }) => (
          <>
            <Box
              sx={{ color: 'primary.main', display: 'inline', }}
            >
              Всего: {cell.getValue() ? cell.getValue().toLocaleString() : ''}
            </Box>
          </>
        ),
        Footer: () => (
          <Stack>
            {(!filtersDataDiagram.schedule_formats?.length || filtersDataDiagram.schedule_formats.includes('chat')) && (
              <Box color="primary.main">Всего по чатам: {tableAllCount.chat.toLocaleString()}</Box>
            )}
            {(!filtersDataDiagram.schedule_formats?.length || filtersDataDiagram.schedule_formats.includes('online')) && (
              <Box color="primary.main">Всего по online: {tableAllCount.online.toLocaleString()}</Box>
            )}
            {(!filtersDataDiagram.schedule_formats?.length || filtersDataDiagram.schedule_formats.includes('in_clinic')) && (
              <Box color="primary.main">Всего в клинике: {tableAllCount.in_clinic.toLocaleString()}</Box>
            )}
            {(!filtersDataDiagram.schedule_formats || !filtersDataDiagram.schedule_formats?.length || filtersDataDiagram.schedule_formats?.length > 1) && (
              <Box color="primary.main">Всего: {(tableAllCount.online + tableAllCount.chat + tableAllCount.in_clinic).toLocaleString()}</Box>
            )}
          </Stack>
        ),
      },
    ],
    [tableAllCount, tableData],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    enableFullScreenToggle: false,
    groupedColumnMode: 'remove',
    layoutMode: 'grid',
    positionToolbarAlertBanner: 'bottom',
    renderToolbarAlertBannerContent: renderToolbarAlertBannerContent,
    enableGrouping: true,
    initialState: {
      density: 'compact',
      grouping: ['organization'],
      pagination: {pageIndex: 0, pageSize: 300}
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  return (
    <Paper style={styledPaper}>
      {filterFields && (
        <LabelWithFiltersModal
          fullWidth={true}
          type={"filter"}
          filtersData={filtersDataDiagram}
          filtersFields={filterFields}
          label={label}
          textButtonFilter={"Применить"}
          submitHandler={submitHandler}
        />
      )}
      {(loading || !isInitialized) && <AbsoluteLoader />}
      {isInitialized && (
        <>
          {filtersDataDiagram.type !== 'table' && chartsData && (
            <ChartComponent
              textButtonFilter="Применить"
              type={filtersDataDiagram.type || 'bar'}
              filtersDataDiagram={filtersDataDiagram}
              nameDiagram="WidgetPaymentSummary"
              data={chartsData}
              margin={{
                left: 90
              }}
              interval={filtersDataDiagram?.interval || 'day'}
              errors={errors}
            />
          )}
          {filtersDataDiagram.type === 'table' && tableData && (
            <div style={{
              maxHeight: '100%',
              overflow: "auto"
            }} className={'material-table'}>
              <MaterialReactTable table={table} />
            </div>
          )}
        </>
      )}
    </Paper>
  )
});
