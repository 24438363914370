import * as React from "react";
import { Link as AppLink } from "react-router-dom";
import styled from "styled-components";

import { colors } from "../styleguide/colors";

const Link = styled(AppLink)`
  font-size: 14px;
  line-height: 23px;
  color: ${colors.black};
  text-underline-offset: 4px;

  &:hover {
    color: ${colors.magenta};
  }
`;

export const LinkView = ({ children, ...props }) => <Link {...props}>{children}</Link>;
