import React from "react";
import styled from "styled-components";
import { useScheduleSocket } from "../schedule/useScheduleSocket";
import { appendOrUpdateData, removeCell } from "./scheduleStore";
import { getPatientFullName } from "../../domain/services/patient";
import { useToast } from "../toast/useToast";
import { colors } from "../styleguide/colors";
import { currentUser as currentUserStore } from "../../stores/auth";
import { useStore } from "effector-react";

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 18px;
`;

const BoldText = styled.span`
  font-weight: 500;
  color: ${colors.black};
`;

export const useScheduleUpdates = () => {
  const { showToast } = useToast();

  const getPatientFullNameFromSchedule = schedule => {
    if (schedule.medical_file) {
      return getPatientFullName(schedule.medical_file.patient);
    } else if (schedule.patient_full_name) {
      return schedule.patient_full_name;
    }
  };

  const currentUser = useStore(currentUserStore);

  useScheduleSocket((msg_type, data) => {
    switch (msg_type) {
      case "schedule_updated":
        if (data.doctor === currentUser.doctor.id) {
          appendOrUpdateData(data);
        }

        break;
      case "schedule_deleted":
        removeCell(data);
        break;
      case "patient_arrived":
        appendOrUpdateData(data);
        showToast(
          <ToastContainer>
            <BoldText>{getPatientFullNameFromSchedule(data)}</BoldText> пришёл в клинику
          </ToastContainer>
        );
        break;
      case "patient_left":
        appendOrUpdateData(data);
        showToast(
          <ToastContainer>
            <BoldText>{getPatientFullNameFromSchedule(data)}</BoldText> покинул клинику
          </ToastContainer>
        );
        break;
    }
  });
};
