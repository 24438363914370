import React from "react";
import styled from "styled-components";
import { PrimaryButton, PrimaryButtonContainer } from "../buttons/PrimaryButton";
import { Fieldset, Container as FieldsetContainer, Legend } from "../form/Fieldset";
import { ButtonRadioGroup } from "../form-elements/ButtonRadioGroup";
import { useFolderSelection } from "../appointment-start-form/useFolderSelection";

const Form = styled.form`
  padding-top: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 42px;
`;

const OverridedPrimaryButtonContainer = styled(PrimaryButtonContainer)`
  width: 256px;
  padding: 0 40px;
`;

const LayoutFieldsetContainer = styled(FieldsetContainer)`
  margin-top: 28px;
  padding-left: 165px;
`;

const LayoutLegend = styled(Legend)`
  top: 0;
`;

const Alert = styled.h3`
  margin-top: 10px;
`;

export const AppointmentMoveForm = ({ afterSubmit, layoutId, patientId }) => {
  const {
    folderTypes,
    folderTypesOrder,
    selectedFolderType,
    onFolderTypeChange
  } = useFolderSelection({ layoutId, patientId, shouldRefresh: true });

  const submitForm = async () => {
    afterSubmit({
      selectedFolderType: folderTypes[selectedFolderType]
    });
  };

  const button = (
    <PrimaryButton
      onClick={submitForm}
      disabled={!selectedFolderType}
      primaryButtonContainer={OverridedPrimaryButtonContainer}>
      Перенести прием
    </PrimaryButton>
  );

  return (
    <Form button={button}>
      {Object.keys(folderTypesOrder || {}).length > 0 ? (
        <>
          <Fieldset
            legend="Тип события"
            overrides={{
              Container: {
                component: LayoutFieldsetContainer
              },
              Legend: {
                component: LayoutLegend
              }
            }}>
            <ButtonRadioGroup
              items={folderTypes}
              name={"folderTypes"}
              order={folderTypesOrder}
              checked={selectedFolderType}
              onChange={onFolderTypeChange}
            />
          </Fieldset>
        </>
      ) : (
        <Alert>Нет доступных типов событий для данного приема.</Alert>
      )}

      <ButtonContainer>{button}</ButtonContainer>
    </Form>
  );
};
