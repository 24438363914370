// @flow
import * as React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import o, { type ExtractOverridesProps } from "react-overrides";

const Label = styled.label`
  position: relative;
  padding: ${props => props.hasButtonIcon ? '10px 36px 10px 14px' : '10px 14px'};
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 8px;
  cursor: pointer;
`;

const Input = styled.input`
  position: absolute;
  appearance: none;
`;

export const Background = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  border-radius: 0;
  background: ${colors.gray050};

  ${Input}:hover + & {
    background: ${colors.lightPink};
  }

  ${Input}:checked + & {
    background: ${colors.magenta};
  }

  ${Input}:focus + & {
    border: solid 2px #e1e1e1;
  }

  ${Input}:focus:not(:focus-visible) + & {
    border: none;
  }

  ${Input}:focus:checked + & {
    box-shadow: 0 0 4px 0 rgba(255, 0, 164, 0.46);
    border: solid 2px #ff00a4;
  }

  ${Input}:focus:not(:focus-visible):checked + & {
    box-shadow: none;
    border: none;
  }
`;

const Text = styled.span`
  font-size: 14px;
  text-align: ${props => props.hasButtonIcon ? 'left' : 'center'};
  color: ${colors.black};
  z-index: 1;

  ${Input}:checked ~ & {
    color: ${colors.white};
  }
`;

const OverridableComponents = {
  Label,
  Input,
  Background,
  Text
};

export const ButtonCheckbox = (props: {
  text: string,
  checked: boolean,
  tabIndex?: number,
  overrides?: ExtractOverridesProps<typeof OverridableComponents>,
  onChange?: boolean => mixed,
  onClick?: () => mixed,
  buttonIcon?: React.Node
}) => {
  return (
    <Label {...o} hasButtonIcon={props.buttonIcon}>
      <Input
        {...o}
        tabIndex={props.tabIndex}
        type={(o: any).type || "checkbox"}
        checked={props.checked}
        onClick={() => props.onClick && props.onClick()}
        onChange={e => props.onChange && props.onChange(e.target.checked)}
      />
      <Background {...o} />
      <Text {...o} hasButtonIcon={props.buttonIcon}>{props.text}</Text>
      { props.buttonIcon && props.buttonIcon }
    </Label>
  );
};
