import React, { useLayoutEffect, useState } from "react";
import styled, { css } from "styled-components";
import { withModifiers } from "with-modifiers";
import pickBy from "lodash/pickBy";
import useReactRouter from "use-react-router";

import { Tabs, DefaultTab } from "../tabs/Tabs";
import { ButtonCaps } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

import { observer } from "mobx-react";
import chatStore from "../../stores/chatStore";
import headerStore, { TABS } from "../../stores/headerStore";
import { FEATURE, useApplicationFeatureEnabled } from "../../utils/applicationFeatures";
import { Logout } from "../logout/Logout";
import commonStore from "../../stores/commonStore";
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import ClockIcon from '@mui/icons-material/AccessTimeOutlined';
import FeedbackIcon from '@mui/icons-material/FeedbackOutlined';
import GeneralCalendarIcon from '@mui/icons-material/PermContactCalendarOutlined';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import OutcomesIcon from '@mui/icons-material/ContentPasteSearchOutlined';
import UpdatedIcon from '@mui/icons-material/TextSnippetOutlined';
import HospitalIcon from '@mui/icons-material/VaccinesOutlined';
import ProfileIcon from '@mui/icons-material/PersonOutlineOutlined';
import MoreIcon from '@mui/icons-material/MoreHorizOutlined';
import SupportIcon from '@mui/icons-material/SupportAgent';
import LogoutIcon from "@mui/icons-material/Logout";
import PatientsIcon from '@mui/icons-material/ContactsOutlined';
import CorpPortalIcon from '@mui/icons-material/LanOutlined';
import { IconContainerStyled } from "../icon-containter-styles/IconContainer.styled";
import { useMediaQuery } from "@mui/material";

const ICON_BY_TAB = {
  [TABS.SCHEDULE]: <CalendarIcon />,
  [TABS.HISTORY]: <ClockIcon />,
  [TABS.ACCOUNT]: <ProfileIcon />,
  [TABS.FEEDBACK]: <FeedbackIcon />,
  [TABS.MY_PATIENTS]: <OutcomesIcon />,
  [TABS.GENERAL_SCHEDULE]: <GeneralCalendarIcon />,
  [TABS.GENERAL_SCHEDULE_NEW]: <GeneralCalendarIcon />,
  [TABS.HOSPITAL]: <HospitalIcon />,
  [TABS.CHATS]: <ChatIcon />,
  [TABS.UPDATE_NEWS]: <UpdatedIcon />,
  [TABS.PROFILE]: <ProfileIcon />,
  [TABS.MORE_NAVIGATION_TABS]: <MoreIcon />,
  [TABS.SUPPORT]: <SupportIcon />,
  [TABS.EXIT]: <LogoutIcon />,
  [TABS.PATIENTS]: <PatientsIcon />,
  [TABS.CORPORATE_PORTAL]: <CorpPortalIcon />
}

export const Container = withModifiers({
  isMobileDevice: () => `
    flex-direction: row;
    width: 100vw;
    height: 50px;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    ${IconContainerStyled} .MuiSvgIcon-root {
      stroke: ${colors.white};
    };
  `,
  isShowMoreTabs: () => `
    width: auto;
    border-top: none;
    align-items: flex-start;
    ${Tab} {
      min-width: 100%;
      min-height: 58px;
      justify-content: flex-start;
      flex-direction: row;
      border-bottom: 1px solid ${colors.transparentGray};
      ${IconContainerStyled} {
        margin-right: 8px;
      };
      ${ButtonCaps} {
        font-size: 15px;
      }
    }
  `
})(
  modifiers => styled.div`
    width: 168px;
    display: flex;
    flex-direction: column;
  
    @media screen and (max-width: 1200px) and (min-width: 769px) {
      flex-direction: row;
      border-top: 1px solid ${colors.transparentGray};
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    }
      
    ${modifiers} 
  `
);

export const Tab = styled(DefaultTab)`
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: transparent;
  border: none;

  padding: 10px 0;
  cursor: pointer;
  ${props => props.disabled ? css`pointer-events: none;` : null}

  & + & {
    border-top: 1px solid ${colors.transparentGray};

    @media screen and (max-width: 1200px) {
      border-top: none;
    }
  }

  @media screen and (max-width: 1200px) and (min-width: 769px) {
    flex-direction: column;
    gap: 3px;
    min-width: 60px;
  }

  @media screen and (max-width: 768px) {
    min-width: 60px;
    padding: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    span {
      text-transform: none;
    }
    ${IconContainerStyled} {
      margin: 0;
    }
  }
`;

const ScheduleTab = props => {
  const notMargin = useMediaQuery("(max-width: 1200px)");

  if (props.tab.customTab) {
    return props.tab.render();
  }

  return (
    <Tab disabled={props.modifiers === "active"} onClick={props.onClick}>
      <IconContainerStyled
        size={props.isMobileDevice ? "1.8rem" : "1.5rem"}
        marginRight={notMargin ? 0 : "8px"}
        modifiers={[ ...(typeof props.modifiers === "object" ? props.modifiers : [props.modifiers]) ]}
      >
        {ICON_BY_TAB[props.tab.id]}
      </IconContainerStyled>
      <ButtonCaps modifiers={["left", props.modifiers === "active" && "color"]} fontSize="14px">
        {props.children}
      </ButtonCaps>
    </Tab>
  );
};

const TabContent = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

const UnreadMessages = withModifiers({
  absolute: () => `
    @media (max-width: 768px) {
      position: absolute;
      top: 5px;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${colors.magenta};
      border-radius: 15px;
      padding-inline: 4px;
      line-height: 12px;
      color: ${colors.white};
      height: 15px;
    }
  `
})(
 modifier => styled.span`
  font-family: "Graphik LCG";
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #c6007f;
  ${modifier}
 `
);

const ChatsTab = observer(({ withName = true }) => {
  return (
    <TabContent>
      {withName && 'Чаты'}
      {chatStore.unreadChatsCount > 0 && (
        <UnreadMessages modifiers={[ 'absolute' ]}>{chatStore.unreadChatsCount}</UnreadMessages>
      )}
    </TabContent>
  );
});

export const UpdateNewsTab = observer(() => {
  return (
    <TabContent>
      Что нового
      {!commonStore.isShowedUpdateVersion && (
        <UnreadMessages>1</UnreadMessages>
      )}
    </TabContent>
  );
});

const getNavigationTabs = (isMobileDevice, isShowMoreTabs) => {
  if (isMobileDevice) {
    if (isShowMoreTabs) {
      return {
        [TABS.ACCOUNT]: { name: "Профиль", show: (props) => props.isShowAccount },
        [TABS.CORPORATE_PORTAL]: { name: "Корпоративный портал", show: (props) => props.isCorpPortalEnabled},
        [TABS.GENERAL_SCHEDULE_NEW]: { name: "Новое общее расписание", show: (props) => props.generalScheduleNewEnabled},
        [TABS.PATIENTS]: { name: "Справочник пациентов", show: (props) => props.showPatients},
        [TABS.FEEDBACK]: { name: "Обратная связь", show: () => true },
        [TABS.MY_PATIENTS]: { name: "Исходы", show: (props) => props.outcomesExists },
        [TABS.HOSPITAL]: { name: "Стационар", show: (props) => props.isHospitalApproved },
        [TABS.UPDATE_NEWS]: { name: "Что нового", show: () => true, render: () => <UpdateNewsTab /> },
        [TABS.PROFILE]: { name: "Профиль", show: (props) => process.env.APP_FLAVOR !== "telemed" && props.showProfile },
        [TABS.SUPPORT]: { name: "Поддержка", show: () => true },
        [TABS.EXIT]: { name: "Выйти", show: () => true, render: () => <Logout isCustomExitTab />, customTab: true }
      };
    } else {
      return {
        [TABS.SCHEDULE]: { show: () => true },
        [TABS.HISTORY]: { show: () => true },
        [TABS.GENERAL_SCHEDULE]: { show: () => true },
        [TABS.CHATS]: { show: (props) => props.isChatsEnabled, render: () => <ChatsTab withName={false} /> },
        [TABS.MORE_NAVIGATION_TABS]: { show: () => true }
      };
    }
  } else {
    return {
      [TABS.SCHEDULE]: { name: "Расписание", show: () => true },
      [TABS.HISTORY]: { name: "История приемов", show: () => true },
      [TABS.ACCOUNT]: { name: "Профиль", show: (props) => props.isShowAccount },
      [TABS.FEEDBACK]: { name: "Обратная связь", show: () => true },
      [TABS.MY_PATIENTS]: { name: "Исходы", show: (props) => props.outcomesExists },
      [TABS.GENERAL_SCHEDULE]: { name: "Общее расписание", show: () => true },
      [TABS.GENERAL_SCHEDULE_NEW]: { name: "Новое общее расписание", show: (props) => props.generalScheduleNewEnabled},
      [TABS.HOSPITAL]: { name: "Стационар", show: (props) => props.isHospitalApproved },
      [TABS.CHATS]: { name: "Чаты", show: (props) => props.isChatsEnabled, render: () => <ChatsTab /> },
      [TABS.UPDATE_NEWS]: { name: "Что нового", show: () => true, render: () => <UpdateNewsTab /> },
      [TABS.PATIENTS]: { name: "Справочник пациентов", show: (props) => props.showPatients},
      [TABS.CORPORATE_PORTAL]: { name: "Корпоративный портал", show: (props) => props.isCorpPortalEnabled},
      [TABS.PROFILE]: { name: "Профиль", show: (props) => process.env.APP_FLAVOR !== "telemed" && props.showProfile }
    };
  }
};

export const NavigationTabs = observer(({ isMobileDevice, isShowMoreTabs }) => {
  const { location, history } = useReactRouter();
  const [isShowNavigationTabs, setIsShowNavigationTabs] = useState(false);
  const showProfile = useApplicationFeatureEnabled(FEATURE.PROFILE);
  const showPatients = useApplicationFeatureEnabled(FEATURE.PATIENTS);
  const isChatsEnabled = useApplicationFeatureEnabled(FEATURE.CHAT);
  const isCorpPortalEnabled = useApplicationFeatureEnabled(FEATURE.CORPORATE_PORTAL);
  const generalScheduleNewEnabled = useApplicationFeatureEnabled(FEATURE.GENERAL_SCHEDULE_NEW);
  const handleOnChangeTab = tab => {
    if (tab === TABS.SUPPORT) {
      if (!document.getElementsByClassName('kit-widget-container is-open').length) {
        document.getElementsByClassName('widget-btn')[0].click();
        setTimeout(() => {
          document.getElementsByClassName('editable')[0].focus();
        });
      }
      return;
    }
    headerStore.handlerChangeTab(tab, history);
  };

  useLayoutEffect(() => {
    headerStore.setActiveTabByLocationPath(location.pathname);
    setIsShowNavigationTabs(headerStore.isShowNavigationTab(isMobileDevice));
  }, [location.pathname]);

  if (!isShowNavigationTabs) {
    return null;
  }

  return (
    <Tabs
      activeTab={headerStore.activeTab}
      handleClickOnTab={handleOnChangeTab}
      isMobileDevice={isMobileDevice}
      tabs={pickBy(getNavigationTabs(isMobileDevice, isShowMoreTabs), ({ show }) =>
        show({
          isShowAccount: process.env.APP_FLAVOR === "telemed",
          isHospitalApproved: headerStore.isHospitalApproved,
          isChatsEnabled: isChatsEnabled,
          generalScheduleNewEnabled: generalScheduleNewEnabled,
          outcomesExists: headerStore.outcomesExists,
          showProfile,
          showPatients,
          isCorpPortalEnabled
        }),
      )}
      overrides={{
        DefaultTab: {
          component: ScheduleTab
        },
        Container: {
          component: Container,
          props: {
            modifiers: [
              isMobileDevice && !isShowMoreTabs && "isMobileDevice",
              isMobileDevice && isShowMoreTabs && "isShowMoreTabs"
            ]
          }
        }
      }}
    />
  );
});
