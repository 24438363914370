"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTrigger = void 0;

var _nanoid = _interopRequireDefault(require("nanoid"));

var _react = require("react");

var createTrigger = function createTrigger() {
  var trigger = function trigger() {
    trigger.id = (0, _nanoid.default)();
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = trigger.subscribers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var subscriber = _step.value;
        subscriber();
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  };

  trigger.id = (0, _nanoid.default)();
  trigger.subscribers = [];

  trigger.subscribe = function (f) {
    trigger.subscribers.push(f);
  };

  trigger.unsubscribe = function (f) {
    trigger.subscribers.indexOf(f) >= 0 && trigger.subscribers.splice(trigger.subscribers.indexOf(f), 1);
  };

  return trigger;
};

exports.createTrigger = createTrigger;