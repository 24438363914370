// @flow
import React from "react";

import { TransitionGroup } from "react-transition-group";
import transition from "styled-transition-group";

const Fade = transition.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    
    &:enter {
        opacity: 0.01;
    }

    &:enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    &:exit {
        opacity: 1;
    }

    &:exit-active {
        opacity: 0.01;
        transition: opacity 100ms ease-in;
    }
`;

export const SidebarFade = (props: { children: React$Node }) => (
  <TransitionGroup>{props.children && <Fade timeout={300}>{props.children}</Fade>}</TransitionGroup>
);
