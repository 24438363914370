import React from "react";

const Icon = props => {
  const { color = "#979797" } = props;
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.8807 9.91334C9.68464 9.71888 9.36806 9.72017 9.1736 9.91622C8.97913 10.1123 8.98042 10.4289 9.17648 10.6233L9.8807 9.91334ZM13.8498 15.2588C14.0459 15.4532 14.3625 15.4519 14.5569 15.2559C14.7514 15.0598 14.7501 14.7432 14.554 14.5488L13.8498 15.2588ZM6.00008 11.8686C9.22174 11.8686 11.8334 9.2569 11.8334 6.03524H10.8334C10.8334 8.70461 8.66946 10.8686 6.00008 10.8686V11.8686ZM11.8334 6.03524C11.8334 2.81358 9.22174 0.201904 6.00008 0.201904V1.2019C8.66946 1.2019 10.8334 3.36586 10.8334 6.03524H11.8334ZM6.00008 0.201904C2.77842 0.201904 0.166748 2.81358 0.166748 6.03524H1.16675C1.16675 3.36586 3.3307 1.2019 6.00008 1.2019V0.201904ZM0.166748 6.03524C0.166748 9.2569 2.77842 11.8686 6.00008 11.8686V10.8686C3.3307 10.8686 1.16675 8.70461 1.16675 6.03524H0.166748ZM9.17648 10.6233L13.8498 15.2588L14.554 14.5488L9.8807 9.91334L9.17648 10.6233Z"
        fill={color}
      />
    </svg>
  );
};

export default Icon;
