// @flow
import * as React from "react";
import styled from "styled-components";
import { AppointmentFormLayout } from "../appointment-page/AppointmentFormLayout";

const ContentContainer = styled.div`
  position: relative;
  padding: 15px 100px 0px 100px;
  max-width: 1470px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 15px 40px 0 40px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px 0 20px;
  }
`;

const MetaInfoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const SectionsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-block: 50px;
  @media (max-width: 768px) {
    margin-block: 25px;
  }
  @media (max-width: 480px) {
    margin-block: 15px;
  }
`;

export const AnalyzesFormLayout = (props: {
  title: React.Node,
  metaInfo?: React.Node,
  toolButtons?: React.Node,
  sections: React.Node,
  analyzes?: React.Node,
  onlineSection?: React.Node,
  buttonForm?: React.Node
}) => {
  return (
    <AppointmentFormLayout
      {...props}
      id="analyzesFormLayout"
      overrides={{
        ContentContainer: {
          component: ContentContainer
        },
        MetaInfoContainer: {
          component: MetaInfoContainer
        },
        SectionsContainer: {
          component: SectionsContainer
        }
      }}
    />
  );
};
