// @flow
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import { DefaultInput } from "../DefaultInput";
import MaskedInput from "react-text-mask";
import Calendar from "react-calendar";

import LeftArrowIcon from "../icons/LeftArrow";
import RightArrowIcon from "../icons/RightArrow";
import {READABLE_DATE_FORMAT} from '../../../utils/dates';

type DateInputProps = {
  ...React$ElementConfig<typeof DefaultInput>,
  valueDateFormat?: string,
  minDateToday?: boolean,
  minDateTomorrow?: boolean,
  minDate?: string,
  maxDateToday?: boolean,
  maxDate?: string
};

const Container = styled.div`
  position: relative;
  display: inline-flex;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CalendarContainer = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  display: block;
  box-shadow: 0 2px 17px rgba(91, 91, 91, 0.259752);
  border-radius: 3px;
  overflow: hidden;
  z-index: 9;
`;

const CalendarContent = styled.div`
  position: relative;
  padding-bottom: 40px;
  background: #fff;
`;

const TodayButton = styled.button`
  position: absolute;
  bottom: 0;
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  font-family: Graphik, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  color: #c6007f;
  cursor: pointer;

  &:hover {
    background: rgba(151, 151, 151, 0.1);
  }
`;

const useClickOutside = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const DateInput = ({ valueDateFormat = READABLE_DATE_FORMAT, minDateToday, minDateTomorrow, minDate, maxDateToday, maxDate, ...props }: DateInputProps) => {
  const { handleChange, onFocus, noCalendar, onKeyDown } = props;
  const [value, onChange] = useState(
    props.value
      ? moment(props.value, valueDateFormat).toDate()
      : new Date()
  );
  const [calendarVisible, setCalendarVisible] = useState(false);
  const containerRef = useRef(null);

  const today = new Date();
  let inputMinDate;
  let inputMaxDate;

  if (minDateToday) {
    inputMinDate = today;
  }
  if (minDateTomorrow) {
    inputMinDate = moment(today)
      .add(1, 'd')
      .toDate();
  }
  if (minDate) {
    inputMinDate = new Date(minDate);
  }
  if (maxDateToday) {
    inputMaxDate = today;
  }
  if (maxDate) {
    inputMaxDate = new Date(maxDate);
  }

  useClickOutside(containerRef, () => {
    setCalendarVisible(false);
  });

  const onInputFocus = () => {
    setCalendarVisible(!noCalendar && true);
    if (onFocus) {
      onFocus();
    }
  };

  const onTodayClick = () => {
    const today = new Date();
    const formattedValue = moment(today).format(READABLE_DATE_FORMAT);
    handleChange(formattedValue);
    onChange(today);
    setCalendarVisible(false);
  };

  const onInputKeyDown = e => {
    if (e.key === "Tab") {
      setCalendarVisible(false);
    }
    onKeyDown?.(e);
  };

  const onInputChange = (value) => {
    const newMoment = moment(value, READABLE_DATE_FORMAT);
    if (newMoment.isValid()) {
      onChange(newMoment.toDate());
    }
    handleChange?.(value);
  };

  const onInputBlur = e => {
   if (inputMinDate || inputMaxDate) {
     const inputDate = moment(e.target.value, READABLE_DATE_FORMAT).toDate();
     const setDate = (date) => {
       const formattedValue = moment(date).format(READABLE_DATE_FORMAT);
       handleChange(formattedValue);
       onChange(date);
     };

     if (inputDate < inputMinDate) {
       return setDate(inputMinDate);
     }
     if (inputDate > inputMaxDate) {
       return setDate(inputMaxDate);
     }
   }
  };

  const inputValue = props.value
    ? props.value.includes('-')
      ? moment(props.value, valueDateFormat).format(READABLE_DATE_FORMAT)
      : props.value :
    props.value;

  return (
    <Container ref={containerRef}>
      <DefaultInput
        {...props}
        value={inputValue}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onKeyDown={onInputKeyDown}
        handleChange={onInputChange}
        overrides={{
          ...props.overrides,
          ErrorText: {
            props: {
              small: true
            }
          },
          Input: {
            props: {
              as: MaskedInput,
              mask: [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/],
              guide: false,
              ...((props.overrides && props.overrides.Input?.props) || {})
            }
          }
        }}
      />
      {
        calendarVisible && (
          <CalendarContainer>
            <CalendarContent>
              <Calendar
                className="date-input-calendar"
                defaultView="month"
                navigationLabel={({ date, label, view }) => {
                  const today = new Date();
                  const dateMoment = moment(date);
                  return view === "month"
                    ? date.getFullYear() === today.getFullYear()
                      ? dateMoment.format("MMMM")
                      : dateMoment.format("MMMM, YYYY")
                    : label;
                }}
                prevLabel={<LeftArrowIcon />}
                nextLabel={<RightArrowIcon />}
                onChange={value => {
                  const formattedValue = moment(value).format("DD.MM.YYYY");
                  handleChange(formattedValue);
                  onChange(value);
                  setCalendarVisible(false);
                }}
                minDate={inputMinDate}
                maxDate={inputMaxDate}
                value={value}
              />
              {
                (!inputMinDate || inputMinDate <= today) && (
                  <TodayButton onClick={onTodayClick}>Сегодня</TodayButton>
                )
              }
            </CalendarContent>
          </CalendarContainer>
        )
      }
    </Container>
  );
};
