// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const Container = styled.li`
  display: flex;
  align-items: center;
  padding: 0 19px;
  min-height: 40px;
  box-sizing: border-box;
  opacity: 0.8;
  cursor: pointer;

  :hover,
  :focus {
    background-color: ${colors.gray100};
  }
`;

const OptionContent = styled.span`
  display: inline-flex;
  align-items: center;
  flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media (max-width: 640px) {
		flex-direction: column;
    align-items: flex-start;
	}
`;

const OptionText = styled.span`
  font-family: inherit;
  font-size: 13px;
  flex: 1;
  line-height: 1.54;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

	@media (max-width: 640px) {
      max-width: 100%;
  }
    
  ${Container}:hover & {
    color: ${colors.magenta};
  }

  ${Container}:focus & {
    color: ${colors.magenta};
  }
`;

const AfterContent = styled.span`
	font-family: inherit;
	font-size: 13px;
	line-height: 1.54;
	white-space: nowrap;
  padding-left: 8px;

	@media (max-width: 640px) {
	  padding-left: 0;
	}

	@media (max-width: 480px) {
		font-size: 11px;
	}

	@media (max-width: 360px) {
		font-size: 10px;
	}
`;

export const Option = (props: { text: string, iconPlace?: React$Node, onClick: () => mixed }) => {
  const { text, iconPlace, afterContent, ...otherProps } = props;
  return (
    <Container tabIndex="0" aria-label={text} role="menuitemradio" {...otherProps}>
      {iconPlace}
      <OptionContent>
        <OptionText title={text}>
          {text}
        </OptionText>
        {
          afterContent && (
            <AfterContent>{afterContent}</AfterContent>
          )
        }
      </OptionContent>
    </Container>
  );
};
