import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  padding: ${props => (props.notPadding ? "0" : "40px 15px 70px")};
  margin: 0 auto;
  overflow-y: auto;
`;

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
`;

export const SectionTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const SectionBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;