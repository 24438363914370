import * as React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import chatStore from "../../../stores/chatStore";
import { MyView } from "./MyView";
import { PrimaryButton, PrimaryButtonContainer, PrimaryButtonText } from "../../buttons/PrimaryButton";
import { Select, Icon as SelectArrow } from "../../form-elements/Select";
import { AudioWaves } from "./AudioWaves";
import { Wrapper, RoundButton, ButtonsWrapper } from "./VideoCall";
import { IconClose } from "./icons/Close";
import { IconCamera } from "./icons/Camera";
import { IconSpeaker } from "./icons/Speaker";
import { IconMic } from "./icons/Mic";
import { IconMicOff } from "./icons/MicOff";
import { IconCameraOff } from "./icons/CameraOff";
import iconArrowDown from "./icons/arrow-down.svg";
import {HeadingH5} from "../../styleguide/typography";

const CallSettingsLayout = styled.div`
  display: grid;
  flex: 1;
  width: 100%;
  height: calc(100% - 100px);
  grid-template-columns: minmax(0, 3fr) minmax(0, 2fr);
  grid-template-rows: 1fr 48px;
  background: #361a2f;
  border-radius: 0 0 16px 16px;
  gap: 24px;
  padding: 60px;
  box-sizing: border-box;
  
  @media screen and (max-width: 1024px) {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto;
    overflow-y: auto;
  }
`;

const CallSettingsControls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
    order: 2;
  }
`;

const ReadyToCallBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  @media screen and (max-width: 1024px) {
    order: 3;
  }
`;

const ReadyToCallText = styled.span`
  color: var(--white, #FFF);
  text-align: center;
  font-family: "Graphik LCG";
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const CallButtonContainer = styled(PrimaryButtonContainer)`
  width: 332px;
  background: #fff;
  border: none;

  &:hover {
    background: #ccc;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
  }
`;

const CallButtonText = styled(PrimaryButtonText)`
  color: #5B3150;
`;

const SelectWrapper = styled.div`
  position: relative;
  flex: 1;
  background: #3E2337;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 240px;
  
  * {
    box-sizing: border-box;
  }
  
  svg {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  > div {
    width: 100%;
    background: transparent;
    
    ${SelectArrow} {
      width: 24px;
      height: 24px;
      background: url(${iconArrowDown});
    }
    
    div {
      background: transparent;
      box-shadow: none;
    }
    
    ul {
      white-space: nowrap;
      min-width: 100%;
      max-width: 100%;
      width: min-content;
      max-height: 140px;
      overflow-y: auto;
      border-radius: 4px;
      background-color: #3E2337;
      transform: translateY(4px);
      
      li {
        width: 100%;
        &[data-active="true"] {
          background: #836077;
        }
        p {
          width: 100%;
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        &:hover {
          background: #836077;
          
          p {
            color: #fff !important;
          }
        }
      }
    }
    
    button {
      padding-left: 48px;
      background: transparent;
      border: none;
      
      h6, span {
        display: block;
        width: 100%;
        color: #fff;
        opacity: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const CloseButton = styled.button`
  --svg-fill: #CAB5BE;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3E2337;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  top: 40px;
  right: 40px;
  border: none;
  cursor: pointer;
  
  &:hover {
    --svg-fill: #fff;
    background: #5B3150;
  }
`;

export const CallSettings = observer(() => {
  const [selectedCameraId, setSelectedCameraId] = React.useState(chatStore.currentDevices.cameraId);
  const [selectedSpeakerId, setSelectedSpeakerId] = React.useState(chatStore.currentDevices.speakerId);
  const [selectedMicId, setSelectedMicId] = React.useState(chatStore.currentDevices.micId);

  const handleCameraChange = (id) => {
    setSelectedCameraId(id);
    chatStore.setCameraId(id);
  };

  const handleSpeakerChange = (id) => {
    setSelectedSpeakerId(id);
    chatStore.setSpeakerId(id);
  };

  const handleMicChange = (id) => {
    setSelectedMicId(id);
    chatStore.setMicId(id);
  };

  const cameraItems = chatStore.videoDevices.reduce((acc, device, index) => {
    acc[device.id] = { name: device.name || `Камера ${index + 1}` };
    return acc;
  }, {});
  const speakersItems = chatStore.outputDevices.reduce((acc, device, index) => {
    acc[device.id] = { name: device.name || `Динамики ${index + 1}` };
    return acc;
  }, {});
  const micItems = chatStore.audioDevices.reduce((acc, device, index) => {
    acc[device.id] = { name: device.name || `Микрофон ${index + 1}` };
    return acc;
  }, {});

  return (
    <Wrapper minimize={chatStore.videoMinimized}>
      <CallSettingsLayout>
        <MyView/>
        <ReadyToCallBlock>
          {(chatStore.isAnonymousMode && !chatStore.allowAnonymousMode) ?
              <ReadyToCallText>Время приема ещё не началось или уже закончилось</ReadyToCallText>
              :
              <>
                <ReadyToCallText>Готовы начать прием?</ReadyToCallText>
                <PrimaryButton primaryButtonContainer={CallButtonContainer} primaryButtonText={CallButtonText} onClick={() => {
                  chatStore.closeCallSettingsScreen();
                  chatStore.callConference();
                }}>
                  Присоединиться к звонку
                </PrimaryButton>
              </>
          }
        </ReadyToCallBlock>
        <CallSettingsControls>
          <SelectWrapper>
            <IconCamera style={{
              '--svg-fill': '#fff'
            }}/>
            <Select showTitles placeholder="Камера" active={selectedCameraId} items={cameraItems} arrowStyle={{
              marginLeft: '16px'
            }} onSelect={handleCameraChange}/>
          </SelectWrapper>
          {
            !!chatStore.outputDevices.length && ( // В Firefox мы не можем выбирать динамики, поэтооу этого селекта нет
              <SelectWrapper>
                <IconSpeaker style={{
                  '--svg-fill': '#fff'
                }}/>
                <Select showTitles placeholder="Устройство вывода" active={selectedSpeakerId} arrowStyle={{
                  marginLeft: '16px'
                }}  items={speakersItems} onSelect={handleSpeakerChange}/>
              </SelectWrapper>
            )
          }
          <SelectWrapper>
            <IconMic style={{
              '--svg-fill': '#fff'
            }}/>
            <Select showTitles placeholder="Устройство ввода" active={selectedMicId} arrowStyle={{
              marginLeft: '16px'
            }}  items={micItems} onSelect={handleMicChange}/>
          </SelectWrapper>
          <AudioWaves/>
        </CallSettingsControls>
      </CallSettingsLayout>
      <ButtonsWrapper>
        <RoundButton
          disabled={chatStore.disabledVideoButton}
          $altered={!chatStore.videoEnabled}
          onClick={() => {
            chatStore.toggleCamera();
          }}>
          {chatStore.videoEnabled ? (
            <IconCamera style={{
              '--svg-fill': '#59304F'
            }}/>
          ) : (
            <IconCameraOff style={{
              '--svg-fill': '#fff'
            }}/>
          )}
        </RoundButton>

        <RoundButton
          $altered={chatStore.microphoneMuted}
          onClick={() => {
            chatStore.toggleMuteMicrophone();
          }}>
          {chatStore.microphoneMuted ? (
            <IconMicOff style={{
              '--svg-fill': '#fff'
            }}/>
          ) : (
            <IconMic style={{
              '--svg-fill': '#59304F'
            }}/>
          )}
        </RoundButton>
      </ButtonsWrapper>
      {!chatStore.isAnonymousMode &&
          <CloseButton onClick={() => {
            chatStore.closeCallSettingsScreen();
            chatStore.resetCurrentDevices();
          }}>
            <IconClose/>
          </CloseButton>
      }
    </Wrapper>
  );
});
