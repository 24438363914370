import { EMS_API_URL, COMMON_FETCH_OPTIONS } from "./api";
import { tokenStore } from "../stores/auth";

export const request = async ({ method, url, data, options }) => {
  const response = await fetch(`${EMS_API_URL}${url}`, {
    ...COMMON_FETCH_OPTIONS,
    ...options,
    method,
    body: data && JSON.stringify(data),
    headers: {
      ...COMMON_FETCH_OPTIONS.headers,
      ...(options && options.headers),
      Authorization: `JWT ${tokenStore.getState()}`
    }
  });

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return response.json().catch(() => {});
};
