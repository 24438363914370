export const medicalFileType = {
  medicalFile: "medicalFile",
  anonymous: "anonymous"
};

export const documentsTypes = {
  embryosProtocol: "embryos-protocol",
  cryoTransfer: "cryo-transfer",
  coverLetterWoman: "cover-letter-woman",
  coverLetterWomanCourier: "cover-letter-woman-courier",
  coverLetterPair: "cover-letter-pair",
  coverLetterPairCourier: "cover-letter-pair-courier"
};
