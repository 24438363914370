// @flow
import React from "react";
import styled from "styled-components";

const Container = styled.form`
  padding: 34px 48px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 768px) {
    padding: 24px;
  }
`;

const HeaderContainer = styled.div`
  align-self: flex-start;
`;

const ContentContainer = styled.div`
  margin-top: 49px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FieldContainer = styled.div`
  & + & {
    margin-top: 28px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 61px;
`;

export const NewTableFormLayout = (props: {
  header: React$Node,
  children: React$Node,
  button: React$Node
}) => {
  return (
    <Container>
      <HeaderContainer>{props.header}</HeaderContainer>
      <ContentContainer>
        {React.Children.map(props.children, (field, fieldId) => (
          <FieldContainer key={fieldId}>{field}</FieldContainer>
        ))}
      </ContentContainer>
      <ButtonContainer>{props.button}</ButtonContainer>
    </Container>
  );
};
