import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import { AccountTabs } from "./AccountTabs";
import accountStore from "../../stores/accountStore";
import { colors } from "../styleguide/colors";
import { getNounEndingByNumber } from "../../utils/getNounEndingByNumber";
import { DeleteButton } from "../decision-support/DeleteButton";
import removeIcon from "../decision-support/icons/remove.svg";
import removeHoverIcon from "../decision-support/icons/remove-hover.svg";
import { Container as DeleteButtonContainer } from "../decision-support/CommonHintedButton";
import { AccountDeleteServiceModal } from "./AccountDeleteServiceModal";
import { AccountAddServiceModal } from "./AccountAddServiceModal";
import { BaseLayout } from "../layouts/BaseLayout";
import { GreetingContentAccount } from "../greeting/GreetingContentAccount";

const DeleteButtonIcon = styled.div`
  width: 17px;
  height: 20px;
  background: url(${removeIcon}) no-repeat;
  background-size: contain;
  position: relative;
  right: 4px;

  ${DeleteButtonContainer}:hover & {
    background: url(${removeHoverIcon}) no-repeat;
    background-size: contain;
  }
`;

const ServiceTab = styled.div`
  display: flex;
  justify-content: center;
`;

const EmptyServices = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: ${colors.gray700};
  padding: 24px;
`;
const Container = styled.div`
  background: #fff;
  border-radius: 0 0 8px 8px;
  padding: 8px 24px;
  box-shadow: 0 7px 9px rgba(0, 0, 0, 0.03);
  overflow: hidden;

  @media screen and (max-width: 576px) {
    width: 100vw;
    height: 100vh;
  }
`;

const ServiceInfo = styled.div``;

const ServiceFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Services = styled.div`
  width: 100%;
`;

const Ellipse = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: ${colors.gray700};
  flex-shrink: 0;
  width: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ServiceText = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: ${colors.gray700};
`;

const ServiceName = styled.div`
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: ${colors.black};
  margin-bottom: 1px;
`;

const ServiceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid ${colors.gray100};
  width: 100%;

  &:last-child {
    border: none;
  }
`;

const DeleteButtonWrapper = styled.div`
  opacity: 0;
  transition: all 0.15s ease-in-out;

  ${ServiceWrapper}:hover & {
    opacity: 1;
  }
`;

const Service = observer(props => {
  const { service } = props;

  const deleteService = service => {
    accountStore.setServiceDeleteModalData(service);
    accountStore.setShowAccountDeleteServiceModal(true);
  };

  return (
    <ServiceWrapper>
      <ServiceInfo>
        <ServiceName>{service.name}</ServiceName>
        <ServiceFlex>
          <ServiceText>
            {service.is_online && "Видео"} {service.is_chat && "Чат"}{" "}
          </ServiceText>
          <Ellipse>•</Ellipse>
          <ServiceText>
            {service.is_online
              ? `${service.duration} ${getNounEndingByNumber(service.duration, [
                  "минут",
                  "минута",
                  "минут"
                ])}`
              : `${service.duration / 60 / 24} ${getNounEndingByNumber(service.duration / 60 / 24, [
                  "день",
                  "дня",
                  "дней"
                ])}`}{" "}
          </ServiceText>
          <Ellipse>•</Ellipse>
          <ServiceText>{service.price}₽</ServiceText>
        </ServiceFlex>
        <ServiceText>{service.description}</ServiceText>
      </ServiceInfo>
      <DeleteButtonWrapper>
        <DeleteButton
          onClick={() => deleteService(service)}
          containerNotPadding
          icon={DeleteButtonIcon}
          withoutTitle
        />
      </DeleteButtonWrapper>
    </ServiceWrapper>
  );
});

const ServicesTabContent = observer(() => {
  if (accountStore.isInitialized) {
    return (
      <ServiceTab>
        {accountStore.services.length === 0 ? (
          <EmptyServices>Пока вы не добавили ни одной услуги.</EmptyServices>
        ) : (
          <Services>
            {accountStore.services.map(service => (
              <Service key={service.id} service={service} />
            ))}
          </Services>
        )}
        {accountStore.showAccountDeleteServiceModal && (
          <AccountDeleteServiceModal
            hide={() => accountStore.setShowAccountDeleteServiceModal(false)}
          />
        )}
        {accountStore.showAccountAddServiceModal && (
          <AccountAddServiceModal hide={() => accountStore.setShowAccountAddServiceModal(false)} />
        )}
      </ServiceTab>
    );
  }
  return "";
});

export const AccountPage = observer(() => {
  accountStore.initialize();

  return (
    <BaseLayout greeting={<GreetingContentAccount />}>
      <AccountTabs />
      <Container>
        {accountStore.activeTab === "me" && <h1>Информация о себе</h1>}
        {accountStore.activeTab === "services" && <ServicesTabContent />}
        {accountStore.activeTab === "finance" && <h1>Информация о финансах</h1>}
      </Container>
    </BaseLayout>
  );
});
