import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: #7d7d7d;
  font-size: 15px;
`;

export const Text = styled.span`
  margin-left: 8px;
  margin-top: 4px;
`;

export const RotatedImage = styled.img`
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
