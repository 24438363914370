import React, { useCallback, useState } from "react";
import Select, { components as SelectComponents } from "react-select";

import { usePortal } from "../../../utils/usePortal";
import { useMyPatientsTableInput } from "./useMyPatientsTableInput";

import * as S from "./MyPatientsTableInputsSelect.styled";
import * as CommonInputStyles from "./MyPatientsTableInput.styled";

const Option = props => {
  return (
    <SelectComponents.Option {...props}>
      <S.SelectOption>{props.data.label}</S.SelectOption>
    </SelectComponents.Option>
  );
};

export const MyPatientsTableInputsSelect = props => {
  const { onUpdate, value, options, multiple } = useMyPatientsTableInput(props);

  const element = usePortal("table-elements-root");

  const [selectedValue, setSelectedValue] = useState(
    Array.isArray(value) ? value.map(item => ({ value: item, label: item })) : value || null
  );

  const handleUpdate = useCallback(
    item => {
      setSelectedValue(item);
      onUpdate(Array.isArray(item) ? item.map(({ value }) => value) : item.value);
    },
    [onUpdate]
  );

  return (
    <CommonInputStyles.Input style={{ minHeight: "inherit" }}>
      <Select
        isClearable
        isSearchable
        value={selectedValue}
        placeholder=""
        styles={S.styles}
        options={options}
        menuIsOpen
        isMulti={multiple}
        defaultMenuIsOpen
        menuPosition="fixed"
        autoFocus
        onChange={handleUpdate}
        menuPortalTarget={element}
        menuShouldScrollIntoView={false}
        noOptionsMessage={() => "Выбраны все значения"}
        components={{
          IndicatorSeparator: () => null,
          IndicatorsContainer: () => null,
          Option
        }}
      />
    </CommonInputStyles.Input>
  );
};
