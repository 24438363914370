// @flow
import styled from "styled-components";
import successIcon from "./icons/succsess.svg";
import errorIcon from "./icons/error.svg";

const InfoTitleIcon = styled.div`
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const RecommendedInfoTitleIcon = styled(InfoTitleIcon)`
  background-image: url(${successIcon});
`;

export const NotRecommendedInfoTitleIcon = styled(InfoTitleIcon)`
  background-image: url(${errorIcon});
`;
