import React from "react";
import moment from "moment";
import { SERVER_DATE_FORMAT } from "../../utils/dates";
import {
  ReportPuncture,
  ReportTransfer
  // ReportOocyteDonationPrograms,
  // ReportProgramsWithDonorSperm,
  // ReportProgramsWithPGT,
  // ReportProgramsWithSM,
  // ReportPregnancyOutcomes,
  // ReportMariborConsensus
} from "./reports";

export const REPORTS = [
  {
    name: "reportPuncture",
    label: "Пункция",
    render: params => <ReportPuncture {...params} />
  },
  {
    name: "reportTransfer",
    label: "Перенос",
    render: params => <ReportTransfer {...params} />
  }
  // {
  //   name: "reportOocyteDonationPrograms",
  //   label: "Программы донорства ооцитов",
  //   render: params => <ReportOocyteDonationPrograms {...params} />
  // },
  // {
  //   name: "reportProgramsWithDonorSperm",
  //   label: "Программы с донорской спермой",
  //   render: params => <ReportProgramsWithDonorSperm {...params} />
  // },
  // {
  //   name: "reportProgramsWithPGT",
  //   label: "Программы с ПГТ",
  //   render: params => <ReportProgramsWithPGT {...params} />
  // },
  // {
  //   name: "reportProgramsWithSM",
  //   label: "Программы с СМ",
  //   render: params => <ReportProgramsWithSM {...params} />
  // },
  // {
  //   name: "reportPregnancyOutcomes",
  //   label: "Исходы беременностей",
  //   render: params => <ReportPregnancyOutcomes {...params} />
  // },
  // {
  //   name: "reportMariborConsensus",
  //   label: "Мариборский консенсус",
  //   render: params => <ReportMariborConsensus {...params} />
  // }
];

export const reportDateGTE = moment()
  .subtract(1, "month")
  .format(SERVER_DATE_FORMAT);
export const reportDateLTE = moment().format(SERVER_DATE_FORMAT);
export const financeTypeOptions = ["ОМС", "СФ", "ЧК"];
export const besplodie1Options = ["Первичное", "Вторичное"];
export const besplodie2Options = [
  "Трубный фактор",
  "Мужской фактор",
  "Ановуляция",
  "Маточный фактор",
  "Бесплодие неясного генеза",
  "Возрастной фактор",
  "Эндометриоз",
  "Снижение овариального резерва"
];
export const besplodie3Options = [
  "Сексуальная дисфункция",
  "Невынашивание беременности",
  "Отсутствие полового партнера",
  "Носительство мутации",
  "Отсроченное материнство",
  "ВИЧ-инфекция",
  "Гепатит В",
  "Гепатит С"
];
