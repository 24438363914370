// @flow
import type { TFeedback, TFeedback_id } from "../../domain/entities/Feedback";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import usePromise from "react-fetch-hook/usePromise";
import { useTrigger } from "react-use-trigger";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import apiv2 from '../../apiv2';

export const FeedbackProvider = (props: {
  id: ?TFeedback_id,
  children: (?TFeedback) => React$Node
}) => {
  const token = useStore(tokenStore);
  const { id, children } = props;
  const patientAppointmentsTrigger = useTrigger(PatientAppointmentsRefreshTrigger);

  const result = usePromise(
    () =>
      token && id
        ? apiv2.feedback.getFeedback(id)
        : Promise.resolve(null),
    [token, id, patientAppointmentsTrigger]
  );

  return children(result.data);
};
