import React from "react";
import styled, { css } from "styled-components";
import dayjs from "dayjs";
import { observer } from 'mobx-react';
import { useMediaQuery } from "@mui/material";

import {
  ItemContainer,
  ItemImageContainer,
  ItemInnerContainer,
  ItemTitleContainer,
  ItemTitle,
  ItemTime,
  ItemContentContainer,
  ItemContent
} from './ItemComponents';
import { SenderAvatar } from './ChatAvatars';
import { EditIcon } from '../icons/Edit';
import { TrashIcon } from '../icons/Trash';
import chatStore, {ChatMessageType, MessageStatus} from '../../../stores/chatStore';
import { getPatientFullName, getPatientLastNameWithInitials } from "../../../domain/services/patient";
import authStore from '../../../stores/authStore';
import { MessageContent } from './MessageContent';

import MessageDeliveredIcon from "../../../assets/icons/chat/delivered.inline.svg";
import MessageReadIcon from "../../../assets/icons/chat/read.inline.svg";
import { FEATURE, useApplicationFeatureValue } from "../../../utils/applicationFeatures";


const MessageItemButtons = styled.div`
  --bg-color: ${props => props.isEditing ? "#FFF5F1" : "#FFF"};
  display: none;
  position: absolute;
  top: 8px;
  right: 24px;
  gap: 16px;
  padding-left: 32px;
  background: linear-gradient(to right, transparent, var(--bg-color, #fff) 16px, var(--bg-color, #fff));
`;

const MessageItemButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  --svg-fill: #4A4A4A;
  
  &:hover {
    --svg-fill: #DE008E;
  }
`;

const MessageItemContainer = styled(ItemContainer)`
  position: relative;
  gap: 16px;
  padding: ${props => !props.hasImage ? '0 24px 10px 80px' : '10px 24px'};
  ${props => !props.hasTime ? css`padding-bottom: 8px` : ''};
  ${props => props.isEditing ? css`background: #FFF5F1` : ''};
  
  &:hover {
    ${MessageItemButtons} {
      display: flex;

      top: ${props => !props.hasImage ? '0' : '10px'};
    }
  }
`;

const MessageItemTime = styled(ItemTime)`
  align-self: flex-end;
`;

const MessageErrorContainer = styled.div`
  margin-top: 8px;
  color: #F2576A;
  
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const MessageIndicators = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;

const ReadIndicatorContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-end;
    height: 23px;
`;

export const MessageItem = observer(({
  message,
  withoutAvatar,
  withoutTitle,
  withoutTime,
  onDelete
}) => {
  const IVB__DOCTOR__ID = useApplicationFeatureValue(FEATURE.IVB__DOCTOR__ID)
  const IVB__DOCTOR__NAME = useApplicationFeatureValue(FEATURE.IVB__DOCTOR__NAME)
  const PEDIATRICIAN__DOCTOR__ID = useApplicationFeatureValue(FEATURE.PEDIATRICIAN__DOCTOR__ID)
  const PEDIATRICIAN__DOCTOR__NAME = useApplicationFeatureValue(FEATURE.PEDIATRICIAN__DOCTOR__NAME)
  const isMobile = useMediaQuery('(max-width: 576px)');
  const currentUserId = authStore.user.id;
  const { id, _type, _status, sender, created_at, is_read, active_sender } = message;
  const createdMoment = dayjs(created_at);

  const checkActiveSenderIdWithFeature = parseInt(active_sender?.id) === parseInt(IVB__DOCTOR__ID)
    || parseInt(active_sender?.id) === parseInt(PEDIATRICIAN__DOCTOR__ID);

  const titleActiveSenderIdWithFeature = () => {
    if (parseInt(active_sender?.id) === parseInt(IVB__DOCTOR__ID)) {
      if (isMobile) {
        return `${IVB__DOCTOR__NAME} ${getPatientLastNameWithInitials(active_sender)}`
      } else {
        return `${IVB__DOCTOR__NAME} ${getPatientFullName(active_sender)}`
      }
    }
    if (parseInt(active_sender?.id) === parseInt(PEDIATRICIAN__DOCTOR__ID)) {
      if (isMobile) {
        return `${PEDIATRICIAN__DOCTOR__NAME} ${getPatientLastNameWithInitials(active_sender)}`
      } else {
        return `${PEDIATRICIAN__DOCTOR__NAME} ${getPatientFullName(active_sender)}`
      }
    }
  }

  let titleText = !sender ? 'Виртуальный помощник Ася' : (active_sender && checkActiveSenderIdWithFeature) ? titleActiveSenderIdWithFeature() : getPatientFullName(sender);
  if (isMobile && sender) {
    titleText = (active_sender && checkActiveSenderIdWithFeature) ? titleActiveSenderIdWithFeature() : getPatientLastNameWithInitials(sender);
  }
  const isMessageEditing = id === chatStore.editingMessage?.id;
  const currentUserMessage = sender && sender.id === currentUserId;
  const canEditMessage = currentUserMessage &&
                         _type !== ChatMessageType.APPOINTMENT &&
                         _type !== ChatMessageType.FILE &&
                         _type !== ChatMessageType.IMAGE;

  return (
    <MessageItemContainer data-message-id={id} hasImage={!withoutAvatar} hasTime={!withoutTime} isEditing={isMessageEditing}>
      {
        !withoutAvatar && (
          <ItemImageContainer>
            <SenderAvatar sender={sender}/>
          </ItemImageContainer>
        )
      }
      <ItemInnerContainer>
        {
          !withoutTitle && (
            <ItemTitleContainer>
              <ItemTitle title={titleText} isAccent={!sender}>{titleText}</ItemTitle>
            </ItemTitleContainer>
          )
        }
        <ItemContentContainer>
          <ItemContent>
            <MessageContent message={message}/>
          </ItemContent>
          <MessageIndicators>
            {
              currentUserMessage ?
                (
                  <ReadIndicatorContainer>
                    {
                      is_read ? (
                        <MessageReadIcon/>
                      ) : (
                        <MessageDeliveredIcon/>
                      )
                    }
                  </ReadIndicatorContainer>
                ) : null
            }
            {
              !withoutTime && (
                <MessageItemTime>
                  {createdMoment.format("HH:mm")}
                </MessageItemTime>
              )
            }
          </MessageIndicators>
        </ItemContentContainer>
        {
          _status === MessageStatus.ERROR && (
            <MessageErrorContainer>
              Не удалось отправить сообщение.{" "}
              <span onClick={async () => {
                await chatStore.resendMessage(message);
              }}>
                Попробовать еще раз
              </span>
            </MessageErrorContainer>
          )
        }
      </ItemInnerContainer>
      <MessageItemButtons isEditing={isMessageEditing}>
        {
          canEditMessage && (
            <MessageItemButton onClick={() => {
              chatStore.setEditingMessage(id);
            }}>
              <EditIcon/>
            </MessageItemButton>
          )
        }
        {
          currentUserMessage && (
            <MessageItemButton onClick={async () => {
              await onDelete?.(id);
            }}>
              <TrashIcon/>
            </MessageItemButton>
          )
        }
      </MessageItemButtons>
    </MessageItemContainer>
  );
});
