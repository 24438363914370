import React, { useCallback, useState } from "react";
import moment from "moment";

import { Calendar } from "./Calendar";

import * as S from "./styled/RangeClanedar.styled";

export const periods = {
  today: {
    title: "Сегодня",
    dates: () => [moment().toDate(), moment().toDate()]
  },
  week: {
    title: "Неделя",
    dates: () => [
      moment().toDate(),
      moment()
        .add(1, "w")
        .toDate()
    ]
  },
  twoWeeks: {
    title: "Две недели",
    dates: () => [
      moment().toDate(),
      moment()
        .add(2, "w")
        .toDate()
    ]
  },
  month: {
    title: "Месяц",
    dates: () => [
      moment().toDate(),
      moment()
        .add(1, "M")
        .toDate()
    ]
  },
  threeMonths: {
    title: "Три месяца",
    dates: () => [
      moment().toDate(),
      moment()
        .add(3, "M")
        .toDate()
    ]
  },
  halfYear: {
    title: "Полгода",
    dates: () => [
      moment().toDate(),
      moment()
        .add(6, "M")
        .toDate()
    ]
  },
  year: {
    title: "Год",
    dates: () => [
      moment().toDate(),
      moment()
        .add(1, "y")
        .toDate()
    ]
  }
};

export const RangeCalendar = ({
  period = null,
  startDate = null,
  endDate = null,
  onSetDateRange,
  onResetDateRange,
  onSelectPeriod
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState(period);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const [mouseOverDate, setMouseOverDate] = useState(null);

  const [leftMonthStartDate, setLeftMonthStartDate] = useState(
    moment()
      .startOf("M")
      .toDate()
  );

  const [rightMonthStartDate, setRightMonthStartDate] = useState(
    moment()
      .add(1, "M")
      .startOf("M")
      .toDate()
  );

  const handleSetSelectedDate = date => {
    if (selectedPeriod || (selectedStartDate && selectedEndDate)) {
      setSelectedPeriod(null);
      setSelectedEndDate(null);
      setSelectedStartDate(date);
    } else if (!selectedStartDate) {
      setSelectedStartDate(date);
    } else if (moment(selectedStartDate).isSame(date, "d")) {
      setSelectedEndDate(date);
      onSetDateRange([selectedStartDate, date]);
    } else if (moment(date).isBefore(selectedStartDate, "d")) {
      setSelectedStartDate(date);
      setSelectedEndDate(selectedStartDate);
      onSetDateRange([date, selectedStartDate]);
    } else {
      setSelectedEndDate(date);
      onSetDateRange([selectedStartDate, date]);
    }
  };

  const handleSelectPeriod = useCallback(
    value => {
      const [startDate, endDate] = periods[value].dates();

      setSelectedPeriod(value);

      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);

      onSelectPeriod(value);
    },
    [onSelectPeriod]
  );

  const handleReset = useCallback(() => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setMouseOverDate(null);
    setSelectedPeriod(null);
    onResetDateRange();
  }, [onResetDateRange]);

  const handlePrev = () => {
    setLeftMonthStartDate(moment(leftMonthStartDate).subtract("1", "M"));
    setRightMonthStartDate(moment(rightMonthStartDate).subtract("1", "M"));
  };

  const handleNext = () => {
    setLeftMonthStartDate(moment(leftMonthStartDate).add("1", "M"));
    setRightMonthStartDate(moment(rightMonthStartDate).add("1", "M"));
  };

  return (
    <S.Container>
      <S.Calendars onMouseOut={() => setMouseOverDate(null)}>
        <Calendar
          prev
          range
          monthStartDate={leftMonthStartDate}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          mouseOverDate={mouseOverDate}
          onMouseOverDate={setMouseOverDate}
          onSetSelectedDate={handleSetSelectedDate}
          onPrev={handlePrev}
        />
        <Calendar
          next
          range
          monthStartDate={rightMonthStartDate}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          mouseOverDate={mouseOverDate}
          onMouseOverDate={setMouseOverDate}
          onSetSelectedDate={handleSetSelectedDate}
          onNext={handleNext}
        />
      </S.Calendars>

      <S.Periods>
        {Object.keys(periods)
          .map(key => ({ key, ...periods[key] }))
          .map(({ key, title }) => (
            <S.Period
              key={key}
              active={key === selectedPeriod}
              onClick={() => handleSelectPeriod(key)}>
              {title}
            </S.Period>
          ))}
        <S.ResetDate onClick={handleReset}>Сбросить дату</S.ResetDate>
      </S.Periods>
    </S.Container>
  );
};
