import React from 'react';
import styled from "styled-components";

import { InputText } from "../styleguide/typography";

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 42px;
`;

export const ErrorContainer = styled.div`
  margin-top: 9px;
`;

export const ErrorText = (props) => {
  const modifiers = ["error"];

  if (props.small) {
    modifiers.push("small");
  }

  return <InputText {...props} modifiers={modifiers} />;
};