// @flow
import * as React from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { HeadingH4, HeadingH6 } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import CloseIcon from "./icons/close.svg";

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 22px 22px 22px 24px;
  background-color: ${rgba(colors.gray100, 0.3)};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Close = styled.button`
  border: none;
  background: url(${CloseIcon}) no-repeat center;
  background-size: 18px 18px;
  width: 28px;
  height: 28px;

  cursor: pointer;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  margin-left: 7px;
`;

export const SidebarHeader = (props: {
  title: string,
  onClose: () => mixed,
  icon?: React.Node,
  breadcrumbs?: React.Node
}) => {
  return (
    <Container>
      <InnerContainer>
        {props.breadcrumbs && props.breadcrumbs}
        <TextContainer>
          {props.breadcrumbs ? (
            <HeadingH6>{props.title}</HeadingH6>
          ) : (
            <HeadingH4>{props.title}</HeadingH4>
          )}
          {props.icon && <IconContainer>{props.icon}</IconContainer>}
        </TextContainer>
      </InnerContainer>
      <Close onClick={props.onClose} />
    </Container>
  );
};
