import React from "react";
import styled from "styled-components";
import { GeneralSchedulePlaceholderCard } from "./GeneralSchedulePlaceholderCard";
import { GeneralSchedulePlaceholderCardNew } from "./GeneralSchedulePlaceholderCardNew";

const GeneralSchedulePlaceholderContentNew = styled.div`
  display: flex;
  padding-left: 50px;
`;

export const GeneralSchedulePlaceholderNew = () => {
  return (
    <GeneralSchedulePlaceholderContentNew>
      {new Array(7).fill().map((value, index) => (
        <GeneralSchedulePlaceholderCardNew key={index} />
      ))}
    </GeneralSchedulePlaceholderContentNew>
  );
};
