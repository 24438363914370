import React, { useRef, useState } from "react";
import { useClickAway } from "react-use";

import { eventTypeFieldName } from "./types";

import * as S from "./styled/EventTypeFilter.styled";

export const EventTypeFilter = ({ onChange, value, outcomes }) => {
  const rootRef = useRef();

  const [optionsOpen, setOptionsOpen] = useState();

  useClickAway(rootRef, () => setOptionsOpen(false));

  const options =
    outcomes &&
    outcomes.fields
      .find(({ name }) => name === eventTypeFieldName)
      ?.possible_values.map(value => ({ id: value, name: value }));

  return (
    <S.Root ref={rootRef}>
      <S.Value focused={optionsOpen} hasValue={value !== null} onClick={() => setOptionsOpen(true)}>
        Тип события
        {value !== null && `: ${options.find(({ id }) => id === value).name}`}
      </S.Value>
      {options && optionsOpen && (
        <S.Options open>
          {options.map(({ id, name }) => (
            <S.Option
              onClick={() => {
                onChange(id);
                setOptionsOpen(false);
              }}
              key={id}>
              {name}
              {value === id && <S.CheckIcon />}
            </S.Option>
          ))}
        </S.Options>
      )}
    </S.Root>
  );
};
