// @flow
import * as React from "react";

import styled from "styled-components";
import {Fieldset} from "../form/Fieldset";
import {DefaultInput} from "../form-elements/DefaultInput";
import {useState} from "react";
import {HeadingH2} from "../styleguide/typography";
import {PrimaryButton} from "../buttons/PrimaryButton";
import {createAppointmentCreationOptionsOutsideSchedule} from "../../domain/value-objects/appointment";
import {useStore} from "effector-react";
import {logout, tokenStore} from "../../stores/auth";
import {Container, ContainerShadow} from "./checkupsStyled"
import {FEATURE, useApplicationFeatureValue} from "../../utils/applicationFeatures";
import apiv2 from "../../apiv2";
import ExitImg from "../header/exit.svg";
import ExitColorImg from "../header/exit-color.svg";
import LogoImg from "../../components/login/icons/logo.svg";

const ContainerPage = styled(Container)`
  button {
    margin-top: 30px;
  }
`;

const Logo = styled.div`
  width: 245px;
  height: 90px;
  background: url(${LogoImg}) no-repeat;
  background-size: contain;
  margin-bottom: 40px;
`;

const Title = styled(HeadingH2)`
  margin-bottom: 30px;
  text-align: center;
`;

const LogoutContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

const Exit = styled.button`
  width: 21px;
  height: 21px;
  margin-top: 0!important;

  background: url(${ExitImg}) no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;

  justify-self: end;
  grid-area: exit;

  transition: all 0.15s ease-in-out;

  :hover {
    background-image: url(${ExitColorImg});
  }
`;


export const CheckupsPage = (props: {||}) => {

  const token = useStore(tokenStore);
  const [numberCard, setNumberCard] = useState('');
  const questionnaireLayoutId = useApplicationFeatureValue(FEATURE.HC_QUESTIONNAIRE_LAYOUT_ID)

  const openCard = async () => {
    try {
      const medicalFile = await apiv2.medicalFiles.getByNumber(numberCard);
      const creationOptions = createAppointmentCreationOptionsOutsideSchedule({
        medical_file: medicalFile.id,
        layout: questionnaireLayoutId,
      });
      const result = await apiv2.appointments.createAppointment(creationOptions);
      window.QUEST_MEDFILE_NUMBER = numberCard;
      props.history.push(`/quest/${result.id}`);
    } catch (e) {
      console.log(e);
    }
  }

  const handleExit = async () => {
    try {
      logout();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ContainerPage>
      <ContainerShadow>
        <Logo />
        <Title>Введите номер медкарты</Title>
        <Fieldset legend="">
          <DefaultInput placeholder="100002" value={numberCard} handleChange={setNumberCard} handleInput={setNumberCard} />
        </Fieldset>
        <PrimaryButton onClick={openCard}>Открыть карту</PrimaryButton>
      </ContainerShadow>
      <LogoutContainer>
        <Exit onClick={handleExit} />
      </LogoutContainer>
    </ContainerPage>
  );
};
