//@flow
import * as React from "react";
import styled from "styled-components";

import {
  AppointmentHistoryTableHead,
  type AppointmentHistoryColumnType
} from "./AppointmentHistoryTableHead";

const Table = styled.table`
  width: 100%;
  margin-top: 8px;
`;

export const AppointmentHistoryTable = (props: {|
  columns: AppointmentHistoryColumnType,
  children: React.Node
|}) => (
  <Table>
    <AppointmentHistoryTableHead columns={props.columns} />
    <tbody>{props.children}</tbody>
  </Table>
);
