import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import useReactRouter from "use-react-router";
// import { useFolderPrintData } from "./useFolderPrintData";
import { useBeforePrint } from "../appointment-print/useBeforePrint";
import { useAfterPrint } from "../appointment-print/useAfterPrint";
import { PNormal } from "../styleguide/typography";
import { AppointmentPrintLayout } from "../appointment-print/AppointmentPrintLayout";
import apiv2 from '../../apiv2';

export const formatDateTime = date => {
  return moment
    .utc(date)
    .locale("ru")
    .format("D MMMM YYYY, HH:mm");
};

export const formatDate = date => {
  return moment
    .utc(date)
    .locale("ru")
    .format("D MMMM YYYY");
};

const FormattedText = styled.div`
  font-size: 13px;
  line-height: 21px;
  word-wrap: break-word;

  table td {
    vertical-align: middle;
  }

  p {
    margin-block-start: 13px;
    margin-block-end: 13px;
  }

  strong,
  b {
    font-weight: 700 !important;
  }
`;

export const AgreementPrintContent = ({ data }) => {
  if (!data) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  const values = <FormattedText dangerouslySetInnerHTML={{ __html: data.file }} />;

  return (
    <React.Fragment>
      <AppointmentPrintLayout values={values} width="100%" />
    </React.Fragment>
  );
};

export const AgreementPrintPage = () => {
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();

  // const { folderData } = useFolderPrintData();
  const [agreement, setAgreement] = React.useState(null);

  React.useEffect(() => {
    apiv2.appointments.getFolderAgreement(params.id, params.agreementId).then(data => {
      setAgreement(data);
    });
  }, [params.id, params.agreementId, token]);

  useBeforePrint(agreement !== null || false);
  useAfterPrint(`/appointment/folder/${params.id}/agreements`);

  return <AgreementPrintContent data={agreement} />;
};
