import React, { useMemo } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import * as yup from "yup";
import {
  DynamicForm,
  handlerOnSubmitHiddenButton
} from "../../../components/common/dynamic-form/DynamicForm";
import Button from "@mui/material/Button";
import corporateLoginStore, { STAGES } from "../../stores/corporateLoginStore";
import { ActionsContainer, ContentContainer, FormContainer } from "./CorporateLogin.styled";
import { Typography } from "@mui/material";

export const CorporateRegistrationForm = observer(() => {
  const data = useMemo(() => {
    return corporateLoginStore.registrationFormData;
  }, [corporateLoginStore.registrationFormData]);

  const validateSchema = yup.object({
    last_name: yup.string().required("Обязательное поле"),
    first_name: yup.string().required("Обязательное поле"),
    middle_name: yup.string().required("Обязательное поле"),
    username: yup.string().required("Обязательное поле"),
    password1: yup
      .string()
      .required("Обязательное поле")
      .test(
        "passwordValidation",
        "Пароль должен содержать не менее 4 символов",
        value => value.length >= 4
      ),
    password2: yup
      .string()
      .required("Обязательное поле")
      .test(
        "checkPassword",
        "Пароли не совпадают",
        value => corporateLoginStore.registrationFormData.password1 === value
      )
  });

  const fields = useMemo(
    () => [
      {
        type: "text",
        name: "last_name",
        label: "Фамилия",
        xs: 12,
        size: "small",
        disabled: corporateLoginStore.isLoading,
        enableInstalledShrink: true
      },
      {
        type: "text",
        name: "first_name",
        label: "Имя",
        xs: 12,
        size: "small",
        disabled: corporateLoginStore.isLoading,
        enableInstalledShrink: true
      },
      {
        type: "text",
        name: "middle_name",
        label: "Отчество",
        xs: 12,
        size: "small",
        disabled: corporateLoginStore.isLoading,
        enableInstalledShrink: true
      },
      {
        type: "text",
        name: "username",
        label: "Логин",
        xs: 12,
        size: "small",
        disabled: corporateLoginStore.isLoading,
        subInfo: "Придумайте логин",
        enableInstalledShrink: true
      },
      {
        type: "password",
        name: "password1",
        label: "Пароль",
        xs: 12,
        size: "small",
        disabled: corporateLoginStore.isLoading,
        subInfo: "Придумайте пароль",
        enableInstalledShrink: true
      },
      {
        type: "password",
        name: "password2",
        label: "Подтвердите пароль",
        xs: 12,
        size: "small",
        disabled: corporateLoginStore.isLoading,
        enableInstalledShrink: true
      }
    ],
    [corporateLoginStore.isLoading]
  );

  const watcherOnChangeField = (updatedData, reset, changedKey) => {
    if (updatedData[changedKey] === corporateLoginStore.registrationFormData[changedKey]) {
      return;
    }
    corporateLoginStore.changeFormValue(updatedData);
  };
  const resetErrors = ({ data, errors, clearErrors }) => {
    if (errors?.password2?.type === "checkPassword" && data.password1 === data.password2) {
      clearErrors("password2");
    }
  };
  const buttonHandlerOnSubmit = () => handlerOnSubmitHiddenButton();
  const handlerOnSubmit = async (params, setError) => {
    if (!_.isEqual(corporateLoginStore.registrationFormData, params)) {
      corporateLoginStore.changeFormValue(params);
    }
    await corporateLoginStore.handleOnRegistration();
    if (corporateLoginStore.errorMessage) {
      fields.forEach(({ name }) => setError(name, { type: "incorrectData" }));
    }
  };

  return (
    <ContentContainer>
      <FormContainer>
        <DynamicForm
          data={data}
          fields={fields}
          validateSchema={validateSchema}
          submitHandler={handlerOnSubmit}
          changeHandler={watcherOnChangeField}
          resetErrorsHandler={resetErrors}
          enableHiddenButton
          hiddenButtonSave
          hiddenButtonCancel
          debounceTime={0}
        />
        {!!corporateLoginStore.errorMessage && (
          <Typography color="#D32F2F">{corporateLoginStore.errorMessage}</Typography>
        )}
      </FormContainer>
      <ActionsContainer>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => corporateLoginStore.changeStage(STAGES.AUTHORIZATION)}
          disabled={corporateLoginStore.isLoading}>
          Назад
        </Button>
        <Button
          variant="contained"
          onClick={buttonHandlerOnSubmit}
          disabled={corporateLoginStore.isLoading}>
          Далее
        </Button>
      </ActionsContainer>
    </ContentContainer>
  );
});
