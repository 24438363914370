// @flow
import * as React from "react";
import { useStore } from "effector-react";
import { hospitalPatientsCounter } from "../hospital-table/HospitalTable";
import { currentUser } from "../../stores/auth";
import { Greeting } from "./Greeting";

export const GreetingContentHospital = () => {
  const user = useStore(currentUser);
  const numberOfPatients = useStore(hospitalPatientsCounter);

  if (user && user.role === "doctor") {
    return (
      <Greeting
        firstName={user.doctor.first_name}
        lastName={user.doctor.last_name}
        avatar={user.doctor.photo}
        numberOfPatients={numberOfPatients}
        page="hospital"
      />
    );
  } else if (user && user.role === "post_nurse") {
    return (
      <Greeting
        firstName={user.post_nurse.first_name}
        lastName={user.post_nurse.last_name}
        avatar={user.post_nurse.photo}
        numberOfPatients={numberOfPatients}
        page="hospital"
      />
    );
  } else {
    return null;
  }
};
