// @flow
import * as React from "react";
import { useContext } from "react";

import { ScrollContext } from "../scroll-detector/ScrollDetector";
import { Box } from "@mui/material";

export const AsideAppointmentList = (props: { children: React.Node }) => {
  const { setTableRef } = useContext(ScrollContext);
  return <Box sx={{ height: "100%" }} ref={setTableRef}>{props.children}</Box>;
};
