// @flow
import { createEvent } from "effector";
import type { Checklist, TChecklistGuid } from "../domain/entities/Checklist";
import * as R from "ramda";
import { appendIfNot } from "../utils/appendIfNot";
import type { Store } from "effector";
import { removeComment, setComment } from "./comment";
import { removeDateRange, setDateRange } from "./dateRange";
import { reorder } from "../utils/reorder";
import { changeCount } from "./count";
import _ from "lodash";

const addChecklist = (value: Checklist) => appendIfNot(value, R.propEq("guid", value.guid));

const remove = guid => R.filter(checklist => checklist.guid !== guid);

const rename = ({ guid, newName }) =>
  R.map(item => (R.propEq("guid", guid)(item) ? { ...item, name: newName } : item));

const toggleChecklist = (guid: TChecklistGuid) =>
  R.map(checklist =>
    checklist.guid === guid ? R.over(R.lensProp("is_checked"), R.not, checklist) : checklist
  );

const setChecklistIsChecked = ({ guid, value }) =>
  R.map(checklist =>
    checklist.guid === guid ? R.over(R.lensProp("is_checked"), () => value, checklist) : checklist
  );

const reorderChecklist = (list, { start, end }) => reorder(start, end)(list);

const updateState = (state, newState) => newState;

const transformToHandler = R.compose(
  R.flip,
  // $FlowFixMe
  R.uncurryN(2)
);

export const initializeChecklistStore = (store: Store<Array<Checklist>>) => {
  const updateStateEvent = createEvent<Checklist[]>();
  const addChecklistEvent = createEvent<Checklist>();
  const removeEvent = createEvent<TChecklistGuid>();
  const renameEvent = createEvent<{ guid: TChecklistGuid, newName: string }>();
  const toggleChecklistEvent = createEvent<TChecklistGuid>();
  const setChecklistIsCheckedEvent = createEvent<{ value: boolean, guid: TChecklistGuid }>();
  const setChecklistComment = createEvent<{ value: string, id: TChecklistGuid }>();
  const changeChecklistCount = createEvent<{ value: string, id: TChecklistGuid }>();
  const removeChecklistComment = createEvent<TChecklistGuid>();
  const reorderEvent = createEvent<{ start: number, end: number }>();
  const setChecklistDateRange = createEvent<{ value: [string, string], id: TChecklistGuid }>();
  const removeChecklistDateRange = createEvent<TChecklistGuid>();

  store.on(
    addChecklistEvent.filter({
      fn: ({ name }) => (name && name.length > 0) || false
    }),
    transformToHandler(addChecklist)
  );
  store.on(removeEvent, transformToHandler(remove));
  store.on(renameEvent, transformToHandler(rename));
  store.on(toggleChecklistEvent, transformToHandler(toggleChecklist));
  store.on(setChecklistIsCheckedEvent, transformToHandler(setChecklistIsChecked));
  store.on(setChecklistComment, transformToHandler(setComment("guid")));
  store.on(changeChecklistCount, transformToHandler(changeCount("guid")));
  store.on(removeChecklistComment, transformToHandler(removeComment("guid")));
  store.on(setChecklistDateRange, transformToHandler(setDateRange("guid")));
  store.on(removeChecklistDateRange, transformToHandler(removeDateRange("guid")));
  store.on(reorderEvent, reorderChecklist);
  store.on(updateStateEvent, updateState);

  return {
    store,
    updateState: updateStateEvent,
    addChecklist: addChecklistEvent,
    remove: removeEvent,
    rename: renameEvent,
    toggleChecklist: toggleChecklistEvent,
    setChecklistIsChecked: setChecklistIsCheckedEvent,
    setComment: setChecklistComment,
    changeCount: changeChecklistCount,
    removeComment: removeChecklistComment,
    reorder: reorderEvent,
    setDateRange: setChecklistDateRange,
    removeDateRange: removeChecklistDateRange
  };
};

const itemsProp = R.lensProp("items");

export const createChildrenStore = (
  guid: TChecklistGuid,
  defaultValues?: Array<Checklist>,
  isDependent?: boolean
) => (store: Store<Array<Checklist>>): Store<Array<Checklist>> => {
  const getChildItems = R.compose(
    R.defaultTo(defaultValues),
    R.view(itemsProp),
    R.find(R.propEq("guid", guid))
  );

  const derivedStore = store.map(getChildItems);
  store.on(derivedStore, (state, derivedStoreValue) => {
    const result = R.map(
      checklist =>
        checklist.guid === guid
          ? R.compose(
            isDependent
              ? R.over(
                R.lensProp("is_checked"),
                isChecked =>
                  isChecked || !!R.find(R.propEq("is_checked", true), derivedStoreValue)
              )
              : value => value,
            R.set(itemsProp, derivedStoreValue)
          )(checklist)
          : checklist,
      state
    );
    if (_.isEqual(state, result)) {
      return state;
    }
    return result;
  });
  if (isDependent) {
    derivedStore.on(store, (derivedStoreState, storeState) => {
      const isChecked = R.compose(
        R.view(R.lensProp("is_checked")),
        R.find(R.propEq("guid", guid))
      )(storeState);
      if (!isChecked && R.find(R.view(R.lensProp("is_checked")), derivedStoreState)) {
        return R.map(R.set(R.lensProp("is_checked"), false), derivedStoreState);
      }
    });
  }

  return derivedStore;
};
