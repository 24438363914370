// @flow
import * as React from "react";
import { ScheduleDay } from "../schedule/ScheduleDay";
import { ScheduleDays } from "./ScheduleDays";
import { useStore } from "effector-react";
import type { ScheduleReserved } from "../../domain/entities/Schedule";
import { findFirstActiveScheduleDayIndex } from "../../domain/services/schedule";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  changeDataDeleteAppointment, changeDataDeleteSchedule, changeIsAddEmptyDate,
  dataStore,
  deleteAppointmentAndUpdateView,
  deleteScheduleAndUpdateView
} from "./scheduleStore";
import { useScheduleUpdates } from "./useScheduleUpdate";
import useReactRouter from "use-react-router";
import { setLastScheduleUrl } from "../appointment-history/appointmentHistoryStore";
import { currentUser } from "../../stores/auth";
import { HeadingH3, PNormal } from "../styleguide/typography";
import { AlertWarning } from "../alert-warning/AlertWarning";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { AddTimeSlotForDayModal } from "./AddTimeSlotForDayModal";
import { ModalPortal } from "../modal/ModalPortal";
import _ from "lodash";
import { colors } from "../styleguide/colors";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const ScheduleHourLink = styled(Link)`
  text-decoration: none;
`;

/** Paper выбивается из текущей стилистики */
const EmptyScheduleContainer = styled(RoundedRectangleWithShadow)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 40px;
`;

export const ScheduleContent = () => {
  const scheduleStore = useStore(dataStore);

  const ScheduleHourWrapper = (props: { ...$Exact<ScheduleReserved>, children: React.Node }) => {
    const { location } = useReactRouter();
    if (!props.medical_file) {
      return props.children;
    }
    return scheduleStore.isEditSchedule ? (
      <div>{props.children}</div>
    ) : (
      <ScheduleHourLink
        to={`/appointment/new/${props.medical_file.id}?autoSelectMedicalFile=true`}
        onClick={() => setLastScheduleUrl(location.pathname)}>
        {props.children}
      </ScheduleHourLink>
    );
  };

  useScheduleUpdates();

  const user = useStore(currentUser);

  if (!user) {
    return null;
  }

  let scheduleItems = scheduleStore.items;
  const focusedDay = findFirstActiveScheduleDayIndex(scheduleItems);

  if (!scheduleItems.length) {
    return (
      <EmptyScheduleContainer>
        {!scheduleStore.isLoading ? (
          <CircularProgress size={50} />
        ) : (
          <Typography fontSize={"15px"} color={colors.gray700}>У вас пока нет расписания.</Typography>
        )}
      </EmptyScheduleContainer>
    )
  }

  return (
    <ScheduleDays>
      {scheduleItems.map(day => (
          <ScheduleDay
            scrollIntoView={
              !!focusedDay && focusedDay >= 0 && scheduleItems[focusedDay].date === day.date
            }
            key={day.date}
            date={day.date}
            items={day.schedule}
            scheduleHourWrapper={ScheduleHourWrapper}
          />
        )
      )}
      {_.size(scheduleStore.dataDeleteAppointment) > 0 && scheduleStore.dataDeleteAppointment.is_reserved && (
        <ModalPortal>
          <AlertWarning
            containerModifiers="small"
            isNeedConfirm
            sendConfirm={async (value) => {
              if (value) {
                await deleteAppointmentAndUpdateView();
              } else {
                changeDataDeleteAppointment({});
              }
            }}
            heading={<HeadingH3 modifiers="center">Удалить запланированный прием?</HeadingH3>}
            description={
              <PNormal modifiers={["left", "center"]}>
                Пациенту придет сообщение о том, что его<br/>прием отменен.
              </PNormal>
            }
            cancelButton={onClick => (
              <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
            )}
            confirmButton={onClick => (
              <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
            )}
          />
        </ModalPortal>
      )}
      {scheduleStore.dataDeleteSchedule && (
        <ModalPortal>
          <AlertWarning
            containerModifiers="small"
            isNeedConfirm
            sendConfirm={async (value) => {
              if (value) {
                await deleteScheduleAndUpdateView();
              }
              changeDataDeleteSchedule('');
            }}
            heading={<HeadingH3 modifiers="center">Удалить весь день?</HeadingH3>}
            description={
              scheduleItems.find((item) => item.date === scheduleStore.dataDeleteSchedule).schedule.some((item) => item.is_reserved) && (
                <PNormal modifiers={["left", "center"]}>
                  Все приемы, запланированные на этот день будут отменены.
                </PNormal>
              )
            }
            cancelButton={onClick => (
              <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
            )}
            confirmButton={onClick => (
              <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
            )}
          />
        </ModalPortal>
      )}
      {scheduleStore.dataAddTimeSlot && (
        <ModalPortal>
          <AddTimeSlotForDayModal />
        </ModalPortal>
      )}
    </ScheduleDays>
  );
};
