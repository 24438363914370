import React from 'react';
import * as R from 'ramda';
import {getAppointment_id} from '../../domain/entities/Appointment';
import {ButtonFormSection} from '../appointment-page/ButtonFormSection';
import {TableWidget} from '../appointment-widgets/TableWidget';

export const getTableFromSection = R.memoizeWith(
  R.prop("id"),
  R.compose(
    R.find(R.where({
      field_type: R.equals("table")
    })),
    R.prop("fields")
  )
);

export const Table = props => {
  const { editable, appointment, table, fieldValue, button } = props;
  return (
    <ButtonFormSection
      name={table.name}
      button={button}
    >
      <TableWidget
        editable={editable}
        field={table}
        appointment_id={getAppointment_id(appointment)}
        layoutFieldValue={fieldValue}
      />
    </ButtonFormSection>
  );
};