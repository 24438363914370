import React from "react";
import { Button, Grid, Paper } from "@mui/material";
import { FormInputDate } from "../../common/dynamic-form/fields/FormInputDate";
import styled from "styled-components";

const Container = styled(Paper)`
  padding: 10px 15px;
`;

export const ReportDateFilter = props => {
  const onSubmit = data => {
    const filters = {
      date__gte: data.date__gte,
      date__lte: data.date__lte
    };
    props.store.setGlobalFiltersTable(filters);
  };

  return (
    <Container>
      <form onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <Grid alignItems={"center"} container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate
              typePicker={"date"}
              label="Дата от"
              name="date__gte"
              control={props.control}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate
              typePicker={"date"}
              label="до "
              name="date__lte"
              control={props.control}
            />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button type={"submit"} variant="contained" color={"success"}>
              Применить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
