// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  width: 990px;
  padding: 27px 22px 0;
  box-sizing: border-box;

  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.03);
  @media (max-width: 768px) {
    width: auto;
    padding: 25px 30px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
`;

export const AppointmentFeedbackLayout = (props: {
  title: React$Node,
  sort?: React$Node,
  table?: React$Node
}) => {
  const { title, sort, table } = props;
  return (
    <Container>
      <HeaderContainer>
        {title}
        {sort}
      </HeaderContainer>
      {table}
    </Container>
  );
};
