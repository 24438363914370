// @flow
import React from "react";
import styled from "styled-components";
import { FormSection } from "../form/FormSection";

const ChildrenContainer = styled.div`
  margin: 24px 0 -8px;
`;

export const AIFormSection = (props: React$ElementConfig<typeof FormSection>) => {
  return (
    <FormSection
      {...props}
      overrides={{
        ChildrenContainer: {
          component: ChildrenContainer
        },
        ...(props.overrides || {})
      }}
    />
  );
};
