// @flow
import * as React from "react";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";
import { Tooltip as MaterialTooltip } from "@mui/material";

import { colors } from "../styleguide/colors";

const Container = styled.div`
  position: relative;
`;

export const Button = styled.button`
  width: 20px;
  height: 20px;

  border: none;
  background: url(${props => props.icon}) no-repeat;
  background-size: contain;
  cursor: pointer;
  position: relative;

  outline: none;

  transition: all 0.5s ease;

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    background: url(${props => props.iconHover}) no-repeat;
    background-size: contain;
  }

  :hover {
    background: none;
  }

  :hover:after {
    opacity: 1;
  }
    
  &[disabled] {
    opacity: 0.5;

    &:hover {
      background: url(${props => props.icon}) no-repeat;
      background-size: auto;
      background-position: center;
    }

    &:hover:after {
      opacity: 0;
    }
  }
`;

export const Tooltip = styled.div`
  width: max-content;
  max-width: 200px;
  padding: 8px 12px;
  box-sizing: border-box;

  border: solid 1px #e9e9e9;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  background-color: ${colors.white};

  font-size: 11px;

  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);

  z-index: 100;

  &:after {
    content: "";
    width: 7px;
    height: 7px;

    border-left: solid 1px #e9e9e9;
    border-top: solid 1px #e9e9e9;
    background-color: ${colors.white};

    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
`;

const OverridableProps = {
  Button
};

export const ButtonWithTooltip = (props: {
  icon: React.Node,
  iconHover?: React.Node,
  disabled?: boolean,
  tooltip?: string | React$Node,
  onClick?: (e: MouseEvent) => mixed,
  overrides?: ExtractOverridesProps<typeof OverridableProps>
}) => {
  const handleClick = e => {
    props.onClick && props.onClick(e);
  };

  return (
    <Container>
      <MaterialTooltip animation="zoom" arrow placement={"bottom"} title={props.tooltip}>
        <span>
          <Button
            type="button"
            {...o}
            {...props}
            icon={props.icon}
            iconHover={props.iconHover ? props.iconHover : props.icon}
            onClick={handleClick}
            disabled={props.disabled}
          />
        </span>
      </MaterialTooltip>
    </Container>
  );
};
