//@flow
import * as R from "ramda";

export const calculateBodyMassIndex = (height: number, weight: number): number => {
  return parseFloat((weight / (height / 100) ** 2).toFixed(2));
};

export const isValidBodyMassIndex = (value: any): boolean => {
  if (typeof value !== "object" || value instanceof Array || R.isEmpty(value)) return false;
  return !!value.height === !!value.weight;
};
