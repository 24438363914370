// @flow
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import React from "react";

const Container = styled.div`
  position: relative;
  width: 750px;
  padding: 0 50px 40px;
  box-sizing: border-box;
  background: ${colors.white};
`;

const CloseContainer = styled.div`
  position: fixed;
  right: 35px;
  top: 35px;
  z-index: 11;
`;

const TabsContainer = styled.div``;

const FixedHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  background: ${colors.white};
  height: 94px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
`;

const HeadingContainer = styled.div`
  max-width: 540px;
  margin-top: 45px;

  ${FixedHeader} + & {
    margin-top: 5px;
  }
`;

const ContentContainer = styled.div`
  margin-top: 38px;
`;

export const InfoLayout = (props: {
  content: React$Node,
  close: React$Node,
  tabs?: React$Node,
  heading: React$Node
}) => {
  const { content, close, tabs, heading } = props;
  return (
    <Container>
      <CloseContainer>{close}</CloseContainer>
      {tabs && (
        <FixedHeader>
          <TabsContainer>{tabs}</TabsContainer>
        </FixedHeader>
      )}

      <HeadingContainer>{heading}</HeadingContainer>
      <ContentContainer>{content}</ContentContainer>
    </Container>
  );
};
