// @flow
import React from "react";
import styled from "styled-components";
import { PNormal } from "../styleguide/typography";
import type { Checklist } from "../../domain/entities/Checklist";

const CommentContainer = styled(PNormal)`
  white-space: pre-line;
`;

export const ReadableChecklistContentWithCommentFormatted = (props: { checklist: Checklist }) => (
  <>
    {props.checklist.name}
    {props.checklist.comment && <CommentContainer>{props.checklist.comment}</CommentContainer>}
  </>
);
