import React from "react";
import {
  Checkbox, FormControl,
  FormControlLabel, FormLabel
} from "@mui/material";
import { Controller } from "react-hook-form";
import { Asterisk } from "../styled/Asterisk.styled";

export const FormInputCheckbox = (props) => {
  return (
    <FormControl fullWidth className={'dynamic-form-input-control'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
      {(props.label && props.withLabel) && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
      <FormControlLabel
        control={
          <Controller
            name={props.name}
            error={props.error}
            control={props.control}
            render={({
              field: { onChange, value },
            }) => (
              <Checkbox
                {...props}
                label={props.withLabel ? '' : props.label}
                size="small"
                disabled={props.readOnly}
                checked={!!value}
                onChange={onChange}
              />
            )}
          />
        }
        label={props.label}
      />
    </FormControl>
  );
};
