// @flow
import * as React from "react";

import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {observer} from "mobx-react";
import {
  ButtonReview,
  DescriptionText,
  FlexContainer,
  HeaderText,
  LogoWrapper,
  SectionBottom,
  SectionTop
} from "./reviewStyled";

import { Textarea } from "../form-elements/Textarea";
import { Checkbox } from "../appointment-page/Checkbox";
import { newColors } from "../styleguide/newColors";
import reviewStore, { CLINIC_CODE } from "../../stores/reviewStore";
import useReactRouter from "use-react-router";
import { ClinicLogo } from "../clinicLogo/ClinicLogo";
import { generateSearchParams, setStoreDataSearchParams } from "./ReviewPage";

const CheckboxFieldReview = styled.div`
  display: flex;

  label {
    color: ${newColors.brown};
  }
`;

const TextareaFieldReview = styled.div`
  textarea {
    min-height: 136px;
    font-size: 15px;
    border: none;
    border-radius: 7px;
    padding: 8px 12px;
    font-family: 'Suisse Intl';
  }
`;

const FormReview = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ReviewStepFiveNegative = withRouter(observer((props: {||}) => {

  if (!reviewStore.token) {
    const { match: { params } } = useReactRouter();
    setStoreDataSearchParams(reviewStore);
    props.history.push(`/review/${params.token}${generateSearchParams(reviewStore)}`);
    return null;
  }

  if (reviewStore.current_page !== 'step-five-negative') {
    reviewStore.setCurrentPage('step-five-negative');
    reviewStore.setProgress();
  }


  const handleSubmit = async () => {
    if (reviewStore.is_need_callback === null) {
      reviewStore.setIsNeedCallback(true);
    }
    await reviewStore.setProgress();
    if (!reviewStore.is_need_callback) {
      props.history.push(`/review/${reviewStore.token}/step-six-not-call${generateSearchParams(reviewStore)}`);
    } else {
      props.history.push(`/review/${reviewStore.token}/step-six-with-call${generateSearchParams(reviewStore)}`);
    }
  };

  return (
    <FlexContainer>
      <SectionTop>
        <LogoWrapper>
          <ClinicLogo name={reviewStore.clinic_code} isShowName={false} size={{width:"79px", height:"79px"}} />
        </LogoWrapper>
        <HeaderText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
          {reviewStore.isMobile ? 'Оставьте свой отзыв' : 'Написать директору по сервису'}
        </HeaderText>
        <DescriptionText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
          {reviewStore.isMobile ? 'Пожалуйста, напишите, что вы думаете о нашем приложении. Это поможет нам сделать его лучше' : 'Расскажите, что вам не понравилось, и что мы можем сделать лучше. Директор разберётся в ситуации, примет меры, свяжется с вами и предложит решение проблемы.'}
        </DescriptionText>
        <FormReview onSubmit={e => e.preventDefault()}>
          <TextareaFieldReview>
            <Textarea value={reviewStore.review} handleChange={(value) => reviewStore.setReview(value)} placeholder={reviewStore.isMobile ? "Что можно улучшить в приложении..." : "Напишите, что вас расстроило в нашем обслуживании"} />
          </TextareaFieldReview>
          <CheckboxFieldReview>
            <Checkbox checkmarkType="circle" text="Мне не нужен обратный звонок" checked={reviewStore.is_need_callback === null ? false : !reviewStore.is_need_callback} onChange={(event) => reviewStore.setIsNeedCallback(!event.target.checked)} />
          </CheckboxFieldReview>
        </FormReview>
      </SectionTop>
      <SectionBottom>
        <ButtonReview type="button" onClick={handleSubmit} modifiers={[reviewStore.clinic_code === CLINIC_CODE.rassvet ? "backgroundPinkRassvet" : "backgroundPink", !reviewStore.review ? "disabled" : "", !reviewStore.review && reviewStore.clinic_code === CLINIC_CODE.rassvet ? "disabledRassvet" : ""]}>
          Отправить
        </ButtonReview>
      </SectionBottom>
    </FlexContainer>
  );
}));
