import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useClickAway } from "react-use";
import moment from "moment";

import { RangeCalendar, periods } from "../calendar/RangeCalendar";
import { CommonHintedButton } from "./CommonHintedButton";

import calendarIcon from "./icons/calendar.svg";
import calendarHoverIcon from "./icons/calendar-hover.svg";
import calendarActiveIcon from "./icons/calendar-active.svg";

import { formatDateToReadableDateFormat, parseDateFromReadableFormat } from "../../utils/dates";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
`;

const CalendarContainer = styled.div`
  position: ${props => props.y ? "fixed" : "absolute"};
  top: ${props => props.y ? `${props.y + 23}px` : "calc(100% + 13px)"};
  right: ${props => (props.isLeftContainer ? "auto" : (props.isCenterContainer ? "-320px" :"-34px"))};
  left: ${props => (props.isLeftContainer ? props.x ? `${props.x}px` : "0" : "auto")};
  box-shadow: 0px 2px 31px rgba(0, 0, 0, 0.09);
  z-index: 1000;
`;

const Icon = styled.div`
  width: 16px;
  height: 18px;
  margin-top: -2px;
  background: url(${calendarIcon});
  background-size: cover;

  ${Container}:hover & {
    background: url(${calendarHoverIcon});
    background-size: cover;
  }
`;

const ActiveIcon = styled(Icon)`
  background: url(${calendarActiveIcon});
`;

const Value = styled.span`
  ${({ active }) =>
    active &&
    css`
      color: ${colors.magenta};
    `}
`;

export const DateRange = ({
  value,
  onSetDateRange,
  defaultTitle,
  isLeftContainer,
  isCenterContainer,
  containerNotPadding,
  onResetDateRange,
  onToggleOpen,
  modifiers,
  hideIcon,
  useFixedPosition,
  ...rest
}) => {
  const $container = useRef(null);
  const $calendar = useRef(null);

  const [isOpen, setOpen] = useState(false);
  const [position, setPosition] = useState({x: 0, y: 0});

  useLayoutEffect(() => {
    const onScroll = () => {
      if (useFixedPosition && $container.current && $calendar.current) {
        const rect = $container.current.getBoundingClientRect();
        // setPosition({x: rect.x, y: rect.y});
        $calendar.current.style.top = `${rect.y + 23}px`;
        $calendar.current.style.left = `${rect.x}px`;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useClickAway($container, () => {
    setOpen(false);
    if (useFixedPosition) {
      if ($calendar.current) {
        $calendar.current.style.top = "";
        $calendar.current.style.left = "";
      }
      setPosition({ x: 0, y: 0 });
    }
    onToggleOpen?.(false);
  });

  const handleSetDateRange = ([startDate, endDate]) => {
    onSetDateRange([
      formatDateToReadableDateFormat(startDate),
      formatDateToReadableDateFormat(endDate)
    ]);
  };

  const handleSelectPeriod = value => {
    const [startDate, endDate] = periods[value].dates();

    onSetDateRange({
      value,
      dates: [formatDateToReadableDateFormat(startDate), formatDateToReadableDateFormat(endDate)]
    });
  };

  const handleResetDateRange = () => {
    onResetDateRange();
  };

  const [startDate, endDate, period] = useMemo(() => {
    if (!value) {
      return [null, null, null];
    }

    if (Array.isArray(value)) {
      return [parseDateFromReadableFormat(value[0]), parseDateFromReadableFormat(value[1]), null];
    }

    return [
      parseDateFromReadableFormat(value.dates[0]),
      parseDateFromReadableFormat(value.dates[1]),
      value.value
    ];
  }, [value]);

  const dateValue = useMemo(() => {
    if (!value) {
      return defaultTitle || "Добавить дату";
    }

    if (Array.isArray(value)) {
      if (moment(value[0]).isSame(value[1], "d")) {
        return value[0];
      }

      return value.join("-");
    }

    return periods[value.value].title;
  }, [value]);

  return (
    <Container
      ref={$container}
      onClick={() => {
        if (useFixedPosition) {
          const rect = $container.current.getBoundingClientRect();
          setPosition({ x: rect.x, y: rect.y });
        }
        setOpen(true);
        onToggleOpen?.(true);
      }}>
      <ActiveIcon style={{ display: "none" }} />
      <CommonHintedButton
        {...rest}
        containerNotPadding={containerNotPadding}
        overrides={{
          Icon: hideIcon
            ? {}
            : {
                component: isOpen ? ActiveIcon : Icon
              },
          Text: {
            props: {
              modifiers,
              children: (
                <span>
                  <Value active={isOpen}>{dateValue}</Value>
                </span>
              )
            }
          }
        }}
      />
      {isOpen && (
        <CalendarContainer ref={$calendar} x={position.x} y={position.y} isLeftContainer={isLeftContainer} isCenterContainer={isCenterContainer}>
          <RangeCalendar
            {...rest}
            onSetDateRange={handleSetDateRange}
            onSelectPeriod={handleSelectPeriod}
            onResetDateRange={handleResetDateRange}
            period={period}
            startDate={startDate}
            endDate={endDate}
          />
        </CalendarContainer>
      )}
    </Container>
  );
};
