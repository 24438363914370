// @flow
import type { Checklist } from "../entities/Checklist";
import * as R from "ramda";

export const withoutUnchecked: (Array<Checklist>) => Array<Checklist> = R.filter(
  item => !!item.is_checked
);

export const withoutUncheckedGroups: (Array<Checklist>) => Array<Checklist> = R.compose(
  R.reject(
    R.compose(
      R.equals(0),
      R.length,
      R.prop("items")
    )
  ),
  R.map(
    R.over(
      R.lensProp("items"),
      R.compose(
        withoutUnchecked,
        R.defaultTo([])
      )
    )
  )
);
