// @flow
import * as R from "ramda";
import type { Prescription } from "../../domain/value-objects/Prescription";
import type { TDrugId } from "../../domain/entities/Drug";

export type PrescriptionDrug = {
  drug: string,
  drug_id: TDrugId
};

type TGetDrugs = (prescriptions: Array<Prescription>) => Array<PrescriptionDrug>;

export const getDrugs: TGetDrugs = R.compose(
  R.uniqBy(R.prop("drug_id")),
  R.map((R.pick(["drug", "drug_id"]): any))
);
