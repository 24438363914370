import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { debounce } from "lodash";
import MaskedInput from "react-text-mask";
import Calendar from "react-calendar";

import { usePortal } from "../../../utils/usePortal";
import { useMyPatientsTableInput } from "./useMyPatientsTableInput";

import { formatDateToReadableDateFormat } from "../../../utils/dates";

import { DefaultInput } from "../../form-elements/DefaultInput";
import LeftArrowIcon from "../../form-elements/icons/LeftArrow";
import RightArrowIcon from "../../form-elements/icons/RightArrow";
// import { MyPatientsPageContext } from "../MyPatientsPageContext";

import * as S from "./MyPatientsTableInputsCalendar.styled";
import * as CommonInputStyles from "./MyPatientsTableInput.styled";

export const MyPatientsTableInputsCalendar = props => {
  const { value, onUpdate } = useMyPatientsTableInput(props);
  // const { offsetRef } = useContext(MyPatientsTableContext);

  const [pos, setPos] = useState(null);

  const calendarContainerRef = useRef(null);
  const containerRef = useRef(null);
  const calendarNode = usePortal("calendar-elements-root");

  const handleSetToday = () => {
    const today = new Date();
    onUpdate(today);
  };

  function correctPosition() {
    if (containerRef.current) {
      setPos(containerRef.current.getBoundingClientRect());
    }
  }

  const debouncedCorrectPosition = debounce(() => {
    correctPosition();
  }, 50);

  useEffect(() => {
    correctPosition();
    // TODO: Maybe use wrapper element for scroll?
    // const wrapperEl = wrapper.current;
    const selection = window.getSelection();
    if (selection) {
      window.getSelection().removeAllRanges();
    }

    window.addEventListener("wheel", debouncedCorrectPosition);
    return () => window.removeEventListener("wheel", debouncedCorrectPosition);
  }, [containerRef.current]);

  const onMouseDown = React.useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const transformStyles = React.useMemo(() => {
    const ROW_HEIGHT = 60;
    const DEFAULT_CALENDAR_HEIGHT = 320;
    const PADDING = 4;
    const calendarHeight = calendarContainerRef.current
      ? calendarContainerRef.current.getBoundingClientRect().height
      : DEFAULT_CALENDAR_HEIGHT;
    return pos
      ? `translate3d(${pos.left}px, ${
          pos.top + pos.height + PADDING + calendarHeight > window.innerHeight
            ? pos.top - pos.height - calendarHeight + ROW_HEIGHT - PADDING * 2
            : pos.top + pos.height + PADDING
        }px, 0px)`
      : "none";
  }, [pos]);

  return (
    <CommonInputStyles.Input /* offset={offsetRef.current} */>
      <S.Container ref={containerRef} onMouseDown={onMouseDown}>
        <DefaultInput
          type="text"
          value={formatDateToReadableDateFormat(value)}
          handleChange={onUpdate}
          overrides={{
            Container: { component: S.DefaultInputContainer },
            Input: {
              props: {
                as: MaskedInput,
                mask: [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/],
                guide: false
              },
              component: S.CalendarInput
            }
          }}
        />
        <>
          {ReactDOM.createPortal(
            <S.CalendarContainer
              ref={calendarContainerRef}
              visible={true}
              style={{
                transform: transformStyles,
                top: 0,
                left: 0
              }}>
              <S.CalendarContent>
                <Calendar
                  className="date-input-calendar"
                  defaultView="month"
                  navigationLabel={({ date, label, view }) => {
                    const today = new Date();
                    const dateMoment = moment(date);
                    return view === "month"
                      ? date.getFullYear() === today.getFullYear()
                        ? dateMoment.format("MMMM")
                        : dateMoment.format("MMMM, YYYY")
                      : label;
                  }}
                  prevLabel={<LeftArrowIcon />}
                  nextLabel={<RightArrowIcon />}
                  onChange={onUpdate}
                  value={value}
                />
                <S.TodayButton onClick={handleSetToday}>Сегодня</S.TodayButton>
              </S.CalendarContent>
            </S.CalendarContainer>,
            calendarNode
          )}
        </>
      </S.Container>
    </CommonInputStyles.Input>
  );
};
