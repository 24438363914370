// @flow
import React from "react";
import headerStore from "../../stores/headerStore";
import { IconButton, SwipeableDrawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../styleguide/colors";
import styled from "styled-components";

const SwipeableDrawerStyled = styled(SwipeableDrawer)`
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: ${colors.transparentGray100};
  min-height: 50px;
  padding-right: 15px;
  @media (max-width: 480px) {
    padding-right: 10px;
  }
`;

const IconButtonStyled = styled(IconButton)`
  grid-area: drawer;
  &:active {
    color: ${colors.lightMagenta};
  }
`;

/** Пока конкретно к Header, в случае необходимости для других возможных мест - расширить */
export const HeaderDrawer = ({ isOpen, content }) => {
  const handleOnOpenDrawer = () => headerStore.handlerOnCollapseDrawer(true);
  const handleOnCloseDrawer = () => headerStore.handlerOnCollapseDrawer(false);

  return (
    <>
      <IconButtonStyled color="black" onClick={handleOnOpenDrawer}>
        <MenuIcon fontSize="medium" />
      </IconButtonStyled>
      <SwipeableDrawerStyled
        anchor="right"
        open={isOpen}
        onClose={handleOnCloseDrawer}
        onOpen={handleOnOpenDrawer}>
        <CloseContainer>
          <IconButtonStyled color="black" onClick={handleOnCloseDrawer}>
            <CloseIcon fontSize="medium" />
          </IconButtonStyled>
        </CloseContainer>
        {content}
      </SwipeableDrawerStyled>
    </>
  );
};
