import React from "react";
import styled, {css} from "styled-components";
import dayjs from "dayjs";
import {observer} from 'mobx-react';

import {
  ItemContainer,
  ItemImageContainer,
  ItemInnerContainer,
  ItemTitleContainer,
  ItemTitle,
  ItemTime,
  ItemContentContainer,
  ItemContent
} from './ItemComponents';
import {OpponentAvatar} from './ChatAvatars';
import {UnreadMessagesBadge} from './UnreadMessagesBadge';
import chatStore, {ChatMessageType} from '../../../stores/chatStore';
import authStore from '../../../stores/authStore';

import lockIcon from "../icons/lock.svg";
import MessageDeliveredIcon from "../../../assets/icons/chat/delivered.inline.svg";
import MessageReadIcon from "../../../assets/icons/chat/read.inline.svg";

const ChatItemContainer = styled(ItemContainer)`
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  cursor: pointer;
  
  ${props => props.isActive && css`background: #FFF5F1;`}

  &:not(:last-child) {
    border-bottom: 1px solid rgba(220, 211, 210, 0.4);
  }

  &:hover {
    background: rgba(220, 211, 210, 0.4);
  }
`;

const ImageContainer = styled(ItemImageContainer)`
  width: 60px;
  height: 60px;
  border-radius: 20px;
`;

const ChatItemTextContainer = styled(ItemContentContainer)`
  max-height: 45px;
  flex: 1;
`;

const ChatItemContent = styled(ItemContent)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ChatItemIndicators = styled.div`
  display: flex;
  align-items: center;
`;

const IconImage = styled.img`
  width: 16px;
  align-self: flex-end;
`;

export const ChatItem = observer(({
  chat
}) => {
  const currentUserId = authStore.user.id;
  const { opponent, last_message, unread_messages_count, can_patient_chat, is_paid } = chat;
  const messageMoment = last_message?.created_at ? dayjs(last_message.created_at) : null;

  return (
    <ChatItemContainer
      data-chat-id={chat.id}
      isActive={chat.id === chatStore.currentChat?.id}
      disabled={chat.id === chatStore.currentChat?.id}
      onClick={() => chatStore.setCurrentChat(chat.id)}
    >
      <ImageContainer>
        <OpponentAvatar opponent={opponent}/>
      </ImageContainer>
      {/*<span>{chat.is_online ? "онлайн" : ""}</span>*/}
      <ItemInnerContainer>
        <ItemTitleContainer>
          <ItemTitle title={opponent.short_name + (is_paid ? ` (платный чат)` : '')} isPaid={is_paid}>{opponent.short_name}</ItemTitle>
          {
            messageMoment && (
              <ChatItemIndicators>
                {
                  last_message.sender?.id === currentUserId ?
                    last_message.is_read ? (
                      <MessageReadIcon/>
                    ) : (
                      <MessageDeliveredIcon/>
                    ) : null
                }
                <ItemTime>
                  {messageMoment.format("HH:mm")}
                </ItemTime>
              </ChatItemIndicators>
            )
          }
        </ItemTitleContainer>
        <ChatItemTextContainer>
          {
            last_message ? (
              <ChatItemContent title={last_message.text} isAccent={last_message.type === ChatMessageType.SYSTEM}>
                {last_message.text}
              </ChatItemContent>
            ) : (
              <ChatItemContent/>
            )
          }
          {
            unread_messages_count > 0 && (
              <UnreadMessagesBadge unreadMessagesCount={unread_messages_count}/>
            )
          }
          {
            !can_patient_chat && (
              <IconImage src={lockIcon} alt="Chat locked"/>
            )
          }
        </ChatItemTextContainer>
      </ItemInnerContainer>
    </ChatItemContainer>
  );
});
