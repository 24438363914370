import apiv2 from '../apiv2';
import DynamicTableStore, { filtersAfterCheck } from "./dynamicTableStore";
import { action, makeObservable } from "mobx";

class VoxPatientConsentStore extends DynamicTableStore {
  constructor() {
    super(apiv2.voxPatientConsent.getListPatientConsent.bind(apiv2.voxPatientConsent), ['id'], undefined);
    makeObservable(this, {
      saveToFile: action,
    })
  }


  async saveToFile() {
    const params = {
      filter_params: { ...filtersAfterCheck(this.filtersTable), ...filtersAfterCheck(this.globalFiltersTable) },
      values_params: this.valuesParamsTable,
      order_by_params: this.sortingTable
    };
    if (this.annotate_mtm_params) {
      params.annotate_mtm_params = this.annotate_mtm_params;
    }
    try {
      params.is_after_get = true;
      const response = await apiv2.voxPatientConsent.getListPatientConsent(params);
      const link = document.createElement("a");
      link.setAttribute('href', `https://${location.hostname}/api/vox_patient_consent/list/?request_uuid=` + response.request_uuid);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', `patientConsent.csv`);
      link.click();
    } catch(e) {
      console.error(e);
      return false;
    }
  }
}

export default new VoxPatientConsentStore();
