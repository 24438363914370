import React from "react";
import { useStore } from 'effector-react';
import styled from "styled-components";
import { colors } from "../styleguide/colors";

import { stimulationProtocol } from "../../stores/stimulationProtocol";
import {
  changeRightSidebar,
  closeRightSidebar,
  STIMULATION_PROTOCOL_CONTENT
} from "../../stores/rightSidebar";

import crossIcon from "./icons/cross.inline.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding: 20px 80px 20px 32px;
  border-left: 4px solid ${colors.magenta};
  border-radius: 3px;
  background: ${colors.white};
`;

const Title = styled.div`
  width: 212px;
  margin-right: 40px;
  flex-shrink: 0;
  font-size: 22px;
  line-height: 25px;
`;

const Caption = styled.div`
  max-width: 660px;
  font-size: 14px;
  line-height: 23px;
  opacity: 0.6;
`;

const ExtraLink = styled.span`
  color: ${colors.magenta};
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`;

const Close = styled(crossIcon)`
  position: fixed;
  top: 24px;
  right: 40px;
  cursor: pointer;
  padding: 10px;
`;

const SidebarContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 40px;
  width: 751px;
`;

const SidebarContent = styled.div`
  p,
  ul,
  ol {
    font-size: 15px !important;
    line-height: 23px !important;

    & + * {
      margin-top: 16px;
    }

    strong {
      font-weight: 500 !important;
    }
  }

  a {
    color: ${colors.magenta} !important;

    &:active {
      color: ${colors.magenta} !important;
    }
  }

  h1 {
    font-size: 30px !important;
    margin-bottom: 8px !important;
    line-height: 39px !important;
  }

  h2 {
    font-size: 22px !important;
    line-height: 29px !important;
    margin-bottom: 16px !important;
    margin-top: 24px !important;
  }

  ul {
    li {
      position: relative;
      padding-left: 34px;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 9px;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        background: ${colors.gray200};
      }
    }
  }

  ol {
    counter-reset: stimulation-protocol-ol-list-counter;

    li {
      position: relative;
      padding-left: 24px;
      counter-increment: stimulation-protocol-ol-list-counter;
    }

    li::before {
      content: counter(stimulation-protocol-ol-list-counter) ". ";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      text-align: center;
      transform: translateY(-50%);
      width: 24px;
      opacity: 0.5;
    }
  }

  blockquote {
    padding: 16px;
    font-size: 15px;
    background: ${colors.oldLace};
    margin-bottom: 8px !important;
    margin-top: 8px !important;
    border-radius: 4px;

    p {
      margin-bottom: unset !important;
    }
  }
`;

export const StimulationProtocolInfo = () => {
  const { description } = stimulationProtocol.getState();
  return (
    <SidebarContainer>
      <Close onClick={closeRightSidebar} />
      <SidebarContent dangerouslySetInnerHTML={{ __html: description }} />
    </SidebarContainer>
  );
};

export const StimulationProtocol = () => {
  const protocol = useStore(stimulationProtocol);

  if (!protocol) {
    return null;
  }

  return (
    <Container>
      <Title>{protocol.title}</Title>
      <Caption>
        {protocol.caption}{" "}
        <ExtraLink
          onClick={() => {
            if (protocol.description) {
              changeRightSidebar(STIMULATION_PROTOCOL_CONTENT);
            }
          }}>
          Подробные рекомендации
        </ExtraLink>
      </Caption>
    </Container>
  );
};
