// @flow

export type Diagnosis = {
  id: number,
  name: string,
  parent: ?number,
  level?: number,
  icd_code?: string,
  has_children: boolean,
  children?: Array<Diagnosis>,
  comment?: string,
  is_group: ?boolean
};

export opaque type TDiagnosis_id: number = number;

export const getDiagnosis_id = (diagnosis: Diagnosis): TDiagnosis_id => {
  return diagnosis.id;
};
