// @flow
import { useStore } from "effector-react";

import { tokenStore } from "../../stores/auth";
import type { Organization } from "../../domain/entities/Organization";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import {useApiRequest} from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

export const useOrganization = (appointment: ?AppointmentWithDetails): ?Organization => {
  const token = useStore(tokenStore);
  const id = appointment && appointment.medical_file.organization.id;

  const organizationResult = useApiRequest(async () => {
    if (id) {
      return await apiv2.organizations.getOrganization(id);
    }
  }, [token, appointment && appointment.medical_file.organization.id]);

  const organization = organizationResult.data;

  return organization;
};
