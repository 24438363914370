// @flow
import * as React from "react";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";
import { rgba } from "polished";
import { colors } from "../styleguide/colors";
import { ButtonCaps } from "../styleguide/typography";

export const Container: React.ComponentType<{ disabled: boolean }> = styled.button`
  height: 38px;
  padding: 0 12px;
  box-sizing: border-box;

  border: solid 1px ${rgba(colors.black, 0.1)};
  border-radius: 4px;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  font-family: inherit;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  ${props =>
    (!props.disabled &&
      `
             &:hover {
                border-color: ${colors.magenta};
             }
       `) ||
    `
            opacity: 0.5;
            filter: grayscale(1);
            pointer-events: none;
       `}
`;

const QuantityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  margin-left: 6px;
  color: ${colors.magenta};
  border: 1px solid ${colors.magenta};
  border-radius: 50%;
  font-size: 12px;
  line-height: 12px;
`;

const ButtonCapsLeft = props => <ButtonCaps {...props} modifiers={["left"]} />;

const Text = styled(ButtonCapsLeft)`
  font-size: 15px;
  font-weight: normal;
  transition: color 0.15s ease-in-out;
  ${Container}:hover & {
    color: ${colors.magenta};
  }
`;

const TextContainer = props => <div>{props.children}</div>;

const OverridableProps = {
  Container,
  Text,
  TextContainer,
  QuantityContainer
};

type BaseButtonOverridesProps = ExtractOverridesProps<typeof OverridableProps>;

export const SummaryButton = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  type?: "button" | "reset" | "submit",
  quantity?: number,
  children: ?React.Node,
  overrides?: BaseButtonOverridesProps
}) => (
  <Container
    type={props.type ? props.type : "button"}
    {...o}
    disabled={props.disabled}
    onClick={props.onClick}>
    <TextContainer {...o}>
      <Text {...o}>{props.children}</Text>
    </TextContainer>
    {!!props.quantity && <QuantityContainer>{props.quantity}</QuantityContainer>}
  </Container>
);
