// @flow
import React from "react";
import styled from "styled-components";
import { PSignNormal } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto;
  grid-gap: 0 18px;

  align-items: center;
  align-self: center;

  & > * {
    padding-top: 30px;
  }
`;

const Placeholder = styled.div`
  height: 7px;
  border-bottom: solid 1px ${colors.transparentGray};
`;

const SignArea = styled(Placeholder)`
  position: relative;
  width: 202px;

  .image-inline, .ck-widget.image-inline {
    position: unset;
    align-items: unset;
    display: unset;
    max-width: unset;
      
    img {
      max-width: 100%;
      max-height: 20mm;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
  }
`;

const NameArea = styled(Placeholder)`
  width: 302px;
`;

const RightFloat = styled.div`
  justify-self: flex-end;
`;

const SignText = styled(PSignNormal)`
  max-width: 540px;
  text-align: right;
  line-height: 1.2em;
`;

export const AppointmentSign = (props: {
  title?: string,
  doctor?: { name: string, regalia: ?string },
  chief?: ?string,
  patient?: string,
  date?: string
}) => {
  return (
    <Container>
      {props.date && (
        <>
          <RightFloat>
            <SignText>Дата</SignText>
          </RightFloat>
          <PSignNormal>{props.date}</PSignNormal>
        </>
      )}

      {props.doctor && (
        <>
          <RightFloat>
            <SignText>
              {props.title || "Врач"} {props.doctor.regalia && <span>{props.doctor.regalia} </span>}
              {props.doctor.name}
            </SignText>
          </RightFloat>
          {!props.doctor.name && <NameArea />}
          <SignArea data-signature-place></SignArea>
        </>
      )}

      {props.chief && (
        <>
          <RightFloat>
            <SignText>Главный врач {props.chief}</SignText>
          </RightFloat>
          <SignArea />
        </>
      )}

      {props.patient && (
        <>
          <RightFloat>
            <SignText>{props.patient}</SignText>
          </RightFloat>
          <SignArea />
        </>
      )}
    </Container>
  );
};
