import { rgba } from "polished";
import styled from "styled-components";

import { colors } from "../../../styleguide/colors";

import { Container as SidebarHeaderContainer } from "../../../sidebar/SidebarHeader";

import crossIcon from "../icons/cross.inline.svg";

export const headerHeight = 150;

export const Container = styled.div`
  width: 429px;
  height: 100%;
`;

export const ItemWrapper = styled.div``;

export const Categories = styled.div``;

export const Service = styled.div`
  padding: 12px 12px 60px 24px;
`;

export const Header = styled(SidebarHeaderContainer)`
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-height: ${headerHeight}px;
  padding: 24px;
  background-color: ${rgba(colors.gray100, 0.3)};
  font-size: 22px;
  line-height: 22px;
`;

export const Title = styled.div`
  font-size: 22px;
  line-height: 22px;
`;

export const NoResultsMessage = styled.div`
  max-width: 351px;
  margin: 80px auto 0;
  font-size: 17px;
  line-height: 23px;
  color: ${colors.gray700};
  text-align: center;
`;

export const CloseContainer = styled.div`
    position: absolute;
    top: 21px;
    right: 25px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;

export const Close = styled(crossIcon)`
  width: 18px;
  height: 18px;

  path {
    stroke: ${colors.gray400};
  }
`;
