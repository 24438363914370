// @flow
import React, { useEffect, useState, useMemo } from "react";
import { ChartComponent } from "../common/charts/ChartComponent";
import reviewStatisticsStore from "../../stores/reviewStatisticsStore";
import { Box, Paper, Stack } from "@mui/material";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import _ from "lodash";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import * as yup from "yup";
import { filterValidation } from "../common/widgets-components-common/constants";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = "widgetRateBySource";

const sourceOptions = [...reviewStatisticsStore.mapNamesOptions, { value: 'app_internal', label: 'APP Внутренний сервис' }];

const colorsBySource = {
  internal: ["#A9BBFF", "#677EFF", "#3156FF", "#0430F6", "#001C98"],
  app_internal: ["#FCCB98", "#F8BF80", "#FFB163", "#FFA03B", "#FF8500"],
  yandex: ["#FDB0D4", "#E595BB", "#EE7DB6", "#E04E91", "#FF117C"],
  "2gis": ["#BBFFB4", "#96FF8D", "#68FF55", "#24D313", "#0C7A00"],
  about_doctors: ["#FFFD99", "#FFFC80", "#F8F455", "#E7E308", "#858400"]
};

const colorsByRate = {
  1: "#EA0000",
  2: "#0430F6",
  3: "#24D313",
  4: "#E7E308",
  5: "#CF679A"
};

const transformTableData = data => {
  const returnData = [];
  if (!Array.isArray(data)) {
    return returnData;
  }
  const groupedDataBySource = _.groupBy(data, "map_name");
  Object.keys(groupedDataBySource).forEach(source => {
    const groupedDataByRate = _.groupBy(groupedDataBySource[source], "rate");
    Object.keys(groupedDataByRate).forEach(key => {
      returnData.push({
        map_name: source,
        rate: key,
        count: groupedDataByRate[key].reduce((acc, item) => {
          acc += item.count;
          return acc;
        }, 0)
      });
    });
  });
  return returnData;
};

const transformRateDiagram = (data = [], mapNames = [], interval, isCollapseData) => {
  const sortedDataByDate = _.sortBy(data, "date_trunc");
  const groupedDataByDate = _.groupBy(sortedDataByDate, "date_trunc");
  const groupedDataByRate = _.groupBy(data, "rate");
  const groupedDataBySource = _.groupBy(sortedDataByDate, "map_name");
  const labels = Object.keys(groupedDataByDate).filter(label => label !== "undefined");
  const rates = Object.keys(groupedDataByRate)
    .filter(rate => rate !== "undefined")
    .map(rate => parseInt(rate));
  const sources = mapNames.map(source => {
    return {
      source,
      name: reviewStatisticsStore.mapNamesOptionsTableName[source]
    };
  });

  const datasets = sources.reduce((acc, { source, name }) => {
    rates.forEach((rate, index) => {
      const calculatedData = ({ source, rate }) => {
        return labels.map(date => {
          const data = !isCollapseData
            ? groupedDataBySource[source] || []
            : groupedDataByDate[date];
          return data
            .filter(item => item.rate === rate && item.date_trunc === date)
            .reduce((acc, item) => acc + item.count, 0);
        });
      };
      const config = !isCollapseData
        ? {
            label: `${name} - оценка ${rate}`,
            color: colorsBySource[source][index],
            stack: `Stack ${source} ${rate}`
          }
        : {
            label: `Оценка ${rate}`,
            color: colorsByRate[rate]
          };

      acc[!isCollapseData ? `${source}_${rate}` : rate] = {
        ...config,
        data: calculatedData({ source, rate }),
        highlightScope: {
          highlighted: "series",
          faded: "global"
        }
      };
    });

    return acc;
  }, {});

  return {
    labels,
    datasets: Object.values(datasets)
  };
};

export const FILTERS_FIELDS_WIDGET_RATE_BY_SOURCE = () => [
  {
    type: "select",
    name: "type",
    label: "Вид графика",
    xs: 12,
    options: [
      { value: "line", label: "Линейный" },
      { value: "bar", label: "Столбцами" },
      { value: "table", label: "Таблицей" }
    ]
  },
  {
    type: "select",
    name: "map_names",
    label: "Площадка",
    multiple: true,
    xs: 12,
    options: sourceOptions
  },
  {
    type: "select",
    name: "rates",
    label: "Оценки",
    multiple: true,
    xs: 12,
    options: [
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" }
    ]
  },
  {
    type: "checkbox",
    name: "isCollapse",
    label: "Объединить выбранные площадки (только для графиков)",
    xs: 12
  },
  {
    type: "text",
    name: "label",
    label: "Название графика",
    xs: 12
  }
];

export const WidgetRateBySource = observer(
  (props: { filtersData: () => {}, rewriteFilters: () => {}, keyWidget: String }) => {
    const styledPaper = {
      padding: "20px",
      height: "100%",
      display: "flex",
      flexDirection: "column"
    };

    const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
    const [filterFields, setFilterFields] = useState({});
    const [tableData, setTableData] = useState([]);
    const [chartsData, setChartsData] = useState();
    const [label, setLabel] = useState("График оценок по отзывам (с группировкой по площадкам)");
    const [isInitialized, setIsInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const validateSchema = yup.object().shape({
      map_names: yup
        .array()
        .required("площадка")
        .min(1, "площадка")
    });

    useEffect(() => {
      (async () => {
        if (getLocalStorage(NAME_WIDGET + props.keyWidget)) {
          setLocalStorage(
            NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", ""),
            getLocalStorage(NAME_WIDGET + props.keyWidget)
          );
          localStorage.removeItem(NAME_WIDGET + props.keyWidget);
        }
        const filterDiagramLocaleStorage = getLocalStorage(
          NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", "")
        );
        if (
          filterDiagramLocaleStorage &&
          moment(filterDiagramLocaleStorage.date_end).isBefore(moment())
        ) {
          filterDiagramLocaleStorage.date_end = moment();
        }
        const filters =
          props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
        if (filters.label) {
          setLabel(filters.label);
        }
        setFiltersDataDiagram(filters);
        setFilterFields([...FILTERS_FIELDS_WIDGET_RATE_BY_SOURCE()]);
        setIsInitialized(true);
      })();
    }, []);

    useEffect(() => {
      (async () => {
        if (_.size(filtersDataDiagram)) {
          setLoading(true);
          setLocalStorage(
            NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", ""),
            filtersDataDiagram
          );
          if (filtersDataDiagram.label) {
            setLabel(filtersDataDiagram.label);
          }
          const response = await reviewStatisticsStore.getRateDiagramBySource(filtersDataDiagram);
          if (response.error) {
            filterValidation({ validateSchema, filtersDataDiagram, setErrors });
          }
          setChartsData(
            transformRateDiagram(
              response,
              filtersDataDiagram.map_names,
              filtersDataDiagram.interval || "day",
              filtersDataDiagram.isCollapse
            )
          );
          setTableData(transformTableData(response));
          setLoading(false);
        }
      })();
    }, [filtersDataDiagram]);

    useEffect(() => {
      if (props.rewriteFilters) {
        setFiltersDataDiagram({ ...filtersDataDiagram, ...props.rewriteFilters });
      }
    }, [props.rewriteFilters]);

    const submitHandler = (filters, callback) => {
      setErrors([]);
      setFiltersDataDiagram(filters);
      callback();
    };

    const columns = useMemo(
      () => [
        {
          accessorKey: "map_name", //access nested data with dot notation
          header: "Название площадки",
          Cell: ({ row }) => reviewStatisticsStore.mapNamesOptionsTableName[row.original.map_name]
        },
        {
          accessorKey: "rate",
          header: "Оценка"
        },
        {
          accessorKey: "count",
          header: "Количество",
          aggregationFn: "sum",
          AggregatedCell: ({ cell }) => (
            <>
              <Box sx={{ color: "info.main", display: "inline", fontWeight: "bold" }}>
                {cell.getValue()}
              </Box>
            </>
          ),
          Footer: () => (
            <Stack>
              <Box color="primary.main">
                Всего:{" "}
                {tableData.reduce((acc, item) => {
                  acc += item.count;
                  return acc;
                }, 0)}
              </Box>
            </Stack>
          )
        }
      ],
      [tableData]
    );

    const table = useMaterialReactTable({
      columns: columns,
      data: tableData,
      enableFullScreenToggle: false,
      enableGrouping: true,
      initialState: {
        density: "compact",
        grouping: ["map_name"],
        pagination: { pageIndex: 0, pageSize: 300 }
      },
      localization: MRT_Localization_RU,
      isMultiSortEvent: () => true
    });

    return (
      <Paper style={styledPaper}>
        {filterFields && (
          <LabelWithFiltersModal
            fullWidth={true}
            type={"filter"}
            filtersData={filtersDataDiagram}
            filtersFields={filterFields}
            label={label}
            textButtonFilter={"Применить"}
            submitHandler={submitHandler}
          />
        )}
        {(loading || !isInitialized) && <AbsoluteLoader />}
        {isInitialized && (
          <>
            {filtersDataDiagram.type !== "table" && chartsData && (
              <ChartComponent
                textButtonFilter="Применить"
                type={filtersDataDiagram.type || "bar"}
                filtersDataDiagram={filtersDataDiagram}
                nameDiagram="ReviewDiagramBySourceRate"
                data={chartsData}
                interval={filtersDataDiagram?.interval || 'day'}
                errors={errors}
              />
            )}
            {filtersDataDiagram.type === "table" && tableData && (
              <div
                style={{
                  maxHeight: "100%",
                  overflow: "auto"
                }}
                className={"material-table"}>
                <MaterialReactTable table={table} />
              </div>
            )}
          </>
        )}
      </Paper>
    );
  }
);
