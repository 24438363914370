import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Questionnaire {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getQuestionnaire(id) {
    return this.apiService.get(`questionnaire/${id}`);
  }

  updateQuestionnaireValue(id, payload) {
    return this.apiService.patch(`questionnaire_values/${id}`, {}, payload);
  }

  finishQuestionnaire(id) {
    return this.apiService.post(`questionnaire/${id}/finish`);
  }

  patchQuestionnairePartner(id, partnerId) {
    return this.apiService.patch(`questionnaire/${id}`, {}, { partner_id: partnerId });
  }
}

export default new Questionnaire();
