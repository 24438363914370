// @flow
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useStore } from "effector-react";

import type { LayoutFieldValue, TAppointment_id } from "../../domain/entities/Appointment";
import type { FieldFile } from "../../domain/entities/Field";
import {
  appendFileToField,
  removeFileFromField,
  editFileInField
} from "../../stores/appointmentFieldValues";
import { tokenStore } from "../../stores/auth";
import { HeadingH3 } from "../styleguide/typography";
import { FileGroup } from "../file/FileGroup";
import { FileUpload } from "../file/FileUpload";
import { useFileEdit } from "../file-edit-modal/useFileEdit";
import { DownloadTemplate } from "../download-template/DownloadTemplate";
import { ModalPortal } from "../modal/ModalPortal";
import { useConfirm } from "../alert-warning/useConfirm";
import { AlertWarning } from "../alert-warning/AlertWarning";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { useFieldValue } from "../appointment-widgets/useFieldValue";
import apiv2 from '../../apiv2';

const DownloadTemplateWrapper = styled.div`
  width: 100%;
  margin-top: 10px;

  display: flex;
  justify-content: flex-end;
`;

const uploadFiles = ({ token, appointment_id, field_id, layoutFieldValue }) => async newFiles => {
  if (!token) {
    return;
  }

  await Promise.all(
    Object.values(newFiles).map(async newFile => {
      if (!(newFile instanceof File)) {
        return;
      }
      try {
        const uploadOptions = { file: newFile, original_name: newFile.name || "" };
        const result = await apiv2.files.postUploadedFile({ uploadOptions, type: "analysis" });
        appendFileToField({ appointment_id, field_id, file: result, layoutFieldValue });
      } catch (e) {
        console.log(e);
      }
    })
  );
};

const deleteFile = ({ appointment_id, field_id, deleteConfirm, layoutFieldValue }) => async (
  id: number
) => {
  if (!deleteConfirm.requestConfirm) {
    return;
  }
  try {
    const confirmResult = await deleteConfirm.requestConfirm();
    if (!confirmResult) {
      return;
    }
    removeFileFromField({ appointment_id, field_id, file_id: id, layoutFieldValue });
  } catch (e) {
    console.log(e);
  }
};

export const FileWidget = (props: {
  field: FieldFile,
  appointment_id: TAppointment_id,
  layoutFieldValue?: LayoutFieldValue
}) => {
  const token = useStore(tokenStore);
  const { appointment_id, field, layoutFieldValue } = props;

  const { fileEditModal, editFile, lastEditedFile } = useFileEdit();

  const deleteConfirm = useConfirm();

  const onAddFile = uploadFiles({
    appointment_id,
    field_id: field.id,
    token,
    layoutFieldValue: layoutFieldValue && layoutFieldValue.files
  });

  const onDelete = deleteFile({
    appointment_id,
    field_id: field.id,
    deleteConfirm,
    layoutFieldValue: layoutFieldValue && layoutFieldValue.files
  });

  const result = useFieldValue(appointment_id, field.id);
  const files = (result && result.files) || (layoutFieldValue && layoutFieldValue.files) || [];

  const deleteAlertWarning = (
    <AlertWarning
      isNeedConfirm={deleteConfirm.isNeedConfirm}
      sendConfirm={deleteConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Вы уверены, что хотите удалить файл?</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
      )}
    />
  );

  useEffect(() => {
    lastEditedFile &&
      editFileInField({
        appointment_id,
        field_id: field.id,
        file: lastEditedFile,
        layoutFieldValue: layoutFieldValue && layoutFieldValue.files
      });
  }, [lastEditedFile]);

  return (
    <React.Fragment>
      {files && <FileGroup files={files} onDelete={onDelete} onEdit={editFile} />}
      <FileUpload onAddFile={onAddFile} />

      {field.template_file && (
        <DownloadTemplateWrapper>
          <DownloadTemplate href={field.template_file.file_url} />
        </DownloadTemplateWrapper>
      )}

      <ModalPortal>
        {deleteAlertWarning}
        {fileEditModal}
      </ModalPortal>
    </React.Fragment>
  );
};
