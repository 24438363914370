// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import plusIcon from "./icons/plus-magenta.svg";

const Container = styled.button`
  border: none;
  background: none;
  height: 43px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  cursor: pointer;
`;

const AddButtonText = styled.div`
  opacity: 0.8;
  font-family: inherit;
  font-size: 15px;
  line-height: 1.33;
  color: ${colors.magenta};
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AddIcon = styled.div`
  width: 12px;
  height: 12px;
  background: url(${plusIcon});
  background-size: cover;
  flex-shrink: 0;
`;

export const AddButton = (props: { text: string, onClick: () => mixed }) => {
  const { text, ...otherProps } = props;
  return (
    <Container {...otherProps}>
      <AddIcon />
      <AddButtonText title={text}>{text}</AddButtonText>
    </Container>
  );
};
