import React from "react";
import styled from "styled-components";
import { observer } from 'mobx-react';

import imageViewerStore from '../../stores/imageViewerStore';
import { ArrowLeftIcon } from './icons/ArrowLeft';
import { ArrowRightIcon } from './icons/ArrowRight';
import { CrossIcon } from './icons/Cross';

const ImageViewerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(57, 23, 23, 0.6);
  z-index: 51;
`;

const ImageViewerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ImageViewerImage = styled.img`
  max-height: calc(100vh - 120px);
  max-width: calc(100vw - 360px);
  
  @media screen and (max-width: 576px) {
    max-height: calc(100vh - 40px);
    max-width: calc(100vw - 80px);
  }
`;

const ImageViewerButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  background: transparent;
  --svg-fill: #DCD3D2;
  
  &:not([disabled]):hover {
    --svg-fill: #fff;
  }
  
  &[disabled] {
    cursor: default;
    opacity: 0.4;
  }
`;

const ImageViewerButtonClose = styled(ImageViewerButton)`
  top: 60px;
  right: 60px;
  width: 40px;
  height: 40px;
  z-index: 2;

  @media screen and (max-width: 576px) {
    top: 20px;
    right: 20px;
  }
`;

const ImageViewerButtonNavigation = styled(ImageViewerButton)`
  top: 50%;
  transform: translateY(-50%);
  height: 180px;
  width: 180px;

  @media screen and (max-width: 576px) {
    height: 40px;
    width: 40px;
  }
`;

const ImageViewerButtonPrevious = styled(ImageViewerButtonNavigation)`
  left: 0;
`;

const ImageViewerButtonNext = styled(ImageViewerButtonNavigation)`
  right: 0;
`;

export const ImageViewer = observer(() => {
  const containerRef = React.useRef(null);
  const handleEmptySpaceClick = (e) => {
    if (e.target === containerRef.current) {
      imageViewerStore.close();
    }
  };

  return imageViewerStore.isViewerVisible && imageViewerStore.hasImages ? (
    <ImageViewerWrapper>
      <ImageViewerContainer ref={containerRef} onClick={handleEmptySpaceClick}>
        <ImageViewerButtonClose onClick={() => imageViewerStore.close()}>
          <CrossIcon/>
        </ImageViewerButtonClose>
        {
          imageViewerStore.hasPreviousImage && (
            <ImageViewerButtonPrevious onClick={() => imageViewerStore.goToPreviousImage()}>
              <ArrowLeftIcon/>
            </ImageViewerButtonPrevious>
          )
        }
        <ImageViewerImage src={imageViewerStore.currentImageUrl}/>
        {
          imageViewerStore.hasNextImage && (
            <ImageViewerButtonNext disabled={!imageViewerStore.hasNextImage} onClick={() => imageViewerStore.goToNextImage()}>
              <ArrowRightIcon/>
            </ImageViewerButtonNext>
          )
        }
      </ImageViewerContainer>
    </ImageViewerWrapper>
  ) : null;
});
