import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import _ from "lodash";
import { styled } from "@mui/material/styles";

const TextFieldStyled = styled(TextField)(
  () => `
  .MuiInputBase-input {
    padding: 0;
    height: 36px;
    font-size: 14px;
  }
`
);

export const CorporateSearchInput = ({
  label = "",
  value = "",
  setValue,
  disabled = false,
  isShowClearIcon = true
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnChangeValue = event => setInputValue(event.target.value);
  const handleOnClearValue = () => setInputValue("");

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    const debouncedOnChange = _.debounce(() => {
      setValue(inputValue);
    }, 300);

    debouncedOnChange();
    return () => {
      debouncedOnChange.cancel();
    };
  }, [inputValue]);

  return (
    <div className="search-input">
      <TextFieldStyled
        placeholder={label}
        value={inputValue}
        disabled={disabled}
        type="text"
        onChange={handleOnChangeValue}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: isShowClearIcon && !!inputValue.length && (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleOnClearValue}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </div>
  );
};
