import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class MessageAttachments {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  send(data) {
    const formData = new FormData();
    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((item) => {
          formData.append(key, item);
        })
      } else {
        formData.append(key, data[key]);
      }
    }
    console.log(formData);
    return this.apiService.post("attachments", {}, formData, {}, false);
  }
}

export default new MessageAttachments();
