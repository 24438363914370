// @flow
import styled from "styled-components";
import { colors } from "../styleguide/colors";

export const PrintableGroupTitle = styled.span`
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: ${colors.black};
`;
