import React from "react";

import * as S from "./styled/ServicesTreeService.styled";

export const ServicesTreeService = ({ onToggleFavorite, favorite, title, code, categoriesText }) => {
    return <S.Container onClick={onToggleFavorite} active={favorite}>
        <S.Code>{code}</S.Code>
        <S.Title>{title} <small>{categoriesText}</small></S.Title>
        <S.StarIcon/>
    </S.Container>
};
