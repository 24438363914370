// @flow
import React, { useMemo } from "react";
import moment from "moment";
import styled from "styled-components";
import type { Checklist } from "../../domain/entities/Checklist";
import filledIcon from "./icons/filled.svg";
import { PNormal } from "../styleguide/typography";
import o, { type ExtractOverridesProps } from "react-overrides";

const Container = styled.div`
  padding: 0 32px;
  display: flex;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${filledIcon});

  flex-shrink: 0;
`;

const TextContainer = styled.div`
  margin-left: 8px;
`;

export const ReadableChecklistItem = (props: {
  checklist?: Checklist,
  children?: React$Node,
  overrides?: ExtractOverridesProps<{
    Container: typeof Container,
    Icon: typeof Icon,
    TextContainer: typeof TextContainer
  }>
}) => {
  const { checklist, children } = props;

  const dateRange = useMemo(() => {
    if (!checklist || !checklist.dateRange) {
      return null;
    }

    const [startDate, endDate] = Array.isArray(checklist.dateRange)
      ? checklist.dateRange
      : checklist.dateRange.dates;

    if (moment(startDate).isSame(endDate, "d")) {
      return `(сдать ${startDate})`;
    }

    return `(сдать с ${startDate} до ${endDate})`;
  }, [checklist]);

  return (
    <Container {...o}>
      <Icon {...o} />
      <TextContainer {...o}>
        {checklist && (
          <PNormal>
            {checklist.name} {dateRange} {checklist.comment}
          </PNormal>
        )}
        {children && <PNormal>{children}</PNormal>}
      </TextContainer>
    </Container>
  );
};
