// @flow
import { Paper } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { observer } from "mobx-react";
import doctorsStore from "../../stores/doctorsStore";
import { AbsoluteLoader } from "../loader/Loader";

export const FILTERS_FIELDS_WIDGET_DOCTORS_IN_CHAT = () => [];

export const WidgetDoctorsInChat = observer(() => {
    const styledPaper = {
      padding: "20px",
      height: "100%",
      display: "flex",
      flexDirection: "column"
    };

    const [tableData, setTableData] = useState([]);
    const [label, setLabel] = useState("Врачи подключенные к чатам");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      (async () => {
        setLoading(true);
        const response = await doctorsStore.getAnalyticsDoctorsInChat();
        setTableData(response.sort((a, b) => a.name.localeCompare(b.name)));
        setLoading(false);
      })();
    }, []);

    const columns = useMemo(
      () => [
        {
          accessorKey: "name",
          header: "Город"
        },
        {
          accessorKey: 'count_all',
          size: 220,
          header: "Подключенные",
          Cell: ({ row }) => row.original.count_all?.toLocaleString() || "0",
        },
        {
          accessorKey: 'count_actual',
          size: 220,
          header: "С актуальным расписанием",
          Cell: ({ row }) => row.original.count_actual?.toLocaleString() || "0",
        }
      ],
      []
    );

    const table = useMaterialReactTable({
      columns: columns,
      data: tableData,
      enableFullScreenToggle: false,
      enablePagination: false,
      enableHiding: false,
      enableColumnFilters: false,
      enableGlobalFilter: false,
      enableDensityToggle: false,
      initialState: {
        density: "compact"
      },
      localization: MRT_Localization_RU,
      isMultiSortEvent: () => true
    });

    return (
      <Paper style={styledPaper}>
        <LabelWithFiltersModal fullWidth={true} label={label} />
        {loading && <AbsoluteLoader />}
        {!!tableData.length && (
          <div
            style={{
              maxHeight: "100%",
              overflow: "auto"
            }}
            className={"material-table"}>
            <MaterialReactTable table={table} />
          </div>
        )}
      </Paper>
    );
  }
);
