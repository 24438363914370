//@flow
import * as React from "react";
import styled from "styled-components";

import { PLarge } from "../styleguide/typography";

const Container = styled.div`
  width: 100%;
  padding: 77px 0 104px;
  display: flex;
  justify-content: center;
  opacity: 0.5;
`;

export const AppointmentHistoryTableStub = () => (
  <Container>
    <PLarge modifiers="center">У вас еще не было пациентов</PLarge>
  </Container>
);
