// @flow
import React from "react";
import styled from "styled-components";
import type { Checklist } from "../../domain/entities/Checklist";
import { GroupTitle } from "./GroupTitle";
import { GroupContainer } from "./GroupContainer";
import { ReadableChecklistList } from "./ReadableChecklistList";

const ChecklistContainer = styled.div`
  margin-top: 16px;
`;

export const ReadableGroupedChecklistList = (props: { items: Array<Checklist> }) => {
  const { items } = props;
  return (
    <>
      {items.map(checklist => (
        <GroupContainer key={checklist.guid}>
          <GroupTitle title={checklist.name} />
          {checklist.items && (
            <ChecklistContainer>
              <ReadableChecklistList items={checklist.items} />
            </ChecklistContainer>
          )}
        </GroupContainer>
      ))}
    </>
  );
};
