// @flow
import React from "react";
import * as moment from "moment";
import { LineChart } from '@mui/x-charts/LineChart';
import { INTERVAL_FORMAT } from "../../../../stores/reviewStatisticsStore";

export function LineChartMUI(props: {
  label: string,
  options?: any,
  valueFormatter?: any,
  data: any,
  width?: number,
  height?: number,
  hiddenLegend?: boolean,
}) {
  const hidden = props.hiddenLegend !== undefined ? props.hiddenLegend : true;
  const data = props.data;
  data.datasets = data.datasets.filter((item) => item.data.length);

  return <LineChart
    skipAnimation
    width={props.width}
    height={props.height}
    data={data}
    margin={props.margin || { top: hidden ? 10 : 100 }}
    series={data.datasets.slice()}
    tooltip={{
      trigger: window.innerWidth < 768 ? "none" : "axis",
      slotProps: {
        popper: {
          placement: "auto",
        },
      },
      classes: {
        root: 'chart-tooltip-root',
        row: 'chart-tooltip-row'
      }
    }}
    xAxis={[{
      scaleType: 'point',
      data: data.labels,
      valueFormatter: props.valueFormatter ? props.valueFormatter : (date) => moment(date).format(INTERVAL_FORMAT[props.interval])
    }]}
    slotProps={{
      legend: {
        labelStyle: {
          fontSize: 10,
        },
        hidden: hidden,
        position: {
          vertical: 'top',
          horizontal: 'left',
        },
        itemMarkWidth: 20,
        itemMarkHeight: 2,
        markGap: 5,
        itemGap: 10,
      }
    }}
  />
}
