// @flow
import type { Organization } from "../../domain/entities/Organization";

export const useContacts = (organization: ?Organization): string | Array<string> => {
  if (!organization) {
    return "";
  }

  return organization && organization.phone ? [organization.phone] : "Нет номера";
};
