// @flow
import { useEffect } from "react";

class Events {
  listeners: { [string]: any[] } = {};

  dispatch = (event: string, data: any) => {
    if (this.listeners[event]) {
      this.listeners[event].forEach(listener => {
        listener(data);
      });
    }
  };

  on = (event: string, cb: any) => {
    if (!this.listeners[event]) this.listeners[event] = [];
    this.listeners[event].push(cb);

    return () => {
      this.listeners[event] = this.listeners[event].filter(listener => listener !== cb);
    };
  };

  off = (event: string, cb: any) => {
    if (!this.listeners[event]) return;

    this.listeners[event] = this.listeners[event].filter(listener => listener !== cb);
  };
}

const events = new Events();

export const useSubscribe = (event: string, cb: any) => {
  useEffect(() => {
    events.on(event, cb);

    return () => events.off(event, cb);
  });
};

export const unsubscribe = (event: string, cb: any) => {
  events.off(event, cb);
};

export const dispatchEvent = events.dispatch;
