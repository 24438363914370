// @flow
import * as React from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import { selectCustomStyles } from "./constants";
import { colors } from "../styleguide/colors";
import { usePortal } from "../../utils/usePortal";

const SelectOption = styled.div`
  padding: 6.5px 8px;
  color: ${colors.black};
  background: ${({ color }) => color};
  border-radius: 4px;
  font-size: 14px;
`;

const EmbryoTableGenericSelectOption = (props: any) => {
  return (
    <components.Option {...props}>
      <SelectOption color={props.data.color}>{props.data.label}</SelectOption>
    </components.Option>
  );
};

const EmbryoTableMultiValueRemove = (props: any) => {
  return props.selectProps.menuIsOpen && <components.MultiValueRemove {...props} />;
};

export const EmbryoTableGenericSelect = ({
  value,
  onChange,
  isEdit,
  isDisabled,
  options,
  styles,
  isMulti,
  noOptionsMessage = "Выбраны все значения"
}: {
  value: any,
  onChange?: any,
  options: any[],
  styles: any,
  isEdit?: boolean,
  isDisabled?: boolean,
  isMulti?: boolean,
  noOptionsMessage?: string
}) => {
  const element = usePortal("table-elements-root");
  return (
    <Select
      value={value}
      options={options}
      isMulti={isMulti}
      autoFocus={isEdit}
      onChange={onChange}
      menuIsOpen={isEdit}
      isDisabled={isDisabled}
      menuPosition={"fixed"}
      defaultMenuIsOpen={isEdit}
      styles={{ ...selectCustomStyles, ...styles }}
      noOptionsMessage={() => noOptionsMessage}
      menuPortalTarget={element}
      menuShouldScrollIntoView={false}
      minMenuHeight={220}
      placeholder=""
      components={{
        IndicatorSeparator: () => null,
        IndicatorsContainer: () => null,
        Option: EmbryoTableGenericSelectOption,
        MultiValueRemove: EmbryoTableMultiValueRemove
      }}
      isClearable
      isSearchable
    />
  );
};
