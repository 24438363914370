import React, { useCallback, useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";

import { DateInput } from "../form-elements/DateInput";
import { TimeInput } from "../form-elements/TimeInput";

import BellIcon from "./icons/bell.inline.svg";

import * as S from "./styled/PatientReminder.styled";

export const PatientReminder = ({ text, date, time, valid, datetimeError, onChange }) => {
  const $container = useRef();
  const $text = useRef();

  const [edit, setEdit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const hasContent = text || date || time;
  const hasAllContent = text && date && time;

  useClickAway($container, () => {
    if (!hasContent) {
      setEdit(false);
      setShowAlert(false);
    } else if (!time || !date || !text) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      setEdit(!valid);
    }
  });

  useEffect(() => {
    if (edit) {
      $text.current.focus();
    }
  }, [edit]);

  const handleEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const handleChangeText = useCallback(
    e => {
      onChange("text", e.target.value);
    },
    [onChange]
  );

  const handleResetClick = useCallback(
    e => {
      e.stopPropagation();
      onChange("text", "");
      onChange("date", "");
      onChange("time", "");
    },
    [onChange]
  );

  return (
    <S.Container edit={edit} ref={$container} onClick={handleEdit}>
      <S.ContainerWithIcon>
        <BellIcon />
        <S.Title active={edit || hasContent}>Напомнить о пациенте</S.Title>
      </S.ContainerWithIcon>
      <S.Form visible={edit}>
        <S.Item>
          <S.Text
            ref={$text}
            placeholder="О чем напомнить"
            value={text}
            onChange={handleChangeText}
          />
        </S.Item>
        <S.Item>
          <DateInput
            value={date}
            invalid={datetimeError}
            onChange={value => onChange("date", value)}
          />
        </S.Item>
        <S.Item>
          <TimeInput
            value={time}
            invalid={datetimeError}
            onChange={value => onChange("time", value)}
          />
        </S.Item>
      </S.Form>
      {showAlert && !hasAllContent && <S.Alert>Заполните все поля</S.Alert>}
      {!edit && valid && (
        <S.Content>
          {date} в {time} <br />
          {text}
        </S.Content>
      )}
      {!edit && valid && <S.Cross onClick={handleResetClick} />}
    </S.Container>
  );
};
