import React, {useState, useEffect, useMemo} from 'react';
import {TableWrapper} from '../prescription-table/TableWrapper';
import {changeTableValue} from '../../stores/appointmentFieldValues';
import {getField_id} from '../../domain/entities/Field';
import {TableContainer} from '../prescription-table/TableContainer';
import styled from 'styled-components';
import {colors} from '../styleguide/colors';

import {TableAddRowButton} from '../table/TableAddRowButton';
import {TableHeadCell} from '../table/TableHeadCell';

import {TableEditableCell} from '../table/TableEditableCell';
import {v4 as uuid} from 'uuid';

import closeIcon from './icons/close.svg';
import {TableBottomCell} from '../table/TableBottomCell';

const NewRowButtonContainer = styled.div`
  padding-top: 20px;
  padding-left: 14px;
  position: relative;
  //z-index: 1;
  background-color: ${colors.white};
`;

const TableContainerWrapper = styled.div`
  width: 100%;
  overflow-y: visible;
`;

const CustomTableContainer = styled(TableContainer)`
  margin: 0 -24px;
  padding: 0 24px;
`;

const RemoveRowButton = styled.button`
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  background: url(${closeIcon}) no-repeat center;
  background-size: 20px;
  cursor: pointer;
  opacity: 0.6;
`;

const TableElement = styled.table`
  width: 100%;
  
  tr {
    position: relative;
    
    &:hover ${RemoveRowButton} {
      display: flex;
    }
  }
`;

const AddRows = styled.div`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  padding: 6px 10px;
  font-size: 15px;
  color: #262626;
  cursor: pointer;

  & > .sc-add-rows-caption {
    opacity: 0.3;
    transition: 0.2s opacity;
    display: flex;
    align-items: center;
  }

  &:hover > .sc-add-rows-caption {
    opacity: 0.75;
  }

  &:hover {
    & input {
      outline: 1px solid ${colors.gray300};
    }
  }
`;

const RowsNumber = styled.input`
  box-sizing: border-box;
  width: 29px;
  padding: 6px;
  margin-left: 4px;
  border: none;
  border-radius: 4px;
  outline: none;
  opacity: 0.3;
  transition: 0.2s opacity;

  &:focus {
    opacity: 0.75;
    outline: 1px solid ${colors.gray300};
  }

  &:focus ~ .sc-add-rows-caption {
    color: red;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const TableWidget = (props) => {
  const {editable, appointment_id, field, layoutFieldValue} = props;
  const {table_description} = field;

  const emptyRow = useMemo(() => {
    return table_description.columns.reduce((acc, column) => {
      acc[column.key] = '';
      return acc;
    }, {});
  }, [appointment_id, table_description]);

  const initialRows = useMemo(() => {
    return layoutFieldValue ? layoutFieldValue.value : Array.from({length: table_description.rows_count || 0}).map(() => {
      const newRow = {...emptyRow};
      newRow.id = uuid();
      return newRow;
    });
  }, [appointment_id, layoutFieldValue, table_description]);

  const minRowsCountToAdd = 1;
  const maxRowsCountToAdd = 60;
  const [rowsCount, setRowsCount] = React.useState(minRowsCountToAdd);
  const [rowsValues, setRowsValues] = useState(initialRows);

  const $tableContainerRef = React.useRef(null);
  const $tableBordersRef = React.useRef(null);

  const updateTableBorders = () => {
    if ($tableContainerRef.current) {
      $tableBordersRef.current = $tableContainerRef.current.getBoundingClientRect();
    }
  };

  const scrollTableContainer = (value) => {
    if ($tableContainerRef.current) {
      $tableContainerRef.current.scrollLeft = $tableContainerRef.current.scrollLeft + value;
    }
  };

  const updateTableValues = (newValues) => {
    setRowsValues(newValues);
    if (editable) {
      changeTableValue({
        field_id: getField_id(field),
        appointment_id,
        tableValues: newValues
      });
    }
  };

  const tableHead = useMemo(() => {
    return table_description.columns.map(column => (
      <TableHeadCell
        key={`header-${column.key}`}
        values={column.title instanceof Array ? column.title : [column.title]}
        style={{
          width: column.width || 'auto',
          minWidth: column.minWidth || column.width || 'auto',
          maxWidth: editable ? column.maxWidth || column.width || 'auto' : 'auto'
        }}
      />
    ));
  }, [appointment_id, table_description]);

  const tableBottom = useMemo(() => {
    return table_description.bottom_title ? table_description.columns.map(column => (
      <TableBottomCell
        key={`bottom-${column.key}`}
        values={column.bottom_title instanceof Array ? column.bottom_title : [column.bottom_title]}
        style={{
          width: column.width || 'auto',
          minWidth: column.minWidth || column.width || 'auto',
          maxWidth: editable ? column.maxWidth || column.width || 'auto' : 'auto'
        }}
      />
    )) : null;
  }, [appointment_id, table_description]);

  const tableBody = useMemo(() => {
    return rowsValues.map((rowValue, rowIndex) => {
      const updateRowValue = (columnKey) => (value) => {
        const newValues = rowsValues.map((currentRowValue) => {
          if (rowValue.id === currentRowValue.id) {
            currentRowValue[columnKey] = value;
          }
          return currentRowValue;
        });
        updateTableValues(newValues);
      };

      const removeRow = () => {
        const newValues = rowsValues.filter(value => rowValue.id !== value.id);
        updateTableValues(newValues);
      };

      const isLastRow = rowIndex === rowsValues.length - 1;

      return (
        <tr key={rowValue.id}>
          {table_description.columns.map((column) => (
            <TableEditableCell
              disabled={!editable}
              key={`body-${column.key}`}
              type={column.type}
              field={table_description.fields?.[column.key] || null}
              style={{
                width: column.width || 'auto',
                minWidth: column.minWidth || column.width || 'auto',
                maxWidth: editable ? column.maxWidth || column.width || 'auto' : 'auto',
                overflow: editable ? 'hidden' : 'visible',
                whiteSpace: editable ? 'nowrap' : '',
                textOverflow: editable ? 'ellipsis' : '',
                textAlign: column.align || 'center'
              }}
              hasBottomTitle={isLastRow && table_description.bottom_title}
              initialValue={rowValue[column.key]}
              onChange={updateRowValue(column.key)}
              tableBorders={$tableBordersRef.current}
              scrollTableContainer={scrollTableContainer}
            />
          ))}
          {
            editable && (
              <td style={{
                width: 0
              }}>
                {
                  !table_description.disable_remove_rows && (
                    <RemoveRowButton tabIndex={2} type="button" onClick={removeRow}/>
                  )
                }
              </td>
            )
          }
        </tr>
      );
    });
  }, [appointment_id, table_description, rowsValues, $tableBordersRef.current]);

  const handleAddRows = (e) => {
    if (e.nativeEvent.pointerId === -1) {
      return;
    }
    const newValues = [...rowsValues, ...Array.from({length: rowsCount || 1}).map(() => {
      const newRow = {...emptyRow};
      newRow.id = uuid();
      return newRow;
    })];
    updateTableValues(newValues);
    updateTableBorders();
  };

  useEffect(() => {
    if (layoutFieldValue) {
      setRowsValues(layoutFieldValue.value || initialRows);
      updateTableBorders();
      return;
    }
    setRowsValues(initialRows);
    updateTableBorders();
  }, [layoutFieldValue]);

  return (
    <>
      <TableWrapper>
        <TableContainerWrapper>
          <CustomTableContainer ref={$tableContainerRef}>
            <TableElement>
              <thead>
              <tr>
                {tableHead}
                {
                  editable && (
                    <th style={{
                      width: 0
                    }}></th>
                  )
                }
              </tr>
              </thead>
              <tbody>
              {tableBody}
              {
                table_description.bottom_title && (
                  <tr>
                    {tableBottom}
                  </tr>
                )
              }
              </tbody>
            </TableElement>
          </CustomTableContainer>
        </TableContainerWrapper>
      </TableWrapper>
      {
        table_description.add_row_button.visible && editable && (
          <NewRowButtonContainer>
            <AddRows>
              <div className="sc-add-rows-caption" style={{opacity: 1}}>
                <TableAddRowButton tabIndex={3} onClick={handleAddRows}>{table_description.add_row_button.text}</TableAddRowButton>
              </div>
              {
                table_description.add_row_button.type === 'multiple' && (
                  <RowsNumber
                    min={minRowsCountToAdd}
                    max={maxRowsCountToAdd}
                    value={rowsCount}
                    type="number"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAddRows(e);
                      }
                    }}
                    onInput={e => {
                      const newValue = parseInt(e.target.value, 10);
                      if (isNaN(newValue)) {
                        setRowsCount(minRowsCountToAdd);
                        return;
                      }
                      if (newValue > maxRowsCountToAdd) {
                        setRowsCount(maxRowsCountToAdd);
                        return;
                      }
                      setRowsCount(newValue);
                    }}
                  />
                )
              }
            </AddRows>
          </NewRowButtonContainer>
        )
      }
    </>
  );
};
