// @flow
import React from "react";
import { WidgetsComponent } from "../common/widgets-components-common/WidgetsComponent";
import styled from "styled-components";
import {
  DASHBOARD_KEYS,
  GET_FIELDS_FOR_WIDGETS,
  GET_GENERATE_WIDGETS, GET_OPTIONS_FOR_WIDGET
} from "../common/widgets-components-common/constants";

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const DashboardPage = () => {
  return (
    <Container>
      <WidgetsComponent
        dashboardLabel="Dashboard"
        defaultData={[
          {
            "name": "widgetConciergeAnalyticsMetric",
            "filters": {
              "type": "line",
              "isCollapse": true,
              "label": "Консьерж",
              "organization_ids": [
                20,
                27,
                23,
                21,
                29,
                12,
                25,
                28,
                31,
                1,
                22,
                9,
                30,
                14,
                4,
                6,
                18,
                13,
                17,
                5,
                10,
                15,
                7,
                26,
                8,
                24,
                0
              ]
            },
            "key": "w0",
            "grid": {
              "i": "w0",
              "x": 0,
              "y": 0,
              "w": 6,
              "h": 3,
              "resizeHandles": [
                "s",
                "w",
                "e",
                "n",
                "sw",
                "nw",
                "se"
              ]
            }
          },
          {
            "name": "widgetRate",
            "filters": {
              "type": "bar",
              "isCollapse": true,
              "label": "Отзывы - оценки",
              "organization_ids": [
                20,
                27,
                23,
                21,
                29,
                12,
                25,
                28,
                31,
                1,
                22,
                9,
                30,
                14,
                4,
                6,
                18,
                13,
                17,
                5,
                10,
                15,
                7,
                26,
                8,
                24
              ]
            },
            "key": "w1",
            "grid": {
              "i": "w1",
              "x": 6,
              "y": 0,
              "w": 6,
              "h": 3,
              "resizeHandles": [
                "s",
                "w",
                "e",
                "n",
                "sw",
                "nw",
                "se"
              ]
            }
          },
          {
            "name": "widgetPaymentSummary",
            "filters": {
              "type": "line",
              "valueField": "amount",
              "isCollapse": true,
              "label": "Онлайн платежи - сумма",
              "organization_ids": [
                20,
                27,
                23,
                21,
                29,
                12,
                25,
                28,
                31,
                1,
                22,
                9,
                30,
                14,
                4,
                6,
                18,
                13,
                17,
                5,
                10,
                15,
                7,
                26,
                8,
                24
              ]
            },
            "key": "w2",
            "grid": {
              "i": "w2",
              "x": 0,
              "y": 3,
              "w": 6,
              "h": 3,
              "resizeHandles": [
                "s",
                "w",
                "e",
                "n",
                "sw",
                "nw",
                "se"
              ]
            }
          },
          {
            "name": "widgetPaymentSummary",
            "filters": {
              "type": "line",
              "valueField": "count",
              "isCollapse": true,
              "label": "Онлайн платежи - количество",
              "organization_ids": [
                20,
                27,
                23,
                21,
                29,
                12,
                25,
                28,
                31,
                1,
                22,
                9,
                30,
                14,
                4,
                6,
                18,
                13,
                17,
                5,
                10,
                15,
                7,
                26,
                8,
                24
              ]
            },
            "key": "w3",
            "grid": {
              "i": "w3",
              "x": 6,
              "y": 3,
              "w": 6,
              "h": 3,
              "resizeHandles": [
                "s",
                "w",
                "e",
                "n",
                "sw",
                "nw",
                "se"
              ]
            }
          }
        ]}
        fieldsWidgets={GET_FIELDS_FOR_WIDGETS(DASHBOARD_KEYS)}
        generateWidgets={GET_GENERATE_WIDGETS(DASHBOARD_KEYS)}
        optionsField={GET_OPTIONS_FOR_WIDGET(DASHBOARD_KEYS)}
        nameLocalStorage="DashboardPageWidgetComponents"
      />
    </Container>
  );
};
