// @flow
import { useAutoFocusRef } from "./useAutoFocusRef";

export const AutoFocusWrapper = (props: {
  children: (ref: { current: ?HTMLElement }) => React$Node,
  elementRef?: { current: ?HTMLInputElement }
}) => {
  const { children, elementRef } = props;
  const ref = useAutoFocusRef(elementRef);

  return children(ref);
};
