// @flow
import React from "react";
import styled from "styled-components";
import type { Checklist } from "../../domain/entities/Checklist";
import { ReadableChecklistItem } from "./ReadableChecklistItem";

const Container = styled.div`
  & > * + * {
    margin-top: 21px;
  }
`;

export const ReadableChecklistList = (props: {
  items?: Array<Checklist>,
  children?: React$Node
}) => {
  const { items, children } = props;
  return (
    <Container>
      {items && items.map(item => <ReadableChecklistItem key={item.guid} checklist={item} />)}
      {children}
    </Container>
  );
};
