// @flow
import { setShortcutsPointer, shortcutsPointer, allShortcuts } from "./shortcutsShared";
import { isShortcutForAppointmentServices } from "../../domain/value-objects/TShortcuts";
import * as R from "ramda";
import type {TChecklistDestination, TShortcutsForServices} from "../../domain/value-objects/TShortcuts";
import { createEvent, forward, merge } from "effector";
import {
  openShortcutsCreate,
  openShortcutsForAppointmentServices
} from "./shortcutsSections";

export const isCurrentAppointmentServices = shortcutsPointer.map<*>(
  pointer => (pointer && pointer.type === "appointment_services") || false
);

export const appointmentServicesShortcuts = allShortcuts.map<Array<TShortcutsForServices>>(
  R.filter(isShortcutForAppointmentServices)
);

export const isHaveAppointmentServicesShortcuts = appointmentServicesShortcuts.map<*>(shortcuts => shortcuts.length > 0);

export const isNotEmptyShortcut = (fieldName: TChecklistDestination) =>
    appointmentServicesShortcuts.getState().some(el => el.destination_type === fieldName);

export const createShortcuts = createEvent<void>();
export const openShortcuts = createEvent<void>();

forward({
  from: createShortcuts,
  to: openShortcutsCreate
});
forward({
  from: openShortcuts,
  to: openShortcutsForAppointmentServices
});

forward({
  from: merge([createShortcuts, openShortcuts]).map(() => ({ type: "appointment_services" })),
  to: setShortcutsPointer
});
