// @flow
import * as React from "react";
import { useState, useMemo } from "react";
import moment from "moment";

import type { UploadedFile } from "../../domain/entities/UploadedFile";
import type { UploadsPatchParams } from "../../domain/value-objects/uploadedFile";
import { READABLE_DATE_FORMAT } from "../../domain/services/field";
import { formatFileDateToNumericDateFullYear } from "../../domain/services/uploadedFile";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { DeleteButton } from "../buttons/DeleteButton";
import { FieldsetVertical } from "../form/FieldsetVertical";
import { DefaultInput } from "../form-elements/DefaultInput";
import { DateInput } from "../form-elements/legacy/DateInput";
import {
  OverridedPrimaryButtonContainer,
  FileEditFormLayout,
  InputContainer
} from "./FileEditFormLayout";

const inputOverrides = {
  Container: {
    component: InputContainer
  },
  Input: {
    props: {
      modifiers: "big"
    }
  }
};

const isValidDate = date =>
  date && (date.length === 10 && moment(date, READABLE_DATE_FORMAT).isValid());
const datesAreSame = (firstDate: string, secondDate: string): boolean => {
  return moment(firstDate, READABLE_DATE_FORMAT) === moment(secondDate, READABLE_DATE_FORMAT);
};

export const UploadFileEditForm = (props: {
  file: UploadedFile,
  onSubmit: (params: UploadsPatchParams) => mixed,
  onRemove: (file: UploadedFile) => mixed
}) => {
  const { file, onSubmit, onRemove } = props;

  const dateIsEditable = true;

  const [date, setDate] = useState(formatFileDateToNumericDateFullYear(file.date, false));
  const [name, setName] = useState(file.name);

  const isValidFileDate = useMemo(() => isValidDate(date), [date]);

  const submitForm = () => {
    const formattedDate = date && moment(date, READABLE_DATE_FORMAT).format("YYYY-MM-DDTHH:MM:SS");
    const dateHasNotChanged = datesAreSame(formattedDate, file.date);
    onSubmit({
      id: file.id,
      original_name: name,
      upload_date: dateHasNotChanged ? file.date : formattedDate
    });
  };

  const remove = () => {
    onRemove(file);
  };

  const buttons = (
    <>
      <PrimaryButton
        disabled={!isValidFileDate || !name}
        primaryButtonContainer={OverridedPrimaryButtonContainer}
        onClick={submitForm}>
        Сохранить изменения
      </PrimaryButton>
      <DeleteButton onClick={remove}>Удалить</DeleteButton>
    </>
  );

  return (
    <FileEditFormLayout buttons={buttons}>
      <FieldsetVertical legend={"Дата*:"}>
        <DateInput
          disabled={!dateIsEditable}
          value={date}
          handleChange={setDate}
          overrides={inputOverrides}
          maxDateToday
        />
      </FieldsetVertical>

      <FieldsetVertical legend={"Название файла:"}>
        <DefaultInput value={name} handleChange={setName} overrides={inputOverrides} />
      </FieldsetVertical>
    </FileEditFormLayout>
  );
};
