import styled from "styled-components";

import { colors } from "../../styleguide/colors";

export const Container = styled.div`
  background: ${colors.white};
  padding: 30px 42px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    padding: 2px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(184 184 184 / 50%);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const TableContainer = styled.div`
  & table {
    min-width: 607px;
  }

  & td {
    height: 44px;
    padding: 0 12px;
    vertical-align: middle;
    border-bottom: 1px solid rgb(68, 68, 68, 0.1);
    border-right: 1px solid rgb(68, 68, 68, 0.1);
    text-align: left;
    box-sizing: border-box;
    user-select: none;
    font-size: 14px;

    & span {
      color: ${colors.darkBlack};
    }

    &:first-child {
      border-left: none;
      cursor: default;
    }

    &:last-child {
      border-right: none;
    }
  }
`;

export const Title = styled.h2`
  font-size: 30px;
  margin-bottom: 25px;
`;

export const ColumnIndicator = styled.th`
  border: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid #c6007f;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
  font-size: 14px;
  padding: 0 12px;
  height: 44px;
  font-weight: 500;
  vertical-align: middle;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  &:nth-child(1) {
    width: 317px;
  }

  &:nth-child(2) {
    width: 110px;
  }
`;

export const InputContainer = styled.div`
  width: ${p => p.width || 200}px;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 17px rgba(91, 91, 91, 0.259752);
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
