// @flow
import styled from "styled-components";
import logo from "./icons/logo.svg";

export const Logo = styled.img.attrs(props => ({
  src: props.logoUrl ? props.logoUrl : logo
}))`
  width: 175px;
  height: 77px;
  object-fit: scale-down;
  object-position: left;
`;
