"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withModifiers = withModifiers;

var _styledComponentsModifiers = require("styled-components-modifiers");

function withModifiers(config) {
  return function (callback) {
    var result = callback((0, _styledComponentsModifiers.applyStyleModifiers)(config));

    if (process.env.NODE_ENV !== 'production') {
      result.propTypes = {
        modifiers: (0, _styledComponentsModifiers.styleModifierPropTypes)(config)
      };
      result.possibleModifiers = Object.keys(config);
    }

    return result;
  };
}