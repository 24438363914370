// @flow
import * as React from "react";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";
import { rgba } from "polished";
import { colors } from "../styleguide/colors";
import { ButtonCaps } from "../styleguide/typography";
import { AbsoluteLoader } from "../loader/Loader";

export const Container: React.ComponentType<{ disabled: boolean }> = styled.button`
  height: 44px;
  padding: 0 15px;
  box-sizing: border-box;
  position: relative;

  border: solid 1px ${rgba(colors.black, 0.1)};
  border-radius: 4px;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  font-family: inherit;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  ${props =>
    (!props.disabled &&
      `
             &:hover {
                border-color: ${colors.magenta};
             }
       `) ||
    `
            opacity: 0.5;
            filter: grayscale(1);
            pointer-events: none;
       `}
`;

const ButtonCapsUppercaseLeft = props => (
  <ButtonCaps {...props} modifiers={["uppercase", "left"]} />
);

const Text = styled(ButtonCapsUppercaseLeft)`
  transition: color 0.15s ease-in-out;
  ${Container}:hover & {
    color: ${colors.magenta};
  }
`;

const TextContainer = props => <div>{props.children}</div>;

const Icon = () => null;

const IconContainer = () => null;

const OverridableProps = {
  Container,
  Text,
  TextContainer,
  Icon,
  IconContainer
};
type BaseButtonOverridesProps = ExtractOverridesProps<typeof OverridableProps>;
export const DefaultButton = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  type?: "button" | "reset" | "submit",
  children?: ?React.Node,
  isLoading?: boolean,
  overrides?: BaseButtonOverridesProps
}) => (
  <Container
    type={props.type ? props.type : "button"}
    {...o}
    disabled={props.disabled || props.isLoading}
    onClick={props.onClick}>
    <IconContainer {...o}>
      <Icon {...o} />
    </IconContainer>
    <TextContainer {...o}>
      <Text {...o}>{props.children}</Text>
    </TextContainer>
    {props.isLoading && <AbsoluteLoader />}
  </Container>
);
