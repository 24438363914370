// @flow
import * as React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import { PrimaryButton, PrimaryButtonContainer, PrimaryButtonText } from "../buttons/PrimaryButton";
import { AutoFocusWrapper } from "../../utils/AutoFocusWrapper";

const AlertWarningButtonContainer = styled(PrimaryButtonContainer)`
  width: 180px;
  ${PrimaryButtonText} {
    font-size: 13px;
  }
`;

const AlertWarningButtonContainerAutoFocus = props => (
  <AutoFocusWrapper>{ref => <AlertWarningButtonContainer {...props} ref={ref} />}</AutoFocusWrapper>
);

export const AlertWarningPrimaryButton = (props: {
  onClick?: () => mixed,
  children: React$Node
}) => (
  <PrimaryButton
    onClick={props.onClick}
    primaryButtonContainer={AlertWarningButtonContainerAutoFocus}>
    {props.children}
  </PrimaryButton>
);

const AlertWarningWhiteButtonContainer = styled(AlertWarningButtonContainer)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border: none;
  background-color: ${colors.white};
  opacity: 0.9;
  :hover {
    background-color: ${colors.white};
    border-color: ${colors.white};
  }
  ${PrimaryButtonText} {
    color: ${colors.black};
  }
`;

export const AlertWarningWhiteButton = (props: { onClick?: () => mixed, children: React$Node }) => (
  <PrimaryButton onClick={props.onClick} primaryButtonContainer={AlertWarningWhiteButtonContainer}>
    {props.children}
  </PrimaryButton>
);
