// @flow
import styled from "styled-components";
import { colors } from "../styleguide/colors";

export const PointHintExamination = styled.span`
  opacity: 1;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: ${props => (props.disabled ? colors.gray400 : colors.darkGray)};
  white-space: nowrap;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${props => (props.disabled ? colors.gray400 : colors.lightMagenta)};
  }
`;
