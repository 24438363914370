// @flow
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import moment from "moment";
import {
  READABLE_DATE_FORMAT,
  READABLE_TIME_FORMAT_MOMENT,
  SERVER_DATE_FORMAT,
  SERVER_TIME_FORMAT
} from "../../utils/dates";
import generalScheduleStore from "../../stores/generalScheduleStore";
import organizationsStore from "../../stores/organizationsStore";
import { MaterialTable } from "../common/MaterialTable";
import doctorsStore, { groupedDoctorsBySpecialty } from "../../stores/doctorsStore";
import { observer } from "mobx-react";
import { DynamicFilters } from "../common/DynamicFilters";
import { ModalPortal } from "../modal/ModalPortal";
import { ScheduleInsertDialog } from "../schedule-insert-modal/ScheduleInsertDialog";
import { useStore } from "effector-react";
import { currentUser as currentUserStore } from "../../stores/auth";
import { GeneralScheduleContentNew } from "./GeneralScheduleContentNew";
import { BackToScheduleButton } from "../appointment-switch-page/BackToScheduleButton";
import { Header } from "../header/Header";
import { trimLongTextValueField } from "../../utils/tableUtils";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControlLabel, Paper, Tooltip } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import { GeneralScheduleNewConfig } from "./GeneralScheduleNewConfig";
import scheduleInsertsStore, { INSERTS_TYPE } from "../../stores/scheduleInsertsStore";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import { DropDownMenu } from "../drop-down-menu/DropDownMenu";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';

const Container = styled.div`
    padding: 25px 25px 60px;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
    //max-height: calc(100vh - 125px);
    //overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media(max-width: 768px) {
        padding: 24px 0 30px 0;
    }
`;

const DensityBlock = styled.div`
`;

const ContainerActionsCalendar = styled.div`
  width: auto;
  gap: 12px;
  display: flex;
  align-items: center;
  @media (max-width: 550px) {
    width: 100%;
    padding: 8px 16px;
  }
  .MuiInputBase-root {
    width: 220px;
    height: 36px;
    @media (max-width: 550px) {
      width: 100%;
    }  
  }
`;

const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
    
  @media (max-width: 700px) {
    flex-wrap: wrap;
    gap: 4px;
  }
  @media (max-width: 550px) {
    padding: 0;
  }
`;

const ContainerSubActions = styled(ContainerActions)`
  padding: 8px 16px;
  border-top: 1px solid #F3F3F3;
`;

const ContainerMultipleActions = styled.div`
  display: flex; 
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 550px) {
    gap: 8px;
    .MuiFormControlLabel-root {
      margin-left: 0;
    }
  }
`;

const ContainerMultipleButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media (max-width: 550px) {
    width: 100%;
    .MuiBox-root, .MuiButtonBase-root {
      flex: 1;
    }
    .MuiBox-root .MuiButtonBase-root {
      width: 100%;
    }
    .MuiButtonBase-root {
      min-width: 120px;
      white-space: nowrap;
    }
  }
`;

const TabsContainer = styled.div`
  @media (max-width: 550px) {
    width: 100%;
    border-bottom: 1px solid #F3F3F3;
  }
`

const TableContainer = styled.div`
  position: relative;
`;

const ContainerWrapper = styled.div`
  //flex-grow: 1;
`;

const BackButtonContainer = styled.div`
  margin-bottom: 0;

  a,
  button {
    margin-bottom: 0;
  }
`;

const propsTabs = (index) => {
  return {
    id: `general-tab-${index}`,
    'aria-controls': `general-tabpanel-${index}`,
  };
}


export const GeneralSchedulePageNew = observer(() => {
  const [activeView, setActiveView] = React.useState(1);
  const [doctors, setDoctors] = useState([]);
  const [paginationParams, setPaginationParams] = React.useState({
    pageIndex: 0,
    pageSize: activeView === 0 ? 100 : 99999
  });
  const [isInitialized, setIsInitialized] = React.useState();
  const [groupType, setGroupType] = React.useState('date');
  const [selectOrganization, setSelectOrganization] = React.useState('date');
  const currentUser = useStore(currentUserStore);
  const isMultipleAppointmentMode = scheduleInsertsStore.enableMultipleAppointmentsMode;

  useEffect(() => {
    scheduleInsertsStore.removeMultipleAppointments({ clearAll: true });
  }, [isMultipleAppointmentMode]);

  const setSelectOrganizationData = (filters) => {
    const dataFilters = filters || generalScheduleStore.globalFiltersTable;
    if (dataFilters.doctor_organization__organization) {
      setSelectOrganization(JSON.stringify(dataFilters.doctor_organization__organization));
    }
    if (dataFilters.doctor_organization__organization__in) {
      setSelectOrganization(JSON.stringify(dataFilters.doctor_organization__organization__in));
    }
  }

  const changeHandler = (filters) => {
    generalScheduleStore.setGlobalFiltersTable(filters);
    setSelectOrganizationData(filters);
  }

  const organizations =
    generalScheduleStore.globalFiltersTable.doctor_organization__organization__in ||
    !currentUser?.doctor?.organizations?.length
      ? []
      : [currentUser.doctor.organizations[0]];

  const defaultData = {
    date__gte: moment().format(SERVER_DATE_FORMAT),
    date__lte: moment().format(SERVER_DATE_FORMAT),
    doctor_organization__organization__in: organizations,
    doctor_organization__doctor__in: generalScheduleStore.globalFiltersTable.doctor_organization__doctor__in || [],
  }

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
      const response = await doctorsStore.fetchAllDoctorsList({
        filtersParams: {
          organizations__isnull: false,
          specialities__isnull: false,
        },
        additionalParamsValues: ['specialities__name', 'organizations__id'],
        annotateMtmParams: {
          specialities: 'specialities__name',
          organizations: 'organizations__id'
        }
      });
      setDoctors(groupedDoctorsBySpecialty(response.data));
      if (!generalScheduleStore.globalFiltersTable.date__lte) {
        generalScheduleStore.setGlobalFiltersTable(defaultData, false)
      }
      setSelectOrganizationData();
      setGroupType('date');
      generalScheduleStore.getSettingsScheduleView();
      setIsInitialized(true);
      setMetaTableSchedule();
    })()
  }, []);

  useEffect(() => {
    (async () => await doctorsStore.fetchFavouriteDoctorsList())();
  }, []);

  const setMetaTableSchedule = () => {
    const meta = {
      pageIndex: 0,
      pageSize: activeView === 0 ? 100 : 99999
    }

    generalScheduleStore.setMetaTable(meta, true, generalScheduleStore.isInitialized);
    setPaginationParams(meta);
  }

  useEffect(() => {
    setMetaTableSchedule();
  }, [activeView]);

  const afterInitializeParams = (callback) => {
    generalScheduleStore.setAnnotateMtmParams({
      "doctor_organization__doctor__specializations": "doctor_organization__doctor__specializations__name",
      "doctor_organization__doctor__organizations": ["doctor_organization__doctor__organizations__name", "doctor_organization__doctor__organizations__id"],
      "doctor_organization__favorites_services": [
        "doctor_organization__favorites_services__id",
        "doctor_organization__favorites_services__name",
        "doctor_organization__favorites_services__ministry_of_health_code",
        "doctor_organization__favorites_services__kdf_code",
        "doctor_organization__favorites_services__price",
        "doctor_organization__favorites_services__is_chat",
        "doctor_organization__favorites_services__is_online"
      ],
      "service": [
        "service__id",
        "service__name",
        "service__kdf_code",
      ]
    })
    if ((!generalScheduleStore.globalFiltersTable.date__lte || !generalScheduleStore.globalFiltersTable.date__gte) && !generalScheduleStore.globalFiltersTable.date){
      generalScheduleStore.setGlobalFiltersTable(defaultData, false)
    }
    callback();
  }

  const dynamicAllFilters = useMemo(() => {
    return [
      {
        type: 'date',
        typePicker: 'date',
        name: 'date',
        filterFn: 'range',
        filterFnDisabled: true,
        label: 'Дата приема',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'select',
        name: 'doctor_organization__organization',
        label: 'Организация',
        labelName: 'label',
        valueName: 'value',
        filterFn: 'in',
        filterFnDisabled: true,
        needSelectAll: true,
        options: organizationsStore.organizations.filter((item) => currentUser?.doctor?.organizations.includes(item.id)).map((item) => ({ value: item.id, label: item.name })),
        xs: 12,
      },
      {
        type: 'selectDoctor',
        name: 'doctor_organization__doctor',
        label: 'Врач',
        labelName: 'fullName',
        valueName: 'id',
        options: doctors.filter((item) => {
          if (generalScheduleStore.globalFiltersTable.doctor_organization__organization) {
            if (item.organizations__id.some((org) => org === generalScheduleStore.globalFiltersTable.doctor_organization__organization)) {
              return true;
            }
            return false;
          }
          if (generalScheduleStore.globalFiltersTable.doctor_organization__organization__in && generalScheduleStore.globalFiltersTable.doctor_organization__organization__in.length) {
            if (item.organizations__id.some((org) => generalScheduleStore.globalFiltersTable.doctor_organization__organization__in.includes(org))) {
              return true;
            }
            return false;
          }
          return true;
        }),
        filterFn: 'in',
        filterFnDisabled: true,
        xs: 12,
      },
      {
        type: 'text',
        name: 'patient_full_name',
        label: 'Пациент',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'number',
        name: 'patient_phone_number',
        label: 'Телефон пациента',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'select',
        name: 'type',
        label: 'Тип приема',
        labelName: 'label',
        valueName: 'value',
        options: generalScheduleStore.typeScheduleNameOptions,
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'medical_file__number',
        label: 'Номер медицинской карты',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'date',
        name: 'time',
        typePicker: 'time',
        format: READABLE_TIME_FORMAT_MOMENT,
        label: 'Время приема',
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'text',
        name: 'comment',
        label: 'Комментарий',
        xs: 12,
        md: 3,
        sm: 6,
      },
    ]
  }, [doctors, organizationsStore.organizations, selectOrganization]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'fullname_doctor',
        filterFn: 'icontains',
        header: 'Врач',
        size: 300,
      },
      {
        accessorKey: 'date',
        header: 'Дата приема',
        Cell: ({ cell }) =>
          moment(cell.getValue()).format(READABLE_DATE_FORMAT),
      },
      {
        accessorKey: 'time',
        filterFn: 'icontains',
        header: 'Время приема',
      },
      {
        accessorKey: 'patient_full_name',
        filterFn: 'icontains',
        header: 'Пациент',
      },
      {
        accessorKey: 'patient_phone_number',
        filterFn: 'icontains',
        header: 'Телефон пациента',
      },
      {
        accessorKey: 'medical_file__number',
        header: 'Номер медицинской карты',
        filterFn: 'icontains',
        size: 300,
      },
      {
        accessorKey: 'type',
        filterFn: 'icontains',
        header: 'Тип',
        Cell: ({ row }) => generalScheduleStore.typeScheduleName[row.original.type]
      },
      {
        accessorKey: 'id', //normal accessorKey
        header: 'Идентификатор записи',
        size: 270,
        filterFn: 'icontains',
      },
      {
        accessorKey: 'comment',
        header: 'Комментарий',
        size: 400,
        filterFn: "icontains",
        Cell: ({ row }) => trimLongTextValueField(row.original.comment)
      },
      {
        accessorKey: 'online_link_for_patient',
        header: 'Ссылка на онлайн',
        size: 400,
        filterFn: "icontains",
        Cell: ({ row }) => trimLongTextValueField(row.original.online_link)
      },
      {
        accessorKey: 'doctor_organization__organization',
        additionalFields: ['doctor_organization__organization__name'],
        header: 'Организация',
        filterFn: 'in',
        filterVariant: 'multi-select',
        filterSelectOptions: organizationsStore.organizations.map((item) => ({value: item.id, label: item.name})),
        Cell: ({ row }) => row.original.doctor_organization__organization__name,
        size: 300,
      },
    ],
    [organizationsStore.organizations],
  );

  // const renderRowActions = ({row}) => (
  //   <Button size={"small"} variant="text" onClick={() => {
  //     generalScheduleStore.changeDataScheduleDialog(row.original);
  //     generalScheduleStore.changeShowInsertDialog(true);
  //   }}>Записаться</Button>
  // )

  const getTime = (str) => {
    const regex = /(\d{2}:\d{2})/;
    if (regex.test(str)) {
      return RegExp.$1; // возвращает найденное время
    } else {
      return null;
    }
  }

  const schedule = useMemo(() => {
    let data = [];
    if (activeView === 1) {
      const newData = [];
      const doctorOrganizations = {};
      const allDoctorOrganizationsByDates = [];
      generalScheduleStore.dataTable?.forEach((item) => {
        if (!allDoctorOrganizationsByDates.some(((organization) => organization.name === item.doctor_organization__organization__name))) {
          allDoctorOrganizationsByDates.push({ name: item.doctor_organization__organization__name, id: item.doctor_organization__organization__id });
        }
        const key = `${item.doctor_organization__doctor}_${item.date}`;
        if (!doctorOrganizations[key]) {
          doctorOrganizations[key] = {
            organizations: [],
            isOnline: false,
            isChat: false,
          };
        }
        if (!_.find(doctorOrganizations[key].organizations, { name: item.doctor_organization__organization__name })) {
          doctorOrganizations[key].organizations.push({ name: item.doctor_organization__organization__name, id: item.doctor_organization__organization__id });
        }
      })

      generalScheduleStore.dataTable?.forEach((item) => {
        item = {...item};
        item.doctor = {};
        item.services = item.service__name.filter((service) => service).map((service, index) => {
          return {
            name: service,
            id: item.service__id[index],
            kdfCode: item.service__kdf_code[index]
          }
        })
        item.doctor['id'] = item.doctor_organization__doctor;
        item.doctor['last_name'] = item.doctor_organization__doctor__last_name;
        item.doctor['first_name'] = item.doctor_organization__doctor__first_name;
        item.doctor['middle_name'] = item.doctor_organization__doctor__middle_name;
        item.doctor['fullname_doctor'] = item.fullname_doctor;
        item.doctor['schedule_step'] = item.doctor_organization__schedule_step;
        item.doctor['allows_foreign_appointments'] = item.doctor_organization__doctor__allows_foreign_appointments;
        item.doctor['photo'] = item.doctor_organization__doctor__photo;
        item.doctor['specializations'] = item.doctor_organization__doctor__specializations__name;
        item.doctor['organization'] = item.doctor_organization__organization__name;
        item.doctor['allOrganizations'] = item.doctor_organization__doctor__organizations__name;
        item.doctor['organizationsName'] = doctorOrganizations[`${item.doctor.id}_${item.date}`].organizations || [];
        item.doctor['allDoctorOrganizationsByDates'] = allDoctorOrganizationsByDates;
        item.doctor['specializationType'] = item.doctor_organization__doctor__specialization_type;
        item.doctor['comment'] = item.doctor_organization__doctor__comment;
        item.doctor['description'] = item.doctor_organization__doctor__description;
        item.doctor['favoritesServices'] = item.doctor_organization__favorites_services__name.map((service, index) => {
          return {
            id: item.doctor_organization__favorites_services__id[index],
            ministryOfHealthCode: item.doctor_organization__favorites_services__ministry_of_health_code[index],
            kdfCode: item.doctor_organization__favorites_services__kdf_code[index],
            name: service,
            price: item.doctor_organization__favorites_services__price[index],
            isOnline: item.doctor_organization__favorites_services__is_online.some((item) => item),
            isChat: item.doctor_organization__favorites_services__is_chat.some((item) => item)
          }
        });
        delete item.doctor_organization__doctor;
        delete item.doctor_organization__organization__name;
        delete item.doctor_organization__organization__id;
        delete item.doctor_organization__doctor__last_name;
        delete item.doctor_organization__doctor__first_name;
        delete item.doctor_organization__doctor__middle_name;
        delete item.fullname_doctor;
        delete item.doctor_organization__schedule_step;
        delete item.doctor_organization__doctor__allows_foreign_appointments;
        delete item.doctor_organization__doctor__photo;
        delete item.doctor_organization__doctor__specializations__name;
        delete item.doctor_organization__doctor__specialization_type;
        delete item.doctor_organization__doctor__description;
        delete item.doctor_organization__doctor__comment;
        delete item.doctor_organization__favorites_services__name;
        delete item.doctor_organization__favorites_services__ministry_of_health_code;
        delete item.doctor_organization__favorites_services__kdf_code;
        delete item.doctor_organization__favorites_services__price;
        delete item.doctor_organization__favorites_services__is_online;
        delete item.doctor_organization__favorites_services__is_chat;
        delete item.service__name;
        delete item.service__kdf_code;
        delete item.service__id;
        if (item.medical_file__number) {
          item.medical_file = {
            patient: {
              id: item.medical_file__patient__id,
              birthdate: item.medical_file__patient__birthdate,
            },
            number: item.medical_file__number,
            id: item.medical_file__id
          }
          delete item.medical_file__patient__birthdate;
          delete item.medical_file__number;
        }

        if (!item.time && item.comment && getTime(item.comment)) {
          item.time = getTime(item.comment);
        }
        newData.push(item);
      })

      if (groupType === 'doctor') {
        const groupData = _.groupBy(newData, 'doctor.id');
        Object.keys(groupData).forEach((doctorId, index) => {
          const doctor = {};
          const scheduleData = {
            doctor,
            items: groupData[doctorId]
          };
          data.push(scheduleData);
        })
        // const sortDoctors = () => {
        //   if (data.length > 1) {
        //     data.sort((schedule1, schedule2) => {
        //       const aValue = schedule1.doctor.last_name.toLowerCase();
        //       const bValue = schedule2.doctor.last_name.toLowerCase();
        //
        //       return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        //     });
        //   }
        // };
        //
        // const currentDoctorIndex = data.find(
        //   schedule => schedule.doctor.id === currentUser.doctor.id
        // );
        // if (currentDoctorIndex >= 0) {
        //   const currentDoctor = data.splice(currentDoctorIndex, 1)[0];
        //   sortDoctors();
        //   data.unshift(currentDoctor);
        // } else {
        //   sortDoctors();
        // }
        data = data.map((schedule) => {
          const doctor = schedule.items[0].doctor;
          return {
            doctor: { ...doctor, organizationsName: doctor.allDoctorOrganizationsByDates },
            items: _.groupBy(schedule.items, 'date'),
          }
        })

        return data;
      } else {
        const groupData = _.groupBy(newData, 'date');
        Object.keys(groupData).forEach((date, index) => {
          const scheduleData = {
            date,
            items: groupData[date]
          };
          data.push(scheduleData);
        })
        data = data.map((schedule) => {
          return {
            date: schedule.date,
            items: _.groupBy(schedule.items, 'doctor.id'),
          }
        })
        return data;
      }
    }
  }, [generalScheduleStore.dataTable, activeView, groupType]);

  const getSelectedSlots = () => {
    return scheduleInsertsStore.multipleAppointmentsData.map((item) => {
      return {
        name: getLastNameWithInitials(item.doctor),
        subText: `${moment(item.date).format(READABLE_DATE_FORMAT)}, ${moment(item.time, SERVER_TIME_FORMAT).format(READABLE_TIME_FORMAT_MOMENT)}`,
        icon: <DeleteIcon />,
        handleOnClick: () => setTimeout(() => scheduleInsertsStore.removeMultipleAppointments({ id: item.id }), 200),
        disabledCloseAfterClick: scheduleInsertsStore.multipleAppointmentsData.length > 1,
        tooltipText: "Удалить выбранный слот",
        tooltipPlacement: "left"
      }
    });
  };

  return (
    <>
      <Header
        prependContent={
          (
            <BackButtonContainer>
              <BackToScheduleButton />
            </BackButtonContainer>
          )
        }
      />
      <Container>
        {!!isInitialized && (
          <ContainerWrapper>
            <DynamicFilters
              hiddenButtonSave
              data={generalScheduleStore.globalFiltersTable}
              hiddenButtonCancel
              titleForm={"Фильтры"}
              changeHandler={changeHandler}
              submitHandler={changeHandler}
              nameLocalStorageSaveFilters={"generalSchedulePageSaveFilters"}
              disabledOptions={['date']}
              defaultFilters={['date', 'doctor_organization__organization', 'doctor_organization__doctor']}
              defaultData={defaultData}
              isNeedAdvancedFilters={true}
              allFilters={dynamicAllFilters} />
          </ContainerWrapper>
        )}
        <Paper sx={{
          height: activeView === 1 ? '90vh' : "auto",
          overflow: 'hidden',
        }}>
          <Box className="general-page-new__container-actions">
            <ContainerActions>
              <TabsContainer>
                <Tabs value={activeView} onChange={(event, value) => setActiveView(value)} aria-label="basic tabs example" variant="fullWidth">
                  <Tab label="Таблица" {...propsTabs(0)} />
                  <Tab label="Календарь" {...propsTabs(1)} />
                </Tabs>
              </TabsContainer>
              {activeView === 1 && (
                <ContainerActionsCalendar>
                  <Select
                    value={groupType}
                    onChange={(event) => setGroupType(event.target.value)}
                  >
                    <MenuItem value={'date'}>По датам</MenuItem>
                    <MenuItem value={'doctor'}>По врачу</MenuItem>
                  </Select>
                  <DensityBlock>
                    {generalScheduleStore.densityCalendar === 'large' && (
                      <Tooltip title="Ширина колонки: большая">
                        <DensityLargeIcon cursor={'pointer'} onClick={() => {
                          generalScheduleStore.changeDensityCalendar('medium');
                        }} transform={'rotate(90)'} />
                      </Tooltip>
                    )}
                    {generalScheduleStore.densityCalendar === 'medium' && (
                      <Tooltip title="Ширина колонки: средняя">
                        <DensityMediumIcon cursor={'pointer'} onClick={() => {
                          generalScheduleStore.changeDensityCalendar('small');
                        }} transform={'rotate(90)'} />
                      </Tooltip>
                    )}
                    {generalScheduleStore.densityCalendar === 'small' && (
                      <Tooltip title="Ширина колонки: короткая">
                        <DensitySmallIcon cursor={'pointer'} onClick={() => {
                          generalScheduleStore.changeDensityCalendar('large');
                        }} transform={'rotate(90)'} />
                      </Tooltip>
                    )}
                  </DensityBlock>
                  <GeneralScheduleNewConfig />
                </ContainerActionsCalendar>
              )}
            </ContainerActions>
            {activeView === 1 && (
              <ContainerSubActions>
                <ContainerActionsCalendar>
                  <ContainerMultipleActions>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={scheduleInsertsStore.enableMultipleAppointmentsMode}
                          onChange={() => scheduleInsertsStore.multipleAppointmentsMode()}
                        />
                      }
                      labelPlacement="start"
                      label="Режим множественной записи"
                    />
                    {isMultipleAppointmentMode && (
                      <ContainerMultipleButtons>
                        <DropDownMenu
                          menuItems={[
                            {
                              name: "Удалить все выбранные слоты",
                              handleOnClick: () => setTimeout(() => scheduleInsertsStore.removeMultipleAppointments({ clearAll: true }), 200),
                            },
                            ...getSelectedSlots()
                          ]}
                          view="button"
                          textButton="Выбранные слоты"
                          disabled={!scheduleInsertsStore.multipleAppointmentsData.length}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        />
                        <Button
                          variant="outlined"
                          disabled={scheduleInsertsStore.multipleAppointmentsData.length <= 1}
                          onClick={() => scheduleInsertsStore.setScheduleInsertDialogData({ type: INSERTS_TYPE.MULTIPLE })}
                        >
                          Записать
                        </Button>
                      </ContainerMultipleButtons>
                    )}
                  </ContainerMultipleActions>
                </ContainerActionsCalendar>
              </ContainerSubActions>
            )}
          </Box>
          <TableContainer style={{ display: activeView === 1 ? "none" : "block", position: "relative" }}>
            <MaterialTable
              disableHeightResizing
              notPadding={true}
              afterInitializeParams={afterInitializeParams}
              enableColumnFilters={false}
              initialState={{ columnVisibility: {id: false}, pagination: paginationParams }}
              store={generalScheduleStore}
              columns={columns}
            />
          </TableContainer>
          {activeView === 1 && (
            <GeneralScheduleContentNew groupType={groupType} schedule={schedule} isLoading={generalScheduleStore.isLoading} />
          )}
        </Paper>
        <ModalPortal>
          <ScheduleInsertDialog />
        </ModalPortal>
      </Container>
    </>
  );
});
