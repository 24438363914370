// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const InnerContainer = styled.div`
  padding: 0 100px;

  @media (max-width: 768px) {
    padding: 0 50px;
  }

  @media (max-width: 480px) {
    padding: 0 15px;
  }
`;

const FormContainer = styled(InnerContainer)`
  max-width: 1024px;
  position: relative;
`;

const ButtonContainer = styled(InnerContainer)`
  padding-top: 51px;

  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;

const SeparateLine = styled.div`
  width: 100%;
  margin-top: 70px;
  border-top: solid 1px ${colors.transparentGray};
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const RequiredFields = styled(InnerContainer)`
  margin-top: 24px;
  > * {
    color: ${colors.watermelon};
  }
`;

const InfoContainer = styled(InnerContainer)`
  margin-top: 10px;
  font-size: 15px;
  line-height: 24px;
  color: ${colors.gray700};

  & {
    a {
      text-decoration: none;
      color: ${colors.magenta};

      &:hover {
        text-decoration: underline;
        text-underline-position: under;
      }
    }
  }
`;

export const ServicesFormLayout = (props: {
  form: React$Node,
  buttons: React$Node,
  info: React$Node,
  requiredFields: React$Node
}) => {
  return (
    <React.Fragment>
      <FormContainer>
        {props.form}
      </FormContainer>
      <SeparateLine />
      <ButtonContainer>{props.buttons}</ButtonContainer>
      {props.info && <InfoContainer>{props.info}</InfoContainer>}
      {props.requiredFields && <RequiredFields>{props.requiredFields}</RequiredFields>}
    </React.Fragment>
  );
};
