// @flow
import * as React from "react";
import styled from "styled-components";
import { DefaultButton, Container } from "../buttons/DefaultButton";
import { colors } from "../styleguide/colors";

const ButtonContainer = styled(Container)`
  width: 100%;
  height: 52px;
  margin-top: 35px;
  background-color: ${colors.gray050};
  border: 1px solid transparent;
`;

export const AnalyzesButton = ({
  children,
  ...props
}: {
  onClick?: () => mixed,
  children: any
}) => (
  <DefaultButton
    {...props}
    overrides={{
      Container: {
        component: ButtonContainer
      }
    }}>
    {children}
  </DefaultButton>
);
