// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { ButtonCaps } from "../styleguide/typography";

const Container = styled.div`
  min-width: 40px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 2px;
  border: solid 1px ${colors.magenta};
  background-color: #fff1f6;
  padding: 0 4px;
  display: flex;
  align-items: center;
`;

export const DiagnosisLabel = (props: { title: string }) => {
  const { title } = props;
  return (
    <Container>
      <ButtonCaps modifiers={["left", "color"]}>{title}</ButtonCaps>
    </Container>
  );
};
