// @flow

import type { TFieldValue } from "../../stores/appointmentFieldValues";
import type { LayoutFieldValue } from "../../domain/entities/Appointment";

export const getNumberValue = (
  fieldValue: ?TFieldValue,
  layoutFieldValue?: LayoutFieldValue,
  layoutFieldValueHandler?: string => string
) => {
  if (fieldValue && fieldValue.field_type === "date") {
    if (typeof fieldValue.value === "string") {
      const dateString = fieldValue.value
        .split(".")
        .reverse()
        .join("-");
      const date = new Date(dateString);

      return (date.getTime() / (24 * 3600 * 1000)).toString();
    }
  }

  if (fieldValue && typeof fieldValue.value === "string") {
    return fieldValue.value;
  }

  if (
    layoutFieldValue &&
    (typeof layoutFieldValue.value === "string" || typeof layoutFieldValue.value === "number") &&
    (layoutFieldValue.value || layoutFieldValue.value === 0)
  ) {
    return layoutFieldValueHandler
      ? layoutFieldValueHandler(layoutFieldValue.value.toString())
      : layoutFieldValue.value;
  }

  return "";
};
