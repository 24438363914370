// @flow
import { useEffect, useRef } from "react";

export const useEl = (root: ?HTMLElement) => {
  const el = useRef<HTMLElement>(document.createElement("div"));

  useEffect(() => {
    root && root.appendChild(el.current);
    return () => {
      root && root.removeChild(el.current);
    };
  }, []);

  return el;
};
