// @flow
import React from "react";
import { ModalFade } from "../modal/ModalFade";
import { ModalBackground } from "../modal/ModalBackground";
import { useModalEscape } from "../modal/useModalEscape";

export const NewTableModal = (props: {
  isShow: boolean,
  hide: () => void,
  children: React$Node
}) => {
  const { isShow, hide, children } = props;

  useModalEscape(hide);

  return <ModalFade>{isShow && <ModalBackground>{children}</ModalBackground>}</ModalFade>;
};
