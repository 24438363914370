// @flow
import React from "react";
import styled from "styled-components";
import { PXSmall } from "../styleguide/typography";
import sortIcon from "./icons/sort-icon.svg";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  position: relative;
`;

const ValueContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const ValueText = styled(PXSmall)`
  & > b {
    font-weight: 500;
  }
`;

const ValueIcon = styled.div`
  background: url(${sortIcon});
  width: 9px;
  height: 13px;
  margin-left: 5px;
  margin-top: 1px;
`;

const ListContainer = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 7px;

  border-radius: 3px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;

  padding: 6px 22px 6px 9px;
`;

const Item = styled.li`
  opacity: 0.8;
  font-size: 12px;
  line-height: 1.58;
  letter-spacing: normal;
  color: ${colors.black};
  cursor: pointer;

  & + & {
    margin-top: 9px;
  }
`;

export const SortSelect = (props: {
  value: string,
  items: Array<{ id: string, title: string }>,
  onClickItem?: string => void
}) => {
  const { items, value, onClickItem } = props;
  const currentItem = items.find(item => item.id === value);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    const clickBackground = () => setIsOpen(false);
    window.addEventListener("click", clickBackground);
    return () => window.removeEventListener("click", clickBackground());
  }, []);

  return (
    <Container onClick={e => e.stopPropagation()}>
      {currentItem ? (
        <ValueContainer onClick={() => setIsOpen(true)}>
          <ValueText modifiers="left">
            Выводить по: <b>{currentItem.title}</b>
          </ValueText>
          <ValueIcon />
        </ValueContainer>
      ) : (
        <ValueContainer onClick={() => setIsOpen(true)}>
          <ValueText modifiers="left">
            Выводить по: <b>не выбрано</b>
          </ValueText>
          <ValueIcon />
        </ValueContainer>
      )}
      {isOpen && (
        <ListContainer>
          {items.map(item => {
            return (
              <Item
                key={item.id}
                onClick={() => {
                  setIsOpen(false);
                  onClickItem && onClickItem(item.id);
                }}>
                По {item.title}
              </Item>
            );
          })}
        </ListContainer>
      )}
    </Container>
  );
};
