// @flow
import { createEffect, createEvent, createStore } from "effector";
import * as R from "ramda";

import { tokenStore, currentUser } from "../../stores/auth";
import { getScheduleInterval } from "../../domain/services/schedule";
import type { Schedule, ScheduleByDays } from "../../domain/entities/Schedule";
import apiv2 from "../../apiv2";
import moment from "moment";
import _ from "lodash";
import { applicationFeatureEnabled, FEATURE } from "../../utils/applicationFeatures";

const limit = 60;

const schedulePath = day_index => R.lensPath([day_index, "schedule"]);

const setNewDayIfNeeded = (day_index, newDay) =>
  R.over(R.lensPath([day_index]), day => day || newDay);

const addScheduleCell = cell =>
  R.compose(
    R.sortBy(R.compose((time) => time || "24:00", R.prop("time"))),
    R.append(cell),
    R.reject(R.propEq("id", cell.id))
  );

export const appendOrUpdateDataSchedule = (
  state: ScheduleByDays,
  cell: Schedule
): ScheduleByDays => {
  const _index = R.findIndex(R.propEq("date", cell.date))(state);
  const index = _index < 0 ? state.length : _index;

  return R.compose(
    R.sortBy(R.prop("date")),
    R.over(schedulePath(index), addScheduleCell(cell)),
    setNewDayIfNeeded(index, { date: cell.date, schedule: [] })
  )(state);
};

export const removeCellFromSchedule = (state: ScheduleByDays, cellId: number): ScheduleByDays => {
  for (let day of state) {
    const cell = R.find(R.propEq("id", cellId))(day.schedule);
    if (cell) {
      const schedule = R.without([cell], day.schedule);
      return R.set(schedulePath(R.indexOf(day, state)), schedule, state);
    }
  }
  return state;
};

export const dataStore = createStore<Array<any>>({
  items: [],
  isEditSchedule: false,
  dataDeleteSchedule: '',
  isAddEmptyDate: false,
  dataDeleteAppointment: {},
  dataAddTimeSlot: '',
  isLoading: false,
});
const setData = createEvent<Array<any>>("setData");

dataStore.on(setData, (state, payload) => {
  return { ...state, ...payload };
});

export const changeViewSchedule = createEvent<Schedule>("changeViewSchedule");
dataStore.on(changeViewSchedule, (state, value) => ({ ...state, isEditSchedule: value }));

export const changeIsAddEmptyDate = createEvent<Schedule>("changeIsAddEmptyDate");
dataStore.on(changeIsAddEmptyDate, (state, value) => ({ ...state, isAddEmptyDate: value }));

export const changeIsLoadingData = createEvent<Schedule>("changeIsLoadingData");
dataStore.on(changeIsLoadingData, (state, value) => ({ ...state, isLoading: value }));

export const changeDataDeleteSchedule = createEvent<Schedule>("changeDataDeleteSchedule");
dataStore.on(changeDataDeleteSchedule, (state, value) => ({ ...state, dataDeleteSchedule: value }));

export const changeDataDeleteAppointment = createEvent<Schedule>("changeDataDeleteAppointment");
dataStore.on(changeDataDeleteAppointment, (state, value) => ({ ...state, dataDeleteAppointment: value }));

export const changeDataForNewTimeSlot = createEvent<Schedule>("changeDataForNewTimeSlot");
dataStore.on(changeDataForNewTimeSlot, (state, value) => ({ ...state, dataAddTimeSlot: value }));

currentUser.watch(user => {
  if (!user) {
    setData({
      items: [],
    });
  }
});

dataStore.watch(changeViewSchedule, (storeValue, eventValue) => {
  if (eventValue && !storeValue.isAddEmptyDate) {
    const newSchedule = generateEmptyDays(storeValue.items);
    setData({
      items: newSchedule,
    });
  }
})

const getScheduleEffect = createEffect("getScheduleEffect").use(async token => {
  if (!token) {
    return;
  }
  changeIsLoadingData(false);
  const { from_date } = getScheduleInterval();
  return apiv2.schedule.get({ from_date, limit, offset: 0 });
});

export const recordingChatAllowed = (day) => {
  const findSchedule = dataStore.getState().items.find((slotItem) => slotItem.date === day);
  return findSchedule && findSchedule.schedule.some((slotItem) => slotItem.type === 'chat' && !slotItem.time && !slotItem.is_reserved);
}

export const recordingUnscheduledAppointmentAllowed = (day) => {
  const findSchedule = dataStore.getState().items.find((slotItem) => slotItem.date === day);
  return findSchedule && !!findSchedule.schedule.filter((slotItem) => slotItem.type !== 'chat').length;
};

export const changeChatAndUpdateSchedules = async (data) => {
  try {
    if (data.isEnabled) {
      await apiv2.schedule.chatDisable(moment(data.date).format());
    } else {
      await apiv2.schedule.chatEnable(moment(data.date).format());
    }
    getScheduleEffect(tokenStore.getState());
  } catch (e) {
    console.log(e);
  }
};

export const deleteAppointmentAndUpdateView = async () => {
  try {
    await apiv2.schedule.delete(dataStore.getState().dataDeleteAppointment.id);
    changeDataDeleteAppointment({})
    getScheduleEffect(tokenStore.getState());
  } catch (e) {
    console.log(e);
  }
};

export const deleteScheduleAndUpdateView = async () => {
  try {
    await apiv2.schedule.deleteDay(dataStore.getState().dataDeleteSchedule)
    changeDataDeleteSchedule('')
    getScheduleEffect(tokenStore.getState());
  } catch (e) {
    console.log(e);
  }
};

export const addTimeSlotAndUpdateView = async (startDate, endDate) => {
  try {
    await apiv2.schedule.create(startDate.format(), endDate.format())
    changeDataForNewTimeSlot('')
    getScheduleEffect(tokenStore.getState());
  } catch (e) {
    console.log(e);
  }
};

getScheduleEffect.done.watch(({ result: schedule }) => {
  if (!schedule) {
    return;
  }
  changeIsAddEmptyDate(false);
  changeIsLoadingData(true);
  let newSchedule = schedule
  const canEditSchedule = applicationFeatureEnabled(FEATURE.EDIT_SCHEDULE);
  if ((canEditSchedule || process.env.APP_FLAVOR === "telemed") && dataStore.getState().isEditSchedule) {
    newSchedule = generateEmptyDays(schedule);
  }

  setData({
    items: newSchedule,
  });
});

const generateEmptyDays = (schedule) => {
  const date = moment();
  let prevIndex = 0;
  for (let i = 0; i < 15; i++) {
    const index = schedule.findIndex((item) => moment(item.date).isSame(date, 'date'));
    if (index === -1) {
      schedule.splice(prevIndex + 1, 0, {
        date: date.format("YYYY-MM-DD"),
        schedule: [],
      });
      prevIndex = prevIndex + 1;
    } else {
      prevIndex = index;
    }
    date.add(1, 'days');
  }
  changeIsAddEmptyDate(true)
  return schedule;
}

tokenStore.watch(token => {
  if (!token) {
    return;
  }
  getScheduleEffect(token);
});

export const appendOrUpdateData = createEvent<Schedule>("appendOrUpdateData");
dataStore.on(appendOrUpdateData, (state, newCell) => {
  const oldData = state.items.find((item) => item.date === newCell.date)?.schedule?.find((item) => item.id === newCell.id)
  if (!_.isEqual(oldData, newCell)) {
    return { ...state, items: appendOrUpdateDataSchedule(state.items, newCell) }
  }
});

export const removeCell = createEvent<number>("removeCell");
dataStore.on(removeCell, (state, id) => ({ ...state, items: removeCellFromSchedule(state.items, id) }));
