import React from 'react';
import styled from 'styled-components';
import {colors} from '../styleguide/colors';
import {Container as FixedCellContainer} from '../prescription-table/PrescriptionTableHeadCellFixed';
import {CaptionSmall} from '../styleguide/typography';

const Container = styled.th`
  min-width: 72px;
  height: 56px;
  box-sizing: border-box;
  padding: 15px 8px 15px 11px;
  border-left: solid 1px ${colors.transparentGray};
  vertical-align: middle;

  position: relative;

  ${FixedCellContainer} + & {
    border-left: none;
  }
  
  &:first-child {
    border-left: none;
  }
`;

const ValueContainer = styled.div``;

export const TableBottomCell = (props) => {
  return (
    <Container style={props.style}>
      {props.values.map((value, id) => {
        return (
          <ValueContainer key={id}>
            {value ? <CaptionSmall>{value}</CaptionSmall> : <CaptionSmall>&nbsp;</CaptionSmall>}
          </ValueContainer>
        );
      })}
    </Container>
  );
};
