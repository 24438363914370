import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, createFilterOptions, FormControl, FormLabel } from "@mui/material";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { observer } from "mobx-react";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "use-debounce";
import { Asterisk } from "../styled/Asterisk.styled";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const FormInputSelect = observer((props) => {
  const [options, setOptions] = useState(props.options || []);
  const [inputValue, setInputValue] = useState('');
  const [searchTextDebounced] = useDebounce(inputValue, 700);
  const [loading, setLoading] = useState(false);
  const valueName = props.valueName || 'value';
  const labelName = props.labelName || 'label';

  const fieldProps = {...props};
  delete fieldProps.valueName;
  delete fieldProps.labelName;
  delete fieldProps.needSearchOptions;
  delete fieldProps.getOptions;
  delete fieldProps.needSelectAll;
  delete fieldProps.transformInputValueClick;
  delete fieldProps.filterFnDisabled;
  delete fieldProps.minSearchLength;
  delete fieldProps.filterFn;
  delete fieldProps.parentName;

  const getOptionsSearch = async (search) => {
    setLoading(true);
    const asyncOptions = await props.getOptions(search);
    if (asyncOptions) {
      setOptions(asyncOptions);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (props.control._formValues[props.name] && props.needSearchOptions && props.getOptions) {
      if (props.multiple) {
        getOptionsSearch(props.control._formValues[props.name]);
      } else {
        setInputValue(props.control._formValues[props.name]);
      }
    }
  }, [props.control._formValues]);


  useEffect(() => {
    (async () => {
      if (props.getOptions && !props.needSearchOptions) {
        setLoading(true);
        const asyncOptions = await props.getOptions();
        setOptions(asyncOptions);
        setLoading(false);
      }
    })();
  }, [props.getOptions]);

  useEffect(() => {
    (async () => {
      if (props.getOptions && inputValue.length > (props.minSearchLength || 0) && props.needSearchOptions) {
        getOptionsSearch(inputValue);
      }
    })();
  }, [searchTextDebounced]);

  useEffect(() => {
    (() => {
      setOptions(props.options || []);
    })();
  }, [props.options]);


  const changeSelect = (data, onChange) => {
    if (fieldProps.multiple) {
      if (data.find(option => option.all)) {
        onChange(data.length - 1 === options.length ? [] : options.map((item) => item[valueName]))
      } else {
        onChange(data.map((item) => item[valueName]));
      }
    } else {
      onChange((data?.[valueName] || data?.[valueName] === false) ? data?.[valueName] : null);
    }
  }

  return (
    <FormControl fullWidth className={'dynamic-form-input-control'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
      {(props.label && props.withLabel) && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
      <Controller
        render={({ field: { onChange, value }, fieldState: { error }}) => (
          <Autocomplete
            {...fieldProps}
            label={props.withLabel ? '' : props.label}
            id={props.parentName || ""}
            options={options}
            filterOptions={!!props.needSelectAll ? (options, params) => { // <<<--- inject the Select All option
              const filter = createFilterOptions();
              const filtered = filter(options, params)
              return [{ [labelName]: 'Выбрать все', all: true }, ...filtered]
            } : undefined}
            disableCloseOnSelect={fieldProps.multiple}
            onChange={(e, data) => {
              changeSelect(data, onChange);
            }}
            getOptionLabel={(option) => option[labelName] || ''}
            defaultValue={fieldProps.multiple ? value || [] : ((value || value === false) ? value : '')}
            inputValue={!!props.needSearchOptions ? inputValue : undefined}
            onInputChange={(event, inputVal) => {
              if (event && props.needSearchOptions) {
                if (event.type === 'click' && props.transformInputValueClick) {
                  setInputValue(props.transformInputValueClick(inputVal));
                } else {
                  setInputValue(inputVal);
                }
              }
            }}
            value={(fieldProps.multiple ? options.filter((option) => value?.includes(option[valueName])) : options.find((option) => value === option[valueName])) || null}
            renderOption={fieldProps.multiple ? (props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={option.all ? !!(value?.length === options.length) : selected}
                />
                {option[labelName]}
              </li>
            ) : undefined}
            renderInput={(params) => {
              return <TextField
                {...params}
                error={!!error}
                helperText={error ? error.message : null}
                label={props.label}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            }}
          />
        )}
        control={props.control}
        name={props.name}
      />
    </FormControl>
  );
});
