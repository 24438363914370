// @flow
import React from "react";
import { observer } from "mobx-react";
import { Typography, useMediaQuery } from "@mui/material";
import { withModifiers } from "with-modifiers";
import { ClinicLogo } from "../clinicLogo/ClinicLogo";
import HeaderUser from "../header/HeaderUser";
import { Logout } from "../logout/Logout";
import { PNormal } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import headerStore, { TABS, TABS_NAME } from "../../stores/headerStore";
import styled from "styled-components";
import { HeaderBackButton } from "./HeaderBackButton";
import { HeaderDrawer } from "./HeaderDrawer";
import { Notices } from "../notices/Notices";
import { FEATURE, useApplicationFeatureEnabled } from "../../utils/applicationFeatures";

const HeaderWrapper = withModifiers({
  mobileDevice: () => `
    min-height: 60px;
    grid-template-areas: "pageTitle notification user";
    grid-template-columns: 1fr auto auto;
    @media (max-width: 768px) {
      padding: 0 40px;
    }  
    @media (max-width: 480px) {
      padding: 0 20px;
    }  
    ${UserContainer} {
      border-right: none;
    }
  `,
  backButtonArea: () => `
    grid-template-areas: "backButton pageTitle notification user";
    grid-template-columns: 40px 1fr auto auto;
  `,
  drawer: () => `
    grid-template-areas: "backButton pageTitle notification drawer";
    grid-template-columns: 40px 1fr auto auto;
  `
})(
  modifiers => styled.div`
    min-height: 40px;
    display: grid;
    grid-template-columns: 40px 1fr auto auto;
    grid-template-areas: "logo notification user exit";
    align-items: center;
    padding: 20px 30px 0;

    ${modifiers}
  `
);

const UserContainer = styled.div`
  position: relative;
  border-right: 1px solid ${colors.transparentGray};
  grid-area: user;
  ${PNormal} {
    margin-right: 10px;
  }
`;

const NotificationContainer = styled.div`
  position: relative;
  justify-self: end;
  grid-area: notification;
  margin-right: ${props => props.rightIndent ? "15px" : ""};
`;

/** padding-left (93px) = (width NotificationContainer + margin-right) + width UserContainer */
/** padding-left (55px) = width NotificationContainer + margin-right */
const TabsNameContainer = styled.div`
  .MuiTypography-root {
      font-size: 15px;
  }
  grid-area: pageTitle;
  justify-self: center;
  text-align: center;
  padding-left: ${(props) => {
    if (props.isShowUserContainer) {
      return "93px";
    } else if ((!props.isShowBackButton && props.isDrawer) || (!props.isShowBackButton && !props.isDrawer) || (props.isShowBackButton && props.isDrawer)) {
      return "55px";
    } else {
      return ""
    }
  }};
`;

type THeaderProps = {
  withLogo?: boolean,
  prependContent?: React.Node,
  isDrawer?: boolean,
  drawerContent?: React.Node
};

export const Header: FC<THeaderProps> = observer(({ withLogo = false, prependContent, isDrawer = false, drawerContent }) => {
  const isMobileDevice = useMediaQuery("(max-width: 768px)");
  const { activeTab, drawerIsCollapsed } = headerStore;

  const isShowLogo = withLogo && !isMobileDevice;
  const isShowNoticeHeader = useApplicationFeatureEnabled(FEATURE.SHOW_NOTICE_HEADER);
  const isShowUserContainer = !isMobileDevice || (isMobileDevice && activeTab === TABS.SCHEDULE);
  const isShowBackButton =
    isMobileDevice &&
    ![
      TABS.HISTORY,
      TABS.SCHEDULE,
      TABS.GENERAL_SCHEDULE,
      TABS.CHATS,
      TABS.MORE_NAVIGATION_TABS
    ].includes(activeTab);

  return (
    <HeaderWrapper modifiers={[isMobileDevice && "mobileDevice", isShowBackButton && "backButtonArea", isDrawer && "drawer"]}>
      {!isMobileDevice && prependContent}
      {isShowLogo && <ClinicLogo size={{ width: "37px", height: "37px" }} />}
      {isShowBackButton && <HeaderBackButton />}
      {isMobileDevice && (
        <TabsNameContainer isShowUserContainer={isShowUserContainer} isShowBackButton={isShowBackButton} isDrawer={isDrawer}>
          <Typography>{TABS_NAME[activeTab] || ""}</Typography>
        </TabsNameContainer>
      )}
      {isShowNoticeHeader && (
        <NotificationContainer rightIndent={isShowUserContainer || isDrawer}>
          <Notices />
        </NotificationContainer>
      )}
      {isShowUserContainer && (
        <UserContainer>
          <HeaderUser isHidingName={isMobileDevice} />
        </UserContainer>
      )}
      {!isMobileDevice && <Logout />}
      {isDrawer && <HeaderDrawer isOpen={drawerIsCollapsed} content={drawerContent} />}
    </HeaderWrapper>
  );
});
