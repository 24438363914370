import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Uploads {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getUploads(params) {
    const { patient, ...other } = params;

    return this.apiService.get(`patients/${patient}/uploads`, other);
  }

  patchUploads(id, params) {
    return this.apiService.patch(`uploads/${id}`, {}, params);
  }

  removeUploads(id) {
    this.apiService.delete(`uploads/${id}`);
  }
}

export default new Uploads();
