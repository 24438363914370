import React from "react";
import { makeAutoObservable } from "mobx";
import Binder from "../../utils/binder";
import { useToast } from "../../components/toast/useToast";
import { setToken } from "../../stores/auth";
import oldAuth from "../../apiv2/entities/oldAuth";
import auth from "../../apiv2/entities/auth";

export const STAGES = {
  AUTHORIZATION: "authorization",
  REGISTRATION: "registration"
};

const toastManager = useToast();

class CorporateLoginStore {
  stage = STAGES.AUTHORIZATION;
  isLoading = false;
  errorMessage = "";

  constructor() {
    this.resetFormData();

    Binder.bind(this, CorporateLoginStore);
    makeAutoObservable(this);
  }

  resetFormData() {
    this.authorizationFormData = {
      username: undefined,
      password: undefined
    };
    this.registrationFormData = {
      last_name: undefined,
      first_name: undefined,
      middle_name: undefined,
      username: undefined,
      password1: undefined,
      password2: undefined
    };
  }

  setIsLoading(flag) {
    this.isLoading = flag;
  }

  setErrorMessage(error) {
    this.errorMessage = error;
  }

  changeStage(stage) {
    this.setErrorMessage("");
    this.resetFormData();
    this.stage = stage;
  }

  changeFormValue(values) {
    this.setErrorMessage("");
    if (this.stage === STAGES.AUTHORIZATION) {
      this.authorizationFormData = { ...this.authorizationFormData, ...values };
    } else {
      this.registrationFormData = { ...this.registrationFormData, ...values };
    }
  }

  async handleOnAuthorization(params) {
    this.setIsLoading(true);
    try {
      const response = await oldAuth.postObtainToken(params || this.authorizationFormData);
      setToken(response.token);

      this.setErrorMessage("");
      this.resetFormData();
    } catch (error) {
      const message = "Введен неправильный логин или пароль";
      console.error(error);
      this.setErrorMessage(message);
      toastManager.showToast(<div>{message}</div>, {
        duration: 5000
      });
    }
    this.setIsLoading(false);
  }

  async handleOnRegistration() {
    this.setIsLoading(true);
    try {
      await auth.registration(this.registrationFormData);
      await this.handleOnAuthorization({
        username: this.registrationFormData.username,
        password: this.registrationFormData.password1
      });
    } catch (error) {
      const message = error?.result?.error || "Ошибка при регистрации";
      toastManager.showToast(<div>{message}</div>, {
        duration: 5000
      });
      this.setErrorMessage(message);
    }
    this.setIsLoading(false);
  }
}

export default new CorporateLoginStore();
