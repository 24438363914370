// @flow
import type { TAppointment_id } from "../../domain/entities/Appointment";
import type { Diagnosis } from "../../domain/entities/Diagnosis";
import * as R from "ramda";
import { createAppointmentStore } from "../../stores/createAppointmentStore";
import { initializeDiagnosisStore } from "../../stores/appointmentDiagnoses";
import { type Store } from "effector";
import { useAutoCleanedStore } from "../../utils/useAutoCleanedStore";

export const useDiagnoses = (
  rootStore: Store<{ [TAppointment_id]: ?Array<Diagnosis> }>,
  appointment_id: TAppointment_id,
  defaultValues?: Array<Diagnosis>
) => {
  const storeCreator = R.compose(
    initializeDiagnosisStore,
    (createAppointmentStore(appointment_id, defaultValues || []): any)
  );
  return useAutoCleanedStore<*, *, *>(() => storeCreator(rootStore), [rootStore, appointment_id]);
};
