import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Metric {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api/common/metric", null, null, API_URL);
  }
  getAnalyticsContent(filters) {
    return this.apiService.post(`analytics/content`, {}, filters);
  }

  getAnalyticsFirstOnlineAppSale(filters) {
    return this.apiService.post(`analytics/first-online-app-sale`, {}, filters);
  }
}

export default new Metric();
