import React from "react";
import styled from "styled-components";
import { AvatarPlaceholder } from '../../greeting/greetingCommon';

import { ItemImage } from './ItemComponents';
import asyaAvatar from '../images/asya_avatar.png';

const ChatAvatarPlaceholder = styled(AvatarPlaceholder)`
  width: 100%;
  height: 100%;
  border-radius: 0;
  font-family: "Graphik LCG";
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  color: #7d7d7d;
  
  background: ${props => props.background ? props.background : '#f3f3f3'};

	@media screen and (max-width: 1200px) {
		width: 100%;
		height: 100%;
		font-size: 17px;
	}
`;

export const OpponentAvatar = ({
  opponent
}) => {
  const hasAvatar = opponent && !!opponent.image;
  const avatarIsColor = hasAvatar && opponent.image.startsWith("#");

  return (
    hasAvatar && !avatarIsColor ? (
      <ItemImage src={ opponent.image }/>
    ) : (
      <ChatAvatarPlaceholder background={avatarIsColor ? opponent.image : null}>
        {opponent.last_name.slice(0, 1) + opponent.first_name.slice(0, 1)}
      </ChatAvatarPlaceholder>
    )
  );
};

export const SenderAvatar = ({
  sender
}) => {
  const hasAvatar = sender && !!sender.avatar;
  const avatarIsColor = hasAvatar && sender.avatar.startsWith("#");

  return (
    sender ? !avatarIsColor ? (
      <ItemImage src={ sender.avatar }/>
    ) : (
      <ChatAvatarPlaceholder background={avatarIsColor ? sender.avatar : null}>
        {sender.last_name.slice(0, 1) + sender.first_name.slice(0, 1)}
      </ChatAvatarPlaceholder>
    ) : (
      <ItemImage src={ asyaAvatar }/>
    )
  );
};