// @flow
import React from "react";
import { useMediaQuery, useTheme, Unstable_Grid2 as Grid } from "@mui/material";
import { CorporateSideBarLayout } from "./layouts";

export const CorporateContent = ({ rightSideBarContent = [], children }) => {
  const theme = useTheme();
  const isShowRightSideBar =
    useMediaQuery(theme.breakpoints.up("laptop")) || !rightSideBarContent.length;

  return (
    <React.Fragment>
      <Grid xs>{children}</Grid>
      {isShowRightSideBar && !!rightSideBarContent.length && (
        <CorporateSideBarLayout>
          {rightSideBarContent.map(({ name, content }) => (
            <React.Fragment key={name}>{content}</React.Fragment>
          ))}
        </CorporateSideBarLayout>
      )}
    </React.Fragment>
  );
};
