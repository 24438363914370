import React from "react";
import moment from "moment";
import styled from "styled-components";
import { useAsync } from "react-use";
import useReactRouter from "use-react-router";
import { PNormal } from "../styleguide/typography";
import { useStore } from "effector-react";
import { tokenStore, currentUser } from "../../stores/auth";
import {
  AppointmentPrintableHeaderItem,
  AppointmentPrintableHeaderLayout
} from "../appointment-print/AppointmentPrintableHeader";
import { AppointmentSign } from "../appointment-print/AppointmentSign";
import { AppointmentPrintLayout } from "../appointment-print/AppointmentPrintLayout";
import { useBeforePrint } from "../appointment-print/useBeforePrint";
import { useLogo } from "../appointment-print/useLogo";
import {
  formatPatientBirthDate,
  getAgeByBirthDate,
  getPatientFullName
} from "../../domain/services/patient";
import { getNounEndingByNumber } from "../../utils/getNounEndingByNumber";
import { getTableData, getTableColumns } from "./utils";
import { VIEW_TYPES } from "./constants";

import { useAfterPrint } from "../appointment-print/useAfterPrint";

import defaultLogo from "../appointment-print/icons/logo.svg";
import apiv2 from '../../apiv2';

export const formatDate = date => {
  return moment
    .utc(date)
    .locale("ru")
    .format("D MMMM YYYY");
};

const Logo = styled.img`
  width: 175px;
  height: 77px;
  object-fit: scale-down;
  object-position: left;
`;

const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 80px;

  & th {
    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    height: 44px;
    max-width: 300px;
    padding: 0 12px;
    vertical-align: middle;
    border: none;
    border-bottom: 1px solid #c6007f;
    border-right: 1px solid rgb(68, 68, 68, 0.1);
    overflow: hidden;
    text-align: left;
    box-sizing: border-box;
    user-select: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.35;
    white-space: pre;
  }

  & td {
    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    height: 44px;
    max-width: 300px;
    padding: 8px 12px;
    vertical-align: middle;
    border-right: 1px solid rgb(68, 68, 68, 0.1);
    border-bottom: 1px solid rgb(68, 68, 68, 0.1);
    overflow: hidden;
    word-break: keep-all;
    text-align: left;
    box-sizing: border-box;
    user-select: none;
    font-size: 14px;
  }
`;

const TableTitle = styled.div`
  font-size: 22px;
  font-weight: 400;
  margin: 37px 0 21px 0;
`;

const tablesType = {
  cryo: "cryo",
  byFolder: "by_folder"
};

const allTablesFilters = {
  [tablesType.byFolder]: [
    VIEW_TYPES.CULTIVATION_AND_FERTILIZATION,
    VIEW_TYPES.BIOPSIES,
    VIEW_TYPES.CRYOPRESERVATIONS,
    VIEW_TYPES.DEFROSTS
  ],
  [tablesType.cryo]: [VIEW_TYPES.CRYOPRESERVATIONS, VIEW_TYPES.DEFROSTS]
};

const filtersTitles = {
  [VIEW_TYPES.CULTIVATION_AND_FERTILIZATION]: "Культивирование",
  [VIEW_TYPES.BIOPSIES]: "Биопсия",
  [VIEW_TYPES.CRYOPRESERVATIONS]: "Криохранилище",
  [VIEW_TYPES.DEFROSTS]: "Размораживание"
};

const oocytesFiltersTitles = {
  [VIEW_TYPES.CULTIVATION_AND_FERTILIZATION]: "Культивирование",
  [VIEW_TYPES.BIOPSIES]: "Биопсия",
  [VIEW_TYPES.CRYOPRESERVATIONS]: "Криохранилище",
  [VIEW_TYPES.DEFROSTS]: "Размораживание"
};

const renderEmbryosTable = (data, filter) => {
  const tableData = getTableData(filter, data);

  const filteredForPrintTable = tableData.map((row) => row.filter(({ print }, columnIndex) => {
    const isColumnForPrint = (typeof print === "function" ? print(filter) : print);
    const isColumnsHasData = tableData.some(tableRow => tableRow[columnIndex].value);

    return isColumnForPrint && isColumnsHasData;
  }));

  const printColumnsMap = tableData[0].map((column) => {
    return !!filteredForPrintTable[0].find(filteredColumn => filteredColumn.field === column.field);
  });

  const columns = getTableColumns(filter);
  const filteredForPrintColumns = columns.filter((_, i) => printColumnsMap[i]);

  return (
    <Table key={filter}>
      <thead>
        <tr>
          {filteredForPrintColumns.map((column, i) => (
            <th key={i}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {filteredForPrintTable.map((row, i) => (
          <tr key={i}>
            {row.map((cell, i) => (
              <td key={i}>
                {cell.DataViewer ? <cell.DataViewer cell={cell} print /> : cell.value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const Container = ({ logoEl, medicalFile, embryosData, doctor, setLogoLoaded, filters }) => {
  if (!logoEl || !medicalFile || !doctor || !embryosData) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  const logo = (
    <Logo
      ref={logoEl}
      src={medicalFile.organization?.logo || defaultLogo}
      onLoad={() => setLogoLoaded(true)}
    />
  );

  const patientAge = getAgeByBirthDate(medicalFile.patient);

  const appointmentPrintableHeaderItems = (
    <>
      <AppointmentPrintableHeaderItem
        label="Эмбриолог"
        content={`${doctor.last_name} ${doctor.first_name} ${doctor.middle_name}`}
        notFixedWidth
      />
      <AppointmentPrintableHeaderItem
        label="Пациент"
        content={`${getPatientFullName(medicalFile.patient)} (№${medicalFile.number}${
          medicalFile.badge ? `, ${medicalFile.badge}` : ""
        })`}
        notFixedWidth
      />
      <AppointmentPrintableHeaderItem label="Дата" content={formatDate(new Date())} notFixedWidth />
      <AppointmentPrintableHeaderItem
        label="Дата рождения"
        content={
          patientAge
            ? `${formatPatientBirthDate(
                medicalFile.patient
              )} (${patientAge} ${getNounEndingByNumber(patientAge, ["год", "года", "лет"])})`
            : `${formatPatientBirthDate(medicalFile.patient)}`
        }
        notFixedWidth
      />
    </>
  );

  const header = (
    <AppointmentPrintableHeaderLayout logo={logo}>
      {appointmentPrintableHeaderItems}
    </AppointmentPrintableHeaderLayout>
  );

  const sign = (
    <AppointmentSign
      title="Эмбриолог"
      doctor={{
        name: null,
        regalia: doctor.regalia || null
      }}
    />
  );

  return embryosData.map((data, i) => {
    const title = <TableTitle>Эмбриологический протокол — {filtersTitles[filters[i]]}</TableTitle>;

    return (
      <AppointmentPrintLayout
        key={i}
        sign={sign}
        breakAfter={i + 1 !== embryosData.length}
        values={data.length > 0 ? renderEmbryosTable(data, filters[i]) : "Нет данных"}
        header={header}
        title={title}
        width="100%"
      />
    );
  });
};

export const PrintableEmbryosPage = () => {
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();

  const type = params.folder_id ? tablesType.byFolder : tablesType.cryo;

  const { logoEl, isLogoLoaded, setLogoLoaded } = useLogo();

  const user = useStore(currentUser);

  const filters = params.filter === VIEW_TYPES.ALL ? allTablesFilters[type] : [params.filter];

  const medicalFileRequestResult = useAsync(async () => {
    const result = await apiv2.medicalFiles.getById(params.medical_file_id);

    return result;
  }, []);

  const embryosRequestResult = useAsync(async () => {
    if (medicalFileRequestResult.value) {
      if (type === tablesType.byFolder) {
        const result = Promise.all(
          filters.map(filter =>
            apiv2.embryos.getEmbryosByFolderId(params.folder_id, filter)
          )
        );

        return result;
      } else {
        const result = Promise.all(
          filters.map(filter =>
            apiv2.embryos.get({
              patientId: medicalFileRequestResult.value.patient.id,
              viewType: filter
            })
          )
        );

        return result;
      }
    }

    return null;
  }, [medicalFileRequestResult, params]);

  const allDataPrepared = isLogoLoaded && medicalFileRequestResult.value && user;

  const backUrl =
    type === tablesType.byFolder
      ? `/appointment/folder/${params.folder_id}/embryos/${params.medical_file_id}`
      : `/appointment/embryos/${params.medical_file_id}`;

  useBeforePrint(allDataPrepared);

  useAfterPrint(backUrl);

  return (
    <Container
      logoEl={logoEl}
      setLogoLoaded={setLogoLoaded}
      medicalFile={medicalFileRequestResult.value}
      embryosData={embryosRequestResult.value}
      filters={filters}
      doctor={user && user.doctor}
    />
  );
};
