import { observer } from "mobx-react";
import BonusPointingIcon from "../../../assets/icons/bonusPointing.inline.svg";
import React, { useEffect, useState } from "react";
import {
  BonusPaper,
  FlexBlock,
  BonusTitleBold,
  FlexBlockGapMini,
  BonusText,
  BonusSubtitle
} from "./bonusSchema.styled";
import { LineChart } from "@mui/x-charts/LineChart";
import { HorizontalBar } from "./HorizontalBar";
import { ChartsReferenceLine } from "@mui/x-charts";
import bonusSchemaStore from "../../../stores/bonusSchemaStore";
import * as moment from "moment";
import styled from "styled-components";
import debounce from 'lodash.debounce';
import dayjs from "dayjs";
import {READABLE_DATE_FORMAT} from "../../../utils/dates";

const BonusPointingIconShrink = styled(BonusPointingIcon)`
  flex-shrink: 0;
`;

const LineChartContainer = styled.div`
    width: 100%;
`;

export const BonusSchemaRateCard = observer(() => {

  const labels = bonusSchemaStore.bonusSchemaData.docplus_evaluation.days.map(item => dayjs(item.date_trunc).format(READABLE_DATE_FORMAT))
  const [widthLineChart, setWidthLineChart] = useState(0);

  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setWidthLineChart(document.getElementsByClassName('bonus-schema-container')[0]?.offsetWidth - 32);
    }, 100);

    window.addEventListener('resize', debouncedResizeHandler);
    setWidthLineChart(document.getElementsByClassName('bonus-schema-container')[0]?.offsetWidth - 32);
    return () => {
      debouncedResizeHandler.cancel();
      window.removeEventListener('resize', debouncedResizeHandler);
    };
  }, []);

  const ucFirst = (str) => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  }

  const data = bonusSchemaStore.bonusSchemaData.docplus_evaluation.days.map(item => item.round)

  const averageData = data.reduce((acc, item) => {
    acc += item;
    return acc;
  }, 0) / data.length;
  if (!averageData && !bonusSchemaStore.bonusSchemaData.docplus_evaluation.prev_month_value) {
    return null;
  }

  return (
    <>
      <BonusPaper>
        <FlexBlock>
          <BonusTitleBold>Оценка карт</BonusTitleBold>
        </FlexBlock>
        {/*<FlexBlockGapMini modifiers={'alignStart'}>*/}
          {/*<BonusPointingIconShrink modifiers={'green'} />*/}
          {/*<BonusText modifiers={'gray'}>В этом месяце ты можешь улучшить свой результат </BonusText>*/}
        {/*  ToDo или открыть этот BonusText только когда предыдущий месяц больше текущего*/}
        {/*</FlexBlockGapMini>*/}
        {!!bonusSchemaStore.bonusSchemaData.docplus_evaluation.prev_month_value && (
          <FlexBlock modifiers={"wrap"}>
            <BonusSubtitle modifiers={'black'}>{ucFirst(moment().subtract(1, 'month').format('MMMM'))}:</BonusSubtitle>
            <BonusSubtitle modifiers={bonusSchemaStore.bonusSchemaData.docplus_evaluation.prev_month_value < 90 ? 'red' : 'green'}>{bonusSchemaStore.bonusSchemaData.docplus_evaluation.prev_month_value}%</BonusSubtitle>
            {bonusSchemaStore.bonusSchemaData.docplus_evaluation.prev_month_value < 90 && <HorizontalBar
              backgroundWrapper="#F0DDDF"
              backgroundBar="#F2576A"
              height="4px"
              widthBar={bonusSchemaStore.bonusSchemaData.docplus_evaluation.prev_month_value + '%'}
            />
            }
          </FlexBlock>
        )}
        {!!averageData &&
          <FlexBlock>
            <BonusSubtitle modifiers={'black'}>{ucFirst(moment().format('MMMM'))}:</BonusSubtitle>
            <BonusSubtitle modifiers={averageData < 90 ? 'red' : 'green'}>{parseInt(averageData)}%</BonusSubtitle>
          </FlexBlock>
        }
        <LineChartContainer>
          <LineChart
            xAxis={[{ data: labels, scaleType: "point" }]}
            series={[
              {
                "label": "Оценка карт(%): ",
                data: data,
              },
            ]}
            margin={{ top: 10, left: 35, right: 10, bottom: 20 }}
            slotProps={{
              legend: {
                hidden: true,
              }
            }}
            width={widthLineChart}
            height={200}
          >
            <ChartsReferenceLine y={averageData} lineStyle={{ stroke: averageData < 90 ? '#F2576A' : '#31BD2E', strokeWidth: 2 }} />
          </LineChart>
        </LineChartContainer>
      </BonusPaper>
    </>
  )
})
