// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { HeadingH4, PSignNormal, PSmall } from "../styleguide/typography";
import { AppointmentSign } from "./AppointmentSign";

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  border-bottom: solid 1px ${colors.transparentGray};
`;

const SignContainer = styled.div`
  page-break-inside: avoid;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const ConsentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 15px;
`;

const ConsentText = styled(PSmall)`
  width: 517px;
`;

const Date = styled(PSignNormal)`
  &:after {
    display: inline-block;
    content: "/";
    margin: 0 6px;
  }
`;

export const AppointmentUserAgreement = (props: {
  title: ?string,
  text: ?string,
  date: string,
  patient: string
}) => (
  <Container>
    <Header>{props.title && <HeadingH4>{props.title}</HeadingH4>}</Header>
    <ConsentContainer>
      <ConsentText>{props.text}</ConsentText>
      <SignContainer>
        <Date>{props.date}</Date>
        <AppointmentSign patient={props.patient} />
      </SignContainer>
    </ConsentContainer>
  </Container>
);
