// @flow
import * as React from "react";

import {AvailableApplicationFlavors, getValueByFlavor} from '../../utils/getApplicationFlavor';

export const ComponentFabric = (props: {|
	flavors: {
		[AvailableApplicationFlavors.DEFAULT | AvailableApplicationFlavors.TELEMED | AvailableApplicationFlavors.SUNRISE]: mixed
	},
|}) => {
	return getValueByFlavor(props.flavors);
};
