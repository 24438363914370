import React, { useState } from "react";
import styled, { css } from "styled-components";
import { LinearGradientBackground } from "../styleguide/LinearGradientBackground";
import { Header } from "../header/Header";
import { ScrollDetector } from "../scroll-detector/ScrollDetector";
import { ScrollContainerDefault } from "../scroll-detector/ScrollContainerDefault";
import { ShadowContainerDefault } from "../scroll-detector/ShadowContainerDefault";
import { colors } from "../styleguide/colors";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { rgba } from "polished";
import { BackToScheduleButton } from "../appointment-switch-page/BackToScheduleButton";

import menuIcon from "./icons/menu-icon.svg";
import menuCloseIcon from "./icons/menu-close-icon.svg";
import { useMediaQuery } from "@mui/material";
import { NavigationTabs } from "../navigation-tabs/NavigationTabs";
import { TabsContainer } from "./BaseLayout";

const Container = styled.div`
  display: flex;
`;

const MainContainer = styled(LinearGradientBackground)`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
`;

const AsideContainerWrapper = styled.div`
  @media screen and (max-width: 1200px) {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(${props => (props.visible ? "0" : "-100%")});
    z-index: 20;
  }
  @media (max-width: 768px) {
    right: 0;
    left: auto;
    transform: translateX(${props => (props.visible ? "0" : "100%")});
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const AsideContainer = styled(RoundedRectangleWithShadow)`
  width: ${props => props.width || 330}px;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  flex-shrink: 0;
  @media (max-width: 768px) {
    height: calc(100% - 50px);
    right: 0;
    left: auto;
  }
  @media (max-width: 480px) {
    width: 100vw;
  }
`;

export const AsideHeader = styled.div`
  padding: 20px 20px 10px;
  background-color: ${rgba(colors.gray100, 0.3)};
  @media (max-width: 768px) {
    padding: 0 15px 10px;
  }
  @media (max-width: 480px) {
    padding: 0 10px 10px;
  }
`;

const ScrollContainer = styled(ScrollContainerDefault)`
  height: 100%;
`;

const ShadowContainer = styled(ShadowContainerDefault)`
  flex-grow: 1;
  min-height: 0;

  :before,
  :after {
    left: 0;
    width: 100%;
    height: 53px;
    pointer-events: none;
  }

  :before {
    top: 0;
    border-top: 1px solid ${colors.transparentGray};
    background-image: none;
    opacity: ${props => props.top && 1};
  }

  :after {
    bottom: 0;
    top: auto;
    background-image: linear-gradient(to top, ${colors.white}, rgba(255, 255, 255, 0));
    opacity: ${props => props.bottom && 1};
  }
`;

const BackButtonContainer = styled.div`
  margin-bottom: 0;

  a,
  button {
    margin-bottom: 0;
  }
`;

const AsideToggleButtonContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  @media (max-width: 768px) {
    width: auto;
    height: auto;
    grid-area: doctor;
    justify-self: flex-end;
  }
`;

const AsideToggleButton = styled.button`
  display: none;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 16px;
  z-index: 12;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    position: static;
  }
  ${props => props.visible ? css`
    background: transparent;
    right: 16px;
  ` : css`
    background: #fff;
    right: -56px;
    box-shadow: 0 7px 9px 0 rgba(0,0,0,0.03);
  `}
`;

export const AsideScrollableContentContainer = ({ children }) => {
  return (
    <ScrollDetector shadowContainer={ShadowContainer} scrollContainer={ScrollContainer}>
      {children}
    </ScrollDetector>
  );
};

const AsideContent = ({ isAsideHidden, aside, isMobileDevice }) => {
  const [asideVisible, setAsideVisible] = useState(false);
  const handleToggleAside = () => setAsideVisible(!asideVisible);

  return isMobileDevice ? null : (
    <AsideContainerWrapper
      style={{ display: isAsideHidden ? "none" : "" }}
      visible={asideVisible}
    >
      <AsideToggleButtonContainer>
        <AsideToggleButton onClick={handleToggleAside} visible={asideVisible}>
          {asideVisible ? <img src={menuCloseIcon} alt="Close icon" /> : <img src={menuIcon} alt="Close icon" />}
        </AsideToggleButton>
      </AsideToggleButtonContainer>
      {aside}
    </AsideContainerWrapper>
  )
}

export const AsideLayout = ({ isAsideHidden, withHeaderBackButton, aside, modals, children, background }) => {
  const isMobileDevice = useMediaQuery('(max-width: 768px)');

  return (
    <Container>
      <AsideContent isAsideHidden={isAsideHidden} aside={aside} isMobileDevice={isMobileDevice} />
      <MainContainer style={background ? {backgroundImage:"none", background: background} : {}}>
        <Header
          isDrawer={!isAsideHidden && isMobileDevice}
          drawerContent={aside}
          prependContent={
            withHeaderBackButton && (
              <BackButtonContainer>
                <BackToScheduleButton />
              </BackButtonContainer>
            )
          }
        />
        {children}
        {isMobileDevice && (
          <TabsContainer>
            <NavigationTabs isMobileDevice={isMobileDevice} />
          </TabsContainer>
        )}
      </MainContainer>
      {modals}
    </Container>
  );
};
