//@flow
import * as React from "react";
import styled from "styled-components";
import { withModifiers } from "with-modifiers";

import { Tabs, DefaultTab } from "../tabs/Tabs";
import { PNormal } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import accountStore from "../../stores/accountStore";
import { observer } from "mobx-react";

const Tab = withModifiers({
  active: () => `
    color: ${colors.black};
    opacity: 1;
  `
})(
  modifier => styled(DefaultTab)`
    background-color: #ffffff;
    opacity: 0.6;
    border-radius: 4px 4px 0px 0px;
    border: none;
    margin-right: 4px;
    padding: 8px;
    line-height: 23px;
    cursor: pointer;
    
    &:last-child {
      margin-right: 0;
    }

    ${PNormal} {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
    }
    

    ${modifier};

    :last-child {
      border: none;
      margin-right: 0;
    }
  `
);

const AccountTab = props => (
  <Tab modifiers={props.modifiers} onClick={props.onClick}>
    <PNormal>{props.children}</PNormal>
  </Tab>
);

const tabs = {
  // me: { name: "О себе" },
  services: { name: "Услуги" },
  // finance: { name: "Финансы" }
};

export const AccountTabs = observer(() => (
  <Tabs
    activeTab={accountStore.activeTab}
    handleClickOnTab={(tab) => accountStore.setActiveTab(tab)}
    tabs={tabs}
    overrides={{
      DefaultTab: {
        component: AccountTab
      }
    }}
  />
));
