import React, { useCallback, useState } from "react";

import * as S from "./styled/TimeInput.styled";

export const hasTimeFormatError = value => {
  const [hours, minutes] = value.split(":").map(Number);
  return hours === undefined || minutes === undefined || (hours > 23 || minutes > 59);
};

export const TimeInput = ({ value, invalid, onChange }) => {
  const [touched, setTouched] = useState(false);

  const handleChange = useCallback(
    e => {
      const [hours, minutes] = e.target.value.split(":").map(Number);

      if (!value && hours && !minutes && hours >= 3 && hours <= 9) {
        e.target.value = `0${e.target.value}`;
      }

      onChange && onChange(e.target.value);
    },
    [onChange, value]
  );

  const handleBlur = useCallback(
    e => {
      const [hours, minutes] = value.split(":");

      if (minutes && minutes.length == 1) {
        e.target.value = `${hours}:0${minutes}`;
      }

      onChange(e.target.value);

      setTouched(value !== "");
    },
    [onChange, value]
  );

  const error = invalid || (touched && hasTimeFormatError(value)) ? "error" : undefined;

  return (
    <S.Input
      defaultValue=""
      value={value}
      placeholder="00:00"
      guide={false}
      mask={[/\d/, /\d/, ":", /\d/, /\d/]}
      error={error}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
