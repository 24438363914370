// @flow
import * as React from "react";
import styled from "styled-components";
import { AppointmentFormLayout } from "../appointment-page/AppointmentFormLayout";

const ContentContainer = styled.div`
  position: relative;
  padding: 20px 50px 50px 100px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 15px 40px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

const MetaInfoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const SectionsContainer = styled.div`
  display: flex;
  margin-top: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 25px;
    margin-top: 30px;
  }
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

export const AnalyzesFormLayout = (props: {
  title: React.Node,
  metaInfo?: React.Node,
  toolButtons?: React.Node,
  sections: React.Node,
  analyzes?: React.Node,
  onlineSection?: React.Node,
  buttonForm?: React.Node
}) => {
  return (
    <AppointmentFormLayout
      {...props}
      overrides={{
        ContentContainer: {
          component: ContentContainer
        },
        MetaInfoContainer: {
          component: MetaInfoContainer
        },
        SectionsContainer: {
          component: SectionsContainer
        }
      }}
    />
  );
};
