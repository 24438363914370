import React, { useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { setFieldValue } from "../../stores/appointmentFieldValues";
import { getNumberValue } from "./getNumberValue";
import { useFieldValue } from "./useFieldValue";
import { READABLE_TIME_FORMAT_MOMENT } from "../../utils/dates";
import * as moment from "moment";
import { colors } from "../styleguide/colors";
import styled from "styled-components";

const MuiTimePicker = styled(DesktopTimePicker)`
  width: 239px;
  @media (max-width: 576px) {
    width: 100%;
  }
  .MuiInputBase-root {
    .MuiInputBase-input {
      padding: 12px 14px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
      border-color: ${colors.gray100};
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.gray100};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.gray300};
    }
    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.watermelon};
    }
  }
`;

/** При использовании TimePicker, на мобильном устройстве на данный момент не поддерживается очищение поля
 * т.к. поле readonly, а clearable выводится при фокусе https://github.com/mui/mui-x/issues/7869 */
export const TimePickerWidget = props => {
  const [error, setError] = useState(false);
  const { layoutFieldValue, field, appointment_id, showError } = props;
  const result = useFieldValue(appointment_id, field.id);
  const value = getNumberValue(result, layoutFieldValue);

  useEffect(() => {
    setError(showError);
  }, [showError]);

  return (
    <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterMoment}>
      <MuiTimePicker
        className={showError ? "error-field" : ""}
        value={value ? moment(value, READABLE_TIME_FORMAT_MOMENT) : null}
        format={READABLE_TIME_FORMAT_MOMENT}
        onChange={time => {
          if (time && !time.isValid()) {
            return;
          }

          setFieldValue({
            field_type: field.field_type,
            appointment_id,
            field_id: field.id,
            value: time?.isValid() ? time.format(READABLE_TIME_FORMAT_MOMENT) : ""
          });
        }}
        onError={event => setError(!!event)}
        slotProps={{
          textField: {
            error
          },
          field: { clearable: true }
        }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
      />
    </LocalizationProvider>
  );
};
