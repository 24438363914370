// @flow
import styled from "styled-components";
import closeImg from "../appointment-widgets/icons/close.svg";
import { PrescriptionTableCellFixed } from "./PrescriptionTableCell";

export const RemoveRowButton = styled.button`
  border: none;
  width: 11px;
  height: 11px;
  opacity: 0;
  background: url(${closeImg}) no-repeat;
  background-size: contain;

  ${PrescriptionTableCellFixed}:hover & {
    opacity: 0.3;
  }

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
`;
