// @flow
import React from "react";

import { useConfirm } from "../alert-warning/useConfirm";
import { AlertWarning } from "../alert-warning/AlertWarning";
import { HeadingH3 } from "../styleguide/typography";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { ModalPortal } from "../modal/ModalPortal";
import { CleanButton } from "../buttons/CleanButton";

export const RemovePrescriptionTableWithConfirmButton = (props: { onClick: () => mixed }) => {
  const removeTableConfirm = useConfirm();

  const removeTableWarning = (
    <AlertWarning
      isNeedConfirm={removeTableConfirm.isNeedConfirm}
      sendConfirm={removeTableConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Вы точно хотите удалить таблицу?</HeadingH3>}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отмена</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
      )}
    />
  );

  const onButtonClick = async () => {
    if (!removeTableConfirm.requestConfirm) {
      return;
    }

    try {
      const confirmResult = await removeTableConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      props.onClick();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <CleanButton onClick={onButtonClick}>Удалить</CleanButton>
      <ModalPortal>{removeTableWarning}</ModalPortal>
    </>
  );
};
