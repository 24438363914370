// @flow
import React from "react";
import { useRef, useEffect } from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("modal-root");

export const ModalPortal = (props: { children: React$Node }) => {
  const el = useRef(document.createElement("div"));

  useEffect(() => {
    modalRoot && modalRoot.appendChild(el.current);
    return () => {
      modalRoot && modalRoot.removeChild(el.current);
    };
  }, []);

  return ReactDOM.createPortal(<React.Fragment>{props.children}</React.Fragment>, el.current);
};
