// @flow
import styled from "styled-components";
import { colors } from "../styleguide/colors";

export const PrescriptionTableCell = styled.td`
  height: 61px;

  box-sizing: border-box;
  border-left: solid 1px ${colors.transparentGray};

  position: relative;
  vertical-align: middle;

  border-bottom: solid 1px ${colors.transparentGray};
`;

export const PrescriptionTableCellFixed = styled(PrescriptionTableCell)`
  position: sticky;
  left: 0;

  background: ${colors.white};

  border-left: none;
  z-index: 10;
`;
