// @flow
import * as React from "react";
import styled from "styled-components";
import type { TItems } from "./ButtonCheckboxGroup";
import { ButtonCheckboxGroup } from "./ButtonCheckboxGroup";
import { Background } from "./ButtonCheckbox";

type TButtonRadioGroupProps<T: TItems> = {
  tabIndex?: number,
  items: T,
  checked?: $Keys<T>,
  name: string,
  order?: Array<string>,
  shouldUncheck?: boolean,
  shouldChangeOnClick?: boolean,
  onChange?: ($Keys<T> | void) => mixed
};

const RadioBackground = styled(Background)`
  border-radius: 15px;
`;

export const ButtonRadioGroup = <T: TItems>(props: TButtonRadioGroupProps<T>) => {
  const {
    checked,
    name,
    onChange,
    shouldUncheck = true,
    shouldChangeOnClick = true,
    ...otherProps
  } = props;
  return (
    <ButtonCheckboxGroup
      {...otherProps}
      checked={checked ? [checked] : []}
      onChange={item => onChange && onChange(shouldUncheck && item === checked ? undefined : item)}
      onClick={item =>
        onChange &&
        shouldChangeOnClick &&
        onChange(shouldUncheck && item === checked ? undefined : item)
      }
      overrides={{
        ButtonCheckbox: {
          props: {
            overrides: {
              Input: {
                props: {
                  type: "radio",
                  name
                }
              },
              Background: {
                component: RadioBackground
              }
            }
          }
        }
      }}
    />
  );
};
