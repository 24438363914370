//@flow
import * as React from "react";
import styled from "styled-components";
import { useContext } from "react";

import { ScrollContext } from "../scroll-detector/ScrollDetector";
import { colors } from "../styleguide/colors";
import { PNormal } from "../styleguide/typography";

const Cell = styled.td`
  padding: 16px 0;
  padding-right: 25px;
  background-color: ${colors.white};
  :last-child {
    padding-right: 0;
  }
`;

export const CellFixed = styled(Cell)`
  position: sticky;
  left: 0;
`;

export const DateCell = styled(Cell)`
  min-width: 70px;
  width: 70px;
`;

export const NumberCell = styled(Cell)`
  min-width: 100px;
  width: 100px;
`;

export const TextCell = styled(Cell)`
  min-width: 230px;
  width: 230px;
`;

export const HistoryCellDate = (props: {| isFixed?: boolean, children: string |}) => {
  const { children, isFixed } = props;
  const { setFixedColumnRef } = useContext(ScrollContext);

  return (
    <DateCell type="date" as={isFixed && CellFixed} ref={isFixed ? setFixedColumnRef : undefined}>
      <PNormal modifiers={"left"}>{children}</PNormal>
    </DateCell>
  );
};
