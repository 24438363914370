import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class DMSServices {
	apiService;
	constructor() {
		this.apiService = new ApiService('/api', null, null, API_URL);
	}

	createOrUpdate(data) {
		return this.apiService.post(`dms/service-for-approval`, {}, data);
	}

	getServiceForApproval(appointmentId) {
		return this.apiService.get(`dms/service-for-approval`, {appointment_id: appointmentId});
	}

}

export default new DMSServices();
