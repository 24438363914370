import apiv2 from "../apiv2";
import { makeAutoObservable } from "mobx";
import { getAppointmentsHistoryInterval } from "../domain/services/appointments";
import moment from "moment";
import {
  READABLE_DATE_FORMAT,
  READABLE_TIME_FORMAT_MOMENT,
  SERVER_TIME_FORMAT
} from "../utils/dates";

export const ACTIVE_TABS = {
  ME: "me",
  ALL: "all"
};

export const COLUMN_PARAMS = {
  [ACTIVE_TABS.ME]: ["dateTime", "patient", "sample", "inProgress"],
  [ACTIVE_TABS.ALL]: ["dateTime", "doctor", "patient", "sample"]
};

export const LIMIT = 15;

class AppointmentHistoryStore {
  activeTab = ACTIVE_TABS.ME;
  dataTable = {
    [ACTIVE_TABS.ME]: [],
    [ACTIVE_TABS.ALL]: []
  };

  constructor() {
    makeAutoObservable(this);
  }

  changeActiveTab(tab) {
    this.activeTab = tab;
  }

  async getAppointments({ searchValue, isOnlyPatient, ...options }) {
    const { from_date } = getAppointmentsHistoryInterval();
    const search_fields =
      this.activeTab === ACTIVE_TABS.ME ? "patient,layout" : "doctor,patient,layout";
    const only_patient = this.activeTab === ACTIVE_TABS.ME ? true : isOnlyPatient;
    const sort_date_finished = this.activeTab === ACTIVE_TABS.ME;

    const params = {
      from_date,
      doctor: this.activeTab,
      search: searchValue,
      only_patient,
      search_fields,
      sort_date_finished,
      ...options
    };

    try {
      const response = await apiv2.appointments.getAppointments(params);
      return response.map(item => {
        const src = item.doctor.photo.slice(-1) === "/" ? null : item.doctor.photo;

        return {
          id: item.id,
          dateTime: `${moment(item.date).format(READABLE_DATE_FORMAT)}, ${moment(
            item.time,
            SERVER_TIME_FORMAT
          ).format(READABLE_TIME_FORMAT_MOMENT)}`,
          doctor: item.doctor,
          doctorPhoto: src,
          patient: item.medical_file.patient,
          patientBadge: item.medical_file.badge || null,
          sample: item.layout.name,
          inProgress: item.in_progress
        };
      });
    } catch (e) {
      console.error(e);
    }
  }
}

export default new AppointmentHistoryStore();
