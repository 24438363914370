import * as React from "react";

import * as CommonViewerStyles from "./MyPatientsTableViewer.styled";
import * as S from "./MyPatientsTableViewersSelect.styled";

export const MyPatientsTableViewerSelect = ({ cell: { value } }) => (
  <CommonViewerStyles.Container>
    {Array.isArray(value)
      ? value.map(item => <S.Item key={item}>{item}</S.Item>)
      : value && <S.Item>{value}</S.Item>}
  </CommonViewerStyles.Container>
);
