// @flow
import React from "react";
import { ReadableChecklistItem } from "./ReadableChecklistItem";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;

const Icon = styled.div`
  width: 24px;
  font-size: 24px;
  text-align: center;
  opacity: 0.3;
  flex-shrink: 0;
`;

const IconComponent: any = () => <Icon>•</Icon>;

export const PrintableChecklistItem = (
  props: React$ElementConfig<typeof ReadableChecklistItem>
) => {
  return (
    <ReadableChecklistItem
      {...props}
      overrides={{
        Container: {
          component: Container
        },
        Icon: {
          component: IconComponent
        },
        ...(props.overrides || {})
      }}
    />
  );
};
