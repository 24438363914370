import React, {useMemo, useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import Select, {components as SelectComponents} from 'react-select';

import {TableCell} from './TableCell';
import {DateInput} from '../form-elements/legacy/DateInput';
import {MedicationInput, PointAutosuggestFixedContainer} from './MedicationInput';
import {usePortal} from '../../utils/usePortal';
import * as S from '../my-patients/MyPatientsTableInputs/MyPatientsTableInputsSelect.styled';
import {colors} from '../styleguide/colors';
import {useClickOutside} from '../../utils/useClickOutside';
import {scrollbars} from '../chats/style-elements';

const StyledTableCell = styled(TableCell)`
  height: auto;
  min-height: 48px;
  
  &:focus {
    outline: none;
  }
  
  .active {
    background: #ebebeb;
  }
`;

const CellInput = styled.textarea`
  position: fixed;
  box-sizing: border-box;
  top: -2px;
  left: -2px;
  text-align: inherit;
  padding: 4px 12px;
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 16px;
  max-height: 480px;
  z-index: 2;
  border: none;
  vertical-align: middle;

  ${scrollbars};
`;

const SelectWrapper = styled.div`
  min-height: inherit;
  height: inherit;
`;

const SelectClearIconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  
  color: hsl(0, 0%, 40%);
  cursor: pointer;
  
  &:hover {
    color: hsl(0, 0%, 20%);
  }
`;

const TeethDiagnosisSelectWrapper = styled(SelectWrapper)`
  display: flex;
  justify-content: center;
`;

const DateInputContainer = styled.div`
  width: auto;
  
  input {
    border: none;
    padding: 0;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
  }
`;

const EmptySpace = styled.div`
  min-height: inherit;
`;

const MenuPortal = (props) => {
  return (
    <SelectComponents.MenuPortal {...props} className="menu-portal"/>
  )
};

const MenuList = (props) => {
  return (
    <SelectComponents.MenuList {...props} className="menu-list"/>
  )
};

const SelectOption = styled(S.SelectOption)`
  white-space: nowrap;
`;

const TeethSingleValueContainer = styled.div`
  white-space: nowrap;
`;

const Option = props => {
  return (
    <SelectComponents.Option {...props}>
      <SelectOption>{props.data.label}</SelectOption>
    </SelectComponents.Option>
  );
};

const TeethDiagnosisOption = props => {
  return (
    <SelectComponents.Option {...props}>
      <SelectOption title={props.data.description}>{props.data.label}</SelectOption>
    </SelectComponents.Option>
  );
};

const TeethDiagnosisSingleValue = props => {
  return (
    <SelectComponents.SingleValue {...props}>
      <TeethSingleValueContainer title={props.data.description}>{props.data.label}</TeethSingleValueContainer>
    </SelectComponents.SingleValue>
  );
};

export const CellType = {
  NUMBER: 'number',
  TEXT: 'text',
  DATE: 'date',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  MEDICATION: 'medication',
  TEETH_DIAGNOSES: 'teeth_diagnoses',
};

const isCustomType = (type) => {
  return (
    type === CellType.DATE &&
    type === CellType.SELECT &&
    type === CellType.MULTISELECT &&
    type === CellType.MEDICATION &&
    type === CellType.TEETH_DIAGNOSES
  );
};

const selectStyles = {
  ...S.styles,
  menu: provided => ({
    ...provided,
    minWidth: "60px",
    width: "auto",
    borderRadius: 0,
    boxShadow: "0px 2px 17px rgb(91 91 91 / 26%)",
    color: colors.black,
    marginTop: "1px",
    fontSize: 15,
    maxHeight: "220px",
    overflow: "auto",
    transform: "translate(-12px, 4px)"
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 2
  }),
  valueContainer: (provided, { selectProps: { menuIsOpen } }) => ({
    ...provided,
    padding: 0,
    flexWrap: "wrap",
    overflow: menuIsOpen ? "hidden" : "initial"
  }),
  singleValue: provided => ({
    ...provided,
    position: "unset",
    top: "unset",
    transform: "unset",
    maxWidth: "100%",
    background: colors.concrete,
    padding: "6.5px 8px",
    borderRadius: "4px",
    color: colors.black,
    fontSize: 14,
    whiteSpace: "nowrap"
  }),
  multiValue: provided => ({
    ...provided,
    position: "unset",
    top: "unset",
    transform: "unset",
    maxWidth: "100%",
    background: colors.concrete,
    padding: "6.5px 8px",
    borderRadius: "4px",
    color: colors.black,
    fontSize: 14,
    whiteSpace: "nowrap"
  }),
  control: (styles) => ({
    ...styles,
    boxShadow: "none",
    borderColor: "#ddd",
    cursor: "pointer",
    border: "none",
    borderRadius: 0,
    height: "100%",
    background: "transparent",
    backgroundColor: "transparent",
    color: colors.black,
    padding: 0,
    fontSize: 14,
    minHeight: "inherit",

    ":hover": {
      borderColor: "#ddd",
      boxShadow: "0 0 0 100px rgba(0,0,0,0.01) inset"
    }
  }),
};

const TEETH_DIAGNOSES = {
  absent: {
    short: "О",
    long: "Отсутствует"
  },
  healthy: {
    short: "З",
    long: "Здоров"
  },
  unerupted: {
    short: "НП",
    long: "Ретенция (непрорезавшийся)"
  },
  root: {
    short: "R",
    long: "Корень"
  },
  periodontics1: {
    short: "AI",
    long: "Пародонтит I степень"
  },
  periodontics2: {
    short: "AII",
    long: "Пародонтит II степень"
  },
  periodontics3: {
    short: "AIII",
    long: "Пародонтит III степень"
  },
  caries: {
    short: "C",
    long: "Кариес"
  },
  pulpitis: {
    short: "P",
    long: "Пульпит"
  },
  periodontics: {
    short: 'Pt',
    long: 'Периодонтит',
  },
  crown: {
    short: 'К',
    long: 'Коронка',
  },
  metal_crown: { // Значение для сохранения совместимости с предыдущими приемами
    short: "КМ",
    long: "Коронка металлическая",
    deprecated: true
  },
  seal: {
    short: "П",
    long: "Пломба"
  },
  seal_caries: {
    short: "ПС",
    long: "Пломба-кариес"
  },
  denture: {
    short: 'ПР',
    long: 'Протез',
  },
  bridge_prosthesis: { // Значение для сохранения совместимости с предыдущими приемами
    short: "М",
    long: "Мостовидный протез",
    deprecated: true
  },
  removable_denture: { // Значение для сохранения совместимости с предыдущими приемами
    short: "ПР",
    long: "Съемный протез",
    deprecated: true
  },
  to_be_removed: {
    short: "Э",
    long: "Подлежащий удалению"
  },
  implant: {
    short: 'И',
    long: 'Искусственный зуб (имплантат)',
  },
  gangrene: { // Значение для сохранения совместимости с предыдущими приемами
    short: "Г",
    long: "Гангрена",
    deprecated: true
  },
  granuloma: { // Значение для сохранения совместимости с предыдущими приемами
    short: "ГР",
    long: "Гранулема",
    deprecated: true
  },
};

export const TableEditableCell = (props) => {
  const { type, field, initialValue, style, onChange, disabled, tableBorders, scrollTableContainer, hasBottomTitle } = props;
  const cellRef = useRef(null);
  const inputRef = useRef(null);
  const cellPositionRef = useRef({x: 0, y: 0, width: 0, height: 0});

  const options = useMemo(() => {
    return field ? field.map(option => ({
      value: option.key,
      label: option.label
    })) : [];
  }, [field]);

  const teethDiagnosesOptions = useMemo(() => {
    return Object.entries(TEETH_DIAGNOSES).map(([diagnosis, diagnosisDescription]) => ({
      value: diagnosis,
      label: diagnosisDescription.short,
      description: diagnosisDescription.long,
      deprecated: diagnosisDescription.deprecated
    }));
  }, []);

  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [selectValue, setSelectValue] = useState(initialValue instanceof Array ? initialValue.map(val => {
    const option = options.find(opt => opt.value === val);
    if (option) {
      return option;
    }
    return null;
  }).filter(Boolean) : []);
  const [teethDiagnosesValue, setTeethDiagnosesValue] = useState(initialValue instanceof Array ? initialValue.map(val => {
    const option = teethDiagnosesOptions.find(opt => opt.value === val);
    if (option) {
      return option;
    }
    return null;
  }).filter(Boolean) : []);
  const element = usePortal("table-elements-root");

  const updateTextareaSize = () => {
    if (!isCustomType(type) && inputRef.current) {
      const textarea = inputRef.current;
      textarea.style.height = '';
      textarea.style.height = textarea.scrollHeight + 'px';
      textarea.scrollTop = textarea.scrollHeight;
      textarea.selectionStart = textarea.value.length;
      textarea.selectionEnd = textarea.value.length;
    }
  };

  const updateTextareaPosition = () => {
    if (!isCustomType(type) && cellRef.current && inputRef.current) {
      const boundingBox = cellRef.current.getBoundingClientRect();
      inputRef.current.style.top = `${boundingBox.top}px`;
      inputRef.current.style.left = `${boundingBox.left}px`;
    }
  };

  const updateSelectPosition = () => {
    if ((type === CellType.SELECT || type === CellType.MULTISELECT || type === CellType.TEETH_DIAGNOSES) && cellRef.current) {
      const cell = cellRef.current;
      const menuPortal = cell.querySelector('.menu-portal');
      if (!menuPortal) {
        return;
      }
      const cellBoundingBox = cell.getBoundingClientRect();
      menuPortal.style.top = `${cellBoundingBox.top + cellBoundingBox.height - 4}px`; // -4px поправка к координатам которые задает сам селект, которые компенсируются через translate
      menuPortal.style.left = `${cellBoundingBox.left + 12}px`; // +12px поправка к координатам которые задает сам селект, которые компенсируются через translate
    }
  };

  const updateMedicationPosition = () => {
    if (type === CellType.MEDICATION && cellRef.current) {
      const cell = cellRef.current;
      const medicationContainer = cell.querySelector(PointAutosuggestFixedContainer);
      if (medicationContainer) {
        const cellBoundingBox = cell.getBoundingClientRect();
        medicationContainer.style.top = `${cellBoundingBox.top + cellBoundingBox.height}px`;
        medicationContainer.style.left = `${cellBoundingBox.left}px`;
      }
    }
  };

  const focusSelectWhenEditable = () => {
    if ((type === CellType.SELECT || type === CellType.MULTISELECT || type === CellType.TEETH_DIAGNOSES) && cellRef.current) {
      const cell = cellRef.current;
      const select = cell.querySelector('input');
      if (select) {
        select.focus();
      }
    }
  };

  const handleCellClick = (e) => {
    if (disabled) {
      e.stopPropagation();
      return;
    }
    if (window.__tableCellInChangeProcess) {
      window.__tableCellInChangeProcess = false;
    }
    setTimeout(() => {
      if (cellRef.current) {
        let boundaries = cellRef.current.getBoundingClientRect();
        if (tableBorders && tableBorders.x > boundaries.x) {
          scrollTableContainer(boundaries.x - tableBorders.x);
          boundaries = cellRef.current.getBoundingClientRect(); // Обновляем их потому что ячейка сместилось из-за прокрутки таблицы
        }
        if (tableBorders && (boundaries.x > tableBorders.x && (boundaries.x + boundaries.width) > (tableBorders.x + tableBorders.width))) {
          scrollTableContainer((boundaries.x + boundaries.width) - (tableBorders.x + tableBorders.width));
          boundaries = cellRef.current.getBoundingClientRect(); // Обновляем их потому что ячейка сместилось из-за прокрутки таблицы
        }
        cellPositionRef.current.x = boundaries.x;
        cellPositionRef.current.y = boundaries.y;
        cellPositionRef.current.width = boundaries.width;
        cellPositionRef.current.height = boundaries.height;
      }
      setIsEdit(true);
      updateTextareaSize();
      updateSelectPosition();
      updateMedicationPosition();
      focusSelectWhenEditable();
    }, 0);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange?.(newValue);
  };

  const handleTabOrEnterPress = (e) => {
    if (window.__tableCellInChangeProcess && !e.repeat) {
      window.__tableCellInChangeProcess = false;
    }
    if (window.__tableCellInChangeProcess) {
      e.preventDefault();
      return;
    }
    if ((e.key === 'Enter' || e.key === 'Tab') && cellRef.current) {
      e.preventDefault();
      window.__tableCellInChangeProcess = true;
      if (e.repeat) {
        e.stopPropagation();
        return;
      }
      const cell = cellRef.current;
      const table = cell.closest('table');
      const tabElements = [...table.querySelectorAll(`td[tabindex]`)].filter(element => element.tabIndex !== -1);
      const cellIndexInTabElement = tabElements.indexOf(cell);
      const nextIndex = e.shiftKey ? -1 : 1;
      if (tabElements[cellIndexInTabElement + nextIndex]) {
        tabElements[cellIndexInTabElement + nextIndex].focus();
        setIsEdit(false);
      }
      window.__tableCellInChangeProcess = false;
    }
  };

  const handleTextareaKeyDown = (e) => {
    if (e.altKey) {
      e.preventDefault();
      return;
    }
    // if (e.key === 'Enter' && e.shiftKey) {
    //   e.preventDefault();
    //   return;
    // }
    if (e.key === 'Enter' && e.ctrlKey) {
      const newValue = e.target.value;
      setValue(newValue + '\n');
      onChange?.(newValue + '\n');
      return;
    }
    handleTabOrEnterPress(e);
  };

  const handleNumberTextareaKeyDown = (e) => {
    if (!/[0-9.,]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Enter' && e.key !== 'Tab') {
      e.preventDefault();
      return;
    }
    handleTextareaKeyDown(e);
  };

  const handleSelectKeyDown = (e) => {
    const activeClassName = 'active';
    const directions = {
      previous: -1,
      next: 1
    };

    const goToOption = (direction) => {
      e.preventDefault();
      if (cellRef.current) {
        const cell = cellRef.current;
        const menuList = cell.querySelector('.menu-list');
        const options = [...menuList.querySelectorAll('div[tabindex]')];
        const focusedOption = options.find(option => option.classList.contains(activeClassName));
        if (!focusedOption && !options.length) {
          return;
        }
        if (!focusedOption || !options.includes(focusedOption)) {
          const index = direction === directions.next ? 0 : options.length - 1;
          options[index].classList.add(activeClassName);
          menuList.scrollTop = direction === directions.next ? 0 : menuList.scrollHeight;
          return;
        }
        if (focusedOption && options.includes(focusedOption)) {
          const optionIndex = options.indexOf(focusedOption);
          let nextOption = options[optionIndex + direction];
          if (!nextOption) {
            const index = direction === directions.next ? 0 : options.length - 1;
            nextOption = options[index];
            menuList.scrollTop = direction === directions.next ? 0 : menuList.scrollHeight;
          }
          focusedOption.classList.remove(activeClassName);
          nextOption.classList.add(activeClassName);
          if (
            (direction === directions.next && (nextOption.offsetTop + nextOption.clientHeight > menuList.clientHeight)) ||
            (direction === directions.previous && (nextOption.offsetTop - nextOption.clientHeight < menuList.clientHeight))
          ) {
            menuList.scrollTop = nextOption.offsetTop;
          }
        }
      }
    };

    if (e.key === 'ArrowDown') {
      return goToOption(directions.next);
    }
    if (e.key === 'ArrowUp') {
      return goToOption(directions.previous);
    }
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (cellRef.current) {
        const cell = cellRef.current;
        const focusedOption = cell.querySelector('div[tabindex].active');
        if (focusedOption) {
          focusedOption.click();
        }
      }
      return;
    }
    handleTabOrEnterPress(e);
  };

  const handleDateChange = (value) => {
    setValue(value);
    onChange?.(value);
  };

  const handleSelectChange = (newValue) => {
    if (newValue === null) {
      setSelectValue([]);
      return;
    }
    if (newValue instanceof Array) {
      setSelectValue(newValue);
    } else {
      setSelectValue([newValue]);
    }
  };

  const handleSelectClear = () => {
    setSelectValue([]);
  };

  const handleTeethDiagnosesChange = (newValue) => {
    if (newValue === null) {
      setTeethDiagnosesValue([]);
      return;
    }
    if (newValue instanceof Array) {
      setTeethDiagnosesValue(newValue);
    } else {
      setTeethDiagnosesValue([newValue]);
    }
  };

  const handleTeethDiagnosesClear = () => {
    setTeethDiagnosesValue([]);
  };

  const handleMedicationChange = (value) => {
    setValue(value);
    onChange?.(value);
    setIsEdit(false);
  };

  useClickOutside(cellRef, () => {
    setIsEdit(false);
  });

  useEffect(() => {
    const newValue = selectValue.map(val => val.value);
    if (type === CellType.SELECT || type === CellType.MULTISELECT) {
      onChange?.(newValue);
      updateSelectPosition();
    }
  }, [selectValue]);

  useEffect(() => {
    const newValue = teethDiagnosesValue.map(val => val.value);
    if (type === CellType.TEETH_DIAGNOSES) {
      onChange?.(newValue);
      updateSelectPosition();
    }
  }, [teethDiagnosesValue]);

  useEffect(() => {
    const handleChangeSizeOrScroll = () => {
      updateTextareaPosition();
      updateSelectPosition();
      updateMedicationPosition();
    };

    window.addEventListener('resize', handleChangeSizeOrScroll);
    window.addEventListener('scroll', handleChangeSizeOrScroll);

    return () => {
      window.removeEventListener('resize', handleChangeSizeOrScroll);
      window.removeEventListener('scroll', handleChangeSizeOrScroll);
    };
  }, []);

  React.useEffect(() => {
    updateTextareaSize();
  }, [value]);

  const getEditCellComponentByType = () => {
    switch(type) {
      case CellType.NUMBER:
        return (
          <CellInput
            ref={inputRef}
            value={value}
            style={{
              width: `${cellPositionRef.current.width}px`,
              minHeight: `${cellPositionRef.current.height}px`,
              top: `${cellPositionRef.current.y}px`,
              left: `${cellPositionRef.current.x}px`,
            }}
            onKeyDown={handleNumberTextareaKeyDown}
            onChange={handleChange}
            onBlur={() => {
              setIsEdit(false);
            }}
            autoFocus={true}
          />
        );
      case CellType.TEXT:
        return (
          <CellInput
            ref={inputRef}
            value={value}
            style={{
              width: `${cellPositionRef.current.width}px`,
              minHeight: `${cellPositionRef.current.height}px`,
              top: `${cellPositionRef.current.y}px`,
              left: `${cellPositionRef.current.x}px`,
            }}
            onKeyDown={handleTextareaKeyDown}
            onChange={handleChange}
            onBlur={() => {
              setIsEdit(false);
            }}
            autoFocus={true}
          />
        );
      case CellType.DATE:
        return (
          <DateInput
            noCalendar
            value={value || ""}
            onKeyDown={handleTabOrEnterPress}
            handleChange={handleDateChange}
            placeholder="ДД.ММ.ГГГГ"
            onBlur={() => {
              if (!/^\d{2}[./-]\d{2}[./-]\d{4}$/.test(value)) {
                setValue("");
                onChange?.("");
              }
              setIsEdit(false);
            }}
            autoFocus={true}
            overrides={{
              Container: {
                component: DateInputContainer
              }
            }}
          />
        );
      case CellType.SELECT:
        return (
          <SelectWrapper>
            <Select
              autoFocus
              openMenuOnFocus
              isClearable
              isSearchable
              blurInputOnSelect
              value={selectValue}
              placeholder=""
              styles={selectStyles}
              options={options}
              isMulti={false}
              tabSelectsValue={false}
              menuPosition="fixed"
              onChange={handleSelectChange}
              onKeyDown={handleSelectKeyDown}
              menuPortalTarget={cellRef.current}
              menuShouldScrollIntoView={false}
              noOptionsMessage={() => "Значения не найдены"}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,
                MenuPortal,
                MenuList,
                Option
              }}
            />
            {
              !!selectValue.length && (
                <SelectClearIconWrapper>
                  <SelectComponents.CrossIcon onClick={handleSelectClear}/>
                </SelectClearIconWrapper>
              )
            }
          </SelectWrapper>
        );
      case CellType.MULTISELECT:
        return (
          <SelectWrapper>
            <Select
              autoFocus
              openMenuOnFocus
              isClearable
              isSearchable
              blurInputOnSelect
              value={selectValue}
              placeholder=""
              styles={selectStyles}
              options={options}
              isMulti={true}
              tabSelectsValue={false}
              menuPosition="fixed"
              onChange={handleSelectChange}
              onKeyDown={handleSelectKeyDown}
              menuPortalTarget={cellRef.current}
              menuShouldScrollIntoView={false}
              noOptionsMessage={() => "Значения не найдены"}
              components={{
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,
                MenuPortal,
                MenuList,
                Option
              }}
            />
          </SelectWrapper>
        );
      case CellType.MEDICATION:
        return (
          <MedicationInput
            initialValue={value}
            cellPosition={cellPositionRef.current}
            onKeyDown={handleTabOrEnterPress}
            onChange={handleMedicationChange}
          />
        );
      case CellType.TEETH_DIAGNOSES:
        return (
          <TeethDiagnosisSelectWrapper>
            <Select
              autoFocus
              openMenuOnFocus
              isClearable
              isSearchable
              blurInputOnSelect
              value={teethDiagnosesValue}
              placeholder=""
              styles={selectStyles}
              options={teethDiagnosesOptions}
              isMulti={false}
              tabSelectsValue={false}
              menuPosition="fixed"
              onChange={handleTeethDiagnosesChange}
              onKeyDown={handleSelectKeyDown}
              menuPortalTarget={cellRef.current}
              menuShouldScrollIntoView={false}
              noOptionsMessage={() => "Значения не найдены"}
              filterOption={(option) => {
                return !option.data.deprecated;
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,
                MenuPortal,
                MenuList,
                Option: TeethDiagnosisOption
              }}
            />
            {
              !!teethDiagnosesValue.length && (
                <SelectClearIconWrapper>
                  <SelectComponents.CrossIcon onClick={handleTeethDiagnosesClear}/>
                </SelectClearIconWrapper>
              )
            }
          </TeethDiagnosisSelectWrapper>
        );
      default:
        return (
          <CellInput
            ref={inputRef}
            type="text"
            value={value}
            style={{
              width: `${cellPositionRef.current.width}px`,
              minHeight: `${cellPositionRef.current.height}px`,
              top: `${cellPositionRef.current.y}px`,
              left: `${cellPositionRef.current.x}px`,
            }}
            onKeyDown={handleTextareaKeyDown}
            onChange={handleChange}
            onBlur={() => {
              setIsEdit(false);
            }}
            autoFocus={true}
          />
        );
    }
  };

  const getDefaultStringValue = (value) =>  {
    return typeof value === 'string' ? value : '';
  };

  const getCellValueByType = () => {
    switch(type) {
      case CellType.NUMBER:
        return getDefaultStringValue(value);
      case CellType.TEXT:
        return getDefaultStringValue(value);
      case CellType.DATE:
        return getDefaultStringValue(value);
      case CellType.SELECT:
        return (
          <SelectWrapper>
            <Select
              value={selectValue}
              placeholder=""
              isDisabled
              styles={selectStyles}
              options={options}
              isMulti={false}
              menuPosition="fixed"
              menuPortalTarget={element}
              menuShouldScrollIntoView={false}
              noOptionsMessage={() => "Выбраны все значения"}
              components={{
                Input: () => null,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,
                MultiValueRemove: () => null,
                Option
              }}
            />
          </SelectWrapper>
        );
      case CellType.MULTISELECT:
        return (
          <SelectWrapper>
            <Select
              value={selectValue}
              placeholder=""
              isDisabled
              styles={selectStyles}
              options={options}
              isMulti={true}
              menuPosition="fixed"
              noOptionsMessage={() => "Выбраны все значения"}
              components={{
                Input: () => null,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,
                MultiValueRemove: () => null,
                Option
              }}
            />
          </SelectWrapper>
        );
      case CellType.MEDICATION:
        return value?.name || '';
      case CellType.TEETH_DIAGNOSES:
        return (
          <TeethDiagnosisSelectWrapper title={teethDiagnosesValue[0]?.description}>
            <Select
              value={teethDiagnosesValue}
              placeholder=""
              isDisabled
              styles={selectStyles}
              options={teethDiagnosesOptions}
              isMulti={false}
              menuPosition="fixed"
              menuPortalTarget={element}
              menuShouldScrollIntoView={false}
              noOptionsMessage={() => "Выбраны все значения"}
              components={{
                Input: () => null,
                IndicatorSeparator: () => null,
                IndicatorsContainer: () => null,
                MultiValueRemove: () => null,
                Option: TeethDiagnosisOption,
                SingleValue: TeethDiagnosisSingleValue
              }}
            />
          </TeethDiagnosisSelectWrapper>
        );
      default:
        return getDefaultStringValue(value);
    }
  };

  const cellContent = isEdit ? (
    getEditCellComponentByType()
  ) : (
    getCellValueByType()
  );

  return (
    <StyledTableCell
      tabIndex={0}
      ref={cellRef}
      style={style}
      onFocus={handleCellClick}
      onClick={handleCellClick}
      title={typeof cellContent === 'string' ? cellContent : ''}
      hasBottomTitle={hasBottomTitle}
    >
      {cellContent || <EmptySpace/> }
    </StyledTableCell>
  )
};
