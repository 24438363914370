// @flow
import { colors, tableSelectColors } from "../styleguide/colors";

export const VIEW_TYPES = Object.freeze({
  ACTUAL: "actual",
  CULTIVATION_AND_FERTILIZATION: "cultivation_and_fertilization",
  OOCYTES_CULTIVATION_AND_FERTILIZATION: "oocytes_cultivation_and_fertilization",
  CRYOPRESERVATIONS: "cryopreservations",
  OOCYTES_CRYOPRESERVATIONS: "oocytes_cryopreservations",
  CRYOSTORAGE: "cryostorage",
  BIOPSIES: "biopsies",
  DEFROSTS: "defrosts",
  OOCYTES_DEFROSTS: "oocytes_defrosts",
  ALL: "all"
});

export const VIEW_TYPES_FILTERS = Object.freeze({
  ...Object.values(VIEW_TYPES).reduce((acc, value) => ({ ...acc, [value]: value })),
  [VIEW_TYPES.CRYOSTORAGE]: VIEW_TYPES.CRYOPRESERVATIONS
});

export const filters = [
  { name: "Культивирование", viewType: VIEW_TYPES.CULTIVATION_AND_FERTILIZATION },
  { name: "Биопсия", viewType: VIEW_TYPES.BIOPSIES },
  {
    name: "Крио",
    viewType: VIEW_TYPES.CRYOPRESERVATIONS
  },
  { name: "Размораживание", viewType: VIEW_TYPES.DEFROSTS }
  // { name: "Актуальное", viewType: VIEW_TYPES.ACTUAL },
  // { name: "Все эмбрионы", viewType: VIEW_TYPES.ALL }
];

export const oocytesFilters = [
  { name: "Культивирование", viewType: VIEW_TYPES.OOCYTES_CULTIVATION_AND_FERTILIZATION },
  {
    name: "Крио",
    viewType: VIEW_TYPES.OOCYTES_CRYOPRESERVATIONS
  },
  { name: "Размораживание", viewType: VIEW_TYPES.OOCYTES_DEFROSTS }
  // { name: "Актуальное", viewType: VIEW_TYPES.ACTUAL },
  // { name: "Все эмбрионы", viewType: VIEW_TYPES.ALL }
];

export const cryoFilters = [
  { name: "Криохранилище", viewType: VIEW_TYPES.CRYOSTORAGE },
  { name: "Размораживание", viewType: VIEW_TYPES.DEFROSTS }
  // { name: "Актуальное", viewType: VIEW_TYPES.ACTUAL },
  // { name: "Все эмбрионы", viewType: VIEW_TYPES.ALL }
];

export const METHOD = Object.freeze({
  eko: "eko",
  iksi: "iksi",
  piksi: "piksi",
  reiksi: "reiksi"
});

export const BIOPSY_TYPE = Object.freeze({
  LASER: "Laser",
  FLICK: "Flick",
  COMBINED: "Combined"
});

export const DIFFICULTIES = Object.freeze({
  YES: "yes",
  NO: "no"
});

export const OOCYTES_ASSESSMENT = Object.freeze({
  OKK: "OKK",
  MII: "MII",
  MI: "MI",
  GV: "GV",
  DEG: "DEG",
  GIANT: "Giant",
  EZP: "EZP",
  DIV: "Дробление"
});

export const OOCYTES_TYPE = Object.freeze({
  fresh: "fresh",
  defrosted: "defrosted"
});

export const FROZEN_OOCYTE = Object.freeze({
  YES: true,
  NO: false
});

export const HELP_HATCH = Object.freeze({
  YES: "yes",
  NO: "no"
});

export const CRIOPRESERVATION_ENV = Object.freeze({
  KITAZATO: "Kitazato",
  CRYOTECH: "Cryotech",
  RAPID_VIT_OMNI: "Rapid Vit Omni",
  VITA_VITRO: "Vita Vitro",
  OTHER: "Другое",
  IRVINE_SCIENTIFIC_VIT: "Irvine Scientific Vit",
  BVIT: "BVIT",
  VITRI_FREEZE_FERTIPRO: "VitriFreeze Fertipro",
  SYDNEY_IVF_BL_VITR: "Sydney IVF Bl. Vitr",
  VITROLIFE_KIT_CLEAVE: "Vitrolife Kit Cleave",
  VITRI_STORE_GYNEMED: "Vitri Store Gynemed",
  MEDICULT_ORIGIO: "Medicult Origio"
});

export const FINAL_ASSESSMENT = Object.freeze({
  "BL-1": "BL-1",
  "BL-2": "BL-2",
  "BL-3": "BL-3",
  "BL-4": "BL-4",
  "BL-5": "BL-5",
  "BL-6": "BL-6",
  "BL-3AA": "BL-3AA",
  "BL-3BA": "BL-3BA",
  "BL-3CA": "BL-3CA",
  "BL-3AB": "BL-3AB",
  "BL-3BB": "BL-3BB",
  "BL-3CB": "BL-3CB",
  "BL-3AC": "BL-3AC",
  "BL-3BC": "BL-3BC",
  "BL-3CC": "BL-3CC",
  "BL-4AA": "BL-4AA",
  "BL-4BA": "BL-4BA",
  "BL-4CA": "BL-4CA",
  "BL-4AB": "BL-4AB",
  "BL-4BB": "BL-4BB",
  "BL-4CB": "BL-4CB",
  "BL-4AC": "BL-4AC",
  "BL-4BC": "BL-4BC",
  "BL-4CC": "BL-4CC",
  "BL-5AA": "BL-5AA",
  "BL-5BA": "BL-5BA",
  "BL-5CA": "BL-5CA",
  "BL-5AB": "BL-5AB",
  "BL-5BB": "BL-5BB",
  "BL-5CB": "BL-5CB",
  "BL-5AC": "BL-5AC",
  "BL-5BC": "BL-5BC",
  "BL-5CC": "BL-5CC",
  "BL-6AA": "BL-6AA",
  "BL-6BA": "BL-6BA",
  "BL-6CA": "BL-6CA",
  "BL-6AB": "BL-6AB",
  "BL-6BB": "BL-6BB",
  "BL-6CB": "BL-6CB",
  "BL-6AC": "BL-6AC",
  "BL-6BC": "BL-6BC",
  "BL-6CC": "BL-6CC",
  M1: "M1",
  M2: "M2",
  M3: "M3",
  M4: "M4",
  DEG: "DEG",
  STOP: "STOP",
  OKK: "OKK",
  MI: "MI",
  MII: "MII",
  GV: "GV",
  "1PN": "1PN",
  "2PN": "2PN",
  "3PN": "3PN"
});

export const MORPHOLOGY = Object.freeze({
  OVAL: "Oval",
  GRAN: "Gran",
  VAC: "Vac",
  SER: "SER",
  RB: "RB",
  VISCOUS: "Viscous",
  DARK: "Dark",
  DEBRIS: "Debris",
  FRAGMPB: "Fragm PB",
  SMALLPB: "Small PB",
  THICKZP: "Thick ZP",
  DARKZP: "Dark ZP",
  LPVS: "LPVS",
  BIGPB: "Big PB",
  NOZP: "No ZP"
});

export const INCUBATOR_INSEMINATION = Object.freeze({
  PLANER: "Planer",
  THERMO: "Thermo",
  EMBRYOSCORE: "EmbryoScore",
  ASTEC: "Astec",
  SANYO: "Sanyo"
});

export const INSEMINATION_ENVIRONMENT = Object.freeze({
  "G-IVF": "G-IVF",
  "CSCM-C": "CSCM-C"
});

export const OIL_INSEMINATION = Object.freeze({
  OVOIL: "OVOIL",
  OIL_FOR_TC: "Oil for TC"
});

export const CRYO_CARRIER = Object.freeze({
  CRYOTOP: "Cryotop",
  VITRIFIT: "Vitrifit",
  CRYOTEC: "Cryotec",
  RAPID_I: "Rapid-I",
  CRYOLOCK: "Cryolock",
  MINIVIRO: "Minviro",
  OTHER: "Другое",
  HIGH_SECURITY_STRAW: "Hight Security Straw",
  CRYOPETTE: "Cryopette",
  VITA_VITRO: "Vita Vitro",
  MCGILL_CRYOLEAF: "Mc Gill Cryo Leaf",
  CRYOTIP: "Cryo Tip",
  PETG_STRAW: "PETG Straw",
  CRYO_LOGIC: "Cryo Logic"
});

export const ZYGOTE_ASSESSMENT = Object.freeze({
  "2PN": "2PN",
  "1PN": "1PN",
  OPN: "0PN",
  DIV: "Дробление",
  MI: "MI",
  GV: "GV",
  "3PN": ">=3PN",
  DEG: "DEG",
  EZP: "EZP"
});

export const INCUBATOR_CULTIVATION = Object.freeze({
  PLANER: "Planer",
  THERMO: "Thermo",
  EMBRYOSCORE: "EmbryoScore",
  ASTEC: "Astec",
  SANYO: "Sanyo"
});

export const CULTIVATION_ENVIRONMENT = Object.freeze({
  "1-STEP": "1-Step",
  "CSCM-C": "CSCM-C",
  "G-TL": "G-TL"
});

export const CRUSHING_OPTIONS = Object.freeze({
  "2a": "2a",
  "3a": "3a",
  "4a": "4a",
  "5a": "5a",
  "6a": "6a",
  "7a": "7a",
  "8a": "8a",
  "9a": "9a",
  "10a": "10a",
  "11a": "11a",
  "12a": "12a",
  "2b": "2b",
  "3b": "3b",
  "4b": "4b",
  "5b": "5b",
  "6b": "6b",
  "7b": "7b",
  "8b": "8b",
  "9b": "9b",
  "10b": "10b",
  "11b": "11b",
  "12b": "12b",
  "2с": "2с",
  "3с": "3с",
  "4с": "4с",
  "5с": "5с",
  "6с": "6с",
  "7с": "7с",
  "8с": "8с",
  "9с": "9с",
  "10с": "10с",
  "11с": "11с",
  "12c": "12c",
  M1: "M1",
  M2: "M2",
  M3: "M3",
  M4: "M4",
  "1": "1",
  "2": "2",
  "3AA": "3AA",
  "3AB": "3AB",
  "3AC": "3AC",
  "3BA": "3BA",
  "3BB": "3BB",
  "3BC": "3BC",
  "3CA": "3CA",
  "3CB": "3CB",
  "3CC": "3CC",
  "2PN": "2PN",
  "1PN": "1PN",
  ">=3PN": ">=3PN",
  STOP: "STOP",
  DEG: "DEG"
});

export const OIL_CULTIVATION = Object.freeze({
  OVOIL: "OVOIL",
  OIL_FOR_TC: "Oil for TC"
});

export const DAY = Object.freeze({
  SECOND: "2 сутки",
  THIRD: "3 сутки",
  FOURTH: "4 сутки"
});

export const FIFTH_TO_SEVENTH_DAY = Object.freeze({
  "1": "1",
  "2": "2",
  "3AA": "3AA",
  "3AB": "3AB",
  "3AC": "3AC",
  "3BA": "3BA",
  "3BB": "3BB",
  "3BC": "3BC",
  "3CA": "3CA",
  "3CB": "3CB",
  "3CC": "3CC",
  "4AA": "4AA",
  "4AB": "4AB",
  "4AC": "4AC",
  "4BA": "4BA",
  "4BB": "4BB",
  "4BC": "4BC",
  "4CA": "4CA",
  "4CB": "4CB",
  "4CC": "4CC",
  "5AA": "5AA",
  "5AB": "5AB",
  "5AC": "5AC",
  "5BA": "5BA",
  "5BB": "5BB",
  "5BC": "5BC",
  "5CA": "5CA",
  "5CB": "5CB",
  "5CC": "5CC",
  "6AA": "6AA",
  "6AB": "6AB",
  "6AC": "6AC",
  "6BA": "6BA",
  "6BB": "6BB",
  "6BC": "6BC",
  "6CA": "6CA",
  "6CB": "6CB",
  "6CC": "6CC",
  M1: "M1",
  M2: "M2",
  M3: "M3",
  M4: "M4",
  DEG: "DEG",
  STOP: "STOP"
});

export const ASSESSMENT_AFTER_DEFROST = Object.freeze({
  "1": "1",
  "2": "2",
  "3AA": "3AA",
  "3AB": "3AB",
  "3AC": "3AC",
  "3BA": "3BA",
  "3BB": "3BB",
  "3BC": "3BC",
  "3CA": "3CA",
  "3CB": "3CB",
  "3CC": "3CC",
  "4AA": "4AA",
  "4AB": "4AB",
  "4AC": "4AC",
  "4BA": "4BA",
  "4BB": "4BB",
  "4BC": "4BC",
  "4CA": "4CA",
  "4CB": "4CB",
  "4CC": "4CC",
  "5AA": "5AA",
  "5AB": "5AB",
  "5AC": "5AC",
  "5BA": "5BA",
  "5BB": "5BB",
  "5BC": "5BC",
  "5CA": "5CA",
  "5CB": "5CB",
  "5CC": "5CC",
  "6AA": "6AA",
  "6AB": "6AB",
  "6AC": "6AC",
  "6BA": "6BA",
  "6BB": "6BB",
  "6BC": "6BC",
  "6CA": "6CA",
  "6CB": "6CB",
  "6CC": "6CC",
  M1: "M1",
  M2: "M2",
  M3: "M3",
  M4: "M4",
  "2a": "2a",
  "2b": "2b",
  "2c": "2c",
  "3a": "3a",
  "3b": "3b",
  "3c": "3c",
  "4a": "4a",
  "4b": "4b",
  "4c": "4c",
  "5a": "5a",
  "5b": "5b",
  "5c": "5c",
  "6a": "6a",
  "6b": "6b",
  "6c": "6c",
  "7a": "7a",
  "7b": "7b",
  "7c": "7c",
  "8a": "8a",
  "8b": "8b",
  "8c": "8c",
  "9a": "9a",
  "9b": "9b",
  "9c": "9c",
  "10a": "10a",
  "10b": "10b",
  "10c": "10c",
  "11a": "11a",
  "11b": "11b",
  "11c": "11c",
  "12a": "12a",
  "12b": "12b",
  "12c": "12c",
  MII: "MII",
  "2PN": "2PN",
  MI: "MI",
  DEG: "DEG"
});

export const FIFTH_DAY = Object.freeze({
  "BL-1": "BL-1",
  "BL-2": "BL-2",
  "BL-3": "BL-3",
  "BL-3AA": "BL-3AA",
  "BL-3BA": "BL-3BA",
  "BL-3CA": "BL-3CA",
  "BL-3AB": "BL-3AB",
  "BL-3BB": "BL-3BB",
  "BL-3CB": "BL-3CB",
  "BL-3AC": "BL-3AC",
  "BL-3BC": "BL-3BC",
  "BL-3CC": "BL-3CC",
  "BL-4AA": "BL-4AA",
  "BL-4BA": "BL-4BA",
  "BL-4CA": "BL-4CA",
  "BL-4AB": "BL-4AB",
  "BL-4BB": "BL-4BB",
  "BL-4CB": "BL-4CB",
  "BL-4AC": "BL-4AC",
  "BL-4BC": "BL-4BC",
  "BL-4CC": "BL-4CC",
  "BL-5AA": "BL-5AA",
  "BL-5BA": "BL-5BA",
  "BL-5CA": "BL-5CA",
  "BL-5AB": "BL-5AB",
  "BL-5BB": "BL-5BB",
  "BL-5CB": "BL-5CB",
  "BL-5AC": "BL-5AC",
  "BL-5BC": "BL-5BC",
  "BL-5CC": "BL-5CC",
  "BL-6AA": "BL-6AA",
  "BL-6BA": "BL-6BA",
  "BL-6CA": "BL-6CA",
  "BL-6AB": "BL-6AB",
  "BL-6BB": "BL-6BB",
  "BL-6CB": "BL-6CB",
  "BL-6AC": "BL-6AC",
  "BL-6BC": "BL-6BC",
  "BL-6CC": "BL-6CC",
  "BL-4": "BL-4",
  "BL-5": "BL-5",
  "BL-6": "BL-6",
  M1: "M1",
  M2: "M2",
  M3: "M3",
  M4: "M4",
  DEG: "DEG",
  STOP: "STOP"
});

export const SIXTH_DAY = Object.freeze({
  "BL-1": "BL-1",
  "BL-2": "BL-2",
  "BL-3": "BL-3",
  "BL-4": "BL-4",
  "BL-5": "BL-5",
  "BL-6": "BL-6",
  "BL-3AA": "BL-3AA",
  "BL-3BA": "BL-3BA",
  "BL-3CA": "BL-3CA",
  "BL-3AB": "BL-3AB",
  "BL-3BB": "BL-3BB",
  "BL-3CB": "BL-3CB",
  "BL-3AC": "BL-3AC",
  "BL-3BC": "BL-3BC",
  "BL-3CC": "BL-3CC",
  "BL-4AA": "BL-4AA",
  "BL-4BA": "BL-4BA",
  "BL-4CA": "BL-4CA",
  "BL-4AB": "BL-4AB",
  "BL-4BB": "BL-4BB",
  "BL-4CB": "BL-4CB",
  "BL-4AC": "BL-4AC",
  "BL-4BC": "BL-4BC",
  "BL-4CC": "BL-4CC",
  "BL-5AA": "BL-5AA",
  "BL-5BA": "BL-5BA",
  "BL-5CA": "BL-5CA",
  "BL-5AB": "BL-5AB",
  "BL-5BB": "BL-5BB",
  "BL-5CB": "BL-5CB",
  "BL-5AC": "BL-5AC",
  "BL-5BC": "BL-5BC",
  "BL-5CC": "BL-5CC",
  "BL-6AA": "BL-6AA",
  "BL-6BA": "BL-6BA",
  "BL-6CA": "BL-6CA",
  "BL-6AB": "BL-6AB",
  "BL-6BB": "BL-6BB",
  "BL-6CB": "BL-6CB",
  "BL-6AC": "BL-6AC",
  "BL-6BC": "BL-6BC",
  "BL-6CC": "BL-6CC",
  M1: "M1",
  M2: "M2",
  M3: "M3",
  M4: "M4",
  DEG: "DEG",
  STOP: "STOP"
});

export const FRAGMENTATION = Object.freeze({
  LESS50: "less50",
  MORE50: "more50",
  FULL: "full"
});

export const SURVIVALS = Object.freeze({
  "0": "0",
  "10": "10",
  "20": "20",
  "30": "30",
  "40": "40",
  "50": "50",
  "60": "60",
  "70": "70",
  "80": "80",
  "90": "90",
  "100": "100"
});

export const NOTATION = Object.freeze({
  fr: "fr",
  AH: "AH",
  noZP: "no ZP",
  icm: "2 ICM",
  uev: "uev",
  mnb: "mnb",
  nocleav: "no cleav",
  vac: "vac"
});

export const COLORS = Object.freeze({
  WHITE: "white",
  YELLOW: "yellow",
  GREEN: "green",
  RED: "red",
  BLUE: "blue",
  PURPLE: "purple",
  ORANGE: "orange",
  PINK: "pink",
  WITHOUT: "without"
});

export const SEVENTH_DAY = Object.freeze({
  "BL-1": "BL-1",
  "BL-2": "BL-2",
  "BL-3": "BL-3",
  "BL-4": "BL-4",
  "BL-5": "BL-5",
  "BL-6": "BL-6",
  "BL-3AA": "BL-3AA",
  "BL-3BA": "BL-3BA",
  "BL-3CA": "BL-3CA",
  "BL-3AB": "BL-3AB",
  "BL-3BB": "BL-3BB",
  "BL-3CB": "BL-3CB",
  "BL-3AC": "BL-3AC",
  "BL-3BC": "BL-3BC",
  "BL-3CC": "BL-3CC",
  "BL-4AA": "BL-4AA",
  "BL-4BA": "BL-4BA",
  "BL-4CA": "BL-4CA",
  "BL-4AB": "BL-4AB",
  "BL-4BB": "BL-4BB",
  "BL-4CB": "BL-4CB",
  "BL-4AC": "BL-4AC",
  "BL-4BC": "BL-4BC",
  "BL-4CC": "BL-4CC",
  "BL-5AA": "BL-5AA",
  "BL-5BA": "BL-5BA",
  "BL-5CA": "BL-5CA",
  "BL-5AB": "BL-5AB",
  "BL-5BB": "BL-5BB",
  "BL-5CB": "BL-5CB",
  "BL-5AC": "BL-5AC",
  "BL-5BC": "BL-5BC",
  "BL-5CC": "BL-5CC",
  "BL-6AA": "BL-6AA",
  "BL-6BA": "BL-6BA",
  "BL-6CA": "BL-6CA",
  "BL-6AB": "BL-6AB",
  "BL-6BB": "BL-6BB",
  "BL-6CB": "BL-6CB",
  "BL-6AC": "BL-6AC",
  "BL-6BC": "BL-6BC",
  "BL-6CC": "BL-6CC",
  M1: "M1",
  M2: "M2",
  M3: "M3",
  M4: "M4",
  DEG: "DEG",
  STOP: "STOP"
});

export const BIOPSY_STATUS = Object.freeze({
  BIOPSY: "biopsy",
  STORE: "store",
  IN_PROGRESS: "in_progress",
  DONE: "done"
});

export const FREEZE_OOCYTES = Object.freeze({
  YES: "yes",
  NO: null
});

export const BIOPSY_RESULT = Object.freeze({
  NORMA: "norma",
  MOZAIK: "mozaik",
  XA: "xa"
});

export const STATUS = Object.freeze({
  CRIO: "crio",
  UTILIZATION: "utilization",
  DEFROSTED: "defrosted",
  DONATION: "donation",
  CRYOTRANSFER: "cryotransfer",
  TO_DEFROST: "to_defrost",
  TO_TRANSFER: "to_transfer",
  TRANSFERRED: "transferred",
  PASSED_FOR_TRANSFER: "passed_for_transfer",
  INSEMINATION: "insemination",
  EMPTY: "empty"
});

export const PGT_STATUS = Object.freeze({
  BIOPSY: "Биопсия",
  IN_PROGRESS: "В работе",
  STORAGE: "Хранение",
  STANDARD: "Норма",
  MOSAIC: "Мозаик",
  HA: "ХА",
  NOSIGNAL: "Нет сигнала",
  RECOMMENDED: "Рекомендован",
  NOT_RECOMMENDED: "Не рекомендован",
  GENETIC_CONSULTATION: 'Консультация генетика'
});

export const PREV_HATCH = Object.freeze({
  YES: "Да",
  NO: "Нет"
});

export const DEFROST_ENV = Object.freeze({
  KITAZATO: "Kitazato",
  RAPID_WARM_OMNI: "Rapid Warm Omni",
  CRYOTECH: "Cryotech",
  ZREV: "ZReV",
  VITA_VITRO_WARMING: "Vita Vitro Warming",
  IRVIN_SCIENTIFIC_THAW: "Irvine Scientific Thaw",
  VITRI_STORE_GYNEMED: "Vitri Store Gynemed",
  VITRO_LIFE_WARM_KIT_CLEAVE: "Vitro Life Warm Kit Cleave",
  SYDNEY_IVF: "Sydney IVF",
  MEDICULT: "Medicult",
  VITRI_THAW_FETRIPRO: "Vitri Thaw Fertipro",
  OTHER: "Другое"
});

export const ALL_FIELDS_CHECK_VALUE = {
  defrost_env: (value) => Object.keys(DEFROST_ENV).map((key) => DEFROST_ENV[key]).includes(value),
  prev_hatch: (value) => Object.keys(PREV_HATCH).map((key) => PREV_HATCH[key]).includes(value),
  pgt_status: (value) => Object.keys(PGT_STATUS).map((key) => PGT_STATUS[key]).includes(value),
  status: (value) => Object.keys(STATUS).map((key) => STATUS[key]).includes(value),
  current_status: (value) => Object.keys(STATUS).map((key) => STATUS[key]).includes(value),
  biopsy_result: (value) => Object.keys(BIOPSY_RESULT).map((key) => BIOPSY_RESULT[key]).includes(value),
  freeze_oocytes: (value) => Object.keys(FREEZE_OOCYTES).map((key) => FREEZE_OOCYTES[key]).includes(value),
  biopsy_status: (value) => Object.keys(BIOPSY_STATUS).map((key) => BIOPSY_STATUS[key]).includes(value),
  seventh_day: (value) => Object.keys(FIFTH_TO_SEVENTH_DAY).map((key) => FIFTH_TO_SEVENTH_DAY[key]).includes(value),
  color: (value) => Object.keys(COLORS).map((key) => COLORS[key]).includes(value),
  notation: (value) => {
    let isCheck = true;
    const data = Object.keys(NOTATION).map((key) => NOTATION[key]);
    if (!value.includes('[') && !value.includes(']')) {
      isCheck = false;
    } else {
      JSON.parse(value.replaceAll("'", '"')).forEach((item) => {
        if (!data.includes(item)) {
          isCheck = false;
        }
      })
    }
    return isCheck;
  },
  survivals: (value) => Object.keys(SURVIVALS).map((key) => SURVIVALS[key]).includes(value),
  fragmentation: (value) => Object.keys(FRAGMENTATION).map((key) => FRAGMENTATION[key]).includes(value),
  sixth_day: (value) => Object.keys(FIFTH_TO_SEVENTH_DAY).map((key) => FIFTH_TO_SEVENTH_DAY[key]).includes(value),
  fifth_day: (value) => Object.keys(FIFTH_TO_SEVENTH_DAY).map((key) => FIFTH_TO_SEVENTH_DAY[key]).includes(value),
  assessment_after_defrost: (value) => Object.keys(ASSESSMENT_AFTER_DEFROST).map((key) => ASSESSMENT_AFTER_DEFROST[key]).includes(value),
  fifth_to_seventh_day: (value) => Object.keys(FIFTH_TO_SEVENTH_DAY).map((key) => FIFTH_TO_SEVENTH_DAY[key]).includes(value),
  day: (value) => Object.keys(DAY).map((key) => DAY[key]).includes(value),
  oil_cultivation: (value) => Object.keys(OIL_CULTIVATION).map((key) => OIL_CULTIVATION[key]).includes(value),
  crushing: (value) => Object.keys(CRUSHING_OPTIONS).map((key) => CRUSHING_OPTIONS[key]).includes(value),
  cultivation_environment: (value) => Object.keys(CULTIVATION_ENVIRONMENT).map((key) => CULTIVATION_ENVIRONMENT[key]).includes(value),
  incubator_cultivation: (value) => Object.keys(INCUBATOR_CULTIVATION).map((key) => INCUBATOR_CULTIVATION[key]).includes(value),
  zygote_assessment: (value) => Object.keys(ZYGOTE_ASSESSMENT).map((key) => ZYGOTE_ASSESSMENT[key]).includes(value),
  cryo_carrier: (value) => Object.keys(CRYO_CARRIER).map((key) => CRYO_CARRIER[key]).includes(value),
  oil_insemination: (value) => Object.keys(OIL_INSEMINATION).map((key) => OIL_INSEMINATION[key]).includes(value),
  insemination_environment: (value) => Object.keys(INSEMINATION_ENVIRONMENT).map((key) => INSEMINATION_ENVIRONMENT[key]).includes(value),
  incubator_insemination: (value) => Object.keys(INCUBATOR_INSEMINATION).map((key) => INCUBATOR_INSEMINATION[key]).includes(value),
  morphology: (value) => {
    let isCheck = true;
    const data = Object.keys(MORPHOLOGY).map((key) => MORPHOLOGY[key]);
    value.split(',').forEach((item) => {
      if (!data.includes(item)) {
        isCheck = false;
      }
    })
    return isCheck;
  },
  final_assessment: (value) => Object.keys(FINAL_ASSESSMENT).map((key) => FINAL_ASSESSMENT[key]).includes(value),
  crio_env: (value) => Object.keys(CRIOPRESERVATION_ENV).map((key) => CRIOPRESERVATION_ENV[key]).includes(value),
  help_hatch: (value) => Object.keys(HELP_HATCH).map((key) => HELP_HATCH[key]).includes(value),
  frozen_oocyte: (value) => Object.keys(FROZEN_OOCYTE).map((key) => FROZEN_OOCYTE[key]).includes(value),
  oocytes_type: (value) => Object.keys(OOCYTES_TYPE).map((key) => OOCYTES_TYPE[key]).includes(value),
  oocytes_assessment: (value) => Object.keys(OOCYTES_ASSESSMENT).map((key) => OOCYTES_ASSESSMENT[key]).includes(value),
  difficulties: (value) => Object.keys(DIFFICULTIES).map((key) => DIFFICULTIES[key]).includes(value),
  biopsy_type: (value) => Object.keys(BIOPSY_TYPE).map((key) => BIOPSY_TYPE[key]).includes(value),
  method: (value) => Object.keys(METHOD).map((key) => METHOD[key]).includes(value),
}

type SelectOptionsData<T> = Array<{ value: T, label: string, color: string }>;
type Method = $Values<typeof METHOD>;
type MethodOptions = SelectOptionsData<Method>;
type OocytesAssessment = $Values<typeof OOCYTES_ASSESSMENT>;
type OocytesAssessmentOptions = SelectOptionsData<OocytesAssessment>;
type OocytesType = $Values<typeof OOCYTES_TYPE>;
type OocytesTypeOptions = SelectOptionsData<OocytesType>;
type Morphology = $Values<typeof MORPHOLOGY>;
type MorphologyOptions = SelectOptionsData<Morphology>;
type IncubatorInsemination = $Values<typeof INCUBATOR_INSEMINATION>;
type IncubatorInseminationOptions = SelectOptionsData<IncubatorInsemination>;
type InseminationEnvironment = $Values<typeof INSEMINATION_ENVIRONMENT>;
type InseminationEnvironmentOptions = SelectOptionsData<InseminationEnvironment>;
type OilInsemination = $Values<typeof OIL_INSEMINATION>;
type OilInseminationOptions = SelectOptionsData<OilInsemination>;
type OilCultivation = $Values<typeof OIL_CULTIVATION>;
type OilCultivationOptions = SelectOptionsData<OilCultivation>;
type ZygoteAssessment = $Values<typeof ZYGOTE_ASSESSMENT>;
type ZygoteAssessmentOptions = SelectOptionsData<ZygoteAssessment>;
type IncubatorCultivation = $Values<typeof INCUBATOR_CULTIVATION>;
type IncubatorCultivationOptions = SelectOptionsData<IncubatorCultivation>;
type CultivationEnvironment = $Values<typeof CULTIVATION_ENVIRONMENT>;
type CultivationEnvironmentOptions = SelectOptionsData<CultivationEnvironment>;
type Day = $Values<typeof DAY>;
type DayOptions = SelectOptionsData<Day>;
type FifthToSeventhDay = $Values<typeof FIFTH_TO_SEVENTH_DAY>;
type FifthToSeventhDayOptions = SelectOptionsData<FifthToSeventhDay>;
type AssessmentAfterDefrost = $Values<typeof ASSESSMENT_AFTER_DEFROST>;
type AssessmentAfterDefrostOptions = SelectOptionsData<AssessmentAfterDefrost>;
type FifthDay = $Values<typeof FIFTH_DAY>;
type FifthDayOptions = SelectOptionsData<FifthDay>;
type SixthDay = $Values<typeof SIXTH_DAY>;
type SixthDayOptions = SelectOptionsData<SixthDay>;
type Crushing = $Values<typeof CRUSHING_OPTIONS>;
type CrushingOptions = SelectOptionsData<Crushing>;
type SeventhDay = $Values<typeof SEVENTH_DAY>;
type SeventhDayOptions = SelectOptionsData<SeventhDay>;
type FreezeOocytes = $Values<typeof FREEZE_OOCYTES>;
type FreezeOocytesOptions = SelectOptionsData<FreezeOocytes>;
type Status = $Values<typeof STATUS>;
type StatusOptions = SelectOptionsData<Status>;
type BiopsyStatus = $Values<typeof BIOPSY_STATUS>;
type BiopsyStatusOptions = SelectOptionsData<BiopsyStatus>;
type FrozenOocyte = $Values<typeof FROZEN_OOCYTE>;
type FrozenOocyteOptions = SelectOptionsData<FrozenOocyte>;
type HelpHatch = $Values<typeof HELP_HATCH>;
type HelpHatchOptions = SelectOptionsData<HelpHatch>;
type Difficulties = $Values<typeof DIFFICULTIES>;
type DifficultiesOptions = SelectOptionsData<Difficulties>;
type BiopsyType = $Values<typeof BIOPSY_TYPE>;
type BiopsyTypeOptions = SelectOptionsData<BiopsyType>;
type BiopsyResult = $Values<typeof BIOPSY_RESULT>;
type BiopsyResultOptions = SelectOptionsData<BiopsyResult>;
type Survivals = $Values<typeof SURVIVALS>;
type SurvivalsOptions = SelectOptionsData<Survivals>;
type Colors = $Values<typeof COLORS>;
type ColorsOptions = SelectOptionsData<Colors>;
type Fragmentation = $Values<typeof FRAGMENTATION>;
type FragmentationOptions = SelectOptionsData<Fragmentation>;
type Notation = $Values<typeof NOTATION>;
type NotationOptions = SelectOptionsData<Notation>;

export const methodOptions: MethodOptions = [
  { value: METHOD.eko, label: "ЭКО", color: tableSelectColors.gray },
  { value: METHOD.iksi, label: "ИКСИ", color: tableSelectColors.gray },
  { value: METHOD.piksi, label: "ПИКСИ", color: tableSelectColors.gray },
  { value: METHOD.reiksi, label: "РеИКСИ", color: tableSelectColors.gray }
];

export const oocytesAssessmentOptions: OocytesAssessmentOptions = [
  {
    value: OOCYTES_ASSESSMENT["OKK"],
    label: OOCYTES_ASSESSMENT["OKK"],
    color: tableSelectColors.gray
  },
  {
    value: OOCYTES_ASSESSMENT["MII"],
    label: OOCYTES_ASSESSMENT["MII"],
    color: tableSelectColors.green
  },
  {
    value: OOCYTES_ASSESSMENT["MI"],
    label: OOCYTES_ASSESSMENT["MI"],
    color: tableSelectColors.yellow
  },
  {
    value: OOCYTES_ASSESSMENT["GV"],
    label: OOCYTES_ASSESSMENT["GV"],
    color: tableSelectColors.red
  },
  {
    value: OOCYTES_ASSESSMENT["DEG"],
    label: OOCYTES_ASSESSMENT["DEG"],
    color: tableSelectColors.red
  },
  {
    value: OOCYTES_ASSESSMENT["GIANT"],
    label: OOCYTES_ASSESSMENT["GIANT"],
    color: tableSelectColors.red
  },
  {
    value: OOCYTES_ASSESSMENT["EZP"],
    label: OOCYTES_ASSESSMENT["EZP"],
    color: tableSelectColors.red
  },
  {
    value: OOCYTES_ASSESSMENT["DIV"],
    label: OOCYTES_ASSESSMENT["DIV"],
    color: tableSelectColors.red
  }
];

export const oocytesTypeOptions: OocytesTypeOptions = [
  {
    value: OOCYTES_TYPE["fresh"],
    label: "свежие",
    color: tableSelectColors.yellow
  },
  {
    value: OOCYTES_TYPE["defrosted"],
    label: "размороженные",
    color: tableSelectColors.blue
  }
];

export const difficultiesOptions: DifficultiesOptions = [
  { value: DIFFICULTIES.YES, label: "Да", color: tableSelectColors.gray },
  { value: DIFFICULTIES.NO, label: "Нет", color: tableSelectColors.gray }
];

export const biopsyTypeOptions: BiopsyTypeOptions = [
  { value: BIOPSY_TYPE.LASER, label: "Laser", color: tableSelectColors.gray },
  { value: BIOPSY_TYPE.FLICK, label: "Flick", color: tableSelectColors.gray },
  { value: BIOPSY_TYPE.COMBINED, label: "Combined", color: tableSelectColors.gray }
];

export const fragmentationOptions: FragmentationOptions = [
  { value: FRAGMENTATION.LESS50, label: "< 50%", color: tableSelectColors.gray },
  { value: FRAGMENTATION.MORE50, label: "> 50%", color: tableSelectColors.gray },
  { value: FRAGMENTATION.FULL, label: "100%", color: tableSelectColors.gray }
];

export const biopsyResultOptions: BiopsyResultOptions = [
  { value: BIOPSY_RESULT.NORMA, label: "Норма", color: tableSelectColors.green },
  { value: BIOPSY_RESULT.MOZAIK, label: "Мозаик", color: tableSelectColors.yellow },
  { value: BIOPSY_RESULT.XA, label: "XA", color: tableSelectColors.red }
];

export const survivalsOptions: SurvivalsOptions = [
  { value: SURVIVALS["0"], label: "0%", color: tableSelectColors.gray },
  { value: SURVIVALS["10"], label: "10%", color: tableSelectColors.gray },
  { value: SURVIVALS["20"], label: "20%", color: tableSelectColors.gray },
  { value: SURVIVALS["30"], label: "30%", color: tableSelectColors.gray },
  { value: SURVIVALS["40"], label: "40%", color: tableSelectColors.gray },
  { value: SURVIVALS["50"], label: "50%", color: tableSelectColors.gray },
  { value: SURVIVALS["60"], label: "60%", color: tableSelectColors.gray },
  { value: SURVIVALS["70"], label: "70%", color: tableSelectColors.gray },
  { value: SURVIVALS["80"], label: "80%", color: tableSelectColors.gray },
  { value: SURVIVALS["90"], label: "90%", color: tableSelectColors.gray },
  { value: SURVIVALS["100"], label: "100%", color: tableSelectColors.gray }
];

export const notationOptions: NotationOptions = [
  { value: NOTATION["fr"], label: NOTATION["fr"], color: tableSelectColors.gray },
  { value: NOTATION["AH"], label: NOTATION["AH"], color: tableSelectColors.gray },
  { value: NOTATION["noZP"], label: NOTATION["noZP"], color: tableSelectColors.gray },
  { value: NOTATION["icm"], label: NOTATION["icm"], color: tableSelectColors.gray },
  { value: NOTATION["uev"], label: NOTATION["uev"], color: tableSelectColors.gray },
  { value: NOTATION["mnb"], label: NOTATION["mnb"], color: tableSelectColors.gray },
  { value: NOTATION["nocleav"], label: NOTATION["nocleav"], color: tableSelectColors.gray },
  { value: NOTATION["vac"], label: NOTATION["vac"], color: tableSelectColors.gray }
];

export const colorsOptions: ColorsOptions = [
  { value: COLORS.WHITE, label: "Белый", color: tableSelectColors.gray },
  { value: COLORS.WITHOUT, label: "Бесцветный", color: tableSelectColors.gray },
  { value: COLORS.GREEN, label: "Зеленый", color: tableSelectColors.green },
  { value: COLORS.BLUE, label: "Синий", color: tableSelectColors.blue },
  { value: COLORS.PURPLE, label: "Фиолетовый", color: tableSelectColors.blue },
  { value: COLORS.YELLOW, label: "Желтый", color: tableSelectColors.yellow },
  { value: COLORS.ORANGE, label: "Оранжевый", color: tableSelectColors.yellow },
  { value: COLORS.RED, label: "Красный", color: tableSelectColors.red },
  { value: COLORS.PINK, label: "Розовый", color: tableSelectColors.red }
];

export const morphologyOptions: MorphologyOptions = [
  { value: MORPHOLOGY.OVAL, label: MORPHOLOGY.OVAL, color: tableSelectColors.gray },
  { value: MORPHOLOGY.GRAN, label: MORPHOLOGY.GRAN, color: tableSelectColors.gray },
  { value: MORPHOLOGY.VAC, label: MORPHOLOGY.VAC, color: tableSelectColors.gray },
  { value: MORPHOLOGY.SER, label: MORPHOLOGY.SER, color: tableSelectColors.gray },
  { value: MORPHOLOGY.RB, label: MORPHOLOGY.RB, color: tableSelectColors.gray },
  { value: MORPHOLOGY.VISCOUS, label: MORPHOLOGY.VISCOUS, color: tableSelectColors.gray },
  { value: MORPHOLOGY.DARK, label: MORPHOLOGY.DARK, color: tableSelectColors.gray },
  { value: MORPHOLOGY.DEBRIS, label: MORPHOLOGY.DEBRIS, color: tableSelectColors.gray },
  { value: MORPHOLOGY.FRAGMPB, label: MORPHOLOGY.FRAGMPB, color: tableSelectColors.gray },
  { value: MORPHOLOGY.SMALLPB, label: MORPHOLOGY.SMALLPB, color: tableSelectColors.gray },
  { value: MORPHOLOGY.BIGPB, label: MORPHOLOGY.BIGPB, color: tableSelectColors.gray },
  { value: MORPHOLOGY.THICKZP, label: MORPHOLOGY.THICKZP, color: tableSelectColors.gray },
  { value: MORPHOLOGY.DARKZP, label: MORPHOLOGY.DARKZP, color: tableSelectColors.gray },
  { value: MORPHOLOGY.LPVS, label: MORPHOLOGY.LPVS, color: tableSelectColors.gray },
  { value: MORPHOLOGY.NOZP, label: MORPHOLOGY.NOZP, color: tableSelectColors.gray }
];

export const incubatorInseminationOptions: IncubatorInseminationOptions = [
  { value: INCUBATOR_INSEMINATION.PLANER, label: "Planer", color: tableSelectColors.gray },
  { value: INCUBATOR_INSEMINATION.THERMO, label: "Thermo", color: tableSelectColors.gray },
  {
    value: INCUBATOR_INSEMINATION.EMBRYOSCORE,
    label: "EmbryoScore",
    color: tableSelectColors.gray
  },
  { value: INCUBATOR_INSEMINATION.ASTEC, label: "Astec", color: tableSelectColors.gray },
  { value: INCUBATOR_INSEMINATION.SANYO, label: "Sanyo", color: tableSelectColors.gray }
];

export const inseminationEnvironmentOptions: InseminationEnvironmentOptions = [
  { value: INSEMINATION_ENVIRONMENT["G-IVF"], label: "G-IVF", color: tableSelectColors.gray },
  { value: INSEMINATION_ENVIRONMENT["CSCM-C"], label: "CSCM-C", color: tableSelectColors.gray }
];

export const oilInseminationOptions: OilInseminationOptions = [
  { value: OIL_INSEMINATION.OVOIL, label: "OVOIL", color: tableSelectColors.gray },
  { value: OIL_INSEMINATION.OIL_FOR_TC, label: "Oil for TC", color: tableSelectColors.gray }
];

export const crushingOptions: CrushingOptions = [
  { value: "2a", label: "2a", color: tableSelectColors.gray },
  { value: "3a", label: "3a", color: tableSelectColors.gray },
  { value: "4a", label: "4a", color: tableSelectColors.gray },
  { value: "5a", label: "5a", color: tableSelectColors.gray },
  { value: "6a", label: "6a", color: tableSelectColors.gray },
  { value: "7a", label: "7a", color: tableSelectColors.gray },
  { value: "8a", label: "8a", color: tableSelectColors.gray },
  { value: "9a", label: "9a", color: tableSelectColors.gray },
  { value: "10a", label: "10a", color: tableSelectColors.gray },
  { value: "11a", label: "11a", color: tableSelectColors.gray },
  { value: "12a", label: "12a", color: tableSelectColors.gray },
  { value: "2b", label: "2b", color: tableSelectColors.gray },
  { value: "3b", label: "3b", color: tableSelectColors.gray },
  { value: "4b", label: "4b", color: tableSelectColors.gray },
  { value: "5b", label: "5b", color: tableSelectColors.gray },
  { value: "6b", label: "6b", color: tableSelectColors.gray },
  { value: "7b", label: "7b", color: tableSelectColors.gray },
  { value: "8b", label: "8b", color: tableSelectColors.gray },
  { value: "9b", label: "9b", color: tableSelectColors.gray },
  { value: "10b", label: "10b", color: tableSelectColors.gray },
  { value: "11b", label: "11b", color: tableSelectColors.gray },
  { value: "12b", label: "12b", color: tableSelectColors.gray },
  { value: "2с", label: "2с", color: tableSelectColors.gray },
  { value: "3с", label: "3с", color: tableSelectColors.gray },
  { value: "4с", label: "4с", color: tableSelectColors.gray },
  { value: "5с", label: "5с", color: tableSelectColors.gray },
  { value: "6с", label: "6с", color: tableSelectColors.gray },
  { value: "7с", label: "7с", color: tableSelectColors.gray },
  { value: "8с", label: "8с", color: tableSelectColors.gray },
  { value: "9с", label: "9с", color: tableSelectColors.gray },
  { value: "10с", label: "10с", color: tableSelectColors.gray },
  { value: "11с", label: "11с", color: tableSelectColors.gray },
  { value: "12c", label: "12c", color: tableSelectColors.gray },
  { value: "M1", label: "M1", color: tableSelectColors.gray },
  { value: "M2", label: "M2", color: tableSelectColors.gray },
  { value: "M3", label: "M3", color: tableSelectColors.gray },
  { value: "M4", label: "M4", color: tableSelectColors.gray },
  { value: "1", label: "1", color: tableSelectColors.gray },
  { value: "2", label: "2", color: tableSelectColors.gray },
  { value: "3AA", label: "3AA", color: tableSelectColors.gray },
  { value: "3AB", label: "3AB", color: tableSelectColors.gray },
  { value: "3AC", label: "3AC", color: tableSelectColors.gray },
  { value: "3BA", label: "3BA", color: tableSelectColors.gray },
  { value: "3BB", label: "3BB", color: tableSelectColors.gray },
  { value: "3BC", label: "3BC", color: tableSelectColors.gray },
  { value: "3CA", label: "3CA", color: tableSelectColors.gray },
  { value: "3CB", label: "3CB", color: tableSelectColors.gray },
  { value: "3CC", label: "3CC", color: tableSelectColors.gray },
  { value: "2PN", label: "2PN", color: tableSelectColors.gray },
  { value: "1PN", label: "1PN", color: tableSelectColors.gray },
  { value: ">=3PN", label: ">=3PN", color: tableSelectColors.gray },
  { value: CRUSHING_OPTIONS.STOP, label: CRUSHING_OPTIONS.STOP, color: tableSelectColors.red },
  { value: CRUSHING_OPTIONS.DEG, label: CRUSHING_OPTIONS.DEG, color: tableSelectColors.red }
];

export const oilCultivationOptions: OilCultivationOptions = [
  { value: OIL_INSEMINATION.OVOIL, label: "OVOIL", color: tableSelectColors.gray },
  { value: OIL_INSEMINATION.OIL_FOR_TC, label: "Oil for TC", color: tableSelectColors.gray }
];

export const zygoteAssessmentOptions: ZygoteAssessmentOptions = [
  {
    value: ZYGOTE_ASSESSMENT["2PN"],
    label: ZYGOTE_ASSESSMENT["2PN"],
    color: tableSelectColors.green
  },
  {
    value: ZYGOTE_ASSESSMENT["1PN"],
    label: ZYGOTE_ASSESSMENT["1PN"],
    color: tableSelectColors.green
  },
  {
    value: ZYGOTE_ASSESSMENT["OPN"],
    label: ZYGOTE_ASSESSMENT["OPN"],
    color: tableSelectColors.yellow
  },
  {
    value: ZYGOTE_ASSESSMENT["MI"],
    label: ZYGOTE_ASSESSMENT["MI"],
    color: tableSelectColors.red
  },
  {
    value: ZYGOTE_ASSESSMENT["GV"],
    label: ZYGOTE_ASSESSMENT["GV"],
    color: tableSelectColors.red
  },
  {
    value: ZYGOTE_ASSESSMENT["3PN"],
    label: ZYGOTE_ASSESSMENT["3PN"],
    color: tableSelectColors.red
  },
  {
    value: ZYGOTE_ASSESSMENT["DEG"],
    label: ZYGOTE_ASSESSMENT["DEG"],
    color: tableSelectColors.red
  },
  {
    value: ZYGOTE_ASSESSMENT["DIV"],
    label: ZYGOTE_ASSESSMENT["DIV"],
    color: tableSelectColors.gray
  },
  {
    value: ZYGOTE_ASSESSMENT["EZP"],
    label: ZYGOTE_ASSESSMENT["EZP"],
    color: tableSelectColors.red
  }
];

export const incubatorCultivationOptions: IncubatorCultivationOptions = [
  { value: INCUBATOR_CULTIVATION.PLANER, label: "Planer", color: tableSelectColors.gray },
  { value: INCUBATOR_CULTIVATION.THERMO, label: "Thermo", color: tableSelectColors.gray },
  { value: INCUBATOR_CULTIVATION.EMBRYOSCORE, label: "EmbryoScore", color: tableSelectColors.gray },
  { value: INCUBATOR_CULTIVATION.ASTEC, label: "Astec", color: tableSelectColors.gray },
  { value: INCUBATOR_CULTIVATION.SANYO, label: "Sanyo", color: tableSelectColors.gray }
];

export const cultivationEnvironmentOptions: CultivationEnvironmentOptions = [
  { value: CULTIVATION_ENVIRONMENT["1-STEP"], label: "1-Step", color: tableSelectColors.gray },
  { value: CULTIVATION_ENVIRONMENT["CSCM-C"], label: "CSCM-C", color: tableSelectColors.gray },
  { value: CULTIVATION_ENVIRONMENT["G-TL"], label: "G-TL", color: tableSelectColors.gray }
];

export const frozenOocyteOptions: FrozenOocyteOptions = [
  {
    value: FROZEN_OOCYTE.YES,
    label: "Да",
    color: tableSelectColors.gray
  },
  {
    value: FROZEN_OOCYTE.NO,
    label: "Нет",
    color: tableSelectColors.gray
  }
];

export const helpHatchOptions: HelpHatchOptions = [
  {
    value: HELP_HATCH.YES,
    label: "Да",
    color: tableSelectColors.gray
  },
  {
    value: HELP_HATCH.NO,
    label: "Нет",
    color: tableSelectColors.gray
  }
];

export const freezeOocytesOptions: FreezeOocytesOptions = [
  { value: FREEZE_OOCYTES.YES, label: "Да", color: tableSelectColors.gray },
  { value: FREEZE_OOCYTES.NO, label: "Нет", color: tableSelectColors.gray }
];

export const dayOptions: DayOptions = [
  {
    value: DAY.SECOND,
    label: DAY.SECOND,
    color: tableSelectColors.gray
  },
  {
    value: DAY.THIRD,
    label: DAY.THIRD,
    color: tableSelectColors.gray
  },
  {
    value: DAY.FOURTH,
    label: DAY.FOURTH,
    color: tableSelectColors.gray
  }
];

export const fifthToSevenDayOptions: FifthToSeventhDayOptions = [
  {
    value: FIFTH_TO_SEVENTH_DAY["1"],
    label: FIFTH_TO_SEVENTH_DAY["1"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["2"],
    label: FIFTH_TO_SEVENTH_DAY["2"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["3AA"],
    label: FIFTH_TO_SEVENTH_DAY["3AA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["3AB"],
    label: FIFTH_TO_SEVENTH_DAY["3AB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["3AC"],
    label: FIFTH_TO_SEVENTH_DAY["3AC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["3BA"],
    label: FIFTH_TO_SEVENTH_DAY["3BA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["3BB"],
    label: FIFTH_TO_SEVENTH_DAY["3BB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["3BC"],
    label: FIFTH_TO_SEVENTH_DAY["3BC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["3CA"],
    label: FIFTH_TO_SEVENTH_DAY["3CA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["3CB"],
    label: FIFTH_TO_SEVENTH_DAY["3CB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["3CC"],
    label: FIFTH_TO_SEVENTH_DAY["3CC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["4AA"],
    label: FIFTH_TO_SEVENTH_DAY["4AA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["4AB"],
    label: FIFTH_TO_SEVENTH_DAY["4AB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["4AC"],
    label: FIFTH_TO_SEVENTH_DAY["4AC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["4BA"],
    label: FIFTH_TO_SEVENTH_DAY["4BA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["4BB"],
    label: FIFTH_TO_SEVENTH_DAY["4BB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["4BC"],
    label: FIFTH_TO_SEVENTH_DAY["4BC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["4CA"],
    label: FIFTH_TO_SEVENTH_DAY["4CA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["4CB"],
    label: FIFTH_TO_SEVENTH_DAY["4CB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["4CC"],
    label: FIFTH_TO_SEVENTH_DAY["4CC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["5AA"],
    label: FIFTH_TO_SEVENTH_DAY["5AA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["5AB"],
    label: FIFTH_TO_SEVENTH_DAY["5AB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["5AC"],
    label: FIFTH_TO_SEVENTH_DAY["5AC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["5BA"],
    label: FIFTH_TO_SEVENTH_DAY["5BA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["5BB"],
    label: FIFTH_TO_SEVENTH_DAY["5BB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["5BC"],
    label: FIFTH_TO_SEVENTH_DAY["5BC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["5CA"],
    label: FIFTH_TO_SEVENTH_DAY["5CA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["5CB"],
    label: FIFTH_TO_SEVENTH_DAY["5CB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["5CC"],
    label: FIFTH_TO_SEVENTH_DAY["5CC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["6AA"],
    label: FIFTH_TO_SEVENTH_DAY["6AA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["6AB"],
    label: FIFTH_TO_SEVENTH_DAY["6AB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["6AC"],
    label: FIFTH_TO_SEVENTH_DAY["6AC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["6BA"],
    label: FIFTH_TO_SEVENTH_DAY["6BA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["6BB"],
    label: FIFTH_TO_SEVENTH_DAY["6BB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["6BC"],
    label: FIFTH_TO_SEVENTH_DAY["6BC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["6CA"],
    label: FIFTH_TO_SEVENTH_DAY["6CA"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["6CB"],
    label: FIFTH_TO_SEVENTH_DAY["6CB"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["6CC"],
    label: FIFTH_TO_SEVENTH_DAY["6CC"],
    color: tableSelectColors.green
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["M1"],
    label: FIFTH_TO_SEVENTH_DAY["M1"],
    color: tableSelectColors.yellow
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["M2"],
    label: FIFTH_TO_SEVENTH_DAY["M2"],
    color: tableSelectColors.yellow
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["M3"],
    label: FIFTH_TO_SEVENTH_DAY["M3"],
    color: tableSelectColors.yellow
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["M4"],
    label: FIFTH_TO_SEVENTH_DAY["M4"],
    color: tableSelectColors.yellow
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["DEG"],
    label: FIFTH_TO_SEVENTH_DAY["DEG"],
    color: tableSelectColors.red
  },
  {
    value: FIFTH_TO_SEVENTH_DAY["STOP"],
    label: FIFTH_TO_SEVENTH_DAY["STOP"],
    color: tableSelectColors.red
  }
];

export const assessmentAfterDefrostOptions: AssessmentAfterDefrostOptions = [
  {
    value: ASSESSMENT_AFTER_DEFROST["1"],
    label: ASSESSMENT_AFTER_DEFROST["1"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["2"],
    label: ASSESSMENT_AFTER_DEFROST["2"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3AA"],
    label: ASSESSMENT_AFTER_DEFROST["3AA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3AB"],
    label: ASSESSMENT_AFTER_DEFROST["3AB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3AC"],
    label: ASSESSMENT_AFTER_DEFROST["3AC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3BA"],
    label: ASSESSMENT_AFTER_DEFROST["3BA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3BB"],
    label: ASSESSMENT_AFTER_DEFROST["3BB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3BC"],
    label: ASSESSMENT_AFTER_DEFROST["3BC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3CA"],
    label: ASSESSMENT_AFTER_DEFROST["3CA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3CB"],
    label: ASSESSMENT_AFTER_DEFROST["3CB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3CC"],
    label: ASSESSMENT_AFTER_DEFROST["3CC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4AA"],
    label: ASSESSMENT_AFTER_DEFROST["4AA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4AB"],
    label: ASSESSMENT_AFTER_DEFROST["4AB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4AC"],
    label: ASSESSMENT_AFTER_DEFROST["4AC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4BA"],
    label: ASSESSMENT_AFTER_DEFROST["4BA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4BB"],
    label: ASSESSMENT_AFTER_DEFROST["4BB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4BC"],
    label: ASSESSMENT_AFTER_DEFROST["4BC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4CA"],
    label: ASSESSMENT_AFTER_DEFROST["4CA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4CB"],
    label: ASSESSMENT_AFTER_DEFROST["4CB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4CC"],
    label: ASSESSMENT_AFTER_DEFROST["4CC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5AA"],
    label: ASSESSMENT_AFTER_DEFROST["5AA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5AB"],
    label: ASSESSMENT_AFTER_DEFROST["5AB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5AC"],
    label: ASSESSMENT_AFTER_DEFROST["5AC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5BA"],
    label: ASSESSMENT_AFTER_DEFROST["5BA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5BB"],
    label: ASSESSMENT_AFTER_DEFROST["5BB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5BC"],
    label: ASSESSMENT_AFTER_DEFROST["5BC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5CA"],
    label: ASSESSMENT_AFTER_DEFROST["5CA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5CB"],
    label: ASSESSMENT_AFTER_DEFROST["5CB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5CC"],
    label: ASSESSMENT_AFTER_DEFROST["5CC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6AA"],
    label: ASSESSMENT_AFTER_DEFROST["6AA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6AB"],
    label: ASSESSMENT_AFTER_DEFROST["6AB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6AC"],
    label: ASSESSMENT_AFTER_DEFROST["6AC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6BA"],
    label: ASSESSMENT_AFTER_DEFROST["6BA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6BB"],
    label: ASSESSMENT_AFTER_DEFROST["6BB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6BC"],
    label: ASSESSMENT_AFTER_DEFROST["6BC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6CA"],
    label: ASSESSMENT_AFTER_DEFROST["6CA"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6CB"],
    label: ASSESSMENT_AFTER_DEFROST["6CB"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6CC"],
    label: ASSESSMENT_AFTER_DEFROST["6CC"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["M1"],
    label: ASSESSMENT_AFTER_DEFROST["M1"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["M2"],
    label: ASSESSMENT_AFTER_DEFROST["M2"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["M3"],
    label: ASSESSMENT_AFTER_DEFROST["M3"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["M4"],
    label: ASSESSMENT_AFTER_DEFROST["M4"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["2a"],
    label: ASSESSMENT_AFTER_DEFROST["2a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["2b"],
    label: ASSESSMENT_AFTER_DEFROST["2b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["2c"],
    label: ASSESSMENT_AFTER_DEFROST["2c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3a"],
    label: ASSESSMENT_AFTER_DEFROST["3a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3b"],
    label: ASSESSMENT_AFTER_DEFROST["3b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["3c"],
    label: ASSESSMENT_AFTER_DEFROST["3c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4a"],
    label: ASSESSMENT_AFTER_DEFROST["4a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4b"],
    label: ASSESSMENT_AFTER_DEFROST["4b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["4c"],
    label: ASSESSMENT_AFTER_DEFROST["4c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5a"],
    label: ASSESSMENT_AFTER_DEFROST["5a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5b"],
    label: ASSESSMENT_AFTER_DEFROST["5b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["5c"],
    label: ASSESSMENT_AFTER_DEFROST["5c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6a"],
    label: ASSESSMENT_AFTER_DEFROST["6a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6b"],
    label: ASSESSMENT_AFTER_DEFROST["6b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["6c"],
    label: ASSESSMENT_AFTER_DEFROST["6c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["7a"],
    label: ASSESSMENT_AFTER_DEFROST["7a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["7b"],
    label: ASSESSMENT_AFTER_DEFROST["7b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["7c"],
    label: ASSESSMENT_AFTER_DEFROST["7c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["8a"],
    label: ASSESSMENT_AFTER_DEFROST["8a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["8b"],
    label: ASSESSMENT_AFTER_DEFROST["8b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["8c"],
    label: ASSESSMENT_AFTER_DEFROST["8c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["9a"],
    label: ASSESSMENT_AFTER_DEFROST["9a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["9b"],
    label: ASSESSMENT_AFTER_DEFROST["9b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["9c"],
    label: ASSESSMENT_AFTER_DEFROST["9c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["10a"],
    label: ASSESSMENT_AFTER_DEFROST["10a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["10b"],
    label: ASSESSMENT_AFTER_DEFROST["10b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["10c"],
    label: ASSESSMENT_AFTER_DEFROST["10c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["11a"],
    label: ASSESSMENT_AFTER_DEFROST["11a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["11b"],
    label: ASSESSMENT_AFTER_DEFROST["11b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["11c"],
    label: ASSESSMENT_AFTER_DEFROST["11c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["12a"],
    label: ASSESSMENT_AFTER_DEFROST["12a"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["12b"],
    label: ASSESSMENT_AFTER_DEFROST["12b"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["12c"],
    label: ASSESSMENT_AFTER_DEFROST["12c"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["MII"],
    label: ASSESSMENT_AFTER_DEFROST["MII"],
    color: tableSelectColors.green
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["2PN"],
    label: ASSESSMENT_AFTER_DEFROST["2PN"],
    color: tableSelectColors.gray
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["MI"],
    label: ASSESSMENT_AFTER_DEFROST["MI"],
    color: tableSelectColors.gray
  },
  {
    value: ASSESSMENT_AFTER_DEFROST["DEG"],
    label: ASSESSMENT_AFTER_DEFROST["DEG"],
    color: tableSelectColors.red
  }
];

export const fifthDayOptions: FifthDayOptions = [
  { value: FIFTH_DAY["BL-1"], label: "BL-1", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-2"], label: "BL-2", color: tableSelectColors.green },
  { value: FIFTH_DAY["M1"], label: "M1", color: tableSelectColors.yellow },
  { value: FIFTH_DAY["M2"], label: "M2", color: tableSelectColors.yellow },
  { value: FIFTH_DAY["M3"], label: "M3", color: tableSelectColors.yellow },
  { value: FIFTH_DAY["M4"], label: "M4", color: tableSelectColors.yellow },
  { value: FIFTH_DAY["DEG"], label: "DEG", color: tableSelectColors.red },
  { value: FIFTH_DAY["STOP"], label: "STOP", color: tableSelectColors.red },
  { value: FIFTH_DAY["BL-3"], label: "BL-3", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-3AA"], label: "BL-3AA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-3CA"], label: "BL-3CA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-3BA"], label: "BL-3BA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-3BB"], label: "BL-3BB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-3AB"], label: "BL-3AB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-3AC"], label: "BL-3AC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-3CB"], label: "BL-3CB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-3CC"], label: "BL-3CC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-3BC"], label: "BL-3BC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4"], label: "BL-4", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4BA"], label: "BL-4BA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4AA"], label: "BL-4AA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4AB"], label: "BL-4AB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4CA"], label: "BL-4CA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4CB"], label: "BL-4CB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4BB"], label: "BL-4BB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4BC"], label: "BL-4BC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4AC"], label: "BL-4AC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5AA"], label: "BL-5AA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-4CC"], label: "BL-4CC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5"], label: "BL-5", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5CA"], label: "BL-5CA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5BA"], label: "BL-5BA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5BB"], label: "BL-5BB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5AB"], label: "BL-5AB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5AC"], label: "BL-5AC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5CB"], label: "BL-5CB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5CC"], label: "BL-5CC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-5BC"], label: "BL-5BC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6"], label: "BL-6", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6AA"], label: "BL-6AA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6BA"], label: "BL-6BA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6CA"], label: "BL-6CA", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6AB"], label: "BL-6AB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6BB"], label: "BL-6BB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6CB"], label: "BL-6CB", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6AC"], label: "BL-6AC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6BC"], label: "BL-6BC", color: tableSelectColors.green },
  { value: FIFTH_DAY["BL-6CC"], label: "BL-6CC", color: tableSelectColors.green }
];

export const sixthDayOptions: SixthDayOptions = [
  { value: SIXTH_DAY["BL-1"], label: "BL-1", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-2"], label: "BL-2", color: tableSelectColors.green },
  { value: SIXTH_DAY["M1"], label: "M1", color: tableSelectColors.yellow },
  { value: SIXTH_DAY["M2"], label: "M2", color: tableSelectColors.yellow },
  { value: SIXTH_DAY["M3"], label: "M3", color: tableSelectColors.yellow },
  { value: SIXTH_DAY["M4"], label: "M4", color: tableSelectColors.yellow },
  { value: SIXTH_DAY["DEG"], label: "DEG", color: tableSelectColors.red },
  { value: SIXTH_DAY["STOP"], label: "STOP", color: tableSelectColors.red },
  { value: SIXTH_DAY["BL-3"], label: "BL-3", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-3AA"], label: "BL-3AA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-3CA"], label: "BL-3CA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-3BA"], label: "BL-3BA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-3BB"], label: "BL-3BB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-3AB"], label: "BL-3AB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-3AC"], label: "BL-3AC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-3CB"], label: "BL-3CB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-3CC"], label: "BL-3CC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-3BC"], label: "BL-3BC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4"], label: "BL-4", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4BA"], label: "BL-4BA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4AA"], label: "BL-4AA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4AB"], label: "BL-4AB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4CA"], label: "BL-4CA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4CB"], label: "BL-4CB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4BB"], label: "BL-4BB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4BC"], label: "BL-4BC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4AC"], label: "BL-4AC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5AA"], label: "BL-5AA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-4CC"], label: "BL-4CC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5"], label: "BL-5", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5CA"], label: "BL-5CA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5BA"], label: "BL-5BA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5BB"], label: "BL-5BB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5AB"], label: "BL-5AB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5AC"], label: "BL-5AC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5CB"], label: "BL-5CB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5CC"], label: "BL-5CC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-5BC"], label: "BL-5BC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6"], label: "BL-6", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6AA"], label: "BL-6AA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6BA"], label: "BL-6BA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6CA"], label: "BL-6CA", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6AB"], label: "BL-6AB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6BB"], label: "BL-6BB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6CB"], label: "BL-6CB", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6AC"], label: "BL-6AC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6BC"], label: "BL-6BC", color: tableSelectColors.green },
  { value: SIXTH_DAY["BL-6CC"], label: "BL-6CC", color: tableSelectColors.green }
];

export const seventhDayOptions: SeventhDayOptions = [
  { value: SEVENTH_DAY["BL-1"], label: "BL-1", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-2"], label: "BL-2", color: tableSelectColors.green },
  { value: SEVENTH_DAY["M1"], label: "M1", color: tableSelectColors.yellow },
  { value: SEVENTH_DAY["M2"], label: "M2", color: tableSelectColors.yellow },
  { value: SEVENTH_DAY["M3"], label: "M3", color: tableSelectColors.yellow },
  { value: SEVENTH_DAY["M4"], label: "M4", color: tableSelectColors.yellow },
  { value: SEVENTH_DAY["DEG"], label: "DEG", color: tableSelectColors.red },
  { value: SEVENTH_DAY["STOP"], label: "STOP", color: tableSelectColors.red },
  { value: SEVENTH_DAY["BL-3"], label: "BL-3", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-3AA"], label: "BL-3AA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-3CA"], label: "BL-3CA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-3BA"], label: "BL-3BA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-3BB"], label: "BL-3BB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-3AB"], label: "BL-3AB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-3AC"], label: "BL-3AC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-3CB"], label: "BL-3CB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-3CC"], label: "BL-3CC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-3BC"], label: "BL-3BC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4"], label: "BL-4", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4BA"], label: "BL-4BA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4AA"], label: "BL-4AA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4AB"], label: "BL-4AB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4CA"], label: "BL-4CA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4CB"], label: "BL-4CB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4BB"], label: "BL-4BB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4BC"], label: "BL-4BC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4AC"], label: "BL-4AC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5AA"], label: "BL-5AA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-4CC"], label: "BL-4CC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5"], label: "BL-5", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5CA"], label: "BL-5CA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5BA"], label: "BL-5BA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5BB"], label: "BL-5BB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5AB"], label: "BL-5AB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5AC"], label: "BL-5AC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5CB"], label: "BL-5CB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5CC"], label: "BL-5CC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-5BC"], label: "BL-5BC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6"], label: "BL-6", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6AA"], label: "BL-6AA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6BA"], label: "BL-6BA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6CA"], label: "BL-6CA", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6AB"], label: "BL-6AB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6BB"], label: "BL-6BB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6CB"], label: "BL-6CB", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6AC"], label: "BL-6AC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6BC"], label: "BL-6BC", color: tableSelectColors.green },
  { value: SEVENTH_DAY["BL-6CC"], label: "BL-6CC", color: tableSelectColors.green }
];

export const biopsyStatusOptions: BiopsyStatusOptions = [
  { value: BIOPSY_STATUS.BIOPSY, label: "Биопсия", color: tableSelectColors.gray },
  { value: BIOPSY_STATUS.IN_PROGRESS, label: "В работе", color: tableSelectColors.gray },
  { value: BIOPSY_STATUS.STORE, label: "Хранение", color: tableSelectColors.gray },
  { value: BIOPSY_STATUS.DONE, label: "Получен результат", color: tableSelectColors.green }
];

export const statusOptions: StatusOptions = [
  { value: STATUS.UTILIZATION, label: "Утилизация", color: tableSelectColors.red },
  { value: STATUS.CRIO, label: "Крио", color: tableSelectColors.blue },
  { value: STATUS.DEFROSTED, label: "Разморожен", color: tableSelectColors.blue },
  { value: STATUS.TRANSFERRED, label: "Перенесен", color: tableSelectColors.green },
  {
    value: STATUS.CRYOTRANSFER,
    label: "Криоперенос",
    color: tableSelectColors.green
  },
  {
    value: STATUS.INSEMINATION,
    label: "Культивирование",
    color: tableSelectColors.yellow
  },
  // { value: STATUS.TO_DEFROST, label: "К разморозке", color: tableSelectColors.green },
  // { value: STATUS.TO_TRANSFER, label: "К переносу", color: tableSelectColors.green },
  {
    value: STATUS.PASSED_FOR_TRANSFER,
    label: "Передан для транспортировки",
    color: tableSelectColors.gray
  },
  {
    value: STATUS.DONATION,
    label: "Донация",
    color: tableSelectColors.gray
  },
  {
    value: STATUS.EMPTY,
    label: "Отсутствует",
    color: tableSelectColors.gray
  }
];

export const finalAssessmentOptions = [
  { value: FINAL_ASSESSMENT["BL-1"], label: "BL-1", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-2"], label: "BL-2", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3"], label: "BL-3", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3AA"], label: "BL-3AA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3CA"], label: "BL-3CA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3BA"], label: "BL-3BA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3BB"], label: "BL-3BB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3AB"], label: "BL-3AB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3AC"], label: "BL-3AC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3CB"], label: "BL-3CB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3CC"], label: "BL-3CC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-3BC"], label: "BL-3BC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4"], label: "BL-4", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4BA"], label: "BL-4BA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4AA"], label: "BL-4AA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4AB"], label: "BL-4AB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4CA"], label: "BL-4CA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4CB"], label: "BL-4CB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4BB"], label: "BL-4BB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4BC"], label: "BL-4BC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4AC"], label: "BL-4AC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5AA"], label: "BL-5AA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-4CC"], label: "BL-4CC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5"], label: "BL-5", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5CA"], label: "BL-5CA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5BA"], label: "BL-5BA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5BB"], label: "BL-5BB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5AB"], label: "BL-5AB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5AC"], label: "BL-5AC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5CB"], label: "BL-5CB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5CC"], label: "BL-5CC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-5BC"], label: "BL-5BC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6"], label: "BL-6", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6AA"], label: "BL-6AA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6BA"], label: "BL-6BA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6CA"], label: "BL-6CA", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6AB"], label: "BL-6AB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6BB"], label: "BL-6BB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6CB"], label: "BL-6CB", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6AC"], label: "BL-6AC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6BC"], label: "BL-6BC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["BL-6CC"], label: "BL-6CC", color: tableSelectColors.green },
  { value: FINAL_ASSESSMENT["M1"], label: "M1", color: tableSelectColors.yellow },
  { value: FINAL_ASSESSMENT["M2"], label: "M2", color: tableSelectColors.yellow },
  { value: FINAL_ASSESSMENT["M3"], label: "M3", color: tableSelectColors.yellow },
  { value: FINAL_ASSESSMENT["M4"], label: "M4", color: tableSelectColors.yellow },
  { value: FINAL_ASSESSMENT["DEG"], label: "DEG", color: tableSelectColors.red },
  { value: FINAL_ASSESSMENT["STOP"], label: "STOP", color: tableSelectColors.red },
  { value: FINAL_ASSESSMENT["OKK"], label: "OKK", color: tableSelectColors.red },
  { value: FINAL_ASSESSMENT["MI"], label: "MI", color: tableSelectColors.red },
  { value: FINAL_ASSESSMENT["MII"], label: "MII", color: tableSelectColors.red },
  { value: FINAL_ASSESSMENT["GV"], label: "GV", color: tableSelectColors.red },
  { value: FINAL_ASSESSMENT["1PN"], label: "1PN", color: tableSelectColors.red },
  { value: FINAL_ASSESSMENT["2PN"], label: "2PN", color: tableSelectColors.red },
  { value: FINAL_ASSESSMENT["3PN"], label: "3PN", color: tableSelectColors.red },
  ...fifthToSevenDayOptions,
  ...zygoteAssessmentOptions,
  ...oocytesAssessmentOptions,
  ...crushingOptions
];

export const pgtStatusOptions = [
  { value: PGT_STATUS.BIOPSY, label: PGT_STATUS.BIOPSY, color: tableSelectColors.gray },
  { value: PGT_STATUS.IN_PROGRESS, label: PGT_STATUS.IN_PROGRESS, color: tableSelectColors.gray },
  { value: PGT_STATUS.STORAGE, label: PGT_STATUS.STORAGE, color: tableSelectColors.gray },
  { value: PGT_STATUS.STANDARD, label: PGT_STATUS.STANDARD, color: tableSelectColors.green },
  { value: PGT_STATUS.MOSAIC, label: PGT_STATUS.MOSAIC, color: tableSelectColors.yellow },
  { value: PGT_STATUS.HA, label: PGT_STATUS.HA, color: tableSelectColors.red },
  { value: PGT_STATUS.NOSIGNAL, label: PGT_STATUS.NOSIGNAL, color: tableSelectColors.gray },
  { value: PGT_STATUS.RECOMMENDED, label: PGT_STATUS.RECOMMENDED, color: tableSelectColors.greenLight },
  { value: PGT_STATUS.NOT_RECOMMENDED, label: PGT_STATUS.NOT_RECOMMENDED, color: tableSelectColors.redLight },
  { value: PGT_STATUS.GENETIC_CONSULTATION, label: PGT_STATUS.GENETIC_CONSULTATION, color: tableSelectColors.gray }
];

export const prevHatchOptions = [
  { value: PREV_HATCH.YES, label: PREV_HATCH.YES, color: tableSelectColors.gray },
  { value: PREV_HATCH.NO, label: PREV_HATCH.NO, color: tableSelectColors.gray }
];

export const defrostEnvOptions = [
  { value: DEFROST_ENV.KITAZATO, label: DEFROST_ENV.KITAZATO, color: tableSelectColors.gray },
  {
    value: DEFROST_ENV.RAPID_WARM_OMNI,
    label: DEFROST_ENV.RAPID_WARM_OMNI,
    color: tableSelectColors.gray
  },
  { value: DEFROST_ENV.CRYOTECH, label: DEFROST_ENV.CRYOTECH, color: tableSelectColors.gray },
  { value: DEFROST_ENV.ZREV, label: DEFROST_ENV.ZREV, color: tableSelectColors.gray },
  {
    value: DEFROST_ENV.VITA_VITRO_WARMING,
    label: DEFROST_ENV.VITA_VITRO_WARMING,
    color: tableSelectColors.gray
  },
  {
    value: DEFROST_ENV.IRVIN_SCIENTIFIC_THAW,
    label: DEFROST_ENV.IRVIN_SCIENTIFIC_THAW,
    color: tableSelectColors.gray
  },
  {
    value: DEFROST_ENV.VITRI_STORE_GYNEMED,
    label: DEFROST_ENV.VITRI_STORE_GYNEMED,
    color: tableSelectColors.gray
  },
  {
    value: DEFROST_ENV.VITRO_LIFE_WARM_KIT_CLEAVE,
    label: DEFROST_ENV.VITRO_LIFE_WARM_KIT_CLEAVE,
    color: tableSelectColors.gray
  },
  { value: DEFROST_ENV.SYDNEY_IVF, label: DEFROST_ENV.SYDNEY_IVF, color: tableSelectColors.gray },
  { value: DEFROST_ENV.MEDICULT, label: DEFROST_ENV.MEDICULT, color: tableSelectColors.gray },
  {
    value: DEFROST_ENV.VITRI_THAW_FETRIPRO,
    label: DEFROST_ENV.VITRI_THAW_FETRIPRO,
    color: tableSelectColors.gray
  },
  { value: DEFROST_ENV.OTHER, label: DEFROST_ENV.OTHER, color: tableSelectColors.gray }
];

export const criopreservationEnvOptions = [
  {
    value: CRIOPRESERVATION_ENV.KITAZATO,
    label: CRIOPRESERVATION_ENV.KITAZATO,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.CRYOTECH,
    label: CRIOPRESERVATION_ENV.CRYOTECH,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.RAPID_VIT_OMNI,
    label: CRIOPRESERVATION_ENV.RAPID_VIT_OMNI,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.VITA_VITRO,
    label: CRIOPRESERVATION_ENV.VITA_VITRO,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.IRVINE_SCIENTIFIC_VIT,
    label: CRIOPRESERVATION_ENV.IRVINE_SCIENTIFIC_VIT,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.BVIT,
    label: CRIOPRESERVATION_ENV.BVIT,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.VITRI_FREEZE_FERTIPRO,
    label: CRIOPRESERVATION_ENV.VITRI_FREEZE_FERTIPRO,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.SYDNEY_IVF_BL_VITR,
    label: CRIOPRESERVATION_ENV.SYDNEY_IVF_BL_VITR,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.VITROLIFE_KIT_CLEAVE,
    label: CRIOPRESERVATION_ENV.VITROLIFE_KIT_CLEAVE,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.VITRI_STORE_GYNEMED,
    label: CRIOPRESERVATION_ENV.VITRI_STORE_GYNEMED,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.MEDICULT_ORIGIO,
    label: CRIOPRESERVATION_ENV.MEDICULT_ORIGIO,
    color: tableSelectColors.gray
  },
  {
    value: CRIOPRESERVATION_ENV.OTHER,
    label: CRIOPRESERVATION_ENV.OTHER,
    color: tableSelectColors.gray
  }
];

export const cryoCarrierOptions = [
  { value: CRYO_CARRIER.CRYOTOP, label: CRYO_CARRIER.CRYOTOP, color: tableSelectColors.gray },
  { value: CRYO_CARRIER.VITRIFIT, label: CRYO_CARRIER.VITRIFIT, color: tableSelectColors.gray },
  { value: CRYO_CARRIER.CRYOTEC, label: CRYO_CARRIER.CRYOTEC, color: tableSelectColors.gray },
  { value: CRYO_CARRIER.RAPID_I, label: CRYO_CARRIER.RAPID_I, color: tableSelectColors.gray },
  { value: CRYO_CARRIER.CRYOLOCK, label: CRYO_CARRIER.CRYOLOCK, color: tableSelectColors.gray },
  { value: CRYO_CARRIER.MINIVIRO, label: CRYO_CARRIER.MINIVIRO, color: tableSelectColors.gray },
  {
    value: CRYO_CARRIER.HIGH_SECURITY_STRAW,
    label: CRYO_CARRIER.HIGH_SECURITY_STRAW,
    color: tableSelectColors.gray
  },
  { value: CRYO_CARRIER.CRYOPETTE, label: CRYO_CARRIER.CRYOPETTE, color: tableSelectColors.gray },
  { value: CRYO_CARRIER.VITA_VITRO, label: CRYO_CARRIER.VITA_VITRO, color: tableSelectColors.gray },
  {
    value: CRYO_CARRIER.MCGILL_CRYOLEAF,
    label: CRYO_CARRIER.MCGILL_CRYOLEAF,
    color: tableSelectColors.gray
  },
  { value: CRYO_CARRIER.CRYOTIP, label: CRYO_CARRIER.CRYOTIP, color: tableSelectColors.gray },
  { value: CRYO_CARRIER.PETG_STRAW, label: CRYO_CARRIER.PETG_STRAW, color: tableSelectColors.gray },
  { value: CRYO_CARRIER.CRYO_LOGIC, label: CRYO_CARRIER.CRYO_LOGIC, color: tableSelectColors.gray },
  { value: CRYO_CARRIER.OTHER, label: CRYO_CARRIER.OTHER, color: tableSelectColors.gray }
];

export const columns = [
  "ID",
  "Дата начала культивирования",
  "Дата крио",
  "ID мужчины",
  "Оплодотворение",
  "Финальная оценка",
  "Статус биопсии",
  "Результат биопсии"
];

export const allEmbryosColumns = [
  "ID",
  "Финальная оценка",
  "Оценка ооцитов",
  "Метод",
  "Инкубатор культивирования",
  "Оценка зигот",
  "Дробление",
  "5 сутки",
  "6 сутки",
  "7 сутки",
  "Статус биопсии",
  "Танк",
  "Стакан",
  "Холдер",
  "Статус",
  "Cобытие переноса"
];

export const cryopreservationsColumns = [
  "Женщина",
  "Мужчина",
  "Дата крио",
  "Дней\nкульт.",
  "Финальная\nоценка",
  "Цвет",
  "Среда\nкрио",
  "Крионоситель",
  "Номер\nкрионосителя",
  "Статус ПГТ",
  "Сосуд\nДьюара",
  "Стакан",
  "Держатель",
  "Текущий\nстатус"
];

export const oocytesCryopreservationsColumns = [
  "Женщина",
  "Дата крио",
  "Дней\nкульт.",
  "Финальная\nоценка",
  "Цвет",
  "Среда\nкрио",
  "Крионоситель",
  "Номер\nкрионосителя",
  "Сосуд\nДьюара",
  "Стакан",
  "Держатель",
  "Текущий\nстатус"
];

export const cryoStorageColumns = [
  "Женщина",
  "Мужчина",
  "Дата крио",
  "Финальная\nоценка",
  "Дней\nкульт.",
  "Номер\nкрионосителя",
  "Среда\nкрио",
  "Крионоситель",
  "Цвет",
  "Статус ПГТ",
  "Сосуд\nДьюара",
  "Стакан",
  "Держатель",
  "Текущий\nстатус"
];

export const biopsyColumns = [
  "Номер\nкрионосителя",
  "Женщина",
  "Мужчина",
  "Статус ПГТ",
  "Дата крио",
  "Финальная\nоценка",
  "Дней культ.",
  "Маркировка\nпробирки",
  "Количество клеток\nв биоптате",
  "Предварительный\nхетчинг",
  "Тип биопсии",
  "Особенности\nбиопсии",
  "Статус MG",
  "Дата установки статуса MG",
  "Результат секвенирования",
];

export const defrostsColumns = [
  "Номер крионосителя",
  "Женщина",
  "Мужчина",
  "Дата крио",
  "Финальная\nоценка",
  "Дней культ.",
  "Дата\nразморозки",
  "Среда\nразмораживания",
  "Выживаемость",
  "Фрагментация",
  "Оценка после\nразморозки",
  "ВХ",
  "Текущий\nстатус",
  "Cобытие\nпереноса"
];

export const oocytesDefrostsColumns = [
  "Номер крионосителя",
  "Женщина",
  "Дата крио",
  "Финальная\nоценка",
  "Дней культ.",
  "Дата\nразморозки",
  "Среда\nразмораживания",
  "Выживаемость",
  "Оценка после\nразморозки",
  "ВХ",
  "Текущий\nстатус",
  "Cобытие\nпереноса"
];

export const inseminationCultivationColumns = [
  "ID",
  "Женщина",
  "Мужчина",
  "Дата пункции",
  "Метод\nоплод.",
  "Оценка\nооцитов",
  "Тип ооцитов",
  "Морфология",
  "Оценка\nзигот",
  "Дробление",
  "Сутки",
  "5 сутки",
  "6 сутки",
  "7 сутки",
  "Примечание",
  "Статус ЭКО",
  "Текущий\nстатус",
  "Номер\nкрионосителя",
  "Статус ПГТ",
  "Событие ЭКО",
  // "Статус MG",
  // "Дата установки статуса MG",
  // "Результат секвинирования",
];

export const ecoInseminationCultivationColumns = [
  "ID",
  "Женщина",
  "Мужчина",
  "Дата оплодотворения",
  "Метод\nоплод.",
  "Оценка\nооцитов",
  "Тип ооцитов",
  "Морфология",
  "Оценка\nзигот",
  "Дробление",
  "Сутки",
  "5 сутки",
  "6 сутки",
  "7 сутки",
  "Примечание",
  "Статус ЭКО",
  "Текущий\nстатус",
  "Номер\nкрионосителя",
  "Статус ПГТ",
  "Событие ЭКО",
];

export const oocytesInseminationCultivationColumns = [
  "ID",
  "Женщина",
  "Дата пункции",
  "Оценка\nооцитов",
  "Тип ооцитов",
  "Морфология",
  "Статус ЭКО",
  "Текущий\nстатус",
  "Номер\nкрионосителя",
  "Событие ЭКО",
];

export const selectCustomStyles = {
  menu: (provided: mixed) => ({
    ...provided,
    minWidth: "60px",
    width: "100%",
    borderRadius: 0,
    boxShadow: "0px 2px 17px rgb(91 91 91 / 26%)",
    color: colors.black,
    marginTop: "1px",
    fontSize: 15,
    maxHeight: "220px",
    overflow: "auto"
  }),
  menuList: () => ({
    paddingBottom: 0,
    paddingTop: 0,
    maxHeight: "220px",
    overflow: "auto"
  }),
  singleValue: (provided: mixed, { data }: any) => {
    return {
      ...provided,
      position: "unset",
      top: "unset",
      transform: "unset",
      maxWidth: "100%",
      background: data.color,
      padding: "6.5px 8px",
      borderRadius: "4px",
      color: colors.black,
      fontSize: 14
    };
  },
  valueContainer: (provided: mixed, { selectProps: { menuIsOpen } }: any) => {
    return {
      ...provided,
      padding: 0,
      flexWrap: menuIsOpen ? "wrap" : "nowrap",
      overflow: menuIsOpen ? "hidden" : "initial"
    };
  },
  multiValue: (provided: mixed, { data }: any) => ({
    ...provided,
    maxWidth: "100%",
    background: data.color,
    padding: "6.5px 8px",
    borderRadius: "4px",
    color: colors.black,
    fontSize: 14,
    display: "flex",
    alignItems: "center",

    ":first-of-type": {
      margin: 0,
      marginRight: 2
    }
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    paddingLeft: 1,
    paddingRight: 0,
    marginLeft: 4,

    ":hover": {
      backgroundColor: "rgb(151 151 151 / 50%)",
      color: "#979797"
    }
  }),
  multiValueLabel: (provided: mixed) => ({ ...provided, fontSize: 14, padding: 0, paddingLeft: 0 }),
  container: (provided: mixed) => ({
    ...provided,
    minHeight: "inherit",
    background: "transparent",
    height: "100%"
  }),
  control: (styles: mixed, { menuIsOpen }: { menuIsOpen: boolean }) => {
    return {
      ...styles,
      boxShadow: "none",
      borderColor: "#ddd",
      cursor: "pointer",
      border: "none",
      borderRadius: 0,
      height: "100%",
      background: "transparent",
      backgroundColor: "transparent",
      color: colors.black,
      padding: menuIsOpen ? "0 12px" : 0,
      fontSize: 14,
      minHeight: "inherit",

      ":hover": {
        borderColor: "#ddd",
        boxShadow: "0 0 0 100px rgba(0,0,0,0.01) inset"
      }
    };
  },
  option: (
    styles: any,
    { isDisabled, isSelected }: { isDisabled: boolean, isSelected: boolean }
  ) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? null : isSelected ? colors.transparent : null,
      color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
      minWidth: "60px",
      width: "100%",
      height: 44,
      display: "flex",
      alignItems: "center",
      padding: "6.5px 12px",
      fontSize: 14,

      ":hover": {
        ...styles[":hover"],
        backgroundColor: !isDisabled && (isSelected ? colors.gray100 : colors.gray100)
      }
    };
  }
};
