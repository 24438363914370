import React, { useState } from "react";
import { Accordion, AccordionSummary, Autocomplete, Box, Chip } from "@mui/material";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { observer } from "mobx-react";
import _ from 'lodash';
import styled from 'styled-components';
import { colors } from "../styleguide/colors";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import popularLayoutsStore from '../../stores/popularLayoutsStore';

const OptionChip = styled(Chip)`
  margin: 1px!important;
  .MuiChip-label {
    padding: 2px 10px;
	  cursor: default;
  }
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const SelectLayout = observer(() => {
  const [expandedGroup, setExpandedGroup] = useState(false);
  const [oldInputValue, setOldInputValue] = useState('');

  const addOptions = (ids) => {
    popularLayoutsStore.addPopularLayouts(ids);
  };

  const removeOptions = (ids) => {
    popularLayoutsStore.deletePopularLayouts(ids);
  };

  const checkGroup = (groupName, value = []) => {
    const groupLength = popularLayoutsStore.allLayouts.filter((c) => c.groupName === groupName).length;
    const optionsValue = popularLayoutsStore.allLayouts.filter((item) => value.includes(item.id));
    const selectedGroupLength = _.uniq(optionsValue.filter(
      (c) => c.groupName === groupName
    ).map((item) => item.id)).length;
    return groupLength === selectedGroupLength;
  };

  const selectedGroupLength = (groupName, value = []) => {
    const groupLength = popularLayoutsStore.allLayouts.filter((c) => c.groupName === groupName).length;
    const optionsValue = popularLayoutsStore.allLayouts.filter((item) => value.includes(item.id));
    const selectedGroupLength = _.uniq(optionsValue.filter(
      (c) => c.groupName === groupName
    ).map((item) => item.id)).length;
    return selectedGroupLength > 0 && selectedGroupLength !== groupLength
  };

  const selectGroup = (groupName, value = []) => {
    const filterAllOptions = popularLayoutsStore.allLayouts.filter((c) => c.groupName === groupName);
    const filterAllOptionsIds = filterAllOptions.map((item) => item.id);
    const optionsValue = popularLayoutsStore.allLayouts.filter((item) => value.includes(item.id));
    const selectedGroupValue = optionsValue.filter(
      (c) => c.groupName === groupName
    );
    if (filterAllOptions.length === selectedGroupValue.length) {
      const result = _.uniq(optionsValue.filter((c) => !filterAllOptionsIds.includes(c.id)).map((item) => item.id));
      removeOptions(result);
    } else {
      const result = _.uniq([...value, ...filterAllOptions.map((item) => item.id)]);
      addOptions(result);
    }
  };

  const removeSelect = (value, currentValue) => {
    const result = _.uniq(value.filter((item) => item !== currentValue));
    removeOptions(result);
  }

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      options={popularLayoutsStore.allLayouts}
      filterOptions={(options, params) => { // <<<--- inject the Select All option
        const filtered = options.filter((item) => params.getOptionLabel(item).toLowerCase().includes(params.inputValue.toLowerCase()) || item.groupName.toLowerCase().includes(params.inputValue.toLowerCase()))
        if (filtered.length && params.inputValue && params.inputValue !== oldInputValue && filtered[0].groupName !== expandedGroup) {
          setExpandedGroup(filtered[0].groupName);
        }
        setOldInputValue(params.inputValue);
        return filtered;
      }}
      getOptionLabel={(option) => option.name}
      onChange={(e, data, reason, details) => {
        const ids = data.map(item => item.id);
        if (reason === 'selectOption') {
          addOptions(ids);
        }
        if (reason === 'removeOption') {
          removeOptions(ids.filter(id => id !== details.option.id));
        }
      }}
      value={popularLayoutsStore.selectedLayouts}
      groupBy={(option) => option.groupName}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderTags={() => {
        return _.uniqBy(popularLayoutsStore.selectedLayouts, layout => layout.id).map((layout) => (
          <OptionChip
            key={layout.id}
            color={"default"}
            label={layout.name}
            onDelete={() => removeSelect(popularLayoutsStore.selectedLayoutIds, layout.id)}
          />
        ));
      }}
      renderGroup={(params) => (
        <li key={params.key}>
          <Accordion expanded={expandedGroup === params.group} onChange={(event, expanded) => {
            if (event.target.classList[0] !== 'PrivateSwitchBase-input') {
              setExpandedGroup(expanded ? params.group : false);
            }
          }} square sx={{
            [`&.MuiAccordion-gutters`]: {
              boxShadow: 'none',
            },
            [`& .MuiAccordionSummary-contentGutters, .MuiAccordionSummary-contentGutters.Mui-expanded`]: {
              margin: 0
            },
            [`& .MuiAccordionSummary-gutters`]: {
              minHeight: '48px!important'
            },
            [`& .MuiAutocomplete-option`]: {
              color: colors.black,
            },
          }}>
            <AccordionSummary
              sx={{
                margin: 0,
                display: 'flex',
                alignItems: 'center'
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Box display={"flex"} alignItems={"center"} position={"relative"}>
                <Checkbox
                  sx={{
                    [`& .MuiSvgIcon-root`]: {
                      fontSize: '1.25rem'
                    },
                  }}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={checkGroup(params.group, popularLayoutsStore.selectedLayoutIds, () => {})}
                  onChange={() => {
                    selectGroup(params.group, popularLayoutsStore.selectedLayoutIds, () => {});
                  }}
                  indeterminate={selectedGroupLength(params.group, popularLayoutsStore.selectedLayoutIds)}
                  // checked={option[params.group] ? !!(value?.length === options.length) : selected}
                />
                {params.group}
              </Box>
            </AccordionSummary>
            <AccordionDetails as="ul">
              {params.children}
            </AccordionDetails>
          </Accordion>
        </li>
      )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Выбранные популярные шаблоны"
          placeholder="Добавить шаблон"
        />
      )}
    />
  );
});