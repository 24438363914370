// @flow
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 429px;
  min-width: 429px;

  @media (max-width: 429px) {
    max-width: 100%;
    min-width: auto;
    width: 100%;
  }
`;

const ChildrenContainer = styled.div`
  overflow-y: auto;
`;

export const DiagnosesTreeSidebarLayout = (props: {
  breadcrumbs: React$Node,
  list: React$Node
}) => {
  const { breadcrumbs, list } = props;
  return (
    <Container>
      {breadcrumbs}
      <ChildrenContainer>{list}</ChildrenContainer>
    </Container>
  );
};
