// @flow
import React from "react";

export const ThrowErrorPage = () => {
  throw new Error("Test error");

  return (
    <div>
      Error
    </div>
  );
}
