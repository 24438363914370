// @flow
import type { TFeedback } from "../entities/Feedback";
import moment from "moment";

export const isNotFutureFeedback = (feedback: TFeedback) => {
  return (
    feedback.response || moment.utc((feedback.notification_date: any)).isSameOrBefore(moment.utc())
  );
};

export const isFilledFeedbackWithoutResponse = (feedback: TFeedback) => {
  return feedback.doctors_comment && !feedback.response;
};

export const isCompleted = (feedback: TFeedback) => {
  return feedback.completed_at || (feedback.response && feedback.response.created_by_doctor);
};
