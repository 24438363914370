//@flow
import * as React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import { PNormal } from "../styleguide/typography";
import SearchIcon from "./icons/search-big.svg";
import SearchIconHover from "./icons/search-big-hover.svg";
import CloseIcon from "./icons/close.svg";
import { useAutoFocusRef } from "../../utils/useAutoFocusRef";
import {Checkbox} from "../appointment-page/Checkbox";
import {observer} from "mobx-react";
import filterAppointmentsStore from "../../stores/filterAppointmentsStore";

const CheckboxContainer = styled.div`
  width: 100%;
  padding-top: 8px;
  display: flex;
  justify-content: right;
  z-index: 1;
  position: relative;
`;

const SearchContainer = styled.div`
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.magenta};

  display: flex;
  align-items: center;
`;

const SearchImage = styled.div`
  width: 24px;
  height: 24px;
  background: url(${SearchIcon}) no-repeat;
  background-size: contain;
  opacity: 0.3;
`;

const SearchInput = styled(PNormal)`
  width: 100%;
  padding-left: 12px;
  box-sizing: border-box;
  border: none;

  outline: none;
`;

const SearchClose = styled.button`
  width: 17px;
  height: 17px;
  padding: 0;
  margin-right: 10px;
  border: none;
  background: url(${CloseIcon}) no-repeat;
  background-size: contain;

  cursor: pointer;
`;

export const SearchButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  background: url(${SearchIcon}) no-repeat;
  background-size: contain;

  opacity: 0.3;
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  :hover {
    opacity: 1;
    background-image: url(${SearchIconHover});
  }
`;

export const AppointmentHistorySearch = observer((props: {
  value: string,
  isAllTab: boolean,
  handleSearchClose: () => mixed,
  handleSearchInputChanges: (e: SyntheticInputEvent<EventTarget>) => mixed
}) => {
  const searchInputEl = useAutoFocusRef();

  return (
    <div>
        <SearchContainer>
          <SearchImage />
          <SearchInput
            as="input"
            ref={searchInputEl}
            value={props.value}
            onChange={props.handleSearchInputChanges}
          />
          <SearchClose onClick={props.handleSearchClose} />
        </SearchContainer>
        { props.isAllTab &&
            <CheckboxContainer>
                <Checkbox
                    text="Только пациенты"
                    checked={filterAppointmentsStore.onlyPatient}
                    onChange={() => filterAppointmentsStore.setOnlyPatient(!filterAppointmentsStore.onlyPatient)}
                />
            </CheckboxContainer>
        }
    </div>
  );
});
