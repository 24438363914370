import styled from "styled-components";
import { Paper } from "@mui/material";
import { colors } from "../../styleguide/colors";
import { withModifiers } from "with-modifiers";

export const BonusPaper = styled(Paper)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: auto;
    gap: 20px;

    &.MuiPaper-root {
        padding: 16px;
        border-radius: 16px;
    }

    @media (max-width: 768px) {
        gap: 10px;
    }
`;

export const BonusPaperModal = styled(Paper)`
    padding: 12px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: calc(50% - 4px);
    box-sizing: border-box;
    border-radius: 16px !important;
    background: ${props => props.background } !important;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const BonusVerticalDivider = styled.div`
  background: #EBEBEB;
  width: 1px;
  height: 40px;
  flex-shrink: 0;
`

export const FlexBlock = withModifiers({
  justifyContentSB: () => `
    justify-content: space-between;
  `,
  column: () => `
    flex-direction: column;
  `,
  alignmentToStart: () => `
    align-items: flex-start;
  `,
  wrap: () => `
    flex-wrap: wrap;
  `
})(
  modifier => styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
      
    ${modifier}
`);
export const BonusTitleBold = withModifiers({
  center: () => `
    text-align: center;
  `
})(
  modifier => styled.div`
    font-size: 20px;
    line-height: 28px;
    color: ${colors.black};
    flex-shrink: 0;

    @media (max-width: 768px) {
        font-size: 18px;
    }

    @media (max-width: 480px) {
        font-size: 16px;
    }
      
    ${modifier}
`);

export const BonusLevelTextColor = withModifiers({
  bronze: () => `
    color: #C3946F;
  `,
  silver: () => `
    color: #ACADB0;
  `,
  gold: () => `
    color: #D5B371;
  `,
  platinum: () => `
    color: #8D9DA8;
  `,
  brilliant: () => `
    color: #6CB6DA;
  `
})(
  modifier => styled.span`
    font-size: 16px;
    color: #ACADB0;
    @media (max-width: 480px) {
      font-size: 14px;
    }
      
    ${modifier}
`);

export const BonusTitleLevel = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    line-height: 24px;
      
    @media (max-width: 768px) {
        font-size: 18px;
    }
      
`;
export const ConditionsBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    
    @media (max-width: 768px) {
        gap: 12px;
        flex-direction: column;
        align-items: flex-start;
    }
`;
export const FlexBlockGapMini = withModifiers({
  right: () => `
    justify-content: flex-end;
  `,
  alignStart: () => `
    align-items: flex-start;
  `
})(
  modifier => styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
      
    ${modifier}
`);
export const BonusText = withModifiers({
  gray: () => `
    color: ${colors.gray700};
  `
})(
modifier => styled.div`
  font-size: 14px;
  color: ${colors.black};
  line-height: 20px;
  font-weight: ${props => props?.fontWeight || "normal" };
      
  ${modifier}
`);
export const BonusSubtitle = withModifiers({
  black: () => `
    color: ${colors.black};
  `,
  red: () => `
    color: #F2576A;
  `,
  green: () => `
    color: #31BD2E;
  `,
})(
  modifier => styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${colors.gray700};
    display: inline-flex;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
      
    ${modifier}
`);
export const LinearCountAppointmentsBlock = styled.div`
    height: 48px;
    border-radius: 4px;
    background: #f3f3f3;
    margin-bottom: 26px;
    position: relative;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;
export const LinearCountAppointmentsCurrent = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: ${props => `${props.width}%`};
    height: 48px;
    background: #31BD2E;
`;
export const CountAppointments = styled.div`
    position: absolute;
    right: 0;
    transform: translateX(50%);
    bottom: -26px;
    font-size: 14px;
    line-height: 23px;
    color: #31BD2E;
`;
export const CardEvaluation = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

export const LevelItemTitle = styled.div`
    font-size: 22px;
    line-height: 22px;
    color: ${colors.black};
    text-align: center;
`;

export const ConditionsBlockLevel = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: ${colors.white};
`;

export const AllLevelsText = styled.div`
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #D6006D;
    cursor: pointer;
    margin-top: 15px;
`;

export const LevelItem = styled.div`
`;

export const LevelsBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
`;

export const BonusCountText = withModifiers({
  bold: () => `
    font-weight: 500;
  `
})(
  modifier => styled.div`
    font-size: 16px;
    line-height: 16px;
      
    ${modifier}
`);
