import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Diagnoses {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getDiagnoses(params) {
    return this.apiService.get(`diagnoses`, params);
  }

  getDiagnosisParents(id) {
    return this.apiService.get(`diagnoses/${id}/parents`);
  }
}

export default new Diagnoses();
