// @flow
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import _ from "lodash";

export type ScrollData = {
  left: boolean,
  right: boolean,
  top: boolean,
  bottom: boolean
};

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

export function useScrollDetector(callback?: (scrollData: ScrollData) => mixed) {
  const [scrollListenerMounted, setScrollListenerMounted] = useState(false);
  const [canScroll, setScroll] = useState({ left: false, right: false, top: false, bottom: false });

  const tableContainer = useRef<React.ElementRef<*>>(null);
  const scrollContainer = useRef<React.ElementRef<*>>(null);
  const table = useRef<React.ElementRef<*>>(null);

  useEffect(() => {
    setScrollListenerMounted(true);

    const updateScrollState = () => {
      if (!table.current || !scrollContainer.current || !tableContainer.current) {
        return;
      }
      const fullWidth = table.current.scrollWidth;
      const viewportWidth = scrollContainer.current.offsetWidth;
      const offset =
        tableContainer.current.getBoundingClientRect().left -
        table.current.getBoundingClientRect().left;

      const fullHeight = table.current.scrollHeight;
      const viewportHeight = scrollContainer.current.offsetHeight;
      const offsetHeight =
        Math.ceil(tableContainer.current.getBoundingClientRect().top) +
        Math.ceil(Math.abs(table.current.getBoundingClientRect().top));

      const scrollData = {
        left: offset > 0,
        right: offset + viewportWidth < fullWidth,
        top: offsetHeight > 0,
        bottom: offsetHeight + viewportHeight < fullHeight
      };

      setScroll(scrollData);

      callback && callback(scrollData);
    };

    const rafUpdateScrollState = () => requestAnimationFrame(updateScrollState);
    const debouncedUpdateScrollState = _.debounce(rafUpdateScrollState, 34);

    let previousScrollLeft = 0;
    let previousScrollTop = 0;
    const updateScrollStateRequest = e => {
      if (previousScrollTop !== e.target.scrollTop) {
        previousScrollTop = e.target.scrollTop;
        debouncedUpdateScrollState();
      }
      if (previousScrollLeft !== e.target.scrollLeft) {
        previousScrollLeft = e.target.scrollLeft;
        debouncedUpdateScrollState();
      }
    };

    updateScrollState();
    scrollContainer.current &&
      scrollContainer.current.addEventListener("scroll", updateScrollStateRequest);
    window.addEventListener("resize", debouncedUpdateScrollState);
    return () => {
      scrollContainer.current &&
        scrollContainer.current.removeEventListener("scroll", updateScrollStateRequest);
      window.removeEventListener("resize", debouncedUpdateScrollState);
    };
  }, [scrollListenerMounted]);

  return {
    canScrollLeft: canScroll.left,
    canScrollRight: canScroll.right,
    canScrollTop: canScroll.top,
    canScrollBottom: canScroll.bottom,
    scrollContainer,
    tableContainer,
    table
  };
}
