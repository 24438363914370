// @flow
import React from "react";
import { Box, Divider, Stack, Tooltip, tooltipClasses, Typography, Zoom } from "@mui/material";
import { colors } from "../../../styleguide/colors";
import EmptySourceIcon from "@mui/icons-material/ApartmentOutlined";
import { styled } from "@mui/material/styles";
import { scrollbars } from "../../../chats/style-elements";

const blackout = (target, event) => {
  target.parentElement.childNodes.forEach(element => {
    if (event === "focused") {
      element.style.filter = element === target ? `brightness(1.1)` : `brightness(.9)`;
    } else if (event === "unfocused" && element.style) {
      element.style.filter = `brightness(1)`;
    }
  });
};

const boxShadow =
  "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)";

const LineChartWrapper = styled(Stack)(
  () => `
    height: 100%;
    overflow: auto;
    padding-block-start: 20px;
    gap: 10px;
    padding-inline: 10px;
    ${scrollbars}
  `
);

const LineChartElement = styled(Stack)(
  () => `
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justifyContent: space-between;
    background: transparent;
    transition: all .15s ease-in-out;
    padding: 0;
    border-radius: 10px;
  `
);

const IconWrapper = styled(Box)(
  () => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    svg {
      max-width: 35px;
      max-height: 35px;
      width: 100%;
      height: 100%;
    }
  `
);

const LineWrapper = styled(Box)(
  () => `
    display: flex;
    width: 100%;
    height: 15px;
    cursor: pointer;
    background: linear-gradient(to right, rgb(138 133 133 / 35%), rgb(180 180 180 / 15%), rgba(151, 151, 151, 0.3));
    border-radius: 10px;
    overflow: hidden;
    transition: all .15s ease-in-out;
    &:hover {
      height: 20px;
      transition: all .15s ease-in-out;
    }
  `
);

const LineElement = styled(Box)(
  ({ filling, color }) => `
    width: ${filling}%;
    height: 100%;
    background: ${color};
    filter: brightness(1);
    transition: all .15s ease-in-out;
  `
);

const CircleColor = styled(Box)(
  ({ color }) => `
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${color};
    border: 2px solid white;
    box-shadow: ${boxShadow};
  `
);

const TooltipStyled = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 180,
    backgroundColor: "white",
    boxShadow: boxShadow,
    padding: "5px 15px"
  }
}));

const TooltipContainer = styled(Stack)(
  () => `
    flex-direction: column;
    align-items: center;
    gap: 5px;
  `
);

const TooltipContent = ({ name = "", contents = [] }) => {
  return (
    <TooltipContainer>
      <Typography color={colors.nearBlack} fontSize="15px">
        {name}
      </Typography>
      <Divider flexItem color={colors.nearBlack} />
      {contents.map(content => (
        <Stack
          key={content.label}
          sx={{ width: "100%" }}
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <CircleColor color={content.color} />
          <Typography variant="body1" color={colors.nearBlack} fontSize="13px" sx={{ flex: 1 }}>
            {content.label}
          </Typography>
          <Typography variant="body1" color={colors.darkBlack} fontSize="13px">
            {content.count}
          </Typography>
        </Stack>
      ))}
    </TooltipContainer>
  );
};

export function HorizontalBarChartMUI(props) {
  return (
    <LineChartWrapper>
      {props.data.datasets.map(({ icon, name, data, value }, index) => {
        return (
          <LineChartElement key={index}>
            <IconWrapper>{icon ? icon : <EmptySourceIcon color="primary" />}</IconWrapper>
            <TooltipStyled
              key={index}
              title={<TooltipContent name={name} contents={data} />}
              TransitionComponent={Zoom}
              followCursor
              placement="top">
              <LineWrapper>
                {data
                  .filter(item => !!item.count)
                  .map((item, index) => (
                    <LineElement
                      key={index}
                      filling={item.filling}
                      color={item.color}
                      onMouseEnter={event => blackout(event.target, "focused")}
                      onMouseLeave={event => blackout(event.target, "unfocused")}
                    />
                  ))}
              </LineWrapper>
            </TooltipStyled>
            <Typography sx={{ textAlign: "end", textWrap: "nowrap" }}>{value}</Typography>
          </LineChartElement>
        );
      })}
    </LineChartWrapper>
  );
}
