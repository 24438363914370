// @flow
import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import {withModifiers} from "with-modifiers";

const Container = withModifiers({
  'z-index-full': () => `
    z-index: 101;
  `
})(
  modifier => styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 50px;
  box-sizing: border-box;
  background-color: ${rgba("#391717", 0.67)};
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  ${modifier};
  @media (max-width: 768px) {
    padding: 20px;
  }
`);

const Inner = styled.div`
  display: contents;
`;

export const ModalBackground = (props: { onClick?: () => mixed, children: React$Node, modifiers?: string }) => {
  return (
    <Container modifiers={props.modifiers} onClick={props.onClick}>
      <Inner onClick={e => e.stopPropagation()}>{props.children}</Inner>
    </Container>
  );
};
