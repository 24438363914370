// @flow
import * as R from "ramda";
import { useStoreMap } from "effector-react";
import type { TAppointment_id } from "../../domain/entities/Appointment";
import type { LayoutFieldValue } from "../../domain/entities/Appointment";
import type { Section } from "../../domain/entities/Section";
import type { TAppointmentFieldValuesStore } from "../../stores/appointmentFieldValues";
import { sectionIsEmpty } from "../../domain/services/field";

import { appointmentFieldValuesStore, fieldValuesPath } from "../../stores/appointmentFieldValues";

export const checkSectionIsEmpty = (
  store: TAppointmentFieldValuesStore,
  appointment: TAppointment_id,
  section: Section,
  savedFilledValues: { [id: number]: LayoutFieldValue }
) => {
  const appointmentFields = R.view(fieldValuesPath(appointment), store);
  if (!appointmentFields) return sectionIsEmpty(section, savedFilledValues);

  const storeSectionIsEmpty = sectionIsEmpty(section, appointmentFields);
  const savedSectionIsEmpty = sectionIsEmpty(
    section,
    R.omit(R.keys(appointmentFields), savedFilledValues)
  );
  return storeSectionIsEmpty && savedSectionIsEmpty;
};

export const IsEmptySectionProvider = (props: {
  appointment: TAppointment_id,
  section: Section,
  savedFilledValues: { [id: number]: LayoutFieldValue },
  children: (isEmpty: boolean) => React$Node
}) => {
  const { appointment, section, savedFilledValues, children } = props;

  const isEmpty = useStoreMap({
    store: appointmentFieldValuesStore,
    keys: [appointment, section],
    fn: (store, [appointment, section]) =>
      checkSectionIsEmpty(store, appointment, section, savedFilledValues)
  });

  return children(isEmpty);
};
