import React, { useState, useEffect } from "react";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import { ButtonRadioGroup } from "../form-elements/ButtonRadioGroup";
import { HospitalTable } from "../hospital-table/HospitalTable";
import { HospitalFormLayout } from "./HospitalFormLayout";
import styled from "styled-components";
import apiv2 from '../../apiv2';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 30px 0 30px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: white;
`;

export const HospitalPage = () => {
  const [hospital, setHospital] = useState(null);
  const [page, setPage] = useState("in_hospital");

  useEffect(() => {
    (async () => {
      const data = await apiv2.hospitalAdmissions.getHospitalAdmissions();
      setHospital(data);
    })();
  }, [token]);

  const token = useStore(tokenStore);

  const sections = hospital ? (
    <Wrapper>
      <HeaderContainer>
        <ButtonRadioGroup
          items={{
            in_hospital: {
              name: "Пациенты в стационаре"
            },
            released: {
              name: "Выписанные пациенты"
            }
          }}
          checked={page}
          name="hospital"
          shouldUncheck={false}
          onChange={value => setPage(value)}
        />
      </HeaderContainer>
      <HospitalTable data={hospital} page={page} />
    </Wrapper>
  ) : null;

  if (!token) {
    return null;
  }

  return <HospitalFormLayout title={null} sections={sections} />;
};
