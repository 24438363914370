import logoImage from '../../src/components/header/logo.svg';
import {applicationFeatureEnabled, FEATURE} from './applicationFeatures';

const createNotification = (data) => {
  if (!applicationFeatureEnabled(FEATURE.CHAT)) {
    return;
  }
  navigator.serviceWorker.ready.then((registration) => {
    registration.showNotification(data.title || "Новое сообщение", {
      body: data.body,
      data: data.data,
      icon: data.icon || logoImage
    })
  });
}

export default async (data) => {
  if (!applicationFeatureEnabled(FEATURE.CHAT) || window.ReactNativeWebView) {
    return;
  }
  if (!("Notification" in window)) {
    alert("Ваш браузер не поддерживает уведомления.");
  } else if (Notification.permission === "granted") {
    createNotification(data)
  } else if (Notification.permission !== "denied") {
    let permission = await Notification.requestPermission();
    if (permission === "granted") {
      createNotification(data)
    }
  }
}