import { observer } from "mobx-react";
import BonusPointingIcon from "../../../assets/icons/bonusPointing.inline.svg";
import React from "react";
import bonusSchemaStore from "../../../stores/bonusSchemaStore";
import {
  BonusPaper,
  FlexBlock,
  BonusTitleBold,
  FlexBlockGapMini,
  BonusText,
  BonusLevelTextColor, BonusSubtitle, BonusVerticalDivider, BonusCountText
} from "./bonusSchema.styled";
import styled from "styled-components";
import BonusUsersIcon from "../../../assets/icons/bonusUsersIcon.inline.svg";
import moment from "moment";
import {Box} from "@mui/material";
import BonusHelpIcon from "../../../assets/icons/bonusHelpIcon.inline.svg";
import MuiTooltip from "@mui/material/Tooltip";
import {declOfNum} from "../../../utils/arrays";

const BonusPointingIconShrink = styled(BonusPointingIcon)`
    flex-shrink: 0;
`;

const BonusPaperMonth = styled(BonusPaper)`
    gap: 12px;
    justify-content: space-between;
`;

const BonusBlock = styled.div`
    flex-direction: column;
    display: flex;
    gap: 4px;
`;


const CountAppointment = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
`;

export const MyAppointmentsMonth = observer(() => {

  const count = bonusSchemaStore.nextLevel ? bonusSchemaStore.nextLevel.conditions[0].min_count - bonusSchemaStore.bonusSchemaData.consultationsCount : 0;
  //  ToDo правильный расчет count-а сделать
  return (
    <>
      <BonusPaperMonth>
        <BonusBlock>
          <FlexBlock>
            <BonusTitleBold>Мои приемы в этом месяце</BonusTitleBold>
            <MuiTooltip
                componentsProps={{
                  tooltip: { sx: { background: "#ffffff!important" } }
                }}
                title={<span>С описанием можно ознакомиться <a href={"https://surl.li/fdjdiz"}>ЗДЕСЬ</a></span>}
            >
              <Box sx={{ display: "flex", cursor: "pointer" }}>
                <BonusHelpIcon />
              </Box>
            </MuiTooltip>
          </FlexBlock>
          {!!bonusSchemaStore.nextLevel && (
            <FlexBlockGapMini modifiers={'alignStart'}>
              <BonusPointingIconShrink />
              <BonusText>Проведите еще {count} {declOfNum(count, ['прием', 'приема', 'приемов'])} для достижения уровня <BonusLevelTextColor modifiers={bonusSchemaStore.nextLevel.name}>{bonusSchemaStore.nextLevel.title}</BonusLevelTextColor></BonusText>
            </FlexBlockGapMini>
          )}
        </BonusBlock>
        <FlexBlock modifiers='justifyContentSB'>
          <CountAppointment>
            <BonusSubtitle modifiers='gray'>
              Оплаченные
              <MuiTooltip
                  componentsProps={{
                    tooltip: { sx: { background: "#ffffff!important" } }
                  }}
                  style={{paddingLeft: 6}}
                  title={'без учета оплат с помощью депозитов и программ'}
              >
                <Box sx={{ display: "flex", cursor: "pointer" }}>
                  <BonusHelpIcon />
                </Box>
              </MuiTooltip>
            </BonusSubtitle>
            <BonusTitleBold modifiers='center'>
              {bonusSchemaStore.bonusSchemaData.schedules.paid}
            </BonusTitleBold>
          </CountAppointment>
          <BonusVerticalDivider />
          <CountAppointment>
            <BonusSubtitle modifiers='gray'>
              Записи
            </BonusSubtitle>
            <BonusTitleBold modifiers='center'>
              {bonusSchemaStore.bonusSchemaData.schedules.reserved}
            </BonusTitleBold>
          </CountAppointment>
          <BonusVerticalDivider />
          <CountAppointment>
            <BonusSubtitle modifiers='gray'>
              Открытые
            </BonusSubtitle>
            <BonusTitleBold modifiers='center'>
              {bonusSchemaStore.bonusSchemaData.schedules.opened}
            </BonusTitleBold>
          </CountAppointment>
        </FlexBlock>
      </BonusPaperMonth>
    </>
  )
})