import Dialog from "@mui/material/Dialog";
import patientsStore from "../../stores/patientsStore";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { DynamicForm } from "../common/dynamic-form/DynamicForm";
import { ModalPortal } from "../modal/ModalPortal";
import React, { useState } from "react";
import { observer } from "mobx-react";
import { useToast } from "../toast/useToast";


export const PatientSendReesModal = observer(() => {
  const defaultData = {
    row_count: 100,
    token: '',
    per_hour: 100,
  };
  const [dataSendRees, setDataSendRees] = useState(defaultData);
  const toastManager = useToast();

  const onCloseModal = () => {
    patientsStore.changeShowSendReesPopup(false);
    setDataSendRees(defaultData);
  }

  const fields = [
    {
      type: 'text',
      name: 'token',
      label: 'Токен из rees',
      xs: 12,
    },
    {
      type: 'number',
      name: 'row_count',
      label: 'Количество записей из выборки',
      xs: 12,
    },
    {
      type: 'number',
      name: 'per_hour',
      label: 'Количество отправок в час',
      xs: 12,
    },
  ]

  const submitHandler = async (data) => {
    if (!data.token && !data.row_count && !data.per_hour) {
      return;
    }
    const response = await patientsStore.sendRees(data);
    if (response) {
      toastManager.showToast(
          <div>
            Рассылка успешно запущена
          </div>,
          {
            duration: 5000
          }
      );
      onCloseModal();
    } else {
      toastManager.showToast(
        <div>
          Произошла ошибка при отправки рассылки
        </div>,
        {
          duration: 5000
        }
      );
    }
  }

  return (
    <ModalPortal>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        open={patientsStore.showSendReesPopup}
        onClose={(event, reason) => onCloseModal(reason)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Отправка рассылки через Rees</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => onCloseModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DynamicForm textButton="Отправить" data={dataSendRees} submitHandler={submitHandler}
                       fields={fields} hiddenButtonCancel={true} />
        </DialogContent>
      </Dialog>
    </ModalPortal>
  )
});