// @flow
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import moment from "moment";
import {
  Avatar,
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { Search as SearchIcon, Close as CloseIcon } from "@mui/icons-material";
import { useInfiniteQuery } from "@tanstack/react-query";
import appointmentHistoryStore, {
  ACTIVE_TABS,
  COLUMN_PARAMS,
  LIMIT
} from "../../stores/appointmentHistoryStore";
import { scrollbars } from "../chats/style-elements";
import { READABLE_DATE_FORMAT } from "../../utils/dates";
import { colors } from "../styleguide/colors";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { setVoximplantSupportData } from "../../utils/voximplantSupport";
import { getLastNameWithInitials } from "../../domain/services/doctor";

const Container = styled(Paper)(
  ({ height }) =>
    `
      ${!height ? `
        height: 100%;
      ` : `
        height: ${height};
        `
      }
      width: 950px;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 5px;
      box-sizing: border-box;
      @media (max-width: 768px) {
        width: auto;
      }
      .MuiTableContainer-root {
        ${scrollbars};
      }
    `
);

const TabsStyled = styled(Tabs)(
  () =>
    `
      min-height: 40px;
      .MuiButtonBase-root {
        min-height: 40px;
        padding: 10px 15px;
      }
    `
);

const CheckboxStyled = styled(FormControlLabel)(
  () =>
    `
     margin-right: 9px;
     .MuiTypography-root {
      font-size: .9rem;
     }
    `
);

const SearchButton = styled(IconButton)(
  () =>
    `
      margin-left: auto;
    `
);

const TextFieldStyled = styled(TextField)(
  () =>
    `
      .MuiInputBase-root {
        min-height: 40px;
      }
    `
);

const Dot = styled("div")(
  () =>
    `
      margin: 0 auto;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: ${colors.magenta};
    `
);

const CellText = styled(Typography)(
  ({ flex }) =>
    `
      ${flex ? `
        display: flex;
        flex-wrap: wrap;
        column-gap: 5px;
      ` : ``}
      font-size: 14px;
      text-wrap: balance;
      &.MuiTypography-caption {
        font-size: 12px;
        color: ${colors.gray500}
      }
      @media (max-width: 768px) {
        font-size: 12px;
        &.MuiTypography-caption {
         font-size: 10px;
        }
      }
    `
);

const Badge = styled("span")`
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid ${colors.magenta};
  border-radius: 99em;
  color: ${colors.magenta};
  font-size: 11px;
  line-height: 11px;
`;

const Header = observer(({ isDisabled, setSearchValue, isOnlyPatient, setIsOnlyPatient, isMobileDevices }) => {
  const isMobile = useMediaQuery("(max-width: 480px)");
  const [isShowSearchField, setIsShowSearchField] = useState(false);
  const handleChangeTab = (event, newValue) => appointmentHistoryStore.changeActiveTab(newValue);
  const handleChangeSearchField = flag => {
    setIsShowSearchField(flag);
    if (!flag) {
      setSearchValue("");
      setIsOnlyPatient(false);
    }
  };
  const handleOnChangeSearchValue = useCallback(
    _.debounce(value => setSearchValue(value), 500),
    []
  );

  return (
    <Stack
      spacing={1}
      direction={isMobile && isShowSearchField ? "column" : "row"}
      alignItems={isMobile && isShowSearchField ? "end" : "center"}
      padding={'20px 20px 0'}
      useFlexGap>
      {!isShowSearchField ? (
        <>
          {!isMobileDevices && <Typography fontSize="20px">История приемов</Typography>}
          <TabsStyled value={appointmentHistoryStore.activeTab} onChange={handleChangeTab}>
            <Tab value={ACTIVE_TABS.ME} label="Мои" disabled={isDisabled} />
            <Divider orientation="vertical" flexItem variant="middle" />
            <Tab value={ACTIVE_TABS.ALL} label="Все" disabled={isDisabled} />
          </TabsStyled>
          <SearchButton onClick={() => handleChangeSearchField(true)}>
            <SearchIcon />
          </SearchButton>
        </>
      ) : (
        <>
          <TextFieldStyled
            autoFocus
            fullWidth
            onChange={event => handleOnChangeSearchValue(event.target.value)}
            type="text"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!isMobile && appointmentHistoryStore.activeTab === ACTIVE_TABS.ALL && (
                    <>
                      <CheckboxStyled
                        onChange={() => setIsOnlyPatient(!isOnlyPatient)}
                        control={<Checkbox size="small" />}
                        label="Только пациенты"
                      />
                      <Divider orientation="vertical" sx={{ height: 25, margin: 0.5 }} />
                    </>
                  )}
                  <IconButton size="small" onClick={() => handleChangeSearchField(false)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {isMobile && appointmentHistoryStore.activeTab === ACTIVE_TABS.ALL && (
            <CheckboxStyled
              onChange={() => setIsOnlyPatient(!isOnlyPatient)}
              control={<Checkbox size="small" />}
              label="Только пациенты"
            />
          )}
        </>
      )}
    </Stack>
  );
});

export const AppointmentHistoryNew = observer(() => {
  const tableContainerRef = useRef(null);
  const tableRef = useRef(null);
  const rowVirtualizerInstanceRef = useRef(null);
  const isMobileDevices = useMediaQuery("(max-width: 768px)");
  const isDesktopDevice = useMediaQuery("(max-width: 1200px)");
  const [containerHeight, setContainerHeight] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [isOnlyPatient, setIsOnlyPatient] = useState(false);
  const [columnOrder, setColumnOrder] = useState(COLUMN_PARAMS[appointmentHistoryStore.activeTab]);

  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery({
    queryKey: [appointmentHistoryStore.activeTab, searchValue, isOnlyPatient],
    queryFn: async ({ pageParam }) => {
      return await appointmentHistoryStore.getAppointments({
        searchValue,
        isOnlyPatient,
        limit: LIMIT,
        offset: pageParam * LIMIT
      });
    },
    initialPageParam: 0,
    getNextPageParam: (_lastGroup, groups) => groups.length,
    refetchOnWindowFocus: false
  });

  const flatData = useMemo(() => data?.pages.flatMap(page => page) ?? [], [
    data,
    appointmentHistoryStore.activeTab
  ]);

  const fetchMoreOnBottomReached = useCallback(
    containerRefElement => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (
          scrollHeight - scrollTop - clientHeight < 200 &&
          !isFetching &&
          !!_.last(data?.pages)?.length
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, flatData]
  );

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: "dateTime",
          header: "Время",
          size: isMobileDevices ? 60 : 115,
          Cell: ({ row }) => (
            <CellText variant="caption">
              {row.original.dateTime}
            </CellText>
          )
        },
        {
          accessorKey: "doctor",
          header: "Врач",
          size: isMobileDevices ? 70 : 170,
          Cell: ({ row }) => (
            <Stack direction="row" spacing={1} alignItems="center">
              {!isMobileDevices && (
                <Avatar
                  sx={{ width: 34, height: 34, fontSize: ".7rem", textTransform: "uppercase" }}
                  src={row.original.doctorPhoto}>
                  {row.original.doctor?.last_name?.slice(0, 1) + row.original.doctor?.first_name?.slice(0, 1)}
                </Avatar>
              )}
              <CellText sx={{ textWrap: "balance" }}>
                {getLastNameWithInitials(row.original.doctor)}
              </CellText>
            </Stack>
          )
        },
        {
          accessorKey: "patient",
          header: "Пациент",
          size: isMobileDevices ? 70 : 310,
          Cell: ({ row }) => (
            <Tooltip title={moment(row.original.patient.birthdate).format(READABLE_DATE_FORMAT)} placement="right">
              <CellText flex={isMobileDevices} sx={{ textWrap: "balance" }}>
                {row.original.patient?.last_name || ""} {row.original.patient?.first_name || ""} {row.original.patient?.middle_name || ""}
                {!!row.original.patientBadge && <Badge sx={{marginLeft: isMobileDevices ? '0' : '4px'}}>{row.original.patientBadge}</Badge>}
              </CellText>
            </Tooltip>
          )
        },
        {
          accessorKey: "sample",
          header: "Шаблон",
          size: isMobileDevices ? 80 : 260,
          Cell: ({ row }) => <CellText>{row.original.sample}</CellText>
        },
        {
          accessorKey: "inProgress",
          size: 10,
          Cell: ({ row }) => row.original.inProgress && <Dot />
        }
      ].filter(column =>
        COLUMN_PARAMS[appointmentHistoryStore.activeTab].includes(column.accessorKey)
      ),
    [appointmentHistoryStore.activeTab, isMobileDevices]
  );

  const table = useMaterialReactTable({
    columns,
    data: flatData,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableColumnActions: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableSorting: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        height: "100%"
      }
    },
    enableRowVirtualization: true,
    rowVirtualizerInstanceRef,
    rowVirtualizerOptions: { overscan: 5 },
    muiTableContainerProps: () => ({
      ref: tableRef,
      sx: {
        height: "100%"
      },
      onScroll: event => fetchMoreOnBottomReached(event.target)
    }),
    muiTableBodyRowProps: ({ row }) => ({
      component: "a",
      href: `/appointment/${row.original.id}`,
      sx: {
        textDecoration: "none",
        ":hover .MuiTypography-root": {
          color: colors.magenta
        }
      }
    }),
    muiTableHeadProps: {
      sx: {
        "th": {
          paddingRight: 0
        },
        "th:last-child": {
          paddingRight: '15px'
        }
      }
    },
    muiTableBodyProps: {
      sx: {
        "& a:hover > td::after": {
          backgroundColor: "white"
        },
        "td": {
          paddingRight: 0
        },
        "td:last-child": {
          paddingRight: '15px'
        },
      }
    },
    layoutMode: "grid",
    state: {
      isLoading,
      columnOrder
    },
    localization: { ...MRT_Localization_RU, noRecordsToDisplay: "У вас еще не было пациентов" },
    isMultiSortEvent: () => true
  });

  useEffect(() => {
    fetchMoreOnBottomReached(tableRef.current);
  }, [fetchMoreOnBottomReached]);

  useEffect(() => {
    appointmentHistoryStore.changeActiveTab(ACTIVE_TABS.ME);
    handleChangeTableHeightOnResize();
  }, []);

  useEffect(() => {
    setColumnOrder(COLUMN_PARAMS[appointmentHistoryStore.activeTab]);
  }, [appointmentHistoryStore.activeTab]);

  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
  }, [appointmentHistoryStore.activeTab, searchValue, isOnlyPatient]);

  useEffect(() => {
    const voximplantData = {
      "Appointment history tab":
        appointmentHistoryStore.activeTab === ACTIVE_TABS.ME ? "Мои" : "Все",
      "User search value": searchValue || null
    };
    setVoximplantSupportData(
      {
        client_email: voximplantData,
        ...window.VoxKitWidgetSettings.client_data
      },
      false,
      true
    );
    return () => {
      setVoximplantSupportData(
        {
          client_email: {}
        },
        false,
        true
      );
    };
  }, [appointmentHistoryStore.activeTab, searchValue]);


  const handleChangeTableHeightOnResize = () => {
    const bottomIndent = isMobileDevices ? 50 : 0;
    const interpolateIndentTop = isDesktopDevice ?
      tableContainerRef?.current?.getBoundingClientRect().top :
      tableRef?.current?.getBoundingClientRect().top;
    setContainerHeight(window.innerHeight - interpolateIndentTop - bottomIndent);
  };

  useEffect(() => {
    window.addEventListener("resize", handleChangeTableHeightOnResize);
    return () => window.removeEventListener("resize", handleChangeTableHeightOnResize);
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    handleChangeTableHeightOnResize();
  }, [isLoading]);

  return (
    <Container elevation={2} ref={tableContainerRef} height={containerHeight ? `${containerHeight}px` : ""}>
      <Header
        isDisabled={isFetching || isLoading}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isOnlyPatient={isOnlyPatient}
        setIsOnlyPatient={setIsOnlyPatient}
        isMobileDevices={isMobileDevices}
      />
      <MaterialReactTable table={table} />
      <Box sx={{ height: "4px" }}>{isFetching && <LinearProgress />}</Box>
    </Container>
  );
});
