// @flow
import React from "react";
import styled from "styled-components";
import { PSmall } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  width: 73px;
  height: 64px;
  border-radius: 3px;
  box-shadow: 0 2px 17px 0 rgba(91, 91, 91, 0.26);
  background-color: ${colors.white};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled(PSmall)`
  border: none;
  font-family: inherit;
  outline: none;

  width: 100%;
  text-align: center;
  padding: 0;
`;

export const DosageInput = (props: {
  inputRef?: React$ElementRef<any>,
  value: string,
  onChange: string => mixed,
  onBlur?: () => mixed
}) => {
  const { inputRef, value, onChange, onBlur } = props;

  return (
    <Container>
      <Input
        ref={inputRef}
        type="text"
        as="input"
        value={value}
        onBlur={onBlur}
        onChange={e => onChange(e.target.value)}
      />
    </Container>
  );
};
