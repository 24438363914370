// @flow
import styled from "styled-components";
import { colors } from "../styleguide/colors";

export const SelectContainer = styled.div`
  width: 528px;
  max-height: 240px;
  border-radius: 3px;
  box-shadow: 0 2px 17px 0 rgba(91, 91, 91, 0.26);
  background-color: ${colors.white};
  overflow: auto;

  @media (max-width: 768px) {
    max-width: 528px;
    width: 100%;
  }
`;
