// @flow
import * as React from "react";
import styled from "styled-components";
import trashIcon from "./icons/new-trash-with-lines.inline.svg";
import { colors } from "../styleguide/colors";

const TrashIcon = styled(trashIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  
  &:hover {
    fill: ${colors.magenta};
  }
`;


export const DeleteButtonIcon = (props: {
  onClick?: () => mixed,
}) => (
  <TrashIcon onClick={props.onClick} />
);