import React, { useCallback, useEffect, useState } from "react";

import moment from "moment";

import * as S from "./styled/Calendar.styled";

const week = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];

export const Calendar = ({
  monthStartDate,
  selectedStartDate,
  selectedEndDate,
  mouseOverDate,
  onSetSelectedDate,
  onMouseOverDate,
  prev,
  next,
  onPrev,
  onNext,
  range
}) => {
  const [currentMonthStartDate, setCurrentMonthStartDate] = useState(
    monthStartDate ||
      moment()
        .startOf("M")
        .toDate()
  );

  const handleNext = useCallback(() => {
    setCurrentMonthStartDate(
      moment(currentMonthStartDate)
        .add(1, "M")
        .toDate()
    );

    onNext?.();
  }, [currentMonthStartDate, onNext]);

  const handlePrev = useCallback(() => {
    setCurrentMonthStartDate(
      moment(currentMonthStartDate)
        .subtract(1, "M")
        .toDate()
    );

    onPrev?.();
  }, [currentMonthStartDate, onPrev]);

  useEffect(() => {
    if (monthStartDate) {
      setCurrentMonthStartDate(monthStartDate);
    }
  }, [monthStartDate]);

  return (
    <S.Container>
      <S.Header>
        {prev && <S.LeftArrow onClick={handlePrev} />}
        <S.Month>{moment(currentMonthStartDate).format("MMMM YYYY")}</S.Month>
        {next && <S.RightArrow onClick={handleNext} />}
      </S.Header>
      <S.Week>
        {week.map(day => (
          <S.DayOfWeek key={day}>{day}</S.DayOfWeek>
        ))}
      </S.Week>
      <S.Days>
        {new Array(
          moment(currentMonthStartDate)
            .endOf("month")
            .date()
        )
          .fill(null)
          .map((_, i) => moment(currentMonthStartDate).add(i, "d"))
          .map((day, i) => {
            const today = moment();
            const momentNumberDayStartOfWeek = moment(currentMonthStartDate)
              .startOf("M")
              .day();
            const startOfWeek =
              momentNumberDayStartOfWeek === 0 ? 6 : momentNumberDayStartOfWeek - 1; // At moment Sunday, 0 is given instead of 6

            const disabled = day.isBefore(today, "d");
            const offset = i === 0 && startOfWeek - 1 >= 0 ? S.itemWidth * startOfWeek : 0;
            const active =
              (selectedStartDate && day.isSame(selectedStartDate, "d")) ||
              (selectedEndDate && day.isSame(selectedEndDate, "d"));

            const inSelectedRange =
              selectedStartDate &&
              day.isAfter(selectedStartDate, "d") &&
              selectedEndDate &&
              day.isBefore(selectedEndDate, "d");

            const inLeftMouseOverRange =
              mouseOverDate &&
              selectedStartDate &&
              !selectedEndDate &&
              !disabled &&
              day.isSameOrAfter(mouseOverDate) &&
              day.isBefore(selectedStartDate, "d");

            const inRightMouseOverRange =
              mouseOverDate &&
              selectedStartDate &&
              !selectedEndDate &&
              !disabled &&
              day.isSameOrBefore(mouseOverDate) &&
              day.isAfter(selectedStartDate, "d");

            return (
              <S.Day
                key={day}
                range={range}
                active={active}
                offset={offset}
                disabled={disabled}
                today={day.isSame(today, "d")}
                inRange={inSelectedRange || inLeftMouseOverRange || inRightMouseOverRange}
                onMouseOver={() => {
                  if (!moment(mouseOverDate).isSame(day) && onMouseOverDate) {
                    onMouseOverDate(day.toDate());
                  }
                }}
                onClick={() => {
                  if (!disabled) {
                    onSetSelectedDate(day.toDate());
                  }
                }}>
                {day.date()}
              </S.Day>
            );
          })}
      </S.Days>
    </S.Container>
  );
};
