// @flow
import React from "react";
import styled from "styled-components";
import { HeadingH4 } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

const Container = styled.div`
  & + & {
    padding-top: 4px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  margin-bottom: 15px;
  border-bottom: solid 1px ${colors.transparentGray};
  page-break-inside: avoid;
  page-break-after: avoid;
`;

export const PrintableSection = (props: { label: string, children: React$Node }) => {
  return (
    <Container>
      <HeaderContainer>
        <HeadingH4>{props.label}</HeadingH4>
      </HeaderContainer>
      {props.children}
    </Container>
  );
};
