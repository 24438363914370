import { makeAutoObservable } from "mobx";
import apiv2 from '../../src/apiv2'
import { useToast } from "../components/toast/useToast";
import React from "react";
import { getLastNameWithInitials } from "../domain/services/doctor";
import { appendOrUpdateData } from "../components/general-schedule-page/fullScheduleStore";

const toastManager = useToast();

export const groupedDoctorsBySpecialty = (doctors) => {
  const doctorsList = [];
  doctors.forEach((item) => {
    if (item.specialities__name.length && item.specialities__name[0] !== null) {
      item.fullName = `${item.first_name} ${item.last_name} ${item.middle_name}`;
      item.specialities__name.forEach((spec) => {
        doctorsList.push({...item, specialitiesGroup: spec});
      })
    }
  });
  doctorsList.sort((a, b) => -b.specialitiesGroup.localeCompare(a.specialitiesGroup));

  return doctorsList;
}

class DoctorsStore {
  doctors = [];
  favouriteDoctors = [];
  salaryData = [];
  salaryFilters = {};
  showNotificationModal = false;
  showNotificationPinCodeModal = false;
  isInitialized = false;
  loading = false;
  pinCode = null;
  errorPin = '';
  accessIsAllowed = false;
  showLevelBonusPopup = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchAllDoctors() {
    try {
      const response = await apiv2.doctors.getAll();
      this.doctors = response;
    } catch (e) {
      console.log(e);
    }
  }

  async fetchAllDoctorsList({ filtersParams = {}, additionalParamsValues = [], annotateMtmParams = {}, meta = null }) {
    try {
      return await apiv2.doctors.getListDoctors({
        filter_params: filtersParams,
        values_params: ['first_name', 'last_name', 'middle_name', 'id', ...additionalParamsValues],
        annotate_mtm_params: annotateMtmParams,
        meta: meta || {
          per_page: 999999,
          current_page: 1,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchAllDoctorsOrganizationList(params) {
    try {
      return await apiv2.doctors.getListDoctorsOrganization(params);
    } catch (e) {
      console.log(e);
    }
  }

  async setSalaryFilters(filters) {
    this.salaryFilters = filters;
    this.fetchSalary();
  }

  async fetchSalary() {
    try {
      this.loading = true;
      let response = await apiv2.doctors.getSalary(this.salaryFilters);
      const data = [];
      response.forEach((item) => {
        item.salary.forEach((el) => {
          el['Организация'] = item.organization.name;
          if (el['Услуга'] && el['Начислено']) {
            el['Начислено после вычета НДФЛ'] = el['Начислено'] * 0.87;
            data.push(el);
          } else if (!el['Услуга'] && !el['СтоимостьУслуг'] && !el['ПризнакДоговораЭКО'] && el['Начислено'] && el['ВидНачисления']) {
            el['Услуга'] = el['ВидНачисления'];
            el['Начислено после вычета НДФЛ'] = el['Начислено'] * 0.87;
            data.push(el);
          }
        })
      })
      this.salaryData = data;

    } catch (e) {
      console.log(e);
      this.salaryData = [];
    }
    this.isInitialized = true;
    this.loading = false;
  }

  async sendTestPushNotification() {
    await apiv2.doctors.sendTestPushNotification();
    this.showNotificationModal = true;
  }

  changeShowNotificationModal(value) {
    this.showNotificationModal = value;
  }

  changeShowNotificationPinCodeModal(value) {
    this.showNotificationPinCodeModal = value;
  }

  changePinCode(value) {
    this.pinCode = value;
  }

  changeAccessIsAllowed(value) {
    this.accessIsAllowed = value;
  }

  changeShowLevelBonusPopup(value) {
    this.showLevelBonusPopup = value;
  }

  async sendPinCodeNotification() {
    await apiv2.doctors.sendPinCodeNotification();
  }

  async checkPinCode() {
    try {
      await apiv2.doctors.checkPinCode(this.pinCode);
      this.changeAccessIsAllowed(true);
      this.showNotificationPinCodeModal = false;
    } catch (e) {
      if (e.result.error === 'wrong pin-code') {
        this.errorPin = "Неверный пин-код"
      }
      console.log(e);
    }
  }

  async getAnalyticsDoctorsInChat() {
    try {
      return await apiv2.doctors.analyticsDoctorsInChat();
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async fetchFavouriteDoctorsList() {
    try {
      const response = await apiv2.doctors.getFavouriteDoctors();
      this.updateFavouriteDoctors(response || []);
    } catch (e) {
      console.error(e);
    }
  }

  updateFavouriteDoctors(doctors) {
    this.favouriteDoctors = doctors;
  }

  async removeDoctorFromFavourites({ doctor, isForce }) {
    try {
      await apiv2.doctors.removeDoctorFromFavourites(doctor.id);
      this.updateFavouriteDoctors(this.favouriteDoctors.filter((item) => item.id !== doctor.id));
      if (isForce) {
        appendOrUpdateData({});
      }
      toastManager.showToast(
        <div>Врач {getLastNameWithInitials(doctor)} удален из избранных.</div>,
        {
          duration: 10000
        }
      );
    } catch (e) {
      toastManager.showToast(
        <div>При удалении врача из избранных, что-то пошло не так.<br/>Попробуйте позже.</div>,
        {
          duration: 10000
        }
      );
    }
  }

  async addDoctorToFavourites({ doctor, isForce }) {
    try {
      await apiv2.doctors.addDoctorToFavourites(doctor.id);
      this.updateFavouriteDoctors([...this.favouriteDoctors, doctor]);
      if (isForce) {
        appendOrUpdateData({});
      }
      toastManager.showToast(
        <div>Врач {getLastNameWithInitials(doctor)} добавлен в избранные.</div>,
        {
          duration: 10000
        }
      );
    } catch (e) {
      toastManager.showToast(
        <div>При добавлении врача в избранное, что-то пошло не так.<br/>Попробуйте позже.</div>,
        {
          duration: 10000
        }
      );
    }
  }
}

export default new DoctorsStore();
