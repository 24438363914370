import styled, { css } from "styled-components";
import MaskedInput from "react-text-mask";

import { colors } from "../../styleguide/colors";

export const Input = styled(MaskedInput)`
  width: 85px;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  line-height: 23px;
  font-family: inherit;

  &::placeholder {
    color: ${colors.silver};
  }

  ${({ error }) =>
    error &&
    css`
      color: ${colors.pomegranate};
    `}
`;
