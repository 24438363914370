import styled from "styled-components";

import { colors } from "../styleguide/colors";

export const Container = styled.div``;

export const Table = styled.div`
  width: 100%;
  margin-top: 0;
  padding-top: 0;
  border-radius: 0;
  padding-top: 0;
  padding-right: 0;
  margin-bottom: 10px;
  overflow: auto;
  // overflow: ${p => (p.inEditMode ? "hidden" : "auto")};
  background: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.045), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.045), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 13px 100%, 13px 100%;
  background-attachment: local, local, scroll, scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(184 184 184 / 50%);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  & td {
    height: 44px;
    padding: 0 12px;
    vertical-align: middle;
    border-bottom: 1px solid rgb(68, 68, 68, 0.1);
    border-right: 1px solid rgb(68, 68, 68, 0.1);
    overflow: hidden;
    word-break: keep-all;
    white-space: nowrap;
    text-align: left;
    box-sizing: border-box;
    user-select: none;
    font-size: 14px;

    &:first-child {
      border-left: none;
      cursor: default;
    }

    &:last-child {
      border-right: none;
    }

    & span {
      color: ${colors.darkBlack}
    }
  }
`;

export const ColumnIndicator = styled.th`
  width: 100px;
  border: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid #c6007f;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
  font-size: 14px;
  padding: 0 12px;
  height: 44px;
  font-weight: 500;
  vertical-align: middle;
  white-space: break-spaces;
  line-height: 1.35;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export const NoResults = styled.div`
  font-size: 22px;
  text-align: center;
  padding: 20px;
  color: ${colors.darkGray};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
