import styled from "styled-components";

import { colors } from "../../../styleguide/colors";

import { headerHeight } from "./SidebarTree.styled";

export const itemHeight = 72;

export const Root = styled.div`
  height: calc(100% - ${headerHeight}px);
  overflow: hidden;
`;

export const Item = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 12px 24px;
  height: ${itemHeight}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Caption = styled.div`
  width: 307px;
  height: 18px;
  border-radius: 4px;
  background: ${colors.gray050};
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: ${colors.gray050};
`;
