// @flow
import React, { useEffect, useMemo } from "react";
import surveyCorporatePortalResultStore from "../../stores/surveyCorporatePortalResultStore";
import { MaterialTable } from "../common/MaterialTable";
import { useForm } from "react-hook-form";
import { FormInputDate } from "../common/dynamic-form/fields/FormInputDate";
import { Grid, Paper } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import { SERVER_DATE_TIME_FORMAT } from "../../utils/dates";
import styled from "styled-components";
import { observer } from "mobx-react";
import organizationsStore from "../../stores/organizationsStore";
import {
  FEATURE, useApplicationFeatureValue
} from "../../utils/applicationFeatures";
import { trimLongTextValueField } from "../../utils/tableUtils";
import useReactRouter from "use-react-router";
import surveyResultStore from "../../stores/surveyResultStore";

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const SurveyCorporatePortalPage = observer(() => {
  const {
    match: { params },
    history
  } = useReactRouter();

  const SURVEY_CORPORATE_PORTAL_RESULT = useApplicationFeatureValue(FEATURE.SURVEY_CORPORATE_PORTAL_RESULT);
  const date_end = moment().set({ hour: 23, minute: 59, second: 59 }).format(SERVER_DATE_TIME_FORMAT);
  const date_start = moment().set({ hour: 0, minute: 0, second: 0 }).set('month', moment().get('month') - 1).format(SERVER_DATE_TIME_FORMAT);

  const defaultValuesDates = {
    date_start,
    date_end
  }

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
    })()
  }, []);

  const methods = useForm({
    defaultValues: defaultValuesDates,
  });
  const { handleSubmit, control, setValue } = methods;

  const afterInitializeParams = (callback) => {
    surveyCorporatePortalResultStore.setAnnotateMtmParams({
      "user__doctor__organizations": "user__doctor__organizations__name",
      "user__doctor__specialities": "user__doctor__specialities__name",
    })
    surveyCorporatePortalResultStore.setAdditionalFilterValues({survey_id: SURVEY_CORPORATE_PORTAL_RESULT,
      is_finished: true});
    if (!surveyCorporatePortalResultStore.globalFiltersTable.created_at__lte){
      surveyCorporatePortalResultStore.setGlobalFiltersTable({
        created_at__gte: date_start,
        created_at__lte: date_end,
      }, false)
    } else {
      setValue('date_start', surveyCorporatePortalResultStore.globalFiltersTable.created_at__gte)
      setValue('date_end', surveyCorporatePortalResultStore.globalFiltersTable.created_at__lte)
    }
    if (!surveyCorporatePortalResultStore.sortingTable.includes('-created_at')) {
      surveyCorporatePortalResultStore.setSortingTable([...surveyCorporatePortalResultStore.sortingTable, '-created_at'])
    }
    callback();
  }

  const onSubmit = (data) => {
    const filters = {
      created_at__gte: data.date_start,
      created_at__lte: data.date_end,
    };

    surveyCorporatePortalResultStore.setGlobalFiltersTable(filters);
  }

  const additionalFilters = (
    <Paper style={{
      padding: '20px'
    }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid alignItems={"center"} container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker="dateTime" label="Дата от"  name="date_start" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker="dateTime" label="до " name="date_end" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button type={"submit"} size={"large"} variant="contained" color={"success"}>
              Применить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'fullname_doctor', //normal accessorKey
        header: 'ФИО',
        filterFn: 'icontains',
        size: 350,
      },
      {
        accessorKey: 'status',
        header: 'Статус',
        filterFn: 'in',
        filterSelectOptions: surveyCorporatePortalResultStore.statusResult,
        filterVariant: 'multi-select',
        Cell: ({ cell }) =>
          surveyCorporatePortalResultStore.statusResult.find((options) => options.value === cell.getValue())?.label,
      },
      {
        accessorKey: 'user__doctor__organizations', //normal accessorKey
        additionalFields: ['user__doctor__organizations__name'],
        header: 'Организации',
        size: 350,
        filterFn: 'in',
        filterVariant: 'multi-select',
        filterSelectOptions: organizationsStore.organizations.map((item) => ({value: item.id.toString(), label: item.name})),
        Cell: ({ row }) => trimLongTextValueField(row.original.user__doctor__organizations__name?.join(', ') || '')
      },
      {
        accessorKey: 'user__doctor__specialities__name', //normal accessorKey
        header: 'Специализации',
        filterFn: 'icontains',
        size: 350,
        Cell: ({ row }) => trimLongTextValueField(row.original.user__doctor__specialities__name?.join(', ') || '')
      },
    ],
    [organizationsStore.organizations],
  );

  const editClick = (row) => {
    surveyCorporatePortalResultStore.changeOldParams({});
    history.push(`/corporate-portal-statistics/${row.id}`);
  }

  return (
    <Container>
      {!!SURVEY_CORPORATE_PORTAL_RESULT && (
        <MaterialTable editClick={editClick} localStorageKeyName={'review-statistics-survey-corporate-portal'} isShowTableData={true} isDisableSavingPresets={true}  disableHeightResizing={true} initialState={{
          showColumnFilters: true,
        }} additionalFilters={additionalFilters} afterInitializeParams={afterInitializeParams} actions={['edit']}
                       store={surveyCorporatePortalResultStore} columns={columns} />
      )}
    </Container>
  );
});
