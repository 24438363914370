import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Organizations {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getOrganizations() {
    return this.apiService.get(`organizations`, {}, {}, true, '', true);
  }

  getOrganization(organizationId) {
    return this.apiService.get(`organizations/${organizationId}`, {}, {}, true, '', true);
  }

  getAllOrganizations() {
    return this.apiService.get(`all_organizations`, {});
  }
}

export default new Organizations();
