// @flow
import React from "react";

import { TransitionGroup } from "react-transition-group";
import transition from "styled-transition-group";

const Fade = transition.div`
    position: relative;
    z-index: 101;
    &:enter {
        opacity: 0.01;
    }

    &:enter-active {
        opacity: 1;
        transition: opacity 500ms ease-in;
    }

    &:exit {
        opacity: 1;
    }

    &:exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }
`;

export const ModalFade = (props: { children: React$Node }) => (
  <TransitionGroup>{props.children && <Fade timeout={500}>{props.children}</Fade>}</TransitionGroup>
);
