// @flow
import React, { useState, useCallback, useEffect, useMemo } from "react";
import useReactRouter from "use-react-router";
import styled from "styled-components";
import { withModifiers } from "with-modifiers";
import { useStore } from "effector-react";
import { omit } from "lodash";

import type { AppointmentFolder } from "../../domain/entities/Appointment";
import { colors } from "../styleguide/colors";
import { currentUser } from "../../stores/auth";
import { PSidebar } from "../styleguide/typography";
import folderIcon from "../buttons/icons/folder.svg";
import arrowUpIcon from "../buttons/icons/arrow-up.svg";
import { AsideAppointmentPatient } from "./AsideAppointmentPatient";
import { Link } from "react-router-dom";

const Container = withModifiers({
  opened: () => `
        background-color: rgba(255, 245, 241, 0.7);
        border: none;
        border-top: 1px solid rgba(255, 245, 241, 0.7);
        & + & {
            border-top: 1px solid rgba(255, 245, 241, 0.7);
        }
        :before {
            opacity: 1;
        }
        :hover {
          background-color: rgba(255, 245, 241, 0.7) !important;
        }
    `
})(
  modifier => styled(Link)`
    width: 100%;
    padding: 20px 28px 20px 48px;
    box-sizing: border-box;
    border: none;
    border-top: 1px solid ${colors.transparentGray};
    background: url(${folderIcon});
    background-repeat: no-repeat;
    background-position: 24px center;
    background-color: ${colors.white};
    text-align: left;

    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    transition: all 0.15s ease-in-out;

    :first-child {
      border-top: none;
    }

    :before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: ${colors.magenta};

      position: absolute;
      top: 0;
      left: 0;

      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }

    ${PSidebar} {
      transition: all 0.15s ease-in-out;
    }

    ${modifier};

    :hover {
      background-color: rgba(216, 216, 216, 0.15);

      ${PSidebar} {
        color: ${colors.magenta};
      }
      :before {
        opacity: 1;
      }
    }
  `
);

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CountContainer = styled.div`
  margin-right: 7px;
  color: ${colors.gray700};
  font-size: 15px;
  line-height: 19px;
`;

const ArrowContainer = styled.div`
  width: 16px;
  height: 16px;
  background: url(${arrowUpIcon});
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(${props => (props.isOpened ? 0 : "180deg")});
  transition: transform 0.3s ease-in-out;
`;

const AsideAppointmentsContainer = styled.div`
  width: 100%;
  max-height: ${props => (props.isOpened ? `${props.count * 120}px` : 0)};
  opacity: ${props => (props.isOpened ? 1 : 0.2)};
  border-top: 1px solid ${colors.transparentGray};
  /* transition: all 0.3s ease-in-out; */
  overflow: hidden;
`;

export const AsideAppointmentFolder = (props: AppointmentFolder) => {
  const { name, appointments, questionnaire } = props;
  const user = useStore(currentUser);

  const {
    match: { params }
  } = useReactRouter();

  const appointmentId = parseInt(params.appointment_id);
  const folderId = parseInt(params.id);
  const folderOpened = folderId === props.id;
  const folderData = omit(props, "appointments");

  const currentFolderAppointmentOpened = React.useCallback(() => {
    return appointments && appointments.some(appointment => appointment.id === appointmentId);
  }, [appointments, appointmentId]);

  const [isOpened, setOpened] = useState(folderOpened || currentFolderAppointmentOpened());

  useEffect(() => {
    if (folderOpened || currentFolderAppointmentOpened()) setOpened(true);
  }, [folderOpened, currentFolderAppointmentOpened]);

  const handleOpen = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setOpened(!isOpened);
    },
    [isOpened]
  );

  const link = useMemo(() => {
    if (!questionnaire.q_layout) {
      return undefined;
    }
    const isCurrentDoctor =
      user && user.role === "doctor" && user.doctor && user?.doctor?.id === props.doctor;
    if (!questionnaire.is_saved && isCurrentDoctor)
      return `/appointment/folder/${props.id}/questionnaire`;
    return `/appointment/folder/${props.id}`;
  }, [questionnaire.is_saved, props.doctor, user, props.id]);

  return (
    <>
      <Container to={link} modifiers={[folderOpened && "opened"]}>
        <PSidebar>{name}</PSidebar>
        <CountWrapper onClick={handleOpen}>
          <CountContainer>{appointments.length}</CountContainer>
          <ArrowContainer isOpened={isOpened} />
        </CountWrapper>
      </Container>
      <AsideAppointmentsContainer isOpened={isOpened} count={appointments && appointments.length}>
        {appointments &&
          appointments.map(innerAppointment => (
            <AsideAppointmentPatient
              key={innerAppointment.id}
              appointment={innerAppointment}
              folder={folderData}
              inFolder
            />
          ))}
      </AsideAppointmentsContainer>
    </>
  );
};
