// @flow
import React, { useEffect } from "react";
import { CorporateContent } from "../components/CorporateContent";
import { CorporateFileManager } from "../components/CorporateFileManager";
import corporateDocumentsStore from "../stores/corporateDocumentsStore";
import { observer } from "mobx-react";
import { BackdropLoader } from "../../components/loader/Loader";
import { CONTROL_PANEL_VIEW, CorporateControlPanel } from "../components/CorporateControlPanel";
import useReactRouter from "use-react-router";

export const CorporateDocumentsPage = observer(() => {
  const {
    match: { params },
    history,
  } = useReactRouter();


  useEffect(() => {
    if (corporateDocumentsStore.isInitialized && !params.id) {
      corporateDocumentsStore.resetState();
    }
    if (params.id) {
      corporateDocumentsStore.setCurrentFolder({id: parseInt(params.id), name: ''});
    } else {
      corporateDocumentsStore.setCurrentFolder({id: 1, name: 'Все документы'});
    }
  }, [params.id]);

  const goBackByFolderId = (id) => {
    corporateDocumentsStore.goBackByFolderId(id);
    history.push(`/corporate-portal/documents/${id}`);
  }

  return (
    <CorporateContent>
      <BackdropLoader isOpen={corporateDocumentsStore.isLoading} />
      <CorporateControlPanel
        view={CONTROL_PANEL_VIEW.BREADCRUMBS}
        isLoading={!!corporateDocumentsStore.historyFolders.length}
        breadcrumbsParams={{
          items: corporateDocumentsStore.historyFolders,
          handleBack: goBackByFolderId
        }}
        searchField={{
          label: "Название документа",
          value: corporateDocumentsStore.searchValue,
          onChange: corporateDocumentsStore.changeSearchValue
        }}
      />
      <CorporateFileManager />
    </CorporateContent>
  );
});
