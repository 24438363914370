import styled from "styled-components";

import { colors } from "../../styleguide/colors";

export const SelectOption = styled.div`
  padding: 6.5px 8px;
  color: ${colors.black};
  background: ${colors.concrete};
  border-radius: 4px;
  font-size: 14px;
`;

export const styles = {
  menu: provided => ({
    ...provided,
    minWidth: "60px",
    width: "100%",
    borderRadius: 0,
    boxShadow: "0px 2px 17px rgb(91 91 91 / 26%)",
    color: colors.black,
    marginTop: "1px",
    fontSize: 15,
    maxHeight: "220px",
    overflow: "auto"
  }),
  menuList: () => ({
    paddingBottom: 0,
    paddingTop: 0,
    maxHeight: "220px",
    overflow: "auto"
  }),
  singleValue: provided => ({
    ...provided,
    position: "unset",
    top: "unset",
    transform: "unset",
    maxWidth: "100%",
    background: colors.concrete,
    padding: "6.5px 8px",
    borderRadius: "4px",
    color: colors.black,
    fontSize: 14
  }),
  multiValue: provided => ({
    ...provided,
    position: "unset",
    top: "unset",
    transform: "unset",
    maxWidth: "100%",
    background: colors.concrete,
    padding: "6.5px 8px",
    borderRadius: "4px",
    color: colors.black,
    fontSize: 14
  }),
  multiValueRemove: provided => ({
    ...provided,
    paddingLeft: 1,
    paddingRight: 0,
    marginLeft: 4,

    ":hover": {
      backgroundColor: "rgb(151 151 151 / 50%)",
      color: "#979797"
    }
  }),
  multiValueLabel: provided => ({ ...provided, fontSize: 14, padding: 0, paddingLeft: 0 }),
  valueContainer: (provided, { selectProps: { menuIsOpen } }) => ({
    ...provided,
    padding: 0,
    flexWrap: "nowrap",
    overflow: menuIsOpen ? "hidden" : "initial"
  }),
  container: provided => ({
    ...provided,
    minHeight: "inherit",
    background: "transparent",
    height: "100%"
  }),
  control: (styles, { menuIsOpen }) => ({
    ...styles,
    boxShadow: "none",
    borderColor: "#ddd",
    cursor: "pointer",
    border: "none",
    borderRadius: 0,
    height: "100%",
    background: "transparent",
    backgroundColor: "transparent",
    color: colors.black,
    padding: menuIsOpen ? "0 12px" : 0,
    fontSize: 14,
    minHeight: "inherit",

    ":hover": {
      borderColor: "#ddd",
      boxShadow: "0 0 0 100px rgba(0,0,0,0.01) inset"
    }
  }),
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? null : isSelected ? colors.transparent : null,
    color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
    cursor: isDisabled ? "not-allowed" : "pointer",
    minWidth: "60px",
    width: "100%",
    height: 44,
    display: "flex",
    alignItems: "center",
    padding: "6.5px 12px",
    fontSize: 14,

    ":hover": {
      ...styles[":hover"],
      backgroundColor: !isDisabled && (isSelected ? colors.gray100 : colors.gray100)
    }
  })
};
