import ApiService from "../../../apiv2/apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class CorporateEmployees {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  getEmployeesInfo() {
    return this.apiService.get(`corporate-portal/employees/clinics`);
  }

  getListOfUsers({ cityId, organizationId, specialityId, searchValue }) {
    return this.apiService.get(`corporate-portal/employees`, {
      city_id: cityId,
      organization_id: organizationId,
      speciality_id: specialityId,
      search: searchValue
    });
  }
}

export default new CorporateEmployees();
