import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Embryos {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  get({ patientId, viewType }) {
    return this.apiService.get(`embryos`, {
      patient_id: patientId,
      view_type: viewType
    });
  }

  getCryoEmbryosCount(patientId) {
    return this.apiService.get(`embryos_count`, {
      patient_id: patientId
    });
  }

  getEmbryoMedicalFiles(params) {
    const {embryoId, ...otherParams} = params;
    return this.apiService.get(`embryos/${embryoId}/medical-files`, otherParams);
  }

  getEmbryosByFolderId(folderId, viewType) {
    return this.apiService.get(`folders/${folderId}/embryos`, { view_type: viewType });
  }

  getEmbryosManipulations(folderId) {
    return this.apiService.get(`folders/${folderId}/manipulations`);
  }

  patchEmbryosManipulations({ id, folderId, time, doctorId, date, clear }) {
    return this.apiService.patch(`folders/${folderId}/manipulations/${id}`, {}, { time, doctor_id: doctorId, date, clear });
  }

  getEmbryosPrintDocumentData({ folderId, patientId, type }) {
    const params = {
      type
    };
    if (folderId) {
      params.folderId = folderId;
    }
    return this.apiService.get(`patients/${patientId}/embryos_print`, params);
  }

  create({ folderId, number }) {
    return this.apiService.post(`folders/${folderId}/embryos`, {}, {
      batch_size: number
    });
  }

  async update({ id, payload }) {
    try {
      return await this.apiService.patch(`embryos/${id}`, {}, payload);
    } catch (err) {
      if (err.code === 400) {
        throw new Error("Недопустимое значение");
      }

      throw new Error("Нет подключения");
    }
  }

  async delete({ id }) {
    try {
      await this.apiService.delete(`embryos/${id}`);
      return true;
    } catch (err) {
      throw new Error("Нет подключения");
    }
  }

  async copy({ id, viewType }) {
    try {
      return await this.apiService.post(`embryos/${id}/copy`, {}, {
        view_type: viewType
      });
    } catch (err) {
      throw new Error("Нет подключения");
    }
  }
}

export default new Embryos();
