// @flow
import React from "react";
import styled from "styled-components";
import { ListContainer } from "../select/ListContainer";
import { OptionsSeparator } from "../select/OptionsSeparator";

const ShadowContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;

  width: 100%;
  max-height: 240px;
  overflow: auto;
  border-radius: 3px;
  box-shadow: 0 2px 17px 0 rgba(91, 91, 91, 0.26);
  box-shadow-top: none;
  pointer-events: none;
  z-index: 100;
`;

const ListPointsContainer = styled.div`
  max-height: 196px;
  overflow: auto;
`;

export const InputSelectLayout = (props: { children: React$Node, addButton?: React$Node }) => (
  <ShadowContainer aria-hidden="true">
    <ListContainer role="menu">
      <ListPointsContainer>{props.children}</ListPointsContainer>
      <OptionsSeparator />
      {props.addButton && props.addButton}
    </ListContainer>
  </ShadowContainer>
);
