import { useState, useEffect } from "react";

export const useCalcHeightContainer = ({ headerRef, mainRef, navigationsRef, isFullScreen, isShowHeader }) => {
  const [tableHeight, setTableHeight] = useState(0);

  /** Высота области видимости экрана браузера - высота header - высота navigationTabs - отступ по горизонтали */
  const calc = () => {
    const documentHeight = window.innerHeight;
    const headerHeight = isShowHeader && headerRef?.current?.offsetHeight || 0;
    const navigationsHeight = navigationsRef?.current?.offsetHeight || 0;
    const paddingInline = !isFullScreen
      ? parseInt(getComputedStyle(mainRef?.current)?.paddingInline)
      : 0;

    setTableHeight(documentHeight - headerHeight - navigationsHeight - paddingInline);
  };

  useEffect(() => {
    window.addEventListener("resize", calc);
    return () => window.removeEventListener("resize", calc);
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => calc(), [headerRef.current, navigationsRef.current, mainRef.current]);

  return `${tableHeight}px`;
};
