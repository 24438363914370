// @flow
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import {useApiRequest} from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

export const useGetMedicalFile = ({ medicalFileId }: { medicalFileId?: number }) => {
  const token = useStore(tokenStore);

  const medicalFileResult = useApiRequest(async () => {
    if (!medicalFileId) {
      return null;
    }
    return await apiv2.medicalFiles.getById(medicalFileId);
  }, [ token, medicalFileId ]);

  if (!medicalFileId) {
    return null;
  }
  if (medicalFileResult.data) {
    return medicalFileResult.data;
  }
};
