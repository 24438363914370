import * as React from "react";
import { useStore } from "effector-react";

import { folderOptions } from "../../stores/folderStore";
import { tableSelectColors, colors } from "../styleguide/colors";

import {
  methodOptions,
  oocytesAssessmentOptions,
  biopsyStatusOptions,
  incubatorInseminationOptions,
  zygoteAssessmentOptions,
  fifthToSevenDayOptions,
  helpHatchOptions,
  statusOptions,
  difficultiesOptions,
  finalAssessmentOptions,
  inseminationEnvironmentOptions,
  morphologyOptions,
  oilInseminationOptions,
  cryoCarrierOptions,
  incubatorCultivationOptions,
  cultivationEnvironmentOptions,
  oilCultivationOptions,
  frozenOocyteOptions,
  criopreservationEnvOptions,
  biopsyTypeOptions,
  survivalsOptions,
  biopsyResultOptions,
  colorsOptions,
  fragmentationOptions,
  crushingOptions,
  dayOptions,
  pgtStatusOptions,
  prevHatchOptions,
  defrostEnvOptions,
  oocytesTypeOptions,
  assessmentAfterDefrostOptions,
  notationOptions
} from "./constants";

import { WideTextEditContainer } from "./EmbryosTableTextEdit";
import { EmbryoTableGenericSelect } from "./EmbryosTableGenericSelect";
import { EmbryosTableContext } from "./EmbryosTableContext";

export const selectViewStyles = {
  singleValue: (provided, { data }) => {
    return {
      ...provided,
      position: "unset",
      top: "unset",
      transform: "unset",
      maxWidth: "100%",
      background: data.color,
      padding: "6.5px 8px",
      borderRadius: "4px",
      color: colors.black,
      marginLeft: 0,
      marginRight: 0,
      fontSize: 14
    };
  },
  input: provided => ({
    ...provided,
    display: "none"
  })
};

export const SelectView = ({ cell, options, isMulti, print }) => {
  const option = React.useMemo(
    () =>
      isMulti
        ? options.filter(o => (cell?.value ? cell.value.includes(o.value) : false))
        : options.find(o => o.value === cell?.value),
    [cell, isMulti, options]
  );

  if (print) {
    return Array.isArray(option)
      ? option.map(({ label }) => label).join(", ")
      : option?.label || "";
  }

  return (
    <EmbryoTableGenericSelect
      styles={selectViewStyles}
      value={option}
      options={options}
      isMulti={isMulti}
      isDisabled
    />
  );
};

export const SelectEdit = ({
  cell,
  options,
  onChange,
  isMulti,
  exitEditMode,
  updateCell = true,
  row,
  noOptionsMessage
}) => {
  const { onCellUpdate } = React.useContext(EmbryosTableContext);
  const handleChange = React.useCallback(
    option => {
      let value = null;
      if (isMulti) {
        value = option.map(i => i.value);
        onChange({ ...cell, ...option, value });
      } else {
        value = option ? option.value : null;
        onChange({ ...cell, ...option, value });
        exitEditMode();
      }

      if (updateCell) {
        onCellUpdate(row, { field: cell.field, value });
      }
    },
    [cell, isMulti, onChange, row, exitEditMode, onCellUpdate, updateCell]
  );

  const option = React.useMemo(
    () =>
      cell &&
      (isMulti
        ? options.filter(o => (cell?.value ? cell.value.includes(o.value) : false))
        : options.find(o => o?.value === cell?.value)),
    [cell, isMulti, options]
  );

  return (
    <WideTextEditContainer style={{ minHeight: "inherit" }}>
      <EmbryoTableGenericSelect
        value={option}
        noOptionsMessage={noOptionsMessage}
        onChange={handleChange}
        options={options}
        isMulti={isMulti}
        isEdit
      />
    </WideTextEditContainer>
  );
};

export const MethodEditSelectComponent = props => {
  return <SelectEdit {...props} options={methodOptions} />;
};
export const MethodViewSelectComponent = props => {
  return <SelectView {...props} options={methodOptions} />;
};

export const IncubatorViewSelectComponent = props => (
  <SelectView {...props} options={incubatorInseminationOptions} />
);
export const IncubatorEditSelectComponent = props => (
  <SelectEdit {...props} options={incubatorInseminationOptions} />
);

export const OocytesAssessmentViewSelectComponent = props => (
  <SelectView {...props} options={oocytesAssessmentOptions} />
);
export const OocytesAssessmentEditSelectComponent = props => (
  <SelectEdit {...props} options={oocytesAssessmentOptions} />
);

export const CrushingViewSelectComponent = props => (
  <SelectView {...props} options={crushingOptions} />
);
export const CrushingEditSelectComponent = props => (
  <SelectEdit {...props} options={crushingOptions} />
);

export const ZygoteAssessmentViewSelectComponent = props => (
  <SelectView {...props} options={zygoteAssessmentOptions} />
);
export const ZygoteAssessmentEditSelectComponent = props => (
  <SelectEdit {...props} options={zygoteAssessmentOptions} />
);

export const DayViewSelectComponent = props => <SelectView {...props} options={dayOptions} />;
export const DayEditSelectComponent = props => <SelectEdit {...props} options={dayOptions} />;

export const FifthDayViewSelectComponent = props => (
  <SelectView {...props} options={fifthToSevenDayOptions} />
);
export const FifthDayEditSelectComponent = props => (
  <SelectEdit {...props} options={fifthToSevenDayOptions} />
);

export const SixthDayViewSelectComponent = props => (
  <SelectView {...props} options={fifthToSevenDayOptions} />
);
export const SixthDayEditSelectComponent = props => (
  <SelectEdit {...props} options={fifthToSevenDayOptions} />
);

export const SeventhDayViewSelectComponent = props => (
  <SelectView {...props} options={fifthToSevenDayOptions} />
);
export const SeventhDayEditSelectComponent = props => (
  <SelectEdit {...props} options={fifthToSevenDayOptions} />
);

export const StatusViewSelectComponent = props => <SelectView {...props} options={statusOptions} />;
export const StatusEditSelectComponent = props => <SelectEdit {...props} options={statusOptions} />;

export const BiopsyStatusViewSelectComponent = props => (
  <SelectView {...props} options={biopsyStatusOptions} />
);
export const BiopsyStatusEditSelectComponent = props => (
  <SelectEdit {...props} options={biopsyStatusOptions} />
);

export const FrozenOocyteViewSelectComponent = props => (
  <SelectView {...props} options={frozenOocyteOptions} />
);
export const FrozenOocyteEditSelectComponent = props => (
  <SelectEdit {...props} options={frozenOocyteOptions} />
);

export const FinalAssessmentViewSelectComponent = props => (
  <SelectView {...props} options={finalAssessmentOptions} />
);
export const FinalAssessmentEditSelectComponent = props => (
  <SelectEdit {...props} options={finalAssessmentOptions} />
);

export const MorphologyViewSelectComponent = props => (
  <SelectView {...props} options={morphologyOptions} isMulti />
);
export const MorphologyEditSelectComponent = props => (
  <SelectEdit {...props} options={morphologyOptions} isMulti />
);

export const InseminationEnvironmentViewSelectComponent = props => (
  <SelectView {...props} options={inseminationEnvironmentOptions} />
);
export const InseminationEnvironmentEditSelectComponent = props => (
  <SelectEdit {...props} options={inseminationEnvironmentOptions} />
);

export const OilInseminationViewSelectComponent = props => (
  <SelectView {...props} options={oilInseminationOptions} />
);
export const OilInseminationEditSelectComponent = props => (
  <SelectEdit {...props} options={oilInseminationOptions} />
);

export const IncubatorCultivationViewSelectComponent = props => (
  <SelectView {...props} options={incubatorCultivationOptions} />
);
export const IncubatorCultivationEditSelectComponent = props => (
  <SelectEdit {...props} options={incubatorCultivationOptions} />
);

export const CultivationEnvironmentViewSelectComponent = props => (
  <SelectView {...props} options={cultivationEnvironmentOptions} />
);
export const CultivationEnvironmentEditSelectComponent = props => (
  <SelectEdit {...props} options={cultivationEnvironmentOptions} />
);

export const CultivationOilViewSelectComponent = props => (
  <SelectView {...props} options={oilCultivationOptions} />
);
export const CultivationOilEditSelectComponent = props => (
  <SelectEdit {...props} options={oilCultivationOptions} />
);

export const DifficultiesViewSelectComponent = props => (
  <SelectView {...props} options={difficultiesOptions} />
);
export const DifficultiesEditSelectComponent = props => (
  <SelectEdit {...props} options={difficultiesOptions} />
);

export const CriopreservationEnvEditSelectComponent = props => (
  <SelectEdit {...props} options={criopreservationEnvOptions} />
);
export const CriopreservationEnvViewSelectComponent = props => (
  <SelectView {...props} options={criopreservationEnvOptions} />
);

export const BiopsyTypeEditSelectComponent = props => (
  <SelectEdit {...props} options={biopsyTypeOptions} />
);
export const BiopsyTypeViewSelectComponent = props => (
  <SelectView {...props} options={biopsyTypeOptions} />
);

export const BiopsyResultEditSelectComponent = props => (
  <SelectEdit {...props} options={biopsyResultOptions} />
);
export const BiopsyResultViewSelectComponent = props => (
  <SelectView {...props} options={biopsyResultOptions} />
);

export const CryoCarrierEditSelectComponent = props => (
  <SelectEdit {...props} options={cryoCarrierOptions} />
);
export const CryoCarrierViewSelectComponent = props => (
  <SelectView {...props} options={cryoCarrierOptions} />
);

export const SurvivalsEditSelectComponent = props => (
  <SelectEdit {...props} options={survivalsOptions} />
);
export const SurvivalsViewSelectComponent = props => (
  <SelectView {...props} options={survivalsOptions} />
);

export const ColorEditSelectComponent = props => <SelectEdit {...props} options={colorsOptions} />;
export const ColorViewSelectComponent = props => <SelectView {...props} options={colorsOptions} />;

export const FragmentationEditSelectComponent = props => (
  <SelectEdit {...props} options={fragmentationOptions} />
);
export const FragmentationViewSelectComponent = props => (
  <SelectView {...props} options={fragmentationOptions} />
);

export const HelpHatchEditSelectComponent = props => (
  <SelectEdit {...props} options={helpHatchOptions} />
);
export const HelpHatchViewSelectComponent = props => (
  <SelectView {...props} options={helpHatchOptions} />
);

export const FolderViewSelectComponent = props => {
  const folders = useStore(folderOptions);
  const value = props?.cell?.value;
  const nextProps = { ...props, cell: { ...props.cell, value } };
  return <SelectView {...nextProps} options={folders} />;
};

export const FolderEditSelectComponent = props => {
  const folders = useStore(folderOptions);
  const value = props?.cell?.value;
  const nextProps = { ...props, cell: { ...props.cell, value } };
  return <SelectEdit {...nextProps} options={folders} />;
};

export const WomanIdentifierViewSelectComponent = props => {
  const { value } = props.cell;
  const options =
    props.cell.value !== null ? [{ label: value, value, color: tableSelectColors.gray }] : [];
  return <SelectView {...props} options={options} />;
};

export const AssessmentAfterDefrostViewSelectComponent = props => (
  <SelectView {...props} options={assessmentAfterDefrostOptions} />
);

export const AssessmentAfterDefrostEditSelectComponent = props => (
  <SelectEdit {...props} options={assessmentAfterDefrostOptions} />
);

export const PgtStatusViewSelectComponent = props => (
  <SelectView {...props} options={pgtStatusOptions} />
);
export const PgtStatusEditSelectComponent = props => (
  <SelectEdit {...props} options={pgtStatusOptions} />
);

export const PrevHatchViewSelectComponent = props => (
  <SelectView {...props} options={prevHatchOptions} />
);
export const PrevHatchEditSelectComponent = props => (
  <SelectEdit {...props} options={prevHatchOptions} />
);

export const DefrostEnvViewSelectComponent = props => (
  <SelectView {...props} options={defrostEnvOptions} />
);
export const DefrostEnvEditSelectComponent = props => (
  <SelectEdit {...props} options={defrostEnvOptions} />
);

export const OocytesTypeViewSelectComponent = props => (
  <SelectView {...props} options={oocytesTypeOptions} />
);
export const OocytesTypeEditSelectComponent = props => (
  <SelectEdit {...props} options={oocytesTypeOptions} />
);

export const NotationViewSelectComponent = props => (
  <SelectView {...props} options={notationOptions} isMulti />
);
export const NotationEditSelectComponent = props => (
  <SelectEdit {...props} options={notationOptions} isMulti />
);
