// @flow

import type { FieldCheckbox } from "../../domain/entities/Field";
import * as React from "react";
import { useFieldValue } from "./useFieldValue";
import { ButtonCheckboxGroup } from "../form-elements/ButtonCheckboxGroup";
import { possibleValuesToButtonGroupItems } from "./possibleValuesToButtonGroupItems";
import { setFieldValue } from "../../stores/appointmentFieldValues";
import type { LayoutFieldValue, TAppointment_id } from "../../domain/entities/Appointment";
import { getTextArrayValue } from "./getTextArrayValue";
import styled from "styled-components";
import { RequiredField } from "../form/FieldGroupItemFieldSet";
import { useErrorHighlighting } from "./useErrorHighlighting";
import { getField_id } from "../../domain/entities/Field";
import { withModifiers } from "with-modifiers";
import { colors } from "../styleguide/colors";

const FlexContainer = withModifiers({
  withError: () => `
    border: 2px solid ${colors.watermelon};
    max-width: max-content;
    padding: 4px;
    border-radius: 3px;
    
    ${RequiredField} {
      left: -10px;
    }
    `
})(
  modifier => styled.div`
    display: flex;
    position: relative;
    ${modifier}

    ${RequiredField} {
      top: -7px;
    }
`);

export const CheckboxGroupWidget = (props: {
  field: FieldCheckbox,
  tabIndex?: number,
  showRequired?: boolean,
  showError?: boolean,
  appointment_id: TAppointment_id,
  layoutFieldValue?: LayoutFieldValue
}) => {
  const { appointment_id, field, tabIndex, layoutFieldValue } = props;
  const result = useFieldValue(appointment_id, field.id);
  const value = getTextArrayValue(result, layoutFieldValue);
  let errorField;

  if (field.required) {
    const { error } = useErrorHighlighting(
      value,
      appointment_id,
      getField_id(field),
      (value) => {
        return field.required ? value.length : true;
      },
      field.required
    );
    errorField = error;
  }

  const onChange = key => {
    const newValue = value.indexOf(key) >= 0 ? value.filter(item => item !== key) : [...value, key];
    setFieldValue({
      field_type: field.field_type,
      appointment_id,
      field_id: field.id,
      value: (newValue: any)
    });
  };

  const isError = props.showError && errorField && errorField.value;

  return (
    <FlexContainer className={isError && 'error-field'} modifiers={isError && 'withError'}>
      {field.required && props.showRequired && (<RequiredField modifiers='withMargin'>*</RequiredField>)}
      <ButtonCheckboxGroup
        tabIndex={tabIndex}
        checked={value}
        required={field.required}
        items={possibleValuesToButtonGroupItems(field.possible_values)}
        onChange={onChange}
      />
    </FlexContainer>
  );
};
