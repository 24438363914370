// @flow
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import useReactRouter from "use-react-router";
import { useAsync } from "react-use";
import { useMediaQuery } from "@mui/material";
import { AsideAppointmentList } from "../aside/AsideAppointmentList";
import { AsidePatient } from "../aside/AsidePatient";
import styled from "styled-components";
import { currentUser, tokenStore } from "../../stores/auth";
import {
  AsideHeader,
  AsideContainer,
  AsideScrollableContentContainer,
  AsideLayout
} from "../layouts/AsideLayout";
import { Route, Switch } from "react-router-dom";
import { AppointmentPage } from "../appointment-page/AppointmentPage";
import { AppointmentStartPage } from "../appointment-start-form/AppointmentStartPage";
import { AnalyzesPage } from "../analyzes/AnalyzesPage";
import { EmbryosByFolderPage } from "../embryos-table/EmbryosByFolderPage";
import { CryoEmbryosPage } from "../embryos-table/CryoEmbryosPage";
import { BackToScheduleButton } from "./BackToScheduleButton";
import { NewAppointmentButton } from "./NewAppointmentButton";
import { MedicalFileConsumer, MedicalFileContext } from "./MedicalFileContext";
import { useMedicalFileFromMedicalFileIdUrl } from "./useMedicalFileFromMedicalFileIdUrl";
import { useFiles } from "../analyzes/useFiles";
import { $patientAppointments, PatientAppointmentsProvider } from "./PatientAppointmentsProvider";
import { useStore } from "effector-react";
import { isAppointmentSidebarHiddenState } from "../../stores/rightSidebar";
import type { MedicalFile } from "../../domain/entities/MedicalFile";
import { CorrectMedicalFilesContext } from "./CorrectMedicalFilesContext";
import { medicalFileStore } from "./medicalFileStore";
import { AsideGenericAppointment } from "../aside/AsideGenericAppointment";
import { FolderSwitchPage } from "../folder-page/FolderSwitchPage";
import { AppointmentMoveModal } from "../appointment-move-modal/AppointmentMoveModal";
import {useApiRequest} from "../../apiv2/useApiRequest";
import { colors } from '../styleguide/colors';
import { FEATURE, useApplicationFeatureEnabled } from '../../utils/applicationFeatures';
import chatStore from '../../stores/chatStore';
import apiv2 from "../../apiv2";
import appointmentCreateStore from "../../stores/AppointmentCreateStore";
import { EmbryoFiltersContextProvider } from '../embryos-table/EmbryosFilters';
import { cryoFilters, filters, oocytesFilters } from "../embryos-table/constants";
import headerStore from "../../stores/headerStore";
import { AppointmentDirectionsPage } from "../appointment-directions-table/AppointmentDirectionsPage";
import { AsideButton } from "../aside/AsideButton";
import DocumentsIcon from "@mui/icons-material/FolderOutlined";
import EmbryosIcon from "@mui/icons-material/AcUnitOutlined";
import DirectionsIcon from "@mui/icons-material/MedicalServicesOutlined";
import MoreIcon from '@mui/icons-material/ReadMoreOutlined';
import CheckChatIcon from '@mui/icons-material/Check';
import ChatIcon from "@mui/icons-material/ChatOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import appointmentMobxStore from "../../stores/AppointmentStore";
import { useCryoEmbryosData } from "../../utils/useCryoEmbryosData";
import { BlockLoader } from "../loader/Loader";
import { OocytesByFolderPage } from "../embryos-table/OocytesByFolderPage";

const AsideButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 15px;
`;

const AsideAddButtonContainer = styled.div`
  padding: 10px 20px;
`;

const ChatButtonDescription = styled.span`
    line-height: 1.3;
    font-size: 12px;
    color: ${colors.gray700};
`;

const MedicalFileProviderFromAppointmentIdUrl = (props: { children: React$Node }) => {
  const { children } = props;
  const medicalFile = useStore(medicalFileStore);
  return (
    <MedicalFileContext.Provider value={{ medicalFile }}>{children}</MedicalFileContext.Provider>
  );
};

const MedicalFileProviderFromMedicalFileIdUrl = (props: { children: React$Node }) => {
  const { children } = props;
  const medicalFile = useMedicalFileFromMedicalFileIdUrl();
  return (
    <MedicalFileContext.Provider value={{ medicalFile }}>{children}</MedicalFileContext.Provider>
  );
};

const MedicalFileProviderFromStore = ({ children }: { children: React$Node }) => {
  const medicalFile = useStore(medicalFileStore);
  return (
    <MedicalFileContext.Provider value={{ medicalFile }}>{children}</MedicalFileContext.Provider>
  );
};

const MedicalFileProviderRouted = (props: { children: React$Node }) => {
  return (
    <Switch>
      <Route
        exact
        path="/appointment/:appointment_id"
        render={() => (
          <MedicalFileProviderFromAppointmentIdUrl>
            {props.children}
          </MedicalFileProviderFromAppointmentIdUrl>
        )}
      />
      <Route
        exact
        path="/appointment/new/:medical_file_id"
        render={() => (
          <MedicalFileProviderFromMedicalFileIdUrl>
            {props.children}
          </MedicalFileProviderFromMedicalFileIdUrl>
        )}
      />
      <Route
        path="/appointment/analyzes/:medical_file_id/:type?"
        render={() => (
          <MedicalFileProviderFromMedicalFileIdUrl>
            {props.children}
          </MedicalFileProviderFromMedicalFileIdUrl>
        )}
      />
      <Route
        exact
        path="/appointment/questionnaire/:id"
        render={() => <MedicalFileProviderFromStore>{props.children}</MedicalFileProviderFromStore>}
      />
      <Route
        exact
        path="/appointment/folder/:id/embryos/:medical_file_id"
        render={() => (
          <MedicalFileProviderFromMedicalFileIdUrl>
            {props.children}
          </MedicalFileProviderFromMedicalFileIdUrl>
        )}
      />
      <Route
        exact
        path="/appointment/folder/:id/oocytes/:medical_file_id"
        render={() => (
          <MedicalFileProviderFromMedicalFileIdUrl>
            {props.children}
          </MedicalFileProviderFromMedicalFileIdUrl>
        )}
      />
      <Route
        exact
        path="/appointment/embryos/:medical_file_id"
        render={() => (
          <MedicalFileProviderFromMedicalFileIdUrl>
            {props.children}
          </MedicalFileProviderFromMedicalFileIdUrl>
        )}
      />
      <Route
        path="/appointment/folder/:id"
        render={() => <MedicalFileProviderFromStore>{props.children}</MedicalFileProviderFromStore>}
      />
      <Route
        path="/appointment/prescribed-services/:medical_file_id"
        render={() => (
          <><MedicalFileProviderFromMedicalFileIdUrl>{props.children}</MedicalFileProviderFromMedicalFileIdUrl></>
        )}
      />
      <Route
        path="/appointment/directions/:medical_file_external_id"
        render={() => (
          <><MedicalFileProviderFromStore>{props.children}</MedicalFileProviderFromStore></>
        )}
      />
    </Switch>
  );
};

const AnalyzesButtonComponent = (props: { medicalFileId: number, to: string }) => {
  return <AsideButton to={props.to} label="Анализы и документы" icon={<DocumentsIcon fontSize="small" />} />;
};

const EmbryonsButtonComponent = ({ cryoEmbryosExist, to, onClick }: { cryoEmbryosExist: boolean, to: string, onClick: () => void }) => {
  return <AsideButton disabled={!cryoEmbryosExist} to={to} onClick={onClick} label="Криохранилище" icon={<EmbryosIcon fontSize="small" />} />;
};

const ChatButtonComponent = (props: { patientId: number, isActive: boolean }) => {
  const user = useStore(currentUser);
  const id = (user && user.role === "doctor" && user.doctor && user?.doctor?.id) || 0;
  const token = useStore(tokenStore);
  const [enabled, setEnabled] = useState(props.isActive);
  const [userHasChat, setUserHasChat] = useState(false);
  const isChatsEnabled = useApplicationFeatureEnabled(FEATURE.CHAT);

  useEffect(() => {
    // Если используем новые чаты, проверяем, есть ли чат для данного пациента
    if (isChatsEnabled) {
      (async () => {
        const result = await apiv2.chatroom.getByPatientId(props.patientId, false, true);
        if (result?.details !== "Not Found user patient") {
          setUserHasChat(true);
        }
      })();
    }
  }, [isChatsEnabled]);

  const handleChatButtonClick = useCallback(async () => {
    if (isChatsEnabled && userHasChat) {
      chatStore.goToChatWithPatient(props.patientId, false)
    } else {
      await apiv2.doctors.patchDoctorPermission(id, {
        patient_id: props.patientId,
        patient_can_chat: !enabled
      });
      /** result при закрытии отдает true, после перезагрузки false, решили подвязать на !enabled после await patchDoctorPermission */
      setEnabled(!enabled);
    }
  }, [isChatsEnabled, enabled, userHasChat, id, props.patientId, token]);

  if (id && user && user.role === "doctor" && user.is_mobile_user && isChatsEnabled) {
    let label = "Перейти в чат";
    let icon = <ChatIcon fontSize="small" />;
    let transparentIcon = false;
    if (!userHasChat) {
      if (enabled) {
        icon = <CheckChatIcon fontSize="small" color="success" />;
        label = "Закрыть чат";
        transparentIcon = true;
      } else {
        icon = <CheckChatIcon fontSize="small" color="success" />;
        label = "Открыть чат";
      }
    }

    return (
      <>
        <AsideButton
          transparentIcon={`${transparentIcon}`}
          onClick={handleChatButtonClick}
          label={label}
          icon={icon}
        />
        {isChatsEnabled && !userHasChat && (
          <ChatButtonDescription>
            Пациента нет в приложении
          </ChatButtonDescription>
        )}
      </>
    );
  }
  return null;
};

const DirectionsButtonComponent = ({ to, onClick }) => {
  return <AsideButton to={to} onClick={onClick} label="Направления" icon={<DirectionsIcon fontSize="small" />} />
}

const withCorrectMedicalFiles = WrappedComponent => props => {
  const token = useStore(tokenStore);
  const medicalFile = useStore(medicalFileStore);

  const { data: organizations } = useApiRequest(async () => {
    return apiv2.organizations.getOrganizations();
  }, []);

  const medicalFiles = useAsync(async () => {
    if (medicalFile) {
      const result = await apiv2.medicalFiles.get({
        patientId: medicalFile.patient.id
      });

      return result;
    }
  }, [token, medicalFile]);

  const correctMedicalFiles = useMemo(() => {
    if (organizations && medicalFiles.value) {
      return medicalFiles.value.filter(medicalFile => {
        return (
          organizations.findIndex(
            organization => organization.id === medicalFile.organization.id
          ) >= 0
        );
      });
    }
    return [];
  }, [organizations, medicalFiles]);

  return (
    <CorrectMedicalFilesContext.Provider value={{ medicalFiles: correctMedicalFiles }}>
      <WrappedComponent {...props} />
    </CorrectMedicalFilesContext.Provider>
  );
};

const AppointmentPageWithMedicalFiles = withCorrectMedicalFiles(AppointmentPage);
const AppointmentStartPageWithMedicalFiles = withCorrectMedicalFiles(AppointmentStartPage);

export const AppointmentsSwitchPage = observer(() => {
  const { history } = useReactRouter();
  const patientAppointments = useStore($patientAppointments);
  const showTablePrescribedServices = useApplicationFeatureEnabled(FEATURE.TABLE_PRESCRIBED_SERVICES);
  const isMobileDevice = useMediaQuery('(max-width: 768px)');
  const [anchorMoreItem, setAnchorMoreItem] = useState(null);
  const isOpenMoreItems = !!anchorMoreItem;
  /** Можно повесить на Link, но из-за этого будет появляться небольшая задеркжа (обработка клика - переход) */
  history.listen(() => {
    if (isMobileDevice) {
      headerStore.handlerOnCollapseDrawer(false);
    }
  });
  useEffect(() => {
    appointmentMobxStore.setAppointments(patientAppointments);
  }, [patientAppointments]);
  const isAppointmentSidebarHidden = useStore(isAppointmentSidebarHiddenState);
  const openMoreItems = event => setAnchorMoreItem(event.currentTarget);
  const closeMoreItems = () => setAnchorMoreItem(null);
  const handleOnNewAppointment = () => appointmentCreateStore.changeCopyAppointment(null);
  const isCheckupBadge = !!appointmentMobxStore.getOpeningCheckupAppointments().length;

  const aside = (
    <MedicalFileConsumer>
      {medicalFile => {
        const { showEmbryosButton, cryoEmbryosExist } = useCryoEmbryosData(medicalFile);

        const showAsideMenu = (
          showEmbryosButton ||
          showTablePrescribedServices
        );

        return (
        <AsideContainer>
          <AsideHeader>
            {!isMobileDevice && <BackToScheduleButton />}
            <AsidePatient patient={medicalFile.patient} badge={medicalFile.badge} checkup={isCheckupBadge} />
            <AsideButtonsContainer>
              <AnalyzesButtonComponent
                medicalFileId={medicalFile.id}
                to={`/appointment/analyzes/${medicalFile.id}`}
              />
              <ChatButtonComponent
                patientId={medicalFile?.patient?.id}
                isActive={medicalFile?.patient?.patient_can_chat || false}
              />
              {
                showAsideMenu && (
                  <>
                    <AsideButton label="Еще" icon={<MoreIcon fontSize="small" />} onClick={openMoreItems} />
                    <Menu
                      anchorEl={anchorMoreItem}
                      open={isOpenMoreItems}
                      onClose={closeMoreItems}
                      className="simple-drop-down-menu">
                      {
                        showEmbryosButton && (
                          <MenuItem>
                            <EmbryonsButtonComponent
                              cryoEmbryosExist={cryoEmbryosExist}
                              onClick={closeMoreItems}
                              to={`/appointment/embryos/${medicalFile.id}`}
                              cr
                            />
                          </MenuItem>
                        )
                      }
                      {showTablePrescribedServices && (
                        <MenuItem>
                          <DirectionsButtonComponent to={`/appointment/prescribed-services/${medicalFile.id}`} onClick={closeMoreItems} />
                        </MenuItem>
                      )}
                    </Menu>
                  </>
                )
              }
            </AsideButtonsContainer>
          </AsideHeader>
          <AsideAddButtonContainer>
            <NewAppointmentButton to={`/appointment/new/${medicalFile.id}`} handleOnClick={handleOnNewAppointment} />
          </AsideAddButtonContainer>
          <AsideScrollableContentContainer>
            <AsideAppointmentList>
              <PatientAppointmentsProvider>
                {(appointments, dataIsReady) => {
                  if (!dataIsReady) {
                    /** 42px - height Tabs в PatientAppointmentsProvider */
                    return <BlockLoader withoutMargin="true" customHeight="calc(100% - 42px)" />;
                  }
                  return (
                    appointments.map((appointment) => (
                      <AsideGenericAppointment
                        key={appointment.type === "folder" ? "folder" + appointment.id : appointment.id}
                        appointment={appointment} />
                      )
                    )
                  );
                }}
              </PatientAppointmentsProvider>
            </AsideAppointmentList>
          </AsideScrollableContentContainer>
        </AsideContainer>
        );
      }}
    </MedicalFileConsumer>
  );

  const modals = (
    <>
      <AppointmentMoveModal />
    </>
  );

  return (
    <MedicalFileProviderRouted>
      <AsideLayout isAsideHidden={isAppointmentSidebarHidden} aside={aside} modals={modals}>
        <Switch>
          <Route
            exact
            path="/appointment/:appointment_id"
            component={AppointmentPageWithMedicalFiles}
          />
          <Route
            exact
            path="/appointment/new/:medical_file_id"
            component={AppointmentStartPageWithMedicalFiles}
          />
          <Route path="/appointment/analyzes/:medical_file_id/:type?" component={AnalyzesPage} />
          <Route
            exact
            path="/appointment/folder/:id/embryos/:medical_file_id"
            component={() => (
              <MedicalFileConsumer>
                {medicalFile => (
                  <EmbryoFiltersContextProvider filters={filters}>
                    <EmbryosByFolderPage medicalFile={medicalFile} />
                  </EmbryoFiltersContextProvider>
                )}
              </MedicalFileConsumer>
            )}
          />
          <Route
            exact
            path="/appointment/folder/:id/oocytes/:medical_file_id"
            component={() => (
              <MedicalFileConsumer>
                {medicalFile => (
                  <EmbryoFiltersContextProvider filters={oocytesFilters}>
                    <OocytesByFolderPage medicalFile={medicalFile} />
                  </EmbryoFiltersContextProvider>
                )}
              </MedicalFileConsumer>
            )}
          />
          <Route
            exact
            path="/appointment/embryos/:medical_file_id"
            component={() => (
              <MedicalFileConsumer>
                {medicalFile => (
                  <EmbryoFiltersContextProvider filters={cryoFilters}>
                    <CryoEmbryosPage medicalFile={medicalFile} />
                  </EmbryoFiltersContextProvider>
                )}
              </MedicalFileConsumer>
            )}
          />
          <Route path="/appointment/folder/:id" component={FolderSwitchPage} />
          <Route path="/appointment/prescribed-services/:medical_file_id" component={AppointmentDirectionsPage} />
          <Route path="/appointment/directions/:medical_file_external_id" component={AppointmentDirectionsPage} />
        </Switch>
      </AsideLayout>
    </MedicalFileProviderRouted>
  );
});
