//@flow
import React from "react";
import styled from "styled-components";

import {HeadingH3, PXSmall} from "../styleguide/typography";
import { ModalWithCloseButtonLayout } from "../modal/ModalWithCloseButtonLayout";
import { ModalBackground } from "../modal/ModalBackground";
import chatStore from "../../stores/chatStore";
import MediaPermissionLockIcon from "../../assets/icons/media-permission-lock.inline.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 653px;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 40px;
  }
`;

const IconContainer = styled.div`
  margin-bottom: 30px;
`;

const TextContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 10px;
`;

const HeadingContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

export const MediaPermissionLockModal = (props: {
  isShown: boolean,
}) => {
  const { isShown } = props;

  return (
    <>
      {isShown && (
        <ModalBackground modifiers={'z-index-full'}>
          <ModalWithCloseButtonLayout hiddenCloseButton={chatStore.isAnonymousMode} handleClose={() => chatStore.changeShowMediaPermissionLockModal()}>
            <Container>
              <IconContainer>
                <MediaPermissionLockIcon />
              </IconContainer>
              <HeadingContainer>
                <HeadingH3 modifiers={['center']}>Использование камеры и микрофона заблокировано</HeadingH3>
              </HeadingContainer>
              <TextContainer>
                <PXSmall modifiers={['gray']}>1.  Нажмите на значок блокировки в адресной строке браузера.</PXSmall>
                <PXSmall modifiers={['gray']}>2. Включите микрофон и камеру.</PXSmall>
                <PXSmall modifiers={['gray']}>3. Перезагрузите страницу.</PXSmall>
              </TextContainer>
            </Container>
          </ModalWithCloseButtonLayout>
        </ModalBackground>
      )}
    </>
  );
};
