import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import Slider from "react-slick";

import { DoctorSearch } from "./doctor-search/DoctorSearch";
import { GeneralScheduleCardList, GeneralScheduleCard } from "./GeneralScheduleCard";
import { GeneralSchedulePlaceholder } from "./GeneralSchedulePlaceholder";
import { SliderButton } from "./SliderButton";
import { Calendar } from "../calendar/legacy/Calendar";
import { ScheduleInsertContext, ScheduleInsertContextProvider } from "./ScheduleInsertContext";
import { ModalPortal } from "../modal/ModalPortal";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import RightArrowIcon from "./icons/RightArrow";
import LeftArrowIcon from "./icons/LeftArrow";
import { appendOrUpdateData } from "./fullScheduleStore";

const Container = styled.div`
  padding: 34px 27px 30px 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0 40px 62px;
  }
  @media (max-width: 480px) {
    padding: 0 20px 62px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FlexBetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    ${FlexContainer} {
      align-self: flex-start;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    ${FlexContainer} {
      &:first-child {
        width: 100%;
      }
      &:last-child {
        align-self: center;
      }
    }
  }
`;

const LoadingScreen = styled.div`
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
  color: #979797;
  text-align: center;
`;

const debouncedSetDay = _.debounce(
  (ref, now, day, setValue, previous = false, onPrevious) => {
    if (ref) {
      if (previous && day && (day.isAfter(now, "d") || day.isSame(now, "d"))) {
        ref.current.slickPrev();
        onPrevious?.(day);
      } else {
        ref.current.slickNext();
      }
      // $FLowFixMe
      setValue(day);
    }
  },
  400,
  {
    leading: true,
    trailing: false
  }
);

export const GeneralScheduleContent = props => {
  // $FlowFixMe
  const scrollRef = useRef(null);
  const generalScheduleCardListRef = useRef(null);

  const { schedule, doctors, favouriteDoctors, isLoading } = props;

  const scheduleFirstDate =
    (schedule && schedule.length && schedule[0].date) || moment(Date.now()).format("YYYY-MM-DD");

  const now = moment(scheduleFirstDate);

  const [momentValue, setMomentValue] = useState(now);
  const [minDate, setMinDate] = useState(now.clone().subtract(6, 'd'));

  useEffect(() => {
    const scheduleCurrentDateItem =
      schedule &&
      schedule.length &&
      schedule.find(scheduleItem => scheduleItem.date === momentValue.format("YYYY-MM-DD"));
    if (!isLoading && !scheduleCurrentDateItem) {
      setMomentValue(now);
    }
    // $FLowFixMe
    if (scrollRef) {
      // $FLowFixMe
      setTimeout(() => {
        // $FLowFixMe
        if (scrollRef && scrollRef.current && scrollRef.current.slickGoTo) {
          scrollRef.current.slickGoTo(
            scheduleCurrentDateItem ? schedule.indexOf(scheduleCurrentDateItem) : 0
          );
        }
      });
      //scrollRef.current.innerSlider.track.node.style.tarnsform = "translate3d(0px, 0px, 0px)";
    }
  }, [scheduleFirstDate, props]);

  const previousScheduleItem = schedule
    ? schedule[
        schedule.findIndex(scheduleItem => scheduleItem.date === momentValue.format("YYYY-MM-DD")) -
          1
      ]
    : 0;
  const prevDay = previousScheduleItem ? moment(previousScheduleItem.date) : null; // momentValue.clone().subtract(1, 'd');
  const previousButtonIsActive = prevDay && prevDay.isSameOrAfter(now, "d");

  const nextScheduleItem = schedule
    ? schedule[
        schedule.findIndex(scheduleItem => scheduleItem.date === momentValue.format("YYYY-MM-DD")) +
          1
      ]
    : 0;
  const nextDay = nextScheduleItem ? moment(nextScheduleItem.date) : null; // momentValue.clone().subtract(1, 'd');
  const nextButtonIsActive = !!nextDay;

  const allDates = schedule ? schedule.map(fullScheduleItem => fullScheduleItem.date) : [];

  const scrollSettings = {
    className: "general-schedule-slider",
    accessibility: false,
    draggable: false,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    variableWidth: true,
    variableHeight: true,
    touchMove: false
  };

  const checkIsPreviousDateSelect = (day) => {
    if (day.isBefore(minDate)) {
      setMinDate(day.clone().subtract(6, 'd'));
      appendOrUpdateData({from_date: day.toDate()});
    }
  };

  const setPreviousDay = () => debouncedSetDay(scrollRef, now, prevDay, setMomentValue, true, checkIsPreviousDateSelect);
  const setNextDay = () => debouncedSetDay(scrollRef, now, nextDay, setMomentValue);

  const availableDaysFilter = day => {
    return !allDates.includes(moment(day).format("YYYY-MM-DD"));
  };

  return (
    <ScheduleInsertContextProvider>
      <ScheduleInsertContext.Consumer>
        {({ scheduleInsertModal }) => (
          <Container>
            <FlexBetweenContainer>
              <FlexContainer>
                <DoctorSearch
                  type={"text"}
                  placeholder={"Поиск по врачу"}
                  doctors={doctors}
                  disabled={isLoading || !schedule}
                />
              </FlexContainer>
              <FlexContainer>
                <Calendar
                  availableDaysFilter={availableDaysFilter}
                  value={momentValue}
                  previousDayActive={previousButtonIsActive}
                  onPrevious={setPreviousDay}
                  nextDayActive={nextButtonIsActive}
                  onNext={setNextDay}
                  disabled={isLoading || !schedule || (schedule && !schedule.length)}
                  onChange={date => {
                    const momentDate = moment(date);

                    checkIsPreviousDateSelect(momentDate);

                    // $FLowFixMe
                    if (scrollRef) {
                      const selectedDateItemIndex = allDates.findIndex(
                        date => momentDate.format("YYYY-MM-DD") === date
                      );
                      // $FLowFixMe
                      scrollRef.current.slickGoTo(selectedDateItemIndex);
                    }

                    setMomentValue(momentDate);
                  }}
                />
              </FlexContainer>
            </FlexBetweenContainer>
            <GeneralScheduleCardList ref={generalScheduleCardListRef}>
              {schedule && schedule.length ? (
                <>
                  <SliderButton
                    position="left"
                    icon={<LeftArrowIcon />}
                    disabled={!previousButtonIsActive}
                    onClick={setPreviousDay}
                  />
                  <Slider ref={scrollRef} {...scrollSettings}>
                    {schedule.map((card, i) => {
                      return (
                        <GeneralScheduleCard
                          key={i}
                          cardData={card}
                          favouriteDoctors={favouriteDoctors}
                          containerRef={generalScheduleCardListRef}
                        />
                      );
                    })}
                  </Slider>
                  <SliderButton
                    position="right"
                    icon={<RightArrowIcon />}
                    disabled={!nextButtonIsActive}
                    onClick={setNextDay}
                  />
                </>
              ) : isLoading ? (
                <GeneralSchedulePlaceholder />
              ) : (
                <LoadingScreen>Расписание не заполнено для врачей этой специальности</LoadingScreen>
              )}
            </GeneralScheduleCardList>
            <ModalPortal>{scheduleInsertModal}</ModalPortal>
          </Container>
        )}
      </ScheduleInsertContext.Consumer>
    </ScheduleInsertContextProvider>
  );
};
