import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import useReactRouter from "use-react-router";
import { useStore } from "effector-react";
import { useAsyncFn } from "react-use";

import { tokenStore } from "../../stores/auth";

import { ecoInseminationCultivationColumns, VIEW_TYPES, VIEW_TYPES_FILTERS } from "./constants";

import { createFolderDocumentLinks } from "./utils";

import { EmbryosPage } from "./EmbryosPage";
import { EmbryosManipulations } from "./EmbryosManipulations/EmbryosManipulations";
import { PrintButtonDropDown } from "../appointment-page/PrintButtonDropDown";
import { DefaultButton } from "../buttons/DefaultButton";
import { MGInfoModal, SendToMG } from './SendToMG';
import { useEmbryoFilters } from './EmbryosFilters';

import arrowIcon from "./icons/arrow-long-left.inline.svg";
import historyIcon from "./icons/history.inline.svg";
import apiv2 from '../../apiv2';
import { FEATURE, useApplicationFeatureEnabled } from '../../utils/applicationFeatures';
import MGOrderList from "./MGOrderList";
import medicalGenomicsStore from "../../stores/medicalGenomicsStore";
import { observer } from "mobx-react";

const Title = styled.div`
  font-size: 22px;
  display: flex;
  align-items: center;
`;

const ArrowIcon = styled(arrowIcon)`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  cursor: pointer;
`;

const HistoryIcon = styled(historyIcon)`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`;

const HistoryIconContainer = styled.div`
  margin-top: 5px;
`;

export const ToolsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const customColumnsByName = {
  ECO: { [VIEW_TYPES.CULTIVATION_AND_FERTILIZATION]: ecoInseminationCultivationColumns }
};

export const EmbryosByFolderPage = observer(props => {
  const [showEmbryosManipulations, setShowEmbryosManipulations] = useState(false);
  const { activeFilter } = useEmbryoFilters();
  const isMedicalGenomicsEnabled = useApplicationFeatureEnabled(FEATURE.MEDICAL_GENOMICS);

  const {
    match: { params },
    history
  } = useReactRouter();

  const token = useStore(tokenStore);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const [fetchFolderState, fetchFolder] = useAsyncFn(async () => {
    const folder = await apiv2.appointments.getFolder(params.id);
    return folder;
  });

  useEffect(() => {
    if (params.id) {
      medicalGenomicsStore.fetchOrderListForFolder(params.id);
    }
  }, [params]);

  const onGetEmbryos = useCallback(
    ({ filter }) =>
      apiv2.embryos.getEmbryosByFolderId(params.id, VIEW_TYPES_FILTERS[filter]),
    [params.id, token]
  );

  const onCreateEmbryos = useCallback(
    number =>
      apiv2.embryos.create({
        number,
        folderId: params.id
      }),
    [params.id, token]
  );

  const title = fetchFolderState.value ? (
    <Title>
      <ArrowIcon onClick={handleGoBack} />
      {fetchFolderState.value.name}
    </Title>
  ) : null;

  const medicalGenomics = React.useMemo(() => {
    return isMedicalGenomicsEnabled && activeFilter.viewType === VIEW_TYPES.BIOPSIES && (
      <>
        <SendToMG folderId={params.id} />
        {
          medicalGenomicsStore.getOrderListForFolder(params.id).length > 0 && (
            <MGOrderList folderId={params.id}/>
          )
        }
        <MGInfoModal folderId={params.id}/>
      </>
    );
  }, [activeFilter, medicalGenomicsStore.orderList]);

  const menuItems = [
    {
      header: "Таблицы",
      links: [
        {
          title: "Все таблицы",
          to: `/embryos/${params.id}/${props.medicalFile.id}/print/${VIEW_TYPES.ALL}`
        },
        {
          title: "Культивирование",
          to: `/embryos/${params.id}/${props.medicalFile.id}/print/${
            VIEW_TYPES.CULTIVATION_AND_FERTILIZATION
          }`
        },
        {
          title: "Биопсия",
          to: `/embryos/${params.id}/${props.medicalFile.id}/print/${VIEW_TYPES.BIOPSIES}`
        },
        {
          title: "Крио",
          to: `/embryos/${params.id}/${props.medicalFile.id}/print/${VIEW_TYPES.CRYOPRESERVATIONS}`
        },
        {
          title: "Размораживание",
          to: `/embryos/${params.id}/${props.medicalFile.id}/print/${VIEW_TYPES.DEFROSTS}`
        }
      ]
    },
    {
      header: "Документы",
      links: createFolderDocumentLinks(props.medicalFile.id, params.id)
    }
  ];

  const tools = fetchFolderState.value ? (
    <ToolsContainer>
      { medicalGenomics }
      <PrintButtonDropDown menuItems={menuItems} withHeader />
      <DefaultButton
        onClick={() => setShowEmbryosManipulations(true)}
        overrides={{
          Icon: {
            component: HistoryIcon
          },
          IconContainer: {
            component: HistoryIconContainer
          }
        }}>
        Манипуляции
      </DefaultButton>
      <EmbryosManipulations
        token={token}
        folderId={params.id}
        open={showEmbryosManipulations}
        onClose={() => setShowEmbryosManipulations(false)}
      />
    </ToolsContainer>
  ) : null;

  useEffect(() => {
    fetchFolder();
  }, [fetchFolder]);

  const getCustomColumns = () => {
    if (fetchFolderState.value?.name?.includes("ЭКО")) {
      return customColumnsByName.ECO;
    }
    return null;
  };

  return (
    <EmbryosPage
      onGetEmbryos={onGetEmbryos}
      onCreateEmbryos={onCreateEmbryos}
      title={title}
      tools={tools}
      header="Эмбрионы"
      showTable={fetchFolderState.value !== undefined}
      customColumns={getCustomColumns()}
      {...props}
    />
  );
});
