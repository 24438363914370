// @flow
import { withModifiers } from "with-modifiers/dist";
import styled from "styled-components";
import checkboxImg from "./icons/checkbox.svg";
import checkboxHoverImg from "./icons/checkbox-hover.svg";
import checkboxDisabledImg from "./icons/checkbox-disabled.svg";

export const CheckboxLabel = withModifiers({
  disabled: () => `
        background: url(${checkboxDisabledImg});
    `
})(
  modifier => styled.button`
    border: none;
    background: url(${checkboxImg}) no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    outline: none;
    transition: all 0.2s;
    flex-shrink: 0;
    cursor: pointer;
      
    @media (min-width: 769px) {
      :hover {
        background-image: url(${checkboxHoverImg});
      }
    }
    @media (max-width: 768px) {
      :active {
        background-image: url(${checkboxImg});
      }
    }

    ${modifier};
  `
);
