import {teethValueToArray, toServicesTeethString, toTeethIDs} from '../teeth-picker/TeethPicker';

export const PPR_TEMPLATE_KEY = 'VAR$PPR$';
export const PRESCRIBED_SERVICES_TEMPLATE_KEY = 'VAR$prescribed_services$';

export const APPOINTMENT_VISIT_DATETIME_KEY = 'VAR$APPOINTMENT_VISIT_DATETIME$';

export const checkForDatetimeInTemplate = (template) => template && template.includes(APPOINTMENT_VISIT_DATETIME_KEY);

export const checkForExaminationsTableInTemplate = (template) => template && template.includes(PPR_TEMPLATE_KEY);
export const checkForPrescribedServicesInTemplate = (template) => template && template.includes(PRESCRIBED_SERVICES_TEMPLATE_KEY);

export const createPrintExaminationsTable = (appointment) => {
  const examinationChecklistItems = appointment.examination_checklist?.items;
  if (examinationChecklistItems && examinationChecklistItems.length) {
    let template = `
      <table class="ppr-table">
        <thead>
          <tr>
            <th>№</th>
            <th>Услуга</th>
            <th>Цена, ₽</th>
            <th>Кол-во</th>
            <th>Сумма</th>
            <th>Дата</th>
            <th>Комментарий</th>
          </tr>
        </thead>
        <tbody>
    `;
    let counter = 0;
    let price = 0;

    const createServicesRows = (items) => {
      items.forEach((checklistItem) => {
        if (checklistItem.items) {
          createServicesRows(checklistItem.items);
        } else if (checklistItem.is_checked && checklistItem.services && checklistItem.services[0]) {
          counter += 1;
          const serviceSum = checklistItem.services[0].price * (checklistItem.count || 1);
          template += `
            <tr>
              <td style="width: 40px; text-align: center;">${counter}</td>
              <td>${checklistItem.name}<br/><span class="ppr-table__service-name">${checklistItem.services[0].name}</span></td>
              <td style="width: 120px; text-align: center;">${checklistItem.services[0].price}</td>
              <td style="width: 80px; text-align: center;">${checklistItem.count || 1}</td>
              <td style="width: 120px; text-align: center;">${serviceSum}</td>
              <td style="text-align: center;">${checklistItem.dateRange ? checklistItem.dateRange[0] + (checklistItem.dateRange.length > 1 ? ` - ${checklistItem.dateRange[1]}` : '') : ''}</td>
              <td style="text-align: center;">${checklistItem.comment}</td>
            </tr>
          `;
          price += serviceSum;
        }
      });
    };

    createServicesRows(examinationChecklistItems);

    template += `
        </tbody>
        <tfoot>
          <tr class="ppr-table__footer">
            <td></td>
            <td colspan="3">
              <strong>Итого</strong>
            </td>
            <td colspan="3">
              <strong>${price}</strong>
            </td>
          </tr>
        </tfoot>
      </table>
    `;
    return template;
  } else {
    return '___________';
  }
};



export const createPrescribedServicesTable = (appointment) => {
  const prescribed_services = appointment.prescribed_services;
  if (!prescribed_services?.length) {
    return '___________';
  }

  const hasDate = prescribed_services.some((s) => s.date_range?.value);
  const hasComment = prescribed_services.some((s) => s.comment);
  const hasTeeth = prescribed_services.some((s) => s.teeth);
  let template = `
    <table class="ppr-table">
      <thead>
        <tr>
          <th>№</th>
          <th>Услуга</th>
          <th>Цена, ₽</th>
          <th>Кол-во</th>
          <th>Сумма</th>
          ${ hasTeeth ? '<th>Зуб</th>' : '' }
          ${ hasDate ? '<th>Дата</th>' : '' }
          ${ hasComment ? '<th>Комментарий</th>' : '' }
        </tr>
      </thead>
      <tbody>
  `;
  let counter = 0;
  let price = 0;

  const createServicesRows = (services) => {
    services.forEach((service) => {
        counter += 1;
        const serviceSum = service.price * (service.count || 1);

        const date_range = service.date_range?.value;
        template += `
          <tr>
            <td style="width: 40px; text-align: center;">${counter}</td>
            <td>${service.name}</td>
            <td style="width: 120px; text-align: center;">${service.price}</td>
            <td style="width: 80px; text-align: center;">${service.count || 1}</td>
            <td style="width: 120px; text-align: center;">${serviceSum}</td>
            ${ hasTeeth ? `<td style="text-align: center;">${toServicesTeethString(toTeethIDs(teethValueToArray(service.teeth)))}</td>` : '' }
            ${ hasDate ? `<td style="text-align: center;">${date_range ? date_range[0] + (date_range.length > 1 ? ` - ${date_range[1]}` : '') : ''}</td>` : '' }
            ${ hasComment ? `<td style="text-align: center;">${service.comment}</td>` : '' }
          </tr>
        `;
        price += serviceSum;
    });
  };

  createServicesRows(prescribed_services);

  let colSpanForPrice = 1;
  if (hasDate) {
    colSpanForPrice++;
  }
  if (hasComment) {
    colSpanForPrice++;
  }
  if (hasTeeth) {
    colSpanForPrice++;
  }
  template += `
      </tbody>
      <tfoot>
        <tr class="ppr-table__footer">
          <td></td>
          <td colspan="3">
            <strong>Итого</strong>
          </td>
          <td colspan={colSpanForPrice}>
            <strong>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                ${price} ₽
            </strong>
          </td>
        </tr>
      </tfoot>
    </table>
  `;
  return template;
};