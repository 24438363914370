// @flow

import type { TFieldValue } from "../../stores/appointmentFieldValues";
import type { LayoutFieldValue } from "../../domain/entities/Appointment";
import type { Prescription } from "../../domain/value-objects/Prescription";

function toString(v: Prescription | string): ?string {
  return typeof v === "string" ? v : undefined;
}

export const getTextArrayValue = (
  fieldValue: ?TFieldValue,
  layoutFieldValue?: LayoutFieldValue
): Array<string> => {
  if (fieldValue && fieldValue.value instanceof Array) {
    return fieldValue.value.map(toString).filter(Boolean);
  }

  if (layoutFieldValue && layoutFieldValue.value instanceof Array) {
    return layoutFieldValue.value.map(toString).filter(Boolean);
  }

  return [];
};
