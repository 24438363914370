// @flow
import { useState } from "react";
import {useApiRequest} from "../../apiv2/useApiRequest";
import apiv2 from "../../apiv2";
import appointmentCreateStore from "../../stores/AppointmentCreateStore";
import { createTrigger, useTrigger } from "react-use-trigger";

export const reloadPopularLayouts = createTrigger();

export const usePopularLayoutRadioGroups = (): {
  items: { [string]: { name: string } },
  checked?: string,
  onChange?: (key?: string) => mixed
} => {
  const [checked, onChange] = useState();
  const reloadPopularLayoutsTrigger = useTrigger(reloadPopularLayouts);

  const emptyResult = { items: {} };
  const { data: layouts, error } = useApiRequest(async () => {
    return apiv2.appointments.getPopularLayouts();
  }, [reloadPopularLayoutsTrigger]);

  if (!layouts || error) {
    return emptyResult;
  }
  const order = [];
  const items = {};
  if (appointmentCreateStore.copyAppointment && !layouts.find((item) => item.id === appointmentCreateStore.copyAppointment.layout.id)) {
    layouts.push(appointmentCreateStore.copyAppointment.layout);
  }
  layouts.forEach((layout) => {
    items[layout.id] = layout;
    order.push(`${layout.id}`);
  });

  return { items, order, checked, onChange };
};
