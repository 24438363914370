export default (value, ranges) => {
  let result = "";
  for (let val in ranges) {
    const range = ranges[val].toArray();
    for (let [min, max] of range) {
      if (value >= min && value <= max) {
        result = val;
        break;
      }
    }
  }
  return result;
};
