import React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import { getPatientLastNameWithInitials } from "../../domain/services/patient";

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 18px;
`;

const BoldText = styled.span`
  font-weight: 500;
  color: ${colors.black};
`;

const ToastText = styled.div`
  color: ${colors.gray500};
`;

export const ScheduleInsertToast = props => {
  const { doctor, insertMoment, offSchedule = false } = props;

  return (
    <ToastContainer>
      <BoldText>Записываем к {getPatientLastNameWithInitials(doctor)}</BoldText>
      <ToastText>
        {offSchedule ? (
          <>{insertMoment.format("DD MMMM")}, Вне расписания</>
        ) : (
          <>{insertMoment.format("DD MMMM, в HH:mm")}</>
        )}
      </ToastText>
    </ToastContainer>
  );
};
