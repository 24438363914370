// @flow

import type { TFieldValue } from "../../stores/appointmentFieldValues";
import type { LayoutFieldValue } from "../../domain/entities/Appointment";

export const getTextValue = (
  fieldValue: ?TFieldValue,
  layoutFieldValue?: LayoutFieldValue,
  layoutFieldValueHandler?: string => string
) => {
  if (fieldValue && typeof fieldValue.value === "string") {
    return fieldValue.value;
  }

  if (layoutFieldValue && typeof layoutFieldValue.value === "string" && layoutFieldValue.value) {
    return layoutFieldValueHandler
      ? layoutFieldValueHandler(layoutFieldValue.value)
      : layoutFieldValue.value;
  }

  return "";
};
