import apiv2 from '../apiv2';
import { makeAutoObservable } from "mobx";
import moment from "moment";
import _ from "lodash";
import { organizationsColor } from "../utils/organizationsColor";

export const INTERVAL_FORMAT = {
  day: 'DD.MM.YYYY',
  week: 'DD.MM.YYYY',
  month: 'MMMM',
  year: 'YYYY'
}

export const transformTableData = (data, organization_ids, valueName, organizations, dashboardStore) => {
  const returnData = [];
  if (Array.isArray(data)) {
    const organizationsData = _.groupBy(data, 'organization_id');
    Object.keys(organizationsData).forEach((organizationId) => {
      const statusData = _.groupBy(organizationsData[organizationId], 'chat_status');
      Object.keys(statusData).forEach((key) => {
        returnData.push(
          {
            organization: parseInt(organizationId) === 0 ? 'Без карты' : organizations.find((organization) => parseInt(organization.id) === parseInt(organizationId))?.name,
            chat_status: dashboardStore.chatStatusOptions.find((item) => item.value === key)?.label || "",
            chat_status_key: key,
            count: statusData[key].reduce((acc, item) => {
              acc += item[valueName];
              return acc;
            }, 0)
          }
        )
      })
    })
  }
  return returnData;
};

export const transformDataDiagram = ({data, interval, isCollapseData, valueField = 'count'}) => {
  const groupedDataDate = _.groupBy(_.sortBy(data, 'date_trunc'), 'date_trunc');
  const groupedDataOrganizations = _.groupBy(_.sortBy(data, 'organization_id'), 'organization_id');
  const groupedDataDateOrganizations = Object.keys(groupedDataDate).reduce((acc, key) => {
    acc[key] = _.groupBy(_.sortBy(groupedDataDate[key], 'organization_id'), 'organization_id');
    return acc;
  }, {})
  const labels = Object.keys(groupedDataDate);
  const organizationsData = Object.keys(groupedDataOrganizations).reduce((acc, key) => {
    acc[key] = {
      patient_invited: [],
      chat_created: [],
      patient_accepted: [],
      patient_text: [],
      label: '',
    };
    return acc;
  }, {});
  labels.forEach((labelDate) => {
    Object.keys(groupedDataOrganizations).forEach((organizationId) => {
      const dataOrganizations = groupedDataDateOrganizations[labelDate][organizationId];
      if (!organizationsData[organizationId].label && dataOrganizations) {
        organizationsData[organizationId].label = dataOrganizations[0].name;
      }
      if (dataOrganizations) {
        const groupedFormat = _.groupBy(dataOrganizations, 'chat_status');
        const setData = (key) => {
          if (groupedFormat[key]?.length) {
            organizationsData[organizationId][key].push(groupedFormat[key].reduce((acc, item) => {
              acc += item[valueField];
              return acc;
            }, 0));
          } else {
            organizationsData[organizationId][key].push(0);
          }
        }
        setData('patient_invited');
        setData('chat_created');
        setData('patient_accepted');
        setData('patient_text');
      } else {
        organizationsData[organizationId].patient_invited.push(0);
        organizationsData[organizationId].chat_created.push(0);
        organizationsData[organizationId].patient_accepted.push(0);
        organizationsData[organizationId].patient_text.push(0);
      }
    })
  })

  const datasets = Object.keys(organizationsData).reduce((acc, key) => {
    const data = organizationsData[key];
    if (isCollapseData) {
      if (!acc.length) {
        acc.push({
          label: data.label + ' - заполнили форму на сайте',
          data: data.patient_invited || [],
          color: '#ea0000',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + ' - создан чат',
          data: data.chat_created || [],
          color: '#0430f6',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + ' - присоединился',
          data: data.patient_accepted || [],
          color: '#24d313',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + ' - обратился впервые',
          data: data.patient_text || [],
          color: '#e7e308',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
      } else {
        acc[0].label += ', ' + data.label + ' - заполнили форму на сайте';
        acc[1].label += ', ' + data.label + ' - создан чат';
        acc[2].label += ', ' + data.label + ' - присоединился';
        acc[3].label += ', ' + data.label + ' - обратился впервые';

        data.patient_invited.forEach((count, index) => {
          acc[0].data[index] = acc[0].data[index] + count;
        })

        data.chat_created.forEach((count, index) => {
          acc[1].data[index] = acc[1].data[index] + count;
        })

        data.patient_accepted.forEach((count, index) => {
          acc[2].data[index] = acc[2].data[index] + count;
        })

        data.patient_text.forEach((count, index) => {
          acc[3].data[index] = acc[3].data[index] + count;
        })
      }
    } else {
      acc.push({
        label: data.label + ' - заполнили форму на сайте',
        data: data.patient_invited || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - создан чат',
        data: data.chat_created || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - присоединился',
        data: data.patient_accepted || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - обратился впервые',
        data: data.patient_text || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
    }
    return acc;
  }, []);
  return { labels, datasets };
}

class DashboardStore {
  chatStatus = {
    patient_invited: 'Заполнили форму на сайте',
    chat_created: 'Создан чат',
    patient_accepted: 'Присоединился',
    patient_text: 'Обратился впервые'
  }
  chatStatusOptions = [
    {
      label: 'Заполнили форму на сайте',
      value: 'patient_invited'
    },
    {
      label: 'Создан чат',
      value: 'chat_created'
    },
    {
      label: 'Присоединился',
      value: 'patient_accepted'
    },
    {
      label: 'Обратился впервые',
      value: 'patient_text'
    },
  ];
  contentStatus = {
    open: "Посмотрели сторис",
    moved_to_content_block: "Перешли в блок контент",
    viewed_article: "Просмотрели статью",
    clicks_on_an_entry: "Тапнули на запись",
    purchased_services: "Купили услугу"
  };
  contentStatusOptions = [
    {
      label: "Посмотрели сторис",
      value: "open"
    },
    {
      label: "Перешли в блок контент",
      value: "moved_to_content_block"
    },
    {
      label: "Просмотрели статью",
      value: "viewed_article"
    },
    {
      label: "Тапнули на запись",
      value: "clicks_on_an_entry"
    },
    {
      label: "Купили услугу",
      value: "purchased_services"
    }
  ];
  firstOnlineAppSaleStatus = {
    open: "Посмотрели сторис",
    online_consultation: "Нажали 'записаться на онлайн консультацию'",
    chat_consultation: "Нажали 'записаться на консультацию в чате'",
    purchased_services_chat: "Записался на чат",
    purchased_services_online: "Записался на онлайн"
  };
  firstOnlineAppSaleStatusOptions = [
    {
      label: "Посмотрели сторис",
      value: "open"
    },
    {
      label: "Нажали 'записаться на онлайн консультацию'",
      value: "online_consultation"
    },
    {
      label: "Нажали 'записаться на консультацию в чате'",
      value: "chat_consultation"
    },
    {
      label: "Записался на чат",
      value: "purchased_services_chat"
    },
    {
      label: "Записался на онлайн",
      value: "purchased_services_online"
    }
  ];

  constructor() {
    makeAutoObservable(this);
  }

  async getAnalyticsMetricData(filters) {
    try {
      return await apiv2.concierge.getAnalyticsMetric(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getAnalyticsContentData(filters) {
    try {
      return await apiv2.metric.getAnalyticsContent(filters);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getAnalyticsFirstOnlineAppSaleData(filters) {
    try {
      return await apiv2.metric.getAnalyticsFirstOnlineAppSale(filters);
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}

export default new DashboardStore();
