// @flow

import * as React from "react";
import styled from "styled-components";
import { useStore } from "effector-react";
import { useTrigger } from "react-use-trigger";
import useReactRouter from "use-react-router";
import _ from "lodash";

import { tokenStore } from "../../stores/auth";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import { getPatientFullName } from "../../domain/services/patient";
import {
  formatAppointmentDateToDateYearWithWords,
  formatAppointmentDateToDateWithWords
} from "../../domain/services/appointments";
import {
  AppointmentPrintableHeaderLayout,
  AppointmentPrintableHeaderItem
} from "../appointment-print/AppointmentPrintableHeader";
import { AppointmentPrintLayout } from "../appointment-print/AppointmentPrintLayout";
import { Logo } from "../appointment-print/Logo";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import { PNormal, HeadingH4 } from "../styleguide/typography";
import { AppointmentSign } from "../appointment-print/AppointmentSign";
import { useOrganization } from "../appointment-print/useOrganization";
import { useContacts } from "../appointment-print/useContacts";
import { useAfterPrint } from "../appointment-print/useAfterPrint";
import { useBeforePrint } from "../appointment-print/useBeforePrint";
import { useLogo } from "../appointment-print/useLogo";
import { getTableFromSection } from './Table';
import { getAppointment_id } from '../../domain/entities/Appointment';
import { TableWidget } from '../appointment-widgets/TableWidget';
import { useApiRequest } from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

const Container = styled.table`
  padding: 68px 85px;
  width: 1520px;
  overflow-x: hidden;

  ${p => (p.breakAfter ? "@media print {page-break-after: always;}" : null)}
`;

const Title = styled(HeadingH4)`
  margin: 37px 0 34px;
`;

const HistoryTableContainer = styled.div`
  width: 1520px;
  overflow: hidden;
`;

export const PrintableTablePage = () => {
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();

  const patientAppointmentsTrigger = useTrigger(PatientAppointmentsRefreshTrigger);
  const appointmentResult = useApiRequest(async () => {
    if (params.appointment_id) {
      return await apiv2.appointments.getAppointmentById(params.appointment_id);
    }
  }, [patientAppointmentsTrigger, token, params.appointment_id]);
  const layoutResult = useApiRequest(async () => {
    if (appointmentResult.data && appointmentResult.data.layout.id) {
      return await apiv2.appointments.getLayout(parseInt(appointmentResult.data && appointmentResult.data.layout.id));
    }
  }, [appointmentResult.data && appointmentResult.data.layout.id]);

  const { logoEl, isLogoLoaded, setLogoLoaded } = useLogo();

  const appointment = appointmentResult.data;
  const layout = layoutResult.data;

  const organization = useOrganization(appointment);
  const contacts = useContacts(organization);
  useBeforePrint(
    (isLogoLoaded && appointment && layout && contacts && !appointment.in_progress) || false
  );
  useAfterPrint((appointment && `/appointment/${appointment.id}`) || "");

  const section = _.find(
    layout && layout.sections,
    item => item.id === parseInt(params.section_id)
  );

  const fieldValuesMap = _.keyBy(
    appointment && appointment.layout_field_values,
    item => item.field
  );

  if (!appointment || !layout || !contacts) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  if (appointment.in_progress) {
    return <PNormal>Прием не завершен</PNormal>;
  }

  if (!section) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  const logo = <Logo ref={logoEl} onLoad={() => setLogoLoaded(true)} />;

  const header = (
    <AppointmentPrintableHeaderLayout logo={logo}>
      <AppointmentPrintableHeaderItem
        label="Врач"
        content={getLastNameWithInitials(appointment.doctor)}
      />
      <AppointmentPrintableHeaderItem
        label="Дата"
        content={formatAppointmentDateToDateYearWithWords(appointment.date)}
      />
      <AppointmentPrintableHeaderItem
        label="Пациент"
        content={getPatientFullName(appointment.medical_file.patient)}
      />
      <AppointmentPrintableHeaderItem label="Контакты" content={contacts} />
    </AppointmentPrintableHeaderLayout>
  );

  const table = getTableFromSection(section);
  const printContent = table ? (
    <HistoryTableContainer>
      <TableWidget
        editable={false}
        field={table}
        appointment_id={getAppointment_id(appointment)}
        layoutFieldValue={fieldValuesMap[table.id]}
      />
    </HistoryTableContainer>
  ) : '';

  const title = <Title>{ table?.name || 'Таблица' }</Title>;

  const sign = (
    <AppointmentSign
      date={formatAppointmentDateToDateWithWords(appointment.date)}
      doctor={{
        name: getLastNameWithInitials(appointment.doctor),
        regalia: appointment.doctor.regalia || null
      }}
    />
  );

  return (
    <>
      <Container>
        <AppointmentPrintLayout
          isHorizontal
          header={header}
          title={title}
          values={printContent}
          sign={sign}
        />
      </Container>
    </>
  );
};
