// @flow
import moment from "moment";
import type { TField_id } from "../entities/Field";

export opaque type AppointmentCreationOptions = {
  medical_file: number,
  layout: number,
  date: string,
  time: string,
  f_layout?: number,
  folder?: number
};

export const createAppointmentCreationOptionsOutsideSchedule = (options: {
  medical_file: number,
  f_layout?: number,
  folder?: number,
  layout: number
}): AppointmentCreationOptions => {
  const currentDate = moment();
  return {
    ...options,
    date: currentDate.format("YYYY-MM-DD"),
    time: currentDate.format("HH:mm:ss")
  };
};

export opaque type AppointmentCopyOptions = {
  date: string,
  time: string,
  medical_file_id: number,
  f_layout_id?: number,
  folder_id?: number
};

export type FiledValuesForSave = Array<{
  field: TField_id,
  value: string | string[],
  files: number
}>;
