import React from "react";
import { colors } from "../../styleguide/colors";

const Icon = props => {
  const { color = colors.gray600, size = 26 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4316 32.4197H43.3575M22.4316 32.4197L27.9561 38.5045M22.4316 32.4197L27.9561 26.3341M1.9409 32C1.9409 15.399 15.3987 1.9412 31.9998 1.9412C48.6008 1.9412 62.0586 15.399 62.0586 32C62.0586 48.601 48.6008 62.0588 31.9998 62.0588C15.3987 62.0588 1.9409 48.601 1.9409 32Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
