// @flow
import React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import { PNormal } from "../styleguide/typography";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Separator = styled.div`
  width: 1px;
  height: 65px;
  border-right: solid 1px ${colors.transparentGray};

  margin-left: 63px;
  margin-top: 6px;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const ItemsContainer = styled.div`
  width: 795px;
  padding: 11px 34px 0;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 0 0px;
`;

const ItemContainer = styled.div`
  display: flex;
  min-height: 33px;
`;

const ItemLabelContainer = styled.div`
  min-width: ${props => (props.notFixedWidth ? "auto" : "85px")};
  margin-right: 11px;
  opacity: 0.6;
  flex-shrink: 0;
`;

const ItemContentContainer = styled.div`
  display: flex;

  & > * {
    display: block;
  }

  & > ${PNormal} + ${PNormal} {
    margin-left: 18px;
  }
`;

const ContainerWithoutLogo = styled(Container)`
  width: 100%;
  justify-content: flex-end;
`;

export const AppointmentPrintableHeaderItem = (props: {
  label?: string,
  content?: string | Array<string>,
  notFixedWidth?: ?boolean,
  children?: React$Node
}) => {
  return (
    <ItemContainer>
      {props.label && (
        <ItemLabelContainer notFixedWidth={props.notFixedWidth}>
          <PNormal modifiers="left">
            {props.label}
            {props.notFixedWidth && ":"}
          </PNormal>
        </ItemLabelContainer>
      )}
      {props.content && (
        <ItemContentContainer>
          {props.content instanceof Array ? (
            props.content.map((contentItem, id) => {
              return (
                <PNormal key={id} modifiers="left">
                  {contentItem}
                </PNormal>
              );
            })
          ) : (
            <PNormal modifiers="left">{props.content}</PNormal>
          )}
        </ItemContentContainer>
      )}
      {props.children}
    </ItemContainer>
  );
};

export const AppointmentPrintableHeaderLayout = (props: {
  logo: React$Node,
  children: React$Node
}) => {
  return (
    <Container>
      {props.logo}
      <ContentContainer>
        <Separator />
        <ItemsContainer>{props.children}</ItemsContainer>
      </ContentContainer>
    </Container>
  );
};

export const AppointmentPrintableHeaderContainerWithoutLogo = (props: { children: React$Node }) => {
  return (
    <ContainerWithoutLogo>
      <ItemsContainer>{props.children}</ItemsContainer>
    </ContainerWithoutLogo>
  );
};
