// @flow
import React from "react";
import { FormSection } from "./FormSection";
import type { Section } from "../../domain/entities/Section";

type AlwaysExpandedFormSectionProps = $Diff<
  React$ElementConfig<typeof FormSection>,
  { expand_by_default: $ElementType<Section, "expand_by_default"> }
>;

export const AlwaysExpandedFormSection = (props: AlwaysExpandedFormSectionProps) => {
  return (
    <FormSection
      {...props}
      expand_by_default
      overrides={{
        ArrowButton: {
          component: () => null
        },
        HeaderContainer: {
          props: {
            onClick: null,
            as: "div",
            "aria-label": null
          }
        }
      }}
    />
  );
};
