import React from 'react';
import {observer} from 'mobx-react';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import doctorsStore from '../../stores/doctorsStore';

export const PushNotificationTestModal = observer(() => {
  return (
    <Dialog
      onClose={() => doctorsStore.changeShowNotificationModal(false)}
      open={doctorsStore.showNotificationModal}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Тестирование мобильных уведомлений
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => doctorsStore.changeShowNotificationModal(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          Здравствуйте, мы отправили вам push-уведомление на мобильное приложение с текстом &quot;Тестовое push-уведомление&quot;. Проверьте, что оно пришло, если нет, то проверьте настройки уведомлений для нашего приложения на вашем устройстве.
        </Typography>
        <Typography gutterBottom>
          Если уведомления в настройках выключены, то включите их и попробуйте отправить тестовое уведомление еще раз. Если повторная отправка не сработала, то перезагрузите приложение и попробуйте снова.
        </Typography>
        <Typography gutterBottom>
          Если после всех проделанных шагов уведомления не приходят, то напишите нам в поддержку в <a target="_blank" rel="noreferrer" href="https://t.me/fominclinic_internalsupport_bot">телеграм бот</a>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => doctorsStore.changeShowNotificationModal(false)}>
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
});
