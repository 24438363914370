// @flow
import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { NewTableFormLayout } from "./NewTableFormLayout";
import { HeadingH3 } from "../styleguide/typography";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { NumericInput } from "../form-elements/NumericInput";
import { ModalWithCloseButtonLayout } from "../modal/ModalWithCloseButtonLayout";
import { FieldsetVertical } from "../form/FieldsetVertical";
import { DateInput } from "../form-elements/legacy/DateInput";
import type { PrescriptionTableParams } from "../../domain/value-objects/Prescription";
import moment from "moment";
import { READABLE_DATE_FORMAT } from "../../domain/services/field";

const InputContainer = styled.div`
  max-width: 557px;
  width: 100%;
`;

const inputOverrides = {
  Container: {
    component: InputContainer
  },
  Input: {
    props: {
      modifiers: "big"
    }
  }
};

const isValidDate = date =>
  !date || (date.length === 10 && moment(date, READABLE_DATE_FORMAT).isValid());

export const NewTableForm = (props: {
  onClose: () => mixed,
  onSubmit: (params: PrescriptionTableParams) => mixed
}) => {
  const [startDate, setStartDate] = useState("");
  const [cycleDay, setCycleDay] = useState("");
  const [duration, setDuration] = useState("1");

  const isValidStartDate = useMemo(() => isValidDate(startDate), [startDate]);

  const header = <HeadingH3>Новая таблица</HeadingH3>;

  const onSubmit = () => {
    const formattedDate = startDate && moment(startDate, READABLE_DATE_FORMAT).format("YYYY-MM-DD");
    props.onSubmit({
      startDate: formattedDate,
      cycleDay: parseInt(cycleDay),
      duration: parseInt(duration)
    });
  };

  const button = (
    <PrimaryButton disabled={!isValidStartDate} onClick={onSubmit}>
      Создать таблицу
    </PrimaryButton>
  );

  return (
    <ModalWithCloseButtonLayout handleClose={props.onClose}>
      <NewTableFormLayout header={header} button={button}>
        <FieldsetVertical legend={"Выберите дату начала лечения:"}>
          <DateInput value={startDate} handleChange={setStartDate} overrides={inputOverrides} />
        </FieldsetVertical>
        <FieldsetVertical legend={"Укажите день цикла:"}>
          <NumericInput value={cycleDay} handleChange={setCycleDay} overrides={inputOverrides} />
        </FieldsetVertical>
        <FieldsetVertical legend={"Укажите длительность лечения:"}>
          <NumericInput value={duration} handleChange={setDuration} overrides={inputOverrides} />
        </FieldsetVertical>
      </NewTableFormLayout>
    </ModalWithCloseButtonLayout>
  );
};
