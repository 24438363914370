import { makeAutoObservable } from "mobx";
import _ from "lodash";
import apiv2 from "../apiv2";
import * as yup from "yup";
import { currentUser } from "./auth";
import Binder from "../utils/binder";
import { validationSchemaTemplates } from "../utils/validationSchemaTemplates";

class SurveyStore {
  surveyId = false;
  surveyData = null;
  currentStep = '';
  currentStepData = [];
  currentStepValues = {};
  currentStepOldValues = {}
  currentFieldsStep = {};
  fieldsWithOtherValueById = {};
  validateSchema = {};
  isInitialized = false;
  showModalInformationSurvey = false;
  surveyCompleted = false;

  constructor() {
    Binder.bind(this, SurveyStore);
    makeAutoObservable(this);
  }

  changeSurveyCompleted(value) {
    this.surveyCompleted = value;
  }

  changeCurrentStepData() {
    if (!this.surveyData.survey.step_by_step) {
      this.currentStepData = this.surveyData.survey.steps;
    } else {
      this.currentStepData = this.surveyData.survey.steps.filter((item) => item.id === this.currentStep);
    }
  }

  setSurveyId(id) {
    this.surveyId = id;
  }

  setCurrentFieldsStep(currentFieldsStep) {
    this.currentFieldsStep = currentFieldsStep;
  }

  setDefaultFieldValues() {
    const fieldsByStep = _.cloneDeep(this.currentFieldsStep);
    Object.entries(fieldsByStep).forEach(([step, fields]) => {
      fields.forEach(field => {
        const fieldId = parseInt(field.name.replaceAll("field-", ""));
        const fieldById = _.find(this.surveyData.values, { field: fieldId });
        if (!fieldById) {
          return;
        }

        const fieldValue = JSON.parse(fieldById.value);
        if (!!this.fieldsWithOtherValueById[step] && this.fieldsWithOtherValueById[step][field.name]) {
          const value = field.options.find((option) => option.value === fieldValue && fieldValue.toLowerCase() !== "другое");
          if (!value) {
            const newData = {
              [`other-${fieldId}`]: fieldValue !== "Другое" ? fieldValue : "",
              [field.name]: "Другое"
            };
            this.currentStepValues[step] = { ...this.currentStepValues[step], ...newData };
            this.currentStepOldValues[step] = { ...this.currentStepOldValues[step], ...newData };
            const index = this.currentFieldsStep[step].findIndex((item) => item.name === field.name);
            this.currentFieldsStep[step].splice(index + 1, 0, { ...this.fieldsWithOtherValueById[step][field.name] });
            return;
          }
        }
        this.currentStepValues[step] = { ...this.currentStepValues[step], [field.name]: fieldValue };
        this.currentStepOldValues[step] = { ...this.currentStepOldValues[step], [field.name]: fieldValue };
      });
    });
  }

  setCurrentStepValues({ currentStepValues, reset, changedKey, step }) {
    const transformChangedKey = parseInt(changedKey.split('-')[1]);
    if (this.fieldsWithOtherValueById?.[step] && Object.keys(this.fieldsWithOtherValueById[step]).length && this.fieldsWithOtherValueById[step][changedKey]) {
      if (!this.currentStepOldValues[step][changedKey].includes("Другое") && currentStepValues[changedKey].includes("Другое")) {
        const index = this.currentFieldsStep[step].findIndex((field) => field.name === changedKey);
        this.currentFieldsStep[step].splice(index + 1, 0, { ...this.fieldsWithOtherValueById[step][changedKey] });
        currentStepValues[`other-${transformChangedKey}`] = "";
      } else if (this.currentStepOldValues[step][changedKey].includes("Другое") && !currentStepValues[changedKey].includes("Другое")) {
        this.currentFieldsStep[step] = this.currentFieldsStep[step].filter((item) => item.name !== `other-${transformChangedKey}`);
        delete currentStepValues[`other-${transformChangedKey}`];
        reset({
          ...currentStepValues,
          [`other-${transformChangedKey}`]: ""
        });
      }
    }

    this.setValueField({ field_id: transformChangedKey, value: JSON.stringify(currentStepValues[changedKey]) });
    this.currentStepValues[step] = currentStepValues;
    this.currentStepOldValues[step] = currentStepValues;
  }

  setCurrentStep(currentStep) {
    this.currentStep = currentStep;
  }

  changeShowModalInformationSurvey(value) {
    this.showModalInformationSurvey = value;
  }

  setSurveyData(data) {
    this.surveyData = data;
  }

  async initialize(surveyId, type) {
    const user = currentUser.getState();
    let isChangeSurvey = true;
    let surveyData;
    if (type === 'survey') {
      this.setSurveyId(surveyId);
      if (user) {
        surveyData = await this.getSurveyList(surveyId);
      }
      if (!surveyData) {
        isChangeSurvey = false;
        surveyData = await this.createSurvey();
      }
    } else {
      surveyData = await apiv2.survey.getSurveyResultById(surveyId);
      this.setSurveyId(surveyData?.survey?.id)
    }
    this.setSurveyData(surveyData);
    this.changeStepData(surveyData.current_step);
    if (isChangeSurvey) {
      this.setDefaultFieldValues();
    }
    this.isInitialized = true;
  }

  getStepNumberByStepId(stepId) {
    if (!this.surveyData) {
      return 1;
    }
    return this.surveyData.survey.steps.findIndex((item) => item.id === stepId) + 1;
  }

  get currentStepNumber() {
    return this.getStepNumberByStepId(this.currentStep);
  }

  changeStepData(current_step) {
    this.setCurrentStep(current_step);
    this.changeCurrentStepData();
    if (this.currentStepData.length) {
      this.generateFieldsStep();
    }
  }

  addAdditionalField({ step, field}) {
    if (field.options.find((item) => item.text.toLowerCase() === "другое")) {
      this.fieldsWithOtherValueById[step] = {
        ...this.fieldsWithOtherValueById[step],
        ['field-' + field.id]: {
          placeholder: field.text_for_other_option || "",
          type: "text",
          name: 'other-' + field.id,
          xs: 12
        }
      }
    }
  }

  async getSurveyList(surveyId) {
    try {
      const findLastUpdateSurvey = (arr) => {
        const arr2 = arr.filter((item) => !item.is_finished);
        return _.sortBy(arr2, "updated_at").reverse()[0];
      };

      const surveys = await apiv2.survey.getSurveyList(surveyId);
      return findLastUpdateSurvey(surveys) || null;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async createSurvey() {
    try {
      return await apiv2.survey.createSurvey({ "survey_id": this.surveyId });
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async setValueField(data) {
    try {
      await apiv2.survey.setValueSurvey(this.surveyData.id, data);
    } catch (e) {
      console.log(e);
    }
  }

  async finishStep(data) {
    try {
      const step = _.last(Object.keys(this.currentStepValues));
      let transformData = [];
      Object.values(this.currentStepValues).forEach((fields) => {
        Object.keys(fields).forEach((field) => {
          const fieldValue = JSON.stringify(fields[field]);
          if (field.startsWith("other")) {
            const fieldId = parseInt(field.replaceAll('other-', ''));
            const fieldById = transformData.find((data) => data.field_id === fieldId);
            fieldById.value = fieldValue;
          } else {
            const fieldId = parseInt(field.replaceAll('field-', ''));
            transformData.push({
              field_id: fieldId,
              value: fieldValue
            });
          }
        })
      });
      if (data) {
        transformData = transformData.map((item) => {
          const dataField = data.find((field) => field.field_id === item.field_id);
          if (dataField) {
            item.value = dataField.value;
          }
          return item;
        })
      }
      const response = await apiv2.survey.finishStep(this.surveyData.id, {
        step_id: this.surveyData.survey.step_by_step ? this.currentStep : step,
        step_values: transformData
      });
      this.setSurveyData(response);
      if (response.is_finished) {
        this.surveyCompleted = true;
        return;
      }
      document.getElementsByClassName('survey-container')[0]?.scrollTo(0, 0);
      this.changeStepData(response.current_step);
    } catch (e) {
      console.log(e);
    }
  }

  generateFieldsStep() {
    const TYPES_TRANSFORM = {
      text: 'text',
      textarea: 'textarea',
      date: 'date',
      number: 'number',
      checkbox: 'chips',
      radiobutton: 'chips',
    }

    const fields = {};
    const values = {};
    const schema = {};
    this.validateSchema = {};

    const validateSchemeByTypeField = {
      text: validationSchemaTemplates.string,
      textarea: validationSchemaTemplates.string,
      date: validationSchemaTemplates.date,
      number: validationSchemaTemplates.string,
      checkbox: validationSchemaTemplates.string,
      radiobutton: validationSchemaTemplates.array,
    };

    this.currentStepData.forEach((item) => {
      fields[item.id] = [];
      values[item.id] = {};
      item.fields.forEach((field) => {
        if (TYPES_TRANSFORM[field.field_type] === 'text') {
          fields[item.id].push({
            type: TYPES_TRANSFORM[field.field_type],
            name: 'field-' + field.id,
            label: field.hide_name ? '' : field.name,
            required: field.required,
            withLabel: true,
            subInfo: field.hint,
            xs: 12
          })
        } else if (TYPES_TRANSFORM[field.field_type] === 'textarea') {
          fields[item.id].push({
            type: TYPES_TRANSFORM[field.field_type],
            name: 'field-' + field.id,
            label: field.hide_name ? "" : field.name,
            required: field.required,
            withLabel: true,
            subInfo: field.hint,
            xs: 12
          })
        } else if (TYPES_TRANSFORM[field.field_type] === 'date') {
          fields[item.id].push({
            typePicker: 'date',
            type: TYPES_TRANSFORM[field.field_type],
            name: 'field-' + field.id,
            required: field.required,
            withLabel: true,
            label: field.hide_name ? '' : field.name,
            subInfo: field.hint,
            xs: 12
          })
        } else if (TYPES_TRANSFORM[field.field_type] === 'number') {
          fields[item.id].push({
            type: TYPES_TRANSFORM[field.field_type],
            name: 'field-' + field.id,
            required: field.required,
            withLabel: true,
            label: field.hide_name ? '' : field.name,
            subInfo: field.hint,
            xs: 12
          })
        } else if (TYPES_TRANSFORM[field.field_type] === 'radio') {
          fields[item.id].push({
            type: TYPES_TRANSFORM[field.field_type],
            name: 'field-' + field.id,
            label: field.hide_name ? '' : field.name,
            subInfo: field.hint,
            withLabel: true,
            required: field.required,
            options: field.options.map((item) => {
              return { value: item.text, label: item.text };
            }),
            xs: 12
          })
        } else if (TYPES_TRANSFORM[field.field_type] === 'chips') {
          fields[item.id].push({
            type: TYPES_TRANSFORM[field.field_type],
            name: 'field-' + field.id,
            label: field.hide_name ? '' : field.name,
            subInfo: field.hint,
            withLabel: true,
            required: field.required,
            multiple: field.field_type === 'radiobutton',
            options: field.options.map((item) => {
              return { value: item.text, label: item.text };
            }),
            xs: 12
          })
          if (field.field_type === 'checkbox') {
            this.addAdditionalField({ step: item.id, field });
          }
        }

        values[item.id] = {
          ...values[item.id],
          ['field-' + field.id]: ''
        }

        if (validateSchemeByTypeField[field.field_type] && field.required) {
          schema[item.id] = {
            ...schema[item.id],
            ["field-" + field.id]: field.field_type
          };
        }
      })
    });

    Object.entries(schema).forEach(([step, fields]) => {
      const schemas = {};

      Object.entries(fields).forEach(([field, type]) => {
        schemas[field] = validateSchemeByTypeField[type];
      });

      this.validateSchema[step] = yup.object({ ...schemas });
    });

    this.setCurrentFieldsStep(fields);
    this.currentStepValues = values;
    this.currentStepOldValues = values;
  }
}

export default new SurveyStore();
