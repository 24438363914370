// @flow
import React, { useEffect, useMemo } from "react";
import feedbackStore from "../../stores/feedbackStore";
import moment from "moment";
import { READABLE_DATE_FORMAT } from "../../utils/dates";
import styled from "styled-components";
import { observer } from "mobx-react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { colors } from "../styleguide/colors";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import arrowIcon from "../appointment-history/icons/arrow.svg";
import callNeededIcon from "../appointment-feedback/icons/icons-clock.svg";
import { Box, IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from "react-router-dom";

const PatientAndSurveyName = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const ArrowContainer = styled(Link)`
    width: 100%;
    cursor: pointer;
    
    * {
        width: 20px;
        height: 20px;
    }
    
    color: ${colors.gray300};
    font-weight: 200;
    
    &:hover * {
        color: ${colors.magenta};
    }
`;

const Arrow = styled.div`
  width: 20px;
  height: 20px;
  background: url(${arrowIcon});
`;

const CallNeededIcon = styled.div`
  width: 20px;
  height: 20px;
  background: url(${callNeededIcon});
`;

const PatientFullName = styled.div`
    color: rgb(38, 38, 38);
    font-size: 15px;
    line-height: 1.53;
`;

const SurveyName = styled.div`
    font-size: 12px;
    color: rgb(89, 89, 89);
`;

const Container = styled.div`
    width: 990px;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
    
    
    @media (max-width: 1240px) {
        width: 100%;
    }
`;

export const FeedbackResultPage = observer(() => {

  useEffect(() => {
    feedbackStore.getFeedbackResults()
  }, []);

  const data = useMemo(
    () => {
      const results = [];
      feedbackStore.feedbackResults.forEach((item) => {
        const rateValue = item.values.find((value) => value.field.name === 'Пожалуйста, оцените работу вашего доктора:');
        const callValue = item.values.find((value) => value.field.name === 'Хотите ли вы получить звонок от вашего лечащего врача для обсуждения лечения?');
        results.push({
          id: item.id,
          created_at: item.updated_at,
          patientFullName: `${item.patient?.last_name} ${item.patient?.first_name} ${item.patient?.middle_name}`,
          surveyName: item.survey.name,
          rate: rateValue ? JSON.parse(rateValue.value) : '',
          call: callValue ? JSON.parse(callValue.value) : '',
          appointment: item.appointment
        })
      })
      return results
    }, [feedbackStore.feedbackResults]
  )

  const renderRowActions = ({ row }) => (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <Link to={`${location.pathname}/${row.original.id}`}>
        <Tooltip title="Просмотр">
          <IconButton>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Link>
    </Box>
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'created_at',
        header: 'Дата',
        Cell: ({ cell }) =>
          cell.getValue() ? moment(cell.getValue()).format(READABLE_DATE_FORMAT) : '',
        size: 100,
      },
      {
        accessorKey: 'patientAndSurveyName',
        header: 'Пациент и шаблон',
        size: 270,
        Cell: ({ row }) => {
          return (
            <PatientAndSurveyName>
              <PatientFullName>{row.original.patientFullName}</PatientFullName>
              <SurveyName>{row.original.surveyName}</SurveyName>
            </PatientAndSurveyName>
          )
        }
      },
      {
        accessorKey: 'rate',
        header: 'Оценка лечения',
        filterFn: 'in',
        filterVariant: 'multi-select',
        size: 130,
        filterSelectOptions: [
          {
            value: '1',
            label: '1'
          },
          {
            value: '2',
            label: '2'
          },
          {
            value: '3',
            label: '3'
          },
          {
            value: '4',
            label: '4'
          },
          {
            value: '5',
            label: '5'
          },
        ],
      },
      {
        accessorKey: 'call',
        header: 'Нужен звонок',
        size: 130,
        Cell: ({row}) => {
          return row.original.call === 'Да' ? <CallNeededIcon/> : 'Нет'
        }
      },
      {
        accessorKey: 'appointment',
        header: 'Прием',
        Cell: ({ row }) => (
          <ArrowContainer to={`/appointment/${row.original.appointment}`}>
            <ArrowForwardIosIcon/>
          </ArrowContainer>
        ),
        size: 100,
      },
    ],
    [],
  );


  const table = useMaterialReactTable({
    columns,
    data: data,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableColumnActions: false,
    enableHiding: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableSorting: false,
    enableRowActions: true,
    renderRowActions: renderRowActions,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        height: "100%"
      }
    },
    muiTableBodyProps: {
      sx: {
        "& a:hover > td": {
          color: "#c6007f !important"
        },
        "& a:hover > td *": {
          color: "#c6007f !important"
        },
      }
    },
    layoutMode: "grid",
    localization: { ...MRT_Localization_RU, noRecordsToDisplay: "У вас еще не было обратной связи" },
  });

  return (
    <Container>
      <MaterialReactTable table={table} />
    </Container>
  );
});
