import React from "react";
import useReactRouter from "use-react-router";
import { useStore } from "effector-react";
import styled from "styled-components";
import { HeadingH2 } from "../styleguide/typography";
import { tokenStore, currentUser } from "../../stores/auth";
import { IvrAnalysesTable } from "../ivr-analyses-table/IvrAnalysesTable";
import { AnalyzesFormLayout } from "./AnalyzesFormLayout";
import { FilesFormLayout } from "./FilesFormLayout";
import { FolderPageContext } from "./FolderPageContext";
import { BackLink } from "./BackLink";
import { FolderAnalysesPrintButton } from "./FolderAnalysesPrintButton";
import { TableSaveIndicator } from "../table-save-indicator/TableSaveIndicator";
import apiv2 from '../../apiv2';
import { patientAnalysesFilter } from "./patientAnalysesFilter";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 32px 0 32px;
  @media (max-width: 768px) {
    padding: 25px 30px 0 30px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px 0 15px;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 3px;
`;

export const FolderAnalysesPage = () => {
  const user = useStore(currentUser);
  const { folder } = React.useContext(FolderPageContext);
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();
  const [analyses, setAnalyses] = React.useState(null);
  const [syncing, setSyncing] = React.useState(false);

  React.useEffect(() => {
    apiv2.appointments.getFolderAnalyses(params.id).then(data => {
      setAnalyses(data.filter(patientAnalysesFilter));
    });
  }, [params.id, token]);

  const isCurrentDoctor =
    user && user.role === "doctor" && user.doctor && user?.doctor?.id === folder.doctor_id;

  const title = <HeadingH2>Анализы</HeadingH2>;
  const sections = analyses ? (
    <Wrapper>
      <HeaderContainer>
        <HeaderTitle>
          <BackLink link={`/appointment/folder/${folder?.id}`}>{folder.name}</BackLink>
          <div style={{ marginLeft: 22, marginBottom: 6 }}>
            <TableSaveIndicator saving={syncing} />
          </div>
        </HeaderTitle>
        <FolderAnalysesPrintButton />
      </HeaderContainer>
      <IvrAnalysesTable
        data={analyses}
        folderId={folder?.id}
        readOnly={!isCurrentDoctor}
        setSyncing={setSyncing}
      />
    </Wrapper>
  ) : null;

  return (
    <AnalyzesFormLayout
      title={title}
      sections={sections}
      layout="Анализы"
      analyzes={<FilesFormLayout type="analysis" />}
    />
  );
};
