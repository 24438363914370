// @flow
import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { FormInputTextarea } from "../common/dynamic-form/fields/FormInputTextarea";
import { useForm } from "react-hook-form";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import ticketStore from "../../stores/ticketStore";
import SendIcon from "@mui/icons-material/Send";
import { scrollbars } from "../chats/style-elements";
import { styled } from "@mui/material/styles";

const CommentsContainer = styled(Paper)(() => `
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto 20px;
  @media (max-width: 768px) {
   margin: 0;
  }
`
);

const OtherCommentsBlockStyled = styled(Stack)(() => `
  padding: 20px;
  gap: 20px;
  overflow: auto;
  max-height: 500px;
  ${scrollbars}
`
);

const CommentsWrapper = styled(Box)(() => `
  display: flex;
  gap: 10px;
`
);

const Body = styled(Box)(() => `
  display: flex;
  flex-direction: column;
  gap: 10px;
`
);

const Header = styled(Box)(() => `
  display: flex;
  align-items: center;
  gap: 10px;
`
);

const FormInputStyled = styled(FormInputTextarea)(() => `
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`
);

const OtherCommentsBlock = observer(() => {
  const blockRef = useRef(null);
  useEffect(() => {
    blockRef?.current?.scrollTo({ top: blockRef.current.scrollHeight, behavior: "smooth" });
  }, [ticketStore.comments.length]);

  return (
    <OtherCommentsBlockStyled ref={blockRef}>
      {!ticketStore.comments.length ? (
        <Typography fontSize={16} variant="caption">Комментарии отсутствуют</Typography>
      ) : (
        ticketStore.comments.map(({ id, avatar, fullName, date, text }) => (
          <CommentsWrapper key={id}>
            <Avatar alt="Avatar" src={avatar.src}>
              {avatar.placeholder}
            </Avatar>
            <Body>
              <Header>
                <Typography variant="subtitle2">{fullName}</Typography>
                <Typography variant="caption">{date}</Typography>
              </Header>
              {text.map((el, index) => (
                <Typography key={index}>{el}</Typography>
              ))}
            </Body>
          </CommentsWrapper>
        ))
      )}
    </OtherCommentsBlockStyled>
  );
});

const CommentBlock = () => {
  const defaultValues = { comment: "" };
  const { control, reset, handleSubmit } = useForm(defaultValues);
  const sendMessage = (data) => {
    ticketStore.sendCommentByTicket(data);
    reset(defaultValues);
  };

  return (
    <form onSubmit={handleSubmit(sendMessage)} noValidate>
      <FormInputStyled
        placeholder="Напишите комментарий"
        control={control}
        type="textarea"
        name="comment"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSubmit(sendMessage)} edge="end">
                <SendIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </form>
  );
};

export const TicketComments = () => {
  return (
    <CommentsContainer>
      <OtherCommentsBlock />
      <Divider />
      <CommentBlock />
    </CommentsContainer>
  );
};
