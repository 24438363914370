// @flow
import React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";

import plusIcon from "./icons/plus.inline.svg";

const Container = styled.button.attrs(props => ({
  type: "button",
  ...props
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  background: transparent;
  border: none;

  cursor: pointer;
`;

const Icon = styled(plusIcon)`
  width: 13px;
  height: 13px;
  margin-top: -1px;

  ${Container}:hover & {
    path {
      stroke: ${colors.gray600};
    }
  }
`;

const TextContainer = styled.div`
  margin-left: 8px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: ${colors.silver};
  text-transform: uppercase;

  ${Container}:hover & {
    color: ${colors.gray600};
  }
`;

export const AddGroupButton = (props: { onClick?: () => mixed }) => {
  return (
    <Container {...props}>
      <Icon />
      <TextContainer>Добавить группу</TextContainer>
    </Container>
  );
};
