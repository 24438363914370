import * as React from "react";
import { getTextValue } from "../appointment-widgets/getTextValue";
import { ShortcutsTextarea } from "../shortcuts/ShortcutsTextarea";

export const TextareaWidget = ({ field, tabIndex, layoutFieldValue, onChange }) => {
  const value = getTextValue(field, layoutFieldValue);

  return (
    <ShortcutsTextarea
      value={value}
      tabIndex={tabIndex}
      handleChange={newValue => {
        onChange({
          field_type: field.field_type,
          field_id: field.id,
          value: newValue
        });
      }}
      isHaveShortcuts={false}
    />
  );
};
