import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Analyses {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  patchAnalyse(id, params) {
    return this.apiService.patch(`analyses/${id}`, {}, params);
  }

  removeAnalyse(id) {
    return this.apiService.delete(`analyses/${id}`);
  }
}

export default new Analyses();
