// @flow
import { useState, useEffect } from "react";
import _ from "lodash";

import { useApiRequest } from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

export const useLayoutRadioGroups = (
  group?: string
): {
  items: { [string]: { name: string } },
  checked?: string,
  onChange?: (key?: string) => mixed
} => {
  const [checked, onChange] = useState();

  useEffect(() => {
    onChange(undefined);
  }, [group]);

  const emptyResult = { items: {} };
  const { data: layouts, error } = useApiRequest(async () => {
    if (group) {
      return await apiv2.appointments.getLayoutsByGroupID(group);
    }
  }, [group]);

  if (!layouts || error) {
    return emptyResult;
  }
  const items = _.chain(layouts)
    .keyBy(item => `${item.id}`)
    .value();

  const order = layouts.map(layout => `${layout.id}`);

  return { items, order, checked, onChange };
};
