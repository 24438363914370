import styled from "styled-components";

import { BaseStyledInput } from "../../form-elements/BaseStyledInput";

export const Container = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 100%;
`;

export const DefaultInputContainer = styled.div`
  width: 100%;
`;

export const CalendarContainer = styled.div`
  position: fixed;
  display: block;
  box-shadow: 0 2px 17px rgba(91, 91, 91, 0.259752);
  border-radius: 3px;
  overflow: hidden;
  z-index: 5123129;
`;

export const CalendarContent = styled.div`
  position: relative;
  padding-bottom: 40px;
  background: #fff;
`;

export const CalendarInput = styled(BaseStyledInput)`
  border-radius: 0;
  border: none;
  height: 100%;
  font-size: 15px;
  padding: 10px 15px;
  height: 44px;
`;

export const TodayButton = styled.button`
  position: absolute;
  bottom: 0;
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  font-family: Graphik, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  color: #c6007f;
  cursor: pointer;

  &:hover {
    background: rgba(151, 151, 151, 0.1);
  }
`;
