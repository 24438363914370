// @flow
import React from "react";
import ReactDOM from "react-dom";
import { useEl } from "../../utils/useEl";

const modalRoot = document.getElementById("right-sidebar-root");

export const RightSidebarPortal = (props: { children: React$Node }) => {
  const el = useEl(modalRoot);

  return ReactDOM.createPortal(<React.Fragment>{props.children}</React.Fragment>, el.current);
};
