// @flow
import styled from "styled-components";

export const ShadowContainerDefault = styled.div`
  position: relative;
  width: 100%;
  :after,
  :before {
    content: "";
    position: absolute;
    top: 0;
    width: 13px;
    height: 100%;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.2s;
  }

  :before {
    left: ${props => props.width}px;
    background-image: linear-gradient(to left, rgba(129, 129, 129, 0), #8e8e8e);
    opacity: ${props => props.left && 0.1};
  }

  :after {
    right: 0;
    background-image: linear-gradient(to right, rgba(129, 129, 129, 0), #8e8e8e);
    opacity: ${props => props.right && 0.1};
  }
`;
