import styled from "styled-components";

// TODO: Поменять кнопки в звонке на эти.
export const CircleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #DCD3D2;
  border: none;
  cursor: pointer;
  
  &:hover {
    filter: brightness(1.1);
  }
  
  &:active {
    filter: brightness(0.9);
  }
`;
