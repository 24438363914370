// @flow
import styled from "styled-components";

export const GroupContainer = styled.div`
  & + & {
    margin-top: 25px;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
    @media (max-width: 480px) {
      margin-top: 15px;
    }   
  }
`;
