import { makeAutoObservable } from "mobx";
import Binder from "../../utils/binder";
import { dataOfDoctors } from "../utils/employees/generatedEmployeesData";
import { ACCORDION_CONTENT_TYPES } from "../components/CorporateAccordion";
import corporateEmployees from "../api/entities/corporateEmployees";

const tabs = [
  { id: "employees", name: "Сотрудники" },
  { id: "structure", name: "Структура компании" }
];

export const ACCORDION_NESTING_LEVELS = {
  CITIES: "cities",
  ORGANIZATIONS: "organizations",
  SPECIALIZATIONS: "specializations"
};

const transformEmployeesData = (data) => {
  return data.map(item => {
    const splitFullName = item.full_name.split(' ');
    return {
      id: item.id,
      photo: item.photo,
      fullName: item.full_name,
      contact_information: {
        first_name: splitFullName[1],
        middle_name: splitFullName[2],
        last_name: splitFullName[0],
        work_phone_number: item.work_phone_number,
        birthdate: item.birthdate,
        personal_phone_number: item.phone_number,
        city: item.city_name
      },
      working_information: {
        position: item.post_name,
        subdivision: item.department_name
      },
      additional_information: {},
      interests: []
    };
  });
};

const transformData = data => {
  if (data.hasOwnProperty("city")) {
    if (data.city.name === 'Москва') {
      data.city.organizations = data.city.organizations.filter((el) => el.id !== 14)
    }
    return {
      level: ACCORDION_NESTING_LEVELS.ORGANIZATIONS,
      type: ACCORDION_CONTENT_TYPES.ACCORDION,
      name: data.city.name,
      breadcrumbs: { name: data.city.name, id: data.city.id },
      details: data.city.organizations.map(item => {
        if (item.name.includes(`${data.city.name} - `)) {
          item.name = item.name.replace(`${data.city.name} - `, '');
        }
        if (item.name.includes('Клиника ')) {
          item.name = item.name.replace('Клиника ', '')
        }
        if (item.name.includes('Москва - ')) {
          item.name = item.name.replace('Москва - ', '')
        }
        return transformData({
          clinic: { name: item.name, id: item.id },
          specializations: item.specialties,
          breadcrumbs: {
            city: { name: data.city.name, id: data.city.id }
          }
        })
      })
    };
  }

  if (data.hasOwnProperty("specializations")) {
    return {
      level: ACCORDION_NESTING_LEVELS.SPECIALIZATIONS,
      type: ACCORDION_CONTENT_TYPES.ACCORDION,
      name: `Клиника «${data.clinic.name}»`,
      breadcrumbs: {
        ...data.breadcrumbs,
        organization: { name: data.clinic.name, id: data.clinic.id }
      },
      details: data.specializations?.map(item =>
        transformData({
          specialization: { name: item.name, id: item.id },
          breadcrumbs: {
            ...data.breadcrumbs,
            organization: { name: data.clinic.name, id: data.clinic.id }
          }
        })
      )
    };
  }

  if (data.hasOwnProperty("specialization")) {
    return {
      breadcrumbs: {
        ...data.breadcrumbs,
        specialization: { name: data.specialization.name, id: data.specialization.id }
      },
      type: ACCORDION_CONTENT_TYPES.CONTENT,
      name: data.specialization.name
    };
  }
};

class CorporateEmployeesStore {
  /** Табы */
  isInitialized = false;
  tabs = [];
  selectedTab = {
    index: 0,
    info: null
  };
  /** Аккордион */
  accordionData = [];
  /** Поиск сотрудников по полю ввода */
  searchValue = "";
  foundedEmployees = null;
  /** Контент для таба "Сотрудники" */
  allEmployeesData = dataOfDoctors;
  /** Контент для таба "Структура компании" */
  companyStructureData = [];
  /** Профиль сотрудника */
  isShowProfileEmployee = false;
  profileEmployeeData = null;

  constructor() {
    Binder.bind(this, CorporateEmployeesStore);
    makeAutoObservable(this);
  }

  setInitialized(flag) {
    this.isInitialized = flag;
  }

  setTabs() {
    this.tabs = tabs;
    this.changeSelectedTab(0);
  }

  openProfileEmployee(employeeData) {
    if (!employeeData) {
      return;
    }
    this.profileEmployeeData = employeeData;
    this.isShowProfileEmployee = true;
  }

  closeProfileEmployee() {
    this.isShowProfileEmployee = false;

    /** Для корректной отработки анимации */
    setTimeout(() => {
      this.profileEmployeeData = null;
    }, 200);
  }

  changeSelectedTab(key) {
    if (key !== 0 && this.searchValue.length) {
      this.searchValue = "";
    }
    this.selectedTab = {
      index: key,
      info: this.tabs[key]
    };

    if (this.tabs[key].id === "employees") {
      this.getAccordionData();
    }

    if (this.tabs[key].id === "structure") {
      this.getCompanyStructureData();
    }
  }

  async getAccordionData() {
    if (this.accordionData.length) {
      return;
    }
    this.setInitialized(false);
    try {
      const response = await corporateEmployees.getEmployeesInfo();
      this.accordionData = response.map(item => transformData(item));
      this.setInitialized(true);
    } catch (e) {
      console.error(e);
    }
  }

  async getEmployeesData(breadcrumbs) {
    const response = await corporateEmployees.getListOfUsers({
      cityId: breadcrumbs.city.id,
      organizationId: breadcrumbs.organization.id,
      specialityId: breadcrumbs.specialization.id
    });

    return transformEmployeesData(response, breadcrumbs?.city?.name);
  }

  getCompanyStructureData() {
    if (this.companyStructureData.length) {
      return;
    }

    this.companyStructureData = [{}];
  }

  async changeSearchValue(value) {
    this.foundedEmployees = null;
    if (this.searchValue === value) {
      return;
    }

    this.searchValue = value;

    if (!this.searchValue.length) {
      return;
    }

    const resp = await corporateEmployees.getListOfUsers({ searchValue: this.searchValue });
    // ToDo для тестовой среды, удалить после интеграции
    const filteredDataEmployee = this.allEmployeesData.filter(employee =>
      employee.fullName.toLowerCase().includes(value.toLowerCase())
    );

    if (!filteredDataEmployee.length) {
      return;
    }

    this.foundedEmployees = filteredDataEmployee.map(employee => {
      return {
        cities: employee.working_information.cities,
        organizations: employee.working_information.organizations,
        specializations: employee.working_information.specializations
      };
    });
  }
}

export default new CorporateEmployeesStore();
