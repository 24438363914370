import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Medicines {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api/decision-support/medicines', null, null, API_URL);
  }

  get(search) {
    return this.apiService.get('', {search}, {}, true, "", false, true);
  }

  propose(name) {
    return this.apiService.post('propose', {}, {name});
  }
}

export default new Medicines();
