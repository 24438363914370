// @flow
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  margin-left: 8px;
`;

export const SubpointTemplate = (props: { icon: React$Node, title: React$Node }) => {
  const { icon, title } = props;
  return (
    <Container>
      {icon}
      <TitleContainer>{title}</TitleContainer>
    </Container>
  );
};
