// @flow
import * as React from "react";
import styled from "styled-components";

import { PNormal, PXSmall } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import ArrowIcon from "./icons/arrow-down-gray.svg";

const Container = styled.div`
  width: calc(100% - 48px);
  min-height: 60px;
  padding: 10px 0;
  margin: 0 24px;
  border-bottom: 1px solid ${colors.transparentGray};
  box-sizing: border-box;

  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Arrow = styled.div`
  border: none;
  background: url(${ArrowIcon}) no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;

  transform: rotate(-90deg);
`;

const SectionName = styled(PXSmall)`
  color: ${colors.gray500};
  opacity: 0.4;
  line-height: 1.5;
`;

export const ShortcutsFieldItem = (props: {
  fieldName: string,
  sectionName?: string,
  onClick: () => mixed
}) => {
  return (
    <Container onClick={props.onClick}>
      <div>
        <PNormal modifiers="left">{props.fieldName}</PNormal>
        {props.sectionName && <SectionName modifiers="left">{props.sectionName}</SectionName>}
      </div>
      <Arrow />
    </Container>
  );
};
