// @flow
import * as React from "react";
import styled from "styled-components";
import settingsIcon from "../appointment-page/icons/settings.svg";
import { DefaultButton, Container } from "../buttons/DefaultButton";
import { colors } from "../styleguide/colors";

const ButtonContainer = styled(Container)`
  height: 38px;
`;

const IconContainer = styled.div`
  display: block;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  transition: all 0.15s ease-in-out;

  mask: url(${settingsIcon});
  background: ${colors.black};

  ${ButtonContainer}:hover & {
    background: ${colors.magenta};
  }
`;

const TextContainer = styled.div`
  display: none;
`;

export const SettingsButton = (props: { onClick?: () => mixed }) => (
  <DefaultButton
    {...props}
    overrides={{
      Container: {
        component: ButtonContainer
      },
      Icon: {
        component: Icon
      },
      IconContainer: {
        component: IconContainer
      },
      TextContainer: {
        component: TextContainer
      }
    }}
  />
);
