// @flow
import * as React from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { colors } from "../styleguide/colors";
import { Container, Close } from "../sidebar/SidebarHeader";

const BreadcrumbsContainer = styled(Container)`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 22px 22px 0;
  background-color: ${rgba(colors.gray100, 0.3)};

  position: relative;
`;

export const DiagnosesTreeBreadcrumbsList = (props: {
  children: Array<React.Node>,
  onClose?: () => mixed
}) => {
  return (
    <BreadcrumbsContainer>
      <div>{props.children}</div>
      <Close onClick={props.onClose} />
    </BreadcrumbsContainer>
  );
};
