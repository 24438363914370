import React, { useState } from "react";
import { useAsync, useAsyncFn } from "react-use";
import { useDebouncedCallback } from "use-debounce";

import {
  getServiceCategories,
  getServices,
  addServiceToFavorties,
  removeServiceFromFavorties
} from "./api/services";

import { arrayToEntriesById, entriesToArrayById } from "../../utils/arrays";
import { omitKey } from "../../utils/omit";

import { closeRightSidebar } from "../../stores/rightSidebar";

import { ServicesTreeBreadcrumbs } from "./ServicesTreeBreadcrumbs";
import { ServicesTreeService } from "./ServicesTreeService";
import { ServicesTreeCategory } from "./ServicesTreeCategory";
import { ServicesTreePreloader } from "./ServicesTreePreloader";
import { ServicesTreeSearch } from "./ServicesTreeSearch";

import * as S from "./styled/ServicesTree.styled";
import * as S_ServiceTreeCategory from "./styled/ServicesTreeCategory.styled";
import * as S_ServicesTreeService from "./styled/ServicesTreeService.styled";
import serviceStore from "../../stores/serviceStore";

const activeLevel = levels => levels[levels.length - 1];

export const ServicesTree = ({ appointment }) => {
  const organizationId = appointment.medical_file.organization.id;

  serviceStore.setOrganizationId(organizationId);

  const [services, setServices] = useState(null);
  const [favoriteServices, setFavoriteServices] = useState(null);
  const [searchedServices, setSearchedServices] = useState(null);
  const [levels, setLevels] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const { loading: initialLoading } = useAsync(async () => {
    const categoriesList = await getServiceCategories(organizationId);

    const servicesList = await getServices({ organizationId });
    const favoriteServicesList = await getServices({ organizationId, favorites: true });

    setLevels([
      {
        id: null,
        title: "Услуги",
        active: false,
        categories: arrayToEntriesById(categoriesList)
      }
    ]);

    const favoriteServices = arrayToEntriesById(
      favoriteServicesList.map(item => ({ ...item, category_id: "favorites" }))
    );

    setServices(
      arrayToEntriesById(
        servicesList.map(item => ({
          ...item,
          favorite: favoriteServices[item.id] !== undefined
        }))
      )
    );

    setFavoriteServices(favoriteServices);
  }, [organizationId]);

  const [{ loading: searchedServicesLoading }, loadServices] = useAsyncFn(
    async value => {
      const services = await getServices({ organizationId, searchQuery: value });
      setSearchedServices(arrayToEntriesById(services));
    },
    [organizationId]
  );

  const handleOpenCategory = id => {
    setSearchQuery("");
    setSearchedServices(null);

    if (id === null) {
      setLevels([]);
    } else {
      setLevels(prev => [
        ...prev.map(item => ({ ...item, active: false })),
        {
          id: activeLevel(prev).categories[id].id,
          title: activeLevel(prev).categories[id].name,
          categories: arrayToEntriesById(activeLevel(prev).categories[id].subcategories),
          active: true
        }
      ]);
    }
  };

  const handleOpenLevel = index => {
    setLevels(prev => [
      ...prev.filter((_, i) => i < index),
      {
        ...prev[index],
        active: true
      }
    ]);
  };

  const handleToggleFavorite = id => {
    try {
      if (favoriteServices[id]) {
        removeServiceFromFavorties(organizationId, id);
        setFavoriteServices(omitKey(favoriteServices, id));

        if (activeLevel(levels).id !== "favorites") {
          setServices({
            ...services,
            [id]: {
              ...services[id],
              favorite: false
            }
          });
        }
      } else {
        addServiceToFavorties(organizationId, id);

        setServices({
          ...services,
          [id]: {
            ...services[id],
            favorite: true
          }
        });

        setFavoriteServices({
          ...favoriteServices,
          [id]: services[id]
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectFavorites = () => {
    setSearchQuery("");
    setSearchedServices(null);

    const withResetFavorites = Object.values(services).map(item => ({
      ...item,
      favorite: favoriteServices[item.id] !== undefined
    }));

    setServices(arrayToEntriesById(withResetFavorites));

    setLevels(prev => [
      ...prev.map(item => ({ ...item, active: false })),
      { id: "favorites", title: "Избранные услуги", active: true }
    ]);
  };

  const [handleDebouncedChangeSearchQuery] = useDebouncedCallback(value => {
    if (value) {
      loadServices(value);
    }
  }, 500);

  const handleChangeSearchQuery = value => {
    if (!value) {
      setSearchedServices(null);
    }

    setSearchQuery(value);
  };

  const handleResetSearchQuery = () => {
    setSearchedServices(null);
    setSearchQuery("");
  };

  const loading = initialLoading || searchedServicesLoading;

  return (
    <S.Container>
      <S.Header>
        {levels.length > 1 ? (
          <ServicesTreeBreadcrumbs items={levels} onSelectLevel={handleOpenLevel} />
        ) : (
          <>
            <S.Title>Услуги</S.Title>
            <ServicesTreeSearch
              query={searchQuery}
              onReset={handleResetSearchQuery}
              onChange={e => {
                handleChangeSearchQuery(e.target.value);
                handleDebouncedChangeSearchQuery(e.target.value);
              }}
            />
          </>
        )}
        <S.Close onClick={() => closeRightSidebar()} />
      </S.Header>
      {loading && <ServicesTreePreloader />}
      {!loading && !searchedServices && activeLevel(levels).categories && (
        <S.Categories>
          {levels.length == 1 && (
            <S_ServiceTreeCategory.Container onClick={handleSelectFavorites}>
              Избранное <S_ServicesTreeService.ActiveStarIcon />
            </S_ServiceTreeCategory.Container>
          )}
          {entriesToArrayById(activeLevel(levels).categories).map(({ id, name }) => (
            <ServicesTreeCategory
              title={name}
              key={id}
              onOpen={() => handleOpenCategory(Number(id))}
            />
          ))}
        </S.Categories>
      )}
      {!loading &&
        !searchedServices &&
        entriesToArrayById(services)
          .filter(
            ({ category_id, favorite }) =>
              activeLevel(levels).id === category_id ||
              (activeLevel(levels).id === "favorites" && favorite)
          )
          .map(({ id, name, kdf_code }) => (
            <ServicesTreeService
              key={id}
              title={name}
              code={kdf_code?.trim()}
              favorite={favoriteServices[id]}
              onToggleFavorite={() => handleToggleFavorite(id)}
            />
          ))}
      {!loading &&
        !searchedServices &&
        activeLevel(levels).id === "favorites" &&
        !entriesToArrayById(services).some(({ favorite }) => favorite) && (
          <S.NoResultsMessage>
            Здесь появятся услуги, которые вы добавите в избранное.
          </S.NoResultsMessage>
        )}
      {!loading &&
        searchedServices &&
        entriesToArrayById(searchedServices).length > 0 &&
        entriesToArrayById(searchedServices).map((service) => (
          <ServicesTreeService
            key={service.id}
            title={service.name}
            categoriesText={serviceStore.categoriesText(service)}
            code={service.kdf_code?.trim()}
            favorite={favoriteServices[service.id]}
            onToggleFavorite={() => handleToggleFavorite(service.id)}
          />
        ))}
      {!loading && searchedServices && entriesToArrayById(searchedServices).length === 0 && (
        <S.NoResultsMessage>
          Мы не нашли услугу с таким названием или кодом. Если вы уверены, что такая услуга должна
          быть, обратитесь к администратору клиники.
        </S.NoResultsMessage>
      )}
    </S.Container>
  );
};
