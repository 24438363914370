import React, { useEffect, useRef, useState } from "react";

import { arrayToEntriesById, entriesToArrayById } from "../../../utils/arrays";

import { closeRightSidebar } from "../../../stores/rightSidebar";

import { SidebarTreeBreadcrumbs } from "./SidebarTreeBreadcrumbs";
import { SidebarTreeCategory } from "./SidebarTreeCategory";
import { SidebarTreePreloader } from "./SidebarTreePreloader";
import { SidebarTreeSearch } from "./SidebarTreeSearch";

import { Container, Header} from "../../sidebar/SidebarContainer";

import * as S from "./styled/SidebarTree.styled";

const activeLevel = levels => levels[levels.length - 1];

export const SidebarTree = ({ loading, categories, items, itemRender, onItemClick, rootName, onChangeSearchText, searchText, placeholderSearch }) => {

  const [levels, setLevels] = useState([]);

  useEffect(() => {
    setLevels([
      {
        id: null,
        title: rootName,
        active: false,
        categories: arrayToEntriesById(categories)
      }
    ]);
  }, [categories]);

  const handleOpenCategory = id => {
    onChangeSearchText("");

    if (id === null) {
      setLevels([]);
    } else {
      setLevels(prev => [
        ...prev.map(item => ({ ...item, active: false })),
        {
          id: activeLevel(prev).categories[id].id,
          title: activeLevel(prev).categories[id].name,
          categories: arrayToEntriesById(activeLevel(prev).categories[id].subcategories),
          active: true
        }
      ]);
    }
  };

  const handleOpenLevel = index => {
    setLevels(prev => [
      ...prev.filter((_, i) => i < index),
      {
        ...prev[index],
        active: true
      }
    ]);
  };

  const sortByPosition = (itemA, itemB) => {
    if (itemA.position === null) {
      return 1; // Сдвигаем вниз, что бы сверху шли элементы с позицией
    }
    if (itemB.position === null) {
      return -1; // Сдвигаем A вверх, что бы сверху шли элементы с позицией
    }
    if (itemA.position > itemB.position) return 1;
    if (itemA.position < itemB.position) return -1;
    if (itemA.position === itemB.position) return 0;
    return 0;
  };

  return (
    <Container>
      <Header>
        {levels.length > 1 ? (
          <SidebarTreeBreadcrumbs items={levels} onSelectLevel={handleOpenLevel} />
        ) : (
          <>
            <S.Title>{rootName}</S.Title>
            <SidebarTreeSearch
              placeholder={placeholderSearch}
              query={searchText}
              onReset={() => onChangeSearchText("")}
              onChange={e => {
                onChangeSearchText(e.target.value);
              }}
            />
          </>
        )}
        <S.CloseContainer onClick={() => closeRightSidebar()} >
          <S.Close />
        </S.CloseContainer>
      </Header>
      {loading && <SidebarTreePreloader />}
      {!loading && !searchText && activeLevel(levels).categories && (
        <S.Categories>
          {entriesToArrayById(activeLevel(levels).categories).map(({ id, name }) => (
            <SidebarTreeCategory
              title={name}
              key={id}
              onOpen={() => handleOpenCategory(Number(id))}
            />
          ))}
        </S.Categories>
      )}
      {!loading &&
        !searchText && items &&
        entriesToArrayById(items)
          .filter(
            ({ categories }) =>
              levels.length > 1 ? categories.includes(activeLevel(levels).id) : categories.length === 0
          )
          .sort(sortByPosition)
          .map((item) => (
            <S.ItemWrapper key={item.id} onClick={() => onItemClick(item)}>{
              itemRender(item)}
            </S.ItemWrapper>
          ))}
      {!loading &&
      searchText && items &&
        entriesToArrayById(items).length > 0 &&
        entriesToArrayById(items)
          .sort(sortByPosition)
          .map((item) => (
          <S.ItemWrapper key={item.id} onClick={() => onItemClick(item)}>{
            itemRender(item)}
          </S.ItemWrapper>
        ))}
      {!loading && searchText && entriesToArrayById(items).length === 0 && (
        <S.NoResultsMessage>
          Мы не нашли элементов с таким названием. Если вы уверены, что здесь что-то должно быть, обратитесь в поддержку.
        </S.NoResultsMessage>
      )}
    </Container>
  );
};
