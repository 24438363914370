// @flow
import { ChartComponent } from "../common/charts/ChartComponent";
import reviewStatisticsStore, {
  transformDiagramDataPercentage,
} from "../../stores/reviewStatisticsStore";
import { Box, Paper, Stack } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import _ from "lodash";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import organizationsStore from "../../stores/organizationsStore";
import * as yup from "yup";
import { filterValidation } from "../common/widgets-components-common/constants";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = 'widgetRPR';
const FIELDS = {
  'Всего пациентов': 'totalPatients',
  'Пациенты с несколькими приемами': 'totalPatientsWithMultipleAppointments',
  'RPR': 'totalRPR',
}

const transformTableDataPercentage = (data, organization_ids, valueName, organizations) => {
  const returnData = [];
  if (Array.isArray(data)) {
    const organizationsData = _.groupBy(data, 'organization_id');
    const allData = {};
    Object.keys(organizationsData).forEach((organizationId) => {
      allData[organizationId] = {
        organization: organizations.find((organization) => parseInt(organization.id) === parseInt(organizationId))?.name
      };
      const patients_with_multiple_appointments = organizationsData[organizationId].reduce((acc, item) => {
        acc += item.patients_with_multiple_appointments;
        return acc;
      }, 0);
      const total_patients = organizationsData[organizationId].reduce((acc, item) => {
        acc += item.total_patients;
        return acc;
      }, 0);
      const rpr = (organizationsData[organizationId].reduce((acc, item) => {
        acc += item.rpr;
        return acc;
      }, 0) / organizationsData[organizationId].length).toFixed();
      allData[organizationId].fields = [
        {name: 'Всего пациентов', count: total_patients},
        {name: 'Пациенты с несколькими приемами', count: patients_with_multiple_appointments},
        {name: 'RPR', count: rpr},
      ]
    })
    Object.keys(allData).forEach((organizationId) => {
      allData[organizationId].fields.forEach((field) => {
        returnData.push(
          {
            organization: allData[organizationId].organization,
            field: field.name,
            count: field.count
          }
        )
      })
    })
  }
  return returnData;
};


export const FILTERS_FIELDS_WIDGET_RPR = () => [
  {
    type: 'select',
    name: 'type',
    label: 'Вид графика',
    xs: 12,
    options: [
      {value: 'line', label: 'Линейный'},
      {value: 'bar', label: 'Столбцами'},
      {value: 'table', label: 'Таблицей'},
    ]
  },
  {
    type: 'checkbox',
    name: 'isCollapse',
    label: 'Объединить выбранные организации (только для графиков)',
    xs: 12,
  },
  {
    type: 'text',
    name: 'label',
    label: 'Название графика',
    xs: 12,
  },
  {
    type: 'select',
    name: 'organization_ids',
    label: 'Организации',
    multiple: true,
    needSelectAll: true,
    valueName: 'id',
    labelName: 'name',
    xs: 12,
    options: organizationsStore.organizations,
  },
];

export const WidgetRPR = observer((props: {
  filtersData: () => {},
  rewriteFilters: () => {},
  keyWidget: String
}) => {

  const styledPaper = {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [filterFields, setFilterFields] = useState({});
  const [tableData, setTableData] = useState([]);
  const [tableFooterData, setTableFooterData] = useState({
    totalPatients: 0,
    totalPatientsWithMultipleAppointments: 0,
    totalRPR: 0,
    organizations: []
  });
  const [chartsData, setChartsData] = useState();
  const [label, setLabel] = useState('График коэффицента повторных покупок');
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const validateSchema = yup.object().shape({
    organization_ids: yup.array().required("организации").min(1, "организации")
  });

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
      if (getLocalStorage(NAME_WIDGET + props.keyWidget)) {
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), getLocalStorage(NAME_WIDGET + props.keyWidget));
        localStorage.removeItem(NAME_WIDGET + props.keyWidget);
      }
      const filterDiagramLocaleStorage = getLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''));
      if (filterDiagramLocaleStorage && moment(filterDiagramLocaleStorage.date_end).isBefore(moment())) {
        filterDiagramLocaleStorage.date_end = moment();
      }
      const filters = props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
      if (filters.label) {
        setLabel(filters.label);
      }
      setFiltersDataDiagram(filters)
      setFilterFields([...FILTERS_FIELDS_WIDGET_RPR()]);
      setIsInitialized(true);
    })()
  }, []);

  useEffect(() => {
    (async () => {
      if (_.size(filtersDataDiagram)) {
        setLoading(true);
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), filtersDataDiagram);
        if (filtersDataDiagram.label) {
          setLabel(filtersDataDiagram.label);
        }
        const response = await reviewStatisticsStore.getRprDiagram(filtersDataDiagram);
        if (response.error) {
          filterValidation({ validateSchema, filtersDataDiagram, setErrors });
        }
        setChartsData(transformDiagramDataPercentage({data: response, valueName: 'rpr', interval: filtersDataDiagram.interval || 'day', isCollapseData: filtersDataDiagram.isCollapse}));
        setTableData(transformTableDataPercentage(response, filtersDataDiagram.organization_ids, 'rpr', organizationsStore.organizations));
        setLoading(false);
      }
    })()
  }, [filtersDataDiagram]);

  useEffect(() => {
    if (props.rewriteFilters) {
      setFiltersDataDiagram({...filtersDataDiagram, ...props.rewriteFilters});
    }
  }, [props.rewriteFilters]);

  useEffect(() => {
    setTableFooterData(
      tableData.reduce((acc, item) => {
        acc[FIELDS[item.field]] += parseInt(item.count);
        if (!acc.organizations.includes(item.organization)) {
          acc.organizations.push(item.organization);
        }
        return acc;
        }, { totalPatients: 0, totalPatientsWithMultipleAppointments: 0, totalRPR: 0, organizations: [] })
    );
  }, [tableData]);

  const submitHandler = (filters, callback) => {
    setErrors([]);
    setFiltersDataDiagram(filters);
    callback();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'organization', //access nested data with dot notation
        header: 'Название организации',
      },
      {
        accessorKey: 'field', //access nested data with dot notation
        header: 'Информация',
        Footer: () => {
          const totalRPR = tableFooterData.totalRPR ? (tableFooterData.totalRPR / tableFooterData.organizations.length).toFixed(0) : 0;
          return (
            <Stack>
              <Box color="primary.main">Всего пациентов: {tableFooterData.totalPatients}</Box>
              <Box color="primary.main">Пациенты с несколькими приемами: {tableFooterData.totalPatientsWithMultipleAppointments}</Box>
              <Box color="primary.main">Среднее RPR: {totalRPR}%</Box>
            </Stack>
          )
        }
      },
      {
        accessorKey: 'count',
        header: 'Количество',
        Cell: ({ row }) => row.original.field === 'RPR' ? `${row.original.count}%` : row.original.count
      },
    ],
    [tableFooterData],
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    enableFullScreenToggle: false,
    enableGrouping: true,
    initialState: {
      density: 'compact',
      grouping: ['organization'],
      pagination: {pageIndex: 0, pageSize: 300}
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  return (
    <Paper style={styledPaper}>
      {filterFields && (
        <LabelWithFiltersModal
          fullWidth={true}
          type={"filter"}
          filtersData={filtersDataDiagram}
          filtersFields={filterFields}
          label={label}
          textButtonFilter={"Применить"}
          submitHandler={submitHandler}
        />
      )}
      {(loading || !isInitialized) && <AbsoluteLoader />}
      {isInitialized && (
        <>
          {filtersDataDiagram.type !== 'table' && chartsData && (
            <ChartComponent
              textButtonFilter="Применить"
              type={filtersDataDiagram.type || 'bar'}
              nameDiagram="ReviewDiagramRPR"
              data={chartsData}
              interval={filtersDataDiagram?.interval || 'day'}
              errors={errors}
            />
          )}
          {filtersDataDiagram.type === 'table' && tableData && (
            <div style={{
              maxHeight: '100%',
              overflow: "auto"
            }} className={'material-table'}>
              <MaterialReactTable table={table} />
            </div>
          )}
        </>
      )}
    </Paper>
  )
});
