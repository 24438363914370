import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Drugs {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getDrugs(query) {
    return this.apiService.get('drugs', {q: query});
  }

  postDrugs(name) {
    return this.apiService.post('drugs', {}, { name });
  }
}

export default new Drugs();
