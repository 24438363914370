// @flow
import { createEvent, type Store } from "effector";
import type { Diagnosis, TDiagnosis_id } from "../domain/entities/Diagnosis";
import * as R from "ramda";
import { appendIfNot } from "../utils/appendIfNot";
import { removeComment, setComment } from "./comment";

const addDiagnosis = (appointmentDiagnosis: Diagnosis) =>
  appendIfNot(appointmentDiagnosis, R.propEq("id", appointmentDiagnosis.id));

const remove = diagnosisGuid => R.filter(diagnosis => diagnosis.id !== diagnosisGuid);

const transformToHandler = R.compose(
  R.flip,
  // $FlowFixMe
  R.uncurryN(2)
);

export const initializeDiagnosisStore = (store: Store<Array<Diagnosis>>) => {
  const updateState = createEvent<Diagnosis[]>();
  const addNewDiagnosis = createEvent<Diagnosis>();
  const removeDiagnosis = createEvent<TDiagnosis_id>();
  const setDiagnosisComment = createEvent<{ value: string, id: TDiagnosis_id }>();
  const removeDiagnosisComment = createEvent<TDiagnosis_id>();

  store.on(addNewDiagnosis, transformToHandler(addDiagnosis));
  store.on(removeDiagnosis, transformToHandler(remove));
  store.on(setDiagnosisComment, transformToHandler(setComment("id")));
  store.on(removeDiagnosisComment, transformToHandler(removeComment("id")));
  store.on(updateState, (state, newState) => newState);

  return {
    store,
    updateState,
    addNewDiagnosis,
    setDiagnosisComment,
    removeDiagnosisComment,
    removeDiagnosis
  };
};
