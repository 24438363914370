import React, { useEffect, useRef } from "react";

import { observer } from "mobx-react";
import styled, { css } from "styled-components";
import chatStore from "../../../stores/chatStore";
import { Avatar, AvatarPlaceholder } from "../../greeting/greetingCommon";

import { IconMicOff } from "./icons/MicOff";
import chat from "../../../apiv2/entities/chat";

export const EndpointWrapper = styled.div`
  position: relative;
  display: flex;
  max-height: 100%;
  max-width: 100%;
  aspect-ratio: 16/9;
  justify-content: center;
  align-items: center;
  background: #3e2337;
  border-radius: 16px;

  video {
    width: 100%;
  }

  ${props =>
    props.minimize &&
    css`
      aspect-ratio: unset;

      ${VideoWrapper} {
        aspect-ratio: unset;
        min-width: 460px;
      }
    `}
`;

export const VideoWrapper = styled.div`
  display: flex;
  max-height: 100%;
  max-width: 100%;
  // ToDo Нужно верстку пересобрать на grid-ы
  min-width: 200px; // чтобы хоть как то на ios видео виднелось  -  без этого - 0px ширина.
  aspect-ratio: 16/9;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const AvatarWrapper = styled(EndpointWrapper)`
  align-items: center;

  ${props =>
    props.minimize &&
    css`
      width: 460px;
      padding: 32px;
      margin: 24px;
    `}
`;

const ViewUsername = styled.span`
  position: absolute;
  bottom: 16px;
  left: 16px;
  font-family: "Graphik LCG";
  font-size: 21px;
  font-weight: 400;
  line-height: 23px;
  color: #fff;
`;

const MutedBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #5b3150;
  border-radius: 50%;
  position: absolute;
  padding: 8px;
  top: 16px;
  right: 16px;
`;

export const EndpointView = observer(({ endpoint }) => {
  const ref = useRef(null);
  const videoRender = endpoint.mediaRenderers.find(mr => mr.kind === "video");

  useEffect(() => {
    if (ref.current && videoRender) {
      ref.current.innerHTML = ''; // Очищаем контент wrapper'а
      videoRender.render(ref.current);
    }
  }, [ref.current, videoRender, chatStore.endpoints]);

  if (!videoRender) {
    if (chatStore.isAnonymousMode) {
      return (
        <AvatarWrapper className="wrapper" minimize={chatStore.videoMinimized}>
          {false && (
            <MutedBadge>
              <IconMicOff
                style={{
                  "--svg-fill": "#fff"
                }}
              />
            </MutedBadge>
          )}

          <AvatarPlaceholder />
        </AvatarWrapper>
      )
    }
    if (!chatStore.callOpponent) {
      return null;
    }
    return (
      <AvatarWrapper className="wrapper" minimize={chatStore.videoMinimized}>
        <ViewUsername>{chatStore.callOpponent.short_name || ''}</ViewUsername>
        {false && (
          <MutedBadge>
            <IconMicOff
              style={{
                "--svg-fill": "#fff"
              }}
            />
          </MutedBadge>
        )}
        {chatStore.callOpponent.image && chatStore.callOpponent.image[0] !== "#" ? (
          <Avatar avatar={chatStore.callOpponent.image} />
        ) : (
          <AvatarPlaceholder>
            {(chatStore.callOpponent.last_name
              ? chatStore.callOpponent.first_name.slice(0, 1)
              : "") + chatStore.callOpponent.last_name.slice(0, 1)}
          </AvatarPlaceholder>
        )}
      </AvatarWrapper>
    );
  }

  return (
    <EndpointWrapper className="wrapper" minimize={chatStore.videoMinimized}>
      {chatStore.callOpponent &&
        <ViewUsername>{chatStore.callOpponent.short_name}</ViewUsername>
      }
      {false && (
        <MutedBadge>
          <IconMicOff
            style={{
              "--svg-fill": "#fff"
            }}
          />
        </MutedBadge>
      )}
      {/*<span style={{ position: "absolute", backgroundColor: "red" }}>*/}
      {/*  {chatStore.voiceActive[endpoint.id] ? "voice ACTIVE" : " voice NOOOT active"}*/}
      {/*  {endpoint.isDefault}*/}
      {/*</span>*/}
      <VideoWrapper ref={ref}>...</VideoWrapper>
    </EndpointWrapper>
  );
});
