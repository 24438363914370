// @flow
import { createEffect, restore, createEvent, combine } from "effector";
import * as R from "ramda";
import type { TShortcuts, TChecklistDestination } from "../../domain/value-objects/TShortcuts";
import type { TokenObject } from "../../domain/value-objects/auth";
import type { TAppointment_id } from "../../domain/entities/Appointment";
import type { TLayout_id } from "../../domain/entities/Layout";
import type { TField_id } from "../../domain/entities/Field";
import { checklistDestinationTypeArray } from "./constants";
import { layoutSectionsFieldsMapStore } from "../../stores/appointmentLayout";
import apiv2 from '../../apiv2';

export const fetchShortcutsEffect = createEffect<
  {
    ...$Exact<TokenObject>,
    layout_id: TLayout_id,
    organization_id: number,
  },
  Array<TShortcuts>,
  any
>();
fetchShortcutsEffect.use(apiv2.shortcuts.getShortcuts.bind(apiv2.shortcuts));

const fetchedShortcuts = restore<*>(fetchShortcutsEffect, []);

export const allShortcuts = combine<*, *, *>(
  layoutSectionsFieldsMapStore,
  fetchedShortcuts,
  (layoutSectionsFieldsMap, shortcuts) =>
    R.compose(
      R.map(
        R.when(
          R.propEq("destination_type", "field"),
          R.ifElse(
            R.path(["field", "parent"]),
            shortcut => ({
              ...shortcut,
              field: {
                ...shortcut.field,
                id: R.path(
                  [
                    shortcut.field.section.copy_id,
                    "fields",
                    shortcut.field.parent.copy_id,
                    "children",
                    shortcut.field.copy_id,
                    "id"
                  ],
                  layoutSectionsFieldsMap
                )
              }
            }),
            shortcut => ({
              ...shortcut,
              field: {
                ...shortcut.field,
                id: R.path(
                  [shortcut.field.section.copy_id, "fields", shortcut.field.copy_id, "id"],
                  layoutSectionsFieldsMap
                )
              }
            })
          )
        )
      ),
      R.filter(
        ({ field, destination_type }) =>
          destination_type === "services" ||
          destination_type === "prescribed_services" ||
          destination_type === "appointment_services" ||
          checklistDestinationTypeArray.includes(destination_type) ||
          R.hasPath([field.section.copy_id, "fields", field.copy_id], layoutSectionsFieldsMap) ||
          R.hasPath(
            [field.section.copy_id, "fields", field.parent.copy_id, "children", field.copy_id],
            layoutSectionsFieldsMap
          )
      )
      // $FlowFixMe
    )(shortcuts)
);

type TShortcutsPointerField = {
  type: "field",
  fieldId: TField_id
};

type TShortcutsPointerServices = {
  type: "services"
};

type TShortcutsPointerAppointmentServices = {
  type: "appointment_services"
};
type TShortcutsPointerChecklist = {
  type: "checklist",
  fieldName: TChecklistDestination,
  appointment_id: TAppointment_id
};

type TShortcutsPointer =
  | TShortcutsPointerField
  | TShortcutsPointerServices
  | TShortcutsPointerAppointmentServices
  | TShortcutsPointerApp
  | TShortcutsPointerChecklist;

export const setShortcutsPointer = createEvent<?TShortcutsPointer>();
export const shortcutsPointer = restore<?TShortcutsPointer>(setShortcutsPointer, null);
