import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled, {css} from 'styled-components';
import {TeethCheckbox} from './TeethCheckbox';

import {Teeth1} from './icons/Teeth1';
import {Teeth2} from './icons/Teeth2';
import {Teeth3} from './icons/Teeth3';
import {Teeth45} from './icons/Teeth45';
import {Teeth68} from './icons/Teeth68';
import {colors} from '../styleguide/colors';
import {useClickOutside} from '../../utils/useClickOutside';

import _ from 'lodash';

const Container = styled.div`
  flex-shrink: 0;
`;

const HandleContainer = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;

const TeethPickerContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: ${props => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.09);
  cursor: default;
  z-index: 10;
  @media (max-width: 768px) {
    width: calc(100% - 45px);
    padding: 15px 20px;
    overflow-x: auto;
  }
  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const TeethRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TeethRow = styled.div`
  display: flex;
`;

const BiteSelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BiteSelect = styled.button`
  font-family: "Graphik LCG";
  font-size: 16px;
  line-height: 27px;
  color: #4a4a4a;
  background: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  
  ${props => props.active && css`
    color: ${colors.magenta};
    border-bottom: 1px solid  ${colors.magenta};
  `}}
`;

const Delimiter = styled.div`
  height: 37px;
  border-right: 2px solid #979797;
  opacity: 0.3;
`;

const ItemsRow = styled.div`
  display: flex;
  gap: 16px;
`;

const TeethClearButton = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  font: 12px "Graphik LCG";
  color: #4a4a4a;
  cursor: pointer;
  
   &:hover {
    color: ${colors.magenta};
  }
  
  &:active {
    color: ${colors.magenta};
  }
`;

const TEETH_PREFIX = 'teeth_';

const TEETH_TYPE = {
  TEETH_1: 'TEETH_1',
  TEETH_2: 'TEETH_2',
  TEETH_3: 'TEETH_3',
  TEETH_45: 'TEETH_45',
  TEETH_68: 'TEETH_68',
}

export const JAWS_TYPE = {
  ORAL_CAVITY: 'oral_cavity',
  UPPER_JAW: 'upper_jaw',
  LOWER_JAW: 'lower_jaw',
}

export const JAWS_TYPE_NAME = {
  [JAWS_TYPE.ORAL_CAVITY]: 'Полость рта',
  [JAWS_TYPE.UPPER_JAW]: 'Верхняя челюсть',
  [JAWS_TYPE.LOWER_JAW]: 'Нижняя челюсть',
};

export const JAWS_TYPES = [
  JAWS_TYPE.ORAL_CAVITY,
  JAWS_TYPE.UPPER_JAW,
  JAWS_TYPE.LOWER_JAW,
];

export const toothNameByNumber = {
  1: 'Центральный резец',
  2: 'Боковой резец',
  3: 'Клык',
  4: 'Первый перемоляр',
  5: 'Второй перемоляр',
  6: 'Первый моляр',
  7: 'Второй моляр',
  8: 'Третий моляр',
};

const BITE_TYPE = {
  MATURE: 'MATURE',
  KID: 'KID'
};

const TEETH_TYPE_ICON_MAP = {
  [TEETH_TYPE.TEETH_1]: <Teeth1/>,
  [TEETH_TYPE.TEETH_2]: <Teeth2/>,
  [TEETH_TYPE.TEETH_3]: <Teeth3/>,
  [TEETH_TYPE.TEETH_45]: <Teeth45/>,
  [TEETH_TYPE.TEETH_68]: <Teeth68/>,
};

const TEETH_BY_BITE = {
  [BITE_TYPE.MATURE]: [
    [
      { number: 18, type: TEETH_TYPE.TEETH_68 },
      { number: 17, type: TEETH_TYPE.TEETH_68 },
      { number: 16, type: TEETH_TYPE.TEETH_68 },
      { number: 15, type: TEETH_TYPE.TEETH_45 },
      { number: 14, type: TEETH_TYPE.TEETH_45 },
      { number: 13, type: TEETH_TYPE.TEETH_3 },
      { number: 12, type: TEETH_TYPE.TEETH_2 },
      { number: 11, type: TEETH_TYPE.TEETH_1 },
      { number: 21, type: TEETH_TYPE.TEETH_1 },
      { number: 22, type: TEETH_TYPE.TEETH_2 },
      { number: 23, type: TEETH_TYPE.TEETH_3 },
      { number: 24, type: TEETH_TYPE.TEETH_45 },
      { number: 25, type: TEETH_TYPE.TEETH_45 },
      { number: 26, type: TEETH_TYPE.TEETH_68 },
      { number: 27, type: TEETH_TYPE.TEETH_68 },
      { number: 28, type: TEETH_TYPE.TEETH_68 },
    ],
    [
      { number: 48, type: TEETH_TYPE.TEETH_68 },
      { number: 47, type: TEETH_TYPE.TEETH_68 },
      { number: 46, type: TEETH_TYPE.TEETH_68 },
      { number: 45, type: TEETH_TYPE.TEETH_45 },
      { number: 44, type: TEETH_TYPE.TEETH_45 },
      { number: 43, type: TEETH_TYPE.TEETH_3 },
      { number: 42, type: TEETH_TYPE.TEETH_2 },
      { number: 41, type: TEETH_TYPE.TEETH_1 },
      { number: 31, type: TEETH_TYPE.TEETH_1 },
      { number: 32, type: TEETH_TYPE.TEETH_2 },
      { number: 33, type: TEETH_TYPE.TEETH_3 },
      { number: 34, type: TEETH_TYPE.TEETH_45 },
      { number: 35, type: TEETH_TYPE.TEETH_45 },
      { number: 36, type: TEETH_TYPE.TEETH_68 },
      { number: 37, type: TEETH_TYPE.TEETH_68 },
      { number: 38, type: TEETH_TYPE.TEETH_68 },
    ]
  ],
  [BITE_TYPE.KID]: [
    [
      { number: 58, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 57, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 56, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 55, type: TEETH_TYPE.TEETH_45 },
      { number: 54, type: TEETH_TYPE.TEETH_45 },
      { number: 53, type: TEETH_TYPE.TEETH_3 },
      { number: 52, type: TEETH_TYPE.TEETH_2 },
      { number: 51, type: TEETH_TYPE.TEETH_1 },
      { number: 61, type: TEETH_TYPE.TEETH_1 },
      { number: 62, type: TEETH_TYPE.TEETH_2 },
      { number: 63, type: TEETH_TYPE.TEETH_3 },
      { number: 64, type: TEETH_TYPE.TEETH_45 },
      { number: 65, type: TEETH_TYPE.TEETH_45 },
      { number: 66, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 67, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 68, type: TEETH_TYPE.TEETH_68, disabled: true },
    ],
    [
      { number: 88, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 87, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 86, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 85, type: TEETH_TYPE.TEETH_45 },
      { number: 84, type: TEETH_TYPE.TEETH_45 },
      { number: 83, type: TEETH_TYPE.TEETH_3 },
      { number: 82, type: TEETH_TYPE.TEETH_2 },
      { number: 81, type: TEETH_TYPE.TEETH_1 },
      { number: 71, type: TEETH_TYPE.TEETH_1 },
      { number: 72, type: TEETH_TYPE.TEETH_2 },
      { number: 73, type: TEETH_TYPE.TEETH_3 },
      { number: 74, type: TEETH_TYPE.TEETH_45 },
      { number: 75, type: TEETH_TYPE.TEETH_45 },
      { number: 76, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 77, type: TEETH_TYPE.TEETH_68, disabled: true },
      { number: 78, type: TEETH_TYPE.TEETH_68, disabled: true },
    ]
  ]
};

export const teethIDsToValue = (ids) => {
  if (ids.length) {
    return ids.join(';') + ';';
  }
  return '';
};

export const teethValueToArray = (value) => {
  if (!value) {
    return [];
  }
  return value.split(';').filter(Boolean);
};

export const toTeethIDs = (teethStringIDs) => {
  return teethStringIDs.map(teeth => teeth.includes('teeth_') ? parseInt(teeth.split('_')[1], 10) : teeth);
};

export const toServicesTeethString = (ids) => {
  if (!ids.length) {
    return '';
  }
  return ids.map(teethId => {
    const toothNum = teethId % 10;
    if (toothNameByNumber[toothNum]) {
      return `${teethId}(${toothNameByNumber[toothNum]})`;
    }
    if (JAWS_TYPES.includes(teethId)) {
      return JAWS_TYPE_NAME[teethId];
    }
    return '';
  }).join(', ');
};

export const teethIDsToString = (ids, showAllTeeth = false) => {
  if (!ids.length) {
    return 'Номер зуба';
  }
  const processedTeeths = ids.map(teeth => teeth.includes('teeth_') ? teeth.split('_')[1] : teeth);
  if (processedTeeths.length === 1 && JAWS_TYPES.includes(processedTeeths[0])) {
    return JAWS_TYPE_NAME[processedTeeths[0]];
  }
  if (showAllTeeth) {
    return processedTeeths.join(', ');
  }
  if (processedTeeths.length > 3) {
    const displayTeeth = processedTeeths.slice(0, 3);
    const otherTeeth = processedTeeths.slice(3, processedTeeths.length);
    return `${displayTeeth.join(',')}+${otherTeeth.length}`;
  }
  return processedTeeths.join(',');
};

export const TeethPicker = ({
  selectedTeeths,
  handleComponent,
  onChange
}) => {
  const selectedTeethIDs = selectedTeeths.map(teeth => teeth.includes(TEETH_PREFIX) ? teeth.split('_')[1] : teeth);
  const [isVisible, setIsVisible] = useState(false);
  const [bite, setBite] = useState(BITE_TYPE.MATURE);

  const getTeethValue = (teethNumber) => {
    return `${TEETH_PREFIX}${teethNumber}`;
  };

  const setTeethValues = (from, to) => {
    return selectedTeethIDs.reduce((acc, next) => {
      const teethNumber = parseInt(next, 10);
      if (teethNumber > from && teethNumber < to) {
        acc[getTeethValue(teethNumber)] = true;
      }
      if (isNaN(teethNumber)) {
        acc[next] = true;
      }
      return acc;
    }, {});
  };

  const [value, setValue] = useState({
    [BITE_TYPE.MATURE]: setTeethValues(10, 50),
    [BITE_TYPE.KID]: setTeethValues(50, 90),
  });
  const containerRef = useRef(null);

  const handleToggleClick = () => {
    setIsVisible(state => !state);
  };

  const handleTeethChange = (key, state) => {
    setValue(value => {
      const biteValuesWithoutJaws = Object.fromEntries(
        Object.entries(value[bite]).filter(([key]) => key.includes(TEETH_PREFIX))
      );
      return {
        ...value,
        [bite]: {
          ...biteValuesWithoutJaws,
          [key]: state
        }
      };
    });
  };

  const handleJawChange = (key, state) => {
    setValue(value => ({
      ...value,
      [bite]: {
        [key]: state
      }
    }));
  };

  const handleTeethClear = () => {
    setValue(value => ({
      ...value,
      [bite]: {}
    }));
  };

  const resultValue = useMemo(() => {
    const resultValues = Object.entries(value[bite]).map(([key, value]) => value ? key : false).filter(Boolean);
    if (resultValues.length) {
      return resultValues;
    }
    return [];
  }, [ value, bite ]);

  useEffect(() => {
    if (_.isEqual(selectedTeeths, resultValue)) {
      return;
    }
    onChange?.(resultValue);
  }, [ resultValue ]);

  useClickOutside(containerRef, () => {
    setIsVisible(false);
  });

  return (
    <Container ref={containerRef}>
      <HandleContainer onClick={handleToggleClick}>
        {handleComponent}
      </HandleContainer>
      <TeethPickerContainer visible={isVisible}>
        <BiteSelectContainer>
          <BiteSelect active={bite === BITE_TYPE.MATURE} onClick={() => {
            setBite(BITE_TYPE.MATURE)
          }}>Обычный прикус</BiteSelect>
          <Delimiter/>
          <BiteSelect active={bite === BITE_TYPE.KID} onClick={() => {
            setBite(BITE_TYPE.KID)
          }}>Молочный прикус</BiteSelect>
        </BiteSelectContainer>
        <TeethRowsContainer>
          {
            TEETH_BY_BITE[bite].map((row, rowIndex) => (
              <TeethRow key={`row-${rowIndex}`}>
                {
                  row.map((teeth, teethIndex) =>
                    (
                      <TeethCheckbox key={teeth.number}
                                     icon={TEETH_TYPE_ICON_MAP[teeth.type]}
                                     text={teeth.number}
                                     value={getTeethValue(teeth.number)}
                                     active={value[bite][getTeethValue(teeth.number)]}
                                     reversedY={teethIndex >= row.length / 2}
                                     reversedX={rowIndex === 1}
                                     disabled={teeth.disabled}
                                     onChange={handleTeethChange}
                      />
                    ))
                }
              </TeethRow>
            ))
          }
        </TeethRowsContainer>
        <ItemsRow>
          {
            JAWS_TYPES.map(jawType => (
              <TeethCheckbox key={jawType}
                             text={JAWS_TYPE_NAME[jawType]}
                             value={jawType}
                             active={value[bite][jawType]}
                             onChange={handleJawChange}
              />
            ))
          }
          <TeethClearButton onClick={handleTeethClear}>
            Очистить
          </TeethClearButton>
        </ItemsRow>
      </TeethPickerContainer>
    </Container>
  );
};