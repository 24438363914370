// @flow
import React from "react";
import styled from "styled-components";
import { GroupTitle } from "./GroupTitle";
import { GroupContainer } from "./GroupContainer";
import { PointTemplate } from "./PointTemplate";
import { DiagnosisLabel } from "./DiagnosisLabel";
import { PNormal } from "../styleguide/typography";
import { PointsContainer } from "./PointsContainer";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";

const PointContainer = styled.div`
  pointer-events: none;
`;

const Point = props => {
  const { diagnosis } = props;
  return (
    <PointContainer>
      <PointTemplate
        icon={diagnosis.icd_code && <DiagnosisLabel title={diagnosis.icd_code} />}
        title={<PNormal modifiers="left">{diagnosis.name}</PNormal>}
        hint={diagnosis.comment && <PNormal modifiers="left">{diagnosis.comment}</PNormal>}
      />
    </PointContainer>
  );
};

const AppointmentDiagnosesList = props => {
  const { values } = props;

  return (
    <PointsContainer>
      {values && values.map(diagnosis => <Point key={diagnosis.id} diagnosis={diagnosis} />)}
    </PointsContainer>
  );
};

export const ReadableAppointmentDiagnoses = (props: { appointment: AppointmentWithDetails }) => {
  const { appointment } = props;

  return (
    <>
      {appointment.primary_diagnoses && appointment.primary_diagnoses.length > 0 && (
        <GroupContainer>
          <GroupTitle title="Основной диагноз:" />
          <AppointmentDiagnosesList values={appointment.primary_diagnoses} />
        </GroupContainer>
      )}
      {appointment.secondary_diagnoses && appointment.secondary_diagnoses.length > 0 && (
        <GroupContainer>
          <GroupTitle title="Сопутствующий диагноз:" />
          <AppointmentDiagnosesList values={appointment.secondary_diagnoses} />
        </GroupContainer>
      )}
    </>
  );
};
