// @flow
import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import moment from "moment";
import { debounce } from "lodash";
import MaskedInput from "react-text-mask";
import Calendar from "react-calendar";

import { DefaultInput } from "../form-elements/DefaultInput";
import LeftArrowIcon from "../form-elements/icons/LeftArrow";
import RightArrowIcon from "../form-elements/icons/RightArrow";

import { usePortal } from "../../utils/usePortal";
import { formatDateToReadableDateFormat, parseDateFromReadableFormat } from "../../utils/dates";
import { BaseStyledInput } from "../form-elements/BaseStyledInput";
import { isValidReadableDate } from "../../domain/services/field";

type EmbryosCalendarInputProps = {
  ...React$ElementConfig<typeof DefaultInput>,
  value: string,
  inputDisabled: ?boolean
};

const Container = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 100%;
`;

const DefaultInputContainer = styled.div`
  width: 100%;
`;

const CalendarContainer = styled.div`
  position: fixed;
  display: block;
  box-shadow: 0 2px 17px rgba(91, 91, 91, 0.259752);
  border-radius: 3px;
  overflow: hidden;
  z-index: 5123129;
`;

const CalendarContent = styled.div`
  position: relative;
  padding-bottom: 40px;
  background: #fff;
`;

const CalendarInput = styled(BaseStyledInput)`
  border-radius: 0;
  border: none;
  height: 100%;
  font-size: 15px;
  padding: 10px 15px;
  height: 44px;
`;

const TodayButton = styled.button`
  position: absolute;
  bottom: 0;
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  border-top: 1px solid rgba(151, 151, 151, 0.3);
  font-family: Graphik, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  color: #c6007f;
  cursor: pointer;

  &:hover {
    background: rgba(151, 151, 151, 0.1);
  }
`;

export const EmbryosCalendarInput = (props: EmbryosCalendarInputProps) => {
  const { handleChange, onFocus } = props;
  const [pos, setPos] = useState(null);
  const [value, onChange] = useState(
    props.value ? parseDateFromReadableFormat(props.value).toDate() : new Date()
  );
  const calendarContainerRef = useRef(null);
  const containerRef = useRef(null);
  const calendarNode = usePortal("calendar-elements-root");

  const onInputFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const onTodayClick = () => {
    const today = new Date();
    handleChange(formatDateToReadableDateFormat(today));
    onChange(today);
  };

  function correctPosition() {
    if (containerRef.current) {
      setPos(containerRef.current.getBoundingClientRect());
    }
  }

  const debouncedCorrectPosition = debounce(() => {
    correctPosition();
  }, 50);

  useEffect(() => {
    correctPosition();
    // TODO: Maybe use wrapper element for scroll?
    // const wrapperEl = wrapper.current;
    window.addEventListener("wheel", debouncedCorrectPosition);
    return () => window.removeEventListener("wheel", debouncedCorrectPosition);
  }, [containerRef.current]);

  const isInvalid = React.useMemo(() => !isValidReadableDate(props.value), [props.value]);

  const onBlur = React.useCallback(
    e => {
      if (isInvalid) {
        e.stopPropagation();
        e.preventDefault();
        alert("Неверная дата!");
      }
    },
    [isInvalid]
  );

  const onKeyDown = React.useCallback(
    e => {
      if (isInvalid) {
        e.stopPropagation();
        if (e.key === "Enter") {
          alert("Неверная дата!");
        }
      }
    },
    [isInvalid]
  );

  const onMouseDown = React.useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const transformStyles = React.useMemo(() => {
    const ROW_HEIGHT = 60;
    const DEFAULT_CALENDAR_HEIGHT = 320;
    const PADDING = 4;
    const calendarHeight = calendarContainerRef.current
      ? calendarContainerRef.current.getBoundingClientRect().height
      : DEFAULT_CALENDAR_HEIGHT;
    return pos
      ? `translate3d(${pos.left}px, ${
          pos.top + pos.height + PADDING + calendarHeight > window.innerHeight
            ? pos.top - pos.height - calendarHeight + ROW_HEIGHT - PADDING * 2
            : pos.top + pos.height + PADDING
        }px, 0px)`
      : "none";
  }, [pos]);

  return (
    <Container ref={containerRef} onKeyDown={onKeyDown} onMouseDown={onMouseDown}>
      <DefaultInput
        type="text"
        value={props.value}
        onBlur={onBlur}
        onFocus={onInputFocus}
        handleChange={handleChange}
        overrides={{
          ...props.overrides,
          Container: { component: DefaultInputContainer },
          Input: {
            props: {
              as: MaskedInput,
              mask: [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/],
              guide: false,
              ...((props.overrides && props.overrides.Input.props) || {})
            },
            component: CalendarInput
          }
        }}
        disabled={props.inputDisabled || false}
        autoFocus
      />
      <>
        {ReactDOM.createPortal(
          <CalendarContainer
            ref={calendarContainerRef}
            visible={true}
            style={{
              transform: transformStyles,
              top: 0,
              left: 0
            }}>
            <CalendarContent>
              <Calendar
                className="date-input-calendar"
                defaultView="month"
                navigationLabel={({ date, label, view }) => {
                  const today = new Date();
                  const dateMoment = moment(date);
                  return view === "month"
                    ? date.getFullYear() === today.getFullYear()
                      ? dateMoment.format("MMMM")
                      : dateMoment.format("MMMM, YYYY")
                    : label;
                }}
                prevLabel={<LeftArrowIcon />}
                nextLabel={<RightArrowIcon />}
                onChange={value => {
                  handleChange(formatDateToReadableDateFormat(value));
                  onChange(value);
                }}
                value={value}
              />
              <TodayButton onClick={onTodayClick}>Сегодня</TodayButton>
            </CalendarContent>
          </CalendarContainer>,
          calendarNode
        )}
      </>
    </Container>
  );
};
