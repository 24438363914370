//@flow
import * as React from "react";
import { AppointmentHistoryTableRow } from "./AppointmentHistoryTableRow";

export const AppointmentHistoryTableRowAll = (props: {
  time: string,
  doctor: React.Node,
  patient: string,
  badge: string,
  layout: string,
  link: string,
  onClickCell: () => mixed
}) => {
  return (
    <AppointmentHistoryTableRow
      time={props.time}
      doctor={props.doctor}
      patient={props.patient}
      badge={props.badge}
      layout={props.layout}
      link={props.link}
      onClickCell={props.onClickCell}
    />
  );
};
