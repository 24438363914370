import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form"
import useReactRouter from "use-react-router";
import { FormInputText } from "./fields/FormInputText";
import { FormInputSelect } from "./fields/FormInputSelect";
import { FormInputMultiCheckbox } from "./fields/FormInputMultiCheckbox";
import { FormInputRadio } from "./fields/FormInputRadio";
import { FormInputSlider } from "./fields/FormInputSlider";
import { FormInputCheckbox } from "./fields/FormInputCheckbox";
import { FormInputNumber } from "./fields/FormInputNumber";
import { FormInputTextarea } from "./fields/FormInputTextarea";
import { FormInputPhoneNumber } from "./fields/FormInputPhoneNumber";
import styled from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup"
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { observer } from "mobx-react";
import _ from "lodash"
import { FormInputDate } from "./fields/FormInputDate";
import { FormInputSelectDoctor } from "./fields/FormInputSelectDoctor";
import { withModifiers } from "with-modifiers";
import { FormInputSelectFreeSolo } from "./fields/FormInputSelectFreeSolo";
import { FormInputChips } from "./fields/FormInputChips";
import { FormInputFile } from "./fields/FormInputFile";
import { FormInputPassword } from "./fields/FormInputPassword";

const ContainerForm = withModifiers({
  md: () => `
    max-width: 800px;
  `
})(
  modifier => styled.div`
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    ${modifier}
  `
);

export const backToHistory = (searchHistory, history) => {
  const search = searchHistory ? `${searchHistory()}` : '';
  const splitPath = location.pathname.split('/');
  history.push(splitPath.slice(0, splitPath.length -1).join('/') + search);
}

export const handlerOnSubmitHiddenButton = () => {
  const forms = Array.from(document.getElementsByClassName("dynamic-form__hidden-button"));
  forms.forEach(form => {
    form.click();
  });
};

export const makeAnimationStartHandler = (stateSetter, value) => (event) => {
  if (value || typeof value === "string") {
    return;
  }
  if (event.animationName === "mui-auto-fill") {
    stateSetter(true);
  }
  if (event.animationName === "mui-auto-fill-cancel") {
    stateSetter(false);
  }
};

export const DynamicForm = observer(({
  data,
  fields,
  additionalBlock,
  validateSchema,
  title,
  titleButton,
  searchHistory,
  callbackOnClose,
  submitHandler,
  changeHandler,
  resetErrorsHandler,
  enableHiddenButton = false,
  hiddenButtonCancel,
  hiddenButtonSave,
  hiddenButtonBack,
  textButton,
  isInlineButtonSave,
  modifiers,
  debounceTime,
  spacingForm,
  parentName = ""
}) => {
  const [canFocus, setCanFocus] = useState(true);
  const isTablet = useMediaQuery("(max-width: 768px)");
  const isMobile = useMediaQuery("(max-width: 480px)");
  const [oldData, setOldData] = useState({});
  const formProps = {
    defaultValues: oldData,
  }
  if (validateSchema) {
    formProps.resolver = yupResolver(validateSchema);
  }

  const { history } = useReactRouter();

  const methods = useForm(formProps);
  const { handleSubmit, control, reset, setValue, formState: { errors }, watch, clearErrors, setError } = methods;
  useEffect(() => {
    if (changeHandler) {
      watch();
    }
  }, [fields]);

  useEffect(() => {
    if (!_.isEqual(oldData, data)) {
      setOldData(data);
      reset(data);
    }
  }, [data]);

  const onSubmit = (data) => {
    if (!submitHandler) {
      return;
    }
    submitHandler(data, setError);
  }

  const onError = () => {
    setCanFocus(true);
  }

  React.useEffect(() => {
    const subscription = watch(_.debounce((value) => {
      if (changeHandler && !_.isEqual(oldData, value)) {
        const changedKey = oldData ? Object.keys(oldData).find((key) => !_.isEqual(oldData[key], value[key])) || "" : null;
        setOldData(value);
        changeHandler(value, reset, changedKey);
        if (resetErrorsHandler) {
          resetErrorsHandler({ data: value, errors, clearErrors, changedKey });
        }
      }
    }, (debounceTime || debounceTime === 0) ? debounceTime : 800));
    return () => subscription.unsubscribe()
  }, [oldData, watch, errors])

  useEffect(() => {
    if (errors && canFocus) {
      const firstErrorElement = document.getElementsByClassName("Mui-error")[0];
      firstErrorElement?.scrollIntoView({ behavior: "smooth", block: "center" });
      setCanFocus(false);
    }
  }, [errors, canFocus]);

  return (
    <ContainerForm modifiers={modifiers}>
      {title && (
        <Stack marginBottom={isMobile ? 2 : isTablet ? 3 : 4} direction="row" flexWrap={"wrap"} alignItems={"center"} justifyContent={"space-between"}>
          <Stack direction="row" spacing={2} alignItems={"center"}>
            {!hiddenButtonBack && (
              <ArrowCircleLeftOutlinedIcon
                color="primary"
                onClick={() => {
                  if (callbackOnClose) {
                    callbackOnClose();
                  }
                  backToHistory(searchHistory, history);
                }}
                fontSize={isMobile ? "medium" : "large"}
                cursor="pointer"
              />
            )}
            <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>{title}</Typography>
          </Stack>
          {titleButton && titleButton}
        </Stack>
      )}
      {additionalBlock}
      <form onSubmit={handleSubmit(onSubmit, onError)} noValidate className="dynamic-form">
        {
          <Grid container spacing={spacingForm || 1.5}>
            {fields.map((field) => {
              if (field.hiddenField) {
                return null;
              }
              delete field.hiddenField;
              return (
                <Grid key={field.name} item xs={field.xs || 6} md={field.md} sm={field.sm}>
                  {field.type === 'text' && (
                    <FormInputText {...field} control={control} submit={handleSubmit(onSubmit)} />
                  )}
                  {field.type === 'textarea' && (
                    <FormInputTextarea {...field} control={control} />
                  )}
                  {field.type === 'file' && (
                    <FormInputFile {...field} allData={data} control={control} submit={handleSubmit(onSubmit)} />
                  )}
                  {field.type === 'select' && (
                    <FormInputSelect {...field} control={control} parentName={parentName} />
                  )}
                  {field.type === "selectFreeSolo" && (
                    <FormInputSelectFreeSolo {...field} control={control} />
                  )}
                  {field.type === 'selectDoctor' && (
                    <FormInputSelectDoctor {...field} control={control} parentName={parentName} />
                  )}
                  {field.type === 'checkbox' && (
                    <FormInputCheckbox
                      control={control}
                      {...field}
                    />
                  )}
                  {field.type === 'checkboxGroup' && (
                    <FormInputMultiCheckbox
                      control={control}
                      setValue={setValue}
                      settedValues={data}
                      {...field}
                    />
                  )}
                  {field.type === 'chips' && (
                    <FormInputChips
                      control={control}
                      setValue={setValue}
                      settedValues={data}
                      {...field}
                    />
                  )}
                  {field.type === 'date' && (
                    <FormInputDate {...field} control={control} />
                  )}
                  {field.type === 'number' && (
                    <FormInputNumber {...field} control={control} />
                  )}
                  {field.type === 'radio' && (
                    <FormInputRadio
                      {...field}
                      control={control}
                    />
                  )}
                  {field.type === 'slider' && (
                    <FormInputSlider
                      {...field}
                      control={control}
                      setValue={setValue}
                    />
                  )}
                  {field.type === 'phoneNumber' && (
                    <FormInputPhoneNumber {...field} control={control} />
                  )}
                  {field.type === 'password' && (
                    <FormInputPassword {...field} control={control} submit={handleSubmit(onSubmit)} />
                  )}
                </Grid>
              )
            })}
            {!hiddenButtonSave && isInlineButtonSave && (
              <Grid alignItems={"center"} display={"flex"} item xs={12} md={2}>
                <Button disabled={!!Object.keys(errors).length} type="submit" variant="contained" color="primary">
                  {textButton || 'Сохранить'}
                </Button>
              </Grid>
            )}
          </Grid>
        }
        {(!hiddenButtonCancel || !hiddenButtonSave) && !isInlineButtonSave && (
          <Stack marginTop={3} direction="row" spacing={2}>
            {!hiddenButtonCancel && (
              <Button
                onClick={() => {
                  if (callbackOnClose) {
                    callbackOnClose();
                  }
                  backToHistory(searchHistory, history);
                }}
                variant="outlined"
                color="primary"
              >
                Отмена
              </Button>
            )}
            {!hiddenButtonSave && (
              <Button disabled={!!Object.keys(errors).length} type="submit" variant="contained" color="primary">
                {textButton || 'Сохранить'}
              </Button>
            )}
          </Stack>
        )}
        {enableHiddenButton && (
          <Button sx={{ display: "none" }} type="submit" className="dynamic-form__hidden-button">
            {textButton || 'Сохранить'}
          </Button>
        )}
      </form>
    </ContainerForm>
  )
});
