// @flow
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-left: 7px;
`;

export const PointTitleWithButtonTemplate = (props: { title: React$Node, button?: React$Node }) => {
  const { title, button } = props;
  return (
    <Container>
      {title}
      <ButtonContainer>{button}</ButtonContainer>
    </Container>
  );
};
