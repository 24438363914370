export function getAbsolutePosition(el) {
  let found,
    left = 0,
    top = 0,
    width = 0,
    height = 0,
    offsetBase = getAbsolutePosition.offsetBase;
  if (!offsetBase && document.body) {
    offsetBase = getAbsolutePosition.offsetBase = document.createElement("div");
    offsetBase.style.cssText = "position:absolute;left:0;top:0";
    if (document.body) document.body.appendChild(offsetBase);
  }
  if (el && el.ownerDocument === document && "getBoundingClientRect" in el && offsetBase) {
    let boundingRect = el.getBoundingClientRect();
    let baseRect = offsetBase.getBoundingClientRect();
    found = true;
    left = boundingRect.left - baseRect.left;
    top = boundingRect.top - baseRect.top;
    width = boundingRect.right - boundingRect.left;
    height = boundingRect.bottom - boundingRect.top;
  }
  return {
    found: found,
    left: left,
    top: top,
    width: width,
    height: height,
    right: left + width,
    bottom: top + height
  };
}
