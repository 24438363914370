import * as React from "react";
import { ButtonRadioGroup } from "../form-elements/ButtonRadioGroup";
import { getTextValue } from "../appointment-widgets/getTextValue";

import _ from "lodash";

const possibleValuesToButtonGroupItems = possible_values => {
  return _.chain(possible_values?.length ? possible_values : ["Да", "Нет"])
    .map(item => ({ name: item }))
    .keyBy(item => item.name)
    .value();
};

export const RadioGroupWidget = ({ field, tabIndex, onChange, layoutFieldValue }) => {
  const value = getTextValue(field, layoutFieldValue);

  return (
    <ButtonRadioGroup
      tabIndex={tabIndex}
      checked={value}
      name={`${field.name}_${field.id}`}
      items={possibleValuesToButtonGroupItems(field.possible_values)}
      onChange={newValue => {
        onChange({
          field_type: field.field_type,
          field_id: field.id,
          value: newValue || ""
        });
      }}
    />
  );
};
