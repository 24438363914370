import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import detailsInfoIcon from "./icons/info.svg";
import arrowDownIcon from "./icons/down.svg";

const DetailsComponent = styled.div`
  background: #fff5f1;
  width: 653px;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const DetailsHandle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  padding: 24px 30px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 20px 30px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

const DetailsHandleText = styled.span`
  display: flex;
  align-items: center;
`;

const DetailsHandleIcon = styled.img`
  margin-right: 12px;
`;

const DetailsArrowDown = styled.img`
  transition: transform 0.1s linear;
  transform: ${props => (props.opened ? "rotateZ(-180deg)" : "rotateZ(0deg)")};
`;

const DetailsContent = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;

  &.scrolled-top::before,
  &.scrolled-bottom::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 30px;
    pointer-events: none;
  }

  &.scrolled-top::before {
    top: 0;
    background: linear-gradient(to bottom, #fff5f1, transparent);
  }

  &.scrolled-bottom::after {
    bottom: 24px;
    background: linear-gradient(to top, #fff5f1, transparent);
  }
`;

const DetailsContentScroll = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding: 10px 0 10px 30px;
  margin: -10px 0;
  color: #7d7d7d;
  line-height: 23px;
  white-space: break-spaces;

  &::-webkit-scrollbar {
    width: 28px;
    height: 28px;
    scroll-behavior: smooth;
  }

  &::-webkit-scrollbar-thumb {
    border: 10px solid transparent;
    width: 8px;
    height: 8px;
    background: #d8d8d8;
    background-clip: padding-box;
    border-radius: 28px;
  }
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
  @media (max-width: 480px) {
    padding: 10px 15px;
  }
`;

const useScroll = ref => {
  useEffect(() => {
    const scrollListener = ({ target }) => {
      if (target) {
        const offsetHeight = target.clientHeight + target.scrollTop;
        if (target.scrollTop > 0) {
          target.parentNode.classList.add("scrolled-top");
        }
        if (target.clientHeight < target.scrollHeight && offsetHeight !== target.scrollHeight) {
          target.parentNode.classList.add("scrolled-bottom");
        }
        if (target.scrollTop === 0) {
          target.parentNode.classList.remove("scrolled-top");
        }
        if (offsetHeight === target.scrollHeight) {
          target.parentNode.classList.remove("scrolled-bottom");
        }
      }
    };

    // $FlowFixMe
    if (ref && ref.current) {
      ref.current.addEventListener("scroll", scrollListener);
      ref.current.addEventListener("resize", scrollListener);
      scrollListener({ target: ref.current });
      return () => {
        ref.current?.removeEventListener("scroll", scrollListener);
        ref.current?.removeEventListener("resize", scrollListener);
      };
    }
  }, [ref]);
};

export const Details = props => {
  const { title, children } = props;
  const contentRef = useRef(null);
  const scrollRef = useRef(null);
  const [opened, setOpened] = useState(false);

  useScroll(scrollRef);

  return (
    <DetailsComponent>
      <DetailsHandle
        onClick={() => {
          setOpened(!opened);
          if (contentRef) {
            if (!opened) {
              setTimeout(() => {
                contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
                contentRef.current.style.paddingBottom = `24px`;
                if (scrollRef.current.clientHeight < 200) {
                  scrollRef.current.style.paddingRight = `30px`;
                } else {
                  scrollRef.current.style.paddingRight = ``;
                }
              });
            } else {
              contentRef.current.style.height = `0`;
              contentRef.current.style.paddingBottom = `0`;
            }
          }
        }}>
        <DetailsHandleText>
          <DetailsHandleIcon src={detailsInfoIcon} alt="Info" />
          {title}
        </DetailsHandleText>
        <DetailsArrowDown opened={opened} src={arrowDownIcon} alt="Arrow down" />
      </DetailsHandle>
      <DetailsContent ref={contentRef}>
        <DetailsContentScroll ref={scrollRef}>{children}</DetailsContentScroll>
      </DetailsContent>
    </DetailsComponent>
  );
};
