import styled from "styled-components";
import { colors } from "../../styleguide/colors";

export const Loading = styled.div`
  padding: 13px 0;
  font-size: 15px;
  color: ${colors.black};
  opacity: 0.6;
`;

export const Container = styled.div`
  // width: 239px;
`;
