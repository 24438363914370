// @flow
import React from "react";
import { ModalFade } from "../modal/ModalFade";
import { ModalBackground } from "../modal/ModalBackground";
import { useModalEscape } from "../modal/useModalEscape";

export const CommentsModal = (props: {
  isShow: boolean,
  hide: () => void,
  handleClickOutside?: () => void,
  children: React$Node
}) => {
  const { isShow, hide, handleClickOutside, children } = props;

  useModalEscape(hide);

  return (
    <ModalFade>
      {isShow && (
        <ModalBackground
          onClick={e => {
            e.stopPropagation();
            handleClickOutside && handleClickOutside();
          }}>
          {children}
        </ModalBackground>
      )}
    </ModalFade>
  );
};
