// @flow
import React, { useLayoutEffect, useEffect, useMemo, useState, useRef } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable
} from "material-react-table";
import styled from "styled-components";
import { scrollbars } from '../chats/style-elements';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { Box, IconButton, Tooltip, Button, Chip, ListItemText, ListItemIcon, useMediaQuery } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useReactRouter from "use-react-router";
import { FlexContainer, SectionBottom, SectionTop } from "../review-statistics-page/reviewStatisticsStyled";
import {
  filtersStoreToFiltersTable,
  sortingToString,
  getMeta,
  parseAllParams, sortingToParamsTable
} from "../../utils/tableUtils";
import { observer } from "mobx-react";
import _ from "lodash"
import MoreIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AbsoluteLoader } from "../loader/Loader";

const TableContainer = styled.div`
    position: relative;
    max-width: 100%;
    width: 100%;
    height: ${props => props?.height || '100%'};
    .MuiTableContainer-root {
      ${scrollbars}
    }
`;

const ToolbarAlertBannerItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const ToolbarAlertBannerLabel = styled.div`
    flex-shrink: 0;
    padding-top: 4px;
`;

const ToolbarAlertBannerContent = styled.div`
    box-sizing: border-box;
    padding: 0.5rem;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    ${props => !props.defaultPadding && "padding-top: 40px"};
    width: 100%;
    @media (max-width: 720px) {
        padding-top: 0.5rem;
    }
    @media (max-width: 450px) {
        flex-wrap: wrap;
    }
`;

export const renderToolbarAlertBannerContent = ({ groupedAlert, defaultPadding = false }) => {
  if (groupedAlert?.props?.children?.length) {
    const groupItems = groupedAlert.props.children[2].map((item) => item.props.children[1]);
    return (
      <ToolbarAlertBannerContent defaultPadding={defaultPadding}>
        <ToolbarAlertBannerLabel>Сгрупировать по</ToolbarAlertBannerLabel>
        <ToolbarAlertBannerItems>
          {groupItems.map((item) => (
            <Chip key={item.props.label} label={item.props.label} variant="outlined"
                  onDelete={item.props.onDelete} />
          ))}
        </ToolbarAlertBannerItems>
      </ToolbarAlertBannerContent>
    )
  }
};

export const TopToolbarCustomActions = ({ actions, anchorEl, setAnchorEl, table = null, disabled = false, tooltipText = "", enableCollapseCustomActions = true }) => {
  const isMobileDevices = useMediaQuery('(max-width: 768px)');

  const open = (event) => setAnchorEl(event.currentTarget);
  const close = () => setAnchorEl(null);

  return (!enableCollapseCustomActions || (!isMobileDevices && actions.length === 1)) ? (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem", p: "4px" }}>
      {actions.map(({ key, options, text }) => (
        <Button key={key} {...options} onClick={() => options.onClick(table)} disabled={disabled}>
          {text}
        </Button>
      ))}
    </Box>
  ) : (
    <>
      <Tooltip title={tooltipText} placement="right">
        <Box>
          <IconButton size="medium" onClick={open} disabled={disabled}>
            <MoreIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        disableScrollLock
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {actions.map((item) => (
          <MenuItem
            key={item.key}
            onClick={() => {
              item.options.onClick(table);
              close();
            }}>
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>{item.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export const MaterialTable = observer((props: {
  isDisableSavingPresets?: Boolean,
  localStorageKeyName?: string,
  columns: Array,
  enableExpandAll?: boolean,
  enableExpanding?: boolean,
  enableColumnOrdering?: boolean,
  enableGrouping?: boolean,
  enableColumnPinning?: boolean,
  enableFacetedValues?: boolean,
  enableColumnFilters?: boolean,
  enableColumnResizing?: boolean,
  disableFetchAfterChangeFilters?: boolean,
  filterFromLeafRows?: boolean,
  isShowTableData?: boolean,
  getSubRows?: (id: number) => mixed,
  muiTableBodyRowProps?: (id: number) => mixed,
  initialState?: boolean,
  paginateExpandedRows?: boolean,
  enableRowActions?: boolean,
  disableHeightResizing?: boolean,
  notPadding?: boolean,
  parentPaddingBottom?: number,
  enableRowSelection?: boolean,
  renderRowActions?: (id: number) => mixed,
  afterInitializeParams?: (id: number) => mixed,
  actions?: Array,
  editClick?: (id: number) => mixed,
  deleteClick?: (id: number) => mixed,
  store?: () => mixed,
  additionalFilters?: ?React.Node,
  columnOrder?: Array,
  enableCollapseCustomActions?: boolean,
  topToolbarCustomActions?: {
    key: string,
    options: {
      color: string,
      variant: string,
      disabled?: boolean,
      onClick: () => void
    },
    text: string
  }[],
  localization?: mixed,
  columnDefOptions?: mixed,
  changedColumnFilters?: mixed,
}) => {
  props.store.enableSavingPresets(!props.isDisableSavingPresets);
  const { history, location } = useReactRouter();

  const defaultColumnVisible = (localStorage.getItem(`${props.localStorageKeyName || location.pathname}TableColumn`) ? props.columns.reduce((acc, column) => {
    acc[column.accessorKey] = JSON.parse(localStorage.getItem(`${props.localStorageKeyName || location.pathname}TableColumn`)).includes(column.accessorKey);
    return acc;
  }, {}) : props.initialState?.columnVisibility) || {};

  const rowVirtualizerInstanceRef = useRef(null);
  const tableRef = useRef(null);
  const [columnKeys, setColumnKeys] = useState([]);
  const [meta, setMeta] = useState({});
  const [isInitializedParams, setIsInitializedParams] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [columnPinning, setColumnPinning] = useState({ left: [], right: []});
  const [columnVisibility, setColumnVisibility] = useState(defaultColumnVisible);
  const [tableHeight, setTableHeight] = useState(0);
  const [topToolbarCustomActionsAnchor, setTopToolbarCustomActionsAnchor] = useState(null);

  const paddingBottom = !props.notPadding ? 70 : props.notPadding && props.parentPaddingBottom ? props.parentPaddingBottom : 0;
  const tableTopPosition = () => tableRef?.current?.getBoundingClientRect().top;
  /** Высота области видимости экрана браузера - начало таблицы - имеющиеся отступы (контейнер FlexContainer / внешний контейнер) */
  const handleChangeTableHeightOnResize = () => setTableHeight(window.innerHeight - tableTopPosition() - paddingBottom);

  useEffect(() => {
    if (props.disableHeightResizing) {
      return;
    }
    window.addEventListener('resize', handleChangeTableHeightOnResize);
    return () => window.removeEventListener('resize', handleChangeTableHeightOnResize);
  }, [window.innerWidth, window.innerHeight]);

  useEffect(() => {
    if (!isInitializedParams || props.disableHeightResizing) {
      return;
    }
    handleChangeTableHeightOnResize();
  }, [isInitializedParams, tableTopPosition]);

  const editItem = (row) => {
    props.store.changeOldParams({});
    history.push(`${location.pathname}/${row[props.store.keyTableField] || row.id}`);
  }

  const setColumnsStore = (needFetch) => {
    const columnVisible = Object.keys(columnVisibility).filter((key) => !columnVisibility[key])
    const columns = props.columns.filter((column) => !columnVisible.includes(column.accessorKey));
    const values = [];
    columns.forEach((item) => {
      if (item.id) {
        values.push(item.id)
      }
      if (item.additionalFields) {
        item.additionalFields.forEach((field) => {
          values.push(field);
        })
      }
    })
    localStorage.setItem(`${props.localStorageKeyName || location.pathname}TableColumn`, JSON.stringify(values));
    props.store.setValuesParamsTable(values, needFetch);
  };

  const renderRowActions = ({ row }) => (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      {props.actions.includes('edit') && (
        <Tooltip title="Редактировать">
          <IconButton onClick={() => props.editClick ? props.editClick(row.original) : editItem(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}

      {props.actions.includes('delete') && (
        <Tooltip title="Удалить">
          <IconButton color="error" onClick={() => props.deleteClick(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );

  const data = useMemo(() => {
    return props.store.dataTable;
  }, [props.store.dataTable]);

  const table = useMaterialReactTable({
    rowVirtualizerInstanceRef,
    columns: props.columns,
    data: data,
    enableExpandAll: props.enableExpandAll || false,
    enableExpanding: props.enableExpanding || false,
    enableColumnOrdering: props.enableColumnOrdering || false,
    enableGrouping: props.enableGrouping || false,
    enableColumnFilters: props.enableColumnFilters === undefined ? true : props.enableColumnFilters,
    enableColumnResizing: props.enableColumnResizing || false,
    enableFacetedValues: props.enableFacetedValues || false,
    filterFromLeafRows: props.filterFromLeafRows || false,
    getSubRows: props.getSubRows ? props.getSubRows : (row) => row.subRows, //default
    initialState: {
      showColumnFilters: !!_.size(props.store.filtersTable),
      density: 'compact',
      ...props.initialState,
    },
    enableFilterMatchHighlighting: false,
    paginateExpandedRows: props.paginateExpandedRows || false, //When rows are expanded, do not count sub-rows as number of rows on the page towards pagination
    enableRowSelection: props.enableRowSelection || false,
    muiTableBodyRowProps: props.muiTableBodyRowProps,
    onColumnFiltersChange: setColumnFilters,
    onColumnPinningChange: setColumnPinning,
    onSortingChange: setSorting,
    onPaginationChange: setMeta,
    enableStickyHeader: true,
    layoutMode: 'grid',
    localization: { ...MRT_Localization_RU, ...props.localization },
    rowCount: props.store.metaTable.total_items,
    enableRowActions: props.renderRowActions || props.actions && props.actions.includes('edit'),
    renderRowActions: props.renderRowActions ? props.renderRowActions : props.actions && props.actions.length ? renderRowActions : '',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    positionToolbarAlertBanner: 'bottom',
    renderToolbarAlertBannerContent: renderToolbarAlertBannerContent,
    isMultiSortEvent : ( ) => true,
    enableGlobalFilter: false,
    enableColumnPinning: true,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 5 },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15, 20, 25, 30, 50, 100, 300, 500, 1000],
    },
    state: {
      pagination: meta,
      columnFilters,
      sorting,
      columnVisibility,
      columnPinning,
      columnOrder: props.columnOrder || []
    },
    onColumnVisibilityChange: setColumnVisibility,
    displayColumnDefOptions:  props.columnDefOptions
        ? props.columnDefOptions
        : {
          "mrt-row-actions": {
            size: 85
          }
        },
    renderTopToolbarCustomActions: () => {
      if (!props.topToolbarCustomActions?.length) {
        return null;
      }

      return (
        <TopToolbarCustomActions
          actions={props.topToolbarCustomActions}
          enableCollapseCustomActions={props.enableCollapseCustomActions}
          anchorEl={topToolbarCustomActionsAnchor}
          setAnchorEl={setTopToolbarCustomActionsAnchor}
        />
      )
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
      }
    }),
    muiTablePaperProps: {
      sx: {
        height: '100%',
      },
    },
  });

  useEffect(() => {
    (async () => {
      const params = parseAllParams(location.search.slice(1, location.search.length).split('&'), props.columns);
      if (props.store.isEnableSavingPresets) {
        if (params.filters && _.size(params.filters)) {
          props.store.setFiltersTable(params.filters, false);
          setColumnFilters(filtersStoreToFiltersTable(props.store.filtersTable, props.columns));
        }
        if (params.sorting && params.sorting.length) {
          props.store.setSortingTable(params.sorting, false);
          setSorting(sortingToParamsTable(props.store.sortingTable));
        }
        if (params.globalfilters && _.size(params.globalfilters)) {
          props.store.setGlobalFiltersTable(params.globalfilters, false);
        }
      } else {
        setColumnFilters(filtersStoreToFiltersTable(props.store.filtersTable, props.columns) );
      }
      if (params.columnPinning && _.size(params.columnPinning)) {
        props.store.setColumnPinning(params.columnPinning || { left: [], right: [] });
        setColumnPinning(props.store.columnPinning);
      } else if (localStorage.getItem(`${props.localStorageKeyName || location.pathname}ColumnPinning`)) {
        setColumnPinning(JSON.parse(localStorage.getItem(`${props.localStorageKeyName || location.pathname}ColumnPinning`)));
      }
      props.store.setMetaTable(getMeta(params.meta), false, true);
      setMeta(props.store.metaTable);
      setColumnsStore(false);
      if (props.afterInitializeParams) {
        props.afterInitializeParams(async () => {
          await props.store.fetchDataTable();
        });
      } else {
        await props.store.fetchDataTable();
      }
      setIsInitializedParams(true);
    })();
  }, []);

  useLayoutEffect(() => {
    if (!isInitializedParams || !columnPinning) {
      return;
    }
    if (!_.isEqual(props.store.columnPinning, columnPinning)) {
      props.store.setColumnPinning(columnPinning);
      localStorage.setItem(`${props.localStorageKeyName || location.pathname}ColumnPinning`, JSON.stringify(columnPinning));
    } else {
      localStorage.setItem(`${props.localStorageKeyName || location.pathname}ColumnPinning`, JSON.stringify(props.store.columnPinning));
    }
  }, [columnPinning]);

  useLayoutEffect(() => {
    (async () => {
      if (!isInitializedParams) {
        return;
      }
      const filters = columnFilters.reduce((acc, filter) => {
        const column = props.columns.find((item) => item.accessorKey === filter.id)
        const filterFn = column._filterFn || column.filterFn;
        if ((!filter.value && filter.value !== false && filter.value !== 0) || (Array.isArray(filter.value) && !filter.value.length)) {
          return acc;
        }
        if (filterFn === "range") {
          acc[`${filter.id}__gte`] = filter.value[0];
          acc[`${filter.id}__lte`] = filter.value[1];
        } else if (filterFn) {
          acc[`${filter.id}__${filterFn}`] = filter.value;
        } else {
          acc[filter.id] = filter.value;
        }
        return acc;
      }, {})
      if (!_.isEqual(props.store.filtersTable, filters)) {
        props.store.setFiltersTable(filters, !props.disableFetchAfterChangeFilters);
      }
    })();
  }, [columnFilters]);

  useLayoutEffect(() => {
    (async () => {
      if (!isInitializedParams) {
        return;
      }
      if (
        (parseInt(props.store.metaTable.pageIndex) !== parseInt(meta.pageIndex) ||
          parseInt(props.store.metaTable.pageSize) !== parseInt(meta.pageSize) ||
          parseInt(props.store.metaTable.total_items) !== parseInt(meta.total_items)) &&
        _.size(meta)
      ) {
        props.store.setMetaTable(meta, true, isInitializedParams, setMeta);
      }
    })();
  }, [meta]);

  useLayoutEffect(() => {
    (async () => {
      if (!isInitializedParams) {
        return;
      }
      if (
        (parseInt(props.store.metaTable.pageIndex) !== parseInt(meta.pageIndex) ||
          parseInt(props.store.metaTable.pageSize) !== parseInt(meta.pageSize) ||
          parseInt(props.store.metaTable.total_items) !== parseInt(meta.total_items)) &&
        _.size(meta)
      ) {
        setMeta(props.store.metaTable);
      }
    })();
  }, [props.store.metaTable]);

  useLayoutEffect(() => {
    (async () => {
      if (!isInitializedParams) {
        return;
      }
      const sortingTable = sortingToString(sorting);
      if (!_.isEqual(props.store.sortingTable, sortingTable)) {
        props.store.setSortingTable(sortingTable);
      }
    })();
  }, [sorting]);

  useEffect(() => {
    const keys = props.columns.map((column) => column.accessorKey);
    if (!_.isEqual(keys, columnKeys)) {
      setColumnKeys(keys);
    }
  }, [props.columns]);

  useEffect(() => {
    setColumnsStore(isInitializedParams);
  }, [columnKeys]);

  useLayoutEffect(() => {
    (async () => {
      if (!isInitializedParams) {
        return;
      }
      setColumnsStore(isInitializedParams);
    })();
  }, [columnVisibility]);

  useEffect(() => {
    if (!props.changedColumnFilters?.length) {
      return;
    }
    let newColumnVisible = {};
    props.changedColumnFilters.forEach((column) => {
      newColumnVisible[column.id] = true;
    })
    setColumnVisibility({ ...columnVisibility, ...newColumnVisible });
    setColumnFilters(props.changedColumnFilters);
  }, [props.changedColumnFilters])

  return (
    <>
      {((props.isShowTableData && props.store.dataTable && props.store.dataTable.length) || !props.isShowTableData) && (
        <FlexContainer notPadding={props.notPadding}>
          {props.additionalFilters && (
            <SectionTop>
              {props.additionalFilters}
            </SectionTop>
          )}
          <SectionBottom>
            <TableContainer ref={tableRef} height={!props.disableHeightResizing ? `${tableHeight}px` : '100%'}>
              {props.store.isLoading && <AbsoluteLoader zIndex={5} />}
              {isInitializedParams && (
                <MaterialReactTable table={table} />
              )}
            </TableContainer>
          </SectionBottom>
        </FlexContainer>
      )}
    </>
  );
});
