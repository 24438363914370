import React from "react";

import * as S from "./styled/SidebarTreeSearch.styled";

export const SidebarTreeSearch = ({ query, onReset, onChange, placeholder }) => (
  <S.Container>
    <S.Input value={query} placeholder={placeholder} onChange={onChange} />
    {query && <S.Reset onClick={onReset} />}
  </S.Container>
);
