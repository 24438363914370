import React from "react";
import toast from "react-hot-toast";

import { Toast, defaultToastDuration } from "./toast";
import { addToast, removeToast } from "./toastStore";

export const useToast = () => {
  const showToast = (content, options = {}, data = {}) => {
    const { type = "default", onClose, onClick, ...restOptions } = options;
    const toastId = toast(
      t => (
        <Toast type={type} toastData={t} onClose={onClose} onClick={onClick}>
          {typeof content === "function" ? content(t) : content}
        </Toast>
      ),
      {
        icon: null,
        ...restOptions
      }
    );
    addToast({
      toastId,
      data
    });
    setTimeout(() => {
      removeToast(toastObject => toastObject.toastId === toastId);
    }, options.duration || defaultToastDuration);
    return toastId;
  };

  const closeToast = toastId => {
    toast.dismiss(toastId);
    removeToast(toastObject => toastObject.toastId === toastId);
  };

  return { showToast, closeToast };
};
