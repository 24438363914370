// @flow
import * as React from "react";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";

import { colors } from "../styleguide/colors";
import { TransitionGroup } from "react-transition-group";
import transition from "styled-transition-group";
import { Container as SidebarHeaderContainer } from "./SidebarHeader";
import { rgba } from "polished";
import { headerHeight } from "../services/styled/ServicesTree.styled";
import { useClickOutside } from "../../utils/useClickOutside";
import { closeRightSidebar } from "../../stores/rightSidebar";

export const StyledSidebarContainer = styled.div`
  height: 100vh;

  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.09);
  background-color: ${colors.white};

  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const FixedContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  max-width: ${props => props?.maxWidth ? props.maxWidth : 429}px;
  width: 100%;
  
  @media (max-width: ${props => props?.maxWidth ? props.maxWidth : 429}px) {
    max-width: 100%;
  }
`;

export const Container = styled.div`
  height: 100%;
`;

export const Header = styled(SidebarHeaderContainer)`
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-height: ${headerHeight}px;
  padding: 24px;
  background-color: ${rgba(colors.gray100, 0.3)};
  font-size: 22px;
  line-height: 22px;
`;

const SidebarTransition = transition.div`
    &:enter {
        transform: translateX(100%);
    }

    &:enter-active {
        transform: translateX(0);
        transition: all 300ms ease-in-out;
    }

    &:exit {
        transform: translateX(0);
    }

    &:exit-active {
        transform: translateX(100%);
        transition: all 200ms ease-in;
    }
`;

const Overrides = {
  StyledSidebarContainer,
  SidebarTransition
};

export const SidebarContainer = (props: {
  children?: React.Node,
  isShown: boolean,
  overrides?: ExtractOverridesProps<typeof Overrides>,
  containerMaxWidth?: number;
  closeOnClickOutside?: boolean;
}) => {
  const ref = React.useRef(null);

  useClickOutside(ref, () => {
    if (props.closeOnClickOutside) {
      closeRightSidebar();
    }
  }, [props.closeOnClickOutside]);

  return (
    <FixedContainer ref={ref} maxWidth={props.containerMaxWidth}>
      <TransitionGroup>
        {props.isShown && (
          <SidebarTransition timeout={300}>
            <StyledSidebarContainer {...o}>{props.children}</StyledSidebarContainer>
          </SidebarTransition>
        )}
      </TransitionGroup>
    </FixedContainer>
  );
};
