// @flow
import * as React from "react";
import styled from "styled-components";
import { withModifiers } from "with-modifiers";
// import { useStore } from "effector-react";

// import { currentUserDoctorId } from "../../stores/auth";
import type { Appointment, AppointmentFolder } from "../../domain/entities/Appointment";
import { isInProgress, getAppointmentDateOrTime } from "../../domain/services/appointments";
import { colors } from "../styleguide/colors";
import { PXSmall, PSidebar } from "../styleguide/typography";
import o, { type ExtractOverridesProps } from "react-overrides";
import { FeedbackIcon } from "../feedback-icon/FeedbackIcon";
import type { TFeedback } from "../../domain/entities/Feedback";
import { isCompleted } from "../../domain/services/feedback";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import { FolderSelect } from "../folder-select/FolderSelect";
import { FolderIcon } from "../folder-select/FolderIcon";
import { Link } from "react-router-dom";
import { useStore } from "effector-react";
import { currentUser } from "../../stores/auth";
import appointmentStore from "../../stores/AppointmentStore";
import { observer } from "mobx-react";

const Container = withModifiers({
  opened: () => `
    background-color: rgba(255, 245, 241, 0.7);
    border: none;
    border-top: 1px solid rgba(255, 245, 241, 0.7);

    & + & {
      border-top: 1px solid rgba(255, 245, 241, 0.7);
    }

    :before {
      opacity: 1;
    }

    :hover {
      background-color: rgba(255, 245, 241, 0.7) !important;
    }
  `,

  inProgress: () => `
    :after {
      content: "";
      position: absolute;
      top: 50%;
      right: 26px;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: ${colors.magenta};
    }
  `,

  hasFolderSelect: () => `
    :hover ${FolderIcon} {
      right: 10px;
      opacity: 1;
    }
  `,

  inFolder: () => ``
})(
  modifier => styled.button`
    width: 100%;
    padding: ${props => (props.to.state.inFolder ? "14px 44px 14px 48px" : "14px 44px 14px 24px")};
    box-sizing: border-box;
    border: none;
    border-top: 1px solid ${colors.transparentGray};
    background-color: ${colors.white};
    text-align: left;

    cursor: pointer;
    position: relative;
    display: block;
    text-decoration: none;
    transition: all 0.15s ease-in-out;

    :first-child {
      border-top: none;
    }

    :before {
      content: "";
      width: 4px;
      height: 100%;
      background-color: ${colors.magenta};

      position: absolute;
      top: 0;
      left: 0;

      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }

    ${PSidebar} {
      transition: all 0.15s ease-in-out;
    }

    ${modifier};

    :hover {
      background-color: rgba(216, 216, 216, 0.15);

      ${PSidebar} {
        color: ${colors.magenta};
      }

      :before {
        opacity: 1;
      }

      ${FolderIcon} {
        right: ${p => (p.to.state.showFeedbackIcon ? "10" : "-18")}px;
        opacity: 1;
      }
    }
  `
);

const AsideAppointmentFeedbackIcon = styled(FeedbackIcon)`
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
`;

const Overrides = {
  Container
};

const Info = styled(PXSmall)`
  opacity: 0.6;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

const InfoNotGrouping = styled(PXSmall)`
  opacity: 0.6;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DoctorName = styled.span`
  display: inline-block;

  &:before {
    margin: 0 6px;
    display: inline-block;
    content: "";
    height: 9px;
    width: 1px;
    background: ${colors.gray500};
  }
`;

const FolderName = withModifiers({
  selected: () => `
    color: ${colors.magenta};
    font-weight: 500;   
  `
})(
  modifier => styled(Link)`
    display: inline-block;
    text-decoration: none;
    color: ${colors.black};
    &:hover {
      color: ${colors.magenta};
    }
  
    ${modifier};
  `
);

export const AsideAppointment = observer((props: {|
  opened: boolean,
  selectedFolder: boolean,
  inFolder: boolean,
  folder?: $Shape<AppointmentFolder>,
  appointment: $Shape<Appointment>,
  feedback?: ?TFeedback,
  showFeedbackIcon: boolean,
  handleAppointmentClick?: (id: number) => mixed,
  overrides?: ExtractOverridesProps<typeof Overrides>
|}) => {
  const user = useStore(currentUser);
  const { appointment, feedback, inFolder, folder, showFeedbackIcon } = props;
  const isCurrentDoctor = user && user.role === "doctor" && user.doctor && user?.doctor?.id === appointment.doctor.id;
  let link = "";
  if (!appointmentStore.isDisplayFolders && !!folder) {
    if (!folder.questionnaire.is_saved && isCurrentDoctor) {
      link = `/appointment/folder/${folder.id}/questionnaire`;
    } else {
      link = `/appointment/folder/${folder.id}`;
    }
  }
  // const doctorId = useStore(currentUserDoctorId);
  // const isAppointmentMaintainter = appointment?.doctor?.id === doctorId;
  // const isFolderMaintainer = folder?.doctor === doctorId;
  const hasFolderMoveRights = true; // isAppointmentMaintainter || isFolderMaintainer;

  return (
    <Container
      {...o}
      onClick={() => props.handleAppointmentClick && props.handleAppointmentClick(appointment.id)}
      modifiers={[
        inFolder && "inFolder",
        hasFolderMoveRights && "hasFolderSelect",
        props.opened && "opened",
        isInProgress(appointment) && "inProgress"
      ]}>

      {(!appointmentStore.isDisplayFolders && !!folder) ? (
        <InfoNotGrouping modifiers={"left"}>
          <FolderName to={link} modifiers={props.selectedFolder && "selected"}>
            {folder.name}
          </FolderName>
          <div>
            {getAppointmentDateOrTime(appointment)}
            <DoctorName>
              {appointment.doctor && getLastNameWithInitials(appointment.doctor)}
            </DoctorName>
          </div>
        </InfoNotGrouping>
      ) : (
        <Info modifiers={"left"}>
          {getAppointmentDateOrTime(appointment)}
          <DoctorName>
            {appointment.doctor && getLastNameWithInitials(appointment.doctor)}
          </DoctorName>
        </Info>
      )}
      <PSidebar>{appointment.layout && appointment.layout.name}</PSidebar>
      {!isInProgress(appointment) && hasFolderMoveRights && (
        <FolderSelect appointment={appointment} folderId={folder?.id} inFolder={inFolder} />
      )}
      {/* $FlowFixMe */}
      {showFeedbackIcon && <AsideAppointmentFeedbackIcon isCompleted={isCompleted(feedback)} />}
    </Container>
  );
});
