import apiv2 from '../apiv2';
import { makeAutoObservable } from "mobx";
import _ from "lodash";
import { organizationsColor } from "../utils/organizationsColor";
import moment from "moment";

export const INTERVAL_FORMAT = {
  minute: 'DD.MM.YYYY HH:mm',
  hour: 'DD.MM.YYYY HH:mm',
  day: 'DD.MM.YYYY',
  week: 'DD.MM.YYYY',
  month: 'MMMM',
  year: 'YYYY'
}

export const transformTableData = ({data, valueName, stories, storiesStore}) => {
  const returnData = [];
  if (Array.isArray(data)) {
    const storiesData = _.groupBy(data, 'story_id');
    Object.keys(storiesData).forEach((storyId) => {
      const statusData = _.groupBy(storiesData[storyId], 'story_status');
      Object.keys(statusData).forEach((key) => {
        returnData.push(
          {
            story: stories.find((story) => parseInt(story.id) === parseInt(storyId))?.miniature_title,
            story_status: storiesStore.storiesStatusOptions.find((item) => item.value === key)?.label || "",
            story_status_key: key,
            count: statusData[key].reduce((acc, item) => {
              acc += item[valueName];
              return acc;
            }, 0)
          }
        )
      })
    })
  }
  return returnData;
};

export const transformDataDiagram = ({data, interval, isCollapseData, valueField = 'count'}) => {
  const groupedDataDate = _.groupBy(_.sortBy(data, 'date_trunc'), 'date_trunc');
  const groupedDataStories = _.groupBy(_.sortBy(data, 'story_id'), 'story_id');
  const groupedDataDateStories = Object.keys(groupedDataDate).reduce((acc, key) => {
    acc[key] = _.groupBy(_.sortBy(groupedDataDate[key], 'story_id'), 'story_id');
    return acc;
  }, {})
  const labels = Object.keys(groupedDataDate);
  const storiesData = Object.keys(groupedDataStories).reduce((acc, key) => {
    acc[key] = {
      open: [],
      targeted_action: [],
      viewed_30: [],
      viewed_70: [],
      label: '',
    };
    return acc;
  }, {});
  labels.forEach((labelDate) => {
    Object.keys(groupedDataStories).forEach((storyId) => {
      const dataStories = groupedDataDateStories[labelDate][storyId];
      if (!storiesData[storyId].label && dataStories) {
        storiesData[storyId].label = dataStories[0].story_name;
      }
      if (dataStories) {
        const groupedFormat = _.groupBy(dataStories, 'story_status');
        const setData = (key) => {
          if (groupedFormat[key]?.length) {
            storiesData[storyId][key].push(groupedFormat[key].reduce((acc, item) => {
              acc += item[valueField];
              return acc;
            }, 0));
          } else {
            storiesData[storyId][key].push(0);
          }
        }
        setData('open');
        setData('targeted_action');
        setData('viewed_30');
        setData('viewed_70');
      } else {
        storiesData[storyId].open.push(0);
        storiesData[storyId].targeted_action.push(0);
        storiesData[storyId].viewed_30.push(0);
        storiesData[storyId].viewed_70.push(0);
      }
    })
  })

  const datasets = Object.keys(storiesData).reduce((acc, key) => {
    const data = storiesData[key];
    if (isCollapseData) {
      if (!acc.length) {
        acc.push({
          label: data.label + ' - открыли',
          data: data.open || [],
          color: '#ea0000',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + ' - совершенно целевое действие',
          data: data.targeted_action || [],
          color: '#0430f6',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + ' - просмотрено 30%',
          data: data.viewed_30 || [],
          color: '#24d313',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + ' - просмотрено 70%',
          data: data.viewed_70 || [],
          color: '#e7e308',
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
      } else {
        acc[0].label += ', ' + data.label + ' - открыли';
        acc[1].label += ', ' + data.label + ' - совершенно целевое действие';
        acc[2].label += ', ' + data.label + ' - просмотрено 30%';
        acc[3].label += ', ' + data.label + ' - просмотрено 70%';

        data.open.forEach((count, index) => {
          acc[0].data[index] = acc[0].data[index] + count;
        })

        data.targeted_action.forEach((count, index) => {
          acc[1].data[index] = acc[1].data[index] + count;
        })
        data.viewed_30.forEach((count, index) => {
          acc[2].data[index] = acc[2].data[index] + count;
        })

        data.viewed_70.forEach((count, index) => {
          acc[3].data[index] = acc[3].data[index] + count;
        })
      }
    } else {
      acc.push({
        label: data.label + ' - открыли',
        data: data.open || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - совершенно целевое действие',
        data: data.targeted_action || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - просмотрено 30%',
        data: data.viewed_30 || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - просмотрено 70%',
        data: data.viewed_70 || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
    }
    return acc;
  }, []);
  return { labels, datasets };
}


class StoriesStore {
  stories = [];

  storiesStatus = {
    open: 'Открыли',
    targeted_action: 'Совершенно целевое действие',
    viewed_30: 'Просмотрено 30%',
    viewed_70: 'Просмотрено 70%'
  }
  storiesStatusOptions = [
    {
      label: 'Открыли',
      value: 'open'
    },
    {
      label: 'Совершенно целевое действие',
      value: 'targeted_action'
    },
    {
      label: 'Просмотрено 30%',
      value: 'viewed_30'
    },
    {
      label: 'Просмотрено 70%',
      value: 'viewed_70'
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  async getStories() {
    if (!this.stories.length) {
      try {
        this.stories = await apiv2.stories.getStories();
        return this.stories;
      } catch(e) {
        console.error(e);
        return false;
      }
    } else {
      return this.stories;
    }
  }

  async getAnalyticsStoriesData(filters) {
    try {
      return await apiv2.stories.getAnalyticsStories(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }
}

export default new StoriesStore();