// @flow
import React from "react";
import _ from "lodash";
import { ButtonStyled, ContainerStyled } from "../styles/ChatFooter.styled";
import { IconContainerStyled } from "../../icon-containter-styles/IconContainer.styled";
import { Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";

interface TChatFooterProps {
  view: {
    icon: React.Node,
    label: string
  };
  controlled: {
    handleOnClick: () => void,
    selected?: boolean
  }[];
}

export const ChatFooter = ({ contents }: { contents: TChatFooterProps }) => {
  /** В моделируемах чатах нужен модификатор active, для отображения стиля активной кнопки использовать color="secondary", в противном случае color="primary" */
  return (
    <>
      {(!!contents.length && !!contents.filter((item) => item.show).length) && (
        <ContainerStyled direction="row">
          {contents.map(({ view, controlled }) => (
            <React.Fragment key={_.uniqueId()}>
              <ButtonStyled
                onClick={controlled.handleOnClick}
                color={controlled.selected ? "secondary" : "primary"}>
                {view.icon && (
                  <IconContainerStyled stroke="white">
                    <SettingsIcon />
                  </IconContainerStyled>
                )}
                {view.label && <Typography>{view.label}</Typography>}
              </ButtonStyled>
            </React.Fragment>
          ))}
        </ContainerStyled>
      )}
    </>
  );
};
