import { CorporateSubTitle, CorporateTitle } from "../styled/CorporateTypography.styled";
import React from "react";
import styled from "styled-components";
import { Avatar as MuiAvatar } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";
import { breakpoints } from "../styled/common";
import { shortName } from "../utils/shortName";

const CommentContainer = styled.div`
  display: flex;
  padding: 8px 0;
  gap: 8px;
  width: 100%;
`;

const Avatar = MuiStyled(MuiAvatar)`
	width: 36px;
	height: 36px;
	font-size: 0.9rem;
	text-transform: uppercase;
  margin-top: 4px;

	${breakpoints.desktop} {
		width: 30px;
		height: 30px;
		font-size: 0.8rem;
	}
	${breakpoints.tablet} {
		width: 25px;
		height: 25px;
		font-size: 0.7rem;
	}
`;

const CommentContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const CommentInfo = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  ${CorporateSubTitle} {
    line-height: 100%;
  }

  @media screen and (max-width: 380px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CommentBody = styled.div`
  white-space: pre-wrap;
  line-height: 20px;
`;

export const CorporateComment = props => {
  return (
    <CommentContainer>
      <Avatar src={props.author.src}>
        {shortName({ lastName: props.author.lastName, firstName: props.author.firstName })}
      </Avatar>
      <CommentContent>
        <CommentInfo>
          <CorporateTitle modifiers={["sm", "black"]}>{props.author.fullName}</CorporateTitle>
          <CorporateSubTitle modifiers={["md", "gray"]}>
            {props.date} {props.time}
          </CorporateSubTitle>
        </CommentInfo>
        <CommentBody>{props.text}</CommentBody>
      </CommentContent>
    </CommentContainer>
  );
};
