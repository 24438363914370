import React, { useEffect } from "react";
import { keyBy } from "lodash";
import styled from "styled-components";
import useReactRouter from "use-react-router";
import { useStore } from "effector-react";
import Spreadsheet from "@blessmesanta/react-spreadsheet/dist/index";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { SelectEdit, SelectView } from "../embryos-table/EmbryosTableSelect";
import { tableSelectColors } from "../styleguide/colors";
import { Row } from "../embryos-table/EmbryosTableRow";
import { Cell } from "../embryos-table/EmbryosTableCell";
import { ColumnIndicator } from "../embryos-table/EmbryosTableColumnIndicator";
import { WideTextEdit } from "../embryos-table/EmbryosTableTextEdit";
import { EmbryosTableContext } from "../embryos-table/EmbryosTableContext";
import { constructTableRowPayload } from "../embryos-table/utils";
import { tokenStore } from "../../stores/auth";
import printIcon from "../appointment-page/icons/print.svg";
import apiv2 from '../../apiv2';

const renderedColumns = ["Название", "Статус"];

const PrintButton = styled.button`
  position: absolute;
  right: 0;
  top: -8px;
  opacity: 0;
  transition: 0.2s;
  border: none;
  padding: 2px 7px;
  border-radius: 3px;
  cursor: pointer;
`;

const WideTextViewStyled = styled.div`
  min-width: 448;
  position: relative;

  &:hover ${PrintButton} {
    opacity: 1;
  }
`;

const ExtraWideTextView = props => {
  const { history } = useReactRouter();
  return (
    <WideTextViewStyled>
      {props.cell.value}
      <PrintButton
        type="button"
        onMouseDown={e => {
          e.stopPropagation();
          history.push(`agreements/print/${props.cell.entityId}`);
        }}>
        <img src={printIcon} alt="print" />
      </PrintButton>
    </WideTextViewStyled>
  );
};

const statusOptions = [
  { value: true, label: "Подписано", color: tableSelectColors.green },
  { value: false, label: "Не подписано", color: tableSelectColors.red }
];

export const StatusEditSelectComponent = props => <SelectEdit {...props} options={statusOptions} />;
export const StatusViewSelectComponent = props => <SelectView {...props} options={statusOptions} />;

const Container = styled(RoundedRectangleWithShadow)`
  overflow: auto;
  padding: 32px;
  @media (max-width: 768px) {
    padding: 25px 30px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
  }
`;

const getTableData = (data, readOnly = false) => {
  return data.map(field => [
    {
      value: field.name,
      field: "name",
      DataViewer: ExtraWideTextView,
      DataEditor: WideTextEdit,
      entityId: field.id,
      readOnly: true
    },
    {
      value: field.is_signed,
      field: "is_signed",
      DataEditor: StatusEditSelectComponent,
      DataViewer: StatusViewSelectComponent,
      isMinimal: true,
      isShort: true,
      entityId: field.id,
      readOnly
    }
  ]);
};

export const IvrAgreementsTable = ({ data, folderId, readOnly, setSyncing }) => {
  const token = useStore(tokenStore);

  const [state, setState] = React.useState([]);
  const tableDataById = React.useRef({});

  useEffect(() => {
    if (data) {
      tableDataById.current = keyBy(data, "id");
      setState(getTableData(data), readOnly);
    }
  }, [data, readOnly]);

  const onCellUpdate = async (row, cell) => {
    try {
      setSyncing(true);
      const tableData = constructTableRowPayload(state[row]);
      const originalTableData = tableDataById.current[tableData.id];
      const payload = {
        ...originalTableData,
        ...tableData,
        [cell.field]: cell.value
      };

      const responseData = await apiv2.appointments.updateFolderAgreement(folderId, payload.id, payload);
      const keys = Object.keys(responseData);

      const replacedKeys = keys.filter(key => payload[key] !== responseData[key]);
      if (replacedKeys.length) {
        setState(state =>
          state.map((stateRow, index) => {
            if (index === parseInt(row, 10)) {
              const nextTableData = getTableData([responseData], readOnly)[0];
              tableDataById.current[tableData.id];
              return nextTableData;
            }
            return stateRow;
          })
        );
      }
    } finally {
      setSyncing(false);
    }
  };

  const onPaste = async cells => {
    try {
      setSyncing(true);
      const rowsToUpdate = cells.reduce((res, curr) => {
        if (!res[curr.row]) {
          res[curr.row] = [];
        }
        res[curr.row].push({ field: state[curr.row][curr.column].field, value: curr.value?.value });
        return res;
      }, {});

      const payloadPairs = Object.keys(rowsToUpdate).map(key => {
        const cells = rowsToUpdate[key];
        const tableData = constructTableRowPayload(state[key]);
        const originalTableData = tableDataById.current[tableData.id];
        const payload = {
          ...originalTableData,
          ...tableData
        };
        cells.forEach(cell => (payload[cell.field] = cell.value));
        return [payload, key];
      });

      for (const [payload, row] of payloadPairs) {
        const responseData = await apiv2.appointments.updateFolderAgreement(folderId, payload.id, payload);
        const keys = Object.keys(responseData);

        if (!keys.length)
          return setState(state => state.filter((stateRow, index) => index !== parseInt(row, 10)));

        const replacedKeys = keys.filter(key => payload[key] !== responseData[key]);
        if (replacedKeys.length) {
          setState(state =>
            state.map((stateRow, index) =>
              index === parseInt(row, 10) ? getTableData([responseData], readOnly)[0] : stateRow
            )
          );
        }
      }
    } finally {
      setSyncing(false);
    }
  };

  return (
    <Container>
      <EmbryosTableContext.Provider
        value={{ onCellUpdate, onRowDelete: null, onRowDuplicate: null }}>
        <Spreadsheet
          Row={Row}
          data={state}
          Cell={Cell}
          onPaste={onPaste}
          onChange={setState}
          hideRowIndicators={true}
          autoPadRowsOnPaste={false}
          columnLabels={renderedColumns}
          ColumnIndicator={ColumnIndicator}
          className="Spreadsheet-full table-100"
        />
      </EmbryosTableContext.Provider>
    </Container>
  );
};
