// @flow
import styled from "styled-components";
import iconFeedback from "./icons/icon-folder.svg";

export const FolderIcon = styled.div`
  position: absolute;
  right: -18px;
  top: -26px;
  width: 20px;
  height: 18px;
  background: url(${iconFeedback});
  opacity: 0;
  transition: 0.3s ease;
`;
