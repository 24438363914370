import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Call {
	apiService;
	constructor() {
		this.apiService = new ApiService('/api/call', null, null, API_URL);
	}

	get(id) {
		return this.apiService.get(`${id}`);
	}

	create(room, start_time, end_time) {
		return this.apiService.post('', {}, {room, start_time, end_time});
	}

}

export default new Call();
