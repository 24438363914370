import styled from "styled-components";

import { colors } from "../../styleguide/colors";

export const Item = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  padding: 12px 16px;
`;

export const Caption = styled.div`
  height: 20px;
  border-radius: 4px;
  background: ${colors.gray050};

  width: ${({ width }) => width}px;
`;

export const CodeCaption = styled(Caption)`
  width: 54px;
  margin-right: 10px;
`;
