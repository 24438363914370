import React, { useState } from "react";
import useReactRouter from "use-react-router";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import { getPatientFullName } from "../../domain/services/patient";
import ArrowIcon from "./icons/arrow.svg";

const StyledPatient = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 12px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const HoverIcon = styled.div`
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  position: absolute;
  top: 8px;
  right: 7px;
  width: 26px;
  height: 26px;
  background-image: url(${ArrowIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${colors.white};
  border-radius: 3px;
  border: 1px solid ${colors.gray800};
  cursor: pointer;
  pointer-events: all;
`;

export const Patient = props => {
  const [isVisible, setVisible] = useState(false);
  const { history } = useReactRouter();

  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);

  return (
    <>
      <StyledPatient
        onMouseDown={() => history.push(`/appointment/${props.cell.value.id}`)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {getPatientFullName(props.cell.value?.medical_file?.patient)}
      </StyledPatient>
      <HoverIcon
        isVisible={isVisible}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={() => history.push(`/appointment/${props.cell.value.id}`)}
      />
    </>
  );
};
