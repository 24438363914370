// @flow
import React from "react";
import type { Checklist } from "../../domain/entities/Checklist";
import { CommaSeparatedWrapper } from "./CommaSeparatedWrapper";

const Separator = () => {
  return " — ";
};

export const ChecklistWithCommaSeparatedChildren = (props: { checklist: Checklist }) => {
  const { checklist } = props;
  const checkedItems = checklist.items && checklist.items.filter(item => item.is_checked);

  return (
    <>
      {checklist.name}
      {checkedItems && checkedItems.length > 0 && (
        <>
          <Separator />
          {checkedItems.map(item => (
            <CommaSeparatedWrapper key={item.guid}>{item.name}</CommaSeparatedWrapper>
          ))}
        </>
      )}
    </>
  );
};
