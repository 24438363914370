// @flow
import React, { useMemo, useState } from "react";
import { observer } from "mobx-react";
import * as yup from "yup";
import Dialog from "@mui/material/Dialog";
import {
  AppBar,
  Button,
  DialogContentText,
  Paper,
  Stack,
  Toolbar,
  Typography
} from "@mui/material";
import { DynamicForm } from "../common/dynamic-form/DynamicForm";
import { styled } from "@mui/material/styles";
import ticketStore from "../../stores/ticketStore";
import _ from "lodash";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { matchIsValidTel } from "mui-tel-input";

const PaperForm = styled(Paper)(() => ({
  padding: "25px",
  height: "100%",
  boxSizing: "border-box",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px"
}));

const GreetingBlock = styled(Stack)(() => ({
  maxWidth: "800px",
  [`.MuiTypography-root`]: {
    textIndent: "1em"
  }
}));

const TopBar = ({ title }) => {
  return (
    <AppBar position="sticky" color="default">
      <Toolbar>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const ConfirmationFormClosure = ({ open, onAgree, onDisagree }) => {
  return (
    <Dialog open={open} onClose={onDisagree}>
      <DialogTitle>Вы уверены, что хотите закрыть форму?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Если вы закроете форму обратной связи, введенные данные могут не сохраниться
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAgree}>Закрыть</Button>
        <Button variant="contained" onClick={onDisagree} autoFocus>
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const getOptions = fields => {
  return fields.map(field => ({ value: field, label: field }));
};

const cityOptions = [
  "Москва - Ленинский пр-кт, 107 к.1",
  "Москва - Долгоруковская, 17 с. 1",
  "Москва - Мичуринский, 15А",
  "Москва - Клиника Рассвет",
  "Тверь - Спартака, 42А",
  "Тверь - Горького, 107А",
  "Тверь - Вагжановский переулок, д. 14",
  "Тверь - Л. Базановой, д.5",
  "Уфа - ул. Генерала Кусимова, 15/1",
  "Уфа - ул. Кирова, 52",
  "Уфа - ул. Комсомольская 17/1",
  "Белгород",
  "Воронеж",
  "Калуга",
  "Краснодар",
  "Пенза",
  "Пермь",
  "Санкт-Петербург",
  "Сочи",
  "Тула"
];


const categoryOptions = [
  "Диагностическая",
  "Лечебно-тактическая",
  "Этическая",
  "Организационная",
  "Техническая",
  "Идентификация биологического материала",
  "Другое"
];
const additionalInformationOptions = [
  "Ваше ФИО",
  "Ваш телефон для связи",
  "ФИО пациента",
  "Номер медицинской карты",
  "Ссылка на прием в Асе",
  "ФИО сотрудника, допустившего ошибку"
];
const causesOptions = [
  "Усталость",
  "Рассеянное внимание",
  "Отвлекли по другому вопросу",
  "Отсутствие знания по данному вопросу",
  "Материально-техническая",
  "Другое"
];

const additionalFields = {
  additionalInformation: [
    {
      key: "Ваше ФИО",
      type: "text",
      name: "fullName",
      label: "Ваше ФИО",
      xs: 12
    },
    {
      key: "Ваш телефон для связи",
      type: "phoneNumber",
      name: "phoneNumber",
      label: "Ваш номер телефона",
      subInfo:
        "При желании укажите ваш номер телефона, по которому мы можем связаться с вами для получения более подробной информации об ошибке",
      xs: 12
    },
    {
      key: "ФИО пациента",
      type: "text",
      name: "patientFullName",
      label: "ФИО пациента",
      xs: 12
    },
    {
      key: "Номер медицинской карты",
      type: "number",
      name: "medCard",
      label: "Номер медицинской карты пациента",
      xs: 12
    },
    {
      key: "Ссылка на прием в Асе",
      type: "text",
      name: "linkAppointment",
      label: "Ссылка на приём в Асе",
      xs: 12
    },
    {
      key: "ФИО сотрудника, допустившего ошибку",
      type: "text",
      name: "employeeFullName",
      label: "ФИО сотрудника, допустившего ошибку",
      xs: 12
    }
  ],
  causes: [
    {
      key: "Усталость",
      type: "text",
      name: "messageAboutFatigue",
      label: "Сообщить об усталости на рабочем месте",
      xs: 12
    }
  ]
};

const getAdditionalField = (key, data) => {
  if (!["additionalInformation", "causes"].includes(key) || !data[key]?.length) {
    return [];
  }
  const fieldsByKey = additionalFields[key];
  const fields = [];
  data[key].forEach(item => {
    const field = _.find(fieldsByKey, { key: item });
    if (!field) {
      return;
    }
    fields.push(field);
  });

  return fields;
};

export const FeedbackForm = observer(
  ({
    isFullscreen = true,
    isOpen,
    onClose,
    withTopBar = true,
    title = "Обратная связь об ошибках"
  }) => {
    const [isOpenConfirm, setOpenConfirm] = useState(false);
    const [localFeedbackData, setLocalFeedbackData] = useState({});
    const subInfoForProblem = (
      <>
        <span style={{ paddingLeft: "1em" }}>
          Здесь вы можете написать, например, о несоблюдении техники выполнения проверки
          проходимости маточных труб, задержке выдачи важных результатов анализов пациентам,
          недостаточной стерилизации инструментов или нехватке необходимого оборудования. Будет
          здорово, если вы укажете, что, по вашему мнению, стало причиной возникшей проблемы и
          предложите способы её исправления и предотвращения подобных ситуаций в будущем.
        </span>
        <br />
        <span style={{ paddingLeft: "1em" }}>
          Данная анкета не предназначена для сообщений об ошибках в работе Аси или неверно указанной
          информации на сайте клиники. Для сообщений о подобных вопросах{" "}
          <a href="https://t.me/fominclinic_internalsupport_bot" target="_blank" rel="noreferrer">
            используйте наш чат-бот.
          </a>
        </span>
      </>
    );

    const createSchema = useMemo(
      () => [
        {
          type: "radio",
          name: "city",
          label: "Из какого вы города?",
          options: getOptions(cityOptions),
          className: "form-input-radio--columns",
          required: true,
          xs: 12
        },
        {
          type: "textarea",
          name: "problem",
          label: "Расскажите, что случилось",
          subInfo: subInfoForProblem,
          required: true,
          xs: 12
        },
        {
          type: "checkboxGroup",
          name: "categories",
          label: "К какой категории, вы отнесли бы данную ошибку?",
          options: getOptions(categoryOptions),
          className: "form-input-multi-checkbox--columns",
          xs: 12
        },
        {
          type: "checkboxGroup",
          name: "additionalInformation",
          label: "Дополнительная информация",
          options: getOptions(additionalInformationOptions),
          className: "form-input-multi-checkbox--columns",
          subInfo:
            "Если это необходимо, выберите какую дополнительную информацию вы хотели бы сообщить",
          xs: 12
        },
        ...getAdditionalField("additionalInformation", localFeedbackData),
        {
          type: "checkboxGroup",
          name: "causes",
          label: "Вероятная причина ошибки",
          className: "form-input-multi-checkbox--columns",
          options: getOptions(causesOptions),
          xs: 12
        },
        ...getAdditionalField("causes", localFeedbackData)
      ],
      [localFeedbackData]
    );

    const validationPhone = (value = '') => matchIsValidTel(value);

    const validateSchema = yup.object({
      city: yup.string().required("Обязательное поле"),
      problem: yup.string().required("Обязательное поле"),
      phoneNumber: yup
        .string()
        .when("additionalInformation", {
          is: (values) => values.some((item) => item === 'Ваш телефон для связи'),
          then: (schema) => schema.test("isValidPhoneNumber", "Некорректный номер телефона", validationPhone),
        })
      // categories: yup.array().min(3, "Обязательное поле")
    });

    const defaultData = useMemo(() => {
      return ticketStore.feedbackData;
    }, [ticketStore.feedbackData]);

    const handlerOnCloseForm = () => {
      if (_.isEqual(ticketStore.feedbackData, localFeedbackData)) {
        onClose();
      } else {
        setOpenConfirm(true);
      }
    };

    const handlerOnAgree = () => {
      setOpenConfirm(false);
      onClose();
    };

    const handlerOnDisagree = () => {
      setOpenConfirm(false);
    };

    const watcherOnChangeField = data => {
      setLocalFeedbackData(data);
    };

    const resetErrors = ({ data, errors, clearErrors }) => {
      if (errors['phoneNumber'] && !data.additionalInformation.some((item) => item === 'Ваш телефон для связи')) {
        clearErrors('phoneNumber');
      }
    }

    const handleOnSaveFeedbackForm = (data) => {
      if (data.phoneNumber && !data.additionalInformation.some((item) => item === 'Ваш телефон для связи')) {
        delete data.phoneNumber;
      }
      ticketStore.setFeedbackData(data);
      onClose();
    };

    return (
      <Dialog fullScreen={isFullscreen} open={isOpen} onClose={onClose}>
        {!!withTopBar && <TopBar title={title} />}
        <PaperForm>
          <GreetingBlock spacing={1}>
            <Typography variant="subtitle1">
              Мы стремимся постоянно улучшать нашу клинику, предоставляя пациентам качественную
              медицинскую помощь и оптимизируя процессы. Мы понимаем, что ошибки неизбежны, и вместо
              того чтобы винить за них, мы учимся на собственном опыте и становимся сильнее.
            </Typography>
            <Typography variant="subtitle1">
              Именно поэтому мы создали форму обратной связи, где каждый сотрудник может анонимно
              поделиться информацией о любых ситуациях, которые привели или могли бы привести к
              медицинским последствиям. Ваши сообщения помогут нам улучшить качество лечения и
              повысить безопасность пациентов.
            </Typography>
          </GreetingBlock>
          <DynamicForm
            data={defaultData}
            fields={createSchema}
            validateSchema={validateSchema}
            changeHandler={watcherOnChangeField}
            submitHandler={handleOnSaveFeedbackForm}
            hiddenButtonCancel
            debounceTime={300}
            modifiers={["md"]}
            resetErrorsHandler={resetErrors}
          />
        </PaperForm>
        <ConfirmationFormClosure
          open={isOpenConfirm}
          onAgree={handlerOnAgree}
          onDisagree={handlerOnDisagree}
        />
      </Dialog>
    );
  }
);
