// @flow
import { rgba } from "polished";
import * as React from "react";
import styled from "styled-components";
import { Container, DefaultButton } from "../buttons/DefaultButton";
import { colors } from "../styleguide/colors";
import trashIcon from "./icons/trash.inline.svg";

const IconContainer = styled.div`
  margin-right: 12px;
`;

const TextContainer = styled.div`
  margin-right: 8px;
`;

export const RemoveButtonContainer = styled(Container)`
  width: 162px;
  height: 60px;
  background: transparent;
  border: none;
  border-color: transparent;
  outline: none;

  &:hover {
    border-color: transparent;
    background: ${rgba(colors.pomegranate, 0.1)};
  }
`;

const Icon = styled(trashIcon)`
  ${RemoveButtonContainer}:hover & {
    path {
      fill: ${colors.pomegranate};
    }
  }
`;

const Text = styled.span`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${colors.gray700};

  ${RemoveButtonContainer}:hover & {
    color: ${colors.pomegranate};
  }
`;

export const RemoveButton = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  children: ?React.Node
}) => (
  <DefaultButton
    {...props}
    overrides={{
      Container: {
        component: RemoveButtonContainer
      },
      Icon: {
        component: Icon
      },
      IconContainer: {
        component: IconContainer
      },
      TextContainer: {
        component: TextContainer
      },
      Text: {
        component: Text
      }
    }}
  />
);
