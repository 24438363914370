// @flow
import * as React from "react";
import styled from "styled-components";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { colors } from "../styleguide/colors";
import { HeadingH4 } from "../styleguide/typography";
import { type ExtractOverridesProps } from "react-overrides";

export const Container = styled(RoundedRectangleWithShadow)`
  margin-top: 25px;
`;

export const HeaderContainer = styled.a`
  position: relative;
  border: none;
  background: none;
  outline: none;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  text-align: left;

  display: flex;
  align-items: center;
  padding: 14px 33px 13px;
  cursor: pointer;
  text-decoration: none;
`;

const MarkerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: ${colors.magenta};
  border-radius: 3px 0 0 3px;
`;

const TitleContainer = styled.div`
  width: 250px;
  color: ${colors.black};
`;

const ChildrenContainer = styled.div`
  color: ${colors.black};
  opacity: 0.6;
  font-size: 14px;
  line-height: 22px;
  transition: all 0.15s ease-in-out;

  ${HeaderContainer}:hover & {
    color: ${colors.magenta};
    opacity: 1;
  }
`;

const Overrides = {
  Container,
  HeaderContainer,
  ChildrenContainer,
  MarkerContainer
};

type OnlineSectionButtonProps = {
  onlineLink: string,
  overrides?: ExtractOverridesProps<typeof Overrides>
};

export const OnlineSectionButton = (props: OnlineSectionButtonProps) => {
  return (
    <Container>
      <HeaderContainer href={props.onlineLink} target="_blank">
        <MarkerContainer />
        <TitleContainer>
          <HeadingH4>Онлайн-прием</HeadingH4>
        </TitleContainer>
        <ChildrenContainer>Нажмите, чтобы начать онлайн-прием в новой вкладке.</ChildrenContainer>
      </HeaderContainer>
    </Container>
  );
};
