// @flow
import { useState } from "react";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import type { Drug, TDrugId } from "../../domain/entities/Drug";
import * as R from "ramda";
import {useApiRequest} from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

type TUseDrugSelectResult = {
  value: string,
  setValue: string => mixed,
  items: Array<Drug>
};

type TFilterDrugs = (ignore: Array<TDrugId>) => (?Array<Drug>) => Array<Drug>;

export const filterDrugs: TFilterDrugs = ignore =>
  R.compose(
    R.take(4),
    R.reject(R.where({ id: id => R.includes(id, ignore) })),
    R.defaultTo([])
  );

export const useDrugSelect = (ignore?: Array<TDrugId>): TUseDrugSelectResult => {
  const [value, setValue] = useState("");
  const token = useStore(tokenStore);
  const result = useApiRequest(async () => {
    if (value) {
      return await apiv2.drugs.getDrugs(value);
    }
  }, [token, value]);

  return {
    value,
    setValue,
    items: filterDrugs(ignore || [])(result.data)
  };
};
