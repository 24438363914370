import React from "react";
import moment from 'moment';
import styled from 'styled-components';
import {observer} from 'mobx-react';

import {ItemText} from './ItemComponents';
import {ChatMessageType} from '../../../stores/chatStore';
import {ChatFolder, ChatFolderTypes} from './ChatFolder';
import {getFileNameFromUrl} from '../../../utils/getFileNameFromURL';
import imageViewerStore from '../../../stores/imageViewerStore';

const MessageTextLink = styled.a`
  color: #c6007f;
`;

const MessagePhoto = styled.img`
  max-width: 288px;
  max-height: 288px;
  border-radius: 12px;
  cursor: pointer;
  @media (max-width: 768px) {
    max-width: 100%;
    background-size: contain;
  }
`;

const MessagePhotoLayout = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  
  ${MessagePhoto} {
    width: 140px;
    height: 120px;
    object-fit: cover;
    object-position: center;
  }
`;

const parseLinks = (text) => {
  if (!text) return '';

  const urlRegexp = /(https?:\/\/[^\s]+)/g;
  return (
    <>
      {
        text.split(urlRegexp).map(part => urlRegexp.test(part) ? <MessageTextLink key={part} href={part} target="_blank" rel="noopener noreferrer">{part}</MessageTextLink> : part)
      }
    </>
  );
};

const MessageContentTypes = {
  [ChatMessageType.TEXT]: ({message}) => (
    <ItemText>
      {parseLinks(message.text)}
    </ItemText>
  ),
  [ChatMessageType.APPOINTMENT]: ({message}) => (
    <ChatFolder
      title={message.data.date_finished && moment(message.data.date_finished).format("DD.MM.YY")}
      text={message.data.services_description}
      href={`/appointment/${message.data.id}`}
      type="appointment"
      {...ChatFolderTypes.APPOINTMENT}
    />
  ),
  [ChatMessageType.FILE]: observer(({message}) => (
    <>
      {
        message.attachments.map(attachment => (
          <ChatFolder
            key={attachment.uuid}
            title="Файл"
            text={attachment.filename || getFileNameFromUrl(decodeURI(attachment.original_url))}
            href={decodeURI(attachment.original_url)}
            type="file"
            {...ChatFolderTypes.FILE}
          />
        ))
      }
    </>
  )),
  [ChatMessageType.IMAGE]: observer(({message}) => (
    message.attachments.length > 1 ? (
      <MessagePhotoLayout>
        {
          message.attachments.map((attachment, index) => (
            <MessagePhoto key={attachment.uuid} src={attachment.original_url} onClick={() => {
              imageViewerStore.setImages(message.attachments.map(attachment => attachment.original_url));
              imageViewerStore.setCurrentImage(index);
              imageViewerStore.show();
            }}/>
          ))
        }
      </MessagePhotoLayout>
    ) : (
      <MessagePhoto src={message.attachments[0].original_url} onClick={() => {
        imageViewerStore.setImages([message.attachments[0].original_url]);
        imageViewerStore.setCurrentImage(0);
        imageViewerStore.show();
      }}/>
    )
  )),
};

export const MessageContent = ({
  message
}) => {
  const MessageContentByType = MessageContentTypes[message._type] || MessageContentTypes[ChatMessageType.TEXT];

  return (
    <MessageContentByType message={message}/>
  );
};
