export const parseMilliseconds = string => {
  const ms = 1;
  const s = 1000 * ms;
  const m = 60 * s;
  const h = 60 * m;
  const d = 24 * h;
  const w = 7 * d;
  const timeAsMilliseconds = { ms, s, m, h, d, w };

  const matches = string.match(/(\d+)([ms|s|m|h|d|w]*)/g);

  return matches.reduce((acc, next) => {
    const key = next.replace(/\d+/, "");
    return acc + parseInt(next) * timeAsMilliseconds[key];
  }, 0);
};
