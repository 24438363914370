// @flow
import React, { useCallback, useEffect } from "react";
import "regenerator-runtime/runtime.js";
import { observer } from "mobx-react";
import { Route, Redirect, Switch } from "react-router-dom";
import { LoginPage } from "./components/login-page/LoginPage";
import { ProfilePageTelemed } from "./components/profile-page/ProfilePageTelemed";
import { AccountPage } from "./components/account-page/AccountPage";
import { AppointmentsSwitchPage } from "./components/appointment-switch-page/AppointmentsSwitchPage";
import { GeneralSchedulePage } from "./components/general-schedule-page/GeneralSchedulePage";
import { GeneralSchedulePageNew } from "./components/general-schedule-page/GeneralSchedulePageNew";
import { PrintableAppointmentPage } from "./components/appointment-print/PrintableAppointmentPage";
import { PrintableHistoryTablePage } from "./components/history-table/PrintableHistoryTable";
import { PrintableEmbryosPage } from "./components/embryos-table/PrintableEmbryosPage";
import { PrintableEmbryosDocumentPage } from "./components/embryos-table/PrintableEmbryosDocumentPage";
import { PrintablePrescriptionTablePage } from "./components/prescription-table/PrintablePrescriptionTable";
import { PrintableTablePage } from "./components/table/PrintableTable";
import { FolderPrintPage } from "./components/folder-page/FolderPrintPage";
import { FolderAnalysesPrintPage } from "./components/folder-page/FolderAnalysesPrintPage";
import { ScheduleSwitchPage } from "./components/schedule-switch-page/ScheduleSwitchPage";
import { MyPatientsPage } from "./components/my-patients/MyPatientsPage";
import { ChatsPage } from "./components/chats/ChatsPage";
import { PaymentPage } from "./components/payment/PaymentPage";
import { SupportPage } from "./components/support/SupportPage";
import { Toaster } from "./components/toast/toast";
import { withRouter } from "react-router";
import { AgreementPrintPage } from "./components/folder-page/AgreementPrintPage";
import { AgreementNewPrintPage } from "./components/appointment-page/AgreementNewPrintPage";

import { VideoCall } from "./components/chats/video/VideoCall";
import { ImageViewer } from "./components/image-viewer/ImageViewer";
import { PrintEditor } from "./components/print-editor/PrintEditor";

import { usePatientReminders } from "./components/appointment-page/usePatientReminders";
import {
  FEATURE,
  useApplicationFeatureEnabled,
  useApplicationFeatures,
  useApplicationFeatureValue
} from "./utils/applicationFeatures";
import authStore from "./stores/authStore";
import chatStore from "./stores/chatStore";
import headerStore, { TABS_URL } from "./stores/headerStore";
import { ReviewStepTwo } from "./components/review-page/ReviewStepTwo";
import { ReviewStepThree } from "./components/review-page/ReviewStepThree";
import { ReviewStepFour } from "./components/review-page/ReviewStepFour";
import { ReviewAlready } from "./components/review-page/ReviewAlready";
import { ReviewStepThreeNegative } from "./components/review-page/ReviewStepThreeNegative";
import { ReviewStepFourNegative } from "./components/review-page/ReviewStepFourNegative";
import { ReviewStepSixWithCall } from "./components/review-page/ReviewStepSixWithCall";
import { ReviewStepSixNotCall } from "./components/review-page/ReviewStepSixNotCall";
import { ReviewStepFiveNegative } from "./components/review-page/ReviewStepFiveNegative";
import { newColors } from "./components/styleguide/newColors";
import { ReviewPage } from "./components/review-page/ReviewPage";
import { currentUser } from "./stores/auth";
import { CheckupsPage } from "./components/checkups-page/CheckupsPage";
import { CheckupsPageAppointments } from "./components/checkups-page/CheckupsPageAppointments";
import { CheckupsPageThanks } from "./components/checkups-page/CheckupsPageThanks";
import { MediaPermissionSelectModal } from "./components/common/mediaPermissionSelectModal";
import { MediaPermissionLockModal } from "./components/common/mediaPermissionLockModal";
import { MediaPermissionNotDeviceModal } from "./components/common/mediaPermissionNotDeviceModal";
import { VoximplantConnectionErrorModal } from "./components/chats/modals/VoximplantConnectionErrorModal";
import { newColorsRassvet } from "./components/styleguide/newColorsRassvet";
import reviewStore, { CLINIC_CODE } from "./stores/reviewStore";
import { ReviewStatisticsPage } from "./components/review-statistics-page/ReviewStatisticsPage";
import { ReviewStatisticsEditPage } from "./components/review-statistics-page/ReviewStatisticsEditPage";
import { TicketCreatePage } from "./components/ticket-page/TicketCreatePage";
import { TicketEditPage } from "./components/ticket-page/TicketEditPage";
import { DoctorSalaryPage } from "./components/doctor-salary-page/DoctorSalaryPage";
import { TicketPage } from "./components/ticket-page/TicketPage";
import { PaymentsStatisticsPage } from "./components/payment-statistics-page/PaymentsStatisticsPage";
import { DashboardPage } from "./components/dashboard-page/DashboardPage";
import { MoreNavigationTabs } from "./components/moreNavigationTabs/MoreNavigationTabs";
import { PatientsPage } from "./components/patients-page/PatientsPage";
import { PatientsEditPage } from "./components/patients-page/PatientsEditPage";
import { DoctorPopularLayoutsPage } from "./components/doctor-popular-layouts-page/DoctorPopularLayoutsPage";
import { ThrowErrorPage } from "./components/throw-error-page/ThrowErrorPage";
import { ProfileTicketPage } from "./components/ticket-page/ProfileTicketPage";
import { setVoximplantSupportData } from "./utils/voximplantSupport";
import { ConnectionErrorModal } from "./components/connection-error-modal/ConnectionErrorModal";
import connectionStability from "./stores/connectionStability";
import { VoximplantSupport } from "./components/voximplant-support/VoximplantSupport";
import { ProfilePage } from "./components/profile-page/ProfilePage";
import { SurveyPage } from "./components/survey-page/SurveyPage";
import { FavoritesAppointmentPage } from "./components/favorites-appointment-page/FavoritesAppointmentPage";
import { VoxInformationPatient } from "./components/vox-information-patient/VoxInformationPatient";
import { PrescribedServicesPage } from "./components/prescribed-services-page/PrescribedServicesPage";

import { CorporatePortal } from "./corporate-portal/CorporatePortal";
import { SurveyResultPage } from "./components/survey-page/SurveyResultPage";
import { SurveyEmployeeEditPage } from "./components/survey-page/SurveyEmployeeEditPage";
import noticesStore from "./stores/noticesStore";
import { NoticesPage } from "./components/notices-page/NoticesPage";
import { BonusSchema } from "./components/doctor-salary-page/bonus-schema/BonusSchema";
import { VoximplantSupportWebView } from "./components/voximplant-support/VoximplantSupportWebView";
import { useActionsLoggingTool } from "./utils/actionsLoggingTool";
import { VoxPatientConsent } from "./components/vox-patient-consent/VoxPatientConsent";
import { ReportsPage } from "./components/reports-page/ReportsPage";
import { SurveyCorporatePortalEditPage } from "./components/survey-page/SurveyCorporatePortalEditPage";
import { SurveyFeedbackClinicEditPage } from "./components/survey-page/SurveyFeedbackClinicEditPage";
import ticketStore from "./stores/ticketStore";

const App = withRouter(
  observer(props => {
    const isChatsEnabled = useApplicationFeatureEnabled(FEATURE.CHAT);
    const showProfile = useApplicationFeatureEnabled(FEATURE.PROFILE);
    const showPatientsPage = useApplicationFeatureEnabled(FEATURE.PATIENTS);
    const questionnaireUserId = useApplicationFeatureValue(FEATURE.HC_QUESTIONNAIRE_USER_ID)
    const SHOW_BONUS_SCHEMA = useApplicationFeatureEnabled(FEATURE.BONUS_SCHEMA);
    let TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR = useApplicationFeatureValue(FEATURE.TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR)
    if (TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR) {
      TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR = TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR.split(',').map((item) => parseInt(item))
    }
    const generalScheduleNewEnabled = useApplicationFeatureEnabled(FEATURE.GENERAL_SCHEDULE_NEW);
    const user = currentUser.getState();

    useEffect(() => {
      if (isChatsEnabled && user) {
        chatStore.initWS();
        chatStore.loadChats();
        chatStore.updateUnreadChatsCount();
      }
    }, [isChatsEnabled, user]);

    useEffect(() => {
      if (user) {
        noticesStore.initWS();
      }
    }, [user])

    const handleMessageListener = useCallback(message => {
      if (message?.data === "eventGoBack") {
        props.history.push(TABS_URL[headerStore.lastMainNavigationTab]);
      }

      if (message?.data?.type === "createMobileTicket") {
        ticketStore.setMobileTicketVoxData(message.data.payload);
      }
    }, []);

    useEffect(() => {
      window.addEventListener("message", handleMessageListener);
      return () => window.removeEventListener("message", handleMessageListener);
    }, []);

    useEffect(() => {
      const unlisten = props.history.listen(location => {
        const voximplantData = { page: location.href };
        setVoximplantSupportData({
          client_email: voximplantData,
          ...window.VoxKitWidgetSettings.client_data
        });
      });
      return () => {
        unlisten();
      };
    }, []);

    const paths = location.pathname.split("/");

    /** Для WebView {7413} */
    if (location.pathname === "/vox-support") {
      document.documentElement.style.background = "#fafafa";
      return <VoximplantSupportWebView />;
    }

    useApplicationFeatures();
    usePatientReminders();
    useActionsLoggingTool();

    if (paths[1] === "vox-information-patient") {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Toaster />
          <VoxInformationPatient />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (!document.documentElement.style.getPropertyValue("--vh")) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      window.addEventListener("resize", () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    }

    if (paths.length === 2 && paths[1] === "support") {
      document.documentElement.style.background = "transparent";
      return <SupportPage />;
    }

    if (paths[1] === "review") {
      if (
        reviewStore.clinic_code === CLINIC_CODE.rassvet ||
        location.search.includes("clinic=rassvet")
      ) {
        reviewStore.setClinicCode(CLINIC_CODE.rassvet);
        document.documentElement.style.background = newColorsRassvet.backgroundPrimary;
      } else {
        reviewStore.setClinicCode(CLINIC_CODE.fomina);
        document.documentElement.style.background = newColors.backgroundPrimary;
      }
      return (
        <Switch>
          <Route exact path="/review/:token" component={ReviewPage} />
          <Route path="/review/:token/step-two" component={ReviewStepTwo} />
          <Route path="/review/:token/step-three" component={ReviewStepThree} />
          <Route path="/review/:token/step-three-negative" component={ReviewStepThreeNegative} />
          <Route path="/review/:token/step-four" component={ReviewStepFour} />
          <Route path="/review/:token/step-four-negative" component={ReviewStepFourNegative} />
          <Route path="/review/:token/step-five-negative" component={ReviewStepFiveNegative} />
          <Route path="/review/:token/step-six-with-call" component={ReviewStepSixWithCall} />
          <Route path="/review/:token/step-six-not-call" component={ReviewStepSixNotCall} />
          <Route path="/review/:token/already" component={ReviewAlready} />
        </Switch>
      );
    }

    if (authStore.token && !authStore.user) {
      return null;
    }

    const registerServiceWorker = () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register(new URL("sw.js", import.meta.url), {
          scope: "/",
          type: "module"
        });
      }
    };
    registerServiceWorker();

    if (paths.length === 2 && paths[1] === "finish-payment") {
      document.documentElement.style.background = "transparent";
      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({ status: "finished" }));
      } catch (e) {}
      return (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "10px",
            width: "80vw"
          }}>
          <div style={{ textAlign: "center", paddingBottom: "20px", fontSize: "120%" }}>
            Оплата завершена
          </div>
          <div style={{ textAlign: "center" }}>Для продолжения вернитесь в приложение</div>
        </div>
      );
    }

    if (paths.length === 3 && paths[1] === "payment") {
      document.documentElement.style.background = "transparent";
      return <PaymentPage id={paths[2]} />;
    }

    if (paths.length >= 3 && paths[1] === "videocall") {
      chatStore.setCallId(paths[2]);
      if (!chatStore.currentChatId) {
        return null;
      }
      return (
        <>
          <Toaster />
          <MediaPermissionLockModal isShown={chatStore.showMediaPermissionLockModal} />
          <MediaPermissionSelectModal isShown={chatStore.showMediaPermissionSelectModal} />
          <MediaPermissionNotDeviceModal isShown={chatStore.showMediaPermissionNotDeviceModal} />
          <VoximplantConnectionErrorModal isShown={chatStore.showVoximplantConnectionErrorModal} />
          <VideoCall />
          <VoximplantSupport />
        </>
      );
    }

    if (location.pathname === "/tickets/segment/medical-error/create") {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Toaster />
          <Switch>
            <Route
              exact
              path="/tickets/segment/medical-error/create"
              component={TicketCreatePage}
            />
          </Switch>
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

      if (location.pathname.includes('/survey/')) {
          document.documentElement.style.background = '#F9F4F4';
          return (
              <>
                  <Toaster />
                  <Switch>
                      <Route exact path="/survey/:id" component={SurveyPage} />
                      <Route exact path="/survey/result/:id" component={SurveyPage} />
                  </Switch>
                  <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
              </>
          );
      }

    if (paths[1] === "corporate-portal" || user?.is_corporate_portal) {
      return (
        <>
          <Toaster />
          <Switch>
            <Route path="/corporate-portal" component={CorporatePortal} />
          </Switch>
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (!authStore.token || !authStore.user) {
      return (
        <>
          <Toaster />
          <LoginPage />
          <VoximplantSupport showMobile={true} />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (
      location.pathname.includes("/tickets/segment/medical-error") &&
      TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR &&
        TICKET_DOCTOR_ASSIGNEE_MEDICAL_ERROR.includes(authStore.user.doctor.id)
    ) {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Toaster />
          <Switch>
            <Route exact path="/tickets/segment/medical-error" component={TicketPage} />
            <Route
              exact
              path="/tickets/segment/medical-error/create"
              component={TicketCreatePage}
            />
            <Route exact path="/tickets/segment/medical-error/:id" component={TicketEditPage} />
          </Switch>
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (!authStore.user.doctor?.last_name && process.env.APP_FLAVOR === "telemed") {
      return (
        <>
          <Toaster />
          <ProfilePageTelemed afterRegistration={true} />
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (authStore.user?.id === questionnaireUserId) {
      window.QUEST_MODE = true;
      return (
        <>
          <Switch>
            <Route exact path="/quest" component={CheckupsPage} />
            <Route exact path="/quest/thanks" component={CheckupsPageThanks} />
            <Route exact path="/quest/:appointment_id" component={CheckupsPageAppointments} />
          </Switch>
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (paths[1] === "review-statistics" || paths[1] === "corporate-portal-statistics" || paths[1] === "feedback-clinic-statistics") {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Toaster />
          <Switch>
            <Route exact path="/review-statistics" component={ReviewStatisticsPage} />
            <Route exact path="/review-statistics/:id" component={ReviewStatisticsEditPage} />
            <Route exact path="/corporate-portal-statistics/:id" component={SurveyCorporatePortalEditPage} />
            <Route exact path="/feedback-clinic-statistics/:id" component={SurveyFeedbackClinicEditPage} />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (paths[1] === "survey-result") {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Toaster />
          <Switch>
            <Route exact path="/survey-result" component={SurveyResultPage} />
            <Route exact path="/survey-result/:id" component={SurveyEmployeeEditPage} />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (paths[1] === "tickets") {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Switch>
            <Route exact path="/tickets" component={TicketPage} />
            <Route exact path="/tickets/create" component={TicketCreatePage} />
            <Route exact path="/tickets/:id" component={TicketEditPage} />
            <Route exact path="/tickets/segment/profile" component={TicketPage} />
            <Route exact path="/tickets/segment/profile/create" component={TicketCreatePage} />
            <Route exact path="/tickets/segment/profile/:id" component={TicketEditPage} />
            <Route exact path="/tickets/segment/legal-lab" component={TicketPage} />
            <Route exact path="/tickets/segment/legal-lab/create" component={TicketCreatePage} />
            <Route exact path="/tickets/segment/legal-lab/:id" component={TicketEditPage} />
            <Route exact path="/tickets/segment/manager-dms" component={TicketPage} />
            <Route exact path="/tickets/segment/manager-dms/create" component={TicketCreatePage} />
            <Route exact path="/tickets/segment/manager-dms/:id" component={TicketEditPage} />
            <Route exact path="/tickets/segment/common" component={TicketPage} />
            <Route exact path="/tickets/segment/common/create" component={TicketCreatePage} />
            <Route exact path="/tickets/segment/common/:id" component={TicketEditPage} />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (paths[1] === "payments") {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Switch>
            <Route exact path="/payments" component={PaymentsStatisticsPage} />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (paths[1] === "vox-patient-consent") {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Switch>
            <Route exact path="/vox-patient-consent" component={VoxPatientConsent} />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (paths[1] === "dashboard") {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Switch>
            <Route exact path="/dashboard" component={DashboardPage} />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    if (paths[1] === "reports-vrt") {
      document.documentElement.style.background = "#fafafa";
      return (
        <>
          <Switch>
            <Route exact path="/reports-vrt" component={ReportsPage} />
          </Switch>
          <VoximplantSupport />
          <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
        </>
      );
    }

    return (
      <>
        <Toaster />
        {/* Отключаем модалку с текстом о запуске блока ВРТ, потому что ссылка ведет на нерабочий Ноушен */}
        {/*<UpdateNewsModal />*/}
        <Switch>
          <Redirect exact from="/" to="/schedule" />
          <Route path="/schedule" component={ScheduleSwitchPage} />
          {/*<Route exact path="/throw-error" component={ThrowErrorPage} />*/}
          {showProfile && <Route exact path="/profile" component={ProfilePage} />}
          {showProfile && <Route exact path="/profile/salary" component={DoctorSalaryPage} />}
          {(showProfile && SHOW_BONUS_SCHEMA) && <Route exact path="/profile/progress" component={BonusSchema} />}
          {showProfile && <Route exact path="/profile/tickets" component={ProfileTicketPage} />}
          {showProfile && (
            <Route exact path="/profile/tickets/create" component={TicketCreatePage} />
          )}
          {showProfile && <Route path="/profile/tickets/:id" component={TicketEditPage} />}
          {showProfile && (
            <Route exact path="/profile/popular-layouts" component={DoctorPopularLayoutsPage} />
          )}
          <Route exact path="/prescribed-services-report" component={PrescribedServicesPage} />
          {showProfile && (
            <Route
              exact
              path="/profile/favorites-appointment"
              component={FavoritesAppointmentPage}
            />
          )}
          {showProfile && (
            <Route
              exact
              path="/profile/notifications"
              component={NoticesPage}
            />
          )}
          {showPatientsPage && <Route exact path="/patients" component={PatientsPage} />}
          {!!generalScheduleNewEnabled && (
            <Route path="/general-schedule-new" component={GeneralSchedulePageNew} />
          )}
          {process.env.APP_FLAVOR === "telemed" && (
            <Route path="/profile" component={ProfilePageTelemed} />
          )}
          {process.env.APP_FLAVOR === "telemed" && (
            <Route path="/account" component={AccountPage} />
          )}
          <Route
            exact
            path="/appointment/:appointment_id/print/:for"
            component={PrintableAppointmentPage}
          />
          <Route
            exact
            path="/appointment/folder/:id/print/:concrete_folder?"
            component={FolderPrintPage}
          />
          <Route
            exact
            path="/appointment/folder/:id/analyzes/print/:type"
            component={FolderAnalysesPrintPage}
          />
          <Route
            exact
            path="/appointment/folder/:id/agreements/print/:agreementId"
            component={AgreementPrintPage}
          />
          <Route
            exact
            path="/appointment/:id/agreements/print/:agreementId"
            component={AgreementNewPrintPage}
          />
          <Route
            exact
            path="/appointment/:appointment_id/:section_id/print/history"
            component={PrintableHistoryTablePage}
          />
          <Route
            exact
            path="/appointment/:appointment_id/:section_id/print/prescriptions"
            component={PrintablePrescriptionTablePage}
          />
          <Route
            exact
            path="/appointment/:appointment_id/:section_id/print/table"
            component={PrintableTablePage}
          />
          <Route
            exact
            path="/embryos/:folder_id/:medical_file_id/print/documents/:document"
            component={PrintableEmbryosDocumentPage}
          />
          <Route
            exact
            path="/embryos/:folder_id/:medical_file_id/print/:filter"
            component={PrintableEmbryosPage}
          />
          <Route
            exact
            path="/embryos/:medical_file_id/print/documents/:document"
            component={PrintableEmbryosDocumentPage}
          />
          <Route
            exact
            path="/embryos/:medical_file_id/print/:filter"
            component={PrintableEmbryosPage}
          />

          <Route path="/appointment" component={AppointmentsSwitchPage} />
          <Route path="/general-schedule" component={GeneralSchedulePage} />
          <Route path="/chats/:id" component={ChatsPage} />
          <Route path="/chats" component={ChatsPage} />
          <Route exact path="/my-patients" component={MyPatientsPage} />
          {/** Раздел "Еще" для мобильных устройств */}
          <Route exact path="/more-navigation-tabs" component={MoreNavigationTabs} />
        </Switch>
        <VideoCall />
        <ImageViewer />
        <PrintEditor />
        <VoximplantSupport />
        <ConnectionErrorModal isShown={connectionStability.isConnectionModalVisible} />
      </>
    );
  })
);

export default App;
