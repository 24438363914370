// @flow
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import useReactRouter from "use-react-router";
import { useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { scrollbars } from "../chats/style-elements";
import appointmentDirectionsStore from "../../stores/appointmentDirectionsStore";
import { trimLongTextValueField } from "../../utils/tableUtils";
import { READABLE_DATE_FORMAT } from "../../utils/dates";
import { AnalyzesFormLayout } from "../analyzes/AnalyzesFormLayout";
import { HeadingH2 } from "../styleguide/typography";
import { renderToolbarAlertBannerContent, TopToolbarCustomActions } from "../common/MaterialTable";
import { FEATURE, useApplicationFeatureEnabled } from "../../utils/applicationFeatures";
import {
  BookmarkAddedOutlined as CompleteIcon,
  EditCalendarOutlined as MakeAnAppointmentIcon
} from "@mui/icons-material";
import { AbsoluteLoader } from "../loader/Loader";

const TableContainer = styled.div`
  width: 100%;
  position: relative;
  display: block;
  height: ${props => (props.height ? `${props.height}px` : "100%")};
  .MuiTableContainer-root {
    ${scrollbars}
  }
    
  .MuiTableRow-head th:nth-child(3) {
      width: 110px;
      max-width: 110px;
      min-width: 110px;
  }

  .MuiTableBody-root .MuiTableRow-root {
      overflow: hidden;
  }
    
  .MuiTableRow-root td:nth-child(3) {
      width: max-content;
      min-width: 110px;
  }
    
  .Mui-TableHeadCell-Content {
      min-height: 28px;
  }
`;

export const AppointmentDirectionsPage = observer(({ isWithoutLayout = false, isWithoutBottomIndent = false }) => {
  const {
    history,
    match: { params }
  } = useReactRouter();
  const isTablet = useMediaQuery("(max-width: 768px)");
  const isMobile = useMediaQuery("(max-width: 480px)");
  const tableRef = useRef(null);
  const rowVirtualizerInstanceRef = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [rowSelection, setRowSelection] = useState({});
  const [topToolbarCustomActionsAnchor, setTopToolbarCustomActionsAnchor] = useState(null);
  const customKeys = ["groupingValue", "isDone", "completed"];
  const generalScheduleNewEnabled = useApplicationFeatureEnabled(FEATURE.GENERAL_SCHEDULE_NEW);

  const handleOnCompleteDirection = rows => {
    appointmentDirectionsStore.handleCompleteDirection(
      rows.getSelectedRowModel().rows,
      setRowSelection
    );
  };

  const handleOnMakeAnAppointment = rows => {
    appointmentDirectionsStore.handleMakeAnAppointment({
      data: rows.getSelectedRowModel().rows,
      history
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "service__name",
        header: "Услуга",
        size: 400,
        Cell: ({ row }) =>
          trimLongTextValueField(`${row.original.service__name} (${row.original.service__kdf_code})`)
      },
      {
        accessorKey: "count",
        header: "Количество",
        Cell: ({ row }) => `${row.original.count || 1} шт.`
      },
      {
        accessorKey: "service__price",
        header: "Цена",
        Cell: ({ row }) => row.original.count ? `${(row.original.service__price * row.original.count).toLocaleString()}₽` : `${(row.original.service__price * 1).toLocaleString()}₽`
      },
      {
        accessorKey: "isDone",
        header: "Выполнено",
        size: 200,
        accessorFn: originalRow => (!originalRow.date_completed ? "Нет" : "Да"),
        filterVariant: "select",
        filterSelectOptions: ["Да", "Нет"]
      },
      {
        accessorKey: "completed",
        header: "Выполнил",
        accessorFn: originalRow => {
          if (!originalRow.date_completed) {
            return "";
          }
          return originalRow.user_completed
            ? originalRow.user_completed__username
            : originalRow.doctor;
        }
      },
      // {
      //   accessorKey: "appointment__layout__name",
      //   header: "Шаблон",
      //   size: 250,
      //   Cell: ({ renderedCellValue }) =>
      //     trimLongTextValueField({ value: renderedCellValue, numberOfCharacters: 33 })
      // },
      // {
      //   accessorKey: "created_at",
      //   header: "Дата создания",
      //   size: 210,
      //   enableColumnFilter: false,
      //   Cell: ({ renderedCellValue }) => dayjs(renderedCellValue).format(READABLE_DATE_FORMAT)
      // },
      // {
      //   accessorKey: "appointment__date",
      //   header: "Дата посещения",
      //   size: 210,
      //   enableColumnFilter: false,
      //   Cell: ({ renderedCellValue }) => dayjs(renderedCellValue).format(READABLE_DATE_FORMAT)
      // },
      {
        accessorKey: "comment",
        header: "Комментарий",
        size: 250,
        Cell: ({ row }) => trimLongTextValueField(row.original.comment)
      },
      {
        accessorKey: "date_completed",
        header: "Дата завершения",
        size: 210,
        enableColumnFilter: false,
        Cell: ({ row }) =>
          row.original.date_completed
            ? dayjs(row.original.date_completed).format(READABLE_DATE_FORMAT)
            : ""
      },
      {
        accessorKey: "groupingValue",
        header: "Прием",
        size: 500,
        Cell: ({ row }) => row.original.groupingValue
      }
    ],
    []
  );

  useLayoutEffect(() => {
    (async () => {
      handleChangeTableHeightOnResize();
      if (appointmentDirectionsStore.dataTable.length) {
        return;
      }
      const values = columns.filter(item => !customKeys.includes(item.id)).map(item => item.id);
      await appointmentDirectionsStore.setPatientId({medical_file_id: params.medical_file_id, medical_file_external_id: params.medical_file_external_id});
      appointmentDirectionsStore.setValuesParamsTable(values, false);
      appointmentDirectionsStore.getPatientData();
    })()
  }, []);

  const toolbarActions = useMemo(
    () =>
      [
        {
          key: "complete_direction",
          text: "Направление выполнено",
          options: {
            color: "success",
            variant: "outlined",
            onClick: rows => handleOnCompleteDirection(rows)
          },
          icon: <CompleteIcon />,
          show: true
        },
        {
          key: "make_appointment",
          text: "Записать",
          icon: <MakeAnAppointmentIcon />,
          options: {
            color: "success",
            variant: "outlined",
            onClick: rows => handleOnMakeAnAppointment(rows)
          },
          show: generalScheduleNewEnabled
        }
      ].filter(action => action.show),
    [generalScheduleNewEnabled]
  );

  const table = useMaterialReactTable({
    columns,
    data: appointmentDirectionsStore.dataTable,
    rowVirtualizerInstanceRef,
    enableRowVirtualization: true,
    rowVirtualizerOptions: { overscan: 5 },
    localization: MRT_Localization_RU,
    isMultiSortEvent: () => true,
    enableFullScreenToggle: true,
    enableGlobalFilter: false,
    enableGrouping: true,
    enablePagination: false,
    layoutMode: "grid",
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 40,
        muiTableHeadCellProps: {
          align: "center"
        },
        muiTableBodyCellProps: {
          align: "center"
        }
      },
      "mrt-row-expand": {
        size: 40,
        muiTableHeadCellProps: {
          align: "center"
        },
        muiTableBodyCellProps: {
          align: "center"
        }
      }
    },
    enableRowSelection: (row) => {
      if (row.getIsGrouped()) {
          return row.subRows.some((row) => !row.original.date_completed);
      } else {
        return !row.original.date_completed;
      }
    },
    enableBatchRowSelection: true,
    onRowSelectionChange: setRowSelection,
    positionToolbarAlertBanner: "bottom",
    renderToolbarAlertBannerContent: (props) => renderToolbarAlertBannerContent({ ...props, defaultPadding: true }),
    renderTopToolbarCustomActions: ({ table }) => {
      const disabled = !table.getSelectedRowModel().rows.length;

      return (
        <TopToolbarCustomActions
          tooltipText={disabled ? "Выберите направление" : ""}
          actions={toolbarActions}
          anchorEl={topToolbarCustomActionsAnchor}
          setAnchorEl={setTopToolbarCustomActionsAnchor}
          table={table}
          disabled={disabled}
        />
      );
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`
      }
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%"
      }
    },
    initialState: {
      showColumnFilters: true,
      density: "compact",
      grouping: ["groupingValue"],
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"]
      },
      columnVisibility: { updated_at: false, created_at: false }
    },
    state: {
      rowSelection
    },
  });

  const handleChangeTableHeightOnResize = () => {
    const bottomIndent = isWithoutBottomIndent ? 0 : isTablet ? 25 : isMobile ? 15 : 50;
    setTableHeight(
      window.innerHeight - tableRef?.current?.getBoundingClientRect().top - bottomIndent
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleChangeTableHeightOnResize);
    return () => window.removeEventListener("resize", handleChangeTableHeightOnResize);
  }, [window.innerWidth, window.innerHeight]);

  if (isWithoutLayout) {
    return (
      <TableContainer ref={tableRef} height={tableHeight}>
        <MaterialReactTable table={table} />
      </TableContainer>
    )
  } else {
    return (
      <AnalyzesFormLayout
        title={<HeadingH2>Направления</HeadingH2>}
        sections={
          <TableContainer ref={tableRef} height={tableHeight}>
            {(appointmentDirectionsStore.isLoading || !appointmentDirectionsStore.isInitialized) && <AbsoluteLoader />}
            {appointmentDirectionsStore.isInitialized && <MaterialReactTable table={table} />}
          </TableContainer>
        }
      />
    )
  }
});
