// @flow
import {
  createEffect,
  createEvent,
  createStore,
  forward,
  restore,
  merge,
  combine,
  sample
} from "effector";
import type { Diagnosis, TDiagnosis_id } from "../domain/entities/Diagnosis";
import { tokenStore } from "./auth";
import { closeRightSidebar, openDiagnosesTreeSidebar } from "./rightSidebar";
import type { Store } from "effector";
import * as R from "ramda";
import { getDiagnosis_id } from "../domain/entities/Diagnosis";
import apiv2 from '../apiv2';

export const openPrimaryDiagnosesTree = createEvent<void>();
export const openSecondaryDiagnosesTree = createEvent<void>();

export type TDiagnosesType = "PRIMARY" | "SECONDARY";

const openDiagnosesTree = createEvent<TDiagnosesType>();

export const openedDiagnosesTreeStore = restore<*>(openDiagnosesTree, "PRIMARY");

export const changeCurrentDiagnosis = createEvent<?Diagnosis>();

export const closeDiagnosesTree = createEvent<void>();

export const currentDiagnosisStore = restore<*>(changeCurrentDiagnosis, null);

const getNullableDiagnosisId = (diagnosis: ?Diagnosis): ?TDiagnosis_id =>
  diagnosis ? getDiagnosis_id(diagnosis) : null;

const getDiagnosesEffect = createEffect<{ parent: ?TDiagnosis_id }, ?Array<Diagnosis>, *>({
  handler: async ({ parent }) => {
    const token = tokenStore.getState();
    if (!token) {
      return;
    }
    return await apiv2.diagnoses.getDiagnoses({ ...(parent ? { parent } : {}) });
  }
});

const getDiagnosesParent = createEffect<{ id: TDiagnosis_id }, ?Array<Diagnosis>, *>({
  handler: async ({ id }) => {
    const token = tokenStore.getState();
    if (!token) {
      return;
    }
    return await apiv2.diagnoses.getDiagnosisParents(id);
  }
});

const diagnosesTreeStore = createStore<{ [parentId: ?TDiagnosis_id]: Array<Diagnosis> }>({});

const diagnosesParentsStore = createStore<{ [id: ?TDiagnosis_id]: Array<Diagnosis> }>({});

const triggerDiagnosis = merge([
  sample(currentDiagnosisStore, openDiagnosesTree, diagnosis => diagnosis),
  currentDiagnosisStore
]);

forward({
  from: triggerDiagnosis.map(diagnosis => ({ parent: getNullableDiagnosisId(diagnosis) })),
  to: getDiagnosesEffect
});

forward({
  from: triggerDiagnosis.filterMap(diagnosis =>
    diagnosis ? { id: getDiagnosis_id(diagnosis) } : undefined
  ),
  to: getDiagnosesParent
});

forward({
  from: merge([openPrimaryDiagnosesTree, openSecondaryDiagnosesTree]),
  to: openDiagnosesTreeSidebar
});

forward({
  from: openPrimaryDiagnosesTree.map(() => "PRIMARY"),
  to: openDiagnosesTree
});

forward({
  from: openSecondaryDiagnosesTree.map(() => "SECONDARY"),
  to: openDiagnosesTree
});

forward({
  from: closeDiagnosesTree,
  to: closeRightSidebar
});

forward({
  from: closeDiagnosesTree.map(() => null),
  to: changeCurrentDiagnosis
});

diagnosesTreeStore.on(getDiagnosesEffect.done, (state, payload) => ({
  ...state,
  [payload.params.parent || null]: payload.result
}));

//$FlowFixMe
const currentDiagnosisTreeStore = combine<*, *, Array<Diagnosis>>(
  currentDiagnosisStore,
  diagnosesTreeStore,
  (currentDiagnosis, diagnosesTree) => diagnosesTree[getNullableDiagnosisId(currentDiagnosis)] || []
);

diagnosesParentsStore.on(getDiagnosesParent.done, (state, payload) => ({
  ...state,
  [payload.params.id]: payload.result
}));

//$FlowFixMe
export const currentDiagnosisParentsStore = combine<*, *, Array<Diagnosis>>(
  currentDiagnosisStore,
  diagnosesParentsStore,
  (currentDiagnosis, diagnosesParents) =>
    diagnosesParents[getNullableDiagnosisId(currentDiagnosis)] || []
);

export type DiagnosisWithChecked = {
  ...$Exact<Diagnosis>,
  isChecked: boolean
};

type TTransformToDiagnosis = (item: DiagnosisWithChecked) => Diagnosis;

export const transformToDiagnosis: TTransformToDiagnosis = R.omit(["isChecked"]);

const isAppointmentDiagnosesHas = appointmentDiagnoses =>
  R.compose(
    R.flip(R.includes)(R.map(getDiagnosis_id, appointmentDiagnoses)),
    R.prop("id")
  );

const joinTreeWithCurrentDiagnoses = (diagnosesTree, appointmentDiagnoses) =>
  R.map(
    item =>
      R.set(R.lensProp("isChecked"), isAppointmentDiagnosesHas(appointmentDiagnoses)(item), item),
    diagnosesTree
  );

export const combineWithAppointmentDiagnosesStore = (
  appointmentDiagnosesStore: Store<Array<Diagnosis>>
) => {
  return {
    store: combine<*, *, $ReadOnlyArray<DiagnosisWithChecked>>(
      currentDiagnosisTreeStore,
      appointmentDiagnosesStore,
      joinTreeWithCurrentDiagnoses
    )
  };
};
