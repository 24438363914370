import React, { useMemo } from "react";
import styled from "styled-components";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { observer } from "mobx-react";
import dayjs from "dayjs";

import { DefaultButton } from "../buttons/DefaultButton";
import { CorporateModalWindow as ModalWindow } from "../../corporate-portal/components/modal/CorporateModalWindow";
import { fromFieldToSelectOptions } from "./SendToMG";
import { READABLE_DATE_TIME_FORMAT } from "../../utils/dates";
import medicalGenomicsStore, { FieldTypes } from "../../stores/medicalGenomicsStore";

import historyIcon from './icons/history.inline.svg';

const ButtonContainer = styled.div`
  margin-right: 8px;
`;

const HistoryIcon = styled(historyIcon)`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`;

const HistoryIconContainer = styled.div`
  margin-top: 5px;
`;

const MG_STATUS_MAP = {
  NEW: "Новый",
  AWAITING_SENT: "Ожидает отправки",
  SENT: "Отправлен",
  PROCESSED: "Обработан"
};

function MGOrderList(props) {
  const { folderId } = props;

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'doctor',
        header: 'Врач',
      },
      {
        accessorKey: 'patient',
        header: 'Пациент',
      },
      {
        accessorKey: 'comment',
        header: 'Комментарий'
      },
      {
        accessorKey: 'created_at',
        header: 'Дата отправки'
      },
      {
        accessorKey: 'status',
        header: 'Статус'
      }
    ];
  }, [medicalGenomicsStore.orderList]);

  const data = useMemo(() => {
    return medicalGenomicsStore.orderList[folderId]?.map(order => ({
      id: order.id,
      doctor: order.detail.doctor_full_name,
      patient: order.detail.patient_full_name,
      comment: order.detail.comment,
      created_at: dayjs(order.created_at).format(READABLE_DATE_TIME_FORMAT),
      status: MG_STATUS_MAP[order.status],
      order: order
    })) || [];
  }, [medicalGenomicsStore.orderList, folderId]);

  const processDetails = (details) => {
    const newDetails = {};

    for (let key in details) {
      const fieldDetails = medicalGenomicsStore.fieldsDetails[key];
      if (fieldDetails && (fieldDetails.type === FieldTypes.MULTISELECT || fieldDetails.type === FieldTypes.SELECT)) {
        if (fieldDetails.type === FieldTypes.SELECT) {
          const options = fromFieldToSelectOptions(medicalGenomicsStore.fieldsDetails[key]);
          newDetails[key] = options.find(option => option.label === details[key]);
        }
        if (fieldDetails.type === FieldTypes.MULTISELECT) {
          const options = fromFieldToSelectOptions(medicalGenomicsStore.fieldsDetails[key]);
          newDetails[key] = details[key].map(value => options.find(option => option.label === value));
        }
      } else {
        newDetails[key] = details[key];
      }
    }

    return newDetails;
  };

  const table = useMaterialReactTable({
    columns: columns,
    data: data,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFilters: false,
    enableTopToolbar: false,
    initialState: {
      density: 'compact'
    },
    muiTableBodyRowProps: ({row}) => ({
      style: {
        cursor: "pointer"
      },
      onClick: () => {
        const item = row.original;
        if (item.order) {
          medicalGenomicsStore.setOrderData(item.order);
          medicalGenomicsStore.setFormData(processDetails(item.order.detail));
          medicalGenomicsStore.closeList();
          medicalGenomicsStore.showForm();
        }
      }
    }),
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  const handleButtonClick = async () => {
    await medicalGenomicsStore.fetchOrderListForFolder(folderId);
    medicalGenomicsStore.setIsOpenedFromList(true);
    medicalGenomicsStore.setEditingDisabled(true);
    medicalGenomicsStore.showList();
  };

  return (
    <>
      <ButtonContainer>
        <DefaultButton
          isLoading={medicalGenomicsStore.isOrderListLoading}
          onClick={handleButtonClick}
          overrides={{
            Icon: {
              component: HistoryIcon
            },
            IconContainer: {
              component: HistoryIconContainer
            }
          }}>
          Список заказов MG
        </DefaultButton>
      </ButtonContainer>
      <ModalWindow
        open={medicalGenomicsStore.isListOpen}
        header={"Список заказов MG"}
        content={
          <MaterialReactTable table={table} />
        }
        onClose={() => {
          medicalGenomicsStore.closeList();
          medicalGenomicsStore.setIsOpenedFromList(false);
          medicalGenomicsStore.setEditingDisabled(false);
        }}
        maxWidth={false}
      />
    </>
  );
}

export default observer(MGOrderList);
