import { createStore, createEvent } from "effector";
// import { tokenStore } from "./auth";

export const openModal = createEvent();
export const closeModal = createEvent();

export const appointmentMoveModalStore = createStore({
  opened: false,
  patientId: null,
  appointmentId: null,
  appointmentLayoutId: null
})
  .on(openModal, (state, payload) => ({
    ...state,
    opened: true,
    patientId: payload.patientId,
    appointmentId: payload.appointmentId,
    appointmentLayoutId: payload.layoutId
  }))
  .on(closeModal, state => ({
    ...state,
    opened: false,
    patientId: null,
    appointmentId: null,
    appointmentLayoutId: null
  }));
