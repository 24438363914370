import { observer } from "mobx-react";
import BonusPointingIcon from "../../../assets/icons/bonusPointing.inline.svg";
import BonusUsersIcon from "../../../assets/icons/bonusUsersIcon.inline.svg";
import SlideArrowIcon from "../../../assets/icons/bonusSlideArrow.inline.svg";
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import MuiTooltip from "@mui/material/Tooltip";
import bonusSchemaStore from "../../../stores/bonusSchemaStore";
import {
  BonusPaper,
  FlexBlock,
  BonusTitleBold,
  FlexBlockGapMini,
  BonusText, BonusSubtitle, BonusCountText
} from "./bonusSchema.styled";
import styled from "styled-components";
import BonusHelpIcon from "../../../assets/icons/bonusHelpIcon.inline.svg";
import * as moment from "moment";
import { withModifiers } from "with-modifiers";
import { Box } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {declOfNum} from "../../../utils/arrays";

const CountAppointment = withModifiers({
  active: () => `
    background: #FCEBF4;
    border-bottom: 2px solid #D6006D;
  `
})(
  modifier => styled.div`
    position: relative;
    padding: 12px;
    svg {
      width: 20px;
      height: 20px;
    }

    ${modifier}
`);

const BonusPatientToday = styled.span`
    position: relative;
    top: -5px;
    font-size: 12px;
    color: #31BD2E;
`;

const BonusPointingRedIcon = styled(BonusPointingIcon)`
   path {
        fill: #F2576A;
    }
`;

const BonusVerticalDivider = styled.div`
  background: #EBEBEB;
  width: 1px;
  height: 36px;
  flex-shrink: 0;
  position: absolute;
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
`

const SliderContainer = styled.div`
  position: relative;
  min-height: 0;
  min-width: 0;
  padding-inline: 30px;
  .slick-track {
    display: flex;
  }
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 10px;
  background: #EBEBEB;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  ${props => props.type === "prev"
    ? `left: 0;` 
    : `
        right: 0;
        svg {
          transform: rotate(180deg);
        }
      `         
  };
  ${props => props.opacity && `
    opacity: 0.5;
    pointer-events: none;
  `};
  &:hover {
    background: #D1D1D1;
  }
  &:active {
    background: #B8B8B8;
  }
`;

const SliderArrow = ({ type, onClick, className }) => {
  return (
    <ArrowContainer onClick={onClick} type={type} opacity={className.includes("slick-disabled")}>
      <SlideArrowIcon />
    </ArrowContainer>
  )
}

export const RunRate = observer(() => {
  const sliderRef = useRef(null);
  if (!bonusSchemaStore.bonusSchemaData.schedules.days.plan.length) {
    return null;
  }
  /** Последний index элемента массива schedules.days.plan, дата которого не позже, чем текущий день */
  const indexDayFromPlan = bonusSchemaStore.bonusSchemaData.schedules.days.plan.findLastIndex((item) => moment(item.date_trunc).startOf('day').diff(moment().startOf('day')) <= 0);
  const initialSlide = indexDayFromPlan < 0 ? 0 : indexDayFromPlan;
  let delta = 0;
  /** Если initialSlide > 0 && день в элементе массива schedules.days.fact === текущему дню */
  if (initialSlide && moment(bonusSchemaStore.bonusSchemaData.schedules.days.fact[initialSlide].date_trunc).isSame(moment().startOf('day'), 'day')) {
    delta = bonusSchemaStore.bonusSchemaData.schedules.days.fact[initialSlide - 1].delta - bonusSchemaStore.bonusSchemaData.schedules.days.fact[initialSlide].delta
    if (delta < 0) {
      delta = 0;
    }
  }

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    centerMode: false,
    initialSlide: initialSlide,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };

  return (
    <>
      <BonusPaper>
        <FlexBlock>
          <BonusTitleBold>План/Факт</BonusTitleBold>
          <MuiTooltip
            componentsProps={{
              tooltip: { sx: { background: "#ffffff!important" } }
            }}
            title={
              <div>
                <CountAppointment>
                  <FlexBlockGapMini>
                    <BonusUsersIcon />
                    <BonusCountText modifiers='bold'>
                      100
                    </BonusCountText>
                    <BonusPatientToday>+5</BonusPatientToday>
                  </FlexBlockGapMini>
                  <FlexBlock>
                    <BonusText modifiers='gray'>
                      {moment().format('DD MMMM')}
                    </BonusText>
                    <BonusSubtitle modifiers='black'>
                      {155}
                    </BonusSubtitle>
                  </FlexBlock>
                </CountAppointment>
                <BonusSubtitle modifiers={'black'}>
                  Где:
                </BonusSubtitle>
                <BonusText>
                  1. Цифра 100 - факт на текущую дату
                </BonusText>
                <BonusText>
                  2. Цифра 5 -  количество записанных пациентов в текущий день
                </BonusText>
                <BonusText>
                  3. Цифра 155 -  максимально возможное количество пациентов, исходя из открытого расписания приемов.
                </BonusText>
              </div>
            }
          >
            <Box sx={{ display: "flex", cursor: "pointer" }}>
              <BonusHelpIcon />
            </Box>
          </MuiTooltip>
        </FlexBlock>
        {!!delta &&
          <FlexBlockGapMini>
            <BonusPointingRedIcon />
            <BonusText>Сегодня на {delta} {declOfNum(delta, ['пациент', 'пациента', 'пациентов'])} меньше</BonusText>
          {/* !!! ToDo  над человечком написать про то, как рассчитывается это число */}
          {/* !!! ToDo  колво приемов с зафиксированной оказанной услугой - 129 */}
          {/*  Можно напистаь какое-то отставание от плана*/}
          </FlexBlockGapMini>
        }
        <SliderContainer>
          <Slider ref={sliderRef} {...sliderSettings}>
            {bonusSchemaStore.bonusSchemaData.schedules.days.plan.map((item, index) => (
               <CountAppointment className={`run-rate-count-appointment-${index}`} key={index} modifiers={moment(item.date_trunc).startOf('day').diff(moment().startOf('day')) === 0 ? 'active' : ''}>
                <FlexBlockGapMini>
                  <BonusUsersIcon />
                  <BonusCountText modifiers='bold'>
                    {bonusSchemaStore.bonusSchemaData.schedules.days.fact[index].count}
                  </BonusCountText>
                  { !!bonusSchemaStore.bonusSchemaData.schedules.days.fact[index].delta &&
                    <BonusPatientToday>+{bonusSchemaStore.bonusSchemaData.schedules.days.fact[index].delta}</BonusPatientToday>
                  }
                </FlexBlockGapMini>
                <FlexBlock>
                  <BonusText modifiers='gray'>
                    {moment(item.date_trunc).format('DD MMMM')}
                  </BonusText>
                  <BonusSubtitle modifiers='black'>
                    {item.count}
                  </BonusSubtitle>
                </FlexBlock>
                {index !== 0 && <BonusVerticalDivider />}
              </CountAppointment>
            ))}
          </Slider>
        </SliderContainer>
      </BonusPaper>
    </>
  )
})
