import { observer } from "mobx-react";
import React from "react";
import {
  BonusPaper,
  FlexBlock,
  BonusTitleBold,
  BonusLevelTextColor,
  BonusSubtitle,
  BonusVerticalDivider
} from "./bonusSchema.styled";
import styled from "styled-components";
import moment from "moment";
import { READABLE_DATE_FORMAT } from "../../../utils/dates";
import { useMediaQuery } from "@mui/material";

const CountAppointment = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    @media (max-width: 650px) {
      width: 45%;
    }
    @media (max-width: 650px) {
      width: 45%;
    }
    @media (max-width: 350px) {
      width: 100%;
    }
`;

export const BonusSchemaSalary = observer(() => {
  const isMobileDevices = useMediaQuery("(max-width: 650px)");

  return (
    <>
      <BonusPaper>
        <FlexBlock modifiers={isMobileDevices && ["column", "alignmentToStart"]}>
          <BonusTitleBold>Моя зарплата</BonusTitleBold>
          <FlexBlock modifiers={isMobileDevices ? ["wrap", "justifyContentSB"] : "justifyContentSB"}>
            {!isMobileDevices && <BonusVerticalDivider />}
            <CountAppointment>
              <BonusSubtitle modifiers='gray'>
                {moment().subtract('days', 1).format(READABLE_DATE_FORMAT)}
              </BonusSubtitle>
              <BonusTitleBold modifiers='center'>
                15 000 ₽
              </BonusTitleBold>
            </CountAppointment>
            {!isMobileDevices && <BonusVerticalDivider />}
            <CountAppointment>
              <BonusSubtitle modifiers='gray'>
                Сегодня
              </BonusSubtitle>
              <BonusTitleBold modifiers='center'>
                5 000 ₽
              </BonusTitleBold>
            </CountAppointment>
            {!isMobileDevices && <BonusVerticalDivider />}
            <CountAppointment>
              <BonusSubtitle modifiers='gray'>
                В конце месяца
              </BonusSubtitle>
              <BonusTitleBold modifiers='center'>
                125 000 ₽
              </BonusTitleBold>
            </CountAppointment>
            {!isMobileDevices && <BonusVerticalDivider />}
            <CountAppointment>
              <BonusSubtitle modifiers='gray'>
                <BonusLevelTextColor modifiers={'gold'}>Gold уровень</BonusLevelTextColor>
              </BonusSubtitle>
              <BonusTitleBold modifiers='center'>
                155 000 ₽
              </BonusTitleBold>
            </CountAppointment>
          </FlexBlock>
        </FlexBlock>
      </BonusPaper>
    </>
  )
})
