//@flow
import React from "react";
import styled from "styled-components";

import {HeadingH3, PXSmall} from "../styleguide/typography";
import { ModalWithCloseButtonLayout } from "../modal/ModalWithCloseButtonLayout";
import { ModalBackground } from "../modal/ModalBackground";
import MediaPermissionSelectIcon from "../../assets/icons/media-permission-select.inline.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 653px;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 40px;
  }
`;

const IconContainer = styled.div`
  margin-bottom: 30px;
`;

const HeadingContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

export const MediaPermissionSelectModal = (props: {
  isShown: boolean,
}) => {
  const { isShown } = props;

  return (
    <>
      {isShown && (
        <ModalBackground modifiers={'z-index-full'}>
          <ModalWithCloseButtonLayout hiddenCloseButton={true}>
            <Container>
              <IconContainer>
                <MediaPermissionSelectIcon />
              </IconContainer>
              <HeadingContainer>
                <HeadingH3 modifiers={['center']}>Нажмите <BoldText>Разрешить</BoldText></HeadingH3>
              </HeadingContainer>
              <PXSmall modifiers={['gray']}>В любой момент встречи микрофон и камеру можно отключить.</PXSmall>
            </Container>
          </ModalWithCloseButtonLayout>
        </ModalBackground>
      )}
    </>
  );
};
