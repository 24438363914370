import { makeAutoObservable } from "mobx";
import Binder from "../../utils/binder";
import { generatedDocumentsData } from "../utils/documents/generatedDocumentsData";
import corporateDocuments from "../api/entities/corporateDocuments";

export const FILE_TYPES = {
  FOLDER: "folder",
  PDF: "pdf",
  DOC: "doc",
  TXT: "txt",
  VIDEO: "video",
  IMAGE: "image",
  UNKNOWN_FORMAT: "unknown_format"
};

class CorporateDocumentsStore {
  resetState() {
    this.isInitialized = false;
    this.isLoading = false;
    this.searchValue = "";
    this.files = [];
    this.currentFolder = null;
    this.historyFolders = [{id: 1, name: 'Все документы'}];
  }

  constructor() {
    this.resetState();
    Binder.bind(this, CorporateDocumentsStore);
    makeAutoObservable(this);
  }

  setHistoryFolders(historyFolders) {
    this.historyFolders = historyFolders;
  }

  setCurrentFolder(folder) {
    this.currentFolder = folder;
    this.getDocuments();
  }

  getTypeFile(fileType) {
    if (!fileType) {
      return FILE_TYPES.UNKNOWN_FORMAT;
    }
    if (fileType.includes('image/')) {
      return FILE_TYPES.IMAGE;
    }
    if (fileType.includes('video/')) {
      return FILE_TYPES.VIDEO;
    }
    if (fileType.includes('text/')) {
      return FILE_TYPES.TXT;
    }
    if (fileType === 'application/excel' || fileType === 'application/xml' || fileType === 'application/msword') {
      return FILE_TYPES.DOC;
    }
    if (fileType === 'application/pdf') {
      return FILE_TYPES.PDF;
    }
    return FILE_TYPES.UNKNOWN_FORMAT;
  }

  async getDetailsInfoFile(file) {
    try {
      const response = await corporateDocuments.getDetailsInfoFile(file.id);
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async getDocuments(isSeacrh) {
    try {
      this.isLoading = true;
      const search = isSeacrh ? this.searchValue : '';
      const response = await corporateDocuments.getDocumentsById(this.currentFolder.id, search);
      const newFiles = []
      if (!isSeacrh || !this.searchValue) {
        response.subdirectories.forEach((subdirectory) => {
          subdirectory.type = FILE_TYPES.FOLDER;
          newFiles.push(subdirectory);
        })
        response.files.forEach((file) => {
          file.type = this.getTypeFile(file.type);
          newFiles.push(file);
        })
        this.searchValue = '';
      } else {
        response.forEach((file) => {
          if (file.type === 'file') {
            file.data.type = this.getTypeFile(file.type);
          } else {
            file.data.type = FILE_TYPES.FOLDER;
          }
          newFiles.push(file.data);
        })
      }
      this.files = newFiles;
      if (!this.historyFolders.find(folder => folder.id === this.currentFolder.id)) {
        this.historyFolders.push(response);
      }

      if (!this.isInitialized) {
        this.isInitialized = true;
      }

      this.isLoading = false
    } catch (e) {
      console.error(e);
      this.isLoading = false
    }
  }

  goBackByFolderId(id) {
    const folderIndex = this.historyFolders.findIndex(folder => folder.id === id);
    if (folderIndex === this.historyFolders.length - 1) {
      return;
    }
    /** Необходимо оставлять текущую открытую папку в интерфейсе */
    this.historyFolders.splice(folderIndex + 1);
  }

  changeSearchValue(value) {
    if (this.searchValue === value) {
      return;
    }

    this.searchValue = value;
    this.getDocuments(true);
  }
}

export default new CorporateDocumentsStore();
