import React, { useRef } from "react";
import styled, { css } from "styled-components";
// import moment from "moment";

import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import "moment/locale/it";

import calendarIcon from "../icons/calendar.svg";
import LeftArrowIcon from "../icons/LeftArrow";
import RightArrowIcon from "../icons/RightArrow";
import { colors } from "../../styleguide/colors";

const CalendarContainer = styled.div`
  display: flex;
  ${props =>
    props.disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : ""}
`;

const CalendarButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49px;
  height: 49px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  transition: border-color 0.1s linear;

  svg > path {
    transition: stroke 0.1s linear;
  }

  &:hover {
    border-color: ${colors.magenta};

    svg > path {
      stroke: ${colors.magenta};
    }
  }
  &:not(:active) {
    border-color: rgba(0, 0, 0, 0.2);
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const CalendarInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  margin: 0 4px;
  padding: 0 20px;
  cursor: pointer;
  transition: border-color 0.1s linear;

  &:hover {
    border-color: ${colors.magenta};

    & input {
      color: ${colors.magenta} !important;
    }
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const CalendarIcon = styled.img`
  margin-left: 20px;
  margin-right: 8px;
`;

const CalendarButtonWithIcon = props => {
  const { icon, ...rest } = props;

  return <CalendarButton {...rest}>{icon ? icon : ""}</CalendarButton>;
};

export const Calendar = props => {
  const {
    availableDaysFilter,
    previousDayActive,
    nextDayActive,
    value,
    onChange,
    onPrevious,
    onNext,
    disabled
  } = props;

  const pickerRef = useRef(null);

  const now = new Date();

  return (
    <CalendarContainer disabled={disabled}>
      <CalendarButtonWithIcon
        icon={<LeftArrowIcon />}
        disabled={!previousDayActive}
        onClick={onPrevious}
      />
      <CalendarInputWrapper
        onClick={() => {
          if (pickerRef && !pickerRef.current.state.showOverlay) {
            pickerRef.current.getInput().focus();
          }
        }}>
        <CalendarIcon src={calendarIcon} alt="Calendar" />
        <DayPickerInput
          ref={pickerRef}
          format="D MMMM YYYY"
          value={`${value.format("D MMMM YYYY")}`}
          onDayChange={onChange}
          formatDate={formatDate}
          parseDate={parseDate}
          inputProps={{
            readOnly: true,
            style: {
              background: "none",
              border: "none",
              outlineStyle: "none",
              fontFamily:
                'Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "12px",
              letterSpacing: "0.2px",
              textTransform: "uppercase",
              textAlign: "left",
              width: "120px",
              cursor: "pointer",
              color: "#262626",
              transition: "color 0.1s linear"
            }
          }}
          dayPickerProps={{
            locale: "ru",
            localeUtils: MomentLocaleUtils,
            showOutsideDays: true,
            fromMonth: now,
            disabledDays: [availableDaysFilter]
          }}
        />
      </CalendarInputWrapper>
      <CalendarButtonWithIcon
        icon={<RightArrowIcon />}
        disabled={!nextDayActive}
        onClick={onNext}
      />
    </CalendarContainer>
  );
};
