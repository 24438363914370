import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {colors} from '../styleguide/colors';

const TeethCheckboxContainer = styled.button`
  display: inline-flex;
  flex-direction: column;
  gap: 7px;
  min-width: 32px;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #4a4a4a;

  svg {
    transform: ${props => props.reversedY && 'rotateY(180deg)'} ${props => props.reversedX && 'rotateX(180deg)'};
  }
  
  span {
    font: 12px "Graphik LCG";
  }
  
  &:hover {
    --svg-stroke: ${colors.magenta};
    color: ${colors.magenta};
  }
  
  &:active {
    --svg-stroke: ${colors.magenta};
    --svg-fill: ${colors.magenta};
    color: ${colors.magenta};
  }
  
  ${props => props.active && css`
    --svg-stroke: ${colors.magenta};
    --svg-fill: ${colors.magenta};
    color: ${colors.magenta};
  `}
  
  &[disabled] {
    --svg-stroke: #4a4a4a;
    --svg-fill: transparent;
    color: #4a4a4a;
    opacity: 0.2;
    pointer-events: none;
  }
`;

export const TeethCheckbox = ({
  value,
  active,
  icon,
  text,
  reversedX,
  reversedY,
  disabled,
  onChange,
}) => {
  const [isActive, setIsActive] = useState(active);

  const handleClick = () => {
    setIsActive(state => !state);
    onChange?.(value, !isActive);
  };

  useEffect(() => {
    if (active !== isActive) {
      setIsActive(active);
    }
  }, [active]);

  return (
    <TeethCheckboxContainer reversedY={reversedY} reversedX={reversedX} active={isActive} onClick={handleClick} disabled={disabled}>
      {icon}
      <span>{disabled ? '—' : text}</span>
    </TeethCheckboxContainer>
  );
};
