//@flow
import type { Shortcut } from "../entities/Shortcut";
import type { TField_id } from "../entities/Field";
import type { Checklist } from "../entities/Checklist";

export type TChecklistDestination =
  | "recommendation_checklist"
  | "medication_checklist"
  | "examination_checklist";

export type TChecklistDestinationTypeArray = Array<TChecklistDestination>;

type TChecklistShortcut = Checklist;

export type TChecklistShortcutArray = TChecklistShortcut[];

export type TShortcutsForField = {
  destination_type: "field",
  field: {
    id: TField_id,
    copy_id: string,
    name: string,
    section: {
      id: number,
      name: string,
      copy_id: string
    },
    parent: ?{
      id: number,
      copy_id: string,
      name: string
    }
  },
  shortcuts: Array<Shortcut>
};

export type TShortcutsForServices = {
  destination_type: "services",
  shortcuts: Array<Shortcut>
};

export type TShortcutsForAppointmentServices = {
  destination_type: "appointment_services",
  shortcuts: Array<Shortcut>
};

export type TShortcutsForChecklist = {
  destination_type: TChecklistDestination,
  shortcuts: Array<Shortcut>
};

export type TShortcuts = TShortcutsForField | TShortcutsForServices | TShortcutsForChecklist | TShortcutsForAppointmentServices;

export function isShortcutForField(shortcut: TShortcuts): %checks {
  return shortcut.destination_type === "field";
}

export function isShortcutForServices(shortcut: TShortcuts): %checks {
  return shortcut.destination_type === "services";
}

export function isShortcutForPrescribedServices(shortcut: TShortcuts): %checks {
  return shortcut.destination_type === "prescribed_services";
}

export function isShortcutForAppointmentServices(shortcut: TShortcuts): %checks {
  return shortcut.destination_type === "appointment_services";
}

export function isShortcutForChecklist(shortcut: TShortcuts): %checks {
  return (
    shortcut.destination_type === "recommendation_checklist" ||
    shortcut.destination_type === "medication_checklist" ||
    shortcut.destination_type === "examination_checklist"
  );
}

export type ShortcutCreationOptions =
  | {
      destination_type: "field",
      field_id: TField_id,
      name: string,
      value: string
    }
  | {
      destination_type: "services",
      name: string,
      value: []
    }
  | {
      destination_type: "appointment_services",
      name: string,
      value: []
    }
  | {
      destination_type: TChecklistDestination,
      name: string,
      checklist: TChecklistShortcutArray
    }
  | {
      destination_type: TChecklistDestination,
      name: string
    };

export type ShortcutOrderOptions = Array<{ shortcut: number, order: number }>;
