import styled, { css } from "styled-components";
import { colors } from "../../styleguide/colors";

import starIcon from "../icons/star.inline.svg";

const activeStarIcon = css`
  stroke: ${colors.magenta};
  fill: ${colors.magenta};
`;

export const Container = styled.div`
  position: relative;
  padding: 12px 60px 12px 24px;
  cursor: pointer;

  &:hover {
    background: ${colors.gray900};
  }

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        ${StarIcon} path {
          stroke: ${colors.magenta};
          fill: ${colors.lavenderBlush};
        }
      }
    `}

  ${({ active }) =>
    active &&
    css`
      ${StarIcon} path {
        ${activeStarIcon};
      }
    `}
`;

export const StarIcon = styled(starIcon)`
  position: absolute;
  right: 27px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ActiveStarIcon = styled(StarIcon)`
  path {
    ${activeStarIcon};
  }
`;

export const Title = styled.p`
  font-size: 15px;
  line-height: 23px;
  overflow-wrap: anywhere;
  small {
    font-size: 12px;
    color: ${colors.darkGray};
  }
`;

export const Code = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 2px;
`;
