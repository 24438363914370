import doctorsStore from "../../../stores/doctorsStore";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import BonusCheckboxError from "../../../assets/icons/bonusCheckboxError.inline.svg";
import BonusCheckboxActiveIcon from "../../../assets/icons/bonusCheckboxActive.inline.svg";
import BonusCheckboxIcon from "../../../assets/icons/bonusCheckbox.inline.svg";
import MuiTooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Dialog from "@mui/material/Dialog";
import React from "react";
import {
  BonusLevelTextColor,
  BonusPaperModal, BonusText,
  ConditionsBlockLevel,
  FlexBlockGapMini,
  LevelsBlock
} from "./bonusSchema.styled";
import bonusSchemaStore from "../../../stores/bonusSchemaStore";
import { observer } from "mobx-react";
import BonusHelpIcon from "../../../assets/icons/bonusHelpIcon.inline.svg";
import styled from "styled-components";
import { colors } from "../../styleguide/colors";


const ConditionsItem = styled.div`
    padding: 6px;
    border-bottom: 1px solid #EBEBEB;
    
    &:last-child {
        border: none;
    }
`;

const DialogContentLevels = styled(DialogContent)`
  padding: 16px !important;
`;

const LevelItemTitle = styled.div`
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: ${colors.white};
    text-align: center;
`;

const COLORS_FOR_TYPE = {
  bronze: '#C3946F',
  silver: '#ACADB0',
  gold: '#D5B371',
  platinum: '#8D9DA8',
  brilliant: '#6CB6DA',
}

export const BonusSchemaAllLevelsModal = observer(() => {
  const onClose = () => {
    bonusSchemaStore.changeShowLevelBonusPopup(false);
  }

  return (
    <>
      <Dialog open={bonusSchemaStore.showLevelBonusPopup} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ m: 0, p: 2 }}>Все уровни</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
        <Divider />
        <DialogContentLevels>
          <LevelsBlock>
            {bonusSchemaStore.bonusSchemaData.all_levels.map((item) => (
              <BonusPaperModal background={COLORS_FOR_TYPE[item.name]} key={item.title}>
                <LevelItemTitle>{item.title}</LevelItemTitle>
                <ConditionsBlockLevel>
                  {item.conditions.map((condition, index) => (
                    <ConditionsItem key={index}>
                      <FlexBlockGapMini >
                        {condition.active ? (
                          <BonusCheckboxActiveIcon />
                        ) : (
                          <BonusCheckboxError/>
                        )}
                        <BonusText>
                          {condition.text}
                        </BonusText>
                        {!!condition.helpText && (
                          <MuiTooltip title={condition.helpText} componentsProps={{
                            tooltip: {
                              sx: { background: '#ffffff!important' }
                            }
                          }}>
                            <Box sx={{ display: "flex", cursor: "pointer" }}>
                              <BonusHelpIcon />
                            </Box>
                          </MuiTooltip>
                        )}
                      </FlexBlockGapMini>
                    </ConditionsItem>
                  ))}
                </ConditionsBlockLevel>
              </BonusPaperModal>
            ))}
          </LevelsBlock>
          {/*<BonusText>Текст с описанием того, на что влияет каждый уровень и т.п.</BonusText>*/}
        </DialogContentLevels>
      </Dialog>
    </>
  )
})
