import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { CorporateSurveyWidget } from "../../modules/widgets";
import { BackdropLoader } from "../../../components/loader/Loader";
import corporateNewsStore from "../../stores/corporateNewsStore";
import { Stack } from "@mui/material";
import { CorporateContent } from "../CorporateContent";
import { CorporateControlPanel } from "../CorporateControlPanel";

const sideBarContent = [
  {
    name: "feedBack",
    content: <CorporateSurveyWidget surveyId={11} />
  }
];

export const CorporateNewsLayout = observer(({ children }) => {
  useLayoutEffect(() => {
    if (corporateNewsStore.categories.length) {
      return;
    }
    (async () => {
      await corporateNewsStore.getCategories();
    })();
  }, []);

  return (
    <CorporateContent rightSideBarContent={sideBarContent}>
      <BackdropLoader isOpen={!corporateNewsStore.isInitialized} />
      <Stack spacing={3}>
        <CorporateControlPanel
          isLoading={!!corporateNewsStore.categories.length}
          tabsParams={{
            isInitialized: !corporateNewsStore.isInitialized,
            initialTabs: corporateNewsStore.categories,
            indexSelectedTab: corporateNewsStore.selectedCategory.index,
            changeSelectedTab: corporateNewsStore.changeSelectedCategory
          }}
          backButton={{
            isShow: !!corporateNewsStore.detailedDataNews,
            text: corporateNewsStore.selectedCategory.info.name,
            path: "/corporate-portal/news"
          }}
          searchField={{
            label: "Поиск новости",
            value: corporateNewsStore.searchValue,
            onChange: corporateNewsStore.changeSearchValue
          }}
        />
        {children}
      </Stack>
    </CorporateContent>
  );
});
