// @flow
import styled, { css } from "styled-components";
import { colors } from "../styleguide/colors";

export const NewPointInputContainer = styled.div`
  margin-top: 3px;
  margin-bottom: 10px;
`;

export const AddPointContainer = styled.div`
  padding: 11px 37px;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
  @media (max-width: 480px) {
    padding: 10px 15px;
  }
  ${({ withText }) =>
    withText &&
    css`
      display: flex;
      align-items: center;
      column-gap: 13px;
      cursor: pointer;
    `}

  ${({ withHover }) =>
    withHover &&
    css`
      :hover {
        span {
          color: ${colors.black} !important;
        }

        button {
          opacity: 1;
        }
      }
    `}
`;

export const AddPointText = styled.span`
  font-size: 15px;
  color: ${colors.silver};
`;
