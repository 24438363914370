// @flow
import React, { useEffect, useMemo } from "react";

import useReactRouter from "use-react-router";
import { backToHistory, DynamicForm } from "../common/dynamic-form/DynamicForm";
import feedbackStore from "../../stores/feedbackStore";
import { observer } from "mobx-react";
import { Paper } from "@mui/material";
import { ContainerComponent } from "../common/dynamic-form/styled/Container.styled";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const SurveyResultBodyBlock = styled.div`
  overflow-y: auto;
  height: max-content;
  margin-top: 30px;
`;

const SurveyResultBlock = styled.div`
    width: 990px;

    @media (max-width: 1240px) {
        width: 100%;
    }
`;

const SurveyValueTextValue = styled.div`
    font-size: 14px;
    line-height: 18px;
`;

const SurveyValueText = styled.div`
    font-size: 14px;
    line-height: 18px;
`;

const SurveyValueTextBold = styled(SurveyValueText)`
    font-weight: 500;
`;

const SurveyValueBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 10px;
    
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.gray700};
    
  &:last-child {
      margin-bottom: 0;
      border-bottom: none;
  }
`;


export const FeedbackResultDetailPage = observer(() => {
  const {
    history,
    match: { params }
  } = useReactRouter();


  const editSchema = [
    {
      type: 'text',
      name: 'patientFullName',
      label: 'ФИО пациента',
      disabled: true,
    },
    {
      type: 'text',
      name: 'surveyName',
      label: 'Название шаблона',
      disabled: true,
    },
  ]

  useEffect(() => {
    (async () => {
      await feedbackStore.getResultById(params.id);
    })();
  }, []);



  const valuesSteps = useMemo(() => {
    if (feedbackStore.surveyResultData) {
      return feedbackStore.surveyResultData.survey.steps.reduce((acc, step) => {
        const filterFields = step.fields.filter((field) => {
          const value = feedbackStore.surveyResultData.values.find((item) => item.field === field.id)?.value;
          return value ? !!JSON.parse(value) : false;
        });
        if (filterFields.length) {
          acc.push({
            id: step.id,
            name: step.name,
            fields: filterFields.map((field) => {
                const value = feedbackStore.surveyResultData.values.find((item) => item.field === field.id)?.value;
                return {
                  name: field.name,
                  value: JSON.parse(value)
                }
              })
          })
        }
        return acc;
      }, [])

    }
    return null;

  }, [feedbackStore.surveyResultData]);

  const submitHandler = async (data) => {
    await feedbackStore.postSurveyResult(data);
    backToHistory(feedbackStore.generateSearch.bind(feedbackStore), history)
  }

  const data = useMemo(() => {
    if (feedbackStore.surveyResultData) {
      return {
        patientFullName: `${feedbackStore.surveyResultData.patient?.last_name} ${feedbackStore.surveyResultData.patient?.first_name} ${feedbackStore.surveyResultData.patient?.middle_name}`,
        surveyName: feedbackStore.surveyResultData.survey.name,
      };
    }
    return null;

  }, [feedbackStore.surveyResultData]);

  return (
    <SurveyResultBlock>
      <ContainerComponent>
        {feedbackStore.surveyResultData && (
          <Paper
            style={{
              padding: '25px',
            }}
          >
            {data && (
              <DynamicForm
                hiddenButtonSave={true}
                hiddenButtonCancel={true}
                data={data} submitHandler={submitHandler}
                fields={editSchema}
                title="Просмотр обратной связи" />
            )}
            <SurveyResultBodyBlock>
              {valuesSteps.map((step) => (
                <>
                  {step.fields.map((field) => {
                    let value = field.value;
                    if (Array.isArray(value) && value.length > 1) {
                      value = value.join(', ');
                    }
                    return (
                      <SurveyValueBlock key={field.name}>
                        <SurveyValueText>
                          <SurveyValueTextBold>Вопрос:</SurveyValueTextBold> {field.name}:
                        </SurveyValueText>
                        <SurveyValueTextValue>
                          <SurveyValueTextBold>Ответ:</SurveyValueTextBold> {value}
                        </SurveyValueTextValue>
                      </SurveyValueBlock>
                    )
                  })}
                </>
              ))}
            </SurveyResultBodyBlock>
          </Paper>
        )}
      </ContainerComponent>
    </SurveyResultBlock>
  );
});
