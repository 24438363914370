import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height};
  border-radius: 100px;
  background: ${props => props.background};
`;

const Bar = styled.div`
  position: relative;
  min-width: ${props => props.minWidth};
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 100px;
  background: ${props => props.background};
`;

const COLORS_FOR_TYPE = {
  bronze: {
    backgroundWrapper: '#F0E5DD',
    backgroundBar: '#C3946F',
  },
  silver: {
    backgroundWrapper: '#EFF0F0',
    backgroundBar: '#ACADB0',
  },
  gold: {
    backgroundWrapper: '#F0E9DD',
    backgroundBar: '#D5B371',
  },
  platinum: {
    backgroundWrapper: '#DDE7F0',
    backgroundBar: '#8D9DA8',
  },
  brilliant: {
    backgroundWrapper: '#DDE9F0',
    backgroundBar: '#6CB6DA',
  },
}

export const HorizontalBar = ({
  backgroundWrapper = "#EFF0F0",
  backgroundBar = "#ACADB0",
  color,
  height = "28px",
  widthBar = "0%",
  minWidthBar = "auto",
  children
}) => {
  return (
    <Container background={color ? COLORS_FOR_TYPE[color].backgroundWrapper : backgroundWrapper} height={height}>
      <Bar width={widthBar} minWidth={minWidthBar} background={color ? COLORS_FOR_TYPE[color].backgroundBar : backgroundBar} height={height}>
        {children}
      </Bar>
    </Container>
  );
};
