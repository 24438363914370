// @flow
import styled from "styled-components";

export const SubpointsContainer = styled.div`
  margin-left: 64px;

  & > * {
    margin-top: 10px;
  }

  & > * + * {
    margin-top: 20px;
  }
`;
