// @flow
import * as React from "react";
import { useState, useCallback, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import styled, { css } from "styled-components";
import { useStore } from "effector-react";
import { observer } from "mobx-react";
import Tooltip from '@mui/material/Tooltip';

import type { Schedule, ScheduleReserved } from "../../domain/entities/Schedule";
import { ScheduleDayLayout } from "./ScheduleDayLayout";
import { ScheduleHour } from "./ScheduleHour";
import { ScheduleHourStub } from "./ScheduleHourStub";
import { colors } from "../styleguide/colors";
import { useScrollIntoView } from "../../utils/useScrollIntoView";
import { ModalPortal } from "../modal/ModalPortal";
import { useScheduleInsert } from "../schedule-insert-modal/useScheduleInsert";
import { formatScheduleDate, isSameOrAfterDate } from "../../domain/services/schedule";
import { currentUser as currentUserStore } from "../../stores/auth";
import { FeedbackProvider } from "../feedback/FeedbackProvider";
import {
  changeChatAndUpdateSchedules, changeDataForNewTimeSlot,
  dataStore, recordingChatAllowed
} from "../schedule-page/scheduleStore";
import {
  isCompleted,
  isFilledFeedbackWithoutResponse,
  isNotFutureFeedback
} from "../../domain/services/feedback";
import { openFeedbackForView } from "../../stores/feedbacks/feedbackSections";
import { FeedbackIcon } from "../feedback-icon/FeedbackIcon";
import { isOnlineSchedule, isScheduleWithChat } from "./types";
import chatStore from "../../stores/chatStore";

import ChatIcon from "./icons/chat.inline.svg";
import OnlineIcon from "./icons/online.inline.svg";
import { Container } from "../buttons/DefaultButton";
import { ButtonWithIcon, Icon } from "../buttons/ButtonWithIcon";
import { CheckboxLabel } from "../decision-support/CheckboxLabel";
import { withModifiers } from "with-modifiers/dist";
import checkboxHoverImg from "../decision-support/icons/checkbox-hover.svg";
import { FEATURE, useApplicationFeatureEnabled } from '../../utils/applicationFeatures';
import voximplant from "../../components/chats/video/voximplantConference";
import apiv2 from '../../apiv2';
import { HeadingH6 } from '../styleguide/typography';
import { ScheduleDaysContext } from "../schedule-page/ScheduleDays";
import { useMediaQuery } from "@mui/material";

const ScheduleDayButton = styled.button`
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border: none;
    background: none;
    cursor: pointer;
    
    &:hover {
        color: ${colors.magenta};
        --svg-stroke: ${colors.magenta};
    }

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
        color: inherit;
    }
`;

const ScheduleHourContainer = styled.div`
  position: relative;
  border-top: solid 1px transparent;
  border-image: linear-gradient(
      to right,
      transparent,
      transparent 24px,
      ${colors.transparentGray} 24px,
      ${colors.transparentGray} calc(100% - 24px),
      transparent calc(100% - 24px)
    )
    100% 1 0 stretch;
  ${props =>
    props.patientIsArrived &&
    css`
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 5%;
        width: 4px;
        height: 90%;
        background: ${colors.magenta};
      }
    `}
`;

const ScheduleHourStubContainer = styled(ScheduleHourContainer)`
  & + & {
    border-top: none;
  }
`;

const ScheduleHourButtonsContainer = withModifiers({
  smallRight: () => `
        right: 30px;
    `
})(
  modifier => styled.div`
  position: absolute;
  top: 0;
  right: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding: 0 12px 0 24px;
  background: linear-gradient(to right, transparent, #fff 16px);

  @media (max-width: 576px) {
    padding: 0 0 0 24px;
  }
  ${modifier};
`);

const ScheduleHourFeedbackIcon = styled(FeedbackIcon)`
  margin-right: 30px;
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    margin-right: 0;
    background-repeat: no-repeat;
    background-position: center;
  }
    @media (max-width: 480px) {
    width: 40px;
    height: 40px;
  }
`;

const AddTimeSlotButtonContainer = styled(Container)`
  width: 100%;
  height: 56px;
  border: none;
  
  &:hover {
    ${Icon} {
      background: ${colors.magenta};
    }
  }
`;

const ButtonAddTimeSlotWrapper = styled.div`
  border-top: solid 1px ${colors.gray200};
  margin: 0 22px;
`;

const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 22px;
  padding: 16px 0;
  border-top: solid 1px ${colors.gray200};
`;

const ChatRecording = withModifiers({
  opacity: () => `
        opacity: 1;
    `
})(
  modifier => styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  line-height: 23px;
  width: 100%;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    button {
      background-image: url(${checkboxHoverImg});
    }
  }
    
  ${modifier};
`);

const EmptyHourWrapper = props => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

const OrganizationNameStub = styled(HeadingH6)`
  padding: 4px 24px;
  border-top: solid 1px transparent;
  border-image: linear-gradient( to right,transparent,transparent 24px,rgba(151,151,151,0.3) 24px,rgba(151,151,151,0.3) calc(100% - 24px),transparent calc(100% - 24px) ) 100% 1 0 stretch;
`;

export const ScheduleDay = observer((props: {
  scrollIntoView?: boolean,
  date: string,
  items: Array<Schedule>,
  scheduleHourWrapper?: React$ComponentType<{ ...$Exact<ScheduleReserved>, children: React.Node }>,
  onClick?: Schedule => mixed
}) => {
  const isMobileDevice = useMediaQuery('(max-width: 768px)');
  const { scheduleContainer } = useContext(ScheduleDaysContext);
  const currentUser = useStore(currentUserStore);
  const scheduleStore = useStore(dataStore);
  // const [activeOnlineButtonScheduleIds, setActiveOnlineButtonScheduleIds] = useState([]);

  const isChatsEnabled = useApplicationFeatureEnabled(FEATURE.CHAT);

  const isMedMeCall = (scheduleItem) => {
    return scheduleItem.online_link && scheduleItem.online_link.includes('med.me');
  };

  const handleOnlineButtonClick = useCallback(async (scheduleItem) => {
    if (window.ReactNativeWebView && scheduleItem.online_link) {
      return chatStore.startCallForWebView(scheduleItem.id, scheduleItem.online_link);
    }

    if (isMedMeCall(scheduleItem) || !scheduleItem.medical_file) {
      window.open(scheduleItem.online_link, "_blank").focus();
    } else {
      if (!!chatStore.voxCall) {
        return false;
      }
      await chatStore.goToChatFromSchedule(scheduleItem, true);
    }
  }, []);

  const handleChatButtonClick = useCallback(async (scheduleItem) => {
    await chatStore.goToChatFromSchedule(scheduleItem, false);
  }, []);

  const { items, onClick, scheduleHourWrapper, scrollIntoView, date } = props;

  const HourWrapper = scheduleHourWrapper || EmptyHourWrapper;

  const wrapperRef = useScrollIntoView(!!scrollIntoView, document.body.getBoundingClientRect().width <= 768 ? 60 : 250, isMobileDevice && scheduleContainer);

  const [organizationNames, setOrganizationNames] = useState({});

  // Фильтрация для слотов с типом "chat", потому что они не имеют времени, и не учитываются при группировке по организациям
  const filterOrganizations = (items = []) => {
    return items.filter((item) => item.type !== 'chat' || item.is_reserved);
  };

  /* Группируем слоты по организациям, с учетом порядка (по времени) */
  const groupOrganizations = (items = []) => {
    const order = [];
    const groupedItems = _.groupBy(items, (item) => {
      if (!order.includes(item.organization)) {
        order.push(item.organization);
      }
      return item.organization;
    });

    return order.map(orgId => {
      return [orgId, groupedItems[orgId]];
    });
  };

  // Строка с именами организаций для плашки с днем недели и датой. Теперь собирается из найденных организаций, а не из мед-карты
  const organizations: string = React.useMemo(() => {
    const organizationsIds = [...new Set(filterOrganizations(items).map(item => item.organization))];
    if (currentUser?.doctor?.organizations?.length > 1) {
      return organizationsIds.filter(Boolean).map(orgId => organizationNames[orgId]).join(" / ");
    }
    return '';
  }, [organizationNames]);

  React.useEffect(() => {
    // Эффект для сбора наименований организаций в расписании, нужен для группироваки если у врача несколько клиник в которых он производит прием.
    const organizationsIds = [...new Set(filterOrganizations(items).map(item => item.organization))];

    (async () => {
      // Для всех уникальных id организаций запрашиваем огранизацию, и добавляем ее имя в мапу
      for (let orgId of organizationsIds) {
        const organization = await apiv2.organizations.getOrganization(orgId);
        if (organization) {
          setOrganizationNames(state => ({
            ...state,
            [orgId]: organization.name
          }));
        } else {
          setOrganizationNames(state => ({
            ...state,
            [orgId]: 'Неизвестная организация'
          }));
        }
      }
    })();
  }, [items]);

  const {
    scheduleInsertModal,
    openScheduleInsertModalWithTime,
    openScheduleInsertModalWithoutTime,
    setDoctor
  } = useScheduleInsert();

  const calculateOnlineButtonsForShow = useCallback(() => {
    // Если уж перестали считать время до показа кнопки, отключаю фильтрацию на активные, что бы voximplant прогревался, если вообще есть онлайн приемы.
    const activeOnlineButtonItemIds = items.filter(item => isOnlineSchedule(item.type)).map(scheduleItem => scheduleItem.id);
    /*.filter(scheduleItem => {
        const momentNow = moment();
        const momentScheduleSlot = moment(`${scheduleItem.date} ${scheduleItem.time}`);
        const momentBeforeScheduleSlot = moment(momentScheduleSlot).subtract(15, 'minutes');
        const momentAfterScheduleSlot = moment(momentScheduleSlot).add(15, 'minutes').add(currentUser.doctor?.schedule_step || 15, 'minutes');

        return momentNow.isBetween(momentBeforeScheduleSlot, momentAfterScheduleSlot);
    })*/

    // setActiveOnlineButtonScheduleIds(activeOnlineButtonItemIds);
    if (activeOnlineButtonItemIds.length) {
      // Прогрев Voximplant что бы звонок из расписания быстро открывался.
      voximplant.getInstance();
    }
  }, [items]);

  useEffect(() => {
    calculateOnlineButtonsForShow();
    const onlineButtonsUpdateIntervalId = setInterval(() => {
      calculateOnlineButtonsForShow();
    }, 1000 * 60);

    return () => {
      clearInterval(onlineButtonsUpdateIntervalId);
    };
  }, []);

  return (
    <React.Fragment>
      <ScheduleDayLayout
        title={formatScheduleDate(date)}
        organizations={organizations}
        showNewAppointmentButton={isSameOrAfterDate(date)}
        ref={wrapperRef}
        date={date}
        onInsertSchedule={() => openScheduleInsertModalWithoutTime(date)}>
        {scheduleStore.isEditSchedule && isSameOrAfterDate(date) && (
          <ContainerFlex>
            <ChatRecording
                modifiers={recordingChatAllowed(date) && 'opacity'}
                onClick={() => changeChatAndUpdateSchedules({ date: props.date, isEnabled: recordingChatAllowed(date)})}>
              <CheckboxLabel
                modifiers={(!recordingChatAllowed(date) && "disabled") || undefined}
              />
              Разрешить запись в чат на этот день
            </ChatRecording>
          </ContainerFlex>
        )}
        {/* Группируем расписание по организациям, что бы отображать плашки, если у врача больше 1 организации */}
        {groupOrganizations(filterOrganizations(items)).map(([organizationId, items], itemIndex, organizationGroups) => {
          const itemsToShow = items;
          return (
            <>
              {
                !!itemsToShow.length && organizationGroups.length > 1 && (
                  <OrganizationNameStub>{ organizationNames[organizationId] }</OrganizationNameStub>
                )
              }
              {
                itemsToShow.map((item, id) => {
                  const closestItemWithAppointment = _.find(
                    items,
                    item => item.is_reserved || item.medical_file,
                    id
                  );
                  if (item.is_reserved) {
                    return (
                      <ScheduleHourContainer key={id} patientIsArrived={item.patient_is_arrived}>
                        <HourWrapper {...item}>
                          <ScheduleHour item={item} onClick={() => onClick && onClick(item)} />
                        </HourWrapper>
                        <ScheduleHourButtonsContainer modifiers={scheduleStore.isEditSchedule && 'smallRight'}>
                          { isChatsEnabled && !scheduleStore.isEditSchedule && isScheduleWithChat(item.type) && (
                            <Tooltip title={item.medical_file ? "Перейти в чат" : "Невозможно перейти в чат с пациентом, так как у него нет мед карты"}>
                              <span>
                                <ScheduleDayButton
                                  onClick={() => handleChatButtonClick(item)}
                                  disabled={!item.medical_file}
                                >
                                  <ChatIcon/>
                                </ScheduleDayButton>
                              </span>
                            </Tooltip>
                          )}
                          {/*перестал проверять, что осталось 15 минут до звонка, чтобы врач заранее видел, что это онлайн прием*/}
                          {(isMedMeCall(item) || (!scheduleStore.isEditSchedule && item.online_link)) && (
                            <Tooltip title={"Открыть онлайн-прием"}>
                              <span>
                                <ScheduleDayButton onClick={() => handleOnlineButtonClick(item)}>
                                  <OnlineIcon/>
                                  <span>Онлайн-прием</span>
                                </ScheduleDayButton>
                              </span>
                            </Tooltip>
                          )}
                          {item.feedback_id && !scheduleStore.isEditSchedule && (
                            <FeedbackProvider id={item.feedback_id}>
                              {feedback => {
                                return feedback &&
                                isNotFutureFeedback(feedback) &&
                                !isFilledFeedbackWithoutResponse(feedback) ? (
                                  <Link
                                    to={`/appointment/${feedback.appointment_id}`}
                                    onClick={() => {
                                      setTimeout(() => {
                                        openFeedbackForView((feedback.id: any));
                                      });
                                    }}>
                                    <ScheduleHourFeedbackIcon
                                      isCompleted={feedback && isCompleted(feedback)}
                                    />
                                  </Link>
                                ) : null;
                              }}
                            </FeedbackProvider>
                          )}
                        </ScheduleHourButtonsContainer>
                      </ScheduleHourContainer>
                    );
                  } else {
                    return (
                      <ScheduleHourStubContainer key={id}>
                        <ScheduleHourStub
                          item={item}
                          available={isSameOrAfterDate(date)}
                          onClick={() => {
                            // $FlowFixMe
                            setDoctor(currentUser && currentUser.doctor ? currentUser.doctor : null);
                            openScheduleInsertModalWithTime(item, closestItemWithAppointment);
                          }}
                        />
                      </ScheduleHourStubContainer>
                    );
                  }
                })
              }
            </>
          )
        })}
        { scheduleStore.isEditSchedule && (process.env.APP_FLAVOR === "telemed") && isSameOrAfterDate(date) && (
          <ButtonAddTimeSlotWrapper>
            <ButtonWithIcon
              onClick={() => changeDataForNewTimeSlot(date)}
              overridesDefaultButton={{
              Container: {
                component: AddTimeSlotButtonContainer,
                props: {
                  modifiers: "noBorder"
                }
              },
              Icon: {
                props: {
                  modifiers: "smallPlus",
                }
              }
            }}>
              Добавить рабочее время
            </ButtonWithIcon>
          </ButtonAddTimeSlotWrapper>
        )}
      </ScheduleDayLayout>
      <ModalPortal>{scheduleInsertModal}</ModalPortal>
    </React.Fragment>
  );
});
