//@flow
import * as React from "react";
import styled from "styled-components";
import { withModifiers } from "with-modifiers";

import { Tabs, DefaultTab } from "../tabs/Tabs";
import { PNormal } from "../styleguide/typography";
import { colors } from "../styleguide/colors";

const Tab = withModifiers({
  active: () => `
            color: ${colors.magenta};
            border-bottom: 1px solid ${colors.magenta};
    `
})(
  modifier => styled(DefaultTab)`
    background-color: transparent;
    border: none;
    border-right: 1px solid ${colors.transparentGray};
    padding: 3px 20px;
    cursor: pointer;

    ${PNormal} {
      line-height: 1.95;
      ${modifier};
    }

    :last-child {
      border: none;
    }
  `
);

const AppointmentHistoryTab = props => (
  <Tab modifiers={props.modifiers} onClick={props.onClick}>
    <PNormal>{props.children}</PNormal>
  </Tab>
);

const tabs = {
  me: { name: "Мои" },
  all: { name: "Все" }
};

type TTab = $Keys<typeof tabs>;

export const AppointmentHistoryTabs = (props: {
  activeTab: TTab,
  handleClickOnTab: TTab => mixed
}) => (
  <Tabs
    {...props}
    tabs={tabs}
    overrides={{
      DefaultTab: {
        component: AppointmentHistoryTab
      }
    }}
  />
);
