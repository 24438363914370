// @flow

import { createEvent, createStore } from "effector";
import * as R from "ramda";

export const initializePaginationStoresAndEvents = (limit: number) => {
  const dataByOffsetStore = createStore<{ number: Array<any> }>({});

  const appendData = createEvent<{ data: Array<any>, offset: number }>("appendData");
  dataByOffsetStore.on(appendData, (state, { data, offset }) => ({ ...state, [offset]: data }));

  const dataStore = dataByOffsetStore.map<Array<any>>(
    R.compose(
      R.flatten,
      R.values
    )
  );

  const offsetStore = createStore<number>(0);
  const setOffset = createEvent<number>("setOffset");
  offsetStore.on(setOffset, (state, payload) => payload);
  const loadMore = createEvent<void>("loadMore");
  offsetStore.on(loadMore, state => state + limit);

  const loadMoreStore = createStore<boolean>(true);
  const setLoadMore = createEvent<boolean>("setLoadMore");
  loadMoreStore.on(setLoadMore, (state, payload) => payload);

  const resetData = createEvent();
  dataByOffsetStore.reset(resetData);

  const clean = () => {
    resetData();
    setOffset(0);
    setLoadMore(true);
  };

  return {
    dataStore,
    appendData,
    offsetStore,
    loadMore,
    loadMoreStore,
    setLoadMore,
    clean
  };
};
