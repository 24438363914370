import styled from "styled-components";

import { colors } from "../styleguide/colors";

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px;
  flex: 1;
  @media (max-width: 768px) {
    padding: 0 40px 102px;
  }
  @media (max-width: 480px) {
    padding: 0 15px 77px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const BackButtonContainer = styled.div`
  padding-top: 24px;
  margin-bottom: 0;
`;

export const Content = styled.div`
  flex: 1;
  box-sizing: border-box;
  margin-top: 29px;
  padding: 32px 30px 37px;
  border-radius: 3px;
  background-color: ${colors.white};
  box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.03);
  @media (max-width: 768px) {
    margin-top: 20px;
    padding: 25px 30px;
  }
  @media (max-width: 480px) {
    margin-top: 15px;
    padding: 15px 20px;
  }
`;

export const Title = styled.div`
  padding: 25px 17px 12px 30px;
  box-sizing: border-box;

  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.03);
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }
`;

export const Filters = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`;
