import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Ticket {
  apiService;
  presetFilterParams;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  getListTickets({
    filter_params,
    meta = {},
    values_params,
    order_by_params,
    annotate_mtm_params
  }) {
    if (this.presetFilterParams && !filter_params.area__in) {
      filter_params = {...filter_params, ...this.presetFilterParams};
    }
    return this.apiService.post(
      `ticket/list`,
      {},
      {
        filter_params,
        current_page: meta.current_page,
        per_page: meta.per_page,
        values_params,
        order_by_params,
        annotate_mtm_params
      }
    );
  }

  postEditedTicket(data) {
    return this.apiService.post(`ticket/update`, {}, data);
  }

  postCreateTicket(data) {
    return this.apiService.post(`ticket`, {}, data);
  }

  sendComment({ ticketId, comment }) {
    return this.apiService.post(`ticket/${ticketId}/comments`, {},  comment);
  }

  getComments(ticketId) {
    return this.apiService.get(`ticket/${ticketId}/comments`);
  }

  postCreateTicketFile(ticketId, formData) {
    return this.apiService.post(`ticket/${ticketId}/files`, {},  formData, {}, false);
  }

  getTicketFiles(ticketId) {
    return this.apiService.get(`ticket/${ticketId}/files`);
  }

  deleteTicketFiles(fileId) {
    return this.apiService.delete(`ticket/files/${fileId}`);
  }
}

export default new Ticket();
