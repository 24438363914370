import React, {useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import _ from 'lodash';
import Spreadsheet from '@blessmesanta/react-spreadsheet';

import * as S from './EmbryosManipulations/EmbryosManipulations.styled';
import {DefaultButton} from '../buttons/DefaultButton';
import {ModalPortal} from '../modal/ModalPortal';
import {ModalFade} from '../modal/ModalFade';
import {ModalBackground} from '../modal/ModalBackground';
import {ModalWithCloseButtonLayout} from '../modal/ModalWithCloseButtonLayout';
import {PrimaryButton} from '../buttons/PrimaryButton';
import {DefaultInput} from '../form-elements/DefaultInput';
import {PhoneInput} from '../form-elements/PhoneInput';
import {colors} from '../styleguide/colors';
import medicalGenomicsStore, { FieldTypes } from '../../stores/medicalGenomicsStore';
import * as SelectStyles from '../my-patients/MyPatientsTableInputs/MyPatientsTableInputsSelect.styled';

import historyIcon from './icons/history.inline.svg';
import Select from 'react-select';
import {
  Checkbox
} from "@mui/material";
import { DateInput } from '../form-elements/legacy/DateInput';
import { READABLE_DATE_FORMAT, SERVER_DATE_FORMAT } from '../../utils/dates';

const HistoryIcon = styled(historyIcon)`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`;

const HistoryIconContainer = styled.div`
  margin-top: 5px;
`;

const SendToMGButtonContainer = styled.div`
  margin-right: 8px;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const DataFields = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-auto-rows: auto;
  flex-wrap: wrap;
  gap: 16px;
`;

const DataFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DataFieldLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  
  span.required {
    color: ${colors.watermelon};
  }
`;

const InputContainer = styled.div`
    width: 100%;
`;

const InputError = styled.span`
  font-size: 12px;
  color: ${colors.watermelon};
`;

const ErrorFields = styled.span`
  font-size: 12px;
  color: ${colors.watermelon};
  align-self: center;
`;

const CheckboxContainer = styled.div`
    width: 100%;
    padding: 0 0 1px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 2px solid #ebebeb;
    font-family: Graphik;
    background: ${props => props.isDisabled ? 'rgba(239,239,239,0.3)' : '#fff'};
    display: flex;
    align-items: center;
`;

const selectStyles = {
  ...SelectStyles.styles,
  menu: provided => ({
    ...provided,
    minWidth: "60px",
    width: "100%",
    borderRadius: 0,
    boxShadow: "0px 2px 17px rgb(91 91 91 / 26%)",
    color: colors.black,
    marginTop: "1px",
    fontSize: 15,
    maxHeight: "220px",
    overflow: "auto",
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 2
  }),
  valueContainer: (provided, { selectProps: { menuIsOpen } }) => ({
    ...provided,
    padding: 0,
    flexWrap: "wrap",
    overflow: menuIsOpen ? "hidden" : "initial"
  }),
  singleValue: provided => ({
    ...provided,
    position: "unset",
    top: "unset",
    transform: "unset",
    maxWidth: "100%",
    background: colors.concrete,
    padding: "6.5px 8px",
    borderRadius: "4px",
    color: colors.black,
    fontSize: 14,
    whiteSpace: "nowrap"
  }),
  multiValue: provided => ({
    ...provided,
    position: "unset",
    top: "unset",
    transform: "unset",
    maxWidth: "100%",
    background: colors.concrete,
    padding: "6.5px 8px",
    borderRadius: "4px",
    color: colors.black,
    fontSize: 14,
  }),
  multiValueLabel: provided => ({
    ...provided,
    whiteSpace: 'wrap'
  }),
  placeholder: (styles) => ({
    ...styles,
    margin: 0
  }),
  control: (styles, state) => ({
    ...styles,
    background: state.isDisabled ? 'rgba(239, 239, 239, 0.3)' : '#fff',
    fontSize: "17px",
    color: "#262626",
    width: "100%",
    padding: "3px 4px 4px 18px",
    boxSizing: "border-box",
    borderRadius: "4px",
    border: "2px solid #ebebeb",
    fontFamily: "Graphik",
    WebkitTransition: "border-color 200ms",
    transition: "border-color 200ms",

    ":hover": {
      borderColor: "#ddd",
      boxShadow: "0 0 0 100px rgba(0,0,0,0.01) inset"
    }
  }),
};

const windowVerticalPadding = 114;

export const fromFieldToSelectOptions = (field) => {
  return field?.options?.map(option => (Object.entries(option).reduce((acc, next) => {
    return ({
      label: next[1],
      value: next[0]
    });
  }, {}))) || [];
};

export const MGInfoModal = observer((props) => {
  const { folderId } = props;
  const $containerRef = useRef(null);

  useEffect(() => {
    if (medicalGenomicsStore.isFormOpen && $containerRef.current) {
      $containerRef.current.style.height = `${window.innerHeight - 2 * windowVerticalPadding}px`;
    }
  }, [medicalGenomicsStore.isFormOpen]);

  useEffect(() => {
    if (!medicalGenomicsStore.hasFormData()) {
      const initialFormData = Object.entries(medicalGenomicsStore.fieldsDetails).reduce((acc, [fieldName, field]) => {
        if (field.type === FieldTypes.SELECT) {
          const selectOptions = fromFieldToSelectOptions(field);
          return { ...acc, [fieldName]: selectOptions.find(option => option.value === field.default) };
        }
        if (field.type === FieldTypes.MULTISELECT) {
          const selectOptions = fromFieldToSelectOptions(field);
          return { ...acc, [fieldName]: selectOptions.filter(option => field.default.includes(option.value)) };
        }
        if (field.type === FieldTypes.CHECKBOX) {
          return { ...acc, [fieldName]: field.default };
        }
        return { ...acc, [fieldName]: field.default.toString() };
      }, {});

      medicalGenomicsStore.setFormData(initialFormData);
    }
  }, [medicalGenomicsStore.fieldsDetails]);

  const getFieldByType = (fieldName, field) => {
    const gridTemplate = medicalGenomicsStore.gridTemplate;
    const fieldIsDisabled = medicalGenomicsStore.isEditingDisabled || !(field.is_required && !field.default) && !field.is_editable;
    const fieldValue = medicalGenomicsStore.getFieldValue(fieldName);

    switch (field.type) {
      case FieldTypes.TEXT:
        return (
          <DataFieldContainer key={fieldName} style={gridTemplate ? {
            gridArea: fieldName
          } : {}}>
            <DataFieldLabel>{field.label}{field.is_required && <span className="required">*</span>}</DataFieldLabel>
            <DefaultInput value={fieldValue || ''} disabled={fieldIsDisabled} handleChange={(value) => {
              medicalGenomicsStore.setFieldError(fieldName, field.is_required && !value);
              medicalGenomicsStore.setFieldValue(fieldName, value);
            }} overrides={{
              Container: {
                component: InputContainer
              }
            }}/>
            {
              medicalGenomicsStore.hasFieldError(fieldName) && (
                <InputError>{medicalGenomicsStore.getFieldError(fieldName)}</InputError>
              )
            }
          </DataFieldContainer>
        );
      case FieldTypes.DATE:
        return (
          <DataFieldContainer key={fieldName} style={gridTemplate ? {
            gridArea: fieldName
          } : {}}>
            <DataFieldLabel>{field.label}{field.is_required && <span className="required">*</span>}</DataFieldLabel>
            <DateInput value={fieldValue || ''} valueDateFormat={(fieldValue || '').includes("-") ? SERVER_DATE_FORMAT : READABLE_DATE_FORMAT} disabled={fieldIsDisabled} handleChange={(value) => {
              medicalGenomicsStore.setFieldError(fieldName, field.is_required && !value);
              medicalGenomicsStore.setFieldValue(fieldName, value);
            }} overrides={{
              Container: {
                component: InputContainer
              }
            }}/>
            {
              medicalGenomicsStore.hasFieldError(fieldName) && (
                <InputError>{medicalGenomicsStore.getFieldError(fieldName)}</InputError>
              )
            }
          </DataFieldContainer>
        );
      case FieldTypes.PHONE:
        return (
          <DataFieldContainer key={fieldName} style={gridTemplate ? {
            gridArea: fieldName
          } : {}}>
            <DataFieldLabel>{field.label}{field.is_required && <span className="required">*</span>}</DataFieldLabel>
            <PhoneInput icon="" value={fieldValue || ''} disabled={fieldIsDisabled} handleChange={(value) => {
              medicalGenomicsStore.setFieldError(fieldName, field.is_required && !value);
              medicalGenomicsStore.setFieldValue(fieldName, value);
            }} overrides={{
              Container: {
                component: InputContainer
              }
            }}/>
            {
              medicalGenomicsStore.hasFieldError(fieldName) && (
                <InputError>{medicalGenomicsStore.getFieldError(fieldName)}</InputError>
              )
            }
          </DataFieldContainer>
        );
      case FieldTypes.SELECT:
        return (
          <DataFieldContainer key={fieldName} style={gridTemplate ? {
            gridArea: fieldName
          } : {}}>
            <DataFieldLabel>{field.label}{field.is_required && <span className="required">*</span>}</DataFieldLabel>
            <Select
              isClearable
              isSearchable
              blurInputOnSelect
              value={fieldValue}
              placeholder={field.label}
              styles={selectStyles}
              options={fromFieldToSelectOptions(field)}
              isMulti={false}
              isDisabled={fieldIsDisabled}
              tabSelectsValue={false}
              onChange={(newValue) => {
                medicalGenomicsStore.setFieldError(fieldName, field.is_required && !newValue);
                medicalGenomicsStore.setFieldValue(fieldName, newValue);
              }}
              menuShouldScrollIntoView={false}
              noOptionsMessage={() => "Значения не найдены"}
            />
            {
              medicalGenomicsStore.hasFieldError(fieldName) && (
                <InputError>{medicalGenomicsStore.getFieldError(fieldName)}</InputError>
              )
            }
          </DataFieldContainer>
        );
      case FieldTypes.MULTISELECT:
        return (
          <DataFieldContainer key={fieldName} style={gridTemplate ? {
            gridArea: fieldName
          } : {}}>
            <DataFieldLabel>{field.label}{field.is_required && <span className="required">*</span>}</DataFieldLabel>
            <Select
              isClearable
              isSearchable
              blurInputOnSelect
              value={fieldValue}
              placeholder={field.label}
              styles={selectStyles}
              options={fromFieldToSelectOptions(field)}
              isMulti={true}
              isDisabled={fieldIsDisabled}
              tabSelectsValue={false}
              onChange={(newValue) => {
                medicalGenomicsStore.setFieldError(fieldName, field.is_required && !newValue);
                medicalGenomicsStore.setFieldValue(fieldName, newValue instanceof Array ? newValue : [newValue]);
              }}
              menuShouldScrollIntoView={false}
              noOptionsMessage={() => "Значения не найдены"}
            />
            {
              medicalGenomicsStore.hasFieldError(fieldName) && (
                <InputError>{medicalGenomicsStore.getFieldError(fieldName)}</InputError>
              )
            }
          </DataFieldContainer>
        );
      case FieldTypes.CHECKBOX:
        return (
          <DataFieldContainer key={fieldName} style={gridTemplate ? {
            gridArea: fieldName
          } : {}}>
            <DataFieldLabel>{field.label}{field.is_required && <span className="required">*</span>}</DataFieldLabel>
            <CheckboxContainer isDisabled={medicalGenomicsStore.isEditingDisabled || (!field.is_required && !field.is_editable)}>
              <Checkbox
                style={{
                  width: '42px',
                }}
                disabled={medicalGenomicsStore.isEditingDisabled || (!field.is_required && !field.is_editable)}
                checked={fieldValue || false}
                onChange={() => {
                  medicalGenomicsStore.setFieldValue(fieldName, !medicalGenomicsStore.getFieldValue(fieldName));
                }}
              />
              <span>{ medicalGenomicsStore.getFieldValue(fieldName) ? 'Да' : 'Нет' }</span>
            </CheckboxContainer>
          </DataFieldContainer>
        );
      default:
        return (
          <DataFieldContainer key={fieldName} style={gridTemplate ? {
            gridArea: fieldName
          } : {}}>
            <DataFieldLabel>{field.label}{field.is_required && <span className="required">*</span>}</DataFieldLabel>
            <DefaultInput value={fieldValue || ''} disabled={fieldIsDisabled} handleChange={(value) => {
              medicalGenomicsStore.setFieldError(fieldName, field.is_required && !value);
              medicalGenomicsStore.setFieldValue(fieldName, value);
            }} overrides={{
              Container: {
                component: InputContainer
              }
            }}/>
            {
              medicalGenomicsStore.hasFieldError(fieldName) && (
                <InputError>{medicalGenomicsStore.getFieldError(fieldName)}</InputError>
              )
            }
          </DataFieldContainer>
        );
    }
  };

  return (
    <ModalPortal>
      <ModalFade>
        {medicalGenomicsStore.isFormOpen && (
          <ModalBackground>
            <ModalWithCloseButtonLayout handleClose={() => {
              medicalGenomicsStore.closeForm();
            }}>
                <S.Container ref={$containerRef}>
                  <S.Title>{medicalGenomicsStore.isEditingDisabled ? "Данные о заказе" : "Таблица отправляемых биоптатов"}</S.Title>
                  <FormContainer>
                    <DataFields style={medicalGenomicsStore.gridTemplate ? {
                      gridTemplateAreas: medicalGenomicsStore.gridTemplate,
                    } : {}}>
                      {
                        medicalGenomicsStore.orderData && Object.entries(medicalGenomicsStore.fieldsDetails).map(([key, field]) => (
                          getFieldByType(key, field)
                        ))
                      }
                    </DataFields>
                    <DataFieldContainer>
                      <DataFieldLabel>{medicalGenomicsStore.isEditingDisabled ? "Таблица эмбрионов на исследовании" : "Таблица отправляемых эмбрионов"}</DataFieldLabel>
                      <S.TableContainer>
                        <Spreadsheet
                          data={medicalGenomicsStore.embryoTableValues}
                          hideRowIndicators
                          columnLabels={Object.values(medicalGenomicsStore.embryoTableData)}
                          ColumnIndicator={({ label }) => (
                            <S.ColumnIndicator>{label}</S.ColumnIndicator>
                          )}
                          className="Spreadsheet-embryos-to-mg"
                        />
                      </S.TableContainer>
                    </DataFieldContainer>
                    {
                      !medicalGenomicsStore.isEditingDisabled && (
                        <DataFieldContainer>
                          {
                            medicalGenomicsStore.formErrorFields.length > 0 && (
                              <ErrorFields>
                                Некорректно заполнены поля: {medicalGenomicsStore.formErrorFields.join(", ")}
                              </ErrorFields>
                            )
                          }
                          <S.SaveButtonContainer>
                            <PrimaryButton isLoading={medicalGenomicsStore.isOrderSending} onClick={async () => {
                              await medicalGenomicsStore.sendOrder();
                              await medicalGenomicsStore.fetchOrderListForFolder(folderId);
                            }}>Отправить данные в МG</PrimaryButton>
                          </S.SaveButtonContainer>
                        </DataFieldContainer>
                      )
                    }
                  </FormContainer>
                </S.Container>
            </ModalWithCloseButtonLayout>
          </ModalBackground>
        )}
      </ModalFade>
    </ModalPortal>
  );
});

export const SendToMG = observer((props) => {
  const { folderId } = props;

  return (
    <>
      <SendToMGButtonContainer>
        <DefaultButton
          isLoading={medicalGenomicsStore.isOrderCreating}
          onClick={async () => {
            await medicalGenomicsStore.createOrder(folderId);
          }}
          overrides={{
            Icon: {
              component: HistoryIcon
            },
            IconContainer: {
              component: HistoryIconContainer
            }
          }}>
          Отправить в MG
        </DefaultButton>
      </SendToMGButtonContainer>
    </>
  );
});