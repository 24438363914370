// @flow
import { createStore, createEvent, combine, type Store } from "effector";
import type { TDrugId } from "../../domain/entities/Drug";
import { createGate } from "effector-react";
import { isOverflowVisibilityArea } from "../../utils/isOverflowVisibilityArea";

export type CellCoordinates = { drugId?: TDrugId, column?: number };

export const focusedCellCoordinatesStore = createStore<CellCoordinates>({});

export const isFocusedStore = (coordinates: CellCoordinates) =>
  focusedCellCoordinatesStore.map<boolean>(
    focusedCellCoordinates =>
      focusedCellCoordinates.drugId === coordinates.drugId &&
      focusedCellCoordinates.column === coordinates.column
  );

export const isRightOfFocusStore = (coordinates: CellCoordinates, maxColumn: number) =>
  focusedCellCoordinatesStore.map<boolean>(
    focusedCellCoordinates =>
      (typeof coordinates.column === "number" &&
        focusedCellCoordinates.drugId === coordinates.drugId &&
        focusedCellCoordinates.column !== maxColumn &&
        focusedCellCoordinates.column === coordinates.column - 1) ||
      false
  );

export const isLeftOfFocusStore = (coordinates: CellCoordinates) =>
  focusedCellCoordinatesStore.map<boolean>(
    focusedCellCoordinates =>
      (typeof coordinates.column === "number" &&
        focusedCellCoordinates.drugId === coordinates.drugId &&
        focusedCellCoordinates.column !== 0 &&
        focusedCellCoordinates.column === coordinates.column + 1) ||
      false
  );

export const setFocused = createEvent<CellCoordinates>();
export const clearFocus = createEvent<any>();

export const incrementColumn = createEvent<number>();
export const decrementColumn = createEvent<void>();

export const incrementDrugId = createEvent<{ drugOrder: Array<TDrugId> }>();
export const decrementDrugId = createEvent<{ drugOrder: Array<TDrugId> }>();

focusedCellCoordinatesStore
  .on(setFocused, (state, params) => params)
  .on(incrementColumn, (state, maxColumn) =>
    state.column === maxColumn - 1 ? state : { ...state, column: (state.column || 0) + 1 }
  )
  .on(decrementColumn, state =>
    state.column === 0 ? state : { ...state, column: (state.column || 0) - 1 }
  )
  .on(decrementDrugId, (state, { drugOrder }) => {
    const index = drugOrder.indexOf(state.drugId);
    return index === 0
      ? state
      : {
          ...state,
          drugId: drugOrder[index - 1]
        };
  })
  .on(incrementDrugId, (state, { drugOrder }) => {
    const index = drugOrder.indexOf(state.drugId);
    return index === drugOrder.length - 1
      ? state
      : {
          ...state,
          drugId: drugOrder[drugOrder.indexOf(state.drugId) + 1]
        };
  })
  .reset(clearFocus);

export const rightOfFocusWidthGate = createGate<any>();

export const leftOfFocusWidthGate = createGate<any>();

export const focusedPositionGate = createGate<ClientRect>();

export const visibilityAreaGate = createGate<ClientRect>();

export const isLastVisibleColumnStore: Store<boolean> = combine(
  visibilityAreaGate.state,
  focusedPositionGate.state,
  rightOfFocusWidthGate.state,
  (visibilityArea, position, rightOfFocusWidth) => {
    return (
      (visibilityArea &&
        position &&
        typeof rightOfFocusWidth === "number" &&
        visibilityArea.right - position.right < rightOfFocusWidth) ||
      false
    );
  }
);

export const moveScrollRight = createEvent<number>();

isLastVisibleColumnStore.watch(incrementColumn, isLastVisibleColumn => {
  if (isLastVisibleColumn && typeof rightOfFocusWidthGate.current === "number") {
    moveScrollRight(rightOfFocusWidthGate.current);
  }
});

export const isFirstVisibleColumnStore: Store<boolean> = combine(
  visibilityAreaGate.state,
  focusedPositionGate.state,
  leftOfFocusWidthGate.state,
  (visibilityArea, position, leftOfFocusWidth) => {
    return (
      (visibilityArea &&
        position &&
        typeof leftOfFocusWidth === "number" &&
        position.left - visibilityArea.left < leftOfFocusWidth) ||
      false
    );
  }
);

export const moveScrollLeft = createEvent<number>();

isFirstVisibleColumnStore.watch(decrementColumn, isFirstVisibleColumn => {
  if (isFirstVisibleColumn && typeof leftOfFocusWidthGate.current === "number") {
    moveScrollLeft(leftOfFocusWidthGate.current);
  }
});

const isOverflowVisibilityAreaStore: Store<boolean> = combine(
  focusedPositionGate.state,
  visibilityAreaGate.state,
  (position, visibilityArea) => {
    return (
      (visibilityArea && position && isOverflowVisibilityArea(position, visibilityArea)) || false
    );
  }
);

isOverflowVisibilityAreaStore.watch(isOverflowVisibilityArea => {
  if (isOverflowVisibilityArea) {
    clearFocus();
  }
});
