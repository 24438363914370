// @flow
import React from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import Grid from '@mui/material/Grid';
import { Paper, Typography } from "@mui/material";
import { ProfileLayout } from "../layouts/ProfileLayout";
import popularLayoutsStore from '../../stores/popularLayoutsStore';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { SelectLayout } from './SelectLayout';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    padding-bottom: 70px;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const DoctorPopularLayoutsPage = observer(() => {
  popularLayoutsStore.initialize();

  return (
    <ProfileLayout activeIndexMenu={4}>
      <Container>
        {
          !popularLayoutsStore.isInitialized ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '100px' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography fontSize={20}>
                Популярные шаблоны
              </Typography>
              <Paper style={{
                padding: '20px',
                display: "flex",
                gap: "20px",
                flexDirection: "column"
              }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                  <Grid item style={{
                    width: '100%'
                  }}>
                    <SelectLayout/>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )
        }
      </Container>
    </ProfileLayout>
  );
});
