// @flow
import * as React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import { DefaultInput } from "./DefaultInput";
import { BaseStyledInput } from "./BaseStyledInput";

const StyledInput = styled(BaseStyledInput)`
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 51px;

  ::placeholder {
    opacity: 1;
    color: ${colors.darkBlack};
  }
`;

export const InputWithIconContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Icon = styled.div`
  width: 26px;
  height: 26px;
  background: url(${props => props.icon}) no-repeat;
  background-size: contain;

  position: absolute;
  top: 16px;
  left: 13px;
`;

export const InputWithIcon = (props: {|
  value: string | number,
  icon: string,
  type?: "text" | "password",
  placeholder?: string | number,
  handleChange: string => mixed,
  error?: string | boolean
|}) => {
  const { icon, ...defaultIconProps } = props;
  const IconComponent = () => <Icon icon={icon} />;

  return (
    <DefaultInput
      {...defaultIconProps}
      overrides={{
        Container: {
          component: InputWithIconContainer
        },
        Icon: {
          component: IconComponent
        },
        Input: {
          component: StyledInput
        }
      }}
    />
  );
};
