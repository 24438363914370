import styled from "styled-components";

// import { Link } from "react-router-dom";

import { colors } from "../../styleguide/colors";
import fileIcon from "../icons/file.svg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;

  &:hover {
    & .sc-link-badge {
      display: inline-block;
    }
  }
`;

export const Badge = styled.i`
  display: inline-block;
  box-sizing: border-box;
  font-size: 11px;
  margin-left: 8px;
  padding: 5px 8px;
  border-radius: 15px;
  border: 1px solid ${colors.gray700};
  color: ${colors.gray700};
`;

export const LinkBadge = styled.span`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  border: 1px solid ${colors.gray800};
  border-radius: 3px;
  background: ${colors.white};
  padding: 6px;
  cursor: pointer;
`;

export const FileIcon = styled.i`
  display: inline-block;
  width: 11px;
  height: 14px;
  background: url(${fileIcon});
  background-repeat: no-repeat;
  background-size: contain;
`;
