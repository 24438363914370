// @flow
import { ChartComponent } from "../common/charts/ChartComponent";
import { transformDiagramDataPercentage } from "../../stores/reviewStatisticsStore";
import { Box, Paper, Stack } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import _ from "lodash";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import organizationsStore from "../../stores/organizationsStore";
import * as yup from "yup";
import { filterValidation } from "../common/widgets-components-common/constants";
import { AbsoluteLoader } from "../loader/Loader";
import surveyResultStore from "../../stores/surveyResultStore";
import specialitiesStore from "../../stores/specialitiesStore";

const NAME_WIDGET = 'widgetSurveyEmployeesENPS';

export const transformTableData = (data, organization_ids, organizations, speciality) => {
  const returnData = [];
  if (Array.isArray(data)) {
    const organizationsData = _.groupBy(data, 'organization_id');
    Object.keys(organizationsData).forEach((organizationId) => {
      const specializationsData = _.groupBy(organizationsData[organizationId], 'speciality_id');
      Object.keys(specializationsData).forEach((specializationId) => {
        if (organizationId && specializationId) {
          let lengthCritics = 0;
          let lengthSupporters = 0;
          specializationsData[specializationId].forEach((item) => {
            if (item.value > 8) {
              lengthSupporters++;
            }
            if (item.value < 7) {
              lengthCritics++;
            }
          })
          returnData.push(
            {
              organization: organizations.find((organization) => parseInt(organization.id) === parseInt(organizationId))?.name,
              speciality: speciality.find((speciality) => parseInt(speciality.id) === parseInt(specializationId))?.name,
              enps: (lengthSupporters / specializationsData[specializationId].length * 100) - (lengthCritics / specializationsData[specializationId].length * 100),
              doctorCount: specializationsData[specializationId].length,
              count: parseFloat((specializationsData[specializationId].reduce((acc, item) => {
                acc += parseFloat(item.value);
                return acc;
              }, 0) / specializationsData[specializationId].length).toFixed(1))
            }
          )
        }
      })
    })
  }
  return returnData;
};

export const FILTERS_FIELDS_WIDGET_SURVEY_EMPLOYEES_ENPS = () => [
  {
    type: 'text',
    name: 'label',
    label: 'Название графика',
    xs: 12,
  },
  {
    type: 'select',
    name: 'organization_ids',
    label: 'Организации',
    multiple: true,
    needSelectAll: true,
    valueName: 'id',
    labelName: 'name',
    xs: 12,
    options: organizationsStore.organizations,
  },
  {
    type: 'select',
    name: 'specialities_ids',
    label: 'Специальности',
    multiple: true,
    needSelectAll: true,
    valueName: 'id',
    labelName: 'name',
    xs: 12,
    options: specialitiesStore.specialities,
  },
];

export const WidgetSurveyEmployeesENPS = observer((props: {
  filtersData: () => {},
  rewriteFilters: () => {},
  keyWidget: String
}) => {

  const styledPaper = {
    padding: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
  const [filterFields, setFilterFields] = useState({});
  const [tableData, setTableData] = useState([]);
  const [avgENPS, setAvgENPS] = useState(0);
  const [chartsData, setChartsData] = useState();
  const [label, setLabel] = useState('ENPS по рекомендации как работодателей');
  const [isInitialized, setIsInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const validateSchema = yup.object().shape({
    organization_ids: yup.array().required("организации").min(1, "организации"),
    // specialities_ids: yup.array().required("специализации").min(1, "специализации")
  });


  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
      await specialitiesStore.getSpecialities();
      if (getLocalStorage(NAME_WIDGET + props.keyWidget)) {
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), getLocalStorage(NAME_WIDGET + props.keyWidget));
        localStorage.removeItem(NAME_WIDGET + props.keyWidget);
      }
      const filterDiagramLocaleStorage = getLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''));
      if (filterDiagramLocaleStorage && moment(filterDiagramLocaleStorage.date_end).isBefore(moment())) {
        filterDiagramLocaleStorage.date_end = moment();
      }
      const filters = props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
      if (filters.label) {
        setLabel(filters.label);
      }
      setFiltersDataDiagram(filters)
      setFilterFields([...FILTERS_FIELDS_WIDGET_SURVEY_EMPLOYEES_ENPS()]);
      setIsInitialized(true);
    })()
  }, []);

  const avgENPSFunc = (data) => {
    let lengthCritics = 0;
    let lengthSupporters = 0;
    data.forEach((item) => {
      if (item.value > 8) {
        lengthSupporters++;
      }
      if (item.value < 7) {
        lengthCritics++;
      }
    })
    setAvgENPS((lengthSupporters / data.length * 100) - (lengthCritics / data.length * 100),)
  }

  useEffect(() => {
    (async () => {
      if (_.size(filtersDataDiagram)) {
        setLoading(true);
        setLocalStorage(NAME_WIDGET + props.keyWidget + location.pathname.replaceAll('/', ''), filtersDataDiagram);
        if (filtersDataDiagram.label) {
          setLabel(filtersDataDiagram.label);
        }
        const response = await surveyResultStore.getENPSDiagram(filtersDataDiagram);
        if (response.error) {
          filterValidation({ validateSchema, filtersDataDiagram, setErrors });
        }
        if (response) {
          avgENPSFunc(response)
          setChartsData(transformDiagramDataPercentage({data: response, valueName: 'ces', interval: filtersDataDiagram.interval || 'day', isCollapseData: filtersDataDiagram.isCollapse}));
          setTableData(transformTableData(response, filtersDataDiagram.organization_ids, organizationsStore.organizations, specialitiesStore.specialities));
          setLoading(false);
        }
      }
    })()
  }, [filtersDataDiagram]);

  useEffect(() => {
    if (props.rewriteFilters) {
      setFiltersDataDiagram({...filtersDataDiagram, ...props.rewriteFilters});
    }
  }, [props.rewriteFilters]);

  const submitHandler = (filters, callback) => {
    setErrors([]);
    setFiltersDataDiagram(filters);
    callback();
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'organization', //access nested data with dot notation
        header: 'Название организации',
      },
      {
        accessorKey: 'speciality', //access nested data with dot notation
        header: 'Название специализации',
      },
      {
        accessorKey: 'count',
        header: 'Средняя оценка',
        aggregationFn: 'mean',
        Cell: ({ row }) => row.original.count,
        AggregatedCell: ({ cell, table }) => (
          <>
            <Box
              sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            >
              {cell.getValue().toFixed(1)}
            </Box>
          </>
        ),
        Footer: () => (
          <Stack>
            <Box color="primary.main">Итого: {(tableData.reduce((acc, item) => {
              acc += item.count;
              return acc;
            }, 0) / tableData.length).toFixed(1)} </Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'doctorCount',
        header: 'Количество врачей',
        aggregationFn: 'sum',
        Cell: ({ row }) => row.original.doctorCount,
        AggregatedCell: ({ cell, table }) => (
          <>
            <Box
              sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            >
              {cell.getValue()}
            </Box>
          </>
        ),
        Footer: () => (
          <Stack>
            <Box color="primary.main">Итого: {(tableData.reduce((acc, item) => {
              acc += item.doctorCount;
              return acc;
            }, 0))} </Box>
          </Stack>
        ),
      },
      {
        accessorKey: 'enps', //access nested data with dot notation
        header: 'eNPS',
        aggregationFn: 'mean',
        Cell: ({ row }) => `${row.original.enps.toFixed(1)}%`,
        AggregatedCell: ({ cell, table }) => (
          <>
            <Box
              sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            >
              {cell.getValue().toFixed(1)}%
            </Box>
          </>
        ),
        Footer: () => (
          <Stack>
            <Box color="primary.main">Итого: {avgENPS?.toFixed(1)}% </Box>
          </Stack>
        ),
      },
    ],
    [tableData],
  )

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    enableFullScreenToggle: false,
    enableGrouping: true,
    initialState: {
      density: 'compact',
      grouping: ['organization'],
      pagination: {pageIndex: 0, pageSize: 300}
    },
    localization: MRT_Localization_RU,
    isMultiSortEvent : ( ) => true,
  });

  return (
    <Paper style={styledPaper}>
      {filterFields && (
        <LabelWithFiltersModal
          fullWidth={true}
          type={"filter"}
          filtersData={filtersDataDiagram}
          filtersFields={filterFields}
          label={label}
          textButtonFilter={"Применить"}
          submitHandler={submitHandler}
        />
      )}
      {(loading || !isInitialized) && <AbsoluteLoader />}
      {isInitialized && (
        <>
          {tableData && (
            <div style={{
              maxHeight: '100%',
              overflow: "auto"
            }} className={'material-table'}>
              <MaterialReactTable table={table} />
            </div>
          )}
        </>
      )}
    </Paper>
  )
});
