// @flow
import { useLayoutEffect, useRef } from "react";

export const useAutoFocusRef = (elementRef = null, additionalDependencies = {}) => {
  const newRef = useRef<?HTMLElement>(null);
  const ref = elementRef ? elementRef : newRef;

  useLayoutEffect(() => {
    ref.current && ref.current.focus();
  }, [ref, additionalDependencies]);

  return ref;
};
