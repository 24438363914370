// @flow
import React from "react";
import { observer } from "mobx-react";
import * as moment from "moment";
import { Stack } from "@mui/material";
import { CorporateCard } from "../../components/CorporateCard";
import { CorporateWrapper } from "../../styled/CorporateWrapper.styled";
import { CorporateUserCard } from "../../components/CorporateUserCard";
import corporateEmployeesStore from "../../stores/corporateEmployeesStore";
import { CorporateSubTitle } from "../../styled/CorporateTypography.styled";

export const CorporateBirthdaysWidget = observer(() => {
  const users = corporateEmployeesStore.allEmployeesData.slice(0, 3);

  return (
    <CorporateWrapper modifiers={"withoutPadding"}>
      <CorporateCard title="Дни рождения" enableCollapseContent>
        <Stack spacing={2}>
          {users.map((user, index) => (
            <CorporateUserCard
              key={index}
              src={user.photo}
              user={user.contact_information}
              isWithInitials>
              <CorporateSubTitle modifiers={["md", "gray"]}>
                {moment(user.contact_information.birthdate).format("DD.MM")}
              </CorporateSubTitle>
              <CorporateSubTitle modifiers={["md", "gray"]}>
                {user.working_information.specializations[0]}
              </CorporateSubTitle>
            </CorporateUserCard>
          ))}
        </Stack>
      </CorporateCard>
    </CorporateWrapper>
  );
});
