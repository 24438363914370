import styled from "styled-components";

import { colors } from "../../styleguide/colors";

import starIcon from "../icons/star.inline.svg";

import { headerHeight } from "./ServicesTree.styled";

export const itemHeight = 72;

export const Root = styled.div`
  height: calc(100% - ${headerHeight}px);
  overflow: hidden;
`;

export const Item = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 12px 60px 12px 24px;
  height: ${itemHeight}px;
`;

export const Caption = styled.div`
  height: 18px;
  border-radius: 4px;
  background: ${colors.gray050};
`;

export const ShortCaption = styled(Caption)`
  width: 105px;
  margin-bottom: 8px;
`;

export const LongCaption = styled(Caption)`
  width: 307px;
`;

export const Star = styled(starIcon)`
  position: absolute;
  top: 50%;
  right: 27px;
  transform: translateY(-50%);

  path {
    fill: ${colors.gray050};
    stroke: ${colors.gray050};
  }
`;
