import React, { useEffect } from "react";

import savingIcon from "./icons/saving.svg";
import savedIcon from "./icons/saved.svg";
import errorIcon from "./icons/error.svg";

import * as S from "./TableSaveIndicator.styled";

export const TableSaveIndicator = ({ saving, error, hideDelay = 3000 }) => {
  const savingHideTimeout = React.useRef();
  const errorHideTimeout = React.useRef();
  const lastError = React.useRef();
  const [savingVisible, setSavingVisible] = React.useState(saving);
  const [errorVisible, setErrorVisible] = React.useState(saving);

  useEffect(() => {
    if (!error && saving && !savingVisible && !errorVisible) {
      setSavingVisible(true);
    }
  }, [saving, error, errorVisible, savingVisible]);

  useEffect(() => {
    if (error && !errorVisible) {
      setSavingVisible(false);

      lastError.current = error;
      setErrorVisible(true);
    }
  }, [error, errorVisible]);

  useEffect(() => {
    if (!saving && savingVisible) {
      savingHideTimeout.current = setTimeout(() => {
        setSavingVisible(false);
      }, hideDelay);
    }

    return () => {
      clearTimeout(savingHideTimeout.current);
    };
  }, [saving, savingVisible, hideDelay]);

  useEffect(() => {
    if (!error && errorVisible) {
      errorHideTimeout.current = setTimeout(() => {
        setErrorVisible(false);
      }, hideDelay);
    }

    return () => {
      clearTimeout(errorHideTimeout.current);
    };
  }, [error, errorVisible, hideDelay]);

  return (
    <>
      <S.Wrapper style={{ display: errorVisible ? "flex" : "none" }}>
        <img src={errorIcon} />
        <S.Text>{lastError.current}</S.Text>
      </S.Wrapper>
      <S.Wrapper style={{ display: saving && savingVisible ? "flex" : "none" }}>
        <S.RotatedImage src={savingIcon} />
        <S.Text>Сохранение</S.Text>
      </S.Wrapper>
      <S.Wrapper style={{ display: !saving && savingVisible ? "flex" : "none" }}>
        <img src={savedIcon} />
        <S.Text>Сохранено</S.Text>
      </S.Wrapper>
    </>
  );
};
