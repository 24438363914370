import styled, { css } from "styled-components";
import TextareaAutosize from "react-autosize-textarea";

import { colors } from "../../styleguide/colors";

import { DefaultToastBody } from "../../toast/toast";

import crossIcon from "../icons/cross-reminder.inline.svg";

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  border-radius: 8px;
  font-size: 15px;
  max-width: 600px;

  ${({ edit }) =>
    !edit &&
    css`
      cursor: pointer;
      &:hover {
        background: ${colors.gray900};
      }
    `}
`;

export const ContainerWithIcon = styled.div`
  display: flex;
  & svg {
      padding-right: 10px;
  }
`

export const Title = styled.div`
  line-height: 23px;
  color: ${colors.silver};

  ${Container}:hover & {
    color: ${colors.gray700};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${colors.gray700};
    `}
`;

export const Form = styled.div`
  display: none;
  align-items: flex-start;
  line-height: 23px;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  ${({ visible }) =>
    visible &&
    css`
      display: flex;
    `}
`;

export const Content = styled.div`
  line-height: 23px;
`;

export const Text = styled(TextareaAutosize)`
  width: 348px;
  padding: 0;
  border: none;
  outline: none;
  resize: none;
  background: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: 23px;
  @media (max-width: 768px) {
    width: 100%;
  }

  &::placeholder {
    color: ${colors.silver};
  }
`;

export const Item = styled.div`
  position: relative;
  @media (min-width: 768px) {
    &:not(:last-child) {
      margin-right: 32px;
    }
    &:not(:first-child):before {
      content: "";
      position: absolute;
      left: -16px;
      width: 1px;
      background: ${colors.gray150};
      height: 23px;
    }
  }
`;

export const Cross = styled(crossIcon)`
  position: absolute;
  top: 3px;
  right: -2px;
  padding: 10px;
  width: 11px;
  height: 11px;
`;

export const Alert = styled.div`
  font-size: 15px;
  line-height: 23px;
  color: ${colors.pomegranate};
`;

export const Toast = styled.div`
  font-size: 13px;
  line-height: 19px;
  cursor: pointer;
`;

export const ToastTitle = styled.div`
  font-weight: 500;
  color: ${colors.gray600};
`;

export const ToastText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: ${colors.gray700};
`;

export const ToastBody = styled(DefaultToastBody)`
  display: block;
  text-decoration: none;
`;
