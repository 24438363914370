// @flow
import type { FieldNumber } from "../../domain/entities/Field";
import * as React from "react";
import { useFieldValue } from "./useFieldValue";
import { setFieldValue } from "../../stores/appointmentFieldValues";
import type { LayoutFieldValue, TAppointment_id } from "../../domain/entities/Appointment";
import { NumericInput } from "../form-elements/NumericInput";
import { getNumberValue } from "./getNumberValue";
import { useErrorHighlighting } from "./useErrorHighlighting";
import { getField_id } from "../../domain/entities/Field";

export const NumberWidget = (props: {
  field: FieldNumber,
  tabIndex?: number,
  showError?: boolean,
  appointment_id: TAppointment_id,
  layoutFieldValue?: LayoutFieldValue
}) => {
  const { appointment_id, field, tabIndex, layoutFieldValue } = props;
  const result = useFieldValue(appointment_id, field.id);
  const value = getNumberValue(result, layoutFieldValue);

  let errorField;

  if (field.required) {
    const { error } = useErrorHighlighting(
      value,
      appointment_id,
      getField_id(field),
      null,
      field.required
    );
    errorField = error;
  }

  return (
    <NumericInput
      value={value}
      tabIndex={tabIndex}
      error={(props.showError || value) && errorField ? errorField.value : false}
      handleChange={newValue => {
        setFieldValue({
          field_type: field.field_type,
          appointment_id,
          field_id: field.id,
          value: newValue
        });
      }}
    />
  );
};
