// @flow
import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import filledIcon from "./icons/filled.svg";

const FormattedText = styled.div`
  p,
  li {
    font-size: 15px;
    line-height: 1.53;
    color: ${colors.black};
  }

  b {
    font-weight: 500;
  }

  i {
    font-style: italic;
  }

  li {
    padding-left: 32px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      z-index: 1;
    }
  }

  ul li:before {
    background: url(${filledIcon});
  }

  ol {
    list-style: none;
    counter-reset: info-text-counter;
    li {
      counter-increment: info-text-counter;
      &:before {
        content: counter(info-text-counter) ". ";
        opacity: 0.5;
        font-size: 15px;
        font-style: italic;
        text-align: center;
      }
    }
  }

  a {
    color: ${colors.magenta};
    position: relative;
    text-decoration: none;

    &:after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 1px;
      background: #f5d5e9;
    }
  }

  li + li {
    margin-top: 2px;
  }

  p + ul {
    margin-top: 8px;
  }

  ul + p {
    margin-top: 18px;
  }

  p + ol {
    margin-top: 8px;
  }

  ol + p {
    margin-top: 18px;
  }

  p + p {
    margin-top: 18px;
  }
`;

export const InfoTextFormatter = (props: { html: string }) => {
  const { html } = props;
  return <FormattedText dangerouslySetInnerHTML={{ __html: html }} />;
};
