// @flow
import React, { useLayoutEffect } from "react";
import { observer } from "mobx-react";
import corporateNewsStore from "../stores/corporateNewsStore";
import { Box, Unstable_Grid2 as Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CorporateWrapper } from "../styled/CorporateWrapper.styled";
import { CorporateMiniCardNews } from "../components/CorporateMiniCardNews";
import { breakpoints } from "../styled/common";
import { CorporateSubTitle } from "../styled/CorporateTypography.styled";

const MiniNewsContainer = styled(Box)`
  &.MuiBox-root {
    flex-grow: 1;
    margin-top: 24px;
    ${breakpoints.desktop} {
      margin-top: 16px;
    }
    ${breakpoints.sm} {
      margin-top: 10px;
    }
  }
`;

export const CorporateNewsPage = observer(() => {
  useLayoutEffect(() => {
    (async () => {
      if (corporateNewsStore.isInitialized) {
        corporateNewsStore.resetState();
      }

      await corporateNewsStore.getListNews();
    })();
  }, []);

  return (
    <MiniNewsContainer>
      {corporateNewsStore.isInitialized && !corporateNewsStore.dataNews.length ? (
        <CorporateWrapper>
          <CorporateSubTitle modifiers={["gray"]}>Новостей не найдено</CorporateSubTitle>
        </CorporateWrapper>
      ) : (
        <Grid container spacing={2}>
          {corporateNewsStore.dataNews.map(news => (
            <Grid key={news.id} xs={12} sm={6} md={6} xl={4}>
              <CorporateMiniCardNews {...news} />
            </Grid>
          ))}
        </Grid>
      )}
    </MiniNewsContainer>
  );
});
