import { Typography } from "@mui/material";
import React, { Fragment } from "react";
import { ReportsTableWrapper } from "../Reports.styled";
import { ReportCalculations } from "../common/ReportCalculations";

const LIST_CALCULATIONS = [
  { key: "total", name: "Все" },
  { key: "eko", name: "ЭКО" },
  { key: "banking", name: "Банкинг" },
  { key: "donors", name: "Доноры" }
];

export const ReportProgramsWithPGT = props => {
  return (
    <Fragment>
      <ReportCalculations label={props.label} calculations={LIST_CALCULATIONS} />
      <ReportsTableWrapper>
        <Typography>ТАБЛИЦА</Typography>
      </ReportsTableWrapper>
    </Fragment>
  );
};
