// @flow
import * as React from "react";

import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {
  AgreementText,
  ButtonReview,
  DescriptionText,
  FlexContainer,
  HeaderText,
  LogoWrapper,
  SectionBottom,
  SectionTop,
  WrapperTexts,
} from "./reviewStyled";

import reviewStore, { CLINIC_CODE } from "../../stores/reviewStore";
import useReactRouter from "use-react-router";
import { ClinicLogo } from "../clinicLogo/ClinicLogo";
import { generateSearchParams, setStoreDataSearchParams } from "./ReviewPage";

export const ReviewStepFourNegative = withRouter(observer((props: {||}) => {

  if (!reviewStore.token) {
    const { match: { params } } = useReactRouter();
    setStoreDataSearchParams(reviewStore);
    props.history.push(`/review/${params.token}${generateSearchParams(reviewStore)}`);
    return null;
  }

  reviewStore.setCurrentPage('step-five-negative');
  reviewStore.setProgress();

  const handleClickWriteReview = () => {
    props.history.push(`/review/${reviewStore.token}/step-five-negative${generateSearchParams(reviewStore)}`);
  };

  return (
    <FlexContainer>
      <SectionTop>
        <LogoWrapper>
          <ClinicLogo name={reviewStore.clinic_code} isShowName={false} size={{width:"79px", height:"79px"}} />
        </LogoWrapper>
        <HeaderText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
          Важный момент
        </HeaderText>
        <WrapperTexts smallMargin={true}>
          <DescriptionText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
            <span>У вас нет программы лояльности</span>
          </DescriptionText>
          <DescriptionText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
            После того, как вы перейдете к отзыву мы автоматически зарегистрируем вас и отправим сообщение со ссылкой на карту лояльности.
          </DescriptionText>
        </WrapperTexts>
      </SectionTop>
      <SectionBottom>
        <ButtonReview onClick={handleClickWriteReview} modifiers={[reviewStore.clinic_code === CLINIC_CODE.rassvet ? "backgroundPinkRassvet" : "backgroundPink"]}>
          Всё понятно
        </ButtonReview>
        <AgreementText>
          Нажимая на кнопку, я подтверждаю согласие <a target="_blank" rel="noopener noreferrer" href={reviewStore.city === 'Пермь' ? "/loyalty-program-rules-new_perm.pdf" : "/loyalty-program-rules-new_all.pdf"}>с
          условиями программы лояльности</a>
        </AgreementText>
      </SectionBottom>
    </FlexContainer>
  );
}));
