// @flow
import * as React from "react";

import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {
  ButtonReview,
  DescriptionText,
  FlexContainer,
  HeaderText,
  LogoWrapper,
  SectionBottom,
  SectionTop, WrapperTexts
} from "./reviewStyled";

import logo from "./assets/logo-step-three-negative.png";
import logoRassvet from "./assets/logo-step-three-negative-rassvet.png";
import reviewStore, { CLINIC_CODE } from "../../stores/reviewStore";
import useReactRouter from "use-react-router";
import { generateSearchParams, setStoreDataSearchParams } from "./ReviewPage";

export const ReviewStepThreeNegative = withRouter(observer((props: {||}) => {

  if (!reviewStore.token) {
    const { match: { params } } = useReactRouter();
    setStoreDataSearchParams(reviewStore);
    props.history.push(`/review/${params.token}${generateSearchParams(reviewStore)}`);
    return null;
  }

  reviewStore.setCurrentPage('step-three-negative');
  reviewStore.setProgress();

  const handleClickWriteReview = () => {
    if (reviewStore.is_loyalty_card) {
      props.history.push(`/review/${reviewStore.token}/step-five-negative${generateSearchParams(reviewStore)}`);
    } else {
      props.history.push(`/review/${reviewStore.token}/step-four-negative${generateSearchParams(reviewStore)}`);
    }
  };

  return (
    <FlexContainer>
      <SectionTop>
        <LogoWrapper>
          <img src={reviewStore.clinic_code === CLINIC_CODE.rassvet ? logoRassvet : logo} />
        </LogoWrapper>
        <HeaderText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
          Нам крайне жаль, что вы столкнулись с негативным опытом
        </HeaderText>
        <WrapperTexts>
          <DescriptionText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
            Обычно люди не находят времени оставить отзывы. А вы нашли. Мы это ценим.
          </DescriptionText>
          <DescriptionText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
            В благодарность за ваше время мы пополним вашу программу лояльности бонусными баллами в размере 500 рублей за оставленный отзыв.
          </DescriptionText>
        </WrapperTexts>
      </SectionTop>
      <SectionBottom>
        <ButtonReview onClick={handleClickWriteReview} modifiers={[reviewStore.clinic_code === CLINIC_CODE.rassvet ? "backgroundPinkRassvet" : "backgroundPink"]}>
          Написать директору по сервису
        </ButtonReview>
      </SectionBottom>
    </FlexContainer>
  );
}));
