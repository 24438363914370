import React, { useContext } from "react";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import styled from "styled-components";
import { useStore } from "effector-react";
import useReactRouter from "use-react-router";
import { tokenStore } from "../../stores/auth";
import { colors } from "../styleguide/colors";
import { AnalyzesFormSection } from "../form/AnalyzesFormSection";
import { FilesFieldset } from "../form/FilesFieldset";
import { AnalyzesButton } from "./AnalyzesButton";
import { FolderPageContext } from "./FolderPageContext";
import { useFiles } from "../analyzes/useFiles";
import { FileUploadButton } from "../file/FileUploadButton";
import apiv2 from '../../apiv2';

const FilesContainer = styled(RoundedRectangleWithShadow)`
  width: 395px;
  min-width: 345px;
  height: fit-content;
  margin-left: 20px;
  background-color: ${colors.white};

  @media (max-width: 768px) {
    min-width: auto;
    width: auto;
    margin-left: 0;
  }
`;

export const FilesFormLayout = ({ type }) => {
  const [files, setFiles] = React.useState([]);
  const { history } = useReactRouter();

  const token = useStore(tokenStore);
  const { folder } = useContext(FolderPageContext);

  const { uploads, analyzes, patient } = useFiles(parseInt(folder?.medical_file), 5);

  const uploadedFiles = type === "analysis" ? analyzes : uploads;
  const title = type === "analysis" ? "Файлы" : "Документы";

  const onAddFile = React.useCallback(
    async newFiles => {
      if (!token || !patient) return;
      const uploadedFiles = await Promise.all(
        Object.values(newFiles).map(async file => {
          if (!(file instanceof File)) return;
          try {
            const result = await apiv2.files.postUploadedFile({
              uploadOptions: { file: file, original_name: file.name || "" },
              patient,
              type
            });
            return result;
          } catch (e) {
            return null;
          }
        })
      );
      setFiles(prevUploads => [...uploadedFiles.filter(Boolean), ...prevUploads]);
    },
    [patient, token, type]
  );

  React.useEffect(() => {
    if (!uploadedFiles) return;
    setFiles(uploadedFiles);
  }, [uploadedFiles]);

  const renderedFiles = React.useMemo(() => {
    if (!files) return null;
    return files.map(file => {
      return <FilesFieldset key={file.name + file.id} {...file} textOverflow />;
    });
  }, [files]);

  return (
    <FilesContainer>
      <AnalyzesFormSection
        key={title}
        id={title}
        name={title}
        showPrintCheckmark={false}
        toolButton={<FileUploadButton onAddFile={onAddFile} text="Загрузить" />}
        noToolButtonPadding>
        {renderedFiles}
        <AnalyzesButton
          onClick={() => history.push(`/appointment/analyzes/${folder.medical_file}/${type}`)}>
          {type === "analysis" ? "Все анализы" : "Все документы"}
        </AnalyzesButton>
      </AnalyzesFormSection>
    </FilesContainer>
  );
};
