import styled from "styled-components";

export const Input = styled.div`
  // position: fixed;
  // left: ${p => (p.offset ? `${-p.offset}px` : "unset")};
  width: 300px;
  max-width: 300px;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 17px rgba(91, 91, 91, 0.259752);
`;
