import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class FullSchedule {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getFullSchedule(params) {
    return this.apiService.get(`full-schedule`, params);
  }
}

export default new FullSchedule();
