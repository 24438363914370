// @flow
import * as React from "react";
import styled from "styled-components";

import { LinearGradientBackground } from "../styleguide/LinearGradientBackground";
import { DefaultInput } from "../form-elements/DefaultInput";
import { PrimaryButton }  from "../buttons/PrimaryButton";
import { DefaultButton }  from "../buttons/DefaultButton";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import authStore from "../../stores/authStore";
import { withModifiers } from "with-modifiers";
import { ImageInput } from "../form-elements/ImageInput";

const Title = styled.h1`
  font-size: 2em;
`;

const Container = styled(LinearGradientBackground)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`;

const EditForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
`;

export const ProfilePageTelemed = withRouter(observer((props: {|
  afterRegistration: boolean
|}) => {
  const { user } = authStore;

  const [firstName, setFirstName] = React.useState(user.doctor?.first_name || '');
  const [middleName, setMiddleName] = React.useState(user.doctor?.middle_name || '');
  const [lastName, setLastName] = React.useState(user.doctor?.last_name || '');
  const [photo, setPhoto] = React.useState(user.doctor?.photo);

  const userHasChanges = () => {
    return (
      firstName &&
      lastName &&
      (user.doctor && (
        user.doctor.first_name !== firstName ||
        user.doctor.middle_name !== middleName ||
        user.doctor.last_name !== lastName ||
        user.doctor.photo !== photo
      ))
    );
  };

  const handleSaveClick = async () => {
    try {
      const formData = new FormData();
      formData.append('first_name', firstName);
      formData.append('middle_name', middleName);
      formData.append('last_name', lastName);
      formData.append('photo', photo);
      await authStore.editUser(formData);
      props.history.push('/schedule');
    } catch(e) {
      console.error(e);
    }
  };

  const handleScheduleClick = () => {
    props.history.push('/schedule');
  };

  return (
    <Container>
      <EditForm>
        <Title>Профиль</Title>
        <DefaultInput placeholder="Фамилия" value={lastName} handleChange={setLastName}/>
        <DefaultInput placeholder="Имя" value={firstName} handleChange={setFirstName}/>
        <DefaultInput placeholder="Отчество" value={middleName} handleChange={setMiddleName}/>
        <ImageInput label={"Кликните, чтобы загрузить фотографию."} value={photo} handleChange={setPhoto} />
        <PrimaryButton onClick={handleSaveClick} disabled={!userHasChanges()}>
          Сохранить
        </PrimaryButton>
        {
          !props.afterRegistration && (
            <DefaultButton onClick={handleScheduleClick}>
              К расписанию
            </DefaultButton>
          )
        }
      </EditForm>
    </Container>
  );
}));
