import apiv2 from '../apiv2';
import { makeAutoObservable } from "mobx";
import _ from 'lodash';

class OrganizationsStore {
 organizations = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getOrganizations() {
    if (!this.organizations.length) {
      try {
        const response = await apiv2.organizations.getAllOrganizations();
        this.organizations = _.sortBy(response, 'name');
        return this.organizations;
      } catch(e) {
        console.error(e);
        return false;
      }
    } else {
      return this.organizations;
    }
  }
}

export default new OrganizationsStore();
