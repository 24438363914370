import React from 'react';
import {observer} from 'mobx-react';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import commonStore from "../../stores/commonStore";
import { UPDATE_NEWS_LINK } from "../../version";
import styled from "styled-components";
import updateRNImage from "../../assets/image/updateRN.png";

const DialogContentUpdate = styled(DialogContent)`
  padding-bottom: 0 !important;
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
`;

export const UpdateVersionModal = observer(() => {
  const handleOnClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: "openLink", link: UPDATE_NEWS_LINK }));
    } else {
      window.open(UPDATE_NEWS_LINK, "_blank");
    }
    commonStore.changeIsShowedUpdateVersion(true);
    commonStore.changeShowUpdateVersionModal(false);
  }

  return (
    <Dialog
      onClose={() => commonStore.changeShowUpdateVersionModal(false)}
      open={commonStore.showUpdateVersionModal}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Обновление Аси
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => commonStore.changeShowUpdateVersionModal(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContentUpdate dividers>
        <img src={updateRNImage} width={"100%"} height={"100%"} />
        <ButtonBlock>
          <Button variant={"contained"} onClick={handleOnClick}>
            Подробнее об изменениях
          </Button>
        </ButtonBlock>
      </DialogContentUpdate>
    </Dialog>
  );
});
