import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class HospitalAdmissions {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getHospitalAdmissions() {
    return this.apiService.get(`hospital_admission`);
  }

  patchHospitalAdmissions(payload) {
    return this.apiService.patch(`hospital_admission/${payload.id}`, {}, payload);
  }
}

export default new HospitalAdmissions();
