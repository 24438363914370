import { makeAutoObservable } from 'mobx';

class ImageViewerStore {
  currentImageIndex = 0;
  images = [];
  isViewerVisible = false;

  constructor() {
    makeAutoObservable(this);

    this.show = this.show.bind(this);
    this.close = this.close.bind(this);
    this.setImages = this.setImages.bind(this);
    this.setCurrentImage = this.setCurrentImage.bind(this);
    this.goToPreviousImage = this.goToPreviousImage.bind(this);
    this.goToNextImage = this.goToNextImage.bind(this);
  }

  get hasImages() {
    return this.images.length > 0;
  }

  get currentImageUrl() {
    return this.images[this.currentImageIndex];
  }

  get hasPreviousImage() {
    return this.currentImageIndex > 0;
  }

  get hasNextImage() {
    return this.currentImageIndex < this.images.length - 1;
  }

  _resetState() {
    this.images = [];
    this.currentImageIndex = 0;
  }

  show() {
    this.isViewerVisible = true;
  }

  close() {
    this.isViewerVisible = false;
    this._resetState();
  }

  setImages(images) {
    this._resetState();
    this.images = images;
  }

  setCurrentImage(imageIndex) {
    this.currentImageIndex = imageIndex;
  }

  goToPreviousImage() {
    if (this.hasPreviousImage) {
      this.currentImageIndex -= 1;
    }
  }

  goToNextImage() {
    if (this.hasNextImage) {
      this.currentImageIndex += 1;
    }
  }
}

export default new ImageViewerStore();
