import apiv2 from '../apiv2';
import { makeAutoObservable } from "mobx";
import _ from 'lodash';

class SpecialitiesStore {
  specialities = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getSpecialities() {
    if (!this.specialities.length) {
      try {
        const response = await apiv2.integrations.getSpecialities();
        this.specialities = _.sortBy(response.results, 'name');
        return this.specialities;
      } catch(e) {
        console.error(e);
        return false;
      }
    } else {
      return this.specialities;
    }
  }
}

export default new SpecialitiesStore();
