// @flow
import * as React from "react";
import styled from "styled-components";
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { PList } from "../styleguide/typography";
import type { ScheduleVacant } from "../../domain/entities/Schedule";
import {
  formatScheduleTimeForDisplay,
  isSameOrAfterDate,
  isSameOrAfterDateAndTime
} from "../../domain/services/schedule";
import { colors } from "../styleguide/colors";
import { rgba } from "polished";
import PlusIcon from "./icons/plus.svg";
import { DeleteButtonIcon } from "../buttons/DeleteButtonIcon";
import { useStore } from "effector-react";
import {
  changeDataDeleteAppointment,
  dataStore, deleteAppointmentAndUpdateView
} from "../schedule-page/scheduleStore";
import moment from "moment";
import { READABLE_TIME_FORMAT_MOMENT } from "../../utils/dates";

const Plus = styled.div`
  opacity: 0;
  width: 12px;
  height: 12px;
  background: url(${PlusIcon}) no-repeat;
  background-size: contain;
  transition: opacity 0.15s ease-in-out;
`;

const DeleteScheduleButtonContainer = styled.div`
  display: none;
`;

const Time = styled(PList)`
  font-variant-numeric: tabular-nums;
  opacity: 0.3;
  transition: opacity 0.15s ease-in-out;
`;

const ContainerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  
  svg {
    width: 16px;
    height: 16px;
    margin-right: 24px;
  }

  @media (max-width: 768px) {
    svg {
      margin-right: 20px;
    }
  }
  @media (max-width: 480px) {
    svg {
      margin-right: 15px;
    }
  }
`;

const Container = styled.div`
  flex: 1;
  height: 30px;
  padding: 0 24px;
  cursor: ${props => props.available && "pointer"};
  ${props => props.isDisabled && "pointer-events: none;"};

  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: background 0.15s ease-in-out;

  :hover {
    background-color: ${props => props.available && rgba("#D8D8D8", 0.15)};
    ${Plus} {
      opacity: ${props => props.available && 1};
    }
    ${Time} {
      color: ${props => props.available && colors.magenta};
      opacity: ${props => props.available && 1};
    }
    ${DeleteScheduleButtonContainer} {
      display: flex;
    }
  }
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  @media (max-width: 480px) {
    padding: 0 15px;
  }
`;

const TooltipWrapper = styled.div`
    display: flex;
    color: #595959;
    opacity: 0.3;
`;

export const ScheduleHourStub = (props: {
  item: Object<ScheduleVacant>,
  available: boolean,
  onClick?: () => mixed
}) => {
  const scheduleStore = useStore(dataStore);
  const deleteAppointmentButtonClick = (item) => {
    changeDataDeleteAppointment(item);
    deleteAppointmentAndUpdateView();
  }

  let isDisabled = props.item.type === "home_visit";
  if (!isDisabled) {
    isDisabled = props.item.time
      ? !isSameOrAfterDateAndTime(props.item.date, props.item.time)
      : !isSameOrAfterDate(props.item.date);
  }

  const isTechnical = props.item.is_technical;

  const timeText = props.item.datetime
    ? moment(props.item.datetime).format(READABLE_TIME_FORMAT_MOMENT)
    : "—:—";

  return (
    <ContainerWrapper>
      <Container
        isDisabled={isDisabled}
        available={(!isDisabled && props.available) || scheduleStore.isEditSchedule}
        onClick={props.available && !scheduleStore.isEditSchedule && process.env.APP_FLAVOR === 'default' && !isDisabled ? props.onClick : undefined}
      >
        <Time modifiers="disabled">
          {timeText}
        </Time>
        {!scheduleStore.isEditSchedule && process.env.APP_FLAVOR === 'default' && (
          <Plus />
        )}
        {scheduleStore.isEditSchedule && (process.env.APP_FLAVOR === "telemed") && (
          <DeleteScheduleButtonContainer>
            <DeleteButtonIcon onClick={() => deleteAppointmentButtonClick(props.item)} />
          </DeleteScheduleButtonContainer>
        )}
      </Container>
      {
        isTechnical && (
          <TooltipWrapper>
            <Tooltip title={`[${timeText}] в МИС(1с) слот помечен как "/-----/"`}>
              <HelpOutlineIcon/>
            </Tooltip>
          </TooltipWrapper>
        )
      }
    </ContainerWrapper>
  );
};
