//@flow
import { createEffect, createEvent, createStore } from "effector";
import { currentUser as currentUserStore, tokenStore } from "../../stores/auth";
import { getDateString } from "../../domain/dates";
import moment from "moment";
import _ from "lodash";

// import type { TokenObject } from "../../domain/value-objects/auth";
// import type { ScheduleGetParams } from "../../domain/value-objects/schedule";
import type { Doctor } from "../../domain/entities/Doctor";
import apiv2 from '../../apiv2';

type BeforeReloadData = {
  activeOrganization?: string,
  activeSpeciality?: any,
  selectedDoctor?: any
};

const beforeReloadDataKey = "general-schedule-before-reload-data";
const rawBeforeReloadData = localStorage.getItem(beforeReloadDataKey);
export let beforeReloadData: BeforeReloadData = {};

if (rawBeforeReloadData) {
  beforeReloadData = JSON.parse(rawBeforeReloadData);
  localStorage.removeItem(beforeReloadDataKey);
}

const saveBeforeReload = () => {
  localStorage.setItem(beforeReloadDataKey, JSON.stringify(beforeReloadData));
};

export const favouritesKey = "favourites";
let needUseDoctor = false;

export const scheduleStore = createStore<Array<any>>([]);
export const doctorsStore = createStore<Array<any>>({isLoading: true, doctors: []});
export const activeOrganizationStore = createStore<string>(
  beforeReloadData.activeOrganization ? beforeReloadData.activeOrganization : ""
);
export const activeSpecialityStore = createStore<any>(
  beforeReloadData.activeSpeciality || beforeReloadData.activeSpeciality === null
    ? beforeReloadData.activeSpeciality
    : favouritesKey
);
export const selectedDoctorStore = createStore<any>(
  beforeReloadData.selectedDoctor ? beforeReloadData.selectedDoctor : null
);

export const favouriteDoctorsStore = createStore<any[]>([]);
const setScheduleData = createEvent<Array<any>>("setScheduleData");
const setDoctorsData = createEvent<Array<any>>("setDoctorsData");
export const setActiveOrganization = createEvent<string>("setActiveOrganization");
export const setActiveSpeciality = createEvent<any>("setActiveSpeciality");
export const setSelectedDoctor = createEvent<any>("setSelectedDoctor");
const setFavouriteDoctorsData = createEvent<any[]>("setFavouriteDoctorsData");
export const getDoctors = createEvent<{}>("getDoctors");
scheduleStore.on(setScheduleData, (state, payload) => payload);
doctorsStore.on(setDoctorsData, (state, payload) => payload);
activeOrganizationStore.on(setActiveOrganization, (state, payload) => payload);
activeSpecialityStore.on(setActiveSpeciality, (state, payload) => payload);
selectedDoctorStore.on(setSelectedDoctor, (state, payload) => {
  return payload;
});
favouriteDoctorsStore.on(setFavouriteDoctorsData, (state, payload) => payload);
activeOrganizationStore.watch((storeValue, eventValue) => {
  getDoctors({});
  if (beforeReloadData.selectedDoctor) {
    setSelectedDoctor(beforeReloadData.selectedDoctor);
    setActiveSpeciality(null);
  } else {
    setSelectedDoctor(null);
    setActiveSpeciality(
      beforeReloadData.activeOrganization === eventValue
        ? beforeReloadData.activeSpeciality
          ? beforeReloadData.activeSpeciality
          : favouritesKey
        : favouritesKey
    );
  }
  beforeReloadData.activeOrganization = eventValue;
  saveBeforeReload();
});
activeSpecialityStore.watch(setActiveSpeciality, (prev, next) => {
  if (next) {
    needUseDoctor = false;
    appendOrUpdateData({});
  }
  beforeReloadData.activeSpeciality = next;
  saveBeforeReload();
});
selectedDoctorStore.watch(setSelectedDoctor, (prev, next) => {
  const activeSpeciality = activeSpecialityStore.getState();

  if (!prev && !next && !activeSpeciality) {
    needUseDoctor = false;
    setActiveSpeciality(favouritesKey);
  } else if (prev && next) {
    needUseDoctor = true;
    appendOrUpdateData({});
  }

  beforeReloadData.selectedDoctor = next;
  saveBeforeReload();
});
currentUserStore.watch(user => {
  if (!user) {
    const { isLoading } = doctorsStore.getState();
    setScheduleData([]);
    setDoctorsData({isLoading, doctors: []});
  }
});

const getDoctorsByOrganization = (doctors: Doctor[], organizationId: number) => {
  return doctors.filter(doctor => {
    const doctorOrganizations = doctor.organizations;
    if (doctorOrganizations && doctorOrganizations.length) {
      return doctorOrganizations.find(organization => organization.id === organizationId);
    } else {
      return false;
    }
  });
};

const getUniqueDoctors = (doctors: Doctor[]): Doctor[] => {
  return _.uniqBy(doctors, "id");
};

const getFullScheduleEffect = createEffect("getFullScheduleEffect").use(async params => {
  if (!params.token) {
    return;
  }
  if (!params.from_date) {
    params.from_date = new Date();
  }
  const from_date = getDateString(moment(params.from_date).subtract(1, 'week'));
  delete params.from_date;
  let currentDoctorSchedule = null;
  if (params.favorites_only) {
    const currentUser = currentUserStore.getState();
    // $FlowFixMe
    if (currentUser && currentUser.doctor) {
      const currentUserDoctor = currentUser.doctor;

      currentDoctorSchedule = await apiv2.fullSchedule.getFullSchedule({
        from_date,
        doctor: currentUserDoctor.id.toString(),
        organization: params.organization
      });
    }
  }
  const fullSchedule = await apiv2.fullSchedule.getFullSchedule({ from_date, ...params });

  if (currentDoctorSchedule && currentDoctorSchedule.length > 0) {
    currentDoctorSchedule.forEach(currentDoctorScheduleItem => {
      const sameIndex = fullSchedule.findIndex(
        fullScheduleItem => fullScheduleItem.date === currentDoctorScheduleItem.date
      );
      if (sameIndex >= 0) {
        fullSchedule[sameIndex] = {
          date: currentDoctorScheduleItem.date,
          doctors: [...currentDoctorScheduleItem.doctors, ...fullSchedule[sameIndex].doctors]
        };
      } else {
        fullSchedule.push(currentDoctorScheduleItem);
      }
    });

    fullSchedule.sort((fullScheduleItemA, fullScheduleItemB) => {
      const dateA = moment(fullScheduleItemA.date);
      const dateB = moment(fullScheduleItemB.date);

      return dateA.isAfter(dateB) ? 1 : dateA.isSame(dateB) ? 0 : -1;
    });
  }

  return fullSchedule;
});

getFullScheduleEffect.done.watch(({ result: schedule }) => {
  if (!schedule) {
    return;
  }
  setScheduleData(schedule);
});

const getDoctorsEffect = createEffect("getDoctorsEffect").use(async params => {
  if (!params.token) {
    return;
  }
  const result = await apiv2.doctors.getAll();
  return result || [];
});

getDoctorsEffect.done.watch(({ result: doctors }) => {
  const activeOrganization = activeOrganizationStore.getState();

  if (!doctors || !activeOrganization) {
    return;
  }

  setDoctorsData({isLoading: false, doctors: getDoctorsByOrganization(getUniqueDoctors(doctors), parseInt(activeOrganization))});
});

doctorsStore.on(getDoctors, state => {
  const token = tokenStore.getState();
  const { doctors } = doctorsStore.getState();

  if (!token) {
    return;
  }

  setDoctorsData({isLoading: true, doctors });
  getDoctorsEffect({ token });
  return state;
});

const getFavouriteDoctorsEffect = createEffect("getFavouriteDoctorsEffect").use(async params => {
  if (!params.token) {
    return;
  }
  const result = await apiv2.doctors.getFavouriteDoctors();
  return result || [];
});

getFavouriteDoctorsEffect.done.watch(({ result: doctors }) => {
  if (!doctors) {
    return;
  }

  setFavouriteDoctorsData(doctors);
});

export const appendOrUpdateData = createEvent<{
  // ...$Exact<TokenObject>,
  // ...$Exact<ScheduleGetParams>,
  doctor?: string,
  speciality?: number,
  organization?: number,
  favorites_only?: boolean
}>("appendOrUpdateData");

export const updateScheduleData = createEvent<any>("updateScheduleData");
scheduleStore.on(updateScheduleData, (state, schedule) => {
  if (schedule && state) {
    const scheduleItem = state.find(scheduleItem => scheduleItem.date === schedule.date);
    if (scheduleItem && scheduleItem.doctors) {
      const doctorSchedule = scheduleItem.doctors.find(
        doctorSchedule => doctorSchedule.doctor.id === schedule.doctor
      );
      if (doctorSchedule) {
        doctorSchedule.schedule = doctorSchedule.schedule.map(doctorScheduleItem => {
          if (doctorScheduleItem.id === schedule.id) {
            return schedule;
          }
          return doctorScheduleItem;
        });
      }
    }
  }

  return [...state];
});
appendOrUpdateData.watch((/*data*/) => {
  // console.log(data);
});
scheduleStore.on(appendOrUpdateData, (state, params = {}) => {
  const token = tokenStore.getState();
  const organization = activeOrganizationStore.getState();
  const speciality = activeSpecialityStore.getState();
  const doctor = selectedDoctorStore.getState();

  if (!token) {
    return;
  }

  if (organization && !params.organization) {
    // $FlowFixMe
    params.organization = parseInt(organization);
  }

  if (speciality && (!params.speciality && !params.favorites_only)) {
    if (speciality === favouritesKey) {
      params.favorites_only = true;
    } else {
      params.speciality = parseInt(speciality);
    }
  }

  if (needUseDoctor) {
    if (doctor && !params.doctor) {
      delete params.favorites_only;
      delete params.speciality;
      // $FlowFixMe
      params.doctor = doctor.id;
    }
  }

  getFullScheduleEffect({ token, ...params });
  return ["loading"];
});

export const getFavoriteDoctors = createEvent<{
  // ...$Exact<TokenObject>,
  // ...$Exact<ScheduleGetParams>,
}>("getFavoriteDoctors");
getFavoriteDoctors.watch((/*data*/) => {
  // console.log(data);
});
favouriteDoctorsStore.on(getFavoriteDoctors, (state, params = {}) => {
  const token = tokenStore.getState();

  if (!token) {
    return;
  }

  getFavouriteDoctorsEffect({ token, ...params });
  return state;
});
