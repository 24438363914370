import React, { useCallback, useState, useEffect } from "react";
import { useAsyncFn } from "react-use";
import { useStore } from "effector-react";

import {
  fieldInputs,
  defaultFields,
  fieldTypeByApiType,
  fieldViewers,
  fieldTypesIncomeHandlers,
  fieldTypesOutcomeHandlers,
  fieldTypes,
  eventTypeFieldName
} from "./types";

import { tokenStore } from "../../stores/auth";

// import { sortAppointments } from "./utils";

import { MyPatientsTable } from "./MyPatientsTable";
import { Header as AppHeader } from "../header/Header";
import { BackToScheduleButton } from "../appointment-switch-page/BackToScheduleButton";
import { MyPatientsTableSearch } from "./MyPatientsTableSearch";
import { MyPatientsTableViewersPatient } from "./MyPatientsTableViewers/MyPatientsTableViewersPatient";
import { MyPatientsTableViewersRemind } from "./MyPatientsTableViewers/MyPatientsTableViewersRemind";
import { MyPatientsPageContext } from "./MyPatientsPageContext";
import { ButtonRadioGroup } from "../form-elements/ButtonRadioGroup";
import { EventTypeFilter } from "./EventTypeFilter";

import { HeadingH2 } from "../styleguide/typography";
import * as S from "./MyPatientsPage.styled";
import { AsideLayout } from "../layouts/AsideLayout";
import { useMediaQuery } from "@mui/material";
import apiv2 from '../../apiv2';

export const MyPatientsPage = () => {
  const token = useStore(tokenStore);
  const isMobileDevice = useMediaQuery('(max-width: 768px)');
  const [table, setTable] = useState({ values: [], fields: [] });
  const [query, setQuery] = useState("");
  const [error, setError] = useState("");

  const [filters, setFilters] = useState([
    { name: "Актуальные исходы", value: "active", active: true, noResultsCaption: "актуальных " },
    { name: "Все исходы", value: "all", active: false, noResultsCaption: "" }
  ]);

  const activeFilter = filters.find(({ active }) => active);

  const [activeEventTypeFilter, setActiveEventTypeFilter] = useState(null);

  const [fetchAppointmentsOutcomesState, fetchAppointmentsOutcomes] = useAsyncFn(
    async ({ query, filter, eventTypeFilter }) => {
      const response = await apiv2.appointments.getAppointmentsOutcomes({ query, filter });

      const fields = [
        ...defaultFields,
        ...response.fields.map(
          ({ copy_id, name, field_type, possible_values, can_delete, can_change }) => ({
            id: copy_id,
            name,
            field_type,
            possible_values,
            copy_id,
            can_delete,
            can_change
          })
        )
      ];

      if (eventTypeFilter) {
        response.appointments = response.appointments.filter(({ field_values }) => {
          const eventTypeField = field_values.find(
            ({ field: { name } }) => name === eventTypeFieldName
          );

          return eventTypeField?.value === eventTypeFilter;
        });
      }

      const values = response.appointments.map(
        ({ full_name, reminder_day, field_values, badge, id: appointmentId }, i) => [
          {
            index: [i, 0],
            value: full_name,
            readOnly: true,
            appointmentId,
            fieldType: fieldTypes.name,
            removable: false,
            badge,
            DataViewer: MyPatientsTableViewersPatient
          },
          {
            index: [i, 1],
            value: fieldTypesIncomeHandlers[fieldTypes.date](reminder_day),
            readOnly: true,
            appointmentId,
            fieldType: fieldTypes.reminder,
            removable: false,
            DataViewer: MyPatientsTableViewersRemind
          },
          ...fields
            .filter(({ isDefault }) => !isDefault)
            .map(({ id, field_type, possible_values, can_delete, can_change }, j) => {
              const item = field_values.find(({ field }) => id === field.copy_id) || {
                id: null,
                value: null
              };

              const fieldType = fieldTypeByApiType[field_type];

              return {
                id: item.id,
                fieldId: id,
                fieldType,
                index: [i, defaultFields.length + j],
                removable: can_delete,
                readOnly: !can_change,
                appointmentId: appointmentId,
                value: fieldTypesIncomeHandlers[fieldType]
                  ? fieldTypesIncomeHandlers[fieldType](item.value)
                  : item.value,
                options: possible_values.map(item => ({ value: item, label: item })),
                multiple: fieldType === fieldTypes.multipleSelect,
                DataEditor: fieldInputs[fieldType],
                DataViewer: fieldViewers[fieldType]
              };
            })
        ]
      );

      setTable({ values, fields });

      return { values, fields, loaded: true };
    }
  );

  const [
    updateAppointmentsOutcomesFieldValueState,
    updateAppointmentsOutcomesFieldValue
  ] = useAsyncFn(async ({ id, value }) => {
    try {
      const response = await apiv2.appointments.patchAppointmentsOutcomesFieldValue(id, value);

      return response;
    } catch (err) {
      setError(err.message);
    } finally {
      setError("");
    }
  });

  const [
    createAppointmentsOutcomesFieldValueState,
    createAppointmentsOutcomesFieldValue
  ] = useAsyncFn(async ({ fieldId, appointmentId, value }) => {
    try {
      return await apiv2.appointments.postAppointmentsOutcomesFieldValue({ fieldId, appointmentId, value });
    } catch (err) {
      setError(err.message);
    } finally {
      setError("");
    }
  });

  const handleCreate = useCallback(
    ({ fieldId, fieldType, appointmentId, value, index }) => {
      console.info("handle create", fieldId, fieldType, appointmentId, value, index);
      createAppointmentsOutcomesFieldValue({
        fieldId,
        appointmentId,
        value: fieldTypesOutcomeHandlers[fieldType]
          ? fieldTypesOutcomeHandlers[fieldType](value)
          : value
      }).then(({ id, appointment }) => {
        setTable(table => ({
          ...table,
          values: table.values.map((row, rowNumber) =>
            row.map(cell => {
              if (rowNumber === index[0] && cell.fieldType === fieldTypes.reminder) {
                return {
                  ...cell,
                  value: fieldTypesIncomeHandlers[fieldTypes.date](appointment.reminder_day)
                };
              }

              if (cell.index[0] === index[0] && cell.index[1] === index[1]) {
                return { ...cell, id };
              }

              return cell;
            })
          )
        }));
      });

      setTable({
        ...table,
        values: table.values.map(row =>
          row.map(cell =>
            cell.index[0] === index[0] && cell.index[1] === index[1] ? { ...cell, value } : cell
          )
        )
      });
    },
    [table, createAppointmentsOutcomesFieldValue]
  );

  const handleUpdate = useCallback(
    ({ id, value, fieldType, index }) => {
      updateAppointmentsOutcomesFieldValue({
        id,
        value: fieldTypesOutcomeHandlers[fieldType]
          ? fieldTypesOutcomeHandlers[fieldType](value)
          : value
      }).then(({ appointment }) => {
        setTable(table => ({
          ...table,
          values: table.values.map((row, rowNumber) =>
            row.map(cell => {
              if (rowNumber === index[0] && cell.fieldType === fieldTypes.reminder) {
                return {
                  ...cell,
                  value: fieldTypesIncomeHandlers[fieldTypes.date](appointment.reminder_day)
                };
              }

              return cell;
            })
          )
        }));
      });

      setTable(table => ({
        ...table,
        values: table.values.map(row =>
          row.map(cell =>
            cell.index[0] === index[0] && cell.index[1] === index[1] ? { ...cell, value } : cell
          )
        )
      }));
    },
    [updateAppointmentsOutcomesFieldValue]
  );

  const handleSearch = useCallback(
    query => {
      setQuery(query);
      fetchAppointmentsOutcomes({
        query,
        filter: filters.find(({ active }) => active).value,
        eventTypeFilter: activeEventTypeFilter
      });
    },
    [fetchAppointmentsOutcomes, filters, activeEventTypeFilter]
  );

  const handleFilterChange = useCallback(
    activeIndex => {
      setFilters(
        filters.map((item, index) =>
          activeIndex === index.toString() ? { ...item, active: true } : { ...item, active: false }
        )
      );

      fetchAppointmentsOutcomes({
        query,
        filter: filters[activeIndex].value,
        eventTypeFilter: activeEventTypeFilter
      });
    },
    [filters, fetchAppointmentsOutcomes, activeEventTypeFilter, query]
  );

  const handleEventTypeChange = useCallback(
    eventType => {
      setActiveEventTypeFilter(eventType === activeEventTypeFilter ? null : eventType);
      fetchAppointmentsOutcomes({
        query,
        filter: filters.find(({ active }) => active).value,
        eventTypeFilter: eventType === activeEventTypeFilter ? null : eventType
      });
    },
    [filters, fetchAppointmentsOutcomes, activeEventTypeFilter, query]
  );

  useEffect(() => {
    fetchAppointmentsOutcomes({ query, filter: filters.find(({ active }) => active).value });
  }, []);

  const noResultsMessage = query
    ? `У пациента с таким именем нет ${activeFilter.noResultsCaption}исходов`
    : `Нет ${activeFilter.noResultsCaption}исходов`;

  return (
    <MyPatientsPageContext.Provider value={{ onUpdate: handleUpdate, onCreate: handleCreate }}>
      <AsideLayout withHeaderBackButton isAsideHidden>
        <S.Container>
          <S.HeadingContainer>
            {!isMobileDevice && <HeadingH2>Исходы программы ВРТ </HeadingH2>}
            <MyPatientsTableSearch onChange={handleSearch} />
          </S.HeadingContainer>
          <S.Content>
            <S.Filters>
              <ButtonRadioGroup
                items={filters}
                checked={filters.findIndex(({ active }) => active).toString()}
                shouldUncheck={false}
                shouldChangeOnClick={false}
                onChange={handleFilterChange}
              />
              <EventTypeFilter
                value={activeEventTypeFilter}
                outcomes={fetchAppointmentsOutcomesState.value}
                onChange={handleEventTypeChange}
              />
            </S.Filters>
            <MyPatientsTable
              table={table}
              loading={fetchAppointmentsOutcomesState.loading}
              onUpdate={handleUpdate}
              onCreate={handleCreate}
              noResultsMessage={noResultsMessage}
              error={error}
              loaded={
                fetchAppointmentsOutcomesState.value && fetchAppointmentsOutcomesState.value.loaded
              }
              updating={
                updateAppointmentsOutcomesFieldValueState.loading ||
                createAppointmentsOutcomesFieldValueState.loading
              }
            />
          </S.Content>
        </S.Container>
      </AsideLayout>
    </MyPatientsPageContext.Provider>
  );
};
