//@flow
import * as React from "react";
import { useState } from "react";
import { useStore } from "effector-react";

import { currentUser } from "../../stores/auth";
import { ScheduleInsertForm } from "./ScheduleInsertForm";
import { ScheduleInsertModal } from "./ScheduleInsertModal";

import type { Schedule } from "../../domain/entities/Schedule";
import type { Doctor } from "../../domain/entities/Doctor";
import { Details } from "./ScheduleInsertModalDoctorDetails";

export const useScheduleInsert = () => {
  const user = useStore(currentUser);
  const [scheduleStep, setScheduleStep] = useState<number>(
    user && user.doctor && user.doctor.schedule_step ? parseInt(user.doctor.schedule_step) : 60
  );

  const [modalIsShown, setModalIsShown] = useState(false);

  const [title, setTitle] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<?string>();
  const [closestTime, setClosestTime] = useState<?string>();
  const [organization, setOrganization] = useState<?number>(null);

  // $FlowFixMe
  const [doctor, setDoctor] = useState<?Doctor | null>(user && user.doctor ? user.doctor : null);
  const [afterSubmit, setAfterSubmit] = useState<Function | null>(null);

  const openScheduleInsertModalWithTime = (
    item: Schedule,
    closestItemWithAppointment: Schedule | typeof undefined
  ) => {
    setTitle("Запись пациента");
    setDate(item.date);
    setTime(item.time);
    setClosestTime(closestItemWithAppointment ? closestItemWithAppointment.time : "");
    setOrganization(item.organization);
    setModalIsShown(true);
  };

  const openScheduleInsertModalWithoutTime = (date: string) => {
    setTime(null);
    setOrganization(null);
    setTitle("Запись пациента вне расписания");
    setDate(date);
    setModalIsShown(true);
  };

  const hideModal = () => setModalIsShown(false);

  const afterSubmitHandler = scheduleValues => {
    hideModal();
    setOrganization(null);
    setDoctor(null);
    if (afterSubmit) {
      afterSubmit(scheduleValues);
      setAfterSubmit(null);
    }
  };

  const form = date ? (
    <ScheduleInsertForm
      date={date}
      time={time}
      doctor={doctor}
      closestTime={closestTime}
      scheduleStep={scheduleStep}
      organization={organization}
      afterSubmit={afterSubmitHandler}
    />
  ) : null;

  const afterFormContent = doctor && doctor.comment ? (
    <Details title={"Правила приема врача"}>{doctor.comment}</Details>
  ) : null;

  const scheduleInsertModal = (
    <ScheduleInsertModal
      title={title}
      isShown={modalIsShown}
      hide={hideModal}
      form={form}
      afterFormContent={doctor && doctor.comment ? afterFormContent : null}
    />
  );

  return {
    scheduleInsertModal,
    openScheduleInsertModalWithTime,
    openScheduleInsertModalWithoutTime,
    setScheduleStep,
    setOrganization,
    setDoctor,
    setAfterSubmit
  };
};
