// @flow
import * as React from "react";

import { DefaultInput } from "./DefaultInput";
import styled, {css} from "styled-components";
import { BaseStyledInput } from "./BaseStyledInput";
import { useEffect, useState } from "react";

const InputNumber = styled(BaseStyledInput)`
  &[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  ${({ readOnly }) =>
      readOnly &&
      css`
      border: none;
    `}
`;

const numberInputOnWheelPreventChange = e => {
  e.currentTarget.blur();
  return false;
};

type NumericInputProps = React$ElementConfig<typeof DefaultInput>;

export const NumericInput = (props: NumericInputProps) => {
  const [valueInput, setValueInput] = useState(props.value);
  const handleChange = newValue => {
    if (props.min !== undefined) {
      if (newValue < props.min) {
        newValue = props.min;
      }
    }
    setValueInput(newValue || "");
    if (props.handleChange) {
      props.handleChange(newValue);
    }
  };
  useEffect(() => {
    if (valueInput !== props.value) {
      setValueInput(props.value);
    }
  }, [props.value]);
  return (
    <DefaultInput
      {...props}
      type="number"
      handleChange={handleChange}
      value={valueInput}
      overrides={{
        ...props.overrides,
        Input: {
          component: InputNumber,
          props: {
            readOnly: props.readonly,
            step: props.step || "0.1",
            min: props.min,
            onWheel: numberInputOnWheelPreventChange,
            ...((props.overrides && props.overrides.Input && props.overrides.Input.props) || {})
          }
        },
      }}
    />
  );
};
