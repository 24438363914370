import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class ReviewStatistics {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getListReviews({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params}) {
    return this.apiService.post(`review/analytics/list`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params});
  }

  postReviews(data) {
    return this.apiService.post(`review/analytics`, {}, data);
  }

  getReviewTags() {
    return this.apiService.get(`review/analytics/tag`, {});
  }

  getAppointmentTypeDiagram(filters) {
    return this.apiService.post(`review/analytics/appointment-type`, {}, filters);
  }

  getSetRateDiagram(filters) {
    return this.apiService.post(`review/analytics/set-rate`, {}, filters);
  }

  getRedirectYandexDiagram(filters) {
    return this.apiService.post(`review/analytics/redirect-yandex`, {}, filters);
  }

  getHaveReviewTextDiagram(filters) {
    return this.apiService.post(`review/analytics/have-review-text`, {}, filters);
  }

  getRateDiagram(filters) {
    return this.apiService.post(`review/analytics/rate`, {}, filters);
  }

  getInternalRateDiagram(filters) {
    return this.apiService.post(`review/analytics/inner-rate`, {}, filters);
  }

  getRateDiagramBySource(filters) {
    return this.apiService.post(`review/analytics/map-name/rate`, {}, filters);
  }

  getUrlOpenDiagram(filters) {
    return this.apiService.post(`review/analytics/url-open`, {}, filters);
  }
  getUrlSendDiagram(filters) {
    return this.apiService.post(`review/analytics/url-send`, {}, filters);
  }

  getRegistrationLoyaltyDiagram(filters) {
    return this.apiService.post(`review/analytics/registration-loyalty`, {}, filters);
  }

  getTagsDiagram(filters) {
    return this.apiService.post(`review/analytics/tags`, {}, filters);
  }

  getNpsDiagram(filters) {
    return this.apiService.post(`review/analytics/nps`, {}, filters);
  }

  getCesDiagram(filters) {
    return this.apiService.post(`review/analytics/ces`, {}, filters);
  }

  getCsatDiagram(filters) {
    return this.apiService.post(`review/analytics/csat`, {}, filters);
  }

  getRprDiagram(filters) {
    return this.apiService.post(`appointments/analytics/rpr`, {}, filters);
  }

  getTotalReviewsDiagram(filters) {
    return this.apiService.post(`review/analytics/map-name/average-rate`, {}, filters);
  }

  reviewModeration(filters) {
    return this.apiService.post(`/review/analytics/moderation/`, {}, filters);
  }

  getNumberOfLoyaltyPointsByPatientId(id) {
    return this.apiService.get(`review/loyalty-card/${id}/balance`, {});
  }

  postAddLoyaltyPoint(patientId, reviewId) {
    return this.apiService.post(`review/loyalty-card/add-coins`, {}, { patient_id: patientId, review_id: reviewId });
  }

  getRatingByReviews(filters) {
    return this.apiService.post(`review/analytics/average-rating`, {}, filters);
  }
}

export default new ReviewStatistics();
