import React, { useCallback, useRef, useState } from "react";
import { useClickAway } from "react-use";
import moment from "moment";

import { READABLE_DATE_FORMAT, formatDateToReadableDateFormat } from "../../utils/dates";

import { Calendar } from "../calendar/Calendar";

import * as S from "./styled/DateInput.styled";

export const hasDateFormatError = value =>
  value.length !== READABLE_DATE_FORMAT.length || !moment(value, READABLE_DATE_FORMAT).isValid();

export const DateInput = ({ value, invalid, onChange }) => {
  const [touched, setTouched] = useState(false);

  const $container = useRef();

  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleChange = useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange]
  );

  const handleSelect = useCallback(
    date => {
      const value = formatDateToReadableDateFormat(date);

      setCalendarOpen(false);
      onChange(value);
    },
    [onChange]
  );

  const handleBlur = useCallback(() => {
    setTouched(value !== "");
  }, [value]);

  useClickAway($container, () => setCalendarOpen(false));

  const error = invalid || (touched && hasDateFormatError(value)) ? "error" : undefined;

  return (
    <S.Container ref={$container}>
      <S.Input
        placeholder="ДД.ММ.ГГГГ"
        guide={false}
        value={value}
        onFocus={() => setCalendarOpen(true)}
        onChange={handleChange}
        onBlur={handleBlur}
        mask={[/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
        error={error}
      />
      {calendarOpen && (
        <S.CalendarWrapper>
          <Calendar next prev onSetSelectedDate={handleSelect} />
        </S.CalendarWrapper>
      )}
    </S.Container>
  );
};
