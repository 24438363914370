import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Typography } from "../styled/CorporateTypography.styled";
import { breakpoints, corporateWrapperPalette } from "../styled/common";
import ArrowBackIcon from "../../assets/icons/corporate-portal/arrow-back.inline.svg";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
`;

const ButtonText = styled(Typography)`
  font-size: 22px;
  line-height: 33px;

  ${breakpoints.desktop} {
    font-size: 18px;
  }
  ${breakpoints.tablet} {
    font-size: 14px;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${corporateWrapperPalette.white};
`;

export const CorporateBackButton = props => {
  const { withWrapper } = props;
  const ButtonWrapper = withWrapper ? Wrapper : React.Fragment;

  return (
    <ButtonWrapper>
      <StyledLink to={props.path}>
        <ArrowBackIcon />
        <ButtonText>{props.text || "Назад"}</ButtonText>
      </StyledLink>
    </ButtonWrapper>
  );
};
