// @flow
import * as React from "react";

export const useScrollIntoView = (enabled: boolean, offset: number = 0, containerRef = null) => {
  const elementRef = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const scrollToElement = () => {
      const body: HTMLElement = (document.body: any);
      if (!elementRef.current || (!body && !containerRef)) {
        return;
      }

      const containerHeight = containerRef ? containerRef.current.scrollHeight : body.scrollHeight;
      let elementTop = elementRef.current.getBoundingClientRect().top + window.scrollY - offset;
      elementTop = Math.min(containerHeight - window.innerHeight, elementTop);
      if (containerRef) {
        setTimeout(() => containerRef.current.scrollTo(0, elementTop));
      } else {
        setTimeout(() => window.scrollTo(0, elementTop));
      }
    };

    scrollToElement();
    window.addEventListener("unload", scrollToElement);
  }, [enabled, offset]);

  return elementRef;
};
