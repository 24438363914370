// @flow
import styled from "styled-components";
import downImg from "./icons/down.svg";
import upImg from "./icons/up.svg";

export const SubpointsToggleButton: React$ComponentType<{ isOpen?: boolean }> = styled.button`
  border: none;
  width: 15px;
  height: 15px;

  background: url(${props => (props.isOpen ? upImg : downImg)});
  background-size: cover;
`;
