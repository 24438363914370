// @flow
import React from "react";
import styled from "styled-components";
import { PNormal } from "../styleguide/typography";
import { BaseInput, Container } from "./BaseInput";
import iconImg from "./icons/checkbox-disabled.svg";

const ExtendedContainer = styled(Container)`
  height: 43px;
`;

const Input = styled(PNormal)`
  outline: none;
  border: none;
  background: transparent;

  margin-left: 7px;
  width: 100%;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${iconImg});
  background-size: cover;
  flex-shrink: 0;
`;

export const NewPointInput = (props: React$ElementConfig<typeof BaseInput>) => {
  return (
    <BaseInput
      {...props}
      overrides={{
        Container: {
          component: ExtendedContainer
        },
        Input: {
          component: Input,
          props: {
            modifiers: "left"
          }
        },
        Icon: {
          component: Icon
        }
      }}
    />
  );
};
