// @flow
import React, { useEffect, useMemo } from "react";

import useReactRouter from "use-react-router";
import { DynamicForm, backToHistory } from "../common/dynamic-form/DynamicForm";
import reviewStatisticsStore from "../../stores/reviewStatisticsStore";
import _ from "lodash";
import { observer } from "mobx-react";
import { Grid, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { ContainerComponent } from "../common/dynamic-form/styled/Container.styled";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import styled from "styled-components";

const ButtonContainer = styled.div`
  margin: 40px auto 0;
  display: flex;
  gap: 80px;
  justify-content: center;
  margin-left: -16px;
    
  @media(max-width: 900px) {
      margin-top: 24px;
      gap: 40px;
  }
`;

const ContainerModerate = styled.div`
    margin: 80px;
    @media(max-width: 900px) {
        margin: 20px;
    }
`;

const IconBack = styled(ArrowCircleLeftOutlinedIcon)`
    width: 40px!important;
    height: 40px!important;
    margin-bottom: 40px;
    @media(max-width: 900px) {
        margin-bottom: 20px;
    }
`;

const LoyaltyProgramPaper = styled(Paper)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 25px;
    gap: 20px;
    margin-bottom: 20px;
`

export const ReviewStatisticsEditPage = observer(() => {
  const {
    patientId,
    loyaltyProgram
  } = reviewStatisticsStore;

  const isShowProgramLoyalty = patientId && loyaltyProgram.isRegisteredInProgram;
  const addPatientLoyaltyPoint = () => reviewStatisticsStore.addLoyaltyPoint();

  const {
    history,
    match: { params }
  } = useReactRouter();

  const moderateSchemaPatientReview = [
    {
      type: 'text',
      name: 'fullname',
      label: 'Пациент',
      disabled: true,
    },
    {
      type: 'date',
      name: 'patient_reviews__date_is_set_rate',
      label: 'Дата отправления',
      disabled: true,
    },
    {
      type: 'text',
      name: 'patient_reviews__organization__name',
      label: 'Организация',
      disabled: true,
    },
    {
      type: 'number',
      name: 'patient_reviews__rate',
      label: 'Оценка',
      disabled: true,
    },
    {
      type: 'textarea',
      name: 'patient_reviews__review_text',
      label: 'Отзыв',
      disabled: true,
    },
  ]

  const moderateSchemaReview = [
    {
      type: 'text',
      name: 'patient_name',
      label: 'Пациент',
      disabled: true,
    },
    {
      type: 'date',
      name: 'date',
      typePicker: 'dateTime',
      label: 'Дата отправления',
      disabled: true,
    },
    {
      type: 'text',
      name: 'organization__name',
      label: 'Организация',
      disabled: true,
    },
    {
      type: 'number',
      name: 'rate',
      label: 'Оценка',
      disabled: true,
    },
    {
      type: 'textarea',
      name: 'review_text',
      label: 'Отзыв',
      disabled: true,
    },
  ]

  const editSchema = [
    {
      type: 'number',
      name: 'patient_reviews__patient',
      label: 'ID пациента',
      hiddenField: true
    },
    {
      type: 'text',
      name: 'id',
      label: 'Идентификатор',
      disabled: true
    },
    {
      type: 'date',
      typePicker: 'dateTime',
      name: 'date',
      label: 'Дата отправления',
      disabled: true,
    },
    {
      type: 'text',
      name: 'patient_name',
      label: 'Пациент',
      disabled: true,
    },
    {
      type: 'text',
      name: 'patient_reviews__patient__phone_number',
      label: 'Телефон пациента',
      disabled: true,
    },
    {
      type: 'text',
      name: 'organization__name',
      label: 'Организация',
      disabled: true,
    },
    {
      type: 'number',
      name: 'rate',
      label: 'Оценка',
      min: 1,
      max: 5
    },
    {
      type: 'number',
      name: 'patient_reviews__doctor_rate',
      label: 'Оценка по доктору',
      min: 1,
      max: 5
    },
    {
      type: 'number',
      name: 'patient_reviews__administrators_rate',
      label: 'Оценка по администратору',
      min: 1,
      max: 5
    },
    {
      type: 'number',
      name: 'patient_reviews__middle_medical_staff_rate',
      label: 'Оценка по среднему персоналу',
      min: 1,
      max: 5
    },
    {
      type: 'number',
      name: 'patient_reviews__service_rate',
      label: 'Оценка по сервису',
      min: 1,
      max: 5
    },
    {
      type: 'checkbox',
      name: 'patient_reviews__is_need_callback',
      label: 'Нужен обратный звонок',
      disabled: true,
      readOnly: true
    },
    {
      type: 'textarea',
      name: 'review_text',
      label: 'Отзыв',
      disabled: true,
    },
    {
      type: 'select',
      name: 'patient_reviews__review_found',
      label: 'Отзыв найден?',
      disabled: true,
      options: [
        {
          value: 'yes',
          label: 'Да'
        },
        {
          value: 'no',
          label: 'Нет'
        },
        {
          value: 'not sure',
          label: 'Возможно'
        },
      ]
    },
    {
      type: 'textarea',
      name: 'comment',
      label: 'Коментарий сотрудника',
    },
    {
      type: 'select',
      name: 'status',
      label: 'Статус',
      options: reviewStatisticsStore.statusOptionsFields,
    },
    {
      type: 'select',
      name: 'tags__id',
      label: 'Тэг',
      multiple: true,
      valueName: 'id',
      labelName: 'name',
      getOptions: async () => {
        return await reviewStatisticsStore.getReviewTag();
      },
    },
    {
      type: 'select',
      name: 'appointment_type',
      label: 'Тип пациента',
      options: reviewStatisticsStore.appointmentTypeOptionsFields
    },
    {
      type: 'number',
      name: 'patient_reviews__is_set_loyalty_coins',
      label: 'Начислены ли баллы лояльности',
      hiddenField: true
    },
  ]

  const fetchData = async () => {
    const fields = editSchema.map((item) => item.name);
    await reviewStatisticsStore.getReview(fields);
  }

  useEffect(() => {
    (async () => {
      reviewStatisticsStore.setReviewId(params.id);
      fetchData();
    })();
  }, []);

  const data = useMemo(() => {
    return _.cloneDeep(reviewStatisticsStore.review);
  }, [reviewStatisticsStore.review]);

  const submitHandler = async (data) => {
    await reviewStatisticsStore.postReviews(data);
    backToHistory(reviewStatisticsStore.generateSearch.bind(reviewStatisticsStore), history)
  }

  const reviewModeration = async (data) => {
    await reviewStatisticsStore.reviewModeration(data);
    fetchData();
  }

  return (
    <ContainerComponent>
      {data && (
        <>
          {data.patient_reviews__review_found === 'not sure' ? (
            <ContainerModerate>
              <IconBack color="primary" onClick={() => backToHistory(reviewStatisticsStore.generateSearch.bind(reviewStatisticsStore), history)} fontSize="large" cursor="pointer" />
              <Grid container rowSpacing={3} columnSpacing={10}>
                <Grid item md={6} xs={12}>
                  <Paper style={{
                    padding: '25px',
                  }}>
                    <DynamicForm hiddenButtonBack hiddenButtonCancel hiddenButtonSave data={data} fields={moderateSchemaReview} title="Отзыв со стороннего источника" />
                  </Paper>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Paper style={{
                    padding: '25px',
                    height: '100%',
                    boxSizing: "border-box"
                  }}>
                    <DynamicForm hiddenButtonBack hiddenButtonCancel hiddenButtonSave data={data} fields={moderateSchemaPatientReview} title="Отзыв за баллы" />
                  </Paper>
                </Grid>
              </Grid>
              <ButtonContainer>
                <Button onClick={() => reviewModeration({patient_review_id: data.patient_reviews__id, review_found: 'yes'})} variant="contained" color={"success"}>Подтвердить</Button>
                <Button onClick={() => reviewModeration({patient_review_id: data.patient_reviews__id, review_found: 'no'})} variant="outlined" color="primary">Отклонить</Button>
              </ButtonContainer>
            </ContainerModerate>
          ) : (
            <>
              <Paper
                style={{
                padding: '25px',
                maxWidth: '1440px',
                margin: '20px auto'
                }}
              >
                {data && (
                  <DynamicForm
                    searchHistory={reviewStatisticsStore.generateSearch.bind(reviewStatisticsStore)}
                    data={data} submitHandler={submitHandler}
                    fields={editSchema}
                    title="Редактирование отзыва" />
                )}
              </Paper>

              {isShowProgramLoyalty && (
                <LoyaltyProgramPaper>
                <Typography>Количество начисленных баллов лояльности: {loyaltyProgram.numberOfPoints}</Typography>
                {!loyaltyProgram.isInstalledPoints && (
                  <Button variant="contained" color="primary" onClick={addPatientLoyaltyPoint}>
                    Начислить баллы
                  </Button>
                )}
                </LoyaltyProgramPaper>
              )}
            </>
          )}
        </>
      )}
    </ContainerComponent>
  );
});
