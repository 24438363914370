// @flow
import React from "react";
import styled from "styled-components";
import { HeaderContainer, Container, FormSection } from "../form/FormSection";

const ExtendedContainer = styled(Container)`
  padding: ${props => (props.isOpen && "0 0 32px") || "0"};

  @media (max-width: 768px) {
    padding: ${props => (props.isOpen && "0 0 20px") || "0"};
  }
  @media (max-width: 480px) {
    padding: ${props => (props.isOpen && "0 0 15px") || "0"};
  }
`;

const ExtendedHeaderContainer = styled(HeaderContainer)`
  margin: 0 30px;
  width: calc(100% - 60px);
  @media (max-width: 768px) {
    margin: 0 20px;
    width: calc(100% - 40px);
  }
  @media (max-width: 480px) {
    margin: 0 15px;
    width: calc(100% - 30px);
  }
`;

const ChildrenContainer = styled.div`
  margin: 30px 0 -10px;
  @media (max-width: 768px) {
    margin: 20px 0 -10px;
  }
  @media (max-width: 480px) {
    margin: 15px 0 0;
  }
`;

export const DecisionSupportFormSection = (props: React$ElementConfig<typeof FormSection>) => {
  return (
    <FormSection
      {...props}
      overrides={{
        Container: {
          component: ExtendedContainer
        },
        HeaderContainer: {
          component: ExtendedHeaderContainer
        },
        ChildrenContainer: {
          component: ChildrenContainer
        },
        ...(props.overrides || {})
      }}
    />
  );
};
