import ApiService from "../apiService";
const API_URL = process.env.EMS_API_URL || location.origin;

class Reports {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api/reports/vrt-report", null, null, API_URL);
  }

  getReportPuncture({
    filter_params,
    meta = {},
    values_params,
    order_by_params,
    annotate_mtm_params
  }) {
    return this.apiService.post(
      `puncture`,
      {},
      {
        filter_params,
        current_page: meta.current_page,
        per_page: meta.per_page,
        values_params,
        order_by_params,
        annotate_mtm_params
      },
      {},
      true,
      "",
      true
    );
  }
  getReportTransfer({
    filter_params,
    meta = {},
    values_params,
    order_by_params,
    annotate_mtm_params
  }) {
    return this.apiService.post(
      `transfer`,
      {},
      {
        filter_params,
        current_page: meta.current_page,
        per_page: meta.per_page,
        values_params,
        order_by_params,
        annotate_mtm_params
      },
      {},
      true,
      "",
      true
    );
  }
}

export default new Reports();
