import { createTheme } from "@mui/material/styles";
import { colors } from "../components/styleguide/colors";

export const THEME = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200
    }
  },
  typography: {
    allVariants: {
      fontFamily: "Graphik"
    }
  },
  palette: {
    text: {
      white: colors.white,
      black: colors.black,
      gray: colors.gray700,
      lightGray: "#8B8FA4",
      pink: colors.pink100,
      red: colors.watermelon,
      green: "#31BD2E"
    },
    wrapper: {
      white: colors.white
    },
    background: {
      layout: "#F9F4F4",
      magenta: colors.magenta
    },
    primary: {
      main: colors.pink100
    },
    success: {
      main: colors.lightMagenta
    },
    grey: {
      main: colors.gray400
    },
    black: {
      text: colors.black,
      main: colors.darkBlack
    }
  }
});
