export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
}

export const removeItemLocalStorage = (name) => {
  localStorage.removeItem(name);
}

export const getLocalStorage = (name) => {
  if (localStorage.getItem(name)) {
    return JSON.parse(localStorage.getItem(name));
  }
};