import React, { useRef, useContext, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { useStore } from "effector-react";

import { DoctorMenu } from "./DoctorMenu";
import { DoctorAvatar } from "../../doctor/DoctorAvatar";
import { ScheduleInsertContext } from "../ScheduleInsertContext";
import { ScheduleInsertToast } from "../ScheduleInsertToast";

import { useToast } from "../../toast/useToast";
import { parseMilliseconds } from "../../../utils/parseMilliseconds";
import { currentUser as currentUserStore } from "../../../stores/auth";

import favoriteDoctorIcon from "../icons/favorite-doctor.svg";
import adultDoctor from "../../../assets/icons/adultDoctor.svg";
import childrenDoctor from "../../../assets/icons/childrenDoctor.svg";
import { Popover } from "@mui/material";

const Container = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  padding-bottom: 10px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 10px;
`

const FavouriteDoctorIcon = styled(Icon)`
  right: 0;
`;

const SpecialtyIcon = styled(Icon)`
  left: 0;
`;

const PopoverStyled = styled(Popover)`
  pointer-events: none;
`;

const specialtyIconPath = (specialty) => {
  const path = {
    ADULT: adultDoctor,
    CHILDREN: childrenDoctor,
  }

  return path[specialty];
}

export const DoctorView = props => {
  const [isHovered, setIsHovered] = useState(false);
  const wrapperRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = !!anchorEl;

  const handlePopoverOpen = (event) => {
    setIsHovered(true);
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setIsHovered(false);
    setAnchorEl(null);
  };

  const { date, activeOrganization, scheduleItem, inFavourites, ...restProps } = props;

  const { doctor, schedule } = scheduleItem;

  const currentUser = useStore(currentUserStore);
  const isShowSpecializationDoctor = ['ADULT', 'CHILDREN'].includes(doctor.specialization_type);
  const isAllowedScheduleInsertWithoutTime = !!schedule.filter((item) => item.type !== "chat").length;

  const {
    openScheduleInsertModalWithoutTime,
    setScheduleStep,
    setOrganization,
    setDoctor,
    setAfterSubmit
  } = useContext(ScheduleInsertContext);

  const { showToast } = useToast();

  const openScheduleInsertWithoutTime = () => openScheduleInsertModalWithoutTime(date);

  const offScheduleEntriesCount = schedule.reduce((acc, item) => {
    if ((item.is_reserved || item.medical_file) && !item.time) {
      acc++;
    }
    return acc;
  }, 0);

  if (doctor.schedule_step % 20 !== 0 && doctor.schedule_step % 15 !== 0 && doctor.schedule_step !== 10) {
    return null;
  }

  return (
      <Container
        ref={wrapperRef}
        {...restProps}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <DoctorAvatar doctor={doctor} isHovered={isHovered} />
        {inFavourites ? <FavouriteDoctorIcon src={favoriteDoctorIcon} alt="Favorite icon" /> : ""}
        {isShowSpecializationDoctor && (
            <SpecialtyIcon src={specialtyIconPath(doctor.specialization_type)} alt="Type of doctor icon" />
        )}
        <PopoverStyled
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <DoctorMenu
            id="mouse-over-popover"
            doctor={doctor}
            allowsForeignAppointments={doctor.allows_foreign_appointments}
            isAllowedScheduleInsertWithoutTime={isAllowedScheduleInsertWithoutTime}
            openScheduleInsertWithoutTime={() => {
              handlePopoverClose();
              setScheduleStep(doctor.schedule_step ? doctor.schedule_step : 60);
              setOrganization(activeOrganization);
              setDoctor(doctor);
              setAfterSubmit(() => scheduleValues => {
                if (scheduleValues.medical_file) {
                  const insertMoment = moment(`${scheduleValues.date}`);
                  showToast(
                    <ScheduleInsertToast
                      doctor={doctor}
                      patient={scheduleValues.medical_file.patient}
                      insertMoment={insertMoment}
                      offSchedule={true}
                    />,
                    {
                      duration: parseMilliseconds("60m")
                    },
                    {
                      triggeredDoctor: currentUser.doctor,
                      doctor: doctor,
                      scheduleId: null,
                      scheduleValues
                    }
                  );
                }
              });
              openScheduleInsertWithoutTime();
            }}
            offScheduleEntriesCount={offScheduleEntriesCount}
            inFavourites={inFavourites}
          />
        </PopoverStyled>
      </Container>
  );
};
