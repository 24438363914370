// @flow
import React, { useEffect, useMemo } from "react";

import useReactRouter from "use-react-router";
import { DynamicForm, backToHistory } from "../common/dynamic-form/DynamicForm";
import patientsStore from "../../stores/patientsStore";
import _ from "lodash";
import { observer } from "mobx-react";
import { Paper } from "@mui/material";
import { ContainerComponent } from "../common/dynamic-form/styled/Container.styled";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import styled from "styled-components";
import organizationsStore from "../../stores/organizationsStore";

const IconBack = styled(ArrowCircleLeftOutlinedIcon)`
    width: 40px!important;
    height: 40px!important;
    margin-bottom: 40px;
    @media(max-width: 900px) {
        margin-bottom: 20px;
    }
`;

export const PatientsEditPage = observer(() => {

  const {
    history,
    match: { params }
  } = useReactRouter();
  
  const editSchema = [
    {
      type: 'select',
      name: 'patient__last_name',
      label: 'Фамилия',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'select',
      name: 'patient__first_name',
      label: 'Имя',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'select',
      name: 'patient__middle_name',
      label: 'Отчество',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'date',
      typePicker: 'date',
      name: 'patient__birthdate',
      label: 'Дата рождения',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'date',
      typePicker: 'date',
      name: 'created_at',
      label: 'Дата создания пациента',
      xs: 12,
      md: 3,
      sm: 6,
      disabled: true,
    },
    {
      type: 'text',
      name: 'number',
      label: 'Номер карты',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'select',
      name: 'organization__name',
      label: 'Организация',
      labelName: 'label',
      valueName: 'value',
      options: organizationsStore.organizations.map((item) => ({ value: item.id, label: item.name })),
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'text',
      name: 'badge',
      label: 'Признак пациента',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'select',
      name: 'patient__sex',
      label: 'Пол',
      labelName: 'label',
      valueName: 'value',
      options: patientsStore.sexOptions,
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'text',
      name: 'patient__email',
      label: 'E-mail',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'text',
      name: 'patient__registration_address',
      label: 'Адрес по прописке',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'text',
      name: 'patient__city',
      label: 'Город',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'text',
      name: 'patient__comment',
      label: 'Комментарий',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'text',
      name: 'patient__comment_dms',
      label: 'Комментарий по ДМС',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'date',
      typePicker: 'date',
      name: 'patient__last_review_date',
      filterFn: 'range',
      label: 'Дата последнего отзыва',
      xs: 12,
      md: 3,
      sm: 6,
    },
    {
      type: 'checkbox',
      name: 'patient__is_private_access',
      label: 'Отображать только для лечащих врачей',
      xs: 12,
      md: 3,
      sm: 6,
    },
  ]

  const fetchData = async () => {
    const fields = editSchema.map((item) => item.name);
    await patientsStore.fetchDataPatient(fields);
  }

  useEffect(() => {
    (async () => {
      patientsStore.setPatientEditId(params.id);
      fetchData();
    })();
  }, []);

  const data = useMemo(() => {
    return _.cloneDeep(patientsStore.patientEditData);
  }, [patientsStore.patientEditData]);

  const submitHandler = async (data) => {
    await patientsStore.postPatient(data);
    backToHistory(patientsStore.generateSearch.bind(patientsStore), history)
  }

  return (
    <ContainerComponent>
      {data && (
        <Paper style={{
          padding: '25px',
          maxWidth: '1440px',
          margin: '20px auto'
        }}>
          {data && (
            <DynamicForm searchHistory={patientsStore.generateSearch.bind(patientsStore)} data={data} submitHandler={submitHandler} fields={editSchema} title="Редактирование пациента" />
          )}
        </Paper>
      )}
    </ContainerComponent>
  );
});
