// @flow
import React from "react";
import styled from "styled-components";
import checkboxIcon from "./icons/checkbox.svg";
import checkboxOnIcon from "./icons/checkbox-on.svg";
import checkboxCircleIcon from "./icons/checkbox-circle.svg";
import checkboxCircleOnIcon from "./icons/checkbox-circle-on.svg";
import checkboxOnDisabledIcon from "./icons/checkbox-disabled.svg";
import { withModifiers } from "with-modifiers";
import { Tooltip } from "@mui/material";

const Container = styled.label`
  position: relative;
  padding-left: ${props => (props.inAppointmentPage ? "32px" : "24px")};
  cursor: ${props => (props.disabled ? "default" : "pointer")};

  font-size: 14px;
  line-height: 1.64;
  color: rgba(38, 38, 38, 0.6);
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: ${props => (props.disabled ? "default" : "pointer")};
`;

const Checkmark = withModifiers({
  circle: () => `
    background-image: url(${checkboxCircleIcon});
    
    ${Input}:checked ~ & {
    background-image: url(${checkboxCircleOnIcon});
  }
  `
})(
  modifier => styled.span`
  width: 18px;
  height: 18px;

  position: absolute;
  top: ${props => (props.inAppointmentPage ? "-2px" : "0")};
  left: 0;

  background-image: url(${checkboxIcon});
  background-size: contain;

  opacity: ${props => (props.disabled ? 0.3 : 1)};

  ${Input}:checked ~ & {
    background-image: url(${props => (props.disabled ? checkboxOnDisabledIcon : checkboxOnIcon)});
  }
    
  ${modifier} 
`);

export const Checkbox = (props: {
  textTooltip?: string,
  text: string,
  checked: boolean,
  disabled?: boolean,
  onChange?: any,
  checkmarkType?: string,
  inAppointmentPage?: boolean,
  style?: any,
}) => {
  const { textTooltip = "", text, checked, disabled, onChange, inAppointmentPage, style, checkmarkType } = props;
  return (
    <Tooltip title={textTooltip} placement="right">
      <Container style={style} disabled={disabled} inAppointmentPage={!!inAppointmentPage}>
        {text}
        <Input
          type="checkbox"
          checked={checked && "checked"}
          disabled={disabled && "disabled"}
          onChange={onChange}
        />
        <Checkmark modifiers={[checkmarkType]} disabled={disabled} inAppointmentPage={!!inAppointmentPage}/>
      </Container>
    </Tooltip>
  );
};
