// @flow
import { createEffect, createEvent, createStore, forward } from "effector";
import type { TAppointment_id } from "../domain/entities/Appointment";
import { tokenStore } from "./auth";
import type { AISlice } from "../domain/entities/AISlice";
import apiv2 from '../apiv2';

export const refetchAISlices = createEvent<TAppointment_id>();

const getAISlicesEffect = createEffect<{ id: ?TAppointment_id }, ?Array<AISlice>, *>({
  handler: async ({ id }) => {
    try {
      const token = tokenStore.getState();
      if (!token || !id) {
        throw new Error("Empty token or id");
      }
      return await apiv2.appointments.getAppointmentAISlices(id);
    } catch (e) {
      console.log(e);
    }
  }
});

forward({
  from: refetchAISlices.map(id => ({ id: id })),
  to: getAISlicesEffect
});

export const appointmentAISlicesStore = createStore<{
  [appointmentId: ?TAppointment_id]: Array<AISlice>
}>({});
appointmentAISlicesStore.on(getAISlicesEffect.done, (state, payload) => ({
  ...state,
  [payload.params.id || null]: payload.result
}));
