import apiv2 from '../apiv2';
import DynamicTableStore, { filtersAfterCheck } from "./dynamicTableStore";
import { action, makeObservable, observable } from "mobx";

class PrescribedServicesReportFourStore extends DynamicTableStore {
  constructor() {
    super(apiv2.prescribedServices.getPrescribedServicesReportFour.bind(apiv2.prescribedServices));
    this.enableSavingPresets(false);
  }

  async saveToFile() {
    const params = {
      filter_params: { ...filtersAfterCheck(this.filtersTable), ...filtersAfterCheck(this.globalFiltersTable) },
      values_params: this.valuesParamsTable,
      order_by_params: this.sortingTable
    };
    if (this.annotate_mtm_params) {
      params.annotate_mtm_params = this.annotate_mtm_params;
    }
    try {
      params.is_after_get = true;
      const response = await apiv2.prescribedServices.getPrescribedServicesReportFour(params);
      const link = document.createElement("a");
      link.setAttribute('href', 'https://asya.fomin-clinic.ru/api/prescribed-service-report/by-service/?request_uuid=' + response.request_uuid);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', `prescribed-service-report-by-service.csv`);
      link.click();
    } catch(e) {
      console.error(e);
      return false;
    }


  }
}

export default new PrescribedServicesReportFourStore();
