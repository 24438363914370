import { useCallback, useContext } from "react";

import { MyPatientsPageContext } from "../MyPatientsPageContext";

export const useMyPatientsTableInput = ({ cell, exitEditMode }) => {
  const { onUpdate, onCreate } = useContext(MyPatientsPageContext);

  const handleUpdate = useCallback(
    value => {
      if (!cell.multiple) {
        exitEditMode();
      }

      const data = {
        value,
        index: cell.index,
        fieldId: cell.fieldId,
        fieldType: cell.fieldType,
        appointmentId: cell.appointmentId
      };

      if (cell.id) {
        onUpdate({
          id: cell.id,
          ...data
        });
      } else {
        onCreate(data);
      }
    },
    [exitEditMode, onUpdate, onCreate, cell]
  );

  return {
    onUpdate: handleUpdate,
    value: cell.value,
    id: cell.id,
    options: cell.options,
    multiple: cell.multiple,
    cell
  };
};
