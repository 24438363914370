// @flow
import { useRef } from "react";

export const useCancelableTimeout = (callback?: () => mixed, timeout: number) => {
  const timeoutRef = useRef();

  const action = () => {
    timeoutRef.current = setTimeout(() => {
      callback && callback();
    }, timeout);
  };

  const cancel = () => {
    clearTimeout(timeoutRef.current);
  };

  return [action, cancel];
};
