import apiv2 from '../apiv2';
import DynamicTableStore from "./dynamicTableStore";
import { action, makeObservable, observable } from "mobx";
import { getLocalStorage, setLocalStorage } from "../utils/localStorageUtils";

class GeneralScheduleStore extends DynamicTableStore {
  typeScheduleName = {
    in_clinic: 'В клинике',
    chat: 'Чат',
    online: 'Онлайн',
    online_and_in_clinic: 'Онлайн и в клинике',
  }
  typeScheduleNameOptions = [
    { value: "in_clinic", label: 'В клинике'},
    { value: "chat", label: 'Чат'},
    { value: "online", label: 'Онлайн'},
    { value: "online_and_in_clinic", label: 'Онлайн и в клинике'},
  ]
  showInsertDialog = false;
  dataScheduleDialog = {
    duration: '',
  };
  densityCalendarWidth = {
    large: 260,
    medium: 200,
    small: 140,
  }
  densityCalendar = 'large';
  settingsScheduleView = {};
  settingsScheduleViewDefault = {
    doctorOrganization: {
      id: 'switch-list-label-doctor-organization',
      value: 'doctorOrganization',
      text: 'Отображать организации у врача',
      height: 34,
      checked: true,
    },
    doctorSpecialization: {
      id: 'switch-list-label-doctor-specialization',
      value: 'doctorSpecialization',
      text: 'Отображать специализации у врача',
      height: 22,
      checked: true,
    },
    doctorShortFio: {
      id: 'switch-list-label-doctor-short-fio',
      value: 'doctorShortFio',
      text: 'Короткое ФИО врача',
      checked: false,
    },
    patientBirthDate: {
      id: 'switch-list-label-patient-birth-date',
      value: 'patientBirthDate',
      text: 'Отображать дату рождения у пациента',
      checked: true,
    },
    patientComment: {
      id: 'switch-list-label-patient-comment',
      value: 'patientComment',
      text: 'Отображать комментарий к пациенту',
      checked: true,
    },
    patientShortFio: {
      id: 'switch-list-label-patient-short-fio',
      value: 'patientShortFio',
      text: 'Короткое ФИО пациента',
      checked: true,
    }
  }

  constructor() {
    super(apiv2.schedule.scheduleListWithValues.bind(apiv2.schedule),
      [
        'doctor_organization__doctor__allows_foreign_appointments',
        'doctor_organization__doctor',
        'doctor_organization__doctor__photo',
        'doctor_organization__schedule_step',
        'doctor_organization__comment',
        'doctor_organization__doctor__last_name',
        'doctor_organization__doctor__first_name',
        'doctor_organization__doctor__middle_name',
        'medical_file__patient__birthdate',
        'medical_file__patient__id',
        'doctor_organization__doctor__specializations__name',
        'doctor_organization__doctor__organizations__name',
        'doctor_organization__doctor__specialization_type',
        'doctor_organization__doctor__description',
        'doctor_organization__doctor__comment',
        'doctor_organization__organization__id',
        'is_reserved',
        'medical_file__id',
        'doctor_organization__favorites_services__id',
        'doctor_organization__favorites_services__name',
        'doctor_organization__favorites_services__ministry_of_health_code',
        'doctor_organization__favorites_services__kdf_code',
        'doctor_organization__favorites_services__price',
        'doctor_organization__favorites_services__is_chat',
        'doctor_organization__favorites_services__is_online',
        'online_link_for_patient',
        'entry_source',
        'id',
        'service__id',
        'service__name',
        'service__kdf_code',
        'num_appointments',
        'status_wa',
        'status_call_center',
        'status_wa_answer'
      ]);
    makeObservable(this, {
      changeShowInsertDialog: action,
      changeDataScheduleDialog: action,
      changeDensityCalendar: action,
      changeSettingsScheduleView: action,
      dataScheduleDialog: observable,
      densityCalendarWidth: observable,
      showInsertDialog: observable,
      typeScheduleName: observable,
      typeScheduleNameOptions: observable,
      densityCalendar: observable,
      settingsScheduleView: observable,
      settingsScheduleViewDefault: observable
    });
  }

  getSettingsScheduleView() {
    const settings = getLocalStorage("generalScheduleNewSettingsView");
    this.settingsScheduleView = settings || this.settingsScheduleViewDefault;
  }

  changeSettingsScheduleView(name) {
    this.settingsScheduleView[name].checked = !this.settingsScheduleView[name].checked;
    localStorage.removeItem('generalScheduleNewSettingsView');
    setLocalStorage('generalScheduleNewSettingsView', this.settingsScheduleView)
  }

  changeDensityCalendar(value) {
    this.densityCalendar = value;
    setLocalStorage('densityCalendar', value);
  }

  changeShowInsertDialog(value) {
    this.showInsertDialog = value;
  }

  changeDataScheduleDialog(value) {
    this.dataScheduleDialog = {...this.dataScheduleDialog, ...value};
  }
}

export default new GeneralScheduleStore();
