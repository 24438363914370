import * as React from "react";
import styled from "styled-components";
import { useStore } from "effector-react";

import { appointmentLiveBirthProbability } from "../../stores/appointmentLiveBirthProbability";

import { Fieldset, Legend } from "../form/Fieldset";
import { colors } from "../styleguide/colors";

const Percentage = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 4px solid ${colors.gray100};
  border-radius: 1px;

  &::before {
    content: " ";
    position: absolute;
    left: 0;
    width: ${({ value }) => `${value}%`};
    border-bottom: 4px solid ${({ mode }) => (mode === "print" ? colors.black : colors.magenta)};
    border-radius: 1px;
  }
`;

export const Title = styled.div`
  display: flex;
  margin-bottom: 4px;
  font-size: 17px;
  line-height: 23px;
  font-weight: 400;
`;

export const Value = styled(Title)`
  margin-left: 8px;
  color: ${colors.darkGray};
`;

export const Item = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const LegendOverride = styled(Legend)`
  top: 0px;
`;

export const LackingData = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  color: ${colors.gray700};
`;

const calculatePercentage = value => {
  const parsedValue = parseFloat(value);
  const percentage = parsedValue * 100;
  return parseFloat(percentage.toFixed(4));
};

export const Probability = ({ title, value, mode }) => (
  <Item>
    <Title>
      {title} <Value>{value ? `${calculatePercentage(value)}%` : "Недостаточно данных"}</Value>
    </Title>
    <Percentage value={value ? calculatePercentage(value) : 0} mode={mode} />
  </Item>
);

export const Chart = ({ eko_chance, eko_do_chance, vmi_chance }) => (
  <>
    <Probability title="ЭКО" value={eko_chance} />
    <Probability title="ЭКО + ДО" value={eko_do_chance} />
    <Probability title="ВМИ" value={vmi_chance} />
  </>
);

export const LiveBirthProbability = () => {
  const state = useStore(appointmentLiveBirthProbability);

  return (
    <Fieldset
      legend="Вероятность живорождения"
      overrides={{
        Legend: {
          component: LegendOverride
        }
      }}>
      {!state.eko_chance && !state.eko_do_chance && !state.vmi_chance ? (
        <LackingData>
          Заполните поля: план лечения; количество попыток ЭКО, в которых выполнена пункция.
        </LackingData>
      ) : (
        <Chart {...state} />
      )}
    </Fieldset>
  );
};
