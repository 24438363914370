import { makeAutoObservable } from "mobx";
import * as moment from "moment";
import Binder from "../../utils/binder";
import corporateNews from "../api/entities/corporateNews";
import { READABLE_DATE_FORMAT, READABLE_TIME_FORMAT_MOMENT } from "../../utils/dates";
import emptyNews from "../../assets/image/corporate-portal/emptyNews.png";

const transformDataNews = data => {
  return {
    id: parseInt(data.id),
    category: { ...data.category },
    src: data.photo || emptyNews,
    title: data.title,
    description: data.description,
    content: data.content,
    dataOfLikes: {
      isLiked: data.is_liked,
      count: data.count_likes,
      handleChangeCount(type) {
        if (type === "decrement") {
          this.count--;
        } else {
          this.count++;
        }
        this.isLiked = !this.isLiked;
      }
    },
    dataOfViews: {
      isViewed: data.is_viewed,
      count: data.count_views,
      handleIncreaseViews() {
        this.count++;
      }
    },
    date: moment(data.created_at).format(READABLE_DATE_FORMAT),
    time: moment(data.created_at).format(READABLE_TIME_FORMAT_MOMENT)
  };
};

const transformDataComments = data => {
  return {
    ...data,
    author: {
      id: data.author.id,
      fullName: `${data.author?.last_name || ""} ${data.author?.first_name || ""} ${data.author
        ?.middle_name || ""}`,
      lastName: data.author?.last_name || "",
      firstName: data.author?.first_name || "",
      middleName: data.author?.middle_name || "",
      src: data.photo || ""
    },
    date: moment(data.created_at).format(READABLE_DATE_FORMAT),
    time: moment(data.created_at).format(READABLE_TIME_FORMAT_MOMENT)
  };
};

class CorporateNewsStore {
  isInitialized = false;
  isCommentsLoading = false;
  categories = [];
  selectedCategory = {
    index: 0,
    info: { id: 0, name: "Все новости" }
  };

  resetState() {
    this.dataNews = [];
    this.detailedDataNews = null;
    this.newsComments = [];
    this.searchValue = "";
    this.commentValue = "";
  }

  constructor() {
    this.resetState();
    Binder.bind(this, CorporateNewsStore);
    makeAutoObservable(this);
  }

  async getCategories() {
    try {
      const response = await corporateNews.getAllCategories();
      this.categories = [{ id: 0, name: "Все новости" }, ...response];
    } catch (e) {
      console.error(e);
    }
  }

  async getListNews() {
    this.setIsInitialized(false);
    try {
      const response = await corporateNews.getListNews({
        categoryId: this.selectedCategory.info.id,
        searchValue: this.searchValue
      });
      const transformData = response.map(item => transformDataNews(item));

      this.setDataNews(transformData);
    } catch (e) {
      console.error(e);
    }
    this.setIsInitialized(true);
  }

  async getNewsByNewsId(newsId) {
    this.setIsInitialized(false);
    try {
      const response = await corporateNews.getNewsByNewsId(newsId);
      const transformData = transformDataNews(response);

      this.setDetailedNews(transformData);
      if (!transformData.dataOfViews.isViewed) {
        await corporateNews.setReadNews(newsId);
        this.detailedDataNews.dataOfViews.handleIncreaseViews();
      }
    } catch (e) {
      console.error(e);
    }
    this.setIsInitialized(true);
  }

  async getCommentsByNewsId(newsId) {
    this.setIsCommentsLoading(true);
    try {
      const response = await corporateNews.getCommentsByNewsId(newsId);
      const transformData = response.map(item => transformDataComments(item));

      this.setNewsComments(transformData);
    } catch (e) {
      console.error(e);
    }
    this.setIsCommentsLoading(false);
  }

  setIsInitialized(flag) {
    this.isInitialized = flag;
  }

  setIsCommentsLoading(flag) {
    this.isCommentsLoading = flag;
  }

  setDataNews(data) {
    this.dataNews = data;
  }

  setDetailedNews(data) {
    this.detailedDataNews = data;
  }

  setNewsComments(comments) {
    this.newsComments = comments;
  }

  async changeSelectedCategory(key) {
    this.selectedCategory = {
      index: key,
      info: this.categories[key]
    };
    await this.getListNews();
  }

  async changeSearchValue(value) {
    if (this.searchValue === value) {
      return;
    }

    this.searchValue = value;
    await this.getListNews();
  }

  changeCommentValue(value) {
    this.commentValue = value;
  }

  async changeLikeCounter({ newsId, data }) {
    try {
      let typeOfChange;
      if (data.isLiked) {
        await corporateNews.setDislikeNews(newsId);
        typeOfChange = "decrement";
      } else {
        await corporateNews.setLikeNews(newsId);
        typeOfChange = "increment";
      }

      data.handleChangeCount(typeOfChange);
    } catch (e) {
      console.error(e);
    }
  }

  async postComment(newsId) {
    try {
      const response = await corporateNews.postComment(newsId, this.commentValue);
      this.newsComments.push(transformDataComments(response));
      this.changeCommentValue("");
    } catch (e) {
      console.error(e);
    }
  }
}

export default new CorporateNewsStore();
