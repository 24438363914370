// @flow

export opaque type DateString = string; // YYYY-MM-DD
export opaque type DateStringWithSlash = string; // DD/MM/YY

export function getDateString(date: moment$Moment): DateString {
  return date.format("YYYY-MM-DD");
}

export function getDateStringWithSlash(date: moment$Moment): DateStringWithSlash {
  return date.format("DD/MM/YY");
}
