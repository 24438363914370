import {useState, useEffect} from "react";

export const useApiRequest = (asyncCallback, dependsArray = []) => {
  const [ data, setData ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const result = await asyncCallback();
        setError(null);
        setData(result);
      } catch(e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, dependsArray);

  return {
    data,
    isLoading,
    error
  };
};
