import React, { useRef, useState, useContext } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import _ from "lodash";
import { useStore } from "effector-react";
import useReactRouter from "use-react-router";
import MuiTooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { ScheduleInsertContext } from "./ScheduleInsertContext";
import { ScheduleInsertToast } from "./ScheduleInsertToast";
import { DoctorView } from "./doctor-view/DoctorView";
import { useToast } from "../toast/useToast";
import { useCalcWidthContainer } from "./useCalcWidthContainer";

import { activeOrganizationStore } from "./fullScheduleStore";
import { currentUser as currentUserStore } from "../../stores/auth";

import { colors } from "../styleguide/colors";
import { HeadingH4 } from "../styleguide/typography";

import { parseMilliseconds } from "../../utils/parseMilliseconds";
import { getPatientFullName } from "../../domain/services/patient";

export const GeneralScheduleCardList = styled.div`
  display: flex;
  position: relative;
  flex: auto;
  overflow: hidden;
  @media (max-width: 768px) {
    .general-schedule-slider .slick-list, .slick-track {
      margin: 0;
    }
  }
`;

const GeneralScheduleCardContainer = styled.div`
  background-color: ${colors.white};
  display: inline-flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 18px 0 0;
  align-items: center;
  flex-shrink: 0;
`;

const GeneralScheduleCardTitle = styled(HeadingH4)`
  align-self: flex-start;
  padding: 0 22px;
`;

const DoctorsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: auto;
  padding: 22px 0 18px;
  max-width: ${props => props?.maxWidth || '1440px'};
`;

const DoctorSchedule = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
  list-style-type: none;
  flex-shrink: 0;
  width: 60px;
  height: 1040px;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const DoctorsAvatars = styled.div`
  display: flex;
  padding: 0 22px;
  width: fit-content;
`;

const TimeSlotsScrollComponent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  padding: 0 22px;
  width: fit-content;
`;

const TimeSlots = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const PlaceholderCell = styled.div`
    box-sizing: border-box;
    height: 18px;
    margin: 1px 0;
    width: 100%;
    border: 1px solid rgba(151, 151, 151, 0.2);
    border-radius: 2px;
`;

const TimeSlot = styled.li`
  position: absolute;
  top: ${props => `${props.top}px`};
  height: ${props => `${props.height}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${ colors.gray200 };
  ${props => (props.reserved ? `border-color: rgba(151, 151, 151, 0.2);` : "")}
  border-radius: 2px;
  text-align: center;
  width: 60px;
  ${props =>
    !props.reserved && props.allowsForeignAppointments
      ? `
    cursor: pointer;
    transition: color 0.1s linear, border-color 0.1s linear;
    &:hover {
        border-color: ${colors.magenta};
        color: ${colors.magenta};
    }
    `
      : ""}
  box-sizing: border-box;
  font-family: "Graphik LCG", monospace;
    
  ${props => props.disabled && !props.reserved && css`
    cursor: default;
    background: #FFF;
    border-color: rgba(151, 151, 151, 0.2);
    color: rgba(0, 0, 0, 0.3);

    &:hover {
      border-color: rgba(151, 151, 151, 0.2);
      color: rgba(0, 0, 0, 0.3);
    }
  `}
  ${props => (props.reserved ? "background-color: #F8E7E7;" : "background-color: #FFF;")}
  ${props => (props.reserved && props.hasMedicalFile ? "cursor: pointer;" : "")}

  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;

const TimeSlotVariants = {
  "10": styled(TimeSlot)`
    font-size: 8px;
    line-height: 8px;

    &:not(:last-child) {
      margin-bottom: 1px;
    }
  `,
  "15": styled(TimeSlot)`
    font-size: 9px;
    line-height: 9px;
  `,
  "20": styled(TimeSlot)`
    font-size: 12px;
    line-height: 12px;
  `,
  "30": styled(TimeSlot)`
    font-size: 12px;
    line-height: 12px;
  `,
  "40": styled(TimeSlot)`
    font-size: 12px;
    line-height: 12px;
  `,
  "45": styled(TimeSlot)`
    font-size: 12px;
    line-height: 12px;
  `,
  "60": styled(TimeSlot)`
    font-size: 12px;
    line-height: 12px;
  `,
  "80": styled(TimeSlot)`
    font-size: 12px;
    line-height: 12px;
  `,
  "90": styled(TimeSlot)`
    font-size: 12px;
    line-height: 12px;
  `,
  "120": styled(TimeSlot)`
    font-size: 12px;
    line-height: 12px;
  `,
  "180": styled(TimeSlot)`
    font-size: 12px;
    line-height: 12px;
  `
};

const TooltipComponent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const TooltipBubbleComponent = styled.div`
  display: ${props => (props.visible ? "block" : "none")};
  opacity: ${props => (props.visible ? 1 : 0)};
  position: absolute;
  height: max-content;
  left: ${props => (props.isLeft ? "-5px" : "calc(100% + 5px)")};
`;

const TooltipBubbleOverflowFix = styled.div`
  position: fixed;
  transform: ${props => (props.isLeft ? "translateY(-50%) translateX(-100%)" : "translateY(-50%)")};
  ${props => (props.isLeft ? "padding-right: 4px" : "padding-left: 4px")};
  z-index: 2;
`;

const TooltipBubbleContentContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  max-width: 300px;
  padding: 10px 16px;
  font-size: 11px;
  line-height: 11px;
  background: #fff;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.080305));
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  box-sizing: border-box;

  &::before {
    position: absolute;
    ${props => (props.isLeft ? "right: -16px" : "left: -16px")};
    content: "";
    width: 0;
    height: 0;
    border: 8px solid transparent;
    ${props => (props.isLeft ? "border-left-color: #e9e9e9;" : "border-right-color: #e9e9e9")};
  }

  &::after {
    position: absolute;
    ${props => (props.isLeft ? "right: -12px" : "left: -12px")};
    content: "";
    width: 0;
    height: 0;
    border: 6px solid transparent;
    ${props => (props.isLeft ? "border-left-color: #fff;" : "border-right-color: #fff")};
  }
`;

const Tooltip = props => {
  const { children, text, timeout = 500 } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipReversed, setTooltipReversed] = useState(false);
  const tooltipBubbleRef = useRef(null);
  const tooltipBubbleContentRef = useRef(null);

  let showTimeout = null;

  return (
    <TooltipComponent
      onMouseOver={() => {
        if (tooltipBubbleRef.current && tooltipBubbleContentRef.current) {
          tooltipBubbleRef.current.style.display = "block";
          const { x } = tooltipBubbleRef.current.getBoundingClientRect();
          const { width: bcWidth } = tooltipBubbleContentRef.current.getBoundingClientRect();
          const { x: cx, width: cWidth } = document
            .querySelector(".slick-slider")
            .getBoundingClientRect();
          tooltipBubbleRef.current.style.display = "";
          showTimeout = setTimeout(() => {
            setTooltipReversed(x + bcWidth + 10 - cx > cWidth - 60);
            setTooltipVisible(true);
          }, timeout);
        }
      }}
      onMouseOut={() => {
        clearTimeout(showTimeout);
        setTooltipVisible(false);
        setTooltipReversed(false);
      }}>
      {children}
      <TooltipBubbleComponent
        ref={tooltipBubbleRef}
        visible={tooltipVisible}
        isLeft={tooltipReversed}>
        <TooltipBubbleOverflowFix isLeft={tooltipReversed}>
          <TooltipBubbleContentContainer ref={tooltipBubbleContentRef} isLeft={tooltipReversed}>
            {text}
          </TooltipBubbleContentContainer>
        </TooltipBubbleOverflowFix>
      </TooltipBubbleComponent>
    </TooltipComponent>
  );
};

const ReservedTooltipBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReservedTooltipTitle = styled.div`
  font-size: 11px;
  line-height: 16px;
  color: ${colors.black};
  min-width: max-content;
`;

const ReservedTooltipComment = styled.div`
  font-size: 11px;
  line-height: 16px;
  color: #7d7d7d;
`;

const TooltipWrapper = styled.div`
    display: flex;
    color: #595959;
    opacity: 0.3;
    margin-left: 2px;
    
    svg {
        width: 14px;
        height: 14px;
    }
`;

const TimeSlotsScroll = props => {
  return (
    <TimeSlotsScrollComponent>
      <TimeSlots>{props.children}</TimeSlots>
    </TimeSlotsScrollComponent>
  );
};

export const GeneralScheduleCard = props => {
  const { favouriteDoctors } = props;
  const { date, doctors } = props.cardData;
  const { history } = useReactRouter();
  const currentUser = useStore(currentUserStore);
  const activeOrganization = useStore(activeOrganizationStore);
  const {
    openScheduleInsertModalWithTime,
    setScheduleStep,
    setOrganization,
    setDoctor,
    setAfterSubmit
  } = useContext(ScheduleInsertContext);
  const maxWidthDoctorList = useCalcWidthContainer(props.containerRef);

  const { showToast } = useToast();

  const inFavourites = id =>
    currentUser?.doctor?.id === id
      ? true
      : favouriteDoctors ? favouriteDoctors.find(favouriteDoctor => favouriteDoctor.id === id) : false;

  const sortDoctors = () => {
    if (doctors.length > 1) {
      doctors.sort((doctor1, doctor2) => {
        const aValue = doctor1.doctor.last_name.toLowerCase();
        const bValue = doctor2.doctor.last_name.toLowerCase();

        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      });
    }
  };

  const currentDoctorIndex = doctors.findIndex(
    doctorItem => doctorItem.doctor && doctorItem.doctor.id === currentUser.doctor.id
  );
  if (currentDoctorIndex >= 0) {
    const currentDoctor = doctors.splice(currentDoctorIndex, 1)[0];
    sortDoctors();
    doctors.unshift(currentDoctor);
  } else {
    sortDoctors();
  }

  return (
    <GeneralScheduleCardContainer>
      <GeneralScheduleCardTitle>
        {moment(date, "YYYY-MM-DD")
          .format(doctors.length > 2 ? "DD MMMM, dd" : "DD MMM, dd")
          .replace(/\./g, "")}
      </GeneralScheduleCardTitle>
      <DoctorsList maxWidth={maxWidthDoctorList}>
        <DoctorsAvatars>
          {doctors.map((doctorScheduleItem, i) => (
            <DoctorView
              key={i}
              date={date}
              activeOrganization={activeOrganization}
              scheduleItem={doctorScheduleItem}
              inFavourites={inFavourites(doctorScheduleItem.doctor.id)}
            />
          ))}
        </DoctorsAvatars>
        <TimeSlotsScroll>
          {doctors.map((doctorScheduleItem, i) => {
            const { doctor, schedule } = doctorScheduleItem;

            if (doctor.schedule_step % 20 !== 0 && doctor.schedule_step % 15 !== 0 && doctor.schedule_step !== 10) {
              return null;
            }

            const now = new Date();
            const date = schedule.length ? schedule[0].date : moment(now).format("YYYY-MM-DD");
            const startDate = new Date(`${date} 08:00`);
            const endDate = new Date(`${date} 21:00`);
            const minuteSize = 4/3;
            const scheduleStep = doctor.schedule_step ? doctor.schedule_step : 60;

            const cells = Array.from({length: moment(endDate).diff(startDate, "m") / 15}).map((_, i) => <PlaceholderCell key={i}/>);

            const timeSlots = schedule.filter(scheduleItem => scheduleItem.type !== "chat" && scheduleItem.time).map(scheduleItem => {
              const scheduleMoment = moment(`${scheduleItem.date} ${scheduleItem.time}`);
              return {
                reserved:
                  (scheduleItem && (scheduleItem.is_reserved || scheduleItem.medical_file)) ||
                  false,
                disabled: scheduleMoment.isSameOrBefore(now),
                scheduleItem: scheduleItem,
                top: scheduleMoment.diff(startDate, "minutes") * minuteSize,
                height: scheduleStep * minuteSize,
                time: scheduleMoment.format("HH:mm")
              };
            });

            return (
              <DoctorSchedule key={i}>
                {cells}
                {timeSlots.map((slot, i) => {
                  const isTechnical = slot.scheduleItem?.is_technical;
                  const medicalFile = slot.scheduleItem?.medical_file;
                  const TimeSlot =
                    TimeSlotVariants[scheduleStep];

                  const closestItemWithAppointment = _.find(
                    schedule,
                    item => item.is_reserved || item.medical_file,
                    schedule.findIndex(
                      scheduleItem => scheduleItem.time === slot.scheduleItem.time
                    )
                  );

                  const handleClickTimeSlot = () => {
                    if (slot.reserved && medicalFile) {
                      if (!window.ReactNativeWebView) {
                        const url =
                          window.location.origin +
                          `/appointment/new/${slot.scheduleItem.medical_file.id}`;
                        window.open(url);
                      } else {
                        history.push(`/appointment/new/${slot.scheduleItem.medical_file.id}`);
                      }
                    }

                    if (!slot.disabled && !slot.reserved && doctor.allows_foreign_appointments) {
                      setScheduleStep(doctor.schedule_step ? doctor.schedule_step : 60);
                      setOrganization(activeOrganization);
                      setDoctor(doctor);
                      setAfterSubmit(() => scheduleValues => {
                        if (scheduleValues.medical_file) {
                          const insertMoment = moment(
                            `${scheduleValues.date} ${scheduleValues.time}`
                          );
                          showToast(
                            <ScheduleInsertToast
                              doctor={doctor}
                              patient={scheduleValues.medical_file.patient}
                              insertMoment={insertMoment}
                            />,
                            {
                              duration: parseMilliseconds("60m")
                            },
                            {
                              triggeredDoctor: currentUser.doctor,
                              doctor: doctor,
                              scheduleId: slot.scheduleItem.id,
                              scheduleValues
                            }
                          );
                        }
                      });
                      openScheduleInsertModalWithTime(
                        slot.scheduleItem,
                        closestItemWithAppointment
                      );
                    }
                  };

                  const TimeSlotComponent = (
                    <TimeSlot
                      top={slot.top}
                      height={slot.height}
                      allowsForeignAppointments={doctor.allows_foreign_appointments}
                      reserved={slot.reserved}
                      disabled={slot.disabled}
                      hasMedicalFile={medicalFile}
                      key={i}
                      onClick={handleClickTimeSlot}>
                      {slot.time}
                      {
                        isTechnical && (
                          <TooltipWrapper>
                            <MuiTooltip title={`${slot.time ? `[${slot.time}] ` : ""}в МИС(1с) слот помечен как "/-----/"`}>
                              <HelpOutlineIcon/>
                            </MuiTooltip>
                          </TooltipWrapper>
                        )
                      }
                    </TimeSlot>
                  );

                  return !doctor.allows_foreign_appointments ? (
                    <MuiTooltip key={i} placement={"right"} title={"Запись только через регистратуру"}>
                      {TimeSlotComponent}
                    </MuiTooltip>
                  ) : slot.reserved ? (
                    <MuiTooltip
                      key={i}
                      placement={"right"}
                      enterDelay={200}
                      title={
                        <ReservedTooltipBody>
                          <ReservedTooltipTitle>
                            {slot.scheduleItem.medical_file
                              ? getPatientFullName(slot.scheduleItem.medical_file.patient)
                              : slot.scheduleItem.patient_full_name}
                            {slot.scheduleItem.medical_file
                              ? `, ${moment(
                                slot.scheduleItem.medical_file.patient.birthdate
                              ).format("DD.MM.YYYY")}`
                              : ""}
                          </ReservedTooltipTitle>
                          <ReservedTooltipComment>
                            {slot.scheduleItem.comment}
                          </ReservedTooltipComment>
                        </ReservedTooltipBody>
                      }>
                      {TimeSlotComponent}
                    </MuiTooltip>
                  ) : (
                    TimeSlotComponent
                  );
                })}
              </DoctorSchedule>
            );
          })}
        </TimeSlotsScroll>
      </DoctorsList>
    </GeneralScheduleCardContainer>
  );
};
