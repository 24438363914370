// @flow
import React from "react";
import styled from "styled-components";
import { Paper } from "@mui/material";

export const ContainerComponent = styled.div`
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: ${props => props.fullHeight ? `100%` : `calc(100% - 35px)`};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const DynamicFormContainer = ({
  style,
  children
}) => {
  const styles = {
    padding: "25px", maxWidth: "1440px", width: '100%', boxSizing: 'border-box', margin: "20px auto",
    ...style,
  }
  return !children ? null : (
    <ContainerComponent>
      <Paper style={styles}>{children}</Paper>
    </ContainerComponent>
  );
};
