// @flow
import React, { useMemo } from "react";
import styled from "styled-components";
import organizationsStore from "../../stores/organizationsStore";
import { MaterialTable } from "../common/MaterialTable";
import { observer } from "mobx-react";
import { DynamicFilters } from "../common/DynamicFilters";
import { Paper } from "@mui/material";
import prescribedServicesReportTwoStore from "../../stores/prescribedServicesReportTwoStore";

const Container = styled.div`
    padding: 24px 27px 30px 30px;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media(max-width: 768px) {
        padding: 24px 0 30px 0;
    }
`;

export const PrescribedServicesReportTwoPage = observer(() => {
  const [isInitialized, setIsInitialized] = React.useState();

  const changeHandler = (filters) => {
    prescribedServicesReportTwoStore.setGlobalFiltersTable(filters);
  }


  const afterInitializeParams = async (callback) => {
    callback();
    await organizationsStore.getOrganizations();
    setIsInitialized(true);
  }

  const topToolbarCustomActions = [
    {
      key: "save_to_csv",
      options: {
        color: "success",
        variant: "outlined",
        onClick: () => prescribedServicesReportTwoStore.saveToFile()
      },
      text: "Скачать CSV"
    }
  ];

  const dynamicAllFilters = useMemo(() => {
    return [
      {
        type: 'date',
        filterFn: 'range',
        typePicker: 'date',
        name: 'created_at',
        label: 'Дата создания назначения',
        clearable: true,
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'date',
        filterFn: 'range',
        typePicker: 'date',
        name: 'planned_finish_at',
        label: 'Дата планируемого выполнения назначения',
        clearable: true,
        xs: 12,
        md: 3,
        sm: 6,
      },
      {
        type: 'select',
        filterFn: 'in',
        name: 'organization__name',
        label: 'Организация',
        labelName: 'label',
        valueName: 'value',
        options: organizationsStore.organizations.map((item) => ({ value: item.name, label: item.name })),
        xs: 12,
        md: 3,
        sm: 6,
      },
    ]
  }, [organizationsStore.organizations]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'doctor_name',
        header: 'ФИО',
        size: 250,
      },
      {
        accessorKey: 'pres_serv_count',
        header: 'кол-во назначенных',
        Cell: ({ row }) => row.original.pres_serv_count ? row.original.pres_serv_count.toLocaleString() : 0,
        size: 200,
      },
      {
        accessorKey: 'completed_pres_serv_count',
        header: 'кол-во выполненных',
        Cell: ({ row }) => row.original.completed_pres_serv_count ? row.original.completed_pres_serv_count.toLocaleString() : 0,
        size: 200,
      },
      {
        accessorKey: 'cr_count',
        header: 'процент выполненных',
        Cell: ({ row }) => row.original.cr_count ? row.original.cr_count.toLocaleString() : 0,
        size: 200,
      },
      {
        accessorKey: 'pres_serv_price',
        header: 'потенциальная выручка (стоимость назначений)',
        Cell: ({ row }) => row.original.pres_serv_price ? row.original.pres_serv_price.toLocaleString() : 0,
        size: 200,
      },
      {
        accessorKey: 'completed_pres_serv_price',
        header: 'выручка по выполненным',
        Cell: ({ row }) => row.original.completed_pres_serv_price ? row.original.completed_pres_serv_price.toLocaleString() : 0,
        size: 200,
      },
      {
        accessorKey: 'cr_price',
        header: 'процент от выручки',
        Cell: ({ row }) => row.original.cr_price ? row.original.cr_price.toLocaleString() : 0,
        size: 200,
      },
      {
        accessorKey: 'service_count_by_patient',
        header: 'услуг на пациента',
        Cell: ({ row }) => row.original.service_count_by_patient ? row.original.service_count_by_patient.toLocaleString() : 0,
        size: 200,
      },
      {
        accessorKey: 'service_price_by_patient',
        header: 'выручка на пациента',
        Cell: ({ row }) => row.original.service_price_by_patient ? row.original.service_price_by_patient.toLocaleString() : 0,
        size: 200,
      },
    ],
    [],
  );

  return (
    <>
      <Container>
        {!!isInitialized && (
          <DynamicFilters
            hiddenButtonSave
            data={prescribedServicesReportTwoStore.globalFiltersTable}
            hiddenButtonCancel
            titleForm={"Фильтры"}
            changeHandler={changeHandler}
            submitHandler={changeHandler}
            nameLocalStorageSaveFilters={"prescribedServicesReportTwoPage"}
            disabledOptions={['date']}
            defaultFilters={['organization__name__in', 'created_at__range', 'planned_finish_at__range']}
            defaultData={{organization__name__in: [], created_at__lte: '', created_at__gte: '', planned_finish_at__lte: '', planned_finish_at__gte: ''}}
            isNeedAdvancedFilters={true}
            allFilters={dynamicAllFilters} />
        )}
        <Paper sx={{
          overflow: 'hidden',
        }}>
          <MaterialTable
            disableHeightResizing
            notPadding={true}
            afterInitializeParams={afterInitializeParams}
            enableColumnFilters={false}
            store={prescribedServicesReportTwoStore}
            columns={columns}
            topToolbarCustomActions={topToolbarCustomActions}
          />
        </Paper>
      </Container>
    </>
  );
});
