// @flow
import React, { useEffect, useMemo } from "react";

import useReactRouter from "use-react-router";
import { DynamicForm, backToHistory } from "../common/dynamic-form/DynamicForm";
import surveyResultStore from "../../stores/surveyResultStore";
import { observer } from "mobx-react";
import { Accordion, AccordionSummary, Paper } from "@mui/material";
import { ContainerComponent } from "../common/dynamic-form/styled/Container.styled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { colors } from "../styleguide/colors";


const SurveyResultTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const SurveyResultBlock = styled.div`
  margin-top: 60px;
`;

const SurveyValueTextValue = styled.div`
    font-size: 14px;
    line-height: 18px;
`;

const SurveyValueText = styled.div`
    font-size: 14px;
    line-height: 18px;
`;

const SurveyValueTextBold = styled(SurveyValueText)`
    font-weight: 500;
`;

const SurveyValueBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 10px;
    
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.gray700};
    
  &:last-child {
      margin-bottom: 0;
      border-bottom: none;
  }
`;


export const SurveyEmployeeEditPage = observer(() => {
  const {
    history,
    match: { params }
  } = useReactRouter();


  const editSchema = [
    {
      type: 'text',
      name: 'id',
      label: 'Идентификатор',
      disabled: true,
      hiddenField: true
    },
    {
      type: 'text',
      name: 'fullName',
      label: 'ФИО',
      disabled: true,
    },
    {
      type: 'number',
      name: 'rate',
      label: 'Оценка',
      disabled: true,
    },
    {
      type: 'text',
      name: 'organizations',
      label: 'Организации',
      disabled: true,
    },
    {
      type: 'text',
      name: 'specialities',
      label: 'Специализации',
      disabled: true,
    },
    {
      type: 'select',
      name: 'status',
      label: 'Статус',
      options: surveyResultStore.statusResult
    },
  ]

  const fetchData = async () => {
    await surveyResultStore.getResultById();
  }

  useEffect(() => {
    (async () => {
      surveyResultStore.setSurveyResultId(params.id);
      fetchData();
    })();
  }, []);

  const data = useMemo(() => {
    if (surveyResultStore.surveyResultData) {
      return {
        fullName: `${surveyResultStore.surveyResultData.doctor?.last_name} ${surveyResultStore.surveyResultData.doctor?.first_name} ${surveyResultStore.surveyResultData.doctor?.middle_name}`,
        rate: surveyResultStore.surveyResultData.rate,
        specialities: surveyResultStore.surveyResultData.doctor.specialities.map((item) => item.name).join(', '),
        organizations: surveyResultStore.surveyResultData.doctor.organizations.map((item) => item.name).join(', '),
        status: surveyResultStore.surveyResultData.status,
        id: surveyResultStore.surveyResultData.id,
      };
    }
    return null;

  }, [surveyResultStore.surveyResultData]);

  const valuesSteps = useMemo(() => {
    if (surveyResultStore.surveyResultData) {
      return surveyResultStore.surveyResultData.survey.steps.reduce((acc, step) => {
        const filterFields = step.fields.filter((field) => {
          const value = surveyResultStore.surveyResultData.values.find((item) => item.field === field.id)?.value;
          return value ? !!JSON.parse(value) : false;
        });
        if (filterFields.length) {
          acc.push({
            id: step.id,
            name: step.name,
            fields: filterFields.map((field) => {
                const value = surveyResultStore.surveyResultData.values.find((item) => item.field === field.id)?.value;
                return {
                  name: field.name,
                  value: JSON.parse(value)
                }
              })
          })
        }
        return acc;
      }, [])

    }
    return null;

  }, [surveyResultStore.surveyResultData]);

  const submitHandler = async (data) => {
    await surveyResultStore.postSurveyResult(data);
    backToHistory(surveyResultStore.generateSearch.bind(surveyResultStore), history)
  }

  return (
    <ContainerComponent>
      {data && (
        <Paper
          style={{
            padding: '25px',
            maxWidth: '1440px',
            margin: '20px auto'
          }}
        >
          {data && (
            <DynamicForm
              searchHistory={surveyResultStore.generateSearch.bind(surveyResultStore)}
              data={data} submitHandler={submitHandler}
              fields={editSchema}
              title="Просмотр результатов анкеты" />
          )}

          <SurveyResultBlock>
            <SurveyResultTitle>
              {surveyResultStore.surveyResultData.survey.name}
            </SurveyResultTitle>
            {valuesSteps.map((step) => (
              <Accordion key={step.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {step.name}
                </AccordionSummary>
                <AccordionDetails>
                  {step.fields.map((field) => (
                    <SurveyValueBlock key={field.name}>
                      <SurveyValueText>
                        <SurveyValueTextBold>Вопрос:</SurveyValueTextBold> {field.name}:
                      </SurveyValueText>
                      <SurveyValueTextValue>
                        <SurveyValueTextBold>Ответ:</SurveyValueTextBold> {field.value}
                      </SurveyValueTextValue>
                    </SurveyValueBlock>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </SurveyResultBlock>
        </Paper>
      )}
    </ContainerComponent>
  );
});
