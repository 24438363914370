// @flow
import type { Patient } from "../entities/Patient";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const getPatientFullName = (patient: Patient): string => {
  return [patient.last_name, patient.first_name, patient.middle_name].join(" ");
};

export const getPatientLastNameWithInitials = (patient: Patient): string => {
  const first_nameLetter = patient.first_name.length > 0 && patient.first_name[0];
  const middle_nameLetter = patient.last_name.length > 0 && patient.middle_name[0];
  return `${patient.last_name} ${first_nameLetter ? first_nameLetter + "." : ""}${
    middle_nameLetter ? middle_nameLetter + "." : ""
  }`;
};

export const getAgeByBirthDate = (patient: Patient): ?number => {
  if (!patient.birthdate) {
    return;
  }
  const birthdate = dayjs(new Date(patient.birthdate));

  return dayjs().diff(birthdate, "year");
};

export const getMonthAgeByBirthDate = (patient: Patient): ?number => {
  if (!patient.birthdate) {
    return;
  }
  const birthdate = dayjs(new Date(patient.birthdate));

  return dayjs().diff(birthdate, "month") % 12;
};

export const formatPatientBirthDate = (patient: Patient): string => {
  return dayjs.utc(patient.birthdate).format("DD.MM.YYYY");
};
