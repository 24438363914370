import * as React from "react";

import { PreloaderBody } from "../prealoader/PreloaderBody";

import * as S from "./MyPatientsTablePlaceholder.styled";

export const MyPatientsTablePlaceholder = () => {
  return (
    <div>
      <PreloaderBody>
        <S.Table>
          <thead>
            <tr>
              <S.Column>
                <S.PlaceholderCol style={{ width: 34 }} />
              </S.Column>
              <S.Column>
                <S.PlaceholderCol />
              </S.Column>
              <S.Column>
                <S.PlaceholderCol />
              </S.Column>
              <S.Column>
                <S.PlaceholderCol />
              </S.Column>
              <S.Column>
                <S.PlaceholderCol />
              </S.Column>
            </tr>
          </thead>
          <tbody>
            {[...new Array(4).keys()].map(id => (
              <tr key={id}>
                <S.Cell>
                  <S.PlaceholderCol style={{ width: 34 }} />
                </S.Cell>
                <S.Cell>
                  <S.PlaceholderCol />
                </S.Cell>
                <S.Cell>
                  <S.PlaceholderCol />
                </S.Cell>
                <S.Cell>
                  <S.PlaceholderCol />
                </S.Cell>
                <S.Cell>
                  <S.PlaceholderCol />
                </S.Cell>
              </tr>
            ))}
          </tbody>
        </S.Table>
      </PreloaderBody>
    </div>
  );
};
