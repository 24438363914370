// @flow
import React from "react";
import styled from "styled-components";
import { withModifiers } from "with-modifiers";
import pathLogoFominWithText from "../../assets/logo/logoFominWithName.svg";
import pathLogoFominWithoutText from "../../assets/logo/logoFominWithoutName.svg";
import pathLogoRassvetWithText from "../../assets/logo/logoRassvetWithName.svg";
import pathLogoRassvetWithoutText from "../../assets/logo/logoRassvetWithoutName.svg";
import { CLINIC_CODE } from "../../stores/reviewStore";

const Logo = withModifiers({
  filterBW: () => `
    filter: brightness(0); 
  `
})(
  modifier => styled.div`
    ${modifier};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    background: url(${props => props.path}) no-repeat center / contain;
    grid-area: logo;
  `
);

type IClinicLogo = {
  clinic?: CLINIC_CODE.fomina | CLINIC_CODE.rassvet,
  isWithText?: boolean,
  width?: string,
  height?: string,
  isFilterBW?: boolean
};

export const ClinicLogo: React.FC<IClinicLogo> = ({
  name = CLINIC_CODE.fomina,
  isShowName = false,
  isFilterBW = false,
  /** Нужна подложка под контейнер которого будет подстраиваться svg и избавиться от inline */
  size = { width: "245px", height: "90px" },
}) => {
  const pathByClinic = {
    [CLINIC_CODE.fomina]: isShowName ? pathLogoFominWithText : pathLogoFominWithoutText,
    [CLINIC_CODE.rassvet]: isShowName ? pathLogoRassvetWithText : pathLogoRassvetWithoutText
  };

  return (
    <Logo
      {...size}
      path={pathByClinic[name]}
      modifiers={[isFilterBW && "filterBW"]}
    />
  );
};
