import React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

import readIcon from "./icons/read.inline.svg";

const ReadIcon = styled(readIcon)`
  margin-left: 10px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: ${colors.gray700};

  &:hover {
    color: ${colors.magenta};

    ${ReadIcon} {
      g {
        stroke: ${colors.magenta};
      }
    }
  }
`;

export const AllServicesButton = ({ onClick }) => (
  <Container onClick={onClick}>
    Все услуги
    <ReadIcon />
  </Container>
);
