// @flow
import * as React from "react";
import { useStore } from "effector-react";
import { currentUser } from "../../stores/auth";
import { GreetingFeedback } from "./GreetingFeedback";
// import { useApiRequest } from '../../apiv2/useApiRequest';
// import apiv2 from '../../apiv2';

export const GreetingContentFeedback = () => {
  const user = useStore(currentUser);

  // let { data: feedbackCount } = useApiRequest(async () => {
  //   return await apiv2.feedback.getFeedbacksCount();
  // });
  // const numberOfFeedbacks = (feedbackCount && feedbackCount.need_callback) || 0;

  if (!user) {
    return null;
  } else if (user.role === "doctor") {
    return (
      <GreetingFeedback
        firstName={user.doctor.first_name}
        lastName={user.doctor.last_name}
        avatar={user.doctor.photo}
      />
    );
  } else {
    return <GreetingFeedback firstName={user.username} avatar="" />;
  }
};
