//@flow
import * as React from "react";
import { HeadingH4 } from "../styleguide/typography";

import { scrollbars } from '../chats/style-elements';
import { colors } from "../styleguide/colors";
import styled from "styled-components";

const Container = styled.div`
  width: 990px;
  padding: 25px 17px 12px 30px;
  box-sizing: border-box;

  background-color: ${colors.white};
  border-radius: 3px;
  box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.03);
  @media (max-width: 768px) {
    height: 100%;
    width: auto;
    padding: 20px;
    overflow-y: auto;
    ${scrollbars}
  }
  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const HeaderContainer = styled.div`
  height: 50px;
  @media (max-width: 768px) {
    height: auto;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-right: 12px;
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const Heading = styled(HeadingH4)`
  margin-right: 9px;
`;

export const AppointmentHistoryLayout = (props: {|
  children: React.Node,
  tabs: React.Node,
  search: React.Node,
  searchButton: React.Node,
  searchIsShown: boolean,
  hidingTitle: boolean
|}) => (
  <Container>
    <HeaderContainer>
      {props.searchIsShown ? (
        props.search
      ) : (
        <Header>
          <HeaderLeft>
            {!props.hidingTitle && <Heading modifiers={"left"}>История приемов</Heading>}
            {props.tabs}
          </HeaderLeft>
          {props.searchButton}
        </Header>
      )}
    </HeaderContainer>
    {props.children}
  </Container>
);
