import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Config {
  apiService;
  constructor() {
    this.apiService = new ApiService('', null, null, API_URL);
  }

  getConfig() {
    return this.apiService.get('mobile-api/configs');
  }
}

export default new Config();
