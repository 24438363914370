// @flow
import * as React from "react";
import _ from "lodash";
import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea";
import { moveCursorToEnd } from "../../utils/moveCursorToEnd";
import { BaseStyledInput } from "../form-elements/BaseStyledInput";
import { EmbryosTableContext } from "./EmbryosTableContext";

export const WideEditContainer = styled.div`
  max-width: 300px;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #fff;
`;

export const WideTextEditContainer = styled(WideEditContainer)`
  box-shadow: 0px 2px 17px rgba(91, 91, 91, 0.259752);
`;

export const WideTextEditArea = styled(BaseStyledInput)`
  width: 100%;
  box-sizing: border-box;
  border: none;
  resize: none;
  min-height: inherit !important;
  padding: 10px 15px;
  line-height: 1.475;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 15px;
  height: 44px;
`;

export const WideTextEdit = ({
  onChange,
  cell,
  row
}: {
  onChange: any,
  cell: any,
  row: number
}) => {
  const isTime = cell.isTime || false;
  const isInteger = cell.isInteger || false;
  const isText = !isInteger && !isTime;
  const isLimited = cell.isLimited || false;
  const isShortDebounce = cell.isShortDebounce || false;

  const { onCellUpdate } = React.useContext(EmbryosTableContext);
  const textareaRef = React.useRef<HTMLTextAreaElement | HTMLInputElement | null>(null);

  const callCellUpdate = React.useCallback(
    (row: number, cell: any) => {
      if (onCellUpdate) onCellUpdate(row, cell);
    },
    [onCellUpdate]
  );

  const handleCellUpdate = React.useCallback(_.debounce(callCellUpdate, isShortDebounce ? 300 : 2000), [isText]);

  const handleChange = React.useCallback(
    (event: SyntheticEvent<HTMLTextAreaElement>) => {
      const { value } = event.currentTarget;
      onChange({ ...cell, value });
      handleCellUpdate(row, {
        field: cell.field,
        value: isInteger ? parseInt(value, 10) : value
      });
    },
    [onChange, cell, row, handleCellUpdate, isInteger]
  );

  const handleKeyPress = React.useCallback((event: KeyboardEvent) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }, []);

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      moveCursorToEnd(textareaRef.current);
    }
  }, [textareaRef]);

  const value = cell ? cell.value : "";
  const type = isInteger ? "number" : isTime ? "time" : "text";

  const additionalProps = React.useMemo(() => {
    const props = {};
    switch (type) {
      case "number": {
        props.handleKeyPress = handleKeyPress;
        break;
      }
      case "time": {
        props.step = 60;
        break;
      }
      case "text": {
        props.async = true;
        props.maxRows = 5;
        break;
      }
      default:
        break;
    }
    return props;
  }, [type, handleKeyPress]);

  return (
    <WideTextEditContainer>
      <WideTextEditArea
        type={type}
        value={value}
        ref={textareaRef}
        onChange={handleChange}
        as={isText ? TextareaAutosize : "input"}
        maxLength={isLimited ? 9 : ""}
        {...additionalProps}
      />
    </WideTextEditContainer>
  );
};
