import apiv2 from '../apiv2';
import DynamicTableStore from "./dynamicTableStore";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import _ from "lodash";
import { organizationsColor } from "../utils/organizationsColor";

export const INTERVAL_FORMAT = {
  minute: 'DD.MM.YYYY HH:mm',
  hour: 'DD.MM.YYYY HH:mm',
  day: 'DD.MM.YYYY',
  week: 'DD.MM.YYYY',
  month: 'MMMM',
  year: 'YYYY'
}

export const transformTableData = (data, organization_ids, valueName, organizations, paymentStatisticsStore) => {
  const returnData = [];
  if (Array.isArray(data)) {
    const organizationsData = _.groupBy(data, 'organization_id');
    Object.keys(organizationsData).forEach((organizationId) => {
      const formatData = _.groupBy(organizationsData[organizationId], 'format');
      Object.keys(formatData).forEach((key) => {
        returnData.push(
          {
            organization: organizations.find((organization) => parseInt(organization.id) === parseInt(organizationId))?.name,
            format: paymentStatisticsStore.formatOptions.find((item) => item.value === key)?.label || "",
            formatKey: key,
            [valueName]: formatData[key].reduce((acc, item) => {
              acc += item[valueName];
              return acc;
            }, 0)
          }
        )
      })
    })
  }
  return returnData;
};

export const transformDataDiagram = ({data, interval, isCollapseData, valueField = 'count'}) => {
  const groupedDataDate = _.groupBy(_.sortBy(data, 'date_trunc'), 'date_trunc');
  const groupedDataOrganizations = _.groupBy(_.sortBy(data, 'organization_id'), 'organization_id');
  const groupedDataDateOrganizations = Object.keys(groupedDataDate).reduce((acc, key) => {
    acc[key] = _.groupBy(_.sortBy(groupedDataDate[key], 'organization_id'), 'organization_id');
    return acc;
  }, {})
  const labels = Object.keys(groupedDataDate);
  const organizationsData = Object.keys(groupedDataOrganizations).reduce((acc, key) => {
    acc[key] = {
      chat: [],
      online: [],
      in_clinic: [],
      label: '',
    };
    return acc;
  }, {});
  labels.forEach((labelDate) => {
    Object.keys(groupedDataOrganizations).forEach((organizationId) => {
      const dataOrganizations = groupedDataDateOrganizations[labelDate][organizationId];
      if (!organizationsData[organizationId].label && dataOrganizations) {
        organizationsData[organizationId].label = dataOrganizations[0].name;
      }
      if (dataOrganizations) {
        const groupedFormat = _.groupBy(dataOrganizations, 'format');
        const setData = (key) => {
          if (groupedFormat[key]?.length) {
            organizationsData[organizationId][key].push(groupedFormat[key].reduce((acc, item) => {
              acc += item[valueField];
              return acc;
            }, 0));
          } else {
            organizationsData[organizationId][key].push(0);
          }
        }
        setData('chat');
        setData('online');
        setData('in_clinic');
      } else {
        organizationsData[organizationId].chat.push(0);
        organizationsData[organizationId].online.push(0);
        organizationsData[organizationId].in_clinic.push(0);
      }
    })
  })

  const datasets = Object.keys(organizationsData).reduce((acc, key) => {
    const data = organizationsData[key];
    if (isCollapseData) {
      if (!acc.length) {
        acc.push({
          label: data.label + ' - чаты',
          data: data.chat || [],
          color: organizationsColor[1],
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + ' - онлайн',
          data: data.online || [],
          color: organizationsColor[2],
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
        acc.push({
          label: data.label + ' - в клинике',
          data: data.in_clinic || [],
          color: organizationsColor[3],
          highlightScope: {
            highlighted: 'series',
            faded: 'global',
          }
        })
      } else {
        acc[0].label += ', ' + data.label + ' - чаты';
        acc[1].label += ', ' + data.label + ' - онлайн';
        acc[2].label += ', ' + data.label + ' - в клинике';

        data.chat.forEach((count, index) => {
          acc[0].data[index] = acc[0].data[index] + count;
        })

        data.online.forEach((count, index) => {
          acc[1].data[index] = acc[1].data[index] + count;
        })

        data.in_clinic.forEach((count, index) => {
          acc[2].data[index] = acc[2].data[index] + count;
        })
      }
    } else {
      acc.push({
        label: data.label + ' - чаты',
        data: data.chat || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - онлайн',
        data: data.online || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
      acc.push({
        label: data.label + ' - в клинике',
        data: data.in_clinic || [],
        color: organizationsColor[key],
        highlightScope: {
          highlighted: 'series',
          faded: 'global',
        }
      })
    }
    return acc;
  }, []);
  return { labels, datasets };
}

class PaymentStatisticsStore extends DynamicTableStore {
  formatKeys = {
    online: 'Онлайн',
    chat: 'Чат',
    in_clinic: 'В клинике',
  }
  formatOptions = [
    {
      label: 'Онлайн',
      value: 'online'
    },
    {
      label: 'Чат',
      value: 'chat'
    },
    {
      label: 'В клинике',
      value: 'in_clinic'
    },
  ];

  statusKeys = {
    pending: 'Ожидание платежа',
    succeeded: 'Платеж успешно завершен',
    debited: 'Средства списаны',
    partial_refunded: 'Частичный возврат средств',
    refunded: 'Денежные средства возвращены',
    canceled: 'Отменен',
  }
  statusOptions = [
    {
      label: 'Ожидание платежа',
      value: 'pending'
    },
    {
      label: 'Платеж успешно завершен',
      value: 'succeeded'
    },
    {
      label: 'Средства списаны',
      value: 'debited'
    },
    {
      label: 'Частичный возврат средств',
      value: 'partial_refunded'
    },
    {
      label: 'Денежные средства возвращены',
      value: 'refunded'
    },
    {
      label: 'Отменен',
      value: 'canceled'
    },
  ];


  PAYMENT_STATUS_CHOICES = (
    ("pending", "Ожидание платежа"),
      ("succeeded", "Платеж успешно завершен"),
      ("debited", "Средства списаны"),
      ("partial_refunded", "Частичный возврат средств"),
      ("refunded", "Денежные средства возвращены"),
      ("canceled", "Отменен")
  )

  constructor() {
    super(apiv2.payment.getListPayments.bind(apiv2.payment));
    makeObservable(this, {
      formatOptions: observable,
      formatKeys: observable,
      getAmountChartData: action,
      getCountChartData: action,
    })
  }

  async getAmountChartData(filters) {
    try {
      return await apiv2.payment.getServiceAmounts(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getCountChartData(filters) {
    try {
      return await apiv2.payment.getServiceAmounts(filters);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

}

export default new PaymentStatisticsStore();
