import React, { useState, useRef } from "react"
import { observer } from 'mobx-react';
import styled, { css } from "styled-components";
import { useDebouncedCallback } from 'use-debounce';

import { DefaultInput } from "../../form-elements/DefaultInput";
import chatStore, { ChatFilters } from '../../../stores/chatStore';
import { FEATURE, useApplicationFeatureEnabled } from '../../../utils/applicationFeatures';
import { colors } from '../../styleguide/colors';

import ChatFilterIcon from '../../../assets/icons/filter.inline.svg';
import searchIcon from "../../my-patients/icons/search-big.svg";
import {useClickOutside} from '../../../utils/useClickOutside';

const Container = styled.div`
  position: relative;
  display: flex;
`;

const InputIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
`;

const InputContainer = styled.div`
  flex: 1;
  
  input {
    padding: 16px 16px 16px 48px;
    border: none;
    border-bottom: 1px solid rgba(220, 211, 210, 0.4);
    border-radius: 0;
    font-family: "Graphik LCG";
    font-size: 15px;
    line-height: 23px;
    
    &:focus {
      border-color: rgba(220, 211, 210, 0.4);
    }
  }
`;

const FilterContainer = styled.div`
  position: relative;
`;

const FilterButton = styled.button`
  font: 12px "Graphik LCG";
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  cursor: pointer;
  background: #fff;
  border: none;
  border-left: 1px solid rgba(220, 211, 210, 0.4);
  border-bottom: 1px solid rgba(220, 211, 210, 0.4);
  span {
    color: ${colors.darkBlack};
  }
    
  ${props => props.active && css`
    --svg-stroke: ${colors.magenta};
    color: ${colors.magenta};
  `}
`;

const FilterList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  display: ${props => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 31px rgba(0,0,0, 0.1);
  z-index: 10;
  @media (max-width: 576px) {
    left: auto;
    right: 0;
  }  
`;

const FilterItem = styled.li`
  font: 13px "Graphik LCG";
  line-height: 23px;
  padding: 10px 20px;
  white-space: nowrap;
  cursor: pointer;
  
  &:hover {
    color: ${colors.magenta};
  }
  
  ${props => props.active && css`
    background: ${colors.gray050};
    color: ${colors.magenta};
  `}
`;

const ChatFiltersList = [
  ChatFilters.ALL,
  ChatFilters.NOT_READ,
  ChatFilters.OPENED,
  ChatFilters.CLOSED,
  ChatFilters.PAID
];

const ChatFiltersNames = {
  [ChatFilters.ALL]: "Все чаты",
  [ChatFilters.NOT_READ]: "Непрочитанные",
  [ChatFilters.OPENED]: "Открытые",
  [ChatFilters.CLOSED]: "Закрытые",
  [ChatFilters.PAID]: "Платные",
};

export const ChatSearch = observer(() => {
  const [filterListIsVisible, setFilterListIsVisible] = useState(false);
  const [debouncedSearch] = useDebouncedCallback(chatStore.searchChats, 200);
  const isChatFiltersEnabled = useApplicationFeatureEnabled(FEATURE.CHAT_FILTERS);
  const filterContainerRef = useRef(null);

  useClickOutside(filterContainerRef, () => {
    setFilterListIsVisible(false);
  });

  return (
    <Container>
      <InputIcon src={searchIcon}/>
      <DefaultInput overrides={{
        Container: {
          component: InputContainer
        }
      }} value={chatStore.searchText} handleChange={(value) => {
        chatStore.setSearchText(value);
        debouncedSearch();
      }} placeholder="Поиск по пациентам"/>
      {
        isChatFiltersEnabled && (
          <FilterContainer ref={filterContainerRef}>
            <FilterButton active={filterListIsVisible} onClick={() => {
              setFilterListIsVisible(value => !value);
            }}>
              <ChatFilterIcon/>
              <span>{ChatFiltersNames[chatStore.selectedFilter]}</span>
            </FilterButton>
            <FilterList visible={filterListIsVisible}>
              {
                ChatFiltersList.map(filterName => (
                  <FilterItem key={filterName} active={chatStore.selectedFilter === filterName} onClick={() => {
                    chatStore.setFilter(filterName);
                    chatStore.loadChats();
                    setFilterListIsVisible(false);
                  }}>{ChatFiltersNames[filterName]}</FilterItem>
                ))
              }
            </FilterList>
          </FilterContainer>
        )
      }
    </Container>
  );
});