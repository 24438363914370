// @flow
import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import styled, { css } from "styled-components";
import jsPDF from 'jspdf';
import {Tooltip} from '@mui/material';
import {PrimaryButton, PrimaryButtonContainer} from '../buttons/PrimaryButton';
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import {HeadingH3, PNormal, PPrintNormal} from "../styleguide/typography";
import useReactRouter from "use-react-router";
import _ from "lodash";
import { PrintableSection } from "./PrintableSection";
import { PrintableField } from "./PrintableField";
import type { Field, FieldSingle, FieldBodyMassIndex } from "../../domain/entities/Field";
import type { Section } from "../../domain/entities/Section";
import {
  fieldIsEmpty,
  getFieldSingleTextValue,
  isLabelNeededAtGroup,
  sectionIsEmpty,
  sectionShouldBeHidden,
  formatDateToReadableDateFormat
} from "../../domain/services/field";
import { TextareaView } from "../form/TextareaView";
import { useTrigger } from "react-use-trigger";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import { useOrganization } from "../appointment-print/useOrganization";
import { useContacts } from "../appointment-print/useContacts";
import { useBeforePrint } from "../appointment-print/useBeforePrint";
import { useLogo } from "../appointment-print/useLogo";
import { PrintableGroupTitle } from "../decision-support/PrintableGroupTitle";
import { PrintableDiagnosis } from "../decision-support/PrintableDiagnosis";
import { PrintableDiagnosisContainer } from "../decision-support/PrintableDiagnosisContainer";
import { withoutUnchecked, withoutUncheckedGroups } from "../../domain/services/checklist";
import { PrintableChecklistItem } from "../decision-support/PrintableChecklistItem";
import { PrintableGroupContainer } from "../decision-support/PrintableGroupContainer";
import { PrintableChecklistContainer } from "../decision-support/PrintableChecklistContainer";
import { ChecklistWithCommaSeparatedChildrenInParentheses } from "../decision-support/ChecklistWithCommaSeparatedChildrenInParentheses";
import { ReadableChecklistContentWithCommentFormatted } from "../decision-support/ReadableChecklistContentWithCommentFormatted";
import type { BodyMassIndex } from "../../domain/value-objects/BodyMassIndex";
import { Chart } from "../appointment-page/LiveBirthProbability";
import { PrintableServices, PrintableServicesComment } from "./PrintableServices";
import {
  applicationFeatureEnabled,
  useApplicationFeatureValueForOrganization,
  FEATURE
} from "../../utils/applicationFeatures";
import apiv2 from '../../apiv2';
import printEditorStore, {PrintEditorEvents} from '../../stores/printEditorStore';
import {appointmentHeaderTableStyles} from '../print-styles/AppointmentHeaderTable';
import {observer} from 'mobx-react';
import {AppointmentUserAgreement} from './AppointmentUserAgreement';
import {
  formatAppointmentDateToDateTimeWithWords,
  formatAppointmentDateToDateYearWithWords
} from '../../domain/services/appointments';
import {getPatientLastNameWithInitials} from '../../domain/services/patient';
import {getLastNameWithInitials} from '../../domain/services/doctor';
import {AppointmentSign} from './AppointmentSign';
import {escapeHTML} from '../../utils/escapeHTML';
import {useToast} from '../toast/useToast';
import {useApiRequest} from '../../apiv2/useApiRequest';

import '../../fonts/forPDF/Graphik-normal';
import '../../fonts/forPDF/Graphik-bold';

import SignInlineIcon from '../../assets/icons/sign.inline.svg';
import MailInlineIcon from '../../assets/icons/mail.inline.svg';

const toastManager = useToast();

const PrintContainer = styled.div`
  width: ${props => (props.isHorizontal ? "1520px" : "1025px")};
`;

const PrintForContainer = styled.div`
  width: ${props => (props.isHorizontal ? "1520px" : "1025px")};
  
  * {
    page-break-before: avoid;
    page-break-inside: avoid;
    page-break-after: avoid;
  }

  ${appointmentHeaderTableStyles}
  
  ${p => (p.width ? `width: ${p.width};` : null)}

  ${p => (p.breakAfter ? "@media print {page-break-after: always;}" : null)}
`;

const SignContainer = styled.div`
  page-break-inside: avoid;
  display: flex;
  justify-content: flex-end;
`;

const ChildFieldContainer = styled.span`
  & + & {
    :before {
      content: ", "
    }
  }

  ${p =>
    p.break &&
    css`
      & + & {
        :before {
          content: "";
          display: block;
        }
      }
    `}
`;

const PrintableTwoColumnsContainer = styled.div`
  column-count: 2;
`;

const PrintableSectionFigure = styled.img`
  width: 501px;
  margin-top: 8px;
`;

const PrintableTextarea = styled(PPrintNormal)`
  white-space: pre-wrap;
`;

const PrintableChart = styled.div`
  max-width: 480px;
`;

const Title = styled(HeadingH3)`
  margin: 30px 0 13px;
`;

const CustomButtonContainer = styled(PrimaryButtonContainer)`
  width: 80px;
  height: 50px;
  cursor: pointer;
    
  --svg-stroke: #fff;

  @media print {
    display: none;
  }
  @media screen and (max-width: 768px) {
    width: auto;
    height: 40px;
  }
`;

const PromoContainer = styled.div`
  border: 1px solid #979797;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 32px;
  align-items: center;
  margin-top: 75px;
`;

const PromoImage = styled.img`
  width: 100px;
  height: 100px;
`;

const PromoText = styled.p`
  flex: 1;
`;

const PrintableBodyMassIndex = (props: { field: FieldBodyMassIndex, value: BodyMassIndex }) => {
  const { field, value } = props;
  if (
    !(
      value.height ||
      (value.height === 0 && value.weight) ||
      (value.weight === 0 && value.bmi) ||
      value.bmi === 0
    )
  ) {
    return null;
  }
  return (
    <>
      <PrintableField label="Рост:">
        <PPrintNormal as="span">{value.height || "-"}</PPrintNormal>
      </PrintableField>
      <PrintableField label="Вес:">
        <PPrintNormal as="span">{value.weight || "-"}</PPrintNormal>
      </PrintableField>
      <PrintableField label={`${field.name}:`}>
        <PPrintNormal as="span">{value.bmi || "-"}</PPrintNormal>
      </PrintableField>
    </>
  );
};

const PrintableLiveBirthProbability = ({ label, ...rest }) => {
  return (
    <PrintableSection label={label}>
      <PrintableChart>
        <Chart {...rest} mode="print" />
      </PrintableChart>
    </PrintableSection>
  );
};

const StampContainer = styled.div`
    position: relative;
    height: 40mm;
    margin-right: 80px;
`;

const Stamp = styled.img`
    width: 40mm;
    position: absolute;
    right: 0;
    top: 0;
`;

const Signature = styled.img`
    max-width: 100%;
    max-height: 20mm;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
`;

const convertAllSVGImages = (parentNode) => {
  const converter = document.createElement('canvas');
  const context = converter.getContext('2d');
  [...parentNode.querySelectorAll('img')].forEach(image => {
    if (image.src.startsWith('data:image/svg+xml;')) {
      converter.width = image.width;
      converter.height = image.height;
      context.clearRect(0, 0, converter.width, converter.height);
      context.drawImage(image, 0, 0);
      const newImage = new Image();
      newImage.src = converter.toDataURL('image/png');
      image.replaceWith(newImage);
    }
  });
};

const getDataURLFromTypeDataObject = (imageDataObj) => {
  return `data:${imageDataObj.type};base64,${imageDataObj.data}`;
};

export const PrintableAppointment = observer((props: *) => {
  const { appointment, printFor, needEdit, needSend, doctorTemplate, patientTemplate, layout, contacts, organization, logoEl, setLogoLoaded } = props;
  const { history, match: { params } } = useReactRouter();
  const containerRef = useRef(null);
  let contentForPrint;

  const printForBoth = printFor === "both";
  const printForDoctor = printFor === "doctor";
  const printForPatient = printFor === "patient";

  useEffect(() => {
    const afterPrint = () => {
      const goBack = () => {
        history.replace(`/appointment/${params.appointment_id}`);
      };

      if (!needEdit) {
        goBack();
      }
    };
    window.addEventListener("afterprint", afterPrint, false);
    const unsubscribeBack = printEditorStore.addEventListener(PrintEditorEvents.back, () => {
      history.replace(`/appointment/${params.appointment_id}`);
      printEditorStore.clearEventListeners(PrintEditorEvents.back);
    });
    return () => {
      window.removeEventListener("afterprint", afterPrint);
      unsubscribeBack();
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const contentImages = [...container.querySelectorAll('img')];
      if (!contentImages.length) {
        setLogoLoaded(true);
        return;
      }

      let loadedImages = 0;

      const checkAllImagesLoaded = () => {
        if (loadedImages === contentImages.length) {
          setTimeout(() => {
            setLogoLoaded(true);
          }, 200);
        }
      };

      const incrementLoadedImages = () => {
        loadedImages += 1;
        checkAllImagesLoaded();
      }

      for (let imageElement of contentImages) {
        if (imageElement.complete) {
          incrementLoadedImages();
        } else {
          imageElement.addEventListener('load', incrementLoadedImages);
          imageElement.addEventListener('error', incrementLoadedImages);
        }
      }
    }
  }, [appointment, layout, contacts, organization, patientTemplate, doctorTemplate]);

  useEffect(() => {
    if (needEdit && contentForPrint && !printEditorStore.isVisible) {
      const data = ReactDOMServer.renderToString(contentForPrint);
      const signature = appointment.doctor.signature_data ? ReactDOMServer.renderToString(
        <Signature id="signature" src={getDataURLFromTypeDataObject(appointment.doctor.signature_data)}/>
      ) : null;
      const stamp = organization.seal_data ? ReactDOMServer.renderToString(
        <StampContainer>
          <Stamp id="stamp" src={getDataURLFromTypeDataObject(organization.seal_data)}/>
        </StampContainer>
      ) : null;

      printEditorStore.setData(data);

      if (printForPatient) {
        printEditorStore.addCustomButton('signButton', ({editorRef}) => {
          const onSignClick = () => {
            if (!signature) {
              toastManager.showToast(
                <div>
                  У вас нет добавленной подписи
                </div>,
                {
                  duration: 10000
                }
              );
            }
            if (!stamp) {
              toastManager.showToast(
                <div>
                  У поликлиники нет добавленной печати
                </div>,
                {
                  duration: 10000
                }
              );
            }
            if (!stamp && !signature) {
              return;
            }

            const editor = editorRef.current;
            if (editor) {
              const parser = new DOMParser();
              const data = editor.getData();
              const dataDoc = parser.parseFromString(data, 'text/html');
              if (stamp && !dataDoc.querySelector('#stamp')) {
                dataDoc.querySelector('[data-stamp-place]').insertAdjacentHTML('afterbegin', stamp);
              }
              if (signature && !dataDoc.querySelector('#signature')) {
                dataDoc.querySelector('[data-signature-place]').insertAdjacentHTML('afterbegin', signature);
              }
              editor.setData(dataDoc.body.innerHTML);
            }
          };

          return (
            <Tooltip title="Подписать">
              <div>
                <PrimaryButton onClick={onSignClick} primaryButtonContainer={CustomButtonContainer}>
                  <SignInlineIcon/>
                </PrimaryButton>
              </div>
            </Tooltip>
          );
        });
        printEditorStore.addCustomButton('sendMailButton', ({editorRef}) => {
          const onSendClick = () => {
            const editor = editorRef.current;
            if (editor) {
              const parser = new DOMParser();
              const data = editor.getData();
              const dataDoc = parser.parseFromString(data, 'text/html');
              const root = document.querySelector('#root');
              root.style.display = 'none';

              const printContent = document.createElement('div');
              printContent.append(...dataDoc.body.children);
              document.body.append(printContent);

              convertAllSVGImages(printContent); // Перед конвертацией в pdf преобразовываем svg в png, потому что библиотека не умеет рендерить svg в pdf
              const doc = new jsPDF({
                unit: 'px',
                autoPaging: 'text',
                hotfixes: ['px_scaling'],
              });
              doc.html(printContent, {
                callback: async function (generatedDoc) {
                  const pdfData = generatedDoc.output('datauristring');
                  try {
                    const response = await apiv2.appointments.sendAppointmentPDF(params.appointment_id, pdfData.replaceAll('filename=generated.pdf;', ''));
                    console.log(response);
                  } catch (e) {
                    console.error(e);
                  }
                  document.body.removeChild(printContent);
                  root.style.display = '';
                },
                width: 1025,
                windowWidth: 1500,
                x: 40,
                y: 40
              });
            }
          };

          return (
            <Tooltip title="Отправить на почту пациенту">
              <div>
                <PrimaryButton onClick={onSendClick} primaryButtonContainer={CustomButtonContainer}>
                  <MailInlineIcon/>
                </PrimaryButton>
              </div>
            </Tooltip>
          );
        });
      }

      printEditorStore.show();
    }

    return () => {
      if (printEditorStore.isVisible) {
        printEditorStore.close();
        if (printForPatient) {
          printEditorStore.removeCustomButton('signButton');
          printEditorStore.removeCustomButton('sendMailButton');
        }
      }
    };
  }, [appointment, layout, contacts, organization, patientTemplate, doctorTemplate]);

  if (!appointment || !layout || !contacts || !organization || !(patientTemplate || doctorTemplate)) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  if (appointment.in_progress) {
    return <PNormal>Прием не завершен</PNormal>;
  }

  const fieldValuesMap = _.keyBy(appointment.layout_field_values, item => item.field);

  const getBodyMassIndexValue = (field: FieldBodyMassIndex): BodyMassIndex => {
    const value = fieldValuesMap[field.id] && fieldValuesMap[field.id].value;
    if (
      !value ||
      value instanceof Array ||
      typeof value === "string" ||
      typeof value === "number"
    ) {
      return { height: null, weight: null, bmi: null };
    }
    return {
      height: parseFloat(value.height) || null,
      weight: parseFloat(value.weight) || null,
      bmi: parseFloat(value.bmi) || null
    };
  };

  const cleanupValue = (value) => {
    return escapeHTML(value)?.replaceAll('\n','<br/>');
  };

  const getValueSingle = (field: FieldSingle) => {
    if (!fieldValuesMap[field.id]) {
      return;
    }
    const value = getFieldSingleTextValue(field, fieldValuesMap[field.id]);
    return field.field_type === "textarea" ? (
      <PrintableTextarea as="span" dangerouslySetInnerHTML={{__html: cleanupValue(value)}}/>
    ) : (
      <PPrintNormal as="span" dangerouslySetInnerHTML={{__html: cleanupValue(value)}}/>
    );
  };

  const getPrintValueSingle = (field) => {
    const result = cleanupValue(getFieldSingleTextValue(field, fieldValuesMap[field.id]));
    if (field.field_type === "textarea") {
      return `<span style="white-space: pre-wrap;">${result}</span>`;
    }
    return result;
  };

  const getPrintGroupValue = (field) => {
    const children = field.children.filter(child => !fieldIsEmpty(child, fieldValuesMap));
    return (
      <PPrintNormal
        dangerouslySetInnerHTML={{__html: children.map((child, index) => {
          const isLastElement = index === (children.length - 1);
          let result = "";
          if (isLabelNeededAtGroup(child)) {
            result += `${child.name}:&nbsp;`;
          }
          if (fieldValuesMap[child.id]) {
            result += getPrintValueSingle(child);
          }
          result += isLabelNeededAtGroup(child)
            ? isLastElement ? "" : "<br/>"
            : isLastElement ? "" : ", ";
          return result;
        }).join("")}}
      />
    );
  };

  const getValue = (field: Field) => {
    if (field.field_type === "group") {
      return getPrintGroupValue(field);
    } else {
      return getValueSingle(field);
    }
  };

  const getSectionFieldsTwoCol = (fields: Array<Field>, shouldShowLabel: boolean) => {
    const notEmptyFields = fields.filter(field => !fieldIsEmpty(field, fieldValuesMap));

    return (
      <PrintableTwoColumnsContainer>
        {notEmptyFields.map(field =>
          field.field_type === "body_mass_index" ? (
            <PrintableBodyMassIndex field={field} value={getBodyMassIndexValue(field)} />
          ) : (
            <PrintableField inline key={field.id} label={shouldShowLabel ? `${field.name}:` : ""}>
              {getValue(field) || "—"}
            </PrintableField>
          )
        )}
      </PrintableTwoColumnsContainer>
    );
  };

  const getLocalStorageSections = (checked: boolean): string[] => {
    const rawCheckedSections = localStorage.getItem("checkedSections");
    if (rawCheckedSections) {
      return Object.entries(JSON.parse(rawCheckedSections))
        .map(([key: string, value: boolean]) => (value === checked ? key : ""))
        .filter(Boolean);
    }
    return [];
  };
  const checkedSections = getLocalStorageSections(true);

  const getValues = (sections: Array<Section>, showFigure: boolean, valuesForDoctor) => {
    if (_.isEmpty(sections)) {
      sections = [];
    }
    const notCheckedSections = getLocalStorageSections(false);

    return (
      <React.Fragment>
        {sections.map(section => {
          const shouldShowLabel = section.fields.length > 1;
          return (notCheckedSections.includes(
            section.id.toString()
          ) && !valuesForDoctor) ? null : section.for_live_birth_probability &&
            (appointment.eko_chance || appointment.eko_do_chance || appointment.vmi_chance) ? (
            <PrintableLiveBirthProbability label={section.name} key={section.id} {...appointment} />
          ) : sectionIsEmpty(section, fieldValuesMap) || sectionShouldBeHidden(section) ? null : (
            <PrintableSection label={section.name} key={section.id}>
              {section.print_layout === "two_col" ? (
                getSectionFieldsTwoCol(section.fields, shouldShowLabel)
              ) : (
                <div>
                  {section.fields.map(field => {
                    if (field.field_type === "body_mass_index") {
                      return (
                        <PrintableBodyMassIndex
                          field={field}
                          value={getBodyMassIndexValue(field)}
                        />
                      );
                    }
                    return fieldIsEmpty(field, fieldValuesMap) ? null : (
                      <PrintableField
                        key={field.id}
                        label={shouldShowLabel ? `${field.name}:` : ""}>
                        {getValue(field) || "—"}
                      </PrintableField>
                    );
                  })}
                </div>
              )}
            </PrintableSection>
          );
        })}
        {appointment.primary_diagnoses &&
          appointment.secondary_diagnoses &&
          (appointment.primary_diagnoses.length > 0 ||
            appointment.secondary_diagnoses.length > 0) &&
          (!notCheckedSections.includes("diagnosis") || valuesForDoctor) && (
            <PrintableSection label="Диагноз по МКБ">
              {appointment.primary_diagnoses && appointment.primary_diagnoses.length > 0 && (
                <PrintableGroupContainer>
                  <PrintableGroupTitle>Основной диагноз:</PrintableGroupTitle>
                  <PrintableDiagnosisContainer>
                    {appointment.primary_diagnoses.map(diagnosis => (
                      <PrintableDiagnosis key={diagnosis.id} diagnosis={diagnosis} />
                    ))}
                  </PrintableDiagnosisContainer>
                </PrintableGroupContainer>
              )}
              {appointment.secondary_diagnoses && appointment.secondary_diagnoses.length > 0 && (
                <PrintableGroupContainer>
                  <PrintableGroupTitle>Сопутствующий диагноз:</PrintableGroupTitle>
                  <PrintableDiagnosisContainer>
                    {appointment.secondary_diagnoses.map(diagnosis => (
                      <PrintableDiagnosis key={diagnosis.id} diagnosis={diagnosis} />
                    ))}
                  </PrintableDiagnosisContainer>
                </PrintableGroupContainer>
              )}
            </PrintableSection>
          )}

        {appointment.examination_checklist &&
          withoutUncheckedGroups(appointment.examination_checklist.items).length > 0 &&
          (!notCheckedSections.includes("examination") || valuesForDoctor) && (
            <PrintableSection label="Обследование">
              {withoutUncheckedGroups(appointment.examination_checklist.items).map(group => (
                <PrintableGroupContainer key={group.guid}>
                  <PrintableGroupTitle>{group.name}</PrintableGroupTitle>
                  <PrintableChecklistContainer>
                    {group.items &&
                      group.items
                        .map(item => <PrintableChecklistItem key={item.guid} checklist={item} />)}
                  </PrintableChecklistContainer>
                </PrintableGroupContainer>
              ))}
            </PrintableSection>
          )}

        {appointment.medication_checklist &&
          withoutUnchecked(appointment.medication_checklist.items).length > 0 &&
          (!notCheckedSections.includes("medication") || valuesForDoctor) && (
            <PrintableSection label="Медикаментозное лечение">
              {withoutUnchecked(appointment.medication_checklist.items).map(checklist => (
                <PrintableChecklistItem key={checklist.guid}>
                  <ChecklistWithCommaSeparatedChildrenInParentheses checklist={checklist} />
                </PrintableChecklistItem>
              ))}
            </PrintableSection>
          )}

        {appointment.recommendation_checklist &&
          withoutUnchecked(appointment.recommendation_checklist.items).length > 0 &&
          (!notCheckedSections.includes("recommendation") || valuesForDoctor) && (
            <PrintableSection label="Рекомендации">
              {withoutUnchecked(appointment.recommendation_checklist.items).map(item => (
                <PrintableChecklistItem key={item.guid}>
                  <ReadableChecklistContentWithCommentFormatted checklist={item} />
                </PrintableChecklistItem>
              ))}
            </PrintableSection>
          )}

        {appointment.next_visit_date && (
          <PrintableSection label="Дата следующего приема">
            <PrintableField>
              <TextareaView overrides={{ Textarea: { component: PPrintNormal } }}>
                {formatDateToReadableDateFormat(appointment.next_visit_date)}
              </TextareaView>
            </PrintableField>
          </PrintableSection>
        )}

        {(checkedSections.includes("prescribedServices") || valuesForDoctor) &&
            (appointment.prescribed_services.length > 0) && (
                <PrintableSection label="Направления">
                  {appointment.prescribed_services.length > 0 && (
                      <PrintableServices displayTeeth={layout.display_teeth} services={appointment.prescribed_services} organizationId={appointment.medical_file.organization.id} showServicesId={valuesForDoctor} />
                  )}
                </PrintableSection>
            )}

        {(!notCheckedSections.includes("services") || valuesForDoctor) &&
          (appointment.services.length > 0 || appointment.services_description) && (
            <PrintableSection label="Услуги">
              {appointment.services.length > 0 && (
                <PrintableServices displayTeeth={layout.display_teeth} services={appointment.services} organizationId={appointment.medical_file.organization.id} showServicesId={valuesForDoctor} />
              )}
              {appointment.services_description && (
                <PrintableServicesComment>
                  {appointment.services_description}
                </PrintableServicesComment>
              )}
            </PrintableSection>
          )}
        {showFigure && layout.figure && (
          <PrintableSection label={layout.figure.name}>
            <PrintableSectionFigure src={layout.figure.image} />
          </PrintableSection>
        )}
      </React.Fragment>
    );
  };


  const values = getValues(
    layout.sections.filter(
      section => section.for_customer || checkedSections.includes(section.id.toString())
    ),
    Boolean(layout.figure && layout.figure.for_patient)
  );

  const valuesForDoctor = getValues(layout.sections, true, true);

  const title = <Title className="appointment-title">{appointment.layout.name}</Title>;

  const sign = (
    <AppointmentSign
      doctor={{
        name: getLastNameWithInitials(appointment.doctor),
        regalia: appointment.doctor.regalia || null
      }}
      chief={layout.needs_chief_signature ? organization.chief_name : null}
    />
  );

  const userAgreement = layout.user_agreement ? (
    <AppointmentUserAgreement
      title={layout.user_agreement && layout.user_agreement.title}
      text={layout.user_agreement && layout.user_agreement.text}
      date={formatAppointmentDateToDateYearWithWords(appointment.date)}
      patient={getPatientLastNameWithInitials(appointment.medical_file.patient)}
    />
  ) : null;

  const promoBar = layout.is_qr && (
    <PromoContainer>
      <PromoImage src={getDataURLFromTypeDataObject(layout.qr_logo)}/>
      <PromoText>
        {layout.qr_text}
      </PromoText>
      <PromoImage src={getDataURLFromTypeDataObject(layout.qr_code)}/>
    </PromoContainer>
  );

  const visitTime = layout.print_visit_time
    ? formatAppointmentDateToDateTimeWithWords(appointment)
    : formatAppointmentDateToDateYearWithWords(appointment.date);

  const patientHTML = patientTemplate
    .replace('VAR$APPOINTMENT_VISIT_DATETIME$', visitTime)
    .replace('VAR$APPOINTMENT_DATA$', ReactDOMServer.renderToString((
    <>
      {title}
      {values}
      {sign && <SignContainer data-stamp-place>{sign}</SignContainer>}
      {promoBar}
    </>
  )));

  const doctorHTML = doctorTemplate
    .replace('VAR$APPOINTMENT_VISIT_DATETIME$', visitTime)
    .replace('VAR$APPOINTMENT_DATA$', ReactDOMServer.renderToString((
    <>
      {title}
      {valuesForDoctor}
      {sign && <SignContainer>{sign}</SignContainer>}
      {userAgreement}
    </>
  )));

  contentForPrint = (
    <PrintContainer ref={containerRef}>
      {values && (printForBoth || printForPatient) && (
        <PrintForContainer dangerouslySetInnerHTML={{__html: patientHTML}} breakAfter={printForBoth || printForDoctor}/>
      )}
      {valuesForDoctor && (printForBoth || printForDoctor) && (
        <PrintForContainer dangerouslySetInnerHTML={{__html: doctorHTML}}/>
      )}
    </PrintContainer>
  );

  if (needEdit) {
    return;
  }

  return contentForPrint;
});

export const PrintableAppointmentPage = () => {
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();

  const searchParams = new URLSearchParams(location.search);
  const needEdit = searchParams.get('needEdit') === 'true';
  const needSend = searchParams.get('needSend') === 'true';

  const patientAppointmentsTrigger = useTrigger(PatientAppointmentsRefreshTrigger);
  const appointmentResult = useApiRequest(async () => {
    if (params.appointment_id) {
      return await apiv2.appointments.getAppointmentById(params.appointment_id);
    }
  }, [patientAppointmentsTrigger, token, params.appointment_id]);

  const layoutResult = useApiRequest(async () => {
    if (appointmentResult.data && appointmentResult.data.layout.id) {
      return await apiv2.appointments.getLayout(parseInt(appointmentResult.data && appointmentResult.data.layout.id));
    }
  }, [appointmentResult.data && appointmentResult.data.layout.id]);

  const { logoEl, isLogoLoaded, setLogoLoaded } = useLogo();

  const appointment = appointmentResult.data;
  const layout = layoutResult.data;

  const organization = useOrganization(appointment);
  const contacts = useContacts(organization);
  const [doctorTemplate, setDoctorTemplate] = useState('');
  const [patientTemplate, setPatientTemplate] = useState('');

  const doctorTemplateId = useApplicationFeatureValueForOrganization(FEATURE.APPOINTMENT_PRINT_DOCTOR_TEMPLATE_ID, organization?.id);
  const patientTemplateId = useApplicationFeatureValueForOrganization(FEATURE.APPOINTMENT_PRINT_PATIENT_TEMPLATE_ID, organization?.id);

  useEffect(() => {
    (async () => {
      if (!doctorTemplate && (params.for === "doctor" || params.for === "both") && doctorTemplateId !== false &&  doctorTemplateId >= 0) {
        const template = await apiv2.documents.getTemplate(doctorTemplateId, { appointmentId: parseInt(params.appointment_id) });
        setDoctorTemplate(template.file);
      }
      if (!patientTemplate && (params.for === "patient" || params.for === "both") && patientTemplateId !== false && patientTemplateId >= 0) {
        const template = await apiv2.documents.getTemplate(patientTemplateId, { appointmentId: parseInt(params.appointment_id) });
        setPatientTemplate(template.file);
      }
    })();
  }, [doctorTemplateId, patientTemplateId]);

  const doctorTemplateLoaded = params.for === "doctor" || params.for === "both" ? doctorTemplate : true;
  const patientTemplateLoaded = params.for === "patient" || params.for === "both" ? patientTemplate : true;

  useBeforePrint(
    !needEdit && doctorTemplateLoaded && patientTemplateLoaded && isLogoLoaded && !appointment.in_progress,
    appointment?.id
  );

  return (
    <PrintableAppointment
      appointment={appointment}
      printFor={params.for}
      needEdit={needEdit}
      needSend={needSend}
      doctorTemplate={doctorTemplate}
      patientTemplate={patientTemplate}
      contacts={contacts}
      layout={layout}
      organization={organization}
      logoEl={logoEl}
      setLogoLoaded={setLogoLoaded}
    />
  );
};
