import styled, { css } from "styled-components";
import { colors } from "../../styleguide/colors";

import arrowIcon from "../icons/arrow.inline.svg";

const divider = css`
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 380px;
  height: 1px;
  background: ${colors.hintOfRed};
`;

export const Container = styled.div`
  position: relative;
  padding: 28px 60px 28px 24px;
  font-size: 15px;
  line-height: 19px;
  cursor: pointer;

  &:not(:first-child)::before {
    top: 0;

    ${divider};
  }

  &:last-child::after {
    bottom: 0;

    ${divider};
  }

  &:hover {
    background: ${colors.gray900};
  }

  &:hover:before,
  &:hover:after,
  &:hover + &:before {
    display: none;
  }
`;

export const Title = styled.div`
  overflow-wrap: anywhere;
`;

export const Arrow = styled(arrowIcon)`
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
`;
