import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography
} from "@mui/material";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import _ from "lodash"
import { Asterisk } from "../styled/Asterisk.styled";

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormInputMultiCheckbox = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  useEffect(() => {
    if (props.settedValues[props.name] && !_.isEqual(props.settedValues[props.name], selectedItems)) {
      setSelectedItems(props.settedValues[props.name]);
    }
  }, [props.settedValues]);

  useEffect(() => {
    props.setValue(props.name, selectedItems);
  }, [selectedItems]);

  return (
    <FormControl fullWidth className={'dynamic-form-input-control'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
      {props.label && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
      {!!props.subInfo && <Typography variant="caption">{props.subInfo}</Typography>}
      <CheckboxWrapper className={`form-input-multi-checkbox ${props.className}`}>
        {props.options.map((option) => {
          return (
            <FormControlLabel
              label={option.label}
              key={option.value}
              className={"form-input-multi-checkbox__item"}
              control={
                <Controller
                  control={props.control}
                  name={props.name}
                  render={() => {
                    return (
                      <Checkbox
                        // {...props}
                        size="small"
                        disabled={props.readOnly}
                        checked={selectedItems.includes(option.value)}
                        onChange={() => handleSelect(option.value)}
                      />
                    );
                  }}
                />
              }
            />
          );
        })}
      </CheckboxWrapper>
    </FormControl>
  );
};
