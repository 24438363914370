// @flow
import React from "react";
import { DiagnosesTreeBreadcrumbsItem } from "./DiagnosesTreeBreadcrumbsItem";
import { DiagnosesTreeBreadcrumbsList } from "./DiagnosesTreeBreadcrumbsList";
import { useList, useStore } from "effector-react";
import {
  changeCurrentDiagnosis,
  closeDiagnosesTree,
  combineWithAppointmentDiagnosesStore,
  currentDiagnosisParentsStore,
  currentDiagnosisStore,
  openedDiagnosesTreeStore,
  transformToDiagnosis
} from "../../stores/diagnosesTree";
import { useDiagnoses } from "../decision-support/useDiagnoses";
import {
  addNewPrimaryDiagnosis,
  primaryDiagnoses,
  removePrimaryDiagnosis,
  secondaryDiagnoses
} from "../../stores/decisionSupportStores";
import { getAppointment_id } from "../../domain/entities/Appointment";
import { useAutoCleanedStore } from "../../utils/useAutoCleanedStore";
import type { AppointmentWithDetails } from "../../domain/entities/Appointment";
import { DiagnosesTreeItem } from "./DiagnosesTreeItem";
import { DiagnosesTreeSidebarLayout } from "./DiagnosesTreeSidebarLayout";
import { getDiagnosis_id } from "../../domain/entities/Diagnosis";
import { createEvent, forward } from "effector";
import { CheckboxLabel } from "../decision-support/CheckboxLabel";

export const DiagnosesTreeSidebar = (props: { appointment: AppointmentWithDetails }) => {
  const { appointment } = props;
  const openedDiagnosesTree = useStore(openedDiagnosesTreeStore);
  const currentDiagnosis = useStore(currentDiagnosisStore);

  const { store: appointmentDiagnosesStore, addNewDiagnosis, removeDiagnosis } = useDiagnoses(
    openedDiagnosesTree === "PRIMARY" ? primaryDiagnoses : secondaryDiagnoses,
    getAppointment_id(appointment),
    openedDiagnosesTree === "PRIMARY"
      ? appointment.primary_diagnoses
      : appointment.secondary_diagnoses
  );

  const addNewPrimaryDiagnosisConnected = React.useMemo(() => addNewPrimaryDiagnosis(appointment), [
    appointment
  ]);
  const removePrimaryDiagnosisConnected = React.useMemo(() => removePrimaryDiagnosis(appointment), [
    appointment
  ]);

  const diagnosesWithCheckedStore = useAutoCleanedStore<*, *, *>(
    () => combineWithAppointmentDiagnosesStore(appointmentDiagnosesStore),
    [appointmentDiagnosesStore]
  );

  const list = useList(diagnosesWithCheckedStore.store, item => {
    const { has_children: hasChildren, is_group: isGroup } = item;
    const diagnosis = transformToDiagnosis(item);
    const diagnosisId = getDiagnosis_id(diagnosis);
    const onClick = createEvent<void>();
    forward({
      from: onClick
        .filter({
          fn: () => hasChildren
        })
        .map(() => diagnosis),
      to: changeCurrentDiagnosis
    });
    const toggleDiagnosis = createEvent<void>();
    forward({
      from: toggleDiagnosis
        .filter({
          fn: () => !item.isChecked && !isGroup
        })
        .map(() => diagnosis),
      to: addNewDiagnosis
    });
    forward({
      from: toggleDiagnosis
        .filter({
          fn: () => !item.isChecked && !isGroup && openedDiagnosesTree === "PRIMARY"
        })
        .map(() => diagnosis),
      to: addNewPrimaryDiagnosisConnected
    });
    forward({
      from: toggleDiagnosis
        .filter({
          fn: () => item.isChecked && !isGroup
        })
        .map(() => diagnosisId),
      to: removeDiagnosis
    });
    forward({
      from: toggleDiagnosis
        .filter({
          fn: () => item.isChecked && !isGroup && openedDiagnosesTree === "PRIMARY"
        })
        .map(() => diagnosisId),
      to: removePrimaryDiagnosisConnected
    });
    const checkbox =
      (!isGroup && (
        <CheckboxLabel
          modifiers={(!item.isChecked && "disabled") || undefined}
          onClick={toggleDiagnosis}
        />
      )) ||
      null;
    return (
      <DiagnosesTreeItem
        hasChildren={hasChildren}
        title={item.icd_code || ""}
        description={item.name}
        onClick={onClick}
        checkbox={checkbox}
      />
    );
  });

  const parentDiagnosisList = useList(currentDiagnosisParentsStore, parentDiagnosis => (
    <DiagnosesTreeBreadcrumbsItem onClick={() => changeCurrentDiagnosis(parentDiagnosis)}>
      <span>
        {parentDiagnosis.icd_code} {parentDiagnosis.name}
      </span>
    </DiagnosesTreeBreadcrumbsItem>
  ));

  const breadcrumbs = (
    <DiagnosesTreeBreadcrumbsList onClose={closeDiagnosesTree}>
      <DiagnosesTreeBreadcrumbsItem onClick={() => changeCurrentDiagnosis(null)}>
        МКБ
      </DiagnosesTreeBreadcrumbsItem>
      {parentDiagnosisList}
      {(currentDiagnosis && (
        <DiagnosesTreeBreadcrumbsItem>
          <span>
            {currentDiagnosis.icd_code} {currentDiagnosis.name}
          </span>
        </DiagnosesTreeBreadcrumbsItem>
      )) ||
        null}
    </DiagnosesTreeBreadcrumbsList>
  );

  return <DiagnosesTreeSidebarLayout breadcrumbs={breadcrumbs} list={list} />;
};
