import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SupportContainer = styled.div`
  background: #C6007F;
  border-radius: 12px;
  width: 103px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 50;
  color: white;
  font-weight: 450;
  cursor: pointer;
  &:before {
      content: "";
      display: inline-block;
      position: relative;
      width: 8px;
      height: 8px;
      line-height: 8px;
      margin-right: 5px;
      border-radius: 5px;
      vertical-align: .1em;
      box-shadow: 0 0 0 1px hsla(0,0%,100%,.8);
      background-color: #51D161;
      background-image: -webkit-linear-gradient(left top,#80DC7A,#23C748);
      background-image: linear-gradient(to right bottom,#80DC7A,#23C748)
  }
    
  @media (max-width: 768px) {
    ${props => (props.showMobile ? "display: flex;" : "display: none;")};
	  right: 20px;
	  bottom: 20px;
  }

  @media print {
      display: none;
  }
`;

export const VoximplantSupport = (props) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!window.VoxKitWidget) {
      const script = document.createElement('script')
      script.textContent=`var l=function(){var t=function(){"object"==typeof VoxKitWidget&&"object"==typeof VoxKitWidgetSettings&&VoxKitWidget.init(VoxKitWidgetSettings)},e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://kit.voximplant.com/static/widgets/web-chat.js?"+(new Date).valueOf(),e.readyState?e.onreadystatechange=function(){"loaded"!==e.readyState&&"complete"!==e.readyState||(e.onreadystatechange=null,t())}:e.onload=function(){t()};var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(e,a)};l();`;
      script.type = 'text/javascript';
      document.getElementsByTagName('head')[0].appendChild(script);
    }
    setIsInitialized(true);
  }, [window.VoxKitWidgetSettings.client_data.client_display_name]);



  return (
    <>
      {isInitialized && (
        <SupportContainer showMobile={props.showMobile} onClick={() => {
          document.getElementsByClassName('widget-btn')[0]?.click();
          setTimeout(() => {
            document.getElementsByClassName('editable')[0]?.focus();
          });
        }}>
          <span>Помощь</span>
        </SupportContainer>
      )}
    </>
  );
};
