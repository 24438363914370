import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Review {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getLink(token, app, city) {
    return this.apiService.get(`review/link`, { token, app, city });
  }

  putProgress(progress) {
    return this.apiService.put(`review/set_progress`, {}, progress, {}, true);
  }
}

export default new Review();
