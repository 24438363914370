// @flow
import { useStore } from "effector-react";
import { currentUser } from "../../stores/auth";
import * as React from "react";
import { Doctor } from "../doctor/Doctor";
import { getLastNameWithInitials } from "../../domain/services/doctor";

const HeaderUser = ({ isHidingName }) => {
  const user = useStore(currentUser);
  if (!user) {
    return null;
  } else if (user.role === "doctor") {
    const doctor = user.doctor;
    return (
      <Doctor
        name={getLastNameWithInitials(user.doctor)}
        avatar={doctor.photo || undefined}
        needLinkToProfile
        doctor={doctor}
        isHidingName={isHidingName}
      />
    );
  } else if (user.role === "post_nurse") {
    const nurse = user.post_nurse;
    return (
      <Doctor
        name={getLastNameWithInitials(user.post_nurse)}
        avatar={nurse.photo || undefined}
        doctor={nurse}
        isHidingName={isHidingName}
      />
    );
  } else {
    return <Doctor name={user.username} />;
  }
};

export default HeaderUser;
