import React from 'react';
import { makeAutoObservable } from 'mobx';

import { useToast } from '../components/toast/useToast';
import apiv2 from '../apiv2';

const toastManager = useToast();

export const FieldTypes = {
  TEXT: 'text',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  CHECKBOX: 'checkbox',
  EMAIL: 'email',
  PHONE: 'phone',
  DATE: 'date',
};

const ERROR_MESSAGE_MAP = {
  "Not found appointment in folder.": "Не найден приём в папке",
  "Not found embryos in folder.": "Не найдено доступных эмбрионов для отправки"
};

class MedicalGenomicsStore {
  orderList = {};
  orderData = {};
  metadata = {};
  formData = {};
  formErrors = {};
  isFormOpen = false;
  isListOpen = false;
  isOrderCreating = false;
  isOrderSending = false;
  isOrderListLoading = false;
  isEditingDisabled = false;
  isOpenedFromList = false;

  constructor() {
    makeAutoObservable(this);
  }

  get embryoTableData() {
    return this.metadata.mapper_order_embryo || {};
  }

  get fieldsDetails() {
    return this.metadata.mapper_order_detail || {};
  }

  get gridTemplate() {
    return this.metadata.grid_template_order_detail ? this.metadata.grid_template_order_detail.reduce((acc, next) => {
      return `${acc}\n"${next}"`;
    }, "") : "";
  }

  get embryoTableValues() {
    return this.orderData?.embryos?.map((item, i) => {
      return Object.keys(this.embryoTableData).map(key => ({
        value: item[key],
        readOnly: true,
        index: [i, 0],
        removable: false,
        fieldType: key
      }));
    }) || [];
  }

  get formErrorFields() {
    return Object.entries(this.formErrors).filter(([, fieldError]) => Boolean(fieldError)).map(([fieldName]) => this.fieldsDetails[fieldName]?.label);
  }

  *createOrder(folderId) {
    try {
      this.isOrderCreating = true;
      const newOrder = yield apiv2.medicalGenomics.createOrder({ folderId });
      this.setFormData({});
      this.setOrderData(newOrder.order);
      this.setMetadata(newOrder.__metadata__);
      this.showForm();
      this.isOrderCreating = false;
    } catch (e) {
      this.isOrderCreating = false;
      const messageTranlation = ERROR_MESSAGE_MAP[e.result?.message] || null;
      toastManager.showToast(
        <div>
          Произошла ошибка при создании заказа.<br/>
          Код ошибки: {e.code} {messageTranlation && messageTranlation}
        </div>,
        {
          duration: 10000
        }
      );
      console.error(e);
    }
  }

  *sendOrder() {
    this.isOrderSending = true;
    if (!this.validateFormData()) {
      this.isOrderSending = false;
      return;
    }
    try {
      yield apiv2.medicalGenomics.updateOrder({
        orderId: this.orderData.id,
        orderData: this.transformedFormData
      });
      yield apiv2.medicalGenomics.sendOrder({ orderId: this.orderData.id });
      toastManager.showToast(
        <div>
          Данные успешно отправлены в Medical Genomics
        </div>,
        {
          duration: 10000
        }
      );
      this.isOrderSending = false;
      this.closeForm();
    } catch(e) {
      console.error(e);
      toastManager.showToast(
        <div>
          Произошла неизвестная ошибка при отправке данных.<br/>
          Перезагрузите страницу или попробуйте позже.
        </div>,
        {
          duration: 10000
        }
      );
      this.isOrderSending = false;
    }
  }

  *fetchOrderListForFolder(folderId) {
    if (!folderId) {
      return;
    }
    try {
      this.isOrderListLoading = true;
      const { orders, __metadata__ } = yield apiv2.medicalGenomics.getOrderList(folderId);
      this.setMetadata(__metadata__);
      this.orderList = {...this.orderList, [folderId]: orders};
      this.isOrderListLoading = false;
      return orders;
    } catch(e) {
      this.isOrderListLoading = false;
      console.error(e);
    }
  }

  getOrderListForFolder(folderId) {
    return this.orderList[folderId] || [];
  }

  setFormData(data) {
    if (data instanceof Function) {
      this.formData = data(this.formData);
    } else {
      this.formData = data;
    }
  }

  validateFormData() {
    const invalidFields = [];

    for (let fieldName in this.formData) {
      const fieldDetails = this.fieldsDetails[fieldName];
      const fieldData = this.formData[fieldName];
      if (!fieldDetails || (!fieldDetails.is_required && !fieldData)) {
        continue;
      }

      const regPhone = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gi;
      if (fieldDetails.type === FieldTypes.PHONE) {
        if (!fieldData.length) {
          this.setFieldError(fieldName, true);
          invalidFields.push(fieldName);
        }
        if (!regPhone.test(fieldData)) {
          this.setFieldError(fieldName, "Неверный формат номера");
          invalidFields.push(fieldName);
        }
      }
      if (
        (fieldDetails.type === FieldTypes.MULTISELECT && !fieldData?.length) ||
        (fieldDetails.type === FieldTypes.SELECT && !fieldData?.value) ||
        (fieldDetails.type === FieldTypes.EMAIL && !fieldData?.length) ||
        (fieldDetails.type === FieldTypes.TEXT && !fieldData?.length)
      ) {
        this.setFieldError(fieldName, true);
        invalidFields.push(fieldName);
      }
    }

    return invalidFields.length === 0;
  }

  get transformedFormData() {
    const newFormData = {};

    for (let fieldName in this.formData) {
      const fieldDetails = this.fieldsDetails[fieldName]

      if (!fieldDetails.is_required && !fieldDetails.is_editable) continue;

      const fieldValue = this.formData[fieldName];

      if (fieldDetails.type === FieldTypes.MULTISELECT) {
        newFormData[fieldName] = fieldValue.map(selectValue => selectValue.value);
        continue;
      }

      if (fieldDetails.type === FieldTypes.SELECT) {
        newFormData[fieldName] = fieldValue.value;
        continue;
      }

      if (fieldDetails.type === FieldTypes.PHONE) {
        newFormData[fieldName] = fieldValue.split("").filter(char => /[+0-9]/.test(char)).join("");
        continue;
      }

      newFormData[fieldName] = fieldValue;
    }

    return newFormData;
  }

  hasFormData() {
    return Object.keys(this.formData).length > 0;
  }

  setFieldValue(fieldName, value) {
    this.formData = { ...this.formData, [fieldName]: value };
  }

  setFieldError(fieldName, error) {
    this.formErrors = { ...this.formErrors, [fieldName]: error };
  }

  setOrderData(orderData) {
    this.orderData = orderData;
  }

  setMetadata(metadata) {
    this.metadata = metadata;
  }

  setEditingDisabled(value) {
    this.isEditingDisabled = value;
  }

  setIsOpenedFromList(value) {
    this.isOpenedFromList = value;
  }

  setFormErrors(errors) {
    if (errors instanceof Function) {
      this.formErrors = errors(this.formErrors);
    } else {
      this.formErrors = errors;
    }
  }

  getFieldValue(fieldName) {
    return this.formData[fieldName];
  }

  hasFieldError(fieldName) {
    return !!this.formErrors[fieldName];
  }

  getFieldError(fieldName) {
    return this.formErrors[fieldName] === true ? "Это поле обязательно" : this.formErrors[fieldName];
  }

  showForm() {
    this.isFormOpen = true;
  }

  showList() {
    this.isListOpen = true;
  }

  closeList() {
    this.isListOpen = false;
  }

  closeForm() {
    this.isFormOpen = false;
    this.setOrderData({});
    this.setFormData({});
    if (!this.isOpenedFromList) {
      this.setMetadata({});
    }
    this.formErrors = {};

    if (this.isOpenedFromList) {
      this.showList();
    }
  }
}

export default new MedicalGenomicsStore();
