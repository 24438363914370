import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Payment {
	apiService;
	constructor() {
		this.apiService = new ApiService('/api/payment', null, null, API_URL);
	}

	getPayment(id) {
		return this.apiService.get(id);
	}

	getListPayments({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params}) {
		return this.apiService.post(`analytics/list`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params});
	}

	getServiceAmounts(filters) {
		return this.apiService.post(`analytics/service-amounts`, {}, filters);
	}
}

export default new Payment();
