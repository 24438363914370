// @flow
import * as React from "react";
import type { Appointment } from "../../domain/entities/Appointment";
import useReactRouter from "use-react-router";

export const OpenedAppointmentProvider = (props: {
  appointment: Appointment,
  children: boolean => React$Node
}) => {
  const {
    match: { params }
  } = useReactRouter();
  const { appointment, children } = props;
  const isSelectedFolder = appointment.type === "fromFolder" && appointment.folder.id === parseInt(params.id);

  return <React.Fragment>{children("" + appointment.id === params.appointment_id, isSelectedFolder)}</React.Fragment>;
};
