"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useTrigger", {
  enumerable: true,
  get: function get() {
    return _useTrigger.useTrigger;
  }
});
Object.defineProperty(exports, "createTrigger", {
  enumerable: true,
  get: function get() {
    return _createTrigger.createTrigger;
  }
});

var _useTrigger = require("./useTrigger");

var _createTrigger = require("./createTrigger");