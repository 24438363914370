import React, { useState } from "react";
import { setFieldValue } from "../../stores/appointmentFieldValues";
import { Fieldset } from "../form/Fieldset";
import { NumericInput } from "../form-elements/NumericInput";
import { FieldsetContainer } from "../appointment-page/EditAppointmentPage";
import { ReadOnlyInput } from "../form-elements/ReadOnlyInput";
import { calculateBodyMassIndex, isValidBodyMassIndex } from "../../domain/services/bodyMassIndex";

const getValues = (fieldValue, layoutFieldValue) => {
  if (fieldValue && fieldValue.value) {
    return {
      height: fieldValue.value.height ? parseFloat(fieldValue.value.height) : null,
      weight: fieldValue.value.weight ? parseFloat(fieldValue.value.weight) : null
    };
  }

  if (layoutFieldValue && layoutFieldValue.value) {
    return {
      height: layoutFieldValue.value.height ? parseFloat(layoutFieldValue.value.height) : null,
      weight: layoutFieldValue.value.weight ? parseFloat(layoutFieldValue.value.weight) : null
    };
  }

  return {
    height: null,
    weight: null
  };
};

export const BodyMassIndexWidget = ({ field, appointment_id, layoutFieldValue }) => {
  const [isHighlighted, setIsHighlighted] = useState({ height: false, weight: false });
  const values = getValues(field, layoutFieldValue);

  const bmi =
    !values.height || !values.weight ? null : calculateBodyMassIndex(values.height, values.weight);

  const handleInputChange = (key, value) => {
    const newValue = { ...values, [key]: value ? parseFloat(value) : null };
    setFieldValue({
      field_type: field.field_type,
      appointment_id,
      field_id: field.id,
      value: newValue
    });
  };

  const handleFocus = () => setIsHighlighted({ height: false, weight: false });
  const handleBlur = () =>
    !isValidBodyMassIndex(values) &&
    setIsHighlighted({ height: !values.height, weight: !values.weight });

  return (
    <>
      <FieldsetContainer>
        <Fieldset legend="Рост, см">
          <NumericInput
            value={values.height || ""}
            handleChange={newValue => handleInputChange("height", newValue)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={isHighlighted.height}
          />
        </Fieldset>
      </FieldsetContainer>
      <FieldsetContainer>
        <Fieldset legend="Вес, кг">
          <NumericInput
            value={values.weight || ""}
            handleChange={newValue => handleInputChange("weight", newValue)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={isHighlighted.weight}
          />
        </Fieldset>
      </FieldsetContainer>
      <FieldsetContainer>
        <Fieldset legend={field.name}>
          <ReadOnlyInput>{bmi || ""}</ReadOnlyInput>
        </Fieldset>
      </FieldsetContainer>
    </>
  );
};
