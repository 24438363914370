import { makeAutoObservable } from 'mobx';

class DynamicFiltersStore {
  isAdvancedMode = false;
  FILTERS_FN_LABEL = {
    iexact: 'Равно',
    lte: 'Меньше или равно',
    gte: 'Больше или равно',
    in: 'Включает в себя',
    icontains: 'Содержит',
    range: 'Интервал',
    isnull: 'Пустое'
  };
  FILTERS_FN = [
    'iexact',
    'lte',
    'gte',
    'in',
    'icontains',
    'isnull',
  ]

  FILTERS_FN_FOR_TYPE_FIELD = {
    text: ['icontains', 'iexact', 'isnull'],
    checkbox: ['iexact', 'isnull'],
    checkboxGroup: ['iexact', 'in', 'isnull'],
    textarea: ['icontains', 'iexact', 'isnull'],
    number: ['iexact', 'lte', 'gte', 'icontains', 'isnull'],
    date: ['iexact', 'lte', 'gte', 'range', 'isnull'],
    select: ['iexact', 'in', 'isnull'],
    selectDoctor: ['iexact', 'in', 'isnull'],
    radio: ['iexact', 'isnull'],
    slider: ['iexact', 'lte', 'gte', 'range', 'isnull'],
  }

  constructor() {
    makeAutoObservable(this);
  }

  changeAdvancedMode(value) {
    this.isAdvancedMode = value;
  }
}

export default new DynamicFiltersStore();
