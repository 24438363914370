// @flow
import * as React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";
import { DefaultInput } from "./DefaultInput";
import { BaseStyledInput } from "./BaseStyledInput";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import countries from "./value/phoneCountries";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

const StyledInput = styled(BaseStyledInput)`
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 51px;

  ::placeholder {
    opacity: 1;
    color: ${colors.darkBlack};
  }
`;

export const PhoneInputWithIconContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Icon = styled.div`
  width: 26px;
  height: 26px;
  background: url(${props => props.icon}) no-repeat;
  background-size: contain;

  position: absolute;
  top: 16px;
  left: 13px;
`;

export const isValidNumberFunction = value => {
  if (typeof value !== "string") {
    return false;
  }
  const obj = parsePhoneNumberFromString(value);
  let isValid = obj ? obj.isValid() : false;

  return isValid;
};

export const PhoneInputWithIcon = (props: {|
  value: string | number,
  icon: string,
  type?: "text" | "password",
  placeholder?: string | number,
  handleChange: string => mixed,
  onKeyDown?: string => mixed,
  error?: string | boolean
|}) => {
  const { icon, value, ...defaultIconProps } = props;
  const IconComponent = () => <Icon icon={icon} />;
  const inputRef = useRef(null);
  const [positionCaret, setPositionCaret] = useState(0);
  const [countryISO, setCountryISO] = useState("");
  const [country, setCountry] = useState(value || "");
  const [oldCountry, setOldCountry] = useState(value || "");
  const countryCodeFromValue = () => {
    if (!value) {
      return "";
    }
    const phoneInfo = parsePhoneNumberFromString(value);
    if (phoneInfo && phoneInfo.country) {
      return _.find(countries, { iso: phoneInfo.country }).code;
    } else {
      for (let i = value.length; i > 1; i--) {
        if (_.find(countries, { code: value.substr(0, i) })) {
          return value.substr(0, i);
        }
      }
    }
    return value;
  };
  useEffect(() => {
    if (inputRef !== null) {
      // возвращаем курсор на оригинальную позицию
      inputRef.current.selectionStart = positionCaret;
      inputRef.current.selectionEnd = positionCaret;
    }
  }, [positionCaret]);
  useEffect(() => {
    watchCountry();
  }, [country]);
  const watchCountry = () => {
    if (country) {
      let v = value;
      const countryCodeFromValueData = countryCodeFromValue();
      if (!countryCodeFromValueData && !oldCountry) {
        props.handleChange(country.code + " ");
        return;
      }
      if (countryCodeFromValueData === country.code && v[countryCodeFromValueData.length] === " ") {
        return;
      }
      if (v.indexOf(countryCodeFromValueData + " ") === 0) {
        v = v.replace(countryCodeFromValueData + " ", country.code + " ");
      } else {
        v = v.replace(countryCodeFromValueData, country.code + " ");
      }
      props.handleChange(v);
    }
  };
  useEffect(() => {
    updateCountryISO();
    if (formatPhoneNumber(value) !== value) {
      props.handleChange(formatPhoneNumber(value));
    } else if (!value.startsWith("+") && isValidNumberFunction("+" + value)) {
      props.handleChange("+" + formatPhoneNumber(value));
    }
    if (!value) {
      props.handleChange("+");
    }
  }, [value]);

  const onInput = event => {
    if (!event.currentTarget.value.startsWith("+")) {
      let newValue = event.currentTarget.value;
      if (newValue.indexOf('+') > -1) {
        newValue = newValue.split('');
        newValue.splice(newValue.indexOf('+'), 1);
      }
      props.handleChange("+" + newValue);
      setPositionCaret(event.target.selectionStart + 1);
    } else {
      let newValue = event.currentTarget.value;
      if (newValue && value.split(" ").length > newValue.split(" ").length) {
        const changeIndex = value
          .split("")
          .findIndex((item, index) => item !== newValue.charAt(index));
        const dataSplit = newValue.split("");
        dataSplit.splice(changeIndex - 1, 1);
        newValue = dataSplit.join("");
      }
      const formatNumber = newValue ? formatPhoneNumber(newValue) : "";

      let countSpaceNewValue = newValue.split("");
      if (value.length > newValue.length) {
        countSpaceNewValue.splice(0, event.target.selectionStart);
      }
      countSpaceNewValue = countSpaceNewValue.join("").split(" ").length;
      let countSpaceFormatNumber = formatNumber.split("");
      if (value.length > newValue.length) {
        countSpaceFormatNumber.splice(0, event.target.selectionStart);
      }
      countSpaceFormatNumber = countSpaceFormatNumber.join("").split(" ").length;

      props.handleChange(formatNumber);
      if (value.length > newValue.length) {
        setPositionCaret(
          newValue.length !== event.currentTarget.value.length
            ? event.target.selectionStart - (countSpaceNewValue - countSpaceFormatNumber + 1)
            : event.target.selectionStart
        );
      } else if (countSpaceFormatNumber > countSpaceNewValue) {
        setPositionCaret(event.target.selectionStart + (countSpaceFormatNumber - countSpaceNewValue));
      }
    }
  };
  const onPaste = pasteValue => {
    const v = pasteValue.replace(/(?:\r\n|\r|\n)/g, " ");
    setPhone(v);
  };
  const setPhone = number => {
    let v = number;
    setTimeout(() => {
      let trimmedV = v.replace(/[^0-9+]/gi, "");
      if (v.startsWith("+")) {
        v = value;
      } else {
        const plusValue = "+" + v;
        const obj = parsePhoneNumberFromString(plusValue);
        let isValid = obj ? obj.isValid() : false;
        if (isValid) {
          v = value;
          trimmedV = plusValue;
        } else if (v.startsWith("8")) {
          const obj = parsePhoneNumberFromString(v.replace("8", "+7"));
          let isValid = obj ? obj.isValid() : false;
          if (isValid) {
            v = value;
          }
        }
      }

      if (v !== trimmedV) {
        props.handleChange(formatPhoneNumber(trimmedV));
      }
    });
    return true;
  };
  const updateCountryISO = () => {
    const phoneInfo = value ? parsePhoneNumberFromString(value) : null;
    let newCountryISO;
    if (phoneInfo && phoneInfo.country) {
      newCountryISO = phoneInfo.country;
    } else {
      // определяем страну по коду когда телефон не заполнен
      const newCountry = _.find(countries, { code: value.trim(), priority: 0 });
      // берем iso из страны
      if (!country || country.code !== _.get(newCountry, "code", null)) {
        newCountryISO = _.get(newCountry, "iso", null);
      }
    }
    if (!newCountryISO && country && value.indexOf(country.code) === 0) {
      return;
    }
    setCountryISO(newCountryISO);
  };

  const setCountryValue = () => {
    setOldCountry(country);
    return _.find(countries, { iso: countryISO });
  };

  useEffect(() => {
    setCountry(setCountryValue);
  }, [countryISO]);

  const formatPhoneNumber = (value, notNeedReturnDefaultValue) => {
    const number = parsePhoneNumberFromString(value, country ? country.ISO : "RU");
    if (number) {
      if (number.isValid()) {
        return number.formatInternational().trim();
      }
    }
    return notNeedReturnDefaultValue ? null : value;
  };

  return (
    <DefaultInput
      {...defaultIconProps}
      handleChange={() => {}}
      handleInput={onInput}
      onPaste={onPaste}
      value={value}
      onKeyDown={props.onKeyDown}
      inputRef={inputRef}
      overrides={{
        Container: {
          component: PhoneInputWithIconContainer
        },
        Icon: {
          component: IconComponent
        },
        Input: {
          component: StyledInput,
          props: {
            guide: true,
            ...((props.overrides && props.overrides.Input.props) || {})
          }
        }
      }}
    />
  );
};
