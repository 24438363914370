// @flow
import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import * as yup from "yup";
import useReactRouter from "use-react-router";
import { useToast } from "../toast/useToast";
import ticketStore, { AREAS_FIELD, getTicketOptionsByAccessorKey, TICKET_SEGMENTS_URL } from "../../stores/ticketStore";
import { DynamicForm, backToHistory } from "../common/dynamic-form/DynamicForm";
import { currentUser as currentUserStore } from "../../stores/auth";
import { DynamicFormContainer } from "../common/dynamic-form/styled/Container.styled";
import { Button } from "@mui/material";
import { FeedbackForm } from "./FeedbackForm";
import apiv2 from "../../apiv2";

const getOptionsArea = () => {
  return Object.keys(AREAS_FIELD).map(key => ({ value: key, label: AREAS_FIELD[key] }));
};

const FEEDBACK_FIELD = {
  city: "Город:",
  problem: "Проблема:",
  categories: "Категория:",
  causes: "Вероятная причина:",
  fullName: "Ваше ФИО:",
  phoneNumber: "Ваш номер телефона:",
  patientFullName: "ФИО пациента:",
  medCard: "Номер медицинской карты пациента:",
  linkAppointment: "Ссылка на приём в Асе:",
  employeeFullName: "ФИО сотрудника, допустившего ошибку:",
  messageAboutFatigue: "Причина усталости на рабочем месте:"
};

/** Собирается строка в формате из данных для textarea */
const generateFieldDescription = data => {
  if (!data) {
    return "";
  }
  return Object.entries(data)
    .filter(([key]) => !["additionalInformation"].includes(key))
    .reduce((acc, [key, value]) => {
      if (!value || !value.length) {
        return acc;
      }
      acc += `${acc.length ? "\n" : ""}`;
      if (Array.isArray(value)) {
        acc += `${FEEDBACK_FIELD[key]}`;
        value.forEach((item, index) => {
          const lastElement = index === value.length - 1;
          acc += ` ${item}${lastElement ? ";" : ","}`;
        });
      } else {
        acc += `${FEEDBACK_FIELD[key]} ${value};`;
      }
      return acc;
    }, "");
};

export const TicketCreatePage = observer(() => {
  const { history } = useReactRouter();
  const currentUser = currentUserStore.getState();
  const [localTicketData, setLocalTicketData] = useState({});
  const [isSelectedMedicalError, setIsIsSelectedMedicalError] = useState(false);
  const [isShowFeedbackForm, setIsShowFeedbackForm] = useState(false);
  const ticketParams = TICKET_SEGMENTS_URL[ticketStore.segment];
  const toastManager = useToast();

  const handleOnOpenFeedbackForm = () => {
    setIsShowFeedbackForm(true);
  };
  const handleOnCloseFeedbackForm = () => {
    setIsShowFeedbackForm(false);
  };

  useEffect(() => {
    (async () => {
      if (ticketParams.defaultData()?.area === 'Врачебная ошибка') {
        handleOnOpenFeedbackForm();
      }
    })();
  }, []);


  const createSchema = useMemo(
    () => {
      if (ticketParams.createSchema) {
        return ticketParams.createSchema;
      }
      return [
        {
          type: "text",
          name: "title",
          label: "Заголовок",
          md: 6,
          xs: 12,
        },
        {
          type: "select",
          name: "area",
          label: "Область",
          md: 6,
          xs: 12,
          options: ticketParams.defaultData()?.area === "Врачебная ошибка" ? [{ value: 'medical_error' }] : getOptionsArea()
        },
        {
          type: "text",
          name: "theme",
          label: "Тема",
          md: 6,
          xs: 12,
        },
        {
          type: "textarea",
          name: "description",
          label: "Описание",
          md: 6,
          xs: 12,
        },
        {
          type: "text",
          name: "author_text",
          label: "Автор текстом, если нет пользователя"
        },
        {
          type: "text",
          name: "author__username",
          label: "Автор",
          disabled: true,
          md: 6,
          xs: 12,
        },
        {
          type: "select",
          name: "status",
          label: "Статус",
          options: getTicketOptionsByAccessorKey("status"),
          md: 6,
          xs: 12,
        },
        {
          type: "select",
          name: "priority",
          label: "Приоритет",
          options: getTicketOptionsByAccessorKey("priority"),
          md: 6,
          xs: 12,
        }
      ].map(item => {
        if (ticketParams.hiddenFieldForCreation.includes(item.name)) {
          item.hiddenField = true;
        }
        if (ticketParams.disabledFieldsForCreation.includes(item.name)) {
          item.readOnly = true;
          item.disabled = true;
        }
        return item;
      })
    },
    [ticketStore.segment]
  );

  const defaultData = useMemo(() => {
    const newData = {
      ...localTicketData,
      status: "created",
      priority: "medium",
      author: currentUser?.id,
      author__username: currentUser?.username,
      description: generateFieldDescription(ticketStore.feedbackData),
      ...ticketParams.defaultData()
    };
    if (newData.area === "Врачебная ошибка") {
      setIsIsSelectedMedicalError(true);
    }
    return newData;
  }, [ticketStore.ticket, ticketStore.feedbackData]);

  const validateSchema = yup.object({
    title: yup.string().required("Обязательное поле"),
    area: yup.string().required("Обязательное поле")
  });

  const watcherOnChangeField = data => {
    setLocalTicketData(data);
    setIsIsSelectedMedicalError(data.area === "Врачебная ошибка");
  };

  const handleOnBack = () => ticketStore.resetFeedbackData();

  const handleOnSubmitEdit = async data => {
    try {
      delete data.author__username;
      await ticketStore.postCreateTicket(data, true);
      ticketStore.resetFeedbackData();
      if (defaultData.area !== 'Врачебная ошибка') {
        backToHistory(null, history);
      } else {
        setLocalTicketData({});
        ticketStore.setFeedbackData({});
        toastManager.showToast(
          <div>
            Обращение успешно создано
          </div>,
          {
            duration: 20000
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <DynamicFormContainer>
      {defaultData && (
        <DynamicForm
          hiddenButtonBack={defaultData.area === 'Врачебная ошибка'}
          hiddenButtonCancel={defaultData.area === 'Врачебная ошибка'}
          callbackOnClose={handleOnBack}
          data={defaultData}
          submitHandler={handleOnSubmitEdit}
          fields={createSchema}
          validateSchema={validateSchema}
          title="Создание обращения"
          debounceTime={0}
          titleButton={
            isSelectedMedicalError && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOnOpenFeedbackForm}>
                Форма обратной связи
              </Button>
            )
          }
          textButton={"Создать"}
          changeHandler={watcherOnChangeField}
        />
      )}
      <FeedbackForm isOpen={isShowFeedbackForm} onClose={handleOnCloseFeedbackForm} />
    </DynamicFormContainer>
  );
});
