// @flow
import { AddingHOC } from "./AddingHOC";
import { AddPointContainer, AddPointText, NewPointInputContainer } from "./AddPointContainer";
import { AutosuggestRelativeContainer } from "./AutosuggestRelativeContainer";
import { NewPointInput } from "./NewPointInput";
import { SelectContainer } from "./SelectContainer";
import { ListContainer } from "../select/ListContainer";
import { Option } from "../select/Option";
import { OptionsSeparator } from "../select/OptionsSeparator";
import { AddButton } from "../select/AddButton";
import { AddPointButton } from "./AddPointButton";
import React from "react";
import styled from "styled-components";
import { AutosuggestAbsoluteContainer } from "./AutosuggestAbsoluteContainer";
import { ListPointsContainer } from "../select/ListPointsContainer";

const PointAutosuggestAbsoluteContainer = styled(AutosuggestAbsoluteContainer)`
  left: 46px;
`;

type BaseItem = {
  id: any,
  name: string
};

type TSuggestProvider<T> = React$ComponentType<{
  value: string,
  children: (items: Array<T>) => React$Node
}>;

const OptionsList = <T: BaseItem>(props: { items: Array<T>, onClick: (item: T) => void }) => {
  const { items, onClick } = props;
  return (
    <>
      {items.map(item => {
        //$FlowFixMe
        const key = item.syntheticId || item.id;
        return <Option onClick={() => onClick(item)} key={key} text={item.name} />;
      })}
    </>
  );
};

export const PointAdding = <T: BaseItem>(props: {
  propose: string => mixed,
  title?: string,
  select: T => mixed,
  SuggestProvider: TSuggestProvider<T>
}) => {
  const { propose, select, title, SuggestProvider } = props;
  return (
    <AddingHOC>
      {({ value, onChange, isAdding, setAdding }) => (
        <>
          {isAdding && (
            <NewPointInputContainer>
              <AutosuggestRelativeContainer>
                <SuggestProvider value={value}>
                  {items => (
                    <>
                      <NewPointInput
                        value={value}
                        onChange={onChange}
                        onBlur={() => {
                          setTimeout(() => setAdding(false), 1000);
                        }}
                        onEnter={() => {
                          if (items.length > 0) {
                            select(items[0]);
                          }
                          setTimeout(() => setAdding(false), 100);
                        }}
                      />
                      {(items.length > 0 || value.length > 0) && (
                        <PointAutosuggestAbsoluteContainer>
                          <SelectContainer>
                            <ListContainer role="menu">
                              <ListPointsContainer>
                                <OptionsList
                                  items={items}
                                  onClick={item => {
                                    select(item);
                                    setAdding(false);
                                  }}
                                />
                              </ListPointsContainer>
                              <OptionsSeparator />
                              <AddButton
                                text={value}
                                onClick={() => {
                                  const trimmedValue = value.trim();
                                  if (trimmedValue && trimmedValue.length) {
                                    propose(value);
                                  }
                                  setAdding(false);
                                }}
                              />
                            </ListContainer>
                          </SelectContainer>
                        </PointAutosuggestAbsoluteContainer>
                      )}
                    </>
                  )}
                </SuggestProvider>
              </AutosuggestRelativeContainer>
            </NewPointInputContainer>
          )}
          {!isAdding && (
            <AddPointContainer onClick={() => setAdding(true)} withHover withText={!!title}>
              <AddPointButton />
              {title && <AddPointText>{title}</AddPointText>}
            </AddPointContainer>
          )}
        </>
      )}
    </AddingHOC>
  );
};
