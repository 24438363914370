import * as React from "react";

export const SignalIndicator = ({strength, style}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <rect x="4" y="13" width="4" height="5" rx="2" fill={strength >= 1 ? "white" : "rgba(255, 255, 255, 0.2)"} />
      <rect x="10" y="9" width="4" height="9" rx="2" fill={strength >= 2 ? "white" : "rgba(255, 255, 255, 0.2)"} />
      <rect x="16" y="6" width="4" height="12" rx="2" fill={strength >= 3 ? "white" : "rgba(255, 255, 255, 0.2)"} />
    </svg>
  )
};
