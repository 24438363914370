//@flow
import React, { useState } from "react";
import styled from "styled-components";

import { HeadingH3 } from "../styleguide/typography";
import { ModalWithCloseButtonLayout } from "../modal/ModalWithCloseButtonLayout";
import { useModalEscape } from "../modal/useModalEscape";
import { ModalFade } from "../modal/ModalFade";
import { ModalBackground } from "../modal/ModalBackground";
import { PrimaryButton, PrimaryButtonContainer } from "../buttons/PrimaryButton";
import accountStore from "../../stores/accountStore";
import { DefaultInput } from "../form-elements/DefaultInput";
import { FieldsetVertical } from "../form/FieldsetVertical";
import { ButtonRadioGroup } from "../form-elements/ButtonRadioGroup";
import { NumericInput } from "../form-elements/NumericInput";
import { observer } from "mobx-react";

const Form = styled.form`
  padding-top: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const inputOverrides = {
  Container: {
    component: InputContainer
  },
  Input: {
    props: {
      modifiers: "big"
    }
  }
};

const PrimaryButtonContainerMax = styled(PrimaryButtonContainer)`
  width: 256px;
  padding: 0 40px;
`;

const Container = styled.div`
  width: 663px;
  padding: 32px 48px;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    padding: 20px 32px;
  } 
`;

const FIELD_FOR_TYPE = {
  is_online: ({minutes}) => {
    return { is_online: true, duration: minutes };
  },
  is_chat: ({days}) => {
    return { is_chat: true, duration: days * 60 * 24 };
  }
}

export const AccountAddServiceModal = observer((props: {
  hide: () => void,
}) => {
  const { hide } = props;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('is_online');
  const [minutes, setMinutes] = useState('');
  const [days, setDays] = useState('');
  const [price, setPrice] = useState('');

  useModalEscape(hide);

  const handlerAddButton = async () => {
    const data = { name, description, price, ...FIELD_FOR_TYPE[type]({days, minutes}) };
    const isCreate = await accountStore.addDoctorService(data)
    if (isCreate) {
      accountStore.setShowAccountAddServiceModal(false);
    }
  }

  const button = (
    <PrimaryButton
      disabled={
        !name ||
        !type ||
        !price ||
        (type === "is_chat" ? !days : !minutes)
      }
      primaryButtonContainer={PrimaryButtonContainerMax}
      onClick={handlerAddButton}>
      Сохранить
    </PrimaryButton>
  );

  return (
    <ModalFade>
      <ModalBackground>
        <ModalWithCloseButtonLayout handleClose={hide}>
          <Container>
            <HeadingH3>Новая услуга</HeadingH3>
            <Form visible={true} button={button}>
              <FieldsetVertical legend={"Название услуги*"}>
                <DefaultInput value={name} handleChange={setName} overrides={inputOverrides} />
              </FieldsetVertical>
              {/*<FieldsetVertical legend={"Описание услуги"}>*/}
              {/*  <DefaultInput value={description} handleChange={setDescription} overrides={inputOverrides} />*/}
              {/*</FieldsetVertical>*/}
              <FieldsetVertical legend={"Тип услуги*"}>
                <ButtonRadioGroup
                  items={{
                    is_online: {
                      name: "Видео"
                    },
                    is_chat: {
                      name: "Чат"
                    }
                  }}
                  checked={type}
                  name="type"
                  shouldUncheck={false}
                  onChange={setType}
                />
              </FieldsetVertical>
              {type === 'is_online' ? (
                <FieldsetVertical legend={"Продолжительность, мин.*"}>
                  <ButtonRadioGroup
                    items={{
                      15: {
                        name: "15"
                      },
                      30: {
                        name: "30"
                      },
                      45: {
                        name: "45"
                      },
                      60: {
                        name: "60"
                      },
                      90: {
                        name: "90"
                      },
                    }}
                    checked={minutes}
                    name="minutes"
                    shouldUncheck={false}
                    onChange={setMinutes}
                  />
                </FieldsetVertical>
              ) : (
                <FieldsetVertical legend={"Количество дней*"}>
                  <NumericInput
                    value={days || ""}
                    handleChange={setDays}
                    overrides={inputOverrides}
                  />
                </FieldsetVertical>
              ) }
              <FieldsetVertical legend="Стоимость, ₽*">
                <NumericInput
                  value={price || ""}
                  handleChange={setPrice}
                  overrides={inputOverrides}
                />
              </FieldsetVertical>
              <ButtonContainer>{ button }</ButtonContainer>
            </Form>
          </Container>
        </ModalWithCloseButtonLayout>
      </ModalBackground>
    </ModalFade>
  );
});
