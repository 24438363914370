import styled from "styled-components";

import { InputText } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import { withModifiers } from "with-modifiers/dist/index";

export const BaseStyledInput = withModifiers({
  error: () => `
        border: 2px solid ${colors.watermelon};
        
        :focus {
            border-color: ${colors.watermelon};
        }
    `,
  big: () => `
        padding: 17px 18px;
    `
})(
  modifier => styled(InputText)`
    width: 100%;
    padding: 13px 18px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 2px solid ${colors.gray100};
    font-family: Graphik;
    transition: border-color 200ms;

    :focus {
      border-color: ${colors.gray300};
      outline: none;
    }

    ::placeholder {
      opacity: 0.6;
    }

    ${modifier};
  `
);
