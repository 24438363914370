import React from "react";
import { observer } from "mobx-react";
import { SignalIndicator } from "./icons/SignalIndicator";
import chatStore from "../../../stores/chatStore";
import styled from "styled-components";
import { getSignalStrength } from "../../../utils/getSignalStrength";

const SignalWrapper = styled.div`
    position: relative;
`;

const SignalContainer = styled.div`
    display: flex;
    position: absolute;
    right: 8px;
    top: 8px;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
`;

function SignalStrengthIndicator(props) {
  const videoStats = Object.values(chatStore.voxCallStats?.outbound || {}).find(values => values.find(value => value.kind === "video"))?.[0];
  const {bitrate, loss, jitter, qualityLimitationReason} = videoStats || {};
  const strength = videoStats ? getSignalStrength(jitter, loss, bitrate) : 4;

  return strength <= 3 ? (
    <SignalWrapper>
      <SignalContainer>
        <SignalIndicator strength={strength}/>
      </SignalContainer>
    </SignalWrapper>
  ) : null;
}

export default observer(SignalStrengthIndicator);