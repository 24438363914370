import React from "react";
import styled from "styled-components";
import { PreloaderBody } from "../prealoader/PreloaderBody";

const PlaceholderBase = styled.div`
  height: 10px;
  border-radius: 3px;
  background: #d4d4d4;
  opacity: 0.5;
`;

const TitlePlaceholder = styled(PlaceholderBase)`
  width: 182px;
  margin-bottom: 18px;
`;

const Delimiter = styled.div`
  border-bottom: 1px solid #979797;
  opacity: 0.3;
`;

const Analyzes = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AnalysisPlaceholderBody = styled.div`
  display: flex;
  margin-top: 36px;
  width: 50%;
`;

const AnalysisPlaceholderIcon = styled(PlaceholderBase)`
  width: 42px;
  height: 50px;
  margin-right: 9px;
`;

const AnalysisPlaceholderInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnalysisPlaceholderTitle = styled(PlaceholderBase)`
  width: ${props => props.width}px;
  margin-bottom: 4px;
`;

const AnalysisPlaceholderDescription = styled(PlaceholderBase)`
  width: 116px;
  margin-top: 10px;
`;

const AnalysisPlaceholder = () => {
  return (
    <AnalysisPlaceholderBody>
      <AnalysisPlaceholderIcon />
      <AnalysisPlaceholderInfo>
        <AnalysisPlaceholderTitle width={230} />
        <AnalysisPlaceholderTitle width={160} />
        <AnalysisPlaceholderDescription />
      </AnalysisPlaceholderInfo>
    </AnalysisPlaceholderBody>
  );
};

export const AnalyzesPreloader = () => {
  return (
    <PreloaderBody>
      <TitlePlaceholder />
      <Delimiter />
      <Analyzes>
        {new Array(12).fill().map((value, index) => (
          <AnalysisPlaceholder key={index} />
        ))}
      </Analyzes>
    </PreloaderBody>
  );
};
