import styled, { css } from "styled-components";

import { colors } from "../../styleguide/colors";

export const Container = styled.div`
  display: flex;
  width: fit-content;
  
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export const Calendars = styled.div`
  display: flex;
  padding-right: 8px;
  background: ${colors.white};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

export const Periods = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 152px;
  padding: 16px 0 16px 16px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: ${colors.gray900};
  font-size: 13px;
  color: ${colors.black};

  @media (max-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const Period = styled.div`
  padding: 8px;
  line-height: 16px;
  cursor: pointer;

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        color: ${colors.lightMagenta};
      }
    `}

  ${({ active }) =>
    active &&
    css`
      color: ${colors.magenta};
    `}
`;

export const ResetDate = styled(Period)`
  margin-top: auto;
`;
