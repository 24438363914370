// @flow
import { createEvent, combine, type Store, forward, merge, sample } from "effector";
import { allShortcuts, setShortcutsPointer, shortcutsPointer } from "./shortcutsShared";
import * as R from "ramda";

import type { TShortcuts, TShortcutsForField } from "../../domain/value-objects/TShortcuts";
import { isShortcutForField } from "../../domain/value-objects/TShortcuts";
import type { TField_id } from "../../domain/entities/Field";
import {
  openShortcutsCreate,
  openShortcutsForField,
  openShortcutsForLayout
} from "./shortcutsSections";

export type TFieldWithShortcutsMap = { [TField_id]: TShortcutsForField };

const fieldSectionIdPath = field_id => R.lensPath([field_id, "field", "section", "id"]);

const filterResult: (Array<TShortcuts>) => Array<TShortcutsForField> = R.filter(isShortcutForField);

const fieldShortcuts = allShortcuts.map<*>(filterResult);

export const fieldsWithShortcutsStore = fieldShortcuts.map<TFieldWithShortcutsMap>(
  R.indexBy(R.view(R.lensPath(["field", "id"])))
);

export const currentFieldStore = shortcutsPointer.map<?TField_id>(pointer =>
  pointer && pointer.type === "field" ? pointer.fieldId : null
);

export const currentFieldSectionStore: Store<?number> = combine(
  currentFieldStore,
  fieldsWithShortcutsStore,
  (field_id, shortcutsMap) => {
    return R.view(fieldSectionIdPath(field_id), shortcutsMap);
  }
);

export const openShortcuts = createEvent<TField_id>();
export const createShortcuts = createEvent<TField_id>();

const openShortcutsIsExisted = sample(
  fieldsWithShortcutsStore,
  openShortcuts,
  (shortcutsMap, fieldId) => R.has(fieldId, shortcutsMap)
);
forward({
  from: openShortcutsIsExisted.filter({ fn: payload => payload }).map(() => undefined),
  to: openShortcutsForField
});
forward({
  from: openShortcutsIsExisted.filter({ fn: payload => !payload }).map(() => undefined),
  to: openShortcutsForLayout
});
forward({
  from: createShortcuts.map(() => undefined),
  to: openShortcutsCreate
});
forward({
  from: merge([openShortcuts, createShortcuts]).map(fieldId => ({ type: "field", fieldId })),
  to: setShortcutsPointer
});
