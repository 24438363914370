// @flow
import React from "react";
import { Box, FormControlLabel } from "@mui/material";
import { CorporateWrapper } from "../../styled/CorporateWrapper.styled";
import { CorporateCard } from "../../components/CorporateCard";
import { CorporateSubTitle } from "../../styled/CorporateTypography.styled";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const CorporateVoteWidget = () => {
  return (
    <CorporateWrapper>
      <CorporateCard title="Голосование">
        <Container>
          <CorporateSubTitle>Как вам корпоративный портал?</CorporateSubTitle>
          <Box sx={{ display: "flex", flexDirection: "column", pt: "24px" }}>
            <FormControlLabel control={<Checkbox />} label="Отлично" />
            <FormControlLabel control={<Checkbox />} label="Хорошо" />
            <FormControlLabel control={<Checkbox />} label="Нормально" />
            <FormControlLabel control={<Checkbox />} label="Плохо" />
          </Box>
        </Container>
      </CorporateCard>
    </CorporateWrapper>
  );
};
