// @flow
import React, { useMemo } from "react";
import { observer } from "mobx-react";
import chatStore from "../../../stores/chatStore";
import authStore from "../../../stores/authStore";
import { DynamicForm } from "../../common/dynamic-form/DynamicForm";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const SettingChatsModal = observer(() => {
  const { user } = authStore;
  const handleOnClose = () => chatStore.closeSettingChatModal();
  const submitHandler = (settings) => chatStore.setChatSettings(settings);

  const fields = useMemo(
    () => [
      {
        type: "select",
        name: "status_in_chats",
        label: "Статус",
        xs: 12,
        options: [
          { value: "Больничный", label: "Больничный" },
          { value: "Отпуск", label: "Отпуск" }
        ]
      },
      // {
      //   type: "checkbox",
      //   name: "is_notify_about_messages",
      //   label: "Оповещать о сообщениях",
      //   xs: 12
      // },
      {
        type: "textarea",
        name: "message_to_the_patient",
        label: "Сообщение клиенту",
        xs: 12
      },
      {
        type: "date",
        name: "status_reset_date",
        label: "Дата сброса статуса",
        xs: 12
      }
    ],
    []
  );

  const defaultData = {
    status_in_chats: user.status_in_chats || undefined,
    // is_notify_about_messages: user.is_notify_about_messages || false,
    message_to_the_patient: user.message_to_the_patient || undefined,
    status_reset_date: user.status_reset_date || undefined
  }

  return (
    <Dialog
      open={chatStore.isShowSettingChatsModal}
      onClose={handleOnClose}
      fullWidth
      maxWidth="sm">
      <DialogTitle sx={{ m: 0, p: 2 }}>Настройки чатов</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleOnClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DynamicForm
          textButton="Сохранить"
          submitHandler={submitHandler}
          data={defaultData}
          fields={fields}
          hiddenButtonCancel={true}
        />
      </DialogContent>
    </Dialog>
  );
});
