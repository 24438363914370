import { makeAutoObservable } from "mobx";
import _ from "lodash";
import dayjs from "dayjs";
import apiv2 from "../apiv2";
import { getPatientLastNameWithInitials } from "../domain/services/patient";
import {
  READABLE_DATE_FORMAT,
  READABLE_TIME_FORMAT_MOMENT,
  SERVER_DATE_TIME_FORMAT,
  SERVER_TIME_FORMAT
} from "../utils/dates";
import { currentUser } from "./auth";
import doctorsStore from "./doctorsStore";
import generalScheduleStore from "./generalScheduleStore";
import voxInformationPatientStore from "./voxInformationPatientStore";

const getGroupValue = ({ doctor, sample, date, time }) => {
  const formattedDate = dayjs(date).format(READABLE_DATE_FORMAT);
  const formattedTime = dayjs(time, SERVER_TIME_FORMAT).format(READABLE_TIME_FORMAT_MOMENT);

  return `${doctor} | ${sample} | ${formattedDate}, ${formattedTime}`;
};

const transformData = data => {
  return data.map(item => {
    const doctor = getPatientLastNameWithInitials({
      first_name: item.appointment__doctor__first_name,
      middle_name: item.appointment__doctor__middle_name,
      last_name: item.appointment__doctor__last_name
    });
    const groupingValue = getGroupValue({
      doctor,
      sample: item.appointment__layout__name,
      date: item.appointment__date,
      time: item.appointment__time
    });

    for (const iteration in item) {
      if (
        [
          "appointment__doctor__first_name",
          "appointment__doctor__middle_name",
          "appointment__doctor__last_name",
          "appointment__layout__name",
          "appointment__time"
        ].includes(iteration)
      ) {
        delete item[iteration];
      }
    }
    return {
      ...item,
      groupingValue,
      doctor
    };
  });
};

class AppointmentDirectionsStore {
  additionalValuesParams = [
    "id",
    "appointment__doctor__first_name",
    "appointment__doctor__middle_name",
    "appointment__doctor__last_name",
    "appointment__layout__name",
    "appointment__date",
    "appointment__time",
    "service__kdf_code",
    "service__ministry_of_health_code",
    "user_completed",
    "user_completed__username"
  ];
  valuesParamsTable = [];
  patientId = "";
  isLoading = false;
  isInitialized = false;
  dataTable = [];
  patientData = null;

  constructor() {
    // ToDo Вернуться к зубам --> teeth
    makeAutoObservable(this);
  }

  async setPatientId({medical_file_id, medical_file_external_id}) {
    if (medical_file_id) {
      const medicalFile = await apiv2.medicalFiles.getById(medical_file_id);
      this.patientId = medicalFile.patient.id;
    } else if (medical_file_external_id) {
      const response = await apiv2.medicalFiles.getListMedicalFiles({
        filter_params: {
          external_id: medical_file_external_id
        },
        values_params: ["id"]
      });
      const medicalFile = await apiv2.medicalFiles.getById(response.data[0].id);
      this.patientId = medicalFile.patient.id;
    }
  }

  setValuesParamsTable(valuesParamsTable, isNeedFetch = true) {
    this.valuesParamsTable = _.concat(valuesParamsTable, this.additionalValuesParams);

    if (isNeedFetch) {
      this.getList();
    }
  }

  async getPatientData() {
    try {
      const medicalFiles = await apiv2.medicalFiles.get({ patientId: this.patientId });
      this.patientData = medicalFiles;
      this.getList();
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getList() {
    try {
      const filter_params = {};
      if (voxInformationPatientStore.isInitialized) {
        filter_params.appointment__medical_file__patient__phone_number = voxInformationPatientStore.phone_number;
      } else {
        filter_params.appointment__medical_file__patient = this.patientId;
      }

      this.isLoading = true;
      const { data } = await apiv2.appointments.getAppointmentList({
        values_params: this.valuesParamsTable,
        meta: { per_page: 1000000 },
        order_by_params: ["-appointment__date", "-appointment__time"],
        filter_params
      });
      this.dataTable = transformData(data);
      this.isLoading = false;
      this.isInitialized = true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  handleCompleteDirection(data, setRowSelection) {
    const { doctor } = currentUser.getState();
    const date = dayjs().format(SERVER_DATE_TIME_FORMAT);

    const changedData = data.map(item => {
      return {
        ...item.original,
        user_completed: doctor.id,
        date_completed: date
      };
    });

    try {
      apiv2.appointments.completeAppointment(changedData);
      this.isLoading = true;
      setTimeout(() => {
        this.getList();
      }, 200);
      setRowSelection({});
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async handleMakeAnAppointment({ data, history }) {
    const changedData = data
      .filter(item => !!item.original.service__ministry_of_health_code)
      .map(item => item.original.service__ministry_of_health_code);

    try {
      const neededOrganizationsId = this.patientData.map(file => file.organization.id);
      const params = {
        filter_params: {
          doctor__specialities__isnull: false,
          doctor__organizations__isnull: false,
          organization__in: neededOrganizationsId
        },
        values_params: [
          "doctor__id",
          "doctor__first_name",
          "doctor__last_name",
          "doctor__middle_name",
          "doctor__organizations__id",
          "doctor__specialities__name"
        ],
        annotate_mtm_params: {
          doctor__specialities: "doctor__specialities__name",
          doctor__organizations: "doctor__organizations__id"
        },
        meta: {
          per_page: 999999,
          current_page: 1
        }
      };
      if (changedData.length) {
        params.filter_params = {
          ...params.filter_params,
          favorites_services__ministry_of_health_code__in: changedData
        };
      }
      const neededDoctors = await doctorsStore.fetchAllDoctorsOrganizationList(params);

      generalScheduleStore.setGlobalFiltersTable(
        {
          doctor_organization__organization__in: neededOrganizationsId,
          doctor_organization__doctor__in: neededDoctors.data.map(doctor => doctor.doctor__id)
        },
        false
      );

      history.push("/general-schedule-new");
    } catch (e) {
      console.error(e);
    }
  }
}

export default new AppointmentDirectionsStore();
