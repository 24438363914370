import React from "react";

import { formatDateToReadableDateFormat } from "../../../utils/dates";

import * as S from "./MyPatientsTableViewersRemind.styled";

export const MyPatientsTableViewersRemind = ({ cell: { value } }) => (
  <S.Container expired={value && new Date() > value}>
    {value ? formatDateToReadableDateFormat(value) : null}
  </S.Container>
);
