// @flow

import type { TDiagnosis_id } from "./Diagnosis";

export type Checklist = {
  guid: string,
  name: string,
  comment?: string,
  dateRange?: { dates: [string, string], value: string } | [string, string] | null,
  period?: number | null,
  items?: Array<Checklist>,
  is_checked?: boolean,
  is_heading?: boolean,
  count?: number,
  services?: Object,
  diagnoses?: Array<TDiagnosis_id>
};

export opaque type TChecklistGuid: string = string;

export const getChecklistGuid = (checklist: Checklist): TChecklistGuid => {
  return checklist.guid;
};
