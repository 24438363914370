// @flow
import React, { useEffect, useMemo } from "react";
import surveyFeedbackClinicResultStore from "../../stores/surveyFeedbackClinicResultStore";
import { MaterialTable } from "../common/MaterialTable";
import { useForm } from "react-hook-form";
import { FormInputDate } from "../common/dynamic-form/fields/FormInputDate";
import { Grid, Paper } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import { SERVER_DATE_TIME_FORMAT } from "../../utils/dates";
import styled from "styled-components";
import { observer } from "mobx-react";
import organizationsStore from "../../stores/organizationsStore";
import {
  FEATURE, useApplicationFeatureValue
} from "../../utils/applicationFeatures";
import { trimLongTextValueField } from "../../utils/tableUtils";
import useReactRouter from "use-react-router";
import surveyResultStore from "../../stores/surveyResultStore";
import { TrimmedText } from "../../corporate-portal/styled/CorporateTypography.styled";

const Container = styled.div`
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    position: relative;
`;

export const SurveyFeedbackClinicPage = observer(() => {
  const {
    match: { params },
    history
  } = useReactRouter();

  const SURVEY_FEEDBACK_CLINIC_RESULT = useApplicationFeatureValue(FEATURE.SURVEY_FEEDBACK_CLINIC_RESULT);
  const date_end = moment().set({ hour: 23, minute: 59, second: 59 }).format(SERVER_DATE_TIME_FORMAT);
  const date_start = moment().set({ hour: 0, minute: 0, second: 0 }).set('month', moment().get('month') - 1).format(SERVER_DATE_TIME_FORMAT);

  const defaultValuesDates = {
    date_start,
    date_end
  }

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
    })()
  }, []);

  const methods = useForm({
    defaultValues: defaultValuesDates,
  });
  const { handleSubmit, control, setValue } = methods;

  const afterInitializeParams = (callback) => {
    surveyFeedbackClinicResultStore.setAnnotateMtmParams({
      "values": ["values__value"],
    })
    surveyFeedbackClinicResultStore.setAdditionalFilterValues({survey_id: SURVEY_FEEDBACK_CLINIC_RESULT,
      is_finished: true});
    if (!surveyFeedbackClinicResultStore.globalFiltersTable.created_at__lte){
      surveyFeedbackClinicResultStore.setGlobalFiltersTable({
        created_at__gte: date_start,
        created_at__lte: date_end,
      }, false)
    } else {
      setValue('date_start', surveyFeedbackClinicResultStore.globalFiltersTable.created_at__gte)
      setValue('date_end', surveyFeedbackClinicResultStore.globalFiltersTable.created_at__lte)
    }
    if (!surveyFeedbackClinicResultStore.sortingTable.includes('-created_at')) {
      surveyFeedbackClinicResultStore.setSortingTable([...surveyFeedbackClinicResultStore.sortingTable, '-created_at'])
    }
    callback();
  }

  const onSubmit = (data) => {
    const filters = {
      created_at__gte: data.date_start,
      created_at__lte: data.date_end,
    };

    surveyFeedbackClinicResultStore.setGlobalFiltersTable(filters);
  }

  const additionalFilters = (
    <Paper style={{
      padding: '20px'
    }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid alignItems={"center"} container spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker="dateTime" label="Дата от"  name="date_start" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <FormInputDate typePicker="dateTime" label="до " name="date_end" control={control} />
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Button type={"submit"} size={"large"} variant="contained" color={"success"}>
              Применить
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'values__value', //normal accessorKey
        header: 'Значение полей',
        filterFn: 'icontains',
        size: 350,
        Cell: ({ row }) =>
          trimLongTextValueField(row.original.values__value?.join(', '))
      },
      {
        accessorKey: 'status',
        header: 'Статус',
        filterFn: 'in',
        filterSelectOptions: surveyFeedbackClinicResultStore.statusResult,
        filterVariant: 'multi-select',
        Cell: ({ cell }) =>
          surveyFeedbackClinicResultStore.statusResult.find((options) => options.value === cell.getValue())?.label,
      },
    ],
    [],
  );

  const editClick = (row) => {
    surveyFeedbackClinicResultStore.changeOldParams({});
    history.push(`/feedback-clinic-statistics/${row.id}`);
  }

  return (
    <Container>
      {!!SURVEY_FEEDBACK_CLINIC_RESULT && (
        <MaterialTable editClick={editClick} localStorageKeyName={'review-statistics-survey-feedback-clinic'} isShowTableData={true} isDisableSavingPresets={true}  disableHeightResizing={true} initialState={{
          showColumnFilters: true,
        }} additionalFilters={additionalFilters} afterInitializeParams={afterInitializeParams} actions={['edit']}
                       store={surveyFeedbackClinicResultStore} columns={columns} />
      )}
    </Container>
  );
});
