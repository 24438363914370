import React from 'react';

const FiltersContext = React.createContext({});

const getLatestFilter = (filters) => {
  const cachedItem = JSON.parse(localStorage.getItem("last_embryo_filter"));
  return cachedItem !== null && filters.length > parseInt(cachedItem, 10) ? cachedItem : "0";
};

export const EmbryoFiltersContextProvider = ({ filters, children }) => {
  const [activeFilterIdx, setActiveFilterIdx] = React.useState(getLatestFilter(filters));

  const activeFilter = React.useMemo(() => filters[parseInt(activeFilterIdx, 10)], [
    activeFilterIdx,
    filters
  ]);

  const setActiveFilter = React.useCallback( (data) => {
    setActiveFilterIdx(data);
    localStorage.setItem("last_embryo_filter", JSON.stringify(data));
  }, []);

  return (
    <FiltersContext.Provider value={{
      filters,
      activeFilterIdx,
      activeFilter,
      setActiveFilter
    }}>
      { children }
    </FiltersContext.Provider>
  );
};

export const useEmbryoFilters = () => {
  return React.useContext(FiltersContext);
};
