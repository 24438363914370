// @flow
import { createEvent, restore, forward } from "effector";
import type { Shortcut, ChecklistShortcut } from "../../domain/entities/Shortcut";
import { openShortcutsEdit } from "./shortcutsSections";

export const setEditingShortcut = createEvent<?Shortcut | ?ChecklistShortcut>("setEditingShortcut");
export const editingShortcutStore = restore<?Shortcut | ?ChecklistShortcut>(
  setEditingShortcut,
  null
);

export const editShortcut = createEvent<Shortcut | ChecklistShortcut>();
forward({
  from: editShortcut,
  to: setEditingShortcut
});
forward({
  from: editShortcut.map(() => undefined),
  to: openShortcutsEdit
});
