import { ReportsCalculationWrapper } from "../Reports.styled";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { BlockLoader } from "../../loader/Loader";

export const ReportCalculations = ({ isLoading = false, label, calculations = [] }) => {
  return (
    <ReportsCalculationWrapper>
      {isLoading ? (
        <BlockLoader />
      ) : (
        <Fragment>
          <Typography variant="h6">Подсчеты для отчета "{label}"</Typography>
          <Divider />
          {calculations.map(({ key, name, description = "", value = 0, symbol }) => (
            <Box key={key} sx={{ maxWidth: "max-content", display: "flex", cursor: "pointer" }}>
              <Tooltip title={description} placement="right">
                <Typography>
                  {name} - {value}
                  {!!symbol && symbol}
                </Typography>
              </Tooltip>
            </Box>
          ))}
        </Fragment>
      )}
    </ReportsCalculationWrapper>
  );
};
