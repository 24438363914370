import apiv2 from '../apiv2';
import DynamicTableStore from "./dynamicTableStore";
import { action, makeAutoObservable, makeObservable, observable } from "mobx";

class FeedbackStore {
  surveyResultData = null;
  feedbackResults = [];

  constructor() {
    makeAutoObservable(this);
  }


  async getResultById(id) {
    try {
      const response = await apiv2.survey.getSurveyResultById(id);
      this.surveyResultData = response;
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async getFeedbackResults() {
    try {
      this.feedbackResults = await apiv2.survey.getFeedbackResults();
    } catch(e) {
      console.error(e);
      return false;
    }
  }
}

export default new FeedbackStore();
