import * as Voximplant from "voximplant-websdk";
import apiv2 from "../../../apiv2";
import chatStore from "../../../stores/chatStore";
import {captureEventToSentry, captureExceptionToSentry} from '../../../utils/captureToSentry';

let voximplantInstance = null;

const connectVoximplantCloud = async () => {
  if (!voximplantInstance) {
    return false;
  }
  const voximplant = await voximplantInstance;

  try {
    const connectionResult = await voximplant.connect();
    if (connectionResult) {
      chatStore.setVoximplantIsReconnecting(false);
      chatStore.closeVoximplantErrorModal();
      return true;
    }
  } catch(e) {
    console.error(e);
    captureExceptionToSentry(e);
    chatStore.setVoximplantIsReconnecting(false);
    chatStore.showVoximlpantErrorModal();
    return false;
  }

  chatStore.setVoximplantIsReconnecting(false);
  chatStore.showVoximlpantErrorModal();
  return false;
};

const initVoximplant = async () => {
  voximplantInstance = new Promise(async (resolve, reject) => {
    try {
      chatStore.closeVoximplantErrorModal();
      chatStore.setVoximplantIsReconnecting(false);
      const urlResponse = await apiv2.vox.getUrl(chatStore.currentCallId);
      const voximplant = Voximplant.getInstance();
      // If the Voximplant instance is not initialized, initialize it
      if (!voximplant.alreadyInitialized) {
        await voximplant.init({ showDebugInfo: process.env.NODE_ENV === "production" }); //{ showDebugInfo: true } // Add to collect debug information
      }
      // Connect to the cloud and request a key
      voximplant.connect().then(() => voximplant.requestOneTimeLoginKey(urlResponse.url));

      let reconnectingTimeout;
      // Listen to the server response
      voximplant.on(Voximplant.Events.AuthResult, async e => {
        console.log(`[Voximplant] AuthResult: ${e.result}`);
        console.log(`[Voximplant] Auth code: ${e.code}`);
        if (e.result) {
          resolve(voximplant);
        } else if (e.code === 302) {
          console.log(`[Voximplant] Key: ${e.key}`);
          const tokenResponse = await apiv2.vox.getToken(e.key, chatStore.currentCallId);
          await voximplant.loginWithOneTimeKey(urlResponse.url, tokenResponse.token);
        }
      });
      voximplant.on(Voximplant.Events.Reconnecting , async e => {
        console.log("[Voximplant] Trying to reconnect...");
        captureEventToSentry(e);
        reconnectingTimeout = setTimeout(() => { // Поскольку при 10 попыткахх реконнекта, сюда мы попадаем лишь единожды, пришлось сделать через таймаут
          chatStore.setVoximplantIsReconnecting(true);
          chatStore.showVoximlpantErrorModal();
        }, 5000);
      });
      voximplant.on(Voximplant.Events.Reconnected , async e => {
        console.log("[Voximplant] Reconnected.");
        captureEventToSentry(e);
        if (reconnectingTimeout) { // Если переподключились, значит и модалку показывать не надо
          clearTimeout(reconnectingTimeout);
        }
        chatStore.setVoximplantIsReconnecting(false);
        chatStore.closeVoximplantErrorModal();
      });
      voximplant.on(Voximplant.Events.ConnectionClosed , async e => { // Сначала Voximplant пытается 10 раз переподключиться, если не получилось, попадаем сюда
        console.log("[Voximplant] Connection closed.");
        captureEventToSentry(e);
        if (reconnectingTimeout) { // Если отключились раньше чем показали подолку о переподключении
          clearTimeout(reconnectingTimeout);
        }
        connectVoximplantCloud();
      });
    } catch (e) {
      reject(e);
      captureExceptionToSentry(e);
      chatStore.setVoximplantIsReconnecting(false);
      chatStore.showVoximlpantErrorModal();
    }
  });

  return voximplantInstance;
};

const getInstance = async () => {
  if (voximplantInstance) {
    const v = await voximplantInstance;
    console.log('v:', v);
    if (v instanceof Error) {
      captureExceptionToSentry(v);
      return await initVoximplant();
    }

    console.log('[Voximplant]:', v);
    return v;
  }
  console.log('not have instance:', voximplantInstance);

  return await initVoximplant();
};

export default {
  connectVoximplantCloud,
  getInstance
};
