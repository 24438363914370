// @flow
import * as React from "react";
import styled from "styled-components";
import normIcon from "./icons/norm.svg";
import normIconGrey from "./icons/norm-grey.svg";
import { DefaultButton } from "../buttons/DefaultButton";
import { ButtonWithTooltip } from "../buttons/ButtonWithTooltip";

const Icon = styled.div`
  width: 24px;
  height: 24px;

  background: url(${normIcon});
`;

const IconContainer = styled.div`
  margin-right: 6px;
`;

export const NormButton = (props: { onClick?: () => mixed }) => (
  <DefaultButton
    {...props}
    overrides={{
      Icon: {
        component: Icon
      },
      IconContainer: {
        component: IconContainer
      }
    }}>
    Норма
  </DefaultButton>
);

export const NormButtonAsIcon = (props: { onClick?: () => mixed }) => (
  <ButtonWithTooltip
    icon={normIconGrey}
    iconHover={normIcon}
    tooltip="Норма"
    onClick={props.onClick}
  />
);
