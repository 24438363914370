// @flow
import styled, { css } from "styled-components";
import { withModifiers } from "with-modifiers";
import { colors } from "./colors";

const color = () => `
    color: ${colors.magenta}
`;

const light = () => `
    color: ${colors.white};
`;

const gray = () => `
  color: ${colors.gray700};
`;

const center = () => `
    text-align: center;
`;

const left = () => `
    text-align: left;
`;

const uppercase = () => `
    text-transform: uppercase;
`;

const capitalize = () => `
    text-transform: capitalize;
`;

const nowrap = () => `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TEXT_MODIFIER_CONFIG = {
  color,
  light,
  gray,
  center,
  left,
  uppercase,
  capitalize,
  nowrap
};

const BaseText = css`
  color: ${colors.black};
`;

export const HeadingDisplay = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.p`
    font-size: 56px;
    line-height: 1.25;
    font-weight: 500;

    ${BaseText};
    ${modifier};
  `
);

export const HeadingH1 = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.h1`
    font-size: 53px;
    line-height: 1.32;

    ${BaseText};
    ${modifier};

    @media (max-width: 768px) {
      font-size: 46px;
    }
  `
);

export const HeadingH2 = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.h2`
    font-size: 38px;

    ${BaseText};
    ${modifier};

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 480px) {
      font-size: 25px;
    }
  `
);

export const HeadingH3 = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.h3`
    font-size: 30px;

    ${BaseText};
    ${modifier};

    @media (max-width: 768px) {
      font-size: 24px;
    }
    @media (max-width: 480px) {
      font-size: 20px;
    }
  `
);

export const HeadingH4 = withModifiers({
  left: () => `
        font-size: 22px;
        text-align: left;
    `,
  capitalize
})(
  modifier => styled.h4`
    font-size: 22px;

    ${BaseText};
    ${modifier};

    @media (max-width: 768px) {
      font-size: 18px;
    }
  `
);

export const HeadingH5 = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.h5`
    font-size: 20px;
    line-height: 1.5;

    ${BaseText};
    ${modifier};
  `
);

export const HeadingH6 = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.h6`
    font-size: 17px;
    line-height: 1.35;

    ${BaseText};
    ${modifier};
  `
);

export const InputLabel = withModifiers({
  small: () => `
        font-size: 12px;
    `,
  normal: () => `
        font-size: 12px;

        letter-spacing: 0.77;
    `
})(
  modifier => styled.span`
    color: ${colors.darkBlack};
    ${BaseText};
    ${modifier};
  `
);

export const InputText = withModifiers({
  small: () => `
    font-size: 14px;
  `,

  error: () => `
        color: ${colors.watermelon};
    `,
  success: () => `
        color: #45c53a;
    `,
  placeholder: () => `
        opacity: 0.6;
        line-height: 1.35;
        color: ${colors.darkBlack};
    `
})(
  modifier => styled.span`
    font-size: 17px;

    ${BaseText};
    ${modifier};
  `
);

export const ButtonCaps = withModifiers({
  center: () => `
        font-size: 13px;

        letter-spacing: 1.1;
        text-align: center;
    `,
  left: () => `
        font-size: 12px;

        letter-spacing: 0.2;
    `,
  uppercase,
  color
})(
  modifier => styled.span`
    font-weight: 500;

    ${BaseText};
    ${modifier};
    font-size: ${props => !!props.fontSize && props.fontSize};
    @media screen and (max-width: 1200px) {
      font-size: ${props => !!props.fontSize && "12px"};
      text-align: center;
    }
  `
);

export const ButtonCenter = withModifiers({
  red: () => `
        color: #e3051c;
    `,
  primary: () => `
        font-size: 15px;
    
        color: #0067a2;
    `
})(
  modifier => styled.span`
    font-size: 14px;
    font-weight: 500;

    letter-spacing: 0.21;
    text-align: center;

    ${BaseText};
    ${modifier};
  `
);

export const PLarge = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.p`
    font-size: 22px;
    line-height: 1.5;

    ${BaseText};
    ${modifier};
  `
);

export const PNormal = withModifiers({
  ...TEXT_MODIFIER_CONFIG,
  light: () => `
        font-size: 17px;
        line-height: 1.59;
        color: ${colors.white};
    `,
  left: () => `
        font-size: 15px;
        line-height: 1.53;
    `
})(
  modifier => styled.p`
    font-size: 16px;
    line-height: 1.69;

    ${BaseText};
    ${modifier};
  `
);

export const PSmall = withModifiers({
  ...TEXT_MODIFIER_CONFIG,
  "1": () => `
    font-size: 14px;
    line-height: 1.64;
    `,
  "2": () => `
    font-size: 18px;
    line-height: 1.64;
    `,
})(
  modifier => styled.p`
    font-size: 15px;
    line-height: 1.53;

    ${BaseText};
    ${modifier};
  `
);

export const PXSmall = withModifiers({
  ...TEXT_MODIFIER_CONFIG,
  center: () => `
        font-size: 11px;
        text-align: center;
    `,
  left: () => `
        font-size: 12px;
    `,
  averageSize: () => `
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0.3px;
    `
})(
  modifier => styled.p`
    color: #595959;

    ${BaseText};
    ${modifier};
  `
);

export const PSidebar = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.p`
    font-size: 15px;
    line-height: 1.27;

    ${BaseText};
    ${modifier};
  `
);

export const PList = withModifiers({
  ...TEXT_MODIFIER_CONFIG,
  disabled: () => `
     line-height: 1.77;
     color: #595959;
`
})(
  modifier => styled.p`
    font-size: 13px;
    line-height: 1.54;

    ${BaseText};
    ${modifier};
  `
);

export const PPrintNormal = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.p`
    font-size: 17px;
    line-height: 1.35;
    display: inline;

    ${BaseText};
    ${modifier};
  `
);

export const PSignNormal = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.p`
    font-size: 14px;

    ${BaseText};
    ${modifier};
  `
);

export const CaptionLarge = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.span`
    font-size: 18px;
    font-weight: 500;

    line-height: 1.56;

    ${BaseText};
    ${modifier};
  `
);

export const CaptionNormal = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.span`
    font-size: 16px;
    font-weight: 500;

    line-height: 1.44;

    ${BaseText};
    ${modifier};
  `
);

export const CaptionSmall = withModifiers(TEXT_MODIFIER_CONFIG)(
  modifier => styled.span`
    font-size: 14px;
    font-weight: 500;

    line-height: 1.64;

    ${BaseText};
    ${modifier};
  `
);
