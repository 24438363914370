// @flow
import React, { useMemo } from "react";
import { AsideAppointmentFolder } from "./AsideAppointmentFolder";
import { AsideAppointmentPatient } from "./AsideAppointmentPatient";
import type { GenericAppointment } from "../../domain/entities/Appointment";

export const AsideGenericAppointment = ({ appointment }: { appointment: GenericAppointment }) => {
  return useMemo(() => {
    switch (appointment.type) {
      case "appointment":
      case "fromFolder": {
        return <AsideAppointmentPatient appointment={appointment} />;
      }
      case "folder": {
        return <AsideAppointmentFolder {...appointment} />;
      }
      default:
        return null;
    }
  }, [appointment]);
};
