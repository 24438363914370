import React from "react"
import styled, { css } from "styled-components";
import { observer } from 'mobx-react';

import { CircleButton } from '../../buttons/CircleButton';
import { scrollbars } from '../style-elements';
import chatStore from '../../../stores/chatStore';
import { useClickOutside } from '../../../utils/useClickOutside';
import { CrossIcon } from '../icons/Cross';
import plusIcon from "../icons/plus.svg";
import arrowUpIcon from "../icons/arrow_up.svg";

const Container = styled.div`
  position: relative;
`;

const AppendFileButton = styled(CircleButton)`
  position: absolute;
  bottom: 8px;
  left: 24px;
  @media (max-width: 576px) {
    left: 16px;
  }
`;

const SendButton = styled(CircleButton)`
  background: #C6007F;

  position: absolute;
  bottom: 8px;
  right: 24px;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid rgba(220, 211, 210, 0.4);
`;

const InputTextarea = styled.textarea`
  box-sizing: border-box;
  resize: none;
  width: 100%;
  padding: 16px 24px 16px 80px;
  ${props => props.hasValue ? css`padding-right: 80px;` : ''};
  border: none;
  border-radius: 0;
  font-family: "Graphik LCG";
  font-size: 15px;
  line-height: 23px;
  height: 56px;
  max-height: 180px;

  &:focus {
    border-color: rgba(220, 211, 210, 0.4);
    outline: none;
  }
  @media (max-width: 576px) {
    padding: 16px 70px 16px 70px;
  }

  ${scrollbars};
`;

const FileUploadMenu = styled.div`
  position: absolute;
  bottom: 60px;
  left: 24px;
  
  display: ${props => props.isVisible ? 'flex' : 'none'};
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 31px 0px rgba(0, 0, 0, 0.09);
  flex-direction: column;
  min-width: 144px;
  overflow: hidden;
  
  label {
    padding: 10px 20px;
    font-family: "Graphik LCG";
    font-size: 13px;
    line-height: 23px;
    color: #262626;
    cursor: pointer;
    
    &:hover {
      background: #F3F3F3;
      color: #DE008E;
    }
    
    input {
      display: none;
    }
  }
`;

const EditingContainer = styled.div`
  padding: 12px 24px;
  border-top: 1px solid #F3F3F3;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Graphik LCG";
  font-size: 15px;
  line-height: 23px;
  color: #4a4a4a;
  
  span {
    flex: 1;
  }
  
  button {
    display: flex;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    --svg-fill: #262626;
    
    &:hover {
      --svg-fill: #C6007F;
    }
  }
`;

export const MessageInput = observer(({
  onSubmit
}) => {
  const [fileUploadMenuVisible, setFileUploadMenuVisible] = React.useState(false);
  const [showPatientNotInChat, setShowPatientNotInChat] = React.useState(!chatStore.currentChat.opponent.patient_id || false);
  const textareaRef = React.useRef(null);
  const fileUploadMenuRef = React.useRef(null);

  useClickOutside(fileUploadMenuRef, () => {
    setFileUploadMenuVisible(false);
  })

  React.useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = '';
      textarea.style.height = textarea.scrollHeight + 'px';
      textarea.scrollTop = textarea.scrollHeight;
    }
  }, [chatStore.currentMessageText]);

  React.useEffect(() => {
    if (chatStore.currentChat) {
      setShowPatientNotInChat(!chatStore.currentChat.opponent.patient_id);
    }
  }, [chatStore.currentChat]);

  const sendMessageCallback = React.useCallback(async () => {
    if (!chatStore.currentMessageText) {
      if (chatStore.editingMessage) {
        await chatStore.deleteMessage(chatStore.editingMessage.id);
      }
      return;
    }
    await chatStore.sendMessage(chatStore.currentMessageText);
    await onSubmit?.();
  }, [chatStore.currentMessageText]);

  const handleChangeCallback = React.useCallback((e) =>  {
    chatStore.setMessageText(e.target.value)
  }, []);

  const handleKeyDownCallback = React.useCallback((e) => {
    if (e.altKey) {
      e.preventDefault();
      return;
    }
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      return;
    }
    if (e.key === 'Enter' && e.ctrlKey) {
      chatStore.setMessageText(e.target.value + '\n');
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessageCallback();
      return;
    }
  }, []);

  const handleImagesChange = React.useCallback(async (e) => {
    if (e.target.files.length > 0) {
      chatStore.sendImages([...e.target.files]);
      setFileUploadMenuVisible(false);
      e.target.value = "";
    }
  }, []);

  const handleFilesChange = React.useCallback(async (e) => {
    if (e.target.files.length > 0) {
      chatStore.sendFiles([...e.target.files]);
      setFileUploadMenuVisible(false);
      e.target.value = "";
    }
  }, []);

  const handlePatientNotInChatClose = React.useCallback(async () => {
    setShowPatientNotInChat(false);
  }, []);

  const onInputFocus = React.useCallback((e) => {
    chatStore.setInitialScreenHeight(window.innerHeight);

    setTimeout(() => {
      if (chatStore.isMobileDevice) {
        if (chatStore.initialScreenHeight > window.innerHeight || window.ReactNativeWebView) {
          return; // View сам поменял высоту, значит клавиатуру поднимать не надо
        }
        // Клавиатура вылезла, а высота не поменялась
        chatStore.setScreenKeyboardShowed(true);
      }
    }, 300);
  }, []);

  const onInputBlur = React.useCallback((e) => {
    setTimeout(() => {
      if (chatStore.isMobileDevice && chatStore.screenKeyboardShowed) {
        chatStore.setScreenKeyboardShowed(false);
      }
    }, 100);
  }, []);

  return (
    <>
      {showPatientNotInChat && (
        <EditingContainer>
          <span>Пациент увидит ваши сообщения, когда зарегистрируется в приложении.</span>
          <button
            onClick={handlePatientNotInChatClose}>
            <CrossIcon/>
          </button>
        </EditingContainer>
      )}
      {chatStore.editingMessage && (
        <EditingContainer>
          <span>Редактирование сообщения</span>
          <button
            onClick={() => {
              chatStore.setEditingMessage(0);
            }}>
            <CrossIcon/>
          </button>
        </EditingContainer>
      )}
      <Container>
        <FileUploadMenu ref={fileUploadMenuRef} isVisible={fileUploadMenuVisible}>
          <label>
            <input type="file" accept="*" multiple="multiple" onChange={handleFilesChange}/>
            Файл
          </label>
          <label>
            <input type="file" accept=".jpg,.jpeg,.png,.webm,image/*" multiple="multiple" onChange={handleImagesChange}/>
            Фото
          </label>
        </FileUploadMenu>
        <AppendFileButton onClick={() => {
          setFileUploadMenuVisible(true);
        }}>
          <img src={plusIcon}/>
        </AppendFileButton>
        <InputContainer>
          <InputTextarea
            ref={textareaRef}
            hasValue={!!chatStore.currentMessageText.length}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            onKeyDown={handleKeyDownCallback}
            onChange={handleChangeCallback}
            placeholder="Написать сообщение"
            value={chatStore.currentMessageText}
          />
        </InputContainer>
        {
          !!chatStore.currentMessageText.length && (
            <SendButton onClick={sendMessageCallback}>
              <img src={arrowUpIcon}/>
            </SendButton>
          )
        }
      </Container>
    </>
  );
});
