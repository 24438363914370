import ApiService from "../../../apiv2/apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class CorporateNews {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  getAllCategories() {
    return this.apiService.get(`corporate-portal/news/categories`);
  }

  getListNews({ categoryId, searchValue }) {
    return this.apiService.get(`corporate-portal/news/articles`, {
      category_id: categoryId,
      search: searchValue
    });
  }

  getNewsByNewsId(newsId) {
    return this.apiService.get(`corporate-portal/news/articles/${newsId}`);
  }

  getCommentsByNewsId(newsId) {
    return this.apiService.get(`corporate-portal/news/articles/${newsId}/commentaries`);
  }

  /** 400 при создании (если есть лайк от данного пользователя) / 201 если успех */
  setLikeNews(id) {
    return this.apiService.post(`corporate-portal/news/articles/${id}/likes`);
  }

  /** 400 при создании (если есть дизлайк от данного пользователя) / 204 если успех */
  setDislikeNews(id) {
    return this.apiService.delete(`corporate-portal/news/articles/${id}/likes`);
  }

  setReadNews(id) {
    return this.apiService.post(`corporate-portal/news/articles/${id}/views`);
  }

  // GET получить информацию по лайкам пользователей ПО СТАТЬЕ
  getLikeInfo(id) {
    return this.apiService.get(`corporate-portal/news/articles/${id}/likes`);
  }

  postComment(newsId, value) {
    return this.apiService.post(
      `corporate-portal/news/articles/${newsId}/commentaries`,
      {},
      {
        text: value
      }
    );
  }
}

export default new CorporateNews();
