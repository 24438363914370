//@flow
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {observer} from 'mobx-react';
import Dialog from '@mui/material/Dialog';

import {HeadingH4, PXSmall} from "../styleguide/typography";
import {getBase64} from '../../utils/getBase64';

import warningImage from "../../assets/image/warning.png";
import loaderIcon from '../schedule-loader-modal/icons/loader.svg';
import connectionStability from '../../stores/connectionStability';
import { BlockLoader } from "../loader/Loader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 653px;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 40px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`;

const HeadingContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
`;

const WarningImage = styled.img`
  max-height: 120px;
  margin-bottom: 32px;
`;

const getImageBase64 = async (imageURL) => {
  return getBase64(await fetch(imageURL).then(response => response.blob()));
};

export const ConnectionErrorModal = observer((props: {
  isShown: boolean,
}) => {
  const { isShown } = props;
  const [ loadedImages, setLoadedImages ] = useState({
    warningImage: warningImage,
    loaderIcon: loaderIcon,
  });

  useEffect(() => {
    Object.entries(loadedImages).forEach(async ([key, url]) => { // Если обрубится соединение, мы не сможем получить изображения по URL
      const imageBase64 = await getImageBase64(url);
      setLoadedImages(state => ({ ...state, [key]: imageBase64 }));
    });
  }, []);

  return (
    <>
      <Dialog open={isShown}>
        <Container>
          <WarningImage src={loadedImages.warningImage} alt="Warning"/>
          <HeadingContainer>
            <HeadingH4 modifiers={['center']}>
              Возникли проблемы с подключением
            </HeadingH4>
          </HeadingContainer>
          {
            !connectionStability.isAsyaAvailable && connectionStability.isOuterServiceAvailable && (
              <TextContainer>
                <PXSmall modifiers={['gray']}>
                  Ася недоступна, нет сетевого доступа к серверу Аси.
                </PXSmall>
                <PXSmall modifiers={['gray']}>
                  Не перезагружайте страницу, как только сетевой доступ восстановится вы сможете продолжить работу
                </PXSmall>
                <BlockLoader />
              </TextContainer>
            )
          }
          {
            !connectionStability.isAsyaAvailable && !connectionStability.isOuterServiceAvailable && (
              <TextContainer>
                <PXSmall modifiers={['gray']}>
                  Ася недоступна, проверьте соединение с интернетом.
                </PXSmall>
                <PXSmall modifiers={['gray']}>
                  Не перезагружайте страницу, как только интернет-соединение восстановится вы сможете продолжить работу
                </PXSmall>
                <BlockLoader />
              </TextContainer>
            )
          }
        </Container>
      </Dialog>
    </>
  );
});
