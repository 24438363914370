// @flow
import * as React from "react";
import styled from "styled-components";

import { Fieldset, Container, Legend } from "./Fieldset";

const ContainerVertical = styled(Container)`
  padding-left: 0;
  padding-top: 30px;
  margin-bottom: 32px;
  max-width: 100%;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    padding-top: 20px;
    margin-bottom: 22px;
  }
`;

const LegendVertical = styled(Legend)`
  top: 0;
  max-width: initial;
`;

export const FieldsetVertical = (props: { legend: string, children: React.Node, tooltipLegendText?: string, }) => (
  <Fieldset
    {...props}
    overrides={{
      Container: {
        component: ContainerVertical
      },
      Legend: {
        component: LegendVertical,
        props: {
          cursorPointer: !!props.tooltipLegendText
        }
      }
    }}
    tooltipLegendText={props.tooltipLegendText}
  />
);
