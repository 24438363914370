import React, { useState } from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { useMediaQuery } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getPatientFullName, getPatientLastNameWithInitials } from "../../../domain/services/patient";
import chatStore from "../../../stores/chatStore";
import { ItemImageContainer } from "./ItemComponents";
import { MessageInput } from "./MessageInput";
import { MessageItem } from "./MessageItem";
import { PrimaryButtonContainer, PrimaryButtonSmall } from "../../buttons/PrimaryButtonSmall";
import { OpponentAvatar } from "./ChatAvatars";
import { HeadingH3 } from "../../styleguide/typography";
import { AlertWarning } from "../../alert-warning/AlertWarning";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../../buttons/AlertButton";
import { ButtonWithTooltip } from "../../buttons/ButtonWithTooltip";
import { ModalPortal } from "../../modal/ModalPortal";
import { useConfirm } from "../../alert-warning/useConfirm";
import { scrollbars } from "../style-elements";
import { setLastScheduleUrl } from "../../appointment-history/appointmentHistoryStore";
import { colors } from "../../styleguide/colors";
import { ClosingChatsModal } from "../modals/ClosingChatsModal";

import arrowLeftIcon from "../icons/arrow_left.svg";
import phoneIcon from "../icons/phone.svg";
import phoneHoverIcon from "../icons/phone_hover.svg";
import lockIcon from "../icons/lock.svg";
import lockHoverIcon from "../icons/lock_hover.svg";
import unlockIcon from "../icons/unlock.svg";
import unlockHoverIcon from "../icons/unlock_hover.svg";
import loaderIcon from '../../schedule-loader-modal/icons/loader.svg';

const AvatarLink = styled(Link)`
  text-decoration: none;
`;

const ActiveChatHead = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 24px 7px;
  border-bottom: 1px solid rgba(220, 211, 210, 0.4);
  @media (max-width: 576px) {
    gap: 10px;
    justify-content: space-between;
    padding: 5px 10px;
  }
`;

const ActiveChatHeadContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
    
  @media screen and (max-width: 576px) {
    text-align: center;
  }
`;

const ActiveChatHeadTitle = styled(Link)`
  color: #262626;
  font-family: "Graphik LCG";
  font-size: 17px;
  font-weight: bold;
  line-height: 23px;
  text-decoration: none;
  flex: 1;
    
  ${props => props.disabled && css`pointer-events: none;`}
`;

const ActiveChatHeadBirthDate = styled.span`
  color: #7d7d7d;
  font-family: "Graphik LCG";
  font-size: 12px;
  line-height: 12px;
`;

const ActiveChatMessagesContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ${scrollbars};
`;

const ActiveChatMessages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
`;

const ChatMessagesDate = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${colors.darkGray};
  margin-top: 12px;
  margin-bottom: 4px;
`;

const ActiveChatCloseButton = styled.button`
  border: none;
  background: none;
  display: none;

  @media screen and (max-width: 576px) {
    display: block;
  }
`;

const ActiveChatButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-right: 16px;
  @media (max-width: 576px) {
    gap: 15px;
    margin-right: 10px;
  }
`;

const MessageInputContainer = styled.div`
    position: relative;
`;

const InputPlaceholder = styled.div`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  border-top: 1px solid rgba(220, 211, 210, 0.4);
`;

const OpenChatButtonContainer = styled(PrimaryButtonContainer)`
  width: auto;
  height: auto;
  padding: 8px 23px;
`;

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
`;

const ToBottomButton = styled.button`
  display: ${props => props.isVisible ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${colors.magenta};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: calc(100% + 12px);
  right: 24px;
  box-shadow: 0 7px 9px 0 rgba(0,0,0,0.03);
  color: #fff;
  z-index: 1;
`;

const Loader = styled.img`
  margin-bottom: 16px;
  animation: rotate 2s linear infinite;
`;

export const ActiveChat = observer(() => {
  const isMobile = useMediaQuery('(max-width: 576px)');
  const currentChat = chatStore.currentChat;
  const messageContainerRef = React.useRef(null);
  // const userIsAFKRef = React.useRef(true);
  const userIsActiveRef = React.useRef(false);
  const scrollData = React.useRef({
    previousScrollHeight: 0,
    previousMessageCount: 0
  });
  const deleteConfirm = useConfirm();
  const [isShowClosingChatModal, setIsShowClosingChatModal] = useState(false);
  const [isBottomScrollPosition, setIsBottomScrollPosition] = React.useState(true);

  const deleteAlertWarning = (
    <AlertWarning
      isNeedConfirm={deleteConfirm.isNeedConfirm}
      sendConfirm={deleteConfirm.sendConfirm}
      heading={<HeadingH3 modifiers="center">Удалить сообщение?</HeadingH3>}
      description={"Сообщене удалится у вас и у пациента."}
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отмена</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Удалить</AlertWarningPrimaryButton>
      )}
    />
  );

  const handleDelete = React.useCallback(async messageId => {
    try {
      const confirmResult = await deleteConfirm.requestConfirm();
      if (!confirmResult) {
        return;
      }
      chatStore.deleteMessage(messageId);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleOnOpenClosingChatModal = () => {
    setIsShowClosingChatModal(true);
  }

  const handleOnCloseClosingChatModal = () => {
    setIsShowClosingChatModal(false);
  }

  const handleOnOpenChat = ({ date }) => {
    handleOnCloseClosingChatModal();
    try {
      chatStore.openChat(chatStore.currentChat.id, date);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCallOpenChat = React.useCallback(async () => {
    try {
      await chatStore.showCallSettingsScreen();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const checkMessagesIsInView = React.useCallback(async () => {
    if (!messageContainerRef.current) {
      return;
    }
    if (!chatStore.currentChat) {
      return;
    }
    if (
      chatStore.currentChat.unread_messages_count > 0 && // Если есть непрочитанные сообщения в чате
      !chatStore.chatIsReadingNow && // чат уже читается
      userIsActiveRef.current && // если пользователь активен (двигает мышкой или пишет)
      messageContainerRef.current.scrollTop >= messageContainerRef.current.scrollHeight - messageContainerRef.current.clientHeight - 10
    ) { // Если доскролили до самого низа, читаем сразу весь чат
      chatStore.readAllMessagesInChat(chatStore.currentChatId);
      return;
    }
    // chatStore.currentChatMessages.forEach(message => { // Иначе читаем сообщения которые попадают в зону видимости чата
    //   if (
    //     !chatStore.isChatMessageRead(chatStore.currentChatId, message.id) &&
    //     !chatStore.messageSenderIsDoctor(chatStore.currentChatId, message.id)
    //   ) {
    //     const messageElement = document.querySelector(`[data-message-id="${message.id}"]`);
    //     const messageContainerTopLine =  messageContainerRef.current.scrollTop;
    //     const messageContainerBottomLine = messageContainerTopLine + messageContainerRef.current.clientHeight;
    //     if (messageElement && messageElement.offsetTop >= messageContainerTopLine && (messageElement.offsetTop + messageElement.clientHeight) <= messageContainerBottomLine) {
    //       chatStore.readMessage(message);
    //     }
    //   }
    // });
  }, []);

  const handleScroll = React.useCallback(async () => {
    if (!messageContainerRef.current) {
      return;
    }
    const saveScroll = () => {
      scrollData.current.previousScrollHeight = messageContainerRef.current.scrollHeight;
    };
    const restoreScroll = () => {
      const scrollDelta = messageContainerRef.current.scrollHeight - scrollData.current.previousScrollHeight;
      if (scrollDelta > 0 && scrollData.current.previousScrollHeight > 0) {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollTop + scrollDelta;
      }
      saveScroll();
    };
    if (scrollData.current.previousMessageCount < chatStore.currentChatData?.messages.length) {
      scrollData.current.previousMessageCount = chatStore.currentChatData?.messages.length;
      restoreScroll();
      return;
    }
    setIsBottomScrollPosition(
      messageContainerRef.current.scrollHeight -
        messageContainerRef.current.clientHeight -
        messageContainerRef.current.scrollTop <
        300
    );
    userIsActiveRef.current = true;
    checkMessagesIsInView();
    if (messageContainerRef.current.scrollTop > 100) {
      return;
    }
    if (chatStore.loadingChatMessages) {
      return;
    }
    if (chatStore.currentChatData.allMessagesLoaded) {
      return;
    }
    saveScroll();
    await chatStore.fetchCurrentChatMessages();
  }, []);

  const scrollToChatBottom = React.useCallback((cancelScroll) => {
    if (!cancelScroll) {
      setTimeout(() => {
        if (!messageContainerRef.current) {
          return;
        }
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight - messageContainerRef.current.clientHeight;
      }, 100);
    }
  }, []);

  React.useEffect(() => {
    if (!messageContainerRef.current) {
      return;
    }
    const debouncedHandleScroll = _.debounce(handleScroll, 100);
    messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    messageContainerRef.current.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
  }, [messageContainerRef.current]);

  React.useEffect(() => {
    if (currentChat) {
      scrollToChatBottom();
    }
  }, [currentChat]);

  React.useEffect(() => {
    const onUserActive = () => {
      userIsActiveRef.current = true; // Если пользователь пишет или двигает мышкой - становится активным
      checkMessagesIsInView(); // и проверяем можем ли мы прочитать сообщения
    };

    document.addEventListener('mousemove', onUserActive);
    document.addEventListener('keydown', onUserActive);

    return () => {
      document.removeEventListener('mousemove', onUserActive);
      document.removeEventListener('keydown', onUserActive);

    };
  }, []);

  React.useEffect(() => {
    (async () => {
      userIsActiveRef.current = false; // И мы думаем что пользователь не активен
      await handleScroll(); // Когда сообщения отрендерились, проверяем, осталось ли место для новых
    })();
  }, [chatStore.currentChatData?.messages.length]); // Потому что тут лежат сообщения до фильтрации по only_for_patient

  React.useEffect(() => {
    scrollToChatBottom(!isBottomScrollPosition);
  }, [chatStore.currentChat?.id + chatStore.currentChatMessages.length]);


  if (!currentChat) {
    return null;
  }

  let lastMessageDate = null;

  return (
    <>
      <ActiveChatHead>
        <ActiveChatCloseButton
          onClick={() => {
            chatStore.closeCurrentChat();
          }}>
          <img src={arrowLeftIcon} alt="Back" />
        </ActiveChatCloseButton>
        {/** Был контейнер ActiveChatHeadContent, т.к. был еще 1 компонент ActiveChatHeadBirthDate */}
        <ActiveChatHeadTitle to={`/appointment/new/${currentChat.opponent.medical_file_id}`} disabled={!currentChat?.opponent?.medical_file_id}>
          {!isMobile ? getPatientFullName(currentChat.opponent) : getPatientLastNameWithInitials(currentChat.opponent)}
        </ActiveChatHeadTitle>
        {/*<ActiveChatHeadBirthDate>01.01.1990 (33 года)</ActiveChatHeadBirthDate>*/}
        <ActiveChatButtonsContainer>
          {currentChat.opponent.patient_id &&
            (currentChat.can_patient_chat ? (
              <ButtonWithTooltip
                icon={unlockIcon}
                iconHover={unlockHoverIcon}
                tooltip="Запретить переписку"
                onClick={() => {
                  chatStore.closeChat(currentChat.id);
                }}
              />
            ) : (
              <ButtonWithTooltip
                icon={lockIcon}
                iconHover={lockHoverIcon}
                tooltip="Разрешить переписку"
                onClick={handleOnOpenClosingChatModal}
              />
            ))}
          {/** На мите с Димой решили пока убрать кнопку звонка для WebView */}
          {!window.ReactNativeWebView && (
            <ButtonWithTooltip
              icon={phoneIcon}
              iconHover={phoneHoverIcon}
              tooltip="Позвонить пациенту"
              onClick={() => {
                if (!!chatStore.voxCall) {
                  return false;
                }
                return currentChat.can_patient_chat
                  ? chatStore.showCallSettingsScreen()
                  : handleCallOpenChat();
              }}
            />
          )}
        </ActiveChatButtonsContainer>
        <ItemImageContainer>
          {currentChat.opponent.medical_file_id ? (
            <AvatarLink
              to={`/appointment/new/${currentChat.opponent.medical_file_id}`}
              onClick={() => setLastScheduleUrl(location.pathname)}>
              <OpponentAvatar opponent={currentChat.opponent} />
            </AvatarLink>
          ) : (
            <OpponentAvatar opponent={currentChat.opponent} />
          )}
        </ItemImageContainer>
      </ActiveChatHead>
      <ActiveChatMessagesContainer ref={messageContainerRef}>
        <ActiveChatMessages>
          {
            !chatStore.currentChatData?.allMessagesLoaded && (
              <LoaderContainer>
                <Loader src={loaderIcon}/>
                <span>Загружаем сообщения...</span>
              </LoaderContainer>
            )
          }
          {chatStore.currentChatMessages.map((message, index) => {
            const dt = moment(message.created_at).format("DD MMMM");
            const result = [];
            if (lastMessageDate !== dt){
              lastMessageDate = dt;
              result.push(<ChatMessagesDate key={message.id + 'date'}>{dt}</ChatMessagesDate>)
            }
            result.push(<MessageItem key={message.id} message={message} onDelete={handleDelete} />)
            return result;
          })}
        </ActiveChatMessages>
      </ActiveChatMessagesContainer>
      <MessageInputContainer>
        <ToBottomButton isVisible={!isBottomScrollPosition} onClick={() => scrollToChatBottom()}>
          <ExpandMoreIcon/>
        </ToBottomButton>
        {currentChat.opponent?.patient_id && !currentChat.can_patient_chat ? (
          <InputPlaceholder>
            <span>Чат закрыт. Чтобы писать сообщения, откройте чат.</span>
            <PrimaryButtonSmall
              primaryButtonContainer={OpenChatButtonContainer}
              onClick={handleOnOpenClosingChatModal}>
              Открыть чат
            </PrimaryButtonSmall>
          </InputPlaceholder>
        ) : (
          <MessageInput
            onSubmit={() => {
              if (messageContainerRef.current) {
                messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
              }
            }}
          />
        )}
      </MessageInputContainer>
      <ModalPortal>
        {deleteAlertWarning}
        <ClosingChatsModal isShow={isShowClosingChatModal} onClose={handleOnCloseClosingChatModal} onSubmit={handleOnOpenChat} />
      </ModalPortal>
    </>
  );
});
