import React from "react";
import useReactRouter from "use-react-router";
import headerStore, { TABS_URL } from "../../stores/headerStore";
import { IconButton } from "@mui/material";
import { colors } from "../styleguide/colors";
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import styled from "styled-components";

const IconButtonStyled = styled(IconButton)`
  grid-area: backButton;
  &:active {
    color: ${colors.lightMagenta};
  }
`;

export const HeaderBackButton = () => {
  const { history } = useReactRouter();
  const handleOnBack = () => history.push(TABS_URL[headerStore.lastMainNavigationTab]);

  return (
    <IconButtonStyled color="black" onClick={handleOnBack}>
      <BackIcon fontSize="medium" />
    </IconButtonStyled>
  );
};
