// @flow
import styled from "styled-components";
import { withModifiers } from "with-modifiers";
import arrowIcon from "./icons/arrow-up.svg";
import arrowHoverIcon from "./icons/arrow-up-hover.svg";

export const GroupMoveButton = withModifiers({
  down: () => `
        transform: rotate(180deg);
    `
})(
  modifier => styled.div`
    width: 24px;
    height: 24px;

    background: url(${arrowIcon});
    background-size: contain;
    cursor: pointer;

    ${modifier};

    &:hover {
      background: url(${arrowHoverIcon});
      background-size: contain;
    }
  `
);
