import React from "react";
import moment from "moment";
import styled from "styled-components";
import { PNormal } from "../styleguide/typography";
import useFetch from "react-fetch-hook";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import useReactRouter from "use-react-router";
import { useOrganization } from "../appointment-print/useOrganization";
import {
  AppointmentPrintableHeaderItem,
  AppointmentPrintableHeaderLayout
} from "../appointment-print/AppointmentPrintableHeader";
import { AppointmentSign } from "../appointment-print/AppointmentSign";
import { AppointmentPrintLayout } from "../appointment-print/AppointmentPrintLayout";
import { AppointmentPrintableContacts } from "../appointment-print/AppointmentPrintContacts";
import { useBeforePrint } from "../appointment-print/useBeforePrint";
import { useAfterPrint } from "../appointment-print/useAfterPrint";
import { useLogo } from "../appointment-print/useLogo";
import { rgba } from "polished";
import logo from "../appointment-print/icons/logo.svg";
import { EMS_API_URL } from "../../api/api";
import {
  formatPatientBirthDate,
  getAgeByBirthDate,
  getPatientFullName
} from "../../domain/services/patient";
import { getNounEndingByNumber } from "../../utils/getNounEndingByNumber";
import { get } from "lodash";
import {useApiRequest} from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

export const formatDateTime = date => {
  return moment(date)
    .locale("ru")
    .format("D MMMM YYYY, HH:mm");
};

const Title = styled.div`
  font-size: 30px;
  line-height: 30px;
  margin-top: 24px;
  color: #262626;
`;

const Folder = styled.div`
  padding-top: 36px;
  padding-bottom: 24px;

  &:first-child {
    padding-top: 48px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${rgba("#979797", 0.3)};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const MetadataLine = styled.div`
  padding-top: 4px;
  line-height: 23px;

  span {
    color: #ccc;
  }
`;

const FolderSectionLine = styled.div`
  margin-top: 4px;
  line-height: 28px;
  color: #262626;

  span {
    color: #ccc;
  }
`;

const TwoColumnsContainer = styled.div`
  column-count: 2;
`;

const TwoColumnsFolderSectionLine = styled(FolderSectionLine)`
  margin: 0;

  & + & {
    :before {
      margin-top: 4px;
      content: "";
      display: block;
    }
  }
`;

const extractDateFromFolderName = string => {
  return string.match(/(\d{1,4}([.\-/])\d{1,2}([.\-/])\d{1,4})/g);
};

const Logo = styled.img.attrs(props => ({
  src: props.logoUrl ? `${EMS_API_URL}/${props.logoUrl}` : logo
}))`
  width: 175px;
  height: 77px;
  object-fit: scale-down;
  object-position: left;
`;

export const FolderPrintContent = ({ logoEl, organization, setLogoLoaded, data }) => {
  if (!data) {
    return <PNormal>Подготовка данных...</PNormal>;
  }

  const logo = (
    <Logo ref={logoEl} logoUrl={organization?.logo} onLoad={() => setLogoLoaded(true)} />
  );

  const patientAge = getAgeByBirthDate(data.medical_file.patient);

  const appointmentPrintableHeaderItems = (
    <>
      <AppointmentPrintableHeaderItem label="Врач" content={data.therapist} notFixedWidth />
      <AppointmentPrintableHeaderItem
        label="Пациент"
        content={`${getPatientFullName(data.medical_file.patient)} (№${data.medical_file.number})`}
        notFixedWidth
      />
      <AppointmentPrintableHeaderItem
        label="Дата"
        content={formatDateTime(new Date())}
        notFixedWidth
      />
      <AppointmentPrintableHeaderItem
        label="Дата рождения"
        content={
          patientAge
            ? `${formatPatientBirthDate(
                data.medical_file.patient
              )} (${patientAge} ${getNounEndingByNumber(patientAge, ["год", "года", "лет"])})`
            : `${formatPatientBirthDate(data.medical_file.patient)}`
        }
        notFixedWidth
      />
    </>
  );

  const header = (
    <AppointmentPrintableHeaderLayout logo={logo}>
      {appointmentPrintableHeaderItems}
    </AppointmentPrintableHeaderLayout>
  );

  const diagnose = data.results.find(d => get(d, "diagnose", false))?.diagnose || "";

  const startDate =
    extractDateFromFolderName(data.results[data.results.length - 1]?.title || "") || "N/a";
  const endDate = extractDateFromFolderName(data.results[0]?.title || "") || "N/a";

  const values = data.results.map(folder => (
    <Folder key={folder.title}>
      {folder.sections.map((section, i) => {
        const twoColumnsView = section.columns_number === 2 && section.values.length > 1;
        const Container = twoColumnsView ? TwoColumnsContainer : React.Fragment;
        const SectionLine = twoColumnsView ? TwoColumnsFolderSectionLine : FolderSectionLine;

        return (
          <Container key={i}>
            {section.values.map((value, j) => (
              <SectionLine
                inline={{ display: section.columns_number === 2 ? "inline" : "block" }}
                key={j}>
                <span>{value.name}:</span>{" "}
                {typeof value.value === "object" ? value.value.value : value.value}
              </SectionLine>
            ))}
          </Container>
        );
      })}
    </Folder>
  ));

  const result = (
    <>
      <Title>Выписной эпикриз</Title>
      {diagnose && (
        <MetadataLine style={{ paddingTop: 12 }}>
          <span>Диагноз:</span> {diagnose || ""}
        </MetadataLine>
      )}
      <MetadataLine>
        Пациенты обследованы в полном объеме согласно приказу МЗ РФ №803н от 31.07.2020,
        противопоказаний для проведения программы ВРТ не выявлено
      </MetadataLine>
      <MetadataLine>
        <span>Находилась на лечении:</span> с {startDate} по {endDate}
      </MetadataLine>
      <div>{values}</div>
    </>
  );

  const sign = (
    <AppointmentSign
      doctor={{
        name: data.therapist,
        regalia: ""
      }}
      chief={data.head_physician}
    />
  );

  const contactsLayout = <AppointmentPrintableContacts organization={{}} />;

  return (
    <React.Fragment>
      <AppointmentPrintLayout
        sign={sign}
        values={result}
        header={header}
        contacts={contactsLayout}
      />
    </React.Fragment>
  );
};

export const FolderPrintPage = () => {
  const token = useStore(tokenStore);
  const {
    match: { params }
  } = useReactRouter();

  const { logoEl, isLogoLoaded, setLogoLoaded } = useLogo();

  const folderData = useApiRequest(async () => {
    if (params.id || params.concrete_folder) {
      return await apiv2.appointments.getFolderPrintDetails({
        id: params.id || "",
        concreteFolder: params.concrete_folder || params.id
      });
    }
  }, [token, params.id, params.concrete_folder || true]);

  const organization = useOrganization(folderData?.data);

  useBeforePrint(
    (isLogoLoaded && folderData?.data && !folderData.in_progress && organization) || false
  );
  useAfterPrint((folderData && `/appointment/folder/${params.id}`) || "");

  return (
    <FolderPrintContent
      data={folderData?.data}
      logoEl={logoEl}
      setLogoLoaded={setLogoLoaded}
      organization={organization}
    />
  );
};
