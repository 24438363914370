// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";
import { Link } from "react-router-dom";

import { PNormal } from "../styleguide/typography";
import { DoctorAvatar } from "./DoctorAvatar";
import type { Doctor as DoctorType } from "../../domain/entities/Doctor";
import { FEATURE, useApplicationFeatureEnabled } from "../../utils/applicationFeatures";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 38px;
  height: 38px;
  margin-right: 14px;
  border-radius: 100%;

  background: url(${props => props.avatar}) no-repeat;
  background-size: contain;
`;

const Profile = styled.div``;

const LinkProfile = styled(Link)`
  text-decoration: none;
`;

const OverridableProps = { Avatar };

type DoctorOverridesProps = ExtractOverridesProps<typeof OverridableProps>;

export const Doctor = (props: {|
  name: string,
  avatar?: string,
  avatarStyle?: any,
  placeholderStyle?: any,
  doctor?: DoctorType,
  needLinkToProfile?: boolean,
  overrides?: DoctorOverridesProps,
  isHidingAvatar?: boolean,
  isHidingName?: boolean
|}) => {
  const components = {
    linkProfile: LinkProfile,
    profile: Profile
  };
  const DynamicComponent =
    props.needLinkToProfile
      ? components.linkProfile
      : components.profile;

  return (
    <DynamicComponent
      to={
        props.needLinkToProfile
          ? "/profile"
          : null
    }>
      <Container>
        {!props.isHidingAvatar && (
          props.doctor ? (
            <DoctorAvatar
              doctor={props.doctor}
              avatarStyle={
                props.avatarStyle ||
                css`
                  width: 38px;
                  height: 38px;
                  margin-right: ${!props.isHidingName && '14px'};
                `
              }
              placeholderStyle={
                props.placeholderStyle ||
                css`
                  width: 38px;
                  height: 38px;
                  margin-right: ${!props.isHidingName && '14px'};
                  font-size: 14px;
                  line-height: 14px;
                `
              }
            />
          ) : (
            <Avatar {...o} avatar={props.avatar} />
          )
        )}
        {!props.isHidingName && <PNormal modifiers={"left"}>{props.name}</PNormal>}
      </Container>
    </DynamicComponent>
  );
}
