import React from "react";

import { PreloaderBody } from "../prealoader/PreloaderBody";

import * as S from "./styled/ServicesSuggestPreloader.styled";

export const ServicesSuggestPreloader = () => (
  <PreloaderBody>
    {new Array(2)
      .fill()
      .map((_, i) => i + 1)
      .map(i => (
        <React.Fragment key={i}>
          <S.Item>
            <S.CodeCaption />
            <S.Caption width={551} />
          </S.Item>
          <S.Item>
            <S.CodeCaption />
            <S.Caption width={408} />
          </S.Item>
          <S.Item>
            <S.CodeCaption />
            <S.Caption width={490} />
          </S.Item>
        </React.Fragment>
      ))}
  </PreloaderBody>
);
