"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTrigger = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var useTrigger = function useTrigger(trigger) {
  var _useState = (0, _react.useState)(trigger.id),
      _useState2 = (0, _slicedToArray2.default)(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var update = function update() {
    return setValue(trigger.id);
  };

  (0, _react.useEffect)(function () {
    trigger.subscribe(update);
    return function () {
      return trigger.unsubscribe(update);
    };
  }, []);
  return value;
};

exports.useTrigger = useTrigger;