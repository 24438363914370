// @flow
import { useStore } from "effector-react";
import {
  appointmentFieldValuesStore,
  getFieldValueDescription
} from "../../stores/appointmentFieldValues";
import type { TFieldValue } from "../../stores/appointmentFieldValues";
import type { TAppointment_id } from "../../domain/entities/Appointment";

export const useFieldValue = (appointment_id: TAppointment_id, field_id: number): ?TFieldValue => {
  return useStore(
    appointmentFieldValuesStore.map(store => {
      return getFieldValueDescription(appointment_id, field_id)(store) || null;
    })
  );
};
