import React from 'react';
import styled, { css } from 'styled-components';
import {colors} from '../../styleguide/colors';

export const ItemContainer = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const ItemImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 13px;
  overflow: hidden;
  flex-shrink: 0;
`;

export const ItemInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 0;
`;

export const ItemTitleContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ItemTitle = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  font-family: "Graphik LCG";
  font-size: 15px;
  font-weight: bold;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.isAccent ? '#C6007F' : '#262626'};

  ${props => props.isPaid && css`
    &::before {
      content: "\u20bd";
      margin-right: 8px;
      color: ${colors.magenta};
    }
  `}
`;

export const ItemTime = styled.span`
  color: #DCD3D2;
  font-family: "Graphik LCG";
  font-size: 13px;
  line-height: 23px;
`;

export const ItemContentContainer = styled.div`
  position: relative;
  display: flex;
  gap: 12px;
`;

export const ItemContent = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  color: ${props => props.isAccent ? '#C6007F' : '#7D7D7D'};
  font-family: "Graphik LCG";
  font-size: 15px;
  line-height: 23px;
`;

export const ItemText = styled.pre`
  width: 100%;
  white-space: pre-line;
  word-break: break-word;
`;

export const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
