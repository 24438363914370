// @flow
import { ChartComponent } from "../common/charts/ChartComponent";
import reviewStatisticsStore from "../../stores/reviewStatisticsStore";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { LabelWithFiltersModal } from "../common/widgets-components-common/LabelWithFiltersModal";
import _ from "lodash";
import { getLocalStorage, setLocalStorage } from "../../utils/localStorageUtils";
import { observer } from "mobx-react";
import { PaperStyled } from "./widgets.styled";
import organizationsStore from "../../stores/organizationsStore";
import YandexLogo from "../../assets/icons/sources/yandex.inline.svg";
import ProDoctorLogo from "../../assets/icons/sources/prodoctorov.inline.svg";
import TwoGisLogo from "../../assets/icons/sources/2gis.inline.svg";
import AppLogo from "../../assets/icons/sources/app.inline.svg";
import GoogleLogo from "../../assets/icons/sources/google.inline.svg";
import { AbsoluteLoader } from "../loader/Loader";

const NAME_WIDGET = "widgetTotalReviews";
const LOGO_BY_AREA = {
  "2gis": <TwoGisLogo />,
  about_doctors: <ProDoctorLogo />,
  internal: <AppLogo />,
  app_internal: <AppLogo />,
  yandex: <YandexLogo />,
  google: <GoogleLogo />
};
const colorsByRate = {
  1: "#E83222",
  2: "#EF9536",
  3: "#F6D246",
  4: "#FFFC55",
  5: "#63CC3D"
};
const RATINGS = ["count_1", "count_2", "count_3", "count_4", "count_5"];

export const FILTERS_FIELDS_WIDGET_TOTAL_REVIEWS = () => [
  {
    type: "text",
    name: "label",
    label: "Название графика",
    xs: 12
  }
];

const transformDiagramData = data => {
  if (!data) {
    return [];
  }

  const legend = [1, 2, 3, 4, 5].map(rating => ({
    label: `Оценка - ${rating}`,
    color: colorsByRate[rating]
  }));

  const ratings = data.reduce((acc, item) => {
    acc[item.map_name] = RATINGS.map(rating => item[rating]);
    return acc;
  }, {});

  const datasets = data.map(item => {
    const totalRating = ratings[item.map_name].reduce((acc, rating) => acc + rating, 0);
    const maxRating = _.max(ratings[item.map_name]);

    return {
      data: ratings[item.map_name].map((rating, index) => {
        const rate = index + 1;
        return {
          label: `Оценка ${rate}`,
          count: rating,
          color: colorsByRate[rate],
          filling: Math.round((rating / maxRating) * 100)
        };
      }),
      name: reviewStatisticsStore.mapNamesOptionsTableName[item.map_name],
      icon: LOGO_BY_AREA[item.map_name],
      value: `${totalRating} / ${item.average_rating}`
    };
  });

  return { datasets, legend };
};

export const WidgetTotalReviews = observer(
  (props: { filtersData: () => {}, rewriteFilters: () => {}, keyWidget: String }) => {
    const [filtersDataDiagram, setFiltersDataDiagram] = useState({});
    const [filterFields, setFilterFields] = useState({});
    const [chartsData, setChartsData] = useState();
    const [label, setLabel] = useState("Общее количество отзывов и средний рейтинг по площадкам");
    const [isInitialized, setIsInitialized] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      (async () => {
        await organizationsStore.getOrganizations();
        if (getLocalStorage(NAME_WIDGET + props.keyWidget)) {
          setLocalStorage(
            NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", ""),
            getLocalStorage(NAME_WIDGET + props.keyWidget)
          );
          localStorage.removeItem(NAME_WIDGET + props.keyWidget);
        }
        const filterDiagramLocaleStorage = getLocalStorage(
          NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", "")
        );
        if (
          filterDiagramLocaleStorage &&
          moment(filterDiagramLocaleStorage.date_end).isBefore(moment())
        ) {
          filterDiagramLocaleStorage.date_end = moment();
        }
        const filters =
          props.rewriteFilters || filterDiagramLocaleStorage || props.filtersData || {};
        if (filters.label) {
          setLabel(filters.label);
        }
        setFiltersDataDiagram(filters);
        setFilterFields([...FILTERS_FIELDS_WIDGET_TOTAL_REVIEWS()]);
        setIsInitialized(true);
      })();
    }, []);

    useEffect(() => {
      (async () => {
        if (_.size(filtersDataDiagram)) {
          setLoading(true);
          setLocalStorage(
            NAME_WIDGET + props.keyWidget + location.pathname.replaceAll("/", ""),
            filtersDataDiagram
          );
          if (filtersDataDiagram.label) {
            setLabel(filtersDataDiagram.label);
          }
          const response = await reviewStatisticsStore.getTotalReviewsDiagram(filtersDataDiagram);
          setChartsData(transformDiagramData(response));
          setLoading(false);
        }
      })();
    }, [filtersDataDiagram]);

    useEffect(() => {
      if (props.rewriteFilters) {
        setFiltersDataDiagram({ ...filtersDataDiagram, ...props.rewriteFilters });
      }
    }, [props.rewriteFilters]);

    return (
      <PaperStyled>
        {filterFields && <LabelWithFiltersModal fullWidth={true} label={label} />}
        {(loading || !isInitialized) && <AbsoluteLoader />}
        {isInitialized && chartsData && (
          <ChartComponent
            textButtonFilter="Применить"
            type="horizontalBar"
            nameDiagram="ReviewDiagramCES"
            data={chartsData}
          />
        )}
      </PaperStyled>
    );
  }
);
