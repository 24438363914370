import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowDown as ArrowIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const TabStyled = styled(Tab)(
  ({ theme, blocked, rotate_icon }) =>
    `
      ${
        blocked === "true"
          ? `
            pointer-events: none;
          `
          : ``
      };
      text-transform: none;
      font-size: 16px; 
      font-weight: 400;
      min-height: auto;
      height: 65px;
      .MuiSvgIcon-root {
        transform: rotate(${rotate_icon});
        transition: transform 0.15s ease-in-out;
      }
      :not(.Mui-selected) {
        color: ${theme.palette.black.text}
      }
      ${theme.breakpoints.down("xl")} {
        height: 60px;
      }
      ${theme.breakpoints.down("lg")} {
        height: 55px;
      }
    `
);

const DropDownMoreTabs = ({
  anchor,
  indexSelectedTab,
  allTabs,
  dropdownTabs,
  text,
  handleChangeTab,
  handleCloseMoreTabs,
  handleChangeMoreTab
}) => {
  const changeMoreTab = tab => {
    const indexTabByName = allTabs.findIndex(item => item.id === tab.id);
    if (indexTabByName < 0) {
      return;
    }
    handleChangeTab(null, null, true);
    handleChangeMoreTab(indexTabByName);
  };

  return (
    <React.Fragment>
      {text}
      <Menu open={!!anchor} onClose={handleCloseMoreTabs} anchorEl={anchor}>
        {dropdownTabs.map(tab => (
          <MenuItem
            key={tab.id}
            onClick={() => changeMoreTab(tab)}
            selected={tab.id === allTabs[indexSelectedTab].id}>
            {tab.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export const CorporateResponsiveTabs = ({
  isInitialized = false,
  initialTabs = [],
  indexSelectedTab = 0,
  changeSelectedTab,
  nameMoreTab = "Еще"
}) => {
  const tabsRef = useRef(null);
  const [moreTabAnchor, setMoreTabAnchor] = useState(null);
  const [allTabsWidth, setAllTabsWidth] = useState([]);
  const [dropdownTabs, setDropdownTabs] = useState([]);
  const [visibleTabs, setVisibleTabs] = useState(initialTabs);
  const [activeTab, setActiveTab] = useState(indexSelectedTab);

  const calculationTabs = tabsWidthStart => {
    setTimeout(() => {
      let newEmptyWidth = document.getElementsByClassName("responsive-tabs")[0]?.offsetWidth;
      const newVisibleTabs = [];
      const newDropdownTabs = [];

      for (let i = 0; i < initialTabs.length; i++) {
        const isMoreTab = newVisibleTabs.find(item => item.name === nameMoreTab);
        if (newEmptyWidth - tabsWidthStart[i] > 0 && !isMoreTab) {
          newEmptyWidth = newEmptyWidth - tabsWidthStart[i];
          newVisibleTabs.push(initialTabs[i]);
        } else {
          if (!isMoreTab) {
            if (newEmptyWidth < 96 && !!newVisibleTabs.length) {
              newDropdownTabs.push(newVisibleTabs[newVisibleTabs.length - 1]);
              newVisibleTabs.pop();
            }
            newDropdownTabs.push(initialTabs[i]);
            newVisibleTabs.push({ id: "more", name: nameMoreTab });
          } else {
            newDropdownTabs.push(initialTabs[i]);
          }
        }
      }

      setVisibleTabs(newVisibleTabs);
      setDropdownTabs(newDropdownTabs);

      const indexNewVisibleTab = newVisibleTabs.findIndex(
        tab => tab.id === initialTabs[indexSelectedTab].id
      );
      if (indexNewVisibleTab < 0) {
        setActiveTab(newVisibleTabs.length - 1);
      } else {
        setActiveTab(indexNewVisibleTab);
      }
    }, 100);
  };

  useEffect(() => {
    if (indexSelectedTab < visibleTabs.length && indexSelectedTab !== activeTab) {
      setActiveTab(indexSelectedTab);
    }
  }, [indexSelectedTab, visibleTabs]);

  useEffect(() => {
    if (!tabsRef.current) {
      return;
    }
    if (!allTabsWidth.length) {
      setTimeout(() => {
        const tabsContainer = tabsRef.current.querySelectorAll(".MuiTab-root");
        const tabsWidthStart = [];
        for (let i = 0; i < tabsContainer.length; i++) {
          const tabWidth = Math.ceil(tabsContainer[i].getBoundingClientRect().width);
          tabsWidthStart.push(tabWidth);
        }
        setAllTabsWidth(tabsWidthStart);
        calculationTabs(tabsWidthStart);
      }, 100);
    }

    const debouncedResizeHandler = _.debounce(() => {
      handleCloseMoreTabs();
      calculationTabs(allTabsWidth);
    });

    window.addEventListener("resize", debouncedResizeHandler);
    return () => {
      debouncedResizeHandler.cancel();
      window.removeEventListener("resize", debouncedResizeHandler);
    };
  }, [allTabsWidth, indexSelectedTab]);

  const handleChangeTab = (event, value, isMoreTab) => {
    if (isMoreTab) {
      setActiveTab(visibleTabs.length - 1);
    } else if (visibleTabs[value].name !== nameMoreTab) {
      changeSelectedTab(value);
      setActiveTab(value);
    }
  };
  const handleChangeMoreTab = value => {
    changeSelectedTab(value);
  };
  const handleOpenMoreTabs = event => {
    setMoreTabAnchor(event.currentTarget);
    if (moreTabAnchor) {
      handleCloseMoreTabs();
    }
  };
  const handleCloseMoreTabs = () => {
    setMoreTabAnchor(null);
  };

  return (
    <Tabs className="responsive-tabs" value={activeTab} onChange={handleChangeTab} ref={tabsRef}>
      {visibleTabs.map((tab, index) => {
        return (
          <TabStyled
            blocked={`${isInitialized}`}
            rotate_icon={moreTabAnchor ? "180deg" : "0deg"}
            ref={tabsRef[index]}
            key={`${tab.name}_${tab.id}`}
            icon={tab.name === nameMoreTab ? <ArrowIcon /> : null}
            iconPosition="end"
            label={
              tab.name !== nameMoreTab ? (
                tab.name
              ) : (
                <DropDownMoreTabs
                  anchor={moreTabAnchor}
                  indexSelectedTab={indexSelectedTab}
                  allTabs={initialTabs}
                  dropdownTabs={dropdownTabs}
                  text={nameMoreTab}
                  handleChangeTab={handleChangeTab}
                  handleCloseMoreTabs={handleCloseMoreTabs}
                  handleChangeMoreTab={handleChangeMoreTab}
                />
              )
            }
            onClick={event => {
              if (tab.name === nameMoreTab) {
                handleOpenMoreTabs(event);
              }
            }}
          />
        );
      })}
    </Tabs>
  );
};
