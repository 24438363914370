// @flow
import React from "react/index";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { colors } from "../styleguide/colors";

export const CustomOptionWrapper = styled("li")(({ theme }) => {
  /** use themes after creating UI KIT instead of colors */
  return {
    ".MuiTypography-root": {
      color: colors.black,
      fontSize: "15px",
      "&.MuiTypography-caption": {
        fontSize: "12px",
        color: colors.black,
        opacity: 0.6
      }
    },
    "&.MuiAutocomplete-option": {
      position: "relative",
      transition: "all 0.15s ease-in-out",
      ":before": {
        content: "''",
        width: "4px",
        height: "100%",
        backgroundColor: colors.magenta,
        position: "absolute",
        top: 0,
        left: 0,
        opacity: 0,
        transition: "all .15s ease-in-out"
      },
      "&.Mui-focused": {
        transition: "all .15s ease-in-out",
        backgroundColor: "rgba(216, 216, 216, 0.15)",
        ".MuiTypography-root": {
          color: colors.magenta,
          "&.MuiTypography-caption": {
            color: colors.black
          }
        },
        "&:before": {
          opacity: 1
        }
      }
    }
  };
});

interface IOptionProps {
  name: string;
  caption?: string;
}

export const Option: React.FC<IOptionProps> = ({ name, caption }) => {
  return (
    <Box>
      {caption && <Typography variant="caption">{caption}</Typography>}
      <Typography>{name}</Typography>
    </Box>
  );
};
