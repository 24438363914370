import React from 'react';
import styled from 'styled-components';

export const Badge = styled.span`
  display: inline-flex;
  justify-content: center;
  flex-shrink: 0;
  align-self: flex-end;
  width: 32px;
  background: #C6007F;
  border-radius: 99em;
  color: #fff;
  font-family: "Graphik LCG";
  font-size: 13px;
  line-height: 23px;
  font-weight: 400;
`;

export const UnreadMessagesBadge = ({
  unreadMessagesCount
}) => {
  return (
    <Badge>
      {unreadMessagesCount > 99 ? '99+' : unreadMessagesCount.toString()}
    </Badge>
  );
};
