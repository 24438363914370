const Binder = {
  getAllMethods: (instance, cls) => {
    return Object.getOwnPropertyNames(Object.getPrototypeOf(instance)).filter(name => {
      const method = instance[name];
      return !(!(method instanceof Function) || method === cls);
    });
  },
  bind: (instance, cls) => {
    Binder.getAllMethods(instance, cls).forEach(mtd => {
      try {
        let mtdFunc = instance[mtd].bind(instance);
        instance[mtd] = function setInstance(...args) {
          return mtdFunc(...args);
        };
      } catch (e) {
        console.error(e);
      }
    });
  }
};

export default Binder;
