// @flow
import { useEffect } from "react";

export const useBeforeUnload = (message: string, enable?: boolean = true) => {
  useEffect(() => {
    if (enable) {
      const handleBeforeunload = e => {
        e.returnValue = message;
        return message;
      };

      window.onbeforeunload = handleBeforeunload;

      return () => (window.onbeforeunload = undefined);
    }
  }, [message, enable]);
};
