import { createStore, createEffect } from "effector";
import { tableSelectColors } from "../components/styleguide/colors";
import { tokenStore } from "./auth";
import apiv2 from '../apiv2';

export const getFoldersEffect = createEffect({
  handler: async patientId => {
    const token = tokenStore.getState();
    if (!token) {
      return;
    }
    return await apiv2.appointments.getFolders({ patientId });
  }
});

export const folderStore = createStore([]).on(getFoldersEffect.done, (state, response) => {
  if (response && response.result) {
    return response.result.folders;
  }
  return {};
});

export const folderOptions = folderStore.map(state =>
  (state || []).map(folder => ({
    label: folder.name,
    value: folder.id,
    color: folder.is_finished ? tableSelectColors.gray : tableSelectColors.green
  }))
);
