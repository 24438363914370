// @flow
import React from "react";
import { EditCommentButton } from "./EditCommentButton";
import { AddCommentButton } from "./AddCommentButton";
import { ModalPortal } from "../modal/ModalPortal";
import { CommentsModal } from "./CommentsModal";
import { CommentForm } from "./CommentForm";
import { DeleteButton } from "./DeleteButton";
import { HintedButtonsContainer } from "./HintedButtonsContainer";
import { DateRange } from "./DateRange";
import { CommentsModalProvider } from "./CommentsModalProvider";

export const PointButtons = (props: {
  comment: ?string,
  setComment: string => mixed,
  removeComment: () => mixed,
  remove: () => mixed,
  withDateRange?: boolean,
  dateRange?: { dates: [string, string], value: string } | [string, string] | null,
  setDateRange?: ([string, string]) => mixed,
  removeDateRange?: ([string, string]) => mixed,
  onToggleOpenDateRangePicker: () => mixed
}) => {
  const {
    comment,
    setComment,
    removeComment,
    remove,
    withDateRange,
    dateRange,
    setDateRange,
    removeDateRange,
    onToggleOpenDateRangePicker
  } = props;

  return (
    <HintedButtonsContainer>
      <CommentsModalProvider >
        {({ isModalShow, showModal, hideModal }) => (
          <>
            {comment ? (
              <EditCommentButton onClick={showModal} />
            ) : (
              <AddCommentButton onClick={showModal} />
            )}
            <ModalPortal>
              <CommentsModal isShow={isModalShow} hide={hideModal}>
                <CommentForm
                  defaultValue={comment || ""}
                  onClose={hideModal}
                  onSubmit={value => {
                    setComment(value);
                    hideModal();
                  }}
                  onDelete={() => {
                    removeComment();
                    hideModal();
                  }}
                  isAutofocus
                />
              </CommentsModal>
            </ModalPortal>
          </>
        )}
      </CommentsModalProvider>
      {withDateRange && (
        <DateRange
          value={dateRange}
          onSetDateRange={setDateRange}
          onResetDateRange={removeDateRange}
          onToggleOpen={onToggleOpenDateRangePicker}
        />
      )}
      <DeleteButton withoutTitle onClick={remove} />
    </HintedButtonsContainer>
  );
};
