import React, { createContext, useContext } from "react";

const SummaryContext = createContext({
  summary: [],
  addSummary: () => {},
  removeSummary: () => {}
});

export const useSummaryContext = () => {
  return useContext(SummaryContext);
};

export const SummaryContextProvider = (props) => {
  const [summary, setSummary] = React.useState([]);

  const addSummary = (id) => {
    setSummary(state => [...state, id]);
  };

  const removeSummary = (id) => {
    setSummary(state => state.filter(summaryId => summaryId !== id));
  };

  return (
    <SummaryContext.Provider value={{
      summary,
      addSummary,
      removeSummary
    }}>
      {props.children}
    </SummaryContext.Provider>
  );
};
