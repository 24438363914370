// @flow
import React, { Fragment, useState } from "react";
import { styled as styledMaterial } from "@mui/material/styles";
import { AppBar, Box, Button, Toolbar, useScrollTrigger } from "@mui/material";
import { ClinicLogo } from "../../components/clinicLogo/ClinicLogo";
import { breakpoints } from "../styled/common";
import { observer } from "mobx-react";
import { colors } from "../../components/styleguide/colors";
import IconButton from "@mui/material/IconButton";
import ExitIcon from "../../assets/icons/exit.inline.svg";
import { CorporateUserCard } from "./CorporateUserCard";
import { useStore } from "effector-react";
import { currentUser, logout } from "../../stores/auth";
import { CorporateSubTitle } from "../styled/CorporateTypography.styled";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { clearToasts } from "../../components/toast/toastStore";
import { Link } from "react-router-dom";

const HeaderContainer = styledMaterial(AppBar)(
  ({ theme }) => `
    position: sticky;
    box-sizing: border-box;
    background: ${theme.palette.wrapper.white};
    z-index: ${theme.zIndex.appBar};
    height: 60px;
    padding: 10px 30px;
    .MuiToolbar-root {
      padding: 0;
      min-height: auto;
    }
    ${theme.breakpoints.down("lg")} {
      padding: 10px 25px;
      height: 55px;
    }
    ${theme.breakpoints.down("sm")} {
      padding: 5px 20px;
      height: 45px;
    }
  `
);

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  div {
    width: 40px;
    height: 40px;
    ${breakpoints.desktop} {
      width: 35px;
      height: 35px;
    }
  }
`;

const UserContainer = styled(Box)`
  border-right: 1px solid ${colors.transparentGray};
  padding-right: 10px;
  ${CorporateSubTitle} {
    font-size: 15px;
  }
  ${breakpoints.desktop} {
    .MuiAvatar-root {
      width: 35px;
      height: 35px;
    }
    ${CorporateSubTitle} {
      font-size: 14px;
    }
  }
  ${breakpoints.sm} {
    ${CorporateSubTitle} {
      font-size: 12px;
    }
  }
`;

const ExitButton = styledMaterial(IconButton)`
  margin-left: 10px;
  width: 40px;
  height: 40px;
  ${breakpoints.desktop} {
    width: 35px;
    height: 35px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ConfirmationOfAction = ({ open, onAgree, onDisagree }) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onDisagree}>
      <DialogTitle>Вы точно хотите выйти?</DialogTitle>
      <DialogActions>
        <Button onClick={onDisagree}>Отменить</Button>
        <Button variant="contained" onClick={onAgree} autoFocus>
          Выйти
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CorporateHeader = observer(({ targetForScrollTrigger = null }) => {
  const triggerForShadow = useScrollTrigger({
    disableHysteresis: true,
    target: targetForScrollTrigger || window,
    threshold: 0
  });
  const user = useStore(currentUser);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);

  const handleOnExit = async () => {
    try {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("logout");
      }
      logout();
      clearToasts();
    } catch (e) {
      console.log(e);
    }
    setIsShowConfirmDialog(false);
  };

  return (
    <Fragment>
      <HeaderContainer elevation={triggerForShadow ? 3 : 0}>
        <Toolbar>
          <LogoContainer>
            {user.is_corporate_portal ? (
              <ClinicLogo />
            ) : (
              <Link to={'/schedule'}>
                <ClinicLogo />
              </Link>
            )}

          </LogoContainer>
          <UserContainer>
            <CorporateUserCard src={user.photo} user={user.doctor} isWithInitials />
          </UserContainer>
          <ExitButton onClick={() => setIsShowConfirmDialog(true)}>
            <ExitIcon />
          </ExitButton>
        </Toolbar>
      </HeaderContainer>
      <ConfirmationOfAction
        open={isShowConfirmDialog}
        onAgree={handleOnExit}
        onDisagree={() => setIsShowConfirmDialog(false)}
      />
    </Fragment>
  );
});
