// @flow
import moment from "moment";

import { getDateString, getDateStringWithSlash } from "../dates";
import type { DateString, DateStringWithSlash } from "../dates";
import type { Appointment } from "../entities/Appointment";

export const formatAppointmentDateToDateWithWords = (date: string): string => {
  return moment
    .utc(date)
    .locale("ru")
    .format("D MMMM");
};

export const formatAppointmentDateToDateYearWithWords = (date: string): string => {
  return moment
    .utc(date)
    .locale("ru")
    .format("D MMMM YYYY");
};

export const formatAppointmentDateToDateTimeWithWords = (appointment: Appointment): string => {
  return moment
    .utc(`${appointment.date}T${appointment.time}`)
    .locale("ru")
    .format("D MMMM YYYY, HH:mm");
};

export const formatAppointmentDateToNumericDate = (date: string): string => {
  return moment.utc(date).format("DD.MM.YY");
};

const formatAppointmentTime = (time: string): string => {
  return moment(time, "HH:mm:ss").format("HH:mm");
};

export const isInProgress = (appointment: Appointment): boolean => appointment.in_progress;

export const getAppointmentDateOrTime = (appointment: Appointment): string => {
  return isInProgress(appointment)
    ? formatAppointmentTime(appointment.time)
    : formatAppointmentDateToNumericDate(appointment.date);
};

export const getAppointmentDateTime = (appointment: Appointment): string => {
  return [
    formatAppointmentDateToNumericDate(appointment.date),
    formatAppointmentTime(appointment.time)
  ].join(", ");
};

export const getAppointmentsHistoryInterval = (): {
  from_date: DateString,
  till_date: DateString
} => {
  const from_date = getDateString(moment([2018, 1, 1]));
  const till_date = getDateString(moment.utc());
  return { from_date, till_date };
};

export const formatHistoryTableDate = (date: string): DateStringWithSlash => {
  return getDateStringWithSlash(moment.utc(date));
};
