// @flow
import * as React from "react";
import styled from "styled-components";
import plusIcon from "./icons/plus.svg";
import { Container, DefaultButton } from "./DefaultButton";
import { colors } from "../styleguide/colors";
import { rgba } from "polished";

const AddButtonContainer = styled(Container)`
  height: 42px;
  width: 100%;
`;

export const Icon = styled.div`
  width: 12px;
  height: 12px;

  mask: url(${plusIcon});
  background: ${rgba(colors.black, 0.5)};

  transition: all 0.15s ease-in-out;

  ${AddButtonContainer}:hover & {
    background: ${colors.magenta};
  }
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

const TextContainer = styled.div`
  margin-right: 8px;
`;

type TDefaultButtonOverrides = $ElementType<React$ElementConfig<typeof DefaultButton>, "overrides">;

export const AddButton = (props: {
  disabled?: boolean,
  onClick?: () => mixed,
  children: ?React.Node,
  overridesDefaultButton?: TDefaultButtonOverrides
}) => (
  <DefaultButton
    {...props}
    overrides={{
      Container: {
        component:
          (props.overridesDefaultButton &&
            props.overridesDefaultButton.Container &&
            props.overridesDefaultButton.Container.component) ||
          AddButtonContainer,
        props:
          (props.overridesDefaultButton &&
            props.overridesDefaultButton.Container &&
            props.overridesDefaultButton.Container.props) ||
          {}
      },
      Icon: {
        component:
          (props.overridesDefaultButton &&
            props.overridesDefaultButton.Icon &&
            props.overridesDefaultButton.Icon.component) ||
          Icon
      },
      IconContainer: {
        component:
          (props.overridesDefaultButton &&
            props.overridesDefaultButton.IconContainer &&
            props.overridesDefaultButton.IconContainer.component) ||
          IconContainer
      },
      TextContainer: {
        component:
          (props.overridesDefaultButton &&
            props.overridesDefaultButton.TextContainer &&
            props.overridesDefaultButton.TextContainer.component) ||
          TextContainer
      }
    }}
  />
);
