// @flow
import type { FieldPrescription } from "../../domain/entities/Field";
import * as React from "react";
import { useState, useMemo, useRef, useEffect } from "react";
import styled from "styled-components";
import type { LayoutFieldValue, TAppointment_id } from "../../domain/entities/Appointment";
import { NewTableButton } from "../prescription-table/NewTableButton";
import { NewTableForm } from "../prescription-table/NewTableForm";
import { NewTableModal } from "../prescription-table/NewTableModal";
import { ModalPortal } from "../modal/ModalPortal";
import type { PrescriptionTableParams } from "../../domain/value-objects/Prescription";
import {
  createPrescriptionFromTableParamsAndDrug,
  prescriptionTableParamsToRange
} from "../../domain/services/prescription";
import { PrescriptionTableHeadCellFixed } from "../prescription-table/PrescriptionTableHeadCellFixed";
import { PrescriptionTableHeadCell } from "../prescription-table/PrescriptionTableHeadCell";
import { PrescriptionTableHead } from "../prescription-table/PrescriptionTableHead";
import { NewRowButton } from "../prescription-table/NewRowButton";
import { useFieldValue } from "./useFieldValue";
import {
  addDrugToPrescriptionTable,
  changePrescriptionTableValue,
  deserializeLayoutFieldValueToPrescriptionTable,
  getPrescriptionTableValue,
  removeDrugFromPrescriptionTable,
  setPrescriptionTableParams
} from "../../stores/appointmentFieldValues";
import {
  PrescriptionTableCell,
  PrescriptionTableCellFixed
} from "../prescription-table/PrescriptionTableCell";
import _ from "lodash";
import type { Drug, TDrugId } from "../../domain/entities/Drug";
import { getDrugId } from "../../domain/entities/Drug";
import { PNormal } from "../styleguide/typography";
import { getField_id } from "../../domain/entities/Field";
import { createTrigger } from "react-use-trigger";
import { RemovePrescriptionTableRowWithConfirmButton } from "../prescription-table/RemovePrescriptionTableRowWithConfirmButton";
import { ScrollContext, ScrollDetector } from "../scroll-detector/ScrollDetector";
import { colors } from "../styleguide/colors";
import * as R from "ramda";

import { usePosition } from "../../utils/usePosition";
import { TableWrapper } from "../prescription-table/TableWrapper";
import { TableContainer } from "../prescription-table/TableContainer";
import { DrugSelect } from "../prescription-table/DrugSelect";
import { EditableCell } from "../prescription-table/EditableCell";
import { RemoveRowButton } from "../prescription-table/RemoveRowButton";
import {
  moveScrollLeft,
  moveScrollRight
} from "../prescription-table/prescriptionFocusedCellStore";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 32px 0 10px;
`;

const NewRowButtonContainer = styled.div`
  padding-top: 20px;
  padding-left: 14px;
  position: relative;
  z-index: 10;
  background-color: ${colors.white};
`;

const DrugNameContainer = styled.div`
  margin: 0 20px;
`;

const saveCellTrigger = createTrigger();
const tableScrollTrigger = createTrigger();

const AutoScroller = props => {
  const { children, scrollContainerRef } = props;
  useEffect(() => {
    moveScrollRight.watch(value => {
      scrollContainerRef.current.scrollTo({ left: scrollContainerRef.current.scrollLeft + value });
    });
    moveScrollLeft.watch(value => {
      scrollContainerRef.current.scrollTo({
        left: Math.max(0, scrollContainerRef.current.scrollLeft - value)
      });
    });
  }, []);
  return children;
};

const WidgetEditableCell = (props: {
  field: FieldPrescription,
  appointment_id: TAppointment_id,
  drug: Drug,
  offset: number,
  prescriptionTableParams: PrescriptionTableParams,
  drugOrder: Array<TDrugId>,
  visibilityArea: ?ClientRect
}) => {
  const {
    field,
    appointment_id,
    drug,
    offset,
    prescriptionTableParams,
    visibilityArea,
    drugOrder
  } = props;

  const drug_id = getDrugId(drug);
  const field_id = getField_id(field);
  const currentPrescriptionValue = useMemo(() => {
    return getPrescriptionTableValue(appointment_id, field_id, offset, drug_id);
  }, [offset, drug_id, field_id, appointment_id]);

  const onSave = tempValue => {
    const prescription = createPrescriptionFromTableParamsAndDrug({
      drug,
      offset,
      prescriptionTableParams,
      value: tempValue
    });
    changePrescriptionTableValue({
      field_id: getField_id(field),
      appointment_id,
      prescription
    });
    saveCellTrigger();
  };

  const coordinates = useMemo(() => ({ drugId: getDrugId(drug), column: offset }), [drug, offset]);

  return (
    <EditableCell
      currentPrescriptionValue={currentPrescriptionValue}
      onSave={onSave}
      tableScrollTrigger={tableScrollTrigger}
      visibilityArea={visibilityArea}
      coordinates={coordinates}
      drugOrder={drugOrder}
      duration={prescriptionTableParams.duration}
    />
  );
};

const getDrugIds = R.map(drug => getDrugId(drug));

const usePrescription = (field: FieldPrescription, appointment_id: TAppointment_id) => {
  const result = useFieldValue(appointment_id, field.id);
  const prescriptionTableParams =
    result && result.prescriptionTable && result.prescriptionTable.params;

  const headRange = useMemo(
    () => prescriptionTableParams && prescriptionTableParamsToRange(prescriptionTableParams),
    [prescriptionTableParams]
  );

  const prescriptionTableDrugs =
    (result && result.prescriptionTable && result.prescriptionTable.drugs) || [];
  const drugsIds = useMemo(() => getDrugIds(prescriptionTableDrugs), [prescriptionTableDrugs]);

  const wasCleaned =
    (result && result.prescriptionTable && result.prescriptionTable.wasCleaned) || false;

  return {
    headRange,
    prescriptionTableParams,
    drugsIds,
    prescriptionTableDrugs,
    wasCleaned
  };
};

export const PrescriptionTableWidget = (props: {
  field: FieldPrescription,
  appointment_id: TAppointment_id,
  layoutFieldValue?: LayoutFieldValue
}) => {
  const { appointment_id, field, layoutFieldValue } = props;
  const [isTableParamsModalShow, setIsTableParamsModalShow] = useState(false);
  const [isAddNewRow, setIsAddNewRow] = useState(false);
  const {
    headRange,
    prescriptionTableParams,
    drugsIds,
    prescriptionTableDrugs,
    wasCleaned
  } = usePrescription(field, appointment_id);

  const showCreationButton = !headRange || wasCleaned;

  useEffect(() => {
    if (layoutFieldValue && !headRange && !wasCleaned) {
      deserializeLayoutFieldValueToPrescriptionTable({
        appointment_id: appointment_id,
        field_id: getField_id(field),
        layoutFieldValue
      });
    }
  }, []);

  const hideTableParamsModal = () => setIsTableParamsModalShow(false);
  const onSubmitTableParamsModal = values => {
    setPrescriptionTableParams({
      appointment_id: appointment_id,
      field_id: field.id,
      params: values
    });
    hideTableParamsModal();
  };

  const onSelectNewDrug = drug => {
    addDrugToPrescriptionTable({
      appointment_id: appointment_id,
      field_id: field.id,
      drug
    });
    setIsAddNewRow(false);
  };

  const prescriptionTableRef = useRef(null);
  const [tableBorders, updateTableBorders] = usePosition(prescriptionTableRef);

  useEffect(() => {
    if (!showCreationButton) {
      updateTableBorders();
    }
  }, [showCreationButton]);

  return (
    <>
      {showCreationButton && (
        <ButtonContainer>
          <NewTableButton onClick={() => setIsTableParamsModalShow(true)} />
        </ButtonContainer>
      )}

      <ModalPortal>
        <NewTableModal isShow={isTableParamsModalShow} hide={hideTableParamsModal}>
          <NewTableForm onClose={hideTableParamsModal} onSubmit={onSubmitTableParamsModal} />
        </NewTableModal>
      </ModalPortal>
      {!showCreationButton && (
        <>
          <TableWrapper>
            <table>
              <PrescriptionTableHead>
                <PrescriptionTableHeadCellFixed
                  disableBorderRight
                  values={["Дата", "День цикла"]}
                />
              </PrescriptionTableHead>
              <tbody>
                {prescriptionTableDrugs &&
                  prescriptionTableDrugs.map(drug => (
                    <tr key={drug.id}>
                      <PrescriptionTableCellFixed>
                        <RemovePrescriptionTableRowWithConfirmButton>
                          {onButtonClick => (
                            <RemoveRowButton
                              onClick={onButtonClick(() => {
                                removeDrugFromPrescriptionTable({
                                  appointment_id,
                                  field_id: getField_id(field),
                                  drug_id: getDrugId(drug)
                                });
                              })}
                            />
                          )}
                        </RemovePrescriptionTableRowWithConfirmButton>

                        <DrugNameContainer>
                          <PNormal>{drug.name}</PNormal>
                        </DrugNameContainer>
                      </PrescriptionTableCellFixed>
                    </tr>
                  ))}
                {isAddNewRow && (
                  <tr>
                    <PrescriptionTableCellFixed>
                      <DrugSelect
                        ignore={drugsIds}
                        onSelect={onSelectNewDrug}
                        onBlur={() => setIsAddNewRow(false)}
                      />
                    </PrescriptionTableCellFixed>
                  </tr>
                )}
              </tbody>
            </table>
            <TableContainer ref={prescriptionTableRef}>
              <ScrollDetector callback={tableScrollTrigger}>
                <ScrollContext.Consumer>
                  {({ setTableRef, setScrollContainerRef }) => (
                    <AutoScroller scrollContainerRef={setScrollContainerRef}>
                      <table ref={setTableRef}>
                        <PrescriptionTableHead>
                          {headRange &&
                            headRange.map((headItem, id) => (
                              <PrescriptionTableHeadCell
                                key={id}
                                values={[headItem.top, headItem.bottom]}
                              />
                            ))}
                        </PrescriptionTableHead>
                        <tbody>
                          {prescriptionTableDrugs &&
                            prescriptionTableDrugs.map(drug => (
                              <tr key={drug.id}>
                                {prescriptionTableParams &&
                                  _.range(prescriptionTableParams.duration || 0).map(offset => {
                                    return (
                                      <WidgetEditableCell
                                        key={offset}
                                        appointment_id={appointment_id}
                                        field={field}
                                        offset={offset}
                                        drug={drug}
                                        prescriptionTableParams={prescriptionTableParams}
                                        visibilityArea={tableBorders}
                                        drugOrder={drugsIds}
                                      />
                                    );
                                  })}
                              </tr>
                            ))}
                          {isAddNewRow && (
                            <tr>
                              {_.range(
                                (prescriptionTableParams && prescriptionTableParams.duration) || 0
                              ).map(id => {
                                return <PrescriptionTableCell key={id} />;
                              })}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </AutoScroller>
                  )}
                </ScrollContext.Consumer>
              </ScrollDetector>
            </TableContainer>
          </TableWrapper>
          <NewRowButtonContainer>
            <NewRowButton onClick={() => setIsAddNewRow(true)} />
          </NewRowButtonContainer>
        </>
      )}
    </>
  );
};
