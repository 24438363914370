// @flow
import { ButtonWithTooltip } from "../buttons/ButtonWithTooltip";
import * as React from "react";
import infoIcon from "./icons/info.svg";
import infoIconColor from "./icons/info-color.svg";

export const InfoButton = (props: { onClick?: () => mixed }) => (
  <ButtonWithTooltip
    icon={infoIcon}
    iconHover={infoIconColor}
    tooltip="Информация"
    onClick={props.onClick}
  />
);
