import React from "react";
import { observer } from "mobx-react";
import corporateNewsStore from "../stores/corporateNewsStore";
import { CorporateCard } from "./CorporateCard";
import { Box, CardActionArea, CardContent, CardMedia } from "@mui/material";
import {
  CorporateSubTitle,
  CorporateTitle,
  TrimmedText
} from "../styled/CorporateTypography.styled";
import { CorporateLikeCounter } from "./activity/CorporateLikeCounter";
import { CorporateViewCounter } from "./activity/CorporateViewCounter";
import { CorporateMiniCardWrapper } from "../styled/CorporateWrapper.styled";
import { styled } from "@mui/material/styles";
import { breakpoints } from "../styled/common";
import { Link } from "react-router-dom";

const LinkStyled = styled(Link)(
  () =>
    `
      width: 100%;
      height: 100%;
      text-decoration: none;
    `
);
const CardActionAreaStyled = styled(CardActionArea)(
  () =>
    `
      width: 100%;
      height: 100%;
      display: flex;
    `
);
const CardMediaStyled = styled(CardMedia)(
  () =>
    `
      height: 180px;
      ${breakpoints.desktop} {
        height: 160px;
      }
      ${breakpoints.tablet} {
        height: 140px;
      }
    `
);
const CardContentStyled = styled(CardContent)(
  () =>
    `
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px;
      &:last-child {
        padding-bottom: 12px;
      }
      ${breakpoints.tablet} {
        gap: 5px;
      }
    `
);

const CardInfoContainer = styled(Box)(
  ({ direction = "row" }) =>
    `
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      flex-direction: ${direction};
      ${breakpoints.tablet} {
        gap: 5px;
      }
    `
);

const CardActionsContainer = styled(Box)(
  () =>
    `
      display: flex;
      justify-content: space-between;
      padding: 12px;
    `
);

export const CorporateMiniCardNews = observer(
  ({ id, category, title, description, dataOfLikes, dataOfViews, ...props }) => {
    const handleChangeLikeCounter = async () => {
      await corporateNewsStore.changeLikeCounter({ newsId: id, data: dataOfLikes });
    };

    return (
      <CorporateMiniCardWrapper>
        <CorporateCard>
          <CardActionAreaStyled>
            <LinkStyled to={`/corporate-portal/news/${id}`}>
              <CardMediaStyled image={props.src} title={title} />
              <CardContentStyled>
                <CardInfoContainer>
                  <CorporateSubTitle modifiers={["md", "pink"]}>{category.name}</CorporateSubTitle>
                  <CorporateSubTitle modifiers={["md", "gray"]}>
                    {props.date} {props.time}
                  </CorporateSubTitle>
                </CardInfoContainer>
                <CardInfoContainer direction="column">
                  <TrimmedText modifiers="xs">
                    <CorporateTitle modifiers={"sm"}>{title}</CorporateTitle>
                  </TrimmedText>
                  <TrimmedText modifiers="sm">
                    <CorporateSubTitle>{description}</CorporateSubTitle>
                  </TrimmedText>
                </CardInfoContainer>
              </CardContentStyled>
            </LinkStyled>
          </CardActionAreaStyled>
          <CardActionsContainer>
            <CorporateLikeCounter
              value={dataOfLikes.count}
              active={dataOfLikes.isLiked}
              onClick={handleChangeLikeCounter}
            />
            <CorporateViewCounter value={dataOfViews.count} />
          </CardActionsContainer>
        </CorporateCard>
      </CorporateMiniCardWrapper>
    );
  }
);
