// @flow
import * as React from "react";
import { useStore } from "effector-react";
import _ from "lodash";

import { SidebarHeader } from "../sidebar/SidebarHeader";
import { SidebarLayout } from "../sidebar/SidebarLayout";
import { SidebarFade } from "../sidebar/SidebarFade";
import { useSidebarFade } from "../sidebar/useSidebarFade";
import { ShortcutsFieldItem } from "./ShortcutsFieldItem";
import { allShortcuts } from "../../stores/shortcuts/shortcutsShared";
import { closeShortcutsSidebar } from "../../stores/shortcuts/shortcutsSections";
import { openShortcuts } from "../../stores/shortcuts/fieldShortcuts";
import { openShortcuts as openServicesShortcuts } from "../../stores/shortcuts/servicesShortcuts";
import { openShortcuts as openPrescribedServicesShortcuts } from "../../stores/shortcuts/prescribedServicesShortcuts";
import { openShortcuts as openAppointmentServicesShortcuts } from "../../stores/shortcuts/appointmentServicesShortcuts";
import type { TAppointment_id } from "../../domain/entities/Appointment";
import { openShortcuts as openChecklistShortcuts } from "../../stores/shortcuts/checklistShortcuts";

const getShortcutsSectionsNames = (item): string => {
  if (!item.field.parent) return item.field.section.name;
  return [item.field.section.name, item.field.parent.name].join(", ");
};

const getShortcutsSections = (item, appointment_id) => {
  switch (item.destination_type) {
    case "field":
      return (
        <ShortcutsFieldItem
          key={item.field.id}
          fieldName={item.field.name}
          sectionName={getShortcutsSectionsNames(item)}
          onClick={() => openShortcuts(item.field.id)}
        />
      );

    case "services":
      return (
        <ShortcutsFieldItem
          key={"services"}
          fieldName={"Услуги"}
          sectionName={"Услуги"}
          onClick={openServicesShortcuts}
        />
      );
    case "prescribed_services":
      return (
        <ShortcutsFieldItem
          key={"prescribed_services"}
          fieldName={"Направления"}
          sectionName={"Направления"}
          onClick={openPrescribedServicesShortcuts}
        />
      );
    case "appointment_services":
      return (
        <ShortcutsFieldItem
          key={"appointment_services"}
          fieldName={"Оказанные услуги"}
          sectionName={"Оказанные услуги"}
          onClick={openAppointmentServicesShortcuts}
        />
      );
    case "examination_checklist":
      return (
        <ShortcutsFieldItem
          key={"examination_checklist"}
          fieldName={"Обследования"}
          sectionName={"ППР"}
          onClick={() =>
            openChecklistShortcuts({ fieldName: "examination_checklist", appointment_id })
          }
        />
      );
    case "medication_checklist":
      return (
        <ShortcutsFieldItem
          key={"medication_checklist"}
          fieldName={"Медикаментозное лечение"}
          sectionName={"ППР"}
          onClick={() =>
            openChecklistShortcuts({ fieldName: "medication_checklist", appointment_id })
          }
        />
      );
    case "recommendation_checklist":
      return (
        <ShortcutsFieldItem
          key={"recommendation_checklist"}
          fieldName={"Рекомендации"}
          sectionName={"ППР"}
          onClick={() =>
            openChecklistShortcuts({ fieldName: "recommendation_checklist", appointment_id })
          }
        />
      );

    default:
      break;
  }
};

export const ShortcutsForLayout = (props: { title?: string, appointment_id: TAppointment_id }) => {
  const { title, appointment_id } = props;
  const shouldStartTransition = useSidebarFade();
  const fieldsWithShortcutsArray = useStore(allShortcuts);
  const header = <SidebarHeader onClose={closeShortcutsSidebar} title={title || "Заготовки"} />;

  return (
    <SidebarFade>
      {shouldStartTransition && (
        <SidebarLayout header={header}>
          {fieldsWithShortcutsArray &&
            _.map(fieldsWithShortcutsArray, item => {
              return getShortcutsSections(item, appointment_id);
            })}
        </SidebarLayout>
      )}
    </SidebarFade>
  );
};
