import React from "react";
import styled from "styled-components";

import { EmbryosLayout } from "./EmbryosLayout";

import { EmbryosTable } from "./EmbryosTable";
import { ButtonRadioGroup } from "../form-elements/ButtonRadioGroup";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { useEmbryoFilters } from './EmbryosFilters';

const ButtonsWrapper = styled.div`
  width: 100%;
  padding-bottom: 0;
  @media (max-width: 768px) {
    /** Кнопки в разных контейнерах с отрицательным внешним отступом -_- */
    width: calc(100% - 150px);
  }
`;

const ContentContainer = styled(RoundedRectangleWithShadow)`
  position: relative;
  margin-top: 46px;
  box-sizing: border-box;
  width: 100%;
  padding: 30px 32px 30px 32px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  @media (max-width: 768px) {
    padding: 25px 30px;
    margin-top: 30px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
    margin-top: 20px;
  }
`;

const TableContentContainer = styled(ContentContainer)`
  margin-top: 0;
  padding-top: 0;
  border-radius: 0;
  width: 100%;
  padding-top: 0;
  overflow: auto;
  @media (max-width: 768px) {
    margin-top: 0;
    padding-top: 0;
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: ${p => p.mb}px;
  justify-content: ${p => p.jc};
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
  }
`;

export const EmbryosPage = ({ title, tools, header, showTable = true, ...rest }) => {
  const { filters, activeFilterIdx, activeFilter, setActiveFilter } = useEmbryoFilters();

  const correctTableContainerScroll = React.useCallback(() => {
    if (tableContainerRef?.current) {
      tableContainerRef.current.scrollLeft = 0;
    }
  }, []);

  const onActiveFilterChange = React.useCallback(
    data => {
      setActiveFilter(data);
      correctTableContainerScroll();
    },
    [correctTableContainerScroll]
  );

  const tableContainerRef = React.useRef(null);

  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <EmbryosLayout header={header}>
      <ContentContainer>
        {(title || tools) && (
          <Header mb={title ? 32 : -32} jc={title ? "space-between" : "flex-end"}>
            {title}
            {tools}
          </Header>
        )}
        <ButtonsWrapper>
          <ButtonRadioGroup
            items={filters}
            checked={activeFilterIdx}
            shouldUncheck={false}
            onChange={onActiveFilterChange}
          />
        </ButtonsWrapper>
      </ContentContainer>
      {showTable && (
        <TableContentContainer ref={correctTableContainerScroll}>
          <EmbryosTable filter={activeFilter.viewType} {...rest} />
        </TableContentContainer>
      )}
    </EmbryosLayout>
  );
};
