import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ticketStore from "../../stores/ticketStore";

export const VoximplantSupportWebView = observer(() => {
  useEffect(() => {
    ticketStore.resetMobileTicketVoxData();

    const script = document.createElement("script");
    script.innerHTML = `
      window.VoxKitWidgetSettings = JSON.parse(localStorage.getItem('voxKitWidgetSettings') || '{}');

      const l = function () {
        const t = function () {
          "object" == typeof VoxKitWidget && "object" == typeof VoxKitWidgetSettings && VoxKitWidget.init(VoxKitWidgetSettings);
              
          let intervalId;
            
          const openVoximplant = () => {
            const element = document.querySelector('.widget-icon__closed');
            
            if (!element) {
              return;
            }

            element.click();
            window.ReactNativeWebView?.postMessage('ready');
            clearInterval(intervalId);

            setTimeout(() => {
              const closeButtonElement = document.getElementsByClassName("kit-widget-modal__close-btn")[0];
              
              if (closeButtonElement && window.ReactNativeWebView) {
                closeButtonElement.addEventListener('click', () => {
                  window.ReactNativeWebView.postMessage('close');
                })
              }
              
              const chatWrapper = document.querySelector('.kit-widget-modal__messages');
              
              const resetChatWrapperScroll = () => {
                setTimeout(() => {
                  chatWrapper.scrollTo(0, chatWrapper.scrollHeight);
                }, 400);
              };
              
              window.addEventListener('message', (nativeEvent) => {
                if (nativeEvent?.data === 'keyboardOpened' || nativeEvent?.data === 'keyboardClosed') {
                  resetChatWrapperScroll();
                }
              });
            });
          }

          intervalId = setInterval(openVoximplant, 200);
        }
        const e = document.createElement("script");

        e.type = "text/javascript"
        e.async = !0
        e.src = "https://kit.voximplant.com/static/widgets/web-chat.js?" + (new Date).valueOf()
        e.readyState ?
          e.onreadystatechange = function () {
          "loaded" !== e.readyState && "complete" !== e.readyState || (e.onreadystatechange = null, t())
        } : e.onload = function () {
          t();
        };

        const a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(e, a);
      };
      l();
    `;

    const style = document.createElement("style");

    style.innerHTML = `
      .kit-widget-modal__messages {
        overflow-y: scroll !important;
        overflow-x: hidden !important;
      }
      
      .kit-widget-messages__wrapper {
        scroll-behavior: smooth !important;
      }
      
      .kit-widget-gallery-download {
        visibility: hidden !important;
      }
      
      .kit-widget-attach__image-action {
        visibility: hidden !important;
        z-index: -1 !important;
      }
    `;

    document.head.appendChild(script);
    document.head.appendChild(style);
  }, []);

  const [widgetContainer, setWidgetContainer] = useState(null);

  const handleOnClick = async event => {
    if (event.target.nodeName !== "BUTTON" || !ticketStore.mobileTicketVoxData) {
      return;
    }

    const response = await ticketStore.postCreateTicket({
      ...ticketStore.mobileTicketVoxData,
      theme: event?.target?.innerText || ""
    });

    const input = widgetContainer
      .getElementsByClassName("kit-widget-chat-input")[0]
      .querySelector("p");
    const button = widgetContainer.getElementsByClassName("kit-widget-chat-input__send-btn")[0];

    if (!response || !input || !button) {
      return;
    }

    input.textContent = `Мое обращение в техническую поддержку под номером ${response.id}.`;
    setTimeout(() => button.click());
    ticketStore.resetMobileTicketVoxData();
  };

  useEffect(() => {
    if (!ticketStore.mobileTicketVoxData) {
      setWidgetContainer(null);
    } else {
      setWidgetContainer(document.getElementById("KitWidgetContainer"));
    }
  }, [ticketStore.mobileTicketVoxData]);

  useEffect(() => {
    if (!widgetContainer) {
      return;
    }

    widgetContainer.addEventListener("click", handleOnClick);
    return () => widgetContainer.removeEventListener("click", handleOnClick);
  }, [widgetContainer]);

  return null;
});
