import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
let _organization_id = undefined;
class Shortcuts {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  getShortcuts({ layout_id, organization_id }) {
    if (organization_id) {
      // этот хак, чтобы в миллионах мест не пробрасывать эту переменную, пробросил только внутри EditAppointmentPage
      _organization_id = organization_id
    }
    return this.apiService.get(`layouts/${layout_id}/shortcuts`, {organization_id: _organization_id});
  }

  postShortcut(creationOptions) {
    return this.apiService.post(`shortcuts`, {}, creationOptions);
  }

  putShortcut(shortcutId, creationOptions) {
    return this.apiService.patch(`shortcuts/${shortcutId}`, {}, creationOptions);
  }

  postShortcutOrder(shortcutsOrderOptions) {
    return this.apiService.post(`shortcuts/set-order`, {}, shortcutsOrderOptions);
  }

  deleteShortcut(shortcutId) {
    return this.apiService.delete(`shortcuts/${shortcutId}`);
  }
}

export default new Shortcuts();
