import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ArrowImg from "../aside/arrow-long-left.svg";

const Wrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #262626;
  font-size: 22px;

  &:hover {
    color: #262626;
    text-decoration: none;
  }
`;

const BackArrow = styled.div`
  width: 26px;
  height: 26px;
  margin-right: 15px;
  background: url(${ArrowImg}) no-repeat;
  background-size: contain;
`;

export const BackLink = ({ link, children }) => (
  <Wrapper to={link}>
    <BackArrow />
    {children}
  </Wrapper>
);
