// @flow
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const MoveContainer = styled.div`
  margin-left: 5px;
`;

export const GroupActionsLayout = (props: {
  edit?: React$Node,
  remove?: React$Node,
  moveDown?: React$Node,
  moveUp?: React$Node
}) => {
  const { edit, remove, moveDown, moveUp } = props;
  return (
    <Container>
      {edit && edit}
      {remove && remove}
      {moveDown && <MoveContainer>{moveDown}</MoveContainer>}
      {moveUp && <MoveContainer>{moveUp}</MoveContainer>}
    </Container>
  );
};
