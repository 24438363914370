//@flow
import * as React from "react";
import styled from "styled-components";

import { colors } from "../styleguide/colors";

const Row = styled.tr`
  border-bottom: 1px solid ${colors.transparentGray};
`;

export const HistoryTableRow = (props: { children: Array<React.Node> }) => {
  return <Row>{props.children}</Row>;
};
