// @flow
import * as React from "react";

import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {
  ButtonReview,
  DescriptionText, DescriptionTextBold,
  FlexContainer,
  HeaderText,
  LogoWrapper,
  SectionBottom,
  SectionTop, WrapperTexts
} from "./reviewStyled";

import logo from "./assets/logo-step-three.png";
import logoRassvet from "./assets/logo-step-three-rassvet.png";
import YandexLogo from "./assets/yandex-logo.inline.svg";
import TwoGisLogo from "./assets/two-gis-logo.png";
import GoogleMapsLogo from "./assets/google-maps-logo.png";
import AboutDoctorsLogo from "./assets/about-doctors-logo.png";
import YandexMapsLogo from "./assets/yandex-maps-logo.inline.svg";
import reviewStore, { CLINIC_CODE } from "../../stores/reviewStore";
import useReactRouter from "use-react-router";
import { generateSearchParams, setStoreDataSearchParams } from "./ReviewPage";

export const ReviewStepThree = withRouter(observer((props: {||}) => {

  if (!reviewStore.token) {
    const { match: { params } } = useReactRouter();
    setStoreDataSearchParams(reviewStore);
    props.history.push(`/review/${params.token}${generateSearchParams(reviewStore)}`);
    return null;
  }

  reviewStore.setCurrentPage('step-three');
  reviewStore.setProgress();

  const handleClickCompany = (company) => {
    reviewStore.setCompany(company);
    props.history.push(`/review/${reviewStore.token}/step-four${generateSearchParams(reviewStore)}`);
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // Максимум не включается, минимум включается
  }

  const randomOrder = getRandomInt(1, 3);


  return (
    <FlexContainer>
      <SectionTop>
        <LogoWrapper>
          <img src={reviewStore.clinic_code === CLINIC_CODE.rassvet ? logoRassvet : logo} />
        </LogoWrapper>
        <HeaderText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
          Оставьте свой отзыв о нашей работе
        </HeaderText>
        <WrapperTexts>
          <DescriptionText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
            Обычно люди не находят времени оставить отзывы, если им всё понравилось. А вы нашли. Мы это ценим.
          </DescriptionText>
          <DescriptionText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
            В благодарность за ваше время мы пополним вашу программу лояльности бонусными баллами в размере 500 рублей за оставленный <DescriptionTextBold modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}> текстовый отзыв на сайте:</DescriptionTextBold>
          </DescriptionText>
        </WrapperTexts>
      </SectionTop>
      <SectionBottom>
        <ButtonReview modifiers={['backgroundYellow']} onClick={() => handleClickCompany('yandex')}>
          <YandexMapsLogo />
          <YandexLogo />
        </ButtonReview>
        {randomOrder === 1 ? (
          <>
            <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickCompany('aboutDoctorsLink')}>
              <img src={AboutDoctorsLogo} />
            </ButtonReview>
            <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickCompany('google')}>
              <img src={GoogleMapsLogo} />
            </ButtonReview>
            <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickCompany('twogis')}>
              <img src={TwoGisLogo} />
            </ButtonReview>
          </>
        ) : (
          <>
            <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickCompany('google')}>
              <img src={GoogleMapsLogo} />
            </ButtonReview>
            <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickCompany('twogis')}>
              <img src={TwoGisLogo} />
            </ButtonReview>
            <ButtonReview modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]} onClick={() => handleClickCompany('aboutDoctorsLink')}>
              <img src={AboutDoctorsLogo} />
            </ButtonReview>
          </>
        )}

      </SectionBottom>
    </FlexContainer>
  );
}));
