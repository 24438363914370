// @flow

export const isOverflowVisibilityArea = (
  position: ClientRect,
  visibilityArea: ClientRect
): boolean => {
  return (
    position.left - visibilityArea.left < 0 ||
    visibilityArea.right - position.right < 0 ||
    position.top - visibilityArea.top < 0 ||
    visibilityArea.bottom - position.bottom < 0
  );
};
