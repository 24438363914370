// @flow
import * as React from "react";
import mkbIcon from "./icons/mkb-01.svg";
import mkbIconHover from "./icons/mkb-01-hover.svg";
import { ButtonWithTooltip } from "../buttons/ButtonWithTooltip";

export const OpenTreeButton = (props: { onClick?: () => mixed }) => (
  <ButtonWithTooltip
    icon={mkbIcon}
    iconHover={mkbIconHover}
    tooltip="МКБ"
    onClick={props.onClick}
  />
);
