import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { REPORTS } from "./constants";
import { ReportsPageContainer, ReportsTabContent } from "./Reports.styled";
import organizationsStore from "../../stores/organizationsStore";

export const ReportsPage = () => {
  const [value, setValue] = useState(REPORTS[0].name);
  const handleChange = (event, newValue) => setValue(newValue);

  useEffect(() => {
    (async () => {
      await organizationsStore.getOrganizations();
    })();
  }, []);

  return (
    <ReportsPageContainer>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        {REPORTS.map(report => (
          <Tab key={report.name} label={report.label} value={report.name} />
        ))}
      </Tabs>
      {REPORTS.map(report => (
        <ReportsTabContent
          key={report.name}
          style={{ display: report.name === value ? "" : "none" }}>
          {report.render({ ...report, localization: { min: "От", max: "До" } })}
        </ReportsTabContent>
      ))}
    </ReportsPageContainer>
  );
};
