import React from "react";

import * as S from "./styled/ReadableServicesList.styled";
import {teethIDsToString, teethValueToArray} from '../teeth-picker/TeethPicker';

export const ReadableServicesList = ({ services }) => (
  <S.List>
    {services.map(({ id, name, kdf_code,  count, comment, teeth }) => (
      <S.Item key={id}>
        {/* ToDo не знаю, нужно ли выводить этот код, возможно, нужно согласование с МедДепом, показывать ли пациенту, показывать ли врачу*/}
        {/*{kdf_code?.trim() ? `${kdf_code.trim()} - ` : ''}*/}
        {name} - {count ? `${count} шт` : ''}
        {(teeth || comment) && <br/>}
        {teeth && (
          <>
            <S.Comment>Зубы: {teethIDsToString(teethValueToArray(teeth), true)}</S.Comment>
            {comment && <br/>}
          </>
        )}
        {comment && <S.Comment>Комментарий: {comment}</S.Comment>}
      </S.Item>
    ))}
  </S.List>
);
