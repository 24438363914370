// @flow
import type { Prescription } from "../../domain/value-objects/Prescription";
import { useTrigger } from "react-use-trigger";
import { useEffect, useRef, useState } from "react";
import { usePosition } from "../../utils/usePosition";
import { PrescriptionTableCell } from "./PrescriptionTableCell";
import { AutoFocusWrapper } from "../../utils/AutoFocusWrapper";
import { DosageInput } from "./DosageInput";
import { PSmall } from "../styleguide/typography";
import * as React from "react";
import styled from "styled-components";
import type { TriggerWrapper } from "react-use-trigger/dist/TriggerWrapper";
import { useGate, useStore } from "effector-react";
import {
  setFocused,
  clearFocus,
  type CellCoordinates,
  incrementColumn,
  decrementColumn,
  incrementDrugId,
  decrementDrugId,
  isFocusedStore,
  isRightOfFocusStore,
  rightOfFocusWidthGate,
  isLeftOfFocusStore,
  leftOfFocusWidthGate,
  focusedPositionGate,
  visibilityAreaGate
} from "./prescriptionFocusedCellStore";
import type { TDrugId } from "../../domain/entities/Drug";

const DosageInputContainer = styled.div`
  position: fixed;
  margin-top: -1px;
  margin-left: -1px;
  z-index: 10;
`;

const PositionWrapper = props => {
  const { children, tableScrollTrigger, cellRef, visibilityArea } = props;
  const tableScrollTriggerValue = useTrigger(tableScrollTrigger);
  const [position, updatePosition] = usePosition(cellRef);

  useGate(focusedPositionGate, (position: any));
  useGate(visibilityAreaGate, (visibilityArea: any));

  useEffect(() => {
    updatePosition();
  }, [tableScrollTriggerValue]);

  return children(position);
};

const RightOnFocusPositionWrapper = props => {
  const { cellRef } = props;
  const [position] = usePosition(cellRef);

  useGate(rightOfFocusWidthGate, position && position.width);

  return null;
};

const LeftOnFocusPositionWrapper = props => {
  const { cellRef } = props;
  const [position] = usePosition(cellRef);

  useGate(leftOfFocusWidthGate, position && position.width);

  return null;
};

export const EditableCell = (props: {
  currentPrescriptionValue: ?Prescription,
  onSave: string => mixed,
  coordinates: CellCoordinates,
  drugOrder: Array<TDrugId>,
  duration: number,
  tableScrollTrigger: TriggerWrapper, //todo remove
  visibilityArea: ?ClientRect // todo remove
}) => {
  const {
    tableScrollTrigger,
    currentPrescriptionValue,
    visibilityArea,
    onSave,
    coordinates,
    drugOrder,
    duration
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const isFocused = useStore(isFocusedStore(coordinates));
  const isRightOfFocus = useStore(isRightOfFocusStore(coordinates, duration));
  const isLeftOfFocus = useStore(isLeftOfFocusStore(coordinates));
  const [tempValue, setTempValue] = useState(
    (currentPrescriptionValue && currentPrescriptionValue.value) || ""
  );
  const cellRef = useRef(null);
  const dosageInputRef = useRef(null);

  useEffect(() => {
    if (isEdit && !isFocused) {
      onSave(tempValue);
    }
    setIsEdit(isFocused);
  }, [isEdit, isFocused]);

  const onClick = () => {
    setFocused(coordinates);
  };

  const onKeyDown = (keyCode: number) => {
    if (keyCode === 37) {
      if (dosageInputRef.current && dosageInputRef.current.selectionStart !== 0) return;
      decrementColumn();
    }
    if (keyCode === 38) {
      decrementDrugId({ drugOrder });
    }
    if (keyCode === 39) {
      if (
        dosageInputRef.current &&
        dosageInputRef.current.selectionStart !== dosageInputRef.current.value.length
      )
        return;
      incrementColumn(duration);
    }
    if (keyCode === 40) {
      incrementDrugId({ drugOrder });
    }
  };

  useEffect(() => {
    setTempValue((currentPrescriptionValue && currentPrescriptionValue.value) || "");
  }, [currentPrescriptionValue]);

  return (
    <>
      {isRightOfFocus && <RightOnFocusPositionWrapper cellRef={cellRef} />}
      {isLeftOfFocus && <LeftOnFocusPositionWrapper cellRef={cellRef} />}
      <PrescriptionTableCell onClick={onClick} ref={cellRef} onKeyDown={e => onKeyDown(e.keyCode)}>
        {isEdit && (
          <PositionWrapper
            cellRef={cellRef}
            tableScrollTrigger={tableScrollTrigger}
            visibilityArea={visibilityArea}>
            {position => (
              <DosageInputContainer
                style={{
                  left: `${(position && position.left) || 0}px`,
                  top: `${(position && position.top) || 0}px`
                }}>
                <AutoFocusWrapper elementRef={dosageInputRef}>
                  {ref => (
                    <DosageInput
                      value={tempValue}
                      onChange={setTempValue}
                      onBlur={clearFocus}
                      inputRef={ref}
                    />
                  )}
                </AutoFocusWrapper>
              </DosageInputContainer>
            )}
          </PositionWrapper>
        )}
        {!isEdit && <PSmall modifiers="center">{tempValue}</PSmall>}
      </PrescriptionTableCell>
    </>
  );
};
