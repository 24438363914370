import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { Asterisk } from "../styled/Asterisk.styled";

export const FormInputRadio = (props) => {
  const newProps = {...props};
  delete newProps.className;

  const generateRadioOptions = () => {
    return props.options.map((singleOption) => (
      <FormControlLabel
        className={'form-input-radio__label'}
        key={singleOption.value}
        value={singleOption.value}
        label={singleOption.label}
        control={<Radio size="small" />}
      />
    ));
  };

  return (
    <FormControl fullWidth className={'dynamic-form-input-control'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
      {props.label && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { onChange, value }}) => {
          return (
            <RadioGroup
              {...newProps}
              className={`form-input-radio ${props.className}`}
              value={value || null}
              onChange={onChange}
            >
              {generateRadioOptions()}
            </RadioGroup>
        )}}
      />
    </FormControl>
  );
};
