// @flow
import * as React from "react";

import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {
  ButtonReview,
  DescriptionText,
  FlexContainer,
  HeaderText,
  LogoWrapper,
  SectionBottom,
  SectionTop
} from "./reviewStyled";

import logo from "./assets/logo-step-six.png";
import logoRassvet from "./assets/logo-step-six-rassvet.png";
import reviewStore, { CLINIC_CODE } from "../../stores/reviewStore";
import useReactRouter from "use-react-router";
import { generateSearchParams, setStoreDataSearchParams } from "./ReviewPage";
import { ClinicLogo } from "../clinicLogo/ClinicLogo";

export const ReviewStepSixWithCall = withRouter(observer((props: {||}) => {

  if (!reviewStore.token) {
    const { match: { params } } = useReactRouter();
    setStoreDataSearchParams(reviewStore);
    props.history.push(`/review/${params.token}${generateSearchParams(reviewStore)}`);
    return null;
  }

  reviewStore.setCurrentPage('step-five-with-call');
  reviewStore.setProgress();

  const handleClick = async () => {
    window.close();
  };

  return (
    <FlexContainer>
      <SectionTop>
        <LogoWrapper>
          <ClinicLogo name={reviewStore.clinic_code} isShowName={false} size={{width:"79px", height:"79px"}} />
        </LogoWrapper>
        <HeaderText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
          Перезвоним, разберёмся и предложим решение проблемы
        </HeaderText>
        <DescriptionText modifiers={[reviewStore.clinic_code !== CLINIC_CODE.fomina && reviewStore.clinic_code]}>
          Спасибо за отзыв, в ближайшее время мы свяжемся с вами.
        </DescriptionText>
      </SectionTop>
      <SectionBottom>
      </SectionBottom>
    </FlexContainer>
  );
}));
