import React from "react";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import {
  appointmentMoveModalStore,
  closeModal
} from "../../stores/modals/appointmentMoveModalStore";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import { Modal } from "../modal/Modal";
import { ModalPortal } from "../modal/ModalPortal";
import { AppointmentMoveForm } from "./AppointmentMoveForm";
import apiv2 from '../../apiv2';

export const AppointmentMoveModal = () => {
  const token = useStore(tokenStore);

  const { opened, appointmentId, appointmentLayoutId, patientId } = useStore(
    appointmentMoveModalStore
  );

  const handleSubmit = async data => {
    await apiv2.appointments.moveAppointmentFolder({
      appointmentId,
      folderTypeId: data.selectedFolderType.id
    });
    closeModal();
    PatientAppointmentsRefreshTrigger();
  };

  const form = (
    <AppointmentMoveForm
      patientId={patientId}
      afterSubmit={handleSubmit}
      layoutId={appointmentLayoutId}
    />
  );

  return (
    <ModalPortal>
      <Modal
        content={form}
        isShown={opened}
        hide={closeModal}
        title="Перенести прием в новое событие"
      />
    </ModalPortal>
  );
};
