// @flow
import * as React from "react";
import styled from "styled-components";
import { AppointmentFormLayout } from "../appointment-page/AppointmentFormLayout";

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
`;

const MetaInfoContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 50px;
  display: flex;
  align-items: flex-end;
`;

const SectionsContainer = styled.div`
  display: flex;
  margin-top: 28px;
`;

export const SummaryFormLayout = (props: {
  title: React.Node,
  metaInfo: React.Node,
  toolButtons: React.Node,
  sections: React.Node,
  analyzes?: React.Node,
  onlineSection?: React.Node,
  buttonForm?: React.Node,
  notNeedAddMinHeight: boolean,
}) => {
  return (
    <AppointmentFormLayout
      {...props}
      overrides={{
        ContentContainer: {
          component: ContentContainer
        },
        MetaInfoContainer: {
          component: MetaInfoContainer
        },
        SectionsContainer: {
          component: SectionsContainer
        }
      }}
    />
  );
};
