// @flow
import React from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import popularLayoutsStore from "../../stores/popularLayoutsStore";
import {
  Favorite as DeleteFavoritesAppointmentIcon,
  FavoriteBorderOutlined as AddFavoritesAppointmentIcon,
  Star as DeletePopularLayoutIcon,
  StarOutline as AddPopularLayoutIcon
} from "@mui/icons-material";
import favoritesAppointmentStore from "../../stores/favoritesAppointmentStore";
import { DropDownMenu } from "../drop-down-menu/DropDownMenu";

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "10px"
}));

export const AppointmentTitle = observer(({ title, layoutId, appointmentId }) => {
  const isPopularLayout =
    popularLayoutsStore.isInitialized && popularLayoutsStore.isPopularLayout(layoutId);
  const isFavoriteAppointment =
    favoritesAppointmentStore.isInitialized &&
    favoritesAppointmentStore.isFavoriteAppointmentById(appointmentId);

  const menuItems = [
    {
      name: isPopularLayout ? "Убрать из популярных шаблонов" : "Добавить в популярные шаблоны",
      icon: isPopularLayout ? (
        <DeletePopularLayoutIcon color="primary" />
      ) : (
        <AddPopularLayoutIcon color="primary" />
      ),
      highlighted: `${isPopularLayout}`,
      handleOnClick: () => {
        if (isPopularLayout) {
          popularLayoutsStore.deletePopularLayout(layoutId);
        } else {
          popularLayoutsStore.addPopularLayout(layoutId);
        }
      }
    },
    {
      name: isFavoriteAppointment ? "Убрать из избранных приёмов" : "Добавить в избранные приёмы",
      icon: isFavoriteAppointment ? (
        <DeleteFavoritesAppointmentIcon color="primary" />
      ) : (
        <AddFavoritesAppointmentIcon color="primary" />
      ),
      highlighted: `${isFavoriteAppointment}`,
      handleOnClick: () => {
        if (isFavoriteAppointment) {
          favoritesAppointmentStore.deleteFavoriteAppointment(appointmentId);
        } else {
          favoritesAppointmentStore.addFavoriteAppointment(appointmentId);
        }
      }
    }
  ];

  return (
    <Container>
      <DropDownMenu
        menuItems={menuItems}
        view="item"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        backlight={isPopularLayout || isFavoriteAppointment ? "active" : "inactive"}
      />
      <Typography variant="h4">{title}</Typography>
    </Container>
  );
});
