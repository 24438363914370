import React, { useEffect } from "react";
import styled from "styled-components";
import { keyBy } from "lodash";
import { useStore } from "effector-react";
import Spreadsheet from "@blessmesanta/react-spreadsheet/dist/index";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { SelectEdit, SelectView } from "../embryos-table/EmbryosTableSelect";
import { tableSelectColors, colors } from "../styleguide/colors";
import { Row } from "../embryos-table/EmbryosTableRow";
import { Cell } from "../embryos-table/EmbryosTableCell";
import { ColumnIndicator } from "../embryos-table/EmbryosTableColumnIndicator";
import { CalendarTextEdit } from "../embryos-table/EmbryosCalendarEdit";
import { WideTextEdit } from "../embryos-table/EmbryosTableTextEdit";
import { constructTableRowPayload, formatServerDate } from "../embryos-table/utils";
import { EmbryosTableContext } from "../embryos-table/EmbryosTableContext";
import { tokenStore } from "../../stores/auth";
import apiv2 from '../../apiv2';

const renderedColumns = ["Название", "Дата забора", "Годен, дней", "Дата истечения", "Статус"];

const ExtraWideTextView = props => {
  return <>{props.cell.value}</>;
};

const statusViewOptions = [
  { value: "Не требуется", label: "Не требуется", color: tableSelectColors.gray },
  { value: "Годен", label: "Годен", color: tableSelectColors.green },
  { value: "Не сдан", label: "Не сдан", color: tableSelectColors.red },
  { value: "Не годен", label: "Истек", color: tableSelectColors.yellow }
];

const statusEditOptions = [
  { value: "not_required", label: "Не требуется", color: tableSelectColors.gray }
];

export const StatusEditSelectComponent = props => (
  <SelectEdit {...props} options={statusEditOptions} />
);
export const StatusViewSelectComponent = props => (
  <SelectView {...props} options={statusViewOptions} />
);

const Container = styled(RoundedRectangleWithShadow)`
  overflow: auto;
  padding: 32px;
  padding-top: 20px;
  @media (max-width: 768px) {
    padding: 25px 30px;
  }
  @media (max-width: 480px) {
    padding: 15px 20px;
  }

  .sc-ivs-analyses-table {
    table {
      tr:first-child {
        th {
          border-top: 1px solid rgb(68, 68, 68, 0.1);
          border-bottom: 1px solid ${colors.magenta};
        }
      }

      td {
        padding: 12px;
        vertical-align: middle;
        width: auto;
        white-space: unset;
        word-break: unset;
        min-width: unset;
        max-width: unset;
        height: unset;
      }

      td:first-child {
        width: 100%;
        line-height: 1.2;
      }

      td:last-child {
        min-width: 132px;
      }
    }
  }
`;

const CalendarWithDisabledInput = props => <CalendarTextEdit {...props} inputDisabled />;

const getTableData = (data, readOnly = false) => {
  return data.map((field, i) => [
    {
      value: field.name,
      field: "name",
      DataViewer: ExtraWideTextView,
      DataEditor: WideTextEdit,
      entityId: field.id,
      readOnly: true,
      removable: false,
      index: [i, 0]
    },
    {
      value: formatServerDate(field.date),
      field: "date",
      DataEditor: CalendarWithDisabledInput,
      isShort: true,
      entityId: field.id,
      readOnly,
      removable: true,
      index: [i, 1]
    },
    {
      value: field.shelf_life === 0 ? "Однократно" : field.shelf_life,
      field: "shelf_life",
      DataEditor: WideTextEdit,
      isInteger: true,
      isShort: true,
      entityId: field.id,
      readOnly: true,
      removable: false,
      index: [i, 2]
    },
    {
      value: formatServerDate(field.last_day_valid || null),
      field: "last_day_valid",
      DataEditor: CalendarTextEdit,
      isShort: true,
      entityId: field.id,
      readOnly: true,
      removable: false,
      index: [i, 3]
    },
    {
      value: field.status,
      field: "status",
      DataEditor: StatusEditSelectComponent,
      DataViewer: StatusViewSelectComponent,
      isShort: true,
      entityId: field.id,
      readOnly: readOnly || field.status === "Не требуется",
      removable: false,
      index: [i, 4]
    }
  ]);
};

export const IvrAnalysesTable = ({ data, folderId, readOnly, setSyncing }) => {
  const token = useStore(tokenStore);

  const [state, setState] = React.useState([]);
  const tableDataById = React.useRef({});

  const onCellUpdate = async (row, cell) => {
    try {
      setSyncing(true);
      const tableData = constructTableRowPayload(state[row]);
      const originalTableData = tableDataById.current[tableData.id];
      const payload = {
        ...originalTableData,
        ...tableData,
        [cell.field]: cell.value
      };

      const responseData = await apiv2.appointments.updateFolderAnalyze(folderId, payload.id, { ...payload, not_required: payload.status === "not_required" });
      const keys = Object.keys(responseData);

      const replacedKeys = keys.filter(key => payload[key] !== responseData[key]);
      if (replacedKeys.length) {
        setState(state =>
          state.map((stateRow, index) => {
            if (index === parseInt(row, 10)) {
              const nextTableData = getTableData([responseData], readOnly)[0];
              tableDataById.current[tableData.id];
              return nextTableData;
            }
            return stateRow;
          })
        );
      }
    } finally {
      setSyncing(false);
    }
  };

  const onPaste = async cells => {
    try {
      setSyncing(true);
      const rowsToUpdate = cells.reduce((res, curr) => {
        if (!res[curr.row]) {
          res[curr.row] = [];
        }
        res[curr.row].push({ field: state[curr.row][curr.column].field, value: curr.value?.value });
        return res;
      }, {});

      const payloadPairs = Object.keys(rowsToUpdate).map(key => {
        const cells = rowsToUpdate[key];
        const tableData = constructTableRowPayload(state[key]);
        const originalTableData = tableDataById.current[tableData.id];
        const payload = {
          ...originalTableData,
          ...tableData
        };
        cells.forEach(cell => (payload[cell.field] = cell.value));
        return [payload, key];
      });

      for (const [payload, row] of payloadPairs) {
        const responseData = await apiv2.appointments.updateFolderAnalyze(folderId, payload.id, payload);
        const keys = Object.keys(responseData);

        if (!keys.length) {
          return setState(state => state.filter((stateRow, index) => index !== parseInt(row, 10)));
        }

        const replacedKeys = keys.filter(key => payload[key] !== responseData[key]);
        if (replacedKeys.length) {
          setState(state =>
            state.map((stateRow, index) =>
              index === parseInt(row, 10) ? getTableData([responseData], readOnly)[0] : stateRow
            )
          );
        }
      }
    } finally {
      setSyncing(false);
    }
  };

  const onClear = async ([{ row, column }]) => {
    const cell = state[row][column];
    // const newState = state.map(row => row.map(({ index, ...rest }) => (index[0] === row && index[1] === column) ? ({ ...rest, }) ));

    if (cell.removable) {
      setSyncing(true);

      const result = await apiv2.appointments.updateFolderAnalyze(folderId, cell.entityId, {
        ...tableDataById.current[cell.entityId],
        [cell.field]: null
      });

      setState(prev => prev.map((item, i) => (i === row ? getTableData([result])[0] : item)));

      setSyncing(false);
    }
  };

  useEffect(() => {
    if (data) {
      tableDataById.current = keyBy(data, "id");
      setState(getTableData(data), readOnly);
    }
  }, [data, readOnly]);

  return (
    <Container>
      <EmbryosTableContext.Provider
        value={{ onCellUpdate, onRowDelete: null, onRowDuplicate: null }}>
        <Spreadsheet
          Row={Row}
          data={state}
          Cell={Cell}
          onPaste={onPaste}
          // onChange={setState}
          onClear={onClear}
          hideRowIndicators={true}
          autoPadRowsOnPaste={false}
          columnLabels={renderedColumns}
          ColumnIndicator={ColumnIndicator}
          className="Spreadsheet-full table-100 sc-ivs-analyses-table"
        />
      </EmbryosTableContext.Provider>
    </Container>
  );
};
