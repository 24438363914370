import { useEffect, useState } from "react";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import { useApiRequest } from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

export const useGetFolders = ({ triggerFetch = false, layoutId, currentFolderId, patientId }) => {
  const token = useStore(tokenStore);
  const [folders, setFolders] = useState([]);
  const { data: apiResult } = useApiRequest(async () => {
    if (triggerFetch && !folders.length) {
      return await apiv2.appointments.getFolders( { layoutId: layoutId || "", patientId: patientId || "" });
    }
  }, [token, layoutId, triggerFetch && !folders.length, patientId]);

  useEffect(() => {
    if (apiResult && !folders.length) {
      setFolders(apiResult.folders.filter(f => f.id !== currentFolderId));
    }
  }, [apiResult, currentFolderId, folders.length]);

  return { folders };
};
