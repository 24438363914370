// @flow

import type { Prescription, PrescriptionTableParams } from "../value-objects/Prescription";
import moment from "moment";
import type { Drug } from "../entities/Drug";
import { getDrugId } from "../entities/Drug";
import * as R from "ramda";
import type { FieldPrescription } from "../entities/Field";
import type { Section } from "../entities/Section";

export const filterPrescriptions = (value: Array<string | Prescription>): Array<Prescription> => {
  const result = [];
  value.forEach(item => {
    if (typeof item !== "string") {
      result.push(item);
    }
  });
  return result;
};

const getTopBottom = (startDate, cycleDay) => offset => {
  const top =
    (startDate &&
      moment(startDate)
        .add(offset, "d")
        .format("DD/MM")) ||
    "";
  const bottom = cycleDay || cycleDay === 0 ? String(cycleDay + offset) : "";
  return { top, bottom };
};

export const prescriptionTableParamsToRange = (
  params: PrescriptionTableParams
): Array<{ top: string, bottom: string }> => {
  const { startDate, cycleDay, duration } = params;
  return R.map(getTopBottom(startDate, cycleDay), R.range(0, duration));
};

type TPrescriptionFind = (Array<Prescription>) => ?Prescription;

const filterNotNilField = field =>
  R.filter(
    R.compose(
      R.not,
      R.isNil,
      R.prop(field)
    )
  );

const getProp = (field, defaultValue) =>
  R.compose(
    R.prop(field),
    R.defaultTo(defaultValue)
  );

const findExtremum = extremum => R.reduce(extremum, undefined);

export const getMinDate: TPrescriptionFind = R.compose(
  findExtremum(R.minBy(getProp("date", { date: "9999-99-99" }))),
  filterNotNilField("date")
);

export const getMinCycleDay: TPrescriptionFind = R.compose(
  findExtremum(R.minBy(getProp("cycle_day", { cycle_day: Number.MAX_VALUE }))),
  filterNotNilField("cycle_day")
);

export const getMaxOrder: TPrescriptionFind = findExtremum(
  R.maxBy(getProp("table_column", { table_column: -1 }))
);
export const getMinOrder: TPrescriptionFind = findExtremum(
  R.minBy(getProp("table_column", { table_column: 99999 }))
);

export const valueArrayToRange = (
  value: Array<Prescription>,
  startOffset: number = 0,
  rangeLength?: number
): Array<{ top: string, bottom: string }> => {
  const minDate = getMinDate(value);
  const minCycleDay = getMinCycleDay(value);

  const minOrder = getMinOrder(value);
  const maxOrder = getMaxOrder(value);

  const startColumn = (minOrder && minOrder.table_column) || 0;
  const finishColumn = (maxOrder && maxOrder.table_column) || 0;

  const start = startOffset - startColumn;

  return R.map(
    getTopBottom(minDate && minDate.date, minCycleDay && minCycleDay.cycle_day),
    R.range(start, !rangeLength ? finishColumn + 1 - startColumn || 0 : start + rangeLength)
  );
};

type TPrescriptionPredicate = Prescription => boolean;

export const whereTableColumnAndDrugId = (
  table_column: number,
  drug_id: number
): TPrescriptionPredicate =>
  R.where({
    table_column: R.equals(table_column),
    drug_id: R.equals(drug_id)
  });

export const offsetDate = (date: string, offset: number) => {
  return moment(date)
    .add(offset, "d")
    .format("YYYY-MM-DD");
};

export const createPrescriptionFromTableParamsAndDrug = (options: {
  drug: Drug,
  offset: number,
  prescriptionTableParams: PrescriptionTableParams,
  value: string
}): Prescription => {
  const { drug, offset, prescriptionTableParams, value } = options;

  return {
    date:
      (prescriptionTableParams.startDate &&
        offsetDate(prescriptionTableParams.startDate, offset)) ||
      null,
    cycle_day:
      ((prescriptionTableParams.cycleDay || prescriptionTableParams.cycleDay === 0) &&
        prescriptionTableParams.cycleDay + offset) ||
      null,
    drug: drug.name,
    drug_id: getDrugId(drug),
    value,
    numeric_value: parseFloat(value),
    table_column: offset
  };
};

const isFieldTypePrescription = R.where({
  field_type: R.equals("prescription")
});

export const getPrescriptionFromSection: (section: Section) => ?FieldPrescription = (R.memoizeWith(
  R.prop("id"),
  R.compose(
    R.find(isFieldTypePrescription),
    R.prop("fields")
  )
): any);

export const prescriptionToDrug = (prescription: Prescription): Drug => {
  return {
    id: prescription.drug_id,
    name: prescription.drug
  };
};
