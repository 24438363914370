// @flow
import * as React from "react";

import {AppointmentPage} from "../appointment-page/AppointmentPage";
import {Container} from "./checkupsStyled"


export const CheckupsPageAppointments = () => {
  return (
    <Container>
      <AppointmentPage />
    </Container>
  );
};
