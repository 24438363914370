import * as React from "react";
import { Link } from "react-router-dom";
import { FeedbackProvider } from "../feedback/FeedbackProvider";
import { AsideAppointment } from "./AsideAppointment";
import { OpenedAppointmentProvider } from "./OpenedAppointmentProvider";
import { isInProgress } from "../../domain/services/appointments";

import {
  isFilledFeedbackWithoutResponse,
  isNotFutureFeedback
} from "../../domain/services/feedback";

export const AsideAppointmentPatient = ({ appointment, inFolder = false, folder = null }) => {
  return (
    <OpenedAppointmentProvider appointment={appointment}>
      {(opened, selectedFolder) => (
        <FeedbackProvider id={appointment.feedback}>
          {feedback => {
            const showFeedbackIcon =
              !isInProgress(appointment) &&
              appointment.feedback &&
              feedback &&
              isNotFutureFeedback(feedback) &&
              !isFilledFeedbackWithoutResponse(feedback);

            return (
              <AsideAppointment
                overrides={{
                  Container: {
                    props: {
                      as: Link,
                      to: {
                        pathname: `/appointment/${appointment.id}`,
                        state: { inFolder, showFeedbackIcon }
                      }
                    }
                  }
                }}
                opened={opened}
                selectedFolder={selectedFolder}
                feedback={feedback}
                inFolder={inFolder}
                folder={appointment?.folder || folder}
                appointment={appointment}
                showFeedbackIcon={showFeedbackIcon}
              />
            );
          }}
        </FeedbackProvider>
      )}
    </OpenedAppointmentProvider>
  );
};
