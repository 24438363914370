// @flow
import { useEffect, useMemo } from "react";
import { useStore } from "effector-react";
import { appointmentFieldValuesStore, setNextVisitDay } from "../../stores/appointmentFieldValues";
import type { TAppointment_id } from "../../domain/entities/Appointment";
import { READABLE_DATE_FORMAT } from "../../domain/services/field";
import moment from "moment";
import { createEvent, forward } from "effector";

export const useNextVisitDate = (appointment_id: TAppointment_id, initialValue?: string) => {
  const valueStore = useMemo(
    () =>
      appointmentFieldValuesStore.map(store =>
        store[appointment_id] && store[appointment_id].next_visit_day
          ? store[appointment_id].next_visit_day
          : initialValue
          ? moment(initialValue).format(READABLE_DATE_FORMAT)
          : ""
      ),
    [appointment_id]
  );
  const value = useStore(valueStore);

  const onChange = useMemo(() => createEvent<string>(), [appointment_id]);

  useEffect(() => {
    forward({
      from: onChange.map(payload => {
        return { appointment_id, next_visit_day: payload };
      }),
      to: setNextVisitDay
    });
  }, [appointment_id]);

  return [value, onChange];
};
