import React, { useContext, useMemo } from "react";
import useReactRouter from "use-react-router";
import { useStore } from "effector-react";
import styled from "styled-components";
import { get, groupBy, keyBy } from "lodash";
import useFetch from "react-fetch-hook";
import { useTrigger } from "react-use-trigger";
import { useDebouncedCallback } from "use-debounce";

import { TextWidget } from "./TextWidget";
import { CheckboxGroupWidget } from "./CheckboxGroupWidget";
import { TextareaWidget } from "./TextareaWidget";
import { NumberWidget } from "./NumberWidget";
import { RadioGroupWidget } from "./RadioGroupWidget";
import { FileWidget } from "./FileWidget";
import { DateWidget } from "./DateWidget";
import { BodyMassIndexWidget } from "./BodyMassIndexWidget";
import { PartnerWidget } from "./PartnerWidget/PartnerWidget";
import { HeadingH2 } from "../styleguide/typography";
import { isFieldWithoutLabel, isLabelNeededAtGroup } from "../../domain/services/field";
import { FieldGroupItemFieldSet } from "../form/FieldGroupItemFieldSet";
import { FormSection } from "../form/FormSection";
import { Fieldset, Legend } from "../form/Fieldset";
import { tokenStore } from "../../stores/auth";
import { PatientQuestionnaireRefreshTrigger } from "../appointment-switch-page/PatientQuestionnaireRefreshTrigger";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { FolderPageContext } from "../folder-page/FolderPageContext";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import {useApiRequest} from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';
import {observer} from 'mobx-react';
import questionnaireStore from '../../stores/QuestionnaireStore';

const SectionsContainer = styled.div`
  & + * {
    margin-top: 27px;
  }
`;

const ContentContainer = styled.div`
  padding: 15px 100px 100px;
  max-width: 1224px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: auto;
    padding: 40px 40px 80px;
  }
  @media (max-width: 480px) {
    padding: 15px 15px 50px;
  }
`;

export const FieldsetContainer = styled.div`
  & + & {
    margin-top: 15px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 62px);
`;

const Footer = styled(ContentContainer)`
  padding: 50px 100px;
  background: white;
  max-width: unset;
  @media (max-width: 768px) {
    padding: 50px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
`;

const FieldsetGroupItemContainer = styled.div`
  margin-bottom: 12px;
`;

const FieldsetLegendCheckbox = styled(Legend)`
  top: 6px;
`;

const FieldsetLegendGroup = styled(Legend)`
  top: 0;
`;

export const QuestionnairePage = () => {
  const { folder } = useContext(FolderPageContext);
  const token = useStore(tokenStore);

  const questionnaireTrigger = useTrigger(PatientQuestionnaireRefreshTrigger);

  const questionnaireResult = useApiRequest(async () => {
    return await apiv2.questionnaire.getQuestionnaire(folder.questionnaire.id);
  }, [questionnaireTrigger, token, folder?.questionnaire]);

  if (!questionnaireResult.data) {
    return null;
  }

  return (
    <Questionnaire
      questionnaire={questionnaireResult.data}
      id={folder?.questionnaire?.id}
      folderId={folder?.id}
    />
  );
};

const Questionnaire = observer(({ questionnaire, id, folderId }) => {
  const token = useStore(tokenStore);
  const { history } = useReactRouter();

  const [fields, setFields] = React.useState([]);

  useMemo(() => {
    setFields(keyBy(questionnaire.values.map(val => ({ ...val, ...val.qfield, valueId: val.id })), "id"));
  }, [questionnaire]);

  const state = React.useMemo(() => {
    const fieldValues = groupBy(Object.values(fields), "section_id");

    Object.entries(fieldValues).forEach(([sectionId, fields]) => {
      const groupIndicesMap = {};

      const sortedFields = fields.sort((x, y) => x.position - y.position);

      sortedFields.forEach((field, fieldIndex) => {
        if (!field.group_name) return;

        if (!get(groupIndicesMap, field.group_name, false)) {
          groupIndicesMap[field.group_name] = fieldIndex;
        }

        const currentIndex = groupIndicesMap[field.group_name];

        delete fieldValues[sectionId][fieldIndex];

        if (fieldValues[sectionId][currentIndex]) {
          fieldValues[sectionId][currentIndex].children.push(field);
        } else {
          fieldValues[sectionId][currentIndex] = {
            id: field.group_name,
            children: [field],
            field_type: "group",
            name: field.group_name
          };
        }
      });
    });

    return questionnaire.sections.map(section => {
      const fields = fieldValues[section.id] || [];
      return { ...section, fields };
    });
  }, [fields, questionnaire]);

  const handleChange = data => {
    const valueId = fields[data.field_id].valueId;
    apiv2.questionnaire.updateQuestionnaireValue(valueId, {
      value: data.value ? data.value : null
    });
  };

  const [handleDebouncedChange] = useDebouncedCallback(handleChange, 300);

  const onChange = React.useCallback(
    data => {
      setFields(state => ({
        ...state,
        [data.field_id]: { ...state[data.field_id], value: data.value }
      }));
      handleDebouncedChange(data);
    },
    [handleDebouncedChange]
  );

  const onSave = async () => {
    try {
      questionnaireStore.changeIsQuestionnaireSaving(true);
      await apiv2.questionnaire.finishQuestionnaire(id);
      questionnaireStore.changeIsQuestionnaireSaving(false);
      history.push(`/appointment/folder/${folderId}`);
      PatientAppointmentsRefreshTrigger();
    } catch (err) {
      console.log("An error occurred while finishing an appointment", err);
    }
  };

  const getWidgetSingle = React.useCallback(
    (field, tabIndex) => {
      switch (field.field_type) {
        case "text":
          return <TextWidget field={field} tabIndex={tabIndex} onChange={onChange} />;
        case "textarea":
          return <TextareaWidget field={field} tabIndex={tabIndex} onChange={onChange} />;
        case "number":
          return <NumberWidget field={field} tabIndex={tabIndex} onChange={onChange} />;
        case "date":
          return <DateWidget field={field} tabIndex={tabIndex} onChange={onChange} />;
        case "multiselect":
          return <CheckboxGroupWidget field={field} tabIndex={tabIndex} onChange={onChange} />;
        case "select":
        case "bool":
          return <RadioGroupWidget field={field} tabIndex={tabIndex} onChange={onChange} />;
        case "file":
          return <FileWidget field={field} onChange={onChange} />;
        case "body_mass_index":
          return <BodyMassIndexWidget key={field.id} field={field} onChange={onChange} />;
        case "partner":
          return (
            <PartnerWidget
              token={token}
              field={field}
              tabIndex={tabIndex}
              onChange={onChange}
              questionnaireId={id}
            />
          );
        // case "formula":
        //   return <FormulaWidget key={field.id} field={field} appointment={appointment} />;
        default: {
          return (
            <div style={{ height: "50px" }}>Haven&quot;t widget for type {field.field_type}</div>
          );
        }
      }
    },
    [onChange]
  );

  const getWidget = React.useCallback(
    (field, tabIndex) => {
      if (field.field_type === "group") {
        return (
          <React.Fragment>
            {field.children.map(child => {
              return (
                <FieldsetGroupItemContainer key={child.id}>
                  {isLabelNeededAtGroup(child) ? (
                    <FieldGroupItemFieldSet label={child.name}>
                      {getWidgetSingle(child, tabIndex)}
                    </FieldGroupItemFieldSet>
                  ) : (
                    getWidgetSingle(child, tabIndex)
                  )}
                </FieldsetGroupItemContainer>
              );
            })}
          </React.Fragment>
        );
      } else {
        return getWidgetSingle(field, tabIndex);
      }
    },
    [getWidgetSingle]
  );

  const getFieldsetLegendComponent = field => {
    if (
      field.field_type === "checkbox" ||
      field.field_type === "radiobutton" ||
      (field.field_type === "group" &&
        field.children.length > 0 &&
        (field.children[0].field_type === "checkbox" ||
          field.children[0].field_type === "radiobutton"))
    ) {
      return FieldsetLegendCheckbox;
    }
    if (field.field_type === "group" && field.children.length > 0) {
      return FieldsetLegendGroup;
    }
  };

  const rendered = state.map((section, index) => {
    if (!section.fields.length) return undefined;
    return (
      <SectionsContainer key={section.id}>
        <FormSection {...section} isEmpty={false} expand_by_default={true} shouldExpand={true}>
          {section.fields.map(field => {
            const widget = getWidget(field, index + 1);
            const isWithoutLabel = isFieldWithoutLabel(field);
            if (field.field_type === "body_mass_index") {
              return widget;
            }
            return (
              <FieldsetContainer key={field.id}>
                {isWithoutLabel ? (
                  widget
                ) : (
                  <Fieldset
                    overrides={{
                      Legend: {
                        component: getFieldsetLegendComponent(field)
                      }
                    }}
                    legend={field.name}>
                    {widget}
                  </Fieldset>
                )}
              </FieldsetContainer>
            );
          })}
        </FormSection>
      </SectionsContainer>
    );
  });

  const title = <HeadingH2 style={{ marginBottom: 44 }}>{questionnaire.folder?.name}</HeadingH2>;

  return (
    <Wrapper>
      <ContentContainer>
        {title}
        {rendered}
      </ContentContainer>

      <Footer>
        <PrimaryButton onClick={onSave} isLoading={questionnaireStore.isQuestionnaireSaving}>Сохранить</PrimaryButton>
      </Footer>
    </Wrapper>
  );
});
