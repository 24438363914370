import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Vox {
	apiService;
	constructor() {
		this.apiService = new ApiService('/api/vox', null, null, API_URL);
	}

	getUrl(call_id) {
		if (!call_id) {
			call_id = undefined;
		}
		return this.apiService.get('get_url', {call_id});
	}

	getToken(key, call_id) {
		if (!call_id) {
			call_id = undefined;
		}
		return this.apiService.get('get_token', {key, call_id});
	}

}

export default new Vox();
