import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Files {
  apiService;
  constructor() {
    this.apiService = new ApiService('', null, null, API_URL);
  }

  postUploadedFile({ patient, uploadOptions, type }) {
    let formData = new FormData();

    formData.append("file", uploadOptions.file);
    formData.append("original_name", uploadOptions.original_name || "");
    formData.append("type", type || "analysis");

    return this.apiService.post(patient ? `api/patients/${patient}/uploads` : `api/uploads`, {}, formData, {}, false);
  }
}

export default new Files();
