// @flow

import type { FieldText } from "../../domain/entities/Field";
import { DefaultInput } from "../form-elements/DefaultInput";
import * as React from "react";
import { useFieldValue } from "./useFieldValue";
import { setFieldValue } from "../../stores/appointmentFieldValues";
import type { LayoutFieldValue, TAppointment_id } from "../../domain/entities/Appointment";
import { getTextValue } from "./getTextValue";
import { useErrorHighlighting } from "./useErrorHighlighting";
import { getField_id } from "../../domain/entities/Field";
import { isValidReadableDate } from "../../domain/services/field";

export const TextWidget = (props: {
  field: FieldText,
  tabIndex?: number,
  showError?: boolean,
  appointment_id: TAppointment_id,
  layoutFieldValue?: LayoutFieldValue
}) => {
  const { appointment_id, field, tabIndex, layoutFieldValue } = props;
  const result = useFieldValue(appointment_id, field.id);
  const value = getTextValue(result, layoutFieldValue);
  let errorField;

  if (field.required) {
    const { error } = useErrorHighlighting(
      value,
      appointment_id,
      getField_id(field),
      null,
      field.required
    );
    errorField = error;
  }

  return (
    <DefaultInput
      value={value}
      tabIndex={tabIndex}
      error={(props.showError || value) && errorField ? errorField.value : false}
      handleChange={newValue => {
        setFieldValue({
          field_type: field.field_type,
          appointment_id,
          field_id: field.id,
          value: newValue
        });
      }}
    />
  );
};
