import React, { useEffect } from "react";
import styled from "styled-components";
import { keyBy } from "lodash";
import { useStore } from "effector-react";
import { createEvent, createStore } from "effector";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { SelectEdit, SelectView } from "../embryos-table/EmbryosTableSelect";
import { tableSelectColors, colors } from "../styleguide/colors";
import { getPatientFullName } from "../../domain/services/patient";
import { getLastNameWithInitials } from "../../domain/services/doctor";
import Spreadsheet from "@blessmesanta/react-spreadsheet/dist/index";
import { Row } from "../embryos-table/EmbryosTableRow";
import { Cell } from "./HospitalTableCell";
import { Patient } from "./HospitalPatient";
import { ColumnIndicator } from "../embryos-table/EmbryosTableColumnIndicator";
import { WideTextEdit } from "../embryos-table/EmbryosTableTextEdit";
import { AppointmentHistoryDoctor } from "../appointment-history/AppointmentHistoryDoctor";
import { EmbryosTableContext } from "../embryos-table/EmbryosTableContext";
import { formatServerDate } from "../embryos-table/utils";
import { tokenStore, currentUser } from "../../stores/auth";
import apiv2 from '../../apiv2';

const renderedColumns = ["Поступил", "Врач", "Пациент", "Основной диагноз", "Палата", "Статус"];

const StyledEmptyPatients = styled.div`
  margin-top: 54px;
  margin-bottom: 32px;
  text-align: center;
  font-size: 22px;
  line-height: 33px;
  color: ${colors.gray700};
`;

const EmptyPatients = ({ patients, page }) => {
  if (patients.length !== 0) return null;

  return (
    <StyledEmptyPatients>
      {page === "in_hospital" ? "В стационаре нет пациентов" : "Выписанных пациентов нет"}
    </StyledEmptyPatients>
  );
};

export const DiagnosisContainer = styled.div`
  width: 410px;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #fff;
  box-shadow: 0px 2px 17px rgba(91, 91, 91, 0.259752);
`;

export const DiagnosisArea = styled.div`
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding: 10px 15px;
  line-height: 1.475;
  border-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 15px;
  user-select: none;
`;

const DiagnosisView = props => {
  return (
    <DiagnosisContainer>
      <DiagnosisArea>{props.cell.value}</DiagnosisArea>
    </DiagnosisContainer>
  );
};

const WideTextView = props => {
  return <div>{props.cell.value}</div>;
};

const PatientView = props => {
  return <div>{getPatientFullName(props.cell.value?.medical_file?.patient)}</div>;
};

const Doctor = props => {
  return (
    <AppointmentHistoryDoctor
      name={getLastNameWithInitials(props.cell.value)}
      doctor={props.cell.value}
    />
  );
};

const statusOptions = [
  { value: "in_hospital", label: "В стационаре", color: tableSelectColors.gray },
  { value: "released", label: "Выписан", color: tableSelectColors.green }
];

export const StatusEditSelectComponent = props => <SelectEdit {...props} options={statusOptions} />;
export const StatusViewSelectComponent = props => <SelectView {...props} options={statusOptions} />;

const Container = styled(RoundedRectangleWithShadow)`
  padding: 32px;
  padding-top: 25px;
`;

const getTableData = (data, userRole) => {
  return data.map(field => [
    {
      value: formatServerDate(field.receipt_date),
      field: "receipt_date",
      entityId: field.id,
      readOnly: true,
      isShort: true
    },
    {
      value: field.appointment?.doctor,
      field: "doctor",
      DataViewer: Doctor,
      readOnly: true,
      entityId: field.id
    },
    {
      value: field.appointment,
      field: "patient",
      DataViewer: userRole === "post_nurse" ? PatientView : Patient,
      entityId: field.id,
      readOnly: true
    },
    {
      value: field.primary_diagnosis,
      field: "primary_diagnosis",
      DataViewer: WideTextView,
      DataEditor: DiagnosisView,
      entityId: field.id
    },
    {
      value: field.room,
      field: "room",
      DataEditor: WideTextEdit,
      isShort: true,
      isLimited: true,
      entityId: field.id
    },
    {
      value: field.status,
      field: "status",
      DataEditor: StatusEditSelectComponent,
      DataViewer: StatusViewSelectComponent,
      isShort: true,
      entityId: field.id
    }
  ]);
};

export const hospitalPatientsCounter = createStore(0);
const setPatientsCounter = createEvent("setPatientsCounter");
hospitalPatientsCounter.on(setPatientsCounter, (state, payload) => payload);

export const HospitalTable = ({ data, page }) => {
  const token = useStore(tokenStore);
  const user = useStore(currentUser);
  const { role } = user || {};

  const [state, setState] = React.useState([]);
  const tableDataById = React.useRef({});

  const onCellUpdate = async (row, cell) => {
    const id = state[row][0].entityId;
    const payload = {
      id,
      [cell.field]: cell.value
    };

    const responseData = await apiv2.hospitalAdmissions.patchHospitalAdmissions(payload);
    const keys = Object.keys(responseData);

    const replacedKeys = keys.filter(key => payload[key] !== responseData[key]);
    if (replacedKeys.length) {
      setState(state =>
        state
          .map((stateRow, index) => {
            if (index === parseInt(row, 10)) {
              const nextTableData = getTableData([responseData], role)[0];
              tableDataById.current[payload.id] = responseData;
              return nextTableData;
            }
            return stateRow;
          })
          .filter(stateRow => stateRow.find(field => field.value === page))
      );
      setPatientsCounter(
        Object.values(tableDataById.current).filter(patient => patient.status === "in_hospital")
          .length
      );
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      tableDataById.current = keyBy(data, "id");
      setPatientsCounter(data.filter(patient => patient.status === "in_hospital").length);
    }
  }, [data]);

  useEffect(() => {
    const pageData = Object.values(tableDataById.current).filter(
      admission => admission.status === page
    );
    setState(getTableData(pageData, role));
  }, [page, role]);

  return (
    <Container>
      <EmbryosTableContext.Provider
        value={{ onCellUpdate, onRowDelete: null, onRowDuplicate: null }}>
        <Spreadsheet
          Row={Row}
          data={state}
          Cell={Cell}
          onChange={setState}
          hideRowIndicators={true}
          autoPadRowsOnPaste={false}
          columnLabels={renderedColumns}
          ColumnIndicator={ColumnIndicator}
          className="Spreadsheet-full table-100 Spreadsheet-hospital"
        />
      </EmbryosTableContext.Provider>
      <EmptyPatients patients={state} page={page} />
    </Container>
  );
};
