// @flow
import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { useAutoFocusRef } from "../../utils/useAutoFocusRef";
import o, { type ExtractOverridesProps } from "react-overrides";

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px;
  background-color: ${rgba("#d8d8d8", 0.15)};
`;

const Input = styled.input`
  outline: none;
  border: none;
  background: transparent;
`;

const Icon: React$ComponentType<any> = React.Fragment;

export const BaseInput = (props: {
  value: string,
  onChange: string => mixed,
  onBlur?: () => mixed,
  onEnter?: () => mixed,
  onKeyDown?: () => mixed,
  overrides?: ExtractOverridesProps<{
    Container: typeof Container,
    Input: typeof Input,
    Icon: typeof Icon
  }>
}) => {
  const { value, onChange, onBlur, onEnter, onKeyDown } = props;
  const inputRef = useAutoFocusRef();
  return (
    <Container {...o}>
      <Icon {...o} />
      <Input
        {...o}
        ref={inputRef}
        as={"input"}
        type="text"
        value={value}
        onChange={e => onChange(e.target.value)}
        onBlur={onBlur}
        onKeyDown={e => onKeyDown ? onKeyDown(e) : (e.key === "Enter") && onEnter && onEnter()}
      />
    </Container>
  );
};
