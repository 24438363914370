//@flow
import styled from "styled-components";

export const CommaSeparatedWrapper = styled.span`
  & + & {
    &:before {
      content: ", ";
    }
  }
`;
