// @flow
import * as React from "react";
import { useStore } from "effector-react";
import { dataStore } from "../schedule-page/scheduleStore";
import { getScheduleIntervalForToday, getNumberOfPatients } from "../../domain/services/schedule";
import { currentUser } from "../../stores/auth";
import { Greeting } from "./Greeting";

export const GreetingContent = () => {
  const user = useStore(currentUser);
  const schedule = useStore(dataStore);

  const { till_date } = getScheduleIntervalForToday();
  const today = schedule.items.find(day => day.date === till_date);
  const reservedTodayHours = today && today.schedule.filter(hour => hour.is_reserved);
  const numberOfPatients = reservedTodayHours && getNumberOfPatients(reservedTodayHours);

  if (!user) {
    return null;
  } else if (user.role === "doctor") {
    return (
      <Greeting
        firstName={user.doctor.first_name}
        lastName={user.doctor.last_name}
        avatar={user.doctor.photo}
        numberOfPatients={numberOfPatients}
      />
    );
  } else {
    return <Greeting firstName={user.username} avatar="" numberOfPatients={0} />;
  }
};
