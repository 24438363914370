import * as React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { usePortal } from "../../utils/usePortal";
import { useHotkeys } from "react-hotkeys-hook";
import trashIcon from "./icons/trash.svg";
import duplicateIcon from "./icons/duplicate.svg";
import { colors } from "../styleguide/colors";

const useClickOutside = (ref, callback) => {
  React.useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const ContextMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 2px 17px rgba(91, 91, 91, 0.259752);
  min-width: 190px;
  border-radius: 3px;
`;

const ContextMenuButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 20px 14px;
  box-sizing: border-box;
  border: none;
  border-bottom: ${({ isFirst }) => isFirst && "1px solid #cecece"};
  background: transparent;
  color: "#262626";
  cursor: pointer;
`;

const ContextMenuTitle = styled.div`
  display: flex;
  align-items: center;
`;

const ContextMenuShortcut = styled.span`
  margin-left: 30px;
  font-size: 13px;
  color: #979797;
`;

const ContextMenuContent = styled.div`
  margin-left: 13px;
`;

const ContextMenuId = styled.div`
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 4px 8px;
  background: ${colors.gray050};
  color: ${colors.silver};
  font-size: 10px;
  line-height: 10px;
`;

export const ContextMenu = ({ id, close, posX, posY, onDuplicate, onDelete }) => {
  const rootRef = React.useRef(null);
  const contextMenuNode = usePortal("context-menu-root");
  useClickOutside(rootRef, close);
  useHotkeys("ctrl+d, cmd+d", event => {
    event.stopPropagation();
    event.preventDefault();
    onDuplicate();
    close();
    return false;
  });
  useHotkeys(
    "backspace",
    () => {
      event.stopPropagation();
      event.preventDefault();
      onDelete();
      close();
    },
    {
      filterPreventDefault: true
    }
  );

  return ReactDOM.createPortal(
    <ContextMenuContainer
      style={{
        position: "absolute",
        left: posX,
        top: posY
      }}
      ref={rootRef}>
      <ContextMenuButton type="button" onClick={onDelete} isFirst>
        <ContextMenuTitle>
          <img src={trashIcon} />
          <ContextMenuContent>Удалить</ContextMenuContent>
        </ContextMenuTitle>
        <ContextMenuShortcut>Del</ContextMenuShortcut>
      </ContextMenuButton>
      <ContextMenuButton type="button" onClick={onDuplicate}>
        <ContextMenuTitle>
          <img src={duplicateIcon} />
          <ContextMenuContent>Дублировать</ContextMenuContent>
        </ContextMenuTitle>
        <ContextMenuShortcut>Ctrl+D</ContextMenuShortcut>
      </ContextMenuButton>
      <ContextMenuId>{id}</ContextMenuId>
    </ContextMenuContainer>,
    contextMenuNode
  );
};
