import React, { useEffect } from "react";
import useReactRouter from "use-react-router";
import { Route } from "react-router";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import { FolderPage } from "./FolderPage";
import { FolderAnalysesPage } from "./FolderAnalysesPage";
import { FolderAnalysesPrintPage } from "./FolderAnalysesPrintPage";
import { FolderAgreementsPage } from "./FolderAgreementsPage";
import { AgreementPrintPage } from "./AgreementPrintPage";
import { setMedicalFile } from "../appointment-switch-page/medicalFileStore";
import { useGetMedicalFile } from "../appointment-switch-page/useGetMedicalFile";
import { FolderPageContext } from "./FolderPageContext";
import { QuestionnairePage } from "../questionnaire-page/QuestionnairePage";
import { FolderPrintPage } from "./FolderPrintPage";
import apiv2 from '../../apiv2';
import { BlockLoader } from "../loader/Loader";

export const FolderSwitchPage = () => {
  const token = useStore(tokenStore);
  const {
    match: { params, path }
  } = useReactRouter();

  const [folder, setFolder] = React.useState(null);

  useEffect(() => {
    (async () => {
      const folder = await apiv2.appointments.getFolder(params.id);
      setFolder(folder);
    })();
  }, [params.id, token, params]);

  const medicalFile = useGetMedicalFile({ medicalFileId: folder?.medical_file });

  useEffect(() => {
    if (medicalFile) {
      setMedicalFile(medicalFile);
    }
  }, [medicalFile]);

  if (!folder) return (
    <BlockLoader transparent />
  );

  return (
    <FolderPageContext.Provider value={{ folder }}>
      <Route path={path} exact component={FolderPage} />
      <Route path={`${path}/analyzes/print/:type`} exact component={FolderAnalysesPrintPage} />
      <Route path={`${path}/agreements/print/:agreementId`} exact component={AgreementPrintPage} />
      <Route path={`${path}/print/:concrete_folder?`} exact component={FolderPrintPage} />
      <Route path={`${path}/agreements`} component={FolderAgreementsPage} />
      <Route path={`${path}/analyzes`} exact component={FolderAnalysesPage} />
      <Route path={`${path}/questionnaire`} component={QuestionnairePage} />
    </FolderPageContext.Provider>
  );
};
