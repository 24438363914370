// @flow
import React from "react";
import styled from "styled-components";
import { PNormal } from "../styleguide/typography";

const Container = styled(PNormal)`
  border: none;
  font-family: inherit;
  outline: none;
  width: 124px;
  margin: 0 18px;
`;

export const Input = (props: { value: string, onChange: string => mixed, inputRef?: any }) => {
  const { value, onChange, inputRef, ...otherProps } = props;
  return (
    <Container
      type="text"
      as="input"
      value={value}
      onChange={e => onChange(e.target.value)}
      ref={inputRef}
      {...otherProps}
    />
  );
};
