// @flow
import * as React from "react";
import styled from "styled-components";

import { PrimaryButton, PrimaryButtonContainer } from "../buttons/PrimaryButton";
import { InputWithIcon, InputWithIconContainer } from "../form-elements/InputWithIcon";
import UserImg from "./icons/user.svg";
import LockImg from "./icons/lock.svg";
import { RoundedRectangleWithShadow } from "../styleguide/RoundedRectangleWithShadow";
import { useEffect, useState } from "react";
import { setRefreshToken, setToken } from "../../stores/auth";
import { ClinicLogo } from "../clinicLogo/ClinicLogo";
import oldAuth from '../../apiv2/entities/oldAuth';

const Container = styled(RoundedRectangleWithShadow)`
  width: 610px;
  padding: 63px 0 101px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
      width: calc(100% - 24px);
      box-sizing: border-box;
  }
`;

const Form = styled.form`
  margin-top: 43px;
  width: 398px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${InputWithIconContainer} {
    margin-bottom: 13px;
  }

  ${InputWithIconContainer}:last-of-type {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 475px) {
      width: calc(100% - 24px);
  }
`;

const ButtonContainer = styled(PrimaryButtonContainer)`
  width: 244px;
`;

const onSubmit = async (username, password, onError) => {
  try {
    const result = await oldAuth.postObtainToken({ username, password });
    setToken(result.token);
    setRefreshToken(result.refresh);
  } catch (e) {
    console.log(e);
    onError();
  }
};

export const Login = (props: {| onSubmit?: () => mixed |}) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [login, password]);

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(login, password, () => setError(true));
    props.onSubmit?.();
  };

  return (
    <Container>
      <ClinicLogo isShowName />
      <Form onSubmit={handleSubmit}>
        <InputWithIcon value={login} placeholder="Логин" icon={UserImg} handleChange={setLogin} error={!!isError} />
        <InputWithIcon
          value={password}
          placeholder="Пароль"
          icon={LockImg}
          handleChange={setPassword}
          type="password"
          error={(isError && "Введен неправильный логин или пароль") || false}
        />
        <PrimaryButton primaryButtonContainer={ButtonContainer} type="submit">
          Войти
        </PrimaryButton>
      </Form>
    </Container>
  );
};
