// @flow
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useStore } from "effector-react";

import type {
  TAppointment_id,
  LayoutFieldValue,
  AppointmentWithDetails
} from "../../domain/entities/Appointment";
import type { LayoutDetailed, TLayout_id } from "../../domain/entities/Layout";
import { SidebarContainer, StyledSidebarContainer } from "../sidebar/SidebarContainer";
import { FeedbackFixedLabel } from "./FeedbackFixedLabel";
import {
  closeFeedbackSidebar,
  feedbackSidebarSectionStore,
  openFeedbackForView
} from "../../stores/feedbacks/feedbackSections";
import type { FeedbackSections } from "../../stores/feedbacks/feedbackSections";
import { ShortcutsForView } from "./FeedbackForView";
import { FeedbackProvider } from "./FeedbackProvider";
import {
  isCompleted,
  isFilledFeedbackWithoutResponse,
  isNotFutureFeedback
} from "../../domain/services/feedback";

const FeedbacksSidebarContainer = styled(StyledSidebarContainer)`
  max-width: 466px;
  width: 100%;
`;

export const Feedback = (props: {
  appointment: AppointmentWithDetails,
  appointment_id: TAppointment_id,
  layout_id: TLayout_id,
  layout: LayoutDetailed,
  fieldValuesMap: { [id: number]: LayoutFieldValue }
}) => {
  const { appointment } = props;
  const feedbacksSidebarSection = useStore(feedbackSidebarSectionStore);
  const [lastAppointmentId, setLastAppointmentId] = React.useState(props.appointment_id);

  useEffect(() => {
    if (lastAppointmentId !== props.appointment_id) {
      closeFeedbackSidebar();
      setLastAppointmentId(props.appointment_id);
    }
  }, [props.appointment_id]);

  const getFeedbackSection = (shortcutsSection: ?FeedbackSections) => {
    if (!shortcutsSection) return null;

    switch (shortcutsSection) {
      case "forView":
        return <ShortcutsForView title="Обратная связь" appointment={appointment} />;
      default:
        return null;
    }
  };

  return (
    <>
      {appointment.feedback && (
        <FeedbackProvider id={appointment.feedback}>
          {feedback => {
            return (
              (feedback &&
                isNotFutureFeedback(feedback) &&
                !isFilledFeedbackWithoutResponse(feedback) && (
                  <FeedbackFixedLabel
                    isCompleted={feedback && isCompleted(feedback)}
                    onClick={() => openFeedbackForView((appointment.feedback: any))}
                  />
                )) ||
              null
            );
          }}
        </FeedbackProvider>
      )}
      <SidebarContainer
        isShown={!!feedbacksSidebarSection}
        overrides={{ StyledSidebarContainer: { component: FeedbacksSidebarContainer } }}>
        {getFeedbackSection(feedbacksSidebarSection)}
      </SidebarContainer>
    </>
  );
};
