import React, { useRef, useState, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { useStore } from "effector-react";

import { DoctorAvatar } from "../../doctor/DoctorAvatar";
import { ScheduleInsertContext } from "../ScheduleInsertContext";
import { ScheduleInsertToast } from "../ScheduleInsertToast";

import { useToast } from "../../toast/useToast";
import { parseMilliseconds } from "../../../utils/parseMilliseconds";
import { currentUser as currentUserStore } from "../../../stores/auth";

import favoriteDoctorIcon from "../icons/favorite-doctor.svg";
import { rgba } from "polished";
import { colors } from "../../styleguide/colors";
import { DoctorMenuNew } from "./DoctorMenuNew";
import generalScheduleStore from "../../../stores/generalScheduleStore";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { observer } from "mobx-react";
import { DropDownMenu } from "../../drop-down-menu/DropDownMenu";
import InformationIcon from "@mui/icons-material/InfoOutlined";
import { ModalPortal } from "../../modal/ModalPortal";
import { InformationAboutDoctor } from "./InformationAboutDoctor";
import adultDoctorIcon from "../../../assets/icons/adultDoctor.svg";
import childrenDoctorIcon from "../../../assets/icons/childrenDoctor.svg";
import chatIcon from "./icons/chat.svg";
import onlineIcon from "./icons/online.svg";
import { DoctorInformationIcons } from "./DoctorInformationIcons";
import UnscheduledAppointmentIcon from '@mui/icons-material/PostAdd';
import scheduleInsertsStore, { INSERTS_TYPE } from "../../../stores/scheduleInsertsStore";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  ${props => (props.heightContainer ? `height: ${props.heightContainer}px;` : "height: 176px;")}
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 10px 10px 10px;
  flex-shrink: 0;
  overflow: hidden;
  border-right: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
`;

const DoctorFullName = styled.div`
  color: #262626;
  font-family: "Graphik LCG";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
`;

const DoctorAvatarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const DoctorInfoContainer = styled.div`
  width: 100%;
  max-width: ${props => `${props.densityWidth}px`};
  display: flex;
  flex-direction: column;
`;

const DoctorInfoTop = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const DoctorInfoBottom = styled.div`

`;

const DoctorInfoOrganizations = styled.div`
  min-height: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #C6007F;
    font-family: "Graphik LCG";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
  }
`;

const DoctorInfoSpeciality = styled.div`
  min-height: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
    
  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #7D7D7D;
    font-family: "Graphik LCG";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
  }
`;

const specialtyIconPath = specialty => {
  const path = {
    ADULT: adultDoctorIcon,
    CHILDREN: childrenDoctorIcon
  };

  return path[specialty];
};

export const DoctorViewNew = observer(
  ({
     doctor,
     inFavourites = false,
     date,
     time,
     dates,
     offScheduleEntriesCount = 0,
     enableDensityWidth = false,
     isBlockedUnscheduledAppointment = false
  }) => {
  const [isShowInformationModal, setIsShowInformationModal] = useState(false);
  const wrapperRef = useRef(null);
  const organizationsName = doctor.organizationsName.map((organization) => organization.name);
  const containerWidthDoctorInfo = enableDensityWidth ? generalScheduleStore.densityCalendarWidth[generalScheduleStore.densityCalendar] : 260;

  const handleUnscheduledAppointment = () => {
    scheduleInsertsStore.setScheduleInsertDialogData({
      type: INSERTS_TYPE.WITHOUT_TIME_SLOT,
      slot: {
        doctor,
        date,
        time,
        dates
      }
    })
  }

  let heightContainer = 129;
  if (generalScheduleStore.settingsScheduleView?.doctorOrganization?.checked) {
    heightContainer += generalScheduleStore.settingsScheduleView.doctorOrganization.height;
  }
  if (generalScheduleStore.settingsScheduleView?.doctorSpecialization?.checked) {
    heightContainer += generalScheduleStore.settingsScheduleView.doctorSpecialization.height;
  }

  const menuItems = [
    {
      name: "Информация о враче",
      icon: <InformationIcon />,
      handleOnClick: () => setIsShowInformationModal(true)
    },
    {
      name: "Прием вне расписания",
      icon: <UnscheduledAppointmentIcon />,
      disabled: isBlockedUnscheduledAppointment,
      badgeCount: offScheduleEntriesCount || 0,
      handleOnClick: handleUnscheduledAppointment
    },
  ];

  const handleOnCloseInformationModal = () => {
    setIsShowInformationModal(false);
  };

  const infoIconOptions = [
    { isShow: inFavourites, icon: favoriteDoctorIcon, tooltipText: "Избранный врач"},
    { isShow: doctor.favoritesServices.some(service => service.isOnline), icon: onlineIcon, tooltipText: "Принимает онлайн"},
    { isShow: doctor.favoritesServices.some(service => service.isChat), icon: chatIcon, tooltipText: "Принимает в чатах"},
    {
      isShow: ["ADULT", "CHILDREN"].includes(doctor.specializationType),
      icon: specialtyIconPath(doctor.specializationType),
      tooltipText: doctor.specializationType === "ADULT" ? "Врач для взрослых" : "Врач для детей"
    }
  ]
    .filter(option => option.isShow)
    .map(({ icon, tooltipText }) => ({ icon, tooltipText }));

  const getShortName = () => {
    const lastName = doctor.fullname_doctor.split(" ")?.[0] || "";
    const firstName = doctor.fullname_doctor.split(" ")?.[1][0] || "";
    const middleName = doctor.fullname_doctor.split(" ")?.[2][0] || "";
    return `${lastName} ${firstName ? `${firstName}.` : ""} ${middleName ? `${middleName}.` : ""}`;
  }

  return (
    <>
      <Container heightContainer={heightContainer} ref={wrapperRef}>
        <DoctorInfoContainer densityWidth={containerWidthDoctorInfo}>
            <DoctorInfoTop>
            <DoctorAvatarContainer>
              <DoctorAvatar doctor={doctor} />
              <DoctorInformationIcons data={infoIconOptions} position="absolute" />
            </DoctorAvatarContainer>
            <DropDownMenu menuItems={menuItems} />
            <DoctorFullName>
              {!!(
                generalScheduleStore.densityCalendar !== "small" &&
                !generalScheduleStore.settingsScheduleView?.doctorShortFio?.checked
              ) && <>{doctor.fullname_doctor}</>}
              {!!(
                generalScheduleStore.densityCalendar === "small" ||
                generalScheduleStore.settingsScheduleView?.doctorShortFio?.checked
              ) && (
                <Tooltip title={doctor.fullname_doctor}>
                  {getShortName()}
                </Tooltip>
              )}
            </DoctorFullName>
          </DoctorInfoTop>
          <DoctorInfoBottom>
            {generalScheduleStore.settingsScheduleView?.doctorOrganization?.checked && (
              <Tooltip
                title={organizationsName?.length > 1 ? organizationsName?.join(" / ") : ""}>
                <DoctorInfoOrganizations>
                  <span>{organizationsName?.join(" / ")}</span>
                </DoctorInfoOrganizations>
              </Tooltip>
            )}
            {generalScheduleStore.settingsScheduleView?.doctorSpecialization?.checked && (
              <Tooltip
                title={
                  doctor?.specializations?.length > 1 ? doctor?.specializations?.join(", ") : ""
                }>
                <DoctorInfoSpeciality>
                  <span>{doctor?.specializations?.join(", ")}</span>
                </DoctorInfoSpeciality>
              </Tooltip>
            )}
          </DoctorInfoBottom>
        </DoctorInfoContainer>
      </Container>
      <ModalPortal>
        <InformationAboutDoctor
          doctor={doctor}
          isShow={isShowInformationModal}
          onClose={handleOnCloseInformationModal}
          informationIcons={<DoctorInformationIcons data={infoIconOptions} size="l" />}
        />
      </ModalPortal>
    </>
  );
});
