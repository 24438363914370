import React from "react";
import { Controller } from "react-hook-form";
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'moment/locale/ru';
import styled from "styled-components";
import {
  SERVER_DATE_FORMAT,
  READABLE_DATE_FORMAT,
  SERVER_DATE_TIME_FORMAT,
  READABLE_DATE_TIME_FORMAT, SERVER_TIME_FORMAT
} from "../../../../utils/dates";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { FormControl, FormLabel } from "@mui/material";
import { Asterisk } from "../styled/Asterisk.styled";

const ContainerFieldDate = styled.div`
    > div {
      width: 100%;
    }
`;

export const FormInputDate = (props) => {
    const type = props.typePicker || 'date';
    return (
        <ContainerFieldDate>
          <FormControl fullWidth className={'dynamic-form-input-control'} error={!!props.control._formState.errors[props.name]} size={"small"} variant={"outlined"}>
            {(props.label && props.withLabel) && <FormLabel component="legend" className="not-transform">{props.required && <Asterisk/>} {props.label}</FormLabel>}
            <Controller
                name={props.name}
                control={props.control}
                render={({ field, fieldState: { error },}) => (
                    <LocalizationProvider adapterLocale="ru" dateAdapter={AdapterMoment}>
                        {type === 'date' && (
                            <DatePicker
                                format={READABLE_DATE_FORMAT}
                                onChange={(date) => field.onChange(!date ? null : date.format(SERVER_DATE_FORMAT))}
                                value={field.value ? moment(field.value) : null}
                                {...props}
                                label={props.withLabel ? '' : props.label}
                                error={!!error}
                                slotProps={{
                                  textField: {
                                    error,
                                    helperText: error ? error.message : props.subInfo ? props.subInfo : null,
                                  },
                                }}
                            />
                        )}
                        {type === 'dateTime' && (
                            <DateTimePicker
                                format={READABLE_DATE_TIME_FORMAT}
                                onChange={(date) => field.onChange(!date ? null : date.format(SERVER_DATE_TIME_FORMAT))}
                                value={field.value ? moment(field.value) : null}
                                {...props}
                                label={props.withLabel ? '' : props.label}
                                error={!!error}
                                slotProps={{
                                  textField: {
                                    helperText: error ? error.message : props.subInfo ? props.subInfo : null,
                                  },
                                }}
                            />
                        )}
                        {type === 'time' && (
                            <TimePicker
                                {...props}
                                label={props.withLabel ? '' : props.label}
                                format={props?.format || SERVER_TIME_FORMAT}
                                onChange={(time) => field.onChange(!time ? null : time.format(props?.format || SERVER_TIME_FORMAT))}
                                value={field.value ? moment(field.value, props?.format || SERVER_TIME_FORMAT) : null}
                                error={!!error}
                                slotProps={{
                                  textField: {
                                    helperText: error ? error.message : props.subInfo ? props.subInfo : null,
                                  },
                                }}
                            />
                        )}

                    </LocalizationProvider>
                )}
            />
          </FormControl>
        </ContainerFieldDate>
    );
};
