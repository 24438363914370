export const getSignalStrength = (jitter, loss, bitrate) => {
  let jitterQuality = 0;
  let lossQuality = 0;
  let bitrateQuality = 0;

  // Оценка jitter
  if (jitter >= 0) {
    if (jitter < 0.03) {
      jitterQuality = 3;
    } else if (jitter >= 0.03 && jitter <= 0.05) {
      jitterQuality = 2;
    } else {
      jitterQuality = 1;
    }
  }

  // Оценка packet loss
  if (loss >= 0) {
    if (loss < 0.01) {
      lossQuality = 3;
    } else if (loss >= 0.01 && loss <= 0.03) {
      lossQuality = 2;
    } else {
      lossQuality = 1;
    }
  }

  // Оценка bitrate
  if (bitrate >= 0) {
    if (bitrate > 2500) {
      bitrateQuality = 3;
    } else if (bitrate >= 1000 && bitrate <= 2500) {
      bitrateQuality = 2;
    } else {
      bitrateQuality = 1;
    }
  }

  // Среднее значение для определения качества связи
  return Math.floor((jitterQuality + lossQuality + bitrateQuality) / 3);

  //   Пороговые значения для jitter, loss и bitrate основаны на стандартах качества связи и опыте в области сетевых технологий и видео-телефонии. Вот объяснение этих значений:
  //
  //   Jitter: Видеосвязь становится заметно хуже при jitter более 30-50 мс, так как это приводит к непостоянству в получении пакетов.
  //   Loss: Потеря пакетов более 1-3% существенно влияет на качество видео, вызывая заикания и пропуски кадров.
  //   Bitrate: Более высокий битрейт обеспечивает лучшее качество видео, особенно для HD-видео, где минимальные требования к битрейту обычно превышают 1000 kbps.
  //   Эти значения часто используются в индустрии связи для оценки качества VoIP и видеосвязи.
};
