import styled from "styled-components";
import { withModifiers } from "with-modifiers";
import { newColors } from "../styleguide/newColors";
import { newColorsRassvet } from "../styleguide/newColorsRassvet";

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  padding: 32px 15px;
  height: calc(100vh - 64px);
  height: calc(var(--vh, 1vh) * 100 - 64px);
  max-width: 800px;
  margin: 0 auto;
  overflow-y: auto;
`;

export const LogoWrapper = styled.div`
  height: 10vh;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  
  img {
    height: 100%;
  }
`;

export const ButtonReview = withModifiers({
  notBackground: () => `
    background-color: transparent;
  `,
  backgroundPink: () => `
    background-color: ${newColors.pink};
    color: ${newColors.white}
  `,
  backgroundYellow: () => `
    background-color: ${newColors.yellow};
    color: ${newColors.white}
  `,
  backgroundPinkRassvet: () => `
    background-color: ${newColorsRassvet.pink};
    color: ${newColorsRassvet.white}
  `,
  disabled: () => `
    pointer-events: none;
    background-color: ${newColors.brown30};
    color: ${newColors.brown75}
  `,
  disabledRassvet: () => `
    pointer-events: none;
    background-color: ${newColorsRassvet.brown30};
    color: ${newColorsRassvet.brown75}
  `,
  rassvet: () => `
    background-color: ${newColorsRassvet.white};
    color: ${newColorsRassvet.pink};
  `
})(
  modifier => styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${newColors.white};
  border: none;
  cursor: pointer;
  max-width: 500px;
  width: 100%;
  border-radius: 100px;
  padding: 10px 45px;
  outline: none;
  font-family: 'Suisse Intl';
  color: ${newColors.pink};
  font-size: 14px;
  min-height: 48px;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
    
  ${modifier};  
`);

export const SectionTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const SectionBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const HeaderText = withModifiers({
  center: () => `
    text-align: center;
  `,
  rassvet: () => `
    color: ${newColorsRassvet.brown};
  `
})(
  modifier => styled.h1`
  color: ${newColors.brown};
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  font-family: 'Suisse Intl';
  ${modifier}
`);

export const DescriptionText = withModifiers({
  rassvet: () => `
    color: ${newColorsRassvet.brown};
  `,
  fomina: () => `
    color: ${newColors.pink};
  `
})(
  modifier => styled.p`
  color: ${newColors.brown};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  font-family: 'Suisse Intl';

  ${modifier}
  
  &:last-child {
    margin-bottom: 0;
  }
  
  span {
    font-weight: 500;
  }
      
`);

export const DescriptionTextBold = withModifiers({
  rassvet: () => `
    color: ${newColorsRassvet.pink};
  `,
})(
  modifier => styled.span`
  color: ${newColors.pink};
  font-weight: 500;

  ${modifier}
      
`);


export const WrapperTexts = styled.div`
  ${DescriptionText} {
    margin-bottom: ${props => (props.smallMargin ? '8px' : '16px')};
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  margin-bottom: 16px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

export const AgreementText = styled.p`
  color: ${newColors.brown};
  font-family: 'Suisse Intl';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  
  a {
    color: ${newColors.brown};
    font-family: 'Suisse Intl';
  }
`;

export const ReviewSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ReviewBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 7px;
    gap: 8px;
    background: #fff;
`;

export const ReviewBoxText = withModifiers({
  rassvet: () => `
    color: ${newColorsRassvet.brown};
  `
})(
  modifier => styled.h3`
	font: 600 14px / 19.6px 'Suisse Intl';
  color: ${newColors.brown}
      
  ${modifier}
`);

export const ReviewBoxChipsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const ReviewChip = withModifiers({
  rassvet: () => `
    --bg-color: ${newColorsRassvet.pink};
    --border-color: ${newColorsRassvet.brown};
    --color: ${newColorsRassvet.brown};
  `
})(
  modifier => styled.label`
    --bg-color: ${newColors.pink};
	  --border-color: ${newColors.brown};
	  --color: ${newColors.brown};
	  display: inline-flex;
    flex: 1;

	  input[type="checkbox"], input[type="radio"] {
		  display: none;
	  }

	  span {
      box-sizing: border-box;
		  display: inline-flex;
      width: 100%;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      min-height: 36px;
      border-radius: 99em;
      background: transparent;
      border: 1px solid var(--border-color, ${newColors.brown});
	    color: var(--color, ${newColors.brown});
	    font-family: 'Suisse Intl';
	  }

	  input[type="checkbox"]:checked + span, input[type="radio"]:checked + span {
        background: var(--bg-color, ${newColors.pink});
        color: #fff;
        border-color: transparent;
    }
    
    ${modifier};  
  `
);
