import React from "react";
import styled from "styled-components";

import { HeadingH3 } from "../styleguide/typography";
import { ModalWithCloseButtonLayout } from "./ModalWithCloseButtonLayout";
import { useModalEscape } from "./useModalEscape";
import { ModalFade } from "./ModalFade";
import { ModalBackground } from "./ModalBackground";

const Container = styled.div`
  width: 653px;
  padding: 35px 40px 50px;
  box-sizing: border-box;
`;

export const Modal = props => {
  const { title, isShown, hide } = props;

  useModalEscape(hide);

  return (
    <ModalFade>
      {isShown && (
        <ModalBackground>
          <ModalWithCloseButtonLayout handleClose={hide}>
            <Container>
              <HeadingH3>{title}</HeadingH3>
              {props.content}
            </Container>
            {props.afterFormContent ? props.afterFormContent : null}
          </ModalWithCloseButtonLayout>
        </ModalBackground>
      )}
    </ModalFade>
  );
};
