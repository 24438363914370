import { makeAutoObservable } from "mobx";
import { VERSION } from "../version";
import { currentUser } from "./auth";


const SHOW_UPDATE_KEY = "show_ems_update_data";

class CommonStore {
  showUpdateVersionModal = false;
  isShowedUpdateVersion = false;


  constructor() {
    makeAutoObservable(this);
  }

  saveDataShow(data) {
    localStorage.setItem(SHOW_UPDATE_KEY, JSON.stringify(data));
  }

  showUpdateVersion(data, user) {
    if (user && user.role === "doctor") {
      if (!data.version || data.version !== VERSION) {
        data.version = VERSION;
        data.doctorList = [];
        this.saveDataShow(data);
      }
      if (!data.doctorList.includes(user.doctor.id)) {
        data.doctorList.push(user.doctor.id);
        this.saveDataShow(data);
      }
    }
  }

  checkShowedUpdateVersion() {
    const showUpdateLocalData = localStorage.getItem(SHOW_UPDATE_KEY);

    if (showUpdateLocalData) {
      const user = currentUser.getState();
      const parsedDataShow = JSON.parse(showUpdateLocalData);
      if (parsedDataShow.doctorList.includes(user.doctor.id)) {
        this.changeIsShowedUpdateVersion(true);
      }
    }
  }

  changeShowUpdateVersionModal(value) {
    this.showUpdateVersionModal = value;
  }

  changeIsShowedUpdateVersion(value) {
    this.isShowedUpdateVersion = value;
    if (this.isShowedUpdateVersion) {
      const user = currentUser.getState();
      const showUpdateLocalData = localStorage.getItem(SHOW_UPDATE_KEY);

      let showParsedData = {
        version: VERSION,
        doctorList: []
      };

      if (showUpdateLocalData) {
        showParsedData = JSON.parse(showUpdateLocalData);
      }

      this.showUpdateVersion(showParsedData, user);
    }
  }

}

export default new CommonStore();