//@flow
import * as React from "react";
import { AppointmentHistoryTableRow } from "../appointment-history/AppointmentHistoryTableRow";
import { PNormal, PXSmall } from "../styleguide/typography";
import styled from "styled-components";
import { colors } from "../styleguide/colors";
import callNeededIcon from "./icons/icons-clock.svg";

const TemplateText = styled(PXSmall)`
  color: ${colors.gray500};
  margin-top: 2px;
`;

const CallNeededIcon = styled.div`
  width: 20px;
  height: 20px;
  background: url(${callNeededIcon});
`;

export const AppointmentFeedbackRow = (props: {
  date: string,
  patientWithTemplate?: { patient: string, template: string },
  rate: string,
  isCallNeeded: boolean,
  link: string,
  onClickCell?: () => mixed
}) => {
  return (
    <AppointmentHistoryTableRow
      date={props.date}
      patientWithTemplate={
        (props.patientWithTemplate && (
          <>
            <PNormal modifiers={["left"]}>{props.patientWithTemplate.patient}</PNormal>
            <TemplateText modifiers={["left"]}>{props.patientWithTemplate.template}</TemplateText>
          </>
        )) ||
        null
      }
      rate={props.rate}
      isCallNeeded={props.isCallNeeded ? <CallNeededIcon /> : <div />}
      link={props.link}
      withArrow
      big
      onClickCell={(props.onClickCell: any)}
    />
  );
};
