import * as React from 'react';
import Popover from '@mui/material/Popover';
import SettingsIcon from '@mui/icons-material/Settings';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import generalScheduleStore from "../../stores/generalScheduleStore";
import { Tooltip } from "@mui/material";
import { observer } from "mobx-react";

export const GeneralScheduleNewConfig = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);

  };
  const open = Boolean(anchorEl);

  const id = open ? 'general-schedule-new-config' : undefined;
  return (
    <div>
      <Tooltip title="Настройки отображения элементов">
        <SettingsIcon cursor={'pointer'} aria-describedby={id} onClick={handleClick} />
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          subheader={<ListSubheader>Настройки отображения элементов</ListSubheader>}
        >
          {Object.keys(generalScheduleStore.settingsScheduleView).map((key, index) => (
            <ListItem key={index + generalScheduleStore.settingsScheduleView[key].value}>
              <ListItemText id={generalScheduleStore.settingsScheduleView[key].id} primary={generalScheduleStore.settingsScheduleView[key].text} />
              <Switch
                edge="end"
                onChange={() => generalScheduleStore.changeSettingsScheduleView(generalScheduleStore.settingsScheduleView[key].value)}
                checked={generalScheduleStore.settingsScheduleView[key].checked}
                inputProps={{
                  'aria-labelledby': generalScheduleStore.settingsScheduleView[key].id,
                }}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
})
