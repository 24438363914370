// @flow
import { createEffect, createEvent, combine, createStore } from "effector";
import { currentUser, tokenStore } from "../../stores/auth";
import { initializePaginationStoresAndEvents } from "../../utils/effectorPagination";
import apiv2 from '../../apiv2';

const limit = 12;

type TOrder = "date" | "priority";
export const orderStore = createStore<?TOrder>("date");
export const setOrder = createEvent<TOrder>("setOrder");
orderStore.on(setOrder, (state, payload) => payload);

export const reloadFeedbacks = createEvent<void>("reloadFeedbacks");

export const {
  dataStore,
  appendData,
  offsetStore,
  loadMore,
  loadMoreStore,
  setLoadMore,
  clean
} = initializePaginationStoresAndEvents(limit);

const cleanerStore = combine(orderStore, order => ({
  order
}));

cleanerStore.watch(() => {
  clean();
});

currentUser.watch(user => {
  if (!user) {
    clean();
  }
});

reloadFeedbacks.watch(() => {
  clean();
  const data = getFeedbacksStore.getState();
  getFeedbacksEffect(data);
});

const getFeedbacksEffect = createEffect("getFeedbacksffect").use(
  async ({ token, order, offset }) => {
    if (!token) {
      return;
    }

    return apiv2.feedback.getFeedbacks({
      order,
      unread_only: true,
      limit,
      offset
    });
  }
);

getFeedbacksEffect.done.watch(({ result: appointments, params }) => {
  if (!appointments) {
    return;
  }
  setLoadMore(appointments.length === limit);
  appendData({ data: appointments, offset: params.offset + 1 });
});

const getFeedbacksStore = combine(tokenStore, orderStore, offsetStore, (token, order, offset) => ({
  token,
  order,
  offset
}));
getFeedbacksStore.watch(data => {
  if (!data.token) {
    return;
  }
  getFeedbacksEffect(data);
});
