// @flow
import * as React from "react";

import { getNounEndingByNumber } from "../../utils/getNounEndingByNumber";
import { HeadingH2 } from "../styleguide/typography";

import {
  Avatar,
  AvatarPlaceholder,
  Container, ContainerWrapper,
  InnerContainer,
  Text,
  TextContainer
} from "./greetingCommon";
import { FEATURE, useApplicationFeatureEnabled } from "../../utils/applicationFeatures";

export const GreetingFeedback = (props: {|
  firstName: string,
  lastName?: string,
  avatar?: string,
  numberOfFeedbacks?: number
|}) => {
  const { firstName, lastName, numberOfFeedbacks = 0 } = props;
  const isFeedbackNew = useApplicationFeatureEnabled(FEATURE.FEEDBACK_NEW);

  return (
    <Container>
      <ContainerWrapper>
        {props.avatar && !props.avatar.startsWith("#") ? (
          <Avatar avatar={props.avatar} />
        ) : (
          <AvatarPlaceholder>
            {(lastName ? lastName.slice(0, 1) : "") + firstName.slice(0, 1)}
          </AvatarPlaceholder>
        )}
        <InnerContainer>
          <HeadingH2>Обратная связь</HeadingH2>
          {!isFeedbackNew && (
            <TextContainer>
              {numberOfFeedbacks ? (
                <>
                  <Text as="span" modifiers={["bold", "color"]}>
                    {numberOfFeedbacks}{" "}
                    {getNounEndingByNumber(numberOfFeedbacks, ["пациент", "пациента", "пациентов"])}{" "}
                  </Text>
                  <Text as="span">ожидают звонка</Text>
                </>
              ) : (
                <Text as="span">Нет форм обратной связи, требующих вашего внимания</Text>
              )}
            </TextContainer>
          )}
        </InnerContainer>
      </ContainerWrapper>
    </Container>
  );
};
