// @flow
import React from "react";
import moment from "moment";
import { BarChart } from '@mui/x-charts/BarChart';
import { INTERVAL_FORMAT } from "../../../../stores/reviewStatisticsStore";

export function BarChartMUI(props: {
  label: string,
  options?: any,
  data: any,
  width?: number,
  height?: number,
  hiddenLegend?: boolean,
  margin?: () => {},
}) {
  const hidden = props.hiddenLegend !== undefined ? props.hiddenLegend : true;
  const data = props.data;
  data.datasets = data.datasets.filter((item) => item.data.length);

  return <BarChart
      width={props.width}
      height={props.height}
      margin={props.margin || { top: hidden ? 10 : 100 }}
      series={data.datasets.slice()}
      tooltip={{
        trigger: window.innerWidth < 768 ? "none" : "axis",
        slotProps: {
          popper: {
            placement: "auto",
          },
        },
        classes: {
          root: 'chart-tooltip-root',
          row: 'chart-tooltip-row'
        }
      }}
      xAxis={[{
        scaleType: 'band',
        data: data.labels,
        valueFormatter: (date) => moment(date).format(INTERVAL_FORMAT[props.interval])}]}
      slotProps={{
        legend: {
          labelStyle: {
            fontSize: 10,
          },
          hidden: hidden,
          position: {
            vertical: 'top',
            horizontal: 'left',
          },
          itemMarkWidth: 20,
          itemMarkHeight: 2,
          markGap: 5,
          itemGap: 10,
        }
      }}
    />;
}
