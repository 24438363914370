// @flow
import React from "react";
import styled from "styled-components";

const CounterContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  cursor: ${props => (props.onClick ? "pointer" : "default")};
`;

const CounterValue = styled.span`
  font: 14px/20px "Graphik LCG", monospace;
`;

export const CorporateCounter = (props: {|
  icon: React$Node,
  value: string | number,
  onClick?: () => void
|}) => {
  const { icon, value, onClick } = props;

  return (
    <CounterContainer className="counter" onClick={onClick}>
      {icon}
      <CounterValue className="counter__value">{value}</CounterValue>
    </CounterContainer>
  );
};
