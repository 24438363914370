import * as React from "react";
import { useStore } from "effector-react";
import Spreadsheet from "@blessmesanta/react-spreadsheet/dist/index";
import styled from "styled-components";
import { keyBy, omit } from "lodash";
import { Row } from "./EmbryosTableRow";
import { Cell } from "./EmbryosTableCell";
import { ColumnIndicator } from "./EmbryosTableColumnIndicator";
import { EmbryosTablePlaceholder } from "./EmbryosTablePlaceholder";
import Plus from "../general-schedule-page/doctor-view/icons/Plus";
import { getFoldersEffect } from "../../stores/folderStore";
import { currentUser as currentUserStore, tokenStore } from "../../stores/auth";
import { ALL_FIELDS_CHECK_VALUE, VIEW_TYPES, VIEW_TYPES_FILTERS } from "./constants";
import {
  getTableData,
  constructTableRowPayload,
  constructTableServerPayload,
  getTableColumns, getModifiedTableColumns
} from "./utils";
import { EmbryosTableContext } from "./EmbryosTableContext";
import { TableSaveIndicator } from "../table-save-indicator/TableSaveIndicator";
import { colors } from "../styleguide/colors";
import apiv2 from '../../apiv2';
import _ from 'lodash';

const AddRows = styled.div`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  padding: 6px 10px;
  font-size: 15px;
  color: #262626;
  cursor: pointer;

  & > .sc-add-rows-caption {
    opacity: 0.3;
    transition: 0.2s opacity;
    display: flex;
    align-items: center;
  }

  &:hover > .sc-add-rows-caption {
    opacity: 0.75;
  }

  &:hover {
    & input {
      outline: 1px solid ${colors.gray300};
    }
  }
  
  button {
    background: transparent;
    border: none;
    
    &[disabled] {
      pointer-events: none;
    }
  }
`;

const RowsNumber = styled.input`
  box-sizing: border-box;
  width: 29px;
  padding: 6px;
  margin-left: 4px;
  border: none;
  border-radius: 4px;
  outline: none;
  opacity: 0.3;
  transition: 0.2s opacity;

  &:focus {
    opacity: 0.75;
    outline: 1px solid ${colors.gray300};
  }

  &:focus ~ .sc-add-rows-caption {
    color: red;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Container = styled.div`
  width: 100%;
  margin-top: 0;
  padding-top: 0;
  border-radius: 0;
  padding-top: 0;
  padding-right: 0;
  overflow: auto;
  background: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.045), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.045), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 13px 100%, 13px 100%;
  background-attachment: local, local, scroll, scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(184 184 184 / 50%);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  & tr:first-child {
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0px 0px 10px #cacaca;

    &:after {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #c6007f;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${p => p.justifyContent};
  margin-top: 14px;
`;

export const EmbryosTable = props => {
  const currentUser = useStore(currentUserStore);
  const hasEditAccess = currentUser?.doctor?.is_embryologist;
  const $containerRef = React.useRef();

  const scrollIntoLastTableRowTimeoutIdRef = React.useRef();

  const [scrollTop, setScrollTop] = React.useState(0);

  const token = useStore(tokenStore);
  const actualToken = token || "";
  const patientId = parseInt(props.medicalFile?.patient?.id);

  const tableDataById = React.useRef({});
  const [loading, setLoading] = React.useState(true);
  const [syncing, setSyncing] = React.useState(false);
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState([]);
  const [addingRows, setAddingRows] = React.useState(false);
  const minRowsCountToAdd = 1;
  const maxRowsCountToAdd = 60;
  const [rowsCountValue, setRowsCountValue] = React.useState(minRowsCountToAdd.toString());

  const rowsCount = React.useMemo(() => {
    const newValue = parseInt(rowsCountValue, 10);
    if (isNaN(newValue)) {
      return minRowsCountToAdd;
    }
    if (newValue > maxRowsCountToAdd) {
      return maxRowsCountToAdd;
    }
    return newValue;
  }, [rowsCountValue]);

  const reindexStateRows = (state) => {
    // Нужно клонировать, что бы можно было менять обьекты внутри массива, иначе они readOnly
    const clonedState = _.cloneDeep(state);
    return clonedState.map((row, index) => {
      if (row[0].canReindex) {
        row[0].value = index + 1;
      }
      return row;
    });
  };

  const onAdd = async () => {
    setAddingRows(true);
    const items = await props.onCreateEmbryos(rowsCount);

    for (const item of items) {
      tableDataById.current[item.id] = item;
    }

    setState(state => reindexStateRows([
      ...state,
      ...getTableData(props.filter, items, !hasEditAccess)
    ]));
    setRowsCountValue('1');
    scrollIntoLastTableRow();
    setAddingRows(false);
  };

  const scrollIntoLastTableRow = () => {
    scrollIntoLastTableRowTimeoutIdRef.current = setTimeout(() => {
      $containerRef.current.scrollTop = $containerRef.current.scrollHeight;
    }, 100);
  };

  const mapViewType = (viewType) => { // Костыль для того что бы не создавать новую задачу на бэк, потому что танные те же, отличаютяс только столбцы
    if (viewType === VIEW_TYPES.OOCYTES_CULTIVATION_AND_FERTILIZATION) {
      return VIEW_TYPES.CULTIVATION_AND_FERTILIZATION;
    }
    if (viewType === VIEW_TYPES.OOCYTES_CRYOPRESERVATIONS) {
      return VIEW_TYPES.CRYOPRESERVATIONS;
    }
    if (viewType === VIEW_TYPES.OOCYTES_DEFROSTS) {
      return VIEW_TYPES.DEFROSTS;
    }
    return viewType;
  };

  React.useEffect(() => {
    getFoldersEffect(patientId);
  }, [patientId]);

  React.useEffect(() => {
    if (!patientId || !props.filter) return;

    setLoading(true);
    (async () => {
      const data = await props.onGetEmbryos({
        token: actualToken,
        filter: mapViewType(props.filter),
        patientId
      });

      tableDataById.current = keyBy(data, "id");
      const tableData = getTableData(props.filter, data, !hasEditAccess);
      setState(tableData);
      setLoading(false);
    })();
  }, [patientId, props.filter, hasEditAccess]);

  const renderedColumns = React.useMemo(() => {
    return props.customColumns
      ? getModifiedTableColumns(props.filter, props.customColumns)
      : getTableColumns(props.filter);
  }, [props.customColumns, props.filter]);

  const onRowDuplicate = async row => {
    try {
      const id = state[row][0].entityId;
      const data = await apiv2.embryos.copy({
        id,
        viewType: mapViewType(props.filter)
      });
      tableDataById.current[data.id] = data;
      setState(state => reindexStateRows([
        ...state,
        ...getTableData(props.filter, [data], !hasEditAccess)
      ]));
      scrollIntoLastTableRow();
    } catch (err) {
      setError(err.message);
    } finally {
      setError("");
    }
  };

  const shouldRenderAddButton = (
    props.filter === VIEW_TYPES.CULTIVATION_AND_FERTILIZATION ||
    props.filter ===  VIEW_TYPES.OOCYTES_CULTIVATION_AND_FERTILIZATION
  ) && hasEditAccess;

  const onRowDelete = row => {
    const id = state[row][0].entityId;
    apiv2.embryos.delete({ id })
      .then(() => {
        delete tableDataById.current[id];
        setState(state => reindexStateRows(state.filter((_, index) => index !== row)));
      })
      .catch(err => {
        setError(err.message);
      })
      .finally(() => {
        setError("");
        setSyncing(false);
      });
  };

  // TODO: Maybe refactor this?
  const onCellUpdate = async (row, cell, updateValue) => {
    try {
      setSyncing(true);
      const originalTableData = tableDataById.current[state[row][0].entityId];
      const tableData = constructTableRowPayload(state[row], originalTableData);

      const updateData = cell.processUpdateValue
        ? cell.processUpdateValue(updateValue, originalTableData)
        : { [cell.field]: cell.value };

      let payload = {
        ...originalTableData,
        ...tableData,
        view_type: VIEW_TYPES_FILTERS[mapViewType(props.filter)],
        ...updateData
      };

      const currentStatusUpdating =
        updateData?.current_status &&
        updateData.current_status !== originalTableData.current_status;

      if (!currentStatusUpdating) {
        payload = omit(payload, "current_status");
      }

      const serverReadyPayload = constructTableServerPayload(payload);
      const responseData = await apiv2.embryos.update({
        id: payload.id,
        payload: serverReadyPayload
      });

      tableDataById.current[tableData.id] = {
        ...tableDataById.current[tableData.id],
        ...responseData
      };

      setState(state => reindexStateRows(state.map((stateRow, index) => {
        if (index === parseInt(row, 10)) {
          const nextTableData = getTableData(props.filter, [responseData], !hasEditAccess)[0];
          return nextTableData;
        }
        return stateRow;
      })));
    } catch (err) {
      setError(err.message);
    } finally {
      setError("");
      setSyncing(false);
    }
  };

  const handlePaste = React.useCallback(
    (cells, selectedCells, copiedCells) => {
      setSyncing(true);

      const pasteSingleValueToMultipleCellsWithinColumn =
        cells.length === 1 && selectedCells.every(cell => cell.column === cells[0].column);

      const cellsToPaste = pasteSingleValueToMultipleCellsWithinColumn
        ? selectedCells
            .map(cell => ({ ...cell, value: cells[0].value }))
            .map(cell => ({ ...cell, from: copiedCells[0] || selectedCells[0] }))
        : cells.map((cell, i) => ({ ...cell, from: copiedCells[i] || selectedCells[i] }));

      const rowsToUpdate = cellsToPaste.reduce((acc, { row, column, from, value }) => {
        if (!acc[row]) {
          acc[row] = [];
        }

        return {
          ...acc,
          [row]: [
            ...acc[row],
            { ...state[row][column], value: value?.value, from: state[from.row][from.column] }
          ]
        };
      }, {});

      if (pasteSingleValueToMultipleCellsWithinColumn) {
        const cellToPaste = cells[0];
        const cellsToUpdateByIndexes = keyBy(
          selectedCells,
          ({ row, column }) => `${row},${column}`
        );

        setState(state =>
          state.map((row, i) =>
            row.map((cell, j) =>
              cellsToUpdateByIndexes[`${i},${j}`]
                ? { ...cell, value: cellToPaste.value.value }
                : cell
            )
          )
        );
      }

      const promises = Promise.all(
        Object.keys(rowsToUpdate).map(row => {
          const cells = rowsToUpdate[row];

          const originalData = tableDataById.current[state[row][0].entityId];
          const tableData = constructTableRowPayload(state[row], originalData);

          let payload = {
            ...originalData,
            ...tableData,
            view_type: VIEW_TYPES_FILTERS[mapViewType(props.filter)]
          };

          cells.forEach(cell => {
            if (ALL_FIELDS_CHECK_VALUE[cell.field] && ALL_FIELDS_CHECK_VALUE[cell.field](cell.value) || !ALL_FIELDS_CHECK_VALUE[cell.field]) {
              payload[cell.field] = cell.value;

              if (cell.from.processUpdate) {
                const originalFromData = tableDataById.current[cell.from.entityId];
                payload = { ...payload, ...cell.from.processUpdate(originalFromData) };
              }
            }
          });

          const currentStatusUpdating = cells
            .map(({ field }) => ({ [field]: "" }))
            .some(({ current_status }) => current_status !== undefined);

          if (!currentStatusUpdating) {
            payload = omit(payload, "current_status");
          }

          const serverReadyPayload = constructTableServerPayload(payload);
          return apiv2.embryos.update({
            id: serverReadyPayload.id,
            payload: serverReadyPayload
          });
        })
      );

      promises
        .then(responses => {
          const byId = keyBy(responses, "id");

          tableDataById.current = {
            ...tableDataById.current,
            ...byId
          };

          setState(state =>
            reindexStateRows(state.map(row =>
              byId[row[0].entityId]
                ? getTableData(props.filter, [byId[row[0].entityId]], !hasEditAccess)[0]
                : row
            ))
          );
        })
        .catch(err => {
          setError(err.message);
        })
        .finally(() => {
          setSyncing(false);
          setError("");
        });
    },
    [state, props.filter, actualToken, hasEditAccess]
  );

  const handleClear = React.useCallback(
    cells => {
      setSyncing(true);

      const ignoredFields = ["date_start"];
      const rowsFieldsToClear = cells.reduce((acc, { row, column }) => {
        if (!acc[row]) {
          acc[row] = [];
        }

        return {
          ...acc,
          [row]: [...acc[row], state[row][column]]
        };
      }, {});
      const promises = Promise.all(
        Object.keys(rowsFieldsToClear)
          .map(row => {
            const cells = rowsFieldsToClear[row];
            const originalTableData = tableDataById.current[state[row][0].entityId];
            const tableData = constructTableRowPayload(state[row], originalTableData);

            let payload = {
              ...originalTableData,
              ...tableData,
              view_type: VIEW_TYPES_FILTERS[mapViewType(props.filter)]
            };

            cells
              .filter(cell => !ignoredFields.includes(cell.field))
              .forEach(cell => {
                payload[cell.field] = "";

                if (cell.processUpdateValue) {
                  payload = { ...payload, ...cell.processUpdateValue("") };
                }
              });

            const serverReadyPayload = constructTableServerPayload(payload, originalTableData);

            return serverReadyPayload;
          })
          .map(payload =>
            apiv2.embryos.update({
              id: payload.id,
              payload
            })
          )
      );

      promises
        .then(responses => {
          const byId = keyBy(responses, "id");

          tableDataById.current = {
            ...tableDataById.current,
            ...byId
          };

          setState(state =>
            reindexStateRows(state.map(row =>
              byId[row[0].entityId]
                ? getTableData(props.filter, [byId[row[0].entityId]], !hasEditAccess)[0]
                : row
            ))
          );
        })
        .catch(err => {
          setError(err.message);
        })
        .finally(() => {
          setSyncing(false);
          setError("");
        });
    },
    [state, actualToken, props.filter, hasEditAccess]
  );

  const handleScroll = React.useCallback(() => {
    setScrollTop($containerRef.current.scrollTop);
  }, []);

  React.useEffect(() => {
    if (!loading && $containerRef.current) {
      if (window.innerHeight > 900) {
        $containerRef.current.style.maxHeight = `${window.innerHeight / 2}px`;
      } else {
        $containerRef.current.style.maxHeight = "320px";
      }
    }
  }, [loading]);

  React.useEffect(() => {
    const element = $containerRef.current;

    if (!loading && element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, handleScroll]);

  React.useEffect(() => {
    const timeoutId = scrollIntoLastTableRowTimeoutIdRef.current;

    return () => {
      clearTimeout(timeoutId);
    };
  });

  return (
    <>
      <EmbryosTableContext.Provider
        value={{
          onCellUpdate,
          onRowDelete,
          onRowDuplicate,
          hasEditAccess,
          tableScrollTop: scrollTop,
          data: state
        }}>
        {loading ? (
          <EmbryosTablePlaceholder />
        ) : (
          <Container ref={$containerRef}>
            <Spreadsheet
              Row={Row}
              data={state}
              Cell={Cell}
              columnLabels={renderedColumns}
              hideRowIndicators={true}
              ColumnIndicator={ColumnIndicator}
              // onChange={setState}
              onPaste={handlePaste}
              onClear={handleClear}
              autoPadRowsOnPaste={false}
            />
          </Container>
        )}
      </EmbryosTableContext.Provider>
      <Footer justifyContent={shouldRenderAddButton ? "space-between" : "flex-end"}>
        {shouldRenderAddButton && (
          <AddRows>
            <button className="sc-add-rows-caption" onClick={onAdd} disabled={addingRows}>
              <Plus />
              <span style={{ marginLeft: 6 }}>Добавить строки</span>
            </button>
            <RowsNumber
              min={minRowsCountToAdd}
              max={maxRowsCountToAdd}
              value={rowsCountValue}
              type="number"
              onInput={e => {
                setRowsCountValue(e.target.value);
              }}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  await onAdd();
                }
              }}
              onBlur={() => {
                setRowsCountValue(rowsCount.toString());
              }}
            />
          </AddRows>
        )}
        <div style={{ marginLeft: shouldRenderAddButton ? 8 : 0 }}>
          <TableSaveIndicator saving={syncing} error={error} />
        </div>
      </Footer>
    </>
  );
};
