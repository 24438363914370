import { useEffect } from "react";

const events = ["mouseup", "keydown", "scroll", "mousemove"];

const initialData = {
  time_spent: 0,
  mouse_clicks_count: 0,
  button_link_clicks_count: 0,
  key_presses_count: 0,
  scrolling_count: 0,
  mouse_movement: 0
};

let countersData = { ...initialData };

const eventsHandler = e => {
  if (e.type === "mouseup") {
    countersData.mouse_clicks_count += 1;
    if (e.target.nodeName === "BUTTON" || e.target.nodeName === "A") {
      countersData.button_link_clicks_count += 1;
    }
  } else if (e.type === "keydown") {
    countersData.key_presses_count += 1;
  } else if (e.type === "scroll") {
    countersData.scrolling_count += 1;
  } else if (e.type === "mousemove") {
    countersData.mouse_movement += 1;
  }
};

const setListeners = () => {
  events.forEach(eventType => {
    if (typeof document !== "undefined") {
      document.addEventListener(eventType, eventsHandler);
    }
  });
};

const removeListeners = () => {
  events.forEach(eventType => {
    if (typeof document !== "undefined") {
      document.removeEventListener(eventType, eventsHandler);
    }
  });
};

export const useDomEventsCounter = () => {
  useEffect(() => {
    setListeners();
    const timer = setInterval(() => {
      if (typeof document !== "undefined" && !document.hidden) {
        countersData.time_spent += 1;
      }
    }, 1000);

    return () => {
      removeListeners();
      clearInterval(timer);
      countersData = { ...initialData };
    };
  }, []);

  return countersData;
};
