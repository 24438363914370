import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Survey {
  apiService;
  presetFilterParams;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  getSurveyResultsForIdList({filter_params, meta = {}, values_params, order_by_params, annotate_mtm_params}) {
    values_params = values_params.filter((item) => item !== 'user__doctor__organizations');
    return this.apiService.post(`analytic/survey/result/list`, {}, {filter_params, current_page: meta.current_page, per_page: meta.per_page, values_params, order_by_params, annotate_mtm_params});
  }

  postSurveyResult(data) {
    return this.apiService.post(`analytic/survey/result`, {}, data);
  }

  getSurveyResultById(id) {
    return this.apiService.get(`analytic/survey/result`, { id: id });
  }

  getENPSDiagram(filters) {
    return this.apiService.post(`analytic/survey/widgets/employees/enps`, {}, filters);
  }

  getENPSDiagram(filters) {
    return this.apiService.post(`analytic/survey/widgets/employees/enps`, {}, filters);
  }
}

export default new Survey();
