import React from "react";

import styled, { css } from "styled-components";
import { colors } from "../../../styleguide/colors";
import { notes } from "../constants/fillers";

export const Container = styled.div`
  width: 1025px;
`;

export const H2 = styled.h2`
  box-sizing: border-box;
  padding: 32px 0 8px;
  text-align: center;
  font-size: 25px;
  line-height: 32px;

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`;

export const TextBlock = styled.div`
  box-sizing: border-box;
  font-size: 17px;
  line-height: 24px;
  padding: 8px 0;

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`;

export const Row = styled.tr``;

export const TableBody = styled.tbody``;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  ${({ fixed = true }) =>
    fixed &&
    css`
      table-layout: fixed;
    `}

  ${({ columnWidth }) =>
    columnWidth &&
    css`
      td {
        width: ${columnWidth}px;
      }
    `}
`;

export const Cell = styled.td`
  box-sizing: border-box;
  height: 44px;
  border: 1px solid ${colors.alto};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  padding: 0 12px;
  vertical-align: middle;
  word-break: break-word;

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}

  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 500;
    `}

  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
`;

export const WithoutTopBorderCell = styled(Cell)`
  border-top: 0;
`;

export const CenterCell = styled(Cell).attrs(() => ({ center: true }))``;

export const HeaderCell = styled(Cell.withComponent("th"))`
  border-bottom: 1px solid ${colors.magenta};
`;

export const NotesContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: end;
  height: 64px;
  padding: 8px 0;
  font-size: 17px;
  line-height: 24px;
`;

export const SignContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 134px;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.darkBlack};
  page-break-inside: avoid;

  &::after {
    content: "";
    border-bottom: 1px solid ${colors.darkGray};
    width: 200px;
    margin-top: 16px;
    margin-left: 10px;
  }
`;

export const Notes = () => <NotesContainer>{notes}</NotesContainer>;

export const Sign = ({ specialization = "Врач", doctor }) => (
  <SignContainer>
    {specialization}: {doctor && doctor}
  </SignContainer>
);
