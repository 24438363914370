//@flow
import React from "react";

import { HeadingH3, PNormal } from "../styleguide/typography";
import { useModalEscape } from "../modal/useModalEscape";
import accountStore from "../../stores/accountStore";
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from "../buttons/AlertButton";
import { AlertWarning } from "../alert-warning/AlertWarning";

export const AccountDeleteServiceModal = (props: { hide: () => void }) => {
  const { hide } = props;

  useModalEscape(hide);

  const handlerDelete = async () => {
    const isDelete = await accountStore.deleteDoctorService();
    if (isDelete) {
      accountStore.setShowAccountDeleteServiceModal(false);
    }
  };

  return (
    <AlertWarning
      containerModifiers="small"
      isNeedConfirm={true}
      sendConfirm={value => accountStore.setShowAccountDeleteServiceModal(value)}
      heading={<HeadingH3 modifiers="center">Удалить услугу?</HeadingH3>}
      description={
        <PNormal modifiers={["left", "center"]}>
          Пациенты больше не смогут записаться на эту услугу. Запланированные приемы с этой услугой
          останутся. Их можно удалить вручную через расписание.
        </PNormal>
      }
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отменить</AlertWarningWhiteButton>
      )}
      confirmButton={() => (
        <AlertWarningPrimaryButton onClick={() => handlerDelete()}>
          Удалить
        </AlertWarningPrimaryButton>
      )}
    />
  );
};
