// @flow
import React from "react";

export const PrescriptionTableHead = (props: { children?: React$Node }) => {
  return (
    <thead>
      <tr>{props.children}</tr>
    </thead>
  );
};
