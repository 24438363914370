import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import bonusSchemaStore from "../../../stores/bonusSchemaStore";

export const ModelStand = (props) => {
  const group = useRef()
  let currentAnimation = 0;
  const { nodes, materials, animations } = useGLTF('/DoctorStand.glb')
  const { actions } = useAnimations(animations, group)
  const [rotation, setRotation] = useState(-1.4);

    useEffect(() => {
        if (props.run) {
            // Math.random() > 0.5 ? 1 : 2
          actions[Object.keys(actions)[0]].play();
        } else {
          actions[Object.keys(actions)[2]].play();
            const interval = setInterval(() => {
                setRotation((oldValue) => {
                    oldValue = oldValue + 0.1;
                    if (oldValue > -0.2) {
                        clearInterval(interval);
                        actions[Object.keys(actions)[0]].stop();
                        actions[Object.keys(actions)[2]].stop();
                        actions[Object.keys(actions)[1]].play();
                        return -0.2;
                    }
                    return oldValue;
                });
            }, 1000 / 30);
        }
    }, [props.run]);

  return (
    <group ref={group} dispose={null} onClick={(e) => {
      actions[Object.keys(actions)[4]].play();
      setTimeout(() => {
          actions[Object.keys(actions)[4]].stop();
          actions[Object.keys(actions)[1]].play();
      }, 2400);
      e.stopPropagation();
      //   console.log(actions)
      // if (currentAnimation !== 0) {
      //   console.log('stop')
      //   actions[Object.keys(actions)[currentAnimation - 1]].stop();
      // } else {
      //   actions[Object.keys(actions)[4]].stop();
      // }
      // console.log('stop')
      //   console.log(currentAnimation);
      // actions[Object.keys(actions)[currentAnimation]].play();
      // if (currentAnimation === 4) {
      //   currentAnimation = 0;
      // } else {
      //   ++currentAnimation;
      // }
      // e.stopPropagation();
    }}>
      <group name="Scene">
        {/*<group name="Armature001" rotation={[props.x,props.y,props.z]} scale={0.0085}>*/}
        <group name="Armature001" rotation={[90, 0, rotation]} scale={0.0085}>
            <group name="DoctorStand">
            <skinnedMesh
              name="Меш013"
              geometry={nodes.Меш013.geometry}
              material={materials['белый.003']}
              skeleton={nodes.Меш013.skeleton}
            />
            <skinnedMesh
              name="Меш013_1"
              geometry={nodes.Меш013_1.geometry}
              material={materials['волосы.003']}
              skeleton={nodes.Меш013_1.skeleton}
            />
            <skinnedMesh
              name="Меш013_2"
              geometry={nodes.Меш013_2.geometry}
              material={materials['body.003']}
              skeleton={nodes.Меш013_2.skeleton}
            />
            <skinnedMesh
              name="Меш013_3"
              geometry={nodes.Меш013_3.geometry}
              material={materials['boots.003']}
              skeleton={nodes.Меш013_3.skeleton}
            />
            <skinnedMesh
              name="Меш013_4"
              geometry={nodes.Меш013_4.geometry}
              material={materials['boots2.003']}
              skeleton={nodes.Меш013_4.skeleton}
            />
            <skinnedMesh
              name="Меш013_5"
              geometry={nodes.Меш013_5.geometry}
              material={materials['pants.003']}
              skeleton={nodes.Меш013_5.skeleton}
            />
            <skinnedMesh
              name="Меш013_6"
              geometry={nodes.Меш013_6.geometry}
              material={materials['Материал.003']}
              skeleton={nodes.Меш013_6.skeleton}
            />
            <skinnedMesh
              name="Меш013_7"
              geometry={nodes.Меш013_7.geometry}
              material={materials.белый}
              skeleton={nodes.Меш013_7.skeleton}
            />
          </group>
          <primitive object={nodes.mixamorigHips} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/DoctorStand.glb')
