import _ from "lodash";
import photo1 from "../../../assets/image/corporate-portal/photosDoctor/photo1.png";
import photo2 from "../../../assets/image/corporate-portal/photosDoctor/photo2.png";
import photo3 from "../../../assets/image/corporate-portal/photosDoctor/photo3.png";
import photo4 from "../../../assets/image/corporate-portal/photosDoctor/photo4.png";
import photo5 from "../../../assets/image/corporate-portal/photosDoctor/photo5.png";
import photo6 from "../../../assets/image/corporate-portal/photosDoctor/photo6.png";
import photo7 from "../../../assets/image/corporate-portal/photosDoctor/photo7.png";
import photo8 from "../../../assets/image/corporate-portal/photosDoctor/photo8.png";
import photo9 from "../../../assets/image/corporate-portal/photosDoctor/photo9.png";

const photos = [photo1, photo2, photo3, photo4, photo5, photo6, photo7, photo8, photo9];

const firstNames = [
  "Анастасия",
  "Александра",
  "Анна",
  "Виктория",
  "Дарья",
  "Екатерина",
  "Елизавета",
  "Елена",
  "Ирина",
  "Кристина",
  "Мария",
  "Надежда",
  "Наталья",
  "Ольга",
  "Полина",
  "Светлана",
  "София",
  "Татьяна",
  "Юлия",
  "Яна"
];

const middleNames = [
  "Александровна",
  "Алексеевна",
  "Андреевна",
  "Викторовна",
  "Дмитриевна",
  "Евгеньевна",
  "Ивановна",
  "Константиновна",
  "Максимовна",
  "Николаевна",
  "Олеговна",
  "Петровна",
  "Романовна",
  "Сергеевна",
  "Степановна",
  "Тимофеевна",
  "Фёдоровна",
  "Филипповна",
  "Юрьевна",
  "Яковлевна"
];

const lastNames = [
  "Абрамова",
  "Алексеева",
  "Афанасьева",
  "Богданова",
  "Васильева",
  "Гаврилова",
  "Дмитриева",
  "Егорова",
  "Жукова",
  "Зайцева",
  "Ильина",
  "Кириллова",
  "Козлова",
  "Королёва",
  "Кузнецова",
  "Лазарева",
  "Морозова",
  "Павлова",
  "Романова",
  "Семёнова"
];

const birthdate = [
  "1998-03-24",
  "1997-12-06",
  "1996-05-21",
  "1995-08-31",
  "1994-11-09",
  "1993-02-17",
  "1992-04-29",
  "1991-07-08",
  "1990-09-20",
  "1989-12-30",
  "1988-01-13",
  "1987-03-26",
  "1986-05-07",
  "1985-06-18",
  "1984-08-28",
  "1983-10-09",
  "1982-12-21",
  "1981-01-31",
  "1980-03-12",
  "1979-04-23"
];

const cities = [
  "Москва",
  // "Санкт-Петербург",
  "Тверь",
  "Уфа"
  // "Сочи",
  // "Краснодар",
  // "Пермь",
  // "Пенза",
  // "Воронеж",
  // "Белгород",
  // "Тула",
  // "Калуга"
];

export const clinicsOfOrganizations = [
  {
    city: "Москва",
    organizations: [
      {
        clinic: "Долгоруковская",
        specializations: [
          "Аллерголог",
          "Андролог",
          "Анестезиолог",
          "Ведение беременности",
          "Врач пренатальной диагностики",
          "Гастроэнтеролог",
          "Гематолог",
          "Гематолог-онколог",
          "Генетик",
          "Гепатолог",
          "Специализация 1",
          "Специализация 2",
          "Специализация 3",
          "Специализация 4",
          "Специализация 5",
          "Специализация 6",
          "Специализация 7",
          "Специализация 8",
          "Специализация 9",
          "Специализация 10",
          "Специализация 11",
          "Специализация 12",
          "Специализация 13",
          "Специализация 14",
          "Специализация 15",
          "Специализация 16",
          "Специализация 17",
          "Специализация 18",
          "Специализация 19",
          "Специализация 20",
          "Специализация 21",
          "Специализация 22",
          "Специализация 23",
          "Специализация 24"
        ]
      },
      {
        clinic: "Ленинский",
        specializations: ["Аллерголог", "Андролог", "Анестезиолог", "Ведение беременности"]
      },
      {
        clinic: "Мичуринский",
        specializations: ["Аллерголог", "Андролог", "Анестезиолог", "Ведение беременности"]
      },
      {
        clinic: "Рассвет",
        specializations: ["Аллерголог", "Андролог", "Анестезиолог", "Ведение беременности"]
      },

      { clinic: "Клиника 12", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 13", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 14", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 15", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 16", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 17", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 18", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 19", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 20", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 21", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 22", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 23", specializations: ["Анестезиолог"] },
      { clinic: "Клиника 24", specializations: ["Анестезиолог"] }
    ]
  },
  {
    city: "Тверь",
    organizations: [
      { clinic: "Тверь - Вагжановский", specializations: ["Гастроэнтеролог"] },
      { clinic: "Тверь - Горького", specializations: ["Гастроэнтеролог"] },
      { clinic: "Тверь - Базановой", specializations: ["Гастроэнтеролог"] },
      { clinic: "Тверь - Спартака", specializations: ["Гастроэнтеролог"] }
    ]
  },
  {
    city: "Уфа",
    organizations: [
      {
        clinic: "УФА УД Кирова",
        specializations: ["Гастроэнтеролог", "Гематолог", "Гематолог-онколог"]
      },
      {
        clinic: "Уфа - Генерала Кусимова",
        specializations: ["Гастроэнтеролог", "Андролог", "Ведение беременности"]
      },
      { clinic: "Уфа - Кирова", specializations: ["Гастроэнтеролог", "Гематолог-онколог"] },
      { clinic: "Уфа - Комсомольская", specializations: ["Гастроэнтеролог", "Анестезиолог"] }
    ]
  }
];

export const dataOfDoctors = new Array(10).fill().map((value, index) => {
  const randomValue6 = () => Math.floor(Math.random() * 6);
  const randomValue12 = () => Math.floor(Math.random() * 12);
  const randomValue20 = () => Math.floor(Math.random() * 20);
  const isShowPhoto = (index + 1) % 2 === 0 || (index + 1) % 3 === 0 || (index + 1) % 5 === 0;
  const first_name = firstNames[randomValue20()];
  const middle_name = middleNames[randomValue20()];
  const last_name = lastNames[randomValue20()];
  const citiesDoctor = [cities[Math.floor(Math.random() * 3)]];

  return {
    id: _.uniqueId(),
    photo: isShowPhoto ? photos[randomValue6()] : "",
    fullName: `${last_name} ${first_name} ${middle_name}`,
    contact_information: {
      first_name: first_name,
      middle_name: middle_name,
      last_name: last_name,
      birthdate: birthdate[randomValue20()],
      work_phone_number: "+79999999999",
      personal_phone_number: "+79999999999",
      telegram: "@telegramNickname",
      whats_app: "+79999999999",
      city: citiesDoctor[0]
    },
    working_information: {
      subdivision: `Название подразделения ${index + 1}`,
      type_medical_staff: `Название типа мед персонала ${index + 1}`,
      position: `Название должности ${index + 1}`,
      specialization: `Название направления деятельности ${index + 1}`,
      cities: citiesDoctor,
      organizations:
        citiesDoctor[0] === "Москва"
          ? ["Долгоруковская"]
          : citiesDoctor[0] === "Тверь"
          ? ["Тверь - Вагжановский"]
          : citiesDoctor[0] === "Уфа"
          ? ["Уфа - Кирова"]
          : [],
      specializations:
        citiesDoctor[0] === "Москва"
          ? ["Специализация 3"]
          : citiesDoctor[0] === "Тверь"
          ? ["Гастроэнтеролог"]
          : citiesDoctor[0] === "Уфа"
          ? ["Гастроэнтеролог"]
          : []
    },
    additional_information: {
      subordinates:
        (index + 1) % 2 === 0
          ? [
              {
                photo: "",
                first_name: "Тестова1",
                middle_name: "Тестовичева1",
                last_name: "Тестовна1",
                position: "Название должности"
              }
            ]
          : (index + 1) % 3 === 0
          ? [
              {
                photo: "",
                first_name: "Тестова2",
                middle_name: "Тестовичева2",
                last_name: "Тестовна2",
                position: "Название должности"
              },
              {
                photo: "",
                first_name: "Тестова3",
                middle_name: "Тестовичева3",
                last_name: "Тестовна3",
                position: "Название должности"
              }
            ]
          : [],
      supervisor:
        (index + 1) % 2 === 0
          ? []
          : [
              {
                photo: "",
                first_name: "Тестова4",
                middle_name: "Тестовичева4",
                last_name: "Тестовна4",
                position: "Название должности"
              }
            ]
    },
    interests: []
  };
});
