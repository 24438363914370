// @flow
import React from "react";
import styled from "styled-components";
import { FormSection } from "../form/FormSection";
import type { Section } from "../../domain/entities/Section";

const ServicesFormSectionContainer = styled.div``;

const ServicesFormChildrenContainer = styled.div`
  margin-top: 8px;
`;

type ServicesFormSectionProps = $Diff<
  React$ElementConfig<typeof FormSection>,
  { expand_by_default: $ElementType<Section, "expand_by_default"> }
>;

export const ServicesFormSection = (props: ServicesFormSectionProps) => {
  return (
    <FormSection
      {...props}
      expand_by_default
      overrides={{
        Container: {
          component: ServicesFormSectionContainer
        },
        ChildrenContainer: {
          component: ServicesFormChildrenContainer
        },
        ArrowButton: {
          component: () => null
        },
        HeaderContainer: {
          props: {
            onClick: null,
            as: "div",
            "aria-label": null
          }
        }
      }}
    />
  );
};
