// @flow
import React from "react";
import styled from "styled-components";

import { PNormal } from "../styleguide/typography";
import type { Organization } from "../../domain/entities/Organization";

const Container = styled.div`
  display: flex;
  margin-top: 11px;
`;

export const AppointmentPrintableContacts = (props: { organization: Organization }) => {
  return (
    <Container>
      <PNormal>{props.organization.info}</PNormal>
    </Container>
  );
};
