// @flow
import * as React from "react";

import { DefaultInput } from "./DefaultInput";
import MaskedInput from "react-text-mask";
import {parsePhoneNumberFromString} from 'libphonenumber-js/min';

type PhoneInputProps = React$ElementConfig<typeof DefaultInput>;

export const isValidNumberFunction = value => {
  if (typeof value !== "string") {
    return false;
  }
  const obj = parsePhoneNumberFromString(value);
  let isValid = obj ? obj.isValid() : false;

  return isValid;
};

export const PhoneInput = (props: PhoneInputProps) => {
  return (
    <DefaultInput
      {...props}
      overrides={{
        ...props.overrides,
        Input: {
          props: {
            as: MaskedInput,
            mask: function(rawValue) {
              if (rawValue[0] === '8') {
                return ["8", "(", /[1-9]/, /\d/, /\d/, ")", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/];
              }
              return ["+", "7", "(", /[1-9]/, /\d/, /\d/, ")", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/];
            },
            guide: true,
            placeholder: "+7(___)___-__-__",
            ...((props.overrides && props.overrides.Input?.props) || {})
          }
        }
      }}
    />
  );
};
