import React, {useState } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { WidthProvider, Responsive } from "react-grid-layout";
import { getLocalStorage, setLocalStorage } from "../../../utils/localStorageUtils";
const ResponsiveReactGridLayout = WidthProvider(Responsive);
import CloseIcon from '@mui/icons-material/Close';
import styled from "styled-components";

const Component = styled.div`
    position: relative;
`;

const CloseElementIcon = styled(CloseIcon)`
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    opacity: 0.3;
    z-index: 101;
    
    &:hover {
        opacity: 1;
    }
`;

export const GridLayoutComponent = (props: {
  children?: React.Node,
  rowHeight?: Number,
  cols?: () => {},
  layouts?: () => {},
  changeLayouts?: () => any,
  keyLocalStorage?: String,
  onCloseHandler?: () => any,
  enableEditingMode?: boolean
}) => {
  const defaultLayout = getLocalStorage(props.keyLocalStorage) || props.layouts || {
    lg: [],
    md: [],
    sm: [],
    xs: [],
    xxs: []
  };
  const [layouts, setLayouts] = useState(defaultLayout);
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");

  const onLayoutChange = (layout, layouts) => {
    setLayouts({...layouts});

    if (props.changeLayouts) {
      props.changeLayouts(layouts);
    }
    setLocalStorage(props.keyLocalStorage, layouts);
  }

  const onChangeBreakpoint = (breakpoint) => {
    setLocalStorage(`${props.keyLocalStorage}Breakpoint`, breakpoint);
    setCurrentBreakpoint(breakpoint);
  }

  const onCloseHandler = (child) => {
    setLayouts(Object.keys(layouts).reduce((acc, layoutName) => {
      acc[layoutName] = layouts[layoutName].filter((item) => item.i !== child.key)
      return acc;
    }, {}))
    props.onCloseHandler(child.props)
  }

  return (
    <ResponsiveReactGridLayout
      className="responsive-grid-layout"
      layouts={layouts}
      onLayoutChange={onLayoutChange}
      onChangeBreakpoint={onChangeBreakpoint}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      style={{width: '100%'}}
      autoSize={true}
      draggableCancel={".material-table, .MuiDialog-root, .responsive-grid-layout__close, .label-with-filters-modal, .chart-component, .chart-component-legends"}
      isResizable={props.enableEditingMode}
      isDraggable={props.enableEditingMode}
    >
      {!!props.children && props.children.map((child, index) => (
        <Component key={child.key} data-grid={layouts[currentBreakpoint][index] || child.props.grid}>
          {props.enableEditingMode && props.onCloseHandler && (
            <CloseElementIcon className={'responsive-grid-layout__close'} onClick={() => onCloseHandler(child)} />
          )}
          {child}
        </Component>
      ))}
    </ResponsiveReactGridLayout>
  )
}