// @flow
import * as React from "react";

import { PNormal } from "../styleguide/typography";

export const TextView = (props: { children: string }) => (
  <PNormal as="span" modifiers={"left"}>
    {props.children}
  </PNormal>
);
