import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Notifications {
  apiService;
  constructor() {
    this.apiService = new ApiService('/api', null, null, API_URL);
  }

  createPatientReminder(payload) {
    return this.apiService.post(`notifications`, {}, {
      appointment_id: payload.appointmentId,
      date_send: payload.date,
      text: payload.text
    });
  }

  getPatientReminders() {
    return this.apiService.get(`notifications`);
  }

  getPatientRemindersUrl(token) {
    const url = new URL(`${API_URL}/api/ws/notifications/`);
    url.searchParams.append("token", token);
    url.protocol = "wss:";

    return url.toString();
  }

  setPatientReminderRead(id) {
    return this.apiService.patch(`notifications/${id}/read`);
  }
}

export default new Notifications();
