// @flow
import type { FieldTextArea } from "../../domain/entities/Field";
import * as React from "react";
import { useStore } from "effector-react";
import { useFieldValue } from "./useFieldValue";
import { setFieldValue } from "../../stores/appointmentFieldValues";
import type { LayoutFieldValue, TAppointment_id } from "../../domain/entities/Appointment";
import { getTextValue } from "./getTextValue";
import { getField_id } from "../../domain/entities/Field";
import {
  currentFieldStore,
  openShortcuts,
  createShortcuts,
  fieldsWithShortcutsStore
} from "../../stores/shortcuts/fieldShortcuts";
import * as R from "ramda";
import { ShortcutsTextarea } from "../shortcuts/ShortcutsTextarea";
import { useErrorHighlighting } from "./useErrorHighlighting";

export const TextareaWidget = (props: {
  field: FieldTextArea,
  tabIndex?: number,
  showError?: boolean,
  appointment_id: TAppointment_id,
  layoutFieldValue?: LayoutFieldValue
}) => {
  const { appointment_id, field, tabIndex, layoutFieldValue } = props;
  const fieldValueResult = useFieldValue(appointment_id, field.id);
  const value = getTextValue(fieldValueResult, layoutFieldValue);

  const isCurrent = useStore(currentFieldStore.map(store => store && store === field.id));
  const isHaveShortcuts = useStore(fieldsWithShortcutsStore.map(R.has("" + field.id)));

  let errorField;

  if (field.required) {
    const { error } = useErrorHighlighting(
      value,
      appointment_id,
      getField_id(field),
      null,
      field.required
    );
    errorField = error;
  }

  return (
    <ShortcutsTextarea
      value={value}
      tabIndex={tabIndex}
      error={(props.showError || value) && errorField ? errorField.value : false}
      handleChange={newValue => {
        setFieldValue({
          field_type: field.field_type,
          appointment_id,
          field_id: field.id,
          value: newValue
        });
      }}
      storedValue={fieldValueResult && (fieldValueResult.value: any)}
      isCurrent={!!isCurrent}
      isHaveShortcuts={isHaveShortcuts}
      onCreateShortcuts={() => createShortcuts(getField_id(field))}
      onOpenShortcuts={() => openShortcuts(getField_id(field))}
    />
  );
};
