// @flow
import * as React from "react";
import styled from "styled-components";
import o, { type ExtractOverridesProps } from "react-overrides";
import { ButtonCheckbox } from "./ButtonCheckbox";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
`;

const CheckboxContainer = styled.div`
  margin-right: 10px;
  
  &:last-child {
    margin-right: 0;
  }
`;

const OverridableComponents = {
  Container,
  ButtonCheckbox,
  CheckboxContainer
};

export type TItems = { [string]: { name: string } };

type TButtonCheckboxGroupProps<T: TItems> = {
  tabIndex?: number,
  items: T,
  checked: Array<$Keys<T>>,
  order?: Array<$Keys<T>>,
  onChange?: ($Keys<T>, boolean) => mixed,
  onClick?: ($Keys<T>) => mixed,
  overrides?: ExtractOverridesProps<typeof OverridableComponents>
};

export const ButtonCheckboxGroup = <T: TItems>(props: TButtonCheckboxGroupProps<T>) => {
  const { tabIndex, items, checked, order, onChange, onClick, buttonIcon } = props;

  const itemOrder = order || Object.keys(items);
  return (
    <Container {...o}>
      {itemOrder.map(itemKey => {
        const itemChecked = checked.indexOf(itemKey) >= 0;
        return (
          <CheckboxContainer key={itemKey}>
            <ButtonCheckbox
              {...o}
              tabIndex={tabIndex}
              text={items[itemKey].name}
              checked={itemChecked}
              buttonIcon={buttonIcon && buttonIcon(items[itemKey])}
              onChange={value => onChange && onChange(itemKey, value)}
              onClick={() => onClick && onClick(itemKey)}
            />
          </CheckboxContainer>
        );
      })}
    </Container>
  );
};
