// @flow
import { useEffect } from "react";
import { useState } from "react";
import { useStore } from "effector-react";
import { useDebounce } from "use-debounce";

import { tokenStore } from "../../stores/auth";
import type { MedicalFile } from "../../domain/entities/MedicalFile";
import {useApiRequest} from '../../apiv2/useApiRequest';
import apiv2 from '../../apiv2';

type TUseMedicalFileSelectResult = {
  value: string,
  setValue: string => mixed,
  items: Array<MedicalFile>
};

export const useMedicalFileSelect = (defaultValue?: string): TUseMedicalFileSelectResult => {
  const [value, setValue] = useState(defaultValue || "");

  const [searchText, setSearchText] = useState(value);
  const [searchTextDebounced] = useDebounce(searchText, 500);

  useEffect(() => {
    searchTextDebounced.length >= 3 ? setValue(searchTextDebounced) : setValue("");
  }, [searchTextDebounced]);

  const result = useApiRequest(async () => {
    return await apiv2.medicalFiles.get({ search: value });
  }, [ value ]);

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  return {
    value: searchText,
    setValue: setSearchText,
    items: result.data || []
  };
};
