// @flow
import { useEffect } from "react";
import useReactRouter from "use-react-router";

export const useAfterPrint = (url: string) => {
  const { history } = useReactRouter();

  const afterPrint = () => {
    setTimeout(() => {
      history.replace(url);
    });
  };

  useEffect(() => {
    window.addEventListener("afterprint", afterPrint, false);
    return () => window.removeEventListener("afterprint", afterPrint);
  }, [url]);
};
