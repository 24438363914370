// @flow
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { rgba } from "polished";
import _ from "lodash";
import {
  AsideHeader,
  AsideContainer,
  AsideScrollableContentContainer,
  AsideLayout
} from "../layouts/AsideLayout";
import { BackToScheduleButton } from "../appointment-switch-page/BackToScheduleButton";
import { SpecialtyList } from "./SpecialtyList";
import { GeneralScheduleContent } from "./GeneralScheduleContent";
import { Select } from "../form-elements/Select";
import { useStore } from "effector-react";
import { useOrganizationSelect } from "../schedule-insert-modal/useOrganizationSelect";
import { useGeneralScheduleUpdates } from "./useGeneralScheduleUpdate";
import {
  favouritesKey,
  scheduleStore,
  doctorsStore,
  activeSpecialityStore,
  setActiveOrganization,
  beforeReloadData,
  getFavoriteDoctors
} from "./fullScheduleStore";
import doctorsMobxStore from "../../stores/doctorsStore";
import { colors } from "../styleguide/colors";
import type { DoctorSpeciality, Doctor } from "../../domain/entities/Doctor";
import { useMediaQuery } from "@mui/material";
import { setVoximplantSupportData } from "../../utils/voximplantSupport";

const ClinicHeading = styled.div`
  font-size: 17px;
  line-height: 23px;
  margin: 0 5px;
`;

const ClinicSelectContainer = styled.div`
  padding: 0 20px 18px 20px;
  background-color: ${rgba(colors.gray100, 0.3)};
`;

export const GeneralSchedulePage = observer(() => {
  const isMobileDevice = useMediaQuery('(max-width: 768px)');
  const schedule = useStore(scheduleStore);
  const { isLoading: isDoctorsLoading, doctors } = useStore(doctorsStore);

  const activeSpeciality = useStore(activeSpecialityStore);

  useEffect(() => {
    (async () => await doctorsMobxStore.fetchFavouriteDoctorsList())();
  }, []);

  useGeneralScheduleUpdates();

  const getAllDoctorSpecialities = (doctors: Doctor[]): DoctorSpeciality[] => {
    return _.uniqBy(
      _.flattenDeep(
        doctors.map(doctor => {
          return doctor.specialities;
        })
      ),
      "id"
    );
  };

  const organizationItemsOrder = organizationItems => {
    if (organizationItems) {
      const organizationsEntries = Object.entries(organizationItems);
      organizationsEntries.sort(([, a], [, b]) => {
        // $FlowFixMe
        const aValue = a.name.toLowerCase();
        // $FlowFixMe
        const bValue = b.name.toLowerCase();

        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      });

      return organizationsEntries.map(([key]) => key);
    }

    return [];
  };

  const filterScheduleByDoctorsTimeslots = schedule => {
    return schedule
      .map(card => {
        const doctorsHaveCorrectScheduleStep = card.doctors.reduce(
          (acc, next) =>
            acc || (next.doctor.schedule_step % 15 === 0 || next.doctor.schedule_step % 20 === 0 || next.doctor.schedule_step === 10 ),
          false
        );

        if (!doctorsHaveCorrectScheduleStep) {
          return null;
        }

        return card;
      })
      .filter(Boolean);
  };

  const specialities = getAllDoctorSpecialities(doctors);

  const {
    items: organizationItems,
    active: activeOrganization,
    onSelect: onSelectOrganization
  } = useOrganizationSelect(); //{ active: beforeReloadData.activeOrganization ? beforeReloadData.activeOrganization : "" }

  const organizationsOrder = organizationItemsOrder(organizationItems);

  useEffect(() => {
    if (organizationItems) {
      const organizationsKeys = Object.keys(organizationItems);
      if (!activeOrganization && organizationsKeys.length) {
        if (onSelectOrganization) {
          if (beforeReloadData.activeOrganization) {
            onSelectOrganization(beforeReloadData.activeOrganization);
            // $FlowFixMe
            setActiveOrganization(beforeReloadData.activeOrganization);
          } else {
            onSelectOrganization(organizationsKeys[0]);
            setActiveOrganization(organizationsKeys[0]);
          }
        }
      }
    }
  }, [organizationItems]);

  useEffect(() => {
    if (activeOrganization && activeSpeciality) {
      const organization = Object.values(organizationItems).find(
        // $FlowFixMe
        organization => organization.id === parseInt(activeOrganization)
      );

      const speciality = Object.values(specialities).find(
        // $FlowFixMe
        speciality => speciality?.id === activeSpeciality
      );

      const voximplantData = {
        "Active organization ID": organization?.id || null,
        "Active organization name": organization?.name || null,
        "Active speciality": favouritesKey === activeSpeciality ? "Избранное" : speciality ? speciality?.name : null
      };

      setVoximplantSupportData({
        client_email: voximplantData,
        ...window.VoxKitWidgetSettings.client_data
      }, false, true);
    }
    return () => {
      setVoximplantSupportData({
        client_email: {}
      }, false, true);
    };
  }, [activeOrganization, activeSpeciality, organizationItems, specialities]);

  const aside = (
    <AsideContainer width={248}>
      {!isMobileDevice && (
        <AsideHeader>
          <BackToScheduleButton />
        </AsideHeader>
      )}
      <ClinicSelectContainer>
        {organizationItems && Object.keys(organizationItems).length > 1 ? (
          <Select
            {...{
              items: organizationItems,
              active: activeOrganization,
              order: organizationsOrder,
              onSelect: item => {
                if (onSelectOrganization) {
                  onSelectOrganization(item);
                }
                setActiveOrganization(item);
              }
            }}
            containerStyle={{
              backgroundColor: "transparent"
            }}
            style={{
              height: "auto",
              padding: "12px 18px",
              background: "transparent",
              border: `1px solid rgba(0, 0, 0, 0.2)`
            }}
            headingStyle={{
              fontFamily:
                'Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              fontSize: "17px",
              lineHeight: "23px",
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
            arrowStyle={{
              marginLeft: "8px"
            }}
            placeholder={"Клиника"}
            optionsModifiers={["overflowScroll"]}
          />
        ) : (
          <ClinicHeading>
            {activeOrganization &&
              organizationItems &&
              organizationItems[activeOrganization] &&
              organizationItems[activeOrganization].name}
          </ClinicHeading>
        )}
      </ClinicSelectContainer>
      <AsideScrollableContentContainer>
        <div>
          <SpecialtyList items={specialities} isLoading={isDoctorsLoading} />
        </div>
      </AsideScrollableContentContainer>
    </AsideContainer>
  );

  const [scheduleLoading, filteredSchedule] = useMemo(() => {
    if (schedule && schedule.length && schedule[0] === "loading") {
      return [true, []];
    }
    return [false, filterScheduleByDoctorsTimeslots(schedule)];
  }, [schedule]);

  return (
    <AsideLayout aside={aside}>
      <GeneralScheduleContent
        doctors={doctors}
        favouriteDoctors={doctorsMobxStore.favouriteDoctors}
        schedule={filteredSchedule}
        isLoading={scheduleLoading}
      />
    </AsideLayout>
  );
});
