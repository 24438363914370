import styled from "styled-components";
import { withModifiers } from "with-modifiers";
import { colors } from "../styleguide/colors";

export const IconContainerStyled = withModifiers({
  active: () => `
    .MuiSvgIcon-root {
      fill: ${colors.magenta};
    }
  `,
})(
  modifier => styled.div`
    margin-right: ${props => props?.marginRight || 0};
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiSvgIcon-root {
      fill: ${(props) => props?.fill || colors.darkBlack};
      font-size: ${props => props.size ? props.size : `1.8rem`};
      stroke: ${props => props?.stroke || "rgb(244, 223, 226)"};
      stroke-width: ${props => props?.strokeWidth || 1.1};
    }

    ${modifier}
  `
);
