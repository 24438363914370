// @flow
import moment from "moment";

export const READABLE_DATE_FORMAT = "DD.MM.YYYY";
export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const READABLE_DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm:ss";
export const SERVER_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const SERVER_TIME_FORMAT = "hh:mm:ss";

export const READABLE_TIME_FORMAT_MOMENT = "HH:mm";
export const READABLE_TIME_FORMAT = "hh:mm";

export const formatDateToReadableDateFormat = (date: string | Date) =>
  moment(date).format(READABLE_DATE_FORMAT);

export const formatDateToReadableTimeFormat = (date: string | Date) =>
  moment(date).format(READABLE_TIME_FORMAT);

export const formatDatetimeToDatetimeFormat = (date: string, time: string) =>
  moment(`${date} ${time}`, `${READABLE_DATE_FORMAT} ${READABLE_TIME_FORMAT}`);

export const parseDateFromReadableFormat = (date: string) => moment(date, READABLE_DATE_FORMAT);
