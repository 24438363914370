import React, { Fragment } from "react";
import { CorporateSubTitle } from "../../styled/CorporateTypography.styled";

export const CorporateInformationBlock = ({ label, value }) => {
  return (
    <Fragment>
      <CorporateSubTitle modifiers={["lightGray", "md"]}>{label}</CorporateSubTitle>
      <CorporateSubTitle>{value || "Данные не указаны"}</CorporateSubTitle>
    </Fragment>
  );
};
