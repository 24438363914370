// @flow
import React, { useRef, useState, useContext } from "react";
import { FolderPageContext } from "./FolderPageContext";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { rgba } from "polished";
import { PrintButton } from "../appointment-page/PrintButton";
import { useClickOutside } from "../../utils/useClickOutside";

const ButtonContainer = styled.div`
  position: relative;

  button {
    outline: none;
  }
`;

const LinksContainer = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  display: ${props => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  background: #fff;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 2px 17px rgba(91, 91, 91, 0.259752);
  z-index: 101;

  a {
    font-size: 13px;
    line-height: 20px;
    color: #262626;
    padding: 20px 13px;
    white-space: nowrap;
    text-decoration: none;

    &:visited {
      color: #262626;
    }

    &:hover {
      color: #c6007f;
      background: ${rgba("#ebebeb", 0.3)};
    }
  }
`;

export const FolderAnalysesPrintButton = () => {
  const { folder } = useContext(FolderPageContext);
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  return (
    <ButtonContainer ref={wrapperRef}>
      <PrintButton
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        Печать
      </PrintButton>
      <LinksContainer isOpen={isOpen}>
        <Link to={`/appointment/folder/${folder.id}/analyzes/print/full`}>Весь список</Link>
        <Link to={`/appointment/folder/${folder.id}/analyzes/print/not-passed`}>
          Только несданные
        </Link>
      </LinksContainer>
    </ButtonContainer>
  );
};
