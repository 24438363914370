import React from "react";
import { Box, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const IconsWrapper = styled(Box)(
  ({ position }) => `
    display: flex;
    gap: 2px;
    ${position === 'absolute' &&
      `
        position: absolute;
        bottom: -7px;
      `}
  `
);

const IconContainer = styled("img")(
  ({ size }) => `
    cursor: pointer;
    ${size === "s" &&
      `
        width: 18px;
        height: 18px;
    `}
    ${size === "m" &&
      `
        width: 24px;
        height: 24px;
    `}
    ${size === "l" &&
      `
        width: 36px;
        height: 36px;
    `}
  `
);

export const DoctorInformationIcons = ({ data = [], position = '', size = "s" }) => {
  return !data.length ? null : (
    <IconsWrapper position={position}>
      {data.map((item, index) => (
        <Tooltip key={index} title={item?.tooltipText || ""}>
          <IconContainer src={item.icon} size={size} />
        </Tooltip>
      ))}
    </IconsWrapper>
  );
};
