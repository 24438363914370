import React, { Fragment } from "react";
import { CorporateSubTitle } from "../../styled/CorporateTypography.styled";
import { CorporateUserCard } from "../../components/CorporateUserCard";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const UserBlock = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const CorporateUserCardBlock = ({ label, value }) => {
  return (
    <Fragment>
      <CorporateSubTitle modifiers={["lightGray", "md"]}>{label}</CorporateSubTitle>
      {!value.length ? (
        <CorporateSubTitle>Данные не указаны</CorporateSubTitle>
      ) : (
        <UserBlock>
          {value.map((item) => (
            <CorporateUserCard
              key={`${item.last_name}_${item.first_name}`}
              src={item.photo}
              user={{
                last_name: item.last_name,
                first_name: item.first_name,
                middle_name: item.middle_name
              }}>
              <CorporateSubTitle modifiers={["md", "gray"]}>{item.position}</CorporateSubTitle>
            </CorporateUserCard>
          ))}
        </UserBlock>
      )}
    </Fragment>
  );
};
