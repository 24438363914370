// @flow
import React from "react";
import styled from "styled-components";

const ContentContainer = styled.div`
  padding: 15px 100px 100px;
  width: 1024px;
  
  @media (max-width: 1024px) {
    max-width: 1024px;
    width: 100%;
    padding: 15px;
    max-width: calc(100% - 30px);
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 40px 40px 80px;
  }
  @media (max-width: 480px) {
    padding: 15px 15px 50px;
  }
`;

const FormContainer = styled.div`
  margin-top: 46px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

export const AppointmentStartLayout = (props: { title: React$Node, form: React$Node }) => {
  return (
    <ContentContainer>
      {props.title}
      <FormContainer>{props.form}</FormContainer>
    </ContentContainer>
  );
};
