//@flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";
import { colors } from "../styleguide/colors";
import { PreloaderBody } from "../prealoader/PreloaderBody";
import {
  AppointmentHistoryTableHead,
  type AppointmentHistoryColumnType
} from "./AppointmentHistoryTableHead";

import arrowIcon from "./icons/arrow.svg";

const Table = styled.table`
  width: 100%;
`;

const Row = styled.tr`
  border-top: 1px solid ${colors.transparentGray};
  cursor: pointer;
`;

const Arrow = styled.div`
  width: 20px;
  height: 20px;
  background: url(${arrowIcon});
`;

const CellComponent = styled.td`
  max-width: 350px;
  height: 53px;
  box-sizing: border-box;
  padding-right: 10px;
  vertical-align: middle;
  @media (max-width: 480px) {
    padding-right: 5px;
  }
`;

const CellContent = styled.div`
  display: flex;
  align-items: center;
`;

export const AppointmentHistoryPreloaderTable = (props: {|
  columns: AppointmentHistoryColumnType,
  children: React.Node
|}) => (
  <Table>
    <AppointmentHistoryTableHead columns={props.columns} />
    <PreloaderBody as="tbody">{props.children}</PreloaderBody>
  </Table>
);

const PlaceholderPart = styled.div`
  width: ${props =>
    props.cellName === "time" ? "68px" : props.cellName === "doctor" ? "115px" : "221px"};
  max-width: 100%;
  height: 10px;
  border-radius: 3px;
  background: #d4d4d4;
  opacity: 0.5;
`;

const DoctorCellPlaceholderAvatar = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #d4d4d4;
  opacity: 0.5;
  margin-right: 13px;
`;

export const AppointmentHistoryPreloaderTableRow = (props: {
  [key: string]: React.Node | string,
  withDoctor?: boolean
}) => {
  const { withDoctor } = props;
  const cells = ["time", ...(withDoctor ? ["doctor", "patient"] : ["patient"]), "layout"];
  return (
    <Row>
      {_.map(cells, (cell, index: number) => (
        <CellComponent key={index}>
          <CellContent>
            {cell === "doctor" ? <DoctorCellPlaceholderAvatar /> : null}
            <PlaceholderPart cellName={cell} />
          </CellContent>
        </CellComponent>
      ))}
      <CellComponent>
        <Arrow />
      </CellComponent>
    </Row>
  );
};
