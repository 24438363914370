// @flow
import React, { useMemo } from "react";
import type { Prescription } from "../../domain/value-objects/Prescription";
import * as R from "ramda";
import {
  filterPrescriptions,
  valueArrayToRange,
  whereTableColumnAndDrugId
} from "../../domain/services/prescription";
import { PrescriptionTableHead } from "../prescription-table/PrescriptionTableHead";
import { PrescriptionTableHeadCellFixed } from "../prescription-table/PrescriptionTableHeadCellFixed";
import { PrescriptionTableHeadCell } from "../prescription-table/PrescriptionTableHeadCell";
import styled from "styled-components";
import {
  PrescriptionTableCell,
  PrescriptionTableCellFixed
} from "../prescription-table/PrescriptionTableCell";
import { PNormal, PSmall } from "../styleguide/typography";
import o, { type ExtractOverridesProps } from "react-overrides";
import { ScrollContext, ScrollDetector } from "../scroll-detector/ScrollDetector";
import { TableWrapper } from "../prescription-table/TableWrapper";
import { getDrugs } from "../prescription-table/getDrugs";
import type { PrescriptionDrug } from "../prescription-table/getDrugs";

const TableContainer = styled.div`
  overflow-x: auto;
`;

const DrugNameContainer = styled.div`
  margin: 0 20px;
`;

const Cell = (props: { value: Array<string | Prescription>, drug_id: number, offset: number }) => {
  const { value, drug_id, offset } = props;

  const prescription = useMemo(
    () => R.find(whereTableColumnAndDrugId(offset, drug_id))((value: any)),
    [offset, drug_id, value]
  );
  return (
    <PrescriptionTableCell {...o}>
      <PSmall modifiers="center">{(prescription && prescription.value) || ""}</PSmall>
    </PrescriptionTableCell>
  );
};

export const ReadablePrescriptionTable = (props: {
  startOffset?: number,
  rangeLength?: number,
  value: Array<string | Prescription>,
  overrides?: ExtractOverridesProps<{
    PrescriptionTableCellFixed: typeof PrescriptionTableCellFixed,
    Cell: React$ComponentType<{
      overrides?: ExtractOverridesProps<{ PrescriptionTableCell: typeof PrescriptionTableCell }>
    }>,
    ScrollDetector: any,
    TableContainer: any
  }>,
  drugs?: Array<PrescriptionDrug>
}) => {
  const { value, startOffset = 0, rangeLength } = props;
  const filteredValue = filterPrescriptions(value);
  const headRange = useMemo(() => valueArrayToRange(filteredValue, startOffset, rangeLength), [
    filteredValue,
    rangeLength,
    startOffset
  ]);

  const drugs = useMemo(() => props.drugs || getDrugs((value: any)), [value, props.drugs]);
  return (
    <TableWrapper>
      <table>
        <PrescriptionTableHead>
          <PrescriptionTableHeadCellFixed disableBorderRight values={["Дата", "День цикла"]} />
        </PrescriptionTableHead>
        <tbody>
          {drugs.map(drug => (
            <tr key={drug.drug_id}>
              <PrescriptionTableCellFixed {...o}>
                <DrugNameContainer>
                  <PNormal>{drug.drug}</PNormal>
                </DrugNameContainer>
              </PrescriptionTableCellFixed>
            </tr>
          ))}
        </tbody>
      </table>
      <TableContainer {...o}>
        <ScrollDetector {...o}>
          <ScrollContext.Consumer>
            {({ setTableRef }) => (
              <table ref={setTableRef}>
                <PrescriptionTableHead>
                  {headRange.map((headItem, id) => (
                    <PrescriptionTableHeadCell key={id} values={[headItem.top, headItem.bottom]} />
                  ))}
                </PrescriptionTableHead>
                <tbody>
                  {drugs.map(drug => (
                    <tr key={drug.drug_id}>
                      {R.range(startOffset, startOffset + headRange.length).map(offset => (
                        <Cell
                          {...o}
                          key={offset}
                          value={value}
                          drug_id={drug.drug_id}
                          offset={offset}
                        />
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </ScrollContext.Consumer>
        </ScrollDetector>
      </TableContainer>
    </TableWrapper>
  );
};
