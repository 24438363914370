import { THEME } from "../theme";

export const corporateTextPalette = THEME.palette.text;
export const corporateWrapperPalette = THEME.palette.wrapper;
export const breakpoints = {
  all: THEME.breakpoints.up("mobile"),
  /** < 1200px */
  desktop: THEME.breakpoints.down("desktop"),
  /** < 1024px */
  laptop: THEME.breakpoints.down("laptop"),
  /** < 900px */
  md: THEME.breakpoints.down("md"),
  /** < 640px */
  tablet: THEME.breakpoints.down("tablet"),
  /** < 600px */
  sm: THEME.breakpoints.down("sm"),
  /** < 1200px */
  lg: THEME.breakpoints.down("lg"),
  /** < 1536px */
  xl: THEME.breakpoints.down("xl")
};
