import React from "react";
import styled from "styled-components";
import { PreloaderBody } from "../prealoader/PreloaderBody";

const ModifiedPreloaderBody = styled(PreloaderBody)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PlaceholderBase = styled.div`
  height: 10px;
  border-radius: 3px;
  background: #d4d4d4;
  opacity: 0.5;
`;

const PlaceholderCardBody = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  padding: 16px 22px;
  height: 100%;
  box-sizing: border-box;

  &:not(:last-child) {
    margin-right: 19px;
  }
`;

const PlaceholderCardDate = styled(PlaceholderBase)`
  width: 182px;
  height: 23px;
  margin-bottom: 22px;
`;

const PlaceholderCardDoctors = styled.div`
  display: flex;
  flex: 1;
`;

const PlaceholderCardDoctorColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:last-child) {
    margin-right: 20px;
  }
`;

const PlaceholderCardDoctorAvatar = styled(PlaceholderBase)`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const PlaceholderCardDoctorTimesheet = styled(PlaceholderBase)`
  width: 60px;
  flex: 1;
`;

const PlaceholderCardDoctor = () => {
  return (
    <PlaceholderCardDoctorColumn>
      <PlaceholderCardDoctorAvatar />
      <PlaceholderCardDoctorTimesheet />
    </PlaceholderCardDoctorColumn>
  );
};

export const GeneralSchedulePlaceholderCard = () => {
  return (
    <PlaceholderCardBody>
      <ModifiedPreloaderBody>
        <PlaceholderCardDate />
        <PlaceholderCardDoctors>
          {new Array(5).fill().map((value, index) => (
            <PlaceholderCardDoctor key={index} />
          ))}
        </PlaceholderCardDoctors>
      </ModifiedPreloaderBody>
    </PlaceholderCardBody>
  );
};
