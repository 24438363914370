import ApiService from "../apiService";

const API_URL = process.env.EMS_API_URL || location.origin;
class Documents {
  apiService;
  constructor() {
    this.apiService = new ApiService("/api", null, null, API_URL);
  }

  get() {
    return this.apiService.get("documents");
  }

  getTemplate(agreementId, { folderId, appointmentId }) {
    return this.apiService.get(`documents/${agreementId}/print`, {
      folder_id: folderId,
      appointment_id: appointmentId
    });
  }

  getCategories() {
    return this.apiService.get('document_categories');
  }
}

export default new Documents();
