// @flow
import { createEffect, createEvent, restore, sample, type Store } from "effector";
import type { TokenObject } from "../../domain/value-objects/auth";
import type { TFeedback, TFeedback_id } from "../../domain/entities/Feedback";
import { tokenStore } from "../auth";
import { closeFeedbackSidebar, openFeedbackForView } from "./feedbackSections";
import type {
  FieldLayoutGroup,
  FieldLayoutSingle
} from "../../domain/entities/FeedbackLayoutField";
import type { TFeedbackLayout_id } from "../../domain/entities/FeedbackLayout";
import type { TFeedbackUserResponse } from "../../domain/entities/FeedbackUserResponse";
import { PatientAppointmentsRefreshTrigger } from "../../components/appointment-switch-page/PatientAppointmentsRefreshTrigger";
import { reloadFeedbacks } from "../../components/appointment-feedback/feedbacksStore";
import apiv2 from '../../apiv2';

type TFeedBackParams = {
  ...$Exact<TokenObject>,
  id: TFeedback_id
};

type TLayoutParams = {
  ...$Exact<TokenObject>,
  id: TFeedbackLayout_id
};

const fetchFeedbackEffect = createEffect<TFeedBackParams, TFeedback, any>();
fetchFeedbackEffect.use(apiv2.feedback.getFeedback.bind(apiv2.feedback));

const fetchFeedbackFieldsEffect = createEffect<
  TLayoutParams,
  Promise<Array<FieldLayoutGroup | FieldLayoutSingle>>,
  any
>();
fetchFeedbackFieldsEffect.use(apiv2.feedback.getFeedbackFields.bind(apiv2.feedback));

const fetchFeedbackResponseEffect = createEffect<TFeedBackParams, TFeedbackUserResponse, any>();
fetchFeedbackResponseEffect.use(apiv2.feedback.getFeedbackResponse.bind(apiv2.feedback));

const currentFeedbackIdStore: Store<?TFeedback_id> = restore((openFeedbackForView: any), null);

export const reloadFeedback = createEvent<void>();

const handleCurrentFeedbackIdStore = currentFeedbackId => {
  const token = tokenStore.getState();
  if (!token) {
    return;
  }
  if (currentFeedbackId) {
    fetchFeedbackEffect({ id: currentFeedbackId, token });
    fetchFeedbackResponseEffect({ id: currentFeedbackId, token });
  }
};

sample(currentFeedbackIdStore, reloadFeedback, currentFeedbackId => currentFeedbackId).watch(
  handleCurrentFeedbackIdStore
);
currentFeedbackIdStore.watch(handleCurrentFeedbackIdStore);

export const currentFeedbackStore: Store<?TFeedback> = restore((fetchFeedbackEffect: any), null);
currentFeedbackStore.watch(currentFeedback => {
  const token = tokenStore.getState();
  if (!currentFeedback || !currentFeedback.layout || !token) {
    return;
  }
  fetchFeedbackFieldsEffect({ id: (currentFeedback.layout.id: any), token });
});

export const currentFeedbackFieldsStore: Store<?Array<
  FieldLayoutGroup | FieldLayoutSingle
>> = restore((fetchFeedbackFieldsEffect: any), null);

export const currentFeedbackResponseStore: Store<?TFeedbackUserResponse> = restore(
  (fetchFeedbackResponseEffect: any),
  null
);

export const sendFeedbackComplete = createEvent<{ id: TFeedback_id, doctors_comment?: string }>();

const sendFeedbackCompleteJoined = sample(tokenStore, sendFeedbackComplete, (token, params) => ({
  token,
  ...params
}));

sendFeedbackCompleteJoined.watch(async params => {
  closeFeedbackSidebar();
  await apiv2.feedback.getFeedbackComplete((params: any));
  PatientAppointmentsRefreshTrigger();
  reloadFeedback();
  reloadFeedbacks();
});

export const sendFeedbackLoad = createEvent<{ id: TFeedback_id, doctors_comment?: string }>();

const sendFeedbackLoadJoined = sample(tokenStore, sendFeedbackLoad, (token, params) => ({
  token,
  ...params
}));

sendFeedbackLoadJoined.watch(async params => {
  await apiv2.feedback.getFeedbackComplete((params: any));
  PatientAppointmentsRefreshTrigger();
  reloadFeedback();
  reloadFeedbacks();
});
