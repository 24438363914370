// @flow
import React, { useRef } from "react";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import printIcon from "../appointment-page/icons/file.svg";
import { DefaultButton } from "../buttons/DefaultButton";
import { useStore } from "effector-react";
import { tokenStore } from "../../stores/auth";
import { useSummaryContext } from './SummaryContext';
import printEditorStore, {PrintEditorEvents} from '../../stores/printEditorStore';
import apiv2 from '../../apiv2';

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${printIcon});
`;

const IconContainer = styled.div`
  margin-left: -2px;
  margin-right: 6px;
`;

const ButtonContainer = styled.div`
  button {
    outline: none;
  }
`;

export const SummaryPrintButton = ({ patientId }: { patientId: number }) => {
  const wrapperRef = useRef(null);
  const { summary } = useSummaryContext();

  const token = useStore(tokenStore);

  const [getPrintableState, hanldeGetPrintable] = useAsyncFn(async () => {
    const printable = await apiv2.appointments.getSummaryPrint({ patient_id: patientId, summary });

    if (printable.file && !printEditorStore.isVisible) {
      printEditorStore.setData(printable.file);
      printEditorStore.show();
    }
  }, [token, patientId, summary]);

  return (
    <ButtonContainer ref={wrapperRef}>
      <DefaultButton
        disabled={getPrintableState.loading}
        onClick={hanldeGetPrintable}
        overrides={{
          Icon: {
            component: Icon
          },
          IconContainer: {
            component: IconContainer
          }
        }}>
        ЭПИКРИЗ
      </DefaultButton>
    </ButtonContainer>
  );
};
