import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

const Container = styled.img`
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 0;
  margin: -1px;
  border: 1px solid transparent;
  transition: all 0.2s linear;
  object-fit: cover;
    
  ${props => props?.isHovered && `
      padding: 4px;
      margin: -5px;
      border-color: rgba(104, 104, 104, 0.2);
    `
  }}
    
  ${props =>
    props.useHoverAnimation 
      ? css`
          cursor: pointer;

          &:hover {
            padding: 4px;
            margin: -5px;
            border-color: rgba(104, 104, 104, 0.2);
          }
        `
      : css`
          cursor: default;
        `}

  ${props => (props.avatarStyle ? props.avatarStyle : "")};
`;

const Placeholder = styled(Container)`
  background-color: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  line-height: 22px;
  color: #7d7d7d;
  text-transform: uppercase;
  background-clip: content-box;
  ${props => (props.placeholderStyle ? props.placeholderStyle : "")};
`;

const cachedAvatarMap = new Map();

export const DoctorAvatar = props => {
  const { doctor, useHoverAnimation = false, isHovered = false, avatarStyle, placeholderStyle, onClick } = props;

  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    if (doctor.photo) {
      const cachedAvatar = cachedAvatarMap.get(doctor.photo);
      if (cachedAvatar === undefined) {
        const doctorAvatarImage = new Image();
        doctorAvatarImage.onload = () => {
          setPhoto(doctorAvatarImage);
          cachedAvatarMap.set(doctor.photo, doctorAvatarImage);
        };
        doctorAvatarImage.onerror = () => {
          setPhoto(null);
          cachedAvatarMap.set(doctor.photo, null);
        };
        doctorAvatarImage.src = doctor.photo;
      } else {
        setPhoto(cachedAvatar);
      }
    }
    return () => {
      setPhoto(null);
    };
  }, [doctor]);

  return photo ? (
    <Container
      src={photo.src}
      alt="Photo"
      avatarStyle={avatarStyle}
      useHoverAnimation={useHoverAnimation}
      isHovered={isHovered}
      onClick={onClick}
    />
  ) : (
    <Placeholder
      as="div"
      placeholderStyle={placeholderStyle}
      useHoverAnimation={useHoverAnimation}
      isHovered={isHovered}
      onClick={onClick}>
      {doctor?.last_name?.slice(0, 1) + doctor?.first_name?.slice(0, 1)}
    </Placeholder>
  );
};
