//@flow
import * as React from "react";
import styled from "styled-components";
import _ from "lodash";

import { colors } from "../styleguide/colors";
import { cellsByName } from "./AppointmentHistoryTableCells";

import arrowIcon from "./icons/arrow.svg";

const Row = styled.tr`
  border-top: 1px solid ${colors.transparentGray};
  cursor: pointer;
  &:hover {
    color: ${colors.magenta};
    
    p {
      color: ${colors.magenta};
    }
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${colors.magenta};
`;

const Arrow = styled.div`
  width: 20px;
  height: 20px;
  background: url(${arrowIcon});
`;

const Cell = (props: {
  cellComponent: any,
  children?: React.Node | string,
  link: string,
  badge?: string,
  big?: boolean,
  onClickCell: () => mixed
}) => {
  const Container = props.cellComponent;
  return (
    <Container
      big={props.big}
      onClickCell={props.onClickCell}
      link={props.link}
      badge={props.badge}>
      {props.children}
    </Container>
  );
};

export const AppointmentHistoryTableRow = (props: {
  [key: string]: React.Node | string,
  link: string,
  badge?: string,
  inProgress?: boolean,
  withArrow?: boolean,
  big?: boolean,
  onClickCell: () => mixed
}) => {
  const { link, badge, onClickCell, inProgress, withArrow, big, ...cells } = props;
  return (
    <Row>
      {_.map(cells, (cell, index) => (
        <Cell
          onClickCell={onClickCell}
          link={link}
          key={index}
          big={big}
          // $FlowFixMe
          badge={index === "patient" && badge}
          cellComponent={cellsByName[index] || cellsByName.default}>
          {cell}
        </Cell>
      ))}
      <Cell
        onClickCell={onClickCell}
        link={link}
        cellComponent={withArrow ? cellsByName.arrow : cellsByName.dot}>
        {withArrow ? <Arrow /> : inProgress ? <Dot /> : null}
      </Cell>
    </Row>
  );
};
