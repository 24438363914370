//@flow
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {observer} from 'mobx-react';
import Dialog from '@mui/material/Dialog';

import {HeadingH4, PXSmall} from "../../styleguide/typography";
import {PrimaryButton, PrimaryButtonContainer} from '../../buttons/PrimaryButton';
import chatStore from "../../../stores/chatStore";
import {getBase64} from '../../../utils/getBase64';

import warningImage from "../../../assets/image/warning.png";
import loaderIcon from '../../schedule-loader-modal/icons/loader.svg';
import { BlockLoader } from "../../loader/Loader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 653px;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 40px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`;

const HeadingContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
`;

const CustomButtonContainer = styled(PrimaryButtonContainer)`
  margin-top: 32px;
  align-self: center;
`;

const CustomButtonContainer2 = styled(PrimaryButtonContainer)`
  margin-top: 12px;
  align-self: center;
`;

const WarningImage = styled.img`
  max-height: 120px;
  margin-bottom: 32px;
`;

const getImageBase64 = async (imageURL) => {
  return getBase64(await fetch(imageURL).then(response => response.blob()));
};

export const VoximplantConnectionErrorModal = observer((props: {
  isShown: boolean,
}) => {
  const { isShown } = props;
  const [ loadedImages, setLoadedImages ] = useState({
    warningImage: warningImage,
    loaderIcon: loaderIcon,
  });

  useEffect(() => {
    Object.entries(loadedImages).forEach(async ([key, url]) => { // Если обрубится соединение, мы не сможем получить изображения по URL
      const imageBase64 = await getImageBase64(url);
      setLoadedImages(state => ({ ...state, [key]: imageBase64 }));
    });
  }, []);

  const onReloadClick = () => {
    location.reload();
  };

  const isOnCallPage = location.pathname.includes('chats') || location.pathname.includes('videocall');
  const isCallInProgress = chatStore.callSettingsScreenShowed || chatStore.voxCall;

  return (
    <>
      <Dialog open={isShown && isOnCallPage && isCallInProgress}>
        <Container>
          {/*<IconContainer>*/}
          {/*  <MediaPermissionLockIcon />*/}
          {/*</IconContainer>*/}
          <WarningImage src={loadedImages.warningImage} alt="Warning"/>
          <HeadingContainer>
            <HeadingH4 modifiers={['center']}>
              {
                chatStore.voximplantIsReconnecting
                  ? "Ошибка при подключении к звонку"
                  : "Не удалось подключиться к звонку"
              }

            </HeadingH4>
          </HeadingContainer>
          {
            chatStore.voximplantIsReconnecting ? (
              <TextContainer>
                <PXSmall modifiers={['gray']}>
                  На вашем устройстве возникла ошибка при подключении к звонку. Обращаем внимание, что если у вас включен VPN, то он может блокировать правильную работу
                </PXSmall>
                <PXSmall modifiers={['gray']}>
                  Пожалуйста, подождите, идет переподключение...
                </PXSmall>
                <BlockLoader />
                <PXSmall style={{
                  alignSelf: 'center'
                }} modifiers={['gray']}>
                  или
                </PXSmall>
                <PrimaryButton onClick={onReloadClick} primaryButtonContainer={CustomButtonContainer2}>
                  Перезагрузите страницу
                </PrimaryButton>
              </TextContainer>
            ) : (
              <TextContainer>
                <PXSmall modifiers={['gray']}>
                  Проверьте подключение к интернету и попробуйте еще раз.
                </PXSmall>
                <PXSmall modifiers={['gray']}>
                  Если не получается подключиться к звонку с исправным подключением к интернету, пожалуйста, напишите нам в <a target="_blank" rel="noreferrer" href="https://t.me/fominclinic_internalsupport_bot">телеграм бот</a>
                </PXSmall>
                <PrimaryButton onClick={onReloadClick} primaryButtonContainer={CustomButtonContainer}>
                  Обновить страницу
                </PrimaryButton>
              </TextContainer>
            )
          }
        </Container>
      </Dialog>
    </>
  );
});
