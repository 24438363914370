// @flow
import React from "react";

import { CorporateCounter } from "../common/CorporateCounter";

import EyeIcon from "../../../assets/icons/corporate-portal/eye.inline.svg";

export const CorporateViewCounter = (props: {| value: number | string |}) => {
  const { value } = props;

  return <CorporateCounter icon={<EyeIcon />} value={value} />;
};
