// @flow
import * as React from "react";
import styled from "styled-components";
import { ModalWithCloseButtonLayout } from "../modal/ModalWithCloseButtonLayout";
import { withModifiers } from "with-modifiers";

const Container = withModifiers({
  small: () => `
    max-width: 514px;
    width: 100%;
    padding: 48px 32px;
    
    ${Description} {
      max-width: 370px;
      p {
        font-size: 17px;
      }
    }
  `
})(
  modifier => styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 653px;
    width: 100%;
    padding: 107px 108px 95px 95px;
    ${modifier};
    box-sizing: border-box;
    
    @media (max-width: 768px) {
      padding: 40px;
    }
  `
);

const Description = styled.div`
  margin-top: 16px;
  max-width: 450px;
`;

const Buttons = styled.div`
  margin-top: 39px;
  display: flex;
  gap: 19px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 10px;
    justify-content: center;
  }
`;

export const AlertWarningLayout = (props: {
  heading: React.Node,
  description?: React.Node,
  confirmButton?: React.Node,
  cancelButton?: React.Node,
  onClose: () => mixed,
  containerModifiers?: Array,
  hiddenCloseButton?: boolean,
  hiddenButtons?: boolean,
}) => {
  return (
    <ModalWithCloseButtonLayout hiddenCloseButton={props.hiddenCloseButton} handleClose={props.onClose}>
      <Container modifiers={props.containerModifiers}>
        {props.heading}
        {props.description && <Description>{props.description}</Description>}
        { !props.hiddenButtons && (
          <Buttons>
            {props.cancelButton}
            {props.confirmButton}
          </Buttons>
        )}
      </Container>
    </ModalWithCloseButtonLayout>
  );
};
