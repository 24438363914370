import React, { Fragment, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { observer } from "mobx-react";
import reportTransferStore from "../../../stores/reports/reportTransferStore";
import { trimLongTextValueField } from "../../../utils/tableUtils";
import { READABLE_DATE_FORMAT } from "../../../utils/dates";
import { ReportCalculations } from "../common/ReportCalculations";
import { MaterialTable } from "../../common/MaterialTable";
import {
  besplodie1Options,
  besplodie2Options,
  besplodie3Options,
  financeTypeOptions,
  reportDateGTE,
  reportDateLTE
} from "../constants";
import { ReportDateFilter } from "../common/ReportDateFilter";
import { ReportLinkButton } from "../common/ReportLinkButton";
import { ReportsTableWrapper } from "../Reports.styled";
import organizationsStore from "../../../stores/organizationsStore";

export const ReportTransfer = observer(props => {
  const [isInitialized, setIsInitialized] = useState(reportTransferStore.isInitialized);
  const defaultValueDates = {
    date__gte: reportDateGTE,
    date__lte: reportDateLTE
  };
  const methods = useForm({ defaultValues: defaultValueDates });

  const calculations = useMemo(
    () => [
      {
        key: "total",
        name: "Все",
        description: "Число документов «Перенос эмбрионов»",
        value: reportTransferStore.othersTableData?.["total"] || 0
      },
      {
        key: "fresh",
        name: "Свежие",
        description: "Число документов «Перенос эмбрионов», где поле «Разморожено эмбрионов»= 0",
        value: reportTransferStore.othersTableData?.["fresh"] || 0
      },
      {
        key: "crio",
        name: "Крио",
        description:
          "Число документов «Перенос эмбрионов», где поле «Разморожено эмбрионов»= 1 и более",
        value: reportTransferStore.othersTableData?.["crio"] || 0
      },
      {
        key: "with_pgt",
        name: "С ПГТ",
        description: "Число документов «Перенос эмбрионов», где поле «ПГТ»=да",
        value: reportTransferStore.othersTableData?.["with_pgt"] || 0
      },
      {
        key: "with_do",
        name: "С ДО",
        description: "Число документов «Перенос эмбрионов», где поле «Донор ооцитов»=да",
        value: reportTransferStore.othersTableData?.["with_do"] || 0
      },
      {
        key: "without_do_pgt",
        name: "Без ДО и ПГТ",
        description:
          "Число документов «Перенос эмбрионов» в папке «Криоперенос», где поле «ПГТ»=нет и поле «Донор ооцитов»=нет",
        value: reportTransferStore.othersTableData?.["without_do_pgt"] || 0
      },
      {
        key: "chnb_total",
        name: "ЧНБ всего",
        description:
          "Число документов «Исход программы ВРТ», где отмечено тип события «перенос» и беременность «клиническая», делим на число документов «Перенос эмбрионов» (Пункт 1) и умножаем на 100%",
        value: reportTransferStore.othersTableData?.["chnb_total"] || 0,
        symbol: "%"
      },
      {
        key: "chnb_fresh",
        name: "ЧНБ свежие",
        description:
          "Число документов «Исход программы ВРТ», где отмечено тип события «перенос» и беременность «клиническая»,  расположенных в папке вместе с документом «Перенос эмбрионов», где поле «Разморожено эмбрионов» = 0, делим на число документов «Перенос эмбрионов», где поле «Разморожено эмбрионов» = 0 (Пункт 2) и умножаем на 100%",
        value: reportTransferStore.othersTableData?.["chnb_fresh"] || 0,
        symbol: "%"
      },
      {
        key: "chnb_crio",
        name: "ЧНБ крио",
        description:
          "Число документов «Исход программы ВРТ», где отмечено тип события «перенос» и беременность «клиническая»,  расположенных в папке вместе с документом «Перенос эмбрионов», где поле «Разморожено эмбрионов» = 1 и более, делим на число документов «Перенос эмбрионов», где поле «Разморожено эмбрионов» = 1 и более (Пункт 3) и умножаем на 100%",
        value: reportTransferStore.othersTableData?.["chnb_crio"] || 0,
        symbol: "%"
      },
      {
        key: "chnb_with_pgt",
        name: "ЧНБ с ПГТ",
        description:
          "Число документов «Исход программы ВРТ», где отмечено тип события «перенос» и беременность «клиническая»,  расположенных в папке вместе с документом «Перенос эмбрионов», где поле «ПГТ»=да, делим на число документов «Перенос эмбрионов», где поле «ПГТ»=да (Пункт 4) и умножаем на 100%",
        value: reportTransferStore.othersTableData?.["chnb_with_pgt"] || 0,
        symbol: "%"
      },
      {
        key: "cnb_with_do",
        name: "ЧНБ с ДО",
        description:
          "Число документов «Исход программы ВРТ», где отмечено тип события «перенос» и беременность «клиническая»,  расположенных в папке вместе с документом «Перенос эмбрионов», где поле «Донор ооцитов»=да, делим на число документов «Перенос эмбрионов», где поле «Донор ооцитов»=да (Пункт 5) и умножаем на 100%",
        value: reportTransferStore.othersTableData?.["cnb_with_do"] || 0,
        symbol: "%"
      },
      {
        key: "chnb_without_pgt_do",
        name: "ЧНБ без ДО и ПГТ",
        description:
          "Число документов «Исход программы ВРТ», где отмечено тип события «перенос» и беременность «клиническая»,  расположенных в папке «Криоперенос» вместе с документом «Перенос эмбрионов», где поле «Донор ооцитов»=нет и поле «ПГТ»=нет, делим на число документов «Перенос эмбрионов» в папке «Криоперенос», где поле «ПГТ»=нет и поле «Донор ооцитов»=нет (Пункт 6) и умножаем на 100%",
        value: reportTransferStore.othersTableData?.["chnb_without_pgt_do"] || 0,
        symbol: "%"
      },
      {
        key: "without_outcome",
        name: "Нет данных по исходам",
        description:
          "Число документов «Исход программы ВРТ», где отмечено тип события «перенос» и не выбран ни один из вариантов в после «Беременность»,  делим на общее число документов «Перенос эмбрионов»  (Пункт 1) и умножаем на 100%",
        value: reportTransferStore.othersTableData?.["without_outcome"] || 0
      }
    ],
    [reportTransferStore.othersTableData]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Прием",
        enableHiding: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        enableResizing: false,
        size: 100,
        Cell: ({ row }) => <ReportLinkButton path={`/appointment/${row.original.id}`} />
      },
      {
        accessorKey: "date",
        header: "Дата создания шаблона",
        enableColumnFilter: false,
        size: 250,
        Cell: ({ row }) => moment(row.original.date).format(READABLE_DATE_FORMAT)
      },
      {
        accessorKey: "folder_name",
        header: "Название папки",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.folder_name)
      },
      {
        accessorKey: "layout_name",
        header: "Название шаблона",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.layout_name)
      },
      {
        accessorKey: "organization",
        header: "Клиника",
        filterFn: "in",
        filterVariant: "multi-select",
        filterSelectOptions: organizationsStore.organizations.map(item => item.name),
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.organization)
      },
      {
        accessorKey: "patient_name",
        header: "Пациент",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.patient_name)
      },
      {
        accessorKey: "doctor_name",
        header: "Врач",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.doctor_name)
      },
      {
        accessorKey: "embriolog",
        header: "Эмбриолог",
        filterFn: "icontains",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.embriolog)
      },
      {
        accessorKey: "age",
        header: "Возраст женщины",
        filterFn: "range",
        filterVariant: "range",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.age)
      },
      {
        accessorKey: "amg",
        header: "АМГ",
        filterFn: "range",
        filterVariant: "range",
        size: 210,
        Cell: ({ row }) => trimLongTextValueField(row.original.amg)
      },
      {
        accessorKey: "finance_type",
        header: "Тип финансирования",
        filterFn: "icontains",
        filterVariant: "select",
        filterSelectOptions: financeTypeOptions,
        size: 250
      },
      {
        accessorKey: "besplodie1",
        header: "Бесплодие",
        filterFn: "icontains",
        filterVariant: "select",
        filterSelectOptions: besplodie1Options,
        size: 210
      },
      {
        accessorKey: "besplodie2",
        header: "Факторы бесплодия",
        filterFn: "contains",
        filterVariant: "multi-select",
        filterSelectOptions: besplodie2Options,
        size: 250,
        Cell: ({ row }) =>
          trimLongTextValueField(
            !row.original?.besplodie3?.length ? "" : row.original.besplodie3.join(", ")
          )
      },
      {
        accessorKey: "besplodie3",
        header: "Др. факторы бесплодия",
        filterFn: "contains",
        filterVariant: "multi-select",
        filterSelectOptions: besplodie3Options,
        size: 260,
        Cell: ({ row }) =>
          trimLongTextValueField(
            !row.original?.besplodie3?.length ? "" : row.original.besplodie3.join(", ")
          )
      },
      {
        accessorKey: "pgt",
        header: "ПГТ",
        filterFn: "icontains",
        filterVariant: "select",
        filterSelectOptions: ["Да", "Нет"],
        size: 210
      },
      {
        accessorKey: "razmorojeno_embrionov",
        header: "Разморожено эмбрионов",
        filterFn: "range",
        filterVariant: "range",
        size: 260,
        Cell: ({ row }) => trimLongTextValueField(row.original.razmorojeno_embrionov)
      },
      {
        accessorKey: "donor_oocitov",
        header: "Донор ооцитов",
        filterFn: "icontains",
        filterVariant: "select",
        filterSelectOptions: ["Да", "Нет"],
        enableHiding: false,
        size: 210
      }
    ],
    [organizationsStore.organizations]
  );

  const afterInitializeParams = async callback => {
    if (!reportTransferStore.globalFiltersTable.date__lte) {
      reportTransferStore.setGlobalFiltersTable(defaultValueDates, false);
    } else {
      methods.setValue("date__gte", reportTransferStore.globalFiltersTable.date__gte);
      methods.setValue("date__lte", reportTransferStore.globalFiltersTable.date__lte);
    }

    await callback();
    setIsInitialized(reportTransferStore.isInitialized);
  };

  const topToolbarCustomActions = [
    {
      key: "set_filters",
      options: {
        color: "success",
        variant: "outlined",
        onClick: () => reportTransferStore.fetchDataTable(true)
      },
      text: "Применить фильтры"
    }
  ];

  return (
    <Fragment>
      <ReportDateFilter {...methods} store={reportTransferStore} />
      <ReportCalculations
        isLoading={!isInitialized}
        label={props.label}
        calculations={calculations}
      />
      <ReportsTableWrapper>
        <MaterialTable
          disableHeightResizing
          isDisableSavingPresets
          enableColumnResizing
          disableFetchAfterChangeFilters
          notPadding
          enableCollapseCustomActions={false}
          topToolbarCustomActions={topToolbarCustomActions}
          afterInitializeParams={afterInitializeParams}
          store={reportTransferStore}
          columns={columns}
          localStorageKeyName={props.name}
          localization={props.localization}
          initialState={{
            showColumnFilters: true
          }}
        />
      </ReportsTableWrapper>
    </Fragment>
  );
});
