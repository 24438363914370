//@flow
import * as React from "react";

import type { UploadedFile } from "../../domain/entities/UploadedFile";
import { formatFileDateToNumericDateFullYear } from "../../domain/services/uploadedFile";
import { bytesToSize } from "../../utils/bytesToSize";
import TrashImg from "./icons/trash-light.svg";
import EditImg from "./icons/edit.svg";
import { FileLayout, FileToolButton } from "./FileLayout";

export const File = (props: {
  file: UploadedFile,
  onDelete?: (id: number) => mixed,
  onEdit?: (file: UploadedFile) => mixed
}) => {
  const { file, onDelete, onEdit } = props;

  const tools = (
    <>
      {onDelete && <FileToolButton icon={TrashImg} onClick={() => onDelete(file.id)} />}
      {onEdit && <FileToolButton icon={EditImg} onClick={() => onEdit(file)} />}
    </>
  );

  const handleOnClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ event: "openLink", link: file.file_url }));
    } else {
      window.open(file.file_url, "_blank");
    }
  }

  return (
    <FileLayout
      date={formatFileDateToNumericDateFullYear(file.date)}
      size={bytesToSize(file.size)}
      title={file.name}
      tools={tools}
      onDownload={handleOnClick}
    />
  );
};
