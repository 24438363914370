// @flow
import * as React from "react";
import useReactRouter from "use-react-router";
import { useTrigger } from "react-use-trigger";
import { currentUser, tokenStore } from "../../stores/auth";
import { setLayoutSectionsFieldsMap } from "../../stores/appointmentLayout";
import { EditAppointmentPage } from "./EditAppointmentPage";
import { ReadableAppointmentPage } from "./ReadableAppointmentPage";
import { PatientAppointmentsRefreshTrigger } from "../appointment-switch-page/PatientAppointmentsRefreshTrigger";
import { setMedicalFile } from "../appointment-switch-page/medicalFileStore";
import { CorrectMedicalFilesContext } from "../appointment-switch-page/CorrectMedicalFilesContext";
import type { MedicalFile } from "../../domain/entities/MedicalFile";
import { setAppointmentLiveBrithProbability } from "../../stores/appointmentLiveBirthProbability";
import { resetStimulationProtocol, setStimulationProtocol } from "../../stores/stimulationProtocol";
import { setAppointment } from "../../stores/appointment";
import apiv2 from '../../apiv2';
import { useApiRequest } from '../../apiv2/useApiRequest';
import { setVoximplantSupportData } from "../../utils/voximplantSupport";
import { BlockLoader } from "../loader/Loader";

export const AppointmentPage = () => {
  const {
    match: { params },
    history,
  } = useReactRouter();
  const patientAppointmentsTrigger = useTrigger(PatientAppointmentsRefreshTrigger);

  const { medicalFiles } = React.useContext<{ medicalFiles: Array<MedicalFile> }>(
    CorrectMedicalFilesContext
  );
  const appointmentResult = useApiRequest(async () => {
    const response = await apiv2.appointments.getAppointmentById(params.appointment_id);
    const doctorUser = currentUser.getState();
    if (!doctorUser?.doctor?.organizations?.includes(response.medical_file.organization.id)) {
      return response;
    }
    // предзагрузка услуг (она кешируется, поэтому не страшно вызывать её каждый раз)
    apiv2.services.getOrganizationServices(response.medical_file.organization.id, {only_favorites: true});

    return response;
  }, [ patientAppointmentsTrigger, params.appointment_id, tokenStore.getState() ]);

  const medicalFile = appointmentResult?.data?.medical_file;

  React.useEffect(() => {
    if (medicalFile) {
      setMedicalFile(medicalFile);
    }
  }, [medicalFile]);

  const layoutResult = useApiRequest(async () => {
    const layoutId = parseInt(appointmentResult.data && appointmentResult.data.layout.id);
    if (!isNaN(layoutId)) {
      return apiv2.appointments.getAppointmentLayoutById(layoutId);
    } else {
      return null;
    }
  }, [ appointmentResult.data && appointmentResult.data.layout.id ]);

  const ultrasoundScansResult = useApiRequest(async () => {
    return apiv2.appointments.getUltrasoundScans(params.appointment_id);
  }, [ patientAppointmentsTrigger, params.appointment_id ]);

  const in_progress = appointmentResult.data && appointmentResult.data.in_progress;

  React.useEffect(() => {
    if (in_progress === false) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    }
  }, [in_progress]);

  React.useEffect(() => {
    const data = {
      "Appointment ID": params.appointment_id,
      "Appointment in progress": in_progress,
      ...window.VoxKitWidgetSettings.client_data
    };
    setVoximplantSupportData({
      client_email: data
    }, false, true);

    return () => {
      setVoximplantSupportData({
        client_email: {}
      }, false, true);
    };
  }, [in_progress, params]);

  React.useEffect(() => {
    if (layoutResult.data) {
      setLayoutSectionsFieldsMap(layoutResult.data);
    }
  }, [layoutResult]);

  React.useEffect(() => {
    resetStimulationProtocol();

    if (
      layoutResult.data?.display_ovarian_response &&
      appointmentResult.data?.display_ovarian_response &&
      appointmentResult.data?.folder?.ovarian_response
    ) {
      setStimulationProtocol(appointmentResult.data?.folder?.ovarian_response);
    }
  }, [appointmentResult, layoutResult]);

  React.useEffect(() => {
    if (appointmentResult.data) {
      setAppointmentLiveBrithProbability({
        eko_chance: appointmentResult.data.eko_chance,
        eko_do_chance: appointmentResult.data.eko_do_chance,
        vmi_chance: appointmentResult.data.vmi_chance
      });
    }
  }, [appointmentResult]);

  React.useEffect(() => {
    if (appointmentResult.data) {
      setAppointment(appointmentResult.data);
    }
  }, [appointmentResult]);

  if (window.QUEST_MODE && appointmentResult.data && window.QUEST_MEDFILE_NUMBER !== appointmentResult.data.medical_file.number) {
    setTimeout(() => {
      history.push('/quest');
    })
    return null;
  }

  if (!appointmentResult.data || !layoutResult.data) {
    return (
      <BlockLoader transparent />
    );
  }

  if (appointmentResult.data.date_finished || (!appointmentResult.data.date_finished && !appointmentResult.data.can_be_reopened)) {
    return (
      <ReadableAppointmentPage
        appointment={appointmentResult.data}
        layout={layoutResult.data}
        ultrasoundScans={ultrasoundScansResult.data}
        medicalFiles={medicalFiles}
      />
    );
  }

  return <EditAppointmentPage appointment={appointmentResult.data} layout={layoutResult.data} />;
};
