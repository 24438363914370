import apiv2 from '../apiv2';
import DynamicTableStore, { filtersAfterCheck } from "./dynamicTableStore";
import { action, makeObservable, observable } from "mobx";

class PatientsStore extends DynamicTableStore {
  sexLabels = {
    male: 'Мужской',
    female: 'Женский',
  }
  sexOptions = [
    { value: "male", label: 'Мужской'},
    { value: "female", label: 'Женский'},
  ]
  patientEditId = null;
  patientEditData = null;
  showAddFieldPopup = false;
  showSendReesPopup = false;
  customColumns = [];
  customFilters = [];

  constructor() {
    super(apiv2.medicalFiles.getListMedicalFiles.bind(apiv2.medicalFiles), ['id', 'fullNamePatient', 'patient__phone_number', 'patient__birthdate']);
    makeObservable(this, {
      setPatientEditId: action,
      fetchDataPatient: action,
      changeShowAddFieldPopup: action,
      changeShowSendReesPopup: action,
      changeCustomColumns: action,
      changeCustomFilters: action,
      postPatient: action,
      sexLabels: observable,
      sexOptions: observable,
      patientEditId: observable,
      patientEditData: observable,
      showAddFieldPopup: observable,
      showSendReesPopup: observable,
      customFilters: observable,
      customColumns: observable,
    });
  }

  changeCustomColumns(value) {
    this.customColumns = value;
  }

  changeCustomFilters(value) {
    this.customFilters = value;
  }

  changeShowAddFieldPopup(value) {
    this.showAddFieldPopup = value;
  }

  changeShowSendReesPopup(value) {
    this.showSendReesPopup = value;
  }

  setPatientEditId(value) {
    this.patientEditId = value;
  }

  async fetchDataPatient() {
    const response = await apiv2.medicalFiles.getDynamicMedicalFileById({id: this.patientEditId});
    this.patientEditData = response;
  }

  async postPatient(data) {
    data.tags = data.tags__id;
    delete data.tags__id;
    try {
      await apiv2.medicalFiles.postDynamicMedicalFileById([data]);
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async sendRees(data) {
    const params = {
      filter_params: { ...filtersAfterCheck(this.filtersTable), ...filtersAfterCheck(this.globalFiltersTable) },
      values_params: this.valuesParamsTable,
      order_by_params: this.sortingTable,
      communication: { ...data }
    };
    if (this.annotate_mtm_params) {
      params.annotate_mtm_params = this.annotate_mtm_params;
    }
    try {
      await apiv2.medicalFiles.sendRees(params);
      return true;
    } catch(e) {
      console.error(e);
      return false;
    }
  }

  async saveToFile() {
    const params = {
      filter_params: { ...filtersAfterCheck(this.filtersTable), ...filtersAfterCheck(this.globalFiltersTable) },
      values_params: this.valuesParamsTable,
      order_by_params: this.sortingTable
    };
    if (this.annotate_mtm_params) {
      params.annotate_mtm_params = this.annotate_mtm_params;
    }
    try {
      params.is_after_get = true;
      const response = await apiv2.medicalFiles.getListMedicalFiles(params);
      const link = document.createElement("a");
      link.setAttribute('href', `https://${location.hostname}/api/medical-files-search/?request_uuid=` + response.request_uuid);
      link.setAttribute('target', '_blank');
      link.setAttribute('download', `patients.csv`);
      link.click();
    } catch(e) {
      console.error(e);
      return false;
    }


  }
}

export default new PatientsStore();
