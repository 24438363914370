import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";

import chatStore, { ChatFilters } from "../../stores/chatStore";
import { BaseLayout } from "../layouts/BaseLayout";
import { GreetingContentChats } from "../greeting/GreetingContentChats";
import { ChatSearch } from "./components/ChatSearch";
import { ChatFooter } from "./components/ChatFooter";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { SettingChatsModal } from "./modals/SettingChatsModal";
import { ChatItem } from "./components/ChatItem";
import { ActiveChat } from "./components/ActiveChat";
import { scrollbars } from "./style-elements";
import { HeadingH3 } from '../styleguide/typography';
import { AlertWarning } from '../alert-warning/AlertWarning';
import { AlertWarningPrimaryButton, AlertWarningWhiteButton } from '../buttons/AlertButton';
import { useToast } from '../toast/useToast';

import chatIcon from "./icons/chat.svg";
import useReactRouter from "use-react-router";
import loaderIcon from '../schedule-loader-modal/icons/loader.svg';
import {MediaPermissionLockModal} from "../common/mediaPermissionLockModal";
import {MediaPermissionSelectModal} from "../common/mediaPermissionSelectModal";
import {MediaPermissionNotDeviceModal} from "../common/mediaPermissionNotDeviceModal";
import {VoximplantConnectionErrorModal} from './modals/VoximplantConnectionErrorModal';
import { FEATURE, useApplicationFeatureEnabled } from "../../utils/applicationFeatures";
import headerStore, { TABS_URL } from "../../stores/headerStore";

const Container = styled.div`
  display: grid;
  background: #fff;
  border-radius: 8px;
  grid-template-columns: minmax(0, 2fr) minmax(0, 4fr);
  grid-template-rows: 1fr;
  height: calc(100vh - 150px);
  box-shadow: 0 7px 9px rgba(0, 0, 0, 0.03);
  overflow: hidden;

	@media screen and (max-width: 1310px) {
		height: calc(100vh - 170px);
	}

	@media screen and (max-width: 1200px) {
	  height: calc(100vh - 200px);
	}

	@media screen and (max-width: 1081px) {
		height: calc(100vh - 215px);
	}

	@media screen and (max-width: 821px) {
		height: calc(100vh - 230px);
	}

	@media screen and (max-width: 779px) {
		height: calc(100vh - 245px);
	}

  @media screen and (max-width: 768px) {
    height: ${props => props.screenKeyboardShowed ? "50%" : "100%"};
    grid-template-columns: minmax(0, 1fr);
    overflow: hidden;
    position: relative;
  }
  @media screen and (max-width: 480px) {
    border-radius: 0;
  }
`;

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ChatHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #f3f3f3;
  min-height: 0;
`;

const ChatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
    
  ${scrollbars};
`;

const ActiveChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  background: #fff;

  @media screen and (max-width: 768px) {
    position: absolute;
    left: 100%;
    z-index: 2;
    width: 100%;
    height: 100%;
    transition: left 0.2s ease-in-out;
  }
`;

const NoActiveChatContainer = styled(AlignCenter)`
  flex-direction: column;
  gap: 16px;
`;

const Loader = styled.img`
  margin-bottom: 16px;
  animation: rotate 2s linear infinite;
`;

const UpdateChatrooms = styled(AlignCenter)`
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
`;

const NoContentText = styled.span`
  color: #7d7d7d;
  font-family: "Graphik LCG";
  font-size: 15px;
  line-height: 23px;
  text-align: center;
`;

const NoChatsByFilterMessages = {
  [ChatFilters.ALL]: (
    <>
      У вас нет ни одного чата.
      <br />
      Откройте чат в медкарте
      <br />
      пациента, чтобы он появился
      <br />
      здесь.
    </>
  ),
  [ChatFilters.NOT_READ]: "У вас нет непрочитанных чатов",
  [ChatFilters.OPENED]: "У вас нет открытых чатов",
  [ChatFilters.CLOSED]: "У вас нет закрытых чатов",
  [ChatFilters.PAID]: "У вас нет платных чатов"
};

const footerContent = (SHOW_CHATS_SETTING) => {
  return [
    {
      view: {
        icon: <SettingsIcon />,
        label: "Настройки"
      },
      controlled: {
        handleOnClick: () => chatStore.openSettingChatModal()
      },
      show: SHOW_CHATS_SETTING
    }
  ];
}

export const ChatsPage = observer(() => {
  const {
    history,
    match: { params }
  } = useReactRouter();
  const toastManager = useToast();
  const chatsContainerRef = useRef()
  const SHOW_CHATS_SETTING = useApplicationFeatureEnabled(FEATURE.SHOW_CHATS_SETTING);

  const handleScroll = React.useCallback(async () => {
    if (!chatsContainerRef.current) {
      return;
    }
    if (chatsContainerRef.current.scrollTop < chatsContainerRef.current.scrollHeight - (chatsContainerRef.current.clientHeight + 200)) {
      return;
    }
    await chatStore.updateChats();
  }, []);

  const handleMessageListener = useCallback(message => {
    if (message?.data === "refreshCurrentChat" && chatStore.currentChat) {
      chatStore.loadCurrentChatMessages();
    }
    if (message?.data === "refreshChatsWebSocket" && chatStore.socket) {
      chatStore.socket.close();
      chatStore.initWS();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleMessageListener);
    return () => window.removeEventListener("message", handleMessageListener);
  }, []);

  useEffect(() => {
    (async () => {
      let chatId = null;
      if (params.id) {
        chatId = parseInt(params.id, 10);
        chatStore.setCurrentChatId(chatId);
      }
      if (!chatStore.isChatsLoaded && !chatStore.isChatsLoading) {
        // Грузим чаты для звонка, если чаты выключены у пользователя, но произошел переход по прямой ссылке.
        chatStore.loadChats();
        return;
      }

      const scrollToChat = (chatId) => {
        setTimeout(() => {
          const chatItemElement = document.querySelector(`[data-chat-id="${chatId}"]`);

          if (chatItemElement) {
            chatItemElement.scrollIntoView({
              block: "center"
            });
          }
        }, 100);
      };

      const setChatAndCallScreen = async (chatId) => {
        chatStore.setCurrentChat(chatId);

        const currentURL = new URL(window.location.href);
        if (currentURL.searchParams.size > 0) {
          if (currentURL.searchParams.get("startCall") === "true") {
            currentURL.searchParams.delete("startCall");
            window.history.pushState(null, null, currentURL.toString());
            await chatStore.showCallSettingsScreen();
          }
        }
      };

      let chatWasExist = false;
      if (params.id) {
        chatWasExist = chatStore.hasChat(chatId);
        if (chatWasExist) {
          await setChatAndCallScreen(chatId);
        }
      } else {
        chatStore.setCurrentChat(null);
      }

      await chatStore.loadChats();
      if (chatWasExist) {
        scrollToChat(chatId); // скроллимся к чату после 2 обновления (если чат нашелся в 1 группе), что бы не было дерганья
      }

      if (params.id && !chatWasExist) {
        await chatStore.loadChatById(chatId);
        if (!chatStore.hasChat(chatId)) {
          history.replace('/chats');
          toastManager.showToast((
            <div>
              Чат не найден
            </div>
          ), {
            duration: 10000
          });
          return;
        }
        await setChatAndCallScreen(chatId);
        scrollToChat(chatId);
      }
    })();
  }, [chatStore.isChatsLoaded]);

  const callOpenChatAlertWarning = (
    <AlertWarning
      isNeedConfirm={chatStore.isCallOpenChatConfirmShowed}
      sendConfirm={async (value) => {
        chatStore.closeCallOpenChatConfirm();
        if (value) {
          await chatStore.openChat(chatStore.currentChatId);
          await chatStore.showCallSettingsScreen();
        }
      }}
      heading={<HeadingH3 modifiers="center">Чат откроется, когда вы позвоните пациенту</HeadingH3>}
      description={
        "Пациент сможет отправлять вам сообщения, а вы сможете написать ему. Закрыть чат можно в чате (иконка с замком)."
      }
      cancelButton={onClick => (
        <AlertWarningWhiteButton onClick={onClick}>Отмена</AlertWarningWhiteButton>
      )}
      confirmButton={onClick => (
        <AlertWarningPrimaryButton onClick={onClick}>Позвонить</AlertWarningPrimaryButton>
      )}
    />
  );

  return (
    <BaseLayout contentWidth={1015} greeting={null}>
      <Container screenKeyboardShowed={chatStore.screenKeyboardShowed}>
        <ChatHistoryContainer>
          <ChatSearch />
          {!chatStore.isChatsLoaded ? (
            <AlignCenter>
              <Loader src={loaderIcon} alt="Loading" />
            </AlignCenter>
          ) : (!!chatStore.chats.length || chatStore.isChatsLoading) ? (
            <ChatsContainer ref={chatsContainerRef} onScroll={handleScroll}>
              {
                chatStore.isChatsLoading && (
                  <UpdateChatrooms>
                    Загружаем чаты...
                    <Loader src={loaderIcon} alt="Loading" />
                  </UpdateChatrooms>
                )
              }
              {
                chatStore.chats.length > 0 ? chatStore.chats.map(chat => {
                  return <ChatItem key={chat.id} chat={chat} />;
                }) : !chatStore.isChatsLoading && (
                  <AlignCenter>
                    <NoContentText>
                      Чаты не найдены
                    </NoContentText>
                  </AlignCenter>
                )
              }
              {
                chatStore.isChatsLoading && chatStore.chatsPagination.totalPageCount && chatStore.chatsPagination.totalPageCount > chatStore.chatsPagination.currentPage && (
                  <UpdateChatrooms>
                    Загружаем чаты...
                    <Loader src={loaderIcon} alt="Loading" />
                  </UpdateChatrooms>
                )
              }
            </ChatsContainer>
          ) : (
            <AlignCenter>
              <NoContentText>
                { NoChatsByFilterMessages[chatStore.selectedFilter] }
              </NoContentText>
            </AlignCenter>
          )}
          <ChatFooter contents={footerContent(SHOW_CHATS_SETTING)} />
        </ChatHistoryContainer>
        <ActiveChatContainer
          style={{
            left: chatStore.currentChat ? "0" : "100%"
          }}>
          {!!chatStore.currentChatId ? (
            <ActiveChat />
          ) : (
            <NoActiveChatContainer>
              <img src={chatIcon} alt="Chat icon" />
              <NoContentText>
                Выберите чат, чтобы
                <br />
                написать пациенту
              </NoContentText>
            </NoActiveChatContainer>
          )}
        </ActiveChatContainer>
        { callOpenChatAlertWarning }
        <MediaPermissionLockModal isShown={chatStore.showMediaPermissionLockModal} />
        <MediaPermissionSelectModal isShown={chatStore.showMediaPermissionSelectModal} />
        <MediaPermissionNotDeviceModal isShown={chatStore.showMediaPermissionNotDeviceModal} />
        <VoximplantConnectionErrorModal isShown={chatStore.showVoximplantConnectionErrorModal} />
        <SettingChatsModal />
      </Container>
    </BaseLayout>
  );
});
