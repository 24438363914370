// @flow
import * as React from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { PNormal, CaptionSmall } from "../styleguide/typography";
import { colors } from "../styleguide/colors";
import ArrowIcon from "./icons/arrow-down-gray.svg";

const Container = styled.div`
  width: 100%;
  min-height: 75px;
  padding: 12px 24px;
  box-sizing: border-box;

  cursor: pointer;
  transition: all 0.2s;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  :hover {
    background-color: ${props => props.hasChildren && rgba(colors.gray100, 0.15)};
  }

  :after,
  :before {
    position: absolute;
    width: calc(100% - 47px);
    background-color: ${colors.transparentGray};
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s;
  }

  :before {
    content: "";
    top: 1px;
  }

  &:last-child:after {
    content: "";
    bottom: 1px;
  }

  &:first-child:before {
    opacity: 0;
  }

  &:hover:before,
  &:hover + &:before,
  &:hover:last-child:after {
    opacity: ${props => (props.hasChildren ? 0 : null)};
  }
`;

const CheckboxContainer = styled.div`
  opacity: 0;
  pointer-events: none;
  flex-shrink: 0;

  ${Container}:hover & {
    opacity: 1;
    pointer-events: auto;
  }
`;

const TextContainer = styled.div`
  width: 330px;
`;

const Arrow = styled.div`
  border: none;
  background: url(${ArrowIcon}) no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  margin-left: 10px;

  flex-shrink: 0;
  transform: rotate(-90deg);
`;

const Description = styled(PNormal)`
  opacity: 0.6;
`;

export const DiagnosesTreeItem = (props: {
  title: string,
  description: string,
  checkbox?: React$Node,
  hasChildren?: boolean,
  onClick?: () => mixed
}) => {
  return (
    <Container onClick={props.onClick} hasChildren={props.hasChildren}>
      <TextContainer>
        <CaptionSmall modifiers="left">{props.title}</CaptionSmall>
        <Description modifiers="left">{props.description}</Description>
      </TextContainer>
      {props.checkbox && (
        <CheckboxContainer onClick={e => e.stopPropagation()}>{props.checkbox}</CheckboxContainer>
      )}
      {props.hasChildren && <Arrow />}
    </Container>
  );
};
