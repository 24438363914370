// @flow
import React from "react";
import { AddButton } from "../buttons/AddButton";
import { Link } from "react-router-dom";

export const NewAppointmentButton = (props: { to: string, handleOnClick?: () => void }) => {
  return (
    <AddButton
      onClick={props.handleOnClick && props.handleOnClick}
      overridesDefaultButton={{
        Container: {
          props: {
            as: Link,
            to: props.to
          }
        }
      }}
    >
      Новая запись
    </AddButton>
  );
};
