// @flow
import React from "react";
import styled from "styled-components";
import { HeadingH6 } from "../styleguide/typography";
import { BaseInput, Container } from "./BaseInput";

const ExtendedContainer = styled(Container)`
  height: 50px;
`;

const Input = styled(HeadingH6)`
  outline: none;
  border: none;
  background: transparent;

  width: 100%;
`;

export const NewGroupInput = (props: React$ElementConfig<typeof BaseInput>) => {
  return (
    <BaseInput
      {...props}
      overrides={{
        Container: {
          component: ExtendedContainer
        },
        Input: {
          component: Input
        }
      }}
    />
  );
};
