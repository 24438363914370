import { Grid, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { ModalPortal } from "../../modal/ModalPortal";
import Dialog from "@mui/material/Dialog";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { AddOutlined } from "@mui/icons-material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { DynamicForm } from "../dynamic-form/DynamicForm";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AbsoluteLoader } from "../../loader/Loader";

const LabelDiv = styled.div`
    font-size: 24px;
    
    @media(max-width: 768px) {
        font-size: 20px;
    }
`;

export function LabelWithFiltersModal(props: {
  filtersData?: () => {},
  filtersFields?: Array,
  callbackOnCloseFilter?: () => void,
  label: String,
  loading?: boolean,
  debounceTime?: number,
  textButtonFilter?: String,
  submitHandler?: () => any,
  watcherOnChangeData?: () => any,
  type?: String,
  fullWidth?: Boolean,
  wrap?: Boolean,
  editingMode?: {
    isShow: Boolean,
    isActive: Boolean,
    handleEnableEditingMode: () => void
  }
}) {
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    setFilters(props.filtersData || {})
  }, [props.filtersData]);

  const onCloseFilters = (reason) => {
    if (reason !== 'backdropClick') {
      setOpenFilter(false)
    }
  }

  const submitHandler = (filters) => {
    props.submitHandler(filters, () => setOpenFilter(false));
  }

  return (
    <Grid maxWidth={props.fullWidth ? "100%" : "max-content"} alignItems={"center"} flexWrap={props.wrap ? 'wrap' : "nowrap"} justifyContent={"space-between"} container spacing={2}>
      <Grid item>
        <LabelDiv variant="h5" gutterBottom>
          { props.label }
        </LabelDiv>
      </Grid>
      {props.filtersFields && (
        <>
          <Grid className={'label-with-filters-modal'} item>
            <Stack direction="row" spacing={2}>
              {props.editingMode?.isShow && (
                <Button
                  startIcon={props.editingMode.isActive ? <SaveOutlinedIcon /> : <EditOutlinedIcon />}
                  variant={'outlined'}
                  onClick={props.editingMode.handleEnableEditingMode}
                >
                  {props.editingMode.isActive ? 'Выключить' : 'Включить'} редактирование
                </Button>
              )}
              <Button
                variant={props.type === 'widget' ? 'contained' : 'outlined'}
                startIcon={props.type === 'widget' && <AddOutlined />}
                onMouseDown={(event) => event.stopPropagation()}
                onTouchStart={(event) => event.stopPropagation()}
                onClick={() => setOpenFilter(true)}
              >
                {props.type === 'filter' && <FilterAltOutlinedIcon />}
                {props.type === 'widget' && 'Добавить виджет'}
              </Button>
            </Stack>
          </Grid>
          <ModalPortal>
            <Dialog
              maxWidth={"sm"}
              fullWidth
              open={openFilter}
              onClose={(event, reason) => onCloseFilters(reason)}
            >
              <DialogTitle sx={{ m: 0, p: 2 }}>{props.type === 'filter' ? 'Фильтры' : 'Добавить виджет'}</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setOpenFilter(false);
                  props.callbackOnCloseFilter && props.callbackOnCloseFilter();
                }}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                {props.loading && <AbsoluteLoader />}
                <DynamicForm textButton={props.textButtonFilter} debounceTime={props.debounceTime} data={filters} submitHandler={submitHandler} fields={props.filtersFields} hiddenButtonCancel={true} changeHandler={props.watcherOnChangeData} />
              </DialogContent>
            </Dialog>
          </ModalPortal>
      </>
    )}
    </Grid>
  )
}
