import * as React from "react";
import styled from "styled-components";
import { colors } from "../styleguide/colors";

const COLUMN_LABEL_BASE = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const COLUMN_LABEL_BASE_LENGTH = COLUMN_LABEL_BASE.length;

export function columnIndexToLabel(column) {
  let result = "";

  while (column >= 0) {
    result = String.fromCharCode((column % COLUMN_LABEL_BASE_LENGTH) + 97) + result;
    column = Math.floor(column / COLUMN_LABEL_BASE_LENGTH) - 1;
  }

  return result.toUpperCase();
}

const CellHeader = styled.th`
  position: relative;
  border-left: 1px solid rgb(231, 231, 231);
  background: ${colors.white};
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
  user-select: none;
  font-size: 14px;
  padding: 0 12px;
  max-width: 300px;
  height: 44px;
  font-weight: 500;
  vertical-align: middle;
  line-height: 1.35;
  white-space: pre;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export const ColumnIndicator = ({ column, label }) => (
  <CellHeader isFirst={column === 0}>
    {label !== undefined ? label : columnIndexToLabel(String(column))}
  </CellHeader>
);
