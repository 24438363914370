//@flow
import * as React from "react";
import { useContext } from "react";

import { ScrollContext } from "../scroll-detector/ScrollDetector";

import { HistoryTableHead, type HistoryColumnType } from "./HistoryTableHead";

export const HistoryTableLayout = (props: {|
  columns: HistoryColumnType,
  children?: React.Node
|}) => {
  const { setTableRef } = useContext(ScrollContext);
  return (
    <table ref={setTableRef}>
      <HistoryTableHead columns={props.columns} />
      <tbody>{props.children}</tbody>
    </table>
  );
};
